<table role="table" class="table">
    <thead class="table-head">
        <tr role="row" class="">
            <th
                colspan="1"
                role="columnheader"
                *ngFor="let columnHeader of columnHeaders"
                class="base-cell header-cell {{ columnHeader.class }}  "
            >
                {{ columnHeader.label }}
            </th>
        </tr>
    </thead>

    <tbody role="rowgroup" class="table-body">
        <ng-container *ngIf="!isLoading; else loading">
            <ng-container
                *ngTemplateOutlet="subtable; context: { subtableDataArray: this.getManagementCostsSubtableDataArray() }"
            >
            </ng-container>

            <ng-container
                *ngTemplateOutlet="
                    breaklineRef;
                    context: {
                        breakLineValues: this.managementCosts,
                        label: translateService.instant('PAGES.ECONOMIC_PLAN.TABLE.MANAGEMENT_COSTS'),
                        economicPlanType: this.economicPlanType,
                        textStyleClass: 's-head-14-22-bold'
                    }
                "
            >
            </ng-container>

            <ng-container
                *ngTemplateOutlet="subtable; context: { subtableDataArray: this.getReserveFundSubtableDataArray() }"
            >
            </ng-container>

            <ng-container
                *ngTemplateOutlet="
                    breaklineRef;
                    context: {
                        breakLineValues: this.finalCosts,
                        label: translateService.instant('PAGES.ECONOMIC_PLAN.TABLE.FINAL_COSTS'),
                        classModifier: 'big',
                        economicPlanType: this.economicPlanType,
                        textStyleClass: 's-head-16-24-bold'
                    }
                "
            >
            </ng-container>
        </ng-container>
    </tbody>
</table>

<ng-template
    #breaklineRef
    let-economicPlanType="economicPlanType"
    let-breakLineValues="breakLineValues"
    let-label="label"
    let-classModifier="classModifier"
    let-textStyleClass="textStyleClass"
>
    <ng-container *ngIf="breakLineValues">
        <tr class="breakline breakline--{{ classModifier }}"></tr>
        <tr class="{{ textStyleClass }}">
            <td role="cell" class="base-cell pt-0-75r pb-2r">{{ label }}</td>
            <td class="base-cell pt-0-75r pb-2r">{{ breakLineValues['labels'][0] }}</td>
            <td role="cell" class="base-cell">{{ ' ' }}</td>
            <td role="cell" class="base-cell">{{ ' ' }}</td>
            <ng-container *ngIf="economicPlanType === 'single'">
                <td role="cell" class="base-cell">{{ ' ' }}</td>
                <td class="base-cell pt-0-75r pb-2r">{{ breakLineValues['labels'][1] }}</td>
            </ng-container>
        </tr>
    </ng-container>
</ng-template>

<ng-template #subtable let-subtableDataArray="subtableDataArray">
    <ng-template [ngIf]="asSubtableDataArray(subtableDataArray)" let-subtableDataArray="ngIf">
        <ng-container *ngFor="let subtableData of subtableDataArray">
            <tr role="row" class="body-row group-heading-row">
                <td role="cell" class="base-cell group-heading-row_cell">{{ subtableData.headlingLabel }}</td>
            </tr>
            <tr role="row" class="body-row basic-row" *ngFor="let dataRowValues of subtableData.dataRowValues">
                <td *ngFor="let value of dataRowValues" role="cell" class="base-cell basic-row_cell">
                    {{ value }}
                </td>
            </tr>
            <!-- row for spacing between data rows and summary rows -->
            <tr role="row" class="spacing-row-cell">
                <td role="cell">{{ ' ' }}</td>
            </tr>
            <tr role="row" class="body-row summary-row" *ngIf="subtableData.summaryRowValues">
                <td role="cell" class="base-cell summary-row_cell" *ngFor="let cell of subtableData.summaryRowValues">
                    {{ cell }}
                </td>
            </tr>
        </ng-container>
    </ng-template>
</ng-template>

<ng-template #loading>
    <tr role="row">
        <td role="cell" class="base-cell">{{ ' ' }}</td>
    </tr>
</ng-template>
