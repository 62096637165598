import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { EnergyCertificate, EnergyCertificatesService } from 'src/app/generated-sources/base';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';

@Component({
    selector: 'app-delete-energy-certificate-overlay',
    templateUrl: './delete-energy-certificate-overlay.component.html',
    styleUrls: ['./delete-energy-certificate-overlay.component.scss'],
})
export class DeleteEnergyCertificateOverlayComponent extends OverlayChildComponent implements OnInit, OnDestroy {
    public buildingId?: string;
    public propertyId?: string;
    public energyCertificate?: EnergyCertificate;
    private unsubscribe$ = new Subject<void>();

    public constructor(
        private energyCertificatesService: EnergyCertificatesService,
        private toastService: ToastService,
        private translateService: TranslateService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.buildingId = this.config?.data.buildingId;
        this.propertyId = this.config?.data.propertyId;
        this.energyCertificate = this.config?.data.energyCertificate;
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public submit(): void {
        if (!this.propertyId || !this.buildingId || !this.energyCertificate) {
            return;
        }
        this.energyCertificatesService
            .deleteEnergyCertificate(this.propertyId, this.buildingId, this.energyCertificate?.id)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: () => {
                    this.toastService.showSuccess(
                        this.translateService.instant('PAGES.DELETE_ENERGY_CERTIFICATE.SUCCESS_TOAST')
                    );
                    this.saveEmitter$.next();
                },
                error: (error) => {
                    if (error) {
                        this.toastService.showError(error.error['message']);
                    }
                },
            });
    }
}
