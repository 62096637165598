import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import {
    BreadcrumbModule,
    DropdownModule,
    GridModule,
    InlineLoadingModule,
    InputModule,
    LoadingModule,
    TabsModule,
} from 'carbon-components-angular';
import { SharedModule } from 'src/app/shared/shared.module';
import { AddEditTicketFormComponent } from './components/add-edit-ticket-form/add-edit-ticket-form.component';
import { AddServiceProviderComponent } from './components/add-service-provider/add-service-provider.component';
import { TicketsIndexComponent } from './components/tickets-index/tickets-index.component';
import { DeleteTicketServiceProviderComponent } from './components/delete-ticket-service-provider/delete-ticket-service-provider.component';
import { DeleteTicketComponent } from './components/delete-ticket/delete-ticket.component';
import { TicketCommentsComponent } from './components/ticket-comments/ticket-comments.component';
import { TicketDetailIconsComponent } from './components/ticket-detail-icons/ticket-detail-icons.component';
import { TicketDetailComponent } from './components/ticket-detail/ticket-detail.component';
import { TicketOverviewComponent } from './components/ticket-overview/ticket-overview.component';
import { TicketStatusDropdownComponent } from './components/ticket-overview/ticket-status-dropdown/ticket-status-dropdown.component';
@NgModule({
    declarations: [
        TicketsIndexComponent,
        AddEditTicketFormComponent,
        TicketDetailComponent,
        DeleteTicketComponent,
        TicketOverviewComponent,
        TicketCommentsComponent,
        TicketStatusDropdownComponent,
        TicketDetailIconsComponent,
        AddServiceProviderComponent,
        DeleteTicketServiceProviderComponent,
    ],
    imports: [
        CommonModule,
        GridModule,
        SharedModule,
        TabsModule,
        AngularSvgIconModule,
        DropdownModule,
        InputModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        BreadcrumbModule,
        LoadingModule,
        InlineLoadingModule,
    ],
})
export class CommunicationModule {}
