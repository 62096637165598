import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { tap } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { PdfBackgroundDto, PdfConfigurationsService } from 'src/app/generated-sources/accounting';

@Component({
    selector: 'app-pdf-configurations-file-input',
    templateUrl: './pdf-configurations-file-input.component.html',
    styleUrls: ['./pdf-configurations-file-input.component.scss'],
})
export class PdfConfigurationsFileInputComponent {
    public isFileLoading = false;
    public isLoading = false;
    public selectedFile?: File;

    @ViewChild('fileInput') public fileInput: any;

    @Input() public currentFile?: PdfBackgroundDto;
    @Input() public pdfTypeTitle?: string;
    @Input() public pdfBackgroundType?: PdfBackgroundDto.TypeEnum;

    @Output() public refreshFiles = new EventEmitter<void>();

    public constructor(
        private pdfConfigurationsService: PdfConfigurationsService,
        private toastService: ToastService
    ) {}

    public fileChangeEvent(event: any): void {
        this.selectedFile = event.target.files[0];

        if (!this.selectedFile || !this.pdfBackgroundType) {
            return;
        }

        this.isFileLoading = true;

        this.pdfConfigurationsService
            .setNewBackgroundTemplate(this.pdfBackgroundType, this.selectedFile)
            .pipe(tap(() => (this.selectedFile = undefined)))
            .subscribe({
                next: () => {
                    this.refreshFiles.emit();
                    this.isFileLoading = false;
                    this.toastService.showSuccess('Datei erfolgreich hochgeladen');
                },
                error: (error) => {
                    this.isFileLoading = false;
                    this.toastService.showError(error.message);
                },
            });
    }

    public deleteFile(): void {
        if (!this.pdfBackgroundType) {
            return;
        }
        this.isLoading = true;

        this.pdfConfigurationsService.deleteBackgroundTemplate(this.pdfBackgroundType).subscribe({
            next: () => {
                this.refreshFiles.emit();
                this.isLoading = false;
            },
            error: (error) => {
                this.isLoading = false;
                this.toastService.showError(error.message);
            },
        });
    }
}
