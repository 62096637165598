import { Component } from '@angular/core';
import { DocumentsFlow } from '../documents/add-document-overlay/add-document-overlay.component';

@Component({
    selector: 'app-documents-overview',
    templateUrl: './documents-overview.component.html',
    styleUrls: ['./documents-overview.component.scss'],
})
export class DocumentsOverviewComponent {
    public DocumentsFlow = DocumentsFlow;
}
