import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from 'src/app/core/services/toast.service';
import { controlInFormGroupIsInvalid } from 'src/app/core/utils/formValidationHelpers';
import { SubscriptionsService } from 'src/app/generated-sources/base';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';

@Component({
    selector: 'app-update-subscription-overlay',
    templateUrl: './update-subscription-overlay.component.html',
    styleUrls: ['./update-subscription-overlay.component.scss'],
})
export class UpdateSubscriptionOverlayComponent extends OverlayChildComponent implements OnInit {
    public constructor(
        public formBuilder: UntypedFormBuilder,
        public subscriptionsService: SubscriptionsService,
        public toastService: ToastService,
        public translateService: TranslateService,
        public router: Router
    ) {
        super();
    }

    public readonly labels = {
        titles: { UPDATE: 'PAGES.ABO.UPDATE.UPDATE_ABO', CANCEL: 'PAGES.ABO.CANCEL.CANCEL_ABO' },
        buttons: { UPDATE: 'PAGES.ABO.UPDATE.BUTTONLABEL', CANCEL: 'PAGES.ABO.CANCEL.CANCEL_ABO' },
    };

    public isLoading = false;

    public flow: 'none' | 'UPDATE' | 'CANCEL' = 'none';

    public ngOnInit(): void {
        this.flow = this.config?.data.flow;
        console.log({ config: this.config });
    }

    public onSubmit(): void {
        this.saveEmitter$.next();
    }

    public onCancel(): void {
        this.cancelEmitter$.next();
    }

    //  create custom validator to check if input value mathes the string 'kündigen' or 'kuendigen'
    public validateInput: ValidatorFn = (control: any) => {
        const input = (control.value ?? '').toLowerCase();
        if (input === 'kündigen' || input === 'kuendigen') {
            return null;
        } else {
            return { validateInput: { valid: false } };
        }
    };

    // create form with text input and submit button
    public form = this.formBuilder.group({
        validationInput: [null, this.validateInput],
    });

    public get isButtonDisabled(): boolean {
        return this.flow === 'CANCEL' && this.form.invalid;
    }

    public get imageSrc(): string {
        return this.flow === 'CANCEL' ? '40_delete.svg' : '';
    }

    public controlInFormGroupIsInvalid = controlInFormGroupIsInvalid;
}
