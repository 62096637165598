import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject, combineLatest, switchMap, takeUntil, tap } from 'rxjs';
import {
    TechnicalEquipmentDto,
    TechnicalEquipmentFormMappingDto,
    TechnicalEquipmentService,
} from 'src/app/generated-sources/base';
import { OverlayCallbacks } from 'src/app/shared/components/overlay-details/overlay-details.component';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { AddEditTechnicalEquipmentOverlayComponent } from '../add-edit-technical-equipment-overlay/add-edit-technical-equipment-overlay.component';
import { DeleteTechnicalEquipmentComponent } from '../delete-technical-equipment/delete-technical-equipment.component';

@Component({
    selector: 'app-technical-equipment-details-overlay',
    templateUrl: './technical-equipment-details-overlay.component.html',
    styleUrls: ['./technical-equipment-details-overlay.component.scss'],
})
export class TechnicalEquipmentDetailsOverlayComponent extends OverlayChildComponent implements OnInit, OnDestroy {
    public technicalEquipmentsIdList?: string[];
    public technicalEquipmentCurrentId$ = new BehaviorSubject<string>('');
    public refreshTechnicalEquipment$ = new BehaviorSubject<void>(undefined);
    public unsubscribe$ = new Subject<void>();

    public propertyId = '';
    public buildingId = '';
    public pageTitle = '';

    public technicalEquipment?: TechnicalEquipmentDto;
    public fieldsToShow?: TechnicalEquipmentFormMappingDto[] = [];

    public findTechnicalEquipmentById$ = combineLatest([
        this.technicalEquipmentCurrentId$,
        this.refreshTechnicalEquipment$,
    ]).pipe(
        switchMap(([equipmentId]) => {
            return this.technicalEquipmentService.findOneBuildingTechnicalEquipment(
                this.propertyId,
                this.buildingId,
                equipmentId
            );
        }),
        tap((equipment) => {
            this.technicalEquipment = equipment;
        }),
        switchMap(() => {
            return this.technicalEquipmentService.findBuildingEquipmentTypes();
        }),
        tap((findFormInfo) => {
            const findTechnicalEquipmentById = findFormInfo.find(
                (item) => item.id === this.technicalEquipment?.equipmentTypeId
            );

            this.fieldsToShow = findTechnicalEquipmentById?.formFields.sort((a, b) => {
                if (a.key === 'specificationArray') {
                    return 1;
                }
                if (b.key === 'specificationArray') {
                    return -1;
                }
                return 0;
            });

            this.pageTitle = findTechnicalEquipmentById?.equipmentLabel ?? '';
        })
    );

    public overlayCallbacks: OverlayCallbacks = {
        deleteOverlay: () => {
            this.openDeleteTechnicalEquipmentOverlay();
        },
        editOverlay: () => {
            this.openEditTechnicalEquipmentOverlay();
        },
    };

    public constructor(
        private technicalEquipmentService: TechnicalEquipmentService,
        private overlayService: OverlayService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.propertyId = this.config?.data.propertyId;
        this.buildingId = this.config?.data.buildingId;

        this.technicalEquipmentCurrentId$.next(this.config?.data.technicalEquipment?.id);
        this.technicalEquipmentsIdList = this.config?.data.technicalEquipmentsIdList;

        this.findTechnicalEquipmentById$.subscribe();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public findNewEquipmentId(id: string): void {
        this.technicalEquipmentCurrentId$.next(id);
    }

    public openEditTechnicalEquipmentOverlay(): void {
        const ref = this.overlayService.open(AddEditTechnicalEquipmentOverlayComponent, {
            data: {
                technicalEquipmentToEdit: this.technicalEquipment,
                buildingId: this.buildingId,
                propertyId: this.propertyId,
            },
        });

        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.refreshTechnicalEquipment$.next());
    }

    public openDeleteTechnicalEquipmentOverlay(): void {
        const ref = this.overlayService.open(DeleteTechnicalEquipmentComponent, {
            data: {
                technicalEquipment: this.technicalEquipment,
                buildingId: this.buildingId,
                propertyId: this.propertyId,
            },
        });

        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.saveEmitter$.next();
        });
    }
}
