<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>
<app-overlay-container
    [headlineTitle]="
        technicalEquipmentToEdit
            ? 'PAGES.ADD_TECHNICAL_EAQUIPMENT.HEADLINE_EDIT'
            : 'PAGES.ADD_TECHNICAL_EAQUIPMENT.HEADLINE'
    "
    [containerSize]="(showAddTechnicalEquimentDetailsForm$ | async) ? 'default' : 'large'"
>
    <!-- FORM -->
    <ng-container *ngIf="showAddTechnicalEquimentDetailsForm$ | async; else selectTechnicalEquipmentType">
        <app-headline-with-icon
            color="green"
            [image]="'40_technical-equipment-' + selectedTechnicalEquiment?.id + '.svg'"
            [text]="selectedTechnicalEquiment?.equipmentLabel ?? ''"
            size="ml"
        ></app-headline-with-icon>

        <form *ngIf="form" [formGroup]="form" class="tw-mt-8">
            <div class="tw-grid tw-grid-cols-2 tw-gap-8">
                <ibm-label
                    *ngIf="getFormItem('year') as formItem"
                    [invalid]="controlInFormGroupIsInvalid(form, formItem.key)"
                    invalidText="{{ 'ERROR.GENERAL' | translate }}"
                >
                    {{ formItem.label }}
                    <input
                        type="number"
                        min="1000"
                        max="2099"
                        ibmText
                        [name]="formItem.key"
                        [formControlName]="formItem.key"
                        [invalid]="controlInFormGroupIsInvalid(form, formItem.key)"
                    />
                </ibm-label>

                <ibm-label
                    *ngIf="getFormItem('lastRenewed') as formItem"
                    invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                >
                    {{ formItem.label }}
                    <input ibmText [name]="formItem.key" [formControlName]="formItem.key" />
                </ibm-label>
            </div>

            <ibm-label
                *ngIf="getFormItem('preservationStatus') as formItem"
                invalidText="{{ 'ERROR.REQUIRED' | translate }}"
            >
                {{ formItem.label }}
                <input ibmText [name]="formItem.key" [formControlName]="formItem.key" />
            </ibm-label>

            <ibm-label *ngIf="getFormItem('manufacturer') as formItem" invalidText="{{ 'ERROR.REQUIRED' | translate }}">
                {{ formItem.label }}
                <input ibmText [name]="formItem.key" [formControlName]="formItem.key" />
            </ibm-label>

            <ibm-label *ngIf="getFormItem('serialNumber') as formItem" invalidText="{{ 'ERROR.REQUIRED' | translate }}">
                {{ formItem.label }}
                <input ibmText [name]="formItem.key" [formControlName]="formItem.key" />
            </ibm-label>

            <ibm-label
                *ngIf="getFormItem('maintenanceInterval') as formItem"
                invalidText="{{ 'ERROR.REQUIRED' | translate }}"
            >
                {{ formItem.label }}
                <input ibmText [name]="formItem.key" [formControlName]="formItem.key" />
            </ibm-label>

            <ibm-label *ngIf="getFormItem('notes') as formItem">
                {{ formItem.label }}
                <textarea
                    rows="3"
                    ibmTextArea
                    aria-label="textarea"
                    [formControlName]="formItem.key"
                    maxlength="100000"
                ></textarea>
            </ibm-label>
        </form>

        <div class="tw-mt-16" *ngIf="getFormItem('specificationArray') as formItem">
            <div class="tw-mb-6 tw-s-head-14-22-bold">Wählen Sie die Merkmale der Ausstattung</div>
            <app-multi-selector-list
                [itemList]="specificationList"
                (updateSelectedItems)="updateSelectedSpecification($event)"
            ></app-multi-selector-list>
        </div>

        <div class="tw-mt-16" *ngIf="getFormItem('technicalEquipmentFiles') as formItem">
            <div class="tw-s-head-14-22-bold tw-mb-4">{{ formItem.label }}</div>
            <app-file-uploader
                #fileUpload
                (filesLoaded)="updateLoadingFilesStatus($event)"
                (fileIdsLoaded)="updateFileIdsLodaded($event)"
                (updatedFileCategories)="updatedFileCategories($event)"
                [categories]="categories"
                [validFiles]="['jpg', 'jpeg', 'png', 'pdf', 'PDF', 'JPG', 'PNG', 'JPEG']"
                defaultCategory="TECHNICAL_EQUIPMENT"
                [existingFiles]="existingFiles"
                (updateExistingFiles)="updateExistingFilesList($event)"
            ></app-file-uploader>
        </div>
    </ng-container>
    <!-- END FORM -->

    <!-- TYPE SELECTION -->
    <ng-template #selectTechnicalEquipmentType>
        <div class="tw-flex tw-justify-between tw-mb-8">
            <div class="tw-s-head-14-22-bold">
                {{ 'PAGES.ADD_TECHNICAL_EAQUIPMENT.LABEL_SELECT_EQUIPMENT_TYPE' | translate }}
            </div>

            <app-search [searchInputId]="'teskjb'"></app-search>
        </div>

        <div class="tw-grid tw-grid-cols-4 tw-gap-6">
            <ng-container *ngFor="let item of technicalEquipmentTypesFilteredList; let index = index">
                <app-card-selection
                    *ngIf="showAllTechnicalEquimentItems ? true : index < 8"
                    iconColor="green"
                    [iconSrc]="'40_technical-equipment-' + item.id + '.svg'"
                    [label]="item.equipmentLabel"
                    [selected]="selectedTechnicalEquiment?.id === item.id"
                    (click)="selectTechnicalEquiment(item)"
                    iconColorNotSelectedItem="green"
                    minHeight="tw-min-h-[182px]"
                ></app-card-selection>
            </ng-container>
        </div>

        <div class="tw-flex tw-justify-center tw-mt-10">
            <app-scalara-button
                variant="ghost"
                svgName="24_arrow-down.svg"
                (click)="changeTechnicalEquimentVisibility()"
                [iconStyle]="showAllTechnicalEquimentItems ? 'tw-rotate-180' : ''"
                >{{ showAllTechnicalEquimentItems ? 'Weniger' : 'Mehr' }} anzeigen</app-scalara-button
            >
        </div>
    </ng-template>
    <!-- END TYPE SELECTION -->

    <div class="tw-flex tw-justify-between tw-mt-[120px]">
        <ng-container *ngIf="showAddTechnicalEquimentDetailsForm$ | async; else abortButton">
            <app-scalara-button (click)="backPage()" variant="outline">
                {{ 'CONTROLS.BUTTONS.BACK' | translate }}
            </app-scalara-button>
        </ng-container>

        <ng-template #abortButton>
            <app-scalara-button (click)="abort()" variant="outline">
                {{ 'CONTROLS.BUTTONS.CANCEL' | translate }}
            </app-scalara-button>
        </ng-template>

        <app-scalara-button
            *ngIf="showAddTechnicalEquimentDetailsForm$ | async; else buttonToContinue"
            [disabled]="!selectedTechnicalEquiment || !areFilesFullyUploaded"
            (click)="submit()"
        >
            {{ 'CONTROLS.BUTTONS.SAVE' | translate }}
        </app-scalara-button>

        <ng-template #buttonToContinue>
            <app-scalara-button [disabled]="!selectedTechnicalEquiment" (click)="showTechnicalEquipmentForm()">
                {{ 'CONTROLS.BUTTONS.CONTINUE' | translate }}
            </app-scalara-button>
        </ng-template>
    </div>
</app-overlay-container>
