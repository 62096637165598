<app-delete-overlay
    title="PAGES.DELETE_TECHNICAL_EQUIPMENT.TITLE"
    headline="PAGES.DELETE_TECHNICAL_EQUIPMENT.HEADLINE"
    subtitle="PAGES.DELETE_TECHNICAL_EQUIPMENT.SUBTITLE"
    (closeOverlay)="abort()"
    (submitOverlay)="submit()"
>
    <div class="tw-bg-scalaraGray-06 tw-px-4 tw-py-3 tw-s-body-14-22-regular">
        {{ technicalEquipmentToDelete?.technicalEquipmentLabel }}
    </div>
</app-delete-overlay>
