<div class="file-selector">
    <div *ngIf="!disabled" class="mb-07" (click)="changeSelectDisplay()">
        <div class="d-flex file-selector__header align-items-center py-05">
            <app-icon-bubble [size]="40" [color]="'green'" [image]="'24_list.svg'"></app-icon-bubble>
            <div class="s-head-14-22-bold mt-02 ml-05">{{ title | translate }}</div>
            <svg-icon
                [ngClass]="{ 'rotate-180': !isCollapsed }"
                class="ml-auto file-selector__collapse-icon mr-04"
                src="/assets/icons/24_arrow-down.svg"
            ></svg-icon>
        </div>
    </div>

    <app-tooltip *ngIf="disabled" position="top">
        <div hoverable-item class="mb-07 file-selector file-selector--disabled">
            <div class="d-flex file-selector__header align-items-center py-05 s-gray-05">
                <app-icon-bubble [size]="40" [color]="'gray'" [image]="'24_list.svg'"></app-icon-bubble>
                <div class="s-head-14-22-bold mt-02 ml-05 s-gray-04">{{ title | translate }}</div>
                <svg-icon
                    class="ml-auto file-selector__collapse-icon mr-04"
                    src="/assets/icons/24_arrow-down.svg"
                ></svg-icon>
            </div>
        </div>
        <div content class="tw-px-10 tw-py-8 tw-s-head-14-22-regular tw-w-[575px]">
            {{ disabledText | translate }}
        </div>
    </app-tooltip>

    <div [hidden]="!isCollapsed">
        <div class="mb-04" *ngFor="let row of selectedRows">
            <app-file [file]="row" [fileProgress]="101" (cancel)="removeSelectedFile($event)"></app-file>
        </div>
    </div>

    <div
        [ngClass]="{ 'd-none': isCollapsed, 'd-flex': !isCollapsed }"
        class="w-100 align-items-end justify-content-end"
    >
        <div class="mr-2r">
            <ibm-checkbox (change)="filterBookedReceiptsCheck()" [checked]="!isFilteringBookingReceipts">
                {{ 'COMPONENTS.FILE_SELECTOR.CHECKBOX_LINKED' | translate }}
            </ibm-checkbox>
        </div>
        <div>
            <ibm-checkbox (change)="receiptsAllAccountsCheck()" [checked]="isReceiptsAllAccountsShowing">
                {{ 'COMPONENTS.FILE_SELECTOR.CHECKBOX_ACCOUNTS' | translate }}
            </ibm-checkbox>
        </div>
    </div>
    <div [hidden]="isCollapsed">
        <div ibmRow class="mb-1r">
            <div ibmCol class="d-flex justify-content-end">
                <app-search [searchInputId]="'fileSelectorTable'"></app-search>
            </div>
        </div>
        <app-table-multi-selector
            [header]="header"
            [data]="data"
            [emptyText]="emptyText"
            [emptyTextLink]="emptyTextLink"
            [emptyTextLinkLabel]="emptyTextLinkLabel"
            [itemsPerPage]="itemsPerPage"
            [tableSearchId]="'fileSelectorTable'"
            (rowSelected)="onRowClick($event)"
            (handleFile)="handleFiles($event)"
            #TableMultiSelectorComponent
        >
        </app-table-multi-selector>
    </div>
</div>

<ng-container *ngIf="fileToView && showModal">
    <app-file-viewer [fileToView]="fileToView" (closeViewer)="handleModal()"></app-file-viewer>
</ng-container>
