export const environment = {
production: false,
cognitoUserPoolId: 'PLACEHOLDER_POOLID',
cognitoAppClientId: 'PLACEHOLDER_CLIENTID',
apiUrl: 'https://base.PLACEHOLDER_API',
accountingUrl: 'https://accounting.PLACEHOLDER_API',
fileStorageUrl: 'https://filestorage.PLACEHOLDER_API',
maxFileSizeInMB: 50,
intercomAppId: 'PLACEHOLDER_INTERCOM',
demoEnvironment: false,
googleApiKey: 'AIzaSyDyIfDxehvZEwohpqZLYwlYfBD0AJy_IX8',
userflowToken: 'PLACEHOLDER_USERFLOW',
appTitleTag: 'PLACEHOLDER_APPTITLE',
appPostBoxUrl: 'PLACEHOLDER_POSTBOXURL',
localEnvironment: false,

 };
