<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<div ibmGrid class="bx--grid--full-width">
    <div ibmRow class="mb-10">
        <div ibmCol></div>
        <div ibmCol [columnNumbers]="{ md: 8, lg: 8 }">
            <div ibmRow class="headline-wrapper">
                <div class="mr-06 headline-wrapper__icon">
                    <app-icon-bubble
                        *ngIf="isChoosingEconomicPlan"
                        [size]="72"
                        [color]="'green'"
                        [image]="'40_document_edit.svg'"
                    ></app-icon-bubble>
                    <app-icon-bubble
                        *ngIf="!isChoosingEconomicPlan"
                        [size]="72"
                        [color]="'blue'"
                        [image]="'40_information.svg'"
                    ></app-icon-bubble>
                </div>
                <div class="headline-wrapper__title">
                    <div class="s-head-28-36-bold">
                        {{ 'PAGES.ANNUAL_STATEMENT.ADD.HEADLINE' | translate }}
                    </div>
                </div>
            </div>
        </div>
        <div ibmCol></div>
    </div>

    <div ibmRow>
        <div ibmCol></div>
        <div ibmCol [columnNumbers]="{ md: 8, lg: 8 }">
            <form (submit)="onSubmit()" (keydown.enter)="$event.preventDefault()" [formGroup]="form">
                <div ibmRow *ngIf="isChoosingEconomicPlan" class="mb-5r">
                    <div ibmCol>
                        <div ibmRow>
                            <div ibmCol class="s-head-16-24-bold mb-2r">
                                {{ 'PAGES.ANNUAL_STATEMENT.ADD.CHOOSE_PLAN' | translate }}
                            </div>
                        </div>
                        <div ibmRow>
                            <div ibmCol [columnNumbers]="{ md: 8, lg: 8 }">
                                <div class="mb-4-5r">
                                    <app-combo-box
                                        [size]="'md'"
                                        id="economicPlanSelection"
                                        [appendInline]="true"
                                        label="{{ 'ENTITIES.ANNUAL_STATEMENT.LABEL_YEAR' | translate }}*"
                                        [items]="economicPlansList"
                                        formControlName="economicPlanSelection"
                                        placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT' | translate }}"
                                        invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                                        (selected)="onSelectEconomicPlanYear($event)"
                                    >
                                        <ibm-dropdown-list></ibm-dropdown-list>
                                    </app-combo-box>
                                </div>
                            </div>
                        </div>

                        <div class="mt-2r" *ngIf="economicPlanAlreadyInUse">
                            <app-notification type="warning">
                                {{ 'PAGES.ANNUAL_STATEMENT.ADD.INFO_BOX_OPTION_2' | translate }}
                            </app-notification>
                        </div>
                        <div class="mt-2r" *ngIf="!isLoading && !economicPlansList.length">
                            <app-notification type="warning">
                                {{ 'PAGES.ANNUAL_STATEMENT.ADD.INFO_BOX_EMPTY_LIST' | translate }}
                            </app-notification>
                        </div>
                    </div>
                </div>

                <div *ngIf="!isChoosingEconomicPlan" class="s-head-16-24-bold mb-2r">
                    {{ 'PAGES.ANNUAL_STATEMENT.ADD.INFO_EDIT_TITLE' | translate }}
                </div>
                <div ibmRow *ngIf="!isChoosingEconomicPlan" class="mb-1-5r s-body-14-20-regular align-items-center">
                    <div ibmCol>
                        <div>{{ 'PAGES.ANNUAL_STATEMENT.ADD.INFO_EDIT_TEXT' | translate }}</div>
                        <div>{{ 'PAGES.ANNUAL_STATEMENT.ADD.INFO_EDIT_TEXT_2' | translate }}</div>
                        <div>{{ 'PAGES.ANNUAL_STATEMENT.ADD.INFO_EDIT_TEXT_3' | translate }}</div>
                    </div>
                </div>
            </form>
            <div ibmRow></div>
            <!-- START BUTTON ROW -->
            <div ibmRow class="mt-5r mb-5r">
                <div ibmCol>
                    <button *ngIf="!isChoosingEconomicPlan" [ibmButton]="'tertiary'" type="reset" (click)="abort()">
                        {{ 'CONTROLS.BUTTONS.BACK' | translate }}
                    </button>

                    <button *ngIf="isChoosingEconomicPlan" [ibmButton]="'tertiary'" type="reset" (click)="abort()">
                        {{ 'CONTROLS.BUTTONS.CANCEL' | translate }}
                    </button>
                </div>

                <div ibmCol class="text-right">
                    <button
                        *ngIf="isChoosingEconomicPlan"
                        [ibmButton]="'primary'"
                        type="submit"
                        (click)="changeToConfirmation()"
                    >
                        {{ 'CONTROLS.BUTTONS.CONTINUE' | translate }}
                    </button>
                    <button *ngIf="!isChoosingEconomicPlan" [ibmButton]="'primary'" type="submit" (click)="onSubmit()">
                        {{ 'PAGES.ANNUAL_STATEMENT.ADD.BUTTON_CONFIRMATION' | translate }}
                    </button>
                </div>
            </div>
            <!-- END BUTTON ROW -->
        </div>
        <div ibmCol></div>
    </div>
</div>
