import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import {
    BankTransactionForListDto,
    BankTransactionsService,
    MarkBankTransactionDto,
} from 'src/app/generated-sources/accounting';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';

@Component({
    selector: 'app-banking-transactions-mark-booked-overlay',
    templateUrl: './banking-transactions-mark-booked-overlay.component.html',
    styleUrls: ['./banking-transactions-mark-booked-overlay.component.scss'],
})
export class BankingTransactionsMarkBookedOverlayComponent extends OverlayChildComponent implements OnInit {
    private unsubscribe$ = new Subject<void>();
    public bankTransaction?: BankTransactionForListDto;
    public isFullyBooked = false;

    public constructor(
        private toastService: ToastService,
        private bankTransactionsService: BankTransactionsService,
        private translateService: TranslateService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.bankTransaction = this.config?.data.bankTransaction as BankTransactionForListDto;
        this.isFullyBooked = this.bankTransaction.isFullyBooked;
    }

    public submit(): void {
        if (this.bankTransaction) {
            const markBankTransaction: MarkBankTransactionDto = {
                isFullyBooked: !this.isFullyBooked,
            };

            this.bankTransactionsService
                .markBankTransaction(this.bankTransaction.id, markBankTransaction)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe({
                    next: () => {
                        if (this.isFullyBooked) {
                            this.toastService.showSuccess(
                                this.translateService.instant(
                                    'PAGES.BANK_TRANSACTIONS.MARK_AS_BOOKED.SUCCESS_REMOVE_TOAST'
                                )
                            );
                        } else {
                            this.toastService.showSuccess(
                                this.translateService.instant(
                                    'PAGES.BANK_TRANSACTIONS.MARK_AS_BOOKED.SUCCESS_APPLY_TOAST'
                                )
                            );
                        }

                        this.saveEmitter$.next();
                    },
                    error: (error) => {
                        if (error) {
                            console.error(error.error['message']);
                            this.toastService.showError(this.translateService.instant('COMPONENTS.TOAST.TOAST_ERROR'));
                        }
                    },
                });
            this.saveEmitter$.next();
        }
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }
}
