import { formatNumber } from '@angular/common';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, combineLatest, switchMap, takeUntil, tap } from 'rxjs';
import { TooltipKey } from 'src/app/features/account-settings/services/custom-tooltip.service';
import { PersonLocalService } from 'src/app/features/property/services/person-local.service';
import { DistributionKeyDto, EconomicPlansService } from 'src/app/generated-sources/accounting';
import { Person, Property } from 'src/app/generated-sources/base';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { EurocentPipe } from 'src/app/shared/pipes/eurocent.pipe';
import { CellTemplate } from 'src/app/shared/table/enums/cell-template';
import { HeaderTemplate } from 'src/app/shared/table/enums/header-template';
import { TableItem } from 'src/app/shared/table/interfaces/table-item';
import { TableModel } from 'src/app/shared/table/interfaces/table-model';
import { WegEconomicPlanDistributionKeyAddComponent } from './weg-economic-plan-distribution-key-add/weg-economic-plan-distribution-key-add.component';

@Component({
    selector: 'app-weg-economic-plan-distribution-keys',
    templateUrl: './weg-economic-plan-distribution-keys.component.html',
    styleUrls: ['./weg-economic-plan-distribution-keys.component.scss'],
})
export class WegEconomicPlanDistributionKeysComponent implements OnInit {
    @Input() public editMode = false;

    private unsubscribe$ = new Subject<void>();

    public ledgerId = '';
    public economicPlanId = '';
    public isLoading = false;
    public isPropertyManager = false;
    public standardDistKeysDescriptions = [
        'FRACTION',
        'ENTITY_PLURAL',
        'PERSONS',
        'SQUARE_METERS',
        'HEATED_SQUARE_METERS',
    ];

    public standardDistKeys: DistributionKeyDto[] = [];
    public individualDistKeys: DistributionKeyDto[] = [];

    public standardDistKeysTableModel: TableModel = { data: [], header: [] };
    public individualDistKeysTableModel: TableModel = { data: [], header: [] };

    public showMissingDataWarning = false;

    @ViewChild('warningNotificationTemplate', { static: true })
    public warningNotificationTemplate?: TemplateRef<any>;

    public constructor(
        private translateService: TranslateService,
        private overlayService: OverlayService,
        private route: ActivatedRoute,
        private economicPlansService: EconomicPlansService,
        private personLocalService: PersonLocalService
    ) {}

    public ngOnInit(): void {
        this.initTableHeaders();

        this.isLoading = true;
        combineLatest([this.route.parent!.paramMap, this.route.paramMap])
            .pipe(
                tap((params: ParamMap[]) => {
                    this.ledgerId = String(params[0].get('id'));
                    this.economicPlanId = String(params[1].get('economicPlanId'));
                    // this.economicPlanId = 'ADD YOUR economicPlanId here from swagger' TO REMOVE;
                }),
                switchMap(() => this.personLocalService.getRolesOfProperty()),
                tap((roles: Property.PermissionRolesEnum[] | null) => {
                    this.isPropertyManager = roles
                        ? Object.values(roles).includes(Person.PermissionRolesEnum.PropertyManager)
                        : false;
                }),
                switchMap(() => this.economicPlansService.getAllDistributionKeys(this.ledgerId, this.economicPlanId)),
                tap((distributionKeys: DistributionKeyDto[]) => {
                    distributionKeys.map((distributionKey: DistributionKeyDto) => {
                        if (
                            distributionKey.distributionGroup == 'ALL_OWNERSHIPS' &&
                            distributionKey.distributionBase !== 'CONSUMPTION'
                        ) {
                            this.standardDistKeys.push(distributionKey);
                        } else {
                            this.individualDistKeys.push(distributionKey);
                        }
                    });
                    this.standardDistKeysTableModel.data = this.standardDistKeys.map(
                        (standardDistKey: DistributionKeyDto, index: number) => {
                            let allNull = true;
                            let hasNull = false;

                            standardDistKey.ownerships.map((ownership: any) => {
                                if (ownership.distributionBaseShare !== null) {
                                    allNull = false;
                                } else {
                                    hasNull = true;
                                }
                            });

                            if (allNull) {
                                return this.createRow(standardDistKey, index, true, 'missingData');
                            } else if (hasNull) {
                                return this.createRow(standardDistKey, index, true, 'incompleteData');
                            }

                            return this.createRow(standardDistKey, index, true);
                        }
                    );
                    this.individualDistKeysTableModel.data = this.individualDistKeys.map(
                        (individualDistKey: DistributionKeyDto) => this.createRow(individualDistKey)
                    );
                    this.isLoading = false;
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }

    public openAddIndividualDistributionKeyOverlay(): void {
        const data = {
            ledgerId: this.ledgerId,
            economicPlanId: this.economicPlanId,
            distributionKeys: this.standardDistKeys,
        };
        const ref = this.overlayService.open(WegEconomicPlanDistributionKeyAddComponent, {
            data,
        });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

        ref.saveEmitter$
            .pipe(
                switchMap(() => this.economicPlansService.getAllDistributionKeys(this.ledgerId, this.economicPlanId)),
                tap((distributionKeys: DistributionKeyDto[]) => {
                    this.standardDistKeys = [];
                    this.individualDistKeys = [];
                    this.standardDistKeysTableModel.data = [];
                    this.individualDistKeysTableModel.data = [];
                    distributionKeys.map((distributionKey: DistributionKeyDto) => {
                        if (
                            distributionKey.distributionGroup == 'ALL_OWNERSHIPS' &&
                            distributionKey.distributionBase !== 'CONSUMPTION'
                        ) {
                            this.standardDistKeys.push(distributionKey);
                        } else {
                            this.individualDistKeys.push(distributionKey);
                        }
                    });
                    this.standardDistKeysTableModel.data = this.standardDistKeys.map(
                        (standardDistKey: DistributionKeyDto, index: number) => this.createRow(standardDistKey, index)
                    );
                    this.individualDistKeysTableModel.data = this.individualDistKeys.map(
                        (individualDistKey: DistributionKeyDto) => this.createRow(individualDistKey)
                    );
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }

    private initTableHeaders(): void {
        this.standardDistKeysTableModel.header = [
            'ENTITIES.DISTRIBUTION_KEY.LABEL_DESCRIPTION',
            'ENTITIES.DISTRIBUTION_KEY.LABEL_DISTRIBUTION_BASE',
            'ENTITIES.DISTRIBUTION_KEY.LABEL_DISTRIBUTION_GROUP',
            '',
            {
                data: { key: 'ENTITIES.DISTRIBUTION_KEY.LABEL_TOTAL_DISTRIBUTION', params: {} },
                template: HeaderTemplate.RightAligned,
            },
        ];
    }

    private createRow(
        distKey: DistributionKeyDto,
        index?: number,
        isStandardDistKeysTableModel?: boolean,
        infoMessage?: string
    ): TableItem[] {
        const euroCent = new EurocentPipe();
        const link = `/accounting/ledger/${this.ledgerId}/economic-plans/${this.economicPlanId}/distribution-keys/${distKey.id}`;

        if (infoMessage === 'missingData') {
            this.showMissingDataWarning = true;
        }

        let labelDistributionBase = '';
        if (distKey.distributionBase == 'OWNERSHIPS') {
            labelDistributionBase = this.translateService.instant('ENTITIES.OWNERSHIP.LABEL_ENTITY_PLURAL');
        } else {
            labelDistributionBase = this.translateService.instant(
                'ENTITIES.OWNERSHIP.LABEL_' + distKey.distributionBase
            );
        }

        let labelDescription = '';
        if (index !== undefined) {
            labelDescription = this.translateService.instant(
                'ENTITIES.OWNERSHIP.LABEL_' + this.standardDistKeysDescriptions[index]
            );
        } else {
            labelDescription = distKey.description;
        }

        return [
            {
                data: {
                    label: labelDescription,
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: labelDistributionBase,
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: this.translateService.instant('ENTITIES.DISTRIBUTION_KEY.' + distKey.distributionGroup),
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: infoMessage ? infoMessage : '',
                    link,
                },
                template: infoMessage ? this.warningNotificationTemplate : CellTemplate.Default,
            },
            {
                data: {
                    label:
                        distKey.distributionBase == 'CONSUMPTION'
                            ? euroCent.transform(distKey.totalDistribution)
                            : formatNumber(distKey.totalDistribution, 'de-DE', '1.2'),
                    link,
                    rightAligned: true,
                },
                template: CellTemplate.Default,
            },
        ];
    }

    public TooltipKey = TooltipKey;
}
