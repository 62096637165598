<div class="sca-notification sca-notification--{{ type }}" role="alert">
    <div class="d-flex sca-notification__details" [ngClass]="{ 'flex-direction-column': this.direction === 'column' }">
        <svg-icon
            *ngIf="customIconSrc"
            src="/assets/icons/{{ customIconSrc }}"
            [class]="'sca-notification__icon sca-notification__icon--size-' + iconSize"
        ></svg-icon>
        <svg-icon
            *ngIf="this.type && !customIconSrc"
            src="/assets/icons/{{ this.iconSrcMap[this.type] }}"
            [class]="'sca-notification__icon sca-notification__icon--size-' + iconSize"
        ></svg-icon>
        <div class="sca-notification__text-wrapper">
            <div *ngIf="title || subtitle" class="sca-notification__input-container">
                <p class="sca-notification__title">{{ title }}</p>
                <p class="sca-notification__subtitle">{{ subtitle }}</p>
            </div>
            <ng-content></ng-content>
        </div>
    </div>
</div>
