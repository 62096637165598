<div class="tooltip-container">
    <div
        class="tooltip-content-wrapper tooltip-content-wrapper--{{ size }}"
        [ngClass]="useDefaultStyles ? 'tooltip-content-wrapper--' + position + ' tooltip-content-wrapper--border' : ''"
        *ngIf="!isDisabled"
    >
        <ng-content select="[content]"></ng-content>
    </div>
    <ng-content select="[hoverable-item]"></ng-content>
</div>
