<div class="d-flex">
    <app-sidenav
        menuTitle="COMPONENTS.SIDENAV_SETTINGS.LABEL_SETTINGS"
        [property]=""
        [menuItems]="menuItems"
        [showChildrenItems]="false"
    ></app-sidenav>
    <div class="bx--grid--full-width w-100 mb-10r">
        <router-outlet></router-outlet>
    </div>
</div>
