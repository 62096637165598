import { Component, OnDestroy, OnInit } from '@angular/core';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';
import { ToastService } from 'src/app/core/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil, tap } from 'rxjs';
import { EconomicPlanDto, EconomicPlansService } from 'src/app/generated-sources/accounting';
import { isDateInputOverlappingOtherPlans } from 'src/app/core/utils/common';
import { Router } from '@angular/router';

@Component({
    selector: 'app-weg-economic-plan-activate',
    templateUrl: './weg-economic-plan-activate.component.html',
    styleUrls: ['./weg-economic-plan-activate.component.scss'],
})
export class WegEconomicPlanActivateComponent extends OverlayChildComponent implements OnDestroy, OnInit {
    private unsubscribe$ = new Subject<void>();

    public ledgerId = '';
    public economicPlanId = '';
    public economicPlan?: EconomicPlanDto;
    public economicPlans: EconomicPlanDto[] = [];

    public constructor(
        private toastService: ToastService,
        private economicPlanService: EconomicPlansService,
        private translateService: TranslateService,
        private router: Router
    ) {
        super();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public ngOnInit(): void {
        this.economicPlan = this.config?.data?.economicPlan;
        this.economicPlanId = this.config?.data?.economicPlan.id;
        this.ledgerId = this.config?.data?.ledgerId;

        this.economicPlanService
            .findAll(this.ledgerId)
            .pipe(
                tap((economicPlans: EconomicPlanDto[]) => (this.economicPlans = economicPlans)),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }

    public isOverlap(): boolean {
        if (this.economicPlan) {
            const startDate = new Date(this.economicPlan.startDate);
            const endDate = new Date(this.economicPlan.startDate);
            return isDateInputOverlappingOtherPlans(startDate, endDate, this.economicPlans);
        } else {
            return false;
        }
    }

    public onSubmit(): void {
        this.economicPlanService
            .activateEconomicPlan(this.ledgerId, this.economicPlanId)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: () => {
                    this.toastService.showSuccess(
                        this.translateService.instant('PAGES.ECONOMIC_PLAN.ACTIVATE.TOAST_SUCCESS_MESSAGE')
                    );
                    this.saveEmitter$.next();
                    this.router.navigate(['/accounting', 'ledger', this.ledgerId, 'economic-plans']);
                },
                error: (error) => {
                    if (error) {
                        this.toastService.showError(error.error['message']);
                    }
                },
            });
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }
}
