<div class="tw-px-8" *ngIf="vm">
    <div class="tw-mt-6 tw-mb-10"><ibm-breadcrumb [items]="vm!.breadcrumbs"></ibm-breadcrumb></div>
    <div class="tw-flex tw-justify-between tw-w-full">
        <div class="tw-s-head-28-36-bold tw-mb-10">
            {{ 'PAGES.LIST_GENERATOR.LIST_GENERATOR' | translate }}
        </div>
        <div>
            <app-accounting-date-picker-selector></app-accounting-date-picker-selector>
        </div>
    </div>

    <div class="tw-mb-10" *ngIf="vm.flow === listGeneratorFlowEnum['selectProperty']">
        <app-combo-box
            *ngIf="vm.comboBoxItems.length > 0"
            [items]="vm.comboBoxItems"
            id="person"
            [size]="'md'"
            [appendInline]="true"
            label="Immobilie*"
            placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT' | translate }}"
            formControlName="personListItem"
            (selected)="onPropertySelected($event)"
            invalidText="{{ 'ACCOUNTING.ERROR.INVALID_AMOUNT' | translate }}"
        >
            <ibm-dropdown-list></ibm-dropdown-list>
        </app-combo-box>
    </div>
    <div *ngIf="true" class="tw-flex tw-justify-end tw-w-full tw-mb-2">
        <app-search [searchInputId]="'generator'"></app-search>
    </div>
    <app-table
        [tableSearchId]="'generator'"
        [itemsPerPage]="1000"
        [header]="vm.tableModel.header"
        [data]="vm.tableModel.data"
        [isLoading]="false"
        [emptyText]="'PAGES.LIST_GENERATOR.EMPTY_TEXT'"
    ></app-table>
</div>
