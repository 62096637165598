import { Injectable } from '@angular/core';
import { CellData, RowData } from './simple-table-row.component';
@Injectable({
    providedIn: 'root',
})
export class SimpleTableRowTemplatesService {
    public constructor() {}

    public cells<Tkey>(
        columnKeys: Tkey[],
        values?: (string | number)[],
        rowCellClass?: string,
        firstColumnCellStyleClass?: string
    ): CellData<Tkey>[] {
        let counter = 0;
        if (!values || values.length === 0) {
            values = [''];
        }
        const result: CellData<Tkey>[] = [];
        columnKeys.forEach((columnKey) => {
            result.push({
                key: columnKey,
                type: 'value',
                value: values![counter],
                pipeToApply: typeof values![counter] === 'number' ? 'eurocent' : undefined,
                rowCellClass:
                    counter === 0 && firstColumnCellStyleClass ? firstColumnCellStyleClass : rowCellClass ?? '',
            });
            counter++;
        });
        return result;
    }
    public spacerRowHx<Tkey>(columnKeys: Tkey[], height: number): RowData<Tkey> {
        return {
            cells: [{ key: columnKeys[0], type: 'value', value: '', rowCellClass: `tw-h-${height}` }],
        };
    }

    public summLine<Tkey>(columnKeys: Tkey[], values?: (string | number)[], rowCellClass?: string): RowData<Tkey> {
        return {
            cells: this.cells(columnKeys, values, rowCellClass),
            rowClass: 'tw-border-t tw-border-solid tw-border-scalaraGray-01',
        };
    }

    public headRow<Tkey>(
        columnKeys: Tkey[],
        values?: (string | number)[],
        rowCellClass?: string,
        firstColumnCellStyleClass?: string
    ): RowData<Tkey> {
        return {
            cells: this.cells(columnKeys, values, rowCellClass, firstColumnCellStyleClass),
            rowClass:
                's-label-12-22-semibold tw-px-2 tw-h-8 tw-pb-4 tw-border-b tw-border-scalaraGray-05 tw-border-solid',
        };
    }

    public sumRow<Tkey>(columnKeys: Tkey[], values?: (string | number)[], rowCellClass?: string): RowData<Tkey> {
        return {
            cells: this.cells(columnKeys, values, rowCellClass),
            rowClass: 'tw-font-bold ',
        };
    }

    /**
     * Creates a Value Row with no Styling
     * @param columnKeys Sorted-Array of Coloumn-Keys defined in calling Component.
     * @param values Sorted Array of String or Number Values that a placed in the Coloumns.
     * @param rowCellClass String for Styling Cells.
     * @returns Complete Value Styled RowData Object.
     */
    public valueRow<Tkey>(columnKeys: Tkey[], values?: (string | number)[], rowCellClass?: string): RowData<Tkey> {
        return {
            cells: this.cells(columnKeys, values, rowCellClass),
        };
    }

    /**
     * Creates a sub sum Row with Bold, Background and H-10
     * @param columnKeys Sorted-Array of Coloumn-Keys defined in calling Component.
     * @param values Sorted Array of String or Number Values that a placed in the Coloumns.
     * @param rowCellClass String for Styling Cells.
     * @returns Complete Sub Summ Row Styled RowData Object.
     */
    public subSumRow<Tkey>(columnKeys: Tkey[], values?: (string | number)[], rowCellClass?: string): RowData<Tkey> {
        return {
            cells: this.cells(columnKeys, values, rowCellClass),
            rowClass: 'tw-h-10 tw-bg-scalaraGray-06 tw-font-bold',
        };
    }
}
