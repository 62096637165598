import { formatNumber } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject, of, switchMap, takeUntil, tap } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { getNameFromPerson, isCurrentDateWithinRange, stabilizeNumber } from 'src/app/core/utils/common';
import { TooltipKey } from 'src/app/features/account-settings/services/custom-tooltip.service';
import { CustomSubscriptionsService } from 'src/app/features/subscriptions/services/custom-subscriptions.service';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { HeaderTemplate } from 'src/app/shared/table/enums/header-template';
import {
    MVUnit,
    OccupationService,
    OwnerRelationDto,
    Ownership,
    OwnershipsService,
    Person,
    PropertiesService,
    Property,
    UnitsService,
} from '../../../../generated-sources/base';
import { CellTemplate } from '../../../../shared/table/enums/cell-template';
import { TableItem } from '../../../../shared/table/interfaces/table-item';
import { TableModel } from '../../../../shared/table/interfaces/table-model';
import { PersonLocalService } from '../../services/person-local.service';
import { PropertyCustomService } from '../../services/property-custom.service';
import { DocumentsFlow } from '../documents/add-document-overlay/add-document-overlay.component';
import { AddEditMvUnitComponent } from '../unit-ownership/add-edit-mv-unit/add-edit-mv-unit.component';
import { AddEditOwnershipFormComponent } from '../unit-ownership/add-edit-ownership-form/add-edit-ownership-form.component';

@Component({
    selector: 'app-property-ownership',
    templateUrl: './property-ownerships.component.html',
    styleUrls: ['./property-ownerships.component.scss'],
})
export class PropertyOwnershipsComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();

    public propertyId?: string;
    public tableModel: TableModel = { data: [], header: [] };
    public isLoading = false;
    public isInitialLoading$ = new BehaviorSubject<boolean>(false);
    public isPropertyManager = true;
    public propertyType?: Property.PropertyTypeEnum;
    public ownershipsRelations?: OwnerRelationDto[];
    public ownerships?: Ownership[];
    public ownershipsIdToFindOccupations = '';
    public units?: MVUnit[];
    public relations?: OwnerRelationDto[];
    public fractionAmount = 0;

    public constructor(
        private overlayService: OverlayService,
        private ownershipService: OwnershipsService,
        private route: ActivatedRoute,
        private personLocalService: PersonLocalService,
        private propertyCustomService: PropertyCustomService,
        private unitsService: UnitsService,
        private propertiesService: PropertiesService,
        private customSubscriptionsService: CustomSubscriptionsService,
        private router: Router,
        private occupationService: OccupationService,
        private translateService: TranslateService,
        private toastService: ToastService
    ) {}

    public possibleOwnershipsToCreate = 0;
    public ownershipsNotificationMessageWasClosed: boolean | null = false;

    public ngOnInit(): void {
        this.loadOwnershipViewItems();
        this.loadPossibleOwnershipsToCreate();
        this.loadNotificationStatusFromLocalStorage();
    }

    public loadNotificationStatusFromLocalStorage(): void {
        const ownershipsNotificationMessageWasClosed = JSON.parse(
            localStorage.getItem('ownershipsNotificationMessageWasClosed') ?? 'false'
        );
        this.ownershipsNotificationMessageWasClosed = ownershipsNotificationMessageWasClosed;
    }

    public handleNotificationMessage(): void {
        this.ownershipsNotificationMessageWasClosed = true;
        localStorage.setItem('ownershipsNotificationMessageWasClosed', 'true');
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public openAddOwnershipOverlay(): void {
        const ref = this.overlayService.open(
            this.propertyType === 'MV' ? AddEditMvUnitComponent : AddEditOwnershipFormComponent,
            {
                data: { propertyId: this.propertyId },
            }
        );

        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.loadOwnershipViewItems();
            //  refresh the accountMetrics in custom subscriptions service
            this.customSubscriptionsService.triggerAccountMetricsRefresh();
        });
    }

    private compareUnits(a: MVUnit, b: MVUnit): 1 | 0 | -1 {
        if (a.name > b.name) {
            return 1;
        }
        if (a.name < b.name) {
            return -1;
        }
        return 0;
    }

    private loadOwnershipViewItems(): void {
        this.propertyCustomService
            .getProperty$()
            .pipe(
                tap((property) => {
                    this.isInitialLoading$.next(true);
                    this.isLoading = true;
                    this.propertyType = property?.propertyType;
                    this.propertyId = property?.id;
                }),
                switchMap((property) => {
                    if (this.propertyType === 'MV') {
                        return this.unitsService.findMVUnits(property?.id || '');
                    } else {
                        return this.ownershipService.findAll(property?.id || '');
                    }
                }),
                switchMap((data: Ownership[] | MVUnit[]) => {
                    let ownershipsId = [];
                    if (this.propertyType === 'MV') {
                        this.units = data as MVUnit[];
                        ownershipsId = this.units.map((item) => item.designatedOwnershipId);
                        this.ownershipsIdToFindOccupations = ownershipsId.join(',');
                        return of([]);
                    } else {
                        this.ownerships = data as Ownership[];
                        ownershipsId = this.ownerships.map((item) => item.id);
                        this.ownershipsIdToFindOccupations = ownershipsId.join(',');
                        return this.propertiesService.findOwnerRelations(this.propertyId || '');
                    }
                }),
                switchMap((relations: OwnerRelationDto[]) => {
                    this.relations = relations;
                    return this.propertyId
                        ? this.occupationService.findAllOccupationsForExactOwnershipSet(
                              this.propertyId,
                              this.ownershipsIdToFindOccupations
                          )
                        : of([]);
                }),
                tap((occupation) => {
                    if (this.propertyType !== 'MV') {
                        this.ownershipsRelations = this.relations;

                        this.tableModel.data =
                            this.ownerships?.map((ownership) => {
                                const currentTenants: Person[] = [];

                                occupation.map((item) => {
                                    const findUnit = item.unitsIncluded.find(
                                        (item) => item.ownershipId === ownership.id
                                    );
                                    if (findUnit) {
                                        const currentDate = new Date().getTime();
                                        const from = new Date(item.from).getTime();
                                        const to = item.to ? new Date(item.to).getTime() : null;

                                        if (
                                            (to && currentDate >= from && currentDate <= to) ||
                                            (!to && currentDate >= from)
                                        ) {
                                            currentTenants?.push(...item.tenants);
                                        }
                                    }
                                });
                                return this.createRow(
                                    ownership as Ownership,
                                    currentTenants.map((item) => getNameFromPerson(item)).join(', ')
                                );
                            }) || [];
                        if (this.ownerships) {
                            this.sumFraction(this.ownerships);
                        }
                    } else {
                        this.tableModel.data =
                            this.units?.sort(this.compareUnits).map((unit) => {
                                const currentTenants: Person[] = [];

                                occupation.map((item) => {
                                    const findUnit = item.unitsIncluded.find((item) => item.id === unit.id);
                                    if (findUnit) {
                                        const currentDate = new Date().getTime();
                                        const from = new Date(item.from).getTime();
                                        const to = item.to ? new Date(item.to).getTime() : null;
                                        if (
                                            (to && currentDate >= from && currentDate <= to) ||
                                            (!to && currentDate >= from)
                                        ) {
                                            currentTenants?.push(...item.tenants);
                                        }
                                    }
                                });

                                return this.createRow(
                                    unit,
                                    currentTenants.map((item) => getNameFromPerson(item)).join(', ')
                                );
                            }) || [];

                        if (this.units) {
                            this.sumFraction(this.units);
                        }
                    }
                    this.initTableHeader();
                    this.isInitialLoading$.next(false);
                    this.isLoading = false;
                }),
                switchMap(() => this.personLocalService.getRolesOfProperty()),
                tap((roles: Property.PermissionRolesEnum[] | null) => {
                    this.isPropertyManager = roles
                        ? Object.values(roles).includes(Person.PermissionRolesEnum.PropertyManager)
                        : false;
                }),
                takeUntil(this.unsubscribe$)
            )

            .subscribe({
                error: (error) => {
                    if (error) {
                        this.toastService.showError(error.error['message']);
                    }
                    this.isInitialLoading$.next(false);
                    this.isLoading = false;
                },
            });
    }

    private loadPossibleOwnershipsToCreate(): void {
        this.customSubscriptionsService
            .getPossibleOwnershipsToCreate$()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((possibleOwnershipsToCreate) => {
                this.possibleOwnershipsToCreate = possibleOwnershipsToCreate;
            });
    }

    private sumFraction(ownership: (MVUnit | Ownership)[]): void {
        this.fractionAmount = ownership.reduce((acc, obj) => {
            return stabilizeNumber(acc + (obj.fraction ?? 0));
        }, 0);
    }

    private initTableHeader(): void {
        this.tableModel.header = [
            'PROPERTY.COMMON.DESCRIPTION',
            'ENTITIES.OWNERSHIP.LABEL_BUILDING',
            'ENTITIES.OWNERSHIP.LABEL_LOCATION',
            ...(this.propertyType !== Property.PropertyTypeEnum.Mv ? ['ENTITIES.OWNERSHIP.CURRENT_OWNERS'] : []),
            'ENTITIES.OWNERSHIP.LABEL_CURRENT_RENTERS',
            ...(this.propertyType !== Property.PropertyTypeEnum.Mv
                ? [
                      {
                          data: { key: 'ENTITIES.OWNERSHIP.LABEL_FRACTION', params: {} },
                          template: HeaderTemplate.RightAligned,
                      },
                  ]
                : []),
        ];
    }

    private createRow(item: MVUnit | Ownership, currentTenants: string): TableItem[] {
        const link =
            this.propertyType === Property.PropertyTypeEnum.Mv
                ? `/properties/${this.propertyId}/unit/${item.id}`
                : `/properties/${this.propertyId}/ownerships/${item.id}`;

        const owners = this.ownershipsRelations
            ?.filter((relation) => {
                return relation.ownership.id === item.id && isCurrentDateWithinRange(relation.from, relation.to);
            })
            .map((relation) => {
                return getNameFromPerson(relation.person);
            });

        return [
            {
                data: {
                    label: item.name,
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: (item as Ownership).building ?? (item as MVUnit).buildingName,
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: item.location || '',
                    link,
                },
                template: CellTemplate.Default,
            },
            ...(this.propertyType !== Property.PropertyTypeEnum.Mv
                ? [
                      {
                          data: {
                              label: owners?.join(', ') || '–',
                              link,
                          },
                          template: CellTemplate.Default,
                      },
                  ]
                : []),
            {
                data: {
                    label: currentTenants,
                    link,
                },
                template: CellTemplate.Default,
            },
            ...(this.propertyType !== Property.PropertyTypeEnum.Mv
                ? [
                      {
                          data: {
                              label: formatNumber(item?.fraction ?? 0, 'de-DE', '0.1-5') || '',
                              link,
                              rightAligned: true,
                          },
                          template: CellTemplate.Default,
                      },
                  ]
                : []),
        ];
    }

    public goToSubscriptions(): void {
        this.router.navigate(['subscriptions']);
    }

    public get headline(): string {
        return this.propertyType === 'MV'
            ? this.translateService.instant('PROPERTY.COMMON.UNITS')
            : 'Einheiten & Eigentümer';
    }

    public get tooltipText(): { headline: string } {
        const headline =
            this.propertyType === 'MV'
                ? ''
                : this.translateService.instant('PAGES.TOOLTIPS.PROPERTY_OWNERSHIPS.HEADLINE');
        return { headline };
    }

    public tooltipInfoForTable: { tooltipKey: TooltipKey; tooltipValue: string } = {
        tooltipKey: TooltipKey.tooltipsInProperties,
        tooltipValue: this.translateService.instant('PAGES.TOOLTIPS.PROPERTY_OWNERSHIPS.DOCUMENTS_TABLE'),
    };

    public TooltipKey = TooltipKey;

    public DocumentsFlow = DocumentsFlow;
}
