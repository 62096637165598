<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<div class="d-flex">
    <app-sidenav
        [property]="propertyWithLedgerType"
        [menuItems]="menuItems"
        [additionalInfo]="ownershipNames"
        menuTitle="ACCOUNTING.OVERVIEW.HEADLINE"
    ></app-sidenav>

    <div class="bx--grid--full-width w-100 mb-10r">
        <app-property-switch
            [property]="propertyWithLedgerType"
            [table]="propertyWithLedgerTable"
            [additionalInfo]="ownershipNames"
            menuTitle="ledger"
            [ledgerId]="ledger?.id"
        ></app-property-switch>
        <div class="tw-mt-[78px] tw-mx-8">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
