import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
    passwordConfirmInvalid,
    passwordInvalid,
    passwordRequirementsErrors,
} from 'src/app/core/utils/formValidationHelpers';
import { PasswordValidators } from '../../validators/password-validators';

@Component({
    selector: 'app-password-input',
    templateUrl: './password-input.component.html',
    styleUrls: ['./password-input.component.scss'],
})
export class PasswordInputComponent implements OnInit {
    public touched = false;

    public isTooltipVisible = false;
    public isPasswordVisible = false;

    public static MIN_PASSWORD_LENGTH = 8;

    public passwordRequirementErrors: boolean[] = [true];
    @Input() public label = '';
    @Input() public form = new UntypedFormGroup({});
    @Input() public inputName: 'password' | 'confirmPassword' = 'password';

    public constructor() {}

    public ngOnInit(): void {
        if (this.inputName == 'password') {
            this.form.addControl(
                'password',
                new UntypedFormControl('', [
                    Validators.required,
                    Validators.minLength(PasswordInputComponent.MIN_PASSWORD_LENGTH),
                    PasswordValidators.requiredNumber,
                    PasswordValidators.requiredCapitalCase,
                    PasswordValidators.requiredSmallCase,
                    PasswordValidators.requiredSpecialCharacter,
                ])
            );
        } else {
            this.form.addControl('confirmPassword', new UntypedFormControl('', Validators.required));
        }
    }

    public setTooltipVisibility(visibility: boolean): void {
        this.isTooltipVisible = visibility;
    }

    public setPasswordVisibility(): void {
        this.isPasswordVisible = !this.isPasswordVisible;
    }

    public get passwordInvalid(): boolean {
        return passwordInvalid({ form: this.form });
    }

    public get passwordConfirmInvalid(): boolean {
        return passwordConfirmInvalid({ form: this.form });
    }

    public get passwordRequirementsErrors(): boolean[] {
        return passwordRequirementsErrors({ form: this.form });
    }
}
