<div
    class="tw-border-dashed tw-border-2 tw-border-scalaraGray-04 tw-mt-6 tw-p-2"
    [formGroup]="deleteRentReceivablesFormGroup"
>
    <div class="tw-flex-row tw-s-head-16-24-bold tw-mb-2">
        {{ 'ACCOUNTING.REASONABILITY_CHECK.HEADLINE_DEL_RENT_RECEIVABLES' | translate }}
    </div>
    <div class="tw-flex tw-justify-between tw-ml-2">
        <div class="tw-w-[500px]">
            <app-combo-box
                #comboBoxTypeBankAccount
                id="occupations"
                [appendInline]="true"
                label="{{ 'ENTITIES.OCCUPATION.LABEL_ENTITY' | translate }}*"
                [items]="(occupationsList$ | async) ?? []"
                formControlName="occupations"
                placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT' | translate }}"
                invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                (selected)="onSelectedOccupation($event)"
                (change)="onSelectedOccupation($event)"
                class="tw-w-[500px]"
            >
                <ibm-dropdown-list></ibm-dropdown-list>
            </app-combo-box>
        </div>
        <div>
            <ibm-date-picker
                label="Anfangsdatum"
                [value]="[deleteRangeStartDate]"
                (valueChange)="onChangeDeleteRangeStartDate($event)"
                id="deleteRangeStartDate"
                [placeholder]="'tt.mm.jjjj'"
                [size]="'md'"
                [language]="'de'"
                [dateFormat]="'d.m.Y'"
                formControlName="deleteRangeStartDate"
            >
            </ibm-date-picker>
        </div>
        <div>
            <ibm-date-picker
                label="Enddatum"
                [value]="[deleteRangeEndDate]"
                (valueChange)="onChangeDeleteRangeEndDate($event)"
                id="deleteRangeEndDate"
                [placeholder]="'tt.mm.jjjj'"
                [size]="'md'"
                [language]="'de'"
                [dateFormat]="'d.m.Y'"
                formControlName="deleteRangeEndDate"
            >
            </ibm-date-picker>
        </div>
        <div class="tw-w-[170px]">
            <label class="bx--label"></label>
            <app-scalara-button
                class="mr-05 tw-w-[170px]"
                (click)="onSubmit()"
                [fullWidth]="true"
                svgName="24_delete.svg"
                [disabled]="deleteRentReceivablesFormGroup.invalid"
                >{{ 'CONTROLS.BUTTONS.DELETE' | translate }}</app-scalara-button
            >
        </div>
    </div>
    <!--  -->
</div>
