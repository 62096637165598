import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, firstValueFrom, map, ObservedValueOf, Subject, takeUntil } from 'rxjs';
import { MediaQueriesService } from 'src/app/core/services/media-queries.service.ts.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { controlInFormGroupIsInvalid } from 'src/app/core/utils/formValidationHelpers';
import { BankAccount, PersonsService } from 'src/app/generated-sources/base';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';
import { bankAccountToContactDetailRow } from '../../utils';

@Component({
    selector: 'app-delete-bank-account-overlay',
    templateUrl: './delete-bank-account-overlay.component.html',
    styleUrls: ['./delete-bank-account-overlay.component.scss'],
})
export class DeleteBankAccountOverlayComponent extends OverlayChildComponent implements OnInit {
    public constructor(
        public formBuilder: UntypedFormBuilder,
        public personsService: PersonsService,
        public toastService: ToastService,
        public mediaQueriesService: MediaQueriesService,
        public translateService: TranslateService
    ) {
        super();
    }

    public ngOnInit(): void {
        const bankAccount = this.config?.data.bankAccount;
        const personId = this.config?.data.personId;
        if (bankAccount) {
            this.bankAccount$.next(bankAccount);
        }
        if (personId) {
            this.personId$.next(personId);
        }
        this.vm$.subscribe((vm) => {
            this.vm = vm;
        });
    }

    public unsubscribe$ = new Subject<void>();
    public personId$ = new BehaviorSubject<string | null>(null);
    public bankAccount$ = new BehaviorSubject<BankAccount | undefined>(undefined);
    public contactDetailRows$ = this.bankAccount$.pipe(
        map((bankAccount) => bankAccountToContactDetailRow(bankAccount, this.translateService))
    );
    public isLoading$ = new BehaviorSubject(false);
    public currentLayoutMode$ = this.mediaQueriesService.getCurrentLayoutMode();
    public vm$ = combineLatest([
        this.personId$,
        this.bankAccount$,
        this.contactDetailRows$,
        this.isLoading$,
        this.currentLayoutMode$,
    ]).pipe(
        map(([personId, bankAccount, contactDetailRows, isLoading, currentLayoutMode]) => ({
            personId,
            bankAccount,
            contactDetailRows,
            isLoading,
            currentLayoutMode,
        })),
        takeUntil(this.unsubscribe$)
    );

    public vm: ObservedValueOf<typeof this.vm$> | null = null;

    public async submit(): Promise<void> {
        const personId = this.vm?.personId;
        const bankAccount = this.vm?.bankAccount;
        if (!personId || !bankAccount) {
            this.toastService.showError('Es ist ein Fehler aufgetreten.');
            console.warn('no personId or bankAccount');
            return;
        }
        try {
            this.isLoading$.next(true);
            await firstValueFrom(this.personsService._delete(personId, bankAccount.iban));
            this.toastService.showSuccess('Zahlungsverbindung erfolgreich entfernt.');
            this.saveEmitter$.next();
        } catch (e) {
            this.toastService.showError('Es ist ein Fehler aufgetreten.');
        } finally {
            this.isLoading$.next(false);
        }
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public onDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public controlInFormGroupIsInvalid = controlInFormGroupIsInvalid;
}
