import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { TooltipKey } from 'src/app/features/account-settings/services/custom-tooltip.service';
import { AddServiceProviderOverlayComponent } from 'src/app/features/relations/add-service-provider-overlay/add-service-provider-overlay.component';
import { RelationUpdateDto } from 'src/app/features/relations/interfaces';
import { ServiceProviderComponent } from 'src/app/features/relations/service-provider/service-provider.component';
import { Person, Property } from 'src/app/generated-sources/base';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { OverlayRef } from 'src/app/shared/overlay/utils/overlay-ref';
import { TableModel } from 'src/app/shared/table/interfaces/table-model';
import { PersonLocalService } from '../../services/person-local.service';

@Component({
    selector: 'app-service-providers-overview',
    templateUrl: './service-providers-overview.component.html',
    styleUrls: ['./service-providers-overview.component.scss'],
})
export class ServiceProvidersOverviewComponent implements OnInit {
    public isLoading = false;
    public isManagerOfThisProperty = false;

    private unsubscribe$ = new Subject<void>();
    private ownershipId = '';
    private propertyId = '';
    public originalRelation: RelationUpdateDto = {
        personId: '',
        propertyId: '',
        ownershipId: '',
        relationId: '',
        parent: '',
        startDate: new Date(),
    };

    public tableModel: TableModel = { data: [], header: [] };
    @ViewChild(ServiceProviderComponent) public serviceProviderComponent?: ServiceProviderComponent;

    public constructor(
        private route: ActivatedRoute,
        private overlayService: OverlayService,
        private personLocalService: PersonLocalService
    ) {}

    public ngOnInit(): void {
        this.propertyId = String(this.route.parent?.snapshot.paramMap.get('id'));
        this.ownershipId = String(this.route.snapshot.paramMap.get('ownershipId'));

        this.personLocalService
            .getRolesOfProperty()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((roles: Property.PermissionRolesEnum[] | null) => {
                this.isManagerOfThisProperty = roles
                    ? Object.values(roles).includes(Person.PermissionRolesEnum.PropertyManager)
                    : false;
            });
    }

    public openAddOwnerOverlay(): void {
        const data = {
            componentParent: 'app-service-providers-overview',
            extraData: { propertyId: this.propertyId, ownershipId: this.ownershipId },
        };

        const ref = this.overlayService.open(AddServiceProviderOverlayComponent, { data });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

        this.saveEmittersUpdate(ref);
    }

    private saveEmittersUpdate(ref: OverlayRef): void {
        ref.saveEmitter$.subscribe(() => {
            this.serviceProviderComponent?.loadServiceProviders();
        });
        ref.cancelEmitter$.subscribe(() => {
            ref.close();
        });
    }

    public TooltipKey = TooltipKey;
}
