import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-person-data-label',
    templateUrl: './person-data-label.component.html',
    styleUrls: ['./person-data-label.component.scss']
})
export class PersonDataLabelComponent {

    @Input() public label?: string;
}
