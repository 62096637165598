/**
 * SCALARA Accounting Module
 * The SCALARA Accounting API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BookingRecommendationVerdictDto { 
    id: string;
    verdict: BookingRecommendationVerdictDto.VerdictEnum;
}
export namespace BookingRecommendationVerdictDto {
    export type VerdictEnum = 'DECLINED' | 'ACCEPTED';
    export const VerdictEnum = {
        Declined: 'DECLINED' as VerdictEnum,
        Accepted: 'ACCEPTED' as VerdictEnum
    };
}


