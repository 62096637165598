import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChartConfiguration, ChartType } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { de } from 'date-fns/locale';
import { Subject, of, switchMap, takeUntil } from 'rxjs';
import { DashboardDueItems, DashboardsService, DueItem, TimeSplitDueItem } from 'src/app/generated-sources/accounting';
import { Property } from 'src/app/generated-sources/base';
import { LedgerCustomService } from '../../services/ledger-custom.service';

interface DashboardDueItemsDto {
    title: string;
    dueIitem: DueItem[];
    total: number;
    isOpen: boolean;
    openItemSearchId: 'RECEIVABLES' | 'LIABILITIES';
}

@Component({
    selector: 'app-dashboard-due-open-items',
    templateUrl: './dashboard-due-open-items.component.html',
    styleUrls: ['./dashboard-due-open-items.component.scss'],
})
export class DashboardDueOpenItemsComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();
    public dashboardDueItems: DashboardDueItemsDto[] = [];
    public ledgerType?: Property.PropertyTypeEnum;
    public ledgerId = '';

    public barChartData?: ChartConfiguration['data'];
    public barChartType: ChartType = 'bar';
    public barChartOptions: ChartConfiguration['options'] = {
        responsive: true,
        scales: {
            x: {
                adapters: {
                    date: {
                        locale: de,
                    },
                },
                grid: {
                    tickLength: 5,
                    drawOnChartArea: false,
                },
                ticks: {
                    color: '#A9ACAD',
                    font: {
                        size: 12,
                        family: 'Open Sans',
                        style: 'normal',
                        weight: 'normal',
                    },
                },
            },
            y: {
                display: false,
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                cornerRadius: 0,
                backgroundColor: '#FFFFFF',
                borderColor: '#E5E7E9',
                borderWidth: 1,
                displayColors: false,
                padding: 12,
                bodyFont: {
                    size: 12,
                    family: 'Open Sans',
                    style: 'normal',
                    weight: 'normal',
                },
                bodyColor: '#77797A',
                titleFont: {
                    size: 12,
                    family: 'Open Sans',
                    style: 'normal',
                    weight: 'normal',
                },

                titleColor: '#77797A',
                callbacks: {
                    label: function (context) {
                        return context.dataset?.label || '';
                    },
                    afterBody: function (context) {
                        return `${new Intl.NumberFormat('de-DE', {
                            style: 'currency',
                            currency: 'EUR',
                        }).format(context[0]?.parsed?.y || 0)}`;
                    },
                },
            },
        },
    };

    public constructor(
        private dashboardsService: DashboardsService,
        private ledgerCustomService: LedgerCustomService,
        private router: Router
    ) {}

    public ngOnInit(): void {
        this.ledgerCustomService
            .getLedger$()
            .pipe(
                switchMap((ledger) => {
                    if (!ledger) {
                        return of([]);
                    }
                    this.ledgerType = ledger.type;
                    this.ledgerId = ledger.id;
                    return this.dashboardsService.getDashboardDueItems(ledger.id);
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: (dashboardAdvancements) => {
                    if (!Array.isArray(dashboardAdvancements)) {
                        this.barChartData = this.createChart(
                            dashboardAdvancements.timeSplitDueReceivableAndLiabilities
                        );
                        this.dashboardDueItems = this.createDueOpenItemsExpandableTile(dashboardAdvancements);
                    }
                },
            });
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public formartDate(date: string): string {
        return new Date(date).toLocaleDateString('de-DE', {
            month: 'short',
            year: '2-digit',
        });
    }

    public createChart(
        timeSplitDueReceivableAndLiabilities: TimeSplitDueItem[]
    ): ChartConfiguration['data'] | undefined {
        if (timeSplitDueReceivableAndLiabilities.length === 0) {
            return undefined;
        }

        const receivables: number[] = [];
        const liabilities: number[] = [];
        const labels: string[] = [];

        Array.from({ length: 13 }, (_, index) => {
            if (index === 0) {
                const oldData = timeSplitDueReceivableAndLiabilities.find((item) => item.year === '_');

                const totalReceivables = oldData?.totalReceivables ? oldData?.totalReceivables / 100 : 0;
                const totalLiabilities = oldData?.totalLiabilities ? oldData?.totalLiabilities / 100 : 0;

                receivables[0] = totalReceivables ?? 0;
                liabilities[0] = totalLiabilities ?? 0;
                labels[0] = 'Älter';
            } else {
                const offset = 12 - index;
                const currentDate = new Date(new Date().setMonth(new Date().getMonth() - offset));
                const currentYear = currentDate.getFullYear();
                const currentMonth = currentDate.getMonth() + 1;

                const findItem = timeSplitDueReceivableAndLiabilities.find((item) => {
                    if (item.year === '_' || item.month === '_') {
                        return false;
                    }
                    const month = Number(item.month);
                    const year = Number(item.year);

                    return currentYear === year && currentMonth === month;
                });

                const date = this.formartDate(
                    findItem?.year ? `${findItem.year}-${findItem.month}-01` : `${currentYear}-${currentMonth}-01`
                );

                const totalReceivables = findItem?.totalReceivables ? findItem?.totalReceivables / 100 : 0;
                const totalLiabilities = findItem?.totalLiabilities ? findItem?.totalLiabilities / 100 : 0;

                receivables.push(totalReceivables);
                liabilities.push(totalLiabilities);
                labels.push(date);
            }
        });

        return {
            labels: labels,
            datasets: [
                {
                    data: receivables,
                    label: 'Forderungen',
                    backgroundColor: receivables.map((item) => (item === 0 ? '#E5E7E9' : '#8967CE')),
                    hoverBackgroundColor: receivables.map((item) => (item === 0 ? '#E5E7E9' : '#5D22D3')),
                    borderRadius: 10,
                    maxBarThickness: 8,
                    minBarLength: 6,
                    borderWidth: 0,
                },
                {
                    data: liabilities,
                    label: 'Verbindlichkeiten',
                    backgroundColor: liabilities.map((item) => (item === 0 ? '#E5E7E9' : '#FFB361')),
                    hoverBackgroundColor: liabilities.map((item) => (item === 0 ? '#E5E7E9' : '#FF911B')),
                    borderRadius: 10,
                    maxBarThickness: 8,
                    minBarLength: 6,
                    borderWidth: 0,
                },
            ],
        };
    }

    public createDueOpenItemsExpandableTile(dashboardAdvancements: DashboardDueItems): DashboardDueItemsDto[] {
        return [
            {
                title: this.ledgerType === Property.PropertyTypeEnum.Weg ? 'Vorschüsse' : 'Mietforderungen',
                dueIitem: dashboardAdvancements.dueAdvancementOrRentReceivableItems,
                total: dashboardAdvancements.totalDueAdvancementOrRentReceivable,
                isOpen: false,
                openItemSearchId: 'RECEIVABLES',
            },
            {
                title:
                    this.ledgerType === Property.PropertyTypeEnum.Weg
                        ? 'Nachzahlungen aus Jahresabrechnungen'
                        : 'Nachzahlungen aus Abrechnungen',
                dueIitem: dashboardAdvancements.paymentFromBillingItems,
                total: dashboardAdvancements.totalPaymentFromBilling,
                isOpen: false,
                openItemSearchId: 'RECEIVABLES',
            },
            {
                title:
                    this.ledgerType === Property.PropertyTypeEnum.Weg
                        ? 'Rückzahlungen aus Jahresabrechnungen'
                        : 'Erstattungen aus Abrechnungen',
                dueIitem: dashboardAdvancements.RepaymentsFromBillingItems,
                total: dashboardAdvancements.totalRepaymentsFromBilling,
                isOpen: false,
                openItemSearchId: 'LIABILITIES',
            },

            {
                title: 'Sonstige Verbindlichkeiten',
                dueIitem: dashboardAdvancements.dueAdvancementOrRentLiabilityItems,
                total: dashboardAdvancements.totalDueAdvancementOrRentLiability,
                isOpen: false,
                openItemSearchId: 'LIABILITIES',
            },
        ];
    }

    public setAdvancementsVisibility(index: number): void {
        this.dashboardDueItems[index] = {
            ...this.dashboardDueItems[index],
            isOpen: !this.dashboardDueItems[index]?.isOpen,
        };
    }

    public navigateAdvancementsIndex(openItem: DashboardDueItemsDto, dueItem: DueItem): void {
        const searchId = 'tableSearch_' + openItem.openItemSearchId;

        this.router.navigate(['/accounting', 'ledger', this.ledgerId, 'advancements'], {
            state: { openLiabilitiesTabOnInit: openItem.openItemSearchId === 'LIABILITIES' },
            queryParams: { [searchId]: dueItem.unitName },
        });
    }
}
