<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<div
    *ngIf="operationExpenseStatement?.status === 'DRAFT'"
    class="d-flex py-3-5r px-2-5r info-incomplete-data-box justify-space-between"
>
    <div class="d-flex mr-4r">
        <div class="document-icon mr-1-5r">
            <svg-icon src="/assets/icons/40_document_edit.svg"></svg-icon>
        </div>
        <div class="mt-0-5r">
            <div class="s-blue-01 s-head-18-26-bold">
                {{
                    'PAGES.OPERATIONS_EXPENSE_STATEMENTS.DETAILS_OPERATIONS_EXPENSE_STATEMENTS.INFO_BOX_TITLE'
                        | translate
                }}
            </div>
            <div class="mt-1-5r s-body-14-22-regular">
                {{
                    'PAGES.OPERATIONS_EXPENSE_STATEMENTS.DETAILS_OPERATIONS_EXPENSE_STATEMENTS.INFO_BOX_DESCRIPTION'
                        | translate
                }}
            </div>
        </div>
    </div>
    <app-scalara-button (click)="openActiveOesOverlay()" [variant]="'blue'">
        {{
            'PAGES.OPERATIONS_EXPENSE_STATEMENTS.DETAILS_OPERATIONS_EXPENSE_STATEMENTS.ACTIVATE_BUTTON' | translate
        }}</app-scalara-button
    >
</div>

<div class="mt-1-25r mb-3r d-flex justify-space-between">
    <ibm-breadcrumb [items]="breadcrumbs" [noTrailingSlash]="true"> </ibm-breadcrumb>
    <div *ngIf="operationExpenseStatement?.status === 'DRAFT'" class="d-flex justify-content-end">
        <app-scalara-button variant="ghost" (click)="openDeleteOverlay()" svgName="24_delete.svg">{{
            'PAGES.OPERATIONS_EXPENSE_STATEMENTS.DETAILS_OPERATIONS_EXPENSE_STATEMENTS.DELETE_BUTTON' | translate
        }}</app-scalara-button>
        <app-scalara-button variant="default" (click)="openEditOverlay()" svgName="24_edit.svg" class="ml-1r">
            {{ 'PAGES.OPERATIONS_EXPENSE_STATEMENTS.DETAILS_OPERATIONS_EXPENSE_STATEMENTS.EDIT_BUTTON' | translate }}
        </app-scalara-button>
    </div>
</div>

<div class="mb-4r">
    <div class="s-label-12-22-semibold s-gray-03 mb-0-5r">
        {{ 'PAGES.OPERATIONS_EXPENSE_STATEMENTS.DETAILS_OPERATIONS_EXPENSE_STATEMENTS.LABEL_PERIOD' | translate }}
    </div>
    <div class="s-head-28-36-bold">
        {{ operationExpenseStatement?.startDate || '' | dateFormat }} –
        {{ operationExpenseStatement?.endDate || '' | dateFormat }}
    </div>
</div>

<ibm-tabs [followFocus]="true" [cacheActive]="true">
    <ibm-tab heading="Abrechnung">
        <app-oes-statements-tab
            [oesDate]="oesDate"
            [oesActivationDate]="oesActivationDate"
            [oesLengthInDays]="oesLengthInDays"
            [ledgerId]="ledgerId"
            [oesId]="oesId"
            [oesStatus]="oesStatus"
            [triggerRefresh]="triggerTabRefreshValue"
        ></app-oes-statements-tab>
    </ibm-tab>
    <ibm-tab (selected)="onTabSelected('CONSUMPTION')" heading="Verbräuche">
        <app-operations-expense-statements-consumption-tab
            *ngIf="tabsToShow.includes('CONSUMPTION')"
            [ledgerId]="ledgerId"
            [oesId]="oesId"
            [oesDate]="oesDate"
        ></app-operations-expense-statements-consumption-tab>
    </ibm-tab>
    <ibm-tab (selected)="onTabSelected('DISTRIBUTION_KEY')" heading="Verteilerschlüssel">
        <app-operations-expense-statements-distribution-key-tab
            *ngIf="tabsToShow.includes('DISTRIBUTION_KEY')"
            [ledgerId]="ledgerId"
            [oesId]="oesId"
            [oesDate]="oesDate"
        ></app-operations-expense-statements-distribution-key-tab>
    </ibm-tab>
</ibm-tabs>
