import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject, finalize, takeUntil } from 'rxjs';
import { PersonType } from 'src/app/core/models/person-type.enum';
import { ToastService } from 'src/app/core/services/toast.service';
import { formatDateYYYYMMDDWithoutHours } from 'src/app/core/utils/common';
import { PersonsService } from 'src/app/generated-sources/base/api/persons.service';
import { Person } from 'src/app/generated-sources/base/model/person';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';
import { PersonInfosFormComponent } from 'src/app/shared/person-infos-form/person-infos-form.component';

@Component({
    selector: 'app-edit-profile-form',
    templateUrl: './edit-profile-form.component.html',
    styleUrls: ['./edit-profile-form.component.scss'],
})
export class EditProfileFormComponent extends OverlayChildComponent implements OnInit, OnDestroy, AfterViewInit {
    private unsubscribe$ = new Subject<void>();
    public person?: Person;

    public form: UntypedFormGroup = new UntypedFormGroup({});
    public personTypeDeclared = PersonType;
    public isLoading = true;
    public personId = '';

    @ViewChild(PersonInfosFormComponent)
    public personInfosFormComponent?: PersonInfosFormComponent;

    public constructor(
        private personsService: PersonsService,
        private toastService: ToastService,
        private translateService: TranslateService
    ) {
        super();
    }

    public ngOnInit(): void {
        if (!this.config?.data?.extraData?.personId) {
            console.warn('PersonID is not provided');
        } else {
            this.personId = this.config?.data?.extraData?.personId;
            this.loadPerson();
        }
    }
    public ngAfterViewInit(): void {
        if (!this.personInfosFormComponent) {
            console.warn('PersonInfosFormComponent is not initialised');
            return;
        }
        this.form.addControl('personInfo', this.personInfosFormComponent.personForm);
        this.personInfosFormComponent.personForm.setParent(this.form);
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    private loadPerson(): void {
        this.personsService.findOne(this.personId).subscribe((person) => {
            this.person = person;

            this.isLoading = false;
        });
    }

    public onSubmit(): void {
        if (!this.personInfosFormComponent?.personForm.valid) {
            return;
        }
        this.isLoading = true;
        const userForm = this.personInfosFormComponent?.personForm.value;

        userForm.birthday =
            userForm.type === 'NAT_PERSON' && userForm.birthday && userForm.birthday.length > 0 && userForm.birthday[0]
                ? formatDateYYYYMMDDWithoutHours(userForm.birthday[0])
                : null;

        userForm.phoneNumbers =
            userForm.phoneNumbers && userForm.phoneNumbers.length > 0 && userForm.phoneNumbers[0]?.phoneNumber
                ? userForm.phoneNumbers.map((item: any) => {
                      return {
                          phoneNumber: item.phoneNumber,
                          type: item.phoneNumberType?.value ?? item.phoneNumberType,
                      };
                  })
                : [];

        this.personsService
            .update(this.person!.id, userForm)
            .pipe(
                finalize(() => (this.isLoading = false)),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: () => {
                    const message = this.translateService.instant(
                        'USER_MANAGEMENT.EDIT_PROFILE_FORM.TOAST_SUCCESS_MESSAGE'
                    );
                    this.toastService.showSuccess(message);
                    this.saveEmitter$.next();
                },
                error: (error: Error) => this.toastService.showError(error.message),
            });
    }

    public onCancel(): void {
        this.cancelEmitter$.next();
    }

    public isInvalid(): boolean {
        return !this.personInfosFormComponent?.personForm.valid;
    }
}
