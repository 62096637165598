import {AuthService} from '../services/auth.service';
import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {mergeMap, Observable} from "rxjs";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    public constructor(
        private authService: AuthService
    ) { }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.authService.getToken()
            .pipe(
                mergeMap((token) => {
                    if (!token) {
                        return next.handle(request);
                    }

                    const bearerRequest = request.clone({
                        headers: request.headers.append('Authorization', 'Bearer ' + token)
                    });
                    return next.handle(bearerRequest);
                })
            )
    }
}
