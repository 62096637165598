import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
    //size relative to the size of the hoverable item.
    @Input() public size?: string;
    @Input() public position?: string;
    //  disables tooltip content
    //  is helpful when you want to conditionally disable tooltip showing
    @Input() public isDisabled?: boolean;
    @Input() public useDefaultStyles = true;
}
