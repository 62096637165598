import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { finalize, Subject, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { RelationsService } from 'src/app/generated-sources/base';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';
import { RelationUpdateDto } from '../interfaces';

@Component({
    selector: 'app-delete-relation-overlay',
    templateUrl: './delete-relation-overlay.component.html',
    styleUrls: ['./delete-relation-overlay.component.scss'],
})
export class DeleteRelationOverlayComponent extends OverlayChildComponent implements OnInit {
    public isLoading = false;
    private unsubscribe$ = new Subject<void>();
    public originalRelation: RelationUpdateDto = {
        personId: '',
        propertyId: '',
        ownershipId: '',
        relationId: '',
        parent: '',
        startDate: new Date(),
        endDate: new Date(),
    };
    public relationType = '';
    public successMessage = '';

    public constructor(
        private toastService: ToastService,
        private translateService: TranslateService,
        private relationsService: RelationsService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.originalRelation = this.config?.data.relation;

        if (this.originalRelation.parent === 'advisers') {
            this.relationType = this.translateService.instant('ENTITIES.RELATION.LABEL_RELATION_TYPE_ADVISER');
            this.successMessage = 'Beirat wurde erfolgreich entfernt';
        } else if (this.originalRelation.parent === 'ownership') {
            this.relationType = this.translateService.instant('ENTITIES.RELATION.LABEL_RELATION_TYPE_OWNER');
            this.successMessage = 'Eigentümer wurde erfolgreich entfernt';
        } else if (this.originalRelation?.parent == 'serviceProvider') {
            this.relationType = this.translateService.instant('ENTITIES.RELATION.LABEL_RELATION_TYPE_SERVICE_PROVIDER');
            this.successMessage = 'Dienstleister wurde erfolgreich entfernt';
        }
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public submit(): void {
        this.isLoading = true;

        this.relationsService
            .deleteRelation(this.originalRelation.relationId)
            .pipe(
                takeUntil(this.unsubscribe$),
                finalize(() => (this.isLoading = false))
            )
            .subscribe({
                next: () => {
                    this.toastService.showSuccess(this.successMessage);
                    this.saveEmitter$.next();
                },
                error: (error: any) => {
                    if (error) {
                        this.toastService.showError(error.error['message']);
                    }
                },
            });
    }
}
