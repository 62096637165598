import { AfterContentInit, Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[appAutoFocusDirective]',
})
export class AutoFocusDirective implements AfterContentInit {
    public constructor(private elementRef: ElementRef) {}

    public ngAfterContentInit(): void {
        setTimeout(() => {
            this.elementRef.nativeElement.focus();
        }, 0);
    }
}
