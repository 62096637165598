import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import {
    ButtonModule,
    DialogModule,
    DropdownModule,
    GridModule,
    InputModule,
    LoadingModule,
    RadioModule,
    SelectModule,
    TabsModule,
} from 'carbon-components-angular';
import { SharedModule } from 'src/app/shared/shared.module';
import { AccountsViewComponent } from './components/accounts-view/accounts-view.component';
import { EditProfileFormComponent } from './components/edit-profile-form/edit-profile-form.component';
import { PersonDataLabelComponent } from './components/person-data-label/person-data-label.component';
import { SyncAccountsFormComponent } from './components/sync-accounts-form/sync-accounts-form.component';

@NgModule({
    declarations: [
        EditProfileFormComponent,
        PersonDataLabelComponent,
        AccountsViewComponent,
        SyncAccountsFormComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        InputModule,
        SelectModule,
        GridModule,
        RadioModule,
        TranslateModule,
        ButtonModule,
        LoadingModule,
        DialogModule,
        SharedModule,
        DropdownModule,
        TabsModule,

        AngularSvgIconModule,
    ],
    exports: [PersonDataLabelComponent],
})
export class UserManagementModule {}
