<div class="p-05"></div>

<div ibmGrid class="bx--grid--full-width">
    <div ibmRow [ngClass]="'mb-08'">
        <div ibmCol [columnNumbers]="{ md: 5, lg: 12, xl: 12 }">
            <div class="s-head-28-36-bold">{{ 'PAGES.CONTACT.DASHBOARD.LABEL_PAGE_HEADLINE' | translate }}</div>
        </div>
    </div>

    <div ibmRow>
        <div ibmCol class="mb-1r d-flex justify-content-end">
            <app-search [searchInputId]="'contactsDashboard'"></app-search>
        </div>
    </div>
    <div ibmRow>
        <div ibmCol>
            <!-- vm is not there until the data is loaded, we need to pass [] by default -->
            <app-table
                [emptyText]="'PAGES.CONTACT.DASHBOARD.LABEL_EMPTY_TABLE'"
                [header]="tableModelHeader"
                [data]="vm?.tableModelData ?? []"
                (customButton)="inviteContact($event)"
                [tableSearchId]="'contactsDashboard'"
                [isLoading]="isLoading"
            >
            </app-table>
        </div>
    </div>
</div>

<ng-template #label let-data="data">
    <a [routerLink]="data.link" class="data-table-link s-body-14-22-regular"
        >{{ data.label }}
        <app-tag [color]="'green'"> {{ 'ENTITIES.RELATION.LABEL_RELATION_TYPE_ADVISER' | translate }} </app-tag></a
    >
</ng-template>
