<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<div ibmGrid class="bx--grid--full-width">
    <div ibmRow class="mb-12">
        <div ibmCol></div>
        <div ibmCol [columnNumbers]="{ md: 8, lg: 8 }">
            <div ibmRow *ngIf="!isEditing">
                <div class="s-head-28-36-bold mb-09">
                    {{
                        'ACCOUNTING.DUPLICATE_ACCOUNT_FORM.DUPLICATE_ACCOUNT_TITLE'
                            | translate : { accountName: account?.name }
                    }}
                </div>
            </div>
            <div ibmRow *ngIf="isEditing">
                <div class="s-head-28-36-bold mb-09">
                    {{ 'ACCOUNTING.DUPLICATE_ACCOUNT_FORM.UPDATE_ACCOUNT' | translate }}
                </div>
            </div>

            <div class="mb-09" *ngIf="!isEditing">
                <div ibmRow class="mb-04">
                    <div class="s-gray-03 s-label-12-22-semibold mr-04 width-title">
                        {{ 'ACCOUNTING.ACCOUNT_CHARTS.TYPE' | translate }}
                    </div>
                    <div class="s-body-14-22-regular">{{ type }}</div>
                </div>

                <div ibmRow>
                    <div class="s-gray-03 s-label-12-22-semibold mr-04 width-title">
                        {{ 'ACCOUNTING.ACCOUNT_CHARTS.GROUP' | translate }}
                    </div>
                    <div class="s-body-14-22-regular">{{ account?.accountGroup?.name }}</div>
                </div>
            </div>

            <div class="mb-09" *ngIf="isEditing">
                <div ibmRow class="mb-04">
                    <div class="s-head-14-22-bold mr-07">{{ account?.name }}</div>
                    <div class="s-body-14-22-regular">{{ type }}, {{ account?.accountGroup?.name }}</div>
                </div>
            </div>

            <form (submit)="onSubmit()" (keydown.enter)="$event.preventDefault()" [formGroup]="form">
                <div
                    ibmRow
                    class="mb-06"
                    [class.mb-12]="
                        account?.type !== 'EXPENSE' || (account?.type === 'EXPENSE' && !account?.betrkvPosition)
                    "
                >
                    <ibm-label
                        [invalid]="isInvalid('description')"
                        invalidText="{{ 'ACCOUNTING.DUPLICATE_ACCOUNT_FORM.INVALID_ACCOUNT_DESCRIPTION' | translate }}"
                        class="tw-w-full"
                        >{{ 'ACCOUNTING.ACCOUNT_DETAILS.ADDITIONAL_DESCRIPTION' | translate
                        }}{{ !isEditing ? '*' : '' }}
                        <input
                            [invalid]="isInvalid('description')"
                            ibmText
                            type="name"
                            name="description"
                            id="description"
                            formControlName="description"
                            appAutoFocusDirective
                        />
                    </ibm-label>
                </div>

                <div ibmRow class="mb-06" *ngIf="account?.type === 'EXPENSE' && account?.betrkvPosition">
                    <div class="s-body-14-22-bold">
                        {{ 'ACCOUNTING.DUPLICATE_ACCOUNT_FORM.IS_APPORTIONABLE' | translate }}
                    </div>
                </div>

                <div ibmRow class="mb-10" *ngIf="account?.type === 'EXPENSE' && account?.betrkvPosition">
                    <fieldset class="bx--fieldset">
                        <ibm-radio-group aria-label="radiogroup" orientation="vertical" formControlName="apportionable">
                            <ibm-radio [value]="'true'">
                                {{ 'ACCOUNTING.ACCOUNT_DETAILS.APPORTIONABLE' | translate }}
                            </ibm-radio>
                            <ibm-radio [value]="'false'">
                                {{ 'ACCOUNTING.ACCOUNT_DETAILS.NOT_APPORTIONABLE' | translate }}
                            </ibm-radio>
                        </ibm-radio-group>
                    </fieldset>
                </div>

                <div ibmRow>
                    <div ibmCol class="px-00">
                        <app-scalara-button (click)="abort()" [variant]="'outline'">
                            {{ 'COMMON.BTN_CANCEL' | translate }}
                        </app-scalara-button>
                    </div>

                    <div ibmCol class="d-flex justifiy-content-end px-00">
                        <app-scalara-button [disabled]="!isFormValid()" [variant]="'default'" type="submit">
                            {{ 'COMMON.BTN_SAVE' | translate }}
                        </app-scalara-button>
                    </div>
                </div>
            </form>
        </div>
        <div ibmCol></div>
    </div>
</div>
