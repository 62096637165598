import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbItem } from 'carbon-components-angular';
import { Observable, Subject, combineLatest, map, switchMap, takeUntil, tap } from 'rxjs';
import { BreadCrumbService } from 'src/app/core/services/breadcrumb.service';
import { BookingDto, OpenItemDto, OpenItemsService, ReceiptDto } from 'src/app/generated-sources/accounting';
import { Property } from 'src/app/generated-sources/base';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { TableModel } from 'src/app/shared/table/interfaces/table-model';
import { LedgerCustomService } from '../../../../services/ledger-custom.service';
import { OpenItemsCustomService } from '../../../service/open-items-custom.service';
import { OpenItemDeleteOverlayComponent } from '../open-item-delete-overlay/open-item-delete-overlay.component';
import { AdditionalOpenItemData } from './open-item-details-card/open-item-details-card.component';

@Component({
    selector: 'app-open-item-details',
    templateUrl: './open-item-details.component.html',
    styleUrls: ['./open-item-details.component.scss'],
})
export class OpenItemDetailsComponent implements OnInit {
    public constructor(
        public openItemsApiService: OpenItemsService,
        public route: ActivatedRoute,
        public translateService: TranslateService,
        private breadcrumbService: BreadCrumbService,
        private overlayService: OverlayService,
        private ledgerCustomService: LedgerCustomService
    ) {}
    public openItemsCustomService = new OpenItemsCustomService();

    private unsubscribe$ = new Subject<void>();
    public isLoading = false;
    public ledgerId = '';
    public openItemId = '';
    public breadcrumbs: BreadcrumbItem[] = [];
    public openItem = {} as OpenItemDto;
    public additionalOpenItemData = {} as AdditionalOpenItemData;
    public tableModel: TableModel = { data: [], header: [] };
    public receipts: ReceiptDto[] = [];
    public openItemBookings: BookingDto[] = [];
    public pageTitle = '';
    public openItemType?: OpenItemDto.TypeEnum;
    public isLedgerMVorSEV$ = this.ledgerCustomService
        .getLedger$()
        .pipe(
            map(
                (ledger) =>
                    ledger?.type === Property.PropertyTypeEnum.Mv || ledger?.type === Property.PropertyTypeEnum.Sev
            )
        );

    public ngOnInit(): void {
        this.initBreadCrumbs();
        this.route.queryParams.subscribe((params) => {
            this.pageTitle = params['pageTitle'];
            this.openItemType = params['openItemType'];
        });
    }

    public getAdditionalOpenItemData(openItem: OpenItemDto): AdditionalOpenItemData {
        const isDue = this.openItemsCustomService.isDue({ dueStringDate: openItem.dueDate, state: openItem.state });
        const bookingDescription =
            (openItem.bookings?.find((booking) => booking.isOpeningBooking)?.guidedBookingInfo
                ?.shortDescription as string) || '';

        return { isDue, bookingDescription };
    }

    private initBreadCrumbs(): void {
        this.getOpenItem().subscribe((isLedgerMVorSEV) => {
            let url = '';
            let pageName = '';
            if (this.openItemType === 'ADVANCEMENT') {
                url = 'advancements';
                pageName = isLedgerMVorSEV
                    ? this.translateService.instant('PAGES.OPEN_ITEMS.ADVANCEMENT_MV_SEV_HEADLINE')
                    : this.translateService.instant('PAGES.OPEN_ITEMS.ADVANCEMENT_HEADLINE');
            } else {
                url = 'open-items';
                pageName = this.translateService.instant('PAGES.OPEN_ITEMS.HEADLINE');
            }

            const breadcrumb: BreadcrumbItem[] = [
                {
                    content: pageName,
                    route: [`/accounting/ledger/${this.ledgerId}/${url}`],
                },
                {
                    content: this.translateService.instant('ACCOUNTING.OPEN-ITEMS.BREADCRUMB'),
                    route: [`/accounting/ledger/${this.ledgerId}/${url}/${this.openItemId}`],
                    current: true,
                },
            ];
            this.breadcrumbService.resetBreadCrumbs();
            this.breadcrumbService.updateBreadCrumbs(breadcrumb);
            this.breadcrumbs = this.breadcrumbService.getCurrentBreadCrumbs();
        });
    }

    private getOpenItem(): Observable<boolean> {
        this.isLoading = true;
        return combineLatest([this.route.parent!.paramMap, this.route.paramMap]).pipe(
            tap((params: ParamMap[]) => {
                this.ledgerId = String(params[0].get('id'));
                this.openItemId = String(params[1].get('openItemId'));
            }),
            switchMap(() => this.openItemsApiService.findOne(this.ledgerId, this.openItemId, false)),
            tap((openItem: OpenItemDto) => {
                const receiptsArray: ReceiptDto[][] = [];
                const bookings: BookingDto[] = [];

                openItem.bookings?.map((bookingWithItems: BookingDto) => {
                    receiptsArray.push(bookingWithItems.receipts || []);
                    const booking: BookingDto = bookingWithItems;
                    bookings.push(booking);
                });

                this.openItemBookings = bookings;
                this.receipts = Array.prototype.concat.apply([], receiptsArray);

                this.openItem = openItem;
                this.additionalOpenItemData = this.getAdditionalOpenItemData(openItem);
                this.isLoading = false;
            }),
            switchMap(() => {
                return this.isLedgerMVorSEV$;
            })
        );
    }

    public openDeleteOpenItemOverlay(): void {
        const ref = this.overlayService.open(OpenItemDeleteOverlayComponent, {
            data: {
                openItem: this.openItem,
                receipts: this.receipts,
                isReceivables: this.openItemType === 'RECEIVABLE',
                bookingId: (this.openItem.bookings && this.openItem.bookings[0].id) || '',
                ledgerId: this.ledgerId,
            },
        });

        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
    }
}
