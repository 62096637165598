<div class="tw-px-10 tw-pt-4 tw-pb-20 tw-border-y-[1px] tw-border-solid tw-border-scalaraGray-05">
    <div class="tw-flex tw-justify-end tw-space-x-2">
        <app-scalara-button
            *ngIf="overlayCallbacks?.deleteOverlay"
            (click)="openDeleteOverlay()"
            variant="ghost"
            svgName="24_delete.svg"
            >{{ 'CONTROLS.BUTTONS.REMOVE' | translate }}</app-scalara-button
        >
        <app-scalara-button
            *ngIf="overlayCallbacks?.editOverlay"
            (click)="openEditOverlay()"
            variant="ghost"
            svgName="24_edit.svg"
            >{{ 'CONTROLS.BUTTONS.EDIT' | translate }}</app-scalara-button
        >
    </div>

    <div class="tw-mt-4 tw-mb-8">
        <div class="tw-flex tw-items-center tw-space-x-4">
            <div *ngIf="iconSrc" class="tw-fill-{{ iconColor }}-01 tw-w-10">
                <svg-icon src="/assets/icons/{{ iconSrc }}"></svg-icon>
            </div>
            <div class="tw-s-head-18-26-bold">{{ title }}</div>
        </div>

        <div class="tw-mt-2" *ngIf="tagLabel && tagColor">
            <app-tag [color]="tagColor">{{ tagLabel }}</app-tag>
        </div>
    </div>

    <ng-content></ng-content>

    <div *ngIf="documentsList && documentsList.length > 0" class="tw-mt-6">
        <div class="tw-s-head-14-22-bold tw-mb-4">Dokumente</div>
        <app-filestorage-documents-list [documentList]="documentsList"></app-filestorage-documents-list>
    </div>
</div>
<div *ngIf="itemListForPagination" class="tw-px-10 tw-py-4 tw-flex tw-justify-between">
    <app-scalara-button [disabled]="currentPage === 0" (click)="previousItem()" variant="ghost"
        >Vorheriges anzeigen</app-scalara-button
    >
    <app-scalara-button
        [disabled]="currentPage + 1 === itemListForPagination.length"
        (click)="nextItem()"
        variant="ghost"
        >Nächstes anzeigen</app-scalara-button
    >
</div>
