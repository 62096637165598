import { Injectable } from '@angular/core';
import { CognitoUser, CognitoUserAttribute, CognitoUserPool, ISignUpResult } from 'amazon-cognito-identity-js';
import { Observable, Subject, Subscription, throwError } from 'rxjs';
import { PersonData } from 'src/app/shared/person-type-and-name-form/person-type-and-name-form.component';
import { AuthService } from '../../../core/services/auth.service';

type SignUpParams = {
    email: string;
    password: string;
    source: string;
    personData?: PersonData;
    invitationToken?: string;
};

@Injectable({
    providedIn: 'root',
})
export class VerificationService {
    private signUpResult$ = new Subject<ISignUpResult | Error>();
    private readonly userPool: CognitoUserPool;
    private user: CognitoUser | undefined;
    private userSubscription: Subscription;

    public constructor(private authService: AuthService) {
        this.userPool = this.authService.getUserPool();
        this.userSubscription = this.authService.getUser().subscribe((user: CognitoUser) => (this.user = user));
    }

    public signUp({ email, password, source, personData, invitationToken }: SignUpParams): void {
        const emailAttribute = new CognitoUserAttribute({ Name: 'email', Value: email });
        const registrationInfoAttribute = new CognitoUserAttribute({ Name: 'custom:registrationInfo', Value: source });
        const invitationTokenAttribute = new CognitoUserAttribute({
            Name: 'custom:invitationToken',
            Value: invitationToken ? invitationToken : '',
        });

        const customPersonAttributeKeys: { name: string; key: keyof PersonData }[] = [
            { name: 'personType', key: 'type' },
            { name: 'firstName', key: 'firstName' },
            { name: 'lastName', key: 'lastName' },
            { name: 'companyName', key: 'companyName' },
            { name: 'streetName', key: 'streetName' },
            { name: 'streetNumber', key: 'streetNumber' },
            { name: 'zipCode', key: 'zipCode' },
            { name: 'city', key: 'area' },
        ];

        const userData = {
            Username: email,
            Pool: this.userPool,
        };

        const cognitoUser = new CognitoUser(userData);
        this.authService.setUser(cognitoUser);
        this.userPool.signUp(
            email,
            password,
            [
                emailAttribute,
                registrationInfoAttribute,
                invitationTokenAttribute,
                ...customPersonAttributeKeys.map(
                    (attribute) =>
                        new CognitoUserAttribute({
                            Name: `custom:${attribute.name}`,
                            Value: personData?.[attribute.key] ?? '',
                        })
                ),
            ],
            [],
            (err: Error | undefined, result: ISignUpResult | undefined) => {
                if (!result && err) {
                    this.signUpResult$.next(err);
                }
                if (result) {
                    this.signUpResult$.next(result);
                }
            }
        );
    }

    public confirmUser(confirmationCode: string): Observable<any> {
        if (this.user === undefined) {
            return throwError(() => new Error());
        }
        return new Observable((observer: any) => {
            this.user!.confirmRegistration(confirmationCode, true, function (error: Error, result: ISignUpResult) {
                if (error) {
                    observer.error(error);
                } else {
                    observer.next(result);
                    observer.complete();
                }
            });
        });
    }

    public setSignUpResult(result: ISignUpResult | Error): void {
        this.signUpResult$.next(result);
    }

    public getSignUpResult(): Subject<ISignUpResult | Error> {
        return this.signUpResult$;
    }
}
