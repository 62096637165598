<ibm-loading *ngIf="isOverlayLoading" [isActive]="isOverlayLoading" [size]="'normal'" [overlay]="isOverlayLoading">
</ibm-loading>

<div ibmGrid class="bx--grid--full-width">
    <div ibmRow>
        <div ibmCol></div>
        <div ibmCol [columnNumbers]="{ md: 10, lg: 10 }" class="px-00">
            <div ibmRow class="pl-05">
                <div class="d-flex headline-wrapper tw-mb-10">
                    <div class="mr-06 headline-wrapper__icon">
                        <div
                            *ngIf="
                                selectedShortBooking?.groupName === 'Geldeingang buchen' ||
                                selectedShortBooking?.groupName === 'Forderung eröffnen'
                            "
                            class="icon-container icon-container--72"
                        >
                            <svg-icon src="/assets/icons/40_geldeingang.svg"></svg-icon>
                        </div>
                        <div
                            *ngIf="selectedShortBooking?.groupName === 'Geldausgang buchen'"
                            class="icon-container icon-container--72 icon-container--orange"
                        >
                            <svg-icon src="/assets/icons/40_geldausgang.svg"></svg-icon>
                        </div>
                        <div
                            *ngIf="
                                selectedShortBooking?.groupName === 'Mietzahlungen' ||
                                selectedShortBooking?.groupName === 'Vorschüsse'
                            "
                            class="icon-container icon-container--72 icon-container--purple"
                        >
                            <svg-icon src="/assets/icons/40_vorschuesse.svg"></svg-icon>
                        </div>
                        <div
                            *ngIf="selectedShortBooking?.groupName === 'Umbuchen'"
                            class="icon-container icon-container--72 icon-container--blue"
                        >
                            <svg-icon src="/assets/icons/40_umbuchen.svg"></svg-icon>
                        </div>
                        <div
                            *ngIf="selectedShortBooking?.groupName === 'Abgrenzen'"
                            class="icon-container icon-container--72 icon-container--blue"
                        >
                            <svg-icon src="/assets/icons/40_abgrenzen.svg"></svg-icon>
                        </div>
                        <div
                            *ngIf="selectedShortBooking?.groupName === 'Vorratsbrennstoff'"
                            class="icon-container icon-container--72 icon-container--blue"
                        >
                            <svg-icon src="/assets/icons/40_brennstoff.svg"></svg-icon>
                        </div>
                    </div>
                    <div class="headline-wrapper__title">
                        <div class="d-flex align-items-center tw-mb-2">
                            <div class="s-head-28-36-bold tw-mr-2">{{ selectedShortBooking?.subgroupName }}</div>
                            <div>
                                <app-tooltip position="bottom">
                                    <div content>{{ selectedShortBooking?.longDescription || '' }}</div>
                                    <div hoverable-item class="hoverable-item">
                                        <svg-icon src="/assets/icons/24_information.svg"></svg-icon>
                                    </div>
                                </app-tooltip>
                            </div>
                        </div>
                        <div class="subtitle-container">
                            <div *ngIf="selectedShortBooking?.shortDescription" class="s-label-14-22-regular mr-05">
                                {{ selectedShortBooking?.shortDescription }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="mb-12"
                *ngIf="this.config?.data.bankTransaction && (this.config?.data.bankTransaction | keyvalue)?.length"
            >
                <app-banking-transaction-detail-card
                    [bankTransaction]="this.bankTransaction"
                    [bankAccount]="this.bankAccount"
                    [isSimpleView]="true"
                ></app-banking-transaction-detail-card>
            </div>
            <div ibmRow *ngIf="warningMessage.length" class="tw-mb-9">
                <div ibmCol>
                    <app-notification type="error" customIconSrc="40_warning.svg" iconSize="m">
                        <div class="s-head-18-26-bold s-red-01 tw-mb-2">{{ warningMessage[0] }}</div>
                        <div class="s-body-14-22-regular">{{ warningMessage[1] }}</div>
                    </app-notification>
                </div>
            </div>

            <form (keydown.enter)="$event.preventDefault()" [formGroup]="form">
                <div ibmRow class="mb-04">
                    <div
                        ibmCol
                        *ngIf="!isOverlayLoading && hasBookingAdditionalParameter('bookingDate')"
                        [columnNumbers]="{ md: 5, lg: 5 }"
                    >
                        <ibm-date-picker
                            label="{{ 'ACCOUNTING.ADD_BOOKING_FORM.LABEL_BOOKING_DATE' | translate }}*"
                            id="bookingDate"
                            [placeholder]="'tt.mm.jjjj'"
                            [size]="'md'"
                            [language]="'de'"
                            [dateFormat]="'d.m.Y'"
                            [invalid]="isNotFuture('bookingDate')"
                            invalidText="{{ 'ACCOUNTING.ERROR.INVALID_BOOKINGDATE' | translate }}"
                            (valueChange)="changeBookingDate($event)"
                            formControlName="bookingDate"
                        >
                        </ibm-date-picker>
                    </div>
                    <div
                        ibmCol
                        *ngIf="
                            !isOverlayLoading &&
                            hasBookingAdditionalParameter('effectiveYear') &&
                            effectiveYearListItems.length
                        "
                        class="tw-h-[88px]"
                        [columnNumbers]="{ md: 11, lg: 11 }"
                    >
                        <app-combo-box
                            [size]="'md'"
                            id="effectiveYear"
                            [appendInline]="true"
                            [placeholder]="'tt.mm.jjjj - tt.mm.jjjj'"
                            [label]="effectiveYearLabel"
                            [items]="effectiveYearListItems"
                            invalidText="{{ 'ACCOUNTING.ERROR.INVALID_AMOUNT' | translate }}"
                            formControlName="effectiveYear"
                        >
                            <ibm-dropdown-list></ibm-dropdown-list>
                        </app-combo-box>
                    </div>
                </div>

                <div class="mb-07">
                    <ibm-label
                        [invalid]="controlInFormGroupIsInvalid(this.form, 'description')"
                        invalidText="{{ (this.form.controls['description'].errors?.['maxlength'] ? 'CONTROLS.FORMCONTROLS.GENERAL.MAX_LENGHT':'ACCOUNTING.ERROR.INVALID_BOOKING_DESCRIPTION') | translate }}"
                    >
                        {{
                            selectedBookingId !== 4 && selectedBookingId !== 5
                                ? ('ACCOUNTING.ADD_BOOKING_FORM.LABEL_BOOKING_DESCRIPTION' | translate)
                                : ('ENTITIES.OPEN_ITEM.DESCRIPTION' | translate)
                        }}*
                        <textarea
                            ibmTextArea
                            [invalid]="controlInFormGroupIsInvalid(this.form, 'description')"
                            aria-label="textarea"
                            formControlName="description"
                            maxlength="255"
                        ></textarea>
                    </ibm-label>
                </div>

                <ng-container>
                    <app-add-booking-item-form
                        [form]="form"
                        [type]="bookingType"
                        [accountListItems]="accountListItems"
                        [showSum]="selectedBookingId === 4 || selectedBookingId === 5 ? false : showSum"
                        [placeHolder]="accountPlaceHolder"
                        [enableMultipleBookings]="!isAccountToBeClosed"
                        [title]="''"
                        [label]="titleListItems + '*'"
                        [amountDisabled]="isAccountToBeClosed && openItemListItems.length === 0"
                        [reserveFundAccountListItems]="additionalAccountListItem"
                        [titleReserveFundAccountListItems]="titleAdditionalAccountListItem + '*'"
                        [thereIsReserveFundAccount]="thereIsAdditionalAccountList"
                        [selectedBookingId]="selectedBookingId"
                        [bankTransaction]="this.config?.data.bankTransaction"
                        [taxShare]="taxShare"
                        [labourAmount]="labourAmount"
                        [amountDisabled]="isAccountToBeClosed && openItemListItems.length === 0"
                        [taxShare]="taxShare"
                        [labourAmount]="labourAmount"
                        (sizeChanged)="changeShowSumStatus()"
                        (addNewLabourAmountInput)="onAccountSelected($event)"
                        (selectedAccount)="onAccountSelected($event)"
                        amountDisabledTooltipMessage="{{
                            'ACCOUNTING.ADD_BOOKING_FORM.NUMBER_INPUT_TOOLTIP_ERROR_NO_OPENITEMS' | translate
                        }}"
                    ></app-add-booking-item-form>
                </ng-container>

                <ng-container *ngIf="isAccountToBeClosed">
                    <div ibmRow class="mb-3r justifiy-content-end">
                        <div
                            ibmCol
                            *ngIf="
                                selectedShortBooking?.category !== 'EXPENSE' &&
                                (selectedShortBooking?.groupName === 'Vorschüsse' ||
                                    selectedShortBooking?.groupName === 'Mietzahlungen')
                            "
                        >
                            <div class="d-flex align-items-center s-body-14-22-regular mb-2r">
                                <svg-icon class="info-icon mr-0-5r" src="/assets/icons/24_information.svg"></svg-icon>
                                <span class="mr-1r">{{
                                    'ACCOUNTING.ADD_BOOKING_FORM.LABEL_SUGGESTED_AMOUNT_OPEN_ITEMS' | translate
                                }}</span>
                                <span class="s-body-14-22-bold"
                                    >{{ suggestedAmountFromOpenItems | eurocent }}&nbsp;€</span
                                >
                            </div>
                        </div>
                        <div ibmCol class="text-right">
                            <span class="mr-1r s-body-14-22-regular">{{
                                'ACCOUNTING.OPEN-ITEMS.SELECTED_AMOUNT' | translate
                            }}</span
                            ><span class="s-body-14-22-bold">{{ coveredAmount() | eurocent }}&nbsp;€</span>
                        </div>
                    </div>
                    <div ibmRow class="mb-06">
                        <div ibmCol class="s-label-12-22-semibold">
                            {{ 'ACCOUNTING.OPEN-ITEMS.CLOSE_DESCRIPTION' | translate }}
                        </div>
                    </div>
                    <app-table-multi-selector
                        [header]="openItemsHeader"
                        [data]="openItemsDataTable"
                        [emptyText]="'ACCOUNTING.OPEN-ITEMS.EMPTY_MULTIPLE_ITEM'"
                        (rowSelected)="updateOpenItemSelection($event)"
                        [isComponentStandAlone]="false"
                        [tableSearchId]="''"
                        [isLoading]="!isOverlayLoading && isLoading"
                    >
                    </app-table-multi-selector>

                    <div ibmRow *ngIf="openItemsSelected.length && amountToCover() < 0" class="mb-04">
                        <div ibmCol>
                            <app-notification type="info">
                                <div class="s-head-14-22-regular">
                                    {{ 'ACCOUNTING.OPEN-ITEMS.CLOSING_SELECTION_INFO' | translate }}
                                </div>
                            </app-notification>
                        </div>
                    </div>

                    <div ibmRow *ngIf="openItemsSelected.length === 1 && amountToCover() > 0" class="mb-04">
                        <div ibmCol>
                            <app-notification type="warning">
                                <div class="s-head-14-22-regular mb-06">
                                    {{ 'ACCOUNTING.OPEN-ITEMS.CLOSING_PARTLY_INFO' | translate }}
                                </div>
                                <div class="s-head-14-22-semibold">
                                    {{ 'ACCOUNTING.OPEN-ITEMS.CLOSING_PARTLY_INFO_2' | translate }}
                                    {{ amountToCover() | eurocent }}€
                                </div>
                            </app-notification>
                        </div>
                    </div>

                    <div ibmRow *ngIf="openItemsSelected.length > 1 && amountToCover() > 0" class="mb-04">
                        <div ibmCol>
                            <app-notification type="warning">
                                <div class="s-head-14-22-regular mb-06">
                                    {{ 'ACCOUNTING.OPEN-ITEMS.CLOSING_MULTIPLE_PARTLY_INFO' | translate }}
                                </div>
                                <div class="s-head-14-22-regular">
                                    {{ 'ACCOUNTING.OPEN-ITEMS.CLOSING_MULTIPLE_AMOUNT_DISTRIBUTION' | translate }}
                                </div>
                                <ng-container *ngFor="let openItem of getOpenItemAllocation()">
                                    <div *ngIf="!openItem.isResidual" class="s-head-14-22-regular">
                                        {{ 'ACCOUNTING.OPEN-ITEMS.CLOSING_MULTIPLE_ITEM' | translate : openItem }}
                                    </div>
                                    <div *ngIf="openItem.isResidual" class="s-head-14-22-regular">
                                        {{
                                            'ACCOUNTING.OPEN-ITEMS.CLOSING_MULTIPLE_ITEM_WITH_REMAINDER'
                                                | translate : openItem
                                        }}
                                    </div>
                                </ng-container>
                            </app-notification>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="!isOverlayLoading && hasBookingAdditionalParameter('openItemDueDate')">
                    <div ibmRow class="mb-04">
                        <div ibmCol class="d-flex align-items-center mb-05">
                            <svg-icon src="/assets/icons/24_information.svg" class="info-icon"></svg-icon>
                            <div
                                *ngIf="selectedBookingId !== 10 && selectedBookingId !== 11 && selectedBookingId !== 12"
                                class="s-label-14-22-regular"
                            >
                                {{ 'PAGES.OPEN_ITEMS.DUE_AT_DESCRIPTION' | translate
                                }}{{ selectedShortBooking?.category === 'EXPENSE' ? 'Verbindlichkeit' : 'Forderung' }}
                            </div>
                            <div
                                *ngIf="selectedBookingId === 10 || selectedBookingId === 11 || selectedBookingId === 12"
                                class="s-label-14-22-regular"
                            >
                                {{ 'PAGES.OPEN_ITEMS.ADVANCEMENT_DESCRIPTION' | translate }}
                                {{ guidedBookingSubGroup }}
                            </div>
                        </div>
                    </div>
                    <div ibmRow class="mb-04">
                        <div ibmCol [columnNumbers]="{ md: 6, lg: 6 }">
                            <ibm-date-picker
                                label="{{ 'ENTITIES.OPEN_ITEM.DUE_DATE' | translate }}*"
                                id="dueDate"
                                [placeholder]="'tt.mm.jjjj'"
                                [size]="'md'"
                                [language]="'de'"
                                [dateFormat]="'d.m.Y'"
                                invalidText="{{ 'ACCOUNTING.ERROR.INVALID_DUEDATE' | translate }}"
                                (valueChange)="changeDueDate($event)"
                                formControlName="dueDate"
                            >
                            </ibm-date-picker>
                        </div>
                    </div>
                </ng-container>

                <div ibmRow *ngIf="isTransactionAmountError()" class="mb-04">
                    <div ibmCol>
                        <app-notification
                            type="error"
                            subtitle="{{ 'ACCOUNTING.ERROR.INVALID_AMOUNT_TRANSACTION' | translate }}"
                        >
                        </app-notification>
                    </div>
                </div>

                <div class="border-top mt-5r">
                    <div ibmRow class="mb-3r">
                        <div class="s-head-16-24-bold mt-06 ml-05">
                            {{ 'ACCOUNTING.ADD_BOOKING_FORM.RECEIPT_ATTATCH' | translate }}
                        </div>
                    </div>
                </div>

                <div class="d-flex align-items-center py-05 mb-07">
                    <app-icon-bubble [size]="40" [color]="'green'" [image]="'24_upload.svg'"></app-icon-bubble>
                    <div class="s-head-14-22-bold mt-02 ml-05">
                        {{ 'ACCOUNTING.ADD_BOOKING_FORM.RECEIPT_UPLOAD' | translate }}
                    </div>
                </div>

                <app-file-uploader
                    (fileIdsLoaded)="updateFileStorageIds($event)"
                    (filesLoaded)="updateLoadingFileStatus($event)"
                    (updatedFileTypes)="updateFileType($event)"
                    [selectFileType]="true"
                ></app-file-uploader>

                <app-file-selector
                    [disabled]="!accountSelectedIds.length"
                    [disabledText]="'ACCOUNTING.ADD_BOOKING_FORM.RECEIPT_ATTATCH_REQUIREMENT'"
                    [header]="receiptsHeader"
                    [data]="receiptsDataTable"
                    [emptyText]="'ACCOUNTING.RECEIPTS.NO_RECEIPTS_AVAILABLE'"
                    (rowSelected)="updateReceiptFromSelection($event)"
                    (checkboxFilterBookedReceipts)="filterBookedReceiptsCheck($event)"
                    (checkboxAllAccountsReceipts)="receiptsAllAccountsCheck($event)"
                >
                </app-file-selector>

                <div class="tw-mt-28" *ngIf="showBookingRuleSuggestion()">
                    <app-booking-rule-suggestion-card
                        [bookingRulesSuggestion]="itemsToFillBookingRuleSuggestion()"
                        (handleBookingRuleCheckboxOnChange)="handleBookingRuleCheckboxOnChange($event)"
                        [disableCheckbox]="disableBookingRuleSuggestionCheckbox()"
                    ></app-booking-rule-suggestion-card>
                </div>

                <div ibmRow class="mt-11 buttons-container">
                    <div ibmCol class="d-flex justifiy-content-start">
                        <app-scalara-button [variant]="'outline'" (click)="abort()">
                            {{ 'ACCOUNTING.COMMON.BTN_CANCEL' | translate }}
                        </app-scalara-button>
                    </div>

                    <div ibmCol class="d-flex justifiy-content-end">
                        <div ibmRow>
                            <app-scalara-button
                                *ngIf="
                                    !isSelectedAmountFullfillingTransaction &&
                                    !isTransactionAmountError() &&
                                    this.config?.data.bankTransaction
                                "
                                (click)="onSubmitAndContinue()"
                                [disabled]="!isFormValid()"
                                [isLoading]="isOverlayLoading"
                                variant="default"
                                class="mr-1r submit"
                            >
                                {{ 'CONTROLS.BUTTONS.BOOK_AND_CONTINUE' | translate }}
                            </app-scalara-button>
                            <app-scalara-button
                                (click)="onSubmit()"
                                [disabled]="!isFormValid() || isTransactionAmountError()"
                                [isLoading]="isSubmitLoading"
                                variant="default"
                                class="submit"
                            >
                                {{ 'ACCOUNTING.COMMON.BTN_SAVE' | translate }}
                            </app-scalara-button>
                        </div>
                    </div>
                </div>
                <div class="p-10"></div>
            </form>
        </div>
        <div ibmCol></div>
    </div>
</div>
