<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<div ibmRow class="mt-06 mb-4r">
    <div ibmCol>
        <ibm-breadcrumb [items]="breadcrumbs" [noTrailingSlash]="true"> </ibm-breadcrumb>
    </div>
    <div ibmCol class="text-right">
        <div class="d-flex justify-content-end">
            <app-tooltip position="bottom" *ngIf="isCancelable === false && !booking?.isCreatedByEconomicPlan">
                <div content>{{ 'PAGES.BOOKINGS.BOOKING_DETAILS.CANCELLATION_BUTTON_TOOLTIP' | translate }}</div>
                <div hoverabe-item>
                    <app-scalara-button
                        [disabled]="true"
                        [variant]="'ghost'"
                        type="button"
                        (click)="openCancelBookingOverlay()"
                        svgName="24_error.svg"
                    >
                        {{ 'CONTROLS.BUTTONS.CANCELLATION' | translate }}
                    </app-scalara-button>
                </div>
            </app-tooltip>
            <app-scalara-button
                *ngIf="
                    booking?.type !== 'CANCELLATION' &&
                    booking?.type !== 'OPENING_BALANCE' &&
                    isCancelable &&
                    !isBookingJustCancelled &&
                    !booking?.isCancelled &&
                    !booking?.isCreatedByEconomicPlan
                "
                [variant]="'ghost'"
                type="button"
                (click)="openCancelBookingOverlay()"
                svgName="24_error.svg"
            >
                {{ 'CONTROLS.BUTTONS.CANCELLATION' | translate }}
            </app-scalara-button>
        </div>
    </div>
</div>

<div
    ibmRow
    *ngIf="booking?.isCancelled"
    class="cancelled-confirmation px-1-5r mb-4r d-flex justify-content-end align-items-center"
>
    <svg-icon src="/assets/icons/24_error.svg"></svg-icon>
    <div class="ml-0-5r s-label-12-16-regular">
        {{ 'PAGES.BOOKINGS.BOOKING_DETAILS.CANCEL_BOOKING_CONFIRMATION' | translate }}
    </div>
</div>

<div ibmRow class="headline-wrapper mb-4r" *ngIf="booking">
    <div *ngIf="booking.type === 'MANUAL'" class="mr-06 headline-wrapper__icon">
        <div class="icon-container icon-container--72 icon-container--blue">
            <svg-icon src="/assets/icons/40_soll-haben.svg"></svg-icon>
        </div>
    </div>

    <div *ngIf="booking.type === 'CANCELLATION'" class="mr-06">
        <app-icon-bubble [size]="72" [color]="'red'" [image]="'40_error.svg'"></app-icon-bubble>
    </div>

    <div *ngIf="booking.type === 'OPENING_BALANCE'" class="mr-06">
        <app-icon-bubble [size]="72" [color]="'gray'" [image]="'24_euro.svg'"></app-icon-bubble>
    </div>

    <div
        *ngIf="booking.guidedBookingInfo && booking.guidedBookingInfo.category === 'EXPENSE'"
        class="mr-06 headline-wrapper__icon"
    >
        <div class="icon-container icon-container--72 icon-container--orange">
            <svg-icon src="/assets/icons/40_geldausgang.svg"></svg-icon>
        </div>
    </div>

    <div
        *ngIf="booking.guidedBookingInfo && booking.guidedBookingInfo.category !== 'EXPENSE'"
        class="mr-06 headline-wrapper__icon"
    >
        <div class="icon-container icon-container--72">
            <svg-icon src="/assets/icons/40_geldeingang.svg"></svg-icon>
        </div>
    </div>

    <div class="headline-wrapper__title">
        <div *ngIf="booking.type === 'MANUAL'" class="s-head-18-26-bold mb-02">
            {{ 'PAGES.BOOKINGS.BOOKING_DETAILS.CLASSIC_BOOKING' | translate }}
        </div>
        <div *ngIf="booking.type === 'CANCELLATION'" class="s-head-18-26-bold mb-02">
            {{ 'PAGES.BOOKINGS.BOOKING_DETAILS.CANCEL_BOOKING_TABLE_TITLE' | translate }}
        </div>
        <div *ngIf="booking.type === 'OPENING_BALANCE'" class="s-head-18-26-bold mb-02">
            {{ 'ENTITIES.OPENING_BALANCE.LABEL_ENTITY' | translate }}
        </div>
        <div *ngIf="booking.guidedBookingInfo && booking.guidedBookingInfo.subgroup" class="s-head-18-26-bold mb-02">
            {{ booking.guidedBookingInfo.subgroup }}
        </div>
        <div *ngIf="booking.guidedBookingInfo" class="s-head-14-22-regular">
            {{ booking.guidedBookingInfo.shortDescription }}
        </div>
    </div>
</div>

<div *ngIf="booking" class="mb-4r">
    <div ibmRow class="mb-06">
        <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }" class="s-head-14-22-bold">
            {{ 'PAGES.BOOKINGS.BOOKING_DETAILS.DETAILS' | translate }}
        </div>
    </div>

    <div ibmRow class="tw-mb-2">
        <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }" class="info-field__label s-label-12-22-semibold">
            {{ 'PAGES.BOOKINGS.BOOKING_DETAILS.AMOUNT' | translate }}
        </div>
        <div ibmCol class="s-head-14-22-bold">{{ booking.amount | eurocent }} €</div>
    </div>

    <div *ngIf="booking.labourAmount" ibmRow class="tw-mb-2">
        <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }" class="info-field__label s-label-12-22-semibold">
            {{ 'PAGES.BOOKINGS.BOOKING_DETAILS.SALARY_SHARE' | translate }}
        </div>
        <div ibmCol *ngIf="booking.labourAmount < 1">-</div>
        <div ibmCol *ngIf="booking.labourAmount > 0">
            <div class="tw-mb-2 info-last-salary-share s-body-14-22-regular">
                {{ booking.labourAmount | eurocent }} €
            </div>
        </div>
    </div>
    <div *ngIf="booking.vatRate && booking.vatAmount" ibmRow class="tw-mb-2">
        <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }" class="info-field__label s-label-12-22-semibold">
            {{ 'ACCOUNTING.COMMON.TAX_SHARE' | translate }}
        </div>
        <div ibmCol class="s-body-14-22-regular">
            <span>{{ booking.vatAmount | eurocent }}€ </span>({{ booking.vatRate }}%)
        </div>
    </div>

    <div ibmRow class="tw-mb-2">
        <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }" class="info-field__label s-label-12-22-semibold">
            {{ 'ENTITIES.BOOKING.LABEL_CREATED_AT' | translate }}
        </div>
        <div ibmCol class="s-body-14-22-regular">
            {{ booking.createdAt ?? '' | dateFormat }}
        </div>
    </div>

    <div ibmRow class="tw-mb-2">
        <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }" class="info-field__label s-label-12-22-semibold">
            {{ 'ENTITIES.BOOKING.LABEL_BOOKING_DATE' | translate }}
        </div>
        <div ibmCol class="s-body-14-22-regular">
            {{ booking.bookingDate | dateFormat }}
        </div>
    </div>

    <div ibmRow *ngIf="effectiveYear" class="tw-mb-2">
        <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }" class="info-field__label s-label-12-22-semibold">
            {{ 'ENTITIES.BOOKING.LABEL_EFFECTIVE_YEAR' | translate }}
        </div>
        <div ibmCol class="s-body-14-22-regular">
            {{ effectiveYear }}
        </div>
    </div>

    <div ibmRow class="tw-mb-2">
        <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }" class="info-field__label s-label-12-22-semibold">
            {{ 'ENTITIES.BOOKING.LABEL_BOOKING_DESCRIPTION' | translate }}
        </div>
        <div ibmCol class="s-body-14-22-regular">
            {{ booking.description }}
        </div>
    </div>
</div>

<div *ngIf="booking" class="pb-13">
    <div ibmRow class="mb-06">
        <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }" class="s-head-14-22-bold">
            {{ 'PAGES.BOOKINGS.BOOKING_DETAILS.BOOKING_ACCOUNTS' | translate }}
        </div>
    </div>

    <div ibmRow class="tw-mb-2">
        <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }" class="s-label-12-22-semibold">
            {{ 'PAGES.BOOKINGS.BOOKING_DETAILS.DEBIT' | translate }}
        </div>
        <div ibmCol>
            <div ibmRow *ngFor="let debitItem of debits">
                <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }">
                    <div>{{ getDebitCreditValue(debitItem.debit, debitItem.credit) | eurocent }} €</div>
                </div>
                <div ibmCol class="s-body-14-22-regular text-left" [columnNumbers]="{ md: 11, lg: 11 }">
                    {{ debitItem.account.name }}
                    <span *ngIf="debitItem.account.description">, {{ debitItem.account.description }}</span>
                </div>
            </div>
        </div>
    </div>

    <div ibmRow class="mb-4r">
        <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }" class="s-label-12-22-semibold">
            {{ 'PAGES.BOOKINGS.BOOKING_DETAILS.CREDIT' | translate }}
        </div>
        <div ibmCol>
            <div ibmRow *ngFor="let creditItem of credits">
                <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }">
                    <div>{{ getDebitCreditValue(creditItem.debit, creditItem.credit) | eurocent }} €</div>
                </div>
                <div ibmCol class="s-body-14-22-regular text-left" [columnNumbers]="{ md: 11, lg: 11 }">
                    {{ creditItem.account.name }}
                    <span *ngIf="creditItem.account.description">, {{ creditItem.account.description }}</span>
                </div>
            </div>
        </div>
    </div>

    <div ibmRow class="mb-2r" *ngIf="booking.receipts">
        <div ibmCol>
            <div class="tw-flex tw-justify-between">
                <div class="tw-mb-2 s-body-14-22-bold">{{ 'ENTITIES.RECEIPT.LABEL_TABLE_TITLE' | translate }}</div>
                <app-scalara-button svgName="24_add.svg" variant="ghost" (click)="addReceipt()"
                    >Beleg hinzufügen</app-scalara-button
                >
            </div>
            <app-receipts-table [receipts]="booking.receipts"></app-receipts-table>
        </div>
    </div>

    <div ibmRow class="tw-mb-2" *ngIf="booking && booking.openItems && booking.openItems.length > 0">
        <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }" class="s-body-14-22-bold">
            {{
                booking.openItems[0].type === 'ADVANCEMENT'
                    ? ('PAGES.BOOKINGS.BOOKING_DETAILS.LINKED_ADVANCEMENTS' | translate)
                    : ('PAGES.BOOKINGS.BOOKING_DETAILS.LINKED_WATCHLIST' | translate)
            }}
        </div>
    </div>

    <div ibmRow class="mb-2r" *ngIf="booking && booking.openItems && booking.openItems.length > 0">
        <div ibmCol>
            <app-table
                [emptyText]="'PAGES.BOOKINGS.BOOKING_DETAILS.OPEN_ITEMS_EMPTY_TABLE'"
                [header]="tableModel.header"
                [data]="tableModel.data"
                [tableSearchId]="'bookingDetailTable1'"
                [isLoading]="isLoading"
            >
            </app-table>
        </div>
    </div>

    <div ibmRow class="tw-mb-2" *ngIf="booking && booking.bankTransaction">
        <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }" class="s-body-14-22-bold">
            {{ 'PAGES.BOOKINGS.BOOKING_DETAILS.LINKED_TRANSACTION' | translate }}
        </div>
    </div>

    <div ibmRow class="mb-2r" *ngIf="booking && booking.bankTransaction">
        <div ibmCol>
            <app-table
                [emptyText]="'PAGES.BOOKINGS.BOOKING_DETAILS.OPEN_ITEMS_EMPTY_TABLE'"
                [header]="transactionTableModel.header"
                [data]="transactionTableModel.data"
                [tableSearchId]="'bookingDetailTable2'"
                [isLoading]="isLoading"
            >
            </app-table>
        </div>
    </div>

    <div ibmRow class="tw-mb-2" *ngIf="booking.type === 'CANCELLATION'">
        <div ibmCol class="s-body-14-22-bold">
            {{ 'PAGES.BOOKINGS.BOOKING_DETAILS.CONNECTED_BOOKING' | translate }}
        </div>
    </div>

    <div ibmRow class="mb-2r" *ngIf="connectedBooking && booking.type === 'CANCELLATION'">
        <div ibmCol>
            <app-booking-table [bookings]="[connectedBooking]" [isLoading]="isLoading"></app-booking-table>
        </div>
    </div>

    <div ibmRow class="tw-mb-2" *ngIf="booking.isCancelled && !isLoading">
        <div ibmCol class="s-body-14-22-bold">
            {{ 'PAGES.BOOKINGS.BOOKING_DETAILS.CANCEL_BOOKING_TABLE_TITLE' | translate }}
        </div>
    </div>

    <div ibmRow class="mb-2r" *ngIf="booking.isCancelled && !isLoading">
        <div ibmCol>
            <app-table
                [emptyText]="'PAGES.BOOKINGS.BOOKING_DETAILS.OPEN_ITEMS_EMPTY_TABLE'"
                [header]="cancellationTableModel.header"
                [data]="connectedTableModel.data"
            >
            </app-table>
        </div>
    </div>
</div>
