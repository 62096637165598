import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, map, merge, Observable, of, shareReplay, switchMap } from 'rxjs';
import { LedgerDto, LedgersService } from 'src/app/generated-sources/accounting';
import { OwnershipName, OwnershipsService, Property } from 'src/app/generated-sources/base';

export type PropertyWithExtendedName = Property & { nameWithOwnerships: string };
export type LedgerDtoWithExtendedProperty = LedgerDto & { property: PropertyWithExtendedName };

@Injectable({
    providedIn: 'root',
})
export class LedgerCustomService {
    private constructor(private ledgersService: LedgersService, private ownershipsService: OwnershipsService) {
        this.ledgers$.subscribe();
        this.sevOwnershipsNames$.subscribe();
    }

    public getLedgerId$(): Observable<string | null> {
        return this.ledgerId$.asObservable();
    }

    public getLedger$(): typeof this.ledger$ {
        return this.ledger$;
    }

    public getLedgers(): Observable<LedgerDto[]> {
        return this.ledgers$;
    }

    public getLedgerPropertyId$(): Observable<string | null> {
        return this.ledgerPropertyId$.asObservable();
    }

    public refresh(): void {
        this.refresh$.next();
    }

    public setLedger(ledger: LedgerDto): void {
        this.ledgerDtoUpdate$.next(ledger);
    }

    //  do not forget to call this method somewhere to sync ledgerId with url route (z.B. ledgerDetailView),
    //  otherwise ledgerId$ will be null
    public setLedgerId(newLedgerId: string): void {
        this.ledgerId$.next(newLedgerId);
    }

    public setLedgerPropertyId(newLedgerPropertyId: string): void {
        this.ledgerPropertyId$.next(newLedgerPropertyId);
    }

    public getSevOwnershipsNames(): Observable<OwnershipName[]> {
        return this.sevOwnershipsNames$;
    }

    public getLedgersWithSevOwnershipsNames(): typeof this.ledgersWithSevOwnershipsNames$ {
        return this.ledgersWithSevOwnershipsNames$;
    }

    private readonly ledgerId$ = new BehaviorSubject<string | null>(null);
    private readonly ledgerPropertyId$ = new BehaviorSubject<string | null>(null);
    private refresh$ = new BehaviorSubject<void>(undefined);
    private ledgerDtoUpdate$ = new BehaviorSubject<LedgerDto | null>(null);

    // private readonly ledger$ = this.ledgerId$.pipe(
    //     switchMap((ledgerId) => (ledgerId ? this.ledgersService.findOne(ledgerId) : of(null))),
    //     shareReplay({ bufferSize: 1, refCount: true })
    // );

    private readonly ledger$ = merge(
        this.ledgerId$.pipe(switchMap((ledgerId) => (ledgerId ? this.ledgersService.findOne(ledgerId) : of(null)))),
        this.ledgerDtoUpdate$
    ).pipe(shareReplay({ bufferSize: 1, refCount: true }));

    private readonly ledgers$ = this.refresh$.pipe(
        switchMap(() => this.ledgersService.findAll()),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    private sevOwnershipsNames$ = this.getLedgers().pipe(
        switchMap((ledgers) => {
            const sevs = ledgers
                .filter((ledger) => ledger.type === 'SEV')
                .reduce((accumulator: any, currentObject) => {
                    const currentProperty = currentObject.property as Property;
                    const existingObjectIndex = accumulator.findIndex((obj: any) => {
                        return obj.propertyId === currentProperty.id;
                    });

                    if (existingObjectIndex !== -1) {
                        accumulator[existingObjectIndex].ownershipIds.push(...currentObject.ownershipIds);
                    } else {
                        accumulator.push({
                            propertyId: currentProperty.id,
                            ownershipIds: [...currentObject.ownershipIds],
                        });
                    }
                    return accumulator;
                }, []);

            if (sevs.length > 0) {
                let sevsPropertyIds = sevs.map((item: any) => item.propertyId);
                sevsPropertyIds = sevsPropertyIds.join(',');

                let sevsOwnershipsIds = sevs.map((item: any) => item.ownershipIds.join(','));
                sevsOwnershipsIds = sevsOwnershipsIds.join(',');

                return this.ownershipsService.findOwnershipNames(sevsPropertyIds, sevsOwnershipsIds);
            } else {
                return of([]);
            }
        }),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    //  add ownership names to properties of ledgers
    private ledgersWithSevOwnershipsNames$ = combineLatest([this.ledgers$, this.sevOwnershipsNames$]).pipe(
        map(([ledgers, sevOwnershipsNames]) => {
            const ledgersCopy: LedgerDtoWithExtendedProperty[] = ledgers.map((ledger) => {
                const ledgerRelatedOwnerships = sevOwnershipsNames.filter((i) => ledger.ownershipIds.includes(i.id));
                const propertyCopy: PropertyWithExtendedName = { ...ledger.property } as PropertyWithExtendedName;

                propertyCopy.nameWithOwnerships =
                    propertyCopy.name +
                    (ledgerRelatedOwnerships.length > 0
                        ? `, ${ledgerRelatedOwnerships.map((i) => i.name).join(', ')}`
                        : '');

                return { ...ledger, property: propertyCopy };
            });
            return ledgersCopy;
        })
    );
}
