<app-overlay-container
    headlineTitle="PAGES.BANK_ACCOUNTS.LABEL_ADD_NEW_BANK_ACCOUNT"
    [sizeIcon]="72"
    image="40_bank.svg"
    colorIcon="green"
    containerSize="small"
>
    <form [formGroup]="form">
        <div class="mb-1r">
            <ibm-label [invalid]="isInvalidForm('holderName')" invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                >Kontoinhaber*
                <input
                    ibmText
                    name="holderName"
                    id="holderName"
                    [invalid]="isInvalidForm('holderName')"
                    formControlName="holderName"
                />
            </ibm-label>
        </div>

        <div class="mb-1r">
            <ibm-label [invalid]="isInvalidForm('iban')" invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                >IBAN*
                <input ibmText name="iban" id="iban" [invalid]="isInvalidForm('iban')" formControlName="iban" />
            </ibm-label>
        </div>

        <div class="mb-1r">
            <ibm-label [invalid]="isInvalidForm('bankName')" invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                >Bankinstitut*
                <input
                    ibmText
                    name="bankName"
                    id="bankName"
                    [invalid]="isInvalidForm('bankName')"
                    formControlName="bankName"
                />
            </ibm-label>
        </div>

        <div class="mb-1r">
            <ibm-label [invalid]="isInvalidForm('name')" invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                >Kontoname
                <input ibmText name="name" id="name" [invalid]="isInvalidForm('name')" formControlName="name" />
            </ibm-label>
        </div>

        <ibm-label [invalid]="isInvalidForm('bic')" invalidText="{{ 'ERROR.REQUIRED' | translate }}"
            >BIC*
            <input ibmText name="bic" id="bic" [invalid]="isInvalidForm('bic')" formControlName="bic" />
        </ibm-label>

        <div class="d-flex justify-space-between mt-7-5r">
            <app-scalara-button (click)="abort()" variant="outline">
                {{ 'CONTROLS.BUTTONS.CANCEL' | translate }}
            </app-scalara-button>

            <app-scalara-button (click)="submit()" [disabled]="form.invalid" variant="default" type="submit">
                {{ 'CONTROLS.BUTTONS.SAVE' | translate }}
            </app-scalara-button>
        </div>
    </form>
</app-overlay-container>
