import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
    Observable,
    ObservedValueOf,
    Subject,
    combineLatest,
    filter,
    map,
    shareReplay,
    switchMap,
    takeUntil,
} from 'rxjs';
import { getNameFromPerson, unitTypeNameFromEnum } from 'src/app/core/utils/common';
import { formatDateDDMMYYYY } from 'src/app/core/utils/dateUtils';
import { PropertyCustomService } from 'src/app/features/property/services/property-custom.service';
import { DepositInfo, OccupationService } from 'src/app/generated-sources/base';
import { CellTemplate } from 'src/app/shared/table/enums/cell-template';
import { HeaderTemplate } from 'src/app/shared/table/enums/header-template';
import { TableModel } from 'src/app/shared/table/interfaces/table-model';
import { depositTypesToLabelMap } from '../../helpers/depositTypes';

@Component({
    selector: 'app-deposit-overview',
    templateUrl: './deposit-overview.component.html',
    styleUrls: ['./deposit-overview.component.scss'],
})
export class DepositOverviewComponent implements OnInit, OnDestroy {
    @ViewChild('personsCell', { static: true })
    public personsCell?: TemplateRef<any>;

    public constructor(
        public occupationService: OccupationService,
        private propertyCustomService: PropertyCustomService
    ) {}

    public unsubscribe$ = new Subject<void>();
    public isLoading = false;
    public propertyId$ = this.propertyCustomService.getPropertyId$();
    public depositInfo$ = this.propertyId$.pipe(
        filter(Boolean),
        switchMap((propertyId) => this.occupationService.findDepositInfos(propertyId)),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    public tableModel$: Observable<TableModel> = combineLatest([this.propertyId$, this.depositInfo$]).pipe(
        filter(() => this.personsCell !== undefined),
        map(([propertyId, depositInfo]) => {
            return { propertyId, depositInfo };
        }),
        map(({ propertyId, depositInfo }) => {
            const i18OccupationBasePath = 'PAGES.OCCUPATION.';
            const tableModel: TableModel = {
                header: [
                    `${i18OccupationBasePath}OVERVIEW.LABEL_OCCUPATION_NUMBER`,
                    `${i18OccupationBasePath}OVERVIEW.LABEL_UNITS`,
                    `${i18OccupationBasePath}OVERVIEW.LABEL_PERSONS`,
                    `${i18OccupationBasePath}DEPOSIT_OVERVIEW.LABEL_DEPOSIT_TYPE`,
                    {
                        data: { key: `${i18OccupationBasePath}DEPOSIT_OVERVIEW.LABEL_DEPOSIT_AMOUNT`, params: {} },
                        template: HeaderTemplate.RightAligned,
                    },
                ],
                data: depositInfo.map((x) => {
                    const occupationLink = `/properties/${propertyId}/occupations/${x.occupationId}/commit`;
                    return [
                        {
                            data: {
                                label: x.occupationNumber,
                                link: occupationLink,
                            },
                            template: CellTemplate.Default,
                        },
                        {
                            data: {
                                label: this.getOwnershipsLabels(x),
                                link: occupationLink,
                            },
                            template: CellTemplate.Default,
                        },
                        {
                            data: {
                                label: '',
                                link: occupationLink,
                                extraData: { persons: x.tenants },
                            },
                            template: this.personsCell,
                        },
                        {
                            data: {
                                label: depositTypesToLabelMap[x.depositType],
                                link: occupationLink,
                            },
                            template: CellTemplate.Default,
                        },
                        {
                            data: {
                                label: x.depositAmount ?? 0,
                                link: occupationLink,
                            },
                            template: CellTemplate.EuroCent,
                        },
                    ];
                }),
            };
            return tableModel;
        })
    );

    public vm$ = combineLatest([this.propertyId$, this.depositInfo$, this.tableModel$]).pipe(
        map(([propertyId, depositInfo, tableModel]) => ({
            propertyId,
            depositInfo,
            tableModel,
        }))
    );
    public vm: null | ObservedValueOf<typeof this.vm$> = null;

    public formatDate = formatDateDDMMYYYY;

    public getPersonsLabels(depositInfo: DepositInfo): string {
        return depositInfo.tenants.map((tenant) => getNameFromPerson(tenant)).join(', ');
    }

    public getOwnershipsLabels(depositInfo: DepositInfo): string {
        return depositInfo.unitsIncluded.map((unit) => `${unitTypeNameFromEnum(unit)} ${unit.name}`).join(', ');
    }

    public getNameFromPerson = getNameFromPerson;

    public ngOnInit(): void {
        this.isLoading = true;
        this.vm$.pipe(takeUntil(this.unsubscribe$)).subscribe((vm) => {
            this.vm = vm;
            this.isLoading = false;
        });
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }
}
