import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject, combineLatest, filter, of, switchMap, takeUntil, tap } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { TooltipKey } from 'src/app/features/account-settings/services/custom-tooltip.service';
import {
    AccountDto,
    AccountsService,
    BankAccountDto,
    BankAccountsService,
    LedgerDto,
} from 'src/app/generated-sources/accounting';
import { AccountsPerHeadingDto } from 'src/app/generated-sources/accounting/model/accountsPerHeadingDto';
import { Property } from 'src/app/generated-sources/base';
import { TextWithTooltipComponent } from 'src/app/shared/components/text-with-tooltip/text-with-tooltip.component';
import { ToggleSwitchCallbacks } from 'src/app/shared/components/toggle-switch/toggle-switch.component';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { HeaderTemplate } from 'src/app/shared/table/enums/header-template';
import { TableItem } from 'src/app/shared/table/interfaces/table-item';
import { formatDateYYYYMMDDWithoutHours, getCurrentDateDDMMYYYY } from '../../../../../core/utils/common';
import { CellTemplate } from '../../../../../shared/table/enums/cell-template';
import { TableModel } from '../../../../../shared/table/interfaces/table-model';
import { AddAccountFormComponent } from '../../add-account-form/add-account-form.component';
import { AddReserveFormComponent } from '../../add-reserve-form/add-reserve-form.component';
import { OpeningBalanceComponent } from '../../opening-balance/opening-balance.component';
import { AccountingFilterCustomService, DatePickerDates } from '../../services/accounting-filter-custom.service';
import { LedgerCustomService } from '../../services/ledger-custom.service';

interface TableModelWithTitle extends TableModel {
    title: string;
    titleTooltip?: string;
}
@Component({
    selector: 'app-account-charts',
    templateUrl: './account-charts.component.html',
    styleUrls: ['./account-charts.component.scss'],
})
export class AccountChartsComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();
    public refresh$ = new BehaviorSubject(null);

    public ledger?: LedgerDto;
    public form: UntypedFormGroup = new UntypedFormGroup({});
    public accounts?: AccountsPerHeadingDto[];
    public isLoading = false;
    public isInitialLoading = false;
    public bankAccounts: BankAccountDto[] = [];
    public tableModel: TableModelWithTitle[] = [{ data: [], header: [], title: '' }];
    public dates?: DatePickerDates;
    public isDownloading = false;
    public leftSelected = true;

    public constructor(
        public translateService: TranslateService,
        private accountsService: AccountsService,
        private overlayService: OverlayService,
        private bankAccountService: BankAccountsService,
        private ledgerCustomService: LedgerCustomService,
        private accountingFilterCustomService: AccountingFilterCustomService,
        private toastService: ToastService
    ) {}

    public toggleSwitchCallbacks: ToggleSwitchCallbacks = {
        leftCallback: () => {
            this.accountingFilterCustomService.setBookingsCalculationMode('BOOKING_DATE');
            this.refresh$.next(null);
        },
        rightCallback: () => {
            this.accountingFilterCustomService.setBookingsCalculationMode('EFFECTIVE_DATE');
            this.refresh$.next(null);
        },
    };

    public ledger$ = this.ledgerCustomService.getLedger$().pipe(
        filter(Boolean),
        tap((ledger) => (this.ledger = ledger))
    );

    public datePickerSelectedDates$ = this.accountingFilterCustomService.getDatePickerSelectedDates$();
    public bookingsCalculationMode$ = this.accountingFilterCustomService.getBookingsCalculationMode$();

    public createAccountsTables(accounts: AccountsPerHeadingDto[]): void {
        const today = getCurrentDateDDMMYYYY();

        accounts.map((account: AccountsPerHeadingDto, index: number) => {
            this.tableModel[index] = {
                data: account.accounts.map((account: AccountDto) => {
                    return this.createRow(account);
                }),
                header: [
                    {
                        data: {
                            key: '',
                            params: {},
                            extraData: {
                                component: TextWithTooltipComponent,
                                componentData: {
                                    label: this.translateService.instant('ACCOUNTING.ACCOUNT_CHARTS.DESCRIPTION'),
                                    tooltipInnerHtml: this.translateService.instant(
                                        'PAGES.TOOLTIPS.ACCOUNT_CHARTS.DESCRIPTION'
                                    ),
                                    tooltipKey: TooltipKey.tooltipsInAccounting,
                                },
                            },
                        },
                        width: '460px',
                        template: HeaderTemplate.dynamicComponentHeader,
                    },
                    {
                        data: {
                            key: '',
                            params: {},
                            extraData: {
                                component: TextWithTooltipComponent,
                                componentData: {
                                    label: this.translateService.instant(
                                        'ACCOUNTING.ACCOUNT_CHARTS.ADDITIONAL_DESCRIPTION'
                                    ),
                                    tooltipInnerHtml: this.translateService.instant(
                                        'PAGES.TOOLTIPS.ACCOUNT_CHARTS.ADDITIONAL_DESCRIPTION'
                                    ),
                                    tooltipKey: TooltipKey.tooltipsInAccounting,
                                },
                            },
                        },

                        template: HeaderTemplate.dynamicComponentHeader,
                    },
                    { data: { key: '', params: {} }, width: '140px' },
                    {
                        data: { key: 'ACCOUNTING.ACCOUNT_CHARTS.BALANCE', params: {} },
                        width: '192px',
                        template: HeaderTemplate.RightAligned,
                    },
                    { data: { key: '', params: {} }, width: '160px' },
                ],
                title: account.heading,
                titleTooltip: account.headingTooltip,
            };
        });
    }

    public accounts$ = combineLatest([
        this.ledger$,
        this.datePickerSelectedDates$,
        this.bookingsCalculationMode$,
        this.refresh$,
    ]).pipe(
        tap(() => (this.isLoading = true)),
        switchMap(([ledger, selectedBusinessDates, accountCalculationMode]) => {
            this.dates = selectedBusinessDates;
            if (selectedBusinessDates) {
                const startDate = formatDateYYYYMMDDWithoutHours(selectedBusinessDates.startDate);
                const endDate = formatDateYYYYMMDDWithoutHours(selectedBusinessDates.endDate);

                return this.accountsService.findAllGrouped(ledger.id, startDate, endDate, accountCalculationMode);
            }
            return this.accountsService.findAllGrouped(ledger.id, undefined, undefined, accountCalculationMode);
        }),
        switchMap((accounts: AccountsPerHeadingDto[]) => {
            this.accounts = accounts;
            this.createAccountsTables(accounts);
            if (this.ledger?.id) {
                return this.bankAccountService.findAll(this.ledger.id);
            }
            return of([]);
        }),
        tap((bankAccounts) => {
            this.bankAccounts = bankAccounts;
            this.isInitialLoading = false;
            this.isLoading = false;
        }),
        takeUntil(this.unsubscribe$)
    );

    public ngOnInit(): void {
        this.isInitialLoading = true;
        this.accounts$.subscribe();
    }

    public getAccountStatement(): void {
        this.isDownloading = true;
        if (this.ledger && this.dates) {
            const property = this.ledger.property as Property;
            this.accountsService
                .getAllAccountStatementsPdfs(
                    this.ledger?.id,
                    formatDateYYYYMMDDWithoutHours(this.dates.startDate),
                    formatDateYYYYMMDDWithoutHours(this.dates.endDate)
                )
                .subscribe({
                    next: (response) => {
                        const link = document.createElement('a');
                        const blob = new Blob([response], { type: 'application/pdf' });
                        link.href = window.URL.createObjectURL(blob);
                        link.download = `Kontoauszug_${property.name}_${property.address.streetName}.pdf`;
                        link.click();
                        this.isDownloading = false;
                    },
                    error: (e) => {
                        this.toastService.showError(e.message);
                        this.isDownloading = false;
                    },
                });
        }
    }

    private createRow(item: AccountDto): TableItem[] {
        const link = `/accounting/ledger/${this.ledger?.id}/account-charts/${item.id}`;

        return [
            {
                template: CellTemplate.dynamicComponentItem,
                data: {
                    label: item.name,
                    extraData: {
                        component: TextWithTooltipComponent,
                        wrapperClass: 'data-table-link',
                        componentData: {
                            label: item.name,
                            tooltipInnerHtml: item.toolTip,
                            tooltipKey: TooltipKey.tooltipsInAccounting,
                            labelClass: 's-body-14-22-semibold',
                            linkParams: { routerLink: link, linkClass: 'data-table-link' },
                        },
                    },
                },
            },
            {
                data: {
                    label: item.description || '–',
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: '',
                    link,
                    extraData: { account: item },
                },
                template: CellTemplate.apportionableLabel,
            },
            {
                data: {
                    label: item.balance,
                    link,
                },
                template: CellTemplate.EuroCent,
            },
            {
                data: {
                    label: '',
                    extraData: { account: item, ledgerId: this.ledger?.id || '' },
                },
                template: CellTemplate.accountChartsButtons,
            },
        ];
    }

    public openAddAccount(data: any): void {
        this.openOverlayAndRefreshOnSave$(AddAccountFormComponent, { data }).subscribe();
    }

    public openOpeningBalanceOverlay(): void {
        const isLedgerMVorSEV =
            this.ledger?.type === Property.PropertyTypeEnum.Mv || this.ledger?.type === Property.PropertyTypeEnum.Sev;

        this.openOverlayAndRefreshOnSave$(OpeningBalanceComponent, {
            data: { ledgerId: this.ledger!.id, isLedgerMVorSEV: isLedgerMVorSEV },
        }).subscribe();
    }

    public openAddReserveOverlay(): void {
        this.openOverlayAndRefreshOnSave$(AddReserveFormComponent, {
            data: { ledgerId: this.ledger!.id },
        }).subscribe();
    }

    public openOverlayAndRefreshOnSave$ = (
        ...openParams: Parameters<OverlayService['open']>
    ): Observable<string | void> => {
        const ref = this.overlayService.open(...openParams);
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

        return ref.saveEmitter$.pipe(
            tap(() => this.refresh$.next(null)),
            takeUntil(this.unsubscribe$)
        );
    };

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public tooltipKey = TooltipKey;
}
