import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, switchMap, takeUntil, tap } from 'rxjs';
import { getPropertyTypeInfos } from 'src/app/core/utils/common';
import { PersonLocalService } from 'src/app/features/property/services/person-local.service';
import { LedgerDto, Payment, PaymentsService } from 'src/app/generated-sources/accounting';
import { OwnershipName, Person, Property } from 'src/app/generated-sources/base';
import { TableModel } from 'src/app/shared/table/interfaces/table-model';
import { OverlayService } from '../../../../shared/overlay/services/overlay.service';
import { CellTemplate } from '../../../../shared/table/enums/cell-template';
import { TableItem } from '../../../../shared/table/interfaces/table-item';
import { AddLedgerFormComponent } from '../add-ledger-form/add-ledger-form.component';
import {
    LedgerCustomService,
    LedgerDtoWithExtendedProperty,
    PropertyWithExtendedName,
} from '../services/ledger-custom.service';

interface PropertyWithLedger {
    property: Property;
    ledger: LedgerDto;
}

@Component({
    selector: 'app-accounting-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();
    private ledgers: LedgerDtoWithExtendedProperty[] = [];
    private properties: Property[] = [];

    public isLoading = false;
    public isPropertyManager = false;
    public tableModel: TableModel[] = [{ data: [], header: [] }];
    public tableHeader: string[] = [];
    public sevOwnerships: OwnershipName[] = [];

    public openPayments = 0;
    public payments: Payment[] = [];

    //tableModel[0] => all properties
    //tableModel[1] => web properties
    //tableModel[2] => mv properties
    //tableModel[3] => sev properties

    public contextWEG = {
        title: 'Immobilien',
        tagColor: 'green',
        tagTitle: 'WEG',
    };

    public contextMV = {
        title: 'Immobilien',
        tagColor: 'purple',
        tagTitle: 'MV',
    };

    public contextSEV = {
        title: 'Einheiten',
        tagColor: 'orange',
        tagTitle: 'SEV',
    };

    @ViewChild('customTabHeading', { static: true })
    public customTabHeading?: TemplateRef<any>;

    @ViewChild('propertyTable', { static: true })
    public propertyTable?: TemplateRef<any>;

    public constructor(
        private overlayService: OverlayService,
        private personLocalService: PersonLocalService,
        private ledgerCustomService: LedgerCustomService,
        private router: Router,
        private paymentsService: PaymentsService
    ) {}

    public ngOnInit(): void {
        this.isLoading = true;
        this.initTableHeader();

        this.ledgerCustomService
            .getLedgersWithSevOwnershipsNames()
            .pipe(
                tap((ledgers) => {
                    this.ledgers = ledgers;
                }),
                switchMap(() => this.ledgerCustomService.getSevOwnershipsNames()),
                tap((ownerships) => {
                    this.sevOwnerships = ownerships;
                }),
                switchMap(() => this.personLocalService.getExtendedPerson$()),
                tap((person) => {
                    if (person) {
                        this.isPropertyManager = person.personRoles.includes('IS_PROPERTY_MANAGER');
                    }
                }),
                switchMap(() => this.paymentsService.findAll('OPEN')),
                tap((payments) => {
                    this.openPayments = payments.length;
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => {
                const sev: LedgerDtoWithExtendedProperty[] = [];
                const mv: LedgerDtoWithExtendedProperty[] = [];
                const weg: LedgerDtoWithExtendedProperty[] = [];
                const all: LedgerDtoWithExtendedProperty[] = [];

                this.ledgers.map((propertyWithLedger) => {
                    switch (propertyWithLedger.type) {
                        case 'SEV':
                            sev.push(propertyWithLedger);
                            break;
                        case 'MV':
                            mv.push(propertyWithLedger);
                            break;
                        case 'WEG':
                            weg.push(propertyWithLedger);
                            break;
                        default:
                            all.push(propertyWithLedger);
                            break;
                    }

                    all.push(propertyWithLedger);
                });

                const sevTableModel = {
                    data: sev.map((p) => this.createRow(p)),
                    header: [],
                };

                const mvTableModel = {
                    data: mv.map((p) => this.createRow(p)),
                    header: [],
                };

                const wegTableModel = {
                    data: weg.map((p) => this.createRow(p)),
                    header: [],
                };

                const allTableModel = {
                    data: all.map((p) => this.createRow(p)),
                    header: [],
                };

                this.tableModel[0] = allTableModel;
                this.tableModel[1] = wegTableModel;
                this.tableModel[2] = mvTableModel;
                this.tableModel[3] = sevTableModel;

                this.isLoading = false;
            });
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public openAddLedgerOverlay(): void {
        const ref = this.overlayService.open(AddLedgerFormComponent, {});
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.ledgerCustomService.refresh());
    }

    public navigateToPaymentApprovalsOverview(): void {
        this.router.navigate(['/accounting', 'payment-approvals']);
    }

    private initTableHeader(): void {
        this.tableHeader = [
            'ENTITIES.PROPERTY.LABEL_IMG_FULL',
            'ENTITIES.PROPERTY.LABEL_NAME',
            'ENTITIES.ADDRESS.LABEL_STREET_AND_NUMBER',
            'ENTITIES.ADDRESS.LABEL_ZIP_CODE',
            'ENTITIES.ADDRESS.LABEL_AREA',
            'ENTITIES.PROPERTY.LABEL_MANAGEMENT_TYPE',
        ];
    }

    public filterDataByPropertyType(propertyType: Property.PropertyTypeEnum): TableItem[][] {
        if (propertyType === Property.PropertyTypeEnum.Weg) {
            return this.tableModel[1]?.data;
        } else if (propertyType === Property.PropertyTypeEnum.Mv) {
            return this.tableModel[2]?.data;
        } else if (propertyType === Property.PropertyTypeEnum.Sev) {
            return this.tableModel[3]?.data;
        } else {
            return this.tableModel[0]?.data;
        }
    }

    private getLink(data: LedgerDto): string {
        const property = data.property as Property;
        const isPropertyManager = Object.values(property.permissionRoles).includes(
            Person.PermissionRolesEnum.PropertyManager
        );
        const ledgerType = data.type;
        //property manager lands on dashboard
        //other roles lands on economic plans for weg and operations expense statements for mv and sev
        if (isPropertyManager) {
            return `/accounting/ledger/${data.id}/dashboard`;
        }
        if (ledgerType === LedgerDto.TypeEnum.Weg) {
            return `/accounting/ledger/${data.id}/economic-plans`;
        }
        if ([LedgerDto.TypeEnum.Mv, LedgerDto.TypeEnum.Sev].includes(ledgerType)) {
            return `/accounting/ledger/${data.id}/operations-expense-statements`;
        }
        console.warn('GET LINK ERROR, CASE NOT FOUND');
        return '/accounting';
    }

    private createRow(data: LedgerDtoWithExtendedProperty): TableItem[] {
        const link = this.getLink(data);
        const property = data.property as PropertyWithExtendedName;
        const propertyName = property.nameWithOwnerships;

        return [
            {
                data: {
                    label: property.imgSmall ? property.imgSmall : '',
                    link,
                },
                template: CellTemplate.Image,
            },
            {
                data: {
                    label: propertyName,
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: `${property.address.streetName} ${property.address.streetNumber}`,
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: property.address.zipCode,
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: property.address.area,
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: data.type,
                    textColor: getPropertyTypeInfos(data.type).color,
                    link,
                },
                template: CellTemplate.contentWithTagStyle,
            },
        ];
    }
}
