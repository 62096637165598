import { Injectable } from '@angular/core';
import { OpenItemDto } from 'src/app/generated-sources/accounting';

@Injectable({
    providedIn: 'root',
})
export class OpenItemsCustomService {
    public isDateInPast(dueStringDate: string): boolean {
        const dueDate = new Date(dueStringDate);
        const now = new Date();
        return now >= dueDate;
    }

    public isDue({ dueStringDate, state }: { dueStringDate: string; state: OpenItemDto['state'] }): boolean {
        const dateInPast = this.isDateInPast(dueStringDate);
        // FIXME: OpenItemDto['state'] in frontend do not match one in the backend, need hack to work around
        return dateInPast && state !== 'CLOSED';
    }
}
