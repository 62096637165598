import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject, combineLatest, of, switchMap, takeUntil, tap } from 'rxjs';
import { WegSettlementStatementsService, WssDistributionKeyDto, WssDto } from 'src/app/generated-sources/accounting';
import { Person } from 'src/app/generated-sources/base';
import { Property } from 'src/app/generated-sources/base/model/property';
import { CellTemplate } from 'src/app/shared/table/enums/cell-template';
import { HeaderTemplate } from 'src/app/shared/table/enums/header-template';
import { TableItem } from 'src/app/shared/table/interfaces/table-item';
import { TableModel } from 'src/app/shared/table/interfaces/table-model';
import { PersonLocalService } from '../../property/services/person-local.service';

@Component({
    selector: 'app-annual-statement-consumption-tab',
    templateUrl: './annual-statement-consumption-tab.component.html',
    styleUrls: ['./annual-statement-consumption-tab.component.scss'],
})
export class AnnualStatementConsumptionTabComponent implements OnInit, OnDestroy, OnChanges {
    public unsubscribe$ = new Subject<void>();
    public refresh$ = new BehaviorSubject<number>(1);
    public isLoading = false;
    public isPropertyManager = false;

    @Input() public ledgerId = '';
    @Input() public wss?: WssDto;
    @Input() public isEditWssOverlay = false;
    @Input() public set triggerRefresh(val: number) {
        this.refresh$.next(val);
    }

    public consumptionDistribution: WssDistributionKeyDto[] = [];
    public tableModel: TableModel = { data: [], header: [] };

    @Output() public openAddDistributionKeyOverlay = new EventEmitter<void>();

    public constructor(
        private translateService: TranslateService,
        private annualStatementsService: WegSettlementStatementsService,
        private personLocalService: PersonLocalService
    ) {}

    public ledgerId$ = new BehaviorSubject<string | null>('');
    public wss$ = new BehaviorSubject<WssDto | null>(null);

    public isPropertyManager$: Observable<Property.PermissionRolesEnum[] | null> = this.personLocalService
        .getRolesOfProperty()
        .pipe(
            tap((roles: Property.PermissionRolesEnum[] | null) => {
                this.isPropertyManager = roles
                    ? Object.values(roles).includes(Person.PermissionRolesEnum.PropertyManager)
                    : false;
            })
        );

    public distributionKeys$: Observable<WssDistributionKeyDto[]> = combineLatest([
        this.ledgerId$,
        this.wss$,
        this.refresh$,
    ]).pipe(
        tap(() => {
            this.isLoading = true;
        }),
        switchMap(([ledgerId, wss, refresh]) => {
            if (ledgerId && wss && refresh) {
                return this.annualStatementsService.getDistributionKeys(this.ledgerId, wss.id, 'CONSUMPTION');
            } else {
                const emptyDistKeys: WssDistributionKeyDto[] = [];
                return of(emptyDistKeys);
            }
        }),
        tap((data) => {
            this.consumptionDistribution = data;
            this.tableModel.data = this.consumptionDistribution.map((item) => {
                return this.createRow(item);
            });
        })
    );

    public ngOnChanges(): void {
        this.ledgerId$.next(this.ledgerId ?? null);
        this.wss$.next(this.wss ?? null);
    }

    public ngOnInit(): void {
        this.initTableHeader();
        combineLatest([this.distributionKeys$, this.isPropertyManager$])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.isLoading = false;
            });
    }

    private initTableHeader(): void {
        this.tableModel.header = [
            'ACCOUNTING.COMMON.DESCRIPTION',
            'ENTITIES.DISTRIBUTION_KEY.LABEL_DISTRIBUTION_BASE',
            'ENTITIES.DISTRIBUTION_KEY.LABEL_DISTRIBUTION_GROUP',
            {
                data: { key: 'ENTITIES.DISTRIBUTION_KEY.LABEL_TOTAL_DISTRIBUTION', params: {} },
                template: HeaderTemplate.RightAligned,
            },
        ];
    }

    private createRow(item: WssDistributionKeyDto): TableItem[] {
        const link = `/accounting/ledger/${this.ledgerId}/annual-statements/${this.wss?.id}/distribution-keys/${item.id}`;

        return [
            {
                data: {
                    label: item.description,
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: this.translateService.instant('ENTITIES.OWNERSHIP.LABEL_' + item?.distributionBase),
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: this.translateService.instant('ENTITIES.DISTRIBUTION_KEY.' + item?.distributionGroup),
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: item.totalDistribution,
                    rightAligned: true,
                    link,
                },
                template: CellTemplate.EuroCent,
            },
        ];
    }

    public openAddConsumptionDistributionKeyOverlay(): void {
        this.openAddDistributionKeyOverlay.emit();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
