/**
 * SCALARA Accounting Module
 * The SCALARA Accounting API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AdditionalParameterDefinitionDto { 
    /**
     * This identifies the parameter. This needs to be used when passing a parameter value to POST
     */
    name: string;
    /**
     * This specifies what kind of parameter this is. This value can be either SingleSelect or MultiSelect
     */
    style: AdditionalParameterDefinitionDto.StyleEnum;
    /**
     * This specifies (for type=SimpleInput) what datatypes are accepted. Current only \'date\' is supported
     */
    dataType?: string;
    /**
     * This a display name for this property.
     */
    label: string;
    /**
     * This lists the valid options for this value. It is a list of objects. The structure of the objects can differ for different guidedbookingtypes. When calling POST only the id of the objects needs to be passed. (So whatever kinds of objects the list contains, it will always have an id property)
     */
    valueOptions?: Array<object>;
    /**
     * The valueOptions of this parameter can only be determined, if the following parameters are provided first. In order to retrieve the valueOptions call /ledgers/{ledgerId}/guidedbookings/types/{id}/parameters/{parameterName}
     */
    requiredInputParameters?: Array<string>;
    /**
     * If true, then this parameter is not required to be provided when creating a guided booking.
     */
    isOptional?: boolean;
}
export namespace AdditionalParameterDefinitionDto {
    export type StyleEnum = 'SINGLE_SELECT' | 'MULTI_SELECT' | 'SIMPLE_INPUT';
    export const StyleEnum = {
        SingleSelect: 'SINGLE_SELECT' as StyleEnum,
        MultiSelect: 'MULTI_SELECT' as StyleEnum,
        SimpleInput: 'SIMPLE_INPUT' as StyleEnum
    };
}


