<div class="tw-mt-4 tw-mb-8 tw-flex tw-w-full tw-justify-end">
    <app-scalara-button variant="ghost" (click)="openAddBankAccountOverlay()" svgName="24_add.svg">
        {{ 'PAGES.BANK_ACCOUNTS.LABEL_ADD_NEW_BANK_ACCOUNT' | translate }}
    </app-scalara-button>
</div>

<app-bank-connection-expired-notification [bankAccounts]="this.bankAccounts"></app-bank-connection-expired-notification>

<div class="tw-flex tw-gap-6 tw-flex-wrap">
    <div *ngFor="let bankAccount of bankAccounts">
        <div
            [routerLink]="'/accounting/ledger/' + bankAccount.ledgerIds + '/bank-accounts/' + bankAccount.id"
            class="tile tw-w-[372px] tw-h-[204px] tw-p-4 tw-flex tw-flex-col tw-justify-between"
        >
            <div class="tw-flex tw-justify-between tw-items-center">
                <div
                    class="tw-bg-scalaraGray-07 tw-mr-4 tw-w-[72px] tw-h-[72px] tw-flex tw-items-center tw-justify-center"
                >
                    <img
                        *ngIf="bankAccount.bankLogoUrl"
                        [src]="bankAccount.bankLogoUrl"
                        alt=""
                        class="tw-max-w-full tw-max-h-full tw-p-2"
                    />
                    <svg-icon
                        *ngIf="!bankAccount.bankLogoUrl"
                        class="tw-fill-scalaraGray-04 tw-h-10 tw-w-10"
                        src="/assets/icons/40_bank.svg"
                    ></svg-icon>
                </div>
                <div class="tw-s-head-mobile-24-32-bold">{{ bankAccount.balance || 0 | eurocent }}€</div>
            </div>

            <div>
                <div class="tw-mb-1 tw-s-label-12-22-semibold s-gray-03">{{ bankAccount.bankName }}</div>
                <div class="tw-s-head-14-22-bold">{{ bankAccount.iban | ibanFormatter }}</div>
            </div>

            <div class="tw-flex tw-justify-between">
                <div class="tw-s-body-14-22-regular">{{ getBankAccountTypeName(bankAccount) }}</div>
                <div class="formated-icon" *ngIf="bankAccount.type !== 'ManuallyAddedBankAccount'">
                    <svg-icon
                        *ngIf="!bankAccount.dataOutdated; else attentionIcon"
                        class="tw-fill-scalaraGreen-01"
                        src="/assets/icons/24_cloud-connection.svg"
                    ></svg-icon>
                    <ng-template #attentionIcon>
                        <app-tooltip position="left">
                            <div content class="s-gray-01 tooltip-content p-2r">
                                {{ 'PAGES.BANK_ACCOUNTS.TOOLTIP_DATA_OUTDATED' | translate }}
                            </div>

                            <div hoverable-item>
                                <svg-icon class="tw-fill-scalaraRed-01" src="/assets/icons/24_attention.svg"></svg-icon>
                            </div>
                        </app-tooltip>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>
