<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<div class="main-container{{ this.flow === 'UPDATE' ? '--no-icon' : '' }}">
    <app-overlay-container
        [headlineTitle]="this.labels.titles[this.flow]"
        containerSize="small"
        *ngIf="!isLoading && this.flow !== 'none'"
        [sizeIcon]="72"
        [image]="imageSrc"
        colorIcon="red"
    >
        <div class="s-head-16-24-bold">
            {{ 'PAGES.ABO.' + this.flow + '.UPDATE_MESSAGE1' | translate }}
        </div>
        <div class="mt-1-5r s-body-14-22-regular">
            {{ 'PAGES.ABO.' + this.flow + '.UPDATE_MESSAGE2' | translate }}
        </div>
        <form [formGroup]="form">
            <div class="mt-1-5r" *ngIf="this.flow === 'CANCEL'">
                <input
                    [invalid]="controlInFormGroupIsInvalid(this.form, 'validationInput')"
                    ibmText
                    type="validationInput"
                    name="validationInput"
                    id="validationInput"
                    formControlName="validationInput"
                />
            </div>

            <div class="buttons-wrapper mt-5r">
                <app-scalara-button (click)="this.onCancel()" variant="outline" [fullWidth]="true">{{
                    'CONTROLS.BUTTONS.CANCEL' | translate
                }}</app-scalara-button>
                <app-scalara-button
                    type="button"
                    (click)="this.onSubmit()"
                    [fullWidth]="true"
                    class="buttons-wrapper_button--with-padding"
                    [disabled]="isButtonDisabled"
                    [variant]="this.flow === 'CANCEL' ? 'danger' : 'default'"
                    >{{ 'PAGES.ABO.BUTTON_LABEL.' + this.flow | translate }}</app-scalara-button
                >
            </div>
        </form>
    </app-overlay-container>
</div>
