import { Component, Input, OnChanges, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subject, takeUntil, tap } from 'rxjs';
import { formatDateWithoutHhMmSs, getNameFromPerson, isCurrentDateWithinRange } from 'src/app/core/utils/common';
import { RelationUpdateDto } from 'src/app/features/relations/interfaces';
import { OwnerRelationDto, OwnershipsService, PropertiesService } from 'src/app/generated-sources/base';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { OverlayRef } from 'src/app/shared/overlay/utils/overlay-ref';
import { TableModel } from 'src/app/shared/table/interfaces/table-model';
import { CellTemplate } from '../../../shared/table/enums/cell-template';
import { TableItem } from '../../../shared/table/interfaces/table-item';
import { TooltipKey } from '../../account-settings/services/custom-tooltip.service';
import { AddOwnerOverlayComponent } from '../add-owner-overlay/add-owner-overlay.component';
import { DeleteRelationOverlayComponent } from '../delete-relation-overlay/delete-relation-overlay.component';
import { EditRelationOverlayComponent } from '../edit-relation-overlay/edit-relation-overlay.component';

@Component({
    selector: 'app-owners',
    templateUrl: './owners.component.html',
    styleUrls: ['./owners.component.scss'],
})
export class OwnersComponent implements OnChanges, OnInit {
    private unsubscribe$ = new Subject<void>();
    private ownershipId = '';
    private propertyId = '';
    public originalRelation: RelationUpdateDto = {
        personId: '',
        propertyId: '',
        ownershipId: '',
        relationId: '',
        parent: '',
        startDate: new Date(),
    };
    public owners$ = new BehaviorSubject<OwnerRelationDto[]>([]);

    @Input() public isEditable = false;
    @Input() public isMv = false;
    @Input() public tooltipInfo?: { tooltipKey: TooltipKey; tooltipValue: string };

    @ViewChild('actions', { static: true })
    public actions?: TemplateRef<any>;

    @ViewChild('personTags', { static: true })
    public personTags?: TemplateRef<any>;

    public tableModel: TableModel = { data: [], header: [] };

    public constructor(
        private ownershipsService: OwnershipsService,
        private route: ActivatedRoute,
        private overlayService: OverlayService,
        private propertiesService: PropertiesService
    ) {}

    public ngOnInit(): void {
        this.propertyId = String(this.route.parent?.snapshot.paramMap.get('id'));
        this.ownershipId = String(this.route.snapshot.paramMap.get('ownershipId'));

        this.loadOwners();
        this.initTableHeader();
    }

    public ngOnChanges(): void {
        this.owners$.subscribe((owners) => {
            this.tableModel.data = owners?.map((owner) => this.createRow(owner));
        });
    }

    private loadOwners(): void {
        (this.isMv
            ? this.propertiesService.findMVOwners(this.propertyId)
            : this.ownershipsService.findOwnerRelations(this.propertyId, this.ownershipId)
        )
            .pipe(
                tap((owners) => this.owners$.next(owners)),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }

    private createRow(data: OwnerRelationDto): TableItem[] {
        const link = `/contacts/${data.person.id}`;
        let tableLabel = '';

        const transferObject: RelationUpdateDto = {
            personId: data.person.id,
            propertyId: this.propertyId,
            ownershipId: this.ownershipId,
            relationId: data.id ?? '',
            parent: 'ownership',
            startDate: new Date(data.from),
            person: data.person,
            ...(data.to ? { endDate: new Date(data.to) } : undefined),
        };

        if (isCurrentDateWithinRange(data.from, data.to)) {
            tableLabel = 'Aktueller Eigentümer';
        }
        return [
            {
                data: {
                    label: data.person ? getNameFromPerson(data.person) : 'Keine Person gefunden',
                    link,
                    extraData: {
                        isUserRegistered: data.person.hasRegisteredUser,
                        img: data.person.imgSmall,
                    },
                },
                template: data.person.imgSmall ? CellTemplate.imageTableItemWithLabel : CellTemplate.personWithAvatar,
            },
            {
                data: {
                    label: `${formatDateWithoutHhMmSs(data.from)} – ${formatDateWithoutHhMmSs(data.to)}`,
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: tableLabel,
                    link,
                    extraData: {
                        isCurrentOwner: isCurrentDateWithinRange(data.from, data.to),
                        isContactPerson: data.person.isContactPerson,
                    },
                },

                template: this.personTags,
            },
            {
                data: {
                    label: '',
                    extraData: {
                        transferObject,
                        rightAligned: true,
                    },
                },
                template: this.isEditable ? this.actions : CellTemplate.Default,
            },
        ];
    }
    public openAddOwnerOverlay(): void {
        const data = {
            componentParent: 'app-owners',
            extraData: { propertyId: this.propertyId, ownershipId: this.ownershipId },
            isMv: this.isMv,
        };

        const ref = this.overlayService.open(AddOwnerOverlayComponent, { data });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

        this.saveEmittersUpdate(ref);
    }
    private initTableHeader(): void {
        this.tableModel.header = [
            'ENTITIES.PERSON.LABEL_FIRSTNAME_LASTNAME_COMPANY_NAME',
            'ENTITIES.RELATION_WITH_TIMECONSTRAINT.LABEL_FROM_TO',
            '',
            '',
        ];
    }
    private saveEmittersUpdate(ref: OverlayRef): void {
        ref.saveEmitter$.subscribe(() => {
            this.loadOwners();
        });
        ref.cancelEmitter$.subscribe(() => {
            ref.close();
        });
    }
    public openEditRelationOvervlay(rowData: RelationUpdateDto): void {
        const data = {
            extraData: {
                relation: rowData,
            },
        };
        const ref = this.overlayService.open(EditRelationOverlayComponent, { data });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
        this.saveEmittersUpdate(ref);
    }

    public openDeleteRelationOvervlay(rowData: RelationUpdateDto): void {
        const data = {
            relation: rowData,
        };
        const ref = this.overlayService.open(DeleteRelationOverlayComponent, { data });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
        this.saveEmittersUpdate(ref);
    }
}
