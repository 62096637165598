<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<div *ngIf="!isLoading && editMode" class="d-flex edit-mode py-3-5r px-2-5r">
    <svg-icon src="/assets/icons/40_document_edit.svg" class="info-icon mr-1-5r"></svg-icon>
    <div ibmCol [columnNumbers]="{ md: 12, lg: 12 }" class="mr-2r">
        <div class="mt-0-25r mb-1-5r s-head-18-26-bold s-blue-01">
            {{ 'PAGES.ECONOMIC_PLAN.DETAILS.EDIT_MODE_BANNER_TITLE' | translate }}
        </div>
        <div class="s-body-14-22-regular">{{ 'PAGES.ECONOMIC_PLAN.DETAILS.EDIT_MODE_BANNER_TEXT' | translate }}</div>
    </div>
    <app-scalara-button
        *ngIf="isPropertyManager"
        (click)="openActivateEconomicPlanOverlay()"
        variant="ghost"
        class="ml-auto"
        [disabled]="isEditButtonDisabled"
    >
        {{ 'PAGES.ECONOMIC_PLAN.DETAILS.ACTIVATE_PLAN' | translate }}
    </app-scalara-button>
</div>

<div ibmRow class="mt-1-5r mb-3-5r">
    <div ibmCol>
        <ibm-breadcrumb [items]="breadcrumbs" [noTrailingSlash]="true"> </ibm-breadcrumb>
    </div>
    <div ibmCol class="text-right">
        <div class="d-flex justify-content-end">
            <app-scalara-button
                *ngIf="!isLoading && editMode && isPropertyManager"
                class="mr-1-5r"
                [variant]="'ghost'"
                type="button"
                (click)="openDeleteEconomicPlanOverlay()"
                svgName="24_delete.svg"
            >
                {{ 'PAGES.ECONOMIC_PLAN.DETAILS.DELETE_PLAN' | translate }}
            </app-scalara-button>

            <app-scalara-button
                *ngIf="!isLoading && editMode && isPropertyManager"
                type="button"
                (click)="openEditEconomicPlanOverlay()"
                svgName="24_edit.svg"
                [disabled]="isEditButtonDisabled"
            >
                {{ 'PAGES.ECONOMIC_PLAN.DETAILS.EDIT_PLAN' | translate }}
            </app-scalara-button>

            <app-scalara-button
                *ngIf="!isLoading && economicPlan?.status === 'ACTIVE'"
                (click)="resetEconomicPlan()"
                variant="ghost"
                svgName="24_error.svg"
            >
                {{ 'PAGES.ECONOMIC_PLAN.DETAILS.RESET_PLAN' | translate }}
            </app-scalara-button>
        </div>
    </div>
</div>

<div ibmRow *ngIf="!isLoading">
    <div ibmCol>
        <div class="s-label-12-22-semibold mb-0-5r">{{ 'ENTITIES.ECONOMIC_PLAN.LABEL_YEAR' | translate }}</div>
        <div class="d-flex mb-6r">
            <div class="s-head-28-36-bold">
                {{ economicPlan?.startDate || '' | dateFormat }} – {{ economicPlan?.endDate || '' | dateFormat }}
            </div>
            <div
                *ngIf="economicPlan && economicPlan.isAutoFollowUpPlan"
                class="follow-up d-flex tw-ml-[30px] align-items-center"
            >
                <div
                    class="d-flex"
                    [ngClass]="{
                        's-gray-04': economicPlan.status === 'OUTDATED',
                        's-blue-01': economicPlan.status === 'ACTIVE'
                    }"
                >
                    <svg-icon src="/assets/icons/24_dokument.svg"></svg-icon>
                    <div
                        class="s-label-12-22-semibold ml-0-5r tw-mr-3"
                        [ngClass]="{
                            's-gray-04': economicPlan.status === 'OUTDATED',
                            's-blue-01': economicPlan.status === 'ACTIVE'
                        }"
                    >
                        {{ 'PAGES.ECONOMIC_PLAN.OVERVIEW.LABEL_AUTOMATIC_FOLLOW_UP_PLAN' | translate }}
                    </div>
                </div>
                <app-tooltip position="bottom" class="tooltip--white tile__icon expandable-tile-selector__tooltip">
                    <div content class="tooltip-box tw-w-[450px] tw-px-[48px] tw-py-[32px]">
                        {{ 'PAGES.ECONOMIC_PLAN.OVERVIEW.INFO_AUTOMATIC_FOLLOW_UP_PLAN' | translate }}
                    </div>
                    <div hoverable-item>
                        <svg-icon src="/assets/icons/24_information.svg"></svg-icon>
                    </div>
                </app-tooltip>
            </div>
        </div>
    </div>
</div>
<ibm-tabs [followFocus]="true" [cacheActive]="true">
    <ibm-tab heading="{{ 'ENTITIES.ECONOMIC_PLAN.LABEL_PLAN_TYPE_TOTAL' | translate }}">
        <div class="pt-4r">
            <app-weg-economic-plan-details-tab
                [triggerRefresh]="this.triggerTabRefreshValue"
                [ownerships]="ownerships"
                [economicPlanDetails]="economicPlanDetails"
                *ngIf="economicPlanDetails$ | async as economicPlanDetails"
            ></app-weg-economic-plan-details-tab>
        </div>
    </ibm-tab>
    <ibm-tab
        heading="{{ 'ENTITIES.ECONOMIC_PLAN.LABEL_PLAN_TYPE_SINGLE' | translate }}"
        (selected)="onTabSelected('individualEconomicPlanTab')"
    >
        <div *ngIf="tabsToShow.includes('individualEconomicPlanTab')">
            <ng-container *ngIf="showDetailsOrOverview$ | async as showDetailsOrOverview">
                <ng-container *ngIf="showDetailsOrOverview === 'detail'">
                    <app-weg-economic-plan-individual-plan-tab
                        *ngIf="selectedOwnership$ | async as selectedOwnership"
                        [triggerRefresh]="this.triggerTabRefreshValue"
                        [selectedOwnership]="selectedOwnership"
                        [economicPlanType]="economicPlanType"
                        (handleIndividualEconomicPlanDetailPage)="handleIndividualEconomicPlanDetailPage($event)"
                    ></app-weg-economic-plan-individual-plan-tab
                ></ng-container>

                <ng-container *ngIf="showDetailsOrOverview === 'overview'">
                    <app-weg-economic-plan-individual-plan-index
                        *ngIf="rateInterval$ | async as rateInterval"
                        (handleIndividualEconomicPlanDetailPage)="handleIndividualEconomicPlanDetailPage($event)"
                        [rateInterval]="rateInterval"
                    ></app-weg-economic-plan-individual-plan-index
                ></ng-container>
            </ng-container>
        </div>
    </ibm-tab>
    <ibm-tab
        heading="{{ 'ENTITIES.ECONOMIC_PLAN.LABEL_DISTRIBUTION_KEYS' | translate }}"
        (selected)="onTabSelected('distributionKeysTab')"
    >
        <ng-container *ngIf="tabsToShow.includes('distributionKeysTab')">
            <app-weg-economic-plan-distribution-keys [editMode]="editMode"></app-weg-economic-plan-distribution-keys>
        </ng-container>
    </ibm-tab>
</ibm-tabs>
