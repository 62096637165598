<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>
<div class="lg:tw-grid tw-grid-cols-4 tw-mb-20 tw-p-8">
    <div class="tw-col-start-2 tw-col-end-4">
        <div class="tw-flex headline-wrapper tw-mb-16">
            <div class="tw-mr-6 headline-wrapper__icon">
                <app-icon-bubble [size]="72" color="green" image="40_upload.svg"></app-icon-bubble>
            </div>
            <div class="headline-wrapper__title">
                <div class="s-head-28-36-bold">
                    {{ 'PAGES.PROPERTY.ADD_PROPERTY.LABEL_IMAGE_ADD' | translate }}
                </div>
            </div>
        </div>

        <ng-container *ngIf="!uploadedImg">
            <app-file-input
                inputLabel="{{ 'PAGES.PROPERTY.ADD_PROPERTY.LABEL_IMAGE_ADD_LONG' | translate }}"
                [acceptFormats]="['.png', '.jpg']"
                (updateUploadedFile)="fileChangeEvent($event)"
            ></app-file-input>
        </ng-container>

        <div *ngIf="uploadedImg">
            <image-cropper
                [imageChangedEvent]="uploadedImg"
                [maintainAspectRatio]="true"
                [aspectRatio]="1 / 1"
                format="jpeg"
                alignImage="left"
                [resizeToHeight]="379"
                [resizeToWidth]="505"
                (imageCropped)="imageCropped($event)"
                [roundCropper]="true"
            ></image-cropper>
        </div>

        <div *ngIf="uploadedImg">
            <app-scalara-button (click)="removeImg()" variant="ghost" height="48px">
                {{ 'PROPERTY.DETAIL.FOTO_REMOVE' | translate }}
            </app-scalara-button>
        </div>

        <div class="d-flex justify-space-between tw-mt-[120px]">
            <app-scalara-button (click)="abort()" variant="outline">
                {{ 'CONTROLS.BUTTONS.CANCEL' | translate }}
            </app-scalara-button>

            <app-scalara-button (click)="submit()" variant="default">
                {{ 'CONTROLS.BUTTONS.SAVE' | translate }}
            </app-scalara-button>
        </div>
    </div>
</div>
