import { Injector, Type, InjectionToken, InjectFlags } from '@angular/core';

export class OverlayInjector implements Injector {

    public constructor(
        private parentInjector: Injector,
        private additionalTokens: WeakMap<any, any>
    ) { }

    public get<T>(token: Type<T> | InjectionToken<T>, notFoundValue?: T, flags?: InjectFlags): T;
    public get(token: any, notFoundValue?: any): any;
    public get(token: any, notFoundValue?: any, flags?: any): any {
        const value = this.additionalTokens.get(token);
        return value || this.parentInjector.get<any>(token, notFoundValue);
    }
}
