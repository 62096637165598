<ibm-loading *ngIf="isLoading" [isActive]="true" [size]="'normal'" [overlay]="true"></ibm-loading>

<form [formGroup]="form">
    <div class="mb-1r s-body-14-22-bold">Werte der umlagefähigen Kosten aus der WEG Jahresabrechnung übertragen</div>
    <div class="mb-2-5r s-body-14-22-regular">
        Sie können hier die Gesamtsumme der umlagefähigen Kosten für diese Einheit erfassen oder aber die einzelnen
        Werte der jeweiligen Kostenarten, wenn Sie diese ggf. weiter aufteilen möchten.
    </div>

    <ng-container formArrayName="accounts">
        <div *ngFor="let occupationGroup of accountsControl.controls; let i = index" class="mb-2-5r">
            <ng-container *ngIf="i > 0">
                <hr class="mt-2r" />
                <div class="d-flex justify-content-end">
                    <app-scalara-button
                        (click)="removeAccount(i)"
                        variant="ghost"
                        svgName="24_close.svg"
                        height="40px"
                    ></app-scalara-button>
                </div>
            </ng-container>
            <ng-container [formGroupName]="i">
                <div class="d-flex justify-space-between">
                    <ibm-label invalidText="{{ 'ERROR.REQUIRED' | translate }}">
                        <app-combo-box
                            id="selectedAccount"
                            placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT_2' | translate }}"
                            label="Kostenart*"
                            [items]="occupationGroup.value?.accountList || []"
                            formControlName="selectedAccount"
                            invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                        >
                            <ibm-dropdown-list></ibm-dropdown-list>
                        </app-combo-box>
                    </ibm-label>

                    <app-number-input
                        label="Betrag in €*"
                        invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                        formControlName="amount"
                        class="input-small ml-2r"
                    ></app-number-input>
                </div>
            </ng-container>
        </div>
        <div class="d-flex justify-space-between">
            <app-scalara-button (click)="addNewAccount()" variant="ghost" svgName="24_add.svg"
                >Weitere Kostenart</app-scalara-button
            >
            <div class="d-flex">
                <div class="s-head-14-22-bold mr-6r">Gesamtsumme</div>
                <div class="s-head-14-22-bold ml-1-5r">{{ sumTotal() | eurocent }}</div>
            </div>
        </div>
    </ng-container>
</form>

<div class="d-flex justify-space-between mt-7-5r">
    <app-scalara-button (click)="abort()" [variant]="'outline'">
        {{ 'CONTROLS.BUTTONS.BACK' | translate }}
    </app-scalara-button>

    <div class="d-flex">
        <div class="mr-1-5r">
            <app-scalara-button [disabled]="isInvalidForm()" (click)="submit(true)" [variant]="'ghost'" height="48px">
                Speichern & schließen
            </app-scalara-button>
        </div>

        <app-scalara-button [disabled]="isInvalidForm()" variant="default" (click)="submit()">
            Speichern & weiter
        </app-scalara-button>
    </div>
</div>
