import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, filter, map, ObservedValueOf, shareReplay, Subject, switchMap, takeUntil } from 'rxjs';
import { SettlementResultsOverviewDto, WegSettlementStatementsService } from 'src/app/generated-sources/accounting';
import {
    CellData,
    Column,
    RowData,
} from 'src/app/shared/components/simple-table/simple-table-row/simple-table-row.component';
import { CustomWssService } from '../custom-wss.service';

type ColumnKey = keyof SettlementResultsOverviewDto['settlementResults'][number];
type TableData = {
    columns: Column<ColumnKey>[];
    rows: RowData<ColumnKey>[];
};

@Component({
    selector: 'app-billing-results-overview',
    templateUrl: './billing-results-overview.component.html',
    styleUrls: ['./billing-results-overview.component.scss'],
})
export class BillingResultsOverviewComponent implements OnInit, OnDestroy {
    public constructor(
        public wssService: WegSettlementStatementsService,
        public customWssService: CustomWssService,
        public datePipe: DatePipe
    ) {}

    public unsubscribe$ = new Subject<void>();
    public ledgerId$ = this.customWssService.getLedgerId$().pipe(filter(Boolean));
    public wss$ = this.customWssService.getWss$().pipe(filter(Boolean));

    public settlementResultsOverview$ = combineLatest([this.ledgerId$, this.wss$]).pipe(
        switchMap(([ledgerId, wss]) => {
            return this.wssService.getSettlementResultsOverview(ledgerId, wss.id);
        }),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    public table$ = combineLatest([this.settlementResultsOverview$]).pipe(
        map(([settlementResultsOverview]) => {
            const tableData: TableData = {
                columns: [
                    { key: 'ownershipName', label: 'Name' },
                    { key: 'costAllocationAmount', label: 'Kostentragung gem. Einzelabrechnung  in €' },
                    {
                        key: 'advancementAmount',
                        label: 'abzgl. beschlossener Vorschuss gem. Einzelwirtschaftsplan Kostentragung in €',
                    },
                    {
                        key: 'costAllocationAndAdvanceAdjustment',
                        label: 'Beschlussgegenstand Kostentragung Nachschuss/Anpassung der Vorschüsse in €',
                    },
                ],
                rows: [
                    { cells: [{ key: 'advancementAmount', type: 'value', value: '', rowCellClass: '[&&]:tw-h-6' }] },
                    ...settlementResultsOverview.settlementResults.map((settlementResult) => {
                        const row: RowData<ColumnKey> = {
                            cells: [
                                { key: 'ownershipName', type: 'value', value: settlementResult.ownershipName },
                                ...[
                                    'costAllocationAmount',
                                    'advancementAmount',
                                    'costAllocationAndAdvanceAdjustment',
                                ].map(
                                    (key): CellData<ColumnKey> => ({
                                        key: key as ColumnKey,
                                        type: 'value',
                                        value: settlementResult[key as ColumnKey],
                                        pipeToApply: 'eurocent',
                                    })
                                ),
                            ],
                        };
                        return row;
                    }),
                    {
                        cells: [{ key: 'advancementAmount', type: 'value', value: '', rowCellClass: '[&&]:tw-h-10' }],
                        rowClass: '[&&]:tw-border-b [&&]:tw-border-solid [&&]:tw-border-scalaraGray-01',
                    },
                    //render summaries
                    {
                        cells: [
                            { key: 'ownershipName', type: 'value', value: 'Summe' },
                            ...['costAllocationAmount', 'advancementAmount', 'costAllocationAndAdvanceAdjustment'].map(
                                (key): CellData<ColumnKey> => ({
                                    key: key as ColumnKey,
                                    type: 'value',
                                    value: settlementResultsOverview.settlementResults.reduce(
                                        (acc, curr) => acc + (curr[key as ColumnKey] as number),
                                        0
                                    ),
                                    pipeToApply: 'eurocent',
                                })
                            ),
                        ],
                        rowClass: '[&&]:tw-h-10 [&&]:tw-font-bold',
                    },
                ],
            };
            return tableData;
        })
    );

    public vm$ = combineLatest([this.ledgerId$, this.wss$, this.settlementResultsOverview$, this.table$]).pipe(
        map(([ledgerId, wss, settlementResultsOverview, tableData]) => ({
            ledgerId,
            wss,
            settlementResultsOverview,
            tableData,
        }))
    );
    public vm: ObservedValueOf<typeof this.vm$> | null = null;

    public ngOnInit(): void {
        this.vm$.pipe(takeUntil(this.unsubscribe$)).subscribe((vm) => {
            this.vm = vm;
            console.log({ vm });
        });
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
