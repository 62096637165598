import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { Payment, PaymentsService } from 'src/app/generated-sources/accounting';

@Injectable({
    providedIn: 'root',
})
export class PaymentCustomService {
    public constructor(@Inject(DOCUMENT) private document: Document, private paymentsService: PaymentsService) {}

    public approvePayment(
        paymentsIdsToApprove: string,
        bankAccountId: string,
        paymentType: Payment.TypeEnum
    ): Observable<any> {
        return (
            paymentType === 'DIRECT_DEBIT'
                ? this.paymentsService.approveDirectDebitsForBankAccount(bankAccountId, paymentsIdsToApprove)
                : this.paymentsService.approveMoneyTransfersForBankAccount(bankAccountId, paymentsIdsToApprove)
        ).pipe(
            tap((response) => {
                const ownUrlPath = `${window.location.origin}${window.location.pathname}`;
                const webformIdParam = `webformId=${response.webformId}`;
                const update = `update=${true}`;
                const redirectUrlParam = `redirectUrl=${ownUrlPath}?${encodeURIComponent(
                    webformIdParam + '&' + update
                )}`;
                const errorUrlParam = `errorRedirectUrl=${ownUrlPath}?${encodeURIComponent(
                    webformIdParam + '&' + update
                )}`;
                const abortRedirectUrl = `abortRedirectUrl=${ownUrlPath}?${encodeURIComponent(
                    webformIdParam + '&' + update
                )}`;
                this.document.location.href = `${response.url}?${redirectUrlParam}&${errorUrlParam}&${abortRedirectUrl}`;
            })
        );
    }
}
