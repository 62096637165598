import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, filter, map, ObservedValueOf } from 'rxjs';
import { PersonLocalService } from 'src/app/features/property/services/person-local.service';
import { BankAccount } from 'src/app/generated-sources/base';
import { bankAccountToContactDetailRow } from '../../utils';

@Component({
    selector: 'app-bank-account-item',
    templateUrl: './bank-account-item.component.html',
    styleUrls: ['./bank-account-item.component.scss'],
})
export class BankAccountItemComponent implements OnChanges, OnInit {
    public constructor(
        public translateService: TranslateService,
        public route: ActivatedRoute,
        public personLocalService: PersonLocalService
    ) {}

    @Input() public bankAccount?: BankAccount;
    @Output() public deleteClicked = new EventEmitter<BankAccount>();
    @Input() public showDeleteButton = false;

    public bankAccount$ = new BehaviorSubject<BankAccount | undefined>(undefined);
    public contactDetailRows$ = this.bankAccount$.pipe(
        map((bankAccount) => bankAccountToContactDetailRow(bankAccount, this.translateService))
    );
    public routePersonId$ = this.route.paramMap.pipe(
        map((paramMap) => {
            const personId = paramMap.get('personId');
            return personId ?? null;
        }),
        filter(Boolean)
    );
    public currentUserPersonId$ = this.personLocalService.getPerson$().pipe(map((p) => p.id));
    public isOwnProfile$ = combineLatest([this.routePersonId$, this.currentUserPersonId$]).pipe(
        map(([p1, p2]) => p1 === p2)
    );

    public vm$ = combineLatest([this.bankAccount$, this.contactDetailRows$, this.isOwnProfile$]).pipe(
        map(([bankAccount, contactDetailRows, isOwnProfile]) => {
            return {
                bankAccount,
                contactDetailRows,
                isOwnProfile,
            };
        })
    );

    public vm: ObservedValueOf<typeof this.vm$> | null = null;

    public onDeleteClick(bankAccount: ObservedValueOf<typeof this.bankAccount$>): void {
        if (!bankAccount) {
            console.warn('no bank account');
            return;
        }
        this.deleteClicked.emit(bankAccount);
    }

    public ngOnInit(): void {
        this.vm$.subscribe((vm) => {
            this.vm = vm;
        });
    }

    public ngOnChanges(): void {
        this.bankAccount$.next(this.bankAccount);
    }
}
