<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>
<div class="d-flex flex-direction-column w-100 comments-container pb-5r">
    <div class="mb-2r">
        <app-headline-with-icon
            color="green"
            image="24_chat.svg"
            text="{{ 'PAGES.TICKET_DETAILS.COMMENTS' | translate }}"
        ></app-headline-with-icon>
    </div>

    <div class="mb-1r">
        <ibm-label class="mb-1r">
            {{ 'PAGES.TICKET_DETAILS.WRITE_COMMENT' | translate }}
            <textarea
                ibmTextArea
                rows="3"
                aria-label="textarea"
                maxlength="100000"
                [formControl]="description"
            ></textarea>
        </ibm-label>

        <app-file-uploader
            [showButtonFromParent]="true"
            (filesLoaded)="updateLoadingFilesStatus($event)"
            (fileIdsLoaded)="updateFileIdsLodaded($event)"
            [validFiles]="[
                'jpg',
                'jpeg',
                'png',
                'pdf',
                'PDF',
                'JPG',
                'PNG',
                'JPEG',
                'eml',
                'emlx',
                'msg',
                'EML',
                'EMLX',
                'MSG'
            ]"
        >
            <app-scalara-button
                [disabled]="!description.value || !areFilesFullyUploaded"
                (click)="onSubmit()"
                height="40px"
                >Senden</app-scalara-button
            >
        </app-file-uploader>
    </div>

    <div *ngIf="comments.length < 1" class="empty d-flex align-items-center mt-2r">
        <svg-icon src="/assets/icons/24_information.svg"></svg-icon>
        <div class="s-body-14-22-regular ml-0-75r">{{ 'PAGES.TICKET_DETAILS.NO_COMMENTS' | translate }}</div>
    </div>

    <div class="mt-3r">
        <div class="w-100 mb-3r" *ngIf="commentToEdit">
            <ibm-label class="mb-1r">
                <textarea
                    ibmTextArea
                    rows="3"
                    aria-label="textarea"
                    maxlength="100000"
                    [value]="commentToEdit.description"
                    [formControl]="descriptionTicketToEdit"
                ></textarea>
            </ibm-label>
            <div class="d-flex justify-content-end">
                <app-scalara-button
                    (click)="cancelEditComment()"
                    variant="icon-only"
                    height="40px"
                    svgName="24_close.svg"
                ></app-scalara-button>
                <app-scalara-button
                    variant="icon-only"
                    height="40px"
                    svgName="24_checkbox-check.svg"
                    (click)="saveComment()"
                    [disabled]="!descriptionTicketToEdit.value"
                ></app-scalara-button>
            </div>
        </div>

        <div *ngFor="let comment of comments; let last = last; let first = first" [class.mb-2-5r]="last">
            <div class="d-flex mb-1-5r align-items-end" *ngIf="commentToEdit?.id !== comment.id">
                <div *ngIf="!isOwner(comment.owner.id)" class="mr-0-5r">
                    <app-headline-with-icon
                        color="green"
                        image="24_person.svg"
                        textType="body"
                        textWeight="regular"
                        borderColor="gray"
                    ></app-headline-with-icon>
                </div>
                <div
                    [ngClass]="{ 'comment__green comment__owner ml-3r': isOwner(comment.owner.id) }"
                    class="comment py-1r px-2r w-100"
                >
                    <div
                        class="s-label-12-22-semibold s-gray-03 d-flex flex-direction-column lg-flex-direction-row justify-space-between mb-1r"
                    >
                        {{ getNameFromPerson(comment.owner) }}
                        <span>{{ comment.createdAt | date : 'dd.MM.yyyy – HH:mm' }}</span>
                    </div>
                    <p class="s-body-14-22-regular pre-wrap" [class.mb-2-5r]="!havePermission(comment.permissions)">
                        <ng-container>{{ comment.description }}</ng-container>
                    </p>

                    <div *ngIf="havePermission(comment.permissions)">
                        <div class="comment-actions formated-icon d-flex justify-content-end">
                            <button (click)="deleteComment(comment.id)">
                                <svg-icon src="/assets/icons/24_delete.svg"></svg-icon>
                            </button>

                            <button (click)="editComment(comment)">
                                <svg-icon src="/assets/icons/24_edit.svg"></svg-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <app-scalara-button
            class="d-flex justify-content-center"
            *ngIf="commentsOriginalArray && commentsOriginalArray.length > 8"
            (click)="showAllComments()"
            [variant]="'ghost'"
            svgName="24_arrow-down.svg"
            >{{ 'PAGES.TICKET_DETAILS.SHOW_ALL' | translate }}</app-scalara-button
        >
    </div>
</div>
