<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<div ibmGrid class="bx--grid--full-width booking-selection mb-12">
    <div ibmRow>
        <div ibmCol></div>
        <div ibmCol [columnNumbers]="{ md: 10, lg: 10 }">
            <div ibmRow [ngClass]="['s-head-28-36-bold', 'mb-2-5r']">
                {{ 'ACCOUNTING.ADD_BOOKING_FORM.HEADLINE' | translate }}
            </div>
            <div ibmRow class="success s-body-14-22-regular mb-1-5r">
                {{ 'ACCOUNTING.ADD_BOOKING_SELECTION.DESCRIPTION' | translate }}
            </div>

            <div class="mb-1r" *ngFor="let selector of tileSelectors">
                <app-expandable-tile-selector
                    [selectorTitle]="selector.groupName"
                    [selectorDescription]="selector.groupSubline"
                    [isExpense]="selector.isExpense"
                    [tileSelectorOptions]="selector.options"
                    (optionSelected)="goToSelectedBooking($event)"
                ></app-expandable-tile-selector>
            </div>
        </div>
        <div ibmCol></div>
    </div>
</div>
