/**
 * SCALARA Base Module
 * The SCALARA API for User, Person and Property resources
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Person } from './person';
import { Property } from './property';


export interface ServiceProviderRelationDto { 
    property: Property;
    person: Person;
    serviceProvided: ServiceProviderRelationDto.ServiceProvidedEnum;
    relationType: ServiceProviderRelationDto.RelationTypeEnum;
    id: string;
    from: string;
    to?: string;
    permissionRoles: Array<ServiceProviderRelationDto.PermissionRolesEnum>;
}
export namespace ServiceProviderRelationDto {
    export type ServiceProvidedEnum = 'PLANT_CONSTRUCTION' | 'ARCHITECTUAL_SERVICES' | 'ELEVATOR_MAINTENANCE' | 'CONSTRUCTION_ENGINEERING_SERVICES' | 'CONSTRUCTION_MANAGMENT_SERVICES' | 'ROOFING_WORK' | 'ELECTRICAL_INSTALLATION' | 'ENERGY_CONSULTING' | 'ENERGY_SUPPLY' | 'DISPOSAL' | 'EXCAVATION_WORK' | 'WINDOW_AND_DOOR_CONSTRUCTION' | 'FIRE_ALARM_MAINTENANCE' | 'FIRE_PROTECTION' | 'TILE_AND_SCREED_WORK' | 'GARDENING' | 'CLEANING' | 'SCAFFOLDING' | 'GLASSWORK' | 'CARETAKER' | 'HEATING_CONSTRUCTION' | 'REAL_ESTATE_AGENT' | 'INTERIOR_AND_DRYWALL_CONSTRUCTION' | 'CHIMNEY_BUILDER' | 'SEWER_CONSTRUCTION_AND_RENOVATION' | 'VENTILATION_AND_AIR_CONDITIONING' | 'PAINTING' | 'BRICKLAYING_AND_CONCRETE_WORK' | 'MEASURING_SERVICES' | 'METAL_CONSTRUCTION_AND_FORGING_WORK' | 'PAVING_AND_ROAD_CONSTRUCTION_WORK' | 'PIPELINE_CONSTRUCTION_AND_RENOVATION' | 'SURVEYOR' | 'SANITARY_INSTALLATION' | 'CHIMNEY_SWEEPER' | 'LOCKSMITH' | 'CARPENTRY' | 'SOLAR_SYSTEMS' | 'STONEMASON' | 'STUCCO_ADN_PLASTER_WORK' | 'TELECOMMUNICATIONS_PROVIDER' | 'STAIRCASE_CONSTRUCTION' | 'TUV' | 'PLASTERING_WORK' | 'INSURANCE' | 'INSURANCE_BROKER' | 'WATERWORKS_ADN_WATER_SUPPLY' | 'CHIMNEY_SWEEP' | 'ENERGY_SUPPLIER' | 'PRE_MANEGEMENT' | 'WEG_MANAGEMENT' | 'WASTE_COLLECTOR' | 'OTHER';
    export const ServiceProvidedEnum = {
        PlantConstruction: 'PLANT_CONSTRUCTION' as ServiceProvidedEnum,
        ArchitectualServices: 'ARCHITECTUAL_SERVICES' as ServiceProvidedEnum,
        ElevatorMaintenance: 'ELEVATOR_MAINTENANCE' as ServiceProvidedEnum,
        ConstructionEngineeringServices: 'CONSTRUCTION_ENGINEERING_SERVICES' as ServiceProvidedEnum,
        ConstructionManagmentServices: 'CONSTRUCTION_MANAGMENT_SERVICES' as ServiceProvidedEnum,
        RoofingWork: 'ROOFING_WORK' as ServiceProvidedEnum,
        ElectricalInstallation: 'ELECTRICAL_INSTALLATION' as ServiceProvidedEnum,
        EnergyConsulting: 'ENERGY_CONSULTING' as ServiceProvidedEnum,
        EnergySupply: 'ENERGY_SUPPLY' as ServiceProvidedEnum,
        Disposal: 'DISPOSAL' as ServiceProvidedEnum,
        ExcavationWork: 'EXCAVATION_WORK' as ServiceProvidedEnum,
        WindowAndDoorConstruction: 'WINDOW_AND_DOOR_CONSTRUCTION' as ServiceProvidedEnum,
        FireAlarmMaintenance: 'FIRE_ALARM_MAINTENANCE' as ServiceProvidedEnum,
        FireProtection: 'FIRE_PROTECTION' as ServiceProvidedEnum,
        TileAndScreedWork: 'TILE_AND_SCREED_WORK' as ServiceProvidedEnum,
        Gardening: 'GARDENING' as ServiceProvidedEnum,
        Cleaning: 'CLEANING' as ServiceProvidedEnum,
        Scaffolding: 'SCAFFOLDING' as ServiceProvidedEnum,
        Glasswork: 'GLASSWORK' as ServiceProvidedEnum,
        Caretaker: 'CARETAKER' as ServiceProvidedEnum,
        HeatingConstruction: 'HEATING_CONSTRUCTION' as ServiceProvidedEnum,
        RealEstateAgent: 'REAL_ESTATE_AGENT' as ServiceProvidedEnum,
        InteriorAndDrywallConstruction: 'INTERIOR_AND_DRYWALL_CONSTRUCTION' as ServiceProvidedEnum,
        ChimneyBuilder: 'CHIMNEY_BUILDER' as ServiceProvidedEnum,
        SewerConstructionAndRenovation: 'SEWER_CONSTRUCTION_AND_RENOVATION' as ServiceProvidedEnum,
        VentilationAndAirConditioning: 'VENTILATION_AND_AIR_CONDITIONING' as ServiceProvidedEnum,
        Painting: 'PAINTING' as ServiceProvidedEnum,
        BricklayingAndConcreteWork: 'BRICKLAYING_AND_CONCRETE_WORK' as ServiceProvidedEnum,
        MeasuringServices: 'MEASURING_SERVICES' as ServiceProvidedEnum,
        MetalConstructionAndForgingWork: 'METAL_CONSTRUCTION_AND_FORGING_WORK' as ServiceProvidedEnum,
        PavingAndRoadConstructionWork: 'PAVING_AND_ROAD_CONSTRUCTION_WORK' as ServiceProvidedEnum,
        PipelineConstructionAndRenovation: 'PIPELINE_CONSTRUCTION_AND_RENOVATION' as ServiceProvidedEnum,
        Surveyor: 'SURVEYOR' as ServiceProvidedEnum,
        SanitaryInstallation: 'SANITARY_INSTALLATION' as ServiceProvidedEnum,
        ChimneySweeper: 'CHIMNEY_SWEEPER' as ServiceProvidedEnum,
        Locksmith: 'LOCKSMITH' as ServiceProvidedEnum,
        Carpentry: 'CARPENTRY' as ServiceProvidedEnum,
        SolarSystems: 'SOLAR_SYSTEMS' as ServiceProvidedEnum,
        Stonemason: 'STONEMASON' as ServiceProvidedEnum,
        StuccoAdnPlasterWork: 'STUCCO_ADN_PLASTER_WORK' as ServiceProvidedEnum,
        TelecommunicationsProvider: 'TELECOMMUNICATIONS_PROVIDER' as ServiceProvidedEnum,
        StaircaseConstruction: 'STAIRCASE_CONSTRUCTION' as ServiceProvidedEnum,
        Tuv: 'TUV' as ServiceProvidedEnum,
        PlasteringWork: 'PLASTERING_WORK' as ServiceProvidedEnum,
        Insurance: 'INSURANCE' as ServiceProvidedEnum,
        InsuranceBroker: 'INSURANCE_BROKER' as ServiceProvidedEnum,
        WaterworksAdnWaterSupply: 'WATERWORKS_ADN_WATER_SUPPLY' as ServiceProvidedEnum,
        ChimneySweep: 'CHIMNEY_SWEEP' as ServiceProvidedEnum,
        EnergySupplier: 'ENERGY_SUPPLIER' as ServiceProvidedEnum,
        PreManegement: 'PRE_MANEGEMENT' as ServiceProvidedEnum,
        WegManagement: 'WEG_MANAGEMENT' as ServiceProvidedEnum,
        WasteCollector: 'WASTE_COLLECTOR' as ServiceProvidedEnum,
        Other: 'OTHER' as ServiceProvidedEnum
    };
    export type RelationTypeEnum = 'HAS_PROPERTY' | 'IS_OWNER' | 'IS_ADVISER' | 'IS_SERVICE_PROVIDER' | 'IS_TENANT' | 'IS_RESIDENT' | 'HAS_CONTACT_PERSON';
    export const RelationTypeEnum = {
        HasProperty: 'HAS_PROPERTY' as RelationTypeEnum,
        IsOwner: 'IS_OWNER' as RelationTypeEnum,
        IsAdviser: 'IS_ADVISER' as RelationTypeEnum,
        IsServiceProvider: 'IS_SERVICE_PROVIDER' as RelationTypeEnum,
        IsTenant: 'IS_TENANT' as RelationTypeEnum,
        IsResident: 'IS_RESIDENT' as RelationTypeEnum,
        HasContactPerson: 'HAS_CONTACT_PERSON' as RelationTypeEnum
    };
    export type PermissionRolesEnum = 'PROPERTY_MANAGER' | 'OWNER' | 'SERVICE_PROVIDER' | 'ADVISER' | 'CO_OWNER' | 'TENANT';
    export const PermissionRolesEnum = {
        PropertyManager: 'PROPERTY_MANAGER' as PermissionRolesEnum,
        Owner: 'OWNER' as PermissionRolesEnum,
        ServiceProvider: 'SERVICE_PROVIDER' as PermissionRolesEnum,
        Adviser: 'ADVISER' as PermissionRolesEnum,
        CoOwner: 'CO_OWNER' as PermissionRolesEnum,
        Tenant: 'TENANT' as PermissionRolesEnum
    };
}


