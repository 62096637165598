<div class="tw-bg-scalaraGray-07 tw-border-solid tw-border-scalaraGray-05 tw-p-6">
    <ng-container *ngIf="!currentFile">
        <div class="tw-mb-2 tw-s-head-14-22-bold">{{ pdfTypeTitle }}</div>
        <div class="tw-mb-4 tw-flex tw-items-center tw-space-x-2">
            <svg-icon class="tw-fill-scalaraBlue-01 tw-w-6 tw-h-6" src="/assets/icons/24_information.svg"></svg-icon>
            <div class="tw-s-head-14-22-regular">{{ 'PAGES.PDF_CONFIGURATIONS.NO_FILE_UPLOADED' | translate }}</div>
        </div>

        <ng-container [ngTemplateOutlet]="input"></ng-container>
    </ng-container>

    <ng-container *ngIf="currentFile">
        <div class="tw-flex tw-justify-between">
            <div class="tw-flex tw-space-x-6 tw-relative">
                <img
                    class="tw-w-[200px]"
                    [ngClass]="{
                        'tw-h-[284px]':
                            pdfBackgroundType === 'COVER_LETTER' || pdfBackgroundType === 'CONTENT_VERTICAL',
                        'tw-h-[143px]': pdfBackgroundType === 'CONTENT_LANDSCAPE'
                    }"
                    src="data:image/png;base64,{{ currentFile.document }}"
                />

                <div class="tw-flex tw-flex-col tw-space-y-2">
                    <div class="tw-mb-2 tw-s-head-14-22-bold">{{ pdfTypeTitle }}</div>
                    <!-- <div class="tw-s-head-14-22-regular">file name</div> -->
                </div>
            </div>

            <app-scalara-button
                (click)="deleteFile()"
                variant="icon-only"
                height="40px"
                svgName="24_delete.svg"
            ></app-scalara-button>
        </div>
    </ng-container>
</div>

<ng-template #input>
    <div class="file-area">
        <div class="bx--label mb-1-5r">Formate: .pdf</div>

        <input
            #fileInput
            class="file-uploader file-uploader__input"
            type="file"
            (change)="fileChangeEvent($event)"
            [accept]="['.pdf']"
        />

        <div
            class="tw-min-h-[118px] tw-p-6 tw-flex tw-flex-col tw-items-center tw-justify-center"
            [ngClass]="{ 'tw-bg-scalaraGray-06': !isFileLoading }"
        >
            <ng-container *ngIf="isFileLoading; else inputBox">
                <ibm-loading [isActive]="true" size="sm"></ibm-loading>
            </ng-container>
            <ng-template #inputBox>
                <div class="tw-h-10 tw-w-10 tw-fill-scalaraGray-03 tw-mb-2">
                    <svg-icon src="/assets/icons/40_upload.svg"></svg-icon>
                </div>
                <span class="s-body-14-22-regular"> {{ 'PAGES.PDF_CONFIGURATIONS.LABEL_INPUT_AREA' | translate }}</span>
            </ng-template>
        </div>
    </div>
</ng-template>
