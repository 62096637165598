<ibm-loading *ngIf="isLoading" [isActive]="true" [size]="'normal'" [overlay]="true"></ibm-loading>

<div class="tw-pt-10 tw-mb-40">
    <div *ngIf="!vm?.selectedSettlementOwnershipId">
        <div class="mb-1r d-flex justify-content-end tw-space-x-2">
            <app-search searchInputId="wssTable"></app-search>
            <app-scalara-button
                (click)="downloadAllPdfs('pdf')"
                [disabled]="isLoading"
                [variant]="'ghost'"
                type="button"
                svgName="24_download.svg"
            >
                Alle Herunterladen
            </app-scalara-button>
            <app-scalara-button
                (click)="downloadAllPdfs('zip')"
                [disabled]="isLoading"
                [variant]="'ghost'"
                type="button"
                svgName="24_download.svg"
            >
                Alle Herunterladen in zip
            </app-scalara-button>
            <div
                class="tw-relative"
                appClickOutside
                (clickOutside)="setFilterItemsVisibility(true)"
                *ngIf="vm?.wss?.status !== 'ACTIVE'"
            >
                <app-scalara-button
                    (click)="setFilterItemsVisibility()"
                    [disabled]="isLoading"
                    [variant]="'ghost'"
                    type="button"
                    svgName="24_settings.svg"
                >
                    Dokumentauswahl
                </app-scalara-button>
                <app-pdf-print-config-seletion
                    *ngIf="isOpenDocumentPartSelection"
                    class="tw-bg-scalaraWhite tw-p-6 tw-min-w-[516px] tw-right-0 tw-space-y-4 tw-absolute tw-z-10 tile tile--not-clickable tile--static"
                >
                </app-pdf-print-config-seletion>
            </div>
        </div>

        <app-table
            [header]="vm?.wssTable?.header"
            [data]="vm?.wssTable?.data"
            tableSearchId="wssTable"
            (handleFile)="handleFile($event)"
            (rowClick)="this.selectSettlement($event)"
            class="clickable-rows"
        >
        </app-table>
    </div>
    <div *ngIf="vm?.selectedSettlementOwnershipId">
        <app-scalara-button
            variant="ghost"
            svgName="24_arrow-left.svg"
            (click)="this.selectSettlement(null)"
            class="tw-inline-block tw-mb-10"
            >Übersicht alle Abrechnungen</app-scalara-button
        >
        <h2 class="s-head-18-26-bold tw-mb-14" *ngIf="vm?.selectedSettlement">
            Einzelabrechnung für {{ vm?.selectedSimpleSettlement?.ownershipName }} -
            {{ vm?.selectedSimpleSettlement?.owners }}
        </h2>
        <!-- gray box with infos -->
        <div class="tw-p-12 tw-bg-scalaraGray-06">
            <div class="s-head-16-24-bold tw-mb-10">Ergebnis der Abrechnungsspitze Kostentragung</div>
            <div class="tw-flex tw-justify-between tw-mb-3">
                <div>Geschuldete Vorschüsse laut Wirtschaftsplan – Kostentragung</div>
                <div>{{ vm?.selectedSettlement?.plannedAdvancementsCostAllocation ?? 0 | eurocent }} €</div>
            </div>
            <div class="tw-flex tw-justify-between tw-mb-4">
                <div>Betrag laut Einzelabrechnung – Kostentragung</div>
                <div>{{ vm?.selectedSettlement?.actualAmountCostAllocation ?? 0 | eurocent }} €</div>
            </div>
            <div
                class="s-body-14-22-bold tw-pt-4 tw-flex tw-justify-between tw-border-t tw-border-solid tw-border-scalaraGray-05"
            >
                <div>
                    Abrechnungsspitze
                    {{
                        (vm?.selectedSettlement?.settlementPeakCostAllocation ?? 0) >= 0
                            ? '(Überdeckung)'
                            : '(Unterdeckung)'
                    }}
                </div>
                <div>{{ vm?.selectedSettlement?.settlementPeakCostAllocation ?? 0 | eurocent }} €</div>
            </div>
        </div>
        <!-- Grey box with Einheitensaldo -->
        <div
            class="tw-p-12 tw-border tw-border-solid tw-border-scalaraGray-05 tw-mt-4"
            *ngIf="areOutstandingAmountsPresent()"
        >
            <div class="tw-flex tw-justify-start">
                <div class="s-head-14-22-bold tw-mb-10">Nachrichtlich & nicht Beschlussrelevant:</div>
            </div>
            <div class="tw-flex tw-justify-between tw-mb-3">
                <div>Forderungen (Rückstände) zum {{ vm?.wss?.economicPlan?.endDate | dateFormat }}</div>
                <div>{{ vm?.selectedSettlement?.sumOutstandingReceivables ?? 0 | eurocent }} €</div>
            </div>
            <div class="tw-flex tw-justify-between tw-mb-4">
                <div>Verbindlichkeiten (Überzahlungen) zum {{ vm?.wss?.economicPlan?.endDate | dateFormat }}</div>
                <div>{{ vm?.selectedSettlement?.sumOutstandingLiabilities ?? 0 | eurocent }} €</div>
            </div>
            <div class="tw-pt-4 tw-flex tw-justify-between tw-border-t tw-border-solid tw-border-scalaraGray-05">
                <div>
                    Gesamt ({{
                        (vm?.selectedSettlement?.totalOutstandingBalance ?? 0) >= 0 ? 'Guthaben' : 'Rückstand'
                    }})
                </div>
                <div>{{ vm?.selectedSettlement?.totalOutstandingBalance ?? 0 | eurocent }} €</div>
            </div>
            <div class="tw-pt-4 tw-flex tw-justify-between">
                <div>Etwaig verrechnet mit Abrechnungsspitze</div>
                <div>{{ vm?.selectedSettlement?.totalOutstandingBalanceWithSettlementPeak ?? 0 | eurocent }} €</div>
            </div>
        </div>
        <!-- Main Table data -->
        <table class="tw-mt-[4.5rem]" *ngIf="vm && vm.tableData">
            <tr simpleTableRow [columns]="vm.tableData.columns" variant="header"></tr>

            <ng-container *ngFor="let row of vm.tableData.rows">
                <tr simpleTableRow [columns]="vm.tableData.columns" [rowData]="row" [class]="row.rowClass"></tr>
            </ng-container>
        </table>
        <!-- payments  -->
        <table class="tw-mt-[4.5rem]" *ngIf="vm && vm.paymentsTableData">
            <tr simpleTableRow [columns]="vm.paymentsTableData.columns" variant="header"></tr>

            <ng-container *ngFor="let row of vm.paymentsTableData.rows">
                <tr simpleTableRow [columns]="vm.paymentsTableData.columns" [rowData]="row" [class]="row.rowClass"></tr>
            </ng-container>
        </table>

        <ng-container *ngIf="vm && vm.nonApportionableHomeCostsTableData && vm.apportionableHomeCostsTableData">
            <div class="s-head-16-24-bold mb-2-5r tw-mb-10 tw-mt-[4.5rem]">Haushaltsnahe Dienstleistungen</div>
            <table>
                <tr simpleTableRow [columns]="vm.apportionableHomeCostsTableData.columns" variant="header"></tr>

                <ng-container *ngFor="let row of vm.apportionableHomeCostsTableData.rows">
                    <tr
                        simpleTableRow
                        [columns]="vm.apportionableHomeCostsTableData.columns"
                        [rowData]="row"
                        [class]="row.rowClass"
                    ></tr>
                </ng-container>

                <tr simpleTableRow class="[&&]:tw-h-8"></tr>

                <tr simpleTableRow [columns]="vm.nonApportionableHomeCostsTableData.columns" variant="header"></tr>
                <ng-container *ngFor="let row of vm.nonApportionableHomeCostsTableData.rows">
                    <tr
                        simpleTableRow
                        [columns]="vm.nonApportionableHomeCostsTableData.columns"
                        [rowData]="row"
                        [class]="row.rowClass"
                    ></tr>
                </ng-container>
            </table>
        </ng-container>
    </div>
</div>

<ng-container *ngIf="fileToView && showModal">
    <app-file-viewer [fileToView]="fileToView" (closeViewer)="handleModal()"></app-file-viewer>
</ng-container>
