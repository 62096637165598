<div class="bx--label tw-mb-3">
    {{ 'ACCOUNTING.ADD_RECEIPT.FORMATS_VAR' | translate : { formats: validFilesText, filesize: fileSizeInMb }
    }}<span>*</span>
</div>

<div class="file-uploader">
    <div *ngIf="!showButtonFromParent" class="file-area mb-05">
        <input
            type="file"
            ondrop="this.value=null;"
            onclick="this.value=null"
            class="file-uploader file-uploader__input"
            multiple
            #fileInput
            (change)="fileChangeEvent()"
        />
        <div class="file-dummy">
            <svg-icon src="/assets/icons/40_upload.svg" [svgStyle]="{ 'width.px': 40 }"></svg-icon>
            <span class="success s-body-14-22-regular">{{
                textInput || 'ACCOUNTING.ADD_RECEIPT.INPUT_AREA' | translate
            }}</span>
        </div>
    </div>
    <div *ngIf="!isFileExtensionValid" class="mt-04 mb-04 w-100">
        <div class="ibmCol">
            <app-notification
                type="error"
                subtitle="{{ 'ACCOUNTING.ADD_RECEIPT.INVALID_FILE' | translate : { formats: validFilesText } }}"
            >
            </app-notification>
        </div>
    </div>
    <div *ngIf="isFileTooBig" class="mt-04 mb-04 w-100">
        <div class="ibmCol">
            <app-notification
                type="error"
                subtitle="{{ 'ACCOUNTING.ADD_RECEIPT.INVALID_SIZE' | translate : { filesize: fileSizeInMb } }}"
            >
            </app-notification>
        </div>
    </div>
    <div [class.reverse-order-files-button]="reverseOrderFilesButton">
        <div
            [class.mb-08]="!reverseOrderFilesButton"
            class="mt-04 mb-08"
            *ngIf="files.length > 0 || existingFiles.length > 0"
        >
            <div class="single-file mb-04" *ngFor="let file of files; let i = index" [hidden]="filesProgress[i] === -1">
                <app-file
                    [file]="file"
                    [fileProgress]="filesProgress[i]"
                    [fileTypes]="fileTypes"
                    [categories]="categories"
                    [accountsList]="accountsList"
                    [isDisabledOnInit]="isAccountsDisabledOnInit"
                    (categorySelected)="onSelectCategory($event, i)"
                    (typeSelected)="onSelectType($event, i)"
                    (accountSelected)="onSelectAccount($event, i)"
                    (cancel)="cancelFile(i)"
                    [isCategoryDisable]="isCategoryDisable"
                ></app-file>
            </div>

            <div *ngIf="existingFiles.length > 0">
                <app-existing-files
                    (updateExistingFilesList)="updateExistingFilesList($event)"
                    [existingFiles]="existingFiles"
                    [categories]="categories"
                    [isCategoryDisable]="isCategoryDisable"
                ></app-existing-files>
            </div>
        </div>
        <div *ngIf="showButtonFromParent" class="d-flex justify-space-between mt-1r">
            <input
                type="file"
                ondrop="this.value=null;"
                onclick="this.value=null"
                class="file-uploader file-uploader__input"
                multiple
                #fileInput
                (change)="fileChangeEvent()"
                style="display: none"
            />
            <app-scalara-button (click)="fileInput.click()" [variant]="'ghost'" svgName="24_attachment.svg"
                >Anhang hochladen </app-scalara-button
            >
            <ng-content></ng-content>
        </div>
    </div>
</div>
