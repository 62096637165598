import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { BehaviorSubject, debounceTime, map, Observable } from 'rxjs';

export type LayoutMode = '' | 'desktop' | 'mobile';

@Injectable({
    providedIn: 'root',
})
//  service may be used to detect current layout mode in JS runtime
export class MediaQueriesService implements OnDestroy {
    public constructor(public ngZode: NgZone) {
        const updateMatchesObj = (): void => {
            this.ngZode.run(() =>
                this.mathes$.next({
                    ...this.mathes$.getValue(),
                    isDektop: window.matchMedia('(min-width: 1056px)').matches,
                })
            );
        };
        updateMatchesObj();
        this.resizeObserver = new ResizeObserver(() => {
            updateMatchesObj();
        });
        this.resizeObserver.observe(document.body);
    }

    private resizeObserver?: ResizeObserver;

    private mathes$ = new BehaviorSubject<{ isDektop: boolean } | null>(null);
    private currentLayoutMode: Observable<LayoutMode> = this.mathes$.pipe(
        debounceTime(50),
        map((value) => {
            if (value === null) {
                return '';
            }
            if (value.isDektop === true) {
                return 'desktop';
            }
            return 'mobile';
        })
    );

    public ngOnDestroy(): void {
        this.resizeObserver?.disconnect();
    }

    public getCurrentLayoutMode(): Observable<LayoutMode> {
        return this.currentLayoutMode;
    }

    public getMatches(): Observable<{ isDektop: boolean } | null> {
        return this.mathes$.asObservable();
    }
}
