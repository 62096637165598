import { ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RadioChange } from 'carbon-components-angular';
import { PersonType } from 'src/app/core/models/person-type.enum';
import { formControl, formControlHasError } from 'src/app/core/utils/common';
import { Person } from 'src/app/generated-sources/base';
import { AutocompletePlaceSelectedItem } from '../components/scalara-input-address-autocomplete/scalara-input-address-autocomplete.component';

export type PersonData = Required<Pick<Person, 'type' | 'firstName' | 'lastName' | 'companyName'>> & {
    streetName?: string;
    streetNumber?: string;
    zipCode?: string;
    area?: string;
};

@Component({
    selector: 'app-person-type-and-name-form',
    templateUrl: './person-type-and-name-form.component.html',
    styleUrls: ['./person-type-and-name-form.component.scss'],
})
export class PersonTypeAndNameFormComponent implements OnChanges {
    public constructor(private formBuilder: UntypedFormBuilder, private cd: ChangeDetectorRef) {}
    @Input() public partlyPerson?: PersonData;
    public personTypeDeclared = PersonType;

    public personTypeAndNameForm: UntypedFormGroup = this.formBuilder.group({
        type: [this.partlyPerson?.type ?? 'NAT_PERSON'],
        firstName: [this.partlyPerson?.firstName ?? '', [Validators.required]],
        lastName: [this.partlyPerson?.lastName ?? '', [Validators.required]],
        companyName: [this.partlyPerson?.companyName ?? ''],
        streetName: [null, [Validators.required]],
        streetNumber: [null, [Validators.required]],
        zipCode: [null, [Validators.required]],
        area: [null, [Validators.required]],
    });

    public ngOnChanges(): void {
        if (this.partlyPerson?.type) {
            this.personTypeAndNameForm.patchValue(this.partlyPerson);
            this.setValidadorsBasedOnType({ personType: this.partlyPerson.type });
        }
    }

    public onRadioChange($event: RadioChange): void {
        this.setValidadorsBasedOnType({ personType: $event.value as 'COMPANY' | 'NAT_PERSON' });
    }

    public setValidadorsBasedOnType({ personType }: { personType: 'COMPANY' | 'NAT_PERSON' }): void {
        if (personType == PersonType.COMPANY) {
            this.personTypeAndNameForm.controls['companyName'].setValidators(Validators.required);
            this.personTypeAndNameForm.controls['firstName'].clearValidators();
            this.personTypeAndNameForm.controls['lastName'].clearValidators();
        } else {
            this.personTypeAndNameForm.controls['companyName'].clearValidators();
            this.personTypeAndNameForm.controls['firstName'].setValidators(Validators.required);
            this.personTypeAndNameForm.controls['lastName'].setValidators(Validators.required);
        }

        this.personTypeAndNameForm.controls['companyName'].setErrors(null);
        this.personTypeAndNameForm.controls['firstName'].setErrors(null);
        this.personTypeAndNameForm.controls['lastName'].setErrors(null);
        this.personTypeAndNameForm.controls['companyName'].updateValueAndValidity();
        this.personTypeAndNameForm.controls['firstName'].updateValueAndValidity();
        this.personTypeAndNameForm.controls['firstName'].updateValueAndValidity();
    }

    public isInvalid(controlName: string): boolean {
        return (
            formControlHasError(formControl(this.personTypeAndNameForm, controlName), 'required') ||
            formControlHasError(formControl(this.personTypeAndNameForm, controlName), controlName)
        );
    }

    public onAutocompletePlaceSelected(item: AutocompletePlaceSelectedItem): void {
        const formControl = this.personTypeAndNameForm;
        formControl.patchValue({
            streetName: item.formData.streetName,
            streetNumber: item.formData.streetNumber,
            zipCode: item.formData.postalCode,
            area: item.formData.city,
        });
        const formControlsLabels = ['streetName', 'streetNumber', 'zipCode', 'area', 'country'];

        formControlsLabels.forEach((label) => {
            const control = formControl.get(label);
            if (!control) {
                console.log(`Control ${label} not found`);
                return;
            }
            control.markAsTouched();
            control.markAsDirty();
            control.updateValueAndValidity();
        });

        this.cd.detectChanges();
    }
}
