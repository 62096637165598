import { Component, Input, OnChanges } from '@angular/core';
import { Property } from 'src/app/generated-sources/base';
import { getPropertyTypeInfos } from '../../../core/utils/common';
import { accordionAnimations } from '../animations';
import { MenuItem } from '../interfaces/menu-item';
@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
    animations: accordionAnimations,
})
export class SidenavComponent implements OnChanges {
    @Input() public property?: Property;
    @Input() public menuItems?: MenuItem[];
    @Input() public additionalInfo?: string;
    @Input() public showChildrenItems = true;
    @Input() public menuTitle = '';

    public isOpenStates: boolean[] = [];
    public tagColor = '';

    public ngOnChanges(): void {
        this.menuItems?.forEach(() => this.isOpenStates.push(this.showChildrenItems));
        this.tagColor = this.property ? getPropertyTypeInfos(this.property?.propertyType)?.color : '';
    }

    public toggleOpenState(index: number): void {
        this.isOpenStates[index] = !this.isOpenStates[index];
    }
}
