import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbItem } from 'carbon-components-angular/breadcrumb/breadcrumb-item.interface';
import { switchMap, tap } from 'rxjs';
import { BreadCrumbService } from 'src/app/core/services/breadcrumb.service';
import { BankAccountDto, Payment, PaymentsService } from 'src/app/generated-sources/accounting';

@Component({
    selector: 'app-banking-payments-bank-connection-approvable-payments-index',
    templateUrl: './banking-payments-bank-connection-approvable-payments-index.component.html',
    styleUrls: ['./banking-payments-bank-connection-approvable-payments-index.component.scss'],
})
export class BankingPaymentsBankConnectionApprovablePaymentsIndexComponent implements OnInit {
    public breadcrumbs: BreadcrumbItem[] = [];
    public bankConnections: { groupedUniqueBankAccount: BankAccountDto; openPayments: number }[] = [];
    public openPayments = 0;
    public ledgerId?: string;
    public isLoading = false;

    public constructor(
        private breadcrumbService: BreadCrumbService,
        private translateService: TranslateService,
        private router: Router,
        private paymentsService: PaymentsService,
        private route: ActivatedRoute
    ) {}

    public ngOnInit(): void {
        this.isLoading = true;
        this.route.parent?.paramMap
            .pipe(
                switchMap((params) => {
                    const ledgerId = params.get('id');
                    if (ledgerId) {
                        this.ledgerId = ledgerId;
                        Payment.PaymentStatusEnum;
                        return this.paymentsService.findAll('OPEN,EXPORTED', ledgerId);
                    }
                    return this.paymentsService.findAll('OPEN,EXPORTED');
                }),
                tap((payments) => {
                    this.openPayments = payments.length;
                    payments.map((payment) => {
                        const findBankConnection = this.bankConnections.find(
                            (item) => item.groupedUniqueBankAccount.bic === payment.bankAccount.bic
                        );

                        if (!findBankConnection) {
                            const openPaymentsCount = payments.filter((paymentToCount) => {
                                return paymentToCount.bankAccount.bic === payment.bankAccount.bic;
                            }).length;

                            this.bankConnections.push({
                                groupedUniqueBankAccount: payment.bankAccount,
                                openPayments: openPaymentsCount,
                            });
                        }
                    });

                    this.isLoading = false;
                })
            )
            .subscribe();

        this.initBreadcrumbs();
    }

    private initBreadcrumbs(): void {
        this.breadcrumbs = [
            {
                content: this.ledgerId
                    ? this.translateService.instant('ACCOUNTING.BANK_ORDERS.HEADLINE')
                    : this.translateService.instant('NAVIGATION.FINANCE'),
                route: [this.ledgerId ? `/accounting/ledger/${this.ledgerId}/payments` : '/accounting'],
            },
            {
                content: this.translateService.instant('PAGES.PAYMENT_APPROVALS.BREADCRUMB'),
                route: [
                    this.ledgerId
                        ? `/accounting/ledger/${this.ledgerId}/payment-approvals`
                        : '/accounting/payment-approvals',
                ],

                current: true,
            },
        ];
        this.breadcrumbService.resetBreadCrumbs();
        this.breadcrumbService.updateBreadCrumbs(this.breadcrumbs);
        this.breadcrumbs = this.breadcrumbService.getCurrentBreadCrumbs();
    }

    public navigateToPaymentApprovalSelection(groupedUniqueBankAccount: BankAccountDto): void {
        this.router.navigate([
            this.ledgerId
                ? `/accounting/ledger/${this.ledgerId}/payment-approvals/${groupedUniqueBankAccount.bic}`
                : `/accounting/payment-approvals/${groupedUniqueBankAccount.bic}`,
        ]);
    }
}
