import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, finalize, switchMap, takeUntil, tap } from 'rxjs';
import {
    BankAccountDto,
    BankAccountsService,
    BankTransactionDto,
    BankTransactionsService,
    GuidedBookingTypeShortDto,
    GuidedBookingsService,
} from 'src/app/generated-sources/accounting';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { AddGuidedBookingOverlayComponent } from '../add-guided-booking-overlay/add-guided-booking-overlay.component';

export interface ExpandableTileSelector {
    isExpense: boolean;
    groupName: string;
    groupSubline: string;
    options: GuidedBookingTypeShortDto[];
}

@Component({
    selector: 'app-add-booking-selection-overlay',
    templateUrl: './add-booking-selection-overlay.component.html',
    styleUrls: ['./add-booking-selection-overlay.component.scss'],
})
export class AddBookingSelectionOverlayComponent extends OverlayChildComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();

    public isLoading = true;

    private ledgerId = '';
    private bankTransaction?: BankTransactionDto;
    private bankAccount?: BankAccountDto;

    public tileSelectors: ExpandableTileSelector[] = [];

    public constructor(
        private overlayService: OverlayService,
        private guidedBookingsService: GuidedBookingsService,
        private bankTransactionsService: BankTransactionsService,
        private bankAccountsService: BankAccountsService
    ) {
        super();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public ngOnInit(): void {
        this.populateData();
    }

    public goToSelectedBooking($event?: any): void {
        this.overlayService.removeOverlayComponentFromBody();

        let baseData = {
            ledgerId: this.ledgerId,
        };
        if (this.bankAccount) {
            baseData = { ...baseData, ...{ bankAccount: this.bankAccount } };
        }
        if (this.bankTransaction) {
            baseData = { ...baseData, ...{ bankTransaction: this.bankTransaction } };
        }

        const ref = this.overlayService.open(AddGuidedBookingOverlayComponent, {
            data: {
                ...baseData,
                selectedBooking: $event,
            },
        });

        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe({
            next: () => {
                this.saveEmitter$.next();
            },
        });
    }

    public populateData(): void {
        if (!this.config?.data) {
            console.warn('you must give config data to the overlay');
        }
        this.ledgerId = this.config?.data.ledgerId;

        if (!this.config?.data.bankTransactionId) {
            this.getSelectorsData();
            return;
        }

        this.bankTransactionsService
            .findeOneBankTransactionById(this.config?.data.bankTransactionId, true)
            .pipe(
                tap((bankTransaction: BankTransactionDto) => {
                    this.bankTransaction = bankTransaction;
                }),
                switchMap((bankTransaction) => {
                    return this.bankAccountsService.findOne(bankTransaction.bankAccountId ?? '', this.ledgerId);
                }),
                tap((bankAccount) => {
                    this.bankAccount = bankAccount;
                }),
                finalize(() => {
                    this.getSelectorsData();
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }

    public getSelectorsData(): void {
        this.guidedBookingsService
            .getGuidedBookingTypes(this.ledgerId)
            .subscribe((tileSelectorOptions: GuidedBookingTypeShortDto[]) => {
                this.tileSelectors = this.groupSelectorOptions(this.filterTileSelectors(tileSelectorOptions));
                this.isLoading = false;
            });

        takeUntil(this.unsubscribe$);
    }

    public filterTileSelectors(tileSelectors: GuidedBookingTypeShortDto[]): GuidedBookingTypeShortDto[] {
        if (!this.bankTransaction || Object.keys(this.bankTransaction).length < 1) {
            return tileSelectors;
        }
        if (this.bankTransaction.amount == 0) {
            console.warn('the amount of transaction can not be 0');
        }
        const filteredTileSelectors = tileSelectors.filter((item) => item.bankTransactionCompatible);
        if (this.bankTransaction.amount < 0) {
            return filteredTileSelectors.filter(
                (item) => item.category === GuidedBookingTypeShortDto.CategoryEnum.Expense
            );
        }
        return filteredTileSelectors.filter((item) => item.category !== GuidedBookingTypeShortDto.CategoryEnum.Expense);
    }

    private groupSelectorOptions(tileSelectorOptions: GuidedBookingTypeShortDto[]): ExpandableTileSelector[] {
        const selectors: ExpandableTileSelector[] = [];

        for (let i = 0; i < tileSelectorOptions.length; i++) {
            let selectorIndex = -1;

            selectorIndex = selectors.findIndex((selector) => selector.groupName == tileSelectorOptions[i].groupName);

            if (selectorIndex !== -1) {
                selectors[selectorIndex].options.push(tileSelectorOptions[i]);
            } else {
                const selector: ExpandableTileSelector = {
                    isExpense: tileSelectorOptions[i].category === GuidedBookingTypeShortDto.CategoryEnum.Expense,
                    groupName: tileSelectorOptions[i].groupName,
                    groupSubline: tileSelectorOptions[i].groupSubline,
                    options: [tileSelectorOptions[i]],
                };
                selectors.push(selector);
            }
        }
        return selectors;
    }
}
