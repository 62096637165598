import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { BehaviorSubject, ObservedValueOf, Subject, combineLatest, firstValueFrom, map, takeUntil } from 'rxjs';
import { MediaQueriesService } from 'src/app/core/services/media-queries.service.ts.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { controlInFormGroupIsInvalid } from 'src/app/core/utils/formValidationHelpers';
import { PersonsService } from 'src/app/generated-sources/base';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';
import validator from 'validator';
@Component({
    selector: 'app-add-bank-account-overlay',
    templateUrl: './add-bank-account-overlay.component.html',
    styleUrls: ['./add-bank-account-overlay.component.scss'],
})
export class AddBankAccountOverlayComponent extends OverlayChildComponent implements OnInit {
    public constructor(
        public formBuilder: UntypedFormBuilder,
        public personsService: PersonsService,
        public toastService: ToastService,
        public mediaQueriesService: MediaQueriesService
    ) {
        super();
    }
    public unsubscribe$ = new Subject<void>();
    public personId$ = new BehaviorSubject<string | null>(null);
    public isLoading$ = new BehaviorSubject(false);
    public currentLayoutMode$ = this.mediaQueriesService.getCurrentLayoutMode();
    public vm$ = combineLatest([this.personId$, this.isLoading$, this.currentLayoutMode$]).pipe(
        map(([personId, isLoading, currentLayoutMode]) => ({
            personId,
            isLoading,
            currentLayoutMode,
        })),
        takeUntil(this.unsubscribe$)
    );
    public vm: ObservedValueOf<typeof this.vm$> | null = null;

    public ibanValidator(control: AbstractControl): ValidationErrors | null {
        const value = control.value;
        const isIbanValid = validator.isIBAN(value);
        return isIbanValid ? null : { iban: true };
    }

    public bicValidator(control: AbstractControl): ValidationErrors | null {
        const value = control.value;
        if (value === '') {
            return null;
        }
        const isBicValid = validator.isBIC(value);
        return isBicValid ? null : { bic: true };
    }

    public form: UntypedFormGroup = this.formBuilder.group({
        accountHolder: ['', [Validators.required]],
        iban: ['', [Validators.required, this.ibanValidator]],
        bankName: ['', [Validators.required]],
        bic: ['', [this.bicValidator]],
    });

    public isInvalidForm(): boolean {
        return !this.form.valid;
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public async submit(): Promise<void> {
        const formValue = this.form.value;
        //remove spaces from iban
        formValue.iban = formValue.iban.replace(/\s/g, '');
        if (!this.vm?.personId) {
            console.warn('no personId');
            return;
        }
        try {
            this.isLoading$.next(true);
            await firstValueFrom(this.personsService.addBankAccount(this.vm?.personId, formValue));
            this.toastService.showSuccess('Zahlungsverbindung erfolgreich hinzugefügt.');
            this.saveEmitter$.next();
        } catch (e) {
            this.toastService.showError('Es ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihre Eingaben.');
        } finally {
            this.isLoading$.next(false);
        }
    }

    public ngOnInit(): void {
        const personId = this.config?.data.personId;
        if (personId) {
            this.personId$.next(personId);
        }
        this.vm$.subscribe((vm) => (this.vm = vm));
    }

    public onDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public controlInFormGroupIsInvalid = controlInFormGroupIsInvalid;
}
