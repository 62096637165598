import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { getAdressFromPerson, getNameFromPerson, getPropertyTypeInfos } from 'src/app/core/utils/common';
import { Person, TicketDto } from 'src/app/generated-sources/base';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { AddServiceProviderComponent } from '../add-service-provider/add-service-provider.component';
import { DeleteTicketServiceProviderComponent } from '../delete-ticket-service-provider/delete-ticket-service-provider.component';

@Component({
    selector: 'app-ticket-detail',
    templateUrl: './ticket-detail.component.html',
    styleUrls: ['./ticket-detail.component.scss'],
})
export class TicketDetailComponent implements OnChanges {
    private unsubscribe$ = new Subject<void>();

    @Input() public ticket?: TicketDto;
    @Input() public img = '';
    @Output() public refresh = new EventEmitter<void>();

    public recipients?: Person[] = [];
    public labelButtonToShowAllRecipients = 'PAGES.TICKET_DETAILS.SHOW_ALL';
    public tagColor = '';
    public allowedToAddServiceProvider = false;

    public constructor(private overlayService: OverlayService) {}

    public ngOnChanges(): void {
        this.allowedToAddServiceProvider = !!this.ticket?.permissions.find((item) => item == 'ALTER_SERVICE_PROVIDERS');
        this.recipients =
            this.ticket?.ownerships.length === 0 &&
            this.ticket.createdByPropertyManager &&
            this.ticket.occupations.length === 0
                ? []
                : this.ticket?.recipients.slice(0, 8);

        if (this.ticket?.property.propertyType === 'WEG') {
            this.labelButtonToShowAllRecipients = 'PAGES.TICKET_DETAILS.SHOW_OWNERS';
        } else {
            this.labelButtonToShowAllRecipients = 'PAGES.TICKET_DETAILS.SHOW_ALL_OWNERS_AND_TENANTS';
        }

        this.tagColor = this.ticket?.property ? getPropertyTypeInfos(this.ticket?.property?.propertyType)?.color : '';
    }

    public showAllRecipients(): void {
        this.recipients = this.ticket?.recipients;
    }

    public getNameFromPerson(person: Person): string {
        return getNameFromPerson(person);
    }

    public openAddServiceProviderOverlay(): void {
        const data = { ticket: this.ticket };
        const ref = this.overlayService.open(AddServiceProviderComponent, {
            data,
        });

        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            ref.close();
        });

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.refresh.emit();
        });
    }

    public removeServiceProvider(person: Person): void {
        const data = { personToDelete: person, allPerson: this.ticket?.serviceProviders, ticketId: this.ticket?.id };
        const ref = this.overlayService.open(DeleteTicketServiceProviderComponent, {
            data,
        });

        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            ref.close();
        });

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.refresh.emit();
        });
    }

    public getFullAdress(person: Person): string {
        return getAdressFromPerson(person);
    }
}
