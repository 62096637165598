import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ListItem } from 'carbon-components-angular';
import { Subject } from 'rxjs';
import { BankAccountDto } from 'src/app/generated-sources/accounting';
import { OnSubmitSetBankaccountStartAmountCallback } from '../ledger-reasonability-checks.component';

@Component({
    selector: 'app-ledger-reasonability-checks-set-start-amount',
    templateUrl: './ledger-reasonability-checks-set-start-amount.component.html',
    styleUrls: ['./ledger-reasonability-checks-set-start-amount.component.scss'],
})
export class LedgerReasonabilityChecksSetStartAmountComponent implements OnInit, OnChanges {
    public bankAccountStartAmountFormGroup: FormGroup = new FormGroup({});
    public bankAccountItemList$ = new Subject<ListItem[]>();
    public selectedWSSid?: string;
    @Input()
    public bankAccountStartAmountDate = new Date(new Date().getFullYear(), 0, 1);
    @Input()
    public selectedBankAccount?: string;
    @Input()
    public bankAccountStartAmount?: number;
    @Input()
    public bankAccountList: BankAccountDto[] = [];
    @Input()
    public onSubmitCallback?: OnSubmitSetBankaccountStartAmountCallback;

    public constructor(
        private translateService: TranslateService,

        private formBuilder: FormBuilder
    ) {}

    public ngOnInit(): void {
        this.bankAccountStartAmountFormGroup = this.formBuilder.group({
            bankAccountStartAmount: new FormControl(this.bankAccountStartAmount ?? 0, [
                Validators.required,
                Validators.min(1),
                Validators.max(9007199254740991),
                // Validators.pattern('^[0-9]*$'),
            ]),
            bankAccountStartAmountDate: new FormControl(this.bankAccountStartAmountDate, [Validators.required]),
            selectedBankAccount: new FormControl(this.selectedBankAccount, [Validators.required]),
        });
        this.bankAccountStartAmountFormGroup.updateValueAndValidity();
        this.updateBankAccountListItems(this.bankAccountList);
    }
    public ngOnChanges(): void {
        this.updateBankAccountListItems(this.bankAccountList);
    }

    public onSubmit(): void {
        if (!this.onSubmitCallback) {
            return;
        }
        this.onSubmitCallback(
            this.bankAccountStartAmountFormGroup.get('bankAccountStartAmount')?.value,
            this.selectedBankAccount ?? 'a',
            this.bankAccountStartAmountDate.toISOString()
        );
    }

    private updateBankAccountListItems(bankAccountList: BankAccountDto[]): void {
        this.bankAccountItemList$.next(
            bankAccountList.map((bankAccount) => {
                return {
                    content: `${bankAccount.iban}, ${bankAccount.holderName}, ${bankAccount.bankName}`,
                    value: bankAccount.id,
                    selected: false,
                    id: bankAccount.id,
                };
            })
        );
    }

    public onSelectedBankAccount($event: any): void {
        this.selectedBankAccount = $event.item['value'];
        this.bankAccountStartAmountFormGroup;
    }

    public onChangebankAccountStartAmountDateDate($event: any): void {
        this.bankAccountStartAmountDate = $event[0];
    }
}
