import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { IbanFormatterPipe } from 'angular-iban';
import { isEqual } from 'lodash';
import { Subject, distinctUntilChanged, firstValueFrom, map, takeUntil, tap } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { bankAccountTypeName } from 'src/app/core/utils/common';
import { LedgerCustomService } from 'src/app/features/accounting/components/services/ledger-custom.service';
import {
    BankAccountDto,
    Ledger,
    LedgerDto,
    LedgersService,
    UpdateLedgerDto,
} from 'src/app/generated-sources/accounting';
import {
    DirectDebitSettingsDto,
    DirectDebitSettingsPersonBankAccountDto,
    DirectDebitSettingsPersonDto,
    OccupationService,
    OwnershipsService,
} from 'src/app/generated-sources/base';

@Component({
    selector: 'app-banking-debit-configuration',
    templateUrl: './banking-debit-configuration.component.html',
    styleUrls: ['./banking-debit-configuration.component.scss'],
})
export class BankingDebitConfigurationComponent implements OnInit, OnDestroy {
    public debitConfig: DirectDebitSettingsDto[] = [];
    public debitBankAccounts = { bankAccount: 'name' };
    private ibanFormatterPipe = new IbanFormatterPipe();
    public isDebitBankAccountChanged = false;
    public isDebitConfigChanged = false;
    public isCreditorChanged = false;
    public isSubmitLoading = false;
    public debitConfigHeader = [
        'PAGES.BANK_ACCOUNT_DEBIT_CONFIGURATION.LABEL_OWNERSHIP_OCCUPATION',
        'PAGES.BANK_ACCOUNT_DEBIT_CONFIGURATION.LABEL_OWNER_TENANT',
        'ENTITIES.BANK_ACCOUNT.LABEL_ENTITY',
        'PAGES.BANK_ACCOUNT_DEBIT_CONFIGURATION.LABEL_CHECKBOX',
    ];
    public debitBankAccountsHeader = [
        'ENTITIES.BANK_ACCOUNT.ACCOUNT_TYPE',
        'ENTITIES.BANK_ACCOUNT.BANK_NAME',
        'ENTITIES.BANK_ACCOUNT.IBAN',
        'ENTITIES.BANK_ACCOUNT.ACCOUNT_OWNER',
        'PAGES.BANK_ACCOUNT_DEBIT_CONFIGURATION.LABEL_CHECKBOX',
    ];
    public selectedBankAccount?: BankAccountDto;

    @ViewChild('checkboxDebitBankAccount', { static: true })
    public checkboxDebitBankAccount?: TemplateRef<any>;

    private unsubscribe$ = new Subject<void>();

    @Input() public bankAccounts?: BankAccountDto[];

    public ledger?: LedgerDto;
    public creditorForm = this.formBuilder.group({
        creditorName: [null, [Validators.required]],
        creditorId: [null, [Validators.required]],
    });

    public constructor(
        private ledgerService: LedgersService,
        private ownerhipsService: OwnershipsService,
        private occupationsService: OccupationService,
        private toastService: ToastService,
        private ledgerCustomService: LedgerCustomService,
        private formBuilder: UntypedFormBuilder
    ) {}

    public ngOnInit(): void {
        this.ledgerCustomService
            .getLedger$()
            .pipe(
                tap((ledger) => {
                    if (ledger) {
                        this.ledger = ledger;
                    }
                }),
                map((ledger) => ({ type: ledger?.type, id: ledger?.id })),
                distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((ledger) => {
                if (ledger.type) {
                    this.loadDebitConfig(ledger.type);
                }
            });
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }
    public isInvalidCreditorForm(controlName: string): boolean {
        return false;
    }

    public updateCreditorNameOnBlur(): void {
        if (this.ledger && this.ledger.creditorDescription !== this.creditorForm.get('creditorName')?.value) {
            this.isCreditorChanged = true;
            this.ledger.creditorDescription = this.creditorForm.get('creditorName')?.value ?? '';
        }
    }
    public updateCreditorIdOnBlur(): void {
        if (this.ledger && this.ledger.creditorIdentifier !== this.creditorForm.get('creditorId')?.value) {
            this.isCreditorChanged = true;
            this.ledger.creditorIdentifier = this.creditorForm.get('creditorId')?.value ?? '';
        }
    }

    public getBankAccountTypeName(bankAccount: BankAccountDto): string {
        return bankAccountTypeName(bankAccount);
    }

    public save(): void {
        let updateLedger: UpdateLedgerDto = {};
        if (this.isCreditorChanged || this.isDebitBankAccountChanged) {
            if (this.isCreditorChanged) {
                const creditor = {
                    creditorDescription: this.creditorForm.get('creditorName')?.value ?? null,
                    creditorIdentifier: this.creditorForm.get('creditorId')?.value ?? null,
                };
                updateLedger = { ...creditor };
            }
            if (this.isDebitBankAccountChanged) {
                if (this.ledger) {
                    const debitBankAccount = { directDebitBankAccountId: this.ledger.directDebitBankAccountId ?? null };
                    updateLedger = { ...debitBankAccount };
                }
            }
            this.saveLedger(updateLedger);
            return;
        }
        if (this.isDebitConfigChanged) {
            this.saveDebitConfig();
        }
    }

    public async saveLedger(updateObject: UpdateLedgerDto): Promise<void> {
        this.isSubmitLoading = true;
        if (this.ledger) {
            try {
                this.ledgerCustomService.setLedger(
                    await firstValueFrom(this.ledgerService.updateLedger(this.ledger.id, { ...updateObject }))
                );

                this.isSubmitLoading = false;
                this.isDebitBankAccountChanged = false;
                this.isCreditorChanged = false;

                this.toastService.showSuccess('Das Zielkonto für den Lastschrifteinzug ist erfolgreich gespeichert');
            } catch (error) {
                this.toastService.showError('Das Zielkonto konnte nicht gespeichert werden:', JSON.stringify(error));
                this.isSubmitLoading = false;

                return;
            }
        }
    }

    public async saveDebitConfig(): Promise<void> {
        //reduce to only send needed information
        const debitConfigToSend = this.debitConfig.map(({ persons, name, ...rest }) => rest);

        this.isSubmitLoading = true;
        if (this.ledger) {
            if (this.ledger.type === Ledger.TypeEnum.Weg) {
                try {
                    this.debitConfig = await firstValueFrom(
                        this.ownerhipsService.setDirectDebitSettings(this.ledger?.propertyId, debitConfigToSend)
                    );
                    this.isSubmitLoading = false;
                    this.isDebitConfigChanged = false;
                    this.toastService.showSuccess(
                        'Das Konfiguration für den Lastschrifteinzug ist erfolgreich gespeichert'
                    );
                    return;
                } catch (error) {
                    this.toastService.showError(
                        'Die Konfiguration konnte nicht gespeichert werden:',
                        JSON.stringify(error)
                    );
                    this.isSubmitLoading = false;
                    return;
                }
            }
            try {
                this.debitConfig = await firstValueFrom(
                    this.occupationsService.setDirectDebitSettings(this.ledger?.propertyId, debitConfigToSend)
                );
                this.isSubmitLoading = false;
                this.isDebitConfigChanged = false;
                this.toastService.showSuccess(
                    'Das Konfiguration für den Lastschrifteinzug ist erfolgreich gespeichert'
                );
                return;
            } catch (error) {
                this.toastService.showError(
                    'Die Konfiguration konnte nicht gespeichert werden:',
                    JSON.stringify(error)
                );
                this.isSubmitLoading = false;
                return;
            }
        }
    }

    private async loadDebitConfig(ledgerType: LedgerDto.TypeEnum): Promise<void> {
        if (this.ledger) {
            if (ledgerType === Ledger.TypeEnum.Weg) {
                this.debitConfig = await firstValueFrom(
                    this.ownerhipsService.getDirectDebitSettings(this.ledger.propertyId)
                );
                return;
            }
            this.debitConfig = await firstValueFrom(
                this.occupationsService.getDirectDebitSettings(this.ledger.propertyId, this.ledger?.ownershipIds)
            );
        }
    }

    public onDebitAccountCheckboxChange($event: any, bankAccount: BankAccountDto): void {
        if (this.ledger) {
            this.ledger.directDebitBankAccountId = $event.checked ? bankAccount.id : '';
        }
        this.isDebitBankAccountChanged = true;
    }

    public onDebitConfigCheckboxChange(
        $event: any,
        data: DirectDebitSettingsDto,
        person?: DirectDebitSettingsPersonDto,
        bankAccount?: DirectDebitSettingsPersonBankAccountDto
    ): void {
        this.isDebitConfigChanged = true;
        const debitSetting = this.debitConfig.find((debitSetting) => debitSetting.id === data.id);
        if (debitSetting && !$event.checked) {
            debitSetting.selectedPersonId = '';
            debitSetting.selectedBankAccountId = '';
            return;
        }
        if (debitSetting && person) {
            debitSetting.selectedPersonId = person.id;
            debitSetting.selectedBankAccountId = '';
            if (bankAccount) {
                debitSetting.selectedBankAccountId = bankAccount.id;
            }
        }
    }
}
