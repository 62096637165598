<app-delete-overlay
    title="PAGES.DELETE_ENERGY_CERTIFICATE.TITLE"
    headline="PAGES.DELETE_ENERGY_CERTIFICATE.HEADLINE"
    subtitle="PAGES.DELETE_ENERGY_CERTIFICATE.SUBTITLE"
    (closeOverlay)="abort()"
    (submitOverlay)="submit()"
>
    <div class="tw-bg-scalaraGray-06 tw-px-4 tw-py-3 tw-s-body-14-22-regular">
        {{ 'ENTITIES.ENERGY_CERTIFICATE.LABEL_' + energyCertificate?.certificateType | translate }}
    </div>
</app-delete-overlay>
