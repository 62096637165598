<div class="d-flex align-items-center w-100">
    <div class="date-picker-styled">
        <ibm-date-picker
            [value]="[startDate]"
            (valueChange)="onChangeDate($event, 'startDate')"
            id="start"
            [placeholder]="'tt.mm.jjjj'"
            [size]="'md'"
            [language]="'de'"
            [dateFormat]="'d.m.Y'"
            [invalid]="invalidDates()"
        >
        </ibm-date-picker>
    </div>

    <div
        class="ml-0-5r mr-0-5r s-body-14-22-regular"
        [ngClass]="{ 's-red-01': invalidDates(), 's-gray-03': !invalidDates() }"
    >
        –
    </div>

    <div class="date-picker-styled">
        <ibm-date-picker
            [value]="[endDate]"
            (valueChange)="onChangeDate($event, 'endDate')"
            id="end"
            [placeholder]="'tt.mm.jjjj'"
            [size]="'md'"
            [language]="'de'"
            [dateFormat]="'d.m.Y'"
            [invalid]="invalidDates()"
        >
        </ibm-date-picker>
    </div>
</div>

<div *ngIf="invalidDates()" class="s-red-01 error-message">
    {{ 'PAGES.BOOKINGS.BOOKING_DETAILS.INVALID_PERIOD' | translate }}
</div>
