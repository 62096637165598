import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { Property } from 'src/app/generated-sources/base';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { AddEditPropertyFormComponent } from '../add-edit-property-form/add-edit-property-form.component';

@Component({
    selector: 'app-add-property-selection',
    templateUrl: './add-property-selection.component.html',
    styleUrls: ['./add-property-selection.component.scss'],
})
export class AddPropertySelectionComponent extends OverlayChildComponent {
    public isLoading = false;
    private unsubscribe$ = new Subject<void>();

    public constructor(private overlayService: OverlayService, private router: Router) {
        super();
    }

    public goToSelectedTypeProperty(propertyType: Property.PropertyTypeEnum): void {
        this.overlayService.removeOverlayComponentFromBody();

        const ref = this.overlayService.open(AddEditPropertyFormComponent, { data: { propertyType: propertyType } });

        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe({
            next: () => {
                this.saveEmitter$.next();
            },
        });
    }
    public typeEnum = Property.PropertyTypeEnum;
}
