<div class="overlay-container overlay-container--{{ containerSize }} tw-px-4 lg:tw-px-5 pb-8r">
    <div *ngIf="!ignoreHeadline" class="headline-wrapper d-flex mb-2-5r w-100">
        <!-- show icon only on desktop -->
        <div *ngIf="image && vm?.currentLayoutMode === 'desktop'" class="mr-06 headline-wrapper__icon">
            <app-icon-bubble [size]="sizeIcon" [color]="colorIcon" [image]="image"></app-icon-bubble>
        </div>
        <div class="headline-wrapper__title d-flex align-items-center">
            <div class="s-head-28-36-bold mr-1r">
                {{ translateHeadlineTitle ? translatedHeadlineTitle : headlineTitle }}
            </div>
            <app-tooltip position="bottom" *ngIf="tooltipText">
                <div content class="tooltip-content pre-wrap px-3r py-2r">
                    <span>{{ tooltipText }}</span>
                </div>
                <div hoverable-item class="formated-icon">
                    <svg-icon src="/assets/icons/24_information.svg"></svg-icon>
                </div>
            </app-tooltip>
        </div>
    </div>
    <div class="w-100">
        <ng-content></ng-content>
    </div>
</div>
