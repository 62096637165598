<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<div class="tw-flex justify-space-between tw-mb-6">
    <div class="tw-flex tw-items-center">
        <app-headline-with-icon color="green" image="24_person.svg" text="Aktuelle Bewohner"></app-headline-with-icon>
    </div>

    <app-scalara-button (click)="openAddResidentsOverlay()" variant="ghost" svgName="24_add.svg">{{
        'CONTROLS.BUTTONS.ADD' | translate
    }}</app-scalara-button>
</div>

<div *ngIf="residentsTable | async as tableModel">
    <app-table
        emptyText="PAGES.RELATIONS.RESIDENTS.LABEL_EMPTY_TABLE"
        [header]="tableModel.header"
        [data]="tableModel.data"
    >
    </app-table>
</div>

<ng-template #actions let-data="data">
    <app-table-actions
        [data]="data.extraData.transferObject"
        (openDeleteRelationOvervlay)="openDeleteRelationOvervlay($event)"
        (openEditRelationOvervlay)="openEditRelationOvervlay($event)"
    ></app-table-actions>
</ng-template>
