import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ListItem } from 'carbon-components-angular';
import { Observable, Subject, finalize, forkJoin, of, switchMap, takeUntil, tap } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { bankAccountTypeName } from 'src/app/core/utils/common';
import {
    BankAccountDto,
    BankAccountsService,
    BankConnectionDto,
    BankTransactionsService,
    LedgerDto,
    LedgersService,
} from 'src/app/generated-sources/accounting';
import { Ownership, OwnershipsService, Property } from 'src/app/generated-sources/base';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';

@Component({
    selector: 'app-sync-accounts-form',
    templateUrl: './sync-accounts-form.component.html',
    styleUrls: ['./sync-accounts-form.component.scss'],
})
export class SyncAccountsFormComponent extends OverlayChildComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();

    public isLoading = false;
    public form: UntypedFormGroup = new UntypedFormGroup({});
    public propertyItems: ListItem[] = [];
    public ledgersList: ListItem[] = [];
    public bankAccounts?: BankAccountDto[];
    public showInfoBox = false;
    public bankConnection?: BankConnectionDto;
    public ledgers: LedgerDto[] = [];

    public constructor(
        private bankAccountsService: BankAccountsService,
        private translateService: TranslateService,
        private bankTransactionService: BankTransactionsService,
        private toastService: ToastService,
        private ledgersService: LedgersService,
        private ownershipsService: OwnershipsService
    ) {
        super();
    }
    public ngOnInit(): void {
        this.isLoading = true;

        this.bankAccounts = this.config?.data.bankConnection.bankAccounts || [this.config?.data.bankConnection];
        this.bankConnection = this.config?.data.bankConnection;
        this.showInfoBox = this.config?.data.showInfoBox;

        if (this.bankAccounts?.length === 0) {
            const error = this.translateService.instant(['PAGES.USER_MANAGEMENT.ACCOUNTS_VIEW.NOT_ACCOUNTS_ERROR']);
            const errorMsg = error['PAGES.USER_MANAGEMENT.ACCOUNTS_VIEW.NOT_ACCOUNTS_ERROR'];
            this.toastService.showError(errorMsg);
            this.cancelEmitter$.next();
        }

        this.ledgersService
            .findAll()
            .pipe(
                tap((ledgers) => {
                    this.ledgers = ledgers;
                }),
                switchMap(() => {
                    const sevs = this.ledgers
                        .filter((ledger) => ledger.type === 'SEV')
                        .reduce((accumulator: any, currentObject) => {
                            const currentProperty = currentObject.property as Property;
                            const existingObjectIndex = accumulator.findIndex((obj: any) => {
                                return obj.propertyId === currentProperty.id;
                            });

                            if (existingObjectIndex !== -1) {
                                accumulator[existingObjectIndex].ownershipIds.push(...currentObject.ownershipIds);
                            } else {
                                accumulator.push({
                                    propertyId: currentProperty.id,
                                    ownershipIds: [...currentObject.ownershipIds],
                                });
                            }
                            return accumulator;
                        }, []);

                    if (sevs.length > 0) {
                        const obs: Observable<Ownership[]>[] = [];

                        sevs.map((item: any) => {
                            obs.push(this.ownershipsService.findAll(item.propertyId, item.ownershipIds.join(',')));
                        });

                        return forkJoin(obs);
                    } else {
                        return of([]);
                    }
                }),
                tap((ownerships) => {
                    this.ledgersList = this.ledgers.map((ledger) => {
                        if (ledger.type === 'SEV') {
                            const ownershipsNames: string[] = [];
                            ownerships.map((item) => {
                                const findOwnership = item.find((data) => ledger.ownershipIds.includes(data.id));
                                findOwnership && findOwnership.name ? ownershipsNames.push(findOwnership.name) : null;
                            });

                            return {
                                selected: false,
                                content:
                                    ledger.type +
                                    ' ' +
                                    ownershipsNames.join(', ') +
                                    ', ' +
                                    this.getFullPropertyText(ledger.property as Property),
                                id: ledger.id,
                            };
                        }
                        return {
                            selected: false,
                            content: ledger.type + ' ' + this.getFullPropertyText(ledger.property as Property),
                            id: ledger.id,
                        };
                    });
                    this.form = this.createForm();
                }),
                finalize(() => (this.isLoading = false)),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public createForm(): UntypedFormGroup {
        const group: Record<string, UntypedFormControl> = {};
        this.bankAccounts?.forEach((account) => {
            group[account.id] = new UntypedFormControl(null);
        });
        return new UntypedFormGroup(group);
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public onSubmit(): void {
        const requests$: Observable<BankAccountDto>[] = [];

        this.bankAccounts?.forEach((bankAccount) => {
            if (this.form.get(bankAccount.id)?.value) {
                requests$.push(
                    this.bankAccountsService.update(bankAccount.id, {
                        ledgerIds: [this.form.get(bankAccount.id)?.value],
                    })
                );
            }
        });

        this.isLoading = true;

        forkJoin(requests$)
            .pipe(
                switchMap(() => this.bankTransactionService.syncTransactionsForUser()),
                finalize(() => (this.isLoading = false)),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: () => {
                    this.toastService.showSuccess();
                    this.saveEmitter$.next();
                    this.isLoading = false;
                },
                error: (error) => {
                    this.toastService.showError(error.error['message']);
                    this.isLoading = false;
                },
            });
    }

    private getFullPropertyText(property: Property): string {
        return `${property.name}, ${property.address?.streetName} ${property.address?.streetNumber}, ${property.address?.zipCode}, ${property.address?.area}`;
    }

    public onSelectBankAccount(bankAccountId: string, $event: ListItem | ListItem[]): void {
        const event = $event as any; // $event type is wrong here from carbonside. It's not a ListItem but an object with the item/s
        this.form.get(bankAccountId)?.patchValue(event.item.id);
    }

    public clearFormControl(bankAccountId: string): void {
        this.form.get(bankAccountId)?.patchValue(null);
    }

    public get bankNameWithBic(): string {
        const bank = this.bankAccounts?.filter((account) => account.bankName)[0];
        return `${bank?.bankName}`;
    }

    public getBankAccountNameForType(bankAccount: BankAccountDto): string {
        return bankAccountTypeName(bankAccount);
    }

    public validateButton(): boolean {
        return Object.keys(this.form.value).find((item) => this.form.value[item]) ? false : true;
    }
}
