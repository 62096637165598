<div ibmRow class="pt-2r">
    <div ibmCol [columnNumbers]="{ md: 1, lg: 1 }"></div>
    <div ibmCol [columnNumbers]="{ md: 14, lg: 14 }">
        <div ibmRow class="pb-5r">
            <div ibmCol [columnNumbers]="{ md: 4, lg: 4 }" class="s-head-18-26-bold">
                <app-tag color="green" size="m"
                    >{{ platformStatistics.Person }} {{ 'ENTITIES.PERSON.LABEL_ENTITY_PLURAL' | translate }}</app-tag
                ><br />
                <app-tag color="green" size="m"
                    >{{ platformStatistics.User }} {{ 'ENTITIES.USER.LABEL_ENTITY_PLURAL' | translate }}</app-tag
                >
            </div>
            <div ibmCol [columnNumbers]="{ md: 4, lg: 4 }">
                <app-tag color="orange" size="m">
                    {{ platformStatistics.Property }}
                    {{ 'ENTITIES.PROPERTY.LABEL_ENTITY_PLURAL' | translate }}</app-tag
                ><br />
                <app-tag color="orange" size="m"
                    >{{ platformStatistics.Ownership }}
                    {{ 'ENTITIES.OWNERSHIP.LABEL_ENTITY_PLURAL' | translate }}</app-tag
                >
            </div>
            <div ibmCol [columnNumbers]="{ md: 4, lg: 4 }">
                <app-tag color="red" size="m"
                    >{{ platformStatistics.Address }} {{ 'ENTITIES.ADDRESS.LABEL_ENTITY_PLURAL' | translate }}</app-tag
                ><br />
            </div>
            <div ibmCol [columnNumbers]="{ md: 4, lg: 4 }">
                <app-tag color="blue" size="m">
                    {{ platformStatistics.Unit }} {{ 'ENTITIES.UNIT.LABEL_ENTITY_PLURAL' | translate }}</app-tag
                ><br />
                <app-tag color="blue" size="m"
                    >{{ platformStatistics.Building }}
                    {{ 'ENTITIES.BUILDING.LABEL_ENTITY_PLURAL' | translate }}</app-tag
                >
            </div>
        </div>
    </div>
    <div ibmCol [columnNumbers]="{ md: 1, lg: 1 }"></div>
</div>

<div ibmRow class="pb-5r">
    <div ibmCol [columnNumbers]="{ md: 4, lg: 4 }"></div>
    <div ibmCol [columnNumbers]="{ md: 10, lg: 10 }">
        <div ibmRow>
            <div ibmCol [columnNumbers]="{ md: 4, lg: 4 }" class="tw-mb-8">
                <div
                    class="tile tile--box-selection pt-3r pb-2r"
                    (click)="doSomething('lastEnrolledStatistics')"
                    [ngClass]="{
                        'tile--selected': displayLastEnrolledStatistics,
                        '': !displayLastEnrolledStatistics
                    }"
                >
                    <div class="pt-1r pb-1r headline s-head-28-36-bold">
                        <div>{{ lastEnrolledStatistics?.length }}</div>
                    </div>
                    <div class="headline">{{ 'PAGES.STATISTICS.LABEL_TILE_LAST_ENROLLEMENTS' | translate }}</div>
                </div>
            </div>
            <div ibmCol [columnNumbers]="{ md: 4, lg: 4 }" class="tw-mb-8">
                <div
                    class="tile tile--box-selection pt-3r pb-2r"
                    (click)="doSomething('orphanAccounts')"
                    [ngClass]="{
                        'tile--selected': displayOrphanAccounts,
                        '': !displayOrphanAccounts
                    }"
                >
                    <div class="pt-1r pb-1r headline s-head-28-36-bold">
                        <div>{{ orphanAccounts?.length }}</div>
                    </div>
                    <div class="headline">{{ 'PAGES.STATISTICS.LABEL_TILE_ORPHAN_ACCOUNTS' | translate }}</div>
                </div>
            </div>
            <div ibmCol [columnNumbers]="{ md: 4, lg: 4 }" class="tw-mb-8">
                <div
                    class="tile tile--box-selection pt-3r pb-2r"
                    (click)="doSomething('customerStatistics')"
                    [ngClass]="{
                        'tile--selected': displayCustomerStatistics,
                        '': !displayCustomerStatistics
                    }"
                >
                    <div class="pt-1r pb-1r headline s-head-28-36-bold">
                        <div>{{ customerStatistics?.length }}</div>
                    </div>
                    <div class="headline">{{ 'PAGES.STATISTICS.LABEL_TILE_CUSTOMER_STATISTICS' | translate }}</div>
                </div>
            </div>
            <div ibmCol [columnNumbers]="{ md: 4, lg: 4 }" class="tw-mb-8">
                <div
                    class="tile tile--box-selection pt-3r pb-2r"
                    (click)="doSomething('customerSubscriptions')"
                    [ngClass]="{
                        'tile--selected': displayCustomerSubscriptions,
                        '': !displayCustomerSubscriptions
                    }"
                >
                    <div class="pt-1r pb-1r headline s-head-28-36-bold">
                        <div>{{ customerSubscriptions.length }}</div>
                    </div>
                    <div class="headline">{{ 'PAGES.STATISTICS.LABEL_TILE_CUSTOMER_SUBSCRIPTIONS' | translate }}</div>
                </div>
            </div>
            <div ibmCol [columnNumbers]="{ md: 4, lg: 4 }" class="tw-mb-8">
                <div class="tile tile--box-selection pt-3r pb-2r">
                    <div class="pt-1r pb-1r headline s-head-28-36-bold">
                        <div>{{ avgPersonXUser | number : '1.0-0' }}</div>
                    </div>
                    <div class="headline">{{ 'PAGES.STATISTICS.LABEL_TILE_AVG_PERSON_PER_CUSTOMER' | translate }}</div>
                </div>
            </div>
            <div
                *ngIf="
                    platformStatistics !== undefined &&
                    platformStatistics.Property &&
                    customerSubscriptions !== undefined &&
                    customerSubscriptions.length
                "
                ibmCol
                [columnNumbers]="{ md: 4, lg: 4 }"
                class="tw-mb-8"
            >
                <div class="tile tile--box-selection pt-3r pb-2r">
                    <div class="pt-1r pb-1r headline s-head-28-36-bold">
                        <div>{{ platformStatistics.Property / customerSubscriptions.length | number : '1.0-0' }}</div>
                    </div>
                    <div class="headline">
                        {{ 'PAGES.STATISTICS.LABEL_TILE_AVG_PROPERTY_PER_CUSTOMER' | translate }}
                    </div>
                </div>
            </div>
            <div
                *ngIf="platformStatistics && platformStatistics.Ownership && customerSubscriptions"
                ibmCol
                [columnNumbers]="{ md: 4, lg: 4 }"
                class="tw-mb-8"
            >
                <div class="tile tile--box-selection pt-3r pb-2r">
                    <div class="pt-1r pb-1r headline s-head-28-36-bold">
                        <div>{{ platformStatistics.Ownership / customerSubscriptions.length | number : '1.0-0' }}</div>
                    </div>
                    <div class="headline">{{ 'PAGES.STATISTICS.LABEL_TILE_AVG_UNIT_PER_CUSTOMER' | translate }}</div>
                </div>
            </div>
        </div>
    </div>
    <div ibmCol [columnNumbers]="{ md: 4, lg: 4 }"></div>
</div>

<div ibmRow>
    <div ibmCol class="mb-1r d-flex justify-content-end">
        <app-search [searchInputId]="'operationsBase'"></app-search>
    </div>
</div>
<div ibmRow class="pt-2r">
    <div ibmCol [columnNumbers]="{ md: 1, lg: 1 }"></div>
    <div ibmCol [columnNumbers]="{ md: 14, lg: 14 }">
        <app-table
            *ngIf="displayLastEnrolledStatistics"
            [emptyText]="'PAGES.STATISTICS.LABEL_EMPTY_TABLE'"
            [header]="lastEnrolledTable.header"
            [data]="lastEnrolledTable.data"
            [tableSearchId]="'operationsBase'"
        >
        </app-table>
        <app-table
            *ngIf="displayOrphanAccounts"
            [emptyText]="'PAGES.STATISTICS.LABEL_EMPTY_TABLE'"
            [header]="orphanAccountsTable.header"
            [data]="orphanAccountsTable.data"
        >
        </app-table>
        <app-table
            *ngIf="displayCustomerStatistics"
            [emptyText]="'PAGES.STATISTICS.LABEL_EMPTY_TABLE'"
            [header]="customerStatisticsTable.header"
            [data]="customerStatisticsTable.data"
        >
        </app-table>
        <app-table
            *ngIf="displayCustomerSubscriptions"
            [emptyText]="'PAGES.STATISTICS.LABEL_EMPTY_TABLE'"
            [header]="customerSubscriptionsTable.header"
            [data]="customerSubscriptionsTable.data"
        >
        </app-table>
    </div>
    <div ibmCol [columnNumbers]="{ md: 1, lg: 1 }"></div>
</div>
