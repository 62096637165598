<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<div class="tw-flex tw-justify-between tw-mb-8">
    <ibm-breadcrumb [items]="breadcrumbs" [noTrailingSlash]="true"> </ibm-breadcrumb>

    <div class="tw-flex tw-space-x-2">
        <app-scalara-button (click)="openDeleteBuildingOverlay()" variant="ghost" svgName="24_delete.svg">
            {{ 'CONTROLS.BUTTONS.REMOVE' | translate }}
        </app-scalara-button>
        <app-scalara-button (click)="openEditBuildingOverlay()" variant="ghost" svgName="24_edit.svg">
            {{ 'CONTROLS.BUTTONS.EDIT' | translate }}
        </app-scalara-button>
    </div>
</div>

<div class="tw-mb-16">
    <div class="tw-mb-12">
        <div class="tw-s-label-12-22-semibold tw-text-scalaraGray-03">Gebäude</div>
        <div class="tw-s-head-18-26-bold">{{ building?.name }}</div>
    </div>

    <div class="tw-grid tw-grid-cols-4 tw-mb-10">
        <div class="tw-col-span-1 tw-s-label-12-22-semibold tw-text-scalaraGray-03 tw-space-y-2">
            <div>Straße, Nr.</div>
            <div>Adresszusatz</div>
            <div>PLZ</div>
            <div>Ort</div>
        </div>

        <div class="tw-col-span-2 tw-s-body-14-22-regular tw-space-y-2">
            <div>{{ buildingAddress?.streetName }} {{ buildingAddress?.streetNumber }}</div>
            <div>{{ buildingAddress?.streetAddition }}</div>
            <div>{{ buildingAddress?.zipCode }}</div>
            <div>{{ buildingAddress?.area }}</div>
        </div>
    </div>

    <div class="tw-grid tw-grid-cols-4">
        <div class="tw-col-span-1 tw-s-label-12-22-semibold tw-text-scalaraGray-03 tw-space-y-2">
            <div>Laufende Nummer der Grundstücke</div>
            <div>Grundstücksfläche in qm</div>
            <div>Gemarkung</div>
            <div>Flur</div>
            <div>Flurstück</div>
            <div>Wirtschaftsart der Lage</div>
            <div>Baujahr</div>
            <div>Einheitenanzahl</div>
            <div>Etagenanzahl</div>
            <div>Wohnfläche</div>
            <div>Nutzfläche</div>
        </div>

        <div class="tw-col-span-2 tw-s-body-14-22-regular tw-space-y-2">
            <div *ngIf="building?.consecutiveNumberOfProperties; else notSpecifiedLabel">
                {{ building?.consecutiveNumberOfProperties }}
            </div>
            <div *ngIf="building?.plotArea; else notSpecifiedLabel">
                {{ building?.plotArea }}
            </div>
            <div *ngIf="building?.district; else notSpecifiedLabel">
                {{ building?.district }}
            </div>
            <div *ngIf="building?.cadastralDistrict; else notSpecifiedLabel">
                {{ building?.cadastralDistrict }}
            </div>
            <div *ngIf="building?.landParcel; else notSpecifiedLabel">
                {{ building?.landParcel }}
            </div>
            <div *ngIf="building?.economicType; else notSpecifiedLabel">
                {{ building?.economicType }}
            </div>
            <div *ngIf="building?.constructionYear; else notSpecifiedLabel">
                {{ building?.constructionYear }}
            </div>

            <div *ngIf="buildingOverview?.unitCount; else notSpecifiedLabel">{{ buildingOverview?.unitCount }}</div>
            <div *ngIf="building?.floorCount; else notSpecifiedLabel">{{ building?.floorCount }}</div>
            <div *ngIf="buildingOverview?.totalSquareMeters; else notSpecifiedLabel">
                {{ buildingOverview?.totalSquareMeters }} qm
            </div>
            <div *ngIf="buildingOverview?.totalHeatedSquareMeters; else notSpecifiedLabel">
                {{ buildingOverview?.totalHeatedSquareMeters }} qm
            </div>
        </div>
    </div>
</div>

<div class="tw-mb-10" *ngIf="propertyType !== 'MV'">
    <app-shared-ownership-index
        [tableModel]="sharedOwnershipTable"
        [sharedOwnershipCallbacks]="sharedOwnershipCallbacks"
        [isPropertyManager]="isPropertyManager"
    ></app-shared-ownership-index>
</div>

<div class="tw-mb-16">
    <app-energy-certificate-index></app-energy-certificate-index>
</div>

<app-technical-equipment-index></app-technical-equipment-index>

<ng-template #notSpecifiedLabel>
    <div class="s-body-14-22-italic">
        {{ 'PAGES.OWNERSHIP.DETAIL_VIEW.LABEL_NOT_SPECIFIED' | translate }}
    </div>
</ng-template>
