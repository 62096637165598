import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
    selector: 'app-tag-input',
    templateUrl: './tag-input.component.html',
    styleUrls: ['./tag-input.component.scss'],
})
export class TagInputComponent implements ControlValueAccessor {
    public items: string[] = [];
    public inputControl = '';

    public onChange: any = () => {};
    public onTouched: any = () => {};

    @Input() public label = '';

    public constructor(public ngControl: NgControl) {
        ngControl.valueAccessor = this;
    }

    public writeValue(obj: any): void {
        this.items = obj || [];
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public removeItem(itemToRemove: string): void {
        this.items = this.items?.filter((item) => item !== itemToRemove);
        this.onChange(this.items);
    }

    public onInputChange(): void {
        if (this.inputControl !== '') {
            this.items.push(this.inputControl);
            this.inputControl = '';
            this.onChange(this.items);
        }
    }
}
