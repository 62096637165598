<div *ngIf="isLoading" class="is-loading">
    <div class="d-flex align-items-center justify-content-center mt-4r">
        <ibm-inline-loading> </ibm-inline-loading>
    </div>
</div>

<div class="mt-3-75r">
    <div class="mb-0-5r s-head-16-24-bold">
        {{ 'PAGES.ANNUAL_STATEMENT.DETAILS.LABEL_CONSUMPTION_DISTRIBUTION' | translate }}
    </div>
    <div class="d-flex justify-space-between mb-1-5r">
        <div class="s-body-14-22-regular">
            {{ 'PAGES.ANNUAL_STATEMENT.DETAILS.INFO_CONSUMPTION_DISTRIBUTION' | translate }}
        </div>

        <app-scalara-button
            *ngIf="isPropertyManager && wss?.status === 'DRAFT'"
            variant="ghost"
            svgName="24_add.svg"
            (click)="openAddConsumptionDistributionKeyOverlay()"
        >
            {{ 'CONTROLS.BUTTONS.ADD' | translate }}
        </app-scalara-button>
    </div>
    <app-table
        emptyText="PAGES.ANNUAL_STATEMENT.DETAILS.EMPTY_CONSUMPTION_TABLE"
        [header]="tableModel.header"
        [data]="tableModel.data"
    >
    </app-table>
</div>
