import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, Subject, tap } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { Person } from 'src/app/generated-sources/base';
import { OperationsService } from 'src/app/generated-sources/base/api/operations.service';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';

@Component({
    selector: 'app-confirm-surrogate',
    templateUrl: './confirm-surrogate.component.html',
    styleUrls: ['./confirm-surrogate.component.scss'],
})
export class ConfirmSurrogateComponent extends OverlayChildComponent implements OnDestroy, OnInit {
    private unsubscribe$ = new Subject<void>();
    public personId?: string;
    public isLoading = false;
    public persons?: Person[];
    public selectedPerson?: Person;

    public constructor(
        private location: Location,
        private operationsService: OperationsService,
        private route: ActivatedRoute,
        private toast: ToastService,
        private router: Router,
        private authService: AuthService
    ) {
        super();
    }

    public abort(): void {
        this.location?.back();
    }
    public approve(): void {
        this.operationsService
            .setSurrogateWithUserForPerson(String(this.route.snapshot.paramMap.get('personId')))
            .pipe(
                tap((Result?) => {
                    this.toast.showSuccess(
                        'Erfolgreich Vertreten',
                        this.selectedPerson?.firstName +
                            ' ' +
                            this.selectedPerson?.lastName +
                            ' / ' +
                            this.selectedPerson?.companyName
                    );
                }),
                finalize(() => {
                    this.authService.signOut();
                    this.router.navigate(['/sign-in']);
                })
            )
            .subscribe();
    }

    public ngOnInit(): void {
        this.isLoading = true;
        this.personId = String(this.route.snapshot.paramMap.get('personId'));
        this.operationsService
            .findAll()
            .pipe(
                tap((result) => {
                    this.persons = result;
                    this.selectedPerson = this.persons?.find((person) => person.id === this.personId);
                }),
                finalize(() => (this.isLoading = false))
            )
            .subscribe();
    }
    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }
}
