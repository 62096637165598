<div *ngIf="bookings">
    <div class="tw-mb-4 d-flex justify-content-end tw-items-center tw-space-x-2">
        <app-search *ngIf="searchId" [searchInputId]="searchId"></app-search>

        <app-bookings-table-filter *ngIf="showBookingsFilter"></app-bookings-table-filter>
    </div>
    <div *ngIf="accountBalance !== undefined" class="tw-mb-4 d-flex justify-content-end">
        <div ibmRow class="mb-05">
            <div class="tw-s-label-12-24-semibold-grey">Saldo in €</div>
            <div ibmCol class="s-head-16-24-bold text-right mr-05">{{ accountBalance | eurocent }}</div>
        </div>
    </div>

    <app-table
        [emptyText]="'PAGES.BOOKINGS.BOOKINGS_OVERVIEW.EMPTY_TABLE'"
        [header]="tableModel.header"
        [data]="tableModel.data"
        [tableSearchId]="searchId ?? 'bookingTable'"
        [isLoading]="isLoading"
    >
    </app-table>
</div>
