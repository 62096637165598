<div *ngIf="!withLabel" class="template-container">
    <div *ngIf="!userAvatar" class="icon icon--{{ pixelSize }} icon--{{ color }}  icon--border--{{ borderColor }}">
        <svg-icon src="/assets/icons/{{ image }}"></svg-icon>
    </div>

    <img class="tw-rounded-full tw-h-10 tw-w-10" *ngIf="userAvatar" src="{{ userAvatar | imageBase64 }}" alt="" />

    <div *ngIf="text" class=" {{ textFormat }} {{ distance }} {{ textClass }}">{{ text }}</div>
</div>

<div *ngIf="withLabel" class="template-container">
    <div *ngIf="!userAvatar" class="icon icon--{{ pixelSize }} icon--{{ color }} icon--border--{{ borderColor }}">
        <svg-icon src="/assets/icons/{{ image }}"></svg-icon>
    </div>

    <img class="tw-rounded-full tw-h-10 tw-w-10" *ngIf="userAvatar" src="{{ userAvatar | imageBase64 }}" alt="" />

    <div class="{{ distance }}">
        <div ibmRow class="pb-1-5r">
            <div
                class="tagContainer"
                *ngIf="person?.type === 'NAT_PERSON'"
                class="label label-green d-inline-block s-label-12-16-regular"
            >
                {{ 'ENTITIES.PERSON.LABEL_PERSON_TYPE_NAT_PERSON' | translate }}
            </div>
            <div
                class="tagContainer"
                *ngIf="person?.type === 'COMPANY'"
                class="label label-purple d-inline-block s-label-12-16-regular"
            >
                {{ 'ENTITIES.PERSON.LABEL_PERSON_TYPE_COMPANY' | translate }}
            </div>
            <ng-content></ng-content>
        </div>
        <div ibmRow class="{{ textFormat }} {{ textClass }}">
            {{ text }}
        </div>
        <div ibmRow></div>
    </div>
</div>
