<div class="pos-relative">
    <div
        class="password-tooltip tooltip-content-wrapper tooltip-content-wrapper--{{
            layout === 'mobile' ? 'top' : 'right'
        }}"
        *ngIf="this.mediaQueriesService.getCurrentLayoutMode() | async as layout"
    >
        <div content class="tooltip-content s-body-14-20-regular">
            <div class="mb-1r">
                {{ 'CONTROLS.FORMCONTROLS.PASSWORD.TOOLTIP.HEADING' | translate }}
            </div>
            <ul>
                <li *ngFor="let status of requirementErrors; let i = index">
                    <span [ngClass]="{ green: !status, gray: status }">
                        <svg-icon src="/assets/icons/24_checkbox-check.svg"></svg-icon>
                    </span>
                    {{ 'CONTROLS.FORMCONTROLS.PASSWORD.TOOLTIP.TIP' + (i + 1) | translate }}
                </li>
            </ul>
        </div>
    </div>
</div>
