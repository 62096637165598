import { ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Subject, finalize, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { formControl, formControlHasError } from 'src/app/core/utils/common';
import { TooltipKey } from 'src/app/features/account-settings/services/custom-tooltip.service';
import { AutocompletePlaceSelectedItem } from 'src/app/shared/components/scalara-input-address-autocomplete/scalara-input-address-autocomplete.component';
import { countries } from 'src/app/shared/country-data';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import {
    CreateAddressDto,
    CreatePropertyDto,
    PropertiesService,
    Property,
    UpdatePropertyDto,
} from '../../../../generated-sources/base';
import { Country } from '../../../../shared/country';
import { AddPropertySelectionComponent } from '../add-property-selection/add-property-selection.component';

@Component({
    selector: 'app-add-edit-property-form',
    templateUrl: './add-edit-property-form.component.html',
    styleUrls: ['./add-edit-property-form.component.scss'],
})
export class AddEditPropertyFormComponent extends OverlayChildComponent implements OnInit, OnDestroy {
    public isLoading = true;
    public countries: Country[] = countries;
    public imageChangedEvent: any = '';
    public croppedImage: any = '';
    public editingImage = false;
    public editingDetails = false;
    public isImageValid = true;
    public uploadingImage = false;
    private unsubscribe$ = new Subject<void>();
    public propertyId = '';
    public propertyType: Property.PropertyTypeEnum = 'WEG';
    public tooltipKey = TooltipKey;

    public propertyForm = this.formBuilder.group({
        name: ['', [Validators.required]],
        internalPropertyName: [''],
        streetName: ['', [Validators.required]],
        streetNumber: ['', [Validators.required]],
        streetAddition: [''],
        zipCode: ['', [Validators.required]],
        area: ['', [Validators.required]],
        country: ['DE', [Validators.required]],
        plotArea: [null],
        district: [null],
        consecutiveNumberOfProperties: [null],
        cadastralDistrict: [null],
        landParcel: [null],
        economicType: [null],
    });

    @ViewChild('addressComponent', { static: true })
    public addressComponent?: TemplateRef<any>;

    public constructor(
        private formBuilder: UntypedFormBuilder,
        private propertiesService: PropertiesService,
        private router: Router,
        private toastService: ToastService,
        private translateService: TranslateService,
        private overlayService: OverlayService,
        private cd: ChangeDetectorRef
    ) {
        super();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public ngOnInit(): void {
        this.propertyId = this.config?.data?.propertyId;
        this.propertyType = this.config?.data.propertyType;

        switch (this.config?.data?.editingMode) {
            case 'editDetails': {
                this.editingDetails = true;
                break;
            }
            case 'editImage': {
                this.editingImage = true;
                break;
            }
            case 'uploadImage': {
                this.uploadingImage = true;
                break;
            }
        }

        if (this.editingDetails && this.propertyId) {
            this.propertiesService.findOne(this.propertyId).subscribe((property) => {
                const name = this.propertyForm.get('name') as UntypedFormControl;
                this.propertyType = property.propertyType;

                name.clearValidators();
                name.setErrors(null);
                name.updateValueAndValidity();

                this.propertyForm.patchValue({
                    streetName: property.address.streetName,
                    streetNumber: property.address.streetNumber,
                    streetAddition: property.address.streetAddition,
                    zipCode: property.address.zipCode,
                    area: property.address?.area,
                    country: property.address.country || 'DE',
                    internalPropertyName: property.name,
                    plotArea: property?.plotArea,
                    district: property?.district,
                    consecutiveNumberOfProperties: property?.consecutiveNumberOfProperties,
                    cadastralDistrict: property?.cadastralDistrict,
                    landParcel: property?.landParcel,
                    economicType: property?.economicType,
                });
            });
        }

        this.isLoading = false;
    }

    private evaluateToastMessage(): string {
        //ToDo: Erro-Codes implementieren
        if (this.editingDetails) {
            return this.translateService.instant('PROPERTY.ADD_PROPERTY_FORM.TOAST_SUCCESS_MESSAGE_DATA_EDIT');
        }
        if (this.editingImage) {
            return this.translateService.instant('PROPERTY.ADD_PROPERTY_FORM.TOAST_SUCCESS_MESSAGE_FOTO_EDIT');
        }
        if (this.uploadingImage) {
            return this.translateService.instant('PROPERTY.ADD_PROPERTY_FORM.TOAST_SUCCESS_MESSAGE_FOTO_ADD');
        }
        return this.translateService.instant('PROPERTY.ADD_PROPERTY_FORM.TOAST_SUCCESS_MESSAGE');
    }

    //ADD_PROPERTY_FORM in de.json umbennen
    //componennt in add/edit umbenennen

    //Umstellung von eigenem Refresh-Token auf das was vom overlayservice gegeben ist

    public popoulateAddress(data: any): CreateAddressDto {
        return {
            streetName: data.streetName,
            streetNumber: data.streetNumber,
            streetAddition: data.streetAddition,
            zipCode: data.zipCode,
            area: data.area,
            country: data.country,
        };
    }

    public onSubmit(): void {
        this.isLoading = true;
        let observable;
        const nameProperty =
            (this.propertyForm as UntypedFormGroup).get('internalPropertyName')?.value ||
            `${this.propertyType} ${this.propertyForm.value.streetName} ${this.propertyForm.value.streetNumber}, ${this.propertyForm.value.zipCode}, ${this.propertyForm.value.area}`;
        if (this.editingDetails) {
            const updatePropertyDto: UpdatePropertyDto = {
                name: nameProperty,
                address: this.popoulateAddress(this.propertyForm.value),
                plotArea: this.propertyForm.get('plotArea')?.value,
                district: this.propertyForm.get('district')?.value,
                consecutiveNumberOfProperties: this.propertyForm.get('consecutiveNumberOfProperties')?.value,
                cadastralDistrict: this.propertyForm.get('cadastralDistrict')?.value,
                landParcel: this.propertyForm.get('landParcel')?.value,
                economicType: this.propertyForm.get('economicType')?.value,
            };
            observable = this.propertiesService.update(this.propertyId, updatePropertyDto);
        } else {
            if (this.editingImage || this.uploadingImage) {
                observable = this.propertiesService.update(this.propertyId, {
                    imgFull: this.croppedImage.split(',')[1],
                    propertyType: this.propertyType,
                });
            } else {
                const createPropertyDto: CreatePropertyDto = {
                    name: nameProperty,
                    imgFull: this.croppedImage.split(',')[1],
                    propertyType: this.propertyType,
                    buildingName: this.propertyForm.get('name')?.value,
                    address: this.popoulateAddress(this.propertyForm.value),
                    plotArea: this.propertyForm.get('plotArea')?.value ?? undefined,
                    district: this.propertyForm.get('district')?.value,
                    consecutiveNumberOfProperties:
                        this.propertyForm.get('consecutiveNumberOfProperties')?.value ?? undefined,
                    cadastralDistrict: this.propertyForm.get('cadastralDistrict')?.value ?? undefined,
                    landParcel: this.propertyForm.get('landParcel')?.value ?? undefined,
                    economicType: this.propertyForm.get('economicType')?.value ?? undefined,
                };
                observable = this.propertiesService.create(createPropertyDto);
            }
        }
        observable
            .pipe(
                finalize(() => (this.isLoading = false)),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: (property) => {
                    this.router.navigate(['/properties/', property.id, 'base-data']);
                    this.toastService.showSuccess(this.evaluateToastMessage());
                    this.saveEmitter$.next();
                },
                error: (err) => {
                    if (err.status === 400) {
                        this.toastService.showError(
                            this.translateService.instant('PROPERTY.ADD_PROPERTY_FORM.ERROR.400')
                        );
                    }
                },
            });
    }

    public abort(): void {
        if (this.config?.data?.editingMode) {
            this.cancelEmitter$.next();
        } else {
            this.cancelEmitter$.next();

            this.overlayService.removeOverlayComponentFromBody();

            const ref = this.overlayService.open(AddPropertySelectionComponent, {});

            ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

            ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe({
                next: () => {
                    this.saveEmitter$.next();
                },
            });
        }
    }

    public isFormInvalid(): boolean {
        if (this.editingImage || this.uploadingImage) {
            return !this.croppedImage;
        }
        return !this.propertyForm.valid;
    }

    public isInvalid(controlName: string): boolean {
        return formControlHasError(formControl(this.propertyForm, controlName), 'required');
    }

    public fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
        if (this.imageChangedEvent !== null) {
            this.isImageValid = true;
        }
    }
    public imageCropped(event: ImageCroppedEvent): void {
        this.croppedImage = event.base64;
    }
    public loadImageFailed(): void {
        // show message
        this.imageChangedEvent = null;
        this.isImageValid = false;
    }

    // This is an example of further filtration which can take place after
    // preliminary filtration and is optional.
    public onDropped(event: any): void {
        this.fileChangeEvent(event);
    }

    public resetImageUploader(): void {
        const fileInput = document.getElementsByClassName('image-uploader')[0] as HTMLInputElement;
        fileInput.setAttribute('value', 'null');
        this.fileChangeEvent('');
    }

    public showDragArea(): boolean {
        return this.imageChangedEvent && this.imageChangedEvent.target.files.length > 0;
    }

    public getTag(): { color: string; label: string } {
        if (this.propertyType === 'MV') {
            return { color: 'purple', label: 'Mietverwaltung' };
        } else if (this.propertyType === 'SEV') {
            return { color: 'orange', label: 'Sondereigentumsverwaltung' };
        }
        return { color: 'green', label: 'Wohnungseigentumsverwaltung' };
    }

    public getTooltipI18Key(where: 'NAME_INTERNAL' | 'BUILDING_NAME'): string {
        const BASE = 'PAGES.TOOLTIPS.ADD_EDIT_PROPERTY';
        const propType = this.propertyType;
        return `${BASE}.${where}.${propType}`;
    }

    public onAutocompletePlaceSelected(item: AutocompletePlaceSelectedItem): void {
        this.propertyForm.patchValue({
            streetName: item.formData.streetName,
            streetNumber: item.formData.streetNumber,
            zipCode: item.formData.postalCode,
            area: item.formData.city,
            country: item.formData.countryCode,
        });
        const formControlsLabels = ['streetName', 'streetNumber', 'zipCode', 'area', 'country'];
        formControlsLabels.forEach((label) => {
            this.propertyForm.controls[label]?.markAsTouched();
            this.propertyForm.controls[label]?.markAsDirty();
            this.propertyForm.controls[label]?.updateValueAndValidity();
        });
        this.cd.detectChanges();
    }
}
