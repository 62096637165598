<ng-container *ngIf="fileToView && showModal">
    <app-file-viewer [fileToView]="fileToView" (closeViewer)="handleModal()"></app-file-viewer>
</ng-container>

<div class="tw-space-y-4">
    <div
        class="tw-items-center tw-flex tw-justify-between tw-border-b-[1px] tw-border-solid tw-border-scalaraGray-05 tw-px-2"
        *ngFor="let file of documentList"
    >
        <div class="tw-flex tw-space-x-4">
            <div *ngIf="showDocumentIcon" class="tw-w-6 tw-fill-scalaraGray-03">
                <svg-icon src="/assets/icons/24_dokument.svg"></svg-icon>
            </div>
            <div class="s-body-14-20-regular">{{ file.filename }}</div>
        </div>

        <div class="tw-flex">
            <app-scalara-button
                svgName="24_download.svg"
                variant="icon-only"
                (click)="handleFile(file, true)"
            ></app-scalara-button>
            <app-scalara-button
                svgName="24_eye.svg"
                variant="icon-only"
                (click)="handleFile(file, false)"
            ></app-scalara-button>
        </div>
    </div>
</div>
