import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, map, Observable, of, shareReplay, switchMap } from 'rxjs';
import { formatDateDDMMYYYY, getDateWithoutHhMmSs } from 'src/app/core/utils/dateUtils';
import { EconomicPlansService } from 'src/app/generated-sources/accounting';
import { LedgerCustomService } from './ledger-custom.service';

export type BusinessYearDates = {
    startDate: Date;
    endDate: Date;
};

type BusinessYearItem = {
    content: string;
    selected: boolean;
    id: string;
};

export type BusinessYear = BusinessYearDates & BusinessYearItem;

const CUSTOM_BUSINESS_YEAR_ID = '';

@Injectable({
    providedIn: 'root',
})
export class EconomicPlanCustomService {
    private constructor(
        private economicPlanService: EconomicPlansService,
        private ledgerCustomService: LedgerCustomService
    ) {}

    public getEconomicPlans$(): typeof this.economicPlans$ {
        return this.economicPlans$;
    }

    // triggers an update of the list of economic plans.
    // economic plans are used to select the business year in the BusinessYearSelectorComponent dropdown
    public refreshEconomicPlanList(): void {
        // force refresh with same value
        console.log('called refresh economic plan');
        this.refreshEconomicPlanTrigger$.next(null);
    }

    public getBusinessYears$(): typeof this.businessYearsWithSelectedOne$ {
        return this.businessYearsWithSelectedOne$;
    }

    public getSelectedBusinessYear$(): typeof this.selectedBusinessYear$ {
        return this.selectedBusinessYear$;
    }

    public getSelectedBusinessYearId$(): Observable<string | null> {
        return this.businessYearSelectedId$.asObservable();
    }

    public setSelectedBusinessYearId(id: string): void {
        // if id is empty string, then update businessYearSelectedId$ with same value for refresh
        // do not allow user to have unselected business year
        if (!id) {
            return this.businessYearSelectedId$.next(this.businessYearSelectedId$.getValue());
        }
        this.businessYearSelectedId$.next(id);
    }

    //  ECONOMIC PLANS PART
    private readonly refreshEconomicPlanTrigger$ = new BehaviorSubject<null>(null);

    private readonly economicPlans$ = combineLatest([
        this.ledgerCustomService.getLedgerId$(),
        this.refreshEconomicPlanTrigger$,
    ]).pipe(switchMap(([ledgerId]) => (ledgerId ? this.economicPlanService.findAll(ledgerId) : of([]))));

    //  BUSINESS YEAR PART
    private readonly businessYearsUnselected$ = this.economicPlans$.pipe(
        map((economicPlan) => economicPlan.filter((item) => item.status === 'ACTIVE')),
        switchMap((economicPlans) => {
            const businessYears: BusinessYear[] = economicPlans.map((economicPlan) => ({
                startDate: getDateWithoutHhMmSs(economicPlan.startDate),
                endDate: getDateWithoutHhMmSs(economicPlan.endDate),
                content: `${formatDateDDMMYYYY(economicPlan.startDate)} – ${formatDateDDMMYYYY(economicPlan.endDate)}`,
                selected: false,
                id: economicPlan.id,
            }));

            //  remove duplicates
            const businessYearsWithUniqueItems: BusinessYear[] = [];
            businessYears.forEach((item) => {
                if (businessYearsWithUniqueItems.find((i) => i.content === item.content)) {
                    return;
                }
                businessYearsWithUniqueItems.push(item);
            });
            businessYearsWithUniqueItems.sort((a, b) => a.startDate.getTime() - b.startDate.getTime());
            return of(businessYearsWithUniqueItems);
        })
    );

    //  custom id by defualt, when user did no interaction with businessYearSelector
    //  or when there are no economic plans -> no business years of it
    //  here could be stored old value from prev. ledger if user change ledger in FE - must be handled
    private readonly businessYearSelectedId$ = new BehaviorSubject<string>(CUSTOM_BUSINESS_YEAR_ID);

    private readonly businessYearsWithSelectedOne$ = combineLatest([
        this.businessYearsUnselected$,
        this.businessYearSelectedId$,
    ]).pipe(
        switchMap(([businessYearsUnselected, selectedId]) => {
            const businessYearsCopy = [...businessYearsUnselected];
            //  if selected is not set or it is old value from prev. ledger then set first correct as selected
            if (selectedId === null || !businessYearsCopy.find((i) => i.id === selectedId)) {
                this.selectFirstCorrectBusinessYear(businessYearsCopy);
                return of(businessYearsCopy);
            }
            businessYearsCopy.forEach((item) =>
                item.id === selectedId ? (item.selected = true) : (item.selected = false)
            );
            return of(businessYearsCopy);
        }),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    private readonly selectedBusinessYear$ = this.businessYearsWithSelectedOne$.pipe(
        map((businessYears) => {
            const res = businessYears.find((i) => i.selected);
            return res;
        })
    );

    private selectFirstCorrectBusinessYear(businessYears: BusinessYear[]): void {
        if (businessYears.length > 0) {
            const currentDate = new Date();
            const currentYear = currentDate.getFullYear();

            let nearestYearObject = businessYears[0];
            let nearestDifference = Infinity;

            businessYears.forEach((item) => {
                const endDate = new Date(item.endDate);

                const endYear = endDate.getFullYear();

                const endDifference = Math.abs(currentYear - endYear);

                if (endDifference < nearestDifference) {
                    nearestDifference = endDifference;
                    nearestYearObject = item;
                }
            });

            this.businessYearSelectedId$.next(nearestYearObject.id);
        }
    }
}
