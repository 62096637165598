<ibm-loading *ngIf="this.vm?.isLoading" [isActive]="true" [size]="'normal'" [overlay]="true"></ibm-loading>
<app-overlay-container
    [headlineTitle]="'Zahlungsverbindung löschen'"
    [translateHeadlineTitle]="false"
    [sizeIcon]="72"
    [image]="'40_delete.svg'"
    colorIcon="red"
    class="tw-flex justify-content-center"
>
    <div class="tw-s-head-16-24-bold">
        {{ 'PAGES.CONTACT.DELETE_BANK_ACCOUNT_OVERLAY.SUBHEADING1' | translate }}
    </div>
    <div class="tw-mt-6">{{ 'PAGES.CONTACT.DELETE_BANK_ACCOUNT_OVERLAY.SUBHEADING2' | translate }}</div>
    <div *ngIf="vm?.bankAccount">
        <div class="tw-p-4 tw-bg-scalaraGray-06 tw-mt-10 lg:tw-mt-14">
            <div *ngFor="let row of vm?.contactDetailRows">
                <div contactDetailRow [row]="row"></div>
            </div>
        </div>
    </div>

    <div class="tw-mt-16 lg:tw-mt-28 tw-flex tw-justify-between tw-flex-col lg:tw-flex-row-reverse">
        <app-scalara-button
            class="tw-mb-6 tw-w-full lg:tw-w-auto lg:tw-mb-0"
            [buttonStyles]="'tw-w-full lg:tw-w-auto'"
            variant="danger"
            (click)="submit()"
            >{{ 'CONTROLS.BUTTONS.OVERLAY_REMOVE' | translate }}</app-scalara-button
        >
        <app-scalara-button
            class="tw-mr-4 tw-w-full lg:tw-w-auto"
            [buttonStyles]="'tw-w-full lg:tw-w-auto'"
            variant="outline"
            (click)="abort()"
            >{{ 'CONTROLS.BUTTONS.CANCEL' | translate }}</app-scalara-button
        >
    </div>
</app-overlay-container>
