<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"></ibm-loading>

<app-overlay-container headlineTitle="CONTROLS.BUTTONS.EDIT" containerSize="small">
    <app-user-infos-form [person]="this.person"></app-user-infos-form>

    <div class="tw-flex tw-justify-between tw-mt-[120px] tw-mb-20">
        <app-scalara-button (click)="onCancel()" variant="outline">{{
            'CONTROLS.BUTTONS.CANCEL' | translate
        }}</app-scalara-button>

        <app-scalara-button (click)="onSubmit()" [disabled]="isInvalid()">
            {{ 'CONTROLS.BUTTONS.SAVE' | translate }}</app-scalara-button
        >
    </div>
</app-overlay-container>
