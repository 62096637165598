import { DatePipe } from '@angular/common';
import {
    AfterContentChecked,
    ApplicationRef,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { I18n, Table, TableModel } from 'carbon-components-angular';
import { BehaviorSubject, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { ReceiptsService } from 'src/app/generated-sources/accounting';
import { CustomTableItem } from '../../CustomTableItem';
import { FilterableHeaderItem } from '../../FilterableHeaderItem';
import { HeaderItem } from '../../interfaces/header-item';
import { TableItem } from '../../interfaces/table-item';
import { TableSearchService } from '../../services/table-search.service';

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent extends Table implements OnInit, AfterContentChecked, OnDestroy, OnChanges {
    private unsubscribe$ = new Subject<void>();

    @Input() public itemsPerPage = 25;

    @Input() public emptyText? = '';

    @Input() public emptyTextLink? = '';

    @Input() public emptyTextLinkLabel? = '';

    /*
     * Default case: a table is expected to have a search.
     * if no search is needed on that table, set tableSearchId to ''
     */
    @Input() public tableSearchId = 'default';

    @Input() public isZebraTable = false;

    @Input() public isLoading = false;

    @Output() public openAddAccount = new EventEmitter<void>();

    @Output() public customButton = new EventEmitter<void>();

    @Output() public dynamicIconItemClick = new EventEmitter<any>();

    @Output() public handleFile = new EventEmitter<void>();

    @Output() public handleAction = new EventEmitter<any>();

    /**
     * Emits an object containing the search parameters and the filtered data from the table
     */
    @Output() public searchResult = new EventEmitter<any>();

    /**
     * Input of header and data of the table model should happen seperatly so
     * angulars rerender will trigger. In daily use, the header is mostly synchronous
     * but the data will be asynchronous.
     */
    @Input() public header?: (string | HeaderItem)[];

    @Input() public data?: TableItem[][];

    @ViewChild('domTable')
    public domTable?: ElementRef;

    @ViewChild('customTableItem', { static: true })
    public customTableItem?: TemplateRef<any>;

    @ViewChild('formattedCentTableItem', { static: true })
    public formattedCentTableItem?: TemplateRef<any>;

    @ViewChild('formattedDateTableItem', { static: true })
    public formattedDateTableItem?: TemplateRef<any>;

    @ViewChild('formattedDateTimeTableItem', { static: true })
    public formattedDateTimeTableItem?: TemplateRef<any>;

    @ViewChild('boldTableItem', { static: true })
    public boldTableItem?: TemplateRef<any>;

    @ViewChild('rightAlignedHeaderItem', { static: true })
    public rightAlignedHeaderItem?: TemplateRef<any>;

    @ViewChild('customHeaderItem', { static: true })
    public customHeaderItem?: TemplateRef<any>;

    @ViewChild('dynamicComponentHeader', { static: true })
    public dynamicComponentHeader?: TemplateRef<any>;

    @ViewChild('boldHeaderItem', { static: true })
    public boldHeaderItem?: TemplateRef<any>;

    @ViewChild('notFoundText', { static: true })
    public notFoundText?: TemplateRef<any>;

    @ViewChild('imageTableItem', { static: true })
    public imageTableItem?: TemplateRef<any>;

    @ViewChild('iconWithTooltipItem', { static: true })
    public iconWithTooltipItem?: TemplateRef<any>;

    @ViewChild('iconWithStatusItem', { static: true })
    public iconWithStatusItem?: TemplateRef<any>;

    @ViewChild('dynamicIconItem', { static: true })
    public dynamicIconItem?: TemplateRef<any>;

    @ViewChild('iconButtonItem', { static: true })
    public iconButtonItem?: TemplateRef<any>;

    @ViewChild('editButtonItem', { static: true })
    public editButtonItem?: TemplateRef<any>;

    @ViewChild('buttonItem', { static: true })
    public buttonItem?: TemplateRef<any>;

    @ViewChild('textAndButtonItem', { static: true })
    public textAndButtonItem?: TemplateRef<any>;

    @ViewChild('personWithAvatar', { static: true })
    public personWithAvatar?: TemplateRef<any>;

    @ViewChild('contentWithTagStyle', { static: true })
    public contentWithTagStyle?: TemplateRef<any>;

    @ViewChild('twoActionsButtonsItem', { static: true })
    public twoActionsButtonsItem?: TemplateRef<any>;

    @ViewChild('apportionableLabelItem', { static: true })
    public apportionableLabelItem?: TemplateRef<any>;

    @ViewChild('filesActionsItem', { static: true })
    public filesActionsItem?: TemplateRef<any>;

    @ViewChild('textWithIconItem', { static: true })
    public textWithIconItem?: TemplateRef<any>;

    @ViewChild('textWithCancelledLabel', { static: true })
    public textWithCancelledLabel?: TemplateRef<any>;

    @ViewChild('actionsItem', { static: true })
    public actionsItem?: TemplateRef<any>;

    @ViewChild('isSimpleLinkedItem', { static: true })
    public isSimpleLinkedItem?: TemplateRef<any>;

    @ViewChild('dynamicComponentItem', { static: true })
    public dynamicComponentItem?: TemplateRef<any>;

    @ViewChild('accountChartsButtonsItem', { static: true })
    public accountChartsButtonsItem?: TemplateRef<any>;

    @ViewChild('badgeItem', { static: true })
    public badgeItem?: TemplateRef<any>;

    @ViewChild('imageTableItemWithLabel', { static: true })
    public imageTableItemWithLabel?: TemplateRef<any>;

    public paginationTableModel = new TableModel();

    public unFilteredTableData: TableItem[][] = [];

    public searchValue = 'initial_empty_search_value';

    public isSearchInitialized = false;

    public constructor(
        protected override elementRef: ElementRef,
        protected override applicationRef: ApplicationRef,
        protected override i18n: I18n,
        private translateService: TranslateService,
        private receiptsService: ReceiptsService,
        private tableSearchService: TableSearchService,
        private datePipe: DatePipe,
        private changeDetectorRef: ChangeDetectorRef,
        public router: Router,
        public route: ActivatedRoute
    ) {
        super(elementRef, applicationRef, i18n);
    }

    public clickedRow($event: any): void {
        if (!this.isRowDisabled($event)) {
            this.rowClick.emit($event);
        }
    }

    private retriggerTableSearch$ = new BehaviorSubject<void>(undefined);
    private tableSearch$ = this.retriggerTableSearch$.pipe(
        switchMap(() => this.tableSearchService.getCurrentInputSearch$().pipe(takeUntil(this.unsubscribe$)))
    );

    public ngOnInit(): void {
        if (this.tableSearchId !== '') {
            this.tableSearch$.subscribe((search) => {
                //matching search input with table
                if (search && search.searchInputId.includes(this.tableSearchId)) {
                    this.searchValue = search.searchInput;
                }
                if (this.searchValue !== 'initial_empty_search_value') {
                    if (this.searchValue !== '') {
                        this.filteringTableModel();
                    } else {
                        this.model.data = this.prepareData(this.unFilteredTableData);
                    }
                    this.data = this.mapFormattedDataTable();
                    this.paginationTableModel.data = this.prepareData(this.data);
                    this.paginationTableModel.pageLength = this.itemsPerPage;

                    this.selectPage(1);
                    this.changeDetectorRef.detectChanges();
                }
            });
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!this.model) {
            this.model = new TableModel();
        }

        // intiializing data
        if (this.data && this.data.length > 0 && this.data[0].length > 0) {
            this.paginationTableModel.data = this.prepareData(this.data);
            this.paginationTableModel.pageLength = this.itemsPerPage;
            this.selectPage(this.model.currentPage);

            this.unFilteredTableData = this.data;

            // filtering once from search stored in url
            if (!this.isSearchInitialized) {
                this.retriggerTableSearch$.next();
                this.isSearchInitialized = true;
            }

            // if table has no entries
        } else if (this.data && this.data.length == 0) {
            this.model.data = [];
            this.selectPage(1);
        } else {
            this.model = new TableModel();
        }

        // initializing headers
        if (this.header && this.header.length > 0) {
            this.model.header = this.header.map((item: string | HeaderItem) => {
                let additionalParams;
                if (typeof item !== 'string') {
                    additionalParams = {
                        ...(item?.width ? { style: { width: item.width } } : {}),
                        ...(!item.data.key && !item.template ? { sortable: false } : {}),
                    };
                }
                if (typeof item === 'string') {
                    //  disable sorting for blank headers by optinally passing sortable: false param
                    additionalParams = {
                        ...(item?.length === 0 ? { sortable: false } : {}),
                    };
                    return new FilterableHeaderItem({
                        data: item ? this.translateService.instant(item) : '',
                        ...additionalParams,
                    });
                }

                const label = item.data.key ? this.translateService.instant(item.data.key, item.data.params) : '';

                //  data prop should be a string in general, but can be an object if template is used
                //  data['label'] is introduced to render text in templates
                const filterableHeaderItemParams = {
                    ...item,

                    ...(item?.template
                        ? {
                              data: Object.assign(item.data, {
                                  label,
                              }),
                              template: this[item.template!] as TemplateRef<any>,
                          }
                        : { data: label }),
                    ...additionalParams,
                };

                return new FilterableHeaderItem({
                    ...filterableHeaderItemParams,
                });
            });
        }

        if (
            this.header &&
            this.header.length > 0 &&
            this.data &&
            this.data.length > 0 &&
            this.data[0].length !== this.header.length &&
            this.data[0][this.data[0].length - 1].template !== 'iconWithStatusItem'
        ) {
            console.error(
                'header array size is different from columns array size. Add an empty string in the header array to avoid problems'
            );
        }
    }

    public ngAfterContentChecked(): void {
        if (this.model.hasExpandableRows()) {
            this.model.rowsExpanded.map((isExpanded, index) => {
                if (
                    isExpanded &&
                    this.model.data &&
                    this.model.data[index] &&
                    this.model.data[index][0] &&
                    this.model.data[index][0].expandedData.length === 0 &&
                    !this.model.data[index][0].data.extraData.visited &&
                    this.model.data[index][0].data.extraData.bookings
                ) {
                    this.model.data[index][0].data.extraData.visited = true;
                    this.model.data[index][0].expandedData.isLoading = true;
                    this.receiptsService
                        .findOne(
                            this.model.data[index][0].data.extraData.ledgerId,
                            this.model.data[index][0].data.extraData.bookings.id
                        )
                        .pipe(
                            tap((receipt) => {
                                if (this.model.data && this.model.data[index] && this.model.data[index][0]) {
                                    this.model.data[index][0].expandedData.bookings = receipt.bookings;
                                    this.model.data[index][0].expandedData.isLoading = false;
                                }
                            }),
                            takeUntil(this.unsubscribe$)
                        )
                        .subscribe();
                }
            });
        }
    }

    public override ngAfterViewInit(): void {
        super.ngAfterViewInit();
        this.addingIntercomAttributes();
    }

    // Creating for every row a custom attribute with the content of the first cell of each,
    // In order to target every row easily from the intercom app.
    public addingIntercomAttributes(): void {
        const rows = this.elementRef.nativeElement.querySelectorAll('tbody tr');
        for (let i = 0; i < rows.length; i++) {
            if (rows[i].querySelector('a')) {
                const label = rows[i].querySelector('a').innerHTML;
                rows[i].setAttribute('data-intercom', label);
            }
        }
    }

    public override ngOnDestroy(): void {
        super.ngOnDestroy();
        this.unsubscribe$.next();
    }

    public selectPage(page: number): void {
        if (this.data && this.data.length > 0 && this.data[0].length > 0) {
            const pages = [];

            for (let i = 0; i < this.data.length; i += this.itemsPerPage) {
                const chunk = this.data.slice(i, i + this.itemsPerPage);
                pages.push(chunk);
            }

            this.model.data = this.prepareData(pages[page - 1]);
            this.paginationTableModel.currentPage = page;
            this.model.currentPage = page;
        }
    }

    // custom function to sort all data, not just the one on the page
    public override doSort(index: number): void {
        this.model.data = this.prepareData(this.data!);
        this.model.header[index].ascending = this.model.header[index].descending;
        this.model.sort(index);
        this.data = this.mapFormattedDataTable();
        this.selectPage(this.model.currentPage);
    }

    protected prepareData(data: TableItem[][]): CustomTableItem[][] {
        return data.map((row: TableItem[]) =>
            row.map((item: TableItem) => {
                if (
                    item.template === 'formattedDateTimeTableItem' &&
                    typeof item.data.label === 'string' &&
                    item.data.label.indexOf('Z') > 0
                ) {
                    item.data.label = new Date(item.data.label).toISOString().replace('Z', '');
                }
                if (item.expandedTemplate && item.template) {
                    const customItem = new CustomTableItem({
                        data: {
                            label: item.data.label,
                            link: item.data.link,
                            textColor: item.data.textColor,
                            iconSrc: item.data.iconSrc,
                            extraData: item.data.extraData,
                            buttonLink: item.data.buttonLink,
                            rightAligned: item.data.rightAligned,
                        },
                        template:
                            typeof item.template === 'string'
                                ? (this[item.template] as TemplateRef<any>)
                                : item.template, // eslint-disable-line
                        expandedTemplate: item.expandedTemplate,
                        expandedData: item.expandedData ?? [],
                    });
                    return customItem;
                } else if (item.template) {
                    return new CustomTableItem({
                        data: {
                            label: item.data.label,
                            link: item.data.link,
                            textColor: item.data.textColor,
                            iconSrc: item.data.iconSrc,
                            extraData: item.data.extraData,
                            buttonLink: item.data.buttonLink,
                            rightAligned: item.data.rightAligned,
                        },
                        template:
                            typeof item.template === 'string'
                                ? (this[item.template] as TemplateRef<any>)
                                : item.template, // eslint-disable-line
                    });
                }
                return new CustomTableItem({
                    data: { ...item.data },
                    template: this['customTableItem'] as TemplateRef<any>, // eslint-disable-line
                });
            })
        );
    }

    private isRowDisabled(rowNumber: number): boolean {
        const index = this.itemsPerPage * (this.model.currentPage - 1) + rowNumber;
        if (
            this.data &&
            this.data[index] &&
            this.data[index][this.data[index].length - 1].data.label === 'row-disabled'
        ) {
            return true;
        } else {
            return false;
        }
    }

    public openAddAccountEvent(data: any, isEditing: boolean): void {
        data.isEditing = isEditing;
        this.openAddAccount.emit(data);
    }

    public customButtonEvent(data: any): void {
        this.customButton.emit(data);
    }

    public handleFiles(data: any, action: 'delete' | 'download' | 'view'): void {
        data.isDownload = action === 'download';
        data.isDelete = action === 'delete';
        this.handleFile.emit(data);
    }

    public handleActions(data: any, action: string): void {
        const eventObject = { data, action };
        this.handleAction.emit(eventObject);
    }

    public onClickDynamicIconItem(data: any): void {
        const eventObject = { data };
        this.dynamicIconItemClick.emit(eventObject);
    }

    public filteringTableModel(): void {
        const tempUnfilteredData = this.prepareData(this.unFilteredTableData);

        const apportionableTranslation = this.translateService.instant('ACCOUNTING.ACCOUNT_DETAILS.APPORTIONABLE');
        const notApportionableTranslation = this.translateService.instant(
            'ACCOUNTING.ACCOUNT_DETAILS.NOT_APPORTIONABLE'
        );
        const incompleteDataTranslation = this.translateService.instant(
            'PAGES.DISTRIBUTION_KEYS.OVERVIEW.INCOMPLETE_DATA'
        );
        const noDataTranslation = this.translateService.instant('PAGES.DISTRIBUTION_KEYS.OVERVIEW.NO_DATA');

        this.model.data = tempUnfilteredData.filter((row: TableItem[]) => {
            let isRowFiltered = false;
            row.map((itemRow: any) => {
                const templateName = itemRow.template._declarationTContainer.localNames[0];
                if (
                    templateName === 'customTableItem' ||
                    templateName === 'boldTableItem' ||
                    templateName === 'notFoundText' ||
                    templateName === 'personWithAvatar' ||
                    templateName === 'contentWithTagStyle' ||
                    templateName === 'textWithIconItem' ||
                    templateName === 'textWithCancelledLabel' ||
                    templateName === 'dynamicComponentItem'
                ) {
                    isRowFiltered =
                        isRowFiltered ||
                        (!!itemRow.data.label &&
                            (itemRow.data.label as string).toLowerCase().includes(this.searchValue.toLowerCase()));
                }
                if (templateName === 'formattedCentTableItem') {
                    const formattedNumber = new Intl.NumberFormat('de-DE', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }).format(itemRow.data.label / 100);
                    isRowFiltered =
                        isRowFiltered || (!!formattedNumber && formattedNumber.toString().includes(this.searchValue));
                }
                if (templateName === 'apportionableLabelItem') {
                    if (itemRow.data.extraData.account.apportionable == true) {
                        isRowFiltered =
                            isRowFiltered ||
                            (!!apportionableTranslation &&
                                apportionableTranslation.toLowerCase().includes(this.searchValue.toLowerCase()));
                    }
                    if (itemRow.data.extraData.account.apportionable == false) {
                        isRowFiltered =
                            isRowFiltered ||
                            (!!notApportionableTranslation &&
                                notApportionableTranslation.toLowerCase().includes(this.searchValue.toLowerCase()));
                    }
                }
                if (templateName === 'warningNotificationTemplate') {
                    if (itemRow.data.label === 'missingData') {
                        isRowFiltered =
                            isRowFiltered ||
                            (!!noDataTranslation &&
                                noDataTranslation.toLowerCase().includes(this.searchValue.toLowerCase()));
                    }
                    if (itemRow.data.label !== 'missingData') {
                        isRowFiltered =
                            isRowFiltered ||
                            (!!incompleteDataTranslation &&
                                incompleteDataTranslation.toLowerCase().includes(this.searchValue.toLowerCase()));
                    }
                }
                if (templateName === 'dynamicIconItem') {
                    isRowFiltered =
                        isRowFiltered ||
                        (!!itemRow.data.label &&
                            (itemRow.data.label as string).toLowerCase().includes(this.searchValue.toLowerCase()));
                }
                if (templateName === 'formattedDateTableItem') {
                    const formattedDate = this.datePipe.transform(itemRow.data.label as string, 'dd.MM.yyyy');
                    isRowFiltered =
                        isRowFiltered ||
                        (formattedDate ? formattedDate.toLowerCase().includes(this.searchValue.toLowerCase()) : false);
                }
                if (templateName === 'formattedDateTimeTableItem') {
                    const formattedDate = this.datePipe.transform(itemRow.data.label as string, 'dd.MM.yyyy – HH:mm');
                    isRowFiltered =
                        isRowFiltered ||
                        (formattedDate ? formattedDate.toLowerCase().includes(this.searchValue.toLowerCase()) : false);
                }
            });
            return isRowFiltered;
        });
    }

    private mapFormattedDataTable(): TableItem[][] {
        const formatedDataTable: TableItem[][] = [];

        this.model.data.map((item: any[]) => {
            const t: TableItem[] = item.map((itemData) => {
                const formatedItem: TableItem = {
                    ...itemData,
                };
                return formatedItem;
            });

            formatedDataTable.push(t);
        });
        return formatedDataTable;
    }

    public showViewFileButton(fileName: string): boolean {
        const a = fileName.split('.');
        const fileExtension = a[a.length - 1];
        return ['jpg', 'jpeg', 'png', 'pdf', 'PDF', 'JPG', 'PNG', 'JPEG'].indexOf(fileExtension) >= 0;
    }
}
