import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { EurocentPipe } from 'src/app/shared/pipes/eurocent.pipe';

type CellValue = {
    type: 'value';
    value: string;
    rowCellClass?: string;
};

type CellComponent = {
    type: 'component';
    component: any;
    componentData?: any;
    rowCellClass?: string;
};
export type ContactDetailRow = [CellValue, CellComponent | CellValue];

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[contactDetailRow]',
    templateUrl: './contact-detail-row.component.html',
    styleUrls: ['./contact-detail-row.component.scss'],
})
export class ContactDetailRowComponent {
    public constructor(public euroCent: EurocentPipe, public date: DatePipe) {}
    @Input() public row: ContactDetailRow | null = null;
}
