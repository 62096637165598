<div class="tw-flex tw-mb-2" *ngIf="row">
    <div
        class=" tw-text-scalaraGray-03 tw-s-label-12-22-semibold tw-shrink-0
         tw-basis-[120px] lg:tw-basis-[160px] tw-s-label-12-22-semibold
         {{ row[0].rowCellClass }}"
    >
        {{ row[0].value ? row[0].value : ('USER_MANAGEMENT.PROFILE_VIEW.NOT_SPECIFIED' | translate) }}
    </div>
    <div class=" tw-s-body-14-22-regular tw-break-all {{ row[1].rowCellClass }}" *ngIf="row[1].type === 'value'">
        {{ row[1].value ? row[1].value : ('USER_MANAGEMENT.PROFILE_VIEW.NOT_SPECIFIED' | translate) }}
    </div>
    <ng-container *ngIf="row[1].type === 'component'">
        <div
            class="mobile-accordion__second-column {{ row[1].rowCellClass }}"
            dynamicComponentWrapper
            [component]="row[1].component"
            [componentData]="row[1].componentData"
        ></div>
    </ng-container>
</div>
