import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-table-actions',
    templateUrl: './table-actions.component.html',
    styleUrls: ['./table-actions.component.scss'],
})
export class TableActionsComponent {
    @Output() public openDeleteRelationOvervlay = new EventEmitter<any>();
    @Output() public openEditRelationOvervlay = new EventEmitter<any>();
    @Input() public data?: any;

    public openDeleteRelationOvervlayEvent(): void {
        this.openDeleteRelationOvervlay.emit(this.data);
    }

    public openEditRelationOvervlayEvent(): void {
        this.openEditRelationOvervlay.emit(this.data);
    }
}
