<ibm-loading *ngIf="isLoading" [isActive]="true" [size]="'normal'" [overlay]="true"></ibm-loading>
<div class="s-head-28-36-bold tw-mb-10">
    {{ 'ACCOUNTING.BANK_ORDERS.HEADLINE' | translate }}
</div>

<div class="tw-my-10" *ngIf="(bankAccounts | async)?.length === 0">
    <app-notification type="info">
        <div class="s-body-14-22-regular">
            <span>{{ 'PAGES.BANK_ACCOUNTS.EMPTY_TABLE' | translate }} </span>
            <a href="/user-management/accounts" class="s-gray-01 s-link-14-22-regular">{{
                'PAGES.BANK_ACCOUNTS.EMPTY_TABLE_LINK_TEXT' | translate
            }}</a>
        </div>
    </app-notification>
</div>

<div
    (click)="navigateToPaymentApprovalsOverview()"
    [ngClass]="{ 'disabled-tile': openPayments.length === 0 }"
    class="d-flex tile tile--shadow tw-px-6 tw-py-[18px] tw-mb-10 justify-space-between"
>
    <div class="tw-flex tw-items-center">
        <span class="s-head-28-36-bold tw-mr-3">{{ openPayments.length }}</span>
        <span class="s-body-14-22-regular tw-mr-5">
            {{ 'PAGES.PAYMENT_APPROVALS.LABEL_WAITING_PAYMENTS' | translate }}
        </span>
        <span class="s-head-28-36-bold tw-mr-3">{{ incompletePayments.length }}</span>
        <span class="s-body-14-22-regular">
            {{ 'PAGES.PAYMENT_APPROVALS.LABEL_INCOMPLETE_PAYMENTS' | translate }}
        </span>
    </div>
    <app-scalara-button *ngIf="openPayments.length > 0" (click)="navigateToPaymentApprovalsOverview()" variant="ghost">
        {{ 'CONTROLS.BUTTONS.CHECK_AND_APPROVE' | translate }}
    </app-scalara-button>
</div>

<div>
    <ibm-tabs [followFocus]="true" [cacheActive]="true">
        <ibm-tab heading="Überweisungen">
            <div class="tw-mt-6 tw-mb-4 tw-flex tw-w-full tw-justify-end">
                <app-search searchInputId="Überweisungen"></app-search>

                <div class="tw-mx-4">
                    <app-scalara-button (click)="updatePayments()" [variant]="'ghost'">{{
                        'CONTROLS.BUTTONS.UPDATE' | translate
                    }}</app-scalara-button>
                </div>

                <app-scalara-button
                    [disabled]="(bankAccounts | async)?.length === 0"
                    (click)="openAddBankOrderOverlay('MONEY_TRANSFER')"
                    svgName="24_add.svg"
                    >{{ 'CONTROLS.BUTTONS.ADD' | translate }}</app-scalara-button
                >
            </div>

            <div *ngIf="transfersTable$ | async as transfersTable">
                <app-table
                    emptyText="PAGES.PAYMENTS_OVERVIEW.EMPTY_TABLE"
                    [header]="transfersTable.header"
                    [data]="transfersTable.data"
                    tableSearchId="Überweisungen"
                    (customButton)="handlingEvent($event)"
                >
                </app-table>
            </div>
        </ibm-tab>
        <ibm-tab heading="Lastschriften" [active]="openDirectDebitTabOnInit">
            <div class="tw-mt-6 tw-mb-4 tw-flex tw-w-full tw-justify-end">
                <app-search searchInputId="Lastschriften"></app-search>

                <div class="tw-mx-4">
                    <app-scalara-button (click)="updatePayments()" [variant]="'ghost'">{{
                        'CONTROLS.BUTTONS.UPDATE' | translate
                    }}</app-scalara-button>
                </div>

                <app-scalara-button
                    *ngIf="hasCreditorId && (bankAccounts | async)?.length; else disabledButton"
                    (click)="openAddBankOrderOverlay('DIRECT_DEBIT')"
                    svgName="24_add.svg"
                    >{{ 'CONTROLS.BUTTONS.ADD' | translate }}</app-scalara-button
                >
            </div>

            <div *ngIf="directDebitsTable$ | async as directDebitsTable$">
                <app-table
                    emptyText="PAGES.PAYMENTS_OVERVIEW.EMPTY_TABLE"
                    [header]="directDebitsTable$.header"
                    [data]="directDebitsTable$.data"
                    tableSearchId="Lastschriften"
                    (customButton)="handlingEvent($event)"
                >
                </app-table>
            </div>
        </ibm-tab>
        <!-- <ibm-tab heading="Dauerüberweisungen">
            <div class="tw-mt-6 tw-mb-4 tw-flex tw-w-full tw-justify-end">
                <app-search searchInputId="Dauerüberweisungen"></app-search>

                
                <ng-component *ngTemplateOutlet="tabButtons; context: { tabType: 'recurringTransfer' }"></ng-component>
            </div>

            <div *ngIf="recurringTransfersTable$ | async as recurringTransfersTable$">
                <app-table
                    emptyText="PAGES.PAYMENTS_OVERVIEW.EMPTY_TABLE"
                    [header]="recurringTransfersTable$.header"
                    [data]="recurringTransfersTable$.data"
                    tableSearchId="Lastschriften"
                >
                </app-table>
            </div>
        </ibm-tab> -->
        <!-- <ibm-tab heading="Lastschriften">
            <div class="tw-mt-6 tw-mb-4 tw-flex tw-w-full tw-justify-end">
                <app-search searchInputId="Dauerlastschriften"></app-search>

             
                <ng-component *ngTemplateOutlet="tabButtons; context: { tabType: 'recurringDebit' }"></ng-component>
            </div>

            <div *ngIf="recurringDirectDebitsTable$ | async as recurringDirectDebitsTable$">
                <app-table
                    emptyText="PAGES.PAYMENTS_OVERVIEW.EMPTY_TABLE"
                    [header]="recurringDirectDebitsTable$.header"
                    [data]="recurringDirectDebitsTable$.data"
                    tableSearchId="Lastschriften"
                >
                </app-table>
            </div>
        </ibm-tab> -->
    </ibm-tabs>
</div>

<ng-template #disabledButton>
    <app-tooltip position="left" [isDisabled]="!(bankAccounts | async)?.length">
        <div content class="tw-px-12 tw-py-8 tw-w-[575px]">
            {{ 'ACCOUNTING.BANK_ORDERS.TEXT_THERE_ARE_NO_CREDITOR' | translate }}
        </div>
        <div hoverable-item>
            <app-scalara-button [disabled]="true" svgName="24_add.svg">{{
                'CONTROLS.BUTTONS.ADD' | translate
            }}</app-scalara-button>
        </div></app-tooltip
    >
</ng-template>

<ng-template #tabButtons let-tabType="tabType">
    <div class="tw-mx-4">
        <app-scalara-button (click)="updatePayments()" [variant]="'ghost'">{{
            'CONTROLS.BUTTONS.UPDATE' | translate
        }}</app-scalara-button>
    </div>

    <app-scalara-button
        *ngIf="(bankAccounts | async)?.length; else disabledButton"
        (click)="openAddBankOrderOverlay(tabType)"
        svgName="24_add.svg"
        >{{ 'CONTROLS.BUTTONS.ADD' | translate }}</app-scalara-button
    >
</ng-template>
