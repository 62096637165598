import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { formatDateYYYYMMDDWithoutHours } from 'src/app/core/utils/common';
import { WssPdfGenerationConfigDto } from 'src/app/generated-sources/accounting';
import { Filter } from 'src/app/shared/components/filter/filter.component';

export type DatePickerDates = {
    startDate: Date;
    endDate: Date;
};

export type AccountCalculationMode = 'EFFECTIVE_DATE' | 'BOOKING_DATE';

@Injectable({
    providedIn: 'root',
})
export class AccountingFilterCustomService {
    public constructor(private route: ActivatedRoute, private router: Router) {}
    private readonly datePickerSeleted$ = new BehaviorSubject<DatePickerDates>({
        startDate: this.initialStartDate,
        endDate: this.initialEndDate,
    });

    private get initialStartDate(): Date {
        const route = this.router.routerState.root;
        const params = { ...route?.snapshot.queryParams };
        if (params && params['startDate']) {
            return new Date(params['startDate']);
        }

        return new Date(new Date().getFullYear(), 0, 1);
    }

    private get initialEndDate(): Date {
        const route = this.router.routerState.root;
        const params = { ...route?.snapshot.queryParams };
        if (params && params['endDate']) {
            return new Date(params['endDate']);
        }

        return new Date(new Date().getFullYear(), 11, 31);
    }

    public setSelectedDates(startDate: Date, endDate: Date): void {
        if (!startDate || !endDate) {
            return this.datePickerSeleted$.next(this.datePickerSeleted$.getValue());
        }

        this.datePickerSeleted$.next({ startDate: startDate, endDate: endDate });

        const urlTree = this.router.createUrlTree([], {
            queryParams: {
                startDate: formatDateYYYYMMDDWithoutHours(startDate),
                endDate: formatDateYYYYMMDDWithoutHours(endDate),
            },
            queryParamsHandling: 'merge',
            relativeTo: this.route,
        });

        this.router.navigateByUrl(urlTree);
    }

    public getDatePickerSelectedDates$(): Observable<DatePickerDates> {
        return this.datePickerSeleted$.asObservable();
    }

    public resetDatesToCurrentYear(): void {
        const currentYear = new Date().getFullYear();
        this.setSelectedDates(new Date(currentYear, 0, 1), new Date(currentYear, 11, 31));
    }

    // bookings filter
    private readonly bookingsFilter$ = new BehaviorSubject<Filter[]>([
        {
            id: 'excludeCancelledBookings',
            content: 'Stornierte Buchungen ausblenden',
            selected: this.initialExcludeCancelledBookings,
        },
    ]);

    private get initialExcludeCancelledBookings(): boolean {
        const route = this.router.routerState.root;
        const params = { ...route?.snapshot.queryParams };
        if (params && params['excludeCancelledBookings']) {
            return params['excludeCancelledBookings'] === 'true';
        }

        return false;
    }

    public setBookingsFilter(filterIdToUpdate: string, selected: boolean): void {
        const newFilterItem = this.bookingsFilter$.getValue().map((filterItem) => {
            if (filterItem.id === filterIdToUpdate) {
                return { ...filterItem, selected: selected };
            }
            return filterItem;
        });

        this.bookingsFilter$.next(newFilterItem);

        const urlTree = this.router.createUrlTree([], {
            queryParams: {
                excludeCancelledBookings: selected,
            },
            queryParamsHandling: 'merge',
            relativeTo: this.route,
        });

        this.router.navigateByUrl(urlTree);
    }

    public getBookingsFilter$(): Observable<Filter[]> {
        return this.bookingsFilter$.asObservable();
    }

    public resetBookingsFilter(): void {
        this.bookingsFilter$.next(
            this.bookingsFilter$.getValue().map((filterItem) => {
                return {
                    ...filterItem,
                    selected: false,
                };
            })
        );

        const urlTree = this.router.createUrlTree([], {
            queryParams: {
                excludeCancelledBookings: false,
            },
            queryParamsHandling: 'merge',
            relativeTo: this.route,
        });

        this.router.navigateByUrl(urlTree);
    }
    //Bookingscalculation-Filter
    private readonly bookingsCalculationMode$ = new BehaviorSubject<AccountCalculationMode>(
        this.initialBookingsCalculationMode
    );

    private get initialBookingsCalculationMode(): AccountCalculationMode {
        const route = this.router.routerState.root;
        const params = { ...route?.snapshot.queryParams };
        if (params && params['bookingCalculationMode']) {
            return params['bookingCalculationMode'];
        }

        return 'BOOKING_DATE';
    }
    public getBookingsCalculationMode$(): Observable<AccountCalculationMode> {
        return this.bookingsCalculationMode$.asObservable();
    }
    public setBookingsCalculationMode(calculationMode: AccountCalculationMode): void {
        this.bookingsCalculationMode$.next(calculationMode);

        const urlTree = this.router.createUrlTree([], {
            queryParams: {
                bookingCalculationMode: calculationMode,
            },
            queryParamsHandling: 'merge',
            relativeTo: this.route,
        });

        this.router.navigateByUrl(urlTree);
    }
    //PDF-Printing Config
    private readonly pdfPrintingConfig$ = new BehaviorSubject<WssPdfGenerationConfigDto>(this.initialPdfPrintingConfig);

    private get initialPdfPrintingConfig(): WssPdfGenerationConfigDto {
        return {
            generateApportionableHomerelatedCost: true,
            generateTaxAndSocialSecurity: true,
            generateReserveWithdrawels: true,
            genereatAllUnitsWssResults: true,
        };
    }
    public getPdfPrintingConfig$(): Observable<WssPdfGenerationConfigDto> {
        return this.pdfPrintingConfig$.asObservable();
    }
    public setPdfPrintingConfig(pdfPrintingConfig: WssPdfGenerationConfigDto): void {
        this.pdfPrintingConfig$.next(pdfPrintingConfig);
    }
}
