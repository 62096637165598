import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, filter, switchMap, tap } from 'rxjs';
import { convertToISODateString } from 'src/app/core/utils/common';
import { BookingsService } from 'src/app/generated-sources/accounting';
import { AccountingFilterCustomService } from '../../services/accounting-filter-custom.service';
import { LedgerCustomService } from '../../services/ledger-custom.service';

@Injectable({
    providedIn: 'root',
})
export class BookingsCustomService {
    private constructor(
        private ledgerCustomService: LedgerCustomService,
        private bookingsService: BookingsService,
        private accountingFilterCustomService: AccountingFilterCustomService
    ) {}

    public getBookings$(): typeof this.bookings$ {
        return this.bookings$;
    }

    public refreshBookings(): void {
        // force refresh with same value
        this.refreshBookingsTrigger$.next(null);
    }

    private readonly refreshBookingsTrigger$ = new BehaviorSubject<null>(null);

    private datePickerSelected$ = this.accountingFilterCustomService.getDatePickerSelectedDates$();
    private bookingsFilter$ = this.accountingFilterCustomService.getBookingsFilter$();

    private readonly bookings$ = combineLatest([
        this.ledgerCustomService.getLedgerId$().pipe(filter(Boolean)),
        this.datePickerSelected$,
        this.bookingsFilter$,
        this.refreshBookingsTrigger$,
    ]).pipe(
        tap(() => console.log('bookingsService.findAll call')),
        switchMap(([ledgerId, datePickerSelectedDates, bookingsFilter]) => {
            const excludeCancelledBookings =
                bookingsFilter.find((filter) => filter.id === 'excludeCancelledBookings')?.selected ?? false;
            return this.bookingsService.findAll(
                ledgerId,
                excludeCancelledBookings,
                convertToISODateString(datePickerSelectedDates.startDate),
                convertToISODateString(datePickerSelectedDates.endDate)
            );
        })
    );
}
