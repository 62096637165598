import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ListItem } from 'carbon-components-angular';

export interface CustomListItem extends ListItem {
    icon: string;
    color: 'green' | 'gray' | 'orange' | 'purple';
    value: string;
}

@Component({
    selector: 'app-custom-dropdown',
    templateUrl: './custom-dropdown.component.html',
    styleUrls: ['./custom-dropdown.component.scss'],
})
export class CustomDropdownComponent implements OnChanges {
    @Input() public items: CustomListItem[] = [];
    @Input() public disabled = false;
    @Output() public updateSelectedItems = new EventEmitter<CustomListItem>();

    public selectedItem?: CustomListItem;

    public ngOnChanges(): void {
        this.selectedItem = this.items.find((item) => item.selected);
    }

    public onChangeStatus(event: any): void {
        this.selectedItem = event.item;
        this.updateSelectedItems.emit(this.selectedItem);
    }
}
