import { Component, Input } from '@angular/core';
import { BankAccountDto } from 'src/app/generated-sources/accounting';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-bank-connection-expired-notification',
    templateUrl: './bank-connection-expired-notification.component.html',
    styleUrls: ['./bank-connection-expired-notification.component.scss'],
})
export class BankConnectionExpiredNotificationComponent {
    @Input() public bankAccount?: BankAccountDto;
    @Input() public bankAccounts?: BankAccountDto[];

    public readonly environment = environment;

    public get isExpired(): boolean {
        if (this.bankAccount?.consentExpiresAt) {
            return !!this.bankAccount.dataOutdated;
        }

        if (this.bankAccounts && this.bankAccounts.length > 0) {
            return !!this.bankAccounts.find((item) => item.dataOutdated);
        }
        return false;
    }
}
