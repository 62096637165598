<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<div ibmGrid class="bx--grid--full-width">
    <div ibmRow class="mb-11">
        <div ibmCol></div>
        <div ibmCol class="s-head-28-36-bold mb-04" [columnNumbers]="{ md: 10, lg: 10, xl: 10 }">
            {{ 'PAGES.OWNERSHIP.ADD_OWNERSHIP.HEADLINE' | translate }}
        </div>
        <div ibmCol></div>
    </div>

    <div ibmRow class="mb-06">
        <div ibmCol></div>
        <div ibmCol class="s-head-18-26-bold" [columnNumbers]="{ md: 10, lg: 10 }">
            <span>{{ 'PAGES.OWNERSHIP.ADD_OWNERSHIP.SUBHEADLINE_OWNERSHIP' | translate }}</span>
        </div>
        <div ibmCol></div>
    </div>

    <form (submit)="onSubmit()" (keydown.enter)="$event.preventDefault()" [formGroup]="form">
        <div ibmRow>
            <div ibmCol></div>
            <div ibmCol [columnNumbers]="{ md: 10, lg: 10 }">
                <div ibmRow>
                    <div ibmCol>
                        <ibm-label
                            [invalid]="isInvalid('name')"
                            invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                            class="bx-label--mb--4px"
                        >
                            <app-text-with-tooltip
                                class="tw-inline-block"
                                label="{{ 'ENTITIES.OWNERSHIP.LABEL_NAME_SHORT' | translate }}{{ '*' }}"
                                [labelClass]="''"
                                [tooltipKey]="tooltipKey"
                                [tooltipInnerHtml]="'PAGES.TOOLTIPS.ADD_OWNERSHIP.NAME_SHORT' | translate"
                            >
                            </app-text-with-tooltip>

                            <input ibmText name="name" id="name" formControlName="name" [invalid]="isInvalid('name')" />
                        </ibm-label>
                    </div>
                    <div ibmCol>
                        <ibm-label
                            [invalid]="isInvalid('fraction') || isInvalidNumber('fraction')"
                            invalidText="{{ 'ERROR.GENERAL' | translate }}"
                            >{{ 'ENTITIES.OWNERSHIP.LABEL_FRACTION_LONG' | translate }}*
                            <input
                                ibmText
                                name="fraction"
                                id="fraction"
                                formControlName="fraction"
                                [invalid]="isInvalid('fraction') || isInvalidNumber('fraction')"
                            />
                        </ibm-label>
                    </div>
                </div>

                <div ibmRow>
                    <div ibmCol>
                        <ibm-label
                            [invalid]="isInvalid('squaremeters') || isInvalidNumber('squaremeters')"
                            invalidText="{{ 'ERROR.GENERAL' | translate }}"
                            >{{ 'ENTITIES.OWNERSHIP.LABEL_SQUARE_METERS' | translate }}
                            <input
                                ibmText
                                name="squaremeters"
                                id="squaremeters"
                                formControlName="squaremeters"
                                [invalid]="isInvalid('squaremeters') || isInvalidNumber('squaremeters')"
                            />
                        </ibm-label>
                    </div>
                    <div ibmCol>
                        <ibm-label
                            [invalid]="isInvalid('heatedSquaremeters') || isInvalidNumber('heatedSquaremeters')"
                            invalidText="{{ 'ERROR.GENERAL' | translate }}"
                            >{{ 'ENTITIES.OWNERSHIP.LABEL_HEATED_SQUARE_METERS' | translate }}
                            <input
                                ibmText
                                name="heatedSquaremeters"
                                id="heatedSquaremeters"
                                formControlName="heatedSquaremeters"
                                [invalid]="isInvalid('heatedSquaremeters') || isInvalidNumber('heatedSquaremeters')"
                            />
                        </ibm-label>
                    </div>
                </div>

                <div ibmRow class="pb-4-5r">
                    <div ibmCol [columnNumbers]="{ md: 8, lg: 8 }">
                        <ibm-label
                            *ngIf="!editingMode"
                            [invalid]="isInvalid('persons') || isInvalidNumber('persons')"
                            invalidText="{{ 'ERROR.GENERAL' | translate }}"
                            >{{ 'ENTITIES.OWNERSHIP.LABEL_PERSONS' | translate }}
                            <input
                                ibmText
                                name="persons"
                                id="persons"
                                formControlName="persons"
                                [invalid]="isInvalid('persons') || isInvalidNumber('persons')"
                            />
                        </ibm-label>
                    </div>
                </div>

                <div ibmRow class="mb-06">
                    <div ibmCol class="s-head-14-22-semibold">
                        <app-text-with-tooltip
                            class="tw-inline-block"
                            label="{{ 'PAGES.OWNERSHIP.ADD_OWNERSHIP.SUBHEADLINE_UNIT' | translate }}"
                            [labelClass]="''"
                            [tooltipKey]="tooltipKey"
                            [tooltipInnerHtml]="'PAGES.TOOLTIPS.ADD_OWNERSHIP.SUBHEADLINE_UNIT' | translate"
                        ></app-text-with-tooltip>
                    </div>
                </div>

                <div formArrayName="units" *ngFor="let unit of units.controls; let i = index">
                    <ng-container [formGroupName]="i">
                        <ng-container *ngIf="i > 0">
                            <hr class="mb-05" />
                            <div class="d-flex justify-content-end mb-05">
                                <div
                                    class="tw-fill-scalaraGray-01 tw-w-4 tw-h-4 tw-cursor-pointer"
                                    (click)="removeUnit(i)"
                                >
                                    <svg-icon src="/assets/icons/24_close.svg"></svg-icon>
                                </div>
                            </div>
                        </ng-container>

                        <div ibmRow>
                            <div ibmCol>
                                <app-combo-box
                                    #comboBoxTypeUnit
                                    [size]="'md'"
                                    id="type"
                                    [appendInline]="true"
                                    label="{{ 'ENTITIES.UNIT.LABEL_UNIT_TYPE' | translate }}*"
                                    [items]="unitsTypeList"
                                    formControlName="type"
                                    placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT' | translate }}"
                                    [invalid]="isInvalidOwnerShip(i, 'type')"
                                    invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                                >
                                    <ibm-dropdown-list></ibm-dropdown-list>
                                </app-combo-box>
                            </div>
                            <div ibmCol>
                                <ibm-label
                                    [invalid]="isInvalidOwnerShip(i, 'name')"
                                    invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                                    class="bx-label--mb--6px"
                                >
                                    <app-text-with-tooltip
                                        class="tw-inline-block"
                                        label="{{ 'ENTITIES.UNIT.LABEL_UNIT_DESCRIPTION' | translate }}{{
                                            isNameRequired ? '*' : ''
                                        }}"
                                        [labelClass]="''"
                                        [tooltipKey]="tooltipKey"
                                        [tooltipInnerHtml]="'PAGES.TOOLTIPS.ADD_OWNERSHIP.UNIT_DESCRIPTION' | translate"
                                    >
                                    </app-text-with-tooltip>
                                    <input
                                        ibmText
                                        name="name"
                                        id="name"
                                        formControlName="name"
                                        [invalid]="isInvalidOwnerShip(i, 'name')"
                                    />
                                </ibm-label>
                            </div>
                        </div>

                        <div ibmRow>
                            <div ibmCol>
                                <app-combo-box
                                    #comboBoxBuilding
                                    [size]="'md'"
                                    id="building"
                                    [appendInline]="true"
                                    label="Gebäude*"
                                    [items]="buildingList"
                                    formControlName="building"
                                    placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT' | translate }}"
                                    [invalid]="isInvalidOwnerShip(i, 'building')"
                                    invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                                >
                                    <ibm-dropdown-list></ibm-dropdown-list>
                                </app-combo-box>
                            </div>
                            <div ibmCol>
                                <ibm-label
                                    [invalid]="isInvalidOwnerShip(i, 'location')"
                                    invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                                    class="bx-label--mb--6px"
                                >
                                    <app-text-with-tooltip
                                        class="tw-inline-block"
                                        label="{{ 'Lage*' }}"
                                        [labelClass]="''"
                                        [tooltipKey]="tooltipKey"
                                        [tooltipInnerHtml]="'PAGES.TOOLTIPS.ADD_OWNERSHIP.LOCATION' | translate"
                                    >
                                    </app-text-with-tooltip>
                                    <input
                                        ibmText
                                        name="location"
                                        id="location"
                                        formControlName="location"
                                        [invalid]="isInvalidOwnerShip(i, 'location')"
                                    />
                                </ibm-label>
                            </div>
                        </div>

                        <div ibmRow>
                            <div ibmCol>
                                <ibm-label
                                    [invalid]="isInvalidOwnerShip(i, 'roomCount')"
                                    invalidText="{{ 'ERROR.GENERAL' | translate }}"
                                    >{{ 'ENTITIES.OWNERSHIP.LABEL_ROOM' | translate }}
                                    <input
                                        ibmText
                                        name="roomCount"
                                        id="roomCount"
                                        formControlName="roomCount"
                                        [invalid]="isInvalidOwnerShip(i, 'roomCount')"
                                    />
                                </ibm-label>
                            </div>
                            <div ibmCol>
                                <ibm-label
                                    [invalid]="isInvalidOwnerShip(i, 'condition')"
                                    invalidText="{{ 'ERROR.GENERAL' | translate }}"
                                    >{{ 'ENTITIES.OWNERSHIP.LABEL_CONDITION' | translate }}
                                    <input
                                        ibmText
                                        name="condition"
                                        id="condition"
                                        formControlName="condition"
                                        [invalid]="isInvalidOwnerShip(i, 'condition')"
                                    />
                                </ibm-label>
                            </div>
                        </div>

                        <div ibmRow>
                            <div ibmCol>
                                <ibm-label
                                    [invalid]="isInvalidOwnerShip(i, 'squaremeters')"
                                    invalidText="{{ 'ERROR.GENERAL' | translate }}"
                                    >{{ 'ENTITIES.OWNERSHIP.LABEL_SQUARE_METERS' | translate }}
                                    <input
                                        ibmText
                                        inputmode="numeric"
                                        type="number"
                                        name="squaremeters"
                                        id="squaremeters"
                                        formControlName="squaremeters"
                                        [invalid]="isInvalidOwnerShip(i, 'squaremeters')"
                                    />
                                </ibm-label>
                            </div>
                            <div ibmCol>
                                <ibm-label
                                    [invalid]="isInvalidOwnerShip(i, 'heatedSquaremeters')"
                                    invalidText="{{ 'ERROR.GENERAL' | translate }}"
                                    >{{ 'ENTITIES.OWNERSHIP.LABEL_HEATED_SQUARE_METERS' | translate }}
                                    <input
                                        type="number"
                                        inputmode="numeric"
                                        ibmText
                                        name="heatedSquaremeters"
                                        id="heatedSquaremeters"
                                        formControlName="heatedSquaremeters"
                                        [invalid]="isInvalidOwnerShip(i, 'heatedSquaremeters')"
                                    />
                                </ibm-label>
                            </div>
                        </div>

                        <div ibmRow>
                            <div ibmCol>
                                <ibm-label
                                    [invalid]="isInvalidOwnerShip(i, 'inventory')"
                                    invalidText="{{ 'ERROR.GENERAL' | translate }}"
                                    >{{ 'ENTITIES.OWNERSHIP.LABEL_INVENTORY' | translate }}

                                    <textarea
                                        ibmTextArea
                                        aria-label="textarea"
                                        formControlName="inventory"
                                        maxlength="255"
                                        [invalid]="isInvalidOwnerShip(i, 'inventory')"
                                    ></textarea>
                                </ibm-label>
                            </div>
                        </div>

                        <div
                            class="tw-mt-8"
                            ibmRow
                            *ngIf="
                                (this.units.at(i).value?.type?.value || this.units.at(i).value?.type) === 'APARTMENT'
                                    ? true
                                    : false
                            "
                        >
                            <div ibmCol>
                                <ibm-checkbox formControlName="isNotResidential"
                                    ><span class="tw-ml-2">Wird nicht zu Wohnzwecken genutzt</span>
                                </ibm-checkbox>
                            </div>
                        </div>
                    </ng-container>
                </div>

                <div ibmRow class="mb-7-5r tw-mt-16">
                    <div ibmCol>
                        <app-scalara-button
                            (click)="addUnit()"
                            variant="ghost"
                            svgName="24_add.svg"
                            class="d-inline-block"
                        >
                            {{ 'PAGES.OWNERSHIP.ADD_OWNERSHIP.ADD_UNIT' | translate }}</app-scalara-button
                        >
                    </div>
                </div>
                <div ibmRow *ngFor="let unit of savedUnit; let last = last; let i = index">
                    <div ibmCol [class.mb-04]="!last">
                        <app-notification type="warning">
                            <div class="custom-notification">
                                <div>{{ 'PAGES.OWNERSHIP.ADD_OWNERSHIP.MESSAGE_WARNING_BACK_UNIT' | translate }}</div>
                                <button (click)="backUnit(unit)" type="button">
                                    {{ 'PAGES.OWNERSHIP.ADD_OWNERSHIP.BACK_UNIT_BUTTON' | translate }}
                                </button>
                            </div>
                        </app-notification>
                    </div>
                </div>

                <!-- START BUTTON ROW -->
                <div ibmRow class="mt-11">
                    <div ibmCol>
                        <button [ibmButton]="'tertiary'" (click)="abort()">
                            {{ 'COMMON.BTN_CANCEL' | translate }}
                        </button>
                    </div>

                    <div ibmCol class="text-right">
                        <button [ibmButton]="'primary'" type="submit" [disabled]="form.invalid">
                            {{ 'COMMON.BTN_SAVE' | translate }}
                        </button>
                    </div>
                </div>
                <!-- END BUTTON ROW -->

                <div class="p-10"></div>
            </div>
            <div ibmCol></div>
        </div>
    </form>
</div>
