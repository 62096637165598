import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ListItem } from 'carbon-components-angular';
import { isEqual } from 'lodash';
import {
    BehaviorSubject,
    Observable,
    Subject,
    catchError,
    combineLatest,
    distinctUntilChanged,
    filter,
    finalize,
    firstValueFrom,
    map,
    of,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import {
    calculateTaxAmount,
    formControl,
    formControlHasError,
    getLocalISOTime,
    getNowWithoutHhMmSs,
    getRestAmount,
    getTypeOpenItem,
    notFuture,
    sortAccountList,
    sortByDateCallback,
} from 'src/app/core/utils/common';
import { getDateWithoutHhMmSs } from 'src/app/core/utils/dateUtils';
import { controlInFormGroupIsInvalid } from 'src/app/core/utils/formValidationHelpers';
import {
    AccountDto,
    AdditionalParameterDefinitionDto,
    BankAccountDto,
    BankAccountsService,
    BankTransactionDto,
    BankTransactionsService,
    BookingRulesService,
    CreateBookingRuleDto,
    CreateGuidedBookingDto,
    CreateReceiptDto,
    GuidedBookingTypeDto,
    GuidedBookingTypeShortDto,
    GuidedBookingsService,
    LedgerDto,
    OpenItemDto,
    ReceiptDto,
    ReceiptsService,
} from 'src/app/generated-sources/accounting';
import { IbanPersonRecommendationDto, PersonsService, RecommendationsService } from 'src/app/generated-sources/base';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { OverlayRef } from 'src/app/shared/overlay/utils/overlay-ref';
import { EurocentPipe } from 'src/app/shared/pipes/eurocent.pipe';
import { CustomTableItem } from 'src/app/shared/table/CustomTableItem';
import { CellTemplate } from 'src/app/shared/table/enums/cell-template';
import { HeaderTemplate } from 'src/app/shared/table/enums/header-template';
import { HeaderItem } from 'src/app/shared/table/interfaces/header-item';
import { TableItem } from 'src/app/shared/table/interfaces/table-item';
import { OpenItemClosingStrategy, calculateDistribution } from 'src/app/shared/utility/open-item-closing-strategies';
import { RadioAdditionalParameter } from '../../../../interfaces';
import {
    AddBankAccountRecommendationOverlayComponent,
    AddBankAccountRecommendationOverlayComponentConfig,
} from '../../../add-bank-account-recommendation-overlay/add-bank-account-recommendation-overlay.component';
import { LedgerCustomService } from '../../../services/ledger-custom.service';
import { AddBookingSelectionOverlayComponent } from '../add-booking-selection-overlay/add-booking-selection-overlay.component';
import { BookingRuleSuggestion } from './booking-rule-suggestion-card/booking-rule-suggestion-card.component';

//use instead of AdditionalParameterDefinitionDto.valueOptions
type ExtendedAccount = AccountDto & {
    occupationId?: string;
    ownershipId?: string;
};

interface BookingItem {
    account: AccountItem;
    amount: number;
}

interface RowSelected {
    data: CustomTableItem[];
    rowNumber: number;
}

interface AccountItem extends ListItem {
    accountId: string;
}

@Component({
    selector: 'app-add-guided-booking-overlay',
    templateUrl: './add-guided-booking-overlay.component.html',
    styleUrls: ['./add-guided-booking-overlay.component.scss'],
})
export class AddGuidedBookingOverlayComponent extends OverlayChildComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();

    public propertyId$ = this.ledgerCustomService.getLedger$().pipe(map((ledger) => ledger?.propertyId));
    //  in cache are stored recommendations for each selected account(already called ones are cached here)
    //  key is accountId used for BE call
    public recommendationsCache: {
        [key: string]: { result: IbanPersonRecommendationDto; account: AccountDto };
    } = {};

    //accounts getting populated from guided booking
    public accounts: ExtendedAccount[] = [];
    public accountSelectedIds$ = new BehaviorSubject<string[] | undefined>(undefined);
    public suggestedAmountFromOpenItems$ = new BehaviorSubject<number | undefined>(undefined);

    private toastSuccessMessage?: string;
    private ledgerId = '';
    public selectedBookingId = -1;

    public selectedShortBooking?: GuidedBookingTypeShortDto;
    public guidedBooking?: GuidedBookingTypeDto;
    public taxShare?: RadioAdditionalParameter;
    public labourAmount?: AdditionalParameterDefinitionDto;

    public form: UntypedFormGroup = new UntypedFormGroup({});
    public isLoading = false;
    public isOverlayLoading = false;
    public isSubmitLoading = false;
    public isAccountToBeClosed = false;
    public showSum = false;
    public filesLoaded = false;

    public accountSelectedIds: string[] = [];
    public accountListItems: ListItem[] = [];
    public accountsId: string[] = [];
    public fileTypesSelected: string[] = [];
    public warningMessage: string[] = [];
    public bookingType = 'accounts';
    public titleListItems = '';
    public accountPlaceHolder = '';
    public additionalAccountListItem: ListItem[] = [];
    public effectiveYearListItems: ListItem[] = [];
    public titleAdditionalAccountListItem = '';
    public thereIsAdditionalAccountList = false;
    public isSelectedAmountFullfillingTransaction = false;
    public suggestedAmountFromOpenItems = 0;

    public guidedBookingSubGroup?: string;

    private bookings: CreateGuidedBookingDto[] = [];
    public openItemListItems: OpenItemDto[] = []; // what we retrieve from backend
    public openItemsSelected: string[] = []; // ids of selected openItems
    public existingReceiptsSelected: string[] = []; // ids of selected receipts
    public fileStorageIdsSelected: string[] = []; // ids of just uploaded files
    public outstandingAmountOpenItemsSelected: { [id: string]: number } = {};

    public openItemsDataTable: TableItem[][] = []; // openItems that get sent to the table.
    public openItemsHeader: (string | HeaderItem)[] = [];

    public receiptsDataTable: TableItem[][] = [];
    public receiptsHeader: (string | HeaderItem)[] = [];

    public bankTransaction?: BankTransactionDto;
    public bankAccount = {} as BankAccountDto;
    public isFilteringBookingReceipts = true;
    public isReceiptsAllAccountsShowing = false;
    public effectiveYearLabel = '';
    public createBookingRuleChecked = false;
    public createBookingRuleDescription = '';
    public ledger?: LedgerDto;

    public constructor(
        private formBuilder: UntypedFormBuilder,
        private translateService: TranslateService,
        private guidedBookingsService: GuidedBookingsService,
        private toastService: ToastService,
        private overlayService: OverlayService,
        private receiptsService: ReceiptsService,
        private bankTransactionsService: BankTransactionsService,
        private bankAccountsService: BankAccountsService,
        private ledgerCustomService: LedgerCustomService,
        public personsService: PersonsService,
        public recommendationsService: RecommendationsService,
        private eurocentPipe: EurocentPipe,
        private bookingRulesService: BookingRulesService
    ) {
        super();
    }

    public ledger$ = this.ledgerCustomService.getLedger$().pipe(
        filter(Boolean),
        tap((ledger) => {
            this.ledger = ledger;
            this.ledgerId = ledger.id;
        })
    );

    // public ledgerId$ = this.ledgerCustomService.getLedgerId$().pipe(
    //     filter(Boolean),
    //     tap((ledgerId) => (this.ledgerId = ledgerId))
    // );

    public receipts$ = combineLatest([this.ledger$, this.accountSelectedIds$]).pipe(
        tap(() => (this.isLoading = true)),
        switchMap(([ledger, accountSelectedIds]) => {
            const accountsForReceipts = this.isReceiptsAllAccountsShowing ? undefined : accountSelectedIds;
            return this.receiptsService.findAll(
                ledger.id,
                undefined,
                undefined,
                accountsForReceipts,
                undefined,
                this.isFilteringBookingReceipts
            );
        }),
        tap((receipts: ReceiptDto[]) => {
            this.receiptsDataTable = receipts
                .sort((a, b) => sortByDateCallback(a.createdAt, b.createdAt, 'desc'))
                .map((receipt) => {
                    const row: TableItem[] = this.createReceiptRow(receipt);
                    return row;
                });
        }),

        catchError(() => {
            this.toastService.showError(this.translateService.instant('COMPONENTS.TOAST.TOAST_ERROR'));
            return of(null);
        }),
        tap(() => (this.isLoading = false)),
        takeUntil(this.unsubscribe$)
    );

    public ngOnInit(): void {
        this.selectedShortBooking = this.config?.data.selectedBooking;
        this.selectedBookingId = this.selectedShortBooking?.id || -1;
        this.ledgerId = this.config?.data.ledgerId;
        this.bankTransaction = this.config?.data.bankTransaction;
        this.bankAccount = this.config?.data.bankAccount;
        this.isOverlayLoading = true;

        this.form = this.createForm();

        this.guidedBookingsService
            .getGuidedBookingType(this.ledgerId, this.selectedBookingId)
            .pipe(
                tap((guidedBooking: GuidedBookingTypeDto) => {
                    if (!this.selectedShortBooking?.groupName) {
                        this.selectedShortBooking = guidedBooking;
                    }
                    this.guidedBooking = guidedBooking;
                    this.warningMessage = guidedBooking.warningMessage ? guidedBooking.warningMessage.split('\n') : [];
                    if (guidedBooking.additionalParameters.length) {
                        this.mapAdditionalParameters(guidedBooking);
                    }
                    this.isOverlayLoading = false;
                }),

                switchMap(() => this.form?.valueChanges),

                // distinctUntilChanged avoids having an infinite loop
                distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
                tap(() => {
                    this.updateOpenItemStatus();
                    if (this.bankTransaction) {
                        const currentAmountSelected = this.form?.get('accounts')?.value[0].amount;
                        if (currentAmountSelected == getRestAmount(this.bankTransaction)) {
                            this.isSelectedAmountFullfillingTransaction = true;
                        } else {
                            this.isSelectedAmountFullfillingTransaction = false;
                        }
                        if (this.bankTransaction.purpose && this.form.get('description')?.untouched) {
                            this.form.patchValue({ description: this.bankTransaction.purpose });

                            //  mark as touched to show error message only if there is bankTransaction.purpose
                            const descriptionControl = this.form.get('description');
                            descriptionControl?.markAsTouched();
                        }
                    }
                }),

                takeUntil(this.unsubscribe$)
            )
            .subscribe();

        this.setCustomTexts();
        this.initReceiptTableHeaders();
        this.receipts$.subscribe();
    }

    private createForm(): UntypedFormGroup {
        /** Changing updateOn strategy for 'description' control to avoid sending unneeded events,
         * as currently there is no need to watch every character change.
         */
        const form: UntypedFormGroup = this.formBuilder.group({
            description: [null, { validators: [Validators.required, Validators.maxLength(255)] }],
            accounts: this.formBuilder.array([]),
        });

        return form;
    }

    private mapAdditionalParameters(guidedBooking: GuidedBookingTypeDto): void {
        this.mapSingleSelectDropdownParameters(
            guidedBooking.additionalParameters.filter(
                (additionalParameter) =>
                    additionalParameter.style === 'SINGLE_SELECT' && additionalParameter.name !== 'vat'
            )
        );

        const date = this.bankTransaction?.bankBookingDate
            ? getDateWithoutHhMmSs(this.bankTransaction.bankBookingDate)
            : getNowWithoutHhMmSs();

        let form = this.form;
        let openItemIndex = -1;
        guidedBooking.additionalParameters.map((additionalParameter: AdditionalParameterDefinitionDto, index) => {
            if (additionalParameter.name == 'openItems') {
                this.initOpenItemTableHeaders();
                this.isAccountToBeClosed = true;
                openItemIndex = index;
            } else if (additionalParameter.name == 'vat') {
                this.taxShare = additionalParameter as RadioAdditionalParameter;
            } else if (additionalParameter.name == 'labourAmount') {
                this.labourAmount = additionalParameter;
            } else if (additionalParameter.name == 'openItemDueDate') {
                const isRequired = additionalParameter.isOptional === false;
                form = this.formBuilder.group({
                    ...form.controls,
                    dueDate: [date, [isRequired ? Validators.required : '']],
                });
            } else if (additionalParameter.name == 'bookingDate') {
                const isRequired = additionalParameter.isOptional === false;
                form = this.formBuilder.group({
                    ...form.controls,
                    bookingDate: [date, [isRequired ? Validators.required : '', notFuture]],
                });
            }
        });
        this.form = form;

        // processing open items after the form has been completely initialized
        if (openItemIndex > -1) {
            if (this.accountListItems.length == 1) {
                this.accountPlaceHolder = this.accountListItems[0].content;
                this.accountsArray.patchValue([{ account: this.accountListItems[0] }]);
                this.onAccountSelected({
                    formArray: this.accountsArray,
                    index: 0,
                    type: 'accounts',
                });
            }
            /* when length == 0 it means that there is no need to show the account selection,
             * as its clear which account its the booking related to
             */
            if (this.accountListItems.length == 0) {
                this.mapOpenItems(guidedBooking.additionalParameters[openItemIndex]);
            }
        }
    }

    /** Initializing the single selects requested for guided booking.
     * Maximum amount of single select dropdowns (f.e 'tax rate' is not) currently is 3
     */
    private mapSingleSelectDropdownParameters(selects: AdditionalParameterDefinitionDto[]): void {
        const effectiveYearIndex = selects.findIndex(
            (parameter: AdditionalParameterDefinitionDto) => parameter.name == 'effectiveYear'
        );

        /* Effective Year (EY) will always be placed in the top position, disregarding its position in
         * the additionalParameters array
         */
        if (effectiveYearIndex !== -1 && selects[effectiveYearIndex].valueOptions?.length) {
            selects[effectiveYearIndex].valueOptions?.map((option: any, index) => {
                const listItem: ListItem = {
                    content: option['label'],
                    id: option['id'],
                    selected: false,
                };
                this.effectiveYearListItems.push(listItem);
            });
            const isRequired = selects[effectiveYearIndex].isOptional === false;
            this.effectiveYearLabel = this.translateService.instant('ENTITIES.BOOKING.LABEL_EFFECTIVE_YEAR');
            if (isRequired) {
                this.effectiveYearLabel = this.effectiveYearLabel + '*';
            }
            const form = this.formBuilder.group({
                ...this.form.controls,
                effectiveYear: ['', isRequired ? Validators.required : ''],
            });
            this.form = form;
        }

        selects = selects.filter(
            (parameter: AdditionalParameterDefinitionDto) =>
                parameter.name !== 'effectiveYear' && parameter.valueOptions && parameter.valueOptions.length !== 0
        );

        /* For any other Single Select, backend will deliver the desired display order in the additionalParameters array
         * Example: [EY, SS1, SS2]
         * [SS1, SS2, EY]
         * [SS1, EY, SS2]
         * [EY, SS1]
         * [SS1, EY]
         */
        if (selects.length) {
            if (selects.length > 1) {
                this.thereIsAdditionalAccountList = true;

                // in the overlay, the additional account dropdown sits above of the original
                // therefore we map in it the first select of the array.
                this.additionalAccountListItem = this.mapListItems(selects[0]);
                this.titleAdditionalAccountListItem = selects[0].label;
                //TODO : Generalize ALEX
                this.accountListItems = this.mapListItems(selects[1]);
                this.titleListItems = selects[1].label;

                if (this.additionalAccountListItem.length === 1) {
                    this.accountsArray.patchValue([{ reserveFundAccount: this.additionalAccountListItem[0] }]);
                }
            } else {
                this.accountListItems = this.mapListItems(selects[0]);
                this.titleListItems = selects[0].label;
            }
            if (this.accountListItems.length == 1) {
                this.accountPlaceHolder = this.accountListItems[0].content;
                this.accountsArray.patchValue([{ account: this.accountListItems[0] }]);
            }
        }
    }

    private mapListItems(additionalParameters: AdditionalParameterDefinitionDto): ListItem[] {
        let resultListItems: ListItem[] = [];
        this.accounts = [];
        if (additionalParameters.valueOptions) {
            // FIXME: typing from the backend is wrong, AdditionalParameterDefinitionDto.valueOptions should not be Array<object>
            AdditionalParameterDefinitionDto;
            resultListItems = additionalParameters.valueOptions
                .map((parameter: any) => {
                    if (
                        additionalParameters.name !== 'effectiveYear' &&
                        additionalParameters.name !== 'vat' &&
                        additionalParameters.style === 'SINGLE_SELECT'
                    ) {
                        this.accounts.push(parameter);
                        if (additionalParameters.valueOptions?.length === 1 && (parameter.id as string)) {
                            this.accountSelectedIds.push(parameter.id as string);
                            this.accountSelectedIds$.next(this.accountSelectedIds);
                        }

                        return {
                            content: this.getWholeAccountDescriptionForListItem(parameter),
                            selected: false,
                            accountId: parameter.id,
                        } as AccountItem;
                    }

                    const mockListItem: ListItem = {
                        content: '',
                        selected: false,
                        disabled: false,
                    };
                    return mockListItem;
                })
                .sort(sortAccountList);
            if (resultListItems.length == 1) {
                resultListItems[0].selected = true;
            }
        }
        return resultListItems;
    }

    private getWholeAccountDescriptionForListItem(account: AccountDto): string {
        return `${account.name}${account.description ? ', ' + account.description : ''}`;
    }

    private getListOpenItems(selectedOptionId: string): Observable<AdditionalParameterDefinitionDto> {
        const requiredInputParameters: any = {};
        const parameterName = this.guidedBooking?.additionalParameters[0].name || '';
        const selectedBookingId = this.selectedShortBooking?.id || -1;

        requiredInputParameters[parameterName] = selectedOptionId;

        return this.guidedBookingsService.getGuidedBookingTypeParameter(
            this.ledgerId,
            selectedBookingId,
            'openItems',
            requiredInputParameters
        );
    }

    public changeShowSumStatus(): void {
        const accountsSize = this.accountsArray.length;
        this.showSum = accountsSize > 1 ? true : false;
    }

    //  sets new recommendations
    public async populateBankAccountRecommendations(): Promise<void> {
        const iban = this.bankTransaction?.counterpartIban;
        const propertyId = await firstValueFrom(this.propertyId$);
        const selectedIds = (await firstValueFrom(this.accountSelectedIds$)) ?? [];
        if (selectedIds.length < 1) {
            return;
        }
        const selectedId = selectedIds[0];
        const selectedAccount = this.accounts.find((i) => i.id === selectedId);
        if (!selectedAccount) {
            console.warn('no accound found');
            return;
        }

        // do no call if found in cache or if no iban
        if (this.recommendationsCache[selectedAccount.id] || !iban) {
            console.warn('found in cache');
            return;
        }

        try {
            const recommendations = await firstValueFrom(
                this.recommendationsService.findPlatformDetails(
                    iban,
                    selectedAccount.occupationId ?? undefined,
                    selectedAccount.ownershipId ?? undefined,
                    propertyId
                )
            );

            if (recommendations) {
                this.recommendationsCache[selectedId] = {
                    result: recommendations,
                    account: selectedAccount,
                };
            }
        } catch (error) {
            console.warn('error laoding recommendations', error);
        }
    }

    //  opens overlay with recommendation - connect bank account to person
    //  onClose and beforeOpen will be called even if new overlay is not opened due to missing data
    //  use carefull, onClose is called for both save and cancel emitters
    public openAddBankAccountRecommendationOverlay({
        bankTransaction,
        beforeOpen,
        onClose,
    }: {
        bankTransaction: BankTransactionDto | undefined;
        beforeOpen?: () => void;
        onClose?: () => void;
    }): void {
        const selectedAccountId = this.accountSelectedIds[0];
        const recommendationCacheValue = this.recommendationsCache[selectedAccountId];
        const ibanPersonRecommendation = recommendationCacheValue?.result;
        beforeOpen ? beforeOpen() : null;

        //  if no data -> do not open new overlay but handle current component
        if (
            !bankTransaction ||
            !ibanPersonRecommendation ||
            !ibanPersonRecommendation.recommendations ||
            ibanPersonRecommendation.recommendations.length < 1
        ) {
            onClose ? onClose() : null;
            return undefined;
        }

        const configData: AddBankAccountRecommendationOverlayComponentConfig = {
            ibanPersonRecommendation,
            bankTransaction,
            isAttachedToBookings: true,
        };
        const ref: OverlayRef = this.overlayService.open(AddBankAccountRecommendationOverlayComponent, {
            data: {
                ...configData,
            },
        });
        ref.saveEmitter$.subscribe(() => (onClose ? onClose() : null));
        ref.cancelEmitter$.subscribe(() => {
            ref.close();
            onClose ? onClose() : null;
        });
    }

    public async onSubmit(): Promise<void> {
        if (!this.isFormValid()) {
            return;
        }
        this.isSubmitLoading = true;

        this.populateBookingsToPush();

        const completedBookingObs = this.bookings.map((b) =>
            this.guidedBookingsService.createGuidedBooking(this.ledgerId, b)
        );

        const promises = completedBookingObs.map((obs) => firstValueFrom(obs));

        const results = await Promise.allSettled(promises);
        this.isSubmitLoading = false;

        if (results.find((i) => i.status === 'rejected')) {
            this.toastService.showError(this.translateService.instant('COMPONENTS.TOAST.TOAST_ERROR'));
            return;
        }
        if (this.createBookingRuleChecked) {
            this.createBookingRule();
        }
        this.toastService.showSuccess(this.toastSuccessMessage);
        this.saveEmitter$.next();
        this.openAddBankAccountRecommendationOverlay({
            bankTransaction: this.bankTransaction,
            onClose: () => this.saveEmitter$.next(),
            beforeOpen: () => this.overlayService.removeOverlayComponentFromBody(),
        });
    }

    private populateBookingsToPush(): void {
        this.bookings = [];
        for (let i = 0; i < this.form?.get('accounts')?.value.length; i++) {
            const bookingToPush = this.form?.get('accounts')?.value[i];
            const createGuidedBookingDto: CreateGuidedBookingDto = {
                guidedBookingTypeId: this.selectedBookingId,
                description: this.form.get('description')!.value || '',
                amount: bookingToPush.amount,
                additionalParameters: this.getAdditionalParametersToPush(bookingToPush),
            };
            if (this.config?.data.bankTransaction?.id) {
                createGuidedBookingDto.bankTransactionId = this.config.data.bankTransaction.id;
            }
            if (this.fileStorageIdsSelected) {
                const receipts: CreateReceiptDto[] = [];
                this.fileStorageIdsSelected.map((fileId, index) => {
                    receipts.push({
                        fileStorageId: fileId,
                        accountId: this.accountSelectedIds[0],
                        type:
                            (this.fileTypesSelected[i] as CreateReceiptDto.TypeEnum) ||
                            CreateReceiptDto.TypeEnum.Invoice,
                    });
                });
                createGuidedBookingDto.receipts = receipts;
            }
            if (this.existingReceiptsSelected) {
                createGuidedBookingDto.existingReceipts = this.existingReceiptsSelected;
            }
            this.bookings.push(createGuidedBookingDto);
        }
    }

    public isTransactionAmountError(): boolean {
        const bankTransaction = this.config?.data.bankTransaction;
        if (!bankTransaction) {
            return false;
        }
        const bookingsAmount = this.form
            ?.get('accounts')
            ?.value.reduce((acc: number, curr: BookingItem) => acc + curr.amount, 0);

        const openAmount = Math.abs(bankTransaction.amount) - (bankTransaction?.amountAssignedToBookings ?? 0);
        if (bookingsAmount > openAmount) {
            return true;
        }

        return false;
    }

    public abort(): void {
        this.overlayService.removeOverlayComponentFromBody();

        const ref = this.overlayService.open(AddBookingSelectionOverlayComponent, {
            data: { ledgerId: this.ledgerId, bankTransactionId: this.config?.data?.bankTransaction?.id },
        });

        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe({
            next: () => {
                this.saveEmitter$.next();
            },
        });
    }

    public changeBookingDate($event: any): void {
        this.suggestedAmountFromOpenItems = this.getSuggestedAmountFromOpenItems($event);
    }

    public changeDueDate($event: any): void {
        return;
    }

    //  TODO: refactor to use  controlInFormGroupIsInvalid since it checks for all kind of errors and not only 'required'
    public isInvalid(controlName: string): boolean {
        return formControlHasError(formControl(this.form, controlName), 'required');
    }

    public isNotFuture(controlName: string): boolean {
        return formControlHasError(formControl(this.form, controlName), 'dateInFuture');
    }

    public isNotPast(controlName: string): boolean {
        return formControlHasError(formControl(this.form, controlName), 'dateInPast');
    }

    public areAllControlsWithNameDirty(controlname: string): boolean {
        for (const control of this.accountsArray.controls) {
            const group = control as UntypedFormGroup;
            if (group.get(controlname)!.pristine) {
                return false;
            }
        }

        for (const control of this.accountsArray.controls) {
            const group = control as UntypedFormGroup;
            if (group.get(controlname)!.pristine) {
                return false;
            }
        }

        return true;
    }

    public isFormValid(): boolean {
        return this.form.valid && (!this.isAccountToBeClosed || this.amountToCover() >= 0);
    }

    public get accountsArray(): UntypedFormArray {
        return this.form.get(this.bookingType) as UntypedFormArray;
    }

    private getAdditionalParametersToPush(bookingToPush: any): AdditionalParameterDefinitionDto[] {
        const parameters: any = {};
        const additionalParameters = this.guidedBooking?.additionalParameters || [];

        if (this.guidedBooking) {
            const selects = this.guidedBooking.additionalParameters.filter(
                (additionalParameter) =>
                    additionalParameter.style === 'SINGLE_SELECT' &&
                    additionalParameter.name !== 'effectiveYear' &&
                    additionalParameter.name !== 'vat' &&
                    additionalParameter.valueOptions &&
                    additionalParameter.valueOptions.length !== 0
            );
            if (selects.length) {
                if (selects.length > 1) {
                    parameters[selects[0].name] = bookingToPush.reserveFundAccount.accountId;
                    parameters[selects[1].name] = bookingToPush.account.accountId;
                } else {
                    parameters[selects[0].name] = bookingToPush.account.accountId;
                }
            }
        }

        for (let i = 0; i < additionalParameters.length; i++) {
            if (additionalParameters[i].name == 'openItemDueDate') {
                parameters[additionalParameters[i].name] = getLocalISOTime(this.form.get('dueDate')!.value);
            } else if (additionalParameters[i].name == 'bookingDate') {
                parameters[additionalParameters[i].name] = getLocalISOTime(this.form.get('bookingDate')!.value);
            } else if (additionalParameters[i].name == 'openItems') {
                parameters[additionalParameters[i].name] = this.openItemsSelected;
            } else if (additionalParameters[i].name == 'vat') {
                parameters[additionalParameters[i].name] = bookingToPush.taxShare ? bookingToPush.taxShare : null;
            } else if (additionalParameters[i].name == 'source') {
                parameters[additionalParameters[i].name] = bookingToPush.reserveFundAccount.accountId;
            } else if (additionalParameters[i].name == 'target') {
                parameters[additionalParameters[i].name] = bookingToPush.account.accountId;
            } else if (additionalParameters[i].name == 'effectiveYear') {
                parameters[additionalParameters[i].name] = this.form.get('effectiveYear')!.value.id;
            } else if (additionalParameters[i].name == 'labourAmount') {
                parameters[additionalParameters[i].name] = bookingToPush.amountLabour || 0;
            }
        }
        return parameters;
    }

    public onAccountSelected(data: any): void {
        this.accountSelectedIds = data.formArray.value.map((item: any) => item.account.accountId);
        this.accountSelectedIds$.next(this.accountSelectedIds);
        if (this.accountSelectedIds && this.isAccountToBeClosed) {
            this.isLoading = true;
            this.getListOpenItems(data.formArray.value[0].account.accountId)
                .pipe(
                    switchMap((multiSelectorParameters: AdditionalParameterDefinitionDto) => {
                        this.mapOpenItems(multiSelectorParameters);
                        this.isLoading = false;
                        return this.form?.get('accounts')?.valueChanges || of(null);
                    }),
                    tap(() => {
                        let currentAmountSelected = this.form?.get('accounts')?.value[0].amount;
                        if (currentAmountSelected != this.form?.get('accounts')?.value[0].amount) {
                            this.updateOpenItemStatus();
                            currentAmountSelected = this.form?.get('accounts')?.value[0].amount;
                        }
                    }),
                    finalize(() => (this.isLoading = false)),
                    takeUntil(this.unsubscribe$)
                )
                .subscribe();
        }

        this.populateBankAccountRecommendations();
    }

    private mapOpenItems(openItemParameter: AdditionalParameterDefinitionDto): void {
        this.openItemListItems = (openItemParameter.valueOptions as OpenItemDto[]).sort(this.compareOpenItemsDueDate);

        // updating suggested amount only after loading the open items
        this.suggestedAmountFromOpenItems = this.getSuggestedAmountFromOpenItems();

        //  reset selected openItems on account change
        this.openItemsSelected = [];
        this.openItemsDataTable = this.openItemListItems.map((openItem: OpenItemDto) => {
            return this.createOpenItemRow(openItem);
        });
        if (this.openItemListItems.length > 0) {
            this.updateOpenItemStatus();
        }
    }

    /**
     * Updates the status of openItem shown in the table, status that the table-component translates into
     * its proper styling.
     */
    private updateOpenItemStatus(): void {
        const amount = this.form?.get('accounts')?.value[0].amount;
        const isAmountDirty = this.form?.get('accounts')?.get('0')?.get('amount')?.dirty;
        if (!this.openItemsDataTable[0] || (amount == 0 && !isAmountDirty)) {
            return;
        }
        const amountToCover = this.amountToCover();
        const rowLength = this.openItemsDataTable[0].length;
        const updatedTable = [...this.openItemsDataTable];
        for (let i = 0; i < this.openItemsDataTable.length; i++) {
            const currentOpenItemId = this.openItemsDataTable[i][rowLength - 1].data.link as string;
            if (currentOpenItemId && this.openItemsSelected.includes(currentOpenItemId)) {
                updatedTable[i][rowLength - 1].data.label = 'row-selected';
            } else if (
                amount == 0 ||
                (amountToCover >= 0 && currentOpenItemId && !this.openItemsSelected.includes(currentOpenItemId))
            ) {
                updatedTable[i][rowLength - 1].data.label = 'row-disabled';
            } else if (amount !== 0 && amountToCover < 0 && currentOpenItemId) {
                updatedTable[i][rowLength - 1].data.label = 'row-available';
            }
        }

        //copying the whole object to trigger the Inuput change detection
        this.openItemsDataTable = [...updatedTable];
    }

    // The sum of all selected openItems.outstanding balances
    public coveredAmount(): number {
        return this.openItemListItems
            .filter((x) => this.openItemsSelected.includes(x.id))
            .reduce((sum, cur) => sum + cur.outstandingBalance, 0);
    }

    // The sum of all selected openItems.outstanding balances MINUS the bookingAmount
    public amountToCover(): number {
        return this.coveredAmount() - this.form?.get('accounts')?.value[0].amount ?? 0;
    }

    public getOpenItemAllocation(): {
        purpose: OpenItemDto['purpose'];
        description: string;
        settledAmount: string;
        outstandingBalance: string;
        isResidual: boolean;
    }[] {
        const selectedOpenItems = this.openItemListItems.filter((x) =>
            this.openItemsSelected.includes(x.id)
        ) as (OpenItemDto & { initialBookingCreditAccountAccountGroupType: string })[];

        const sortedOpenItems = calculateDistribution(
            (this.ledger?.openItemClosingStrategy ?? 'KOSTENTRAGUNG_FIRST') as OpenItemClosingStrategy,
            selectedOpenItems,
            this.form?.get('accounts')?.value[0].amount ?? 0
        );
        return sortedOpenItems.map((openItem) => ({
            purpose: openItem.purpose,
            description: openItem.description,
            settledAmount: new EurocentPipe().transform(openItem.settledAmount),
            outstandingBalance: new EurocentPipe().transform(openItem.outstandingBalance),
            isResidual: openItem.outstandingBalance > 0,
        }));
    }

    private compareOpenItemsDueDate(o1: OpenItemDto, o2: OpenItemDto): number {
        return o1.dueDate < o2.dueDate ? -1 : o1.dueDate > o2.dueDate ? 1 : 0;
    }

    public updateOpenItemSelection(rowSelected: RowSelected): void {
        const selectedOpenItemId = rowSelected.data[rowSelected.data.length - 1].data.link;
        if (this.openItemsSelected.includes(selectedOpenItemId)) {
            this.openItemsSelected = this.openItemsSelected.filter((id) => id != selectedOpenItemId);
            delete this.outstandingAmountOpenItemsSelected[selectedOpenItemId];
        } else {
            this.openItemsSelected.push(selectedOpenItemId);
            this.outstandingAmountOpenItemsSelected[selectedOpenItemId] =
                rowSelected.data[rowSelected.data.length - 2].data.label;
        }

        this.updateOpenItemStatus();
    }

    public updateLoadingFileStatus($event: boolean): void {
        this.filesLoaded = $event;
    }

    public updateReceiptFromSelection(ids: string[]): void {
        this.existingReceiptsSelected = ids;
    }

    public updateFileStorageIds(ids: string[]): void {
        this.fileStorageIdsSelected = ids;
    }

    public updateFileType($event: any): void {
        this.fileTypesSelected = $event;
    }

    private createOpenItemRow(openItem: OpenItemDto): TableItem[] {
        const link = undefined;

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const dueDate = new Date(openItem.dueDate);
        dueDate.setHours(0, 0, 0, 0);

        const openItemDataTable = [
            {
                data: {
                    label: openItem.description,
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: openItem.dueDate,
                    link,
                    textColor: dueDate <= today ? 's-red-01' : '',
                },
                template: CellTemplate.Date,
            },
            {
                data: {
                    label: openItem.createdAt,
                    link,
                },
                template: CellTemplate.Date,
            },
            {
                data: {
                    label: openItem.amount,
                    link,
                },
                template: CellTemplate.EuroCent,
            },
            {
                data: {
                    label: openItem.outstandingBalance,
                    link,
                },
                template: CellTemplate.EuroCent,
            },
            {
                data: {
                    label: 'row-disabled',
                    link: openItem.id,
                },
                template: CellTemplate.iconWithStatusItem,
            },
        ];
        if (this.selectedBookingId === 3) {
            const type =
                getTypeOpenItem(this.translateService, openItem.type) +
                (openItem.purpose ? ' ' + openItem.purpose : '');

            openItemDataTable.unshift({
                data: {
                    label: type,
                    link,
                },
                template: CellTemplate.Default,
            });
        }

        return openItemDataTable;
    }

    private createReceiptRow(receipt: ReceiptDto): TableItem[] {
        return [
            {
                data: {
                    label: receipt.canDelete
                        ? this.translateService.instant('COMMON.LABEL_NOT_LINKED')
                        : this.translateService.instant('COMMON.LABEL_LINKED'),
                    extraData: {
                        color: receipt.canDelete ? 'gray-03' : 'green',
                        fontWeight: 'semi-bold',
                    },
                    iconSrc: receipt.canDelete ? '/assets/icons/24_not-linked.svg' : '/assets/icons/24_linked.svg',
                },
                template: CellTemplate.textWithIcon,
            },
            {
                data: {
                    label: receipt.fileName,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: receipt.accountName || '–',
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: receipt.type
                        ? this.translateService.instant('ENTITIES.RECEIPT.LABEL_TYPE_' + receipt.type)
                        : '–',
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: receipt.createdAt,
                },
                template: CellTemplate.Date,
            },
            {
                data: {
                    label: receipt.receiptNumber,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: '',
                    extraData: { ...receipt, showOnlyWithHover: false, hideDeleteButton: true },
                },
                template: CellTemplate.filesActions,
            },
            {
                data: {
                    label: 'row-available',
                    link: receipt.id,
                },
                template: CellTemplate.iconWithStatusItem,
            },
        ];
    }

    private initOpenItemTableHeaders(): void {
        this.openItemsHeader = [
            'ACCOUNTING.COMMON.DESCRIPTION',
            'ENTITIES.OPEN_ITEM.DUE_DATE',
            'ACCOUNTING.OPEN-ITEMS.CREATED_AT',
            { data: { key: 'ENTITIES.OPEN_ITEM.AMOUNT', params: {} }, template: HeaderTemplate.RightAligned },
            {
                data: { key: 'ENTITIES.OPEN_ITEM.OUTSTANDING_BALANCE', params: {} },
                template: HeaderTemplate.RightAligned,
            },
            '',
        ];
        if (this.selectedBookingId === 3) {
            this.openItemsHeader.unshift('ENTITIES.OPEN_ITEM.GROUP');
        }
    }

    private setCustomTexts(): void {
        // For specific 'Vorschüsse' bookings showing specific texts for due date description
        if (this.selectedBookingId === 10 || this.selectedBookingId === 11 || this.selectedBookingId === 12) {
            const index = this.selectedShortBooking?.subgroupName.indexOf('–');
            this.guidedBookingSubGroup = index
                ? this.selectedShortBooking?.subgroupName.substring(index + 1).trim()
                : '';
        }

        if (this.selectedBookingId === 4) {
            this.toastSuccessMessage = this.translateService.instant(
                'PAGES.ADD_BOOKING_FORM.NEW_RECEIVABLES_SUCCESS_NOTIFICATION'
            );
        } else if (this.selectedBookingId === 5) {
            this.toastSuccessMessage = this.translateService.instant(
                'PAGES.ADD_BOOKING_FORM.NEW_LIABILITIES_SUCCESS_NOTIFICATION'
            );
        } else {
            this.toastSuccessMessage = this.translateService.instant(
                'PAGES.ADD_BOOKING_FORM.NEW_BOOKING_SUCCESS_NOTIFICATION'
            );
        }
    }

    private initReceiptTableHeaders(): void {
        this.receiptsHeader = [
            '',
            'PAGES.RECEIPTS.HEADER_FILE_NAME',
            'ENTITIES.DOCUMENT.LABEL_ACCOUNT',
            'ENTITIES.DOCUMENT.LABEL_TYPE',
            'PAGES.RECEIPTS.HEADER_UPLOADED_AT',
            'ENTITIES.RECEIPT.LABEL_RECEIPT_NUMBER',
            '',
            '',
        ];
    }

    public async onSubmitAndContinue(): Promise<void> {
        if (!this.isFormValid()) {
            console.warn('form is invalid');
            return;
        }

        this.populateBookingsToPush();

        try {
            this.isOverlayLoading = true;
            const createBookingPromises = this.bookings.map((b) =>
                firstValueFrom(this.guidedBookingsService.createGuidedBooking(this.ledgerId, b))
            );

            const createBookingFinishedPromises = await Promise.allSettled(createBookingPromises);
            if (createBookingFinishedPromises.some((p) => p.status === 'rejected')) {
                console.warn('error on creating some bookings');
                throw new Error('Es ist ein Fehler aufgetreten beim Erstellen der Buchungen');
            }

            if (!this.bankTransaction?.id) {
                console.warn('There is no bank transaction id');
                this.toastService.showSuccess(this.toastSuccessMessage);
                return;
            }
            this.toastService.showSuccess(this.toastSuccessMessage);
            const updatedBankTransaction = await firstValueFrom(
                this.bankTransactionsService.findeOneBankTransactionById(
                    this.bankTransaction!.id,
                    false,
                    undefined,
                    this.ledgerId
                )
            );
            const updatedBankAccount = await firstValueFrom(
                this.bankAccountsService.findOne(updatedBankTransaction.bankAccountId ?? '', this.ledgerId)
            );

            this.openAddBankAccountRecommendationOverlay({
                bankTransaction: updatedBankTransaction,
                beforeOpen: () => this.overlayService.removeOverlayComponentFromBody(),
                onClose: () => {
                    const baseData = {
                        ledgerId: this.ledgerId,
                        bankTransaction: updatedBankTransaction || null,
                        bankAccount: updatedBankAccount || null,
                        emitSaveOnOverlayClose: true,
                    };

                    const ref: OverlayRef = this.overlayService.open(AddGuidedBookingOverlayComponent, {
                        data: {
                            ...baseData,
                            selectedBooking: this.config?.data.selectedBooking,
                        },
                    });

                    //  Subscribing to the saveEmitter and Emitting it so the initial page gets it.
                    //  Here we create chaining of emitters, since only first opened overlay would update the initial page,
                    //  which opened the very first overlay
                    ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
                        this.saveEmitter$.next();
                    });
                },
            });
        } catch (e) {
            this.toastService.showError((e as any)?.message ?? 'Es ist ein Fehler aufgetreten');
        } finally {
            this.isOverlayLoading = false;
        }
    }

    public getSuggestedAmountFromOpenItems($event?: any): number {
        let bookingMonth = '';
        let bookingYear = '';
        if ($event) {
            bookingMonth = getLocalISOTime($event[0]).split('-')[1];
            bookingYear = getLocalISOTime($event[0]).split('-')[0];
        } else {
            bookingMonth = getLocalISOTime(this.form.get('bookingDate')!.value).split('-')[1];
            bookingYear = getLocalISOTime(this.form.get('bookingDate')!.value).split('-')[0];
        }
        let suggestedAmount = 0;
        this.openItemListItems.map((openItem: OpenItemDto) => {
            if (openItem.dueDate.split('-')[1] == bookingMonth && openItem.dueDate.split('-')[0] == bookingYear) {
                suggestedAmount += openItem.amount;
            }
        });

        return suggestedAmount;
    }

    public filterBookedReceiptsCheck($event: any): void {
        this.isFilteringBookingReceipts = $event;
        this.accountSelectedIds$.next(this.accountSelectedIds$.value);
    }

    public receiptsAllAccountsCheck($event: any): void {
        this.isReceiptsAllAccountsShowing = $event;
        this.accountSelectedIds$.next(this.accountSelectedIds$.value);
    }

    public hasBookingAdditionalParameter(parameterName: string): boolean {
        return !!this.guidedBooking?.additionalParameters.find(
            (parameter: AdditionalParameterDefinitionDto) => parameter.name === parameterName
        );
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public controlInFormGroupIsInvalid = controlInFormGroupIsInvalid;

    public formArrayItem(index: number): UntypedFormGroup {
        return (this.form?.get('accounts') as UntypedFormArray).at(index) as UntypedFormGroup;
    }

    public showBookingRuleSuggestion(): boolean {
        const selectedShortBookingIds = [1, 2, 1004, 1005];
        if (this.bankTransaction?.amount) {
            if (
                this.selectedShortBooking &&
                selectedShortBookingIds.includes(this.selectedShortBooking.id) &&
                this.bankTransaction?.id &&
                (this.formArrayItem(0)?.get('amount')?.value === this.bankTransaction?.amount > 0
                    ? this.bankTransaction?.amount
                    : this.bankTransaction?.amount * -1)
            ) {
                return true;
            }
        }

        this.createBookingRuleChecked = false;

        return false;
    }

    public disableBookingRuleSuggestionCheckbox(): boolean {
        return (this.form?.get('accounts') as UntypedFormArray).length > 1;
    }

    public itemsToFillBookingRuleSuggestion(): BookingRuleSuggestion {
        const formItem = this.formArrayItem(0);

        let vat = '';

        const isBoxChecked = formItem.get('checkboxTaxShare')?.value;
        if (isBoxChecked) {
            const taxShareId = formItem.get('taxShare')?.value;
            const shareAmount = formItem.get('amount')?.value;

            if (this.taxShare) {
                const findVatItem = this.taxShare.valueOptions.find((item) => item.id === taxShareId);

                if (findVatItem) {
                    vat = `${findVatItem?.amount}% ${this.eurocentPipe.transform(
                        calculateTaxAmount(findVatItem?.amount, shareAmount)
                    )} €`;
                }
            }
        }

        return {
            amountLabour: formItem.get('amountLabour')?.value,
            iban: this.bankTransaction?.counterpartIban,
            amount: this.bankTransaction?.amount,
            vat,
            bookingAccountDescription: formItem.get('account')?.value?.content,
            bookingAccountId: formItem.get('account')?.value?.accountId,
        };
    }

    public handleBookingRuleCheckboxOnChange(event: {
        createBookingRule: boolean;
        bookingRuleDescription: string;
    }): void {
        const { createBookingRule, bookingRuleDescription } = event;

        this.createBookingRuleChecked = createBookingRule;
        this.createBookingRuleDescription = bookingRuleDescription;
    }

    public createBookingRule(): void {
        if (!this.selectedShortBooking?.id) {
            return;
        }

        const formItem = this.formArrayItem(0);
        const taxShareId = formItem.get('taxShare')?.value;
        const findVatItem = this.taxShare?.valueOptions.find((item) => item.id === taxShareId);

        const createBookingRule: CreateBookingRuleDto = {
            name: this.createBookingRuleDescription ?? '',
            amount: this.bankTransaction?.amount ? Math.abs(this.bankTransaction?.amount) : undefined,
            counterpartIban: this.bankTransaction?.counterpartIban,
            counterpartName: this.bankTransaction?.counterpartName,
            labourAmount: formItem.get('amountLabour')?.value,
            vat: findVatItem?.amount,
            guidedBookingDefinitionId: this.selectedShortBooking?.id,
            accountToBookInId: formItem.get('account')?.value?.accountId,
        };

        this.bookingRulesService.createBookingRule(this.ledgerId, createBookingRule).subscribe({
            next: () => {
                this.saveEmitter$.next();
            },
            error: (error) => {
                if (error) {
                    this.toastService.showError(error.error['message']);
                }
            },
        });
    }
}
