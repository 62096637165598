<ibm-loading *ngIf="vm === null || vm.isLoading === true" [isActive]="true" [size]="'normal'" [overlay]="true">
</ibm-loading>

<div class="tw-pb-40 tw-px-10">
    <ibm-breadcrumb
        class="tw-mt-5 tw-block"
        *ngIf="vm && vm.breadcrumbs"
        [items]="vm.breadcrumbs"
        [noTrailingSlash]="true"
    >
    </ibm-breadcrumb>
    <h1 class="tw-s-head-28-36-bold tw-mt-8">Mandate</h1>
    <div class="tw-text-lg tw-font-bold tw-mt-10 tw-mb-6">
        {{ this.vm?.person ? getNameFromPerson(this.vm!.person!) : '-' }}
    </div>
    <div *ngFor="let sepaMandateItemVM of vm?.sepaMandateItemsVM" class="tw-mt-4">
        <div *ngFor="let row of sepaMandateItemVM?.rows" class="tw-flex">
            <div class="tw-s-label-12-22-semibold tw-w-[120px] lg:tw-w-[160px] tw-pr-1">
                {{ row.firstLabel }}
            </div>
            <div class="tw-s-body-14-22-regular">{{ row.secondLabel }}</div>
        </div>
        <div class="tw-h-px tw-bg-scalaraGray-05 tw-w-full tw-mt-4"></div>
    </div>
    <div *ngIf="vm && vm.sepaMandateItemsVM.length < 1">
        <div class="tw-flex tw-mt-8">
            <svg-icon
                class="tw-flex tw-justify-center tw-items-center tw-fill-scalaraBlue-01 tw-w-6 tw-h-6"
                src="/assets/icons/24_information.svg"
            ></svg-icon>
            <div class="tw-pl-3 tw-s-body-14-22-regular tw-inline-block">
                {{ 'PAGES.SEPA_MANDATE.NO_MANDATES' | translate }}
            </div>
        </div>
    </div>
</div>
