<div class="s-head-18-26-bold mb-3-5r">Entwicklung der Rücklagen</div>
<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [overlay]="false"> </ibm-loading>
<div *ngFor="let table of vm?.tableData?.reservesAsset; index as i">
    <div class="tw-flex tw-justify-items-start tw-mb-16">
        <div class="tw-mr-20">
            <table *ngIf="vm && vm.tableData.reservesDebit[i]" class="tw-min-w-[560px]">
                <ng-container *ngFor="let row of vm.tableData.reservesDebit[i].rows">
                    <tr
                        simpleTableRow
                        [columns]="vm.tableData.reservesDebit[i].columns"
                        [rowData]="row"
                        [class]="row.rowClass"
                    ></tr>
                </ng-container>
            </table>
        </div>
        <div>
            <table *ngIf="vm && vm.tableData.reservesAsset[i]" class="tw-min-w-[560px]">
                <ng-container *ngFor="let row of vm.tableData.reservesAsset[i].rows">
                    <tr
                        simpleTableRow
                        [columns]="vm.tableData.reservesAsset[i].columns"
                        [rowData]="row"
                        [class]="row.rowClass"
                    ></tr>
                </ng-container>
            </table>
        </div>
    </div>
</div>
<div class="tw-flex tw-justify-start tw-mb-16">
    <table *ngIf="vm && vm.tableData.actual" class="tw-min-w-[650px]">
        <ng-container *ngFor="let row of vm.tableData.actual.rows">
            <tr simpleTableRow [columns]="vm.tableData.actual.columns" [rowData]="row" [class]="row.rowClass"></tr>
        </ng-container>
    </table>
</div>

<div class="tw-flex tw-justify-start">
    <table *ngIf="vm && vm.tableData.coherenceCheck" class="tw-min-w-[650px]">
        <ng-container *ngFor="let row of vm.tableData.coherenceCheck.rows">
            <tr
                simpleTableRow
                [columns]="vm.tableData.coherenceCheck.columns"
                [rowData]="row"
                [class]="row.rowClass"
            ></tr>
        </ng-container>
    </table>
</div>
