<div class="bx--list-box__wrapper">
    <label *ngIf="label" [for]="id" class="bx--label" [ngClass]="{ 'bx--label--disabled': disabled }">
        <ng-container>{{ label }}</ng-container>
    </label>
    <div
        #listbox
        [ngClass]="{
            'bx--multi-select bx--multi-select--filterable': type === 'multi',
            'bx--list-box--light': theme === 'light',
            'bx--list-box--expanded': open,
            'bx--list-box--sm': size === 'sm',
            'bx--list-box--lg': size === 'xl',
            'bx--list-box--disabled': disabled,
            'bx--combo-box--warning bx--list-box--warning': warn
        }"
        class="bx--list-box bx--combo-box"
        [attr.data-invalid]="invalid ? true : null"
    >
        <div class="bx--list-box__field">
            <div *ngIf="type === 'multi' && pills.length > 0" class="bx--tag bx--tag--filter bx--tag--high-contrast">
                <span class="bx--tag__label">{{ pills.length }}</span>
                <button
                    type="button"
                    (click)="clearSelected($event)"
                    (keydown.enter)="clearSelected($event)"
                    class="bx--tag__close-icon"
                    tabindex="0"
                    [title]="clearSelectionsTitle"
                    [attr.aria-label]="clearSelectionAria"
                >
                    <svg
                        focusable="false"
                        preserveAspectRatio="xMidYMid meet"
                        style="will-change: transform"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        aria-hidden="true"
                    >
                        <path d="M12 4.7l-.7-.7L8 7.3 4.7 4l-.7.7L7.3 8 4 11.3l.7.7L8 8.7l3.3 3.3.7-.7L8.7 8z"></path>
                    </svg>
                </button>
            </div>
            <input
                #input
                type="text"
                autocomplete="off"
                role="combobox"
                [disabled]="disabled"
                (input)="onSearch($event)"
                (blur)="onBlur()"
                (click)="toggleDropdown()"
                (keydown.enter)="onSubmit($event)"
                [value]="selectedValue"
                class="bx--text-input"
                [ngClass]="{ 'bx--text-input--empty': !showClearButton }"
                tabindex="0"
                [id]="id"
                [attr.aria-labelledby]="id"
                [attr.aria-expanded]="open"
                aria-haspopup="listbox"
                [attr.maxlength]="maxLength"
                aria-haspopup="true"
                [attr.aria-autocomplete]="autocomplete"
                [placeholder]="placeholder"
            />

            <svg-icon
                *ngIf="!warn && invalid"
                src="/assets/icons/24_partly-closed.svg"
                svgClass="tw-h-5 tw-w-5 tw-fill-scalaraRed-01"
                class="bx--list-box__invalid-icon"
            ></svg-icon>
            <svg-icon
                *ngIf="!invalid && warn"
                src="/assets/icons/24_partly-closed.svg"
                svgClass="tw-h-5 tw-w-5 tw-fill-scalaraPurple-01"
                class="bx--list-box__invalid-icon"
            ></svg-icon>
            <div
                *ngIf="showClearButton && clearable"
                role="button"
                class="bx--list-box__selection"
                tabindex="0"
                [attr.aria-label]="clearSelectionAria"
                [title]="clearSelectionTitle"
                (keydown.enter)="clearInput($event)"
                (click)="clearInput($event)"
                (focusout)="stopPropagation($event)"
            >
                <svg-icon src="/assets/icons/24_close.svg" svgClass="tw-h-5 tw-w-5"></svg-icon>
            </div>
            <button
                type="button"
                role="button"
                class="bx--list-box__menu-icon"
                [title]="open ? closeMenuAria : openMenuAria"
                [attr.aria-label]="open ? closeMenuAria : openMenuAria"
                [ngClass]="{ 'bx--list-box__menu-icon--open': open }"
                (focusout)="stopPropagation($event)"
                (click)="toggleDropdown()"
            >
                <svg-icon src="/assets/icons/24_arrow-down.svg" svgClass="tw-h-6 tw-w-6"></svg-icon>
            </button>
        </div>
        <div
            #dropdownMenu
            [ngClass]="{
                'bx--list-box--up': this.dropUp !== null && this.dropUp !== undefined ? dropUp : _dropUp
            }"
            (focusout)="stopPropagation($event)"
        >
            <ng-content *ngIf="open"></ng-content>
        </div>
    </div>
    <div
        *ngIf="helperText && !invalid && !warn"
        class="bx--form__helper-text"
        [ngClass]="{ 'bx--form__helper-text--disabled': disabled }"
    >
        <ng-container>{{ helperText }}</ng-container>
    </div>
    <div *ngIf="!warn && invalid" class="bx--form-requirement">
        <ng-container>{{ invalidText }}</ng-container>
    </div>
    <div *ngIf="!invalid && warn" class="bx--form-requirement">
        <ng-container>{{ warnText }}</ng-container>
    </div>
</div>
