import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import {
    BreadcrumbModule,
    ButtonModule,
    CheckboxModule,
    DialogModule,
    DropdownModule,
    GridModule,
    InlineLoadingModule,
    InputModule,
    LoadingModule,
    RadioModule,
    SelectModule,
    TableModule,
    TabsModule,
} from 'carbon-components-angular';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SharedModule } from '../../shared/shared.module';
import { UserManagementModule } from '../user-management/user-management.module';
import { AddBankAccountOverlayComponent } from './components/add-bank-account-overlay/add-bank-account-overlay.component';
import { BankAccountItemComponent } from './components/bank-account-item/bank-account-item.component';
import { ChangePasswordOverlayComponent } from './components/change-password-overlay/change-password-overlay.component';
import { ContactDetailRowComponent } from './components/contact-detail-row/contact-detail-row.component';
import { AddUserAvatarOverlayComponent } from './components/contact-detail-view/add-user-avatar-overlay/add-user-avatar-overlay.component';
import { ContactDetailViewComponent } from './components/contact-detail-view/contact-detail-view.component';
import { ContactRolesOverviewComponent } from './components/contact-roles-overview/contact-roles-overview.component';
import { ContactsIndexComponent } from './components/contacts-index/contacts-index.component';
import { DeleteBankAccountOverlayComponent } from './components/delete-bank-account-overlay/delete-bank-account-overlay.component';
import { SepaMandatesOverviewComponent } from './components/sepa-mandates-overview/sepa-mandates-overview.component';

@NgModule({
    declarations: [
        ContactsIndexComponent,
        ContactDetailViewComponent,
        ContactRolesOverviewComponent,
        ChangePasswordOverlayComponent,
        AddBankAccountOverlayComponent,
        BankAccountItemComponent,
        ContactDetailRowComponent,
        DeleteBankAccountOverlayComponent,
        SepaMandatesOverviewComponent,
        AddUserAvatarOverlayComponent,
    ],
    imports: [
        BreadcrumbModule,
        CommonModule,
        TableModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        InputModule,
        SelectModule,
        GridModule,
        RadioModule,
        TranslateModule,
        ButtonModule,
        LoadingModule,
        DialogModule,
        SharedModule,
        DropdownModule,
        TabsModule,
        UserManagementModule,
        AngularSvgIconModule,
        InlineLoadingModule,
        CheckboxModule,
        ImageCropperModule,
    ],
})
export class ContactsModule {}
