import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbItem } from 'carbon-components-angular';
import { Subject, combineLatest, switchMap, takeUntil, tap } from 'rxjs';
import { BreadCrumbService } from 'src/app/core/services/breadcrumb.service';
import { PersonLocalService } from 'src/app/features/property/services/person-local.service';
import {
    DistributionKeyDto,
    EconomicPlanDto,
    EconomicPlansService,
    LedgerDto,
    LedgersService,
    OwnershipForDistributionKeyDto,
} from 'src/app/generated-sources/accounting';
import { Ownership, OwnershipsService, Person, Property } from 'src/app/generated-sources/base';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { WegEconomicPlanDistributionKeyDeleteComponent } from '../weg-economic-plan-distribution-key-delete/weg-economic-plan-distribution-key-delete.component';

export interface MockOwnership {
    id: string;
    location: string;
    distributionShare: number;
    description: string;
}

@Component({
    selector: 'app-weg-economic-plan-distribution-key-detail',
    templateUrl: './weg-economic-plan-distribution-key-detail.component.html',
    styleUrls: ['./weg-economic-plan-distribution-key-detail.component.scss'],
})
export class WegEconomicPlanDistributionKeyDetailComponent implements OnInit {
    public isLoading = false;
    public isClosingDistributionKeyDisabled = false;
    private unsubscribe$ = new Subject<void>();
    public ledgerId = '';
    public economicPlanId = '';
    public distributionKeyId = '';
    public breadcrumbs: BreadcrumbItem[] = [];
    public isDeletionHidden = false;
    public isPropertyManager = false;

    public isStandardKey = false;

    public economicPlan?: EconomicPlanDto;
    public distributionKey?: DistributionKeyDto;
    public sortedDistributionKeyOwnerships: OwnershipForDistributionKeyDto[] = [];
    public selectedOwnerships: Ownership[] = [];
    public distributionKeyBase = '';
    public mockTotalDistribution = 100.0;

    public constructor(
        private translateService: TranslateService,
        private breadcrumbService: BreadCrumbService,
        private route: ActivatedRoute,
        private overlayService: OverlayService,
        private economicPlansService: EconomicPlansService,
        private ledgerService: LedgersService,
        private ownershipsService: OwnershipsService,
        private personLocalService: PersonLocalService,
        private datePipe: DatePipe
    ) {}

    public ngOnInit(): void {
        this.isLoading = true;
        combineLatest([this.route.parent!.paramMap, this.route.paramMap])
            .pipe(
                tap((params: ParamMap[]) => {
                    this.ledgerId = String(params[0].get('id'));
                    this.economicPlanId = String(params[1].get('economicPlanId'));
                    this.distributionKeyId = String(params[1].get('distributionKeyId'));
                }),
                switchMap(() => this.economicPlansService.findOne(this.ledgerId, this.economicPlanId)),
                tap((economicPlan: EconomicPlanDto) => {
                    this.economicPlan = economicPlan;
                }),
                switchMap(() => this.personLocalService.getRolesOfProperty()),
                tap((roles: Property.PermissionRolesEnum[] | null) => {
                    this.isPropertyManager = roles
                        ? Object.values(roles).includes(Person.PermissionRolesEnum.PropertyManager)
                        : false;
                }),
                switchMap(() =>
                    this.economicPlansService.getDistributionKeyById(
                        this.ledgerId,
                        this.economicPlanId,
                        this.distributionKeyId
                    )
                ),
                tap((distributionKey: DistributionKeyDto) => {
                    this.distributionKey = distributionKey;
                    this.isStandardKey =
                        distributionKey.distributionGroup == 'ALL_OWNERSHIPS' &&
                        distributionKey.distributionBase !== 'CONSUMPTION';
                    if (distributionKey.distributionBase == 'OWNERSHIPS') {
                        this.distributionKeyBase = this.translateService.instant(
                            'ENTITIES.OWNERSHIP.LABEL_ENTITY_PLURAL'
                        );
                    } else {
                        this.distributionKeyBase = this.translateService.instant(
                            'ENTITIES.OWNERSHIP.LABEL_' + distributionKey.distributionBase
                        );
                    }

                    this.isDeletionHidden =
                        this.economicPlan?.status === EconomicPlanDto.StatusEnum.Active ||
                        this.isStandardKey ||
                        !this.isPropertyManager;
                }),
                switchMap(() => this.ledgerService.findOne(this.ledgerId)),
                switchMap((ledger: LedgerDto) => this.ownershipsService.findAll(ledger.propertyId)),
                tap((ownerships: Ownership[]) => {
                    this.selectedOwnerships = ownerships.filter((ownership: Ownership) => {
                        //Dirty quickfix for Production Should solve wrongly sorted Keys
                        const foundOwnership = this.distributionKey?.ownerships.find(
                            (distributionOwnership: OwnershipForDistributionKeyDto) =>
                                distributionOwnership.id === ownership.id
                        );
                        if (foundOwnership) {
                            this.sortedDistributionKeyOwnerships?.push(foundOwnership);
                            return foundOwnership;
                        }
                        return;
                    });
                    this.initBreadCrumbs();
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => (this.isLoading = false));
    }

    public openCloseDistributionKeyOverlay(): void {
        const ref = this.overlayService.open(WegEconomicPlanDistributionKeyDeleteComponent, {
            data: {
                ledgerId: this.ledgerId,
                economicPlanId: this.economicPlanId,
                distributionKeyId: this.distributionKeyId,
                distributionKeyDescription: this.distributionKey?.description,
            },
        });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
    }

    private initBreadCrumbs(): void {
        const breadcrumb: BreadcrumbItem[] = [
            {
                content: this.translateService.instant('ENTITIES.ECONOMIC_PLAN.LABEL_ENTITY_PLURAL'),
                route: [`/accounting/ledger/${this.ledgerId}/economic-plans`],
            },
            {
                content:
                    this.datePipe.transform(this.economicPlan?.startDate, 'dd.MM.yyyy') +
                    ' - ' +
                    this.datePipe.transform(this.economicPlan?.endDate, 'dd.MM.yyyy'),
                route: [`/accounting/ledger/${this.ledgerId}/economic-plans/${this.economicPlanId}`],
            },
            {
                content: this.isStandardKey
                    ? this.distributionKeyBase
                    : this.distributionKey?.description || 'Mock distribution key name',
                route: [
                    `/accounting/ledger/${this.ledgerId}/economic-plans/${this.economicPlanId}/distribution-keys/${this.distributionKeyId}`,
                ],
                current: true,
            },
        ];
        this.breadcrumbService.resetBreadCrumbs();
        this.breadcrumbService.updateBreadCrumbs(breadcrumb);
        this.breadcrumbs = this.breadcrumbService.getCurrentBreadCrumbs();
    }
}
