import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { CustomSubscriptionsService } from 'src/app/features/subscriptions/services/custom-subscriptions.service';
import { SubscriptionDto } from 'src/app/generated-sources/base';

export type ExtendedSubscriptionDto = SubscriptionDto & { tsType: 'subscriptionDto' };

@Injectable({
    providedIn: 'root',
})
export class ScalaraSubscriptionsGuard implements CanActivate {
    public constructor(private router: Router, private customSubscriptionsService: CustomSubscriptionsService) {
        this.customSubscriptionsService.getCurrentSubscription$().subscribe({
            next: (currentSubscription) => {
                const extendedCurrentSubscription: ExtendedSubscriptionDto | null = currentSubscription
                    ? { ...currentSubscription, tsType: 'subscriptionDto' }
                    : null;
                this.currentSubscription$.next(extendedCurrentSubscription);
                if (currentSubscription === null) {
                    if (this.router.url.startsWith('/subscriptions') || this.router.url === '/') {
                        return;
                    }
                    this.router.navigate(['/subscriptions']);
                }
            },
            error: () => {
                this.router.navigate(['/subscriptions']);
            },
        });
    }

    private currentSubscription$ = new BehaviorSubject<ExtendedSubscriptionDto | null | 'initial'>('initial');

    public resetSubscriptionGuard(): void {
        this.currentSubscription$.next('initial');
    }

    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.currentSubscription$.getValue() === 'initial') {
            return true;
        }
        if (this.currentSubscription$.getValue() !== null) {
            return true;
        }

        return false;
    }
}
