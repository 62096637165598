<app-overlay-details
    [overlayCallbacks]="overlayCallbacks"
    title="Sondereigentum"
    [itemListForPagination]="ownershipUnitIdList"
    [currentItemId]="(currentUnitId$ | async) ?? undefined"
    (changeItemOnOverlay)="findNewUnitId($event)"
>
    <ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"></ibm-loading>
    <div class="tw-space-y-2 tw-mb-8">
        <div class="tw-grid tw-grid-cols-4">
            <div class="tw-col-span-1 tw-s-label-12-22-semibold tw-text-scalaraGray-03">
                {{ 'ENTITIES.UNIT.LABEL_UNIT_TYPE' | translate }}
            </div>
            <div
                *ngIf="unit?.type; else notSpecifiedLabel"
                class="tw-col-start-2 tw-col-span-full tw-s-body-14-22-regular"
            >
                {{ unitType }}
            </div>
        </div>

        <div class="tw-grid tw-grid-cols-4">
            <div class="tw-col-span-1 tw-s-label-12-22-semibold tw-text-scalaraGray-03">
                {{ 'ENTITIES.UNIT.LABEL_UNIT_DESCRIPTION' | translate }}
            </div>
            <div
                *ngIf="unit?.name; else notSpecifiedLabel"
                class="tw-col-start-2 tw-col-span-full tw-s-body-14-22-regular"
            >
                {{ unit?.name }}
            </div>
        </div>

        <div class="tw-grid tw-grid-cols-4">
            <div class="tw-col-span-1 tw-s-label-12-22-semibold tw-text-scalaraGray-03">
                {{ 'ENTITIES.OWNERSHIP.LABEL_BUILDING' | translate }}
            </div>
            <div
                *ngIf="unit?.buildingName; else notSpecifiedLabel"
                class="tw-col-start-2 tw-col-span-full tw-s-body-14-22-regular"
            >
                {{ unit?.buildingName }}
            </div>
        </div>

        <div class="tw-grid tw-grid-cols-4">
            <div class="tw-col-span-1 tw-s-label-12-22-semibold tw-text-scalaraGray-03">
                {{ 'ENTITIES.OWNERSHIP.LABEL_LOCATION' | translate }}
            </div>
            <div
                *ngIf="unit?.location; else notSpecifiedLabel"
                class="tw-col-start-2 tw-col-span-full tw-s-body-14-22-regular"
            >
                {{ unit?.location }}
            </div>
        </div>
    </div>

    <div class="tw-space-y-2">
        <div class="tw-grid tw-grid-cols-4">
            <div class="tw-col-span-1 tw-s-label-12-22-semibold tw-text-scalaraGray-03">
                {{ 'ENTITIES.OWNERSHIP.LABEL_ROOM' | translate }}
            </div>
            <div
                *ngIf="unit?.roomCount; else notSpecifiedLabel"
                class="tw-col-start-2 tw-col-span-full tw-s-body-14-22-regular"
            >
                {{ unit?.roomCount }}
            </div>
        </div>

        <div class="tw-grid tw-grid-cols-4">
            <div class="tw-col-span-1 tw-s-label-12-22-semibold tw-text-scalaraGray-03">
                {{ 'ENTITIES.OWNERSHIP.LABEL_SQUARE_METERS' | translate }}
            </div>
            <div
                *ngIf="unit?.squaremeters; else notSpecifiedLabel"
                class="tw-col-start-2 tw-col-span-full tw-s-body-14-22-regular"
            >
                {{ unit?.squaremeters }}
            </div>
        </div>

        <div class="tw-grid tw-grid-cols-4">
            <div class="tw-col-span-1 tw-s-label-12-22-semibold tw-text-scalaraGray-03">
                {{ 'ENTITIES.OWNERSHIP.LABEL_HEATED_SQUARE_METERS' | translate }}
            </div>
            <div
                *ngIf="unit?.heatedSquaremeters; else notSpecifiedLabel"
                class="tw-col-start-2 tw-col-span-full tw-s-body-14-22-regular"
            >
                {{ unit?.heatedSquaremeters }}
            </div>
        </div>

        <div class="tw-grid tw-grid-cols-4">
            <div class="tw-col-span-1 tw-s-label-12-22-semibold tw-text-scalaraGray-03">
                {{ 'ENTITIES.OWNERSHIP.LABEL_CONDITION' | translate }}
            </div>
            <div
                *ngIf="unit?.condition; else notSpecifiedLabel"
                class="tw-col-start-2 tw-col-span-full tw-s-body-14-22-regular"
            >
                {{ unit?.condition }}
            </div>
        </div>

        <div class="tw-grid tw-grid-cols-4">
            <div class="tw-col-span-1 tw-s-label-12-22-semibold tw-text-scalaraGray-03">
                {{ 'ENTITIES.OWNERSHIP.LABEL_INVENTORY' | translate }}
            </div>
            <div
                *ngIf="unit?.inventory; else notSpecifiedLabel"
                class="tw-col-start-2 tw-col-span-full tw-s-body-14-22-regular"
            >
                {{ unit?.inventory }}
            </div>
        </div>
    </div>
</app-overlay-details>

<ng-template #notSpecifiedLabel>
    <div class="s-body-14-22-italic">
        {{ 'PAGES.OWNERSHIP.DETAIL_VIEW.LABEL_NOT_SPECIFIED' | translate }}
    </div>
</ng-template>
