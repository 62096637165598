<img src="{{ img | imageBase64 }}" *ngIf="img; else emptyImg" width="170" height="120" />

<div class="mt-1r mb-0-75r">
    <app-tag *ngIf="ticket?.property?.propertyType !== 'WEG_SEV'" [color]="tagColor">
        {{ ticket?.property?.propertyType }}</app-tag
    >
    <div class="d-flex" *ngIf="ticket?.property?.propertyType === 'WEG_SEV'">
        <app-tag class="mr-0-5r" color="green">WEG</app-tag>
        <app-tag color="orange">SEV</app-tag>
    </div>
</div>

<div class="s-body-14-22-bold">{{ ticket?.property?.name }}</div>
<div class="s-body-14-22-regular mb-0-5r">
    {{ ticket?.property?.address?.streetName }} {{ ticket?.property?.address?.streetNumber }},
    {{ ticket?.property?.address?.zipCode }} {{ ticket?.property?.address?.area }}
</div>

<div *ngFor="let ownership of ticket?.ownerships" class="s-body-14-22-regular mb-0-5r">
    {{ 'ENTITIES.OWNERSHIP.LABEL_ENTITY' | translate }} {{ ownership.name }}
</div>
<div *ngFor="let occupation of ticket?.occupations" class="s-body-14-22-regular mb-0-5r">
    {{ 'ENTITIES.OCCUPATION.LABEL_ENTITY' | translate }} {{ occupation.occupationNumber }}
</div>

<hr class="mb-1-5r mt-1-5r" />
<div>
    <div class="s-label-12-22-semibold s-gray-03 mb-0-75r">{{ 'ENTITIES.TICKET.RECIPIENTS' | translate }}</div>

    <ng-container
        *ngIf="
            ticket?.ownerships?.length === 0 && ticket?.occupations?.length === 0 && ticket?.createdByPropertyManager
        "
    >
        <div class="s-body-14-22-regular mb-1r">
            {{
                (ticket?.property?.propertyType === 'WEG'
                    ? 'PAGES.TICKET_DETAILS.ALL_OWNERS'
                    : 'PAGES.TICKET_DETAILS.ALL_OWNERS_MIETER'
                ) | translate
            }}
        </div>
        <a [href]="'/properties/' + ticket?.property?.id + '/ownerships'" class="s-link-14-22-regular s-gray-01">{{
            labelButtonToShowAllRecipients | translate
        }}</a>
    </ng-container>

    <ng-container *ngIf="recipients && recipients.length > 0">
        <div class="mb-1r recipients w-100" *ngFor="let recipient of recipients">
            <a class="s-gray-01" [href]="'/contacts/' + recipient.id">
                <app-headline-with-icon
                    [color]="recipient.hasRegisteredUser ? 'green' : 'gray'"
                    image="24_person.svg"
                    [text]="getNameFromPerson(recipient)"
                    textType="body"
                    textWeight="regular"
                    borderColor="gray"
                    [userAvatar]="recipient?.imgSmall ?? undefined"
                ></app-headline-with-icon
            ></a>
        </div>

        <div class="mb-1-5r" *ngIf="ticket && ticket.recipients && ticket.recipients.length > 8">
            <app-scalara-button [variant]="'ghost'" svgName="24_arrow-down.svg" (click)="showAllRecipients()">{{
                'PAGES.TICKET_DETAILS.SHOW_ALL' | translate
            }}</app-scalara-button>
        </div>
    </ng-container>
</div>

<ng-container *ngIf="ticket && ticket?.serviceProviders && ticket.serviceProviders.length > 0">
    <div class="s-label-12-22-semibold s-gray-03 mt-1-5r mb-0-75r">
        {{ 'ENTITIES.RELATION.LABEL_RELATION_TYPE_SERVICE_PROVIDER' | translate }}
    </div>

    <div class="mb-1r d-flex justify-space-between" *ngFor="let person of ticket?.serviceProviders">
        <a class="s-gray-01 recipients recipients-max-content" [href]="'/contacts/' + person.id">
            <app-headline-with-icon
                [color]="person.hasRegisteredUser ? 'green' : 'gray'"
                image="24_person.svg"
                [text]="getNameFromPerson(person)"
                textType="body"
                textWeight="regular"
                borderColor="gray"
                [userAvatar]="person?.imgSmall ?? undefined"
            ></app-headline-with-icon>
            <div class="ml-3-5r mb-0-75r">
                <div>{{ getFullAdress(person) }}</div>
                <div>{{ person.email }} {{ person.secondaryEmail }}</div>
                <div>{{ person.phoneNumbers ? person.phoneNumbers[0].phoneNumber : '' }}</div>
            </div>
        </a>
        <app-scalara-button
            (click)="removeServiceProvider(person)"
            variant="ghost"
            svgName="24_close.svg"
            height="40px"
            *ngIf="allowedToAddServiceProvider"
        ></app-scalara-button>
    </div>
</ng-container>

<div class="mt-1-25r" *ngIf="allowedToAddServiceProvider">
    <app-scalara-button (click)="openAddServiceProviderOverlay()" [variant]="'ghost'" svgName="24_add.svg">{{
        'PAGES.TICKET_DETAILS.ADD_SERVICE_PROVIDER' | translate
    }}</app-scalara-button>
</div>

<ng-template #emptyImg>
    <div class="empty-img d-flex align-items-center justify-content-center">
        <svg-icon src="/assets/icons/40_building.svg"></svg-icon>
    </div>
</ng-template>
