import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbItem } from 'carbon-components-angular';
import { Subject, of, switchMap, takeUntil, tap } from 'rxjs';
import { getOccupationTypeLabel, unitTypeNameFromEnum } from 'src/app/core/utils/common';
import { OesDistributionKeyDto } from 'src/app/generated-sources/accounting';
import { Occupation, OccupationService } from 'src/app/generated-sources/base';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { LedgerCustomService } from '../../services/ledger-custom.service';
import { DeleteOesDistributionKeyComponent } from '../delete-oes-distribution-key/delete-oes-distribution-key.component';

@Component({
    selector: 'app-weg-economic-plan-distribution-key-detail',
    templateUrl: './distribution-key-detail.component.html',
    styleUrls: ['./distribution-key-detail.component.scss'],
})
export class DistributionKeyDetailComponent implements OnInit {
    private unsubscribe$ = new Subject<void>();
    public breadcrumbs: BreadcrumbItem[] = [];
    public isLoading = false;
    public oesDistributionKey?: OesDistributionKeyDto;
    public ledgerId = '';
    public occupations: Occupation[] = [];
    public oesId = '';

    public constructor(
        public route: ActivatedRoute,
        private overlayService: OverlayService,
        private occupationService: OccupationService,
        private ledgerCustomService: LedgerCustomService
    ) {}

    public ngOnInit(): void {
        this.isLoading = true;
        this.route.queryParams
            .pipe(
                switchMap((params) => {
                    this.oesDistributionKey = {
                        id: params['id'],
                        description: params['description'],
                        distributionBase: params['distributionBase'],
                        distributionGroup: params['distributionGroup'],
                        occupations: JSON.parse(params['occupations']),
                        totalDistribution: params['totalDistribution'],
                    };

                    this.ledgerId = params['ledgerId'];
                    this.oesId = params['oesId'];

                    this.breadcrumbs = [
                        {
                            content: 'Betreibskostenabrechnung',
                            route: [`/accounting/ledger/${params['ledgerId']}/operations-expense-statements`],
                        },
                        {
                            content: params['oesDate'],
                            route: [
                                `/accounting/ledger/${params['ledgerId']}/operations-expense-statements/${params['oesId']}`,
                            ],
                        },
                        {
                            content: this.oesDistributionKey.description,
                            route: [
                                `/accounting/ledger/${params['ledgerId']}/operations-expense-statements/${params['oesId']}`,
                            ],
                            current: true,
                        },
                    ];

                    return this.ledgerCustomService.getLedgerPropertyId$();
                }),
                switchMap((propertyId) => {
                    const occupationsIds: string[] = [];
                    this.oesDistributionKey?.occupations.map((item) => occupationsIds.push(item.occupationId));
                    if (propertyId) {
                        return this.occupationService.findAllOccupations(propertyId || '', occupationsIds.join(','));
                    } else {
                        return of([]);
                    }
                }),
                tap((occupations) => {
                    this.occupations = occupations;
                    this.isLoading = false;
                })
            )
            .subscribe();
    }

    public openDeleteOverlay(): void {
        const ref = this.overlayService.open(DeleteOesDistributionKeyComponent, {
            data: {
                ledgerId: this.ledgerId,
                keyId: this.oesDistributionKey?.id,
                oesDistributionKeyDescription: this.oesDistributionKey?.description,
                oesId: this.oesId,
            },
        });

        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
    }

    public getOccupationDescription(occupationId: string): string {
        const occupation = this.occupations.find((item) => item.id === occupationId);
        if (occupation) {
            const units = occupation?.unitsIncluded
                .map((unit) => unitTypeNameFromEnum(unit) + ' ' + unit.name)
                .join(', ');
            return (
                getOccupationTypeLabel(occupation?.occupationType, occupation?.rentDetails?.rentType, false) +
                ', ' +
                occupation?.occupationNumber +
                ', ' +
                units
            );
        }

        return occupationId;
    }
}
