import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EnergyCertificateFile, TechnicalEquipmentFile } from 'src/app/generated-sources/base';

export type OverlayCallbacks = {
    deleteOverlay?: () => void;
    editOverlay?: () => void;
};
@Component({
    selector: 'app-overlay-details',
    templateUrl: './overlay-details.component.html',
    styleUrls: ['./overlay-details.component.scss'],
})
export class OverlayDetailsComponent implements OnInit {
    @Input() public title = '';
    @Input() public iconSrc?: string;
    @Input() public iconColor: 'scalaraGreen' | 'scalaraRed' | 'scalaraOrange' | 'scalaraBlue' = 'scalaraGreen';
    @Input() public documentsList?: EnergyCertificateFile[] | TechnicalEquipmentFile[];
    @Input() public tagLabel?: string;
    @Input() public tagColor?: 'green' | 'red' | 'orange' | 'blue' | 'purple' | 'gray';
    @Input() public overlayCallbacks?: OverlayCallbacks;

    // pagination
    @Input() public itemListForPagination?: string[];
    @Input() public currentItemId?: string;

    @Output() public changeItemOnOverlay = new EventEmitter<string>();

    public pageSize = 0;
    public currentPage = 0;

    public constructor() {}

    public ngOnInit(): void {
        if (this.itemListForPagination && this.currentItemId) {
            this.pageSize = this.itemListForPagination.length;
            this.currentPage = this.itemListForPagination.indexOf(this.currentItemId);
        }
    }

    public nextItem(): void {
        if (this.itemListForPagination && this.currentPage + 1 < this.itemListForPagination?.length) {
            this.currentPage++;
            this.changePage();
        }
    }

    public previousItem(): void {
        if (this.currentPage > 0) {
            this.currentPage--;
            this.changePage();
        }
    }

    public changePage(): void {
        if (this.itemListForPagination) {
            this.currentItemId = this.itemListForPagination[this.currentPage];
            this.changeItemOnOverlay.emit(this.currentItemId);
        }
    }

    public openDeleteOverlay(): void {
        if (this.overlayCallbacks?.deleteOverlay) {
            this.overlayCallbacks?.deleteOverlay();
        }
    }

    public openEditOverlay(): void {
        if (this.overlayCallbacks?.editOverlay) {
            this.overlayCallbacks?.editOverlay();
        }
    }
}
