<div class="tw-p-8">
    <div class="tw-s-head-28-36-bold">{{ 'PAGES.LIST_GENERATOR.LIST_GENERATOR' | translate }}</div>
    <div class="tw-mt-10 tw-space-y-6">
        <div
            *ngFor="let item of this.listGeneratorTypes"
            class="tw-flex tw-w-full tw-justify-between tw-items-center tw-border-solid tw-border tw-border-scalaraGray-05 tw-py-4 tw-px-6 hover:tw-cursor-pointer hover:tw-shadow-scalara-01"
            (click)="item.onClick()"
        >
            <div class="tw-flex tw-justify-items-start tw-items-center">
                <app-icon-bubble [size]="72" color="green" [image]="item.icon"></app-icon-bubble>
                <div class="tw-pl-6 tw-s-head-16-24-bold">{{ item.title }}</div>
            </div>
            <div class="tile__icon" class="tw-h-6 tw-w-6 tw--rotate-90">
                <svg-icon class="tw-fill-scalaraGray-03" src="/assets/icons/24_arrow-down.svg"></svg-icon>
            </div>
        </div>
    </div>
</div>
