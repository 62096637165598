import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    public constructor(private router: Router, private authService: AuthService) {}

    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const isLoggedIn = this.authService.isLoggedInSnapshot();
        const authRoutes = [
            'sign-in',
            'sign-up',
            'reset-password/give-email',
            'reset-password/set-password',
            'auth-success',
        ];

        if (authRoutes.includes(route.routeConfig?.path ?? '')) {
            if (isLoggedIn) {
                this.router.navigate(['/properties']);
            } else {
                //  if demo environment, redirect to sign-in page from any auth route, because only sign-in is available there
                if (environment.demoEnvironment && route.routeConfig?.path !== 'sign-in') {
                    this.router.navigate(['/sign-in']);
                    return false;
                }
                return true;
            }
        } else if (!isLoggedIn) {
            this.router.navigate(['/sign-in']);
        }
        return isLoggedIn;
    }
}
