import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbItem } from 'carbon-components-angular';
import { Subject, combineLatest, switchMap, takeUntil, tap } from 'rxjs';
import { BreadCrumbService } from 'src/app/core/services/breadcrumb.service';
import { PersonLocalService } from 'src/app/features/property/services/person-local.service';
import {
    EconomicPlanDto,
    EconomicPlansService,
    LedgerDto,
    LedgersService,
    OwnershipForDistributionKeyDto,
    WegSettlementStatementsService,
    WssDistributionKeyDto,
    WssDto,
} from 'src/app/generated-sources/accounting';
import { Ownership, OwnershipsService, Person, Property } from 'src/app/generated-sources/base';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { DeleteAnnualStatementDistributionKeyComponent } from '../delete-annual-statement-distribution-key/delete-annual-statement-distribution-key.component';

export interface MockOwnership {
    id: string;
    location: string;
    distributionShare: number;
    description: string;
}

@Component({
    selector: 'app-annual-statement-distribution-key-details',
    templateUrl: './annual-statement-distribution-key-details.component.html',
    styleUrls: ['./annual-statement-distribution-key-details.component.scss'],
})
export class AnnualStatementDistributionKeyDetailsComponent implements OnInit {
    public isLoading = false;
    public isClosingDistributionKeyDisabled = false;
    private unsubscribe$ = new Subject<void>();
    public ledgerId = '';
    public annualStatementId = '';
    public distributionKeyId = '';
    public breadcrumbs: BreadcrumbItem[] = [];
    public isDeletionHidden = false;
    public isPropertyManager = false;

    public isStandardKey = false;

    public annualStatement?: WssDto;
    public distributionKey?: WssDistributionKeyDto;
    public sortedDistributionKeyOwnerships: OwnershipForDistributionKeyDto[] = [];
    public selectedOwnerships: Ownership[] = [];
    public distributionKeyBase = '';
    public mockTotalDistribution = 100.0;

    public constructor(
        private translateService: TranslateService,
        private breadcrumbService: BreadCrumbService,
        private route: ActivatedRoute,
        private overlayService: OverlayService,
        private economicPlansService: EconomicPlansService,
        private annualStatementsService: WegSettlementStatementsService,
        private ledgerService: LedgersService,
        private ownershipsService: OwnershipsService,
        private personLocalService: PersonLocalService,
        private datePipe: DatePipe
    ) {}

    public ngOnInit(): void {
        this.isLoading = true;
        combineLatest([this.route.parent!.paramMap, this.route.paramMap])
            .pipe(
                tap((params: ParamMap[]) => {
                    this.ledgerId = String(params[0].get('id'));
                    this.annualStatementId = String(params[1].get('annualStatementId'));
                    this.distributionKeyId = String(params[1].get('distributionKeyId'));
                }),
                switchMap(() => this.annualStatementsService.findOne(this.ledgerId, this.annualStatementId)),
                tap((annualStatement: WssDto) => {
                    this.annualStatement = annualStatement;
                }),
                switchMap(() => this.personLocalService.getRolesOfProperty()),
                tap((roles: Property.PermissionRolesEnum[] | null) => {
                    this.isPropertyManager = roles
                        ? Object.values(roles).includes(Person.PermissionRolesEnum.PropertyManager)
                        : false;
                }),
                switchMap(() =>
                    this.annualStatementsService.getDistributionKeys(this.ledgerId, this.annualStatementId)
                ),
                tap((distributionKeys: WssDistributionKeyDto[]) => {
                    this.distributionKey = distributionKeys.find(
                        (distributionKey: WssDistributionKeyDto) => distributionKey.id === this.distributionKeyId
                    );

                    this.isStandardKey =
                        this.distributionKey?.distributionGroup == 'ALL_OWNERSHIPS' &&
                        this.distributionKey?.distributionBase !== 'CONSUMPTION';
                    if (this.distributionKey?.distributionBase == 'OWNERSHIPS') {
                        this.distributionKeyBase = this.translateService.instant(
                            'ENTITIES.OWNERSHIP.LABEL_ENTITY_PLURAL'
                        );
                    } else {
                        this.distributionKeyBase = this.translateService.instant(
                            'ENTITIES.OWNERSHIP.LABEL_' + this.distributionKey?.distributionBase
                        );
                    }

                    this.isDeletionHidden =
                        this.annualStatement?.status === EconomicPlanDto.StatusEnum.Active ||
                        this.isStandardKey ||
                        !this.isPropertyManager;
                }),
                switchMap(() => this.ledgerService.findOne(this.ledgerId)),
                switchMap((ledger: LedgerDto) => this.ownershipsService.findAll(ledger.propertyId)),
                tap((ownerships: Ownership[]) => {
                    this.selectedOwnerships = ownerships.filter((ownership: Ownership) => {
                        //Dirty quickfix for Production Should solve wrongly sorted Keys
                        const foundOwnership = this.distributionKey?.ownerships.find(
                            (distributionOwnership: OwnershipForDistributionKeyDto) =>
                                distributionOwnership.id === ownership.id
                        );
                        if (foundOwnership) {
                            this.sortedDistributionKeyOwnerships?.push(foundOwnership);
                            return foundOwnership;
                        }
                        return;
                    });
                    this.initBreadCrumbs();
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => (this.isLoading = false));
    }

    public openCloseDistributionKeyOverlay(): void {
        const ref = this.overlayService.open(DeleteAnnualStatementDistributionKeyComponent, {
            data: {
                ledgerId: this.ledgerId,
                annualStatementId: this.annualStatementId,
                distributionKeyId: this.distributionKeyId,
                distributionKeyDescription: this.distributionKey?.description,
            },
        });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
    }

    private initBreadCrumbs(): void {
        const breadcrumb: BreadcrumbItem[] = [
            {
                content: this.translateService.instant('ENTITIES.ANNUAL_STATEMENT.LABEL_ENTITY'),
                route: [`/accounting/ledger/${this.ledgerId}/annual-statements`],
            },
            {
                content:
                    this.datePipe.transform(this.annualStatement?.economicPlan?.startDate, 'dd.MM.yyyy') +
                    ' – ' +
                    this.datePipe.transform(this.annualStatement?.economicPlan?.endDate, 'dd.MM.yyyy'),
                route: [`/accounting/ledger/${this.ledgerId}/annual-statements/${this.annualStatementId}`],
                current: true,
            },
            {
                content: this.isStandardKey
                    ? this.distributionKeyBase
                    : this.distributionKey?.description || 'Mock distribution key name',
                route: [
                    `/accounting/ledger/${this.ledgerId}/annual-statements/${this.annualStatementId}/distribution-keys/${this.distributionKeyId}`,
                ],
                current: true,
            },
        ];
        this.breadcrumbService.resetBreadCrumbs();
        this.breadcrumbService.updateBreadCrumbs(breadcrumb);
        this.breadcrumbs = this.breadcrumbService.getCurrentBreadCrumbs();
    }
}
