import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, finalize, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { OccupationService } from 'src/app/generated-sources/base';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';

@Component({
    selector: 'app-delete-occupation',
    templateUrl: './delete-occupation.component.html',
    styleUrls: ['./delete-occupation.component.scss'],
})
export class DeleteOccupationComponent extends OverlayChildComponent implements OnInit {
    public isLoading = false;
    private unsubscribe$ = new Subject<void>();
    public occupation?: any; // mudar type
    public propertyId = '';
    public occupationStatus = 'draft';

    public constructor(
        private toastService: ToastService,
        private translateService: TranslateService,
        private occupationService: OccupationService,
        private router: Router
    ) {
        super();
    }

    public ngOnInit(): void {
        this.occupation = this.config?.data.occupation;
        this.propertyId = this.config?.data.propertyId;
        this.occupationStatus = this.config?.data.occupationStatus;

        console.log(this.occupationStatus);
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public onSubmit(): void {
        this.isLoading = true;

        (this.occupationStatus === 'draft'
            ? this.occupationService.removeOccupationForm(this.propertyId, this.occupation?.id || '')
            : this.occupationService.removeOccupation(this.propertyId, this.occupation?.id || '')
        )
            .pipe(
                takeUntil(this.unsubscribe$),
                finalize(() => (this.isLoading = false))
            )
            .subscribe({
                next: () => {
                    this.router.navigate(['/properties', this.propertyId, 'occupations']);
                    this.toastService.showSuccess(
                        this.translateService.instant('PAGES.OCCUPATION.DELETE.SUCCESS_TOAST')
                    );
                    this.saveEmitter$.next();
                },
                error: (error) => {
                    if (error) {
                        this.toastService.showError(error.error['message']);
                    }
                },
            });
    }
}
