<app-overlay-container
    headlineTitle="PAGES.BANK_ACCOUNT_DETAILS.LABEL_UPLOAD_BANK_TRANSACTION"
    [sizeIcon]="72"
    image="40_upload.svg"
    colorIcon="green"
    containerSize="default"
>
    <ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

    <div
        [ngClass]="{
            'tw-mb-16': bankTransactionUploadType === 'default',
            'tw-mb-12': bankTransactionUploadType === 'template'
        }"
    >
        <ibm-radio-group aria-label="radiogroup" orientation="vertical" [(ngModel)]="bankTransactionUploadType">
            <ibm-radio value="default"> Dateityp hochladen: .mt940, .mta, .pcc</ibm-radio>
            <ibm-radio value="template"> .csv Template herunterladen und ausgefüllt hochladen</ibm-radio>
        </ibm-radio-group>
    </div>

    <ng-container *ngIf="bankTransactionUploadType === 'template'">
        <div
            (click)="downloadBankTransactionTemplate()"
            class="tile tw-flex tw-items-center tw-justify-between tw-border-[1px] tw-border-scalaraGray-05 tw-border-solid tw-p-4 tw-cursor-pointer tw-max-w-[436px] tw-mb-12"
            [ngClass]="{ 'tw-pointer-events-none': isLoadingDownloadTemplate }"
        >
            <div class="tw-flex tw-items-center">
                <div
                    class="icon-container icon-container--72 icon-container--green"
                    [ngClass]="{
                        'icon-container--green': !isLoadingDownloadTemplate,
                        'icon-container--gray': isLoadingDownloadTemplate
                    }"
                >
                    <svg-icon src="/assets/icons/navi_40_report.svg"></svg-icon>
                </div>
                <div class="tw-s-head-14-22-semibold tw-ml-5">.csv Template herunterladen</div>
            </div>

            <div *ngIf="!isLoadingDownloadTemplate" class="tw-fill-scalaraGray-02 tw-h-6 tw-w-6">
                <svg-icon src="/assets/icons/24_download.svg"></svg-icon>
            </div>

            <ibm-loading *ngIf="isLoadingDownloadTemplate" [overlay]="false" size="sm"> </ibm-loading>
        </div>

        <div class="tw-mb-4 tw-s-body-14-22-bold">Ausgefüllte Datei hochladen</div>
    </ng-container>

    <app-file-input
        inputLabel="{{ 'PAGES.BANK_ACCOUNT_DETAILS.INPUT_AREA' | translate }}"
        [acceptFormats]="bankTransactionUploadType === 'default' ? ['.mt940', '.mta', '.pcc'] : ['.csv']"
        (updateUploadedFile)="fileChangeEvent($event)"
    ></app-file-input>

    <div class="file mt-1-5r" *ngIf="selectedFile">
        <div class="d-flex align-items-center p-1r">
            <svg-icon src="/assets/icons/24_checkbox-check-green.svg"></svg-icon>
            <div class="px-05 s-body-14-20-regular file__name-wrapper">
                <div class="file__name-text">{{ selectedFile.name }}</div>
            </div>
        </div>
    </div>

    <div class="d-flex justify-space-between mt-7-5r">
        <app-scalara-button (click)="abort()" variant="outline">
            {{ 'CONTROLS.BUTTONS.CANCEL' | translate }}
        </app-scalara-button>

        <app-scalara-button (click)="submit()" [disabled]="!selectedFile" variant="default" type="submit">
            {{ 'CONTROLS.BUTTONS.SAVE' | translate }}
        </app-scalara-button>
    </div>
</app-overlay-container>
