<form [formGroup]="personForm">
    <fieldset>
        <legend class="tw-mb-2">
            <span class="tw-s-head-14-22-semibold tw-text-scalaraGray-03">
                <app-text-with-tooltip
                    class="tw-inline-block"
                    [label]="'USER_MANAGEMENT.EDIT_PROFILE_FORM.LABEL_TYPE' | translate"
                    [labelClass]="''"
                    [tooltipKey]="TooltipKey.tooltipsInProperties"
                    [tooltipInnerHtml]="tooltipInnerHtml"
                ></app-text-with-tooltip>
            </span>
        </legend>

        <ibm-radio-group
            aria-label="radiogroup"
            orientation="vertical"
            formControlName="type"
            (change)="onRadioChange($event)"
        >
            <ibm-radio [value]="personTypeDeclared.COMPANY">
                {{ 'ENTITIES.PERSON.LABEL_PERSON_TYPE_COMPANY' | translate }}
            </ibm-radio>
            <ibm-radio [value]="personTypeDeclared.NAT_PERSON">
                {{ 'ENTITIES.PERSON.LABEL_PERSON_TYPE_NAT_PERSON' | translate }}
            </ibm-radio>
        </ibm-radio-group>
    </fieldset>

    <div
        class="tw-flex tw-justify-between tw-space-x-8"
        *ngIf="personForm.get('type')!.value === personTypeDeclared.NAT_PERSON"
    >
        <ibm-label
            [invalid]="isInvalid('firstName')"
            invalidText="{{ 'ERROR.REQUIRED' | translate }}"
            class="tw-w-full"
        >
            {{ 'ENTITIES.PERSON.LABEL_FIRSTNAME' | translate }}*
            <input ibmText size="xl" type="firstName" name="firstName" id="firstName" formControlName="firstName" />
        </ibm-label>

        <ibm-label [invalid]="isInvalid('lastName')" invalidText="{{ 'ERROR.REQUIRED' | translate }}" class="tw-w-full">
            {{ 'ENTITIES.PERSON.LABEL_LASTNAME' | translate }}*
            <input ibmText size="xl" type="lastName" name="lastName" id="lastName" formControlName="lastName" />
        </ibm-label>
    </div>

    <div *ngIf="personForm.get('type')?.value === personTypeDeclared.COMPANY">
        <ibm-label [invalid]="isInvalid('companyName')" invalidText="{{ 'ERROR.REQUIRED' | translate }}">
            {{ 'ENTITIES.PERSON.LABEL_COMPANY_NAME' | translate }}*
            <input ibmText type="companyName" name="companyName" id="companyName" formControlName="companyName" />
        </ibm-label>
    </div>

    <div formGroupName="address">
        <div class="tw-flex tw-justify-between tw-space-x-8 tw-items-center">
            <div class="tw-w-full">
                <app-scalara-input-address-autocomplete
                    formControlName="streetName"
                    label="{{ 'ENTITIES.ADDRESS.LABEL_STREET_NAME' | translate }} *"
                    [invalid]="isAddressFieldInvalid('streetName')"
                    invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                    (autocompletePlaceSelected)="onAutocompletePlaceSelected($event)"
                ></app-scalara-input-address-autocomplete>
            </div>
            <div>
                <ibm-label
                    [invalid]="isAddressFieldInvalid('streetNumber')"
                    invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                >
                    {{ 'ENTITIES.ADDRESS.LABEL_STREET_NUMBER' | translate }}*
                    <input
                        ibmText
                        type="streetNumber"
                        name="streetNumber"
                        id="streetNumber"
                        formControlName="streetNumber"
                    />
                </ibm-label>
            </div>
        </div>

        <ibm-label>
            {{ 'ENTITIES.ADDRESS.LABEL_STREET_ADDITION' | translate }}
            <input
                ibmText
                type="streetAddition"
                name="streetAddition"
                id="streetAddition"
                formControlName="streetAddition"
            />
        </ibm-label>

        <div class="tw-grid tw-grid-cols-3 tw-gap-8">
            <div class="tw-col-span-1">
                <ibm-label
                    [invalid]="isAddressFieldInvalid('zipCode')"
                    invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                >
                    {{ 'ENTITIES.ADDRESS.LABEL_ZIP_CODE' | translate }}*
                    <input ibmText type="zipCode" name="zipCode" id="zipCode" formControlName="zipCode" />
                </ibm-label>
            </div>

            <div class="tw-col-start-2 tw-col-span-full">
                <ibm-label [invalid]="isAddressFieldInvalid('area')" invalidText="{{ 'ERROR.REQUIRED' | translate }}">
                    {{ 'ENTITIES.ADDRESS.LABEL_AREA' | translate }}*
                    <input ibmText type="area" name="area" id="area" formControlName="area" />
                </ibm-label>
            </div>
        </div>

        <div>
            <ibm-select
                label="{{ 'ENTITIES.ADDRESS.LABEL_COUNTRY' | translate }}*"
                name="country"
                id="country"
                formControlName="country"
            >
                <option [value]="null" selected disabled>
                    {{ 'CONTROLS.FORMCONTROLS.LABEL_COMBOBOX_EMPTY' | translate }}
                </option>
                <option *ngFor="let country of countries" [value]="country.code">
                    {{ country.name }}
                </option>
            </ibm-select>
        </div>
    </div>

    <!-- ONLY FOR SERVICE PROVIDER! -->
    <ng-container *ngIf="showInputsForServiceProvider()">
        <ibm-label [invalid]="isInvalid('companyRegistrationNumber')" invalidText="{{ 'ERROR.GENERAL' | translate }}">
            Handelsregisternr.
            <input
                ibmText
                name="companyRegistrationNumber"
                id="companyRegistrationNumber"
                formControlName="companyRegistrationNumber"
            />
        </ibm-label>
        <ibm-label [invalid]="isInvalid('vatIdentificationNumber')" invalidText="{{ 'ERROR.GENERAL' | translate }}">
            USt-Id Nr.
            <input
                ibmText
                name="vatIdentificationNumber"
                id="vatIdentificationNumber"
                formControlName="vatIdentificationNumber"
            />
        </ibm-label>
        <ibm-label [invalid]="isInvalid('taxNumber')" invalidText="{{ 'ERROR.GENERAL' | translate }}">
            Steuernummer
            <input ibmText name="taxNumber" id="taxNumber" formControlName="taxNumber" />
        </ibm-label>
    </ng-container>

    <!-- TODO: fix form control name -->
    <!-- ONLY FOR PERSON && OWNER -->
    <ibm-date-picker
        *ngIf="showBirtdayInput()"
        label="Geburtsdatum"
        id="birthday"
        [placeholder]="'tt.mm.jjjj'"
        [language]="'de'"
        [dateFormat]="'d.m.Y'"
        formControlName="birthday"
    >
    </ibm-date-picker>

    <!-- primary and secondary email -->
    <div class="tw-flex tw-justify-between tw-space-x-8">
        <ibm-label [invalid]="isInvalid('email')" invalidText="{{ 'ERROR.EMAIL' | translate }}" class="tw-w-full">
            {{ 'ENTITIES.PERSON.LABEL_PRIMARY_EMAIL' | translate }}
            <input ibmText type="email" name="email" id="email" formControlName="email" />
        </ibm-label>
        <ibm-label
            [invalid]="isInvalid('secondaryEmail')"
            invalidText="{{ 'ERROR.EMAIL' | translate }}"
            class="tw-w-full"
        >
            {{ 'ENTITIES.PERSON.LABEL_SECONDARY_EMAIL' | translate }}
            <input ibmText type="email" name="secondaryEmail" id="secondaryEmail" formControlName="secondaryEmail" />
        </ibm-label>
    </div>

    <!-- PHONES  -->
    <ng-container formArrayName="phoneNumbers">
        <ng-container *ngFor="let item of phonesControl.controls; let i = index" [formGroupName]="i">
            <ng-container *ngIf="i > 0">
                <hr class="mt-2r" />
                <div class="d-flex justify-content-end">
                    <app-scalara-button
                        (click)="removePhone(i)"
                        variant="ghost"
                        svgName="24_close.svg"
                        height="40px"
                    ></app-scalara-button>
                </div>
            </ng-container>

            <div class="tw-flex tw-justify-between tw-space-x-8">
                <div class="tw-w-full">
                    <ibm-label invalidText="{{ 'ERROR.PHONE' | translate }}">
                        {{ 'ENTITIES.PERSON.LABEL_PHONE_ONE' | translate }}
                        <input
                            (change)="setPhoneValidation(i)"
                            ibmText
                            name="phoneNumber"
                            id="phoneNumber"
                            formControlName="phoneNumber"
                        />
                    </ibm-label>
                </div>

                <div class="tw-w-full">
                    <app-combo-box
                        size="md"
                        label="{{ 'ENTITIES.PERSON.LABEL_NOTE' | translate }} {{
                            phoneControlItem(i).get('phoneNumber')?.value ? '*' : ''
                        }}"
                        placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT' | translate }}"
                        [clearable]="false"
                        [items]="phoneNoteList"
                        formControlName="phoneNumberType"
                        [invalid]="isPhoneFormControlInvalid(i, 'phoneNumberType')"
                        invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                    >
                        <ibm-dropdown-list></ibm-dropdown-list>
                    </app-combo-box>
                </div>
            </div>
        </ng-container>

        <div class="tw-mt-4" *ngIf="phonesControl.controls.length <= 10">
            <app-scalara-button (click)="addNewPhone()" variant="ghost" svgName="24_add.svg">
                Weitere Telefonummer
            </app-scalara-button>
        </div>
    </ng-container>
    <!-- END PHONES ARRAY CONTROLS -->

    <ibm-label class="tw-mt-8" [invalid]="isInvalid('website')" invalidText="{{ 'ERROR.PHONE' | translate }}">
        Website
        <input ibmText name="website" id="website" formControlName="website" />
    </ibm-label>
    <ibm-label [invalid]="isInvalid('miscellaneousInfo')" invalidText="{{ 'ERROR.PHONE' | translate }}">
        Sonstiges
        <input ibmText name="miscellaneousInfo" id="miscellaneousInfo" formControlName="miscellaneousInfo" />
    </ibm-label>
</form>
