<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<div ibmGrid class="bx--grid--full-width pb-10r" *ngIf="!this.isLoading">
    <div ibmRow>
        <div ibmCol></div>
        <div ibmCol [columnNumbers]="{ md: 8, lg: 8 }" class="px-00">
            <div ibmRow class="s-head-28-36-bold mb-5r">
                <div class="d-flex align-items-center">
                    <div class="mr-06">
                        <app-icon-bubble [size]="72" [color]="'red'" [image]="'40_delete.svg'"></app-icon-bubble>
                    </div>
                    <div>
                        {{ 'PAGES.RELATIONS.DELETE_RELATION.HEADLINE' | translate : { relation: this.relationType } }}
                    </div>
                </div>
            </div>

            <div ibmRow class="s-head-16-24-bold mb-2r">
                {{ 'PAGES.RELATIONS.DELETE_RELATION.TITLE' | translate : { relation: this.relationType } }}
            </div>

            <div *ngIf="originalRelation.parent === 'advisers'" ibmRow class="s-head-14-22-regular mb-2r">
                {{ 'PAGES.RELATIONS.DELETE_RELATION.ADVISER_INFO' | translate }}
            </div>

            <div ibmRow class="mb-7-5r d-flex align-items-center">
                <app-small-person-infobox
                    [personDto]="originalRelation.person"
                    [options]="['name']"
                ></app-small-person-infobox>
                <div class="s-body-14-22-regular ml-2r">
                    {{ originalRelation.startDate | dateFormat }} – {{ originalRelation.endDate! | dateFormat }}
                </div>
            </div>

            <div ibmRow class="d-flex justify-space-between">
                <button [ibmButton]="'tertiary'" (click)="abort()" type="reset">
                    {{ 'COMMON.BTN_CANCEL' | translate }}
                </button>

                <button [ibmButton]="'danger'" (click)="submit()">
                    {{ 'ACCOUNTING.DELETE_ECONOMIC_PLAN.DELETE_BUTTON' | translate }}
                </button>
            </div>
        </div>
        <div ibmCol></div>
    </div>
</div>
