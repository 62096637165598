import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ListItem } from 'carbon-components-angular';
import { map, Subject, tap } from 'rxjs';
import { getPersonDescriptionCombobox } from 'src/app/core/utils/common';
import { Person, PersonsService, PropertiesService } from 'src/app/generated-sources/base';

@Component({
    selector: 'app-existing-persons-combo-box',
    templateUrl: './existing-persons-combo-box.component.html',
    styleUrls: ['./existing-persons-combo-box.component.scss'],
})
export class ExistingPersonsComboBoxComponent implements OnInit {
    public constructor(private personsService: PersonsService, private propertiesService: PropertiesService) {}

    @Output() public handlePersonSelection = new EventEmitter<Person>();
    @Output() public isLoadingEvent = new EventEmitter<boolean>();
    @Input() public isAdviser?: boolean;
    @Input() public propertyId = '';

    public unsubscribe$ = new Subject<void>();
    public persons?: Person[] = [];
    public personsListItems: ListItem[] = [];

    public selectedPersonForm = new UntypedFormControl(null, Validators.required);

    public ngOnInit(): void {
        this.isLoadingEvent.emit(true);
        if (this.isAdviser) {
            this.propertiesService
                .findOwnerRelations(this.propertyId)
                .pipe(
                    map((relations) => {
                        this.personsListItems = relations
                            .filter(
                                (value, index, self) =>
                                    index === self.findIndex((t) => t.person?.id === value.person?.id)
                            )
                            .map((relation) => {
                                this.persons?.push(relation.person);
                                return {
                                    selected: false,
                                    content: this.getContent(relation.person),
                                    id: relation.person.id,
                                };
                            });
                    }),
                    tap(() => this.isLoadingEvent.emit(false))
                )
                .subscribe();
        } else {
            this.personsService
                .findAll()
                .pipe(
                    map((persons) => {
                        this.persons = persons;
                        this.personsListItems = persons.map((person) => {
                            return {
                                selected: false,
                                content: this.getContent(person),
                                id: person.id,
                            };
                        });
                    }),
                    tap(() => this.isLoadingEvent.emit(false))
                )
                .subscribe();
        }
    }

    public getContent = getPersonDescriptionCombobox;

    public isValidPerson(): boolean {
        return Boolean(this.selectedPersonForm.valid);
    }

    public invalidatePerson(): void {
        this.selectedPersonForm.setValue(null);
    }

    public onSelect(): void {
        const selectedPersonId = this.selectedPersonForm.value?.id ?? '';
        const selectedPerson = this.persons?.find((person) => person.id === selectedPersonId);
        this.handlePersonSelection.emit(selectedPerson);
    }
}
