import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, fromEvent, interval, of, startWith, switchMap, takeUntil, tap } from 'rxjs';
import { StatusService } from 'src/app/core/services/status.service';
import { getPropertyTypeInfos } from 'src/app/core/utils/common';
import { LedgerStatusInfoDto, LedgersService } from 'src/app/generated-sources/accounting';
import { Property } from 'src/app/generated-sources/base';
import { TableModel } from 'src/app/shared/table/interfaces/table-model';
import { ScalaraAccordionComponent } from '../components/scalara-accordion/scalara-accordion.component';
import { SearchComponent } from '../table/components/search/search.component';
import { TableComponent } from '../table/components/table/table.component';

@Component({
    selector: 'app-property-switch',
    templateUrl: './property-switch.component.html',
    styleUrls: ['./property-switch.component.scss'],
})
export class PropertySwitchComponent implements OnChanges, OnInit, OnDestroy, AfterViewInit {
    @Input() public property?: Property;
    @Input() public menuTitle: 'property' | 'ledger' = 'property';
    @Input() public additionalInfo?: string;
    @Input() public table: TableModel = { data: [], header: [] };
    @Input() public ledgerId?: string;

    @ViewChild(ScalaraAccordionComponent) public scalaraAccordionComponent?: ScalaraAccordionComponent;

    @ViewChild(SearchComponent) public searchComponent?: SearchComponent;

    @ViewChild(TableComponent)
    public tableComponent?: TableComponent;

    public statusInfo: string[] | [] = [''];

    private destroy$ = new Subject<void>();
    private windowBlur$ = new Subject<void>();

    public tagColor = '';

    public constructor(
        private ledgersService: LedgersService,
        private statusService: StatusService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    public ngOnChanges(): void {
        this.tagColor = this.property ? getPropertyTypeInfos(this.property?.propertyType)?.color : '';

        if (this.ledgerId && !this.statusInfo) {
            // this call was also made here to avoid showing an empty string in the first 5 seconds
            this.ledgersService
                .getStatusInfo(this.ledgerId)
                .pipe(
                    tap((statusInfo) => {
                        this.statusInfo = this.formatStatusInfo(statusInfo);
                    })
                )
                .subscribe();
        }
    }

    public ngAfterViewInit(): void {
        this.scalaraAccordionComponent?.isOpen$.subscribe((isOpen) => {
            if (isOpen) {
                this.searchComponent?.changeSearchDisplay();
            }
        });
    }

    public ngOnInit(): void {
        if (this.menuTitle === 'ledger') {
            fromEvent(window, 'focus')
                .pipe(
                    startWith(undefined),
                    switchMap(() =>
                        interval(10000).pipe(
                            takeUntil(this.windowBlur$),
                            switchMap(() => {
                                if (this.ledgerId) {
                                    return this.ledgersService.getStatusInfo(this.ledgerId);
                                } else {
                                    return of(null);
                                }
                            }),
                            tap((statusInfo) => {
                                if (statusInfo !== null) {
                                    this.statusService.setStatus(statusInfo);
                                    this.statusInfo = this.formatStatusInfo(statusInfo);
                                }
                            })
                        )
                    ),

                    takeUntil(this.destroy$)
                )
                .subscribe();

            fromEvent(window, 'blur').subscribe(() => {
                this.windowBlur$.next();
            });
        }
    }

    public formatStatusInfo(statusInfo: LedgerStatusInfoDto): string[] {
        if (statusInfo.messages[0] && statusInfo.messages[0].includes('Buchungen werden ausgeführt')) {
            const index = statusInfo.messages[0].indexOf('ausgeführt') + 10;
            const firstPart = statusInfo.messages[0].substring(0, index);
            const secondPart = statusInfo.messages[0].substring(index).trim();

            return [firstPart, secondPart];
        } else {
            return statusInfo.messages;
        }
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.windowBlur$.complete();
    }

    public onRowClick(event: any): void {
        const clickedRow = this.tableComponent?.model.row(event);
        const id = clickedRow && clickedRow.length > 0 ? clickedRow[0].data.extraData.id : '';
        let urlSegment = this.route.snapshot.firstChild?.url[0]?.path;

        if (clickedRow && id && urlSegment) {
            const newProperType = (
                this.menuTitle === 'ledger'
                    ? clickedRow[0].data.extraData.type
                    : clickedRow[0].data.extraData.propertyType
            ) as Property.PropertyTypeEnum;

            switch (urlSegment) {
                case 'annual-statements':
                    if (newProperType === 'SEV' || newProperType === 'MV') {
                        urlSegment = 'operations-expense-statements';
                    }
                    break;
                case 'operations-expense-statements':
                    if (newProperType === 'WEG') {
                        urlSegment = 'annual-statements';
                    }
                    break;
                case 'economic-plans':
                    if (newProperType === 'SEV' || newProperType === 'MV') {
                        urlSegment = 'account-charts';
                    }
                    break;
                case 'advisers':
                    if (newProperType === 'SEV' || newProperType === 'MV' || newProperType === 'WEG_SEV') {
                        urlSegment = 'base-data';
                    }
                    break;
                case 'building-details':
                    urlSegment = 'base-data';
                    break;
                case 'unit':
                    urlSegment = 'ownerships';
                    break;
                default:
                    break;
            }

            this.router.navigate(['../', id, `${urlSegment}`], { relativeTo: this.route });
        }

        this.scalaraAccordionComponent?.onHeaderClick();
    }

    public closeAccordion(): void {
        if (this.scalaraAccordionComponent?.isOpen$.getValue()) {
            this.scalaraAccordionComponent?.onHeaderClick();
        }
    }

    public getPropertyLabel(property: Property | undefined): string {
        if (!property) {
            return '';
        }
        const partsSeparatedWithComma = [
            `${property?.address?.streetName} ${property?.address?.streetNumber}`,
            `${property?.address?.zipCode} ${property?.address?.area}`,
        ].filter((part) => ['', ' '].includes(part) === false);

        return partsSeparatedWithComma.join(', ');
    }
}
