<div
    class="details-container details-container--{{ options.state }} "
    [ngClass]="{ 'details-container--is-due': options.type === 'openItem' && options.isDue }"
>
    <div ibmRow>
        <div ibmCol [columnNumbers]="{ md: 16, lg: 16, xl: 16 }">
            <div class="details-container__header">
                <span class="icon-container--24 mr-04 icon-container__custom">
                    <svg-icon [src]="getIconUrl()"></svg-icon>
                </span>
                <span class="s-label-12-16-regular">
                    {{ getLabel() }}
                </span>
            </div>
        </div>
    </div>
    <div ibmRow>
        <ng-content></ng-content>
    </div>
</div>
