<div class="tw-w-full tile tile--shadow tile--not-clickable tile--static tw-px-10 tw-py-8 tw-relative">
    <div class="tw-pb-11">
        <div
            *ngIf="tagLabel"
            class="tw-bg-scalaraBlue-03 tw-text-scalaraBlue-01 tw-s-label-12-16-regular tw-py-[6px] tw-px-3 tw-rounded-b-lg tw-absolute tw-top-0 tw-right-10"
        >
            {{ tagLabel }}
        </div>

        <ng-content select="[top-container]"></ng-content>
    </div>

    <div class="tw-absolute tw-right-0 tw-left-0">
        <div class="tw-bg-scalaraGray-05 tw-h-[1px]"></div>
        <div class="tw-absolute tw-translate-y-[-50%] tw-left-10">
            <app-icon-bubble [size]="40" [color]="dividerIconColor" image="24_arrow-down.svg"></app-icon-bubble>
        </div>
    </div>

    <div class="tw-pt-[52px]">
        <ng-content select="[bottom-container]"></ng-content>
    </div>
</div>
