import { Injectable } from '@angular/core';
import {
    CognitoAccessToken,
    CognitoIdToken,
    CognitoRefreshToken,
    CognitoUser,
    CognitoUserPool,
    CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { BehaviorSubject, distinctUntilChanged, filter, from, Observable, of, shareReplay, Subject } from 'rxjs';
import { UserDto } from 'src/app/generated-sources/base';
import { environment } from 'src/environments/environment';
import { BaseHttpService } from './base-http.service';

interface PoolData {
    UserPoolId: string;
    ClientId: string;
}

interface AuthStorage {
    name: string;
    user: CognitoUser | null;
    session: CognitoUserSession | null;
    pool: CognitoUserPool | null;
    email: string;
    scalaraUser: UserDto | null;
}

@Injectable({
    providedIn: 'root',
})
export class AuthService extends BaseHttpService {
    private readonly poolData: PoolData;
    private readonly userPool: CognitoUserPool;
    private storage!: AuthStorage | null;

    private user$ = new Subject<CognitoUser>();
    private userSession$ = new Subject<CognitoUserSession>();
    private isLoggedIn$ = new BehaviorSubject<boolean>(false);

    private accessTokenCache?: CognitoAccessToken;
    private idTokenCache?: CognitoIdToken;
    private refreshTokenCache?: CognitoRefreshToken;
    private cachedUserSession?: CognitoUserSession;

    private scalaraUser$ = new BehaviorSubject<UserDto | null>(null);

    public constructor() {
        super();

        this.poolData = {
            UserPoolId: environment.cognitoUserPoolId,
            ClientId: environment.cognitoAppClientId,
        };

        this.initStorage();
        this.loadStorage();

        this.scalaraUser$.next(this.storage?.scalaraUser || null);

        this.userPool = new CognitoUserPool(this.poolData);
        this.setIsLoggedIn();
    }

    public getStorage(): AuthStorage {
        return this.storage!;
    }

    public isLoggedIn(): BehaviorSubject<boolean> {
        return this.isLoggedIn$;
    }

    public isLoggedInDebounced(): Observable<boolean> {
        return this.isLoggedInDebounced$;
    }

    public refreshOnLogin(): Observable<boolean> {
        return this.refreshOnLogin$;
    }

    public isLoggedInSnapshot(): boolean {
        return this.isLoggedIn$.getValue();
    }

    public getPoolData(): PoolData {
        return this.poolData;
    }

    public getUserPool(): CognitoUserPool {
        return this.userPool;
    }

    public setUserSession(session: CognitoUserSession): void {
        this.userSession$.next(session);
    }

    public getUserSession(): Subject<CognitoUserSession> {
        return this.userSession$;
    }

    public setUser(user: CognitoUser): void {
        this.user$.next(user);
    }

    public getUser(): Subject<CognitoUser> {
        return this.user$;
    }

    public setIsLoggedIn(): void {
        let isLoggedIn = false;

        const cognitoUser = this.userPool.getCurrentUser(); // console.log(cognitoUser?.signInUserSession.idToken.payload.email);

        if (cognitoUser !== null) {
            this.user$.next(cognitoUser);
            cognitoUser.getSession((error: Error | null, session: CognitoUserSession) => {
                if (error) {
                    alert(error.message || JSON.stringify(error));
                }
                isLoggedIn = session.isValid();

                this.cachedUserSession = session;

                if (this.storage) {
                    this.storage.session = session;
                    this.storage.user = cognitoUser;
                    this.storage.pool = this.userPool;
                }

                this.saveToSessionStore(this.storage?.name || '', this.storage);
            });
        }

        this.isLoggedIn$.next(isLoggedIn);
    }

    public signOut(): void {
        this.getUserGroupsFromIdToken();
        this.clearStorage();
        const user = this.userPool.getCurrentUser();
        if (user) {
            user.signOut();
            this.isLoggedIn$.next(false);
        }
    }

    private initStorage(): void {
        if (this.storage && 'name' in this.storage && this.storage.name === 'auth-service-storage') {
            return;
        }
        this.storage = {
            name: 'auth-service-storage',
            user: null,
            session: null,
            pool: null,
            email: '',
            scalaraUser: null,
        };
    }

    private loadStorage(): AuthStorage | null {
        if (!this.storage) {
            return this.storage;
        }

        this.storage = super.getFromSessionStore(this.storage.name)
            ? super.getFromSessionStore(this.storage.name)
            : this.storage;

        return this.storage;
    }

    private clearStorage(): void {
        if (this.storage && sessionStorage.getItem(this.storage.name)) {
            sessionStorage.removeItem(this.storage.name);
        }
        this.storage = null;
        this.initStorage();
    }

    public getToken(): Observable<unknown> {
        const currentUser = this.userPool.getCurrentUser();
        if (!currentUser) {
            return of('');
        } else {
            return from(
                new Promise((resolve, reject) => {
                    currentUser.getSession((error: Error | null, session: CognitoUserSession) => {
                        if (error) {
                            reject(error);
                        } else if (!session.isValid()) {
                            resolve(null);
                        } else {
                            resolve(session.getAccessToken().getJwtToken());
                        }
                    });
                })
            );
        }
    }

    public getCachedAccessToken(): CognitoAccessToken | undefined {
        return this.cachedUserSession?.getAccessToken();
    }

    public getCachedIdToken(): CognitoIdToken | undefined {
        return this.cachedUserSession?.getIdToken();
    }
    public getUserGroupsFromIdToken(): string[] | null {
        let groups: string[];
        const claims = this.cachedUserSession?.getIdToken().payload;

        if (claims) {
            groups = claims['cognito:groups'] || [''];
            return groups;
        }
        return null;
    }

    public getCachedRefreshToken(): CognitoRefreshToken | undefined {
        return this.cachedUserSession?.getRefreshToken();
    }

    public storeEmail(email: string): void {
        this.storage!.email = email;
        this.saveToSessionStore(this.storage!.name, this.storage);
    }

    public storeScalaraUser(user: UserDto): void {
        this.scalaraUser$.next(user);
        this.storage!.scalaraUser = user;
        this.saveToSessionStore(this.storage!.name, this.storage);
    }

    public getScalaraUser(): Observable<UserDto | null> {
        return this.scalaraUser$.asObservable().pipe(distinctUntilChanged());
    }

    private readonly isLoggedInDebounced$ = this.isLoggedIn$
        .asObservable()
        .pipe(distinctUntilChanged(), shareReplay({ bufferSize: 1, refCount: true }));

    //  ships only true once if logged in
    private readonly refreshOnLogin$ = this.isLoggedInDebounced$.pipe(filter(Boolean));
}
