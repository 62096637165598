<div class="main-container">
    <div class="d-flex align-items-center tw-mb-16">
        <h1 class="s-head-28-36-bold">Kautionsübersicht</h1>
        <app-tooltip position="bottom" class="">
            <div content class="tooltip-content">
                {{ 'PAGES.OCCUPATION.DEPOSIT_OVERVIEW.TOOLTIP_MESSAGE' | translate }}
            </div>
            <div hoverable-item class="hoverable-item">
                <div class="icon-container-custom ml-1r">
                    <svg-icon src="/assets/icons/24_information.svg"></svg-icon>
                </div>
            </div>
        </app-tooltip>
    </div>
    <div class="d-flex justifiy-content-end w-100"><app-search [searchInputId]="'depositOverview'"></app-search></div>
    <div *ngIf="vm && vm.tableModel">
        <app-table
            [data]="vm.tableModel.data"
            [header]="vm.tableModel.header"
            [tableSearchId]="'depositOverview'"
            [emptyText]="'PAGES.OCCUPATION.DEPOSIT_OVERVIEW.EMPTY_TABLE_MESSAGE'"
            [isLoading]="isLoading"
        ></app-table>
    </div>
</div>

<!-- custom templates  -->
<ng-template #personsCell let-data="data">
    <div class="data-table-link">
        <ng-container *ngIf="data.extraData.persons.length > 0">
            <a
                class="person-link"
                [routerLink]="['/contacts', person?.id]"
                *ngFor="let person of data.extraData.persons; first as isFirst"
            >
                {{ isFirst ? '' : ', ' }} {{ getNameFromPerson(person) }}
            </a>
        </ng-container>
    </div>
</ng-template>
