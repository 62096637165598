<div class="mb-5r">
    <div class="s-head-28-36-bold mb-1r">{{ 'PAGES.DOCUMENTS.HEADLINE' | translate }}</div>
    <div class="s-head-14-22-regular">
        {{ 'PAGES.DOCUMENTS.SUBTITLE_OVERVIEW' | translate }}
    </div>
</div>

<app-document-table
    title="PAGES.DOCUMENTS.TABLE_TITLE_OVERVIEW"
    [showButton]="false"
    [inputData]="{ flow: DocumentsFlow.property }"
></app-document-table>
