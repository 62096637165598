import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Subject, combineLatest, map, of, switchMap, takeUntil, tap } from 'rxjs';
import { BankAccountDto, BankAccountsService } from 'src/app/generated-sources/accounting';
import { LedgerCustomService } from '../../../services/ledger-custom.service';

@Component({
    selector: 'app-banking-accounts-index',
    templateUrl: './banking-accounts-index.component.html',
    styleUrls: ['./banking-accounts-index.component.scss'],
})
export class BankingAccountsIndexComponent implements OnInit {
    private unsubscribe$ = new Subject<void>();
    public bankAccounts?: BankAccountDto[];
    public refreshBankAccounts$ = new BehaviorSubject<void>(undefined);

    public ledger$ = this.ledgerCustomService.getLedgerId$().pipe(
        map((ledger) => {
            if (ledger) {
                return ledger;
            }
            return undefined;
        }),

        takeUntil(this.unsubscribe$)
    );

    public constructor(
        private bankAccountService: BankAccountsService,
        private ledgerCustomService: LedgerCustomService
    ) {}

    public ngOnInit(): void {
        combineLatest([this.ledger$, this.refreshBankAccounts$])
            .pipe(
                switchMap(([ledgerId]) => {
                    console.log('entrou aqui em findAll bankAccounts');
                    if (ledgerId) {
                        return this.bankAccountService.findAll(ledgerId);
                    }
                    return of([]);
                }),
                tap((bankAccounts) => (this.bankAccounts = bankAccounts))
            )
            .subscribe();
    }

    public updateBankAccounts(): void {
        this.refreshBankAccounts$.next();
    }
}
