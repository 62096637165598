<div class="d-flex justify-space-between mb-1-5r">
    <div class="tw-flex tw-items-center">
        <app-headline-with-icon color="green" image="24_dokument.svg" [text]="title!"></app-headline-with-icon>
        <app-text-with-tooltip
            *ngIf="tooltipInfo"
            class="tw-inline-block"
            [label]="''"
            [labelClass]="''"
            [tooltipKey]="tooltipInfo.tooltipKey"
            [tooltipInnerHtml]="tooltipInfo.tooltipValue"
        ></app-text-with-tooltip>
    </div>
    <div class="d-flex justify-space-between">
        <app-search [searchInputId]="'document-table'"></app-search>
        <app-scalara-button
            *ngIf="showButton"
            variant="ghost"
            (click)="openAddDocumentOverlay()"
            svgName="24_add.svg"
            >{{ 'PAGES.DOCUMENTS.UPLOAD_BUTTON' | translate }}</app-scalara-button
        >
    </div>
</div>

<app-table
    *ngIf="vm"
    [emptyText]="'PAGES.DOCUMENTS.EMPTY_TABLE'"
    [header]="vm.tableModel?.header"
    [data]="vm.tableModel?.data"
    (handleAction)="handleActions($event)"
    [tableSearchId]="'document-table'"
    [isLoading]="vm.isLoading"
>
</app-table>

<ng-container *ngIf="fileToView && showModal">
    <app-file-viewer [fileToView]="fileToView" (closeViewer)="handleModal()"></app-file-viewer>
</ng-container>
