import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import {
    BreadcrumbModule,
    ButtonModule,
    CheckboxModule,
    DatePickerModule,
    DropdownModule,
    GridModule,
    IconModule,
    InlineLoadingModule,
    InputModule,
    LoadingModule,
    RadioModule,
    SelectModule,
    TableModule,
    TabsModule,
    UIShellModule,
} from 'carbon-components-angular';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SharedModule } from '../../shared/shared.module';
import { RelationsModule } from '../relations/relations.module';
import { AddEditPropertyFormComponent } from './components/add-edit-property-form/add-edit-property-form.component';
import { AddPropertySelectionComponent } from './components/add-property-selection/add-property-selection.component';
import { AdvisersOverviewComponent } from './components/advisers-overview/advisers-overview.component';
import { DeleteDocumentComponent } from './components/delete-document/delete-document.component';
import { DeleteOccupationComponent } from './components/delete-occupation/delete-occupation.component';
import { DocumentsOverviewComponent } from './components/documents-overview/documents-overview.component';
import { AddDocumentOverlayComponent } from './components/documents/add-document-overlay/add-document-overlay.component';
import { DocumentTableComponent } from './components/documents/document-table/document-table.component';
import { EditOccupationPeriodComponent } from './components/edit-occupation-period/edit-occupation-period.component';
import { OccupationsOverviewComponent } from './components/occupations-overview/occupations-overview.component';
import { OverviewComponent } from './components/overview/overview.component';
import { AddEditBuildingOverlayComponent } from './components/property-base-data/building/add-edit-building-overlay/add-edit-building-overlay.component';
import { DeleteBuildingOverlayComponent } from './components/property-base-data/building/delete-building-overlay/delete-building-overlay.component';
import { PropertyBuldingDetailComponent } from './components/property-base-data/building/property-bulding-detail/property-bulding-detail.component';
import { AddEditEnergyCertificateOverlayComponent } from './components/property-base-data/energy-certificate/add-edit-energy-certificate-overlay/add-edit-energy-certificate-overlay.component';
import { DeleteEnergyCertificateOverlayComponent } from './components/property-base-data/energy-certificate/delete-energy-certificate-overlay/delete-energy-certificate-overlay.component';
import { EnergyCertificateIndexComponent } from './components/property-base-data/energy-certificate/energy-certificate-index/energy-certificate-index.component';
import { PropertyBaseDataGeneralInfoCardComponent } from './components/property-base-data/property-base-data-index/property-base-data-general-info-card/property-base-data-general-info-card.component';
import { PropertyBaseDataIndexComponent } from './components/property-base-data/property-base-data-index/property-base-data-index.component';
import { AddEditSharedOwnershipOverlayComponent } from './components/property-base-data/shared-ownership/add-edit-shared-ownership-overlay/add-edit-shared-ownership-overlay.component';
import { DeleteSharedOwnershipOverlayComponent } from './components/property-base-data/shared-ownership/delete-shared-ownership-overlay/delete-shared-ownership-overlay.component';
import { SharedOwnershipDetailsComponent } from './components/property-base-data/shared-ownership/shared-ownership-details/shared-ownership-details.component';
import { SharedOwnershipIndexComponent } from './components/property-base-data/shared-ownership/shared-ownership-index/shared-ownership-index.component';
import { AddEditTechnicalEquipmentOverlayComponent } from './components/property-base-data/technical-equipment/add-edit-technical-equipment-overlay/add-edit-technical-equipment-overlay.component';
import { DeleteTechnicalEquipmentComponent } from './components/property-base-data/technical-equipment/delete-technical-equipment/delete-technical-equipment.component';
import { TechnicalEquipmentDetailsOverlayComponent } from './components/property-base-data/technical-equipment/technical-equipment-details-overlay/technical-equipment-details-overlay.component';
import { TechnicalEquipmentIndexComponent } from './components/property-base-data/technical-equipment/technical-equipment-index/technical-equipment-index.component';
import { PropertyDetailViewComponent } from './components/property-detail-view/property-detail-view.component';
import { PropertyOwnershipsComponent } from './components/property-ownerships/property-ownerships.component';
import { ServiceProvidersOverviewComponent } from './components/service-providers-overview/service-providers-overview.component';
import { AddEditMvUnitComponent } from './components/unit-ownership/add-edit-mv-unit/add-edit-mv-unit.component';
import { AddEditOwnershipFormComponent } from './components/unit-ownership/add-edit-ownership-form/add-edit-ownership-form.component';
import { MvUnitDetailsComponent } from './components/unit-ownership/mv-unit-details/mv-unit-details.component';
import { OwnershipUnitDetailsOverlayComponent } from './components/unit-ownership/ownership-unit-details-overlay/ownership-unit-details-overlay.component';
import { WegOwnershipDetailsComponent } from './components/unit-ownership/weg-ownership-details/weg-ownership-details.component';

@NgModule({
    declarations: [
        AddEditPropertyFormComponent,
        PropertyDetailViewComponent,
        OverviewComponent,
        PropertyBaseDataIndexComponent,
        PropertyOwnershipsComponent,
        AddEditOwnershipFormComponent,
        ServiceProvidersOverviewComponent,
        DocumentTableComponent,
        AddDocumentOverlayComponent,
        DocumentsOverviewComponent,
        DeleteDocumentComponent,
        AdvisersOverviewComponent,
        AddPropertySelectionComponent,
        AddEditBuildingOverlayComponent,
        AddEditMvUnitComponent,
        DeleteOccupationComponent,
        OccupationsOverviewComponent,
        PropertyBaseDataGeneralInfoCardComponent,
        SharedOwnershipIndexComponent,
        EnergyCertificateIndexComponent,
        AddEditSharedOwnershipOverlayComponent,
        AddEditEnergyCertificateOverlayComponent,
        PropertyBuldingDetailComponent,
        TechnicalEquipmentIndexComponent,
        DeleteBuildingOverlayComponent,
        AddEditTechnicalEquipmentOverlayComponent,
        TechnicalEquipmentDetailsOverlayComponent,
        SharedOwnershipDetailsComponent,
        DeleteSharedOwnershipOverlayComponent,
        DeleteEnergyCertificateOverlayComponent,
        DeleteTechnicalEquipmentComponent,
        WegOwnershipDetailsComponent,
        MvUnitDetailsComponent,
        OwnershipUnitDetailsOverlayComponent,
        EditOccupationPeriodComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        InputModule,
        SelectModule,
        GridModule,
        RadioModule,
        TranslateModule,
        ButtonModule,
        LoadingModule,
        InlineLoadingModule,
        TableModule,
        UIShellModule,
        SharedModule,
        BreadcrumbModule,
        DropdownModule,
        ImageCropperModule,
        AngularSvgIconModule,
        IconModule,
        RelationsModule,
        TabsModule,
        DatePickerModule,
        CheckboxModule,
    ],
    exports: [DocumentTableComponent],
})
export class PropertyModule {}
