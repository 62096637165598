import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { formControl, formControlHasError } from 'src/app/core/utils/common';
import { passwordConfirmInvalid, passwordInvalid } from 'src/app/core/utils/formValidationHelpers';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';
import { PasswordValidators } from 'src/app/shared/validators/password-validators';

@Component({
    selector: 'app-change-password-overlay',
    templateUrl: './change-password-overlay.component.html',
    styleUrls: ['./change-password-overlay.component.scss'],
})
export class ChangePasswordOverlayComponent extends OverlayChildComponent {
    public readonly MIN_PASSWORD_LENGTH = 8;
    public constructor(
        public formBuilder: UntypedFormBuilder,
        public authService: AuthService,
        public toastService: ToastService,
        public translateService: TranslateService
    ) {
        super();
    }

    public isLoading = false;
    public form: UntypedFormGroup = this.formBuilder.group(
        {
            existingPassword: ['', [Validators.required]],
        },
        { validators: [PasswordValidators.passwordMatch] }
    );

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public submit(): void {
        if (this.form.invalid) {
            return;
        }
        this.isLoading = true;
        const cachedCognitoUser = this.authService.getStorage().user;
        const existingPassword = this.form.get('existingPassword')?.value;
        const newPassword = this.form.get('password')?.value;

        if (!cachedCognitoUser) {
            console.error('NO USER');
            return;
        }

        cachedCognitoUser.changePassword(existingPassword, newPassword, (err) => {
            if (err) {
                this.setExistingPasswordError(true);
                return;
            }
            this.saveEmitter$.next();
        });
        this.isLoading = false;
    }

    public get existingPasswordInvalid(): boolean {
        const control = formControl(this.form, 'existingPassword');
        return formControlHasError(control, 'required') || formControlHasError(control, 'incorrect');
    }

    public get passwordInvalid(): boolean {
        return passwordInvalid({ form: this.form });
    }

    public get passwordConfirmInvalid(): boolean {
        return passwordConfirmInvalid({ form: this.form });
    }

    public setExistingPasswordError(val: boolean): void {
        const errors = this.form.get('existingPassword')?.errors;
        this.form.controls['existingPassword'].setErrors({ ...errors, incorrect: val });
    }
}
