import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationDetails, CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { BehaviorSubject, Subject, of, switchMap, takeUntil, tap } from 'rxjs';
import { LedgerCustomService } from 'src/app/features/accounting/components/services/ledger-custom.service';
import { PropertyCustomService } from 'src/app/features/property/services/property-custom.service';
import { CustomSubscriptionsService } from 'src/app/features/subscriptions/services/custom-subscriptions.service';
import { BankTransactionsService } from 'src/app/generated-sources/accounting';
import { UsersService } from 'src/app/generated-sources/base/api/users.service';
import { PasswordValidators } from 'src/app/shared/validators/password-validators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../../../core/services/auth.service';
import { ToastService } from '../../../../core/services/toast.service';
import { formControl, formControlHasError } from '../../../../core/utils/common';
import { GiveEmailFlowTypesObj } from '../types/giveEmail';

@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();

    public form: UntypedFormGroup = new UntypedFormGroup({});

    public formNewPassword: UntypedFormGroup = this.formBuilder.group({}, { validators: [PasswordValidators.passwordMatch] });

    public isLoading = false;
    public giveEmailFlowTypeObjDeclared = GiveEmailFlowTypesObj;
    public userAttributes$ = new BehaviorSubject<any | undefined>(undefined);
    public cognitoUser?: CognitoUser;
    public readonly environment = environment;

    public constructor(
        private router: Router,
        private authService: AuthService,
        private translate: TranslateService,
        private formBuilder: UntypedFormBuilder,
        private usersService: UsersService,
        private toaster: ToastService,
        private bankTransactionService: BankTransactionsService,
        private customSubscriptionService: CustomSubscriptionsService,
        private propertyCustomService: PropertyCustomService,
        private ledgerCustomService: LedgerCustomService
    ) {}

    public ngOnInit(): void {
        this.form = this.createForm();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public onSignIn(): void {
        if (!this.form || this.form.invalid) {
            return;
        }
        this.isLoading = true;

        const email = this.form.value.email.trim().toLowerCase();
        const password = this.form.value.password;

        const authenticationDetails = new AuthenticationDetails({
            Username: email,
            Password: password,
        });

        const userData = { Username: email, Pool: this.authService.getUserPool() };
        this.cognitoUser = new CognitoUser(userData);

        this.cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: (result: CognitoUserSession) => {
                this.authService.setUserSession(result);
                this.authService.storeEmail(email);

                this.usersService
                    .findAll()
                    .pipe(
                        switchMap((user) => {
                            if (user) {
                                this.authService.storeScalaraUser(user);
                                return of(user);
                            }

                            const idTokenPayload = result.getIdToken().payload;

                            const userPersonData = {
                                cognitoId: result.getAccessToken().payload['sub'],
                                email: idTokenPayload['email'],
                                registrationInfo: idTokenPayload['custom:registrationInfo'],
                                type: idTokenPayload['custom:personType'],
                                companyName: idTokenPayload['custom:companyName'],
                                firstName: idTokenPayload['custom:firstName'],
                                lastName: idTokenPayload['custom:lastName'],
                                invitationToken: idTokenPayload['custom:invitationToken'], // sends undefined if user was not invited
                                address: {
                                    streetName: idTokenPayload['custom:streetName'],
                                    streetNumber: idTokenPayload['custom:streetNumber'],
                                    area: idTokenPayload['custom:city'],
                                    zipCode: idTokenPayload['custom:zipCode'],
                                    country: 'DE',
                                },
                            };

                            //  if there is invitation token,
                            //  it means that the Person already exists in DB,
                            //  so we only need to create User
                            if (userPersonData.invitationToken) {
                                return this.usersService.createInvitedUser(userPersonData);
                            }

                            return this.usersService.createUserAndPerson(userPersonData);
                        }),
                        tap(() => {
                            this.bankTransactionService.syncTransactionsForUser().subscribe();
                            this.propertyCustomService.refresh();
                            this.ledgerCustomService.refresh();
                        }),
                        takeUntil(this.unsubscribe$)
                    )
                    .subscribe(() => {
                        this.isLoading = false;
                        this.authService.setIsLoggedIn();
                        //  refresh subscriptions to update values for subscriptions guard
                        //  (here should land fixed role guard or created separate service for triggering guards)
                        this.customSubscriptionService.triggerSubscriptionsRefresh();
                        this.router.navigate(['/properties']);
                    });
            },
            newPasswordRequired: (userAttributes, requiredAttributes) => {
                this.userAttributes$.next(requiredAttributes);
                this.isLoading = false;
            },
            onFailure: (error: Error) => {
                this.toaster.showError(this.translate.instant('AUTH.SIGN_IN.ERROR.PASSWORD_INCORRECT'));
                this.isLoading = false;
                this.router.navigate(['/']);
            },
        });
    }

    public changePassword(): void {
        const newPassword = this.formNewPassword.get('password')?.value;

        // save current password with the new password because we want to call onSignIn, and there we are geting this 'form'
        this.form.patchValue({ password: newPassword });

        this.userAttributes$
            .pipe(
                tap((userAtt) => {
                    this.cognitoUser?.completeNewPasswordChallenge(newPassword, userAtt, {
                        onSuccess: () => {
                            this.onSignIn();
                        },
                        onFailure: () => {
                            this.toaster.showError(this.translate.instant('AUTH.SIGN_IN.ERROR.PASSWORD_INCORRECT'));
                            this.isLoading = false;
                            this.router.navigate(['/']);
                        },
                    });
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }

    public get emailInvalid(): boolean {
        return formControlHasError(formControl(this.form, 'email'), 'required');
    }

    public get passwordInvalid(): boolean {
        return (
            formControlHasError(formControl(this.form, 'password'), 'required') ||
            formControlHasError(formControl(this.form, 'password'), 'incorrect')
        );
    }

    private createForm(): UntypedFormGroup {
        return this.formBuilder.group({
            email: ['', [Validators.required]],
            password: ['', [Validators.required]],
        });
    }

    public get rightImgSrc(): string {
        return environment.demoEnvironment ? '/assets/images/sign_in_demo.png' : '/assets/images/sign_in_image.png';
    }
}
