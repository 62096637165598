import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { controlInFormGroupIsInvalid } from 'src/app/core/utils/formValidationHelpers';
import { InvoiceInfo, SubscriptionsService, UpdateInvoiceInformationDto } from 'src/app/generated-sources/base';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';

@Component({
    selector: 'app-change-invoice-information-overlay',
    templateUrl: './change-invoice-information-overlay.component.html',
    styleUrls: ['./change-invoice-information-overlay.component.scss'],
})
export class ChangeInvoiceInformationOverlayComponent extends OverlayChildComponent implements OnInit, OnDestroy {
    public constructor(
        public formBuilder: UntypedFormBuilder,
        public subscriptionsService: SubscriptionsService,
        public toastService: ToastService,
        public translateService: TranslateService,
        public router: Router
    ) {
        super();
    }

    public unsubscribe$ = new Subject<void>();
    public isLoading = false;

    public ngOnInit(): void {
        this.updateFormFromObservable();
    }

    // form for name, email, taxId
    public form = this.formBuilder.group({
        invoiceRecipient: [null, [Validators.required]],
        email: [null, [Validators.required, Validators.email]],
        taxId: [null, [this.taxIdValidator]],
    });

    // create custom validator for taxId allowing only input in fortmat eu_vat - DE{numbers}
    public taxIdValidator(control: AbstractControl): ValidationErrors | null {
        const taxId = control.value;
        //  keep old version in case we want to revert
        // const taxIdRegex = /^DE\d{9}$/;
        const taxIdRegex = /^DE\d+$/;
        if (taxId && !taxIdRegex.test(taxId)) {
            return { taxId: true };
        }
        return null;
    }

    public updateFormFromObservable(): void {
        this.config?.data.invoiceInformation$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((invoiceInformation: InvoiceInfo) => {
                this.form.patchValue({
                    invoiceRecipient: invoiceInformation.invoiceRecipient,
                    email: invoiceInformation.email,
                    taxId: invoiceInformation.taxId,
                });
                this.form.updateValueAndValidity();
            });
    }

    public onSubmit(): void {
        const invoiceInformationUpdate: UpdateInvoiceInformationDto = {
            invoiceRecipient: this.form.value.invoiceRecipient,
            invoiceEmail: this.form.value.email,
            taxId: this.form.value.taxId,
        };
        this.subscriptionsService.updateInvoiceInformation(invoiceInformationUpdate).subscribe({
            next: () => {
                this.toastService.showSuccess(
                    this.translateService.instant('PAGES.CHANGE_INVOICE_INFO_OVERLAY.TOASTS.SUCCESS')
                );
                this.saveEmitter$.next();
            },
            error: (error) => {
                if (error) {
                    this.toastService.showError('Es ist ein Fehler aufgetreten.');
                }
            },
        });
    }

    public onCancel(): void {
        this.cancelEmitter$.next();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public controlInFormGroupIsInvalid = controlInFormGroupIsInvalid;
}
