import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

export interface BookingRuleSuggestion {
    amountLabour?: number;
    iban?: string;
    amount?: number;
    vat?: string;
    bookingAccountDescription?: string;
    bookingAccountId?: string;
}
@Component({
    selector: 'app-booking-rule-suggestion-card',
    templateUrl: './booking-rule-suggestion-card.component.html',
    styleUrls: ['./booking-rule-suggestion-card.component.scss'],
})
export class BookingRuleSuggestionCardComponent implements OnChanges {
    @Input() public bookingRulesSuggestion?: BookingRuleSuggestion;
    @Input() public disableCheckbox = false;
    @Output() public handleBookingRuleCheckboxOnChange = new EventEmitter<{
        createBookingRule: boolean;
        bookingRuleDescription: string;
    }>();

    public bookingRuleDescription = '';
    public createBookingRuleChecked = false;

    public checkBoxChange(event?: any): void {
        this.handleBookingRuleCheckboxOnChange.emit({
            createBookingRule: event?.checked ?? this.createBookingRuleChecked,
            bookingRuleDescription: this.bookingRuleDescription,
        });
    }
    public ngOnChanges(): void {
        if (this.disableCheckbox) {
            this.createBookingRuleChecked = false;
        }
    }
}
