import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ListItem } from 'carbon-components-angular';
import { Observable, Subject, forkJoin, map, switchMap, take, takeUntil, tap } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import {
    UnitTypes,
    formControl,
    formControlHasError,
    getFileCategory,
    getLocalISOTime,
    isValueSet,
} from 'src/app/core/utils/common';
import { PropertyCustomService } from 'src/app/features/property/services/property-custom.service';
import { DateIntervalDto } from 'src/app/features/relations/interfaces';
import {
    CreateOwnershipFilesDto,
    OccupationFormDto,
    OccupationService,
    OccupationUnitSelectionPerBuildingDto,
    OwnerRelationDto,
    OwnershipsService,
    Person,
    PersonsService,
    PropertiesService,
    Property,
    RentIntervalDto,
    UnitWithOwners,
} from 'src/app/generated-sources/base';
import { DateRangePickerComponent } from 'src/app/shared/components/date-range-picker/date-range-picker.component';
import { FileUploaderComponent } from 'src/app/shared/components/file-uploader/file-uploader.component';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';
import { PersonInfosFormComponent } from 'src/app/shared/person-infos-form/person-infos-form.component';

interface UnitsListItem extends ListItem {
    blockedDates: Array<object>;
}

@Component({
    selector: 'app-add-occupation',
    templateUrl: './add-occupation.component.html',
    styleUrls: ['./add-occupation.component.scss'],
})
export class AddOccupationComponent extends OverlayChildComponent implements OnInit, AfterViewInit {
    public typeSelected: OccupationFormDto.OccupationTypeEnum = 'RENTING';
    public selectedStep = 1;
    public fileCategories: any[] = [];
    public fileUuids: string[] = [];
    public isLoading = false;
    public property$ = new Subject<Property>();
    public propertyId = '';
    private unsubscribe$ = new Subject<void>();
    public buildingList: ListItem[][] = [];
    public ownershipsList: UnitsListItem[][] = [];

    public buildingAfterUnitSelectedList: ListItem[][] = [];
    public ownershipsAfterUnitSelectedList: UnitsListItem[][] = [];

    public occupationForm: OccupationFormDto = {
        from: '',
        to: '',
        tenants: [],
        unitsIds: [],
        occupationEntryPhase: 'UNIT_SELECTION',
    };
    public durationForm: UntypedFormGroup = new UntypedFormGroup({});
    public createUserForm: UntypedFormGroup = new UntypedFormGroup({});
    public occupationUnits: OccupationUnitSelectionPerBuildingDto[] = [];
    public occupationUnitsAfterFirstUnitSelected: OccupationUnitSelectionPerBuildingDto[] = [];
    public isExistingUserSelected = false;
    public persons = [];
    public occupationId = '';
    public existingPersons: Person[] = [];
    public currentTenantId = '';
    public propertyType?: Property.PropertyTypeEnum;
    public editMode = false;

    public selectedPerson = {} as Person;

    public categories?: ListItem[] = [
        { content: 'Bonitätsnachweis', selected: false, value: 'SOLVENCY_PROOF' },
        { content: 'Personalausweis', selected: false, value: 'PERSONAL_ID' },
        { content: 'Mieterselbstauskunft', selected: false, value: 'TENANT_SELF_DISCLOSURE' },
        { content: 'Gehaltsnachweise', selected: false, value: 'SALARY_CERTIFICATE' },
        { content: getFileCategory(this.translateService, 'PARTITION_PLAN'), selected: false, value: 'PARTITION_PLAN' },
        { content: getFileCategory(this.translateService, 'ENERGY_PASS'), selected: false, value: 'ENERGY_PASS' },
        { content: getFileCategory(this.translateService, 'CADASTRAL_MAP'), selected: false, value: 'CADASTRAL_MAP' },
        { content: getFileCategory(this.translateService, 'PHOTO'), selected: false, value: 'PHOTO' },
        {
            content: getFileCategory(this.translateService, 'LAND_REGISTER_RECORD'),
            selected: false,
            value: 'LAND_REGISTER_RECORD',
        },
        { content: getFileCategory(this.translateService, 'GROUND_PLAN'), selected: false, value: 'GROUND_PLAN' },
        { content: getFileCategory(this.translateService, 'PLAN'), selected: false, value: 'PLAN' },
        {
            content: getFileCategory(this.translateService, 'DECLARATION_OF_DIVISION'),
            selected: false,
            value: 'DECLARATION_OF_DIVISION',
        },
        {
            content: getFileCategory(this.translateService, 'INSURANCE_POLICY'),
            selected: false,
            value: 'INSURANCE_POLICY',
        },
        { content: getFileCategory(this.translateService, 'CONTRACT'), selected: false, value: 'CONTRACT' },
        {
            content: getFileCategory(this.translateService, 'ADMINISTRATOR_APPOINTMENT'),
            selected: false,
            value: 'ADMINISTRATOR_APPOINTMENT',
        },
        {
            content: getFileCategory(this.translateService, 'MAINTENANCE_AGREEMENT'),
            selected: false,
            value: 'MAINTENANCE_AGREEMENT',
        },
        { content: getFileCategory(this.translateService, 'OTHER'), selected: false, value: 'OTHER' },
    ];

    @ViewChild(PersonInfosFormComponent, { static: false })
    public personInfosFormComponent?: PersonInfosFormComponent;

    @ViewChild(DateRangePickerComponent)
    public dateRangePickerChildComponent?: DateRangePickerComponent;

    @ViewChild('fileUpload')
    public fileUploader?: FileUploaderComponent;

    public areFilesFullyUploaded = false;

    public optForVatValidator: ValidatorFn = (control) => {
        const value = control.value;
        if ([true, false].includes(value)) {
            return null;
        }
        return { error: 'false value' };
    };

    public form: UntypedFormGroup = this.formBuilder.group({
        numberOfPersons: [null],
        from: [null],
        to: [null],
        minimumTermInMonth: [null, [Validators.min(0), Validators.max(1000000)]],
        automaticExtensionInMonth: [null, [Validators.min(0), Validators.max(1000000)]],
        cancellationPeriodInMonth: [null, [Validators.min(0), Validators.max(1000000)]],
        unitsIds: [null],
        rentCalculationMethod: ['BASE_RENT_OPERATIONAL_COST_HEATING'],
        rentType: ['PRIVATE'],
        optedForVAT: [false, [this.optForVatValidator]],
        dueWorkingDay: [null],
        deposit: [null],
        oneTimeSmallRepairsAmount: [null],
        annualSmallRepairsAmount: [null],
        percentageTaxIncluded: ['0.'],
        rentIntervals: this.formBuilder.array([
            this.formBuilder.group({
                startingDate: [null],
                rent: [null],
                operationalCosts: [null],
                heatingCosts: [null],
                prepaidSum: [null],
            }),
        ]),

        miscellaneousInfo: [null],
        ownerships: this.formBuilder.array([
            this.formBuilder.group({
                building: [null],
                ownership: [null, [Validators.required]],
            }),
        ]),
        depositDueDate: [null],
        depositType: [null],
        depositAmount: [null],
        tenants: [null],
    });

    public rentIntervals$ = this.form.controls['rentIntervals'].valueChanges;

    public constructor(
        private formBuilder: UntypedFormBuilder,
        private propertyCustomService: PropertyCustomService,
        private occupantsService: OccupationService,
        private toastService: ToastService,
        private propertiesService: PropertiesService,
        private personService: PersonsService,
        private translateService: TranslateService,
        private router: Router,
        private ownershipsService: OwnershipsService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.isLoading = true;

        if (this.config?.data?.occupation) {
            this.occupationForm = this.config?.data.occupation;

            this.form.patchValue({ ...this.occupationForm });

            const firstRentIntervalsControl = (this.form?.controls['rentIntervals'] as UntypedFormArray).at(
                0
            ) as UntypedFormGroup;
            firstRentIntervalsControl.patchValue({
                startingDate: [this.occupationForm.from ? new Date(this.occupationForm.from) : new Date()],
            });

            this.occupationId = this.config?.data?.occupation.id;
            this.propertyId = this.config.data.propertyId;
            this.typeSelected = this.config?.data?.occupation.occupationType;
            this.editMode = true;

            if (this.occupationForm.tenantIds) {
                const ids = this.occupationForm.tenantIds.map((item) => item);
                this.personService
                    .findAll(ids.join(','))
                    .pipe(
                        tap((persons) => {
                            this.existingPersons = persons;
                        })
                    )
                    .subscribe();
            }

            if (this.occupationForm.occupationEntryPhase === 'PERSON_SELECTION') {
                this.selectedStep = 3;
            } else if (this.occupationForm.occupationEntryPhase === 'RENT_SPECIFICATION') {
                this.selectedStep = 4;
            }
        }

        this.propertyCustomService
            .getProperty$()
            .pipe(
                tap((property) => {
                    this.propertyId = property?.id || '';
                    this.propertyType = property?.propertyType;
                    if (!property) {
                        console.warn('Property not found');
                        return;
                    }
                    this.property$.next(property);
                }),
                switchMap(() => {
                    return this.occupantsService.getFormUnitSelectionList(this.propertyId);
                }),
                tap((occupationUnits) => {
                    this.occupationUnits = occupationUnits;
                    if (this.occupationUnits.length === 1 && !this.editMode) {
                        this.buildingList[0] = [
                            {
                                content: this.occupationUnits[0].buildingName,
                                selected: true,
                                value: this.occupationUnits[0].buildingName,
                            },
                        ];
                    } else {
                        this.buildingList[0] = this.occupationUnits.map((item) => {
                            const findBuilding = item.unitsInBuilding.find((unit) => {
                                const unitId =
                                    this.editMode && this.occupationForm.unitsIds?.length
                                        ? this.occupationForm.unitsIds[0]
                                        : undefined;
                                return unit.unitId === unitId;
                            });

                            return {
                                content: item.buildingName,
                                selected: !!findBuilding,
                                value: item.buildingName,
                            };
                        });
                    }

                    const selectedBuilding =
                        this.buildingList[0].find((item) => item.selected) ?? this.buildingList[0][0];
                    const list =
                        this.occupationUnits
                            ?.find((item) => item.buildingName === selectedBuilding?.content)
                            ?.unitsInBuilding.map((item) => {
                                let selected = false;
                                const unitName = this.getUnitCompleteName(item);
                                if (
                                    this.editMode &&
                                    this.occupationForm.unitsIds &&
                                    this.occupationForm.unitsIds.length > 0 &&
                                    this.occupationForm.unitsIds[0] === item.unitId
                                ) {
                                    this.getOwnershipItemForm(0).patchValue({
                                        ownership: {
                                            content: unitName,
                                            selected: selected,
                                            value: item.unitId,
                                            blockedDates: item.blockedDates,
                                        },
                                    });
                                    selected = true;
                                }
                                return {
                                    content: unitName,
                                    selected: selected,
                                    value: item.unitId,
                                    blockedDates: item.blockedDates,
                                };
                            }) || [];

                    this.ownershipsList[0] = list.sort(this.sortUnits);

                    if (this.occupationForm && this.occupationForm.unitsIds && this.editMode) {
                        this.findSelectionsUnit(
                            this.editMode && this.occupationForm.unitsIds && this.occupationForm.unitsIds.length > 0
                                ? this.occupationForm.unitsIds[0]
                                : ''
                        );
                    }
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => (this.isLoading = false));
    }

    private sortUnits(a: UnitsListItem, b: UnitsListItem): 1 | 0 | -1 {
        const unit1 = a.content;
        const unit2 = b.content;
        if (unit1 > unit2) {
            return 1;
        }
        if (unit1 < unit2) {
            return -1;
        }
        return 0;
    }

    private unitTypeNameFromEnum(unitType: string): string {
        return UnitTypes.find((type) => type.type === unitType)?.name || ' ';
    }

    private getUnitCompleteName(item: UnitWithOwners): string {
        return (
            (this.propertyType !== 'MV' ? item.ownershipName + ', ' : '') +
            this.unitTypeNameFromEnum(item.type) +
            ', ' +
            item.unitName +
            ', ' +
            item.location
        );
    }

    public ngAfterViewInit(): void {
        if (this.personInfosFormComponent?.personForm) {
            this.createUserForm.addControl('personForm', this.personInfosFormComponent.personForm);
            this.personInfosFormComponent.personForm.setParent(this.createUserForm);

            this.populatePersonAddressFromPropertyObs();
        }
        if (this.dateRangePickerChildComponent?.dateRangeForm) {
            this.durationForm.addControl('dateRangeForm', this.dateRangePickerChildComponent.dateRangeForm);
            this.dateRangePickerChildComponent.dateRangeForm.setParent(this.durationForm);
            if (this.occupationForm.from) {
                const endDate = this.occupationForm.to ? new Date(this.occupationForm.to) : undefined;
                this.durationForm.get('dateRangeForm')?.patchValue({
                    startDate: new Date(this.occupationForm.from),
                    endDate: endDate,
                });
            }
        }
    }

    public populatePersonAddressFromPropertyObs(): void {
        this.propertyCustomService
            .getProperty$()
            .pipe(take(1))
            .subscribe((prop) => {
                const patchAddress = prop?.address ? { ...prop.address } : {};

                const personForm = this.createUserForm.controls['personForm'] as UntypedFormGroup;
                personForm.patchValue({ address: patchAddress });
            });
    }

    public findOwnderships(index: number, unitId?: string, buildingName?: string): void {
        if (index === 0) {
            const building = this.buildingList[index].find(
                (item) =>
                    item['value'] === this.getOwnershipItemForm(index)?.value?.building?.value ||
                    item['value'] === buildingName
            );
            const list =
                this.occupationUnits
                    ?.find((item) => item.buildingName === building?.content)
                    ?.unitsInBuilding.map((item) => {
                        return {
                            content: this.getUnitCompleteName(item),
                            selected: unitId && unitId === item.unitId ? true : false,
                            value: item.unitId,
                            blockedDates: item.blockedDates,
                        };
                    }) || [];
            this.ownershipsList[index] = list.sort(this.sortUnits);
        } else {
            const building = this.buildingAfterUnitSelectedList[index].find(
                (item) =>
                    item['value'] === this.getOwnershipItemForm(index)?.value?.building?.value ||
                    item['value'] === buildingName
            );
            const list =
                this.occupationUnitsAfterFirstUnitSelected
                    ?.find((item) => item.buildingName === building?.content)
                    ?.unitsInBuilding.map((item) => {
                        return {
                            content: this.getUnitCompleteName(item),
                            selected: unitId && unitId === item.unitId ? true : false,
                            value: item.unitId,
                            blockedDates: item.blockedDates,
                        };
                    }) || [];
            this.ownershipsAfterUnitSelectedList[index] = list.sort(this.sortUnits);
        }
    }

    public clearPropertyInput(index: number): void {
        this.ownershipsList[index] = [];

        (this.form.get('ownerships') as UntypedFormArray).patchValue([
            this.formBuilder.group({
                ownership: ['', Validators.required],
            }),
        ]);
    }

    public switchOccupationType(occupationType: OccupationFormDto.OccupationTypeEnum): void {
        this.form.patchValue({ occupationType: occupationType });
        this.typeSelected = occupationType;
        if (this.typeSelected === 'VACANCY') {
            this.form.controls['numberOfPersons'].setValidators([Validators.required, Validators.min(1)]);
        } else {
            this.form.controls['numberOfPersons'].clearValidators();
        }

        this.form.controls['numberOfPersons'].setErrors(null);
        this.form.controls['numberOfPersons'].updateValueAndValidity();
    }

    public get ownershipsControl(): UntypedFormArray {
        return this.form.controls['ownerships'] as UntypedFormArray;
    }

    public removeOwnership(index: number): void {
        this.buildingList.splice(index, 1);
        this.ownershipsList.splice(index, 1);
        this.ownershipsControl.removeAt(index);
    }

    public disableButtonSubmit(): boolean {
        if (!this.isExistingUserSelected && !this.createUserForm.valid && this.selectedStep === 2) {
            return true;
        } else if (this.isExistingUserSelected && !this.selectedPerson.id) {
            return true;
        }
        return !this.form.valid;
    }

    public isInvalid(controlName: string): boolean {
        return formControlHasError(formControl(this.form, controlName), 'required');
    }

    public isInvalidNumber(controlName: string): boolean {
        return (
            formControlHasError(formControl(this.form, controlName), 'min') ||
            formControlHasError(formControl(this.form, controlName), 'max')
        );
    }

    public isInvalidArray(index: number, controlName: string): boolean {
        return formControlHasError(formControl(this.getOwnershipItemForm(index), controlName), 'required');
    }

    public addNewOwnership(buildingName?: string, unitId?: string, index?: number): void {
        if (this.buildingAfterUnitSelectedList.length > 0) {
            this.buildingAfterUnitSelectedList.push(
                this.buildingAfterUnitSelectedList[1].map((item) => {
                    if (buildingName === item.content) {
                        return { ...item, selected: true };
                    } else {
                        return item;
                    }
                })
            );
        }

        if (unitId && index) {
            this.findOwnderships(index, unitId, buildingName);
        }

        this.ownershipsControl.push(
            this.formBuilder.group({
                building: [buildingName ? { content: buildingName, value: buildingName, selected: true } : ''],
                ownership: [
                    unitId && index
                        ? this.ownershipsAfterUnitSelectedList[index].find((item) => item['value'] === unitId)
                        : '',
                    [Validators.required],
                ],
            })
        );
    }

    public getOwnershipItemForm(index: number): UntypedFormGroup {
        return this.ownershipsControl.at(index) as UntypedFormGroup;
    }

    public updateLoadingFilesStatus($event: boolean): void {
        this.areFilesFullyUploaded = $event;
    }

    public updateFileIdsLodaded($event: string[]): void {
        this.fileUuids = $event;
    }

    public updatedFileCategories($event: any): void {
        this.fileCategories = $event;
    }

    public onSubmitAndClose(): void {
        this.handleDataToSubmit(true);
    }

    public onSubmitAndContinue(): void {
        this.handleDataToSubmit();
    }

    public handleDataToSubmit(submitAndClose?: boolean): void {
        let selectedType: OccupationFormDto.OccupationEntryPhaseEnum;

        if (this.selectedStep === 1) {
            selectedType = 'UNIT_SELECTION';
        } else if (this.selectedStep === 2 || this.selectedStep === 3) {
            selectedType = 'PERSON_SELECTION';
        } else if (this.selectedStep === 4) {
            selectedType = 'RENT_SPECIFICATION';
        } else {
            selectedType = 'COMPLETED';
        }
        this.occupationForm.occupationEntryPhase = selectedType;

        if (this.selectedStep === 1) {
            const conflictingOccupation = this.isOccupationExistingInSamePeriod();
            if (conflictingOccupation) {
                this.toastService.showError(
                    'Es gibt bereits eine Nutzung für ' + conflictingOccupation + ' in diesem Zeitraum.'
                );
                return;
            }

            const unitsIds = this.ownershipsControl.value.map((item: any) => item.ownership?.value);

            this.occupationForm.from = '';
            this.occupationForm.to = '';

            this.occupationForm = {
                ...this.occupationForm,
                ...this.createRelationWithTimeConstraintObject(),
                unitsIds: unitsIds,
                occupationEntryPhase: selectedType,
                occupationType: this.typeSelected,
            };

            if (this.typeSelected === 'VACANCY') {
                this.occupationForm.miscellaneousInfo = this.form.value.miscellaneousInfo;
                this.occupationForm.numberOfPersons = Number(this.form.value.numberOfPersons);
            }

            if (this.typeSelected === 'RENTING') {
                this.occupationForm.minimumTermInMonth = Number(this.form.value.minimumTermInMonth);
                this.occupationForm.automaticExtensionInMonth = Number(this.form.value.automaticExtensionInMonth);
                this.occupationForm.cancellationPeriodInMonth = Number(this.form.value.cancellationPeriodInMonth);
                this.occupationForm.rentType = this.form.value.rentType;
                this.occupationForm.optedForVAT = this.form.value.optedForVAT;
            }

            const startDate =
                this.durationForm.get('dateRangeForm')?.value['startDate'][0] ||
                this.durationForm.get('dateRangeForm')?.value['startDate'];

            const firstRentIntervalsControl = (this.form?.controls['rentIntervals'] as UntypedFormArray).at(
                0
            ) as UntypedFormGroup;
            firstRentIntervalsControl.patchValue({ startingDate: [new Date(startDate)] });
        } else if (this.selectedStep === 2) {
            if (this.isExistingUserSelected) {
                if (this.occupationForm?.tenantIds?.find((item) => item === this.selectedPerson.id)) {
                    this.selectedStep = this.selectedStep + 1;
                    if (this.selectedStep === 3) {
                        this.form.controls['numberOfPersons'].setValidators([Validators.required, Validators.min(1)]);
                        this.form.controls['numberOfPersons'].setErrors(null);
                        this.form.controls['numberOfPersons'].updateValueAndValidity();
                    }
                    return;
                }

                if (this.occupationForm?.tenantIds) {
                    this.occupationForm?.tenantIds?.push(this.selectedPerson.id);
                } else {
                    this.occupationForm.tenantIds = [this.selectedPerson.id];
                }
                this.existingPersons.push(this.selectedPerson);
            } else {
                if (this.occupationForm?.tenants) {
                    if (this.currentTenantId && this.occupationForm.tenants) {
                        this.occupationForm.tenants = this.occupationForm?.tenants.map((item) => {
                            if (item.id === this.currentTenantId) {
                                return { ...this.createPersonDto(), id: this.currentTenantId };
                            } else {
                                return item;
                            }
                        });
                    } else {
                        this.occupationForm?.tenants?.push(this.createPersonDto());
                    }
                } else {
                    this.occupationForm.tenants = [this.createPersonDto()];
                }
            }
        } else if (this.selectedStep === 3) {
            this.occupationForm.numberOfPersons = this.form.value.numberOfPersons
                ? Number(this.form.value.numberOfPersons)
                : 0;
        } else {
            const {
                rentIntervals,
                depositType,
                dueWorkingDay,
                depositAmount,
                depositDueDate,
                oneTimeSmallRepairsAmount,
                annualSmallRepairsAmount,
                miscellaneousInfo,
                rentCalculationMethod,
            } = this.form.value;

            const rentIntervalFormated: RentIntervalDto[] = rentIntervals.map((item: any) => {
                return {
                    startingDate: item.startingDate && item.startingDate[0] ? item.startingDate[0] : undefined,
                    rent: isValueSet(item.rent) ? Number(item.rent) : 0,
                    operationalCosts: isValueSet(item.operationalCosts) ? Number(item.operationalCosts) : undefined,
                    heatingCosts: isValueSet(item.heatingCosts) ? Number(item.heatingCosts) : undefined,
                    prepaidSum: isValueSet(item.prepaidSum) ? Number(item.prepaidSum) : undefined,
                };
            });

            this.occupationForm = {
                ...this.occupationForm,
                rentIntervals: rentIntervalFormated,
                depositType: depositType?.value || undefined, // ListItem type
                dueWorkingDay: dueWorkingDay?.value || undefined, // ListItem type
                depositAmount: depositAmount ? Number(depositAmount) : undefined,
                depositDueDate:
                    depositDueDate && depositDueDate.length > 0 && depositDueDate[0] ? depositDueDate[0] : undefined,
                annualSmallRepairsAmount: annualSmallRepairsAmount ? Number(annualSmallRepairsAmount) : undefined,
                oneTimeSmallRepairsAmount: oneTimeSmallRepairsAmount ? Number(oneTimeSmallRepairsAmount) : undefined,
                miscellaneousInfo,
                percentageTaxIncluded: this.form.value?.percentageTaxIncluded,
                rentCalculationMethod,
                optedForVAT: this.form.value.optedForVAT,
            };
        }

        this.submit(submitAndClose);
    }

    private submit(submitAndClose?: boolean): void {
        this.isLoading = true;
        if (this.selectedStep === 1 || this.typeSelected === 'VACANCY') {
            this.occupationForm.unitsNames = this.occupationForm.unitsIds?.map((item) => {
                const unitsInBuilding: any = this.occupationUnits?.map((data) => {
                    return data.unitsInBuilding;
                });

                const unit = unitsInBuilding
                    .flat()
                    .concat()
                    .find((unitsInBuildingItem: any) => {
                        return unitsInBuildingItem.unitId === item;
                    });

                return this.getUnitCompleteName(unit);
            });
        }
        if (
            this.occupationForm.tenants?.length === 0 ||
            (this.occupationForm.tenants &&
                this.occupationForm.tenants?.length > 0 &&
                this.occupationForm.tenants[0] === null)
        ) {
            delete this.occupationForm.tenants;
        }

        this.occupantsService
            .postOccupationForm(
                this.propertyId,
                this.selectedStep === 4 || this.typeSelected === 'VACANCY' ? 'COMMIT' : 'DRAFT',
                this.occupationForm,
                this.occupationId
            )
            .pipe(
                switchMap((data: any) => {
                    const status = this.selectedStep === 4 || this.typeSelected === 'VACANCY' ? 'COMMIT' : 'DRAFT';
                    const createFilesDto: CreateOwnershipFilesDto = {
                        createFileDtos: this.fileUuids.map((uuid, index) => ({
                            fileStorageId: uuid,
                            fileCategory: this.fileCategories[index],
                        })),
                    };
                    return (
                        status === 'COMMIT'
                            ? this.occupantsService.createOccupationFiles(this.propertyId, data.id, createFilesDto)
                            : this.occupantsService.createOccupationFormFiles(this.propertyId, data.id, createFilesDto)
                    ).pipe(map(() => data));
                })
            )
            .subscribe({
                next: (data: any) => {
                    this.fileUuids = [];
                    this.fileCategories = [];
                    this.occupationId = data.id;
                    this.occupationForm.tenants = data.tenants;
                    if (submitAndClose || this.selectedStep === 4) {
                        this.isLoading = false;
                        this.unsubscribe$.next();
                        this.router.navigate([`/properties/${this.propertyId}/occupations`]);
                        this.toastService.showSuccess('Nutzung erfolgreich angelegt');
                        this.saveEmitter$.next('commit');
                    } else {
                        // next step
                        if (this.typeSelected === 'OWN_OCCUPATION' && this.selectedStep === 1) {
                            this.selectedStep = 3;
                        } else {
                            this.selectedStep = this.selectedStep + 1;
                        }
                        if (this.selectedStep === 3) {
                            this.form.controls['numberOfPersons'].setValidators([
                                Validators.required,
                                Validators.min(1),
                            ]);
                        } else {
                            this.form.controls['numberOfPersons'].clearValidators();
                        }

                        this.form.controls['numberOfPersons'].setErrors(null);
                        this.form.controls['numberOfPersons'].updateValueAndValidity();

                        if (this.selectedStep === 4) {
                            if (this.typeSelected === 'RENTING') {
                                this.form.controls['depositType'].setValidators(Validators.required);
                                this.form.controls['depositType'].setErrors(null);
                                this.form.controls['depositType'].updateValueAndValidity();
                            }

                            if (this.typeSelected !== 'OWN_OCCUPATION') {
                                this.form.controls['rentIntervals'].setValidators(Validators.required);
                                this.form.controls['rentIntervals'].setErrors(null);
                                this.form.controls['rentIntervals'].updateValueAndValidity();

                                this.form.controls['dueWorkingDay'].setValidators(Validators.required);
                                this.form.controls['dueWorkingDay'].setErrors(null);
                                this.form.controls['dueWorkingDay'].updateValueAndValidity();
                            } else {
                                this.rentIntervals$
                                    .pipe(takeUntil(this.unsubscribe$))
                                    .subscribe((valueChanges: any) => {
                                        if (valueChanges[0].rent > 0) {
                                            this.form.controls['dueWorkingDay'].setValidators(Validators.required);
                                            this.form.controls['dueWorkingDay'].setErrors(null);
                                            this.form.controls['dueWorkingDay'].updateValueAndValidity();
                                        } else {
                                            this.form.controls['dueWorkingDay'].clearValidators();
                                            this.form.controls['dueWorkingDay'].setErrors(null);
                                            this.form.controls['dueWorkingDay'].updateValueAndValidity();
                                        }
                                    });
                            }
                        }
                        this.isLoading = false;
                    }
                    if (
                        (this.selectedStep === 3 && this.occupationForm.tenantIds?.length === 0) ||
                        !this.occupationForm.tenantIds
                    ) {
                        this.findRelations();
                    }
                },
                error: (error) => {
                    if (error) {
                        this.toastService.showError(error.error['message']);
                        this.isLoading = false;
                    }
                },
            });
    }

    /**
     * Checks if there is an existing occupation with the same unit and period
     * If exists, returns the name of the conflicting occupation.
     * If not, returns an empty string
     */
    public isOccupationExistingInSamePeriod(): string {
        const dateFromSelectedNow = new Date(this.createRelationWithTimeConstraintObject().from).getTime();

        const dateToSelectedNow = this.createRelationWithTimeConstraintObject().to
            ? new Date(this.createRelationWithTimeConstraintObject().to!).getTime()
            : null;

        let unitBlocked = '';

        this.ownershipsControl.value.some((item: any) => {
            const findblockedDates = item.ownership?.blockedDates.find((dates: any) => {
                const dateTo = dates.to ? new Date(dates.to).getTime() : null;
                const dateFrom = dates.from ? new Date(dates.from).getTime() : null;

                if (!dateFrom) {
                    return false;
                }

                if (dateTo) {
                    if (
                        (dateFromSelectedNow >= dateFrom && dateFromSelectedNow <= dateTo) ||
                        (dateFromSelectedNow <= dateFrom && !dateToSelectedNow)
                    ) {
                        unitBlocked = item.ownership.content;
                        return true;
                    }
                } else {
                    if (dateFromSelectedNow >= dateFrom || !dateToSelectedNow || dateToSelectedNow >= dateFrom) {
                        unitBlocked = item.ownership.content;
                        return true;
                    }
                }

                return false;
            });
        });
        return unitBlocked;
    }

    public findRelations(): void {
        if (this.typeSelected === 'OWN_OCCUPATION') {
            if (this.propertyType === 'MV') {
                this.propertiesService
                    .findOwnerRelations(this.propertyId)
                    .pipe(
                        tap((relations) => {
                            const uniqueIdsSet = new Set();

                            const uniqueRelations = relations.filter((item) => {
                                if (!uniqueIdsSet.has(item.person.id)) {
                                    uniqueIdsSet.add(item.person.id);
                                    return true;
                                }
                                return false;
                            });

                            this.occupationForm.tenantIds = uniqueRelations.map((item) => item.person.id);
                            this.existingPersons = uniqueRelations.map((item) => item.person);
                        }),
                        takeUntil(this.unsubscribe$)
                    )
                    .subscribe();
            } else {
                this.occupantsService
                    .findSelectedUnitsInfo(this.propertyId, this.occupationId || '')
                    .pipe(
                        switchMap((unitInfo) => {
                            const observables: Observable<OwnerRelationDto[]>[] = [];

                            unitInfo.map((item) => {
                                observables.push(
                                    this.ownershipsService.findOwnerRelations(this.propertyId, item.ownershipId || '')
                                );
                            });
                            return forkJoin(observables);
                        }),
                        tap((relations) => {
                            const persons: Person[] = [];
                            relations.map((data) => {
                                data.map((item) => {
                                    persons.push(item.person);
                                });
                            });

                            const uniqueIdsSet = new Set();

                            const uniquePersons = persons.filter((item) => {
                                if (!uniqueIdsSet.has(item.id)) {
                                    uniqueIdsSet.add(item.id);
                                    return true;
                                }
                                return false;
                            });

                            this.occupationForm.tenantIds = uniquePersons.map((item) => item.id);
                            this.existingPersons = uniquePersons;
                        }),
                        takeUntil(this.unsubscribe$)
                    )
                    .subscribe();
            }
        }
    }

    public switchExistingUserSelection(isExistingSelected: boolean): void {
        if (isExistingSelected) {
            this.isExistingUserSelected = true;
        } else {
            this.isExistingUserSelected = false;
        }
    }

    public onSelectPerson($event: Person): void {
        this.selectedPerson = $event || {};
    }

    private createPersonDto(): Person {
        const userForm = this.createUserForm.get('personForm')?.value;

        userForm.phoneNumbers =
            userForm.phoneNumbers && userForm.phoneNumbers.length > 0 && userForm.phoneNumbers[0]?.phoneNumber
                ? userForm.phoneNumbers.map((item: any) => {
                      return {
                          phoneNumber: item.phoneNumber,
                          type: item.phoneNumberType?.value ?? item.phoneNumberType,
                      };
                  })
                : [];
        return userForm;
    }

    public abort(): void {
        if (this.selectedStep === 1) {
            this.cancelEmitter$.next();
        } else {
            this.form.controls['numberOfPersons'].clearValidators();
            this.form.controls['numberOfPersons'].setErrors(null);
            this.form.controls['numberOfPersons'].updateValueAndValidity();

            this.form.controls['rentIntervals'].clearValidators();
            this.form.controls['rentIntervals'].setErrors(null);
            this.form.controls['rentIntervals'].updateValueAndValidity();

            this.form.controls['dueWorkingDay'].clearValidators();
            this.form.controls['dueWorkingDay'].setErrors(null);
            this.form.controls['dueWorkingDay'].updateValueAndValidity();

            this.form.controls['depositType'].clearValidators();
            this.form.controls['depositType'].setErrors(null);
            this.form.controls['depositType'].updateValueAndValidity();

            if (!this.isExistingUserSelected) {
                this.currentTenantId =
                    this.occupationForm?.tenants && this.occupationForm?.tenants[0]?.id
                        ? this.occupationForm?.tenants[0]?.id
                        : '';
            }

            if (this.selectedStep === 3) {
                this.selectedStep = 1;
            } else {
                this.selectedStep = this.selectedStep - 1;
            }
        }
    }

    private createRelationWithTimeConstraintObject(): DateIntervalDto {
        const startDate =
            this.durationForm.get('dateRangeForm')?.value['startDate'][0] ||
            this.durationForm.get('dateRangeForm')?.value['startDate'];

        const endDate = Array.isArray(this.durationForm.get('dateRangeForm')?.value['endDate'])
            ? this.durationForm.get('dateRangeForm')?.value['endDate'][0]
            : this.durationForm.get('dateRangeForm')?.value['endDate'];

        const datesObj: DateIntervalDto = {
            from: getLocalISOTime(startDate),
        };
        if (endDate) {
            datesObj.to = getLocalISOTime(endDate);
        }
        return datesObj;
    }

    public removeTenant(event: any): void {
        if (event.existingPersons) {
            this.occupationForm?.tenantIds?.splice(
                this.occupationForm?.tenantIds?.findIndex((item) => item === event.id),
                1
            );

            this.existingPersons = this.existingPersons.filter((item) => item.id !== event.id);
        } else {
            this.occupationForm?.tenants?.splice(
                this.occupationForm?.tenants?.findIndex((item) => item.id === event.id),
                1
            );
        }
    }

    public addNewTenant(): void {
        this.form.controls['numberOfPersons'].clearValidators();
        this.form.controls['numberOfPersons'].setErrors(null);
        this.form.controls['numberOfPersons'].updateValueAndValidity();

        this.selectedStep = 2;
        this.currentTenantId = '';
        this.createUserForm.reset({ personForm: { type: 'NAT_PERSON' } });
        this.populatePersonAddressFromPropertyObs();
    }

    public openEditPersonForm(event: any): void {
        this.form.controls['numberOfPersons'].clearValidators();
        this.form.controls['numberOfPersons'].setErrors(null);
        this.form.controls['numberOfPersons'].updateValueAndValidity();
        this.currentTenantId = event.person.id;
        this.isExistingUserSelected = false;
        this.createUserForm.patchValue({ personForm: event.person });
        this.selectedStep = 2;
    }

    public findSelectionsUnit(unitId: string): void {
        this.occupantsService
            .getFormUnitSelectionList(this.propertyId, unitId)
            .pipe(
                tap((units) => {
                    this.occupationUnitsAfterFirstUnitSelected = units;

                    if (this.occupationUnitsAfterFirstUnitSelected.length === 1) {
                        this.buildingAfterUnitSelectedList[1] = [
                            {
                                content: this.occupationUnitsAfterFirstUnitSelected[0].buildingName,
                                selected: true,
                                value: this.occupationUnitsAfterFirstUnitSelected[0].buildingName,
                            },
                        ];
                    } else {
                        this.buildingAfterUnitSelectedList[1] = this.occupationUnitsAfterFirstUnitSelected.map(
                            (item) => {
                                return {
                                    content: item.buildingName,
                                    selected: false,
                                    value: item.buildingName,
                                };
                            }
                        );
                    }
                    const list =
                        this.occupationUnitsAfterFirstUnitSelected
                            ?.find((item) => item.buildingName === this.buildingAfterUnitSelectedList[1][0]?.content)
                            ?.unitsInBuilding.map((item) => {
                                return {
                                    content: this.getUnitCompleteName(item),
                                    selected: false,
                                    value: item.unitId,
                                    blockedDates: item.blockedDates,
                                };
                            }) || [];
                    this.ownershipsAfterUnitSelectedList[1] = list.sort(this.sortUnits);
                })
            )
            .subscribe(() => {
                if (this.occupationForm && this.occupationForm.unitsIds && this.editMode) {
                    this.occupationForm.unitsIds.slice(1).map((unitId, index) => {
                        const findBuilding = this.occupationUnits.find((unitItem) => {
                            const findUnit = unitItem.unitsInBuilding.find((item) => {
                                return item.unitId === unitId;
                            });
                            return !!findUnit;
                        });
                        this.addNewOwnership(findBuilding?.buildingName, unitId, index + 1);
                    });
                }
                if (!this.getOwnershipItemForm(0).value?.ownership && this.selectedStep !== 1) {
                    this.selectedStep = 1;
                }
            });
    }

    public findSelectionUnit(): void {
        const unitId = this.getOwnershipItemForm(0).value?.ownership?.value;
        this.findSelectionsUnit(unitId);
    }

    public onSelectOwnership(selectedOwnership: any, index: number): void {
        if (!selectedOwnership.item?.value) {
            return;
        }
        const isDuplicate = this.ownershipsControl.value.find((item: any, i: number) => {
            if (i === index) {
                return false;
            }
            return item.ownership.value === selectedOwnership.item?.value;
        });

        const ownershipErrorControl = this.getOwnershipItemForm(index).get('ownership');
        if (isDuplicate) {
            if (ownershipErrorControl) {
                ownershipErrorControl?.setErrors({ duplicate: true });
            }
        } else {
            ownershipErrorControl?.clearValidators();
        }
    }

    public isInvalidUnit(index: number): boolean {
        return this.getOwnershipItemForm(index).get('ownership')?.errors?.['duplicate'];
    }
}
