import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-status-with-icon',
    templateUrl: './status-with-icon.component.html',
    styleUrls: ['./status-with-icon.component.scss'],
})
export class StatusWithIconComponent {
    @Input() public queryParams = {};
    @Input() public rightAligned = false;
    @Input() public textColor: 'gray' | 'green' | 'orange' | 'red' | 'purple' | 'blue' | string = 'green';
    @Input() public iconSrc = '';
    @Input() public showNewTag = false;
    @Input() public link?: string;
    @Input() public label = '';

    @Output() public handleButtonClick = new EventEmitter<void>();

    public onButtonClick(): void {
        this.handleButtonClick.emit();
    }
}
