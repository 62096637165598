import { DepositInfo } from 'src/app/generated-sources/base';

export const depositTypesToLabelMap: { [key in DepositInfo.DepositTypeEnum]: string } = {
    NONE: 'Keine Kaution',
    BANK_TRANSFER: 'Banküberweisung',
    CASH_PAYMENT: 'Barzahlung',
    PLEDGED_SAVING_ACCOUNT: 'Verpfändetes Sparkonto',
    BANK_GUARANTEE: 'Bankbürgschaft',
    DEPOSIT_INSURANCE: 'Kautionsversicherung',
    PARENTAL_GUARANTEE: 'Elternbürgschaft',
};
