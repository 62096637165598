<div
    [routerLink]="link"
    [queryParams]="queryParams"
    [ngClass]="{ 'styled-item-icon--justify-end': rightAligned, 'tw-pointer-events-none': !link }"
    [class]="'styled-item-icon ' + textColor"
    class="ml-04 mr-05"
>
    <svg-icon [class]="'fill-' + textColor" [src]="iconSrc" (click)="onButtonClick()"></svg-icon>
    <div>{{ label }}</div>
    <app-tag *ngIf="showNewTag" color="red"> Neu </app-tag>
</div>
