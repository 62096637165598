<div [ngClass]="{ 'scalara-accordion-wrapper': useDefaultStyles }">
    <div
        [ngClass]="{ 'tw-px-5': useDefaultStyles, 'tw-pt-4': useDefaultStyles, 'tw-pb-2': useDefaultStyles }"
        class="  scalara-accordion-header d-flex justify-space-between {{ this.headerClass }}"
        (click)="onHeaderClick()"
    >
        <ng-content select="[scalara-accordion-header]"></ng-content>
        <p *ngIf="this.headingLabel" class="s-head-14-22-bold {{ this.headerLabelClass }}">{{ this.headingLabel }}</p>
        <div class="scalara-accordion-icon-wrapper" [ngClass]="{ 'rotate-180': isOpen$.value && !iconToClose }">
            <svg-icon [src]="isOpen$.value && iconToClose ? iconToClose : iconToOpen"></svg-icon>
        </div>
    </div>
    <div
        *ngIf="isOpen$ | async as isOpen"
        class="scalara-accordion-content"
        [ngClass]="{ 'scalara-accordion-content--opened': isOpen }"
    >
        <ng-content select="[scalara-accordion-content]"></ng-content>
    </div>
</div>
