import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IbanFormatterPipe } from 'angular-iban';
import { Subject, finalize, switchMap, takeUntil, tap } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { bankAccountTypeName } from 'src/app/core/utils/common';
import { BankConnectionsService, LedgersService } from 'src/app/generated-sources/accounting';
import {
    BankAccountDto,
    BankConnectionDto,
    LedgerDto,
    RedirectResponseDto,
} from 'src/app/generated-sources/accounting/model/models';
import { Property } from 'src/app/generated-sources/base';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { environment } from 'src/environments/environment';
import { CellTemplate } from '../../../../shared/table/enums/cell-template';
import { TableItem } from '../../../../shared/table/interfaces/table-item';
import { TableModel } from '../../../../shared/table/interfaces/table-model';
import { AddBankAccountStatus } from '../../enums/add-bank-account-status';
import { SyncAccountsFormComponent } from '../sync-accounts-form/sync-accounts-form.component';

interface CustomDataTableWithBankConnection extends TableModel {
    isExpired: boolean;
    bankConnection: BankConnectionDto;
}
@Component({
    selector: 'app-accounts-view',
    templateUrl: './accounts-view.component.html',
    styleUrls: ['./accounts-view.component.scss'],
})
export class AccountsViewComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();
    private addBankAccountSuccessMessage?: string;
    private addBankAccountErrorMessage?: string;
    private ownUrlPath = `${window.location.origin}${window.location.pathname}`;
    private notAssignedText?: string;

    public isLoading = false;
    public showSynchronized = true;
    public ledgers: LedgerDto[] = [];
    private ibanFormatterPipe = new IbanFormatterPipe();

    @ViewChild('iconWithTooltip', { static: true })
    public iconWithTooltip?: TemplateRef<any>;

    public tableModel: CustomDataTableWithBankConnection[] = [
        {
            data: [],
            header: [],
            isExpired: false,
            bankConnection: {
                id: '',
                bankName: '',
                bic: '',
                consentExpiresAt: '',
                userActionRequired: false,
                bankAccounts: [],
            },
        },
    ];

    public synchronizedAccountsTableModel: TableModel = { data: [], header: [] };
    public unsynchronizedAccountsTableModel: TableModel = { data: [], header: [] };
    public bankAccounts?: BankConnectionDto[];
    public unsychronizedAccounts: BankConnectionDto[] = [];
    public isCollapsed = [false];
    public today = new Date();

    public readonly environment = environment;

    public constructor(
        @Inject(DOCUMENT) private document: Document,
        private bankConnectionsService: BankConnectionsService,
        private translateService: TranslateService,
        private route: ActivatedRoute,
        private toastService: ToastService,
        private overlayService: OverlayService,
        private router: Router,
        private ledgersService: LedgersService
    ) {}

    public createBankAccountsTables(bankConnections: BankConnectionDto[]): void {
        this.today.setHours(0, 0, 0, 0);

        bankConnections.map((bankConnection: BankConnectionDto, index: number) => {
            let isExpired = false;
            this.tableModel[index] = {
                data:
                    (bankConnection?.bankAccounts &&
                        bankConnection.bankAccounts.map((account: BankAccountDto) => {
                            if (!isExpired && account.dataOutdated) {
                                isExpired = true;
                            }
                            return this.createRow(account);
                        })) ||
                    [],
                header: [
                    'ENTITIES.BANK_ACCOUNT.INSTITUTE',
                    'ENTITIES.BANK_ACCOUNT.ACCOUNT_TYPE',
                    'ENTITIES.BANK_ACCOUNT.IBAN',
                    'ENTITIES.BANK_ACCOUNT.ACCOUNT_OWNER',
                    'PAGES.USER_MANAGEMENT.ACCOUNTS_VIEW.DATA_STATUS',
                    '',
                    '',
                    'PAGES.USER_MANAGEMENT.ACCOUNTS_VIEW.ASSIGNED_TO',
                    '',
                ],
                bankConnection: bankConnection,
                isExpired: isExpired,
            };
        });
    }

    public findAllBankAccounts(): void {
        this.ledgersService
            .findAll()
            .pipe(
                switchMap((ledgers) => {
                    this.ledgers = ledgers;
                    return this.bankConnectionsService.findAllBankConnections(true);
                }),
                tap((accounts) => {
                    this.createBankAccountsTables(accounts);
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: () => {
                    this.isLoading = false;
                },
                error: (error) => {
                    this.toastService.showError(error.error['message']);
                    this.isLoading = false;
                },
            });
    }

    public ngOnInit(): void {
        this.isLoading = true;
        this.initMessageTranslations();

        this.route.queryParamMap
            .pipe(
                tap((params: ParamMap) => {
                    const webformIdParam = params.get('webformId');
                    const webformUpdateParam = params.get('update');
                    if (webformIdParam) {
                        this.bankConnectionsService.synchronizeWebformResult({ webformId: webformIdParam }).subscribe({
                            next: (bankConnection: BankConnectionDto) => {
                                if (!webformUpdateParam) {
                                    this.openSyncAccountsOverlay(bankConnection, true);
                                    this.isLoading = false;
                                } else {
                                    this.findAllBankAccounts();
                                }
                            },
                            error: (error) => {
                                this.toastService.showError(error.error['message']);
                                this.isLoading = false;
                            },
                        });
                    } else {
                        this.findAllBankAccounts();
                    }
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public addAccount(): void {
        this.isLoading = true;
        this.bankConnectionsService
            .createWebFormForNewConnection()
            .pipe(
                finalize(() => (this.isLoading = false)),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((response: RedirectResponseDto) => {
                const bankAccountStatusParamSuccess = `addBankAccountStatus=${AddBankAccountStatus.Success}`;
                const bankAccountStatusParamError = `addBankAccountStatus=${AddBankAccountStatus.Error}`;
                const webformIdParam = `webformId=${response.webformId}`;
                const redirectUrlParam = `redirectUrl=${this.ownUrlPath}?${encodeURIComponent(
                    bankAccountStatusParamSuccess + '&' + webformIdParam
                )}`;
                const errorUrlParam = `errorRedirectUrl=${this.ownUrlPath}?${encodeURIComponent(
                    bankAccountStatusParamError + '&' + webformIdParam
                )}`;
                const abortRedirectUrl = `abortRedirectUrl=${this.ownUrlPath}?${encodeURIComponent(
                    bankAccountStatusParamError + '&' + webformIdParam
                )}`;
                this.document.location.href = `${response.url}?${redirectUrlParam}&${errorUrlParam}&${abortRedirectUrl}`;
            });
    }

    private initMessageTranslations(): void {
        this.addBankAccountSuccessMessage = this.translateService.instant(
            'PAGES.USER_MANAGEMENT.ACCOUNTS_VIEW.UPDATE_BANK_ACCOUNT_SUCCESS'
        );
        this.addBankAccountErrorMessage = this.translateService.instant(
            'PAGES.USER_MANAGEMENT.ACCOUNTS_VIEW.ADD_BANK_ACCOUNT_ERROR'
        );
        this.notAssignedText = this.translateService.instant('PAGES.USER_MANAGEMENT.ACCOUNTS_VIEW.NOT_ASSIGNED');
    }

    private createRow(bankAccount: BankAccountDto): TableItem[] {
        const actionTooltipText = this.getActionTooltipText(bankAccount);
        const link = this.getActionLink(bankAccount);

        const rowsToReturn: TableItem[] = [
            {
                data: {
                    label: bankAccount.bankName,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: bankAccountTypeName(bankAccount),
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: this.ibanFormatterPipe.transform(bankAccount.iban),
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: bankAccount.holderName,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: bankAccount.lastSuccessfulUpdate || '',
                },
                template: CellTemplate.DateTime,
            },
            {
                data: {
                    label: '',
                },
                template: bankAccount.dataOutdated ? this.iconWithTooltip : CellTemplate.Default,
            },
            {
                data: {
                    label: bankAccount.isSyncing ? ' Aktualisierung läuft...' : '',
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: this.getAddressForBankAccount(bankAccount) || this.notAssignedText || '',
                },
                template: this.getAddressForBankAccount(bankAccount) ? CellTemplate.Default : CellTemplate.NotFoundText,
            },
            {
                data: {
                    label: actionTooltipText,
                    link,
                    extraData: {
                        bankAccount: bankAccount,
                        disabledButton: bankAccount.ledgerIds.length === 0 ? true : false,
                        showEditButton: bankAccount.ledgerIds.length === 0,
                    },
                },
                template: CellTemplate.twoActionsButtons,
            },
        ];

        return rowsToReturn;
    }

    private getActionTooltipText(bankAccount: BankAccountDto): string {
        let actionTooltipText = '';
        if (bankAccount.ledgerIds.length > 0) {
            actionTooltipText = this.translateService.instant(
                'PAGES.USER_MANAGEMENT.ACCOUNTS_VIEW.SYNCHRONIZED_ACCOUNTS_ACTION_TOOLTIP'
            );
        } else {
            actionTooltipText = this.translateService.instant(
                'PAGES.USER_MANAGEMENT.ACCOUNTS_VIEW.NON_SYNCHRONIZED_ACCOUNTS_ACTION_TOOLTIP'
            );
        }
        return actionTooltipText;
    }

    private getActionLink(bankAccount: BankAccountDto): string {
        if (bankAccount.ledgerIds.length > 0) {
            return `/accounting/ledger/${bankAccount.ledgerIds[0]}/bank-accounts/${bankAccount.id}`;
        } else {
            return '';
        }
    }

    private getAddressForBankAccount(bankAccount: BankAccountDto): string | null {
        const ledger = this.ledgers?.find((ledger) => ledger.id === bankAccount.ledgerIds[0]);
        const property = ledger?.property as Property;
        if (bankAccount.ledgerIds.length === 0 || !property) {
            return null;
        }

        return `${ledger?.type} ${property.address?.streetName} ${property.address?.streetNumber}, ${property.address?.zipCode} ${property.address?.area}`;
    }

    public openSyncAccountsOverlay(bankConnection: BankConnectionDto, showInfoBox?: boolean): void {
        const ref = this.overlayService.open(SyncAccountsFormComponent, { data: { bankConnection, showInfoBox } });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            ref.close();
            this.router.navigate(['/user-management/accounts']);
        });

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.toastService.showSuccess(this.addBankAccountSuccessMessage);
            this.findAllBankAccounts();
        });
    }

    public openSynchronizePage(event: any): void {
        this.openSyncAccountsOverlay(event.extraData.bankAccount);
    }

    public changeSelectDisplay(index: number): void {
        this.isCollapsed[index] = !this.isCollapsed[index];
    }

    public updateAccount(id: string): void {
        this.isLoading = true;
        this.bankConnectionsService
            .createWebformForUpdateConnection(id)
            .pipe(
                finalize(() => (this.isLoading = false)),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((response: RedirectResponseDto) => {
                const bankAccountStatusParamSuccess = `addBankAccountStatus=${AddBankAccountStatus.Success}`;
                const bankAccountStatusParamError = `addBankAccountStatus=${AddBankAccountStatus.Error}`;
                const webformIdParam = `webformId=${response.webformId}`;
                const update = `update=${true}`;
                const redirectUrlParam = `redirectUrl=${this.ownUrlPath}?${encodeURIComponent(
                    bankAccountStatusParamSuccess + '&' + webformIdParam + '&' + update
                )}`;
                const errorUrlParam = `errorRedirectUrl=${this.ownUrlPath}?${encodeURIComponent(
                    bankAccountStatusParamError + '&' + webformIdParam
                )}`;
                this.document.location.href = `${response.url}?${redirectUrlParam}&${errorUrlParam}`;
            });
    }
}
