<div ibmRow class="pt-5r pb-1-5r pr-1r pl-1r justify-space-between">
    <div class="tw-flex tw-items-center">
        <app-headline-with-icon color="green" image="24_person.svg" text="Eigentümer"></app-headline-with-icon>
        <app-text-with-tooltip
            *ngIf="tooltipInfo"
            class="tw-inline-block"
            [label]="''"
            [labelClass]="''"
            [tooltipKey]="tooltipInfo.tooltipKey"
            [tooltipInnerHtml]="tooltipInfo.tooltipValue"
        ></app-text-with-tooltip>
    </div>
    <div *ngIf="isEditable" class="d-flex justify-content-end">
        <button
            ibmButton="ghost"
            (click)="openAddOwnerOverlay()"
            size="sm"
            class="s-body-14-20-regular scal-ghost-button-with-icon"
        >
            <svg-icon src="/assets/icons/24_add.svg"></svg-icon>
            {{ 'CONTROLS.BUTTONS.ADD' | translate }}
        </button>
    </div>
</div>

<div ibmRow>
    <div ibmCol>
        <app-table
            [emptyText]="'PAGES.RELATIONS.OWNER.LABEL_EMPTY_TABLE'"
            [header]="tableModel.header"
            [data]="tableModel.data"
        >
        </app-table>
    </div>
</div>

<ng-template #actions let-data="data">
    <app-table-actions
        [data]="data.extraData.transferObject"
        (openDeleteRelationOvervlay)="openDeleteRelationOvervlay($event)"
        (openEditRelationOvervlay)="openEditRelationOvervlay($event)"
    ></app-table-actions>
</ng-template>

<ng-template #personTags let-data="data">
    <a [routerLink]="data.link" queryParamsHandling="merge" class="tw-flex tw-space-x-1 data-table-link">
        <app-tag *ngIf="data.extraData?.isCurrentOwner" color="green">Aktueller Eigentümer</app-tag>
        <app-tag *ngIf="data.extraData?.isContactPerson" color="purple">Hauptansprechpartner</app-tag>
    </a>
</ng-template>
