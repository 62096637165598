import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ListItem } from 'carbon-components-angular';
import { Subject, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { formControl, formControlHasError, formatDateYYYYMMDDWithoutHours } from 'src/app/core/utils/common';
import {
    CreateEnergyCertificateDto,
    EnergyCertificate,
    EnergyCertificateFile,
    EnergyCertificateFileDto,
    EnergyCertificatesService,
} from 'src/app/generated-sources/base';
import { ExistingFile } from 'src/app/shared/components/file-uploader/existing-files/existing-files.component';
import { FileUploaderComponent } from 'src/app/shared/components/file-uploader/file-uploader.component';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';

@Component({
    selector: 'app-add-edit-energy-certificate-overlay',
    templateUrl: './add-edit-energy-certificate-overlay.component.html',
    styleUrls: ['./add-edit-energy-certificate-overlay.component.scss'],
})
export class AddEditEnergyCertificateOverlayComponent extends OverlayChildComponent implements OnInit, OnDestroy {
    public energyCertificateTypeList: ListItem[] = [];
    public isLoading = false;
    public propertyId = '';
    public buildingId = '';
    public form = this.formBuilder.group({
        certificateType: [''],
        energyValueInkWh: ['', [Validators.required]],
        title: [''],
        issueDate: [''],
        validUntil: [''],
        energyEfficiencyClass: [''],
    });
    public energyCertificateToEdit?: EnergyCertificate;

    //  controlled via events from app-file-uploader
    @ViewChild('fileUpload')
    public fileUploader?: FileUploaderComponent;

    public areFilesFullyUploaded = true;
    public fileUuids: string[] = [];
    public selectedFilesCategories: EnergyCertificateFile.FileCategoryEnum[] = [];
    public fileCategories: ListItem[] = [];
    public existingFiles: ExistingFile[] = [];
    public existingFileToEdit: ExistingFile[] = [];

    public unsubscribe$ = new Subject<void>();

    public constructor(
        private translateService: TranslateService,
        private energyCertificatesService: EnergyCertificatesService,
        private toastService: ToastService,
        private formBuilder: UntypedFormBuilder
    ) {
        super();
    }

    public ngOnInit(): void {
        this.propertyId = this.config?.data.propertyId;
        this.buildingId = this.config?.data.buildingId;
        this.energyCertificateToEdit = this.config?.data.energyCertificateToEdit;

        this.fileCategories = [
            {
                content: this.translateService.instant('ENTITIES.DOCUMENT.ENERGY_PASS'),
                selected: true,
                value: EnergyCertificateFile.FileCategoryEnum.EnergyPass,
            },
        ];

        this.energyCertificateTypeList = Object.values(CreateEnergyCertificateDto.CertificateTypeEnum).map((item) => {
            return {
                content: this.translateService.instant('ENTITIES.ENERGY_CERTIFICATE.LABEL_' + item),
                value: item,
                selected: this.energyCertificateToEdit?.certificateType === item,
            };
        });

        if (this.energyCertificateToEdit) {
            this.form.patchValue({
                certificateType: this.energyCertificateTypeList.find(
                    (item) => item['value'] === this.energyCertificateToEdit?.certificateType
                ),
                energyValueInkWh: this.energyCertificateToEdit.energyValueInkWh,
                issueDate: this.energyCertificateToEdit.issueDate
                    ? [new Date(this.energyCertificateToEdit.issueDate)]
                    : undefined,
                validUntil: this.energyCertificateToEdit.validUntil
                    ? [new Date(this.energyCertificateToEdit.validUntil)]
                    : undefined,
                energyEfficiencyClass: this.energyCertificateToEdit.energyEfficiencyClass,
            });

            this.energyCertificateToEdit.energyCertificateFiles?.map((file) => {
                this.existingFiles.push({
                    fileName: file.filename ?? '',
                    key: file.fileStorageId,
                    category: file.fileCategory,
                });
            });
            this.existingFileToEdit = this.existingFiles;
        }
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public submit(): void {
        this.isLoading = true;

        let energyCertificateFiles: EnergyCertificateFileDto[] = this.fileUuids.map((uuid, index) => ({
            fileStorageId: uuid,
            fileCategory: EnergyCertificateFile.FileCategoryEnum.EnergyPass,
        }));

        if (this.existingFileToEdit.length > 0) {
            const existingFileToUpdate = this.existingFileToEdit.map((file) => ({
                fileStorageId: file.key,
                fileCategory: file.category,
            }));
            if (energyCertificateFiles.length > 0) {
                energyCertificateFiles.push(...existingFileToUpdate);
            } else {
                energyCertificateFiles = existingFileToUpdate;
            }
        }

        console.log(this.form.get('issueDate'));

        const createOrUpdateEnergyCertificate = {
            certificateType: this.form.get('certificateType')?.value?.value,
            energyValueInkWh: this.form.get('energyValueInkWh')?.value,
            energyCertificateFiles: energyCertificateFiles,
            issueDate: this.form.get('issueDate')?.value
                ? formatDateYYYYMMDDWithoutHours(this.form.get('issueDate')?.value[0])
                : undefined,
            validUntil: this.form.get('validUntil')?.value
                ? formatDateYYYYMMDDWithoutHours(this.form.get('validUntil')?.value[0])
                : undefined,
            energyEfficiencyClass: this.form.get('energyEfficiencyClass')?.value,
        };

        if (this.energyCertificateToEdit) {
            this.energyCertificatesService
                .updateEnergyCertificate(
                    this.propertyId,
                    this.buildingId,
                    this.energyCertificateToEdit.id,
                    createOrUpdateEnergyCertificate
                )
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe({
                    next: () => {
                        this.toastService.showSuccess(
                            this.translateService.instant('PAGES.ADD_EDIT_ENERGY_CERTIFICATE.EDIT_SUCCESS_TOAST')
                        );
                        this.saveEmitter$.next();
                        this.isLoading = false;
                    },
                    error: (error) => {
                        if (error) {
                            this.toastService.showError(error.error['message']);
                        }
                        this.isLoading = false;
                    },
                });

            return;
        }

        this.energyCertificatesService
            .create(this.propertyId, this.buildingId, createOrUpdateEnergyCertificate)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: () => {
                    this.toastService.showSuccess(
                        this.translateService.instant('PAGES.ADD_EDIT_ENERGY_CERTIFICATE.SUCCESS_TOAST')
                    );
                    this.saveEmitter$.next();
                },
                error: (error) => {
                    if (error) {
                        this.toastService.showError(error.error['message']);
                    }
                },
            });
    }

    public updateLoadingFilesStatus($event: boolean): void {
        this.areFilesFullyUploaded = $event;
    }

    public updateFileIdsLodaded($event: string[]): void {
        this.fileUuids = $event;
        if (this.fileUuids.length === 0) {
            this.areFilesFullyUploaded = true;
        }
    }

    public updatedFileCategories($event: any): void {
        this.selectedFilesCategories = $event;
    }

    public isInvalid(controlName: string): boolean {
        return formControlHasError(formControl(this.form, controlName), 'required');
    }

    public updateExistingFilesList(newExistingList: ExistingFile[]): void {
        this.existingFileToEdit = newExistingList;
    }
}
