import { Injectable } from '@angular/core';
import { BreadcrumbItem } from 'carbon-components-angular';

@Injectable({
    providedIn: 'root',
})
export class BreadCrumbService {
    // Get breadcrumbs that are already stored
    public getCurrentBreadCrumbs(): BreadcrumbItem[] {
        return JSON.parse(localStorage.getItem('breadcrumbs') || '');
    }

    // Adds new Breadcrumbs to the already stored ones.
    public updateBreadCrumbs(newBreadcrumbs: BreadcrumbItem[]): void {
        //  if routeExtras is not set, set it to queryParamsHandling: 'merge'
        newBreadcrumbs.forEach((i) =>
            Object.prototype.hasOwnProperty.call(i, 'routeExtras')
                ? i
                : (i.routeExtras = { queryParamsHandling: 'merge' })
        );

        for (let i = 0; i < newBreadcrumbs.length; i++) {
            const currentBreadcrumbs: BreadcrumbItem[] = JSON.parse(localStorage.getItem('breadcrumbs') || '');
            if (!currentBreadcrumbs) {
                const updatedbreadcrumb: BreadcrumbItem = newBreadcrumbs[i];
                localStorage.setItem('breadcrumbs', JSON.stringify(updatedbreadcrumb));
                return;
            }

            //  do not update breadcrumbs if there are already breadcrumbs with the same route
            const breadcrumbAlreadyExists = Boolean(
                currentBreadcrumbs.find((breadcrumb) => {
                    //  href must not be used at all, but at this point there are a lot of places where it is misused
                    //  to make thing compatible (sometimes using route and sometimes href we have to check both)
                    //  route = ['/path' ]
                    const breadcrumbRouteOrHref = Object.prototype.hasOwnProperty.call(breadcrumb, 'route')
                        ? (breadcrumb.route as [string])[0]
                        : breadcrumb.href;
                    const newBreadcrumbRouteOrHref = Object.prototype.hasOwnProperty.call(newBreadcrumbs[i], 'route')
                        ? (newBreadcrumbs[i].route as [string])[0]
                        : newBreadcrumbs[i].href;
                    return breadcrumbRouteOrHref == newBreadcrumbRouteOrHref;
                })
            );

            if (breadcrumbAlreadyExists) {
                return;
            }

            const updatedbreadcrumbs: BreadcrumbItem[] = [];
            currentBreadcrumbs.map((breadcrumb: BreadcrumbItem) => {
                breadcrumb.current = false;
                updatedbreadcrumbs.push(breadcrumb);
            });
            updatedbreadcrumbs.push(newBreadcrumbs[i]);
            localStorage.setItem('breadcrumbs', JSON.stringify(updatedbreadcrumbs));
        }
    }

    // Resets all previous Breadcrumbs set
    public resetBreadCrumbs(): void {
        localStorage.setItem('breadcrumbs', JSON.stringify([]));
    }
}
