<ibm-loading *ngIf="isLoading" [isActive]="true" [size]="'normal'" [overlay]="true"></ibm-loading>

<form [formGroup]="form">
    <div class="mb-2r s-body-14-22-bold">Verteilen Sie die umlagefähigen Kosten</div>
    <div class="d-flex justify-space-between">
        <div class="s-gray-03 s-label-12-22-semibold first-column">Konto</div>
        <div class="s-gray-03 s-label-12-22-semibold">Verteilungsschlüssel</div>
        <div class="s-gray-03 s-label-12-22-semibold">Gesamtwert in €</div>
    </div>
    <hr class="mt-1r mb-1r" />

    <ng-container formArrayName="accounts">
        <div *ngFor="let occupationGroup of accountsControl.controls; let i = index">
            <div class="d-flex justify-space-between align-items-center">
                <ng-container [formGroupName]="i">
                    <div class="s-head-14-22-regular first-column">
                        {{
                            occupationGroup.value?.accountName +
                                (occupationGroup.value?.accountDescription
                                    ? ', ' + occupationGroup.value?.accountDescription
                                    : '')
                        }}
                    </div>

                    <ibm-label invalidText="{{ 'ERROR.REQUIRED' | translate }}">
                        <app-combo-box
                            id="distributionKeyId"
                            placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT_2' | translate }}"
                            label=""
                            [items]="occupationGroup.value?.distributionKeysList || []"
                            formControlName="distributionKeyId"
                            invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                            class="combo-box-formated-width"
                        >
                            <ibm-dropdown-list></ibm-dropdown-list>
                        </app-combo-box>
                    </ibm-label>

                    <div class="s-head-14-22-regular">
                        {{ occupationGroup.value?.totalDistribution || 0 | eurocent }}
                    </div>
                </ng-container>
            </div>
            <hr class="mt-1r mb-1r" />
        </div>
    </ng-container>
</form>

<div class="mt-3-5r d-flex justify-content-end total-distribution-costs">
    <div class="mt-2r s-head-14-22-bold d-flex">
        <div class="mr-6r">Gesamtbetrag</div>
        <div class="ml-1-5r">{{ sumTotalDistribution() | eurocent }}</div>
    </div>
</div>

<div class="d-flex justify-space-between mt-7-5r">
    <app-scalara-button (click)="abort()" [variant]="'outline'">
        {{ 'CONTROLS.BUTTONS.BACK' | translate }}
    </app-scalara-button>

    <div class="d-flex">
        <div class="mr-1-5r">
            <app-scalara-button [disabled]="isInvalidForm()" (click)="submit(true)" [variant]="'ghost'" height="48px">
                Speichern & schließen
            </app-scalara-button>
        </div>

        <app-scalara-button [disabled]="isInvalidForm()" variant="default" (click)="submit()">
            Speichern & weiter
        </app-scalara-button>
    </div>
</div>
