import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, map, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { MediaQueriesService } from 'src/app/core/services/media-queries.service.ts.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { Person, UserDto, UsersService } from 'src/app/generated-sources/base';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { environment } from 'src/environments/environment';
import { ChangePasswordOverlayComponent } from '../../../contacts/components/change-password-overlay/change-password-overlay.component';
import { PersonLocalService } from '../../../property/services/person-local.service';
import { CustomTooltipService, TooltipKey } from '../../services/custom-tooltip.service';

@Component({
    selector: 'app-account-settings',
    templateUrl: './account-settings.component.html',
    styleUrls: ['./account-settings.component.scss'],
})
export class AccountSettingsComponent implements OnInit {
    public constructor(
        public personLocalService: PersonLocalService,
        public usersService: UsersService,
        public overlayService: OverlayService,
        public translateService: TranslateService,
        public toastService: ToastService,
        public authService: AuthService,
        public router: Router,
        public mediaQueriesService: MediaQueriesService,
        public customTooltipService: CustomTooltipService
    ) {}

    public unsubscribe$ = new Subject<void>();
    public isLoading = false;
    public person?: Person;
    public user?: UserDto;

    public showAccountDetails(): boolean {
        return this.user?.personId === this.person?.id && !!this.user && !environment.demoEnvironment;
    }

    public ngOnInit(): void {
        this.isLoading = true;
        this.personLocalService.getPerson$().subscribe((person) => {
            this.person = person;
        });

        this.usersService.findAll().subscribe((user) => {
            this.user = user;
            this.isLoading = false;
        });
    }

    public openChangePasswordOverlay(): void {
        const data = {
            user: this.user,
        };
        const ref = this.overlayService.open(ChangePasswordOverlayComponent, {
            data,
        });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            ref.close();
        });

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.toastService.showSuccess(this.translateService.instant('PAGES.CHANGE_PASSWORD.SUCCESS_TOAST'));
            this.authService.signOut();
            this.router.navigate(['/sign-in']);
        });
    }

    public readonly tooltipsConfig: Record<TooltipKey, { label: string; index: number }> = {
        tooltipsInProperties: {
            label: 'Im Bereich ‘Immobilien’ anzeigen',
            index: 0,
        },
        tooltipsInAccounting: {
            label: 'Im Bereich ‘Finanzen’ anzeigen',
            index: 1,
        },
        tooltipsInListGenerator: {
            label: 'Im Bereich ‘Berichte’ anzeigen',
            index: 2,
        },
        tooltipsInCommunication: {
            label: 'Im Bereich ‘Kommunikation’ anzeigen',
            index: 3,
        },
        tooltipsInContacts: {
            label: 'Im Bereich ‘Kontakte’ anzeigen',
            index: 4,
        },
        tooltipsInMyAccount: {
            label: 'Im Bereich ‘Mein Account’ anzeigen',
            index: 5,
        },
    };

    public tooltipState$ = this.customTooltipService.getTooltipState$();
    public tooltipsVM$ = this.tooltipState$.pipe(
        map((tooltipState) => {
            return Object.entries(this.tooltipsConfig)
                .sort((a, b) => a[1].index - b[1].index)
                .map(([key, item]) => {
                    return { key: key as TooltipKey, label: item.label, checked: tooltipState[key as TooltipKey] };
                });
        })
    );

    public onChangeCheckbox(tooltipKey: TooltipKey, value: boolean): void {
        this.customTooltipService.updateTooltipState(tooltipKey, value);
    }
}
