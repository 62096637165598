import { animate, state, style, transition, trigger } from "@angular/animations";

export const accordionAnimations = [
    trigger('open', [
        state('true', style({
            height: '*',
            opacity: 1
        })),
        state('false', style({
            height: 0,
            opacity: 0
        })),
        transition('* => true', [
            animate('.3s')
        ]),
        transition('true => false', [
            animate('.3s')
        ])
    ])
];