import { Component, Input, OnInit } from '@angular/core';
import { map } from 'rxjs';
import { CustomTooltipService, TooltipKey } from 'src/app/features/account-settings/services/custom-tooltip.service';

@Component({
    selector: 'app-text-with-tooltip',
    templateUrl: './text-with-tooltip.component.html',
    styleUrls: ['./text-with-tooltip.component.scss'],
})
export class TextWithTooltipComponent implements OnInit {
    public constructor(private customTooltipService: CustomTooltipService) {}

    @Input() public label?: string;
    @Input() public labelClass?: string;
    @Input() public tooltipKey?: TooltipKey;
    @Input() public tooltipInnerHtml?: string;
    @Input() public linkParams?: { routerLink: string; linkClass?: string };

    public showTooltip$ = this.customTooltipService
        .getTooltipState$()
        .pipe(map((state) => (this.tooltipKey ? state[this.tooltipKey] : true)));

    public ngOnInit(): void {}
}
