import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, shareReplay, Subject, switchMap } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { Person, PersonsService, PropertiesService, Property } from 'src/app/generated-sources/base';
import { PropertyCustomService } from './property-custom.service';

export interface ExtendedPerson extends Person {
    isOwner: boolean;
}
@Injectable({
    providedIn: 'root',
})
export class PersonLocalService {
    private unsubscribe$ = new Subject<void>();

    private constructor(
        private personsService: PersonsService,
        private propertiesService: PropertiesService,
        private propertyCustomService: PropertyCustomService,
        private authService: AuthService
    ) {}

    public getPerson$(): Observable<Person> {
        return this.person$;
    }

    //refactor not to use this anymore, cause now the PersonDto has all the infos we need
    public getExtendedPerson$(): Observable<Person | null> {
        return this.person$;
    }

    // TODO refactor and use property-service instead
    public setRolesOfProperty(roles: Property.PermissionRolesEnum[]): void {
        this.rolesOfProperty$.next(roles);
    }

    // TODO refactor and use property-service instead
    public getRolesOfProperty(): Observable<Property.PermissionRolesEnum[] | null> {
        return this.rolesOfProperty$.asObservable();
    }

    private rolesOfProperty$ = new BehaviorSubject<Property.PermissionRolesEnum[] | null>(null);

    //  refresh person on sign in
    private readonly person$ = this.authService.refreshOnLogin().pipe(
        switchMap(() => this.personsService.findOne('self')),
        shareReplay({ bufferSize: 1, refCount: true })
    );
    // TODO: FINISH PERSON REFACTORING LATER

    // private readonly triggerPerson$ = new BehaviorSubject<void>(undefined);

    // private readonly person$ = this.triggerPerson$
    //     .pipe(switchMap(() => this.personsService.findOne('self')))
    //     .pipe(shareReplay({ bufferSize: 1, refCount: true }));

    // // private readonly person$ = this.personsService.findOne('self').pipe(shareReplay({ bufferSize: 1, refCount: true }));

    // private readonly properties$ = this.propertyCustomService.getProperties$();

    // private readonly extendedPersonNew$ = combineLatest([this.person$, this.properties$]).pipe(
    //     map(([person, properties]) => {
    //         let isOwner = false;
    //         properties.map((property) => {
    //             if (
    //                 property.permissionRoles.includes(Property.PermissionRolesEnum.CoOwner) ||
    //                 property.permissionRoles.includes(Property.PermissionRolesEnum.Owner)
    //             ) {
    //                 // Confirming that user is at least owner of one property
    //                 // in order to grant him permission on the accounting page
    //                 isOwner = true;
    //             }
    //         });
    //         const extendedPerson: ExtendedPerson = { ...person, isOwner: isOwner };
    //         return extendedPerson;
    //     })
    // );

    private person!: ExtendedPerson;

    private readonly extendedPerson$ = new BehaviorSubject<ExtendedPerson | null>(null);
}
