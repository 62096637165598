import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
    BehaviorSubject,
    Subject,
    combineLatest,
    distinctUntilChanged,
    filter,
    map,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs';
import { formatDateWithoutHhMmSs, getNameFromPerson, isCurrentDateWithinRange } from 'src/app/core/utils/common';
import { OwnerRelationDto, OwnershipsService } from 'src/app/generated-sources/base';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { OverlayRef } from 'src/app/shared/overlay/utils/overlay-ref';
import { CellTemplate } from 'src/app/shared/table/enums/cell-template';
import { TableItem } from 'src/app/shared/table/interfaces/table-item';
import { PropertyCustomService } from '../../property/services/property-custom.service';
import { AddResidentOverlayComponent } from '../add-resident-overlay/add-resident-overlay.component';
import { DeleteRelationOverlayComponent } from '../delete-relation-overlay/delete-relation-overlay.component';
import { EditRelationOverlayComponent } from '../edit-relation-overlay/edit-relation-overlay.component';
import { RelationUpdateDto } from '../interfaces';

@Component({
    selector: 'app-residents',
    templateUrl: './residents.component.html',
    styleUrls: ['./residents.component.scss'],
})
export class ResidentsComponent implements OnDestroy, OnInit {
    public isLoading = false;
    public refreshTable$ = new BehaviorSubject<void>(undefined);
    private unsubscribe$ = new Subject<void>();

    @Input() public isEditable = false;

    @ViewChild('actions', { static: true })
    public actions?: TemplateRef<any>;

    public propertyId$ = this.propertyCustomService
        .getPropertyId$()
        .pipe(filter(Boolean), takeUntil(this.unsubscribe$));

    public ownershipId$ = this.route.url.pipe(
        map(() => {
            return this.route.snapshot.paramMap.get('ownershipId') ?? undefined;
        }),
        takeUntil(this.unsubscribe$),
        filter(Boolean),
        distinctUntilChanged()
    );

    public vm = { ownershipId: '', propertyId: '' };
    public vm$ = combineLatest([this.propertyId$, this.ownershipId$]).pipe(
        tap(([propertyId, ownershipId]) => (this.vm = { propertyId: propertyId, ownershipId: ownershipId })),
        takeUntil(this.unsubscribe$)
    );

    public residentsTable = combineLatest([this.propertyId$, this.ownershipId$, this.refreshTable$]).pipe(
        switchMap(([propertyId, ownershipId]) => {
            return this.ownershipsService.findResidentRelations(propertyId, ownershipId);
        }),
        map((residents) => {
            return {
                data: residents.map((resident) => this.createRow(resident)),
                header: [
                    'ENTITIES.PERSON.LABEL_FIRSTNAME_LASTNAME_COMPANY_NAME',
                    'ENTITIES.RELATION_WITH_TIMECONSTRAINT.LABEL_FROM_TO',
                    '',
                    '',
                ],
            };
        })
    );

    public constructor(
        private ownershipsService: OwnershipsService,
        private propertyCustomService: PropertyCustomService,
        private route: ActivatedRoute,
        private overlayService: OverlayService
    ) {}

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public ngOnInit(): void {
        this.vm$.subscribe();
    }

    public openAddResidentsOverlay(): void {
        const ref = this.overlayService.open(AddResidentOverlayComponent, {
            data: { ownershipId: this.vm.ownershipId },
        });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

        this.saveEmittersUpdate(ref);
    }

    public openDeleteRelationOvervlay(rowData: RelationUpdateDto): void {
        const data = {
            relation: rowData,
        };
        const ref = this.overlayService.open(DeleteRelationOverlayComponent, { data });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
        this.saveEmittersUpdate(ref);
    }

    public openEditRelationOvervlay(rowData: RelationUpdateDto): void {
        const data = {
            extraData: {
                relation: rowData,
            },
        };
        const ref = this.overlayService.open(EditRelationOverlayComponent, { data });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
        this.saveEmittersUpdate(ref);
    }

    private createRow(data: OwnerRelationDto): TableItem[] {
        const link = `/contacts/${data.person.id}`;
        let tableLabel = '';

        const transferObject: RelationUpdateDto = {
            personId: data.person.id,
            propertyId: this.vm.propertyId,
            ownershipId: this.vm.ownershipId,
            relationId: data.id ?? '',
            parent: 'resident',
            startDate: new Date(data.from),
            person: data.person,
            ...(data.to ? { endDate: new Date(data.to) } : undefined),
        };

        if (isCurrentDateWithinRange(data.from, data.to)) {
            tableLabel = 'Aktueller Bewohner';
        }
        return [
            {
                data: {
                    label: data.person ? getNameFromPerson(data.person) : 'Keine Person gefunden',
                    link,
                    extraData: {
                        isUserRegistered: data.person.hasRegisteredUser,
                        img: data.person.imgSmall,
                    },
                },
                template: data.person.imgSmall ? CellTemplate.imageTableItemWithLabel : CellTemplate.personWithAvatar,
            },
            {
                data: {
                    label: `${formatDateWithoutHhMmSs(data.from)} – ${formatDateWithoutHhMmSs(data.to)}`,
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: `${tableLabel}`,
                    link,
                    textColor: 'green',
                },
                template: CellTemplate.contentWithTagStyle,
            },
            {
                data: {
                    label: '',
                    extraData: {
                        transferObject,
                        rightAligned: true,
                    },
                },
                template: this.isEditable ? this.actions : CellTemplate.Default,
            },
        ];
    }

    private saveEmittersUpdate(ref: OverlayRef): void {
        ref.saveEmitter$.subscribe(() => {
            this.refreshTable$.next();
        });
        ref.cancelEmitter$.subscribe(() => {
            ref.close();
        });
    }
}
