import { Component, Input } from '@angular/core';
import { EnergyCertificateFile, TechnicalEquipmentFile } from 'src/app/generated-sources/base';
import { FilesService } from 'src/app/generated-sources/file';
import { FiletoView } from '../file-viewer/file-viewer.component';

type DocumentFile = EnergyCertificateFile | TechnicalEquipmentFile;

@Component({
    selector: 'app-filestorage-documents-list',
    templateUrl: './filestorage-documents-list.component.html',
    styleUrls: ['./filestorage-documents-list.component.scss'],
})
export class FilestorageDocumentsListComponent {
    @Input() public documentList: DocumentFile[] = [];
    @Input() public showDocumentIcon = true;

    public fileToView?: FiletoView;
    public showModal = false;

    public constructor(private filesService: FilesService) {}

    public handleFile(fileItem: DocumentFile, isDownload: boolean): void {
        this.filesService.getDownloadLink(fileItem.fileStorageId).subscribe((data: any) => {
            if (isDownload) {
                const link = document.createElement('a');
                link.href = data.url;
                link.download = fileItem.filename ?? '';
                link.click();
            } else {
                this.fileToView = { fileName: fileItem.filename ?? '', file: data.url };
                this.showModal = true;
            }
        });
    }

    public handleModal(): void {
        this.showModal = !this.showModal;
    }
}
