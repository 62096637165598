<div *ngFor="let tableItem of paymentTable; let i = index">
    <!-- header -->
    <div class="tw-flex tw-justify-between tw-items-center tw-bg-scalaraGray-06 tw-p-4 tw-mb-5">
        <div class="tw-flex tw-items-center tw-gap-6">
            <div class="tw-s-body-14-22-bold">IBAN {{ tableItem.header.iban | ibanFormatter }}</div>
            <div *ngIf="!ledgerId">
                <app-tag [color]="getTagColor(tableItem.header.ledgerType)" size="s">{{
                    tableItem.header.ledgerType
                }}</app-tag>
                <span class="tw-ml-2 tw-s-body-14-22-regular">{{ tableItem.header.ledgerName }}</span>
            </div>
            <div *ngIf="!isExecutionDateExpired(tableItem.payments[0].executionDate); else executionDateExpired">
                {{ 'ENTITIES.PAYMENT.LABEL_EXECUITION_DATE' | translate }} {{ ' ' }}
                {{ tableItem.payments[0].executionDate | dateFormat }}
            </div>
            <ng-template #executionDateExpired>
                <ng-container
                    *ngTemplateOutlet="tooltip; context: { executionDate: tableItem.payments[0].executionDate }"
                >
                </ng-container>
            </ng-template>
        </div>
        <div class="tw-flex tw-items-center">
            <div class="tw-mr-8">
                <span class="tw-s-body-14-22-bold"
                    >{{ selectedPayments(tableItem.payments) }} von {{ ' ' }}{{ tableItem.rows.length }}
                </span>
                <span class="tw-s-body-14-22-regular">Aufträgen ausgewählt</span>
            </div>
            <app-scalara-button height="40px" (click)="outputOpenWebForm(tableItem.payments)">
                Alle ausgewählten freigeben
            </app-scalara-button>
        </div>
    </div>
    <!--  -->

    <table class="tw-w-full tw-mb-10">
        <tr simpleTableRow [columns]="tableItem.columns" variant="header"></tr>
        <ng-container *ngFor="let row of tableItem.rows">
            <tr
                simpleTableRow
                [tableSearchId]="paymentType"
                [columns]="tableItem.columns"
                [rowData]="row"
                [class]="row.rowClass"
            ></tr>
        </ng-container>
    </table>
</div>

<ng-template #tooltip let-executionDate="executionDate">
    <div class="tw-items-center">
        <app-tooltip position="top">
            <div content class="tw-py-8 tw-px-12 tw-w-[575px]">
                Das Ausführungsdatum liegt in der Vergangenheit. Wenn Sie den Auftrag freigeben wird er am Tag der
                Freigabe ausgeführt.
            </div>
            <div hoverable-item class="tw-flex tw-items-center tw-justify-end">
                <div class="tw-s-body-14-20-regular tw-mr-1 tw-text-scalaraGray-03">
                    {{ 'ENTITIES.PAYMENT.LABEL_EXECUITION_DATE' | translate }} {{ ' ' }}{{ executionDate | dateFormat }}
                </div>
                <div class="tw-h-6 tw-w-6 tw-fill-scalaraPurple-01">
                    <svg-icon src="/assets/icons/24_attention.svg"></svg-icon>
                </div>
            </div>
        </app-tooltip>
    </div>
</ng-template>
