/**
 * SCALARA Base Module
 * The SCALARA API for User, Person and Property resources
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ImportValidationErrors } from './importValidationErrors';
import { ImportStatistics } from './importStatistics';


export interface ImportInfo { 
    id: string;
    fileName: string;
    uploadedByUser: string;
    processingStatus: ImportInfo.ProcessingStatusEnum;
    fileKey: string;
    errorMessage?: string;
    propertyType: ImportInfo.PropertyTypeEnum;
    createdAt?: string;
    updatedAt?: string;
    importStatistics?: ImportStatistics;
    validationErrors?: ImportValidationErrors;
}
export namespace ImportInfo {
    export type ProcessingStatusEnum = 'PENDING' | 'ONGOING' | 'SUCCESSFUL' | 'FAILED' | 'SQL_IMPORTED';
    export const ProcessingStatusEnum = {
        Pending: 'PENDING' as ProcessingStatusEnum,
        Ongoing: 'ONGOING' as ProcessingStatusEnum,
        Successful: 'SUCCESSFUL' as ProcessingStatusEnum,
        Failed: 'FAILED' as ProcessingStatusEnum,
        SqlImported: 'SQL_IMPORTED' as ProcessingStatusEnum
    };
    export type PropertyTypeEnum = 'WEG' | 'SEV' | 'MV' | 'WEG_SEV';
    export const PropertyTypeEnum = {
        Weg: 'WEG' as PropertyTypeEnum,
        Sev: 'SEV' as PropertyTypeEnum,
        Mv: 'MV' as PropertyTypeEnum,
        WegSev: 'WEG_SEV' as PropertyTypeEnum
    };
}


