<div class="bx--pagination" *ngIf="lastPage >= 2">
    <div class="bx--pagination__control-buttons">
        <button
            ibmButton="ghost"
            [iconOnly]="true"
            class="bx--pagination__button bx--pagination__button--backward"
            [ngClass]="{ 'bx--pagination__button--no-index': currentPage <= 1 || disabled }"
            tabindex="0"
            [attr.aria-label]="backwardText.subject | async"
            (click)="setVisiblePaginationPages(previousPage)"
            [disabled]="currentPage <= 1 || disabled ? true : null"
        >
            <svg-icon src="/assets/icons/24_arrow-down.svg" svgClass="tw-h-6 tw-w-6  rotate-90"></svg-icon>
        </button>
    </div>
    <div
        class="p-02 pointer"
        [ngClass]="{ 's-body-14-22-bold active-page-number': currentPage === 1 }"
        (click)="setVisiblePaginationPages(1)"
    >
        1
    </div>

    <div *ngIf="visiblePaginationPages[0] > 3">...</div>

    <ng-container *ngFor="let page of visiblePaginationPages">
        <div
            class="p-02 pointer"
            [ngClass]="{ 's-body-14-22-bold active-page-number': currentPage === page }"
            (click)="setVisiblePaginationPages(page)"
        >
            {{ page }}
        </div>
    </ng-container>

    <div *ngIf="visiblePaginationPages[visiblePaginationPages.length - 1] < lastPage - 1">...</div>

    <div
        class="p-02 pointer"
        [ngClass]="{ 's-body-14-22-bold active-page-number': currentPage === lastPage }"
        (click)="setVisiblePaginationPages(lastPage)"
    >
        {{ lastPage }}
    </div>

    <div class="bx--pagination__control-buttons">
        <button
            ibmButton="ghost"
            [iconOnly]="true"
            class="bx--pagination__button bx--pagination__button--forward"
            [ngClass]="{ 'bx--pagination__button--no-index': currentPage >= lastPage || disabled }"
            tabindex="0"
            (click)="setVisiblePaginationPages(nextPage)"
            [disabled]="currentPage >= lastPage || disabled ? true : null"
        >
            <svg-icon src="/assets/icons/24_arrow-down.svg" svgClass="tw-h-6 tw-w-6  rotate-270"></svg-icon>
        </button>
    </div>
</div>
