import { Injector } from '@angular/core';

export class InjectorService {

    private static injector: Injector;

    public static setInjector(injector: Injector): void {
        this.injector = injector;
    }

    public static getInjector(): Injector {
        return this.injector;
    }
}
