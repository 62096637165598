import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, catchError, of, takeUntil, tap } from 'rxjs';
import { UserManagementService } from 'src/app/generated-sources/base';
import { MenuItem } from 'src/app/shared/sidenav/interfaces/menu-item';

@Component({
    selector: 'app-settings-detail-view',
    templateUrl: './settings-detail-view.component.html',
    styleUrls: ['./settings-detail-view.component.scss'],
})
export class SettingsDetailViewComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();

    public menuItems: MenuItem[] = [];

    public adminMenuItems = [
        {
            textPath: 'COMPONENTS.SIDENAV_SETTINGS.LABEL_ACCOUNTS_SETTINGS',
            link: 'account-settings',
        },
        {
            textPath: 'COMPONENTS.SIDENAV_SETTINGS.LABEL_ADD_USERS',
            link: 'users',
        },
        {
            textPath: 'COMPONENTS.SIDENAV_SETTINGS.LABEL_DATA_MIGRATION',
            link: 'migration',
        },
        {
            textPath: 'COMPONENTS.SIDENAV_SETTINGS.LABEL_PDF_CONFIGURATIONS',
            link: 'pdf-configurations',
        },
    ];

    public constructor(public userManagementService: UserManagementService) {}

    public ngOnInit(): void {
        this.userManagementService
            .getAllManagedUsers()
            .pipe(
                tap(() => {
                    this.menuItems = this.adminMenuItems;
                }),
                catchError((e) => {
                    if (e.error?.statusCode === 401) {
                        this.menuItems = [
                            {
                                textPath: 'COMPONENTS.SIDENAV_SETTINGS.LABEL_ACCOUNTS_SETTINGS',
                                link: 'account-settings',
                            },
                        ];
                    } else {
                        this.menuItems = this.adminMenuItems;
                    }

                    return of(null);
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }
}
