import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ListItem } from 'carbon-components-angular/dropdown/list-item.interface';
import { BehaviorSubject, Subject, catchError, combineLatest, filter, of, skip, switchMap, takeUntil, tap } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { formatDateYYYYMMDDWithoutHours, sortByDateCallback } from 'src/app/core/utils/common';
import { FiletoView } from 'src/app/shared/components/file-viewer/file-viewer.component';
import { AccountDto, AccountsService, ReceiptDto, ReceiptsService } from '../../../../../generated-sources/accounting';
import { FilesService } from '../../../../../generated-sources/file';
import { OverlayService } from '../../../../../shared/overlay/services/overlay.service';
import { CellTemplate } from '../../../../../shared/table/enums/cell-template';
import { TableItem } from '../../../../../shared/table/interfaces/table-item';
import { TableModel } from '../../../../../shared/table/interfaces/table-model';
import { AccountingFilterCustomService } from '../../services/accounting-filter-custom.service';
import { BusinessYearDates } from '../../services/economic-plan-custom.service';
import { LedgerCustomService } from '../../services/ledger-custom.service';
import {
    AddReceiptConfig,
    ReceiptsAddReceiptOverlayComponent,
} from '../receipts-add-receipt-overlay/receipts-add-receipt-overlay.component';
import { ReceiptsDeleteReceiptOverlayComponent } from '../receipts-delete-receipt-overlay/receipts-delete-receipt-overlay.component';

@Component({
    selector: 'app-receipts-index',
    templateUrl: './receipts-index.component.html',
    styleUrls: ['./receipts-index.component.scss'],
})
export class ReceiptsIndexComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();

    public tableModel: TableModel = { data: [], header: [] };
    public dataNotLinkedReceipts: TableItem[][] = [];

    public isLoading = false;
    public isInitialLoading = false;
    public showModal = false;
    public ledgerId = '';
    public dates?: BusinessYearDates;
    public accountsSelectedId = null;
    public fileToView?: FiletoView;
    public accountList: ListItem[] = [];
    public accountSelectedIds$ = new BehaviorSubject<string[] | undefined>(undefined);
    public allReceiptsTabSelected$ = new BehaviorSubject<boolean>(false);

    public constructor(
        private overlayService: OverlayService,
        private receiptsService: ReceiptsService,
        private filesService: FilesService,
        private toastService: ToastService,
        private translateService: TranslateService,
        private ledgerCustomService: LedgerCustomService,
        private accountsService: AccountsService,
        private accountingFilterCustomService: AccountingFilterCustomService
    ) {}

    @ViewChild('receiptExpandedTemplate')
    public receiptExpandedTemplate?: TemplateRef<any>;

    public ledgerId$ = this.ledgerCustomService.getLedgerId$().pipe(
        filter(Boolean),
        tap((ledgerId) => (this.ledgerId = ledgerId))
    );
    public datePickerSelectedDates$ = this.accountingFilterCustomService.getDatePickerSelectedDates$();

    public accounts$ = this.ledgerId$.pipe(
        switchMap((ledgerId) => this.accountsService.findAll(ledgerId)),
        tap((accounts: AccountDto[]) => {
            this.accountList = accounts.map((item) => {
                return {
                    content: `${item.name}${item.description ? `, ${item.description}` : ''}`,
                    value: item.id,
                    selected: false,
                };
            });
        }),
        catchError(() => {
            this.isLoading = false;
            this.isInitialLoading = false;
            this.toastService.showError(this.translateService.instant('COMPONENTS.TOAST.TOAST_ERROR'));
            return of(null);
        }),
        takeUntil(this.unsubscribe$)
    );

    public allReceipts$ = combineLatest([
        this.ledgerId$,
        this.datePickerSelectedDates$,
        this.accountSelectedIds$,
        this.allReceiptsTabSelected$,
    ])
        .pipe(
            skip(1),
            tap(() => (this.isLoading = true)),
            switchMap(([ledgerId, dates, accountSelectedIds, allReceiptsTabSelected]) => {
                this.ledgerId = ledgerId;
                this.dates = dates;
                return this.receiptsService.findAll(
                    this.ledgerId,
                    formatDateYYYYMMDDWithoutHours(this.dates?.startDate),
                    formatDateYYYYMMDDWithoutHours(this.dates?.endDate),
                    accountSelectedIds,
                    undefined,
                    false
                );
            }),
            tap((allReceipts: ReceiptDto[]) => {
                this.tableModel.data = allReceipts
                    .sort((a, b) => sortByDateCallback(a.createdAt, b.createdAt, 'desc'))
                    .map((receipt) => this.createRow(receipt));

                this.isLoading = false;
                this.isInitialLoading = false;
            }),

            takeUntil(this.unsubscribe$),
            catchError(() => {
                this.toastService.showError(this.translateService.instant('COMPONENTS.TOAST.TOAST_ERROR'));
                return of(null);
            })
        )
        .subscribe();

    public receipts$ = combineLatest([this.ledgerId$, this.datePickerSelectedDates$, this.accountSelectedIds$]).pipe(
        tap(() => (this.isLoading = true)),
        switchMap(([ledgerId, dates, accountSelectedIds]) => {
            this.ledgerId = ledgerId;
            this.dates = dates;
            return this.receiptsService.findAll(
                ledgerId,
                formatDateYYYYMMDDWithoutHours(dates.startDate),
                formatDateYYYYMMDDWithoutHours(dates.endDate),
                accountSelectedIds,
                undefined,
                true
            );
        }),
        tap((unlinkedReceipts: ReceiptDto[]) => {
            this.dataNotLinkedReceipts = unlinkedReceipts
                .sort((a, b) => sortByDateCallback(a.createdAt, b.createdAt, 'desc'))
                .map((receipt) => this.createRow(receipt));
        }),
        catchError(() => {
            this.toastService.showError(this.translateService.instant('COMPONENTS.TOAST.TOAST_ERROR'));
            return of(null);
        })
    );

    public ngOnInit(): void {
        this.isInitialLoading = true;
        this.initTableHeader();
        combineLatest([this.accounts$, this.receipts$])
            .pipe(
                tap(() => {
                    this.isLoading = false;
                    this.isInitialLoading = false;
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public openAddReceiptOverlay(): void {
        const data: AddReceiptConfig = {
            data: {},
        };

        const ref = this.overlayService.open(ReceiptsAddReceiptOverlayComponent, data);
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

        ref.saveEmitter$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => this.accountSelectedIds$.next(this.accountSelectedIds$.value));
    }

    private initTableHeader(): void {
        this.tableModel.header = [
            'PAGES.RECEIPTS.HEADER_LINKED',
            'PAGES.RECEIPTS.HEADER_FILE_NAME',
            'ENTITIES.DOCUMENT.LABEL_ACCOUNT',
            'ENTITIES.DOCUMENT.LABEL_TYPE',
            'PAGES.RECEIPTS.HEADER_UPLOADED_AT',
            'ENTITIES.RECEIPT.LABEL_RECEIPT_NUMBER',
            '',
        ];
    }

    public handleReceipt($event: any): void {
        if ($event.isDelete) {
            const data = {
                ledgerId: this.ledgerId,
                fileName: $event.extraData.fileName,
                id: $event.extraData.id,
            };
            const ref = this.overlayService.open(ReceiptsDeleteReceiptOverlayComponent, {
                data,
            });
            ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
            ref.saveEmitter$
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(() => this.accountSelectedIds$.next(this.accountSelectedIds$.value));
            return;
        }
        this.filesService.getDownloadLink($event.extraData.fileStorageId).subscribe((data: any) => {
            if ($event.isDownload) {
                const link = document.createElement('a');
                link.href = data.url;
                link.download = $event.extraData.fileName;
                link.click();
            } else {
                this.fileToView = { fileName: $event.extraData.fileName, file: data.url };
                this.showModal = true;
            }
        });
    }

    public handleModal(): void {
        this.showModal = !this.showModal;
    }

    public onSelectAccount($event: any): void {
        if ($event.length === 0) {
            this.accountSelectedIds$.next(undefined);
        } else {
            this.accountSelectedIds$.next([$event.item['value']]);
        }
    }

    private createRow(data: ReceiptDto): TableItem[] {
        return [
            {
                data: {
                    label: data.canDelete
                        ? this.translateService.instant('COMMON.LABEL_NOT_LINKED')
                        : this.translateService.instant('COMMON.LABEL_LINKED'),
                    extraData: {
                        color: data.canDelete ? 'gray-03' : 'green',
                        fontWeight: 'semi-bold',
                        bookings: data,
                        ledgerId: this.ledgerId,
                    },
                    iconSrc: data.canDelete ? '/assets/icons/24_not-linked.svg' : '/assets/icons/24_linked.svg',
                },
                expandedData: data.bookings || [],
                expandedTemplate: this.receiptExpandedTemplate,
                template: CellTemplate.textWithIcon,
            },
            {
                data: {
                    label: data.fileName,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: data.accountName || '–',
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: data.type ? this.translateService.instant('ENTITIES.RECEIPT.LABEL_TYPE_' + data.type) : '–',
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: data.createdAt,
                },
                template: CellTemplate.Date,
            },
            {
                data: {
                    label: data.receiptNumber,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: '',
                    extraData: { ...data, showOnlyWithHover: true },
                },
                template: CellTemplate.filesActions,
            },
        ];
    }

    public onAllReceiptsTabSelected(): void {
        if (!this.allReceiptsTabSelected$.value) {
            this.allReceiptsTabSelected$.next(true);
        }
    }
}
