import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
    BehaviorSubject,
    Observable,
    Subject,
    combineLatest,
    filter,
    lastValueFrom,
    map,
    of,
    shareReplay,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs';
import {
    EconomicPlanDetailsDto,
    EconomicPlansService,
    OwnershipAdvancementAmountDto,
} from 'src/app/generated-sources/accounting';
import { Ownership, PropertiesService } from 'src/app/generated-sources/base';
import { BaseDocumentsListItem } from 'src/app/shared/components/base-documents-list/base-documents-list.component';
import { EconomicPlanCustomService } from '../../../../services/economic-plan-custom.service';
import { LedgerCustomService } from '../../../../services/ledger-custom.service';

type EconomicPlanValue = EconomicPlanDetailsDto | null;
type AccountsKey = keyof Pick<
    EconomicPlanDetailsDto,
    | 'revenueAccounts'
    | 'apportionableExpenseAccounts'
    | 'expenseAccounts'
    | 'reserveFundAccounts'
    | 'individualReserveFundAccounts'
>;

@Component({
    selector: 'app-weg-economic-plan-details-tab',
    templateUrl: './weg-economic-plan-details-tab.component.html',
    styleUrls: ['./weg-economic-plan-details-tab.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WegEconomicPlanDetailsTabComponent implements OnInit, OnDestroy, OnChanges {
    @Input() public set triggerRefresh(val: unknown) {
        this.refresh$.next(null);
    }

    public refresh$ = new BehaviorSubject(null);
    public unsubscribe$ = new Subject<void>();

    @Input() public ownerships: Ownership[] | null = null;

    private economicPlanDetails$ = new BehaviorSubject<EconomicPlanValue | null>(null);

    @Input() public set economicPlanDetails(value: EconomicPlanValue | null) {
        this.economicPlanDetails$.next(value);
    }

    public economicPlan: EconomicPlanValue = null;
    public ownershipAdvancementsAmounts: OwnershipAdvancementAmountDto[] = [];
    public selectedOwnershipAdvancementAmount?: OwnershipAdvancementAmountDto;
    public selectedOwnershipId$ = new BehaviorSubject('');

    public isLoading = true;

    public accountGroups: AccountsKey[] = [
        'revenueAccounts',
        'apportionableExpenseAccounts',
        'expenseAccounts',
        'reserveFundAccounts',
        'individualReserveFundAccounts',
    ];

    public constructor(
        private economicPlansService: EconomicPlansService,
        private route: ActivatedRoute,
        private translateService: TranslateService,
        private changeDetectorRef: ChangeDetectorRef,
        private ledgerCustomService: LedgerCustomService,
        private propertiesService: PropertiesService,
        private economicPlanCustomService: EconomicPlanCustomService
    ) {}

    public translations = {
        ownership_label_entity: this.translateService.instant('ENTITIES.OWNERSHIP.LABEL_ENTITY') + ' ',
        reserve_label: this.translateService.instant('PAGES.ECONOMIC_PLAN.DETAILS_TAB.RESERVE_LABEL'),
    };

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['economicPlanDetails']) {
            this.economicPlanDetails$.next(changes['economicPlanDetails'].currentValue);
        }
    }

    public ngOnInit(): void {
        this.economicPlan$.pipe(takeUntil(this.unsubscribe$)).subscribe((economicPlan) => {
            this.economicPlan = economicPlan;
            this.isLoading = false;
            this.changeDetectorRef.detectChanges();
        });

        this.pdfList$.pipe(takeUntil(this.unsubscribe$)).subscribe();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public ledgerId$ = this.route.parent!.params.pipe(
        switchMap((params) => {
            const ledgerId = params['id'];
            return of(ledgerId);
        }),
        takeUntil(this.unsubscribe$)
    );

    public economicPlanId$ = this.route.params.pipe(
        switchMap((params) => {
            const economicPlanId = params['economicPlanId'];
            return of(economicPlanId);
        }),
        takeUntil(this.unsubscribe$)
    );

    public economicPlan$: Observable<EconomicPlanValue> = this.economicPlanDetails$.pipe(
        //  remove irrelevant accounts
        map((economicPlan: EconomicPlanValue) => {
            this.isLoading = true;
            if (economicPlan === null) {
                return null;
            }
            const economicPlanWithoutIrrelevantAccounts: EconomicPlanValue = {
                ...economicPlan,
            } as const;
            const keys = [
                'apportionableExpenseAccounts',
                'expenseAccounts',
                'individualReserveFundAccounts',
                'reserveFundAccounts',
                'revenueAccounts',
            ] as const;

            for (const key of keys) {
                economicPlanWithoutIrrelevantAccounts[key].forEach(
                    (item) =>
                        (item.accountName = item.accountName.replace(
                            'Geplante Geldmittel',
                            this.translations.reserve_label
                        ))
                );
                economicPlanWithoutIrrelevantAccounts[key] = economicPlanWithoutIrrelevantAccounts[key].filter(
                    //  item.isRelevant is not set (===undefined) for single WP Accounts, do not filter them out
                    (item) => item.isRelevant === undefined || item.isRelevant === true
                );
            }
            return economicPlanWithoutIrrelevantAccounts;
        }),
        takeUntil(this.unsubscribe$),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    public economicPlans$ = this.economicPlanCustomService.getEconomicPlans$();

    public ledger$ = this.ledgerCustomService.getLedger$();

    public property$ = this.ledger$.pipe(
        filter(Boolean),
        switchMap((x) => {
            return this.propertiesService.findOne(x.propertyId);
        })
    );

    public pdfList$ = combineLatest([this.ledgerId$, this.economicPlanId$, this.economicPlans$, this.property$]).pipe(
        map(([ledgerId, economicPlanId, economicPlans, property]) => {
            // TODO: fix issue on BE not getting startDate and endDate on EconomicPlanDetails
            // for now get data from  economicPlans

            const economicPlan = economicPlans.find((x) => x.id === economicPlanId);

            const startMonth = economicPlan?.startDate.substring(5, 7) ?? '';
            const endMonth = economicPlan?.endDate.substring(5, 7) ?? '';
            const year = economicPlan?.startDate.substring(0, 4) ?? '';

            const datesAreValid = startMonth && endMonth && year;

            const fileNamePrefix = datesAreValid ? `${startMonth}_${year}-${endMonth}_${year}_` : '';
            const fileNameBase = `Wirtschaftsplan_${property.propertyType}_${property.address.streetName}_${property.address.streetNumber}.pdf`;

            let fileName = fileNamePrefix + fileNameBase;
            fileName = fileName.split(' ').join('_');

            const pdf$ = this.economicPlansService
                .getDownloadLinkForEconomicPlanPDF(ledgerId, economicPlanId)
                .pipe(tap(() => console.log('subscribed pdf')));

            const getLink = (): Promise<{ url: string }> => {
                return lastValueFrom(pdf$);
            };

            const item: BaseDocumentsListItem = { fileName, getLink };
            return [item];
        }),
        takeUntil(this.unsubscribe$)
    );

    public get showDetailsTable(): boolean {
        if (this.ownerships === null) {
            return false;
        }
        if (this.ownerships.length < 1) {
            return false;
        }
        return true;
    }

    public get showErrorMessage(): boolean {
        if (this.ownerships === null) {
            return false;
        }
        if (this.ownerships.length < 1) {
            return true;
        }
        return false;
    }
}
