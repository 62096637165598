<div ibmRow class="tile expandable-tile-selector mb-1r pointer">
    <div class="tile__header" (click)="changeSelectDisplay()">
        <ng-container *ngIf="selectorTitle === 'Geldeingang buchen' || selectorTitle === 'Forderung eröffnen'">
            <app-headline-with-icon
                color="green"
                size="ml"
                image="40_geldeingang.svg"
                text="{{ selectorTitle }}"
            ></app-headline-with-icon>
        </ng-container>
        <ng-container *ngIf="selectorTitle === 'Geldausgang buchen'">
            <app-headline-with-icon
                color="orange"
                size="ml"
                image="40_geldausgang.svg"
                text="{{ selectorTitle }}"
            ></app-headline-with-icon>
        </ng-container>
        <ng-container *ngIf="selectorTitle === 'Mietzahlungen' || selectorTitle === 'Vorschüsse'">
            <app-headline-with-icon
                color="purple"
                size="ml"
                image="40_vorschuesse.svg"
                text="{{ selectorTitle }}"
            ></app-headline-with-icon>
        </ng-container>
        <ng-container *ngIf="selectorTitle === 'Umbuchen'">
            <app-headline-with-icon
                color="blue"
                size="ml"
                image="40_umbuchen.svg"
                text="{{ selectorTitle }}"
            ></app-headline-with-icon>
        </ng-container>
        <ng-container *ngIf="selectorTitle === 'Abgrenzen'">
            <app-headline-with-icon
                color="blue"
                size="ml"
                image="40_abgrenzen.svg"
                text="{{ selectorTitle }}"
            ></app-headline-with-icon>
        </ng-container>
        <ng-container *ngIf="selectorTitle === 'Vorratsbrennstoff'">
            <app-headline-with-icon
                color="blue"
                size="ml"
                image="40_brennstoff.svg"
                text="{{ selectorTitle }}"
            ></app-headline-with-icon>
        </ng-container>
        <ng-container *ngIf="!isKnownGroup(selectorTitle)">
            <app-headline-with-icon
                color="gray"
                size="ml"
                image="40_error.svg"
                text="{{ selectorTitle }}"
            ></app-headline-with-icon>
        </ng-container>
        <div class="tile__icon" [ngClass]="{ 'rotate-180': isSelectOpen }">
            <svg-icon src="/assets/icons/24_arrow-down.svg"></svg-icon>
        </div>
    </div>
    <ul *ngIf="isSelectOpen">
        <li
            *ngFor="let option of tileSelectorOptions; let last = last"
            class="expandable-tile-selector__option d-flex align-items-center"
            [class.mb-1r]="last"
            (click)="emitSelection(option)"
        >
            <span class="s-head-14-22-semibold">{{ option.subgroupName }}</span>
            <span class="ml-04 s-head-14-22-regular"
                >{{ option.shortDescription.length > 0 ? '-' : '' }} {{ option.shortDescription }}</span
            >
            <div class="expandable-tile-selector__tooltip-icon">
                <app-tooltip *ngIf="option.longDescription" position="left">
                    <div content class="tw-w-[400px] tw-p-8">{{ option.longDescription }}</div>
                    <div hoverable-item>
                        <svg-icon src="/assets/icons/24_information.svg"></svg-icon>
                    </div>
                </app-tooltip>
            </div>
        </li>
    </ul>
</div>
