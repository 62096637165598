<div class="tw-mt-8">
    <div class="d-flex justify-space-between">
        <app-headline-with-icon color="green" image="24_person.svg" text="Personenanzahl"></app-headline-with-icon>
        <app-scalara-button
            [variant]="'ghost'"
            type="button"
            svgName="24_add.svg"
            (click)="openAddPersonAmountOverlay()"
        >
            {{ 'CONTROLS.BUTTONS.ADD' | translate }}
        </app-scalara-button>
    </div>
    <app-table
        emptyText="COMPONENTS.PERSON_AMOUNT.EMPTY_TABLE"
        [header]="tableModel.header"
        [data]="tableModel.data"
        [itemsPerPage]="1000"
        [tableSearchId]="''"
    >
    </app-table>
</div>
<ng-template #actions let-data="data">
    <div class="w-100 text-right formated-icon">
        <div class="icon-button-receipts--transparent w-100">
            <button (click)="deletePersonAmount(data.extraData.id)">
                <svg-icon src="/assets/icons/24_delete.svg"></svg-icon>
            </button>
            <button (click)="openEditPersonAmountOverlay(data.extraData.data)">
                <svg-icon src="/assets/icons/24_edit.svg"></svg-icon>
            </button>
        </div>
    </div>
</ng-template>
