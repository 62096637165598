<ibm-loading *ngIf="isInitialLoading" [isActive]="isInitialLoading" [size]="'normal'" [overlay]="isInitialLoading">
</ibm-loading>

<div class="tw-mb-[64px] tw-mt-6 tw-flex tw-justify-between tw-items-center">
    <div class="s-head-28-36-bold tw-mb-3">
        <div class="d-flex justify-space-between">
            <span *ngIf="person$ | async as person">Hallo, {{ getNameFromPerson(person) }}! </span>
        </div>
    </div>

    <app-scalara-button (click)="openDeleteLedgerOverlay()" variant="ghost" svgName="24_delete.svg">{{
        'PAGES.CONTACT.ACCOUNTING_DASHBOARD.BUTTON_LABEL_LEDGER_DELETE' | translate
    }}</app-scalara-button>
</div>

<div *ngIf="showInitialSteps$ | async" ibmRow class="tw-mb-12">
    <div *ngIf="financeDashboardBooleans$ | async as dashboardBooleans" ibmCol [columnNumbers]="{ md: 16, lg: 16 }">
        <div class="first-steps dashboard-box-shadow tw-px-12 tw-py-8 border-1-gray pos-relative">
            <div *ngIf="isCloseButtonVisible" class="first-steps__close">
                <app-scalara-button
                    (click)="closeFirstStepsBox()"
                    variant="icon-only"
                    height="40px"
                    svgName="24_close.svg"
                ></app-scalara-button>
            </div>
            <div ibmRow class="s-head-16-24-bold tw-mb-6">
                {{ 'PAGES.CONTACT.ACCOUNTING_DASHBOARD.FIRST_STEPS_HEADLINE' | translate }}
            </div>
            <div ibmRow (click)="navigateToAccounts()" class="step">
                <div ibmCol class="tw-px-2 tw-py-4">
                    <div class="d-flex align-items-center">
                        <div class="button-icon tw-mr-4">
                            <svg-icon
                                [src]="
                                    dashboardBooleans.isBankAccountConnected
                                        ? '/assets/icons/24_closed.svg'
                                        : '/assets/icons/24_open.svg'
                                "
                                [ngClass]="dashboardBooleans.isBankAccountConnected ? 'fill-green' : 'fill-gray'"
                            ></svg-icon>
                        </div>
                        <div class="s-body-14-22-bold tw-mr-2">1.</div>
                        <div class="s-body-14-22-bold">
                            {{ 'PAGES.CONTACT.ACCOUNTING_DASHBOARD.FIRST_STEPS_1_TITLE' | translate }}
                        </div>
                    </div>
                    <div ibmRow>
                        <div class="tw-ml-[77px]">
                            {{ 'PAGES.CONTACT.ACCOUNTING_DASHBOARD.FIRST_STEPS_1_TEXT' | translate }}
                        </div>
                    </div>
                </div>
                <div class="ml-auto button-icon fill-black d-flex align-items-center tw-mx-4" variant="ghost">
                    <svg-icon src="/assets/icons/24_arrow-right.svg"></svg-icon>
                </div>
            </div>
            <div ibmRow (click)="navigateToAccountCharts()" class="step">
                <div ibmCol class="tw-px-2 tw-py-4">
                    <div class="d-flex align-items-center">
                        <div class="button-icon tw-mr-4">
                            <svg-icon
                                [src]="
                                    dashboardBooleans.isAccountPlanEdited
                                        ? '/assets/icons/24_closed.svg'
                                        : '/assets/icons/24_open.svg'
                                "
                                [ngClass]="dashboardBooleans.isAccountPlanEdited ? 'fill-green' : 'fill-gray'"
                            ></svg-icon>
                        </div>
                        <div class="s-body-14-22-bold tw-mr-2">2.</div>
                        <div class="s-body-14-22-bold">
                            {{ 'PAGES.CONTACT.ACCOUNTING_DASHBOARD.FIRST_STEPS_2_TITLE' | translate }}
                        </div>
                    </div>
                    <div ibmRow>
                        <div class="tw-ml-[77px]">
                            {{ 'PAGES.CONTACT.ACCOUNTING_DASHBOARD.FIRST_STEPS_2_TEXT' | translate }}
                        </div>
                    </div>
                </div>
                <div class="ml-auto button-icon fill-black d-flex align-items-center tw-mr-4" variant="ghost">
                    <svg-icon src="/assets/icons/24_arrow-right.svg"></svg-icon>
                </div>
            </div>
            <div ibmRow (click)="openOpeningBalanceOverlay()" class="step">
                <div ibmCol class="tw-px-2 tw-py-4">
                    <div class="d-flex align-items-center">
                        <div class="button-icon tw-mr-4">
                            <svg-icon
                                [src]="
                                    dashboardBooleans.isExistingOpeningBalance
                                        ? '/assets/icons/24_closed.svg'
                                        : '/assets/icons/24_open.svg'
                                "
                                [ngClass]="dashboardBooleans.isExistingOpeningBalance ? 'fill-green' : 'fill-gray'"
                            ></svg-icon>
                        </div>
                        <div class="s-body-14-22-bold tw-mr-2">3.</div>
                        <div class="s-body-14-22-bold">
                            {{ 'PAGES.CONTACT.ACCOUNTING_DASHBOARD.FIRST_STEPS_3_TITLE' | translate }}
                        </div>
                    </div>
                    <div ibmRow>
                        <div class="tw-ml-[77px]">
                            {{ 'PAGES.CONTACT.ACCOUNTING_DASHBOARD.FIRST_STEPS_3_TEXT' | translate }}
                        </div>
                    </div>
                </div>
                <div class="ml-auto button-icon fill-black d-flex align-items-center tw-mr-4" variant="ghost">
                    <svg-icon src="/assets/icons/24_arrow-right.svg"></svg-icon>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="balance" ibmRow class="tw-mb-6">
    <div ibmCol>
        <div class="balance-box tile expandable-tile-selector">
            <div ibmRow class="tile__header" (click)="changeBalanceBoxDisplay()">
                <div ibmCol>
                    <div ibmRow>
                        <div ibmCol [columnNumbers]="{ md: 11, lg: 11 }" class="s-label-12-22-semibold">
                            {{ 'ENTITIES.ANNUAL_STATEMENT.LABEL_TOTAL_AVAILABLE_FUNDS' | translate }}
                        </div>
                        <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }" class="s-label-12-22-semibold p-0 text-right">
                            {{ 'ACCOUNTING.COMMON.AMOUNT' | translate }}
                        </div>
                        <div
                            ibmCol
                            [columnNumbers]="{ md: 2, lg: 2 }"
                            class="p-0 tw-pl-4 d-flex justify-content-center"
                        >
                            <div *ngIf="balance.bankAccounts.length === 0 || balance.ledgerAccounts.length === 0">
                                <app-icon-bubble
                                    [size]="40"
                                    [color]="'gray'"
                                    [image]="'24_unselect.svg'"
                                ></app-icon-bubble>
                            </div>
                            <div
                                *ngIf="
                                    balance.bankAccounts.length &&
                                    balance.ledgerAccounts.length &&
                                    balance.totalAmountBankAccounts !== balance.totalAmountLedgerAccounts
                                "
                            >
                                <app-icon-bubble
                                    [size]="40"
                                    [color]="'orange'"
                                    [image]="'24_attention.svg'"
                                ></app-icon-bubble>
                            </div>
                            <div
                                *ngIf="
                                    balance.bankAccounts.length &&
                                    balance.ledgerAccounts.length &&
                                    balance.totalAmountBankAccounts === balance.totalAmountLedgerAccounts
                                "
                            >
                                <app-icon-bubble
                                    [size]="40"
                                    [color]="'green'"
                                    [image]="'24_checkbox-check-green.svg'"
                                ></app-icon-bubble>
                            </div>
                        </div>
                    </div>
                    <div ibmRow>
                        <div ibmCol [columnNumbers]="{ md: 11, lg: 11 }" class="s-head-16-24-bold">
                            {{ 'PAGES.CONTACT.ACCOUNTING_DASHBOARD.BALANCE_BOX_BANK_ACCOUNTS' | translate }}
                        </div>
                        <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }" class="s-head-16-24-bold p-0 text-right">
                            {{ balance.bankAccounts.length > 0 ? (balance.totalAmountBankAccounts | eurocent) : '–' }}
                        </div>
                        <div ibmCol [columnNumbers]="{ md: 2, lg: 2 }"></div>
                    </div>
                </div>
                <div ibmCol>
                    <div ibmRow class="mb-1r">
                        <div ibmCol [columnNumbers]="{ md: 11, lg: 11 }" class="s-label-12-22-semibold">
                            {{ 'ENTITIES.ANNUAL_STATEMENT.LABEL_TOTAL_AVAILABLE_FUNDS' | translate }}
                        </div>
                        <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }" class="s-label-12-22-semibold p-0 text-right">
                            {{ 'ACCOUNTING.COMMON.AMOUNT' | translate }}
                        </div>
                        <div
                            ibmCol
                            [columnNumbers]="{ md: 2, lg: 2 }"
                            class="p-0 tw-pl-4 d-flex justify-content-center"
                        >
                            <div class="tile__icon" [ngClass]="{ 'rotate-180': isBalanceBoxOpen }">
                                <svg-icon src="/assets/icons/24_arrow-down.svg"></svg-icon>
                            </div>
                        </div>
                    </div>
                    <div ibmRow>
                        <div ibmCol [columnNumbers]="{ md: 11, lg: 11 }" class="s-head-16-24-bold">
                            {{ 'PAGES.CONTACT.ACCOUNTING_DASHBOARD.BALANCE_BOX_LEDGER_ACCOUNTS' | translate }}
                        </div>
                        <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }" class="s-head-16-24-bold p-0 text-right">
                            {{
                                balance.ledgerAccounts.length > 0 ? (balance.totalAmountLedgerAccounts | eurocent) : '–'
                            }}
                        </div>
                        <div ibmCol [columnNumbers]="{ md: 2, lg: 2 }"></div>
                    </div>
                </div>
            </div>
            <div *ngIf="isBalanceBoxOpen" class="d-flex tile__body">
                <div ibmCol>
                    <div ibmRow *ngFor="let account of balance.bankAccounts" class="tw-mb-4">
                        <div ibmCol [columnNumbers]="{ md: 11, lg: 11 }">
                            {{ account.accountName }}
                        </div>
                        <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }" class="p-0 text-right">
                            {{ account.accountBalance | eurocent }}
                        </div>
                        <div ibmCol [columnNumbers]="{ md: 2, lg: 2 }"></div>
                    </div>
                </div>
                <div ibmCol>
                    <div ibmRow *ngFor="let account of balance.ledgerAccounts" class="tw-mb-4">
                        <div ibmCol [columnNumbers]="{ md: 11, lg: 11 }">
                            {{ account.accountName }}
                        </div>
                        <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }" class="p-0 text-right">
                            {{ account.accountBalance | eurocent }}
                        </div>
                        <div ibmCol [columnNumbers]="{ md: 2, lg: 2 }"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div ibmRow class="tw-mb-6" *ngIf="financeNumbers">
    <div ibmCol [columnNumbers]="{ md: 8, lg: 8 }">
        <app-dashboard-due-open-items></app-dashboard-due-open-items>
    </div>
    <div ibmCol [columnNumbers]="{ md: 8, lg: 8 }">
        <div class="dashboard-box-shadow sales-and-bookings tw-px-8 tw-py-6 border-1-gray">
            <span class="s-label-12-22-semibold tw-mb-4 s-green-01 letter-spacing-1-16px">
                {{ ('PAGES.CONTACT.ACCOUNTING_DASHBOARD.PENDING_BOX_TITLE' | translate).toUpperCase() }}
            </span>
            <div (click)="navigateToBankTransactions()" class="d-flex align-items-center">
                <div class="s-head-28-36-bold tw-mr-6 align-items-center px-0 tw-py-2">
                    {{ financeNumbers.notBookedBankTransactions }}
                </div>
                <div class="s-body-14-22-regular">
                    {{ 'PAGES.CONTACT.ACCOUNTING_DASHBOARD.UNBOOKED_TRANSACTION' | translate }}
                </div>
                <div class="ml-auto button-icon fill-black" variant="ghost">
                    <svg-icon src="/assets/icons/24_arrow-right.svg"></svg-icon>
                </div>
            </div>
            <div (click)="navigateToBookingsRecommendations()" class="d-flex align-items-center border-top-1-gray">
                <div class="s-head-28-36-bold tw-mr-6 align-items-center px-0 tw-py-2">
                    {{ financeNumbers.bookingRecommendations }}
                </div>
                <div class="s-body-14-22-regular">
                    {{ 'PAGES.CONTACT.ACCOUNTING_DASHBOARD.SUGGESTED_BOOKINGS' | translate }}
                </div>
                <div class="ml-auto button-icon fill-black" variant="ghost">
                    <svg-icon src="/assets/icons/24_arrow-right.svg"></svg-icon>
                </div>
            </div>
            <div (click)="navigateToBookingRule()" class="d-flex align-items-center border-top-1-gray">
                <div class="s-head-28-36-bold tw-mr-6 align-items-center px-0 tw-py-2">
                    {{ financeNumbers.bookingRuleRecommendations }}
                </div>
                <div class="s-body-14-22-regular">
                    {{ 'PAGES.CONTACT.ACCOUNTING_DASHBOARD.BOOKING_RULE_RECOMENDATIONS' | translate }}
                </div>
                <div class="ml-auto button-icon fill-black" variant="ghost">
                    <svg-icon src="/assets/icons/24_arrow-right.svg"></svg-icon>
                </div>
            </div>
            <div (click)="navigateToPaymentApprovals()" class="d-flex align-items-center border-top-1-gray">
                <div class="s-head-28-36-bold tw-mr-6 align-items-center px-0 tw-py-2">
                    {{ financeNumbers.pendingPayments }}
                </div>
                <div class="s-body-14-22-regular">
                    {{ 'PAGES.PAYMENT_APPROVALS.LABEL_WAITING_PAYMENTS' | translate }}
                </div>
                <div class="ml-auto button-icon fill-black" variant="ghost">
                    <svg-icon src="/assets/icons/24_arrow-right.svg"></svg-icon>
                </div>
            </div>
            <div (click)="navigateToPaymentsIndex()" class="d-flex align-items-center border-top-1-gray">
                <div class="s-head-28-36-bold tw-mr-6 align-items-center px-0 tw-py-2">
                    {{ financeNumbers.incompletePayments }}
                </div>
                <div class="s-body-14-22-regular">
                    {{ 'PAGES.PAYMENT_APPROVALS.LABEL_INCOMPLETE_PAYMENTS' | translate }}
                </div>
                <div class="ml-auto button-icon fill-black" variant="ghost">
                    <svg-icon src="/assets/icons/24_arrow-right.svg"></svg-icon>
                </div>
            </div>
        </div>
    </div>
</div>
