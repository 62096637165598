<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<app-overlay-container [ignoreHeadline]="true">
    <div class="tw-mb-10 mb-2-5r tw-col-grid">
        <div *ngIf="!editingDetails && !editingImage && !uploadingImage" [ngClass]="['s-head-28-36-bold']">
            {{ 'PAGES.PROPERTY.ADD_PROPERTY.LABEL_HEADLINE' | translate }}
        </div>
        <div *ngIf="editingDetails" [ngClass]="['s-head-28-36-bold']">
            {{ 'PAGES.PROPERTY.ADD_PROPERTY.LABEL_HEADLINE_EDIT' | translate }}
        </div>
        <div *ngIf="editingImage || uploadingImage">
            <div class="headline-wrapper tw-flex">
                <div class="mr-06 headline-wrapper__icon">
                    <div class="icon-container icon-container--72">
                        <svg-icon src="/assets/icons/40_upload.svg"></svg-icon>
                    </div>
                </div>
                <div class="headline-wrapper__title">
                    <div class="s-head-28-36-bold" *ngIf="editingImage">
                        {{ 'PAGES.PROPERTY.ADD_PROPERTY.LABEL_HEADLINE_IMAGE_EDIT' | translate }}
                    </div>
                    <div class="s-head-28-36-bold" *ngIf="uploadingImage">
                        {{ 'PAGES.PROPERTY.ADD_PROPERTY.LABEL_IMAGE_ADD' | translate }}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="tw-mb-14" *ngIf="!editingImage && !uploadingImage">
        <div>
            <app-tag [color]="getTag().color" size="s">{{ propertyType }}</app-tag>
            <span class="ml-0-75r s-head-16-24-bold s-gray-01">{{ getTag().label }}</span>
        </div>
    </div>

    <div>
        <div class="tw-mb-[60px]" [formGroup]="propertyForm" *ngIf="!editingImage && !uploadingImage">
            <!-- START ADRESS -->
            <div class="tw-mb-[60px]">
                <div class="tw-flex tw-space-x-8 tw-w-full">
                    <div class="tw-w-full">
                        <app-scalara-input-address-autocomplete
                            formControlName="streetName"
                            label="{{ 'ENTITIES.ADDRESS.LABEL_STREET_NAME' | translate }} *"
                            [invalid]="isInvalid('streetName')"
                            invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                            (autocompletePlaceSelected)="onAutocompletePlaceSelected($event)"
                        ></app-scalara-input-address-autocomplete>
                    </div>
                    <ibm-label [invalid]="isInvalid('streetNumber')" invalidText="{{ 'ERROR.REQUIRED' | translate }}">
                        {{ 'ENTITIES.ADDRESS.LABEL_STREET_NUMBER' | translate }}&nbsp;<span>*</span>
                        <input
                            ibmText
                            name="streetNumber"
                            id="streetNumber"
                            formControlName="streetNumber"
                            [invalid]="isInvalid('streetNumber')"
                        />
                    </ibm-label>
                </div>

                <div *ngIf="!editingImage && !uploadingImage">
                    <ibm-label>
                        {{ 'ENTITIES.ADDRESS.LABEL_STREET_ADDITION' | translate }}
                        <input ibmText name="streetAddition" id="streetAddition" formControlName="streetAddition" />
                    </ibm-label>
                </div>

                <div class="tw-grid tw-grid-cols-2 tw-gap-8" *ngIf="!editingImage && !uploadingImage">
                    <ibm-label [invalid]="isInvalid('zipCode')" invalidText="{{ 'ERROR.REQUIRED' | translate }}">
                        {{ 'ENTITIES.ADDRESS.LABEL_ZIP_CODE' | translate }}&nbsp;<span>*</span>
                        <input
                            ibmText
                            name="zipCode"
                            id="zipCode"
                            formControlName="zipCode"
                            [invalid]="isInvalid('zipCode')"
                        />
                    </ibm-label>
                    <ibm-label [invalid]="isInvalid('area')" invalidText="{{ 'ERROR.REQUIRED' | translate }}">
                        {{ 'ENTITIES.ADDRESS.LABEL_AREA' | translate }}&nbsp;<span>*</span>
                        <input ibmText name="area" id="area" formControlName="area" [invalid]="isInvalid('area')" />
                    </ibm-label>
                </div>

                <div *ngIf="!editingImage && !uploadingImage">
                    <div>
                        <ibm-select
                            [invalid]="isInvalid('country')"
                            invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                            label="{{ 'ENTITIES.ADDRESS.LABEL_COUNTRY' | translate }} *"
                            name="country"
                            id="country"
                            formControlName="country"
                        >
                            <option *ngFor="let country of countries" [value]="country.code">
                                {{ country.name }}
                            </option>
                        </ibm-select>
                    </div>
                </div>

                <div *ngIf="!editingImage && !uploadingImage && !editingDetails">
                    <div>
                        <ibm-label [invalid]="isInvalid('name')" invalidText="{{ 'ERROR.REQUIRED' | translate }}">
                            <app-text-with-tooltip
                                class="tw-inline-block"
                                [label]="'PAGES.PROPERTY.ADD_PROPERTY.LABEL_BUILDING_NAME' | translate"
                                [labelClass]="''"
                                [tooltipKey]="tooltipKey.tooltipsInProperties"
                                [tooltipInnerHtml]="getTooltipI18Key('BUILDING_NAME') | translate"
                            ></app-text-with-tooltip>
                            <input
                                placeholder="z.B. Haus 1, Haus 2"
                                ibmText
                                name="name"
                                id="name"
                                formControlName="name"
                                [invalid]="isInvalid('name')"
                            />
                        </ibm-label>
                    </div>
                </div>

                <!-- Internal Description-->
                <div *ngIf="!editingImage && !uploadingImage" class="mb-2r">
                    <ibm-label>
                        <app-text-with-tooltip
                            class="tw-inline-block"
                            [label]="'PAGES.PROPERTY.ADD_PROPERTY.LABEL_PROPERTY_NAME_INTERNAL' | translate"
                            [labelClass]="''"
                            [tooltipKey]="tooltipKey.tooltipsInProperties"
                            [tooltipInnerHtml]="getTooltipI18Key('NAME_INTERNAL') | translate"
                        ></app-text-with-tooltip>
                        <input
                            ibmText
                            name="internalPropertyName"
                            id="internalPropertyName"
                            formControlName="internalPropertyName"
                        />
                    </ibm-label>
                </div>
            </div>

            <!-- </ng-container> -->
            <!-- END ADRESS -->

            <!-- START PROPERTY DETAILS -->
            <div class="tw-s-head-14-22-bold tw-mb-6">Grundstücksangaben</div>

            <ibm-label [invalid]="isInvalid('plotArea')" invalidText="{{ 'ERROR.REQUIRED' | translate }}">
                Grundstücksfläche in qm
                <input
                    ibmText
                    [invalid]="isInvalid('plotArea')"
                    name="plotArea"
                    id="plotArea"
                    formControlName="plotArea"
                />
            </ibm-label>

            <div class="tw-grid tw-grid-cols-2 tw-gap-8" *ngIf="!editingImage && !uploadingImage">
                <ibm-label [invalid]="isInvalid('district')" invalidText="{{ 'ERROR.REQUIRED' | translate }}">
                    Gemarkung
                    <input
                        ibmText
                        name="district"
                        id="district"
                        formControlName="district"
                        [invalid]="isInvalid('district')"
                    />
                </ibm-label>
                <ibm-label
                    [invalid]="isInvalid('consecutiveNumberOfProperties')"
                    invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                >
                    Laufende Nummer der Grundstücke
                    <input
                        ibmText
                        type="number"
                        name="consecutiveNumberOfProperties"
                        id="consecutiveNumberOfProperties"
                        formControlName="consecutiveNumberOfProperties"
                        [invalid]="isInvalid('consecutiveNumberOfProperties')"
                    />
                </ibm-label>
            </div>

            <div class="tw-grid tw-grid-cols-2 tw-gap-8" *ngIf="!editingImage && !uploadingImage">
                <ibm-label [invalid]="isInvalid('cadastralDistrict')" invalidText="{{ 'ERROR.REQUIRED' | translate }}">
                    Flur
                    <input
                        ibmText
                        name="cadastralDistrict"
                        id="cadastralDistrict"
                        formControlName="cadastralDistrict"
                        [invalid]="isInvalid('cadastralDistrict')"
                    />
                </ibm-label>
                <ibm-label [invalid]="isInvalid('landParcel')" invalidText="{{ 'ERROR.REQUIRED' | translate }}">
                    Flurstück
                    <input
                        ibmText
                        name="landParcel"
                        id="landParcel"
                        formControlName="landParcel"
                        [invalid]="isInvalid('landParcel')"
                    />
                </ibm-label>
            </div>

            <ibm-label [invalid]="isInvalid('economicType')" invalidText="{{ 'ERROR.REQUIRED' | translate }}">
                Wirtschaftsart der Lage
                <input
                    ibmText
                    name="economicType"
                    id="economicType"
                    formControlName="economicType"
                    [invalid]="isInvalid('economicType')"
                />
            </ibm-label>
        </div>
        <!-- END PROPERTY DETAILS -->

        <!-- START UPLOAD PHOTO-->
        <div *ngIf="!editingDetails || editingImage || uploadingImage">
            <div [ngClass]="{ 'd-none': showDragArea() }">
                <div>
                    <app-file-input
                        inputLabel="{{ 'PAGES.PROPERTY.ADD_PROPERTY.LABEL_IMAGE_ADD_LONG' | translate }}"
                        [acceptFormats]="['.png', '.jpg']"
                        (updateUploadedFile)="fileChangeEvent($event)"
                    ></app-file-input>

                    <div *ngIf="!isImageValid" class="mt-04 mb-04">
                        <div class="">
                            <app-notification type="error" subtitle="{{ 'ERROR.IMAGE' | translate }}">
                            </app-notification>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="mb-04">
                    <image-cropper
                        [imageChangedEvent]="imageChangedEvent"
                        [maintainAspectRatio]="true"
                        [aspectRatio]="4 / 3"
                        format="jpeg"
                        (imageCropped)="imageCropped($event)"
                        (loadImageFailed)="loadImageFailed()"
                        [alignImage]="'left'"
                        [resizeToHeight]="379"
                        [resizeToWidth]="505"
                    ></image-cropper>
                </div>
            </div>
            <div [ngClass]="{ 'd-none': !imageChangedEvent }">
                <div>
                    <button [ibmButton]="'ghost'" type="reset" (click)="resetImageUploader()">
                        {{ 'PAGES.PROPERTY.ADD_PROPERTY.LABEL_IMAGE_REMOVE' | translate }}
                    </button>
                </div>
            </div>
        </div>
        <!-- END UPLOAD ROW-->

        <!-- START BUTTON ROW -->
        <div class="tw-mt-16 tw-flex tw-justify-between">
            <app-scalara-button (click)="abort()" variant="outline">{{
                'CONTROLS.BUTTONS.CANCEL' | translate
            }}</app-scalara-button>

            <app-scalara-button [disabled]="isFormInvalid()" (click)="onSubmit()">{{
                'CONTROLS.BUTTONS.SAVE' | translate
            }}</app-scalara-button>
        </div>
        <!-- END BUTTON ROW -->

        <div class="p-10"></div>
    </div>
</app-overlay-container>
