import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, finalize, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { formatDateYYYYMMDDWithoutHours } from 'src/app/core/utils/common';
import {
    CreateOesDto,
    FullOesDto,
    OperationsExpenseStatementsService,
    SimpleOesDto,
} from 'src/app/generated-sources/accounting';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';

@Component({
    selector: 'app-add-operations-expense-statements',
    templateUrl: './add-operations-expense-statements.component.html',
    styleUrls: ['./add-operations-expense-statements.component.scss'],
})
export class AddOperationsExpenseStatementsComponent extends OverlayChildComponent implements OnInit {
    private unsubscribe$ = new Subject<void>();
    public existStatementForPeriod = false;
    public showConfirmationPage = false;
    public isLoading = false;
    public ledgerId = '';
    public operationsExpenseStatements: SimpleOesDto[] = [];

    public dateRangeForm: UntypedFormGroup = this.formBuilder.group({
        startDate: [null, [Validators.required]],
        endDate: [null, [Validators.required]],
    });

    public constructor(
        private formBuilder: UntypedFormBuilder,
        private operationsExpenseStatementsService: OperationsExpenseStatementsService,
        private toastService: ToastService,
        private translateService: TranslateService,
        private router: Router
    ) {
        super();
    }

    public ngOnInit(): void {
        this.ledgerId = this.config?.data.ledgerId;
        this.operationsExpenseStatements = this.config?.data.operationsExpenseStatements;
    }

    public abort(): void {
        if (this.showConfirmationPage) {
            this.showConfirmationPage = false;
        } else {
            this.cancelEmitter$.next();
        }
    }

    public nextPage(): void {
        const thereIsSamePeriod = this.operationsExpenseStatements.find((item) => {
            const startDate = new Date(item.startDate).getTime();
            const endDate = new Date(item.endDate).getTime();

            let selectedStartDate = this.dateRangeForm.get('startDate')?.value;
            let selectedEndDate = this.dateRangeForm.get('endDate')?.value;

            if (selectedStartDate && selectedEndDate) {
                selectedStartDate = new Date(selectedStartDate).getTime();
                selectedEndDate = new Date(selectedEndDate).getTime();

                return (
                    (startDate < selectedStartDate && endDate > selectedStartDate) ||
                    (startDate > selectedStartDate && startDate < selectedEndDate)
                );
            }
            return false;
        });
        if (thereIsSamePeriod) {
            this.existStatementForPeriod = true;
        } else {
            this.existStatementForPeriod = false;
            this.showConfirmationPage = true;
        }
    }

    public submit(): void {
        if (
            this.dateRangeForm.invalid ||
            this.isDateInvalid() ||
            !this.dateRangeForm.get('startDate')?.value ||
            !this.dateRangeForm.get('endDate')?.value
        ) {
            return;
        }
        this.isLoading = true;

        const createOESDto: CreateOesDto = {
            startDate: formatDateYYYYMMDDWithoutHours(this.dateRangeForm.get('startDate')?.value[0]),
            endDate: formatDateYYYYMMDDWithoutHours(this.dateRangeForm.get('endDate')?.value[0]),
        };

        this.operationsExpenseStatementsService
            .create(this.ledgerId, createOESDto)
            .pipe(
                finalize(() => (this.isLoading = false)),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: (oesDto: FullOesDto) => {
                    this.toastService.showSuccess(
                        this.translateService.instant(
                            'PAGES.OPERATIONS_EXPENSE_STATEMENTS.ADD_OPERATIONS_EXPENSE_STATEMENTS.SUCCESS_TOAST'
                        )
                    );
                    this.saveEmitter$.next();

                    this.router.navigate([
                        '/accounting',
                        'ledger',
                        this.ledgerId,
                        'operations-expense-statements',
                        oesDto.id,
                    ]);
                },
                error: (error: Error) => this.toastService.showError(error.message),
            });
    }

    public correspondsDays(): number {
        let startDate = this.dateRangeForm.get('startDate')?.value;
        let endDate = this.dateRangeForm.get('endDate')?.value;

        if (startDate && endDate) {
            startDate = new Date(startDate);
            endDate = new Date(endDate);

            const oneDay = 24 * 60 * 60 * 1000;
            const diffInMilliseconds = Math.abs(endDate - startDate);

            return Math.floor(diffInMilliseconds / oneDay) + 1;
        }

        return 0;
    }

    public isDateInvalid(): boolean {
        let startDate = this.dateRangeForm.get('startDate')?.value;
        let endDate = this.dateRangeForm.get('endDate')?.value;

        if (startDate && endDate) {
            startDate = new Date(startDate).getTime();
            endDate = new Date(endDate).getTime();

            return endDate < startDate;
        }

        return false;
    }
}
