import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ListItem } from 'carbon-components-angular';
import { Subject, switchMap, takeUntil, tap } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import {
    DistributionUpdateDto,
    DistributionUpdateItem,
    OesDistributionKeyDto,
    OperationsExpenseStatementsService,
} from 'src/app/generated-sources/accounting';

@Component({
    selector: 'app-add-account-distribution-costs',
    templateUrl: './add-account-distribution-costs.component.html',
    styleUrls: ['./add-account-distribution-costs.component.scss'],
})
export class AddAccountDistributionCostsComponent implements OnInit {
    public allOesDistributionsKeys: OesDistributionKeyDto[] = [];
    public isLoading = false;
    public listDistributionKeys: ListItem[] = [];
    public form: UntypedFormGroup = this.formBuilder.group({
        accounts: this.formBuilder.array([]),
    });

    @Input() public ledgerId = '';
    @Input() public oesId = '';
    @Output() public submitAndContinue = new EventEmitter<void>();
    @Output() public submitAndClose = new EventEmitter<void>();
    @Output() public closePage = new EventEmitter<void>();

    private unsubscribe$ = new Subject<void>();

    public constructor(
        private operationsExpenseStatementsService: OperationsExpenseStatementsService,
        private formBuilder: UntypedFormBuilder,
        private toastService: ToastService
    ) {}

    public ngOnInit(): void {
        this.isLoading = true;

        this.operationsExpenseStatementsService
            .findAllDistributionKeys(this.ledgerId, this.oesId)
            .pipe(
                switchMap((oesDistributionKeys) => {
                    this.allOesDistributionsKeys = oesDistributionKeys;
                    this.listDistributionKeys = oesDistributionKeys.map((item) => {
                        return {
                            content: item.description,
                            value: item.id,
                            selected: false,
                        };
                    });
                    return this.operationsExpenseStatementsService.getCostDistribution(this.ledgerId, this.oesId);
                }),
                tap((accounts) => {
                    accounts.items.map((account) => {
                        const selectedDistributionKey = this.listDistributionKeys.find((item, index) => {
                            if (item['value'] === account.distributionKeyId) {
                                this.listDistributionKeys[index].selected = true;
                                return true;
                            }
                            return false;
                        });
                        this.accountsControl.push(
                            this.formBuilder.group({
                                distributionKeysList: [this.listDistributionKeys],
                                distributionKeyId: [selectedDistributionKey || null],
                                accountName: [account.accountName],
                                accountDescription: [account.accountDescription],
                                accountId: [account.accountId],
                                totalDistribution: [account.distributionAmount],
                            })
                        );
                    });
                    this.isLoading = false;
                })
            )
            .subscribe();
    }

    public get accountsControl(): UntypedFormArray {
        return this.form.controls['accounts'] as UntypedFormArray;
    }

    public getAccountItemForm(index: number): UntypedFormGroup {
        return this.accountsControl.at(index) as UntypedFormGroup;
    }

    public loadDistributionKeys(): void {}

    public abort(): void {
        this.closePage.emit();
    }

    public submit(saveAndClose = false): void {
        this.isLoading = true;
        const itemsCostDistribution: DistributionUpdateItem[] = this.accountsControl?.value
            ?.filter((item: any) => !!item.distributionKeyId?.value)
            .map((item: any) => {
                return {
                    accountId: item.accountId,
                    distributionKeyId: item.distributionKeyId.value,
                };
            });
        const oesCostDistributionUpdateDto: DistributionUpdateDto = {
            items: itemsCostDistribution,
        };

        this.operationsExpenseStatementsService
            .updateCostDistribution(this.ledgerId, this.oesId, oesCostDistributionUpdateDto)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: () => {
                    if (saveAndClose) {
                        this.submitAndClose.emit();
                    } else {
                        this.submitAndContinue.emit();
                    }
                    this.isLoading = false;
                },
                error: (error) => {
                    this.isLoading = false;
                    if (error) {
                        this.toastService.showError(error.error['message']);
                    }
                },
            });
    }

    public isInvalidForm(): boolean {
        return false;
    }

    public sumTotalDistribution(): number {
        let sumTotal = 0;
        this.accountsControl.value?.map((item: any) => {
            sumTotal = sumTotal + item.totalDistribution || 0;
        });
        return sumTotal;
    }
}
