<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<div class="mb-2-5r mt-1-5r d-flex justify-space-between">
    <div>
        <ibm-breadcrumb [items]="breadcrumbs" [noTrailingSlash]="true"> </ibm-breadcrumb>
    </div>
    <app-scalara-button
        (click)="openDeleteOpenItemOverlay()"
        *ngIf="openItem.state === 'OPEN' && this.openItemType !== 'ADVANCEMENT'"
        variant="ghost"
        type="button"
        svgName="24_delete.svg"
        >{{ 'PAGES.OPEN_ITEMS.DELETE_OPEN_ITEM_BUTTON' | translate }}</app-scalara-button
    >
</div>

<div *ngIf="!isLoading" class="open-item-details">
    <div>
        <div class="s-head-14-22-bold mb-05">
            {{ pageTitle || ('ACCOUNTING.OPEN-ITEMS.DETAILS.OPEN_ITEM_DETAILS' | translate) }}
        </div>
        <app-open-item-details-card
            [openItem]="openItem"
            [additionalOpenItemData]="additionalOpenItemData"
            [openItemType]="openItemType"
        ></app-open-item-details-card>
    </div>

    <div class="mt-10" *ngIf="receipts">
        <div class="mb-0-75r s-body-14-22-bold">{{ 'PAGES.RECEIPTS.HEADLINE' | translate }}</div>
        <app-receipts-table [receipts]="receipts"></app-receipts-table>
    </div>

    <div class="m1-10">
        <div class="s-body-14-22-bold mb-03">{{ 'ACCOUNTING.OPEN-ITEMS.CONNECTED_BOOKING' | translate }}</div>
        <app-booking-table
            *ngIf="openItem.bookings"
            [bookings]="openItemBookings"
            searchId="tabelOpenBookings12"
            [showOpenItemAmount]="true"
        ></app-booking-table>
    </div>
</div>
