<div class="tw-flex tw-justify-between tw-mb-6">
    <app-headline-with-icon
        color="green"
        image="24_gemeinschaftseigentum.svg"
        text="Gemeinschaftseigentum"
    ></app-headline-with-icon>

    <app-scalara-button
        *ngIf="isPropertyManager"
        variant="ghost"
        svgName="24_add.svg"
        (click)="openAddEditCommonOwnershipOverlay()"
    >
        {{ 'CONTROLS.BUTTONS.ADD' | translate }}
    </app-scalara-button>
</div>

<app-table
    emptyText="PAGES.PROPERTY_BASE_DATA_COMMON_OWNERSHIP.LABEL_EMPTY_TABLE"
    [header]="tableModel.header"
    [data]="tableModel.data"
    (rowClick)="openCommonOwnershipDetailsOverlay($event)"
>
</app-table>
