<div *ngIf="isLoading" class="is-loading">
    <div class="d-flex align-items-center justify-content-center mt-4r">
        <ibm-inline-loading> </ibm-inline-loading>
    </div>
</div>

<div class="mt-2r mb-2r">
    <app-scalara-button [variant]="'ghost'" type="button" svgName="24_arrow-left.svg" (click)="backPage()">
        Übersicht aller Pläne
    </app-scalara-button>
</div>

<ng-container *ngIf="selectedOwnership">
    <ng-container *ngIf="selectedOwnership$ | async as selectedOwnership">
        <ng-container *ngIf="economicPlan">
            <div class="overview-box s-body-14-22-regular">
                <div class="s-head-16-24-bold">
                    {{ 'PAGES.ECONOMIC_PLAN.DETAILS_TAB.OVERVIEW_BOX.HEADING_PREFIX' | translate }} –
                    {{ selectedOwnership.name }}
                </div>
                <ng-container
                    *ngIf="
                        economicPlan.differingAdvancement !== undefined && economicPlan.differingAdvancement !== null
                    "
                >
                    <div class="d-flex justify-space-between pb-1r mt-2-5r">
                        <span>{{
                            'PAGES.ECONOMIC_PLAN.DETAILS_TAB.OVERVIEW_BOX.CALCULATED_AMOUNT_LABEL' | translate
                        }}</span>
                        <span> {{ economicPlan.totalAmountAdvancement | eurocent }} € </span>
                    </div>
                    <div class="d-flex justify-space-between border-bottom pb-2r">
                        <span>{{
                            'PAGES.ECONOMIC_PLAN.DETAILS_TAB.OVERVIEW_BOX.ADJUSTED_AMOUNT_LABEL' | translate
                        }}</span>
                        <span class="s-body-14-22-bold">
                            <span *ngIf="economicPlan.differingAdvancement === 0 || !economicPlan.differingAdvancement"
                                >0,00&nbsp;€</span
                            >
                            <span *ngIf="economicPlan.differingTotalAmountAdvancement > 0">
                                {{ economicPlan.differingTotalAmountAdvancement | eurocent }}&nbsp;€
                            </span>
                        </span>
                    </div>
                </ng-container>
                <ng-container
                    *ngIf="
                        economicPlan.differingAdvancement === undefined || economicPlan.differingAdvancement === null
                    "
                >
                    <div class="d-flex justify-space-between border-bottom pb-2r mt-2-5r">
                        <span>{{ 'PAGES.ECONOMIC_PLAN.DETAILS_TAB.OVERVIEW_BOX.SUBHEADING' | translate }}</span>
                        <span class="s-body-14-22-bold"> {{ economicPlan.totalAmountAdvancement | eurocent }} € </span>
                    </div>
                </ng-container>
                <div class="mt-2r mb-1-5r">
                    {{ 'PAGES.ECONOMIC_PLAN.DETAILS_TAB.OVERVIEW_BOX.FOLLOWING_RESULTS' | translate }}
                </div>

                <div
                    *ngFor="let item of this.getOverviewRows(economicPlan)"
                    class="d-flex justify-space-between mb-0-75r"
                >
                    <span>{{ item[0] }}</span>
                    <span>{{ item[1] }}</span>
                </div>

                <div class="d-flex justify-space-between mt-1r border-top pt-1r">
                    <span class="s-body-14-22-bold">{{
                        'PAGES.ECONOMIC_PLAN.DETAILS_TAB.OVERVIEW_BOX.AMOUNT_TO_PAY' | translate
                    }}</span>

                    <div class="d-flex flex-nowrap">
                        <span class="d-flex flex-nowrap">
                            {{ 'PAGES.ECONOMIC_PLAN.EDIT.LABEL_DAY_OF_THE_MONTH' | translate : { day: maturity } }}
                            <span
                                >,&nbsp;
                                {{ 'PAGES.ECONOMIC_PLAN.EDIT.LABEL_' + economicPlan.rateInterval | translate }}</span
                            >
                        </span>
                        <span class="pl-0-75r s-body-14-22-bold">
                            {{
                                (economicPlan.differingAdvancement
                                    ? economicPlan.differingAdvancement
                                    : economicPlan.advancement
                                ) | eurocent
                            }}&nbsp;€
                        </span>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</ng-container>

<ng-container *ngIf="economicPlan">
    <div class="tw-pl-2 tw-pb-20"><app-base-documents-list [items]="pdfList"></app-base-documents-list></div>
</ng-container>

<ng-container *ngIf="economicPlan">
    <app-weg-economic-plan-details-table
        economicPlanType="single"
        [economicPlan]="economicPlan"
        [isLoading]="isLoading"
    ></app-weg-economic-plan-details-table>
</ng-container>
