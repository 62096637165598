import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { SharedOwnership, SharedOwnershipService } from 'src/app/generated-sources/base';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';

@Component({
    selector: 'app-delete-shared-ownership-overlay',
    templateUrl: './delete-shared-ownership-overlay.component.html',
    styleUrls: ['./delete-shared-ownership-overlay.component.scss'],
})
export class DeleteSharedOwnershipOverlayComponent extends OverlayChildComponent implements OnInit, OnDestroy {
    public sharedOwnershipToDelete?: SharedOwnership;
    public propertyId = '';
    public isLoading = false;

    public unsubscribe$ = new Subject<void>();

    public constructor(
        private toastService: ToastService,
        private translateService: TranslateService,
        private sharedOwnershipService: SharedOwnershipService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.sharedOwnershipToDelete = this.config?.data.sharedOwnership;
        this.propertyId = this.config?.data.propertyId;
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public submit(): void {
        if (!this.propertyId || !this.sharedOwnershipToDelete) {
            return;
        }

        this.isLoading = true;
        this.sharedOwnershipService
            .removeSharedOwnership(
                this.propertyId,
                this.sharedOwnershipToDelete.buildingInfo.id,
                this.sharedOwnershipToDelete.id
            )
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: () => {
                    this.toastService.showSuccess(
                        this.translateService.instant('PAGES.DELETE_COMMON_OWNERSHIP.SUCCESS_TOAST')
                    );
                    this.saveEmitter$.next();
                    this.isLoading = false;
                },
                error: (error) => {
                    if (error) {
                        this.toastService.showError(error.error['message']);
                    }
                    this.isLoading = false;
                },
            });
    }
}
