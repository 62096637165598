import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ListItem } from 'carbon-components-angular';
import { BehaviorSubject, Subject, combineLatest, filter, firstValueFrom, switchMap, takeUntil, tap } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { formControl, formControlHasError, formatDateYYYYMMDDWithoutHours } from 'src/app/core/utils/common';
import { formatDateDDMMYYYY } from 'src/app/core/utils/dateUtils';
import {
    BankAccountDto,
    BankAccountsService,
    BankTransactionDto,
    BookingDto,
    BookingItemDto,
    LedgerBankAccountSummaryDto,
    LedgerDto,
    LedgersService,
    SupportDashboardService,
    WegSettlementStatementsService,
    WssDto,
} from 'src/app/generated-sources/accounting';
import { Occupation, OccupationService } from 'src/app/generated-sources/base';
import { CellTemplate } from 'src/app/shared/table/enums/cell-template';
import { HeaderTemplate } from 'src/app/shared/table/enums/header-template';
import { TableItem } from 'src/app/shared/table/interfaces/table-item';
import { TableModel } from 'src/app/shared/table/interfaces/table-model';
import { LedgerCustomService } from '../services/ledger-custom.service';

@Component({
    selector: 'app-ledger-reasonability-checks',
    templateUrl: './ledger-reasonability-checks.component.html',
    styleUrls: ['./ledger-reasonability-checks.component.scss'],
})
export class LedgerReasonabilityChecksComponent implements OnInit, OnDestroy {
    // public ledgerId: string | undefined;
    public bankAccountStartAmountFormGroup: FormGroup = new FormGroup({});
    private unsubscribe$ = new Subject<void>();
    public refresh$ = new BehaviorSubject<void>(undefined);
    public wSSItemList$ = new Subject<ListItem[]>();
    public bankAccountItemList$ = new Subject<ListItem[]>();
    public bankAccountListForLedger?: BankAccountDto[];
    public bankAccountList?: BankAccountDto[];
    public occupationList?: Occupation[];
    public selectedWSSid?: string;
    public bankAccountStartAmountDate = new Date();
    public selectedBankAccount?: string;
    public bankAccountStartAmount?: number;
    public ledger?: LedgerDto;
    public activeTab: 'bookings' | 'wssRange' | 'actions' | 'bankAccounts' = 'bookings';
    public isLoading = false;
    public bookingsTableData: BookingTablesData = {
        bookingDateTransactionDate: { data: [], header: [] },
        bookingsWithoutTranscations: { data: [], header: [] },
        initalBookingsOfReserves: { data: [], header: [] },
        receivableBookingDateCheck: { data: [], header: [] },
        bookingsWithoutSameYearTransactions: { data: [], header: [] },
    };
    public transactionsTableData: TransactionTablesData = {
        transactionsWithoutBookings: { data: [], header: [] },
        wronglyIgronedTransactions: { data: [], header: [] },
        adjustmentBookings: { data: [], header: [] },
    };
    public bankAccountYearlyAmounts: TableModel = { data: [], header: [] };

    public ledgerBankAccountSummaryHeader = [
        'ACCOUNTING.REASONABILITY_CHECK.YEAR',
        'ACCOUNTING.REASONABILITY_CHECK.BANK_ACCOUNT',
        {
            data: { key: 'ACCOUNTING.REASONABILITY_CHECK.START_AMOUNT', params: {} },
            template: HeaderTemplate.RightAligned,
        },
        {
            data: { key: 'ACCOUNTING.REASONABILITY_CHECK.END_AMOUNT', params: {} },
            template: HeaderTemplate.RightAligned,
        },
    ];

    public bookingAndTransactionHeader = [
        'ACCOUNTING.REASONABILITY_CHECK.DATE',
        'ACCOUNTING.REASONABILITY_CHECK.DESCRIPTION',
        {
            data: { key: 'ACCOUNTING.REASONABILITY_CHECK.AMOUNT', params: {} },
            template: HeaderTemplate.RightAligned,
        },
    ];

    public onSubmitSetBankaccountStartAmount = async (
        amount: number,
        bankAccountId: string,
        startDate: string
    ): Promise<void> => {
        try {
            firstValueFrom(
                await this.supportDashboardService.createFakeBalance({
                    bankAccountId,
                    date: formatDateYYYYMMDDWithoutHours(startDate),
                    newBalance: amount,
                })
            );
            this.toastService.showSuccess('Neues Startsaldo gesetzt!');
            this.refresh$.next();
        } catch (error) {
            if (error) {
                this.toastService.showError((error as HttpErrorResponse).error['message']);
            }
        }
    };
    public onSubmitAddBankaccount = async (bankAccountId: string): Promise<void> => {
        if (this.ledger?.id) {
            try {
                firstValueFrom(
                    await this.supportDashboardService.linkBankAccountToLedger({
                        bankAccountId,
                        ledgerId: this.ledger?.id,
                    })
                );
                this.toastService.showSuccess('Bankkonto verknüpft!');
                this.refresh$.next(undefined);
            } catch (error) {
                if (error) {
                    this.toastService.showError((error as HttpErrorResponse).error['message']);
                }
            }
        }
    };

    public onSubmitDeleteRentReceivables = async (
        occupationId: string,
        startDate: string,
        endDate: string
    ): Promise<void> => {
        try {
            firstValueFrom(
                await this.supportDashboardService.deleteRentReceivablesForOccupation({
                    occupationId,
                    start_date: formatDateYYYYMMDDWithoutHours(startDate),
                    end_date: formatDateYYYYMMDDWithoutHours(endDate),
                })
            );
            this.toastService.showSuccess('Mietforderungen gelöscht');
            this.refresh$.next();
        } catch (error) {
            if (error) {
                this.toastService.showError((error as HttpErrorResponse).error['message']);
            }
        }
    };

    public onSubmitRegenerateRentReceivables = async (occupationId: string): Promise<void> => {
        if (this.ledger?.propertyId) {
            try {
                firstValueFrom(
                    await this.ledgersService.createOrUpdateOccupationAccounts({
                        occupationId,
                        propertyId: this.ledger?.propertyId,
                    })
                );
                this.toastService.showSuccess('Mietforderungen gelöscht');
                this.refresh$.next();
            } catch (error) {
                if (error) {
                    this.toastService.showError((error as HttpErrorResponse).error['message']);
                }
            }
        }
    };

    public ledger$ = this.ledgerCustomService.getLedger$();

    public ledgerTemp$ = combineLatest([this.ledger$, this.refresh$]).pipe(
        filter(Boolean),
        switchMap(async ([ledger]) => {
            if (ledger === null) {
                return;
            }
            this.ledger = ledger;
            this.isLoading = true;

            this.bookingsTableData.bookingDateTransactionDate = this.setTableData(
                await firstValueFrom(
                    this.supportDashboardService.getSupportInfoBookings(this.ledger.id, 'BOOKING_DATE_TRANSACTION_DATE')
                )
            );
            this.bookingsTableData.bookingsWithoutTranscations = this.setTableData(
                await firstValueFrom(
                    this.supportDashboardService.getSupportInfoBookings(this.ledger.id, 'BOOKINGS_WITHOUT_TRANSACTIONS')
                )
            );
            if (this.ledger.type === 'WEG') {
                const bookings = await firstValueFrom(
                    this.supportDashboardService.getSupportInfoBookings(this.ledger.id, 'INITIAL_BOOKINGS_OF_RESERVES')
                );
                if (bookings[0].items) {
                    this.bookingsTableData.initalBookingsOfReserves = this.setTableData(bookings[0].items);
                }
            }

            this.bookingsTableData.receivableBookingDateCheck = this.setTableData(
                await firstValueFrom(
                    this.supportDashboardService.getSupportInfoBookings(this.ledger.id, 'RECEIVABLE_BOOKING_DATE_CHECK')
                )
            );
            this.transactionsTableData.wronglyIgronedTransactions = this.setTableData(
                await firstValueFrom(
                    this.supportDashboardService.getSupportInfoTransactions(
                        this.ledger.id,
                        'WRONGLY_IGNORED_TRANSACTIONS'
                    )
                )
            );
            this.transactionsTableData.adjustmentBookings = this.setTableData(
                await firstValueFrom(
                    this.supportDashboardService.getSupportInfoTransactions(
                        this.ledger.id,
                        'FAKE_BALANCE_ADJUSTING_TRANSACTIONS'
                    )
                )
            );
            try {
                this.bankAccountYearlyAmounts = this.setTableBankData(
                    await firstValueFrom(this.supportDashboardService.getLedgerAccountsSummary(this.ledger.id))
                );
            } catch (error) {
                //done
            }

            this.isLoading = false;
            return ledger;
        }),
        tap((ledger) => {
            this.isLoading = false;
        })
    );

    public wssStatements$ = combineLatest([this.ledger$, this.refresh$]).pipe(
        switchMap(async ([ledger]) => {
            if (ledger?.id) {
                this.updateWssListItems(await firstValueFrom(this.wSSService.findAll(ledger?.id)));
            }
            return [];
        }),
        takeUntil(this.unsubscribe$),
        tap(() => (this.isLoading = false))
    );

    public bankAccounts$ = combineLatest([this.ledger$, this.refresh$]).pipe(
        switchMap(async ([ledger]) => {
            if (ledger?.id) {
                this.bankAccountListForLedger = await firstValueFrom(this.bankAccountService.findAll(ledger?.id));
                this.bankAccountList = await firstValueFrom(this.bankAccountService.findAll());
            }
            return [];
        }),
        takeUntil(this.unsubscribe$),
        tap(() => (this.isLoading = false))
    );

    public occupations$ = combineLatest([this.ledger$, this.refresh$]).pipe(
        switchMap(async ([ledger]) => {
            if (ledger?.id && (ledger.type === 'MV' || ledger.type == 'SEV')) {
                this.occupationList = await firstValueFrom(
                    this.occupationService.findAllOccupations(ledger?.propertyId)
                );
                console.log(this.occupationList);
            }
            return [];
        }),
        takeUntil(this.unsubscribe$),
        tap(() => (this.isLoading = false))
    );

    public constructor(
        private ledgerCustomService: LedgerCustomService,
        private translateService: TranslateService,
        private wSSService: WegSettlementStatementsService,
        private bankAccountService: BankAccountsService,
        private readonly occupationService: OccupationService,
        private formBuilder: FormBuilder,
        private supportDashboardService: SupportDashboardService,
        private toastService: ToastService,
        private ledgersService: LedgersService
    ) {}

    public ngOnInit(): void {
        // this.refresh$.next(undefined);
        this.ledgerTemp$.subscribe();
        this.wssStatements$.subscribe();
        this.bankAccounts$.subscribe();
        this.occupations$.subscribe();
        this.bankAccountStartAmountFormGroup = this.formBuilder.group({
            bankAccountStartAmount: new FormControl(this.bankAccountStartAmount ?? 0, [
                Validators.required,
                Validators.min(1),
                Validators.max(9007199254740991),
                Validators.pattern('^[0-9]*$'),
            ]),
            bankAccountStartAmountDate: [this.bankAccountStartAmountDate, Validators.required],
            selectedBankAccount: [this.selectedBankAccount, Validators.required],
        });
        this.bankAccountStartAmountFormGroup.updateValueAndValidity();
    }

    public isInvalid(controlName: string): boolean {
        if (this.bankAccountStartAmountFormGroup) {
            return formControlHasError(formControl(this.bankAccountStartAmountFormGroup, controlName), 'required');
        }
        return false;
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public async onSelectedWSS($event: any): Promise<void> {
        this.isLoading = true;
        this.selectedWSSid = $event.item['value'];
        if (this.ledger) {
            this.transactionsTableData.transactionsWithoutBookings = this.setTableData(
                await firstValueFrom(
                    this.supportDashboardService.getSupportInfoTransactions(
                        this.ledger.id,
                        'TRANSACTIONS_WITHOUT_BOOKINGS',
                        this.selectedWSSid
                    )
                )
            );
            this.bookingsTableData.bookingsWithoutSameYearTransactions = this.setTableData(
                await firstValueFrom(
                    this.supportDashboardService.getSupportInfoBookings(
                        this.ledger.id,
                        'BOOKINGS_WITHOUT_SAME_YEAR_TRANSACTIONS',
                        this.selectedWSSid
                    )
                )
            );
        }
        this.isLoading = false;
    }

    public onSelectedBankAccount($event: any): void {
        this.selectedBankAccount = $event.item['value'];
        this.bankAccountStartAmountFormGroup;
    }

    private setTableData(
        data: BookingDto[] | BankTransactionDto[] | BookingItemDto[] | LedgerBankAccountSummaryDto[]
    ): TableModel {
        return {
            header: this.bookingAndTransactionHeader,
            data: data.map((obj) => {
                return this.createTableRow(obj);
            }),
        };
    }
    private setTableBankData(data: LedgerBankAccountSummaryDto[]): TableModel {
        return {
            header: this.ledgerBankAccountSummaryHeader,
            data: data.map((obj) => {
                return this.createTableRow(obj);
            }),
        };
    }

    private updateWssListItems(wssList: WssDto[]): void {
        this.wSSItemList$.next(
            wssList.map((wss) => {
                return {
                    content: `${formatDateDDMMYYYY(wss.economicPlan.startDate)} - ${formatDateDDMMYYYY(
                        wss.economicPlan.endDate
                    )}`,
                    value: wss.id,
                    selected: false,
                    id: wss.id,
                };
            })
        );
    }

    public onChangebankAccountStartAmountDateDate($event: any): void {
        this.bankAccountStartAmountDate = $event[0];
        console.log(this.bankAccountStartAmountDate);
    }

    private createTableRow(
        data: BookingDto | BankTransactionDto | BookingItemDto | LedgerBankAccountSummaryDto
    ): TableItem[] {
        let link: string;
        const obj = { date: '', description: '', amount: 0, secoundAmount: 0 };
        if (isOfType<BankTransactionDto>(data, 'valueDate')) {
            obj.date = data.valueDate;
            obj.description = data.purpose;
            obj.amount = data.amount;
            link = `/accounting/ledger/${data.id}/bank-accounts/${data.bankAccountId}`;
        } else if (isOfType<BookingItemDto>(data, 'bookingId')) {
            obj.date = data.bookingDate;
            obj.description = data.account.name;
            obj.amount = (data.credit ? data.credit : data.debit) ?? 0;
            link = `/accounting/ledger/${this.ledger?.id}/bookings/${data.bookingId}`;
        } else if (isOfType<LedgerBankAccountSummaryDto>(data, 'year')) {
            obj.date = data.year.toString();
            obj.description = data.iban;
            obj.amount = data.beginningAmount;
            obj.secoundAmount = data.endAmount;
            link = ``;
        } else {
            data = data as BookingDto;
            obj.date = data.bookingDate;
            obj.description = data.description;
            obj.amount = data.amount;
            link = `/accounting/ledger/${this.ledger?.id}/bookings/${data.id}`;
        }
        const tableData: TableItem[] = [];

        if (isOfType<LedgerBankAccountSummaryDto>(data, 'year')) {
            tableData.push({
                data: {
                    label: obj.date,
                    link,
                },
                template: CellTemplate.Default,
            });
        } else {
            tableData.push({
                data: {
                    label: obj.date,
                    link,
                },
                template: CellTemplate.Date,
            });
        }
        tableData.push(
            {
                data: {
                    label: obj.description,
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: obj.amount,
                    link,
                },
                template: CellTemplate.EuroCent,
            }
        );
        if (isOfType<LedgerBankAccountSummaryDto>(data, 'year')) {
            tableData.push({
                data: {
                    label: obj.secoundAmount,
                    link,
                },
                template: CellTemplate.EuroCent,
            });
            console.log(tableData);
        }

        return tableData;
    }
}

type BookingTablesData = {
    bookingDateTransactionDate: TableModel;
    bookingsWithoutTranscations: TableModel;
    initalBookingsOfReserves: TableModel;
    receivableBookingDateCheck: TableModel;
    bookingsWithoutSameYearTransactions: TableModel;
};

type TransactionTablesData = {
    transactionsWithoutBookings: TableModel;
    wronglyIgronedTransactions: TableModel;
    adjustmentBookings: TableModel;
};

export function isOfType<T>(anyObject: any, key: string): anyObject is T {
    return anyObject[key] !== undefined;
}

export type OnSubmitSetBankaccountStartAmountCallback = (
    amount: number,
    bankAccountId: string,
    startDate: string
) => void;

export type onSubmitAddBankaccountCallback = (bankAccountId: string) => void;

export type onSubmitDeleteRentReceivablesCallback = (occupationId: string, startDate: string, endDate: string) => void;

export type onSubmitRegenerateRentReceivablesCallback = (occupationId: string) => void;
