import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    BehaviorSubject,
    Subject,
    combineLatest,
    filter,
    firstValueFrom,
    shareReplay,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs';
import { getLocalISOTime, getNameFromPerson } from 'src/app/core/utils/common';
import { PersonLocalService } from 'src/app/features/property/services/person-local.service';
import {
    BalanceComparisonDto,
    DashboardsService,
    FinanceNumbersDto,
    LedgerDto,
    LedgerStatusInfoDto,
    LedgersService,
} from 'src/app/generated-sources/accounting';
import { Person } from 'src/app/generated-sources/base/model/person';
import { Property } from 'src/app/generated-sources/base/model/property';
import {
    RemoveEntityOverlayComponent,
    RemoveEntityOverlayComponentConfig,
} from 'src/app/shared/components/remove-entity-overlay/remove-entity-overlay.component';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { OpeningBalanceComponent } from '../opening-balance/opening-balance.component';
import { AccountingFilterCustomService } from '../services/accounting-filter-custom.service';
import { LedgerCustomService } from '../services/ledger-custom.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class AccountingDashboardComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();
    public refresh$ = new BehaviorSubject(null);

    public isInitialLoading = false;
    public isBalanceBoxOpen = false;
    public showInitialSteps$ = new BehaviorSubject<boolean>(false);
    public isCloseButtonVisible = false;
    public isBalanceOk = false;
    public ledger?: LedgerDto;
    public suggestedBookings = 23;
    public balance?: BalanceComparisonDto;
    public statusInfo?: LedgerStatusInfoDto;
    public financeNumbers?: FinanceNumbersDto;

    public today = new Date().toDateString();

    public person$ = this.personLocalService.getPerson$().pipe(takeUntil(this.unsubscribe$));

    public ledger$ = this.ledgerCustomService.getLedger$().pipe(
        filter(Boolean),
        takeUntil(this.unsubscribe$),
        tap((ledger) => (this.ledger = ledger))
    );

    public financeDashboardNumbers$ = combineLatest([this.ledger$, this.refresh$]).pipe(
        switchMap(([ledger, refresh]) => this.dashboardsService.getFinanceDashboardNumbers(ledger.id)),
        takeUntil(this.unsubscribe$),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    public financeDashboardBooleans$ = combineLatest([this.ledger$, this.refresh$]).pipe(
        switchMap(([ledger, refresh]) => this.dashboardsService.getAllDashboardBooleans(ledger.id)),
        takeUntil(this.unsubscribe$),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    public balanceComparison$ = combineLatest([this.ledger$, this.refresh$]).pipe(
        switchMap(([ledger, refresh]) => this.dashboardsService.getBalanceComparison(ledger.id)),
        takeUntil(this.unsubscribe$),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    public checkCookie$ = this.ledger$.pipe(
        tap((ledger) => {
            if (ledger) {
                this.checkCookie(ledger.id);
            }
        }),
        takeUntil(this.unsubscribe$)
    );

    public firstStep$ = combineLatest([this.ledger$, this.financeDashboardBooleans$]).pipe(
        takeUntil(this.unsubscribe$)
    );

    public balanceAndFinanceNumbers$ = combineLatest([
        this.ledger$,
        this.financeDashboardNumbers$,
        this.balanceComparison$,
    ]).pipe(
        tap(([ledger, financeDashboardNumbers, balanceComparison]) => {
            this.isInitialLoading = false;
            this.financeNumbers = financeDashboardNumbers;
            this.balance = balanceComparison;
        }),
        takeUntil(this.unsubscribe$)
    );

    public constructor(
        private router: Router,
        private overlayService: OverlayService,
        private dashboardsService: DashboardsService,
        private personLocalService: PersonLocalService,
        private ledgerCustomService: LedgerCustomService,
        private accountingFilterCustomService: AccountingFilterCustomService,
        private route: ActivatedRoute,
        private ledgersService: LedgersService
    ) {}

    public ngOnInit(): void {
        this.today = getLocalISOTime(new Date());
        this.isInitialLoading = true;
        this.balanceAndFinanceNumbers$.subscribe();
        this.person$.subscribe();
        this.checkCookie$.subscribe();
    }

    public changeBalanceBoxDisplay(): void {
        this.isBalanceBoxOpen = !this.isBalanceBoxOpen;
    }

    public checkCookie(ledgerId: string): void {
        const ledgerEntry = this.getLedgerCookieEntry(ledgerId);
        this.showInitialSteps$.next(true);

        if (ledgerEntry) {
            if (ledgerEntry && ledgerEntry.status === 'hidden') {
                this.showInitialSteps$.next(false);
                return;
            } else if (ledgerEntry && ledgerEntry.hidingButtonDate) {
                const hidingButtonDate = new Date(ledgerEntry.hidingButtonDate);
                const today = new Date();
                today.setHours(0, 0, 0, 0);

                if (hidingButtonDate <= today) {
                    this.isCloseButtonVisible = true;
                }

                this.firstStep$.subscribe(([ledger, financeDashboardBooleans]) => {
                    if (
                        financeDashboardBooleans.isBankAccountConnected &&
                        financeDashboardBooleans.isAccountPlanEdited &&
                        financeDashboardBooleans.isExistingOpeningBalance
                    ) {
                        this.isCloseButtonVisible = true;

                        if (hidingButtonDate <= today) {
                            this.showInitialSteps$.next(false);
                        }
                    }
                });
            }
        } else {
            this.firstStep$.subscribe(([ledger, financeDashboardBooleans]) => {
                if (
                    financeDashboardBooleans.isBankAccountConnected &&
                    financeDashboardBooleans.isAccountPlanEdited &&
                    financeDashboardBooleans.isExistingOpeningBalance
                ) {
                    this.isCloseButtonVisible = true;

                    const initialDays = 10;
                    const d = new Date();
                    d.setTime(d.getTime() + initialDays * 24 * 60 * 60 * 1000);
                    this.updateLedgerCookieEntry(ledgerId, 'hidingButtonDate', d.toUTCString().replace(',', ''));
                } else {
                    this.updateLedgerCookieEntry(ledgerId, 'hidingButtonDate', '');
                }
                this.updateLedgerCookieEntry(ledgerId, 'status', '');
            });
        }
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public navigateToAccountCharts(): void {
        this.router.navigate(['/accounting', 'ledger', this.ledger?.id, 'account-charts']);
    }

    public navigateToBankTransactions(): void {
        this.accountingFilterCustomService.resetDatesToCurrentYear();
        this.router.navigate(['/accounting', 'ledger', this.ledger?.id, 'bank-transactions']);
    }

    public navigateToBookingRule(): void {
        this.accountingFilterCustomService.resetDatesToCurrentYear();
        this.router.navigate(['/accounting', 'ledger', this.ledger?.id, 'bank-transactions'], {
            state: { openBookingRuleSuggestionOnInit: true },
        });
    }

    public navigateToBookingsRecommendations(): void {
        this.accountingFilterCustomService.resetDatesToCurrentYear();
        this.router.navigate(['/accounting', 'ledger', this.ledger?.id, 'bank-transactions'], {
            state: { openSuggestedTabOnInit: true },
        });
    }

    public navigateToPaymentApprovals(): void {
        this.router.navigate(['/accounting', 'ledger', this.ledger?.id, 'payment-approvals'], {});
    }

    public navigateToPaymentsIndex(): void {
        this.router.navigate(['/accounting', 'ledger', this.ledger?.id, 'payments'], {
            state: { openDirectDebitTabOnInit: true },
        });
    }

    public navigateToAccounts(): void {
        this.router.navigate(['/user-management', 'accounts'], {});
    }

    public openOpeningBalanceOverlay(): void {
        const isLedgerMVorSEV =
            this.ledger?.type === Property.PropertyTypeEnum.Mv || this.ledger?.type === Property.PropertyTypeEnum.Sev;

        const ref = this.overlayService.open(OpeningBalanceComponent, {
            data: { ledgerId: this.ledger!.id, isLedgerMVorSEV: isLedgerMVorSEV },
        });
        ref.saveEmitter$.subscribe(() => {
            this.refresh$.next(null);
        });
        ref.cancelEmitter$.subscribe(() => {
            ref.close();
        });
    }

    public getNameFromPerson(person: Person): string {
        return getNameFromPerson(person);
    }

    public openDeleteLedgerOverlay(): void {
        const data: RemoveEntityOverlayComponentConfig = {
            data: {
                entityName: (this.ledger?.property as Property).name,
                entityType: 'ACCOUNTING',
                deleteEntityCallback: async () => {
                    await firstValueFrom(this.ledgersService.deleteLedger(this.ledger?.id ?? ''));
                },
            },
        };
        const ref = this.overlayService.open(RemoveEntityOverlayComponent, data);
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            ref.close();
        });

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.ledgerCustomService.refresh();
            this.router.navigate(['/accounting'], { relativeTo: this.route });
        });
    }

    //handle cookie
    public closeFirstStepsBox(): void {
        this.showInitialSteps$.next(false);

        if (this.ledger?.id) {
            this.updateLedgerCookieEntry(this.ledger.id, 'status', 'hidden');
        }
    }

    private getLedgerCookieEntry(ledgerId: string): any {
        const cookies = decodeURIComponent(document.cookie).split(';');
        const dashboardCookie = cookies.find((cookie) => cookie.trim().startsWith('dashboard-first-steps-card='));
        let cookieArray = [];

        if (dashboardCookie) {
            const cookieValue = dashboardCookie.split('=')[1].trim();
            try {
                cookieArray = JSON.parse(cookieValue);
                if (!Array.isArray(cookieArray)) {
                    throw new Error('Invalid cookie format');
                }
            } catch (e) {
                console.warn('Invalid cookie format detected, resetting cookie 2');
                document.cookie = 'dashboard-first-steps-card=[]; Path=/;';
                return null;
            }

            return cookieArray.find((entry: any) => entry.ledgerId === ledgerId);
        }

        return null;
    }

    private updateLedgerCookieEntry(ledgerId: string, key: string, value: string): void {
        const cookies = decodeURIComponent(document.cookie).split(';');
        const dashboardCookie = cookies.find((cookie) => cookie.trim().startsWith('dashboard-first-steps-card='));
        let cookieArray = [];

        if (dashboardCookie) {
            const cookieValue = dashboardCookie.split('=')[1].trim();
            try {
                cookieArray = JSON.parse(cookieValue);
                if (!Array.isArray(cookieArray)) {
                    throw new Error('Invalid cookie format');
                }
            } catch (e) {
                console.warn('Invalid cookie format detected, resetting cookie 1');
                cookieArray = [];
                document.cookie = 'dashboard-first-steps-card=[]; Path=/;';
            }
        }

        let ledgerEntry = cookieArray.find((entry: any) => entry.ledgerId === ledgerId);
        if (ledgerEntry) {
            ledgerEntry[key] = value;
        } else {
            ledgerEntry = { ledgerId: ledgerId, hidingButtonDate: '', status: '' };
            ledgerEntry[key] = value;
            cookieArray.push(ledgerEntry);
        }

        document.cookie = `dashboard-first-steps-card=${JSON.stringify(cookieArray)}; Path=/`;
    }
}
