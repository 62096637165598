import { Component, Input } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { OperationsExpenseStatementsService } from 'src/app/generated-sources/accounting';

@Component({
    selector: 'app-delete-oes-occupation-distribution',
    templateUrl: './delete-oes-occupation-distribution.component.html',
    styleUrls: ['./delete-oes-occupation-distribution.component.scss'],
})
export class DeleteOesOccupationDistributionComponent {
    @Input() public data = {
        accountId: '',
        ledgerId: '',
        occupationId: '',
        oesId: '',
        excluded: false,
        refreshTable: (): void => console.log(),
    };
    private unsubscribe$ = new Subject<void>();

    public constructor(
        private operationsExpenseStatementsService: OperationsExpenseStatementsService,
        private toastService: ToastService
    ) {}

    public deleteAccount(): void {
        this.operationsExpenseStatementsService
            .excludeAccount(this.data.ledgerId, this.data.oesId, this.data.occupationId, this.data.accountId)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: () => {
                    this.toastService.showSuccess('Kosten erfolgreich entfernt.');
                    this.data.refreshTable();
                },
                error: (error: Error) => this.toastService.showError(error.message),
            });
    }

    public includeAccount(): void {
        this.operationsExpenseStatementsService
            .includeAccount(this.data.ledgerId, this.data.oesId, this.data.occupationId, this.data.accountId)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: () => {
                    this.data.refreshTable();
                },
                error: (error: Error) => this.toastService.showError(error.message),
            });
    }
}
