import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, finalize, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { accountTypeNameFromEnum, formControl, formControlHasError } from '../../../../core/utils/common';
import {
    AccountDto,
    AccountsService,
    DuplicateAccountDto,
    UpdateAccountDto,
} from '../../../../generated-sources/accounting';

@Component({
    selector: 'app-add-account-form',
    templateUrl: './add-account-form.component.html',
    styleUrls: ['./add-account-form.component.scss'],
})
export class AddAccountFormComponent extends OverlayChildComponent implements OnInit {
    public isLoading = false;
    public isEditing = false;
    private accountId = '';
    private ledgerId = '';
    public account?: AccountDto;
    public type = '';
    public form: UntypedFormGroup = new UntypedFormGroup({});
    private unsubscribe$ = new Subject<void>();
    private toastSuccessMessage?: string;

    public constructor(
        private overlayService: OverlayService,
        private translateService: TranslateService,
        private formBuilder: UntypedFormBuilder,
        private accountsService: AccountsService,
        private toastService: ToastService,
        private router: Router
    ) {
        super();
    }

    public ngOnInit(): void {
        this.isLoading = true;

        this.isEditing = this.config?.data.isEditing;
        this.accountId = this.config?.data?.extraData?.account?.id;
        this.ledgerId = this.config?.data?.extraData?.ledgerId;
        this.account = this.config?.data.extraData?.account;

        this.form = this.createForm();

        if (this.account) {
            this.type = accountTypeNameFromEnum(this.account);
        }

        if (this.account?.type === 'EXPENSE' && this.account?.betrkvPosition) {
            this.form.addControl('apportionable', new UntypedFormControl(this.account?.apportionable ? 'true' : 'false'));
        }

        this.isLoading = false;

        this.toastSuccessMessage = this.translateService.instant(
            this.isEditing
                ? 'ACCOUNTING.DUPLICATE_ACCOUNT_FORM.UPDATE_ACCOUNT_SUCCESS_NOTIFICATION'
                : 'ACCOUNTING.DUPLICATE_ACCOUNT_FORM.DUPLICATE_ACCOUNT_SUCCESS_NOTIFICATION'
        );
    }

    public createForm(): UntypedFormGroup {
        if (this.isEditing) {
            return this.formBuilder.group({
                description: [this.config?.data?.extraData?.account?.description],
            });
        } else {
            return this.formBuilder.group({
                description: [null, [Validators.required]],
            });
        }
    }

    public isInvalid(controlName: string): boolean {
        return formControlHasError(formControl(this.form, controlName), 'incorrect');
    }

    public isFormValid(): boolean {
        return this.form.valid;
    }

    public duplicateAccount(): void {
        const createAccountDto: DuplicateAccountDto = {
            description: this.form.get('description')!.value,
            apportionable:
                this.account?.type === 'EXPENSE' && this.account?.betrkvPosition
                    ? this.form.get('apportionable')!.value === 'true'
                    : null,
        };

        this.accountsService
            .duplicateAccount(this.ledgerId, this.accountId, createAccountDto)
            .pipe(
                takeUntil(this.unsubscribe$),
                finalize(() => (this.isLoading = false))
            )
            .subscribe({
                next: (account: AccountDto) => {
                    if (this.config?.data?.componentParent === 'account-details') {
                        this.router.navigate(['/accounting', 'ledger', this.ledgerId, 'account-charts', account.id]);
                    }
                    this.toastService.showSuccess(this.toastSuccessMessage);
                    this.saveEmitter$.next();
                },
                error: (error) => {
                    this.form.controls['description'].setErrors({ incorrect: true });
                },
            });
    }

    public updateAccount(): void {
        const updateAccountDto: UpdateAccountDto = {
            description: this.form.get('description')!.value,
            apportionable:
                this.account?.type === 'EXPENSE' && this.account?.betrkvPosition
                    ? this.form.get('apportionable')!.value === 'true'
                    : null,
        };

        this.accountsService
            .update(this.ledgerId, this.accountId, updateAccountDto)
            .pipe(
                takeUntil(this.unsubscribe$),
                finalize(() => (this.isLoading = false))
            )
            .subscribe({
                next: (account: AccountDto) => {
                    if (this.config?.data?.componentParent === 'account-details') {
                        this.router.navigate(['/accounting', 'ledger', this.ledgerId, 'account-charts', account.id]);
                    }
                    this.toastService.showSuccess(this.toastSuccessMessage);
                    this.saveEmitter$.next();
                },
                error: (error) => {
                    this.form.controls['description'].setErrors({ incorrect: true });
                },
            });
    }

    public onSubmit(): void {
        if (!this.isFormValid()) {
            return;
        }

        this.isLoading = true;

        if (this.isEditing) {
            this.updateAccount();
        } else {
            this.duplicateAccount();
        }
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }
}
