<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"></ibm-loading>

<app-overlay-container
    headlineTitle="PAGES.RELATIONS.RESIDENTS.LABEL_ADD_RESIDENT"
    containerSize="small"
    image="40_add-person.svg"
    colorIcon="green"
    [sizeIcon]="72"
>
    <div class="tw-mb-6">
        <div class="tw-s-head-16-24-bold tw-mb-8">{{ 'PAGES.RELATIONS.RESIDENTS.LABEL_SELF_USE' | translate }}</div>

        <ibm-radio-group (change)="onRadioSelfUseChange($event)" orientation="vertical" aria-label="radiogroup">
            <ibm-radio value="true">Ja</ibm-radio>
            <ibm-radio value="false" [checked]="true">Nein</ibm-radio>
        </ibm-radio-group>
    </div>

    <ng-container *ngIf="selfUse$ | async; else notSelfUse">
        <div class="tw-mb-6">
            <app-combo-box
                size="xl"
                id="person"
                placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT_2' | translate }}"
                [appendInline]="true"
                label="Aktuelle Eigentümer*"
                [items]="(currentOwnersList | async) ?? []"
                (selected)="onSelectCurrentOwner($event)"
                invalidText="{{ 'ERROR.REQUIRED' | translate }}"
            >
                <ibm-dropdown-list></ibm-dropdown-list>
            </app-combo-box>
        </div>
    </ng-container>

    <ng-template #notSelfUse>
        <div class="pb-5r">
            <div class="tw-s-head-16-24-bold tw-mb-8">Person auswählen</div>
            <div class="tw-grid tw-grid-cols-2 tw-gap-8">
                <app-card-selection
                    iconColor="green"
                    iconSrc="40_edit.svg"
                    label="{{ 'PAGES.RELATIONS.ADD_RELATION_OVERLAY.LABEL_ADD_PERSON' | translate }}"
                    [selected]="!isExistingUserSelected"
                    (click)="switchExistingUserSelection(false)"
                ></app-card-selection>
                <app-card-selection
                    iconColor="green"
                    iconSrc="40_person.svg"
                    label="   {{ 'PAGES.RELATIONS.ADD_RELATION_OVERLAY.LABEL_SELECT_EXISTING_PERSON' | translate }}"
                    [selected]="isExistingUserSelected"
                    (click)="switchExistingUserSelection(true)"
                ></app-card-selection>
            </div>
        </div>

        <div [hidden]="this.isExistingUserSelected">
            <app-user-infos-form [existingAddress]="(property$ | async)?.address ?? undefined"></app-user-infos-form>
        </div>

        <div class="mt-1r" [hidden]="!this.isExistingUserSelected">
            <app-existing-persons-combo-box
                (handlePersonSelection)="onSelectPerson($event)"
            ></app-existing-persons-combo-box>
        </div>

        <div class="mb-06">
            <hr class="mt-5r" />
            <div class="s-head-16-24-bold pt-3r pb-2r">
                <span>{{ 'PAGES.RELATIONS.RESIDENTS.LABEL_RELATION_RESIDENT_INTERVAL_HEADLINE' | translate }}</span>
            </div>
            <app-date-range-picker></app-date-range-picker>
            <hr />
        </div>
    </ng-template>

    <div class="tw-flex tw-justify-between tw-mt-[120px] tw-mb-20">
        <app-scalara-button (click)="abort()" variant="outline">{{
            'CONTROLS.BUTTONS.CANCEL' | translate
        }}</app-scalara-button>

        <app-scalara-button (click)="submit()" [disabled]="!this.isFormsValid()">
            {{ 'CONTROLS.BUTTONS.SAVE' | translate }}</app-scalara-button
        >
    </div>
</app-overlay-container>
