import { Component, OnInit } from '@angular/core';
import { Subject, switchMap, takeUntil, tap } from 'rxjs';
import { OperationsExpenseStatementsService, SimpleOesDto } from 'src/app/generated-sources/accounting';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { LedgerCustomService } from '../../services/ledger-custom.service';
import { AddOperationsExpenseStatementsComponent } from '../add-operations-expense-statements/add-operations-expense-statements.component';

@Component({
    selector: 'app-operations-expense-statements-overview',
    templateUrl: './operations-expense-statements-overview.component.html',
    styleUrls: ['./operations-expense-statements-overview.component.scss'],
})
export class OperationsExpenseStatementsOverviewComponent implements OnInit {
    private unsubscribe$ = new Subject<void>();
    public operationsExpenseStatements: SimpleOesDto[] = [];
    public isLoading = false;
    public ledgerId = '';

    public constructor(
        private operationsExpenseStatementsService: OperationsExpenseStatementsService,
        private ledgerCustomService: LedgerCustomService,
        private overlayService: OverlayService
    ) {}

    public ngOnInit(): void {
        this.initOperationsExpenseStatementsService();
    }

    public initOperationsExpenseStatementsService(): void {
        this.isLoading = true;
        this.ledgerCustomService
            .getLedgerId$()
            .pipe(
                switchMap((ledgerId) => {
                    this.ledgerId = ledgerId || '';
                    return this.operationsExpenseStatementsService.findAll(ledgerId || '');
                }),
                tap((operationsExpenseStatements) => {
                    this.operationsExpenseStatements = operationsExpenseStatements;
                    this.isLoading = false;
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }

    public openAddOperationsExpenseStatementsServiceOverlay(): void {
        const data = { ledgerId: this.ledgerId, operationsExpenseStatements: this.operationsExpenseStatements };
        const ref = this.overlayService.open(AddOperationsExpenseStatementsComponent, {
            data,
        });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
        ref.saveEmitter$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => this.initOperationsExpenseStatementsService());
    }
}
