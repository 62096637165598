<div class="person-infobox">
    <div class="avatar-container avatar-container--40">
        <svg-icon src="/assets/icons/24_person.svg"></svg-icon>
    </div>
    <div class="s-body-14-22-semibold ml-1r">{{ name }}</div>
    <div *ngIf="options.includes('email')" class="adress pl-1r">{{ email }}</div>
    <div *ngIf="options.includes('address')" class="adress pl-1r">{{ address }}</div>
    <div *ngIf="options.includes('phone')" class="adress pl-1r">{{ phone }}</div>
    <div *ngIf="options.includes('type')" class="adress pl-1r">
        <div
            class="tagContainer"
            *ngIf="type === 'NAT_PERSON'"
            class="label label-green d-inline-block s-label-12-16-regular"
        >
            {{ 'ENTITIES.PERSON.LABEL_PERSON_TYPE_NAT_PERSON' | translate }}
        </div>
        <div
            class="tagContainer"
            *ngIf="type === 'COMPANY'"
            class="label label-purple d-inline-block s-label-12-16-regular"
        >
            {{ 'ENTITIES.PERSON.LABEL_PERSON_TYPE_COMPANY' | translate }}
        </div>
    </div>
</div>
