import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { OwnershipAdvancementAmountDto } from 'src/app/generated-sources/accounting/model/ownershipAdvancementAmountDto';
import { OwnershipAdvancementAmountsDto } from 'src/app/generated-sources/accounting/model/ownershipAdvancementAmountsDto';
import { EurocentPipe } from 'src/app/shared/pipes/eurocent.pipe';

@Component({
    selector: 'app-adjust-advances',
    templateUrl: './adjust-advances.component.html',
    styleUrls: ['./adjust-advances.component.scss'],
})
export class AdjustAdvancesComponent implements OnInit {
    public form: UntypedFormGroup = new UntypedFormGroup({});
    public isLoading = false;

    @Input() public ledgerId = '';
    @Input() public economicPlanId = '';
    @Input() public advancements: OwnershipAdvancementAmountDto[] = [];

    @Output() public handleAdjustAdvancesOverlay = new EventEmitter<void>();
    @Output() public submitDifferingAdvancements = new EventEmitter<OwnershipAdvancementAmountsDto>();

    public constructor(private formBuilder: UntypedFormBuilder, private eurocentPipe: EurocentPipe) {}

    public ngOnInit(): void {
        this.form = this.createForm();
        this.isLoading = true;

        this.advancements?.map((advancement) => {
            this.advancement.push(
                this.formBuilder.group({
                    calculatedAdvancement: this.formBuilder.control(
                        advancement.calculatedAdvancement
                            ? this.eurocentPipe.transform(advancement.calculatedAdvancement)
                            : 0
                    ),
                    differingAdvancement: this.formBuilder.control(
                        advancement.differingAdvancement
                            ? this.eurocentPipe.transform(advancement.differingAdvancement as number)
                            : null
                    ),
                    ownershipId: this.formBuilder.control(advancement.ownershipId),
                    ownershiName: this.formBuilder.control(advancement.ownershipName),
                })
            );
        });
        this.isLoading = false;
    }

    public createForm(): UntypedFormGroup {
        return this.formBuilder.group({
            advancement: this.formBuilder.array([]),
        });
    }

    public get advancement(): UntypedFormArray {
        return this.form.controls['advancement'] as UntypedFormArray;
    }

    public onSubmit(): void {
        const ownershipAdvancementAmountsDto: OwnershipAdvancementAmountsDto = {
            ownershipAdvancementAmounts: this.advancement.value.map((item: any) => {
                return {
                    ownershipId: item.ownershipId,
                    differingAdvancement:
                        typeof item.differingAdvancement === 'string'
                            ? Number(item.differingAdvancement.replace(/[,.]+/g, ''))
                            : item.differingAdvancement,
                };
            }),
        };

        this.submitDifferingAdvancements.emit(ownershipAdvancementAmountsDto);
    }

    public abort(): void {
        this.handleAdjustAdvancesOverlay.emit();
    }

    public getAdvancementItemForm(index: number): UntypedFormGroup {
        return this.advancement.at(index) as UntypedFormGroup;
    }
}
