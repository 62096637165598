<div
    [ngClass]="{ 'accordion-content': this.scalaraAccordionComponent?.isOpen$ | async }"
    class="tw-fixed tw-z-[2] tw-right-0 tw-left-[316px] tw-bg-white tw-overflow-auto tw-max-h-full"
>
    <app-scalara-accordion
        appClickOutside
        (clickOutside)="closeAccordion()"
        [useDefaultStyles]="false"
        [isOpenedByDefault]="false"
        iconToClose="/assets/icons/24_close.svg"
        iconToOpen="/assets/icons/24_property-select.svg"
    >
        <div class="tw-flex tw-justify-between tw-w-full" scalara-accordion-header>
            <div class="tw-flex tw-items-center tw-gap-3">
                <ng-container *ngIf="property?.imgSmall; else defaultPic">
                    <img
                        class="tw-rounded-full tw-h-10 tw-w-10"
                        src="{{ property?.imgSmall ?? '' | imageBase64 }}"
                        alt=""
                /></ng-container>

                <div class="s-gray-01 s-label-12-22-semibold">
                    {{ property?.name + ' ' + (additionalInfo ? additionalInfo : '') }}
                </div>

                <div class="tw-flex">
                    <div class="s-gray-01 s-label-12-16-regular">
                        {{ this.getPropertyLabel(property) }}
                    </div>
                </div>

                <app-tag *ngIf="property?.propertyType !== 'WEG_SEV'" [color]="tagColor">
                    {{ property?.propertyType }}</app-tag
                >
                <div class="d-flex" *ngIf="property?.propertyType === 'WEG_SEV'">
                    <app-tag class="tw-mr-2" color="green">WEG</app-tag>
                    <app-tag color="orange">SEV</app-tag>
                </div>
            </div>
            <div *ngIf="statusInfo[0]" class="s-label-12-16-regular tw-mr-8 tw-flex tw-items-center">
                <div
                    [ngClass]="{
                        pulse: statusInfo.length > 1
                    }"
                    class="tw-h-2 tw-w-2 tw-rounded-full tw-mr-2 tw-bg-scalaraBlue-01"
                ></div>
                {{ statusInfo[0] }}
                <span class="s-blue-02 s-label-12-22-semibold tw-ml-[6px]" *ngIf="statusInfo.length > 1">
                    {{ statusInfo[1] }}</span
                >
            </div>
        </div>

        <div scalara-accordion-content class="tw-bg-white tw-px-10 tw-py-6">
            <div>
                <div class="d-flex justify-content-end tw-mb-4 tw-w-full">
                    <app-search
                        class="tw-w-full"
                        [isFullwidth]="true"
                        [closedByDefault]="false"
                        [searchInputId]="'switchPropertiesId__' + menuTitle"
                    ></app-search>
                </div>
                <app-table
                    emptyText="PAGES.OCCUPATION.OVERVIEW.LABEL_EMPTY_TABLE"
                    [header]="table.header"
                    [data]="table.data"
                    [tableSearchId]="'switchPropertiesId__' + menuTitle"
                    [itemsPerPage]="10"
                    (rowClick)="onRowClick($event)"
                >
                </app-table>
            </div>
        </div>
    </app-scalara-accordion>
</div>

<ng-template #defaultPic>
    <div class="default-foto tw-rounded-full tw-h-10 tw-w-10">
        <svg-icon src="/assets/icons/40_building.svg"></svg-icon>
    </div>
</ng-template>

<ng-template #accordion>
    <div class="default-foto tw-rounded-full tw-h-10 tw-w-10">
        <svg-icon src="/assets/icons/40_building.svg"></svg-icon>
    </div>
</ng-template>
