import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {InjectorService} from "./injector.service";


type Params = HttpParams | {[param: string]: string | string[]};

export abstract class BaseHttpService {
    private http: HttpClient;
    private headers: HttpHeaders;
    private readonly API_URL: string;
    private BASE_URL: string;

    public static readonly CONTENT_TYPE_URL_ENC = 'application/x-www-form-urlencoded';
    public static readonly CONTENT_TYPE_JSON = 'application/json';

    protected constructor() {
        this.http = InjectorService.getInjector().get(HttpClient);
        this.headers = new HttpHeaders( {
            'Content-Type': BaseHttpService.CONTENT_TYPE_JSON
        });
        this.API_URL = environment.apiUrl;
        this.BASE_URL = `${window.location.origin}/`;
    }

    public setHeaders(value: HttpHeaders): void {
        this.headers = value;
    }

    protected get<T>(urlSuffix: string): Observable<T>;
    protected get<T>(urlSuffix: string, params: Params): Observable<T>;
    protected get<T>(urlSuffix: string, params?: Params, headers?: HttpHeaders): Observable<T> {
        return this.http.get<T>(
            this.API_URL + urlSuffix,
            {params, headers}
        );
    }

    protected post<T>(urlSuffix: string): Observable<T>;
    protected post<T>(urlSuffix: string, body: unknown): Observable<T>;
    protected post<T>(urlSuffix: string, body: unknown, params: Params): Observable<T>;
    protected post<T>(urlSuffix: string, body?: unknown, params?: Params, headers?: HttpHeaders): Observable<T> {
        return this.http.post<T>(
            this.API_URL + urlSuffix,
            body,
            {params, headers}
        );
    }

    protected put<T>(urlSuffix: string): Observable<T>
    protected put<T>(urlSuffix: string, body: unknown): Observable<T>
    protected put<T>(urlSuffix: string, body: unknown, params: Params): Observable<T>
    protected put<T>(urlSuffix: string, body: unknown, params: Params, headers: HttpHeaders): Observable<T>;
    protected put<T>(urlSuffix: string, body?: unknown, params?: Params, headers?: HttpHeaders): Observable<T> {
        return this.http.put<T>(
            this.API_URL + urlSuffix,
            body,
            {params, headers}
        );
    }

    protected patch<T>(urlSuffix: string): Observable<T>
    protected patch<T>(urlSuffix: string, body: unknown): Observable<T>
    protected patch<T>(urlSuffix: string, body: unknown, params: Params): Observable<T>
    protected patch<T>(urlSuffix: string, body?: unknown, params?: Params, headers?: HttpHeaders): Observable<T> {
        return this.http.patch<T>(
            this.API_URL + urlSuffix,
            body,
            {params, headers}
        );
    }

    protected delete<T>(urlSuffix: string): Observable<T>;
    protected delete<T>(urlSuffix: string, params: Params): Observable<T>;
    protected delete<T>(urlSuffix: string, params?: Params, headers?: HttpHeaders): Observable<T> {
        return this.http.delete<T>(
            this.API_URL + urlSuffix,
            {params, headers}
        );
    }

    protected saveToSessionStore<T>(sessionStorageItemName: string, toStore: T): void {
        sessionStorage.setItem(sessionStorageItemName, JSON.stringify(toStore));
    }

    protected getFromSessionStore<T>(sessionStorageItemName: string): T | null {
        const sessionStorageItem: string | null = sessionStorage.getItem(sessionStorageItemName);
        const storage: T = sessionStorageItem ? JSON.parse(sessionStorageItem) : null;
        return storage ? storage : null;
    }
}
