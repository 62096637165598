import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AccountingFilterCustomService } from '../services/accounting-filter-custom.service';

@Component({
    selector: 'app-accounting-date-picker-selector',
    templateUrl: './accounting-date-picker-selector.component.html',
    styleUrls: ['./accounting-date-picker-selector.component.scss'],
})
export class AccoutingDatePickerSelectorComponent implements OnInit {
    public constructor(public accountingFilterCustomService: AccountingFilterCustomService) {}
    @Output() public changeDate = new EventEmitter<{ startDate: Date; endDate: Date }>();

    public currentYear = new Date().getFullYear();
    public startDate = new Date();
    public endDate = new Date();

    public ngOnInit(): void {
        this.accountingFilterCustomService.getDatePickerSelectedDates$().subscribe((dates) => {
            this.startDate = dates.startDate;
            this.endDate = dates.endDate;
        });
    }

    public onChangeDate($event: any, date: 'endDate' | 'startDate'): void {
        this[date] = $event[0];
        this.accountingFilterCustomService.setSelectedDates(this.startDate, this.endDate);
    }

    public invalidDates(): boolean {
        return this.startDate.getTime() > this.endDate.getTime();
    }
}
