import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

//  ok - green, attention - orange, warning - purple, ignored - gray
//  for OpenItem there is also two another states possible (error1, error2)
export type BaseState = 'ok' | 'attention' | 'warning' | 'ignored';
export type Labels = {
    [key in BaseState]: { openItem: string; transaction: string };
};

export type Options =
    | {
          type: 'openItem';
          state: BaseState;
          isDue: boolean;
      }
    | { type: 'transaction'; state: BaseState };

@Component({
    selector: 'app-details-container',
    templateUrl: './details-container.component.html',
    styleUrls: ['./details-container.component.scss'],
})
export class DetailsContainerComponent implements OnInit {
    @Input() public options: Options = {} as Options;
    public labels = {} as Labels;

    public constructor(public translateService: TranslateService) {}

    public ngOnInit(): void {
        this.generateLabels();
    }

    public generateLabels(): void {
        this.labels = {
            ok: {
                openItem: this.translateService.instant('ACCOUNTING.DETAILS_CONTAINER.OPEN_ITEM_STATUS_LABELS.OK'),
                transaction: this.translateService.instant('ACCOUNTING.DETAILS_CONTAINER.TRANSACTION_STATUS_LABELS.OK'),
            },
            attention: {
                openItem: this.translateService.instant(
                    'ACCOUNTING.DETAILS_CONTAINER.OPEN_ITEM_STATUS_LABELS.ATTENTION'
                ),
                transaction: this.translateService.instant(
                    'ACCOUNTING.DETAILS_CONTAINER.TRANSACTION_STATUS_LABELS.ATTENTION'
                ),
            },
            warning: {
                openItem: this.translateService.instant('ACCOUNTING.DETAILS_CONTAINER.OPEN_ITEM_STATUS_LABELS.WARNING'),
                transaction: this.translateService.instant(
                    'ACCOUNTING.DETAILS_CONTAINER.TRANSACTION_STATUS_LABELS.WARNING'
                ),
            },
            ignored: {
                openItem: this.translateService.instant('ACCOUNTING.DETAILS_CONTAINER.OPEN_ITEM_STATUS_LABELS.IGNORED'),
                transaction: this.translateService.instant(
                    'ACCOUNTING.DETAILS_CONTAINER.TRANSACTION_STATUS_LABELS.IGNORED'
                ),
            },
        };
    }

    public getLabel(): string {
        const label = this.labels[this.options.state][this.options.type];
        if (this.options.type === 'openItem' && this.options.isDue) {
            return label + ' (fällig)';
        }
        return label;
    }

    public getIconUrl(): string {
        const iconUrl = '/assets/icons/';
        const state = this.options.state;
        if (state === 'attention') {
            return iconUrl + '24_open.svg';
        }
        if (state === 'ok') {
            return iconUrl + '24_closed.svg';
        }
        if (state === 'warning') {
            return iconUrl + '24_partly-closed.svg';
        }
        if (state === 'ignored') {
            return iconUrl + '24_ignor.svg';
        }
        console.warn('there is no icon found');
        return '';
    }
}
