<app-headline-with-icon color="green" image="24_information.svg" text="Sonstige Informationen"></app-headline-with-icon>

<div class="tw-flex tw-justify-between tw-mt-6">
    <div class="tw-border-b-[1px] tw-border-solid tw-border-scalaraGray-05 tw-w-full">
        <textarea
            *ngIf="showInput$ | async; else label"
            [(ngModel)]="additionalInformation"
            ibmTextArea
            rows="3"
            aria-label="textarea"
            maxlength="100000"
        ></textarea>
    </div>

    <div class="d-flex tw-ml-4">
        <app-scalara-button
            *ngIf="showInput$ | async; else actionsButton"
            (click)="submit()"
            variant="icon-only"
            svgName="24_checkbox-check.svg"
            height="40px"
        ></app-scalara-button>
    </div>
</div>

<ng-template #actionsButton>
    <app-scalara-button (click)="removeInfo()" variant="icon-only" svgName="24_delete.svg" height="40px">
    </app-scalara-button>

    <app-scalara-button
        (click)="showInput()"
        variant="icon-only"
        svgName="24_edit.svg"
        height="40px"
    ></app-scalara-button>
</ng-template>

<ng-template #label
    ><div class="tw-px-4 tw-py-[11px] tw-min-h-[86.95px]">
        <div class="{{ additionalInformation ? '' : 's-body-14-22-italic' }}">
            {{ additionalInformation || 'nicht angegeben' }}
        </div>
    </div>
</ng-template>
