<ng-container>
    <div *ngIf="vm$ | async as vm" class="mb-1-5r">
        <label>
            <span class="bx--label">{{ label }}</span>
            <div class="bx--text-input__field-wrapper">
                <input
                    [value]="showedInput"
                    type="text"
                    class="bx--text-input d-flex align-items-center {{
                        invalid && touched ? 'bx--text-input--invalid' : ''
                    }}"
                    (click)="toggleSetIsOpen()"
                    (keyup)="onKey($event)"
                    autocomplete="off"
                    [disabled]="disabled"
                />
            </div>
            <div class="error-message" *ngIf="this.invalid && !vm.isOpen">{{ invalidText }}</div>
        </label>
        <ul class="dropdown__menu" *ngIf="vm.isOpen">
            <li class="dropdown__menu-item" *ngFor="let listItem of vm.listItems" (click)="onItemSelect(listItem)">
                {{ listItem.content }}
            </li>
        </ul>
    </div>
</ng-container>
