import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    OnDestroy,
    Type,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { environment } from 'src/environments/environment';
import { OverlayService } from '../../services/overlay.service';
import { OverlayRef } from '../../utils/overlay-ref';
import { OverlayConfig } from '../../utils/overlay.config';
import { OverlayChildComponent } from '../overlay-child/overlay-child.component';

@Component({
    selector: 'app-overlay',
    templateUrl: './overlay.component.html',
    styleUrls: ['./overlay.component.scss'],
})
export class OverlayComponent implements AfterViewInit, OnDestroy {
    private childComponent?: OverlayChildComponent;

    public componentRef?: ComponentRef<any>;
    public childComponentType?: Type<any>;
    public config?: OverlayConfig;

    public emitSaveOnOverlayClose = false;

    public readonly environment = environment;

    public isHalfPage = false;

    @ViewChild('child', { read: ViewContainerRef })
    public viewContainer?: ViewContainerRef;

    public constructor(
        private cd: ChangeDetectorRef,
        public overlayRef: OverlayRef,
        private overlayService: OverlayService,
        private viewContainerRef: ViewContainerRef
    ) {}

    public ngAfterViewInit(): void {
        this.isHalfPage = this.config?.overlayType === 'half-page';
        this.initChildComponent(this.childComponentType!);
        this.cd.detectChanges();
    }

    public ngOnDestroy(): void {
        if (!this.componentRef) {
            return;
        }

        this.componentRef.destroy();
        this.childComponent?.saveEmitter$.unsubscribe();
        this.childComponent?.cancelEmitter$.unsubscribe();
    }

    public closeOverlay(evt: MouseEvent): void {
        // When true, a refresh of the page that opened the overlay is required.
        if (this.emitSaveOnOverlayClose) {
            this.overlayRef.saveEmitter$.next();
        } else {
            this.overlayRef.cancelEmitter$.next();
            this.overlayRef.close();
        }
    }

    public initChildComponent(componentType: Type<OverlayChildComponent>): void {
        this.viewContainer!.clear();
        this.childComponent = this.viewContainer!.createComponent(componentType).instance;
        this.childComponent.config = this.config;
        this.emitSaveOnOverlayClose = this.config?.data?.emitSaveOnOverlayClose || false;

        this.childComponent.saveEmitter$.subscribe(() => {
            this.overlayRef.close();
            this.overlayRef.saveEmitter$.next();
        });
        this.childComponent.cancelEmitter$.subscribe(() => this.overlayRef.cancelEmitter$.next());
    }

    public clickOutside(): void {
        if (this.overlayService.getOverlayCount() > 1 || !this.isHalfPage) {
            return;
        }

        if (this.overlayService.closeOverlay()) {
            this.overlayRef.cancelEmitter$.next();
            this.overlayRef.close();
        }
    }

    public clickInside(event: MouseEvent): void {
        event.stopPropagation();
    }
}
