import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

export interface Filter {
    id: 'excludeCancelledBookings'; //when add more filters, add here
    content: string;
    selected: boolean;
}

@Component({
    selector: 'app-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnChanges {
    @Input() public filterItems?: Filter[];
    @Output() public updateFilterItems = new EventEmitter<{ id: string; selected: boolean }>();
    @Output() public resetFilter = new EventEmitter<void>();

    public showFilterItems = false;
    public countSelectedFilter = 0;
    public filterButtonVariant: 'icon-only-blue' | 'icon-only' = 'icon-only';

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['filterItems']) {
            this.countSelectedFilter = 0;
            this.filterItems?.map((item) => {
                if (item.selected) {
                    this.countSelectedFilter = this.countSelectedFilter + 1;
                }
            });

            this.filterButtonVariant = this.countSelectedFilter > 0 ? 'icon-only-blue' : 'icon-only';
        }
    }

    public setFilterItemsVisibility(clickedOutside = false): void {
        if (clickedOutside) {
            this.showFilterItems = false;
            return;
        }
        this.showFilterItems = !this.showFilterItems;
    }

    public onCheckboxChange(event: any, itemId: string): void {
        this.updateFilterItems.emit({ id: itemId, selected: event.checked });
    }

    public stopPropagation(event: any): void {
        event.stopPropagation();
    }

    public deleteFilter(): void {
        this.resetFilter.emit();
    }
}
