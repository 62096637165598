<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<app-overlay-container
    headlineTitle="PAGES.ADD_DOCUMENTS.HEADLINE"
    [sizeIcon]="72"
    image="40_upload.svg"
    colorIcon="green"
    containerSize="default"
>
    <div class="mb-7-5r w-100">
        <app-file-uploader
            #fileUpload
            (filesLoaded)="updateLoadingFilesStatus($event)"
            (fileIdsLoaded)="updateFileIdsLodaded($event)"
            (updatedFileCategories)="updatedFileCategories($event)"
            [categories]="categories"
        ></app-file-uploader>
    </div>

    <div class="d-flex justify-space-between w-100">
        <button [ibmButton]="'tertiary'" type="reset" (click)="abort()">
            {{ 'CONTROLS.BUTTONS.CANCEL' | translate }}
        </button>

        <button [ibmButton]="'primary'" type="submit" (click)="onSubmit()" [disabled]="!areFilesFullyUploaded">
            {{ 'COMMON.BTN_SAVE' | translate }}
        </button>
    </div>
</app-overlay-container>
