import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbItem, InlineLoading, ListItem } from 'carbon-components-angular';
import {
    BehaviorSubject,
    Observable,
    ObservedValueOf,
    Subject,
    combineLatest,
    distinctUntilChanged,
    firstValueFrom,
    map,
    takeUntil,
} from 'rxjs';
import { BreadCrumbService } from 'src/app/core/services/breadcrumb.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { convertToISODateString } from 'src/app/core/utils/common';
import { AccountingFilterCustomService } from 'src/app/features/accounting/components/services/accounting-filter-custom.service';
import {
    LedgerCustomService,
    LedgerDtoWithExtendedProperty,
} from 'src/app/features/accounting/components/services/ledger-custom.service';
import { PropertyCustomService } from 'src/app/features/property/services/property-custom.service';
import {
    AccountingReportsControllerGetOpenItemsReport200Response,
    AccountingReportsService,
} from 'src/app/generated-sources/accounting';
import { BaseReportsService, Property } from 'src/app/generated-sources/base';
import { ScalaraButtonComponent } from 'src/app/shared/components/scalara-button/scalara-button.component';
import { CellTemplate } from 'src/app/shared/table/enums/cell-template';
import { TableModel } from 'src/app/shared/table/interfaces/table-model';
import { ListGeneratorFlowEnum } from '../list-generator-overview/list-generator-overview.component';

type MethodReturn = Observable<AccountingReportsControllerGetOpenItemsReport200Response>;
type ReportKey = 'personReport' | 'rentContractsReport' | 'vacanciesReport' | 'openItemsReport' | 'taxReport';
//  hard to implement correct typing here due to function overloading in generated sources
type MethodToGetUrl = (...args: any) => MethodReturn;
type ReportsConfig = Record<
    ReportKey,
    {
        methodToGetUrl: MethodToGetUrl;
        methodArgs: ['propertyId' | 'ledgerId', 'date' | 'none'];
        getName: () => string;
        showForPropTypes: (LedgerDtoWithExtendedProperty['type'] | Property['propertyType'])[];
        //  ledgerPropertyItem with type 'property' is not associated with ledger,
        //  so we cant call anything assoociated with accounting service
        showForLedgerPropertyItemTypes: ('ledger' | 'property')[];
        reportKey: ReportKey; //proptype for single one and all for allProperties flow
    }
>;
//  ledgers and properties are merged to one array containing this items
type LedgerPropertyItem =
    | {
          type: 'ledger';
          ledger: LedgerDtoWithExtendedProperty;
      }
    | { type: 'property'; property: Property };
type TranslationKeys = {
    listNameKeys: Record<ReportKey, string>;
    breadcrumbKeys: Record<ListGeneratorFlowEnum, string>;
};

@Component({
    selector: 'app-list-generator-details',
    templateUrl: './list-generator-details.component.html',
    styleUrls: ['./list-generator-details.component.scss'],
})
export class ListGeneratorDetailsComponent implements OnInit, OnDestroy {
    public constructor(
        private route: ActivatedRoute,
        private breadcrumbService: BreadCrumbService,
        public translateService: TranslateService,
        private baseReportsService: BaseReportsService,
        private accountingReportsService: AccountingReportsService,
        private toastService: ToastService,
        private ledgerCustomService: LedgerCustomService,
        private propertyCustomService: PropertyCustomService,
        private accountingFilterCustomService: AccountingFilterCustomService
    ) {}

    private readonly translationKeys: TranslationKeys = {
        listNameKeys: {
            personReport: 'PAGES.LIST_GENERATOR.PERSON_REPORT',
            rentContractsReport: 'PAGES.LIST_GENERATOR.RENT_CONTRACTS_REPORT',
            vacanciesReport: 'PAGES.LIST_GENERATOR.VACANCIES_REPORT',
            openItemsReport: 'PAGES.LIST_GENERATOR.OPEN_ITEMS_REPORT',
            taxReport: 'PAGES.LIST_GENERATOR.TAX_REPORT',
        },
        breadcrumbKeys: {
            selectProperty: 'PAGES.LIST_GENERATOR.SELECT_PROPERTY',
            allProperties: 'PAGES.LIST_GENERATOR.ALL_PROPERTIES',
        },
    };

    private readonly reportsConfig: ReportsConfig = {
        personReport: {
            methodToGetUrl: this.baseReportsService.getPersonsReport.bind(this.baseReportsService),
            methodArgs: ['propertyId', 'none'],
            getName: () => this.translateService.instant(this.translationKeys.listNameKeys.personReport),
            showForPropTypes: ['WEG', 'SEV', 'MV', 'WEG_SEV'],
            showForLedgerPropertyItemTypes: ['ledger', 'property'],
            reportKey: 'personReport',
        },
        rentContractsReport: {
            methodToGetUrl: this.baseReportsService.getRentContractsReport.bind(this.baseReportsService),
            methodArgs: ['propertyId', 'none'],
            getName: () => this.translateService.instant(this.translationKeys.listNameKeys.rentContractsReport),
            showForPropTypes: ['SEV', 'MV', 'WEG_SEV'],
            showForLedgerPropertyItemTypes: ['ledger', 'property'],
            reportKey: 'rentContractsReport',
        },
        vacanciesReport: {
            methodToGetUrl: this.baseReportsService.getVacanciesReport.bind(this.baseReportsService),
            methodArgs: ['propertyId', 'none'],
            getName: () => this.translateService.instant(this.translationKeys.listNameKeys.vacanciesReport),
            showForPropTypes: ['SEV', 'MV', 'WEG_SEV'],
            showForLedgerPropertyItemTypes: ['ledger', 'property'],
            reportKey: 'vacanciesReport',
        },
        openItemsReport: {
            methodToGetUrl: this.accountingReportsService.getOpenItemsReport.bind(this.accountingReportsService),
            methodArgs: ['ledgerId', 'none'],
            getName: () => this.translateService.instant(this.translationKeys.listNameKeys.openItemsReport),
            showForPropTypes: ['WEG', 'SEV', 'MV', 'WEG_SEV'],
            showForLedgerPropertyItemTypes: ['ledger'],
            reportKey: 'openItemsReport',
        },
        taxReport: {
            methodToGetUrl: this.accountingReportsService.getSevTaxReport.bind(this.accountingReportsService),
            methodArgs: ['ledgerId', 'date'],
            getName: () => this.translateService.instant(this.translationKeys.listNameKeys.taxReport),
            showForPropTypes: ['SEV', 'MV', 'WEG_SEV'],
            showForLedgerPropertyItemTypes: ['ledger'],
            reportKey: 'taxReport',
        },
    };

    //  get value to be used as argument for methodToGetUrl
    private async getFilterArgs(reportKey: ReportKey): Promise<(string | undefined)[]> {
        const methodArgsConfig = this.reportsConfig[reportKey].methodArgs;
        const isFilterByDate = methodArgsConfig[1] === 'date';
        const dates = await firstValueFrom(this.datePickerSelectedDates$);
        const datesArgs = isFilterByDate
            ? [convertToISODateString(dates.startDate), convertToISODateString(dates.endDate)]
            : [];

        const flow = await firstValueFrom(this.flow$);
        if (flow === ListGeneratorFlowEnum['allProperties']) {
            return [undefined, ...datesArgs];
        }
        const selectedLedgerPropertyItem = await firstValueFrom(this.selectedLedgerProperty$);
        if (!selectedLedgerPropertyItem) {
            console.error('selectedLedgerPropertyItem is null');
            return [undefined];
        }
        const property =
            selectedLedgerPropertyItem.type === 'ledger'
                ? selectedLedgerPropertyItem.ledger.property
                : selectedLedgerPropertyItem.property;
        const ledger = selectedLedgerPropertyItem.type === 'ledger' ? selectedLedgerPropertyItem.ledger : null;

        const firstFilterArgument = methodArgsConfig[0] === 'propertyId' ? property.id : ledger?.id ?? undefined;

        if (!firstFilterArgument) {
            console.error('argument is falsy, but ledgerPropertyItem is selected');
        }
        return [firstFilterArgument, ...datesArgs].filter(Boolean);
    }

    private unsubscribe$ = new Subject<void>();

    private datePickerSelectedDates$ = this.accountingFilterCustomService.getDatePickerSelectedDates$();

    //  either 'select-property' or 'all-properties'
    public flow$: Observable<ListGeneratorFlowEnum> = this.route.queryParams.pipe(
        map((params) => params['flow']),
        distinctUntilChanged()
    );

    //  for 'select-property' flow only
    public selectedLedgerProperty$ = new BehaviorSubject<LedgerPropertyItem | null>(null);
    public properties$ = this.propertyCustomService.getProperties$();
    public ledgerWithOwnerhipNames$ = this.ledgerCustomService.getLedgersWithSevOwnershipsNames();
    public ledgerPropertyItems$ = combineLatest([this.properties$, this.ledgerWithOwnerhipNames$]).pipe(
        map(([properties, ledgerWithOwnerhipNames]) => {
            //  filter out properties which already are in ledgerWithOwnerhipNames
            const uniqueProperties = properties.filter((property) => {
                const propIsAssociatedWithLedger = Boolean(
                    ledgerWithOwnerhipNames.find((ledger) => ledger.property.id == property.id)
                );
                return !propIsAssociatedWithLedger;
            });

            const ledgerPropertyItems: LedgerPropertyItem[] = [
                ...uniqueProperties.map((property) => ({ type: 'property' as const, property })),
                ...ledgerWithOwnerhipNames.map((ledger) => ({ type: 'ledger' as const, ledger })),
            ];
            return ledgerPropertyItems;
        })
    );
    public comboBoxItems$: Observable<ListItem[]> = combineLatest([this.ledgerPropertyItems$]).pipe(
        map(([items]) => {
            return this.propertiesToComboBoxItems(items);
        })
    );

    public isLoading$ = new BehaviorSubject<{ [Key in ReportKey]: boolean }>({
        personReport: false,
        rentContractsReport: false,
        vacanciesReport: false,
        openItemsReport: false,
        taxReport: false,
    });

    public tableModel$ = combineLatest([this.selectedLedgerProperty$, this.flow$, this.isLoading$]).pipe(
        map(([selectedLedgerProperty, flow, isLoading]) => {
            const reportsToShow = Object.values(this.reportsConfig).filter((reportConfig) => {
                //  return all reports if flow is 'allProperties'
                if (flow === ListGeneratorFlowEnum['allProperties']) {
                    return true;
                }
                if (!selectedLedgerProperty) {
                    return false;
                }
                const propType =
                    selectedLedgerProperty.type === 'ledger'
                        ? selectedLedgerProperty.ledger.type
                        : selectedLedgerProperty.property.propertyType;
                const propTypeCorrect = reportConfig.showForPropTypes.includes(propType);
                const ledgerPropertyItemTypeCorrect = reportConfig.showForLedgerPropertyItemTypes.includes(
                    selectedLedgerProperty.type
                );
                return propTypeCorrect && ledgerPropertyItemTypeCorrect;
            });
            const tableModel: TableModel = {
                header: ['PAGES.LIST_GENERATOR.LIST_NAME', ''],
                data: reportsToShow.map((reportConfig, index) => {
                    const isLoadingForReport = isLoading[reportConfig.reportKey];

                    const baseWrapperClass = 'tw-flex tw-justify-end [&&]:tw-duration-0';
                    return [
                        {
                            template: CellTemplate.Default,
                            data: { label: reportConfig.getName() },
                        },
                        {
                            template: CellTemplate.dynamicComponentItem,
                            data: {
                                label: '',
                                extraData: isLoadingForReport
                                    ? {
                                          component: InlineLoading,
                                          wrapperClass: baseWrapperClass + ' [&&&]:tw-pr-4',
                                      }
                                    : {
                                          component: ScalaraButtonComponent,
                                          componentData: {
                                              svgName: '24_download.svg',
                                              onClick: (): void | Promise<void> =>
                                                  this.onDownloadClick(reportConfig.reportKey),
                                              variant: 'icon-only',
                                              height: '40px',
                                              buttonStyles:
                                                  '[&&&]:hover:tw-bg-scalaraGray-05 [&&]:focus:tw-bg-[unset] [&&]:tw-duration-0',
                                          },
                                          wrapperClass: baseWrapperClass,
                                      },
                            },
                        },
                    ];
                }),
            };
            return tableModel;
        })
    );

    public breadcrumbs$ = this.flow$.pipe(
        map((flow) => {
            this.breadcrumbService.resetBreadCrumbs();
            const newBreadcrumbs: BreadcrumbItem[] = [
                {
                    content: this.translateService.instant('PAGES.LIST_GENERATOR.LIST_GENERATOR'),
                    route: [`/list-generator`],
                    routeExtras: { queryParamsHandling: '' },
                },
                {
                    content: this.translateService.instant(this.translationKeys.breadcrumbKeys[flow]),
                    route: ['/list-generator/details'],
                    routeExtras: { queryParams: { flow } },
                },
            ];
            this.breadcrumbService.updateBreadCrumbs(newBreadcrumbs);
            return this.breadcrumbService.getCurrentBreadCrumbs();
        })
    );

    public vm$ = combineLatest([
        this.flow$,
        this.breadcrumbs$,
        this.comboBoxItems$,
        this.selectedLedgerProperty$,
        this.tableModel$,
    ]).pipe(
        map(([flow, breadcrumbs, comboBoxItems, selectedLedgerProperty, tableModel]) => ({
            flow,
            breadcrumbs,
            comboBoxItems,
            selectedLedgerProperty,
            tableModel,
        })),
        takeUntil(this.unsubscribe$)
    );
    public vm: ObservedValueOf<typeof this.vm$> | null = null;

    public propertiesToComboBoxItems(ledgerPropertyItems: LedgerPropertyItem[]): ListItem[] {
        const listItems: ListItem[] = [];
        console.log({ ledgerPropertyItems });
        ledgerPropertyItems.forEach((ledgerPropertyItem) => {
            const property =
                ledgerPropertyItem.type === 'ledger' ? ledgerPropertyItem.ledger.property : ledgerPropertyItem.property;
            const propertyAddress = this.getPropertyAddress(property);
            listItems.push({
                content:
                    ledgerPropertyItem.type === 'ledger'
                        ? ledgerPropertyItem.ledger.property.nameWithOwnerships + propertyAddress
                        : ledgerPropertyItem.property.name + propertyAddress,
                selected: false,
                ledgerPropertyItem,
            });
        });
        return listItems;
    }

    //  combobox typings are wrong, thats why overwrite it with any
    //  correct type is (selected: { item: { ledgerPropertyItem: LedgerPropertyItem } })
    public onPropertySelected(selected: any): void {
        if (Array.isArray(selected)) {
            return;
        }
        this.selectedLedgerProperty$.next(selected.item.ledgerPropertyItem);
    }

    public async onDownloadClick(reportKey: ReportKey): Promise<void> {
        try {
            this.toastService.showInfo('Datei wird erstellt');
            this.isLoading$.next({ ...(await firstValueFrom(this.isLoading$)), [reportKey]: true });
            const methodArgs = await this.getFilterArgs(reportKey);
            const resp = await firstValueFrom(this.reportsConfig[reportKey].methodToGetUrl(...methodArgs));

            if (!resp.url) {
                throw new Error('url is null');
            }

            const link = document.createElement('a');
            link.href = resp.url;
            link.download = 'download';
            link.click();
            link.remove();
        } catch (error) {
            console.warn({ error });
            this.toastService.showError('Fehler beim Herunterladen der Datei');
        } finally {
            this.isLoading$.next({ ...(await firstValueFrom(this.isLoading$)), [reportKey]: false });
        }
    }

    public ngOnInit(): void {
        this.vm$.subscribe((vm) => {
            this.vm = vm;
        });
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public listGeneratorFlowEnum = ListGeneratorFlowEnum;

    public getPropertyAddress(property: Property): string {
        return ` (${property.address.streetName} ${property.address.streetNumber}, ${property.address.zipCode}  ${property.address.area})`;
    }
}
