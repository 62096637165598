import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import {
    ButtonModule,
    CheckboxModule,
    DialogModule,
    GridModule,
    InlineLoadingModule,
    InputModule,
    LoadingModule,
    NotificationModule,
    SelectModule,
} from 'carbon-components-angular';
import { SharedModule } from '../../shared/shared.module';
import { AuthSuccessComponent } from './components/auth-success/auth-success.component';
import { FooterComponent } from './components/footer/footer.component';
import { RegisterInvitationComponent } from './components/register-invitation/register-invitation.component';
import { ResetPasswordGiveEmailComponent } from './components/reset-password/reset-password-give-email/reset-password-give-email.component';
import { ResetPasswordSetPasswordComponent } from './components/reset-password/reset-password-set-password/reset-password-set-password.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { SigninSignupContainerComponent } from './components/signin-signup-container/signin-signup-container.component';

@NgModule({
    declarations: [
        SignInComponent,
        SignUpComponent,
        SigninSignupContainerComponent,
        FooterComponent,
        ResetPasswordGiveEmailComponent,
        ResetPasswordSetPasswordComponent,
        AuthSuccessComponent,
        RegisterInvitationComponent,
    ],
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        InputModule,
        TranslateModule,
        ButtonModule,
        SelectModule,
        ReactiveFormsModule,
        GridModule,
        SharedModule,
        CheckboxModule,
        DialogModule,
        NotificationModule,
        LoadingModule,
        InlineLoadingModule,
        AngularSvgIconModule,
    ],
    providers: [],
    exports: [SignInComponent, SignUpComponent],
})
export class AuthModule {}
