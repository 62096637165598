<div class="p-05"></div>
<div ibmGrid class="bx--grid--full-width">

    <div ibmRow>
        <div ibmCol
             [ngClass]="['s-head-14-22-semibold', 'mb-08']"
             [columnNumbers]="{'md': 8, 'lg': 10}">
            Nutzungsbedingungen
        </div>

        <div ibmCol
             [ngClass]="['s-head-14-22-regular', 'mb-08']"
             [columnNumbers]="{'md': 8, 'lg': 10}">

            Die SCALARA digital GmbH (nachfolgend „SCALARA“) bietet Immobilienverwalter:innen, Eigentümer:innen,
            Mieter:innen und Dienstleister:innen über eine WebApp den Zugang zur SCALARA-Plattform.

            § 1 Begriffsbestimmungen
            1.1 „Eigentümer:in“ bezeichnet Eigentümer:innen einer Wohnung oder einer Immobilie, soweit die Wohnung oder die
            Immobilie über die SCALARA-Plattform verwaltet wird.
            1.2 „Immobilienverwalter:in“ bezeichnet Verwalter:innen der Immobilie mit den Wohnungen, für welche die
            SCALARA-Plattform zur Verfügung steht.
            1.3 „Mieter:in“ bezeichnet Mieter:innen einer Wohnung, für welche die SCALARA-Plattform zur Verfügung steht.
            1.4 „Dienstleister:in“ bezeichnet Dienstleister:innen, die ihre Leistungen innerhalb eines Rahmenvertrags für
            eine verwaltete Immobilie anbieten oder auf Zuruf Dienstleistungen für eine Immobilie anbieten, die mit der
            SCALARA-Plattform verwaltet wird.
            1.5 „Nutzer:in“ bezeichnet Immobilienverwalter:innen, Eigentümer:innen, Mieter:innen und Dienstleister:innen,
            für welche die SCALARA-Plattform zur Verfügung steht.
            1.6 „SCALARA-Plattform“ bezeichnet die Gesamtheit der von SCALARA angebotenen Dienste, insbesondere die
            SCALARA-WebApp und – soweit verfügbar – mobilen SCALARA-Apps.

            Die SCALARA-Plattform bietet folgende Leistungen:

            Geschäftsbeziehungen: Immobilienverwalter:innen können Immobilien anlegen, zugehörige Geschäftsbeziehungen
            verwalten und die entsprechend beteiligten Eigentümer:innen, Mieter:innen und Dienstleister:innen anlegen und
            den einzelnen Immobilien zuweisen.
            Vorgänge: Kontakt zwischen Immobilienverwalter:innen, Eigentümer:innen, Mieter:innen und Dienstleister:innen
            über ein Ticketsystem
            Kontakte: Immobilienverwalter:innen können Kontakte von Eigentümer:innen, Mieter:innen und Dienstleister:innen
            aus den einzelnen Geschäftsbeziehungen verwalten. Die Verwaltung von Kontakten stellt eine Verarbeitung von
            Daten im Auftrag der Verwalterin oder des Verwalters dar. Insofern gilt die im Anschluss an diese AGB
            wiedergegebene Auftragsverarbeitungsvereinbarung samt Anlagen.
            Administrator:in: Der Immobilienverwalter oder Immobilienverwalterin kann weitere Nutzer:innen in der
            Verwalterrolle für einzelne Immobilien anlegen, deren Nutzungsumfang bestimmen und diese Nutzer:innen auch
            wieder löschen.

            § 2 Nutzung der Plattform
            2.1 Vertragsschluss
            Die Nutzung der SCALARA-Plattform setzt voraus, dass Nutzer:innen diese Nutzungsbedingungen während des
            Registrierungsvorgangs akzeptiert. Die Zurverfügungstellung der SCALARA-Plattform stellt kein bindendes Angebot
            von SCALARA zum Vertragsschluss dar, sondern die Parteien schließen den Nutzungsvertrag ausschließlich unter den
            nachfolgenden Bedingungen ab:

            2.1.1
            Indem Nutzer:innen einen Account erstellen, geben sie ein Angebot auf Abschluss eines Nutzungsvertrags unter
            Geltung dieser Nutzungsbedingungen ab. SCALARA nimmt dieses Angebot an, indem SCALARA den Nutzer:innen eine
            Bestätigungs-E-Mail über die Einrichtung des Accounts sendet.

            2.1.2
            SCALARA speichert diese Nutzungsbedingungen nicht für die Nutzer:innen, diese können die Nutzungsbedingungen
            aber jederzeit unter www.scalara.de/nutzungsbedingungen abrufen und speichern oder ausdrucken.

            2.1.3
            Während des Registrierungsvorgangs können Nutzer:innen Eingabefehler jederzeit mit den Standardeingabemethoden
            korrigieren. Vertragssprache ist deutsch.

            2.1.1
            Verwalter:innen, die einen kostenpflichtigen Account erstellen, haben als Unternehmer im Sinne von §14 BGB kein
            Widerrufsrecht.

            2.2 Kosten
            2.2.1
            Die SCALARA Plattform ist für Verwalter:innen kostenpflichtig. Soweit vereinbart, geht der kostenpflichtigen
            Vertragsdauer ein kostenfreie Testzeitraum voraus. Während dieses Zeitraums können Verwalter:innen den
            Nutzungsvertrag jederzeit kündigen, indem sie den Account wieder löschen. Danach richtet sich die
            Vertragslaufzeit nach der Vereinbarung zwischen den Parteien und nach § 3.

            2.2.2
            Verwalter:innen zahlen die vereinbarten Kosten monatlich im Voraus. Es gelten die während des
            Registrierungsprozesses angezeigten Preise, wobei SCALARA jeweils nur die Preiskategorie abrechnet, die der von
            den Verwalter:innen tatsächlich genutzten Menge entsprechen. Wenn Verwalter:innen während der Registrierung also
            eine Preiskategorie auswählt, die Plattform dann aber in geringerem Umfang nutzt, rechnet SCALARA nur die
            entsprechend geringere Preiskategorie ab und umgekehrt.

            2.3 Weiterentwicklung der SCALARA-Plattform
            2.3.1
            SCALARA behält sich das Recht vor, die SCALARA-Plattform in regelmäßigen oder unregelmäßigen Abständen
            grundlegend oder nur in Teilen zu überarbeiten und anzupassen, insbesondere technisch und funktional
            weiterzuentwickeln. Durch derartige Weiterentwicklungen (Updates) können weitere Leistungen hinzukommen,
            Leistungen/Funktionalitäten abgeändert oder abgeschafft werden. Die Entscheidung, ob, innerhalb welchen
            Zeitraums und in welchem Umfang die Plattform weiterentwickelt wird, liegt bei SCALARA. SCALARA weist
            Nutzer:innen bei jedem Update auf wesentliche Änderungen hin.

            2.3.2
            Soweit eine Weiterentwicklung der SCALARA-Plattform eine Änderung dieser Nutzungsbedingungen erforderlich macht,
            wird SCALARA den Nutzer oder die Nutzerinnen über die Änderung per E-Mail informieren. Die Nutzungsbedingen
            werden Nutzer:innen gegenüber wirksam, wenn diese der Änderung der Nutzungsbedingungen nicht innerhalb von vier
            Wochen nach Zugang der Information widersprechen.

            2.4 Nutzungsrechte
            Vorbehaltlich der Einhaltung dieser Nutzungsbedingungen durch die Nutzerin oder den Nutzer, räumt SCALARA der
            Nutzerin oder dem Nutzer das nicht exklusive, nicht übertragbare Recht ein, die SCALARA-Plattform während der
            Laufzeit des Nutzungsvertrags (siehe § 3) zu nutzen. Die Nutzerin oder der Nutzer ist nicht berechtigt, die
            SCALARA-Plattform zu bearbeiten oder zu verändern, zu verbreiten oder öffentlich zugänglich zu machen, es sei
            denn, das Gesetz gestattet dies zwingend.

            2.5 Nutzungsrechte
            2.5.1
            Nutzer:innen haben die im Zusammenhang mit der Nutzung der SCALARA-Plattform notwendigen technische
            Mindestvoraussetzungen für deren Einsatz zu beachten. Für die Verwendung der Web-App ist ein moderner Browser
            (z.B. Chrome, Firefox oder Safari) in der jeweils aktuellen Version erforderlich. Die Kompatibilität der mobilen
            Apps mit mobilen Betriebssystemen (Android oder iOS) ist im jeweiligen Store angegeben.

            2.5.2
            Nutzer:innen sind für die Einhaltung der gesetzlichen Bestimmungen sowie der nachfolgenden Regelungen bei der
            Verwendung der SCALARA-Plattform verantwortlich. Nutzer:innen dürfen insbesondere nicht (i) mit dem
            Nutzungsverhalten gegen die guten Sitten verstoßen, (ii) gewerbliche Schutz- und Urheberrechte oder sonstige
            Eigentumsrechte Dritter verletzen, (iii) Inhalte mit Viren, sog. Trojanischen Pferden oder sonstige
            Programmierungen, die Software oder die SCALARA-Plattform beschädigen können (im Folgenden „Schadsoftware“),
            übermitteln, oder (iv) Werbung oder unaufgeforderte E-Mails (Spam) oder unzutreffende Warnungen vor Viren,
            Fehlfunktionen und dergleichen verbreiten oder zur Teilnahme an Gewinnspielen, Schneeballsystemen und
            vergleichbaren Aktionen auffordern. Es sind ferner jegliche Handlungen zu unterlassen, die geeignet sind, (i)
            eine übermäßige Belastung der SCALARA-Plattform herbeizuführen oder in sonstiger Weise die Funktionalität der
            Infrastruktur zu beeinträchtigen oder zu manipulieren oder (ii) die Integrität, Stabilität oder die
            Verfügbarkeit der SCALARA-Plattform zu gefährden.

            2.5.3
            Laufzeit und Sperrung von Nutzer:innen
            Nutzer:innen werden ihre Zugangsdaten für Dritte unzugänglich aufbewahren und geheim halten.

            § 3 Laufzeit und Sperrung von Nutzer:innen
            3.1 Laufzeit
            3.1.1
            Die Laufzeit dieses Vertrages beginnt mit der Registrierung in der SCALARA-Plattform und endet grds. mit
            Löschung dieses Nutzerkontos. Die Verwalterrolle ohne Administratorenrechte kann vom Administrator oder der
            Administratorin jederzeit gelöscht werden.

            3.1.2
            Beide Parteien können den kostenpflichtigen Vertrag einer Verwalterin oder eines Verwalters zum jeweiligen
            Monatsende kündigen. Anderenfalls verlängert sich der Vertrag jeweils um einen Monat.

            3.2 Sperrung des Nutzers oder der Nutzerin
            3.2.1
            SCALARA ist jederzeit aus sachlichem Grund und für die Dauer des Vorliegens des sachlichen Grundes, d.h. bis zu
            dessen Beseitigung, berechtigt, den Zugriff von Nutzer:innen auf die SCALARA-Plattform zu sperren. Ein
            sachlicher Grund liegt dann vor, wenn sie oder er gegen diese Nutzungsbedingungen, gegen ihre oder seine
            Sorgfaltspflichten im Umgang mit den Zugangsdaten, gegen anwendbares Recht beim Zugang oder der Nutzung der
            SCALARA-Plattform verstoßen hat oder ein wichtiger Grund vorliegt, der SCALARA zur Kündigung berechtigen würde.

            3.2.2
            Für die Dauer einer Sperrung sowie im Fall der Vertragsbeendigung können Nutzer:innen keine Dienste oder
            Leistungen nutzen und auch keine auf der SCALARA-Plattform gespeicherten Daten abrufen.

            § 4 Gewährleistung
            4.1 Verfügbarkeit der SCALARA-Plattform
            SCALARA übernimmt angemessene Maßnahmen, um die Verfügbarkeit der Leistungen nach diesem Vertrag zu ermöglichen
            und den Betrieb aufrechtzuerhalten. Gleichwohl kann es vorkommen, dass die SCALARA-Plattform zeitweilig nicht
            zur Verfügung steht, z.B. wegen eines Ausfalls der Internetverbindung, Hardwareproblemen oder Wartungsarbeiten.
            SCALARA bemüht sich, die Verfügbarkeit schnellstmöglich wiederherzustellen, soweit sich die Ursache für die
            Nichtverfügbarkeit im eigenen Einflussbereich befindet. SCALARA gewährleistet keine bestimmte Verfügbarkeit oder
            Erreichbarkeit der SCALARA-Plattform. Über geplante Wartungsarbeiten, die die Dienste beeinträchtigen können,
            wird SCALARA rechtzeitig informieren.

            4.2 Verfügbarkeit für zahlende Nutzer:innen
            4.2.1
            Soweit Verwalter:innen SCALARA kostenpflichtig nutzen, schuldet SCALARA eine Verfügbarkeit der Plattform von 95%
            berechnet im Monatsmittel.

            4.2.2
            Nichtverfügbarkeiten der Plattform zum Einspielen von Updates und anderen Pflegemaßnahmen wird SCALARA möglichst
            nachts oder am Wochenende durchführen und vorher ankündigen. Eine solche Nichterreichbarkeit lässt die
            Erreichbarkeit nach Ziffer 4.2.1 unberührt.

            4.2.3
            Bei Nichteinhalten der geschuldeten Verfügbarkeit nach Ziffer 4.2.1 kann der Nutzer oder die Nutzerin die
            Vergütung für diesen Monat mindern.

            4.2.4
            Weitergehende Ansprüche sind ausgeschlossen.

            § 5 Haftung von SCALARA
            5.1
            SCALARA haftet für Vorsatz und grobe Fahrlässigkeit sowie nach dem Produkthaftungsgesetz und für die einfach
            fahrlässige Verletzung von Leib, Leben oder Gesundheit unbeschränkt.

            5.2
            Die Haftung für die einfach fahrlässige Verletzung von Vertragspflichten, deren Erfüllung die ordnungsgemäße
            Durchführung des Vertrags überhaupt erst ermöglicht und auf deren Einhaltung der Vertragspartner regelmäßig
            vertraut und vertrauen darf (Kardinalpflichten) ist auf den typischerweise vorhersehbaren Schaden beschränkt.

            § 6 Datenschutz
            6.1
            Hinweise bezüglich der Verarbeitung von Daten im Zusammenhang mit der Nutzung der SCALARA-Plattform können den
            Datenschutzhinweisen entnommen werden.

            6.2
            Die Einhaltung geltenden Datenschutzrechts bei der Erstellung und Verwaltung von Nutzer:innenkontakten und der
            Versendung von Einladungslinks obliegt dem Immobilienverwalter.

            § 7 Schlussbestimmungen
            7.1 Gerichtsstand
            Mit Unternehmern/Kaufleuten und mit juristischen Personen des öffentlichen Rechts wird soweit möglich der
            Gerichtsstand Köln vereinbart. Außerdem ist Köln ausschließlicher Gerichtsstand, wenn Nutzer:innen keinen
            allgemeinen Gerichtsstand in Deutschland haben oder nach Begründung des Rechtsverhältnisses den Wohnsitz oder
            gewöhnlichen Aufenthaltsort aus Deutschland heraus verlegen. Diese Regelung gilt zudem für den Fall, dass der
            Wohnsitz oder gewöhnliche Aufenthalt von Nutzer:innen nicht bekannt ist.

            7.2 Rechtswahl
            Es findet das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts Anwendung. Die
            gesetzlichen Vorschriften zur Beschränkung der Rechtswahl und zur Anwendbarkeit zwingender Vorschriften
            insbesondere des Staates, in dem Nutzer:innen als Verbraucher ihren gewöhnlichen Aufenthalt haben, bleiben
            unberührt.

            7.3 Salvatorische Klausel
            Der Vertrag bleibt auch bei rechtlicher Unwirksamkeit einzelner Punkte in seinen übrigen Teilen verbindlich.
            Anstelle der unwirksamen Punkte treten, soweit vorhanden, die gesetzlichen Vorschriften.

            Stand: Januar 2021

            Auftragsverarbeitungsvereinbarung
            nach Art. 28 Abs. 3 Datenschutzgrundverordnung (DSGVO)

            zwischen einer/einem Verwalter:in (nachfolgend Auftraggeberingenannt)
            und
            der SCALARA digital GmbH (nachfolgend SCALARA oder Auftragnehmeringenannt).

            § 1 Präambel
            SCALARA stellt den Nutzer:innen die SCALARA-Plattform für die Kommunikation zwischen Verwalter:innen,
            Mieter:innen, Eigentümer:innen und Dienstleister:innen zur Verfügung. Die Auftraggeberin ist Verwalter:in und
            nutzt die SCALARA-Plattform insbesondere für die Verwaltung von Kontakten und die Kommunikation mit
            Bewohner:innen, Eigentümer:innen und Dienstleister:innen.

            SCALARA verarbeitet aufgrund des zwischen den Parteien bestehenden Vertragsverhältnisses der Nutzungsbedingungen
            und ggfs. anderen zwischen den Parteien abgeschlossenen Vereinbarungen (nachfolgend „Auftragsverhältnis“
            genannt) personenbezogene Daten im Auftrag der Auftraggeberin.

            Die Parteien schließen daher diese Auftragsverarbeitungsvereinbarung (nachfolgend „Vereinbarung“ genannt), um
            die rechtmäßige Verarbeitung von personenbezogenen Daten durch SCALARA zu gewährleisten. Die Vereinbarung
            konkretisiert insoweit die datenschutzrechtlichen Verpflichtungen von SCALARA.

            Dies vorausgeschickt vereinbaren die Parteien was folgt:

            § 2 Anwendungsbereich
            2.1
            SCALARA verarbeitet personenbezogene Daten im Auftrag und auf Weisung der Auftraggeberin zur Erfüllung der ihr
            aufgrund des Auftragsverhältnisses obliegenden Leistungspflichten. Die personenbezogenen Daten sind in Annex 1
            beschrieben (nachfolgend: „Daten“).

            2.2
            Art, Umfang und Zweck der Datenverarbeitung, die Datenverarbeitung selbst und der Kreis der betroffenen Personen
            ergibt sich aus Annex 1.

            2.3
            SCALARA verarbeitet die Daten nach den Regelungen des Auftragsverhältnisses, dieser Vereinbarung und auf
            dokumentierte Weisung der Auftraggeberin hin.

            2.4
            Ist SCALARA der Ansicht, eine Weisung der Auftraggeberin verstoße gegen die Datenschutzgrundverordnung („DSGVO“)
            oder gegen andere Datenschutzbestimmungen der Europäischen Union oder deren Mitgliedstaaten, wird sie die
            Auftraggeberin darauf hinweisen. SCALARA ist in diesen Fällen berechtigt, die Durchführung der Weisung
            auszusetzen, bis die Auftraggeberin die Weisung bestätigt oder abändert.

            § 3 Pflichten der Auftraggeberin
            3.1
            Die Auftraggeberin ist im Rahmen dieser Vereinbarung für die Einhaltung der gesetzlichen Bestimmungen der
            Datenschutzgesetze, insbesondere für die Rechtmäßigkeit der Datenweitergabe an SCALARA sowie für die
            Rechtmäßigkeit der Datenverarbeitung und die Wahrung der Rechte der Betroffenen verantwortlich
            („Verantwortlicher“ im Sinne des Art. 4 Nr. 7 DSGVO). Sollten Dritte gegen SCALARA aufgrund der Verarbeitung
            ihrer Daten Ansprüche geltend machen, wird die Auftraggeberin SCALARA von allen solchen Ansprüchen auf erstes
            Anfordern freistellen.

            3.2
            Die Auftraggeberin ist Inhaberin aller etwaigen erforderlichen Rechte, welche die Daten betreffen.

            3.3
            Die Auftraggeberin hat SCALARA unverzüglich und vollständig zu informieren, wenn sie Fehler oder
            Unregelmäßigkeiten im Zusammenhang mit der Verarbeitung der Daten durch SCALARA im Rahmen dieser Vereinbarung
            oder seiner Weisungen feststellt.

            § 4 Pflichten von SCALARA
            4.1
            SCALARA verarbeitet die Daten im Rahmen des Hauptvertrags, dieser Vereinbarung sowie der speziellen
            Einzelweisungen der Auftraggeberin. Sie ist nicht berechtigt, die Daten unbefugt an Dritte weiterzugeben. Dies
            gilt nicht, wenn dies

            4.1.1
            im Einklang mit der Vereinbarung und dem Auftragsverhältnis geschieht,

            4.1.2
            von der Auftraggeberin schriftlich verlangt wird oder

            4.1.3
            aufgrund gesetzlicher oder rechtlicher Anforderungen erforderlich ist.

            4.2
            SCALARA wird in Fällen der Ziffer 4.1.3, soweit dies das anwendbare Recht zulässt, die Auftraggeberin vorab über
            die beabsichtigte Weitergabe informieren und sich mit dieser abstimmen. SCALARA stellt sicher, dass alle
            Personen, die Zugang zu den Daten haben, diese entsprechend den Weisungen der Auftraggeberin verarbeiten.

            4.3
            Die Auftraggeberin unterstützt SCALARA bei Kontrollen durch die Aufsichtsbehörden im Rahmen des Zumutbaren und
            Erforderlichen, soweit diese Kontrollen die Datenverarbeitung durch SCALARA betreffen. SCALARA wird der
            Auftraggeberin die Informationen zur Verfügung stellen, die dieser benötigt, um nachzuweisen, dass er
            hinsichtlich dieser Auftragsverarbeitung die Anforderungen des anwendbaren Datenschutzrechts erfüllt hat.

            4.4
            SCALARA unterstützt die Auftraggeberin außerdem unter Berücksichtigung der Art der Datenverarbeitung und der ihm
            zur Verfügung stehenden Informationen auf Anforderung bei der Einhaltung folgender Pflichten:

            4.4.1
            Gewährleistung der Sicherheit der Verarbeitung personenbezogener Daten,

            4.4.2
            Meldung der Verletzung des Schutzes personenbezogener Daten an Aufsichtsbehörden und betroffene Personen,

            4.4.3
            ggfs. Durchführung einer Datenschutzfolgenabschätzung, soweit die Datenverarbeitung durch SCALARA davon
            betroffen ist,

            4.4.4
            ggfs. Durchführung einer erforderlichen vorherigen Konsultation der Datenschutzbehörde, soweit die
            Datenverarbeitung durch SCALARA davon betroffen ist.

            4.5
            SCALARA informiert die Auftraggeberin unverzüglich, wenn ihr ein Verstoß gegen das Datenschutzrecht im Rahmen
            ihrer Auftragsverarbeitung für SCALARA bekannt wird.

            4.6
            SCALARA verpflichtet die bei der Verarbeitung der Daten beschäftigten Personen auf den vertraulichen Umgang mit
            den Daten.

            4.7
            Für die Mitwirkungsleistungen nach Ziffern 4.3 und 4.4 kann SCALARA eine angemessene Vergütung verlangen. Für
            die Mitwirkungsleistung nach Ziffer 4.4.2 allerdings nicht, wenn die Verletzung auf ihrem Verschulden beruht.

            § 5 Technisch-organisatorische Maßnahmen
            5.1
            SCALARA trifft die in Annex 2 definierten technischen und organisatorischen Maßnahmen vor Beginn der
            Datenverarbeitung.

            5.2
            Die technischen und organisatorischen Maßnahmen unterliegen dem technischen Fortschritt und der
            Weiterentwicklung. Insoweit kann SCALARA alternative, adäquate Maßnahmen umzusetzen. Änderungen sind zu
            dokumentieren und die Dokumentationen sind dem Auftraggeber auf Anfrage zur Verfügung zu stellen. Wesentliche
            Änderungen sind der Auftraggeberin schriftlich anzuzeigen. Im Falle einer wesentlichen Änderung ist Annex 2
            entsprechend anzupassen.

            § 6 Kontrollen
            6.1
            Die Auftraggeberin überzeugt sich auf eigene Kosten vor Beginn der Datenverarbeitung durch SCALARA und sodann
            regelmäßig, von den umgesetzten technischen und organisatorischen Maßnahmen nach Annex 2 und dokumentiert das
            jeweilige Ergebnis. Die Auftraggeberin ist zudem berechtigt, die Auftragskontrolle im Benehmen mit SCALARA im
            erforderlichen Umfang durchzuführen oder durch im Einzelfall zu benennende neutrale Dritte durchführen zu
            lassen. Kontrollen sind rechtzeitig im Vorfeld anzumelden und erfolgen während der Geschäftszeiten von SCALARA.
            Die Auftraggeberin wird hierbei auf betriebliche Abläufe von SCALARA angemessen Rücksicht nehmen.

            6.2
            SCALARA verpflichtet sich, der Auftraggeberin auf Anforderung die zur Durchführung einer umfassenden
            Auftragskontrolle erforderlichen Auskünfte zu geben und die entsprechenden Nachweise verfügbar zu machen. Der
            Nachweis der Umsetzung geeigneter Maßnahmen kann auch durch Vorlage aktueller Testate sowie von Berichten
            unabhängiger Prüfer (Wirtschaftsprüfer, Revision, Datenschutzbeauftragter, IT-Sicherheitsabteilung, etc.)
            erbracht werden. In diesem Fall ist eine Vor-Ort-Kontrolle durch die Auftraggeberin ausgeschlossen.

            6.3
            Beauftragt die Auftraggeberin einen Dritten mit der Durchführung der Kontrolle, hat die Auftraggeberin den
            Dritten schriftlich gegenüber SCALARA auf die Verschwiegenheit und Geheimhaltung zu verpflichten, es sei denn,
            dass der Dritte einer beruflichen Verschwiegenheitspflicht unterliegt. Auf Verlangen von SCALARA wird ihr die
            Auftraggeberin die Verschwiegenheitsverpflichtung unverzüglich vorlegen. Die Auftraggeberin darf keinen
            Konkurrenten von SCALARA mit der Kontrolle beauftragen.

            6.4
            SCALARA kann für ihren Aufwand bei der Durchführung der Kontrollen eine angemessene Vergütung verlangen.

            § 7 Unterauftragsverhältnisse
            7.1
            SCALARA darf Unterauftragsverhältnisse hinsichtlich der Verarbeitung der Daten begründen. Dies gilt insbesondere
            hinsichtlich des Hostings der SCALARA-Plattform durch Amazon Web Services. Die Unterauftragnehmer und deren
            jeweilige Tätigkeitsbereiche sind in Annex 3 genannt. Diese gelten mit Unterzeichnung dieser Vereinbarung als
            von der Auftraggeberin akzeptiert.

            7.2
            SCALARA wird die Auftraggeberin über jede beabsichtigte Änderung eines Unterauftragnehmers oder einen neuen
            Unterauftragnehmer unterrichten.

            7.3
            SCALARA wird die in dieser Vereinbarung festgelegten Verpflichtungen, einschließlich der Gewährleistung der
            technischen und organisatorischen Maßnahmen, an ihre Unterauftragnehmer weitergeben. Die technischen und
            organisatorischen Maßnahmen müssen den Anforderungen des anwendbaren Datenschutzrechts entsprechen.

            7.4
            SCALARA wird mit den Unterauftragnehmern eine Vertraulichkeits- bzw. Geheimhaltungsvereinbarung treffen, wenn
            diese nicht einer gesetzlichen Vertraulichkeits- bzw. Geheimhaltungspflicht unterliegen.

            § 8 Rechte von betroffenen Personen
            8.1
            Die Rechte betroffener Personen sind gegenüber der Auftraggeberin geltend zu machen.

            8.2
            Soweit eine betroffene Person ihre Rechte gegenüber SCALARA geltend macht, wird diese das Ersuchen zeitnah an
            die Auftraggeberin weiterleiten.

            8.3
            Soweit eine betroffene Person ihre Rechte gegenüber der Auftraggeberin geltend macht, wird SCALARA der
            Auftraggeberin mit geeigneten technischen und organisatorischen Maßnahmen bei der Erfüllung dieser Ansprüche
            angemessen und im erforderlichen Umfang unterstützen, wenn die Auftraggeberin den Anspruch ohne die
            Unterstützung von SCALARA nicht erfüllen kann.

            8.4
            SCALARA kann für die Unterstützungshandlungen nach § 8 dieser Vereinbarung eine angemessene Vergütung verlangen.

            § 9 Datenschutzbeauftragter
            SCALARA hat keinen Datenschutzbeauftragten benannt.

            § 10 Haftung
            10.1
            Für die Verletzung von datenschutzrechtlichen Vorschriften und der Regelungen dieser Vereinbarung haftet SCALARA
            gegenüber dem Auftraggeber

            10.2
            bei Vorsatz und grober Fahrlässigkeit sowie bei einer Verletzung des Lebens, des Körpers oder der Gesundheit
            unbegrenzt;

            10.3
            bei einfacher Fahrlässigkeit nur, soweit sie eine Pflicht verletzt, deren Erfüllung die ordnungsgemäße
            Durchführung des Vertrags überhaupt erst ermöglicht und auf deren Einhaltung der Auftraggeber regelmäßig
            vertraut und vertrauen darf.

            10.4
            Sollte SCALARA aufgrund einer Verletzung von Datenschutzgesetzen durch den Auftraggeber von Dritten in Anspruch
            genommen werden, stellt der Auftraggeber SCALARA auf erstes Anfordern von der Haftung frei. Außerdem steht der
            Auftraggeber SCALARA bei der Rechtsverteidigung im erforderlichen Umfang bei und erstattet SCALARA alle aus dem
            Vorfall entstandenen Schäden, einschließlich der angemessenen Kosten einer Rechtsverteidigung.

            § 11 Vertragsdauer und Rückgabe bzw. Löschung der Daten
            11.1
            Soweit nichts anderes vereinbart ist, tritt die Vereinbarung mit Abschluss durch die Parteien sofort in Kraft
            und läuft auf unbestimmte Zeit. Die Vereinbarung endet mit Beendigung des Auftragsverhältnisses, ohne dass es
            einer gesonderten Kündigung bedarf.

            11.2
            Die Parteien werden bei Bedarf angemessene Überleitungsregelungen vereinbaren, um die Ordnungsmäßigkeit der
            zugrundeliegenden Verarbeitungsprozesse ggf. auch über das Ende des Hauptvertrags hinaus sicherzustellen.

            11.3
            SCALARA ist verpflichtet, Daten, Originaldatenträger bzw. Unterlagen, die vom der Auftraggeberin im Rahmen
            dieser Vereinbarung an sie übergeben bzw. übermittelt wurden, nach (i) Beendigung der Vereinbarung oder (ii)
            nach Aufforderung der Auftraggeberin (je nachdem, was früher eintritt) entweder

            11.3.1
            an die Auftraggeberin auszuhändigen,

            11.3.2
            an diese zurück zu übermitteln oder

            11.3.3
            nach vorheriger Weisung der Auftraggeberin datenschutzgerecht zu vernichten bzw. so von allen
            Datenspeichergeräten von SCALARA zu löschen, dass diese Daten während oder nach der Entfernung nicht
            wiederherzustellen sind.

            11.4
            SCALARA wird der Auftraggeberin auf Anforderung schriftlich bestätigen, dass die in dieser Ziff. 11.3
            beschriebenen Maßnahmen ordnungsgemäß ausgeführt wurden.

            11.5
            Dokumentation, die dem Nachweis der auftrags- und ordnungsgemäßen Datenverarbeitung dient, ist durch SCALARA
            entsprechend den jeweils maßgeblichen Aufbewahrungsfristen über die Laufzeit der Vereinbarung hinaus
            aufzubewahren. Gleiches gilt für sonstige Unterlagen, die rechtlichen Aufbewahrungspflichten (z.B. aus dem
            Steuerrecht) unterliegen.

            § 12 Sonstiges
            12.1
            Sollten die Daten der Auftraggeberin bei SCALARA durch Pfändung oder Beschlagnahme, durch ein Insolvenz- oder
            Vergleichsverfahren oder durch sonstige Ereignisse oder Maßnahmen Dritter gefährdet werden, so hat SCALARA der
            Auftraggeberin unverzüglich darüber zu informieren. SCALARA wird alle in diesem Zusammenhang verantwortlichen
            Dritten unverzüglich darüber informieren, dass die Hoheit und das Eigentum an den Daten ausschließlich bei der
            Auftraggeberin als „Verantwortlichem“ im Sinne der DSGVO liegen.

            12.2
            Bei Änderungen der tatsächlichen Ausgestaltung der Leistungsbeziehungen zwischen den Parteien werden die
            Parteien die Anlagen entsprechend anpassen und einvernehmlich austauschen. Mit Unterzeichnung der geänderten
            Anlage durch die Parteien wird diese wirksam und ersetzt insoweit die bislang geltende Anlage.

            12.3
            Auf die Vereinbarung findet das Recht der Bundesrepublik Deutschland Anwendung. Gerichtsstand für alle
            Streitigkeiten in Zusammenhang mit dieser Vereinbarung ist Köln.

            12.4
            Änderungen oder Ergänzungen der Vereinbarung bedürfen der Schriftform. Dies gilt für Änderung oder Aufhebung des
            vorstehenden Schriftformerfordernisses entsprechend. Nebenabreden wurden nicht getroffen.

            12.5
            Sollten einzelne Bestimmungen dieser Vereinbarung unwirksam sein oder werden, bleibt die Wirksamkeit der
            Vereinbarung im Übrigen unberührt. An die Stelle der unwirksamen Bestimmung tritt eine wirksame Regelung, die in
            ihrem wirtschaftlichen Gehalt der unwirksamen Bestimmung möglichst nahe kommt. Entsprechendes gilt im Falle von
            Regelungslücken.

            Annex 1: Daten, Betroffene, Datenverarbeitungen und Zweck der Datenverarbeitung
            Annex 2: Technische und organisatorische Maßnahmen
            Annex 3: Genehmigte Unterauftragnehmer und Tätigkeitsbereiche des Unterauftragnehmers
            Annex 1: Betroffene, Daten, Datenverarbeitungen und Zweck der Datenverarbeitung
            SCALARA verarbeitet die personenbezogenen Daten folgender betroffener Personen:
            Mieter der vom Auftraggeber verwalteten Immobilien
            Eigentümer der vom Auftraggeber verwalteten Immobilien
            Geschäftspartner, Lieferanten oder Dienstleister des Auftraggebers
            SCALARA verarbeitet im Rahmen des Auftragsverhältnisses die folgenden personenbezogenen Daten:
            Name, Anschrift, Telefonnummern, E-Mail-Adressen.
            Zuordnung von Wohnungen zu Mietern und Eigentümern.
            Kommunikationsinhalte (z.B. Meldung von Mietmängeln) mit den Mietern, Eigentümern sowie Dienstleistern und
            weitere Geschäftspartner.
            Wohnungs- und gebäudebezogene Dokumente: z.B. Mietverträge, Abrechnungen, Energieausweise etc.

            SCALARA erbringt folgende Verarbeitungen für folgendeZwecke:
            Bereitstellung der Funktionalitäten der SCALARA-Plattform.

            Annex 2: Technische und organisatorische Maßnahmen (Stand Mai 2020)
            SCALARA ist zur Sicherstellung des Datenschutzes verpflichtet. Daher hat sie im Rahmen der zur
            Verfügungsstellung der SCALARA-Plattform die folgenden technischen und organisatorischen Maßnahmen während der
            Laufzeit des Vertrages zu ergreifen und aufrechtzuerhalten. Da die SCALARA-Plattform hauptsächlich durch den
            eingesetzten Unterauftragnehmer, der Amazon Web Services, technisch gehostet und zur Verfügung gestellt wird,
            basieren die technischen organisatorischen Maßnahmen (TOM) von SCALARA auf den TOMs des genannten
            Unterauftragnehmers. Im Rahmen seiner Rolle als Auftragsverarbeiter stellt SCALARA insofern sicher, dass diese
            TOMs umgesetzt und eingehalten werden. Dies sind im Einzelnen:

            § 1 Verschlüsselung
            Die Passwörter der Nutzer:innen werden über bcrypt verschlüsselt

            § 13 Überwachung und Protokollierung
            Ausführliche Informationen zu Abläufen im Netzwerk mit Amazon VPC-FlowLogs

            § 14 Zugriffskontrolle
            Ausführliche Informationen zu Abläufen im Netzwerk mit Amazon VPC-FlowLogs

            Annex 3: Genehmigte Unterauftragnehmer und Tätigkeitsbereiche des Unterauftragnehmers
            1. Amazon Web Services EMEA SARL, 38 Avenue John F. Kennedy, L-1855 Luxembourg“
        </div>

    </div>
</div>
