import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbItem } from 'carbon-components-angular';
import { Subject, combineLatest, switchMap, takeUntil, tap } from 'rxjs';
import { formatDateWithoutHhMmSs } from 'src/app/core/utils/common';
import { FullOesDto, OperationsExpenseStatementsService } from 'src/app/generated-sources/accounting';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { ActivateOesComponent } from '../activate-oes/activate-oes.component';
import { DeleteOperationsExpenseStatementsComponent } from '../delete-operations-expense-statements/delete-operations-expense-statements.component';
import { EditOperationExpenseStatementComponent } from '../edit-operation-expense-statement/edit-operation-expense-statement.component';

type TabsToShow = 'CONSUMPTION' | 'DISTRIBUTION_KEY';
@Component({
    selector: 'app-operations-expense-statements-details',
    templateUrl: './operations-expense-statements-details.component.html',
    styleUrls: ['./operations-expense-statements-details.component.scss'],
})
export class OperationsExpenseStatementsDetailsComponent implements OnInit {
    private unsubscribe$ = new Subject<void>();
    public isLoading = false;
    public operationExpenseStatement?: FullOesDto;
    public triggerTabRefreshValue = 1;

    public ledgerId = '';
    public oesId = '';

    public breadcrumbs: BreadcrumbItem[] = [];

    //  we want to render chosen tabs only when they are opened
    public tabsToShow: TabsToShow[] = [];

    public oesDate = '';
    public oesActivationDate = '';
    public oesLengthInDays = 0;
    public oesStatus?: FullOesDto.StatusEnum;

    public constructor(
        private route: ActivatedRoute,
        private operationsExpenseStatementsService: OperationsExpenseStatementsService,
        private overlayService: OverlayService
    ) {}

    public ngOnInit(): void {
        this.init();
    }

    public init(): void {
        combineLatest([this.route.parent!.paramMap, this.route.paramMap])
            .pipe(
                tap((params) => {
                    this.ledgerId = String(params[0].get('id'));
                    this.oesId = String(params[1].get('oesId'));
                }),
                switchMap(() => this.operationsExpenseStatementsService.findOne(this.ledgerId, this.oesId)),
                tap((operationExpenseStatement) => {
                    this.operationExpenseStatement = operationExpenseStatement;
                    this.oesDate =
                        formatDateWithoutHhMmSs(this.operationExpenseStatement?.startDate) +
                        ' – ' +
                        formatDateWithoutHhMmSs(this.operationExpenseStatement?.endDate);

                    this.oesLengthInDays = this.operationExpenseStatement.lengthInDays;
                    this.oesStatus = this.operationExpenseStatement.status;
                    this.oesActivationDate = formatDateWithoutHhMmSs(this.operationExpenseStatement.activationDate);
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => {
                this.refreshTabs();
                this.breadcrumbs = [
                    {
                        content: 'Betriebskostenabrechnung',
                        route: [`/accounting/ledger/${this.ledgerId}/operations-expense-statements`],
                    },
                    {
                        content: this.oesDate,
                        route: [
                            `/accounting/ledger/${this.ledgerId}/operations-expense-statements/${this.operationExpenseStatement?.id}`,
                        ],
                        current: true,
                    },
                ];
            });
    }

    public refreshTabs(): void {
        this.triggerTabRefreshValue = this.triggerTabRefreshValue + 1;
    }

    public openDeleteOverlay(): void {
        const ref = this.overlayService.open(DeleteOperationsExpenseStatementsComponent, {
            data: {
                ledgerId: this.ledgerId,
                startDate: this.operationExpenseStatement?.startDate,
                endDate: this.operationExpenseStatement?.endDate,
                id: this.operationExpenseStatement?.id,
            },
        });

        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
    }

    public onTabSelected(tab: TabsToShow): void {
        if (this.tabsToShow.includes(tab)) {
            return;
        }

        this.tabsToShow.push(tab);
    }

    public openEditOverlay(): void {
        this.tabsToShow = [];
        const ref = this.overlayService.open(EditOperationExpenseStatementComponent, {
            data: { ledgerId: this.ledgerId, oesId: this.oesId },
        });

        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.init());
    }

    public openActiveOesOverlay(): void {
        const data = {
            ledgerId: this.ledgerId,
            oesId: this.oesId,
            oesDateFrom: this.operationExpenseStatement?.startDate,
            oesDateTo: this.operationExpenseStatement?.endDate,
        };
        const ref = this.overlayService.open(ActivateOesComponent, {
            data,
        });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.init());
    }
}
