<ibm-loading *ngIf="isInitialLoading" [isActive]="isInitialLoading" [size]="'normal'" [overlay]="isInitialLoading">
</ibm-loading>

<div ibmGrid class="bx--grid--full-width">
    <div ibmRow>
        <div ibmCol></div>
        <div ibmCol class="s-head-28-36-bold mb-06" [columnNumbers]="{ md: 10, lg: 10 }">
            {{ 'ACCOUNTING.ADD_LEDGER_FORM.HEADLINE' | translate }}
        </div>
        <div ibmCol></div>
    </div>

    <div ibmRow>
        <div ibmCol></div>
        <div ibmCol class="mb-07" [columnNumbers]="{ md: 10, lg: 10 }">
            <div class="d-flex align-items-center">
                <svg-icon class="mr-0-75r icon icon__purple" src="/assets/icons/24_attention.svg"></svg-icon>
                <div>{{ 'ACCOUNTING.ADD_LEDGER_FORM.INFO_TEXT' | translate }}</div>
            </div>
        </div>
        <div ibmCol></div>
    </div>

    <div ibmRow class="mb-2r">
        <div ibmCol></div>
        <div ibmCol [columnNumbers]="{ md: 10, lg: 10 }">
            <div class="s-head-14-22-bold mb-0-5r">{{ 'ACCOUNTING.ADD_LEDGER_FORM.OPEN_LEDGER' | translate }}</div>
            <div>{{ 'ACCOUNTING.ADD_LEDGER_FORM.OPEN_LEDGER_SEV_NOTE' | translate }}</div>
        </div>
        <div ibmCol></div>
    </div>

    <form (submit)="onSubmit()" (keydown.enter)="$event.preventDefault()" [formGroup]="form">
        <div ibmRow>
            <div ibmCol></div>
            <div ibmCol [columnNumbers]="{ md: 10, lg: 10 }">
                <div ibmRow class="mb-3-5r">
                    <div ibmCol>
                        <app-combo-box
                            [size]="'xl'"
                            id="property"
                            placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT' | translate }}"
                            [appendInline]="true"
                            label="{{ 'ACCOUNTING.ADD_LEDGER_FORM.LABEL_PROPERTY' | translate }} *"
                            [items]="currentProperties"
                            (selected)="onSelectProperty($event)"
                            (search)="invalidatePropertyId()"
                        >
                            <ibm-dropdown-list></ibm-dropdown-list>
                        </app-combo-box>
                    </div>
                </div>
                <div
                    ibmRow
                    *ngIf="
                        !isLoading &&
                        selectedPotentialLedgerProperty &&
                        selectedLedgerType === 'SEV' &&
                        relatedOwnerships.length === 0
                    "
                    ibmRow
                    class="mb-2-5r"
                >
                    <div ibmCol class="icon icon--info d-flex align-items-center">
                        <svg-icon src="/assets/icons/24_information.svg"></svg-icon>
                        <div class="s-body-14-22-regular ml-0-5r">
                            {{ 'ACCOUNTING.ADD_LEDGER_FORM.INFO_TEXT_NO_AVAILABLE_UNITS' | translate }}
                        </div>
                    </div>
                </div>
                <div
                    ibmRow
                    *ngIf="
                        selectedPotentialLedgerProperty &&
                        !isTileSelectionVisible &&
                        (selectedPropertyType === 'WEG' || selectedLedgerType === 'WEG')
                    "
                    class="mb-2-5r"
                >
                    <div ibmCol class="icon icon--info d-flex align-items-start">
                        <svg-icon src="/assets/icons/24_information.svg"></svg-icon>
                        <div class="s-body-14-22-regular ml-0-5r">
                            {{
                                (selectedPropertyType === 'WEG' && selectedLedgerType === 'SEV'
                                    ? 'ACCOUNTING.ADD_LEDGER_FORM.INFO_TEXT_WEG_LEDGER_EXISTS'
                                    : 'ACCOUNTING.ADD_LEDGER_FORM.INFO_TEXT_SEV_LEDGER_EXISTS'
                                ) | translate
                            }}
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="selectedPotentialLedgerProperty && relatedOwnerships.length">
                    <div *ngIf="isTileSelectionVisible" class="mb-3-5r">
                        <app-radio-tile-selector
                            [tileSelectorOptions]="tileSelectorOptions"
                            (optionSelected)="switchLedgerTypeSelection($event)"
                        ></app-radio-tile-selector>
                    </div>
                    <div ibmRow *ngIf="selectedLedgerType !== 'WEG'" class="mb-2-5r">
                        <div ibmCol>
                            <app-combo-box
                                [size]="'xl'"
                                id="property"
                                placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT' | translate }}"
                                [appendInline]="true"
                                label="{{ 'ACCOUNTING.ADD_LEDGER_FORM.LABEL_UNIT' | translate }}*"
                                [items]="relatedOwnerships"
                                (selected)="onSelectRelatedOwnership($event)"
                            >
                                <ibm-dropdown-list></ibm-dropdown-list>
                            </app-combo-box>
                        </div>
                    </div>
                </ng-container>

                <div ibmRow *ngIf="selectedLedgerType !== 'WEG' && relatedOwnershipsTable.length" class="mb-2-5r">
                    <div ibmCol class="related-ownerships-multi__wrapper">
                        <div
                            class="related-ownerships-multi__label bx--label"
                            [ngClass]="{ 'extra-margin': isTileSelectionVisible }"
                        >
                            {{ 'ACCOUNTING.ADD_LEDGER_FORM.LABEL_RELATED_UNITS' | translate }} <br />
                            <span *ngIf="isTileSelectionVisible">
                                {{ 'ACCOUNTING.ADD_LEDGER_FORM.LABEL_RELATED_UNITS_2' | translate }}
                            </span>
                        </div>
                        <app-table-multi-selector
                            [header]="relatedOWnershipsTableHeader"
                            [data]="relatedOwnershipsTable"
                            [emptyText]="'ACCOUNTING.ADD_LEDGER_FORM.INFO_TEXT_NO_AVAILABLE_UNITS'"
                            (rowSelected)="onRowClick($event)"
                            #TableMultiSelectorComponent
                            class="w-100"
                            [isLoading]="!isInitialLoading && isLoading"
                        >
                        </app-table-multi-selector>
                    </div>
                </div>
                <div
                    ibmRow
                    *ngIf="
                        !isLoading &&
                        selectedLedgerType !== 'WEG' &&
                        selectedRelatedOwnership &&
                        relatedOwnershipsTable.length === 0
                    "
                >
                    <div ibmCol class="icon icon--info d-flex align-items-start">
                        <svg-icon src="/assets/icons/24_information.svg"></svg-icon>
                        <div class="s-body-14-22-regular ml-0-5r">
                            {{ 'ACCOUNTING.ADD_LEDGER_FORM.INFO_TEXT_NO_AVAILABLE_UNITS' | translate }}
                        </div>
                    </div>
                </div>

                <div ibmRow *ngIf="isLoading" class="pos-relative">
                    <div class="is-loading">
                        <div class="d-flex align-items-center justify-content-center mt-4r">
                            <ibm-inline-loading> </ibm-inline-loading>
                        </div>
                    </div>
                </div>

                <!-- START BUTTON ROW -->
                <div ibmRow class="mt-11">
                    <div ibmCol>
                        <button [ibmButton]="'tertiary'" (click)="abort()">
                            {{ 'COMMON.BTN_CANCEL' | translate }}
                        </button>
                    </div>

                    <div ibmCol class="text-right">
                        <button [ibmButton]="'primary'" [disabled]="form.invalid" type="submit">
                            {{ 'COMMON.BTN_SAVE' | translate }}
                        </button>
                    </div>
                </div>
                <!-- END BUTTON ROW -->

                <div class="p-10"></div>
            </div>
            <div ibmCol></div>
        </div>
    </form>
</div>
