<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<div *ngIf="showHeader" ibmRow class="pt-5r pb-1-5r pr-1r pl-1r justify-space-between">
    <app-headline-with-icon color="green" image="24_person.svg" text="Dienstleister"></app-headline-with-icon>
    <div class="d-flex justify-content-end">
        <app-search></app-search>

        <app-scalara-button
            *ngIf="isManagerOfThisProperty"
            variant="ghost"
            (click)="openAddOwnerOverlay()"
            svgName="24_add.svg"
            class="ml-1r"
            >{{ 'CONTROLS.BUTTONS.ADD' | translate }}</app-scalara-button
        >
    </div>
</div>

<div ibmRow>
    <div ibmCol>
        <app-table
            [emptyText]="'PAGES.RELATIONS.SERVICE_PROVIDER.LABEL_EMPTY_TABLE'"
            [header]="tableModel.header"
            [data]="tableModel.data"
            [isLoading]="!isInitialLoading && isLoading"
        >
        </app-table>
    </div>
</div>

<ng-template #actions let-data="data">
    <app-table-actions
        [data]="data.extraData.transferObject"
        (openDeleteRelationOvervlay)="openDeleteRelationOvervlay($event)"
        (openEditRelationOvervlay)="openEditRelationOvervlay($event)"
    ></app-table-actions>
</ng-template>
