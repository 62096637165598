<div
    class="tw-border-dashed tw-border-2 tw-border-scalaraGray-04 tw-mt-6 tw-p-2"
    [formGroup]="regenerateRentReceivablesFormGroup"
>
    <div class="tw-flex-row tw-s-head-16-24-bold tw-mb-2">
        {{ 'ACCOUNTING.REASONABILITY_CHECK.HEADLINE_REGEN_RENT_RECEIVABLES' | translate }}
    </div>
    <div class="tw-flex tw-justify-between tw-ml-2">
        <div class="tw-w-[500px]">
            <app-combo-box
                #comboBoxTypeBankAccount
                id="occupations"
                [appendInline]="true"
                label="{{ 'ENTITIES.OCCUPATION.LABEL_ENTITY' | translate }}*"
                [items]="(occupationsList$ | async) ?? []"
                formControlName="occupations"
                placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT' | translate }}"
                invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                (selected)="onSelectedOccupation($event)"
                (change)="onSelectedOccupation($event)"
                class="tw-w-[500px]"
            >
                <ibm-dropdown-list></ibm-dropdown-list>
            </app-combo-box>
        </div>

        <div class="tw-w-[170px]">
            <label class="bx--label"></label>
            <app-scalara-button
                class="mr-05"
                (click)="onSubmit()"
                [fullWidth]="true"
                svgName="24_technische-aussattung.svg"
                [disabled]="regenerateRentReceivablesFormGroup.invalid"
                >{{ 'CONTROLS.BUTTONS.CREATE' | translate }}</app-scalara-button
            >
        </div>
    </div>
    <!--  -->
</div>
