/**
 * SCALARA Accounting Module
 * The SCALARA Accounting API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface GuidedBookingTypeShortDto { 
    /**
     * Id of this GuidedBooking
     */
    id: number;
    category: GuidedBookingTypeShortDto.CategoryEnum;
    /**
     * Is compatible with bank transaction
     */
    bankTransactionCompatible: boolean;
    groupName: string;
    groupSubline: string;
    subgroupName: string;
    shortDescription: string;
    longDescription: string;
    warningMessage: string;
}
export namespace GuidedBookingTypeShortDto {
    export type CategoryEnum = 'EXPENSE' | 'REVENUE' | 'LIABILITY' | 'RECEIVABLE';
    export const CategoryEnum = {
        Expense: 'EXPENSE' as CategoryEnum,
        Revenue: 'REVENUE' as CategoryEnum,
        Liability: 'LIABILITY' as CategoryEnum,
        Receivable: 'RECEIVABLE' as CategoryEnum
    };
}


