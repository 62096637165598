<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<div ibmRow class="mt-1-5r mb-5r">
    <div ibmCol>
        <ibm-breadcrumb [items]="breadcrumbs" [noTrailingSlash]="true"> </ibm-breadcrumb>
    </div>
    <div ibmCol class="text-right">
        <div class="d-flex justify-content-end">
            <app-scalara-button
                *ngIf="!isLoading && !isDeletionHidden"
                [variant]="'ghost'"
                type="button"
                (click)="openCloseDistributionKeyOverlay()"
                svgName="24_delete.svg"
            >
                {{ 'PAGES.DISTRIBUTION_KEYS.DETAIL.REMOVE_BUTTON' | translate }}
            </app-scalara-button>
        </div>
    </div>
</div>

<div class="mb-4r" *ngIf="!isLoading">
    <div ibmRow class="mb-1r">
        <div ibmCol [columnNumbers]="{ md: 4, lg: 4 }" class="s-label-12-22-semibold">
            {{ 'ENTITIES.DISTRIBUTION_KEY.LABEL_DESCRIPTION' | translate }}
        </div>
        <div ibmCol class="s-head-18-26-bold">
            {{ isStandardKey ? distributionKeyBase : distributionKey?.description }}
        </div>
    </div>
    <div ibmRow class="mb-1r">
        <div ibmCol [columnNumbers]="{ md: 4, lg: 4 }" class="s-label-12-22-semibold">
            {{ 'ENTITIES.DISTRIBUTION_KEY.LABEL_DISTRIBUTION_BASE' | translate }}
        </div>
        <div ibmCol class="s-body-14-22-regular">
            {{ distributionKeyBase }} /
            {{
                distributionKey?.distributionBase === 'CONSUMPTION'
                    ? (distributionKey?.totalDistribution ?? 0 | eurocent)
                    : (distributionKey?.totalDistribution | number : '' : 'de-DE')
            }}
        </div>
    </div>
    <div ibmRow class="mb-1r">
        <div ibmCol [columnNumbers]="{ md: 4, lg: 4 }" class="s-label-12-22-semibold">
            {{ 'ENTITIES.DISTRIBUTION_KEY.LABEL_DISTRIBUTION_GROUP' | translate }}
        </div>
        <div ibmCol class="s-body-14-22-regular">
            {{ 'ENTITIES.DISTRIBUTION_KEY.' + distributionKey?.distributionGroup | translate }}
        </div>
    </div>
</div>
<div *ngIf="distributionKey">
    <div ibmRow class="mb-1-5r">
        <div ibmCol [columnNumbers]="{ md: 4, lg: 4 }" class="s-label-12-22-semibold">
            {{ 'ENTITIES.OWNERSHIP.LABEL_ENTITY_PLURAL' | translate }}
        </div>
        <div ibmCol [columnNumbers]="{ md: 4, lg: 4 }" class="s-label-12-22-semibold">
            {{ 'ENTITIES.DISTRIBUTION_KEY.LABEL_DISTRIBUTION_SHARE' | translate }}
        </div>
    </div>

    <div ibmRow class="mt-0-75r" *ngFor="let ownership of selectedOwnerships; let i = index">
        <div ibmCol [columnNumbers]="{ md: 4, lg: 4 }" class="s-body-14-22-regular">{{ ownership.name }}</div>
        <div
            *ngIf="sortedDistributionKeyOwnerships[i].distributionBaseShare !== null"
            ibmCol
            [columnNumbers]="{ md: 4, lg: 4 }"
            class="s-body-14-22-regular"
        >
            {{
                distributionKey.distributionBase === 'CONSUMPTION'
                    ? (sortedDistributionKeyOwnerships[i].distributionBaseShare ?? 0 | eurocent)
                    : (sortedDistributionKeyOwnerships[i].distributionBaseShare | number : '' : 'de-DE')
            }}
        </div>

        <div
            *ngIf="sortedDistributionKeyOwnerships[i].distributionBaseShare === null"
            ibmCol
            [columnNumbers]="{ md: 4, lg: 4 }"
            class="s-body-14-22-regular"
        >
            <div class="s-body-14-22-italic">
                {{ 'PAGES.OWNERSHIP.DETAIL_VIEW.LABEL_NOT_SPECIFIED' | translate }}
            </div>
        </div>
    </div>

    <div ibmRow class="mt-1r">
        <div ibmCol [columnNumbers]="{ md: 4, lg: 4 }" class="s-label-12-22-semibold total-distribution pt-1r">
            {{ 'ENTITIES.DISTRIBUTION_KEY.LABEL_TOTAL_DISTRIBUTION' | translate }}
        </div>
        <div ibmCol [columnNumbers]="{ md: 4, lg: 4 }" class="s-body-14-22-bold total-distribution pt-1r">
            {{
                distributionKey.distributionBase === 'CONSUMPTION'
                    ? (distributionKey.totalDistribution | eurocent)
                    : (distributionKey.totalDistribution | number : '' : 'de-DE')
            }}
        </div>
    </div>
</div>
