import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastService } from 'src/app/core/services/toast.service';
import { BankTransactionsService } from 'src/app/generated-sources/accounting';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-add-bank-transaction',
    templateUrl: './add-bank-transaction.component.html',
    styleUrls: ['./add-bank-transaction.component.scss'],
})
export class AddBankTransactionComponent extends OverlayChildComponent implements OnInit {
    public selectedFile?: File;
    public readonly fileSizeInMb = environment.maxFileSizeInMB;
    public isLoading = false;
    public isLoadingDownloadTemplate = false;
    public bankTransactionUploadType: 'default' | 'template' = 'default';

    @ViewChild('fileInput') public fileInput: any;

    public constructor(private bankTransactionsService: BankTransactionsService, private toastService: ToastService) {
        super();
    }

    public ngOnInit(): void {}

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public submit(): void {
        if (!this.selectedFile || !this.config?.data.bankAccountId) {
            return;
        }
        this.isLoading = true;

        (this.bankTransactionUploadType === 'default'
            ? this.bankTransactionsService.importBankTransactions(this.config?.data.bankAccountId, this.selectedFile)
            : this.bankTransactionsService.importCSVBankTransactions(this.config?.data.bankAccountId, this.selectedFile)
        ).subscribe({
            next: () => {
                this.isLoading = false;
                this.toastService.showSuccess('Umsätze wurde erfolgreich hochgeladen');
                this.saveEmitter$.next();
            },
            error: (error) => {
                this.isLoading = false;
                this.toastService.showError(error.error?.message ?? error.message);
            },
        });
    }

    public fileChangeEvent(event: any): void {
        this.selectedFile = event.target.files[0];
    }

    public downloadBankTransactionTemplate(): void {
        const link = document.createElement('a');
        const filepath = '/assets/templates/scalara-bank-transaction-template.csv';
        link.href = filepath;
        link.download = 'scalara-template.csv';
        link.click();
    }
}
