import { getTodayString } from './date-handling';
import { clusterBy, multiSort, reverse, sortBy } from './sorting';
import { sumStableDistribution } from './sum-stable-distribution';
import { groupToArray } from './utils';

type AdditionalProperties = { settledAmount: number };
type OpenItemInfo = {
    id: string;
    dueDate: string;
    amount: number;
    initialBookingCreditAccountAccountGroupType: string;
    outstandingBalance: number;
};

export enum OpenItemClosingStrategy {
    DUE_DATE_FIRST = 'DUE_DATE_FIRST',
    KOSTENTRAGUNG_FIRST = 'KOSTENTRAGUNG_FIRST',
    ERHALTUNGSRUECKLAGE_FIRST = 'ERHALTUNGSRUECKLAGE_FIRST',
    QUOTELUNG = 'QUOTELUNG',
}

export function calculateDistribution<T extends OpenItemInfo>(
    stragegy: OpenItemClosingStrategy,
    openItems: T[],
    bookingAmount: number
): (T & AdditionalProperties)[] {
    return getStrategy(stragegy)(openItems, bookingAmount);
}

function getStrategy(
    stragegy: OpenItemClosingStrategy
): <T extends OpenItemInfo>(openItems: T[], bookingAmount: number) => (T & AdditionalProperties)[] {
    switch (stragegy) {
        case OpenItemClosingStrategy.DUE_DATE_FIRST:
            return strategyDueDateFirst;
        case OpenItemClosingStrategy.KOSTENTRAGUNG_FIRST:
            return strategyKostentragungFirst;
        case OpenItemClosingStrategy.ERHALTUNGSRUECKLAGE_FIRST:
            return stragegyErhaltungsRuecklageFirst;
        case OpenItemClosingStrategy.QUOTELUNG:
            return strategyQuotelung;
        default:
            throw new Error('UNKNOWN_STRATEGY');
    }
}

function strategyDueDateFirst<T extends OpenItemInfo>(
    openItems: T[],
    bookingAmount: number
): (T & AdditionalProperties)[] {
    const sortedOpenItems = openItems.sort(compareOpenItemsDueDate);
    return simpleDistribution(sortedOpenItems, bookingAmount);
}

function strategyKostentragungFirst<T extends OpenItemInfo>(
    openItems: T[],
    bookingAmount: number
): (T & AdditionalProperties)[] {
    const sortedOpenItems = sortKostentragungFirst(openItems);
    return simpleDistribution(sortedOpenItems, bookingAmount);
}

function stragegyErhaltungsRuecklageFirst<T extends OpenItemInfo>(
    openItems: T[],
    bookingAmount: number
): (T & AdditionalProperties)[] {
    const sortedOpenItems = sortErhaltungsruecklageFirst(openItems);
    return simpleDistribution(sortedOpenItems, bookingAmount);
}

function strategyQuotelung<T extends OpenItemInfo>(
    openItems: T[],
    bookingAmount: number
): (T & AdditionalProperties)[] {
    //group and sort by dueDate...
    const sortedOpenItems = groupToArray([...openItems], (x) => x.dueDate).sort(reverse(sortBy((x) => x.key)));
    const result = [] as (T & AdditionalProperties)[];
    while (bookingAmount > 0) {
        const group = sortedOpenItems.pop();
        if (!group) {
            throw new Error('INSUFFICIENT_OUTSTANDING_BALANCE');
        }
        const openItemsOfGroup = group.values;
        const outStandingAmountOfGroup = openItemsOfGroup.reduce((acc, x) => acc + x.outstandingBalance, 0);
        const currentTotalAmountGroup = openItemsOfGroup.reduce((acc, x) => acc + x.amount, 0);
        const currentlyBookedAmount = currentTotalAmountGroup - outStandingAmountOfGroup;
        let amountToBook = Math.min(outStandingAmountOfGroup, bookingAmount);
        bookingAmount -= amountToBook;
        if (outStandingAmountOfGroup === amountToBook) {
            //Wenn wir dir Gruppe nur auffüllen ist es einfach...
            result.push(
                ...openItemsOfGroup.map((x) => ({ ...x, settledAmount: x.outstandingBalance, outstandingBalance: 0 }))
            );
        } else {
            //Falls wir die Gruppe nur zum Teil befüllen, wollen wir,sofern möglich, erreichen, dass die die offenen Posten in der Gruppe
            //gemäß der verhältnisse ihrer Gesamtbeträge befüllt sind.
            //Beispiel: Offene Posten von 100€ und 200€ auf die ein Betrag von 100 gebucht werden soll => 33,33€ und 66,67€
            //Da die offenen Posten der Gruppe aber evtl. schon (krum) bebucht sind, können wir den Betrag nicht einfach gemäß der Verteilung draufpacken
            //da manche offenen Posten evtl. gar nicht mehr so viel outStanding-Amount haben.
            //Wie gehen daher so vor, dass wir zunächst die ideale (aber ggfs. nicht erreichbare) finale Verteilung berechnen und dann die offenen Posten so bebuchen,
            //dass wir uns dieser annähern.

            //Als Basis für die Wunschverteilung gilt die "nominale" Höhe des Offenen Postens
            const shares = openItemsOfGroup.map((x) => x.amount);
            const idealFinalDistribution = sumStableDistribution(currentlyBookedAmount + amountToBook, shares);
            //Durch unglückliche Rundung könnte es passieren, dass unsere Idealverteilung einem OP mehr zuweisen will als in ihn reinpasst. Das müssen wir korrigien:
            let indexOfTooLargeAssignment: number;
            while (
                (indexOfTooLargeAssignment = openItemsOfGroup.findIndex(
                    (x, i) => x.amount < idealFinalDistribution[i]
                )) !== -1
            ) {
                const indexWithRemainingSpace = openItemsOfGroup.findIndex(
                    (x, i) => x.amount > idealFinalDistribution[i]
                );
                idealFinalDistribution[indexWithRemainingSpace]++;
                idealFinalDistribution[indexOfTooLargeAssignment]--;
            }

            const intermediateResult = openItemsOfGroup.map((x, i) => ({
                ...x,
                bookingPotential: idealFinalDistribution[i] - x.amount + x.outstandingBalance,
                settledAmount: 0,
            }));
            //Booking Potential gibt an wie weit der Bebuchungsstand von der idealen Verteilung abweicht.
            //Wir sortieren absteigend nach dem Potential
            intermediateResult.sort(reverse(sortBy((x) => x.bookingPotential)));
            //Um die OPS in dieser Reihenfolge dem Ideal anzunähern
            let loopIndex = -1;

            while (amountToBook > 0) {
                //Eigentlich müsste es nie nötig sein, dass diese Schleife mehr als einmal durchlaufen wird
                //Ich kann aber nicht gant ausschliessen, dass es unglückliche Rundungsgrenzfälle gibt, die das nötig machen, daher diese Modulo-Konstruktion
                loopIndex = (loopIndex + 1) % intermediateResult.length;
                const openItem = intermediateResult[loopIndex];
                if (!openItem) {
                    throw new Error('INSUFFICIENT_OUTSTANDING_BALANCE');
                }
                let amountToBookForItem = Math.min(openItem.bookingPotential, amountToBook);
                //Eigentlich müsste es immer möglich sein alles an OpenItems mit positiven bookingPotential zu belegen
                //Da ich aber nicht auschliessen kann, dass es unglückliche Rundungsgrenzfälle gibt, handeln wir auch den Fall und Buchen dann so viel möglich und nötig:
                if (amountToBookForItem <= 0) {
                    amountToBookForItem = Math.min(openItem.outstandingBalance, amountToBook);
                }
                amountToBook -= amountToBookForItem;
                openItem.settledAmount += amountToBookForItem;
                openItem.outstandingBalance -= amountToBookForItem;
                openItem.bookingPotential -= amountToBookForItem;
                result.push(openItem);
            }
        }
    }

    return result;
}

function simpleDistribution<T extends OpenItemInfo>(
    sortedOpenItems: T[],
    bookingAmount: number
): (T & AdditionalProperties)[] {
    sortedOpenItems = [...sortedOpenItems].reverse();
    const result = [] as (T & AdditionalProperties)[];
    while (bookingAmount > 0) {
        const openItem = { ...sortedOpenItems.pop() } as T & AdditionalProperties;
        if (!openItem) {
            throw new Error('INSUFFICIENT_OUTSTANDING_BALANCE');
        }
        const amountToBook = Math.min(openItem.outstandingBalance, bookingAmount);
        bookingAmount -= amountToBook;
        openItem.settledAmount = amountToBook;
        openItem.outstandingBalance -= amountToBook;
        result.push(openItem);
    }
    return result;
}

function sortKostentragungFirst<T extends OpenItemInfo>(openItems: T[]): T[] {
    openItems.sort(
        multiSort(
            clusterBy(
                //1.) Alle fälligen OpenItems zu Kostentragungen
                (x: OpenItemInfo) =>
                    x.dueDate <= getTodayString() && x.initialBookingCreditAccountAccountGroupType === 'regular costs',
                //2.) Alle fälligen OpenItems ungleich Kostentragungen
                (x: OpenItemInfo) =>
                    x.dueDate <= getTodayString() && x.initialBookingCreditAccountAccountGroupType !== 'regular costs',
                //3.) Alle nicht fälligen OpenItems zu Kostentragungen
                (x: OpenItemInfo) =>
                    x.dueDate > getTodayString() && x.initialBookingCreditAccountAccountGroupType === 'regular costs',
                //4.) Alle nicht fälligen OpenItems ungleich Kostentragungen
                (x: OpenItemInfo) =>
                    x.dueDate > getTodayString() && x.initialBookingCreditAccountAccountGroupType !== 'regular costs'
            ),
            compareOpenItemsDueDate
        )
    );
    return openItems;
}

function sortErhaltungsruecklageFirst<T extends OpenItemInfo>(openItems: T[]): T[] {
    openItems.sort(
        multiSort(
            clusterBy(
                //1.) Alle fälligen OpenItems ungleich Kostentragungen
                (x: OpenItemInfo) =>
                    x.dueDate <= getTodayString() && x.initialBookingCreditAccountAccountGroupType !== 'regular costs',
                //2.) Alle fälligen OpenItems zu Kostentragungen
                (x: OpenItemInfo) =>
                    x.dueDate <= getTodayString() && x.initialBookingCreditAccountAccountGroupType === 'regular costs',
                //3.) Alle nicht fälligen OpenItems ungleich Kostentragungen
                (x: OpenItemInfo) =>
                    x.dueDate > getTodayString() && x.initialBookingCreditAccountAccountGroupType !== 'regular costs',
                //4.) Alle nicht fälligen OpenItems zu Kostentragungen
                (x: OpenItemInfo) =>
                    x.dueDate > getTodayString() && x.initialBookingCreditAccountAccountGroupType === 'regular costs'
            ),
            compareOpenItemsDueDate
        )
    );
    return openItems;
}

const compareOpenItemsDueDate = sortBy((x: OpenItemInfo) => x.dueDate);
