import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ObservedValueOf, Subject, combineLatest, filter, map, shareReplay, switchMap, takeUntil, tap } from 'rxjs';
import { WegSettlementStatementsService } from 'src/app/generated-sources/accounting';
import { Column, RowData } from 'src/app/shared/components/simple-table/simple-table-row/simple-table-row.component';
import { EurocentPipe } from 'src/app/shared/pipes/eurocent.pipe';
import { formatDateWithoutHhMmSs } from '../../../core/utils/common';
import { SimpleTableRowTemplatesService } from '../../../shared/components/simple-table/simple-table-row/simple-table-row-templates.service';
import { CustomWssService } from '../custom-wss.service';

@Component({
    selector: 'app-reserves-development',
    templateUrl: './reserves-development.component.html',
    styleUrls: ['./reserves-development.component.scss'],
})
export class ReservesDevelopmentComponent implements OnInit, OnDestroy {
    public constructor(
        public wssService: WegSettlementStatementsService,
        public euroCent: EurocentPipe,
        public datePipe: DatePipe,
        public customWssService: CustomWssService,
        public simpleTableRowsTemplatesService: SimpleTableRowTemplatesService
    ) {}

    //Wird für
    public columnKeys: ['name', 'amount'] = ['name', 'amount'];
    public noOfReserves = 0;
    public isLoading = true;

    public unsubscribe$ = new Subject<void>();
    public ledgerId$ = this.customWssService.getLedgerId$().pipe(filter(Boolean));
    public wss$ = this.customWssService.getWss$().pipe(filter(Boolean));
    public reservesDevelopments$ = combineLatest([this.ledgerId$, this.wss$]).pipe(
        switchMap(([ledgerId, wss]) => this.wssService.getReservesDevelopment(ledgerId, wss.id)),
        shareReplay({ bufferSize: 1, refCount: true })
    );
    public balanceComparison$ = combineLatest([this.ledgerId$, this.wss$]).pipe(
        switchMap(([ledgerId, wss]) => this.wssService.getBalanceComparison(ledgerId, wss.id)),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    public tables$ = combineLatest([this.wss$, this.reservesDevelopments$, this.balanceComparison$]).pipe(
        map(([wss, reservesDevelopments, balanceComparison]) => {
            const tableDataActualReserve: TableData = {
                columns: [],
                rows: [],
            };
            const tableDataActualReserveCoherenceCheck: TableData = {
                columns: [],
                rows: [],
            };
            const tableDataDebitReserves: TableData[] = [];
            const tableDataAssetsReserves: TableData[] = [];

            reservesDevelopments.reserves.forEach((reserve) => {
                const tableDataReserveDebit: TableData = { columns: [], rows: [] };
                const tableDataReserveAsset: TableData = { columns: [], rows: [] };
                //Soll-Rücklage
                tableDataReserveDebit.columns.push(
                    { key: 'name', label: `SOLL-${reserve.name}` },
                    { key: 'amount', label: 'Beträge in €' }
                );
                tableDataReserveDebit.rows.push(
                    this.simpleTableRowsTemplatesService.valueRow(
                        this.columnKeys,
                        [`SOLL`, ''],
                        's-head-16-24-bold s-gray-01'
                    )
                );
                tableDataReserveDebit.rows.push(
                    this.simpleTableRowsTemplatesService.headRow(
                        this.columnKeys,
                        [`${reserve.name}`, 'Beträge in €'],
                        undefined,
                        's-head-16-24-bold s-gray-01'
                    )
                );
                reserve.debit.lines.forEach((line) => {
                    tableDataReserveDebit.rows.push(
                        this.simpleTableRowsTemplatesService.valueRow(this.columnKeys, [line.text, line.amount])
                    );
                });
                tableDataReserveDebit.rows.push(this.simpleTableRowsTemplatesService.summLine(this.columnKeys));
                tableDataReserveDebit.rows.push(
                    this.simpleTableRowsTemplatesService.sumRow(this.columnKeys, [
                        reserve.debit.totalLine.text,
                        reserve.debit.totalLine.amount,
                    ])
                );
                tableDataDebitReserves.push(tableDataReserveDebit);
                //Geldmittel-Rücklage
                tableDataReserveAsset.columns.push(
                    { key: 'name', label: `Geldmittel ${reserve.name}` },
                    { key: 'amount', label: 'Beträge in €' }
                );
                tableDataReserveAsset.rows.push(
                    this.simpleTableRowsTemplatesService.valueRow(
                        this.columnKeys,
                        [`Geldmittel`, ''],
                        's-head-16-24-bold s-gray-01'
                    )
                );
                tableDataReserveAsset.rows.push(
                    this.simpleTableRowsTemplatesService.headRow(
                        this.columnKeys,
                        [`${reserve.name}`, 'Beträge in €'],
                        undefined,
                        's-head-16-24-bold s-gray-01'
                    )
                );
                reserve.asset.lines.forEach((line) => {
                    tableDataReserveAsset.rows.push(
                        this.simpleTableRowsTemplatesService.valueRow(this.columnKeys, [line.text, line.amount])
                    );
                });
                tableDataReserveAsset.rows.push(this.simpleTableRowsTemplatesService.summLine(this.columnKeys));
                tableDataReserveAsset.rows.push(
                    this.simpleTableRowsTemplatesService.sumRow(this.columnKeys, [
                        reserve.asset.totalLine.text,
                        reserve.asset.totalLine.amount,
                    ])
                );
                tableDataAssetsReserves.push(tableDataReserveAsset);
            });
            //IST-Rücklage
            tableDataActualReserve.columns.push(
                { key: 'name', label: `IST-Rücklage` },
                { key: 'amount', label: 'Beträge in €' }
            );
            tableDataActualReserve.rows.push(
                this.simpleTableRowsTemplatesService.headRow(
                    this.columnKeys,
                    [`IST-Rücklage`, 'Beträge in €'],
                    undefined,
                    's-head-16-24-bold s-gray-01'
                )
            );
            reservesDevelopments.actualReserve.lines.forEach((line) => {
                tableDataActualReserve.rows.push(
                    this.simpleTableRowsTemplatesService.valueRow(this.columnKeys, [line.text, line.amount])
                );
            });
            tableDataActualReserve.rows.push(this.simpleTableRowsTemplatesService.summLine(this.columnKeys));
            tableDataActualReserve.rows.push(
                this.simpleTableRowsTemplatesService.sumRow(this.columnKeys, [
                    reservesDevelopments.actualReserve.totalLine?.text ?? '',
                    reservesDevelopments.actualReserve.totalLine?.amount ?? '',
                ])
            );
            this.noOfReserves = reservesDevelopments.reserves.length;
            // Schlüssigkeitskontrolle
            tableDataActualReserveCoherenceCheck.columns.push(
                { key: 'name', label: `Schlüssigkeitskontrolle` },
                { key: 'amount', label: 'Beträge in €' }
            );
            tableDataActualReserveCoherenceCheck.rows.push(
                this.simpleTableRowsTemplatesService.headRow(
                    this.columnKeys,
                    [`Schlüssigkeitskontrolle`, 'Beträge in €'],
                    undefined,
                    's-head-16-24-bold s-gray-01'
                )
            );
            reservesDevelopments.coherenceCheck.lines.forEach((line) => {
                tableDataActualReserveCoherenceCheck.rows.push(
                    this.simpleTableRowsTemplatesService.valueRow(this.columnKeys, [line.text, line.amount])
                );
            });
            tableDataActualReserveCoherenceCheck.rows.push(
                this.simpleTableRowsTemplatesService.summLine(this.columnKeys)
            );
            tableDataActualReserveCoherenceCheck.rows.push(
                this.simpleTableRowsTemplatesService.sumRow(this.columnKeys, [
                    reservesDevelopments.coherenceCheck.totalLine?.text ?? '',
                    reservesDevelopments.coherenceCheck.totalLine?.amount ?? '',
                ])
            );
            tableDataActualReserveCoherenceCheck.rows.push(
                this.simpleTableRowsTemplatesService.sumRow(this.columnKeys, [
                    `Stand der Verfügbaren Geldmittel zum ${formatDateWithoutHhMmSs(wss.economicPlan.endDate)}`,
                    balanceComparison.totalAmountLedgerAccounts ?? '',
                ])
            );
            tableDataActualReserveCoherenceCheck.rows.push(
                this.simpleTableRowsTemplatesService.sumRow(this.columnKeys, [
                    `Stand der Bankkonten zum ${formatDateWithoutHhMmSs(wss.economicPlan.endDate)}`,
                    balanceComparison.totalAmountBankAccounts ?? '',
                ])
            );
            this.noOfReserves = reservesDevelopments.reserves.length;

            return {
                reservesDebit: tableDataDebitReserves,
                reservesAsset: tableDataAssetsReserves,
                actual: tableDataActualReserve,
                coherenceCheck: tableDataActualReserveCoherenceCheck,
            };
        })
    );

    public vm$ = combineLatest([this.ledgerId$, this.wss$, this.reservesDevelopments$, this.tables$]).pipe(
        map(([ledgerId, wss, reservesDevelopment, tableData]) => ({ ledgerId, wss, reservesDevelopment, tableData }))
    );
    public vm: ObservedValueOf<typeof this.vm$> | null = null;

    public ngOnInit(): void {
        this.vm$
            .pipe(
                takeUntil(this.unsubscribe$),
                tap(() => (this.isLoading = false))
            )
            .subscribe((vm) => {
                this.vm = vm;
            });
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}

type ColumnKey = ReservesDevelopmentComponent['columnKeys'][number];
type TableData = {
    columns: Column<ColumnKey>[];
    rows: RowData<ColumnKey>[];
};
