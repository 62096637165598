<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"></ibm-loading>

<app-overlay-container
    headlineTitle="PAGES.RELATIONS.SERVICE_PROVIDER.LABEL_ADD"
    containerSize="small"
    image="40_add-person.svg"
    colorIcon="green"
    [sizeIcon]="72"
>
    <div class="pb-5r">
        <div class="tw-grid tw-grid-cols-2 tw-gap-8">
            <app-card-selection
                iconColor="green"
                iconSrc="40_edit.svg"
                label="{{ 'PAGES.RELATIONS.ADD_RELATION_OVERLAY.LABEL_ADD_PERSON' | translate }}"
                [selected]="!isExistingUserSelected"
                (click)="switchExistingUserSelection(false)"
            ></app-card-selection>
            <app-card-selection
                iconColor="green"
                iconSrc="40_person.svg"
                label="   {{ 'PAGES.RELATIONS.ADD_RELATION_OVERLAY.LABEL_SELECT_EXISTING_PERSON' | translate }}"
                [selected]="isExistingUserSelected"
                (click)="switchExistingUserSelection(true)"
            ></app-card-selection>
        </div>
    </div>

    <div class="mb-06">
        <form [formGroup]="serviceForm">
            <ng-container formArrayName="servicesProvided">
                <ng-container *ngFor="let item of servicesControl.controls; let i = index" [formGroupName]="i">
                    <ng-container *ngIf="i > 0">
                        <hr class="mt-2r" />
                        <div class="d-flex justify-content-end">
                            <app-scalara-button
                                (click)="removeService(i)"
                                variant="ghost"
                                svgName="24_close.svg"
                                height="40px"
                            ></app-scalara-button>
                        </div>
                    </ng-container>

                    <app-combo-box
                        id="serviceProvided"
                        placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT_2' | translate }}"
                        [appendInline]="true"
                        label="Gewerk*"
                        [items]="servicesList"
                        invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                        formControlName="serviceProvided"
                    >
                        <ibm-dropdown-list></ibm-dropdown-list>
                        <!-- FIX VALIDATION -->
                    </app-combo-box>

                    <div class="tw-s-head-24-22-regular tw-mb-4 tw-mt-6">
                        <span>{{
                            'PAGES.RELATIONS.ADD_RELATION_OVERLAY.LABEL_RELATION_DIENSTLEISTER_INTERVAL_HEADLINE'
                                | translate
                        }}</span>
                    </div>

                    <div class="tw-grid tw-grid-cols-2 tw-gap-x-8">
                        <ibm-date-picker
                            label="{{ 'ENTITIES.RELATION_WITH_TIMECONSTRAINT.LABEL_FROM' | translate }}*"
                            [id]="'from' + '-' + i"
                            [placeholder]="'tt.mm.jjjj'"
                            [language]="'de'"
                            [dateFormat]="'d.m.Y'"
                            formControlName="from"
                            [invalid]="!!this.getServiceProvidedItemForm(i).controls['from'].invalid"
                            invalidText="Das Startdatum muss vor den Enddatum liegen"
                        >
                        </ibm-date-picker>
                        <ibm-date-picker
                            label="{{ 'ENTITIES.RELATION_WITH_TIMECONSTRAINT.LABEL_TO' | translate }}"
                            [id]="'to' + '-' + i"
                            [placeholder]="'tt.mm.jjjj'"
                            [language]="'de'"
                            [dateFormat]="'d.m.Y'"
                            formControlName="to"
                            [invalid]="!!this.getServiceProvidedItemForm(i).controls['to'].invalid"
                            invalidText="Das Startdatum muss vor den Enddatum liegen"
                        >
                        </ibm-date-picker>
                    </div>
                    <!--  -->

                    <div class="tw-mt-4">
                        <app-scalara-button (click)="addNewService()" variant="ghost" svgName="24_add.svg">
                            Weiteres Gewerk
                        </app-scalara-button>
                    </div>
                </ng-container>
            </ng-container>
        </form>
    </div>

    <!-- use css hidden instead of ngIf because its broken -->
    <div [hidden]="this.isExistingUserSelected">
        <app-user-infos-form [flowType]="'add-service-provider'"></app-user-infos-form>
    </div>

    <div class="mt-1r tw-mb-8" [hidden]="!this.isExistingUserSelected">
        <app-existing-persons-combo-box
            (handlePersonSelection)="onSelectPerson($event)"
        ></app-existing-persons-combo-box>
    </div>

    <div class="tw-flex tw-justify-between tw-mt-[120px] tw-mb-20">
        <app-scalara-button (click)="abort()" variant="outline">{{
            'CONTROLS.BUTTONS.CANCEL' | translate
        }}</app-scalara-button>

        <app-scalara-button (click)="onSubmit()" [disabled]="!this.isFormsValid()">
            {{ 'CONTROLS.BUTTONS.SAVE' | translate }}</app-scalara-button
        >
    </div>
</app-overlay-container>
