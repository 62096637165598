<div class="tw-flex tw-justify-start">
    <app-text-with-tooltip
        class="tw-inline-block"
        label="Optionale Bestandteile des Jahresabschlusses"
        labelClass="tw-s-body-14-22-bold"
        tooltipInnerHtml="Hier können Sie einstellen, welche Bestandteile in den PDF-Ausdrucken generiert werden sollen. Unabhängig davon, werden alle Anhänge wie Steuern, Haushaltsnahe Dienstl., Entnahme aus Rücklagen nur gedruckt, wenn auch Daten vorhanden sind."
    ></app-text-with-tooltip>
</div>

<div [formGroup]="form" class="tw-flex-row tw-space-y-3">
    <div>
        <ibm-checkbox formControlName="allUnitsCheckbox"> Abrechnungsergebnisse aller Einheiten</ibm-checkbox>
    </div>
    <div>
        <ibm-checkbox formControlName="homeRelatedCostsCheckBox"> Haushaltsnahe Dienstleistungen </ibm-checkbox>
    </div>
    <div>
        <ibm-checkbox formControlName="reserveWithdrawelsCheckBox"> Entnahme aus Rücklagen </ibm-checkbox>
    </div>
    <div><ibm-checkbox formControlName="taxCheckBox"> Zinserträge, SolZ & KapESt</ibm-checkbox></div>
</div>
