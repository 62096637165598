import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ListItem } from 'carbon-components-angular';
import { Subject, switchMap, takeUntil, tap } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import {
    DistributionUpdateDto,
    DistributionUpdateItem,
    UpdateWssDto,
    WegSettlementStatementsService,
    WssDistributionDto,
    WssDistributionDtoAccount,
    WssDistributionKeyDto,
    WssDto,
} from 'src/app/generated-sources/accounting';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';

export type AccountsKey = Exclude<keyof WssDistributionDto, ['maturity', 'rateinterval']>;

interface DistributionListItems extends ListItem {
    value: string;
    totalDistribution: number;
    distributionBase: WssDistributionKeyDto.DistributionBaseEnum;
}
@Component({
    selector: 'app-edit-annual-statement',
    templateUrl: './edit-annual-statement.component.html',
    styleUrls: ['./edit-annual-statement.component.scss'],
})
export class EditAnnualStatementComponent extends OverlayChildComponent implements OnInit, OnDestroy {
    public isLoading = false;
    private unsubscribe$ = new Subject<void>();

    public wssId = '';
    public ledgerId = '';

    public annualDistribution?: WssDistributionDto;
    public annualStatementDetails?: WssDto;
    public accountsList: WssDistributionDtoAccount[][] = [];

    public accountGroups: AccountsKey[] = ['revenueAccounts', 'apportionableExpenseAccounts', 'expenseAccounts'];
    public accountGroupsCurrentAmount: number[] = [];
    public totalAnnualAmount = 0;

    public distributionKeysListItems: DistributionListItems[] = [];
    public distributionKeys: WssDistributionKeyDto[] = [];
    public isDifferingAdvancements?: boolean;
    public hideDescriptionTextInputs = true;

    public form: UntypedFormGroup = new UntypedFormGroup({});

    public constructor(
        private toastService: ToastService,
        private annualStatementsService: WegSettlementStatementsService,
        private translateService: TranslateService,
        private formBuilder: UntypedFormBuilder
    ) {
        super();
    }

    public ngOnInit(): void {
        this.isLoading = true;
        this.ledgerId = this.config?.data?.ledgerId;
        this.wssId = this.config?.data?.wssId;

        this.annualStatementsService
            .getDistribution(this.ledgerId, this.wssId)
            .pipe(
                tap((annualDistribution: WssDistributionDto) => {
                    this.annualDistribution = annualDistribution;
                    this.populateAccounts(annualDistribution);
                }),
                switchMap(() => this.annualStatementsService.findOne(this.ledgerId, this.wssId)),
                tap((wssDto: WssDto) => {
                    this.annualStatementDetails = wssDto;
                }),
                switchMap(() => this.annualStatementsService.getDistributionKeys(this.ledgerId, this.wssId)),
                tap((distributionKeys: WssDistributionKeyDto[]) => {
                    this.initSelectors(distributionKeys);
                    this.form = this.createForm();
                    this.updateSharedAmounts();
                    this.isLoading = false;
                }),
                switchMap(() => this.form.valueChanges),
                tap(() => {
                    this.updateSharedAmounts();
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }

    public onSubmit(isClosingOverlay: boolean): void {
        this.isLoading = true;
        const distributionUpdateDto: DistributionUpdateDto = {
            items: this.getAccountsDetails(),
        };

        this.annualStatementsService
            .updateDistribution(this.ledgerId, this.wssId, distributionUpdateDto)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: () => {
                    this.isLoading = false;
                    this.toastService.showSuccess(
                        this.translateService.instant('PAGES.ANNUAL_STATEMENT.EDIT.TOAST_SUCCESS_MESSAGE')
                    );
                    // choosing if we want to exit the overlay, or just switching between steps
                    if (isClosingOverlay) {
                        this.saveEmitter$.next();
                    } else {
                        this.hideDescriptionTextInputs = false;
                    }
                },
                error: (error) => {
                    this.isLoading = false;
                    if (error) {
                        this.toastService.showError(error.error['message']);
                    }
                },
            });
    }

    public submitDescriptions(isClosingOverlay: boolean): void {
        this.isLoading = true;

        if (!this.hideDescriptionTextInputs === true) {
            this.isLoading = true;
            const updateWssDto: UpdateWssDto = {
                movableAssetDescription: this.form.get('movableAssetDescription')?.value,
                realEstateDescription: this.form.get('realEstateDescription')?.value,
            };

            this.annualStatementsService
                .update(this.ledgerId, this.wssId, updateWssDto)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe({
                    next: () => {
                        this.isLoading = false;
                        this.toastService.showSuccess(
                            this.translateService.instant('PAGES.ANNUAL_STATEMENT.EDIT.TOAST_SUCCESS_MESSAGE')
                        );
                        // choosing if we want to exit the overlay, or just switching between steps
                        if (isClosingOverlay) {
                            this.saveEmitter$.next();
                        } else {
                            this.hideDescriptionTextInputs = true;
                        }
                    },
                    error: (error) => {
                        if (error) {
                            this.toastService.showError(error.error['message']);
                        }
                    },
                });
        }
    }

    public abort(event: MouseEvent): void {
        if (this.hideDescriptionTextInputs) {
            this.cancelEmitter$.next();
        } else {
            this.hideDescriptionTextInputs = true;
            event.stopPropagation();
        }
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public populateAccounts(annualAccounts: WssDistributionDto): void {
        for (let i = 0; i < this.accountGroups.length; i++) {
            this.accountsList.push(annualAccounts[this.accountGroups[i]] as WssDistributionDtoAccount[]);
            let sum = 0;
            this.accountsList[i].map((account: WssDistributionDtoAccount) => (sum += account.totalAmount));
            this.accountGroupsCurrentAmount.push(sum);
        }
    }

    private initSelectors(distributionKeys: WssDistributionKeyDto[]): void {
        const items: DistributionListItems[] = [];
        const alreadyUsedDistributionKeys = this.accountsList.flat().map((x) => x.currentDistributionKey.id);

        // should only show where all distributionbaseshares are set for all ownerships (and all keys which have already been used)
        const distributionKeysFiltered = distributionKeys.filter(
            (item) =>
                alreadyUsedDistributionKeys.includes(item.id) ||
                item.ownerships.find((ownership) => ownership.distributionBaseShare)
        );

        for (let i = 0; i < distributionKeysFiltered.length; i++) {
            const isSelected = i == 0 ? true : false;
            items.push({
                content: distributionKeysFiltered[i].description,
                selected: isSelected,
                value: distributionKeysFiltered[i].id,
                totalDistribution: distributionKeysFiltered[i].totalDistribution,
                distributionBase: distributionKeysFiltered[i].distributionBase,
            });
        }
        this.distributionKeysListItems = [...items];
    }

    public createForm(): UntypedFormGroup {
        let form: UntypedFormGroup = new UntypedFormGroup({});

        form = this.formBuilder.group({
            revenueAccounts: this.formBuilder.array([]),
            expenseAccounts: this.formBuilder.array([]),
            apportionableExpenseAccounts: this.formBuilder.array([]),
            movableAssetDescription: [this.annualStatementDetails?.movableAssetDescription],
            realEstateDescription: [this.annualStatementDetails?.realEstateDescription],
        });

        // initializing selectors with the already selected distribution key
        // if no distribution key was selected, the first one is chosen.
        for (let i = 0; i < this.accountGroups.length; i++) {
            for (let j = 0; j < this.accountsList[i].length; j++) {
                const distItem = this.distributionKeysListItems.find(
                    (distKey: ListItem) => this.accountsList[i][j].currentDistributionKey.id === distKey['value']
                );

                distItem!.selected = true;
                (form.get(this.accountGroups[i]) as UntypedFormArray).push(
                    this.formBuilder.group({
                        distributionKey: this.formBuilder.control({
                            value: distItem || this.distributionKeysListItems[0],
                            disabled: false,
                        }),
                    })
                );
            }
        }

        return form;
    }

    public getAccountForm(type: string, index: number): UntypedFormGroup {
        return (this.form.controls[type] as UntypedFormArray).at(index) as UntypedFormGroup;
    }

    public onSelectDistributionKey($event: any, i: number, j: number): void {
        this.accountsList[i][j].totalDistribution = $event.item.totalDistribution;
        this.accountsList[i][j].currentDistributionKey.id = $event.item.value;
    }

    public applyEuroPipe(currentDistributionKeyId: string): boolean {
        const findDistributionBase = this.distributionKeysListItems.find(
            (item) => item['value'] === currentDistributionKeyId
        );
        return findDistributionBase?.distributionBase === WssDistributionKeyDto.DistributionBaseEnum.Consumption;
    }

    public getAccountsDetails(): DistributionUpdateItem[] {
        const accountsDetails: DistributionUpdateItem[] = [];

        for (let i = 0; i < this.accountsList.length; i++) {
            for (let j = 0; j < this.accountsList[i].length; j++) {
                const accountValues = this.getAccountForm(this.accountGroups[i], j).getRawValue();
                accountsDetails.push({
                    accountId: this.accountsList[i][j].accountId || '',
                    distributionKeyId: accountValues.distributionKey.value,
                });
            }
        }
        return accountsDetails;
    }

    public updateSharedAmounts(): void {
        let totalAmount = 0;
        for (let i = 0; i < this.accountGroups.length; i++) {
            let amountGroup = 0;

            this.accountsList[i].map((account: WssDistributionDtoAccount) => (amountGroup += account.totalAmount));
            this.accountGroupsCurrentAmount[i] = amountGroup;
            if (i === 0) {
                totalAmount -= amountGroup;
            } else {
                totalAmount += amountGroup;
            }
        }
        this.totalAnnualAmount = totalAmount;
    }
}
