import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { ManagedUserDto, UserManagementService } from 'src/app/generated-sources/base';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';

@Component({
    selector: 'app-delete-user',
    templateUrl: './delete-user.component.html',
    styleUrls: ['./delete-user.component.scss'],
})
export class DeleteUserComponent extends OverlayChildComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();
    public user?: ManagedUserDto;

    public constructor(
        private toastService: ToastService,
        private translateService: TranslateService,
        public userManagementService: UserManagementService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.user = this.config?.data.user;
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public submit(): void {
        if (!this.user) {
            return;
        }
        this.userManagementService
            .deactivateManagedUser(this.user.id)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: () => {
                    this.toastService.showSuccess(this.translateService.instant('PAGES.DELETE_USER.SUCCESS_TOAST'));
                    this.saveEmitter$.next();
                },
                error: (error) => {
                    if (error) {
                        this.toastService.showError(error.error['message']);
                    }
                },
            });
    }
}
