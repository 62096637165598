import { ComboBox, DocumentService, getEventObservable, hasScrollableParents } from 'carbon-components-angular';
import { Observable, Subscription } from 'rxjs';
/**
 * The carbon-combobox causes an ugly memory/performance-leak
 * The ComboBox  subscribes to an observable of DocumentService. DocumentService does stay in memory,
 * even when we change to a different view in the app. (It does not survive a refresh)
 * So the ComboBox cannot be garbagecollected and the ComboBox again has ties to the Components
 * it lies in (through __ngContext__).
 * These "phantom-components" apparently still run through Angulars change-detection-mechanism which
 * slows down the app, especially when components with large tables remain in memory.
 *
 * The following monkey-patches make sure, that he subscription get unsubscribed when the combobox is destroyed
 */
DocumentService.prototype.handleEvent = function (eventType: any, callback: any): Subscription {
    if (!(<any>this).globalEvents.has(eventType)) {
        if ((<any>this).documentRef) {
            (<any>this).globalEvents.set(eventType, getEventObservable((<any>this).documentRef, eventType));
        } else {
            (<any>this).globalEvents.set(eventType, new Observable());
        }
    }
    const observable = (<any>this).globalEvents.get(eventType);
    const subscription: any = observable.subscribe(callback);
    (<any>this).subscriptions.add(subscription);
    return subscription;
};

DocumentService.prototype.handleClick = function (callback): Subscription {
    return (<any>this).handleEvent('click', callback);
};

ComboBox.prototype.ngAfterViewInit = function (): void {
    (<any>this).documentServiceSubscription = (<any>this).documentService.handleClick((event: any) => {
        if (
            !(<any>this).elementRef.nativeElement.contains(event.target) &&
            !this.dropdownMenu.nativeElement.contains(event.target)
        ) {
            if (this.open) {
                this.closeDropdown();
            }
        }
    });
    // if appendInline is default valued (null) we should:
    // 1. if there are scrollable parents (not including body) don't append inline
    //    this should also cover the case where the dropdown is in a modal
    //    (where we _do_ want to append to the placeholder)
    if (this.appendInline === null && hasScrollableParents((<any>this).elementRef.nativeElement)) {
        this.appendInline = false;
        // 2. otherwise we should append inline
    } else if (this.appendInline === null) {
        this.appendInline = true;
    }
};

ComboBox.prototype.ngOnDestroy = function (): void {
    if (!this.appendInline) {
        this._appendToDropdown();
    }
    if (
        !(<any>this) ||
        !(<any>this)?.documentServiceSubscription ||
        !(<any>this)?.documentServiceSubscription?.unsubscribe
    ) {
        return;
    }
    (<any>this)?.documentServiceSubscription?.unsubscribe();
};
