<app-overlay-container
    headlineTitle="PAGES.ADD_USER.HEADLINE"
    [sizeIcon]="72"
    image="40_add-person.svg"
    colorIcon="green"
    containerSize="small"
>
    <form [formGroup]="form">
        <div class="tw-flex tw-gap-8 tw-mb-4">
            <ibm-label [invalid]="isInvalidForm('name')" invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                >Name*
                <input formControlName="name" [invalid]="isInvalidForm('name')" ibmText name="name" id="name" />
            </ibm-label>
        </div>

        <ibm-label [invalid]="isInvalidForm('email')" invalidText="{{ 'ERROR.REQUIRED' | translate }}"
            >Email*
            <input formControlName="email" [invalid]="isInvalidForm('email')" ibmText name="email" id="email" />
        </ibm-label>

        <div class="tw-mt-28 tw-w-full tw-flex tw-justify-between">
            <app-scalara-button (click)="abort()" height="48px" variant="outline">{{
                'CONTROLS.BUTTONS.CANCEL' | translate
            }}</app-scalara-button>
            <app-scalara-button [disabled]="form.invalid" (click)="submit()" height="48px" class="tw-mb-4 md:tw-mb-0">{{
                'PAGES.ADD_USER.SUBMIT_BUTTON' | translate
            }}</app-scalara-button>
        </div>
    </form>
</app-overlay-container>
