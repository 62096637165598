<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<div ibmGrid class="bx--grid--full-width">
    <div ibmRow class="mb-5r" *ngIf="showInfoBox">
        <div ibmCol></div>
        <div ibmCol [columnNumbers]="{ md: 14, lg: 14 }">
            <div class="info-box">
                <div class="s-head-18-26-bold mb-1-5r">
                    <span>{{ 'PAGES.USER_MANAGEMENT.SYNC_ACCOUNTS.INFO_BOX.HEADLINE' | translate }}</span>
                </div>
                <div class="s-head-14-22-regular mb-1r align-items-center d-flex">
                    <svg-icon
                        src="/assets/icons/24_checkbox-check.svg"
                        class="check-icon mr-1r align-items-center d-flex"
                    ></svg-icon>
                    <span>{{ bankConnection?.bankName }}</span>
                    <span class="ml-1r">{{ 'ENTITIES.BANK_ACCOUNT.BIC' | translate }} {{ bankConnection?.bic }}</span>
                </div>
            </div>
        </div>
        <div ibmCol></div>
    </div>

    <div ibmRow>
        <div ibmCol></div>
        <div ibmCol class="s-head-28-36-bold mb-05" [columnNumbers]="{ md: 14, lg: 14 }">
            <span>{{ 'PAGES.USER_MANAGEMENT.SYNC_ACCOUNTS.HEADLINE' | translate }}</span>
        </div>
        <div ibmCol></div>
    </div>

    <div
        ibmRow
        [ngClass]="{
            'mb-2r': showInfoBox || (bankAccounts && bankAccounts.length === 1),
            'mb-5r': !showInfoBox && bankAccounts && bankAccounts.length > 1
        }"
    >
        <div ibmCol></div>
        <div ibmCol [columnNumbers]="{ md: 14, lg: 14 }">
            <span>{{ 'PAGES.USER_MANAGEMENT.SYNC_ACCOUNTS.TEXT' | translate }}</span>
        </div>
        <div ibmCol></div>
    </div>

    <div ibmRow *ngIf="bankAccounts && bankAccounts.length === 1 && !showInfoBox" class="mb-5r">
        <div ibmCol></div>
        <div ibmCol [columnNumbers]="{ md: 14, lg: 14 }">
            <div class="d-flex align-items-center">
                <div class="icon-container icon-container--32 mr-04">
                    <svg-icon src="/assets/icons/24_switch.svg"></svg-icon>
                </div>

                <div class="s-head-14-22-bold">{{ bankNameWithBic }}</div>
            </div>
        </div>
        <div ibmCol></div>
    </div>

    <form (submit)="onSubmit()" (keydown.enter)="$event.preventDefault()" [formGroup]="form">
        <div ibmRow>
            <div ibmCol></div>
            <div ibmCol [columnNumbers]="{ md: 14, lg: 14 }">
                <div ibmRow>
                    <div ibmCol>
                        <!-- Table -->
                        <table class="bx--data-table bx--data-table__custom">
                            <thead>
                                <tr>
                                    <th>
                                        <span class="bx--table-header-label">{{
                                            'ENTITIES.BANK_ACCOUNT.ACCOUNT_TYPE' | translate
                                        }}</span>
                                    </th>
                                    <th>
                                        <span class="bx--table-header-label">{{
                                            'ENTITIES.BANK_ACCOUNT.IBAN' | translate
                                        }}</span>
                                    </th>
                                    <th>
                                        <span class="bx--table-header-label">{{
                                            'ENTITIES.BANK_ACCOUNT.ACCOUNT_NAME' | translate
                                        }}</span>
                                    </th>
                                    <th>
                                        <span class="bx--table-header-label">{{
                                            'ENTITIES.BANK_ACCOUNT.ACCOUNT_OWNER' | translate
                                        }}</span>
                                    </th>
                                    <th class="property-ledger-list">
                                        <span class="bx--table-header-label">{{
                                            'PAGES.USER_MANAGEMENT.SYNC_ACCOUNTS.ASSIGNMENT' | translate
                                        }}</span>
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let bankAccount of bankAccounts; let i = index">
                                    <td>
                                        {{ getBankAccountNameForType(bankAccount) }}
                                    </td>
                                    <td>
                                        {{ bankAccount.iban }}
                                    </td>
                                    <td>
                                        {{ bankAccount.name }}
                                    </td>
                                    <td>
                                        {{ bankAccount.holderName }}
                                    </td>
                                    <td class="py-05 d-flex justify-content-end align-items-center">
                                        <app-combo-box
                                            [size]="'md'"
                                            id="property"
                                            placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT' | translate }}"
                                            [appendInline]="true"
                                            [items]="ledgersList"
                                            (selected)="onSelectBankAccount(bankAccount.id, $event)"
                                            (search)="clearFormControl(bankAccount.id)"
                                            (cleared)="clearFormControl(bankAccount.id)"
                                            class="w-100 tw-mr-4"
                                        >
                                            <ibm-dropdown-list></ibm-dropdown-list>
                                        </app-combo-box>
                                        <div
                                            *ngIf="form.get(bankAccount.id)?.value"
                                            class="icon-container icon-container--32"
                                        >
                                            <svg-icon src="/assets/icons/24_check.svg"></svg-icon>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div ibmRow class="mt-11">
                    <div ibmCol>
                        <button [ibmButton]="'tertiary'" (click)="abort()">
                            {{ 'PAGES.USER_MANAGEMENT.SYNC_ACCOUNTS.BUTTON_LATER' | translate }}
                        </button>
                    </div>

                    <div ibmCol class="text-right">
                        <button [ibmButton]="'primary'" type="submit" [disabled]="validateButton()">
                            {{ 'PAGES.USER_MANAGEMENT.SYNC_ACCOUNTS.BUTTON_SYNCHRONIZE' | translate }}
                        </button>
                    </div>
                </div>

                <div class="p-10"></div>
            </div>
            <div ibmCol></div>
        </div>
    </form>
</div>
