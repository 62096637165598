<div class="p-05"></div>

<div ibmGrid class="bx--grid--full-width">

    <div ibmRow>
        <div ibmCol
             [ngClass]="['s-head-14-22-semibold', 'mb-08']"
             [columnNumbers]="{'md': 8, 'lg': 10}">
            SCALARA GmbH <br />
            Immendorfer Str. 2 <br />
            50321 Brühl <br />
        </div>

        <div ibmCol
             [ngClass]="['s-head-14-22-regular', 'mb-08']"
             [columnNumbers]="{'md': 8, 'lg': 10}">
            E-Mail: info@scalara.de <br />
            Internet: www.scalara.de <br />
        </div>

        <div ibmCol
             [ngClass]="['s-head-14-22-regular', 'mb-08']"
             [columnNumbers]="{'md': 8, 'lg': 10}">
            Verantwortliche für journalistisch-redaktionelle Inhalte nach § 55 RStV: <br /><br />

            Shari Heep und Ferdinand von Klocke<br />
            Immendorfer Str. 2<br />
            50321 Brühl<br />
            E-Mail: info@scalara.de<br />
        </div>

        <div ibmCol
             [ngClass]="['s-head-14-22-regular', 'mb-08']"
             [columnNumbers]="{'md': 8, 'lg': 10}">
            UST-ID-Nr.: DE 339096109 <br />
            HRB NR: 103644 <br />
        </div>

        <div ibmCol
             [ngClass]="['s-head-14-22-regular', 'mb-08']"
             [columnNumbers]="{'md': 8, 'lg': 10}">
            <strong>Versicherungen:</strong> <br />
            CNA Insurance Company (Europe) S.A.<br />
            Direktion für Deutschland<br />
            Im Mediapark 8<br />
            50670 Köln<br />
            Deutschland<br />
        </div>

        <div ibmCol
             [ngClass]="['s-head-14-22-regular', 'mb-08']"
             [columnNumbers]="{'md': 8, 'lg': 10}">
            <strong>Geschäftsführer:</strong> <br />
            Shari Heep, Ferdinand von Klocke<br />
        </div>
        <div ibmCol
             [ngClass]="['s-head-14-22-regular', 'mb-08']"
             [columnNumbers]="{'md': 8, 'lg': 10}">
            Amtsgericht Köln
        </div>
    </div>
</div>
