<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>
<app-overlay-container headlineTitle="PAGES.OWNERSHIP.ADD_OWNERSHIP.MV_HEADLINE" containerSize="small">
    <form class="tw-mb-8" (submit)="onSubmit()" [formGroup]="form">
        <ng-container formArrayName="ownerships">
            <ng-container *ngFor="let item of ownershipsControl.controls; let i = index" [formGroupName]="i">
                <ng-container *ngIf="i > 0">
                    <hr class="mt-2r" />
                    <div class="d-flex justify-content-end">
                        <app-scalara-button
                            (click)="removebuilding(i)"
                            variant="ghost"
                            svgName="24_close.svg"
                            height="40px"
                        ></app-scalara-button>
                    </div>
                </ng-container>

                <div class="d-flex mb-1-5r">
                    <app-combo-box
                        [size]="'md'"
                        id="type"
                        [appendInline]="true"
                        label="{{ 'ENTITIES.UNIT.LABEL_UNIT_TYPE' | translate }}*"
                        [items]="unitsTypeList"
                        formControlName="type"
                        placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT' | translate }}"
                        [invalid]="isInvalid(i, 'type')"
                        invalidText="{{ 'ACCOUNTING.ERROR.INVALID_AMOUNT' | translate }}"
                    >
                        <ibm-dropdown-list></ibm-dropdown-list>
                    </app-combo-box>

                    <ibm-label
                        class="ml-2r"
                        invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                        [invalid]="isInvalid(i, 'name')"
                        invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                        >{{ 'ENTITIES.UNIT.LABEL_UNIT_DESCRIPTION' | translate }}*
                        <input ibmText name="name" id="name" formControlName="name" [invalid]="isInvalid(i, 'name')" />
                    </ibm-label>
                </div>
                <div class="d-flex mb-1-5r">
                    <app-combo-box
                        [size]="'md'"
                        id="buildingId"
                        [appendInline]="true"
                        label="Gebäude*"
                        [items]="buildingList"
                        formControlName="buildingId"
                        placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT' | translate }}"
                        [invalid]="isInvalid(i, 'buildingId')"
                        invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                    >
                        <ibm-dropdown-list></ibm-dropdown-list>
                    </app-combo-box>

                    <ibm-label
                        [invalid]="isInvalid(i, 'location')"
                        invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                        class="ml-2r"
                        >Lage*
                        <input
                            ibmText
                            name="location"
                            id="location"
                            formControlName="location"
                            [invalid]="isInvalid(i, 'location')"
                        />
                    </ibm-label>
                </div>

                <div class="d-flex mb-1-5r">
                    <ibm-label [invalid]="isInvalid(i, 'roomCount')" invalidText="{{ 'ERROR.GENERAL' | translate }}"
                        >{{ 'ENTITIES.OWNERSHIP.LABEL_ROOM' | translate }}
                        <input
                            ibmText
                            name="roomCount"
                            id="roomCount"
                            formControlName="roomCount"
                            [invalid]="isInvalid(i, 'roomCount')"
                        />
                    </ibm-label>

                    <ibm-label
                        class="ml-2r"
                        [invalid]="isInvalid(i, 'condition')"
                        invalidText="{{ 'ERROR.GENERAL' | translate }}"
                        >{{ 'ENTITIES.OWNERSHIP.LABEL_CONDITION' | translate }}
                        <input
                            ibmText
                            name="condition"
                            id="condition"
                            formControlName="condition"
                            [invalid]="isInvalid(i, 'condition')"
                        />
                    </ibm-label>
                </div>

                <div class="d-flex mb-1-5r">
                    <ibm-label
                        invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                        [invalid]="isInvalid(i, 'squaremeters')"
                        invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                        >Quadratmeter
                        <input
                            ibmText
                            name="squaremeters"
                            id="squaremeters"
                            formControlName="squaremeters"
                            [invalid]="isInvalid(i, 'squaremeters')"
                            type="number"
                        />
                    </ibm-label>

                    <ibm-label
                        class="ml-2r"
                        invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                        [invalid]="isInvalid(i, 'heatedSquaremeters')"
                        invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                        >Quadratmeter beheizt
                        <input
                            ibmText
                            name="heatedSquaremeters"
                            id="heatedSquaremeters"
                            formControlName="heatedSquaremeters"
                            [invalid]="isInvalid(i, 'heatedSquaremeters')"
                            type="number"
                        />
                    </ibm-label>
                </div>
                <div class="d-flex mb-1-5r" style="width: calc(50% - 1rem)">
                    <ibm-label
                        invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                        [invalid]="isInvalid(i, 'fraction')"
                        invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                        >Miteigentumsanteile (MEA)
                        <input
                            ibmText
                            name="fraction"
                            id="fraction"
                            formControlName="fraction"
                            [invalid]="isInvalid(i, 'fraction')"
                            type="number"
                        />
                    </ibm-label>
                </div>

                <ibm-label
                    class="mb-1-5r"
                    [invalid]="isInvalid(i, 'inventory')"
                    invalidText="{{ 'ERROR.GENERAL' | translate }}"
                    >{{ 'ENTITIES.OWNERSHIP.LABEL_INVENTORY' | translate }}

                    <textarea
                        ibmTextArea
                        aria-label="textarea"
                        formControlName="inventory"
                        maxlength="255"
                        [invalid]="isInvalid(i, 'inventory')"
                    ></textarea>
                </ibm-label>

                <div
                    ibmRow
                    *ngIf="
                        (this.ownershipsControl.at(i).value?.type?.value ||
                            this.ownershipsControl.at(i).value?.type) === 'APARTMENT'
                            ? true
                            : false
                    "
                >
                    <div ibmCol>
                        <ibm-checkbox formControlName="isNotResidential"
                            ><span class="tw-ml-2">Wird nicht zu Wohnzwecken genutzt</span>
                        </ibm-checkbox>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </form>

    <app-scalara-button
        (click)="addNewBuilding()"
        variant="ghost"
        svgName="24_add.svg"
        *ngIf="!editingMode"
        class="d-inline-block"
        >{{ 'PAGES.OWNERSHIP.ADD_OWNERSHIP.ADD_MV_UNIT' | translate }}</app-scalara-button
    >
    <!-- END FORM -->
    <div class="mt-7-5r d-flex justify-space-between">
        <app-scalara-button (click)="abort()" [variant]="'outline'">
            {{ 'COMMON.BTN_CANCEL' | translate }}
        </app-scalara-button>

        <app-scalara-button (click)="onSubmit()" [variant]="'default'" type="submit" [disabled]="!form.valid">
            {{ 'COMMON.BTN_SAVE' | translate }}
        </app-scalara-button>
    </div>
</app-overlay-container>
