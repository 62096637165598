import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
    BehaviorSubject,
    Observable,
    Subject,
    combineLatest,
    map,
    of,
    shareReplay,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs';
import { BreadCrumbService } from 'src/app/core/services/breadcrumb.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { BankAccountsService, LedgerDto, PaymentDto, PaymentsService } from 'src/app/generated-sources/accounting';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { CellTemplate } from 'src/app/shared/table/enums/cell-template';
import { HeaderTemplate } from 'src/app/shared/table/enums/header-template';
import { HeaderItem } from 'src/app/shared/table/interfaces/header-item';
import { TableItem } from 'src/app/shared/table/interfaces/table-item';
import { TableModel } from 'src/app/shared/table/interfaces/table-model';
import { LedgerCustomService } from '../../../services/ledger-custom.service';
import {
    AddPaymentComponentConfig,
    BankingPaymentsAddEditOverlayComponent,
} from '../banking-payments-add-edit-overlay/banking-payments-add-edit-overlay.component';

const mockData = [
    {
        id: '31b8efd1-0ead-4f39-9f8b-1ee7eda4d4ec',
        counterpartName: 'Eugen Bondarenko',
        counterpartIban: 'DE89 3704 0044 0532 0130 00',
        counterpartBic: '',
        executionDate: '2024-02-19',
        amount: 1500,
        type: 'MONEY_TRANSFER',
        webformStatus: 'ERROR',
        paymentStatus: 'OPEN',
        errorMessage: 'Unable to complete the operation. The account lacks the necessary capability.',
        property: {
            imgFull: null,
            id: 'a4c50e21-bef4-8fd5-1113-a05e3e07fc04',
            name: 'immo1 weg',
            address: {
                streetName: 'Stolberger Straße',
                streetNumber: '196A',
                zipCode: '50933',
                area: 'Köln',
                country: 'DE',
                createdAt: 'Tue Aug 22 10:01:39 CEST 2023',
                updatedAt: 'Tue Aug 22 10:01:39 CEST 2023',
            },
            createdAt: '2023-08-22T08:01:39.810Z',
            updatedAt: '2023-12-21T09:44:18.458Z',
            permissionRoles: ['PROPERTY_MANAGER'],
            propertyType: 'WEG_SEV',
        },
        bankAccount: {
            id: '26e33e4c-2b25-40ba-9069-e4442af541ef',
            createdAt: '2024-02-15T09:41:52.716Z',
            updatedAt: '2024-02-16T05:55:58.715Z',
            iban: 'DE77533700080111111100',
            bankLogoUrl: 'https://cdn.finapi.io/assets/images/banks-2023.22.1/logos/280001.svg',
            name: 'Main-TestAccount',
            holderName: 'Tommy Sternen-Himmel',
            type: 'Checking',
            lastSyncRequest: '2024-02-16T08:55:55.156Z',
            lastSuccessfulSync: '2024-02-16T08:55:58.712Z',
            lastSuccessfulUpdate: '2024-02-15T13:41:39.000Z',
            personId: '56c50e21-7c5e-a713-55fd-e29b75459877',
            balance: 2065340,
            balanceDate: '2024-02-15T10:41:39.000Z',
            isHidden: false,
            bankConnectionAccounts: [
                {
                    id: 'e2348fa4-0119-4454-89f5-67cc6b2670d7',
                    createdAt: '2024-02-15T09:41:52.716Z',
                    updatedAt: '2024-02-15T09:41:52.716Z',
                    finapiAccountId: 2593229,
                    finapiBankConnection: {
                        id: '7eaa3122-80f8-4add-83f3-f2b713a7004b',
                        createdAt: '2024-02-15T09:41:52.716Z',
                        updatedAt: '2024-02-15T09:41:53.809Z',
                        finapiBankConnectionId: 2282756,
                        finapiBankId: 280001,
                        bankName: 'finAPI Test Bank',
                        bankLogoUrl: 'https://cdn.finapi.io/assets/images/banks-2023.22.1/logos/280001.svg',

                        finapiUserId: '659a16e8-f3e6-4bdb-8203-dd8e57efd8bf',
                        consentExpiresAt: '2024-02-15T11:01:35.000Z',
                        userActionRequired: false,
                    },
                },
            ],
            bankAccountLedgers: [
                {
                    id: '546e8965-b61e-419a-8254-ceb5e59dc0ea',
                    createdAt: '2024-02-15T09:42:00.416Z',
                    updatedAt: '2024-02-15T09:42:00.416Z',
                    bankAccountId: '26e33e4c-2b25-40ba-9069-e4442af541ef',
                    ledgerId: 'b602452e-c386-4c3d-9ade-1331cbe966a3',
                    ledger: {
                        id: 'b602452e-c386-4c3d-9ade-1331cbe966a3',
                        createdAt: '2024-02-14T08:17:59.669Z',
                        updatedAt: '2024-02-14T08:17:59.669Z',
                        personId: '56c50e21-7c5e-a713-55fd-e29b75459877',
                        propertyId: 'a4c50e21-bef4-8fd5-1113-a05e3e07fc04',
                        type: 'WEG',
                    },
                },
            ],
        },
    },
    {
        id: 'fa1180f2-052d-4721-95e2-4703a193f8b4',
        counterpartName: 'Eugen Bondarenko',
        counterpartIban: 'DE89370400440532013000',
        executionDate: '2024-02-19',
        amount: 1000,
        type: 'MONEY_TRANSFER',
        webformStatus: 'OPEN',
        paymentStatus: 'OPEN',

        property: {
            imgFull: null,
            id: 'a4c50e21-bef4-8fd5-1113-a05e3e07fc04',
            name: 'immo1 weg',
            address: {
                streetName: 'Stolberger Straße',
                streetNumber: '196A',
                zipCode: '50933',
                area: 'Köln',
                country: 'DE',
                createdAt: 'Tue Aug 22 10:01:39 CEST 2023',
                updatedAt: 'Tue Aug 22 10:01:39 CEST 2023',
            },
            createdAt: '2023-08-22T08:01:39.810Z',
            updatedAt: '2023-12-21T09:44:18.458Z',
            permissionRoles: ['PROPERTY_MANAGER'],
            propertyType: 'WEG_SEV',
        },

        bankAccount: {
            id: '26e33e4c-2b25-40ba-9069-e4442af541ef',
            createdAt: '2024-02-15T09:41:52.716Z',
            updatedAt: '2024-02-16T05:55:58.715Z',
            iban: 'DE77533700080111111100',
            bankLogoUrl: 'https://cdn.finapi.io/assets/images/banks-2023.22.1/logos/280001.svg',
            name: 'Main-TestAccount',
            holderName: 'Tommy Sternen-Himmel',
            type: 'Checking',
            lastSyncRequest: '2024-02-16T08:55:55.156Z',
            lastSuccessfulSync: '2024-02-16T08:55:58.712Z',
            lastSuccessfulUpdate: '2024-02-15T13:41:39.000Z',
            personId: '56c50e21-7c5e-a713-55fd-e29b75459877',
            balance: 2065340,
            balanceDate: '2024-02-15T10:41:39.000Z',
            isHidden: false,
            bankConnectionAccounts: [
                {
                    id: 'e2348fa4-0119-4454-89f5-67cc6b2670d7',
                    createdAt: '2024-02-15T09:41:52.716Z',
                    updatedAt: '2024-02-15T09:41:52.716Z',
                    finapiAccountId: 2593229,
                    finapiBankConnection: {
                        id: '7eaa3122-80f8-4add-83f3-f2b713a7004b',
                        createdAt: '2024-02-15T09:41:52.716Z',
                        updatedAt: '2024-02-15T09:41:53.809Z',
                        finapiBankConnectionId: 2282756,
                        finapiBankId: 280001,
                        bankName: 'finAPI Test Bank',
                        bankLogoUrl: 'https://cdn.finapi.io/assets/images/banks-2023.22.1/logos/280001.svg',
                        bic: null,
                        finapiUserId: '659a16e8-f3e6-4bdb-8203-dd8e57efd8bf',
                        consentExpiresAt: '2024-02-15T11:01:35.000Z',
                        userActionRequired: false,
                    },
                },
            ],
            bankAccountLedgers: [
                {
                    id: '546e8965-b61e-419a-8254-ceb5e59dc0ea',
                    createdAt: '2024-02-15T09:42:00.416Z',
                    updatedAt: '2024-02-15T09:42:00.416Z',
                    bankAccountId: '26e33e4c-2b25-40ba-9069-e4442af541ef',
                    ledgerId: 'b602452e-c386-4c3d-9ade-1331cbe966a3',
                    ledger: {
                        id: 'b602452e-c386-4c3d-9ade-1331cbe966a3',
                        createdAt: '2024-02-14T08:17:59.669Z',
                        updatedAt: '2024-02-14T08:17:59.669Z',
                        personId: '56c50e21-7c5e-a713-55fd-e29b75459877',
                        propertyId: 'a4c50e21-bef4-8fd5-1113-a05e3e07fc04',
                        type: 'WEG',
                    },
                },
            ],
        },
    },
] as const;

@Component({
    selector: 'app-banking-payments-index',
    templateUrl: './banking-payments-index.component.html',
    styleUrls: ['./banking-payments-index.component.scss'],
})
export class BankingPaymentsIndexComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();
    private refresh$ = new BehaviorSubject<void>(undefined);
    public openPayments: PaymentDto[] = [];
    public incompletePayments: PaymentDto[] = [];
    public ledgerId = '';
    public ledger?: LedgerDto;
    public hasCreditorId = false;
    public openDirectDebitTabOnInit = false;

    public isLoading = false;

    public bankAccounts = this.ledgerCustomService.getLedger$().pipe(
        switchMap((ledger) => {
            if (!ledger) {
                return of();
            }
            this.ledgerId = ledger.id;
            if (ledger.creditorIdentifier) {
                this.hasCreditorId = true;
            }

            return this.bankAccountService.findAll(ledger.id);
        }),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    public payments = combineLatest([this.ledgerCustomService.getLedgerId$(), this.refresh$]).pipe(
        switchMap(([ledgerId]) => {
            if (!ledgerId) {
                return of();
            }
            return this.paymentsService.findAll(undefined, ledgerId);
        }),
        takeUntil(this.unsubscribe$)
    );

    public directDebits$ = new BehaviorSubject<PaymentDto[]>([]);
    public transfers$ = new BehaviorSubject<PaymentDto[]>([]);
    public recurringDirectDebits$ = new BehaviorSubject(mockData);
    public recurringTransfers$ = new BehaviorSubject(mockData);

    public getTableHeader(variant: 'basic' | 'recurring' = 'basic'): (string | HeaderItem)[] {
        return [
            'ENTITIES.COMMON.STATUS',
            'ENTITIES.PAYMENT.LABEL_APPROVAL_DATE',
            'ENTITIES.BANK_ACCOUNT.ACCOUNT_OWNER',
            'ENTITIES.PAYMENT.PURPOSE',
            'ENTITIES.PAYMENT.LABEL_EXECUITION_DATE',
            ...(variant === 'recurring' ? ['ENTITIES.PAYMENT.LABEL_RHYTHM'] : []),
            {
                data: { key: 'ENTITIES.COMMON.LABEL_AMOUNT_TABLE', params: {} },
                template: HeaderTemplate.RightAligned,
            },
            '',
        ];
    }

    // Lastschriften
    public directDebitsTable$: Observable<TableModel> = this.directDebits$.pipe(
        map((item) => {
            return {
                header: this.getTableHeader(),
                data: item.map((item) => this.createRow({ item })),
            };
        }),
        takeUntil(this.unsubscribe$)
    );

    //Überweisungen
    public transfersTable$: Observable<TableModel> = this.transfers$.pipe(
        map((item) => {
            return {
                header: this.getTableHeader(),
                data: item.map((item) => this.createRow({ item })),
            };
        }),
        takeUntil(this.unsubscribe$)
    );

    // DauerLastschriften
    public recurringDirectDebitsTable$ = this.recurringDirectDebits$.pipe(
        map((item) => {
            return {
                header: this.getTableHeader('recurring'),
                data: item.map((item) => this.createRow({ item: item as unknown as PaymentDto, variant: 'recurring' })),
            };
        }),
        takeUntil(this.unsubscribe$)
    );

    // Dauerüberweisungen
    public recurringTransfersTable$ = this.recurringTransfers$.pipe(
        map((item) => {
            return {
                header: this.getTableHeader('recurring'),
                data: item.map((item) => this.createRow({ item: item as unknown as PaymentDto, variant: 'recurring' })),
            };
        }),
        takeUntil(this.unsubscribe$)
    );

    public constructor(
        private ledgerCustomService: LedgerCustomService,
        private overlayService: OverlayService,
        private paymentsService: PaymentsService,
        private translateService: TranslateService,
        private bankAccountService: BankAccountsService,
        private router: Router,
        private breadcrumbService: BreadCrumbService,
        private toastService: ToastService
    ) {
        const routeState = this.router.getCurrentNavigation();
        if (routeState && routeState.extras.state) {
            this.openDirectDebitTabOnInit = routeState.extras.state['openDirectDebitTabOnInit'];
        }
    }

    public ngOnInit(): void {
        this.payments.subscribe((payments) => {
            this.openPayments = payments.filter(
                (payment) => payment.paymentStatus === 'OPEN' || payment.paymentStatus === 'EXPORTED'
            );
            this.incompletePayments = payments.filter((payment) => payment.paymentStatus === 'INCOMPLETE');
            this.directDebits$.next(payments.filter((order) => order.type === 'DIRECT_DEBIT'));
            this.transfers$.next(payments.filter((order) => order.type === 'MONEY_TRANSFER'));
            this.initBreadcrumbs();
        });
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    private initBreadcrumbs(): void {
        const breadcrumbs = [
            {
                content: this.translateService.instant('ENTITIES.PAYMENT.LABEL_TRANSACTION'),
                route: [`/accounting/ledger/${this.ledgerId}/payments`],

                current: true,
            },
        ];
        this.breadcrumbService.resetBreadCrumbs();
        this.breadcrumbService.updateBreadCrumbs(breadcrumbs);
    }

    public getStatusColorAndIcon(status: PaymentDto.PaymentStatusEnum): { color: string; icon: string } {
        switch (status) {
            case 'ERROR':
                return {
                    color: 's-red-01',
                    icon: '/assets/icons/24_open.svg',
                };
            case 'OPEN':
                return {
                    color: 's-orange-01',
                    icon: '/assets/icons/24_progress.svg',
                };
            case 'DONE':
                return {
                    color: 's-green-01',
                    icon: '/assets/icons/24_closed.svg',
                };

            case 'PROCESSING':
                return {
                    color: 's-blue-01',
                    icon: '/assets/icons/24_closed.svg',
                };
            case 'INCOMPLETE':
                return {
                    color: 's-red-01',
                    icon: '/assets/icons/24_attention.svg',
                };

            case 'EXPORTED':
                return {
                    color: 's-gray-02',
                    icon: '/assets/icons/24_export.svg',
                };

            default:
                return {
                    color: 's-orange-01',
                    icon: '/assets/icons/24_progress.svg',
                };
        }
    }

    private createRow({ item, variant = 'basic' }: { item: PaymentDto; variant?: 'basic' | 'recurring' }): TableItem[] {
        const link = item.id;
        const statusInfo = this.getStatusColorAndIcon(item.paymentStatus);

        return [
            {
                data: {
                    label: this.translateService.instant('ENTITIES.PAYMENT.LABEL_STATUS_' + item.paymentStatus),
                    link,
                    textColor: statusInfo.color,
                    iconSrc: statusInfo.icon,
                },
                template: CellTemplate.DynamicIcon,
            },
            {
                data: {
                    label: item.approvalDate || '',
                    link,
                },
                template: CellTemplate.Date,
            },
            {
                data: {
                    label: item.counterpartName,
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: item.purpose || '',
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: item.executionDate,
                    link,
                },
                template: CellTemplate.Date,
            },
            ...(variant === 'recurring'
                ? [
                      {
                          // TODO: fix label with given BE type
                          data: {
                              label: 'rhythm',
                              link,
                          },
                          template: CellTemplate.Default,
                      },
                  ]
                : []),
            {
                data: {
                    label: item.amount,
                    link,
                },
                template: CellTemplate.EuroCent,
            },
            this.addEditButton(item),
        ];
    }

    private addEditButton(item: PaymentDto): TableItem {
        if (
            item.paymentStatus === PaymentDto.PaymentStatusEnum.Open ||
            item.paymentStatus === PaymentDto.PaymentStatusEnum.Incomplete ||
            item.paymentStatus === PaymentDto.PaymentStatusEnum.Exported
            //|| item.paymentStatus === ImportInfo.ProcessingStatusEnum.Failed
        ) {
            return {
                data: {
                    label: '',
                    textColor: 's-gray-02',
                    iconSrc: '/assets/icons/24_edit.svg',
                    extraData: {
                        showOnlyWithHover: !(item.paymentStatus === PaymentDto.PaymentStatusEnum.Incomplete),
                        eventName: 'editPayment',
                        payment: item,
                    },
                },
                template: CellTemplate.iconButton,
            };
        }
        return {
            data: {
                label: '',
            },
            template: CellTemplate.Default,
        };
    }

    public handlingEvent($event: any): void {
        if ($event.extraData && $event.extraData.eventName === 'editPayment') {
            this.openAddBankOrderOverlay($event.extraData.payment.type, true, $event.extraData.payment.id);
        }
    }

    // TODO: fix typing
    public openAddBankOrderOverlay(
        paymentType: PaymentDto.TypeEnum | 'recurringTransfer' | 'recurringDebit',
        editMode?: boolean,
        paymentId?: string
    ): void {
        const config: AddPaymentComponentConfig = { data: { paymentType, editMode, paymentId } };
        const ref = this.overlayService.open(BankingPaymentsAddEditOverlayComponent, config);
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.refresh$.next());
    }

    public navigateToPaymentApprovalsOverview(): void {
        const bankConnectionBIC: string[] = [];
        //Taking Name here since there are Banks without BIC
        this.openPayments.map((payment) => {
            if (bankConnectionBIC.includes(payment.bankAccount.bic)) {
                return;
            }
            bankConnectionBIC.push(payment.bankAccount.bic);
        });
        this.router.navigate([
            bankConnectionBIC.length === 1
                ? `/accounting/ledger/${this.ledgerId}/payment-approvals/${bankConnectionBIC[0]}`
                : `/accounting/ledger/${this.ledgerId}/payment-approvals`,
        ]);
    }

    public updatePayments(): void {
        this.isLoading = true;
        this.paymentsService
            .updatePaymentsStatus()
            .pipe(tap(() => (this.isLoading = false)))
            .subscribe({
                error: () => {
                    this.toastService.showError(this.translateService.instant('COMPONENTS.TOAST.TOAST_ERROR'));
                },
            });
    }
}
