import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbItem } from 'carbon-components-angular';
import {
    BehaviorSubject,
    Subject,
    combineLatest,
    distinctUntilChanged,
    filter,
    map,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs';
import { BreadCrumbService } from 'src/app/core/services/breadcrumb.service';
import { PropertyCustomService } from 'src/app/features/property/services/property-custom.service';
import {
    Address,
    Building,
    BuildingSummaryDto,
    BuildingsService,
    Person,
    Property,
    SharedOwnership,
    SharedOwnershipService,
} from 'src/app/generated-sources/base';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { CellTemplate } from 'src/app/shared/table/enums/cell-template';
import { TableItem } from 'src/app/shared/table/interfaces/table-item';
import { TableModel } from 'src/app/shared/table/interfaces/table-model';
import { AddEditSharedOwnershipOverlayComponent } from '../../shared-ownership/add-edit-shared-ownership-overlay/add-edit-shared-ownership-overlay.component';
import { SharedOwnershipDetailsComponent } from '../../shared-ownership/shared-ownership-details/shared-ownership-details.component';
import { SharedOwnershipCallbacks } from '../../shared-ownership/shared-ownership-index/shared-ownership-index.component';
import { AddEditBuildingOverlayComponent } from '../add-edit-building-overlay/add-edit-building-overlay.component';
import { DeleteBuildingOverlayComponent } from '../delete-building-overlay/delete-building-overlay.component';

@Component({
    selector: 'app-property-bulding-detail',
    templateUrl: './property-bulding-detail.component.html',
    styleUrls: ['./property-bulding-detail.component.scss'],
})
export class PropertyBuldingDetailComponent implements OnInit, OnDestroy {
    public breadcrumbs: BreadcrumbItem[] = [];
    public refreshBuilding$ = new BehaviorSubject<void>(undefined);
    public refreshSharedOwnership$ = new BehaviorSubject<void>(undefined);
    public buildingId = '';
    public propertyId = '';
    public building?: Building;
    public isLoading = false;
    public buildingAddress?: Address;
    private unsubscribe$ = new Subject<void>();
    public sharedOwnershipTable: TableModel = { data: [], header: [] };
    public propertyType?: Property.PropertyTypeEnum;
    public buildingOverview?: BuildingSummaryDto;
    public sharedOwnershipIdList?: string[] = []; // To send to the overlay to do the pagination
    public isPropertyManager = false;

    public buildingId$ = this.route.url.pipe(
        map(() => {
            const buildingId = this.route.snapshot.paramMap.get('buildingId');
            this.buildingId = buildingId ?? '';
            return buildingId;
        }),
        filter(Boolean),
        distinctUntilChanged()
    );

    public property$ = this.propertyCustomService.getProperty$().pipe(
        filter(Boolean),
        tap((property) => {
            this.propertyType = property?.propertyType;
            this.propertyId = property?.id ?? '';
        })
    );

    public findOneBulding$ = combineLatest([this.property$, this.buildingId$, this.refreshBuilding$]).pipe(
        switchMap(([propertyId, buildingId]) => {
            this.isLoading = true;
            return this.buildingsService.findOne(propertyId.id, buildingId);
        }),
        tap((building) => {
            this.building = building;
            this.isPropertyManager = Object.values(building.permissionRoles).includes(
                Person.PermissionRolesEnum.PropertyManager
            );
            // TODO: building address type from backend is wrong
            this.buildingAddress = this.building.address as Address;
            this.initBreadCrumbs();
        }),
        takeUntil(this.unsubscribe$)
    );

    public buildingOverview$ = combineLatest([this.property$, this.buildingId$, this.refreshBuilding$]).pipe(
        switchMap(([propertyId, buildingId]) => {
            return this.buildingsService.getBuildingSummary(propertyId.id, buildingId);
        }),
        tap((overview) => {
            this.buildingOverview = overview;
        }),
        takeUntil(this.unsubscribe$)
    );

    public findSharedOwnership$ = combineLatest([this.property$, this.buildingId$, this.refreshSharedOwnership$]).pipe(
        switchMap(([property, buildingId]) => {
            return this.sharedOwnershipService.getSharedOwnershipForBuilding(property.id, buildingId);
        }),
        tap((sharedOwnership) => {
            this.sharedOwnershipIdList = sharedOwnership.map((item) => item.id);
            this.sharedOwnershipTable.header = [
                'ENTITIES.SHARED_OWNERSHIP.LABEL_CATEGORY',
                'ENTITIES.PROPERTY.LABEL_NAME',
                'ENTITIES.OWNERSHIP.LABEL_BUILDING',
                'ENTITIES.OWNERSHIP.LABEL_ENTITY',
            ];

            this.sharedOwnershipTable.data = sharedOwnership.map((item) => this.createSharedOwnershipTableRow(item));
        })
    );

    public sharedOwnershipCallbacks: SharedOwnershipCallbacks = {
        addOverlay: () => {
            this.openAddEditSharedOwnershipOverlay();
        },
        detailsOverlay: (sharedOwnership) => {
            if (sharedOwnership) {
                this.openSharedOwnershipDetailsOverlay(sharedOwnership);
            }
        },
    };

    public constructor(
        private route: ActivatedRoute,
        private buildingsService: BuildingsService,
        private propertyCustomService: PropertyCustomService,
        private breadcrumbService: BreadCrumbService,
        private overlayService: OverlayService,
        private sharedOwnershipService: SharedOwnershipService
    ) {}

    public ngOnInit(): void {
        this.findOneBulding$.subscribe(() => (this.isLoading = false));
        this.findSharedOwnership$.subscribe();
        this.buildingOverview$.subscribe();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    private initBreadCrumbs(): void {
        const breadcrumb: BreadcrumbItem[] = [
            {
                content: 'Basisdaten',
                route: [`/properties/${this.propertyId}/base-data`],
            },
            {
                content: this.building?.name ?? '',
                route: [`/properties/${this.propertyId}/building-details/${this.buildingId}`],
                current: true,
            },
        ];
        this.breadcrumbService.resetBreadCrumbs();
        this.breadcrumbService.updateBreadCrumbs(breadcrumb);
        this.breadcrumbs = this.breadcrumbService.getCurrentBreadCrumbs();
    }

    public openDeleteBuildingOverlay(): void {
        const data = { building: this.building, propertyId: this.propertyId };

        const ref = this.overlayService.open(DeleteBuildingOverlayComponent, { data });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
    }

    public openEditBuildingOverlay(): void {
        const ref = this.overlayService.open(AddEditBuildingOverlayComponent, {
            data: { buildingToEdit: this.building, propertyId: this.propertyId },
        });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            ref.close();
        });

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.refreshBuilding$.next();
        });
    }

    public openAddEditSharedOwnershipOverlay(): void {
        const ref = this.overlayService.open(AddEditSharedOwnershipOverlayComponent, {
            data: { currentBuilding: this.building },
        });

        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.refreshSharedOwnership$.next());
    }

    public openSharedOwnershipDetailsOverlay(sharedOwnership: SharedOwnership): void {
        const ref = this.overlayService.open(SharedOwnershipDetailsComponent, {
            data: {
                id: sharedOwnership.id,
                propertyId: this.propertyId,
                buildingId: this.building?.id,
                sharedOwnerhsipIdList: this.sharedOwnershipIdList,
            },
            overlayType: 'half-page',
        });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.refreshSharedOwnership$.next();
            ref.close();
        });

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.refreshSharedOwnership$.next();
        });
    }

    private createSharedOwnershipTableRow(item: SharedOwnership): TableItem[] {
        return [
            {
                data: {
                    label: item.category ?? '',
                    extraData: { sharedOwnership: item },
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: item.title ?? '',
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: item.buildingInfo?.name ?? '',
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: item.ownershipInfo?.name ?? '',
                },
                template: CellTemplate.Default,
            },
        ];
    }
}
