import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import {
    BreadcrumbModule,
    DropdownModule,
    InputModule,
    LoadingModule,
    SelectModule,
    UIShellModule,
} from 'carbon-components-angular';
import { SharedModule } from 'src/app/shared/shared.module';
import { AccountingModule } from '../accounting/accounting.module';
import { ListGeneratorDetailsComponent } from './components/list-generator-details/list-generator-details.component';
import { ListGeneratorOverviewComponent } from './components/list-generator-overview/list-generator-overview.component';

@NgModule({
    declarations: [ListGeneratorOverviewComponent, ListGeneratorDetailsComponent],
    imports: [
        CommonModule,
        SharedModule,
        InputModule,
        SelectModule,
        TranslateModule,
        LoadingModule,
        UIShellModule,
        BreadcrumbModule,
        DropdownModule,
        AngularSvgIconModule,
        AccountingModule,
    ],
})
export class ListGeneratorModule {}
