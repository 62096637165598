import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ListItem } from 'carbon-components-angular';

@Component({
    selector: 'app-file',
    templateUrl: './file.component.html',
    styleUrls: ['./file.component.scss'],
})
export class FileComponent {
    @Input() public file?: any;

    @Input() public fileProgress = 0;

    @Input() public categories?: ListItem[] = [];

    @Input() public fileTypes?: ListItem[] = [];

    @Input() public accountsList?: ListItem[] = [];

    @Input() public isDisabledOnInit = false;

    @Input() public isCategoryDisable = false;

    @Output() public cancel = new EventEmitter();

    @Output() public categorySelected = new EventEmitter();

    @Output() public typeSelected = new EventEmitter();

    @Output() public accountSelected = new EventEmitter();

    public cancelFile(): void {
        this.cancel.emit(this.file);
    }

    public onSelectCategory($event: any): void {
        this.categorySelected.emit($event);
    }

    public onSelectType($event: any): void {
        this.typeSelected.emit($event);
    }

    public onSelectAccount($event: any): void {
        this.accountSelected.emit($event);
    }
}
