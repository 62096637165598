import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ListItem } from 'carbon-components-angular';
import { calculateTaxAmount, formControl, formControlHasError } from 'src/app/core/utils/common';
import { OccupationFormDto } from 'src/app/generated-sources/base';
import { FileUploaderComponent } from 'src/app/shared/components/file-uploader/file-uploader.component';

@Component({
    selector: 'app-add-occupation-step4',
    templateUrl: './add-occupation-step4.component.html',
    styleUrls: ['./add-occupation-step4.component.scss'],
})
export class AddOccupationStep4Component implements OnInit {
    public rentCalculationMethod: OccupationFormDto.RentCalculationMethodEnum = 'BASE_RENT_OPERATIONAL_COST_HEATING';
    public showPercentageTaxIncluded = false;
    public depositTypeList: ListItem[] = [
        { content: 'Keine Kaution', value: 'NONE', selected: false },
        { content: 'Banküberweisung', value: 'BANK_TRANSFER', selected: true },
        { content: 'Barzahlung', value: 'CASH_PAYMENT', selected: false },
        { content: 'Verpfändetes Sparkonto', value: 'PLEDGED_SAVING_ACCOUNT', selected: false },
        { content: 'Bankbürgschaft', value: 'BANK_GUARANTEE', selected: false },
        { content: 'Kautionsversicherung', value: 'DEPOSIT_INSURANCE', selected: false },
        { content: 'Elternbürgschaft', value: 'PARENTAL_GUARANTEE', selected: false },
    ];

    private createWorkingDayList(): ListItem[] {
        const array: ListItem[] = [];

        for (let i = 1; i <= 21; i++) {
            array.push({ value: i, content: i + '. ' + 'Werktag des Monats', selected: i === 1 });
        }

        return array;
    }

    public dueWorkingDayList: ListItem[] = this.createWorkingDayList();

    @Input() public form?: UntypedFormGroup;
    @Input() public occupationType: OccupationFormDto.OccupationTypeEnum = 'RENTING';

    @ViewChild('fileUpload')
    public fileUploader?: FileUploaderComponent;

    public fileUuids: string[] = [];
    public areFilesFullyUploaded = false;

    public constructor(private formBuilder: UntypedFormBuilder) {}

    public ngOnInit(): void {}

    public switchRentCalculationMethod(rentCalculationMethod: OccupationFormDto.RentCalculationMethodEnum): void {
        this.rentCalculationMethod = rentCalculationMethod;
        this.form?.patchValue({ rentCalculationMethod: rentCalculationMethod });
        this.rentIntervalsControl.value.map((item: any, index: number) => {
            this.getRentIntervalItemForm(index).patchValue({
                rent: [null],
                operationalCosts: [null],
                heatingCosts: [null],
                prepaidSum: [null],
            });
        });
    }

    public updateLoadingFilesStatus($event: boolean): void {
        this.areFilesFullyUploaded = $event;
    }

    public updateFileIdsLodaded($event: string[]): void {
        this.fileUuids = $event;
    }

    public get rentIntervalsControl(): UntypedFormArray {
        return this.form?.controls['rentIntervals'] as UntypedFormArray;
    }

    public removeRentInterval(index: number): void {
        this.rentIntervalsControl.removeAt(index);
    }

    public addRendInterval(): void {
        this.rentIntervalsControl.push(
            this.formBuilder.group({
                startingDate: [null],
                rent: [null],
                operationalCosts: [null],
                heatingCosts: [null],
                prepaidSum: [null],
            })
        );
    }

    public getRentIntervalItemForm(index: number): UntypedFormGroup {
        return this.rentIntervalsControl.at(index) as UntypedFormGroup;
    }

    public getTaxSum(index: number): number {
        const tax = this.form?.value.percentageTaxIncluded === '19.' ? 0.19 : 0.07;
        const prepaidSum = this.getPrepaidSum(index);
        return Number(calculateTaxAmount(tax * 100, prepaidSum).toFixed(2));
    }

    public getPrepaidSum(index: number): number {
        const { rent, operationalCosts, heatingCosts, prepaidSum } = this.getRentIntervalItemForm(index).value;
        return Number(prepaidSum || 0) + Number(rent || 0) + Number(operationalCosts || 0) + Number(heatingCosts || 0);
    }

    public isInvalid(controlName: string): boolean {
        if (this.form) {
            return formControlHasError(formControl(this.form, controlName), 'required');
        }
        return false;
    }

    public onCheckboxChange(): void {
        this.showPercentageTaxIncluded = !this.showPercentageTaxIncluded;
        if (!this.showPercentageTaxIncluded) {
            this.form?.patchValue({ percentageTaxIncluded: '0.' });
        } else {
            this.form?.patchValue({ percentageTaxIncluded: '19.' });
        }
    }

    public isValidDate(index: number): boolean {
        if (
            index === 0 ||
            (index > 0 && !this.getRentIntervalItemForm(index - 1).value.startingDate) ||
            (index > 0 && !this.getRentIntervalItemForm(index).value.startingDate)
        ) {
            return false;
        }

        const currentDate = new Date(this.getRentIntervalItemForm(index).value.startingDate[0]);
        const previousDate = new Date(this.getRentIntervalItemForm(index - 1).value.startingDate[0]);

        if (currentDate.getTime() <= previousDate.getTime()) {
            return true;
        }

        return false;
    }

    public selectedDepositType(event: any): void {
        if (event.item.value !== 'NONE') {
            this.form?.controls['depositDueDate'].setValidators(Validators.required);
            this.form?.controls['depositAmount'].setValidators(Validators.required);
        } else {
            this.form?.controls['depositDueDate'].clearValidators();
            this.form?.controls['depositAmount'].clearValidators();
        }

        this.form?.controls['depositDueDate'].setErrors(null);
        this.form?.controls['depositDueDate'].updateValueAndValidity();

        this.form?.controls['depositAmount'].setErrors(null);
        this.form?.controls['depositAmount'].updateValueAndValidity();
    }

    public disabledDueWorkingDayDropdown(): boolean {
        return (
            !this.getRentIntervalItemForm(0).value.rent &&
            !this.getRentIntervalItemForm(0).value.operationalCosts &&
            !this.getRentIntervalItemForm(0).value.heatingCosts
        );
    }
}
