import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject, combineLatest, of, switchMap, takeUntil, tap } from 'rxjs';
import { unitTypeNameFromEnum } from 'src/app/core/utils/common';
import { Ownership, Unit, UnitsService } from 'src/app/generated-sources/base';
import { OverlayCallbacks } from 'src/app/shared/components/overlay-details/overlay-details.component';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { AddEditOwnershipFormComponent } from '../add-edit-ownership-form/add-edit-ownership-form.component';

@Component({
    selector: 'app-ownership-unit-details-overlay',
    templateUrl: './ownership-unit-details-overlay.component.html',
    styleUrls: ['./ownership-unit-details-overlay.component.scss'],
})
export class OwnershipUnitDetailsOverlayComponent extends OverlayChildComponent implements OnInit, OnDestroy {
    public ownershipUnitIdList?: string[];
    public propertyId = '';
    public ownership?: Ownership;

    public unit?: Unit;
    public units?: Unit[];
    public unitType?: string;

    public isLoading = false;

    private unsubscribe$ = new Subject<void>();
    public currentUnitId$ = new BehaviorSubject<string>('');
    public refresh$ = new BehaviorSubject<void>(undefined);

    public overlayCallbacks: OverlayCallbacks = {
        editOverlay: () => {
            this.openEditOverlay();
        },
    };

    public findUnit$ = combineLatest([this.currentUnitId$, this.refresh$]).pipe(
        switchMap(([unitId]) => {
            if (!this.ownership) {
                return of();
            }
            return this.unitsService.findOne(this.propertyId, this.ownership?.id, unitId);
        }),
        tap((unit) => {
            this.unit = unit;
            this.unitType = unitTypeNameFromEnum(unit);
            this.isLoading = false;
        })
    );

    public constructor(private unitsService: UnitsService, private overlayService: OverlayService) {
        super();
    }

    public ngOnInit(): void {
        this.propertyId = this.config?.data.propertyId;
        this.ownership = this.config?.data.ownership;
        this.currentUnitId$.next(this.config?.data?.unitId);
        this.units = this.config?.data.units;

        this.ownershipUnitIdList = this.units?.map((unit) => unit.id);

        this.isLoading = true;

        this.findUnit$.subscribe();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public openEditOverlay(): void {
        const ref = this.overlayService.open(AddEditOwnershipFormComponent, {
            data: {
                ownership: this.ownership,
                editingMode: true,
                units: this.units,
                propertyId: this.propertyId,
            },
        });

        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            ref.close();
        });

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.refresh$.next());
    }

    public findNewUnitId(id: string): void {
        this.isLoading = true;
        this.currentUnitId$.next(id);
    }
}
