/**
 * SCALARA Base Module
 * The SCALARA API for User, Person and Property resources
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Property } from './property';


export interface PotentialLedgerPropertyDto { 
    property: Property;
    ledgerTypes: Array<PotentialLedgerPropertyDto.LedgerTypesEnum>;
}
export namespace PotentialLedgerPropertyDto {
    export type LedgerTypesEnum = 'MV' | 'WEG' | 'SEV';
    export const LedgerTypesEnum = {
        Mv: 'MV' as LedgerTypesEnum,
        Weg: 'WEG' as LedgerTypesEnum,
        Sev: 'SEV' as LedgerTypesEnum
    };
}


