import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { BaseReportsService } from './api/baseReports.service';
import { BuildingsService } from './api/buildings.service';
import { DefaultService } from './api/default.service';
import { EnergyCertificatesService } from './api/energyCertificates.service';
import { FilesService } from './api/files.service';
import { HubSpotService } from './api/hubSpot.service';
import { ImportFilesService } from './api/importFiles.service';
import { MaintenanceService } from './api/maintenance.service';
import { OccupationService } from './api/occupation.service';
import { OperationsService } from './api/operations.service';
import { OwnershipsService } from './api/ownerships.service';
import { PermissionsService } from './api/permissions.service';
import { PersonsService } from './api/persons.service';
import { PotentialLedgersService } from './api/potentialLedgers.service';
import { PropertiesService } from './api/properties.service';
import { RecommendationsService } from './api/recommendations.service';
import { RelationsService } from './api/relations.service';
import { SharedOwnershipService } from './api/sharedOwnership.service';
import { SubscriptionsService } from './api/subscriptions.service';
import { TechnicalEquipmentService } from './api/technicalEquipment.service';
import { TicketsService } from './api/tickets.service';
import { UnitsService } from './api/units.service';
import { UserManagementService } from './api/userManagement.service';
import { UsersService } from './api/users.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
