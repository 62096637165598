import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Subject, combineLatest, interval, switchMap, takeUntil, takeWhile, tap } from 'rxjs';
import { TooltipKey } from 'src/app/features/account-settings/services/custom-tooltip.service';
import { PersonLocalService } from 'src/app/features/property/services/person-local.service';
import { EconomicPlanDto, EconomicPlansService } from 'src/app/generated-sources/accounting';
import { Person, Property } from 'src/app/generated-sources/base';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { WegEconomicPlanAddComponent } from './weg-economic-plan-add/weg-economic-plan-add.component';

@Component({
    selector: 'app-weg-economic-plan-index',
    templateUrl: './weg-economic-plan-index.component.html',
    styleUrls: ['./weg-economic-plan-index.component.scss'],
})
export class WegEconomicPlanIndexComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();
    public isLoading = false;

    public economicPlans: EconomicPlanDto[] = [];

    public showModal = false;

    public ledgerId = '';

    public isPropertyManager = false;

    public constructor(
        private route: ActivatedRoute,
        private overlayService: OverlayService,
        private economicPlanService: EconomicPlansService,
        private personLocalService: PersonLocalService
    ) {}

    public ngOnInit(): void {
        this.isLoading = true;
        combineLatest([this.route.parent!.paramMap, this.route.paramMap])
            .pipe(
                tap((params: ParamMap[]) => {
                    this.ledgerId = String(params[0].get('id'));
                }),
                switchMap(() => this.economicPlanService.findAll(this.ledgerId)),
                tap((economicPlans: EconomicPlanDto[]) => {
                    this.economicPlans = economicPlans;
                    this.isLoading = false;
                }),
                switchMap(() => this.personLocalService.getRolesOfProperty()),
                tap((roles: Property.PermissionRolesEnum[] | null) => {
                    this.isPropertyManager = roles
                        ? Object.values(roles).includes(Person.PermissionRolesEnum.PropertyManager)
                        : false;
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();

        this.activationPipe$.subscribe();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    // If there is an economic plan that is being activated currently,
    // we refresh the economic plan list Every 20 seconds until the plan is active.
    public activationPipe$ = interval(20000).pipe(
        takeUntil(this.unsubscribe$),
        takeWhile(() => this.planIsActivating()),
        switchMap(() => this.economicPlanService.findAll(this.ledgerId)),
        tap((economicPlans: EconomicPlanDto[]) => {
            this.economicPlans = economicPlans;
        })
    );

    public startEconomicPlanCreation(): void {
        const data = { ledgerId: this.ledgerId, economicPlans: this.economicPlans };
        const ref = this.overlayService.open(WegEconomicPlanAddComponent, {
            data,
        });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
    }

    private planIsActivating(): boolean {
        let isActivating = false;
        if (this.economicPlans) {
            isActivating = !!this.economicPlans.find(
                (economicPlan: EconomicPlanDto) => economicPlan.status === 'ACTIVATING'
            );
        }
        return isActivating;
    }

    public TooltipKey = TooltipKey;
}
