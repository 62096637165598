import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-icon-bubble',
    templateUrl: './icon-bubble.component.html',
    styleUrls: ['./icon-bubble.component.scss'],
})
export class IconBubbleComponent implements OnInit {
    @Input() public size: 24 | 32 | 40 | 72 = 24;
    @Input() public color: 'blue' | 'green' | 'orange' | 'purple' | 'gray' | 'red' | 'no-fill' = 'green';
    @Input() public image = '';
    @Input() public fillNone = false;

    public sanitizedUrl!: SafeUrl;

    public constructor(private sanitizer: DomSanitizer) {}

    public ngOnInit(): void {
        this.sanitizer.bypassSecurityTrustHtml('');
    }
}
