import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { formControl, formControlHasError } from 'src/app/core/utils/common';
import { OccupationFormDto, Person } from 'src/app/generated-sources/base';

type PersonFormated = Person & { isExistingPersons?: boolean };
@Component({
    selector: 'app-add-occupation-step3',
    templateUrl: './add-occupation-step3.component.html',
    styleUrls: ['./add-occupation-step3.component.scss'],
})
export class AddOccupationStep3Component implements OnChanges {
    @Input() public tenants: Person[] = [];
    @Input() public existingPersons: Person[] = [];
    @Input() public form?: UntypedFormGroup;
    @Input() public typeSelected?: OccupationFormDto.OccupationTypeEnum = 'RENTING';
    @Output() public removeTenant = new EventEmitter<{ id: string; existingPersons: boolean }>();
    @Output() public addNewTenant = new EventEmitter<void>();
    @Output() public setFormDataControl = new EventEmitter<{ controlName: string; value: string }>();
    @Output() public openEditPersonForm = new EventEmitter<{ person: Person; index: number }>();

    public persons: PersonFormated[] = [];

    public constructor() {}

    public ngOnChanges(): void {
        this.persons = this.tenants.concat(
            this.existingPersons.map((person) => {
                return { ...person, isExistingPersons: true };
            })
        );
    }

    public removePerson(id: string, existingPersons: boolean): void {
        this.removeTenant.emit({ id, existingPersons });

        this.persons?.splice(
            this.persons?.findIndex((item) => item.id === id),
            1
        );
    }

    public addNewPerson(): void {
        this.addNewTenant.emit();
    }

    public isInvalid(controlName: string): boolean {
        if (this.form) {
            return formControlHasError(formControl(this.form, controlName), 'required');
        }
        return false;
    }

    public isInvalidNumber(controlName: string): boolean {
        if (this.form) {
            return (
                formControlHasError(formControl(this.form, controlName), 'min') ||
                formControlHasError(formControl(this.form, controlName), 'max')
            );
        }
        return false;
    }

    public editPerson(person: Person, index: number): void {
        this.openEditPersonForm.emit({ person, index });
    }
}
