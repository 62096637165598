<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<ng-container *ngIf="!isLoading">
    <div class="s-head-28-36-bold tw-mb-[60px]">
        {{ 'PROPERTY.COMMON.BASE_DATA' | translate }}
    </div>

    <!-- START PROPERTY INFOS -->

    <div class="tw-mb-16 tile tile--not-clickable tile--static tw-p-8">
        <div class="tw-grid tw-grid-cols-5 tw-gap-x-[78px] tw-gap-y-6">
            <!-- 1 item grid -->
            <div class="tw-col-start-1 tw-col-end-3">
                <div class="property-base s-body-14-22-regular property-base-foto__wrapper">
                    <img
                        *ngIf="property && property.imgFull; else defaultPic"
                        class="property-base-foto"
                        src="{{ property.imgFull | imageBase64 }}"
                    />
                    <div
                        *ngIf="!isImageMenuOpen && isPropertyManager"
                        class="property-base__edit-button property-base-foto__button"
                        #overlayMenuOpenButton
                    >
                        <app-scalara-button
                            svgName="24_edit.svg"
                            variant="icon-only"
                            height="40px"
                        ></app-scalara-button>
                    </div>
                    <div
                        *ngIf="isImageMenuOpen"
                        #overlayMenuCloseButton
                        class="property-base__edit-button property-base-foto__button"
                    >
                        <app-scalara-button
                            svgName="24_close.svg"
                            variant="icon-only"
                            height="40px"
                        ></app-scalara-button>
                    </div>
                    <div *ngIf="isImageMenuOpen" class="property-base image-overlay-menu" #overlayMenu>
                        <ng-container *ngIf="property!.imgFull; else uploadPicMenu">
                            <div class="image-overlay-menu__option image-overlay-menu__option-container">
                                <div
                                    class="image-overlay-menu__option image-overlay-menu__option-wrapper"
                                    (click)="removeImage()"
                                >
                                    <div class="image-overlay-menu__button mb-04">
                                        <svg-icon src="/assets/icons/40_delete.svg"></svg-icon>
                                    </div>
                                    <p>{{ 'PROPERTY.DETAIL.FOTO_REMOVE' | translate }}</p>
                                </div>
                            </div>
                            <div class="image-overlay-menu__option image-overlay-menu__option-container">
                                <div
                                    class="image-overlay-menu__option image-overlay-menu__option-wrapper"
                                    (click)="editImage()"
                                >
                                    <div class="image-overlay-menu__button mb-04">
                                        <svg-icon src="/assets/icons/40_edit.svg"></svg-icon>
                                    </div>
                                    <p>{{ 'PROPERTY.DETAIL.FOTO_EDIT' | translate }}</p>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <!-- end 1 item grid -->

            <!-- 2 item grid -->
            <div class="tw-col-start-3 tw-col-end-6" *ngIf="propertyInfos() as propertyInfos">
                <div class="tw-flex tw-justify-between">
                    <div class="tw-flex tw-items-center tw-space-x-7 tw-w-full">
                        <app-icon-bubble
                            [size]="72"
                            [color]="propertyInfos?.color ?? 'green'"
                            [image]="propertyInfos?.iconSrc ?? ''"
                        ></app-icon-bubble>
                        <div>
                            <div class="s-label-12-22-semibold">
                                {{ 'PROPERTY.COMMON.DESCRIPTION' | translate }}
                            </div>
                            <div class="s-head-18-26-bold">{{ property?.name }}</div>
                        </div>
                    </div>

                    <div *ngIf="isPropertyManager" class="tw-flex tw-justify-end">
                        <app-scalara-button
                            class="tw-inline-block tw-mr-4"
                            *ngIf="isPropertyManager"
                            (click)="removeProperty()"
                            svgName="24_delete.svg"
                            variant="icon-only"
                            height="40px"
                        ></app-scalara-button>

                        <app-scalara-button
                            (click)="editDetails()"
                            svgName="24_edit.svg"
                            variant="icon-only"
                            height="40px"
                        ></app-scalara-button>
                    </div>
                </div>
                <div class="tw-mt-6 tw-w-full">
                    <div class="tw-grid tw-grid-cols-2 tw-gap-3">
                        <div class="tw-text-scalaraGray-03 tw-s-label-12-22-semibold">
                            {{ 'PROPERTY.COMMON.STREET_AND_NUMBER' | translate }}
                        </div>
                        <div class="s-body-14-22-regular">
                            {{ property?.address?.streetName }}, {{ property?.address?.streetNumber }}
                        </div>

                        <!--  -->
                        <div class="tw-text-scalaraGray-03 tw-s-label-12-22-semibold">
                            {{ 'PROPERTY.COMMON.ADDRESS_ADDITIONAL' | translate }}
                        </div>
                        <div class="s-body-14-22-regular">
                            {{ property?.address?.streetAddition }}
                        </div>

                        <!--  -->
                        <div class="tw-text-scalaraGray-03 tw-s-label-12-22-semibold">
                            {{ 'PROPERTY.COMMON.ZIP' | translate }}
                        </div>
                        <div class="s-body-14-22-regular">
                            {{ property?.address?.zipCode }}
                        </div>

                        <!--  -->
                        <div class="tw-text-scalaraGray-03 tw-s-label-12-22-semibold">
                            {{ 'PROPERTY.COMMON.AREA' | translate }}
                        </div>
                        <div class="s-body-14-22-regular">
                            {{ property?.address?.area }}
                        </div>
                    </div>

                    <hr class="tw-my-4" />

                    <div class="tw-grid tw-grid-cols-2 tw-gap-3">
                        <div class="tw-text-scalaraGray-03 tw-s-label-12-22-semibold">
                            {{ 'ENTITIES.PROPERTY.LABEL_MANAGEMENT_TYPE' | translate }}
                        </div>

                        <app-tag [color]="propertyInfos?.color ?? 'green'">{{ propertyInfos?.label }}</app-tag>
                    </div>
                </div>
            </div>
            <!-- end 2 item grid -->

            <!-- 3 item grid -->
            <div class="tw-col-start-1 tw-col-end-3">
                <!-- mudar aqui -->
                <div class="tw-grid tw-grid-cols-2 tw-gap-6" *ngIf="propertyOverview$ | async as ownershipOverview">
                    <app-property-base-data-general-info-card
                        title="Wohnfläche"
                        [info]="ownershipOverview.totalSquareMeters + ' qm'"
                        iconSrc="/assets/icons/24_wohnflaeche.svg"
                    ></app-property-base-data-general-info-card>

                    <app-property-base-data-general-info-card
                        title="Anzahl Einheiten"
                        [info]="ownershipOverview.unitCount"
                        iconSrc="/assets/icons/24_einheit.svg"
                    ></app-property-base-data-general-info-card>

                    <app-property-base-data-general-info-card
                        title="Nutzfläche"
                        [info]="ownershipOverview.totalHeatedSquareMeters + ' qm'"
                        iconSrc="/assets/icons/24_wohnflaeche.svg"
                    ></app-property-base-data-general-info-card>

                    <app-property-base-data-general-info-card
                        title="MEA Gesamt"
                        info="{{ ownershipOverview.fractionTotal | number : '0.1-5' : 'de-DE' }}"
                        iconSrc="/assets/icons/24_information.svg"
                    ></app-property-base-data-general-info-card>
                </div>
            </div>

            <!-- end 3 item grid -->

            <!-- PROPERTY DETAILS -->
            <div class="tw-col-start-3 tw-col-end-6">
                <div class="tw-flex tw-flex-col tw-gap-6">
                    <div class="tw-s-head-14-22-bold">{{ 'ENTITIES.PROPERTY.LABEL_PROPERTY_DETAILS' | translate }}</div>

                    <div class="tw-grid tw-grid-cols-2 tw-gap-2">
                        <div class="tw-text-scalaraGray-03 tw-s-label-12-22-semibold">
                            {{ 'ENTITIES.PROPERTY.LABEL_AREA' | translate }}
                        </div>
                        <div class="tw-text-scalaraGray-03 tw-s-label-12-22-semibold">
                            {{ 'ENTITIES.PROPERTY.LABEL_DISTRICT' | translate }}
                        </div>

                        <div *ngIf="property?.plotArea; else notSpecified" class="tw-s-body-14-22-regular">
                            {{ property?.plotArea }} qm
                        </div>

                        <div *ngIf="property?.district; else notSpecified" class="tw-s-body-14-22-regular">
                            {{ property?.district }}
                        </div>
                    </div>

                    <div class="tw-grid tw-grid-cols-2 tw-gap-2">
                        <div class="tw-text-scalaraGray-03 tw-s-label-12-22-semibold">
                            {{ 'ENTITIES.PROPERTY.LABEL_CONSECUTIVE_NUMBER_OF_PROPERTIES' | translate }}
                        </div>
                        <div class="tw-text-scalaraGray-03 tw-s-label-12-22-semibold">
                            {{ 'ENTITIES.PROPERTY.LABEL_ECONOMIC_TYPE' | translate }}
                        </div>

                        <div
                            *ngIf="property?.consecutiveNumberOfProperties; else notSpecified"
                            class="tw-s-body-14-22-regular"
                        >
                            {{ property?.consecutiveNumberOfProperties }}
                        </div>
                        <div *ngIf="property?.economicType; else notSpecified" class="tw-s-body-14-22-regular">
                            {{ property?.economicType }}
                        </div>
                    </div>

                    <div class="tw-grid tw-grid-cols-2 tw-gap-2">
                        <div class="tw-text-scalaraGray-03 tw-s-label-12-22-semibold">
                            {{ 'ENTITIES.PROPERTY.LABEL_LAND_PARCEL' | translate }}
                        </div>
                        <div class="tw-text-scalaraGray-03 tw-s-label-12-22-semibold">
                            {{ 'ENTITIES.PROPERTY.LABEL_HALLWAY' | translate }}
                        </div>

                        <div *ngIf="property?.landParcel; else notSpecified" class="tw-s-body-14-22-regular">
                            {{ property?.landParcel }}
                        </div>
                        <div *ngIf="property?.cadastralDistrict; else notSpecified" class="tw-s-body-14-22-regular">
                            {{ property?.cadastralDistrict }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END PROPERTY INFOS -->

    <ng-template #defaultPic>
        <div class="property-base-foto property-base-foto--default">
            <svg-icon src="/assets/icons/72_property.svg"></svg-icon>
        </div>
    </ng-template>

    <ng-template #uploadPicMenu>
        <div class="image-overlay-menu__option image-overlay-menu__option-container">
            <div class="image-overlay-menu__option image-overlay-menu__option-wrapper" (click)="uploadImage()">
                <div class="image-overlay-menu__button mb-04">
                    <svg-icon src="/assets/icons/40_upload.svg"></svg-icon>
                </div>
                <p>{{ 'PROPERTY.DETAIL.FOTO_ADD' | translate }}</p>
            </div>
        </div>
    </ng-template>

    <div class="tw-mb-16">
        <div class="mb-1-5r d-flex justify-space-between">
            <div class="tw-flex tw-items-center">
                <app-headline-with-icon color="green" image="24_building.svg" text="Gebäude"></app-headline-with-icon>
                <app-text-with-tooltip
                    class="tw-inline-block"
                    [label]="''"
                    [labelClass]="''"
                    [tooltipKey]="tooltipKey"
                    [tooltipInnerHtml]="'PAGES.TOOLTIPS.PROPERTY_BASE_DATA.BUILDING' | translate"
                ></app-text-with-tooltip>
            </div>

            <app-scalara-button
                *ngIf="isPropertyManager"
                variant="ghost"
                svgName="24_add.svg"
                (click)="openEditBuildingOverlay()"
            >
                {{ 'CONTROLS.BUTTONS.ADD' | translate }}
            </app-scalara-button>
        </div>

        <div class="d-flex building">
            <div *ngFor="let building of buildings">
                <ng-container *ngTemplateOutlet="buildingComponent; context: { building: building }"> </ng-container>
            </div>
        </div>

        <ng-template #buildingComponent let-building="building">
            <div
                (click)="navigateToBuildingDetails(building.id)"
                class="building_card tile tile--box-selection tw-flex tw-justify-between tw-text-left"
                [ngClass]="{ 'tile--not-clickable tile--static': !isPropertyManager }"
            >
                <div class="tw-p-8">
                    <div class="mb-1r s-head-14-22-bold">{{ building.name }}</div>
                    <div class="mb-0-5r s-body-14-22-regular">
                        {{ building.address?.streetName }} {{ building.address?.streetNumber }}
                    </div>
                    <div class="mb-0-5r s-body-14-22-regular">{{ building.address?.streetAddition }}</div>
                    <div>{{ building.address?.zipCode }} {{ building.address?.area }}</div>
                </div>

                <div class="tw-p-4">
                    <div class="tw-w-6 tw-h-6 tw-fill-scalaraGray-02">
                        <svg-icon src="/assets/icons/24_arrow-right.svg"></svg-icon>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>

    <div class="tw-mb-16" *ngIf="property?.propertyType !== 'MV'">
        <app-shared-ownership-index
            [tableModel]="sharedOwnershipTable"
            [sharedOwnershipCallbacks]="sharedOwnershipCallbacks"
            [isPropertyManager]="isPropertyManager"
        ></app-shared-ownership-index>
    </div>

    <div *ngIf="property?.propertyType === 'MV'">
        <app-owners [isMv]="true" [isEditable]="isPropertyManager"> </app-owners>
    </div>

    <div class="tw-my-16">
        <app-additional-information-input
            [additionalInformation]="property?.additionalInformation"
            (updateAdditionalInformation)="updateAdditionalInformation($event)"
        ></app-additional-information-input>
    </div>

    <app-document-table
        *ngIf="isPropertyManager ? true : !isTenant && !isServiceProvider"
        [showButton]="isPropertyManager"
        [tooltipInfo]="tooltipInfo"
        [inputData]="{ flow: DocumentsFlow.property, fileReferenceEnum: 'BASIC_DATA' }"
    ></app-document-table>
</ng-container>

<ng-template #notSpecified>
    <div class="s-body-14-22-italic">
        {{ 'PAGES.OWNERSHIP.DETAIL_VIEW.LABEL_NOT_SPECIFIED' | translate }}
    </div>
</ng-template>
