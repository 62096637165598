<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>
<app-delete-overlay
    title="PAGES.ECONOMIC_PLAN.DETAILS.RESET_PLAN_OVERLAY_TITLE"
    headline="PAGES.ECONOMIC_PLAN.DETAILS.RESERT_PLAN_OVERLAY_HEADLINE"
    subtitle="PAGES.ECONOMIC_PLAN.DETAILS.RESERT_PLAN_OVERLAY_SUBTITLE"
    (closeOverlay)="abort()"
    (submitOverlay)="submit()"
    submitButtonLabel="PAGES.ECONOMIC_PLAN.DETAILS.RESET_PLAN_OVERLAY_SUBMIT_BUTTON"
    headlineIcon="40_error.svg"
    containerSize="small"
>
</app-delete-overlay>
