<app-delete-overlay
    title="PAGES.TICKET_DETAILS.TITLE_DELETE_SERVICE_PROVIDER"
    headline="PAGES.TICKET_DETAILS.HEADLINE_DELETE_SERVICE_PROVIDER"
    subtitle="PAGES.TICKET_DETAILS.TEXT_DELETE_SERVICE_PROVIDER"
    (closeOverlay)="abort()"
    (submitOverlay)="onSubmit()"
>
    <app-headline-with-icon
        [color]="personToDelete?.hasRegisteredUser ? 'green' : 'gray'"
        image="24_person.svg"
        [text]="personToDelete ? getNameFromPerson(personToDelete) : ''"
        textType="body"
        textWeight="regular"
        borderColor="gray"
    ></app-headline-with-icon>
</app-delete-overlay>
