import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { EconomicPlansService } from 'src/app/generated-sources/accounting';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';

@Component({
    selector: 'app-weg-economic-plan-reset-overlay',
    templateUrl: './weg-economic-plan-reset-overlay.component.html',
    styleUrls: ['./weg-economic-plan-reset-overlay.component.scss'],
})
export class WegEconomicPlanResetOverlayComponent extends OverlayChildComponent implements OnInit, OnDestroy {
    private economicPlanId = '';
    private ledgerId = '';
    public isLoading = false;
    private unsubscribe$ = new Subject<void>();

    public constructor(
        private economicPlansService: EconomicPlansService,
        private toastService: ToastService,
        private translateService: TranslateService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.economicPlanId = this.config?.data.economicPlanId;
        this.ledgerId = this.config?.data.ledgerId;
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public submit(): void {
        this.isLoading = true;
        this.economicPlansService
            .resetEconomicPlan(this.ledgerId, this.economicPlanId)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: () => {
                    this.isLoading = false;
                    this.toastService.showSuccess(
                        this.translateService.instant('PAGES.ECONOMIC_PLAN.DETAILS.RESET_PLAN_OVERLAY_SUCCESS_TOAST')
                    );
                    this.saveEmitter$.next();
                },
                error: (error) => {
                    this.isLoading = false;
                    if (error) {
                        this.toastService.showError(error.error['message']);
                    }
                },
            });
    }
}
