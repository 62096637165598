import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AccountingReportsService } from './api/accountingReports.service';
import { AccountsService } from './api/accounts.service';
import { BankAccountsService } from './api/bankAccounts.service';
import { BankConnectionsService } from './api/bankConnections.service';
import { BankTransactionsService } from './api/bankTransactions.service';
import { BookingRecommendationsService } from './api/bookingRecommendations.service';
import { BookingRulesService } from './api/bookingRules.service';
import { BookingsService } from './api/bookings.service';
import { DashboardsService } from './api/dashboards.service';
import { DefaultService } from './api/default.service';
import { EconomicPlansService } from './api/economicPlans.service';
import { EconomicsService } from './api/economics.service';
import { GuidedBookingsService } from './api/guidedBookings.service';
import { LedgersService } from './api/ledgers.service';
import { MaintenanceService } from './api/maintenance.service';
import { OpenItemsService } from './api/openItems.service';
import { OperationsExpenseStatementsService } from './api/operationsExpenseStatements.service';
import { PaymentsService } from './api/payments.service';
import { PdfConfigurationsService } from './api/pdfConfigurations.service';
import { ReceiptsService } from './api/receipts.service';
import { StatisticsService } from './api/statistics.service';
import { SupportDashboardService } from './api/supportDashboard.service';
import { WegSettlementStatementsService } from './api/wegSettlementStatements.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class AccountingApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<AccountingApiModule> {
        return {
            ngModule: AccountingApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: AccountingApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('AccountingApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
