<div *ngIf="bankAccounts?.length">
    <div class="tw-flex tw-mt-10 tw-mb-6 tw-w-full tw-justify-between">
        <div class="tw-max-w-[888px]">
            <div class="tw-flex tw-items-center tw-space-x-3">
                <div
                    class="tw-h-10 tw-w-10 tw-rounded-full tw-bg-scalaraGreen-03 tw-flex tw-justify-center tw-items-center"
                >
                    <div class="tw-s-head-16-24-bold tw-text-scalaraGreen-01">1.</div>
                </div>

                <div class="tw-s-head-14-22-bold">
                    {{ 'PAGES.BANK_ACCOUNT_DEBIT_CONFIGURATION.STEP_1' | translate }}
                </div>
            </div>
        </div>
        <div class="tw-items-end tw-justify-end">
            <app-scalara-button
                (click)="save()"
                [disabled]="!isCreditorChanged && !isDebitBankAccountChanged && !isDebitConfigChanged"
                [isLoading]="isSubmitLoading"
                variant="default"
                class="submit"
                height="40px"
            >
                {{ 'CONTROLS.BUTTONS.SAVE' | translate }}
            </app-scalara-button>
        </div>
    </div>
    <form [formGroup]="creditorForm" class="tw-mt-8 tw-flex tw-justify-start">
        <div class="tw-mb-6 tw-mr-5">
            <ibm-label [invalid]="isInvalidCreditorForm('creditorName')"
                >Gläubiger-Name*
                <input
                    class="tw-w-[400px]"
                    ibmText
                    [invalid]="isInvalidCreditorForm('creditorName')"
                    name="creditorName"
                    id="creditorName"
                    formControlName="creditorName"
                    [ngModel]="ledger?.creditorDescription"
                    (blur)="updateCreditorNameOnBlur()"
                />
            </ibm-label>
        </div>
        <div class="tw-mb-6">
            <ibm-label [invalid]="isInvalidCreditorForm('creditorId')"
                >Gläubiger Identifikationsnummer*
                <input
                    class="tw-w-[400px]"
                    [invalid]="isInvalidCreditorForm('creditorId')"
                    ibmText
                    name="creditorId"
                    id="creditorId"
                    formControlName="creditorId"
                    [ngModel]="ledger?.creditorIdentifier"
                    (blur)="updateCreditorIdOnBlur()"
                />
            </ibm-label>
        </div>
    </form>

    <div class="tw-flex tw-mt-10 tw-mb-6 tw-w-full tw-justify-between">
        <div class="tw-max-w-[888px]">
            <div class="tw-flex tw-items-center tw-space-x-3">
                <div
                    class="tw-h-10 tw-w-10 tw-rounded-full tw-bg-scalaraGreen-03 tw-flex tw-justify-center tw-items-center"
                >
                    <div class="tw-s-head-16-24-bold tw-text-scalaraGreen-01">2.</div>
                </div>

                <div class="tw-s-head-14-22-bold">
                    {{ 'PAGES.BANK_ACCOUNT_DEBIT_CONFIGURATION.STEP_2' | translate }}
                </div>
            </div>
        </div>
    </div>

    <table class="tw-w-full tw-mb-10" *ngIf="debitConfig">
        <tr
            class="s-label-12-22-semibold tw-px-2 tw-h-8 tw-pb-4 tw-border-b tw-border-scalaraGray-05 tw-border-solid tw-text-left"
        >
            <ng-container *ngFor="let coloumn of debitBankAccountsHeader; last as coloumnLast">
                <th [ngClass]="{ 'tw-flex tw-justify-center tw-items-end tw-w-[130px]': coloumnLast }">
                    {{ coloumn | translate }}
                </th>
            </ng-container>
        </tr>
        <ng-container *ngFor="let bankAccount of bankAccounts; last as bankAccountLast">
            <tr
                class="s-label-14-22-regular tw-text-left"
                [ngClass]="{
                    'tw-border-b tw-border-scalaraGray-04 tw-border-solid': !bankAccountLast
                }"
            >
                <td>
                    {{ getBankAccountTypeName(bankAccount) }}
                </td>
                <td>
                    {{ bankAccount.bankName ? bankAccount.bankName : '' }}
                </td>
                <td>
                    {{ bankAccount.iban }}
                </td>
                <td>
                    {{ bankAccount.holderName }}
                </td>
                <td class="tw-w-[130px]">
                    <div class="tw-flex tw-justify-center tw-items-end tw-py-4">
                        <ibm-checkbox
                            [checked]="bankAccount.id === ledger?.directDebitBankAccountId"
                            (change)="onDebitAccountCheckboxChange($event, bankAccount)"
                        >
                        </ibm-checkbox>
                    </div>
                </td>
            </tr>
        </ng-container>
    </table>

    <div class="tw-flex tw-mt-10 tw-mb-6 tw-w-full tw-justify-between">
        <div class="tw-max-w-[888px]">
            <div class="tw-flex tw-items-center tw-space-x-3">
                <div
                    class="tw-h-10 tw-w-10 tw-rounded-full tw-bg-scalaraGreen-03 tw-flex tw-justify-center tw-items-center"
                >
                    <div class="tw-s-head-16-24-bold tw-text-scalaraGreen-01">3.</div>
                </div>

                <div class="tw-s-head-14-22-bold">
                    {{ 'PAGES.BANK_ACCOUNT_DEBIT_CONFIGURATION.STEP_3' | translate }}
                </div>
            </div>
        </div>
    </div>

    <table class="tw-w-full tw-mb-10" *ngIf="debitConfig">
        <tr
            class="s-label-12-22-semibold tw-px-2 tw-h-8 tw-pb-4 tw-border-b tw-border-scalaraGray-05 tw-border-solid tw-text-left"
        >
            <ng-container *ngFor="let coloumn of debitConfigHeader; last as coloumnLast">
                <th [ngClass]="{ 'tw-flex tw-justify-center tw-items-end  tw-w-[130px]': coloumnLast }">
                    {{ coloumn | translate }}
                </th>
            </ng-container>
        </tr>

        <ng-container *ngFor="let unit of debitConfig; first as unitFirst; last as unitLast">
            <ng-container *ngFor="let person of unit.persons; first as personFirst; last as personLast">
                <ng-container
                    *ngFor="let bankAccount of person.bankAccounts; first as bankAccountsFirst; last as bankAccountLast"
                >
                    <tr
                        class="s-label-14-22-regular tw-text-left"
                        [ngClass]="{
                            'tw-border-b tw-border-scalaraGray-04 tw-border-solid': bankAccountLast && personLast
                        }"
                    >
                        <td class="s-body-14-22-bold">
                            {{
                                (unitFirst && personFirst && bankAccountsFirst) ||
                                (!unitFirst && personFirst && bankAccountsFirst)
                                    ? unit.name
                                    : ''
                            }}
                        </td>
                        <td
                            [ngClass]="{
                                'tw-border-b tw-border-scalaraGray-05 tw-border-solid': bankAccountLast && !personLast
                            }"
                        >
                            {{
                                (bankAccountsFirst && personFirst) || (bankAccountsFirst && !personFirst)
                                    ? person.fullName
                                    : ''
                            }}
                        </td>
                        <td
                            [ngClass]="{
                                'tw-border-b tw-border-scalaraGray-05 tw-border-solid':
                                    true && !(bankAccountLast && personLast)
                            }"
                        >
                            {{ bankAccount.iban + ' ' + bankAccount.bankName }}
                        </td>
                        <td
                            class="tw-w-[130px]"
                            [ngClass]="{
                                'tw-border-b tw-border-scalaraGray-05 tw-border-solid':
                                    true && !(bankAccountLast && personLast)
                            }"
                        >
                            <div class="tw-flex tw-justify-center tw-items-end tw-py-4">
                                <ibm-checkbox
                                    [checked]="
                                        unit.selectedBankAccountId === bankAccount.id ||
                                        (unit.selectedPersonId === person.id && !unit.selectedBankAccountId)
                                    "
                                    (change)="onDebitConfigCheckboxChange($event, unit, person, bankAccount)"
                                >
                                </ibm-checkbox>
                            </div>
                        </td></tr
                ></ng-container>
                <tr
                    *ngIf="person.bankAccounts.length === 0"
                    class="s-label-14-22-regular tw-text-left"
                    [ngClass]="{
                        'tw-border-b tw-border-scalaraGray-04 tw-border-solid': personLast
                    }"
                >
                    <td class="s-body-14-22-bold">
                        {{ (unitFirst && personFirst) || (!unitFirst && personFirst) ? unit.name : '' }}
                    </td>
                    <td
                        [ngClass]="{
                            'tw-border-b tw-border-scalaraGray-05 tw-border-solid': !personLast
                        }"
                    >
                        {{ personFirst || person.bankAccounts.length === 0 ? person.fullName : '' }}
                    </td>
                    <td
                        [ngClass]="{
                            'tw-border-b tw-border-scalaraGray-05 tw-border-solid': true && !personLast
                        }"
                    >
                        <app-tooltip position="bottom">
                            <div content class="tw-p-6 tw-w-[360px]">
                                {{ 'PAGES.BANK_ACCOUNT_DEBIT_CONFIGURATION.INCOMPLETE_TOOLTIP' | translate }}
                            </div>
                            <div hoverable-item class="d-flex">
                                <app-status-with-icon
                                    textColor="s-red-01"
                                    label="Unvollständig"
                                    iconSrc="/assets/icons/24_attention.svg"
                                ></app-status-with-icon>
                            </div>
                        </app-tooltip>
                    </td>
                    <td
                        class="tw-w-[130px]"
                        [ngClass]="{
                            'tw-border-b tw-border-scalaraGray-05 tw-border-solid': true && !personLast
                        }"
                    >
                        <div class="tw-flex tw-justify-center tw-items-end tw-py-4">
                            <ibm-checkbox
                                [checked]="unit.selectedPersonId === person.id && !unit.selectedBankAccountId"
                                (change)="onDebitConfigCheckboxChange($event, unit, person, undefined)"
                            >
                            </ibm-checkbox>
                        </div>
                    </td>
                </tr>
            </ng-container>
        </ng-container>
    </table>
    <div class="tw-flex tw-w-full tw-items-end tw-justify-end tw-mt-6">
        <app-scalara-button
            (click)="save()"
            [disabled]="!isDebitConfigChanged && !isCreditorChanged && !isDebitBankAccountChanged"
            [isLoading]="isSubmitLoading"
            variant="default"
            class="submit"
            height="40px"
        >
            {{ 'CONTROLS.BUTTONS.SAVE' | translate }}
        </app-scalara-button>
    </div>
</div>
