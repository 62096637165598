import { formatNumber } from '@angular/common';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, combineLatest, switchMap, takeUntil, tap } from 'rxjs';
import { PersonLocalService } from 'src/app/features/property/services/person-local.service';
import { WegSettlementStatementsService, WssDistributionKeyDto, WssDto } from 'src/app/generated-sources/accounting';
import { Person, Property } from 'src/app/generated-sources/base';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { CellTemplate } from 'src/app/shared/table/enums/cell-template';
import { HeaderTemplate } from 'src/app/shared/table/enums/header-template';
import { TableItem } from 'src/app/shared/table/interfaces/table-item';
import { TableModel } from 'src/app/shared/table/interfaces/table-model';
import { AddAnnualStatementDistributionKeyComponent } from '../add-annual-statement-distribution-key/add-annual-statement-distribution-key.component';

@Component({
    selector: 'app-annual-statement-distribution-keys-tab',
    templateUrl: './annual-statement-distribution-keys-tab.component.html',
    styleUrls: ['./annual-statement-distribution-keys-tab.component.scss'],
})
export class AnnualStatementDistributionKeysTabComponent implements OnInit {
    @Input() public editMode = false;
    @Input() public wss?: WssDto;

    private unsubscribe$ = new Subject<void>();

    public ledgerId = '';
    public wssId = '';
    public isLoading = false;
    public isPropertyManager = false;
    public standardDistKeysDescriptions = [
        'FRACTION',
        'ENTITY_PLURAL',
        'PERSONS',
        'SQUARE_METERS',
        'HEATED_SQUARE_METERS',
    ];

    public standardDistKeys: WssDistributionKeyDto[] = [];
    public individualDistKeys: WssDistributionKeyDto[] = [];

    public standardDistKeysTableModel: TableModel = { data: [], header: [] };
    public individualDistKeysTableModel: TableModel = { data: [], header: [] };

    public showMissingDataWarning = false;

    @ViewChild('warningNotificationTemplate', { static: true })
    public warningNotificationTemplate?: TemplateRef<any>;

    public constructor(
        private translateService: TranslateService,
        private overlayService: OverlayService,
        private route: ActivatedRoute,
        private annualStatementsService: WegSettlementStatementsService,
        private personLocalService: PersonLocalService
    ) {}

    public ngOnInit(): void {
        this.initTableHeaders();

        this.isLoading = true;
        combineLatest([this.route.parent!.paramMap, this.route.paramMap])
            .pipe(
                tap((params: ParamMap[]) => {
                    this.ledgerId = String(params[0].get('id'));
                    this.wssId = String(params[1].get('annualStatementId'));
                }),
                switchMap(() => this.personLocalService.getRolesOfProperty()),
                tap((roles: Property.PermissionRolesEnum[] | null) => {
                    this.isPropertyManager = roles
                        ? Object.values(roles).includes(Person.PermissionRolesEnum.PropertyManager)
                        : false;
                }),
                switchMap(() =>
                    this.annualStatementsService.getDistributionKeys(this.ledgerId, this.wssId, '', 'CONSUMPTION')
                ),
                tap((distributionKeys: WssDistributionKeyDto[]) => {
                    this.standardDistKeys = [];
                    this.individualDistKeys = [];
                    distributionKeys.map((distributionKey: WssDistributionKeyDto) => {
                        if (distributionKey.distributionBase !== 'CONSUMPTION') {
                            if (distributionKey.distributionGroup == 'ALL_OWNERSHIPS') {
                                this.standardDistKeys.push(distributionKey);
                            } else {
                                this.individualDistKeys.push(distributionKey);
                            }
                        }
                    });
                    this.standardDistKeysTableModel.data = this.standardDistKeys.map(
                        (standardDistKey: WssDistributionKeyDto, index: number) => {
                            let allNull = true;
                            let hasNull = false;

                            standardDistKey.ownerships.map((ownership: any) => {
                                if (ownership.distributionBaseShare !== null) {
                                    allNull = false;
                                } else {
                                    hasNull = true;
                                }
                            });

                            if (allNull) {
                                return this.createRow(standardDistKey, index, true, 'missingData');
                            } else if (hasNull) {
                                return this.createRow(standardDistKey, index, true, 'incompleteData');
                            }

                            return this.createRow(standardDistKey, index, true);
                        }
                    );
                    this.individualDistKeysTableModel.data = this.individualDistKeys.map(
                        (individualDistKey: WssDistributionKeyDto) => this.createRow(individualDistKey)
                    );
                    this.isLoading = false;
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }

    public openAddIndividualDistributionKeyOverlay(): void {
        const data = {
            ledgerId: this.ledgerId,
            wssId: this.wssId,
            distributionKeys: this.standardDistKeys,
        };
        const ref = this.overlayService.open(AddAnnualStatementDistributionKeyComponent, {
            data,
        });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

        ref.saveEmitter$
            .pipe(
                switchMap(() =>
                    this.annualStatementsService.getDistributionKeys(this.ledgerId, this.wssId, '', 'CONSUMPTION')
                ),
                tap((distributionKeys: WssDistributionKeyDto[]) => {
                    this.standardDistKeys = [];
                    this.individualDistKeys = [];
                    this.standardDistKeysTableModel.data = [];
                    this.individualDistKeysTableModel.data = [];
                    distributionKeys.map((distributionKey: WssDistributionKeyDto) => {
                        if (distributionKey.distributionBase !== 'CONSUMPTION') {
                            if (distributionKey.distributionGroup == 'ALL_OWNERSHIPS') {
                                this.standardDistKeys.push(distributionKey);
                            } else {
                                this.individualDistKeys.push(distributionKey);
                            }
                        }
                    });
                    this.standardDistKeysTableModel.data = this.standardDistKeys.map(
                        (standardDistKey: WssDistributionKeyDto, index: number) =>
                            this.createRow(standardDistKey, index)
                    );
                    this.individualDistKeysTableModel.data = this.individualDistKeys.map(
                        (individualDistKey: WssDistributionKeyDto) => this.createRow(individualDistKey)
                    );
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }

    private initTableHeaders(): void {
        this.standardDistKeysTableModel.header = [
            'ENTITIES.DISTRIBUTION_KEY.LABEL_DESCRIPTION',
            'ENTITIES.DISTRIBUTION_KEY.LABEL_DISTRIBUTION_BASE',
            'ENTITIES.DISTRIBUTION_KEY.LABEL_DISTRIBUTION_GROUP',
            '',
            {
                data: { key: 'ENTITIES.DISTRIBUTION_KEY.LABEL_TOTAL_DISTRIBUTION', params: {} },
                template: HeaderTemplate.RightAligned,
            },
        ];
    }

    private createRow(
        distKey: WssDistributionKeyDto,
        index?: number,
        isStandardDistKeysTableModel?: boolean,
        infoMessage?: string
    ): TableItem[] {
        const link = `/accounting/ledger/${this.ledgerId}/annual-statements/${this.wssId}/distribution-keys/${distKey.id}`;

        if (infoMessage === 'missingData') {
            this.showMissingDataWarning = true;
        }

        let labelDistributionBase = '';
        if (distKey.distributionBase == 'OWNERSHIPS') {
            labelDistributionBase = this.translateService.instant('ENTITIES.OWNERSHIP.LABEL_ENTITY_PLURAL');
        } else {
            labelDistributionBase = this.translateService.instant(
                'ENTITIES.OWNERSHIP.LABEL_' + distKey.distributionBase
            );
        }

        let labelDescription = '';
        if (index !== undefined) {
            labelDescription = this.translateService.instant(
                'ENTITIES.OWNERSHIP.LABEL_' + this.standardDistKeysDescriptions[index]
            );
        } else {
            labelDescription = distKey.description;
        }

        return [
            {
                data: {
                    label: labelDescription,
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: labelDistributionBase,
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: this.translateService.instant('ENTITIES.DISTRIBUTION_KEY.' + distKey.distributionGroup),
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: infoMessage ? infoMessage : '',
                    link,
                },
                template: infoMessage ? this.warningNotificationTemplate : CellTemplate.Default,
            },
            {
                data: {
                    label: formatNumber(distKey.totalDistribution, 'de-DE', '1.2'),
                    link,
                    rightAligned: true,
                },
                template: CellTemplate.Default,
            },
        ];
    }
}
