import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TableModel } from 'src/app/shared/table/interfaces/table-model';

@Component({
    selector: 'app-contact-roles-overview',
    templateUrl: './contact-roles-overview.component.html',
    styleUrls: ['./contact-roles-overview.component.scss'],
})
export class ContactRolesOverviewComponent implements OnInit {
    @Input() public tableModel: TableModel = { data: [], header: [] };

    public constructor(private route: ActivatedRoute) {}

    public ngOnInit(): void {}
}
