import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, Observable } from 'rxjs';
import { WssDto } from 'src/app/generated-sources/accounting';

@Injectable({
    providedIn: 'root',
})
export class CustomWssService {
    // constructor() { }

    private ledgerId$ = new BehaviorSubject('');
    private wss$ = new BehaviorSubject<WssDto | null>(null);

    public setLedgerId(ledgerId: string): void {
        this.ledgerId$.next(ledgerId);
    }

    public getLedgerId$(): Observable<string> {
        return this.ledgerId$.asObservable().pipe(distinctUntilChanged());
    }

    public setWss(wss: WssDto): void {
        this.wss$.next(wss);
    }

    public getWss$(): Observable<WssDto | null> {
        return this.wss$.asObservable().pipe(distinctUntilChanged());
    }
}
