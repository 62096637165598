import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, ObservedValueOf, Subject, combineLatest, map, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { FiletoView } from '../file-viewer/file-viewer.component';

export type BaseDocumentsListItem = { fileName: string; getLink: () => Promise<{ url: string; fileName?: string }> };

@Component({
    selector: 'app-base-documents-list',
    templateUrl: './base-documents-list.component.html',
    styleUrls: ['./base-documents-list.component.scss'],
})
export class BaseDocumentsListComponent implements OnInit, OnDestroy {
    public constructor(
        private cd: ChangeDetectorRef,
        private toastService: ToastService,
        private translate: TranslateService
    ) {}

    @Input() public items: BaseDocumentsListItem[] = [];
    @Input() public title?: string;

    public unsubscribe$ = new Subject<void>();

    public ngOnInit(): void {
        this.vm$.pipe(takeUntil(this.unsubscribe$)).subscribe((vm) => {
            this.vm = vm;
            this.cd.detectChanges();
        });
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public async onActionClick({
        item,
        flow,
    }: {
        item: BaseDocumentsListItem;
        flow: 'download' | 'view';
    }): Promise<void> {
        this.isLoading$.next(true);
        try {
            const resp = await item.getLink();

            if (flow === 'view') {
                this.fileViewerData$.next({
                    showModal: true,
                    fileToView: { fileName: resp.fileName ? resp.fileName : item.fileName, file: resp.url },
                });

                return;
            }

            const linkDomElement = document.createElement('a');
            linkDomElement.href = resp.url;
            linkDomElement.download = resp.fileName ? resp.fileName : item.fileName;
            linkDomElement.click();
        } catch (e) {
            console.warn(e);
            this.toastService.showError(this.translate.instant('PAGES.RESEND_EMAIL.TOAST_ERROR'));
        } finally {
            this.isLoading$.next(false);
        }
    }

    public closeFileViewerModal(): void {
        this.fileViewerData$.next({ fileToView: null, showModal: false });
    }

    public fileViewerData$ = new BehaviorSubject<{ fileToView: FiletoView | null; showModal: boolean }>({
        fileToView: null,
        showModal: false,
    });

    public isLoading$ = new BehaviorSubject(false);

    public vm$ = combineLatest([this.fileViewerData$, this.isLoading$]).pipe(
        map(([fileViewerData, isLoading]) => {
            return {
                fileViewerData,
                isLoading,
            };
        })
    );
    public vm: null | ObservedValueOf<typeof this.vm$> = null;
}
