import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SettingsModule } from './account-settings/settings.module';
import { AccountingModule } from './accounting/accounting.module';
import { AnnualStatementModule } from './annual-statement/annual-statement.module';
import { AuthModule } from './auth/auth.module';
import { CommunicationModule } from './communication/communication.module';
import { ContactsModule } from './contacts/contacts.module';
import { ListGeneratorModule } from './list-generator/list-generator.module';
import { OperationStatisticsModule } from './operations/statistics/operation-statistics.module';
import { PropertyModule } from './property/property.module';
import { RelationsModule } from './relations/relations.module';
import { SubscriptionsModule } from './subscriptions/subscriptions.module';
import { SurrogateModule } from './surrogate/surrogate.module';
import { UserManagementModule } from './user-management/user-management.module';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        AuthModule,
        UserManagementModule,
        PropertyModule,
        AccountingModule,
        CommunicationModule,
        ContactsModule,
        SurrogateModule,
        RelationsModule,
        OperationStatisticsModule,
        SubscriptionsModule,
        AnnualStatementModule,
        ListGeneratorModule,
        SettingsModule,
    ],
    providers: [],
})
export class FeaturesModule {}
