<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>
<div *ngIf="!isLoading" [ngClass]="{ 'tw-px-10': !ledgerId, 'tw-my-6': !ledgerId }">
    <div class="tw-mt-6 tw-mb-10">
        <ibm-breadcrumb [items]="breadcrumbs" [noTrailingSlash]="true"> </ibm-breadcrumb>
    </div>

    <div *ngIf="!ledgerId" class="tw-mb-8 d-flex align-items-center">
        <span class="s-head-28-36-bold tw-mr-3">{{ openPayments }}</span>
        <span class="s-body-14-22-regular">
            {{ 'PAGES.PAYMENT_APPROVALS.LABEL_WAITING_PAYMENTS' | translate }}
        </span>
    </div>
    <div>
        <div class="s-label-12-22-semibold tw-mb-4">
            {{
                (ledgerId
                    ? 'PAGES.PAYMENT_APPROVALS.LABEL_LEDGER_BANK_ACCOUNTS_LIST'
                    : 'PAGES.PAYMENT_APPROVALS.LABEL_OVERVIEW_BANK_ACCOUNTS_LIST'
                ) | translate
            }}
        </div>
        <div
            *ngFor="let bankConnection of bankConnections"
            class="tile tile--shadow d-flex align-items-center tw-p-4 tw-mb-4"
            (click)="navigateToPaymentApprovalSelection(bankConnection.groupedUniqueBankAccount)"
        >
            <div class="tw-flex tw-items-center tw-w-[50%]">
                <div class="tw-bg-scalaraGray-07 tw-mr-6 tw-w-12 tw-h-12 tw-flex tw-items-center tw-justify-center">
                    <img
                        *ngIf="bankConnection.groupedUniqueBankAccount.bankLogoUrl"
                        [src]="bankConnection.groupedUniqueBankAccount.bankLogoUrl"
                        alt=""
                        class="tw-max-w-full tw-max-h-full tw-p-2"
                    />
                    <svg-icon
                        *ngIf="!bankConnection.groupedUniqueBankAccount.bankLogoUrl"
                        class="tw-fill-scalaraGray-04 tw-h-6 tw-w-6"
                        src="/assets/icons/40_bank.svg"
                    ></svg-icon>
                </div>
                <div class="s-head-14-22-bold tw-mr-4">{{ bankConnection.groupedUniqueBankAccount.bankName }}</div>
                <div class="s-head-14-22-bold">{{ bankConnection.groupedUniqueBankAccount.bic }}</div>
            </div>
            <div class="tw-ml-36">
                <span class="s-head-14-22-bold tw-mr-2">{{ bankConnection.openPayments }}</span>
                <span class="s-body-14-22-regular">
                    {{ 'ENTITIES.PAYMENT.LABEL_TRANSACTION' | translate }}
                </span>
            </div>
            <div class="tile__icon rotate-270">
                <svg-icon src="/assets/icons/24_arrow-down.svg"></svg-icon>
            </div>
        </div>
    </div>
</div>
