<ibm-loading *ngIf="this.vm?.isLoading" [isActive]="true" [size]="'normal'" [overlay]="true"></ibm-loading>

<app-delete-overlay
    headline="{{ vm?.heading }}"
    title="{{ vm?.title }}"
    subtitle="{{ vm?.subtitle }}"
    [translateInputs]="false"
    (closeOverlay)="abort()"
    (submitOverlay)="submit()"
    [disableButton]="vm?.formIsValid === false"
>
    <form [formGroup]="form">
        <div class="tw-space-y-1">
            <ibm-label
                [invalid]="controlInFormGroupIsInvalid(this.form, 'entityName')"
                invalidText="{{ 'ERROR.GENERAL' | translate }}: '{{ this.vm?.entityName }}'"
                >{{ vm?.entityTypeTranslated }}*
                <input
                    ibmText
                    type="text"
                    name="entityName"
                    id="entityName"
                    formControlName="entityName"
                    [invalid]="controlInFormGroupIsInvalid(this.form, 'entityName')"
                />
            </ibm-label>
        </div>
    </form>
</app-delete-overlay>
