/**
 * SCALARA Base Module
 * The SCALARA API for User, Person and Property resources
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreatePropertyFileDto { 
    fileStorageId: string;
    fileReference: CreatePropertyFileDto.FileReferenceEnum;
    fileCategory: CreatePropertyFileDto.FileCategoryEnum;
}
export namespace CreatePropertyFileDto {
    export type FileReferenceEnum = 'BASIC_DATA' | 'OWNERSHIPS';
    export const FileReferenceEnum = {
        BasicData: 'BASIC_DATA' as FileReferenceEnum,
        Ownerships: 'OWNERSHIPS' as FileReferenceEnum
    };
    export type FileCategoryEnum = 'PARTITION_PLAN' | 'ENERGY_PASS' | 'CADASTRAL_MAP' | 'PHOTO' | 'LAND_REGISTER_RECORD' | 'GROUND_PLAN' | 'PLAN' | 'DECLARATION_OF_DIVISION' | 'INSURANCE_POLICY' | 'CONTRACT' | 'ADMINISTRATOR_APPOINTMENT' | 'MAINTENANCE_AGREEMENT' | 'PERSONAL_ID' | 'ACCEPTANCE_PROTOCOL' | 'RESOLUTION_COLLECTION' | 'GUARANTEE' | 'PRIVACY_POLICY' | 'SERVICE_CONTRACT' | 'QUESTIONNAIRE' | 'COMMUNITY_RULES' | 'EXPERT_REPORT' | 'HOUSE_RULES' | 'RENTAL_AGREEMENT' | 'PROTOCOL' | 'SEPA_MANDATE' | 'SUPPLY_CONTRACT' | 'POWER_OF_ATTORNEY' | 'LIVING_SPACE_CALCULATION' | 'HANDOVER_PROTOCOL' | 'SOLVENCY_PROOF' | 'TENANT_SELF_DISCLOSURE' | 'SALARY_CERTIFICATE' | 'TECHNICAL_EQUIPMENT' | 'OTHER';
    export const FileCategoryEnum = {
        PartitionPlan: 'PARTITION_PLAN' as FileCategoryEnum,
        EnergyPass: 'ENERGY_PASS' as FileCategoryEnum,
        CadastralMap: 'CADASTRAL_MAP' as FileCategoryEnum,
        Photo: 'PHOTO' as FileCategoryEnum,
        LandRegisterRecord: 'LAND_REGISTER_RECORD' as FileCategoryEnum,
        GroundPlan: 'GROUND_PLAN' as FileCategoryEnum,
        Plan: 'PLAN' as FileCategoryEnum,
        DeclarationOfDivision: 'DECLARATION_OF_DIVISION' as FileCategoryEnum,
        InsurancePolicy: 'INSURANCE_POLICY' as FileCategoryEnum,
        Contract: 'CONTRACT' as FileCategoryEnum,
        AdministratorAppointment: 'ADMINISTRATOR_APPOINTMENT' as FileCategoryEnum,
        MaintenanceAgreement: 'MAINTENANCE_AGREEMENT' as FileCategoryEnum,
        PersonalId: 'PERSONAL_ID' as FileCategoryEnum,
        AcceptanceProtocol: 'ACCEPTANCE_PROTOCOL' as FileCategoryEnum,
        ResolutionCollection: 'RESOLUTION_COLLECTION' as FileCategoryEnum,
        Guarantee: 'GUARANTEE' as FileCategoryEnum,
        PrivacyPolicy: 'PRIVACY_POLICY' as FileCategoryEnum,
        ServiceContract: 'SERVICE_CONTRACT' as FileCategoryEnum,
        Questionnaire: 'QUESTIONNAIRE' as FileCategoryEnum,
        CommunityRules: 'COMMUNITY_RULES' as FileCategoryEnum,
        ExpertReport: 'EXPERT_REPORT' as FileCategoryEnum,
        HouseRules: 'HOUSE_RULES' as FileCategoryEnum,
        RentalAgreement: 'RENTAL_AGREEMENT' as FileCategoryEnum,
        Protocol: 'PROTOCOL' as FileCategoryEnum,
        SepaMandate: 'SEPA_MANDATE' as FileCategoryEnum,
        SupplyContract: 'SUPPLY_CONTRACT' as FileCategoryEnum,
        PowerOfAttorney: 'POWER_OF_ATTORNEY' as FileCategoryEnum,
        LivingSpaceCalculation: 'LIVING_SPACE_CALCULATION' as FileCategoryEnum,
        HandoverProtocol: 'HANDOVER_PROTOCOL' as FileCategoryEnum,
        SolvencyProof: 'SOLVENCY_PROOF' as FileCategoryEnum,
        TenantSelfDisclosure: 'TENANT_SELF_DISCLOSURE' as FileCategoryEnum,
        SalaryCertificate: 'SALARY_CERTIFICATE' as FileCategoryEnum,
        TechnicalEquipment: 'TECHNICAL_EQUIPMENT' as FileCategoryEnum,
        Other: 'OTHER' as FileCategoryEnum
    };
}


