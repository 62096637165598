<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>
<div ibmRow class="mb-4r add-distribution-key">
    <div ibmCol></div>
    <div ibmCol [columnNumbers]="{ md: 8, lg: 8 }">
        <div ibmRow>
            <div ibmCol class="s-head-28-36-bold mb-5r">{{ 'PAGES.DISTRIBUTION_KEYS.ADD.HEADLINE' | translate }}</div>
        </div>
        <form
            (keydown.enter)="$event.preventDefault()"
            [formGroup]="form"
            [ngClass]="{
                'mb-5r': selectedOwnerships === ownerships.length && currentBase && currentBase !== 'CONSUMPTION',
                'mb-8r': selectedOwnerships !== ownerships.length || currentBase === 'CONSUMPTION'
            }"
        >
            <div ibmRow class="mb-4r">
                <div ibmCol [columnNumbers]="{ md: 8, lg: 8 }">
                    <ibm-label
                        [invalid]="descriptionInvalid"
                        [helperText]="''"
                        invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                        >{{ 'ENTITIES.DISTRIBUTION_KEY.LABEL_DESCRIPTION' | translate }}*
                        <input
                            ibmText
                            type="text"
                            placeholder=""
                            name="description"
                            id="description"
                            formControlName="description"
                            [invalid]="isInvalid('description')"
                        />
                    </ibm-label>
                </div>
                <div ibmCol [columnNumbers]="{ md: 8, lg: 8 }" class="distribution-base">
                    <app-combo-box
                        [size]="'md'"
                        id="distributionBase"
                        [appendInline]="true"
                        label="{{ 'ENTITIES.DISTRIBUTION_KEY.LABEL_DISTRIBUTION_BASE' | translate }}*"
                        [items]="distributionBaseListItems!"
                        formControlName="distributionBase"
                        [invalid]="isInvalid('distributionBase')"
                        invalidText="{{ 'ACCOUNTING.ERROR.INVALID_AMOUNT' | translate }}"
                        (selected)="onSelectDistributionBase($event)"
                        placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT' | translate }}"
                    >
                        <ibm-dropdown-list></ibm-dropdown-list>
                    </app-combo-box>
                </div>
            </div>
            <div ibmRow class="mb-2r s-body-14-22-bold">
                <div ibmCol>{{ 'PAGES.DISTRIBUTION_KEYS.ADD.CREATE_GROUP_TITLE' | translate }}</div>
            </div>
            <div ibmRow class="mb-1-5r">
                <div ibmCol class="s-label-12-22-semibold">
                    {{ 'ENTITIES.OWNERSHIP.LABEL_ENTITY_PLURAL' | translate }}
                </div>
                <div ibmCol class="s-label-12-22-semibold text-right">
                    {{ 'ENTITIES.DISTRIBUTION_KEY.LABEL_DISTRIBUTION_SHARE' | translate }}
                </div>
            </div>
            <ng-container formArrayName="ownershipsUsageShare">
                <ng-container *ngFor="let ownershipUsage of ownershipsUsageShare.controls; let i = index">
                    <div ibmRow class="mt-0-75r ownership" [formGroup]="getOwnershipUsage(i)">
                        <div
                            ibmCol
                            [columnNumbers]="{ md: 8, lg: 8 }"
                            class="s-body-14-22-regular d-flex align-items-center"
                        >
                            <ibm-checkbox (change)="onCheckboxChange($event, i)" formControlName="enableShare">
                                {{ ownerships[i].name }}
                            </ibm-checkbox>
                        </div>
                        <div
                            ibmCol
                            *ngIf="currentBase === ''"
                            [offsets]="{ md: 3, lg: 3 }"
                            [columnNumbers]="{ md: 5, lg: 5 }"
                            class="d-flex justify-content-end align-items-center s-body-14-22-regular"
                        >
                            –
                        </div>
                        <div
                            ibmCol
                            *ngIf="currentBase !== 'CONSUMPTION'"
                            [offsets]="{ md: 3, lg: 3 }"
                            [columnNumbers]="{ md: 5, lg: 5 }"
                            class="d-flex justify-content-end align-items-center s-body-14-22-regular"
                        >
                            <div
                                [ngClass]="{ 's-body-14-22-italic': ownerships[i].fraction === null }"
                                *ngIf="currentBase === 'FRACTION'"
                            >
                                {{
                                    ownerships[i].fraction === null
                                        ? 'nicht eingegeben'
                                        : (ownerships[i].fraction | number : '0.1-5' : 'de-DE')
                                }}
                            </div>
                            <div *ngIf="currentBase === 'OWNERSHIPS'">1</div>
                            <div
                                [ngClass]="{ 's-body-14-22-italic': ownerships[i].persons === null }"
                                *ngIf="currentBase === 'PERSONS'"
                            >
                                {{ ownerships[i].persons === null ? 'nicht eingegeben' : ownerships[i].persons }}
                            </div>
                            <div
                                [ngClass]="{ 's-body-14-22-italic': ownerships[i].squaremeters === null }"
                                *ngIf="currentBase === 'SQUARE_METERS'"
                            >
                                {{
                                    ownerships[i].squaremeters === null
                                        ? 'nicht eingegeben'
                                        : (ownerships[i].squaremeters | number : '1.2' : 'de-DE')
                                }}
                            </div>
                            <div
                                [ngClass]="{ 's-body-14-22-italic': ownerships[i].heatedSquaremeters === null }"
                                *ngIf="currentBase === 'HEATED_SQUARE_METERS'"
                            >
                                {{
                                    ownerships[i].heatedSquaremeters === null
                                        ? 'nicht eingegeben'
                                        : (ownerships[i].heatedSquaremeters | number : '1.2' : 'de-DE')
                                }}
                            </div>
                        </div>
                        <div
                            ibmCol
                            *ngIf="currentBase === 'CONSUMPTION'"
                            ibmCol
                            [offsets]="{ md: 4, lg: 4 }"
                            [columnNumbers]="{ md: 4, lg: 4 }"
                            class="d-flex justify-content-end align-items-center s-body-14-22-regular"
                        >
                            <app-number-input formControlName="amountShare" [allowNegatives]="false"></app-number-input>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <div ibmRow class="mt-2r">
                <div ibmCol class="s-body-14-22-regular text-right">
                    <div class="s-label-12-22-semibold mb-0-75r">
                        {{ 'ENTITIES.DISTRIBUTION_KEY.LABEL_TOTAL_DISTRIBUTION' | translate }}
                    </div>
                    <div class="s-body-14-22-bold" *ngIf="currentBase === 'CONSUMPTION'">
                        {{ calculateSum() | eurocent }}
                    </div>
                    <div class="s-body-14-22-bold" *ngIf="currentBase === 'OWNERSHIPS'">{{ selectedOwnerships }}</div>
                    <div
                        class="s-body-14-22-bold"
                        *ngIf="currentBase !== 'OWNERSHIPS' && currentBase !== 'CONSUMPTION'"
                    >
                        {{ distributionSum | number : '1.2' : 'de-DE' }}
                    </div>
                </div>
            </div>
        </form>
        <div
            ibmRow
            *ngIf="
                !isLoading && selectedOwnerships === ownerships.length && currentBase && currentBase !== 'CONSUMPTION'
            "
            class="mb-5r"
        >
            <div ibmCol>
                <app-notification
                    type="error"
                    subtitle="{{ 'PAGES.DISTRIBUTION_KEYS.ADD.ERROR_NOTIFICATION' | translate }}"
                >
                </app-notification>
            </div>
        </div>

        <!-- START BUTTON ROW -->
        <div ibmRow>
            <div ibmCol>
                <button [ibmButton]="'tertiary'" type="reset" (click)="abort()">
                    {{ 'ACCOUNTING.COMMON.BTN_CANCEL' | translate }}
                </button>
            </div>
            <div ibmCol class="text-right">
                <button
                    [ibmButton]="'primary'"
                    type="submit"
                    (click)="onSubmit()"
                    [disabled]="
                        !this.form.valid ||
                        selectedOwnerships === 0 ||
                        (selectedOwnerships === ownerships.length && currentBase !== 'CONSUMPTION') ||
                        (currentBase === 'CONSUMPTION' && calculateSum() === 0)
                    "
                >
                    {{ 'COMMON.BTN_SAVE' | translate }}
                </button>
            </div>
        </div>
        <!-- END BUTTON ROW -->
    </div>
    <div ibmCol></div>
</div>
