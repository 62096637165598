<div ibmRow>
    <div ibmCol [columnNumbers]="{ md: 16, lg: 16 }">
        <div ibmRow class="mb-10">
            <div ibmCol [columnNumbers]="{ md: 8, lg: 8, xl: 8 }">
                <div class="s-head-28-36-bold mb-04">
                    <app-text-with-tooltip
                        class="tw-inline-block tw-align-baseline"
                        [label]="'ENTITIES.SERVICE_PROVIDER.LABEL_ENTITY' | translate"
                        [labelClass]="''"
                        [tooltipKey]="TooltipKey.tooltipsInProperties"
                        [tooltipInnerHtml]="'PAGES.TOOLTIPS.SERVICE_PROVIDERS_OVERVIEW.LABEL_ENTITY' | translate"
                    ></app-text-with-tooltip>
                </div>
            </div>

            <div ibmCol [columnNumbers]="{ md: 8, lg: 8, xl: 8 }" class="d-flex justifiy-content-end">
                <app-scalara-button
                    *ngIf="isManagerOfThisProperty"
                    variant="ghost"
                    (click)="openAddOwnerOverlay()"
                    svgName="24_add.svg"
                >
                    {{ 'CONTROLS.BUTTONS.ADD' | translate }}
                </app-scalara-button>
            </div>
        </div>
    </div>
</div>

<app-service-provider [showHeader]="false"></app-service-provider>
