import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject, finalize, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';
import { BookingsService, CreateCancellationBookingDto } from '../../../../../../generated-sources/accounting';

@Component({
    selector: 'app-cancellation-booking-overlay',
    templateUrl: './cancellation-booking-overlay.component.html',
})
export class CancellationBookingOverlayComponent extends OverlayChildComponent implements OnInit {
    private unsubscribe$ = new Subject<void>();
    public isLoading = false;
    public fileName = '';
    public idEconomicPlan = '';
    public toastSuccessMessage?: string;
    public ledgerId = '';
    public bookingId = '';

    public form: UntypedFormGroup = new UntypedFormGroup({});

    public constructor(
        private bookingsService: BookingsService,
        private toastService: ToastService,
        private formBuilder: UntypedFormBuilder,
        private translateService: TranslateService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.ledgerId = this.config?.data?.ledgerId;
        this.bookingId = this.config?.data?.bookingId;

        this.form = this.formBuilder.group({
            reason: ['', [Validators.required]],
        });

        this.translateService
            .get('PAGES.BOOKINGS.CANCEL_BOOKING_OVERLAY.TOAST_SUCCESS_MESSAGE')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((result) => (this.toastSuccessMessage = result));
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public submit(): void {
        this.isLoading = true;

        const cancellationDTO: CreateCancellationBookingDto = {
            bookingId: this.bookingId,
            cancellationReason: this.form?.get('reason')?.value,
        };

        this.bookingsService
            .createCancellationBooking(this.ledgerId, cancellationDTO)
            .pipe(
                takeUntil(this.unsubscribe$),
                finalize(() => (this.isLoading = false))
            )
            .subscribe({
                next: () => {
                    this.toastService.showSuccess(this.toastSuccessMessage);
                    this.saveEmitter$.next();
                },
                error: (error) => {
                    if (error) {
                        this.toastService.showError(error.error['message']);
                    }
                },
            });
    }
}
