<ibm-loading *ngIf="isLoading" [isActive]="true" [size]="'normal'" [overlay]="true"></ibm-loading>
<div class="mt-2r mb-3-5r">
    <app-scalara-button [variant]="'ghost'" type="button" svgName="24_arrow-left.svg" (click)="backPage()">
        Übersicht alle Abrechnungen
    </app-scalara-button>
</div>

<ng-container *ngIf="getOccupationDescription() as occupationInfo">
    <div class="mb-2-5r px-3r py-2-5r costs-card mb-5r">
        <div class="d-flex justify-space-between">
            <div class="s-body-14-22-bold">Ergebnis</div>
            <div class="s-body-14-22-regula">
                <span class="mr-2r s-label-12-22-semibold s-gray-03">Erstelldatum</span> {{ oesActivationDate }}
            </div>
        </div>

        <div class="mb-2-5r mt-2-5r d-flex">
            <div class="s-label-12-22-semibold s-gray-03 mr-2r">
                <div class="mb-0-75r">Nutzung</div>
                <div *ngIf="occupation?.occupation?.occupationType !== 'VACANCY'" class="mb-0-75r">Mieter</div>
                <div class="mb-0-75r">Abrechnungszeitraum</div>
                <div class="mb-0-75r">Nutzungszeitraum</div>
                <div class="mb-0-75r">Nutzungstage</div>
            </div>

            <div class="s-body-14-22-regular">
                <div class="mb-0-75r">{{ occupationInfo.occupationDetail }}</div>
                <div *ngIf="occupation?.occupation?.occupationType !== 'VACANCY'" class="mb-0-75r">
                    {{ occupationInfo.occupationNumberAndTenants }}
                </div>
                <div class="mb-0-75r">{{ oesDate }}</div>
                <div class="mb-0-75r">
                    {{ occupation && occupation.usagePeriodStart ? (occupation.usagePeriodStart | dateFormat) : '' }} –
                    {{
                        occupation && occupation.usagePeriodEnd
                            ? (occupation.usagePeriodEnd | dateFormat)
                            : 'unbefristet'
                    }}
                </div>
                <div class="mb-0-75r">{{ occupation?.usagePeriodLength }} von {{ oesLengthInDays }}</div>
            </div>
        </div>

        <div class="d-flex justify-space-between mb-0-75r s-body-14-22-regular">
            <div>Betriebskosten – Vorauszahlungen</div>
            <div>{{ occupation?.additionalCostAdvancementAmount || 0 | eurocent }} €</div>
        </div>

        <div class="d-flex justify-space-between mb-0-75r s-body-14-22-regular">
            <div>Betriebskosten – Forderungen</div>
            <div>{{ occupation?.additionalCostAmount || 0 | eurocent }} €</div>
        </div>
        <div
            *ngIf="occupation?.occupation?.rentDetails?.rentCalculationMethod === 'BASE_RENT_OPERATIONAL_COST_HEATING'"
        >
            <hr class="mt-1r mb-1r" />

            <div class="d-flex justify-space-between mb-0-75r s-body-14-22-regular">
                <div>Betriebskosten – Ergebnis</div>
                <div>{{ occupation?.additionalCostBalance || 0 | eurocent }} €</div>
            </div>

            <div class="mb-3r"></div>

            <div class="d-flex justify-space-between mb-0-75r s-body-14-22-regular">
                <div>Heizkosten – Vorauszahlungen</div>
                <div>{{ occupation?.heatingCostAdvancementAmount || 0 | eurocent }} €</div>
            </div>
        </div>
        <div class="d-flex justify-space-between mb-0-75r s-body-14-22-regular">
            <div>Heizkosten – Forderungen</div>
            <div>{{ occupation?.heatingCostAmount || 0 | eurocent }} €</div>
        </div>
        <div
            *ngIf="occupation?.occupation?.rentDetails?.rentCalculationMethod === 'BASE_RENT_OPERATIONAL_COST_HEATING'"
        >
            <hr class="mt-1r mb-1r" />

            <div class="d-flex justify-space-between mb-0-75r s-body-14-22-regular">
                <div>Heizkosten – Ergebnis</div>
                <div>{{ occupation?.heatingCostBalance || 0 | eurocent }} €</div>
            </div>
        </div>

        <div class="mb-3r"></div>

        <hr class="mt-1r mb-1r" />

        <div class="d-flex justify-space-between s-body-14-22-bold">
            <div>Abrechnungsergebnis</div>
            <div>
                <span class="mr-2r">{{ occupation?.balanceAmount! > 0 ? 'Erstattung' : 'Nachzahlung' }}</span>
                {{ occupation?.balanceAmount || 0 | eurocent }} €
            </div>
        </div>
    </div>
</ng-container>

<!-- <div class="d-flex justify-space-between mb-1-5r">
    <div class="s-head-16-24-bold">PDF Dokumente</div>
    <div class="d-flex justify-content-end">
        <app-scalara-button
            [disabled]="true"
            [variant]="'ghost'"
            type="button"
            svgName="24_download.svg"
            (click)="backPage()"
            class="mr-0-5r"
        >
            Alle Herunterladen
        </app-scalara-button>
        <app-scalara-button [variant]="'ghost'" type="button" svgName="24_add.svg" (click)="backPage()">
            Dokument hochladen
        </app-scalara-button>
    </div>
</div> -->
<!-- TODO: back this code when there is this functionality -->

<div class="mb-3r s-head-16-24-bold">Aufteilung der Gesamtkosten</div>

<table class="mb-3-5r">
    <tr simpleTableRow [columns]="distributionKeyTableData.columns" variant="header"></tr>

    <ng-container *ngFor="let row of distributionKeyTableData.rows">
        <tr simpleTableRow [columns]="distributionKeyTableData.columns" [rowData]="row" [class]="row.rowClass"></tr>
    </ng-container>
</table>

<table class="mb-5r">
    <ng-container *ngFor="let table of costsTables">
        <tr simpleTableRow [columns]="table.columns" variant="header"></tr>

        <ng-container *ngFor="let row of table.rows">
            <tr simpleTableRow [columns]="table.columns" [rowData]="row" [class]="row.rowClass"></tr>
        </ng-container>
        <tr simpleTableRow></tr>
    </ng-container>
</table>

<ng-container>
    <div class="s-head-16-24-bold mb-2-5r">Haushaltsnahe Dienstleistungen</div>
    <table>
        <tr simpleTableRow [columns]="homeCostsTableData.columns" variant="header"></tr>

        <ng-container *ngFor="let row of homeCostsTableData.rows">
            <tr simpleTableRow [columns]="homeCostsTableData.columns" [rowData]="row" [class]="row.rowClass"></tr>
        </ng-container>
    </table>
</ng-container>
