import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateTimeFormat',
})
export class DateTimeFormatPipe implements PipeTransform {
    public constructor(private datePipe: DatePipe) {}

    public transform(value: string | undefined): string {
        if (!value) {
            return '';
        }

        const formattedDate = this.datePipe.transform(new Date(value), 'dd.MM.yyyy – HH:mm', 'Europe/Berlin');

        return formattedDate || value;
    }
}
