<div>
    <div class="d-flex justify-space-between mb-1r">
        <div class="s-head-16-24-bold">{{ title ?? 'PDF Dokumente' }}</div>
        <!-- is not implemented right now -->
        <!-- <div class="d-flex">
            <app-scalara-button variant="ghost" svgName="24_download.svg">Alle Herunterladen</app-scalara-button>
            <app-scalara-button variant="ghost">Hochladen</app-scalara-button>
        </div> -->
    </div>
    <div>
        <div class="item d-flex mb-0-5r" *ngFor="let item of items">
            <div class="icon-container">
                <svg-icon src="/assets/icons/24_dokument.svg"></svg-icon>
            </div>
            <div class="flex-grow pl-0-5r s-body-14-20-regular">{{ item.fileName }}</div>
            <div class="d-flex">
                <app-scalara-button
                    svgName="24_download.svg"
                    variant="ghost"
                    (click)="onActionClick({item, flow: 'download'})"
                ></app-scalara-button>
                <app-scalara-button
                    svgName="24_eye.svg"
                    variant="ghost"
                    (click)="onActionClick({item, flow: 'view'})"
                ></app-scalara-button>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="this.vm && this.vm.fileViewerData.fileToView && this.vm.fileViewerData.showModal">
    <app-file-viewer
        [fileToView]="this.vm.fileViewerData.fileToView"
        (closeViewer)="closeFileViewerModal()"
    ></app-file-viewer>
</ng-container>

<ibm-loading *ngIf="vm && vm.isLoading" [isActive]="true" [size]="'normal'" [overlay]="true"></ibm-loading>
