<div
    class="tw-border-dashed tw-border-2 tw-border-scalaraGray-04 tw-mt-6 tw-p-2"
    [formGroup]="bankAccountStartAmountFormGroup"
>
    <div class="tw-flex-row tw-s-head-16-24-bold tw-mb-2">
        {{ 'ACCOUNTING.REASONABILITY_CHECK.HEADLINE_SET_INITIAL_AMOUNT' | translate }}
    </div>
    <div class="tw-flex tw-justify-between tw-ml-2">
        <div class="tw-w-[500px]">
            <ibm-dropdown
                (selected)="onSelectedBankAccount($event)"
                placeholder="Bankkonto Auswählen"
                label="Bankkonto"
                id="selectedBankAccount"
                formControlName="selectedBankAccount"
            >
                <ibm-dropdown-list [items]="(bankAccountItemList$ | async) ?? []"></ibm-dropdown-list>
            </ibm-dropdown>
        </div>
        <div>
            <ibm-date-picker
                label="Anfangsdatum"
                [value]="[bankAccountStartAmountDate]"
                (valueChange)="onChangebankAccountStartAmountDateDate($event)"
                id="bankAccountStartAmountDate"
                [placeholder]="'tt.mm.jjjj'"
                [size]="'md'"
                [language]="'de'"
                [dateFormat]="'d.m.Y'"
                formControlName="bankAccountStartAmountDate"
            >
            </ibm-date-picker>
        </div>
        <div>
            <app-number-input
                label="Betrag in €*"
                formControlName="bankAccountStartAmount"
                [remainClearOnBlur]="true"
            ></app-number-input>
        </div>
        <div class="tw-w-[170px]">
            <label class="bx--label"></label>
            <app-scalara-button
                class="mr-05"
                (click)="onSubmit()"
                [fullWidth]="true"
                svgName="24_closed.svg"
                [disabled]="bankAccountStartAmountFormGroup.invalid"
                >{{ 'CONTROLS.BUTTONS.SAVE' | translate }}</app-scalara-button
            >
        </div>
    </div>
</div>
