import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbItem } from 'carbon-components-angular';
import { BehaviorSubject, Subject, combineLatest, finalize, forkJoin, map, switchMap, takeUntil, tap } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { BreadCrumbService } from 'src/app/core/services/breadcrumb.service';
import { LayoutMode, MediaQueriesService } from 'src/app/core/services/media-queries.service.ts.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { getNameFromPerson } from 'src/app/core/utils/common';
import {
    AttachmentDto,
    CommentDto,
    Person,
    PropertiesService,
    TicketDto,
    TicketsService,
} from 'src/app/generated-sources/base';
import { FilesService } from 'src/app/generated-sources/file';
import { FiletoView } from 'src/app/shared/components/file-viewer/file-viewer.component';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { CellTemplate } from '../../../../shared/table/enums/cell-template';
import { TableItem } from '../../../../shared/table/interfaces/table-item';
import { TableModel } from '../../../../shared/table/interfaces/table-model';
import { AddEditTicketFormComponent } from '../add-edit-ticket-form/add-edit-ticket-form.component';
import { DeleteTicketComponent } from '../delete-ticket/delete-ticket.component';

@Component({
    selector: 'app-ticket-overview',
    templateUrl: './ticket-overview.component.html',
    styleUrls: ['./ticket-overview.component.scss'],
})
export class TicketOverviewComponent implements OnInit {
    private unsubscribe$ = new Subject<void>();
    public breadcrumbs: BreadcrumbItem[] = [];
    public tableModel: TableModel = { data: [], header: [] };
    public commentsTableModel: TableModel = { data: [], header: [] };
    public ticket?: TicketDto;
    public fileToView?: FiletoView;
    public showModal = false;
    public isLoading = false;
    public isInitialLoading = false;
    public ispropertyManager = false;
    public ticketPermissions?: TicketDto.PermissionsEnum[];
    public comments?: CommentDto[];
    public propertyImg = '';

    public vm?: {
        activeRouteLabel: string;
        currentLayoutMode: LayoutMode;
    };

    public activeRouteLabel$ = new BehaviorSubject<string>('');
    public vm$ = combineLatest([this.activeRouteLabel$, this.mediaQueriesService.getCurrentLayoutMode()]).pipe(
        map(([activeRouteLabel, currentLayoutMode]) => ({
            activeRouteLabel,
            currentLayoutMode,
        }))
    );

    public constructor(
        private overlayService: OverlayService,
        private breadcrumbService: BreadCrumbService,
        public mediaQueriesService: MediaQueriesService,
        private route: ActivatedRoute,
        private ticketService: TicketsService,
        private filesService: FilesService,
        private toastService: ToastService,
        private propertiesService: PropertiesService,
        private authService: AuthService
    ) {}

    public ngOnInit(): void {
        this.vm$.pipe(takeUntil(this.unsubscribe$)).subscribe((vm) => {
            this.vm = vm;
        });
        this.isInitialLoading = true;

        this.ispropertyManager = !!this.authService
            .getStorage()
            .scalaraUser?.permissionRoles?.find((permission) => permission === 'PROPERTY_MANAGER');

        this.route.paramMap
            .pipe(
                tap((paramMap) => {
                    const id = paramMap.get('ticketId');
                    if (id) {
                        this.loadTicket(id);
                    }
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }

    private compare(a: CommentDto, b: CommentDto): 1 | 0 | -1 {
        const date1 = new Date(a.createdAt).getTime();
        const date2 = new Date(b.createdAt).getTime();
        if (date1 > date2) {
            return -1;
        }
        if (date1 < date2) {
            return 1;
        }
        return 0;
    }

    public loadTicket(ticketId?: string): void {
        this.isLoading = true;
        forkJoin([
            this.ticketService.findOne(ticketId ? ticketId : this.ticket?.id || ''),
            this.ticketService.getComments(ticketId ? ticketId : this.ticket?.id || ''),
        ])
            .pipe(
                switchMap((response) => {
                    const ticket = response[0];
                    const comments = response[1].sort(this.compare);

                    this.initBreadCrumbs(ticket?.id, ticket.title);
                    this.ticketPermissions = ticket.permissions;
                    const havePermission = ticket.permissions?.includes('EDIT');
                    this.tableModel.data = ticket.attachments.map((item) => this.createRow(item, havePermission));
                    this.ticket = ticket;

                    const commentsWithAttachments = comments.filter((comment) => comment.attachments.length > 0);

                    const attachmentsData: TableItem[][] = [];

                    commentsWithAttachments.map((comment) => {
                        const havePermission = comment.permissions?.includes('EDIT');
                        attachmentsData.push(
                            ...comment.attachments.map((item) => this.createRow(item, havePermission))
                        );
                    });

                    this.commentsTableModel.data = attachmentsData;

                    this.comments = comments;
                    return this.propertiesService.findOne(ticket.property.id, true);
                }),
                tap((property) => {
                    this.propertyImg = property.imgFull || '';

                    // mapping the address into ticket property
                    // for usage in the ticket-detail component
                    if (this.ticket) {
                        const ticketWithAddress: TicketDto = { ...this.ticket };
                        ticketWithAddress.property.address = property.address;
                        this.ticket = { ...ticketWithAddress };
                    }
                }),
                finalize(() => {
                    this.isLoading = false;
                    this.isInitialLoading = false;
                })
            )
            .subscribe();
    }

    private initBreadCrumbs(ticketId: string, ticketTitle: string): void {
        const limitCaracters = this.vm?.currentLayoutMode === 'desktop' ? 80 : 56;
        this.breadcrumbs = [
            {
                content: 'Tickets',
                route: ['/communications'],
            },
            {
                content:
                    ticketTitle.length > limitCaracters ? ticketTitle.slice(0, limitCaracters) + '...' : ticketTitle,
                route: ['/communications/' + ticketId],
                current: true,
            },
        ];
        this.breadcrumbService.resetBreadCrumbs();
        this.breadcrumbService.updateBreadCrumbs(this.breadcrumbs);
        this.breadcrumbs = this.breadcrumbService.getCurrentBreadCrumbs();
    }

    private createRow(data: AttachmentDto, havePermission: boolean): TableItem[] {
        return [
            {
                data: {
                    label:
                        this.vm?.currentLayoutMode === 'desktop' ? data.fileName : data.fileName.slice(0, 15) + '...',
                    extraData: data,
                    iconSrc: '/assets/icons/24_attachment.svg',
                },
                template: CellTemplate.textWithIcon,
            },
            {
                data: {
                    label: '',
                    extraData: {
                        ...data,
                        showOnlyWithHover: false,
                        hiddenDeleteButton: !havePermission,
                    },
                },
                template: CellTemplate.Actions,
            },
        ];
    }

    public handleActions($event: any): void {
        this.filesService
            .getDownloadLink($event.data.extraData.fileStorageId)
            .pipe(
                tap((data: any) => {
                    if ($event.action === 'download') {
                        const link = document.createElement('a');
                        link.href = data.url;
                        link.download = $event.data.extraData.fileName;
                        link.click();
                    } else if ($event.action === 'view') {
                        this.fileToView = {
                            fileName: $event.data.extraData.fileName,
                            file: data.url,
                        };
                        this.showModal = true;
                    } else if ($event.action === 'delete') {
                        this.ticketService
                            .deleteTicketAttachment($event.data.extraData.id, this.ticket?.id)
                            .pipe(takeUntil(this.unsubscribe$))
                            .subscribe({
                                next: () => {
                                    this.toastService.showSuccess('Dokument erfolgreich gelöscht');
                                    this.loadTicket();
                                },
                                error: (error) => {
                                    if (error) {
                                        this.toastService.showError(error.error['message']);
                                    }
                                },
                            });
                    }
                })
            )
            .subscribe();
    }

    public openDeleteTicketOverlay(): void {
        const ref = this.overlayService.open(DeleteTicketComponent, {
            data: { ticketName: this.ticket?.title, ticketId: this.ticket?.id },
        });

        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
    }

    public openAddTicketOverlay(): void {
        const ref = this.overlayService.open(AddEditTicketFormComponent, {
            data: { ticket: this.ticket },
        });

        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.loadTicket());
    }

    public handleModal(): void {
        this.showModal = !this.showModal;
    }

    public havePermission(permission: TicketDto.PermissionsEnum): boolean {
        return this.ticketPermissions?.includes(permission) || false;
    }

    public getNameFromPerson(owner: Person): string {
        return getNameFromPerson(owner);
    }
}
