<div class="tw-s-head-16-24-bold tw-mb-8">{{ 'ACCOUNTING.LEDGER_SETTINGS.HEADLINE' | translate }}</div>
<div class="tw-mt-6 tw-p-2" [formGroup]="form">
    <div class="tw-flex-row tw-s-head-16-24-bold tw-mb-2">
        {{ 'ACCOUNTING.LEDGER_SETTINGS.OPEN_ITEMS_DISTRIBUTION_HEADLINE' | translate }}
    </div>
    <div class="tw-s-head-14-22-semibold">
        {{ 'ACCOUNTING.LEDGER_SETTINGS.OPEN_ITEMS_DISTRIBUTION_INFOTEXT' | translate }}
    </div>
    <div class="tw-flex tw-justify-between tw-ml-2 tw-mt-4">
        <div class="tw-flex tw-justify-start">
            <div class="tw-mr-4 tw-mt-2">
                <ibm-radio-group
                    aria-label="radiogroup"
                    orientation="vertical"
                    formControlName="ledgerCalculationType"
                    (change)="onRadioChange($event)"
                >
                    <ibm-radio *ngFor="let type of radioButtonListItems" [value]="type" class="tw-my-3">
                        {{ 'ENTITIES.LEDGER.CALCULATION_TYPE.' + type | translate }}
                    </ibm-radio>
                </ibm-radio-group>
            </div>
            <div
                class="tw-flex tw-flex-row tw-justify-start tw-mx-4 tw-w-[450px] tw-mt-2 tw-border tw-border-solid tw-border-scalaraGray-05 tw-p-4"
            >
                <div>
                    <div class="tw-w-6 tw-h-6 tw-fill-scalaraBlue-01">
                        <svg-icon src="/assets/icons/24_information.svg"></svg-icon>
                    </div>
                </div>
                <div class="tw-flex tw-flex-col tw-justify-start tw-ml-1">
                    <div class="tw-s-head-14-22-semibold">{{ descriptionTextHeadline }}</div>
                    <div>{{ descriptionText }}</div>
                </div>
            </div>
        </div>
        <div class="tw-w-[170px] tw-mr-8 tw-mt-2">
            <app-scalara-button
                class="mr-05 tw-w-[170px]"
                (click)="onSubmit()"
                [fullWidth]="true"
                [disabled]="form.invalid"
                >{{ 'CONTROLS.BUTTONS.SAVE' | translate }}</app-scalara-button
            >
        </div>
    </div>
    <!--  -->
</div>
