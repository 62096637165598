import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ISignUpResult } from 'amazon-cognito-identity-js';
import { ToastContent } from 'carbon-components-angular';
import { finalize, Subject, takeUntil } from 'rxjs';
import { passwordConfirmInvalid, passwordInvalid } from 'src/app/core/utils/formValidationHelpers';
import { Person } from 'src/app/generated-sources/base';
import {
    PersonData,
    PersonTypeAndNameFormComponent,
} from 'src/app/shared/person-type-and-name-form/person-type-and-name-form.component';
import { ToastService } from '../../../../core/services/toast.service';
import { formControl, formControlHasError } from '../../../../core/utils/common';
import { PasswordValidators } from '../../../../shared/validators/password-validators';
import { VerificationService } from '../../services/verification.service';
import { AuthSuccessFlowTypeEnum } from '../types/authSuccess';

@Component({
    selector: 'app-register-invitation',
    templateUrl: './register-invitation.component.html',
    styleUrls: ['./register-invitation.component.scss'],
})
export class RegisterInvitationComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();

    public notification?: ToastContent;

    public readonly sources = [
        'Empfehlung eines Eigentümers',
        'Empfehlung einer anderen Verwaltung',
        'Internetanzeige',
        'Internetsuche',
        'Verwaltermesse / -tag',
        'Sonstige',
    ] as const;

    public form: UntypedFormGroup = new UntypedFormGroup({});
    public static MIN_PASSWORD_LENGTH = 8;
    public isLoading = false;

    public invitationFirstName = '';
    public invitationLastName = '';
    public invitationEmail = '';
    public invitationToken = '';
    public invitationCompanyName = '';
    public invitationType = '';

    @ViewChild(PersonTypeAndNameFormComponent)
    public personTypeAndNameFormComponent?: PersonTypeAndNameFormComponent;

    public get passwordInvalid(): boolean {
        return passwordInvalid({ form: this.form });
    }
    public get passwordConfirmInvalid(): boolean {
        return passwordConfirmInvalid({ form: this.form });
    }

    public constructor(
        private router: Router,
        private verificationService: VerificationService,
        private formBuilder: UntypedFormBuilder,
        private toast: ToastService,
        private route: ActivatedRoute // private customUrlSerializer: CustomUrlSerializer
    ) {}

    public ngOnInit(): void {
        this.isLoading = true;
        this.form = this.createForm();
        this.route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((params: any) => {
            this.invitationEmail = decodeURIComponent(params.email);
            this.invitationToken = decodeURIComponent(params.token);
            this.invitationFirstName = decodeURIComponent(params.firstName);
            this.invitationLastName = decodeURIComponent(params.lastName);
            this.invitationCompanyName = decodeURIComponent(params.companyName);
            this.invitationType = decodeURIComponent(params.companyName);
        });

        this.verificationService
            .getSignUpResult()
            .pipe(
                finalize(() => (this.isLoading = false)),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: (result: ISignUpResult | Error) => {
                    if ('user' in result) {
                        this.toast.showSuccess('Anmeldung erfolgreich', 'Bitte verifizieren Sie ihre E-Mail Adresse.');
                        this.router.navigate(['/auth-success'], {
                            queryParams: { flowType: AuthSuccessFlowTypeEnum.newSignUp },
                        });
                    } else {
                        this.toast.showError('Anmeldung fehlgeschlagen', 'E-Mail Adresse bereits registriert.');
                    }
                },
            });
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public onSignUp(): void {
        if (this.form.invalid) {
            return;
        }
        this.isLoading = true;
        const { password } = this.form.value;
        const personTypeAndName: PersonData = {
            type: Person.TypeEnum.Company,
            firstName: this.invitationFirstName,
            lastName: this.invitationLastName,
            companyName: this.invitationCompanyName,
        };

        this.verificationService.signUp({
            email: this.invitationEmail,
            password,
            source: 'invitation',
            personData: personTypeAndName,
            invitationToken: this.invitationToken,
        });
    }

    private createForm(): UntypedFormGroup {
        return this.formBuilder.group(
            {
                generalTerms: [false, Validators.requiredTrue],
            },
            {
                validators: [PasswordValidators.passwordMatch],
            }
        );
    }

    public get generalTermsInvalid(): boolean {
        return formControlHasError(formControl(this.form, 'generalTerms'), 'required');
    }
}
