import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject, combineLatest, interval, switchMap, takeUntil, takeWhile, tap } from 'rxjs';
import { PersonLocalService } from 'src/app/features/property/services/person-local.service';
import { WegSettlementStatementsService, WssDto } from 'src/app/generated-sources/accounting';
import { Person, Property } from 'src/app/generated-sources/base';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { LedgerCustomService } from '../../accounting/components/services/ledger-custom.service';
import { AddAnnualStatementComponent } from '../add-annual-statement/add-annual-statement.component';

@Component({
    selector: 'app-annual-statement-overview',
    templateUrl: './annual-statement-overview.component.html',
    styleUrls: ['./annual-statement-overview.component.scss'],
})
export class AnnualStatementOverviewComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();
    public isLoading = false;

    //TO CHANGE WHEN ANNUALSTATEMENT DTO + SERVICE ARE DELIVERED
    public annualStatements: WssDto[] = [];

    public showModal = false;

    public ledgerId = '';

    public isPropertyManager = false;

    public ledgerId$ = this.ledgerCustomService.getLedgerId$();

    public refreshWss$ = new BehaviorSubject<void>(undefined);

    public constructor(
        private overlayService: OverlayService,
        private annualStatementsService: WegSettlementStatementsService,
        private personLocalService: PersonLocalService,
        private ledgerCustomService: LedgerCustomService
    ) {}

    public ngOnInit(): void {
        this.isLoading = true;

        combineLatest([this.ledgerId$, this.refreshWss$])
            .pipe(
                tap(([ledgerId]) => {
                    this.ledgerId = ledgerId ?? '';
                }),
                switchMap(() => this.annualStatementsService.findAll(this.ledgerId)),
                tap((economicPlans: WssDto[]) => {
                    this.annualStatements = economicPlans;
                    this.isLoading = false;
                }),
                switchMap(() => this.personLocalService.getRolesOfProperty()),
                tap((roles: Property.PermissionRolesEnum[] | null) => {
                    this.isPropertyManager = roles
                        ? Object.values(roles).includes(Person.PermissionRolesEnum.PropertyManager)
                        : false;
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();

        this.activationPipe$.subscribe();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    // If there is an annual statement that is being activated currently,
    // we refresh the annual statement list Every 20 seconds until the plan is active.
    public activationPipe$ = interval(20000).pipe(
        takeUntil(this.unsubscribe$),
        takeWhile(() => this.planIsActivating()),
        switchMap(() => this.annualStatementsService.findAll(this.ledgerId)),
        tap((annualStatements: WssDto[]) => {
            this.annualStatements = annualStatements;
        })
    );

    public startAnnualStatementCreation(): void {
        const data = { ledgerId: this.ledgerId, annualStatements: this.annualStatements };
        const ref = this.overlayService.open(AddAnnualStatementComponent, {
            data,
        });

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.refreshWss$.next());

        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
    }

    private planIsActivating(): boolean {
        let isActivating = false;
        if (this.annualStatements) {
            isActivating = !!this.annualStatements.find(
                (annualStatement: WssDto) =>
                    annualStatement.processingStatus === WssDto.ProcessingStatusEnum.Processing ||
                    annualStatement.processingStatus === WssDto.ProcessingStatusEnum.ProcessingRequested
            );
        }
        return isActivating;
    }
}
