<div *ngIf="bankTransaction?.id && bankAccount.id">
    <div class="s-head-14-22-bold mb-05">{{ 'ACCOUNTING.BANK_TRANSACTION_DETAILS.BREADCRUMB' | translate }}</div>
    <app-details-container [options]="options">
        <div
            class="main-grid"
            [ngClass]="{ 'main-grid--is-simple-view': this.isSimpleView }"
            ibmCol
            [columnNumbers]="{ md: 16, lg: 16 }"
        >
            <div class="py-07 pb-09 pl-07">
                <div class="two-column-grid-container tw-break-all">
                    <div *ngFor="let item of gridContent.topContent">
                        {{ item }}
                    </div>
                </div>
                <div class="separator"></div>
                <div class="two-column-grid-container">
                    <div *ngFor="let item of gridContent.bottomContent">
                        {{ item }}
                    </div>
                </div>
            </div>
            <div class="py-07 pb-09 px-07" [ngClass]="{ 'border-left': !this.isSimpleView }" *ngIf="bankTransaction">
                <div class="flex-container mb-04">
                    <div class="s-label-12-22-semibold">
                        {{ 'ACCOUNTING.BANK_TRANSACTION_DETAILS.AMOUNT' | translate }}
                    </div>
                    <div class="s-head-18-26-bold">{{ bankTransaction.amount | eurocent }}</div>
                </div>
                <div class="flex-container mb-04">
                    <div class="s-label-12-22-semibold">
                        {{ 'ACCOUNTING.BANK_TRANSACTION_DETAILS.PARTLY_BOOKED' | translate }}
                    </div>
                    <div class="s-head-14-22-regular">
                        {{ bankTransaction.amountAssignedToBookings ?? 0 | eurocent }}
                    </div>
                </div>
                <div class="flex-container mb-07">
                    <div class="s-label-12-22-semibold">
                        {{ 'ACCOUNTING.BANK_TRANSACTION_DETAILS.OPEN_AMOUNT_HEADER' | translate }}
                    </div>
                    <div class="s-body-14-22-bold colored-text">
                        {{ getRestAmount(bankTransaction) | eurocent }}
                    </div>
                </div>
                <button
                    *ngIf="!this.isSimpleView && options.state !== 'ok' && options.state !== 'ignored'"
                    ibmButton="primary"
                    class="max-w-none w-100 d-flex justify-content-center"
                    (click)="openAddBookingOverlay()"
                >
                    {{ 'ACCOUNTING.BANK_TRANSACTION_DETAILS.BUTTON_ADD_BOOKING' | translate }}
                </button>
            </div>
        </div>
    </app-details-container>
</div>
