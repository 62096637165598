<div class="sidenav sidenav-shadow">
    <!-- <div *ngIf="property" class="sidenav-header d-flex flex-direction-column">
        <div class="mb-0-5r">
            <app-tag *ngIf="property.propertyType !== 'WEG_SEV'" [color]="tagColor">
                {{ property.propertyType }}</app-tag
            >
            <div class="d-flex" *ngIf="property.propertyType === 'WEG_SEV'">
                <app-tag class="mr-0-5r" color="green">WEG</app-tag>
                <app-tag color="orange">SEV</app-tag>
            </div>
        </div>

        <div class="mb-04">
            <div class="s-gray-01 s-label-12-22-semibold mt-01 pb-0-25r">{{ property?.name }}</div>
            <div *ngIf="additionalInfo" class="s-gray-01 s-label-12-22-semibold mt-01 pb-0-25r">
                {{ additionalInfo }}
            </div>

            <div class="s-gray-01 s-label-12-16-regular">
                {{ property?.address?.streetName }} {{ property?.address?.streetNumber }}
            </div>
            <div class="s-gray-01 s-label-12-16-regular">
                {{ property?.address?.zipCode }} {{ property?.address?.area }}
            </div>
        </div>
    </div> -->

    <div class="s-body-14-22-bold tw-p-4 sidenav-header">
        {{ menuTitle | translate }}
    </div>

    <ul>
        <ng-container *ngFor="let item of menuItems; let i = index">
            <ng-container *ngIf="item.children">
                <li class="bx--side-nav__item">
                    <div class="tw-flex tw-justify-between pointer tw-align-middle" (click)="toggleOpenState(i)">
                        <div class="tw-w-[50px] sidenav_parent_item s-head-14-22-semibold">
                            {{ item.textPath | translate }}
                        </div>
                        <div class="tw-w-[50px] tw-justify-center tw-p-2">
                            <svg-icon
                                *ngIf="isOpenStates[i]"
                                src="/assets/icons/24_arrow-down.svg"
                                svgClass="tw-h-6 tw-w-6 rotate-180 tw-fill-scalaraGray-03"
                            ></svg-icon>
                            <svg-icon
                                *ngIf="!isOpenStates[i]"
                                src="/assets/icons/24_arrow-down.svg"
                                svgClass="tw-h-6 tw-w-6 tw-fill-scalaraGray-03"
                            ></svg-icon>
                        </div>
                    </div>

                    <ng-container *ngFor="let childItem of item.children">
                        <div [@open]="isOpenStates[i]" class="overflow-hidden">
                            <a
                                class="bx--side-nav__link"
                                [routerLink]="childItem.link"
                                routerLinkActive="sidenav-active-link"
                                queryParamsHandling="merge"
                            >
                                <span class="sidenav-link-text sidenav_child_item">{{
                                    childItem.textPath | translate
                                }}</span>
                            </a>
                        </div>
                    </ng-container>
                </li>
            </ng-container>

            <ng-container *ngIf="!item.children">
                <li class="bx--side-nav__item">
                    <a
                        class="bx--side-nav__link"
                        [routerLink]="item.link"
                        routerLinkActive="sidenav-active-link"
                        queryParamsHandling="merge"
                    >
                        <span class="s-head-14-22-semibold s-gray-01">{{ item.textPath | translate }}</span>
                    </a>
                </li>
            </ng-container>
        </ng-container>

        <!-- TODO: For accounting we will need to differ between direct links and menuitems with children, which will open in an accordion -->
    </ul>
</div>

<div class="sidenav-hollow"></div>
