import { Component, OnInit } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ToastService } from 'src/app/core/services/toast.service';
import { Person, PersonsService, UpdatePersonDto } from 'src/app/generated-sources/base';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-add-user-avatar-overlay',
    templateUrl: './add-user-avatar-overlay.component.html',
    styleUrls: ['./add-user-avatar-overlay.component.scss'],
})
export class AddUserAvatarOverlayComponent extends OverlayChildComponent implements OnInit {
    public uploadedImg?: string;
    public croppedImage?: string;
    public isLoading = false;
    public person?: Person;
    public readonly fileSizeInMb = environment.maxFileSizeInMB;

    public constructor(private personsService: PersonsService, private toastService: ToastService) {
        super();
    }

    public ngOnInit(): void {
        this.person = this.config?.data?.person;
    }

    public fileChangeEvent(event: any): void {
        this.uploadedImg = event;
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public submit(): void {
        if (!this.person) {
            return;
        }

        const updatePersonDto: UpdatePersonDto = {
            imgFull: this.croppedImage ? this.croppedImage.split(',')[1] : undefined,
        };

        this.isLoading = true;

        this.personsService.update(this.person.id, updatePersonDto).subscribe({
            next: () => {
                this.toastService.showSuccess('Erfolgreich hochgeladen');
                this.saveEmitter$.next();
                this.isLoading = false;
            },
            error: (error) => {
                if (error) {
                    this.toastService.showError(error.error['message']);
                }
                this.isLoading = false;
            },
        });
    }

    public removeImg(): void {
        this.uploadedImg = undefined;
        this.croppedImage = undefined;
    }

    public imageCropped(event: ImageCroppedEvent): void {
        this.croppedImage = event.base64 ?? undefined;
    }
}
