import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-tag',
    templateUrl: './tag.component.html',
    styleUrls: ['./tag.component.scss'],
})
export class TagComponent implements OnInit {
    @Input() public color = 'gray';
    @Input() public size: 's' | 'm' = 's';
    public textSize? = '';

    public ngOnInit(): void {
        if (this.size == 's') {
            this.textSize = 's-label-12-16-regular';
        }
        if (this.size == 'm') {
            this.textSize = 's-head-18-26-bold pb-1r';
        }
    }
}
