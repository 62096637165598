/* eslint-disable */
export function sortWith(ordering: string[], evaluator: (obj: any) => string) {
    return (a: any, b: any) => {
        let aIndex = ordering.findIndex((x) => evaluator(a).startsWith(x));
        if (aIndex === -1) aIndex = Number.MAX_VALUE;
        let bIndex = ordering.findIndex((x) => evaluator(b).startsWith(x));
        if (bIndex === -1) bIndex = Number.MAX_VALUE;
        return Math.sign(aIndex - bIndex);
    };
}

export function multiSort<T>(...comparers: ((a: T, b: T) => number)[]) {
    return (a: any, b: any) => {
        let result = 0;
        for (let index = 0; index < comparers.length; index++)
            result += Math.pow(10, comparers.length - index) * comparers[index](a, b);
        return result;
    };
}

export function naturalSort(a: string, b: string): number {
    function padNumbers(str: string, maxLength: number): string {
        return str.replace(/\d+/g, (match) => match.padStart(maxLength, '0'));
    }

    function findLongestNumberLength(strs: string[]): number {
        return strs.reduce((maxLength, str) => {
            const localMax = Math.max(...(str.match(/\d+/g) || []).map((num) => num.length));
            return Math.max(maxLength, localMax);
        }, 0);
    }

    const maxLength = findLongestNumberLength([a, b]);
    const normalizedA = padNumbers(a, maxLength);
    const normalizedB = padNumbers(b, maxLength);

    return normalizedA.localeCompare(normalizedB);
}

export function localCompare(a: string, b: string): number {
    if (!a) return 0;
    return a.localeCompare(b);
}

/*
Example:
const people = [
    { name: "John", age: 25 },
    { name: "Jane", age: 22 },
    { name: "Doe", age: 30 }
];

const sortedPeople = people.sort(sortBy((person) => person.age));
console.log(sortedPeople);
// => Jane, John, Doe
*/
export function sortBy(resolver: (obj: any) => any, sortFn?: (a: any, b: any) => number) {
    return (a: any, b: any) => {
        return (sortFn ?? defaultComparer)(resolver(a), resolver(b));
    };
}

function defaultComparer(a: any, b: any) {
    if (a === b) return 0;
    return a < b ? -1 : 1;
}

export function reverse(sortFn: (a: any, b: any) => number) {
    return (a: any, b: any) => {
        return sortFn(a, b) * -1;
    };
}

/*
Example:
const toSort = ['Daniel','Herbert','Peter','Angelika'];
const sorted = toSort.sort(clusterBy(a=>a==='Herbert',a=>a==='Peter'));
console.log(sorted);
//Results in Herbert,Peter,Daniel,Angelika
*/
export function clusterBy(...discriminators: ((val: any) => boolean)[]) {
    return (a: any, b: any) => {
        let indexOfA = discriminators.findIndex((discriminiator) => discriminiator(a));
        if (indexOfA === -1) indexOfA = Number.MAX_VALUE;
        let indexOfB = discriminators.findIndex((discriminiator) => discriminiator(b));
        if (indexOfB === -1) indexOfB = Number.MAX_VALUE;

        return indexOfA - indexOfB;
    };
}

export function sortObjectArrayByKey(array: any, key: any) {
    return array.sort(function (a: any, b: any) {
        const x = a[key];
        const y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
    });
}

// export function groupBy(array: any[], keys: (string | null)[]): any[] {
//     const nullGroup: any[] = [];
//     const otherGroups: Record<string, any[]> = {};

//     array.forEach((item) => {
//         let selectedKey = null;
//         for (const key of keys) {
//             if (item[key] !== undefined && item[key] !== null) {
//                 selectedKey = key;
//                 break;
//             }
//         }
//         if (selectedKey) {
//             const keyValue = String(item[selectedKey]);
//             if (!otherGroups[keyValue]) {
//                 otherGroups[keyValue] = [];
//             }
//             otherGroups[keyValue].push(item);
//         } else {
//             nullGroup.push(item);
//         }
//     });

//     return [...nullGroup, ...Object.values(otherGroups).flat()];
// }
