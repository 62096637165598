import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { getNameFromPerson } from 'src/app/core/utils/common';
import { CommentDto, CreateCommentDto, Person, TicketsService, UpdateCommentDto } from 'src/app/generated-sources/base';

@Component({
    selector: 'app-ticket-comments',
    templateUrl: './ticket-comments.component.html',
    styleUrls: ['./ticket-comments.component.scss'],
})
export class TicketCommentsComponent implements OnChanges, OnInit {
    public areFilesFullyUploaded = true;
    public fileUuids: string[] = [];
    public comments: CommentDto[] = [];
    public commentToEdit?: CommentDto;
    public isLoading = false;
    private unsubscribe$ = new Subject<void>();
    public description = new UntypedFormControl('');
    public descriptionTicketToEdit = new UntypedFormControl('');
    public userId = '';

    @Input() public commentsOriginalArray?: CommentDto[] = [];
    @Input() public ticketId = '';
    @Output() public refreshTicketPage = new EventEmitter<void>();

    public constructor(
        private toastService: ToastService,
        private ticketService: TicketsService,
        private authService: AuthService
    ) {}

    public ngOnInit(): void {
        this.userId = this.authService.getStorage().scalaraUser?.personId ?? '';
    }

    public ngOnChanges(): void {
        this.comments = this.commentsOriginalArray?.slice(0, 8) || [];
    }

    public updateLoadingFilesStatus($event: boolean): void {
        this.areFilesFullyUploaded = $event;
    }

    public updateFileIdsLodaded($event: string[]): void {
        if ($event.length === 0) {
            this.areFilesFullyUploaded = true;
        }
        this.fileUuids = $event;
    }

    public showAllComments(): void {
        this.comments = this.commentsOriginalArray || [];
    }

    public deleteComment(commentId: string): void {
        this.isLoading = true;
        this.ticketService
            .deleteComment(commentId, this.ticketId)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: () => {
                    this.toastService.showSuccess('Kommentar erfolgreich gelöscht');
                    this.refreshTicketPage.emit();
                    this.isLoading = false;
                },
                error: (error) => {
                    if (error) {
                        this.toastService.showError(error.error['message']);
                        this.isLoading = false;
                    }
                },
            });
    }

    public editComment(comment: CommentDto): void {
        this.commentToEdit = comment;
        this.descriptionTicketToEdit.patchValue(comment.description);
    }

    public saveComment(): void {
        this.isLoading = true;
        const updateCommentDto: UpdateCommentDto = {
            description: this.descriptionTicketToEdit.value,
        };

        this.ticketService
            .updateComment(this.commentToEdit?.id || '', this.ticketId, updateCommentDto)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: () => {
                    this.toastService.showSuccess('Kommentar erfolgreich bearbeitet');
                    this.commentToEdit = undefined;
                    this.refreshTicketPage.emit();
                    this.isLoading = false;
                },
                error: (error) => {
                    if (error) {
                        this.toastService.showError(error.error['message']);
                        this.isLoading = false;
                    }
                },
            });
    }

    public onSubmit(): void {
        this.isLoading = true;
        const createCommentDto: CreateCommentDto = {
            description: this.description.value,
            fileStorageIds: this.fileUuids,
        };

        this.ticketService
            .createComment(this.ticketId, createCommentDto)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: () => {
                    this.toastService.showSuccess('Kommentar erfolgreich erstellt');
                    this.refreshTicketPage.emit();
                    this.description.patchValue('');
                    this.isLoading = false;
                },
                error: (error) => {
                    if (error) {
                        this.toastService.showError(error.error['message']);
                        this.isLoading = false;
                    }
                },
            });
    }

    public cancelEditComment(): void {
        this.commentToEdit = undefined;
    }

    public havePermission(permission: CommentDto.PermissionsEnum): boolean {
        return permission.includes('EDIT');
    }

    public getNameFromPerson(person: Person): string {
        return getNameFromPerson(person);
    }

    public isOwner(ownerId: string): boolean {
        return this.userId === ownerId;
    }
}
