import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { showEffectiveYear } from 'src/app/core/utils/common';
import { AccountBookingDto, BookingDto } from 'src/app/generated-sources/accounting';
import { EurocentPipe } from 'src/app/shared/pipes/eurocent.pipe';
import { CellTemplate } from 'src/app/shared/table/enums/cell-template';
import { HeaderTemplate } from 'src/app/shared/table/enums/header-template';
import { TableItem } from 'src/app/shared/table/interfaces/table-item';
import { TableModel } from 'src/app/shared/table/interfaces/table-model';
import { LedgerCustomService } from '../../../services/ledger-custom.service';

@Component({
    selector: 'app-booking-table',
    templateUrl: './booking-table.component.html',
    styleUrls: ['./booking-table.component.scss'],
})
export class BookingTableComponent implements OnInit, OnChanges, OnDestroy {
    @Input() public bookings = [] as BookingDto[] | AccountBookingDto[] | undefined;
    @Input() public showReceiptColumn: boolean | null = null;
    @Input() public showOpenItemAmount: boolean | null = null;
    @Input() public searchId?: string;
    @Input() public isLoading = false;
    @Input() public showBookingsFilter = false;
    @Input() public isAccountBookingDto = false;
    @Input() public accountBalance?: number;

    public ledgerId: undefined | string;

    private unsubscribe$ = new Subject<void>();

    public tableModel: TableModel = { data: [], header: [] };

    public constructor(private ledgerCustomService: LedgerCustomService, private eurocentPipe: EurocentPipe) {}

    public ngOnInit(): void {
        this.ledgerCustomService
            .getLedgerId$()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((ledgerId) => {
                this.ledgerId = ledgerId ?? '';
                this.updateTableData();
            });
    }

    public ngOnChanges(): void {
        this.updateTableData();
    }

    public updateTableData(): void {
        if (this.bookings) {
            const bookingWithVat = this.bookings?.find((booking) => booking.vatRate && booking.vatAmount);

            this.tableModel.data = this.bookings.map((booking) => this.createRow(booking, !!bookingWithVat));
            this.initTableHeaders(!!bookingWithVat);
        }
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private initTableHeaders(showVatColumn: boolean): void {
        this.tableModel.header = [
            {
                data: { key: '', params: {} },
                width: '24px',
            },
            ...(!this.isAccountBookingDto ? ['PAGES.BOOKINGS.BOOKINGS_OVERVIEW.ACCOUNT'] : []),
            'PAGES.BOOKINGS.BOOKINGS_OVERVIEW.DESCRIPTION',
            'PAGES.BOOKINGS.BOOKINGS_OVERVIEW.BOOKING_DATE',
            'ENTITIES.BOOKING.LABEL_EFFECTIVE_YEAR',
            ...(this.showReceiptColumn ? [''] : []),
            {
                data: { key: 'ENTITIES.BOOKING.LABEL_LABOUR_AMOUNT', params: {} },
                width: '140px',
                template: HeaderTemplate.RightAligned,
            },
            ...(showVatColumn
                ? [
                      {
                          data: { key: 'ENTITIES.BOOKING.LABEL_TAX_SHARE', params: {} },
                          width: '124px',
                          template: HeaderTemplate.RightAligned,
                      },
                  ]
                : []),
            ...(this.showOpenItemAmount
                ? [
                      {
                          data: { key: 'ENTITIES.BOOKING.LABEL_OPEN_ITEM_AMOPUNT', params: {} },
                          width: '124px',
                          template: HeaderTemplate.RightAligned,
                      },
                  ]
                : []),
            {
                data: { key: 'PAGES.BOOKINGS.BOOKINGS_OVERVIEW.AMOUNT', params: {} },
                width: '140px',
                template: HeaderTemplate.RightAligned,
            },
            ...(this.isAccountBookingDto
                ? [
                      {
                          data: { key: 'PAGES.ACCOUNTS.ACCOUNT_DETAILS.BALANCE', params: {} },
                          width: '124px',
                          template: HeaderTemplate.RightAligned,
                      },
                  ]
                : []),
        ];
    }

    public getBadgeColor(
        category: BookingDto.CategoryEnum | undefined,
        type: BookingDto.TypeEnum | undefined,
        isCancelled: boolean | undefined
    ): 'green' | 'orange' | 'red' | undefined {
        if (category === BookingDto.CategoryEnum.Expense && !isCancelled) {
            return 'orange';
        }

        if (type === 'CANCELLATION' || isCancelled) {
            return 'red';
        }
        if (category === BookingDto.CategoryEnum.Revenue) {
            return 'green';
        }

        return undefined;
    }

    private createRow(booking: BookingDto | AccountBookingDto, showVatColumn: boolean): TableItem[] {
        const ledgerId = booking.ledgerId ?? this.ledgerId;
        const link = `/accounting/ledger/${ledgerId}/bookings/${booking.id}`;
        const account = `${booking.accountName ?? '-'} ${booking.accountDescription ?? ''}`;

        return [
            {
                data: {
                    label: '',
                    link,
                    textColor: this.getBadgeColor(booking.category, booking.type, booking.isCancelled),
                    rightAligned: true,
                },
                template: this.getBadgeColor(booking.category, booking.type, booking.isCancelled)
                    ? CellTemplate.badge
                    : CellTemplate.Default,
            },
            ...(!this.isAccountBookingDto ? [{ data: { label: account, link }, template: CellTemplate.Default }] : []),
            {
                data: {
                    label: booking.description,
                    link,
                },
                template: booking.isCancelled ? CellTemplate.CancelledBooking : CellTemplate.Default,
            },
            {
                data: {
                    label: booking.bookingDate,
                    link,
                },
                template: CellTemplate.Date,
            },
            {
                data: {
                    label: showEffectiveYear(booking.effectiveFrom, booking.effectiveTo),
                    link,
                },
                template: CellTemplate.Default,
            },
            ...(this.showReceiptColumn
                ? [
                      {
                          data: { label: booking.hasConnectedReceipts ? 'linked' : '', link },
                          template: CellTemplate.isSimpleLinkedItem,
                      },
                  ]
                : []),
            {
                data: {
                    label: booking.labourAmount ? this.eurocentPipe.transform(booking.labourAmount) : '',
                    link,
                    rightAligned: true,
                },
                template: CellTemplate.Default,
            },
            ...(showVatColumn
                ? [
                      {
                          data: {
                              label:
                                  booking.vatRate && booking.vatAmount
                                      ? `${this.eurocentPipe.transform(booking.vatAmount)}€ (${booking.vatRate}%)`
                                      : '',
                              link,
                              rightAligned: true,
                          },
                          template: CellTemplate.Default,
                      },
                  ]
                : []),
            ...(this.showOpenItemAmount
                ? [
                      {
                          data: {
                              label: booking.openItemAmount ?? '',
                              link,
                          },
                          template: CellTemplate.EuroCent,
                      },
                  ]
                : []),
            {
                data: {
                    label: booking.amount,
                    link,
                },
                template: CellTemplate.EuroCent,
            },
            ...(this.isAccountBookingDto
                ? [
                      {
                          data: {
                              label: (booking as AccountBookingDto).balance,
                              link,
                          },
                          template: CellTemplate.EuroCent,
                      },
                  ]
                : []),
        ];
    }
}
