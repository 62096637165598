import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { TechnicalEquipmentDto, TechnicalEquipmentService } from 'src/app/generated-sources/base';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';

@Component({
    selector: 'app-delete-technical-equipment',
    templateUrl: './delete-technical-equipment.component.html',
    styleUrls: ['./delete-technical-equipment.component.scss'],
})
export class DeleteTechnicalEquipmentComponent extends OverlayChildComponent implements OnInit, OnDestroy {
    public technicalEquipmentToDelete?: TechnicalEquipmentDto;
    public propertyId = '';
    public buildingId = '';

    public unsubscribe$ = new Subject<void>();

    public constructor(
        private technicalEquipmentService: TechnicalEquipmentService,
        private toastService: ToastService,
        private translateService: TranslateService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.technicalEquipmentToDelete = this.config?.data.technicalEquipment;
        this.propertyId = this.config?.data.propertyId;
        this.buildingId = this.config?.data.buildingId;
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public submit(): void {
        if (!this.propertyId || !this.buildingId || !this.technicalEquipmentToDelete) {
            return;
        }
        this.technicalEquipmentService
            .deleteBuildingTechnicalEquipment(this.propertyId, this.buildingId, this.technicalEquipmentToDelete?.id)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: () => {
                    this.toastService.showSuccess(
                        this.translateService.instant('PAGES.DELETE_TECHNICAL_EQUIPMENT.SUCCESS_TOAST')
                    );
                    this.saveEmitter$.next();
                },
                error: (error) => {
                    if (error) {
                        this.toastService.showError(error.error['message']);
                    }
                },
            });
    }
}
