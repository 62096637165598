<div class="bank-account-item" *ngIf="vm">
    <div class="bank-account-item__rows">
        <div class="tw-my-6">
            <div *ngFor="let row of vm?.contactDetailRows">
                <div contactDetailRow [row]="row"></div>
            </div>
        </div>
    </div>
    <div class="bank-account-item__status">
        <div
            *ngIf="this.bankAccount?.isAttachedToBookings"
            class="tw-flex tw-whitespace-nowrap lg:tw-pt-6 tw-s-label-12-22-semibold tw-text-scalaraGreen-01"
        >
            <svg-icon
                class="tw-flex tw-justify-center tw-items-center tw-fill-scalaraGreen-01 tw-w-6 tw-h-6"
                src="/assets/icons/24_closed.svg"
            ></svg-icon>
            <span class="tw-pl-2">{{ 'PAGES.CONTACT.DETAIL_VIEW.BANK_ACCOUNT_CONNECTED' | translate }}</span>
        </div>
    </div>
    <div class="bank-account-item__delete-button tw-flex tw-justify-end">
        <app-scalara-button
            variant="ghost"
            svgName="24_delete.svg"
            (click)="onDeleteClick(vm.bankAccount)"
            *ngIf="showDeleteButton"
        >
        </app-scalara-button>
    </div>
</div>
<div class="tw-w-full tw-h-px tw-bg-scalaraGray-05 tw-mt-2 lg:tw-mt-3"></div>
