import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-card-selection',
    templateUrl: './card-selection.component.html',
    styleUrls: ['./card-selection.component.scss'],
})
export class CardSelectionComponent {
    @Input() public selected = false;
    @Input() public label = '';
    @Input() public iconSrc = '';
    @Input() public iconColor: 'gray' | 'blue' | 'orange' | 'green' | 'purple' = 'gray';
    @Input() public minHeight?: 'tw-min-h-[204px]' | 'tw-min-h-[182px]';
    @Input() public iconColorNotSelectedItem: 'gray' | 'blue' | 'orange' | 'green' | 'purple' = 'gray';
}
