<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<app-overlay-container
    [headlineTitle]="
        isEndOccupation ? 'PAGES.OCCUPATION.END.HEADLINE' : 'PAGES.OCCUPATION.EDIT.EDIT_OCCUPATION_PERIOD_HEADLINE'
    "
    containerSize="small"
>
    <div *ngIf="isEndOccupation" class="s-head-16-24-bold mb-2r">
        {{ 'PAGES.OCCUPATION.END.TITLE' | translate }}
    </div>

    <div class="tw-flex tw-justify-between tw-space-x-8">
        <div class="tw-w-full">
            <ibm-date-picker
                label="{{ 'COMMON.LABEL_BEGIN' | translate }}*"
                id="start"
                [(ngModel)]="_startDateArray"
                [placeholder]="'tt.mm.jjjj'"
                [language]="'de'"
                [dateFormat]="'d.m.Y'"
                [invalid]="!startDate"
                invalidText="{{ 'CONTROLS.FORMCONTROLS.GENERAL.REQUIRED' | translate }}"
            >
            </ibm-date-picker>
        </div>
        <div class="tw-w-full">
            <ibm-date-picker
                label="{{ 'COMMON.LABEL_END' | translate }}"
                id="end"
                [(ngModel)]="_endDateArray"
                [placeholder]="'tt.mm.jjjj'"
                [language]="'de'"
                [dateFormat]="'d.m.Y'"
                [invalid]="!isDateInputValid()"
                invalidText="Kein gültiges Datum"
            >
            </ibm-date-picker>
        </div>
    </div>
    <div *ngIf="showOccupationDurationWarning" class="tw-my-10">
        <app-notification type="error" class="w-100">
            <div class="s-head-14-22-regular tw-whitespace-break-spaces">
                <span>{{ 'PAGES.OCCUPATION.EDIT.EDIT_OCCUPATION_PERIOD_WARNING' | translate }}</span>
            </div>
        </app-notification>
    </div>

    <div class="tw-flex tw-justify-between tw-mt-[120px]">
        <app-scalara-button (click)="abort()" variant="outline">{{
            'CONTROLS.BUTTONS.CANCEL' | translate
        }}</app-scalara-button>
        <app-scalara-button (click)="submit()" [disabled]="!isDateInputValid() || !startDate">{{
            'CONTROLS.BUTTONS.SAVE' | translate
        }}</app-scalara-button>
    </div>
</app-overlay-container>
