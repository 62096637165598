import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil, tap } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { isDateInputOverlappingOtherPlans } from 'src/app/core/utils/common';
import {
    ChangeStatusDto,
    EconomicPlanDto,
    WegSettlementStatementsService,
    WssDto,
} from 'src/app/generated-sources/accounting';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';

@Component({
    selector: 'app-activate-annual-statement',
    templateUrl: './activate-annual-statement.component.html',
    styleUrls: ['./activate-annual-statement.component.scss'],
})
export class ActivateAnnualStatementComponent extends OverlayChildComponent implements OnDestroy, OnInit {
    private unsubscribe$ = new Subject<void>();

    public wssId = '';
    public isOverlapping = false;
    public ledgerId = '';
    public economicPlanId = '';
    public annualStatement?: WssDto;
    public annualStatements: WssDto[] = [];

    public constructor(
        private toastService: ToastService,
        private annualStatementsService: WegSettlementStatementsService,
        private translateService: TranslateService,
        private router: Router
    ) {
        super();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public ngOnInit(): void {
        this.annualStatement = this.config?.data?.annualStatement;
        this.wssId = this.config?.data?.annualStatement?.id;
        this.ledgerId = this.config?.data?.ledgerId;
        this.economicPlanId = this.config?.data?.annualStatement?.economicPlanId;
        this.annualStatementsService
            .findAll(this.ledgerId)
            .pipe(
                tap((annualStatements: WssDto[]) => {
                    this.annualStatements = annualStatements;
                    this.isOverlapping = this.isOverlap();
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }

    public isOverlap(): boolean {
        if (this.annualStatement?.economicPlan) {
            const startDate = new Date(this.annualStatement?.economicPlan.startDate);
            const endDate = new Date(this.annualStatement?.economicPlan.startDate);
            const economicPlans: EconomicPlanDto[] = [];
            this.annualStatements.map((annualStatement: WssDto) => {
                economicPlans.push(annualStatement.economicPlan);
            });

            return isDateInputOverlappingOtherPlans(startDate, endDate, economicPlans);
        } else {
            return false;
        }
    }

    public onSubmit(): void {
        const changeStatusDto: ChangeStatusDto = {
            status: ChangeStatusDto.StatusEnum.Active,
        };

        this.annualStatementsService
            .changeStatus(this.ledgerId, this.wssId, changeStatusDto)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: () => {
                    this.toastService.showSuccess(
                        this.translateService.instant('PAGES.ANNUAL_STATEMENT.ACTIVATE.TOAST_SUCCESS_MESSAGE')
                    );
                    this.saveEmitter$.next();
                    this.router.navigate(['/accounting', 'ledger', this.ledgerId, 'annual-statements']);
                },
                error: (error) => {
                    if (error) {
                        this.toastService.showError(error.error['message']);
                    }
                },
            });
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }
}
