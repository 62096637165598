<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<div ibmRow class="mb-6-25r">
    <div ibmCol [columnNumbers]="{ md: 4, lg: 2 }" class="s-head-28-36-bold">
        {{ 'ENTITIES.ANNUAL_STATEMENT.LABEL_ENTITY' | translate }}
    </div>
    <div ibmCol class="d-flex justify-content-end">
        <app-scalara-button
            *ngIf="isPropertyManager"
            variant="ghost"
            (click)="startAnnualStatementCreation()"
            svgName="24_add.svg"
        >
            {{ 'PAGES.ANNUAL_STATEMENT.OVERVIEW.START_CREATION' | translate }}
        </app-scalara-button>
    </div>
</div>

<div ibmRow *ngIf="annualStatements.length === 0 && !isLoading" class="empty d-flex align-items-center py-3-5r px-2-5r">
    <svg-icon src="/assets/icons/24_information.svg"></svg-icon>
    <div class="s-body-14-22-regular ml-0-5r">{{ 'PAGES.ANNUAL_STATEMENT.OVERVIEW.EMPTY' | translate }}</div>
</div>

<ng-container *ngIf="annualStatements.length > 0">
    <ng-container *ngFor="let annualStatement of annualStatements; let i = index">
        <app-tooltip
            size="m"
            position="top"
            *ngIf="annualStatement.processingStatus !== 'READY'"
            class="tooltip--white tile__icon expandable-tile-selector__tooltip"
        >
            <div content class="tooltip-box">
                {{ 'PAGES.ANNUAL_STATEMENT.OVERVIEW.ACTIVATION_INFO' | translate }}
            </div>
            <div hoverable-item>
                <div ibmRow class="tile tile--shadow tile--not-allowed mb-1-5r">
                    <div class="w-100 py-0-5r pr-1-5r text-right label--purple s-head-14-22-regular">
                        {{ 'PAGES.ANNUAL_STATEMENT.OVERVIEW.PLAN_IN_ACTIVATION' | translate }}
                    </div>
                    <div class="tile__header px-2-5r">
                        <app-icon-bubble
                            [size]="72"
                            [color]="'purple'"
                            [image]="'40_document_loading.svg'"
                            class="mr-2r"
                        ></app-icon-bubble>
                        <div class="headline-wrapper_title">
                            <div class="s-gray-03 s-label-12-22-semibold">
                                {{ 'ENTITIES.ANNUAL_STATEMENT.LABEL_YEAR' | translate }}
                            </div>
                            <div class="s-head-16-24-bold">
                                {{ annualStatement.economicPlan.startDate | date : 'dd.MM.yyyy' }} –
                                {{ annualStatement.economicPlan.endDate | date : 'dd.MM.yyyy' }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </app-tooltip>
        <div
            ibmRow
            *ngIf="
                annualStatement.processingStatus === 'READY' &&
                (annualStatement.status === 'IN_REVIEW' ||
                    annualStatement.status === 'ACTIVE' ||
                    (annualStatement.status === 'DRAFT' && isPropertyManager))
            "
            class="tile tile--shadow mb-1-5r"
            [routerLink]="'/accounting/ledger/' + ledgerId + '/annual-statements/' + annualStatement.id"
        >
            <div
                *ngIf="annualStatement.status === 'DRAFT'"
                class="w-100 py-0-5r pr-1-5r text-right label--blue s-head-14-22-regular"
            >
                {{ 'PAGES.ANNUAL_STATEMENT.OVERVIEW.PLAN_IN_CREATION' | translate }}
            </div>
            <div
                *ngIf="annualStatement.status === 'IN_REVIEW'"
                class="w-100 py-0-5r pr-1-5r text-right label--orange s-head-14-22-regular"
            >
                {{ 'PAGES.ANNUAL_STATEMENT.OVERVIEW.PLAN_IN_REVIEW' | translate }}
            </div>
            <div class="tile__header px-2-5r">
                <app-icon-bubble
                    *ngIf="annualStatement.status === 'DRAFT'"
                    [size]="72"
                    [color]="'blue'"
                    [image]="'40_dokument.svg'"
                    class="mr-2r"
                ></app-icon-bubble>
                <app-icon-bubble
                    *ngIf="annualStatement.status === 'IN_REVIEW'"
                    [size]="72"
                    [color]="'orange'"
                    [image]="'40_document_inspect.svg'"
                    class="mr-2r"
                ></app-icon-bubble>
                <app-icon-bubble
                    *ngIf="annualStatement.status === 'ACTIVE'"
                    [size]="72"
                    [color]="'green'"
                    [image]="'40_dokument_check.svg'"
                    class="mr-2r"
                ></app-icon-bubble>
                <div class="headline-wrapper_title">
                    <div class="s-gray-03 s-label-12-22-semibold">
                        {{ 'ENTITIES.ANNUAL_STATEMENT.LABEL_YEAR' | translate }}
                    </div>
                    <div class="s-head-16-24-bold">
                        {{ annualStatement.economicPlan.startDate | date : 'dd.MM.yyyy' }} –
                        {{ annualStatement.economicPlan.endDate | date : 'dd.MM.yyyy' }}

                        <app-tag *ngIf="annualStatement.status === 'ACTIVE'" class="ml-2r" color="green">
                            {{ 'PAGES.ANNUAL_STATEMENT.OVERVIEW.ACTIVE_TAG' | translate }}
                        </app-tag>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>
<div class="mb-10r"></div>
