import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { isEqual } from 'lodash';
import { distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { WssPdfGenerationConfigDto } from 'src/app/generated-sources/accounting';
import { AccountingFilterCustomService } from '../../accounting/components/services/accounting-filter-custom.service';

@Component({
    selector: 'app-pdf-print-config-seletion',
    templateUrl: './pdf-print-config-seletion.component.html',
    styleUrls: ['./pdf-print-config-seletion.component.scss'],
})
export class PdfPrintConfigSeletionComponent implements OnInit, OnDestroy {
    public wssPdfConfig$ = this.accountingFilterCustomService.getPdfPrintingConfig$();

    public form: FormGroup = this.formBuilder.group({
        allUnitsCheckbox: [false],
        homeRelatedCostsCheckBox: [false],
        reserveWithdrawelsCheckBox: [false],
        taxCheckBox: [false],
    });
    public unsubscribe$ = new Subject<void>();

    public constructor(
        private formBuilder: FormBuilder,
        private accountingFilterCustomService: AccountingFilterCustomService
    ) {}
    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public ngOnInit(): void {
        this.wssPdfConfig$.subscribe((config) => {
            this.form.patchValue({
                allUnitsCheckbox: config.genereatAllUnitsWssResults,
                homeRelatedCostsCheckBox: config.generateApportionableHomerelatedCost,
                reserveWithdrawelsCheckBox: config.generateReserveWithdrawels,
                taxCheckBox: config.generateTaxAndSocialSecurity,
            });
            console.log(config);
        });

        this.form.valueChanges
            .pipe(
                distinctUntilChanged((prev, curr) => {
                    console.log(prev, curr);
                    console.log(isEqual(prev, curr));
                    return isEqual(prev, curr);
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => {
                const config: WssPdfGenerationConfigDto = {
                    genereatAllUnitsWssResults: this.form.get('allUnitsCheckbox')?.value ?? false,
                    generateApportionableHomerelatedCost: this.form.get('homeRelatedCostsCheckBox')?.value ?? false,
                    generateReserveWithdrawels: this.form.get('reserveWithdrawelsCheckBox')?.value ?? false,
                    generateTaxAndSocialSecurity: this.form.get('taxCheckBox')?.value ?? false,
                };
                console.log(config);
                this.accountingFilterCustomService.setPdfPrintingConfig(config);
            });
    }
}
