import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbItem } from 'carbon-components-angular';
import { BehaviorSubject, Subject, combineLatest, of, switchMap, takeUntil, tap } from 'rxjs';
import { BreadCrumbService } from 'src/app/core/services/breadcrumb.service';
import { ToastService } from 'src/app/core/services/toast.service';
import {
    BankAccountDto,
    BankAccountsService,
    BankTransactionForListDto,
    BankTransactionsService,
} from 'src/app/generated-sources/accounting';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { CellTemplate } from 'src/app/shared/table/enums/cell-template';
import { HeaderTemplate } from 'src/app/shared/table/enums/header-template';
import { TableItem } from 'src/app/shared/table/interfaces/table-item';
import { TableModel } from 'src/app/shared/table/interfaces/table-model';
import {
    GetStatus,
    bankAccountTypeName,
    defaultSortTransaction,
    formatDateYYYYMMDDWithoutHours,
    getStatusTransaction,
} from '../../../../core/utils/common';
import { AddBankTransactionComponent } from '../add-bank-transaction/add-bank-transaction.component';
import { BankingTransactionsMarkBookedOverlayComponent } from '../banking/transactions/banking-transactions-mark-booked-overlay/banking-transactions-mark-booked-overlay.component';
import { AddBookingSelectionOverlayComponent } from '../bookings/components/add-booking-selection-overlay/add-booking-selection-overlay.component';
import { AccountingFilterCustomService } from '../services/accounting-filter-custom.service';
import { LedgerCustomService } from '../services/ledger-custom.service';

@Component({
    selector: 'app-bank-account-details',
    templateUrl: './bank-account-details.component.html',
    styleUrls: ['./bank-account-details.component.scss'],
})
export class BankAccountDetailsComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();
    private ledgerId = '';
    private bankAccountId = '';

    public breadcrumbs: BreadcrumbItem[] = [];

    public bankTransactions: BankTransactionForListDto[] = [];

    public tableModel: TableModel = { data: [], header: [] };

    public bankAccount?: BankAccountDto;
    public isLoading = false;
    public amountUnbookedTransaction = 0;

    public ledgerId$ = this.ledgerCustomService.getLedgerId$();
    public bankAccountId$ = new Subject<string>();
    public refresh$ = new BehaviorSubject(null);

    public datePickerSelectedBusinessYear$ = this.accountingFilterCustomService.getDatePickerSelectedDates$();

    public filterBankTransaction$ = combineLatest([
        this.datePickerSelectedBusinessYear$,
        this.ledgerId$,
        this.bankAccountId$,
        this.refresh$,
    ])
        .pipe(
            switchMap(([dates, ledgerId, bankAccountId, refresh]) => {
                if (!ledgerId || !bankAccountId) {
                    return of([]);
                }
                const startDate = formatDateYYYYMMDDWithoutHours(dates.startDate);
                const endDate = formatDateYYYYMMDDWithoutHours(dates.endDate);

                return this.bankTransactionsService.findAllBankTransactions(
                    this.bankAccountId,
                    this.ledgerId,
                    startDate,
                    endDate
                );
            }),
            tap((bankTransactions: BankTransactionForListDto[]) => {
                this.amountUnbookedTransaction = 0;
                const transactionsSorted = defaultSortTransaction(bankTransactions);
                this.tableModel.data = transactionsSorted.map((bankTransactionDto) => {
                    if (
                        Math.abs(bankTransactionDto.amount) -
                            Math.abs(bankTransactionDto.amountAssignedToBookings || 0) >
                        0
                    ) {
                        this.amountUnbookedTransaction++;
                    }
                    return this.createRow(bankTransactionDto);
                });
                this.bankTransactions = bankTransactions;
            }),
            takeUntil(this.unsubscribe$)
        )
        .subscribe({
            next: () => (this.isLoading = false),
            error: () => {
                this.isLoading = false;
                this.toastService.showError('Es ist ein Fehler aufgetreten.');
            },
        });

    public constructor(
        private translateService: TranslateService,
        private bankAccountsService: BankAccountsService,
        private bankTransactionsService: BankTransactionsService,
        private breadcrumbService: BreadCrumbService,
        private overlayService: OverlayService,
        private route: ActivatedRoute,
        private router: Router,
        private ledgerCustomService: LedgerCustomService,
        private toastService: ToastService,
        private accountingFilterCustomService: AccountingFilterCustomService
    ) {}

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public ngOnInit(): void {
        this.isLoading = true;
        this.initTableHeaders();
        this.getBankAccountDetailInfo();
    }

    private initTableHeaders(): void {
        this.tableModel.header = [
            'ACCOUNTING.BANK_TRANSACTION_DETAILS.STATUS',
            'ACCOUNTING.BANK_TRANSACTION_DETAILS.COUNTERPART_NAME',
            'ACCOUNTING.BANK_TRANSACTION_DETAILS.PURPOSE',
            'ACCOUNTING.BANK_TRANSACTION_DETAILS.VALUE_DATE',
            {
                data: { key: 'ACCOUNTING.BANK_TRANSACTION_DETAILS.TOTAL_AMOUNT', params: {} },
                template: HeaderTemplate.RightAligned,
            },
            {
                data: { key: 'ACCOUNTING.BANK_TRANSACTION_DETAILS.OPEN_AMOUNT', params: {} },
                template: HeaderTemplate.RightAligned,
            },
            '',
            '',
        ];
    }

    private getBankAccountDetailInfo(): void {
        combineLatest([this.route.parent!.paramMap, this.route.paramMap])
            .pipe(
                tap((params: ParamMap[]) => {
                    this.ledgerId = String(params[0].get('id'));
                    this.bankAccountId = String(params[1].get('bankAccountId'));
                    this.bankAccountId$.next(this.bankAccountId);
                }),
                switchMap(() => this.bankAccountsService.findOne(this.bankAccountId, this.ledgerId)),
                tap((bankAccount: BankAccountDto) => {
                    this.bankAccount = bankAccount;
                    this.ledgerId = bankAccount.ledgerIds.length > 0 ? bankAccount.ledgerIds[0] : '';
                    this.bankAccountId = bankAccount.id;
                    this.bankAccount.bic = bankAccount.bic || '–';
                    this.bankAccount.type = bankAccountTypeName(bankAccount);

                    this.breadcrumbs = [
                        {
                            content: this.translateService.instant('ACCOUNTING.COMMON.CASH_ACCOUNTS'),
                            route: [`/accounting/ledger/${this.ledgerId}/bank-accounts/`],
                        },
                        {
                            content: this.bankAccount!.iban,
                            route: [`/accounting/ledger/${this.ledgerId}/bank-accounts/${this.bankAccount!.id}`],
                            current: true,
                        },
                    ];
                    this.breadcrumbService.resetBreadCrumbs();
                    this.breadcrumbService.updateBreadCrumbs(this.breadcrumbs);
                    this.breadcrumbs = this.breadcrumbService.getCurrentBreadCrumbs();
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: () => (this.isLoading = false),
                error: () => {
                    this.isLoading = false;
                    this.toastService.showError('Es ist ein Fehler aufgetreten.');
                },
            });
    }

    public getRestAmount(amountAssignedToBookings: number, amount: number): number {
        return amount - ((amountAssignedToBookings ?? 0) * Math.abs(amount)) / amount;
    }

    public openAddBookingOverlay($event: any): void {
        const data = {
            ledgerId: this.ledgerId,
            bankTransactionId: $event.extraData.id,
        };
        const ref = this.overlayService.open(AddBookingSelectionOverlayComponent, {
            data,
        });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.refresh$.next(null));
    }

    public handlingEvent($event: any): void {
        if ($event.extraData && $event.extraData.eventName === 'markBankTransaction') {
            this.openMarkAsBookedOverlay($event);
        } else {
            this.openAddBookingOverlay($event);
        }
    }

    public openMarkAsBookedOverlay($event: any): void {
        const data = {
            bankTransaction: $event.extraData.bankTransaction,
        };
        const ref = this.overlayService.open(BankingTransactionsMarkBookedOverlayComponent, {
            data,
        });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.refresh$.next(null));
    }

    public openAddTransactionOverlay(): void {
        const ref = this.overlayService.open(AddBankTransactionComponent, {
            data: { bankAccountId: this.bankAccountId },
        });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.refresh$.next(null));
    }

    private createRow(transactionItem: BankTransactionForListDto): TableItem[] {
        const statusInfo: GetStatus = getStatusTransaction(
            Math.abs(transactionItem.amount),
            Math.abs(transactionItem.amountAssignedToBookings || 0),
            transactionItem.isFullyBooked
        );

        const isIgnored = statusInfo.status === 'Ignoriert';
        const link = `/accounting/ledger/${this.ledgerId}/bank-accounts/${transactionItem.bankAccountId}/bank-transactions/${transactionItem.id}`;
        const restAmount = this.getRestAmount(transactionItem.amountAssignedToBookings || 0, transactionItem.amount);

        let purpose = transactionItem.purpose?.replace(/[+-]/g, ' ') ?? '';
        purpose = purpose.length >= 150 ? purpose.substring(0, 150) + '...' : purpose;

        return [
            {
                data: {
                    label: statusInfo.status,
                    textColor: statusInfo.color,
                    iconSrc: statusInfo.iconSrc,
                    link,
                    extraData: { bankTransaction: transactionItem, enableCustomSorting: true },
                },
                template: CellTemplate.DynamicIcon,
            },
            {
                data: {
                    label: transactionItem.counterpartName,
                    textColor: isIgnored ? 's-gray-03' : 's-gray-01',
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: purpose,
                    textColor: isIgnored ? 's-gray-03' : 's-gray-01',
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: transactionItem.valueDate,
                    textColor: isIgnored ? 's-gray-03' : 's-gray-01',
                    link,
                },
                template: CellTemplate.Date,
            },
            {
                data: {
                    label: transactionItem.amount,
                    textColor: isIgnored ? 's-gray-03' : 's-gray-01',
                    link,
                },
                template: CellTemplate.EuroCent,
            },
            {
                data: {
                    label: restAmount,
                    textColor: isIgnored ? 's-gray-03' : 's-gray-01',
                    link,
                },
                template: CellTemplate.EuroCent,
            },
            ...[
                statusInfo.status === 'Teilverbucht' || statusInfo.status === 'Verbucht'
                    ? {
                          data: {
                              label: '',
                              link,
                          },
                          template: CellTemplate.Default,
                      }
                    : {
                          data: {
                              label: '',
                              textColor: 's-gray-02',
                              iconSrc: isIgnored ? '/assets/icons/24_add-cycle.svg' : '/assets/icons/24_ignor.svg',
                              extraData: {
                                  showOnlyWithHover: true,
                                  eventName: 'markBankTransaction',
                                  bankTransaction: transactionItem,
                              },
                          },
                          template: CellTemplate.iconButton,
                      },
            ],
            {
                data: {
                    label: restAmount === 0 || transactionItem.amount === 0 || isIgnored ? '' : 'Verbuchen',
                    extraData: transactionItem,
                    rightAligned: true,
                },
                template:
                    restAmount === 0 || transactionItem.amount === 0 || isIgnored
                        ? CellTemplate.Default
                        : CellTemplate.button,
            },
        ];
    }
}
