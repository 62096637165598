import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ObservedValueOf, Subject, catchError, combineLatest, map, of, switchMap, takeUntil, tap } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import {
    EconomicPlanDetailsDto,
    EconomicPlansService,
    OwnershipAdvancementAmountDto,
} from 'src/app/generated-sources/accounting';
import { FiletoView } from 'src/app/shared/components/file-viewer/file-viewer.component';
import { TableComponent } from 'src/app/shared/table/components/table/table.component';
import { CellTemplate } from 'src/app/shared/table/enums/cell-template';
import { HeaderTemplate } from 'src/app/shared/table/enums/header-template';
import { TableModel } from 'src/app/shared/table/interfaces/table-model';
import { TableItem } from '../../../../../../../shared/table/interfaces/table-item';

@Component({
    selector: 'app-weg-economic-plan-individual-plan-index',
    templateUrl: './weg-economic-plan-individual-plan-index.component.html',
    styleUrls: ['./weg-economic-plan-individual-plan-index.component.scss'],
})
export class WegEconomicPlanIndividualPlanIndexComponent implements OnInit, OnDestroy {
    public unsubscribe$ = new Subject<void>();
    public ownershipAdvancementsTable: TableModel = { data: [], header: [] };
    public isLoading = false;
    public fileToView?: FiletoView;
    public showModal = false;
    public ownershipAdvancementAmountDto: OwnershipAdvancementAmountDto[] = [];

    @Output() public handleIndividualEconomicPlanDetailPage = new EventEmitter();
    @Input() public rateInterval?: EconomicPlanDetailsDto.RateIntervalEnum;

    @ViewChild(TableComponent)
    public tableComponent!: TableComponent;

    public ledgerId$ = this.route.parent!.params.pipe(
        switchMap((params) => {
            const ledgerId = params['id'];
            return of(ledgerId);
        }),
        catchError(() => {
            this.isLoading = false;
            this.toastService.showError(this.translateService.instant('COMPONENTS.TOAST.TOAST_ERROR'));
            return of(null);
        }),
        takeUntil(this.unsubscribe$)
    );

    public economicPlanId$ = this.route.params.pipe(
        switchMap((params) => {
            const economicPlanId = params['economicPlanId'];
            return of(economicPlanId);
        }),
        takeUntil(this.unsubscribe$)
    );

    public vm$ = combineLatest([this.economicPlanId$, this.ledgerId$]).pipe(
        map(([economicPlanId, ledgerId]) => ({
            economicPlanId,
            ledgerId,
        }))
    );

    public vm: null | ObservedValueOf<typeof this.vm$> = null;

    public constructor(
        private economicPlansService: EconomicPlansService,
        private route: ActivatedRoute,
        private toastService: ToastService,
        private translateService: TranslateService
    ) {}

    public ngOnInit(): void {
        this.isLoading = true;

        this.ownershipAdvancements$.pipe(takeUntil(this.unsubscribe$)).subscribe((ownershipAdvancements) => {
            this.ownershipAdvancementAmountDto = ownershipAdvancements;
            const showDifferingAdvancementColumn = ownershipAdvancements.find(
                (item) => item.differingAdvancement !== undefined
            );
            this.initTableHeader(!!showDifferingAdvancementColumn);
            this.ownershipAdvancementsTable.data = ownershipAdvancements.map((ownershipAdvancement) =>
                this.createRowOwnershipAdvancementsTable(ownershipAdvancement, !!showDifferingAdvancementColumn)
            );
            this.isLoading = false;
        });

        this.vm$.pipe(takeUntil(this.unsubscribe$)).subscribe((vm) => {
            this.vm = vm;
        });
    }
    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public ownershipAdvancements$ = combineLatest([this.ledgerId$, this.economicPlanId$]).pipe(
        switchMap(([ledgerId, economicPlanId]) => {
            return this.economicPlansService.getOwnershipAdvancementAmounts(ledgerId, economicPlanId);
        }),
        takeUntil(this.unsubscribe$)
    );

    private initTableHeader(showDifferingAdvancementColumn = false): void {
        this.ownershipAdvancementsTable.header = [
            'ENTITIES.OWNERSHIP.LABEL_ENTITY',
            {
                data: {
                    key: 'PAGES.ECONOMIC_PLAN.INDIVIDUAL_ECONOMIC_PLAN_OVERVIEW.' + 'LABEL_' + this.rateInterval,
                    params: {},
                },
                template: HeaderTemplate.RightAligned,
            },
            {
                data: { key: 'PAGES.ECONOMIC_PLAN.INDIVIDUAL_ECONOMIC_PLAN_OVERVIEW.LABEL_PDF', params: {} },
                template: HeaderTemplate.RightAligned,
            },
        ];

        if (showDifferingAdvancementColumn) {
            this.ownershipAdvancementsTable.header.splice(2, 0, {
                data: {
                    key: 'PAGES.ECONOMIC_PLAN.INDIVIDUAL_ECONOMIC_PLAN_OVERVIEW.LABEL_ADJUSTED_AMOUNT',
                    params: {},
                },
                template: HeaderTemplate.RightAligned,
            });
        }
    }

    private createRowOwnershipAdvancementsTable(
        data: OwnershipAdvancementAmountDto,
        showDifferingAdvancementColumn = false
    ): TableItem[] {
        const row: TableItem[] = [
            {
                data: {
                    label: data.ownershipName || '',
                    /* ATTENTION: DO NOT REMOVE THIS INFORMATION, IN THIS POSITION OF THE ARRAY,
                    BECAUSE IT IS BEING USED IN A FUNCTION (this.ownershipAdvancementsTable.data[event][2].data.extraData) */
                    extraData: { ...data },
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: data.calculatedAdvancement || 0,
                },
                template: CellTemplate.EuroCent,
            },
            {
                data: {
                    label: '',
                    extraData: { ...data },
                },
                template: CellTemplate.filesActions,
            },
        ];

        if (showDifferingAdvancementColumn) {
            row.splice(
                2,
                0,
                data.differingAdvancement !== null && data.differingAdvancement !== undefined
                    ? {
                          data: {
                              label: data.differingAdvancement || 0,
                          },
                          template: CellTemplate.EuroCent,
                      }
                    : {
                          data: {
                              label: '–',
                              rightAligned: true,
                          },
                          template: CellTemplate.Default,
                      }
            );
        }

        return row;
    }

    public closeModal(): void {
        this.showModal = false;
    }

    public handleOwnershipAdvancementsFile(event: any): void {
        if (!this.vm?.ledgerId || !this.vm?.economicPlanId) {
            return;
        }
        this.isLoading = true;

        this.economicPlansService
            .getDownloadLinkForOwnershipEconomicPlanPDF(
                this.vm?.ledgerId,
                this.vm?.economicPlanId,
                event.extraData.ownershipId
            )
            .pipe(
                tap((data: any) => {
                    const fileName = data.fileName.replace('_pdf', '.pdf');
                    if (event.isDownload) {
                        const link = document.createElement('a');
                        link.href = data.url;
                        link.download = fileName;
                        link.click();
                    } else {
                        this.fileToView = { fileName: fileName, file: data.url };
                        this.showModal = true;
                    }
                }),
                catchError(() => {
                    this.toastService.showError(this.translateService.instant('COMPONENTS.TOAST.TOAST_ERROR'));
                    return of(null);
                }),
                tap(() => (this.isLoading = false))
            )
            .subscribe();
    }

    public openIndividualEconomicPlanTab(event: any): void {
        const clickedRow = this.tableComponent.model.row(event);
        this.handleIndividualEconomicPlanDetailPage.emit(clickedRow[0].data.extraData);
    }

    //  get pdfs as one merged into single pdf or separated pdfs as zip
    public downloadAllPdfs(flow: 'pdf' | 'zip'): void {
        if (!this.vm?.ledgerId || !this.vm?.economicPlanId) {
            return;
        }

        this.isLoading = true;
        const method =
            flow === 'pdf'
                ? this.economicPlansService.getDownloadLinkForCombinedEconomicPlanPDF
                : this.economicPlansService.getAllPdfInZip;
        method
            .bind(this.economicPlansService)(this.vm?.ledgerId, this.vm?.economicPlanId)
            .pipe(
                catchError(() => {
                    this.toastService.showError(this.translateService.instant('COMPONENTS.TOAST.TOAST_ERROR'));
                    return of(null);
                }),
                takeUntil(this.unsubscribe$),
                tap(() => (this.isLoading = false))
            )
            .subscribe((data: any) => {
                const link = document.createElement('a');
                link.href = data.url;
                link.click();
            });
    }
}
