<ibm-label invalidText="{{ 'ERROR.REQUIRED' | translate }}">
    {{ label }}
    <button
        class="tw-overflow-x-auto tw-mt-3 focus-within:tw-outline-offset-[-2px] focus-within:tw-outline focus-within:tw-outline-2 focus-within:tw-outline-scalaraGreen-01 tw-border-none tw-w-full tw-bg-scalaraGray-06 tw-flex tw-h-10 tw-items-center tw-px-4 focus:tw-outline-offset-[-2px] focus:tw-outline focus:tw-outline-2 focus:tw-outline-scalaraGreen-01 tw-cursor-text"
    >
        <div class="tw-flex tw-space-x-1" [ngClass]="{ 'tw-mr-2': items.length > 0 }">
            <div
                *ngFor="let item of items; let i"
                class="tw-bg-scalaraGray-05 tw-px-3 tw-py-1 tw-flex tw-justify-between tw-items-center tw-rounded-sm"
            >
                <div class="tw-mr-1 tw-s-label-12-16-regular tw-whitespace-nowrap">{{ item }}</div>
                <div class="tw-fill-scalaraGray-01 tw-w-4 tw-h-4 tw-cursor-pointer" (click)="removeItem(item)">
                    <svg-icon src="/assets/icons/24_close.svg"></svg-icon>
                </div>
            </div>
        </div>

        <input
            class="tw-w-full tw-border-none tw-bg-transparent focus-visible:tw-outline-none"
            name="location"
            id="location"
            (keydown.enter)="onInputChange()"
            (blur)="onInputChange()"
            [(ngModel)]="inputControl"
        />
    </button>
</ibm-label>
