<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>
<app-overlay-container
    headlineTitle="PAGES.OCCUPATION.EDIT_RENT_PAYMENT.HEADLINE"
    [sizeIcon]="72"
    image="40_edit.svg"
    colorIcon="green"
    containerSize="small"
>
    <form [formGroup]="form">
        <ng-container formArrayName="rentIntervals">
            <ng-container *ngFor="let item of rentIntervalsControl.controls; let i = index" [formGroupName]="i">
                <ng-container *ngIf="i > 0">
                    <hr class="mt-2r" />
                    <div class="d-flex justify-content-end mb-0-5r">
                        <app-scalara-button
                            (click)="removeRentInterval(i)"
                            variant="ghost"
                            svgName="24_close.svg"
                            height="40px"
                        ></app-scalara-button>
                    </div>
                </ng-container>
                <div class="d-flex mb-1r">
                    <app-number-input
                        *ngIf="rentCalculationMethod !== 'BLANKET_RENT'"
                        [label]="'Kaltmiete' + (occupation?.occupationType === 'RENTING' ? '*' : '')"
                        invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                        formControlName="rent"
                        class="input-small mr-1r"
                    ></app-number-input>

                    <app-number-input
                        *ngIf="rentCalculationMethod === 'BLANKET_RENT'"
                        label="Pauschalmiete*"
                        invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                        formControlName="rent"
                        class="input-small mr-1r"
                    ></app-number-input>

                    <app-number-input
                        *ngIf="
                            ['BASE_RENT_TOTAL_PREPAID', 'BLANKET_RENT_CONSUMPTION'].includes(
                                rentCalculationMethod ?? ''
                            )
                        "
                        [label]="'Vorauszahlung' + (occupation?.occupationType === 'RENTING' ? '*' : '')"
                        invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                        formControlName="prepaidSum"
                        class="input-small mr-1r"
                    ></app-number-input>

                    <app-number-input
                        *ngIf="rentCalculationMethod === 'BASE_RENT_OPERATIONAL_COST_HEATING'"
                        [label]="'Betriebskosten' + (occupation?.occupationType === 'RENTING' ? '*' : '')"
                        invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                        formControlName="operationalCosts"
                        class="input-small mr-1r"
                    ></app-number-input>

                    <app-number-input
                        *ngIf="rentCalculationMethod === 'BASE_RENT_OPERATIONAL_COST_HEATING'"
                        [label]="'Heizkosten' + (occupation?.occupationType === 'RENTING' ? '*' : '')"
                        invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                        formControlName="heatingCosts"
                        class="input-small mr-1r"
                    ></app-number-input>

                    <ibm-date-picker
                        [label]="'Gültig ab' + (occupation?.occupationType === 'RENTING' ? '*' : '')"
                        [id]="'startingDate' + '-' + i"
                        [placeholder]="'tt.mm.jjjj'"
                        [size]="'md'"
                        [language]="'de'"
                        [dateFormat]="'d.m.Y'"
                        class="mr-1r"
                        invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                        formControlName="startingDate"
                    >
                    </ibm-date-picker>

                    <div class="total-amount-container">
                        <div class="s-label-12-22-semibold mb-1r s-gray-03">Gesamt</div>
                        <div class="s-body-14-22-bold">{{ getPrepaidSum(i) | eurocent }} €</div>
                    </div>
                </div>
            </ng-container>
        </ng-container>

        <div class="mb-2r">
            <app-scalara-button (click)="addRendInterval()" variant="ghost" svgName="24_add.svg"
                >Mietänderung hinzufügen</app-scalara-button
            >
        </div>

        <div *ngIf="showOccupationDurationWarning" class="tw-my-10">
            <app-notification type="error" class="w-100">
                <div class="s-head-14-22-regular tw-whitespace-break-spaces">
                    <span>{{ 'PAGES.OCCUPATION.EDIT.EDIT_OCCUPATION_PERIOD_WARNING' | translate }}</span>
                </div>
            </app-notification>
        </div>

        <div class="mt-7-5r d-flex justify-space-between">
            <app-scalara-button (click)="abort()" variant="outline">{{
                'CONTROLS.BUTTONS.CANCEL' | translate
            }}</app-scalara-button>
            <app-scalara-button (click)="submit()" class="mb-1r" type="submit">{{
                'CONTROLS.BUTTONS.SAVE' | translate
            }}</app-scalara-button>
        </div>
    </form>
</app-overlay-container>
