<div class="s-head-16-24-bold mb-1r">{{ typeSelected === 'RENTING' ? 'Mietverhältnis' : 'Personen' }}</div>
<div *ngFor="let tenant of persons; let index = index">
    <div class="d-flex justify-space-between px-1r pt-1r">
        <app-headline-with-icon
            image="24_person.svg"
            [text]="tenant.companyName || tenant.firstName + ' ' + tenant.lastName"
            color="{{ 'gray' }}"
            borderColor="gray"
            textWeight="regular"
            [userAvatar]="tenant?.imgSmall ?? undefined"
        ></app-headline-with-icon>

        <div class="d-flex">
            <app-scalara-button
                *ngIf="!tenant.isExistingPersons"
                svgName="24_edit.svg"
                variant="icon-only"
                height="40px"
                (click)="editPerson(tenant, index)"
            ></app-scalara-button>
            <app-scalara-button
                (click)="removePerson(tenant.id || '', !!tenant.isExistingPersons)"
                svgName="24_close.svg"
                variant="icon-only"
                height="40px"
            ></app-scalara-button>
        </div>
    </div>
    <hr class="mt-0-75r" />
</div>

<div class="mt-1-5r">
    <app-scalara-button (click)="addNewPerson()" variant="ghost" svgName="24_add.svg"
        >{{ typeSelected === 'OWN_OCCUPATION' ? 'Weitere Person' : 'Weiteren Mieter hinzufügen' }}
    </app-scalara-button>
</div>

<div class="s-head-16-24-bold mb-0-5r mt-4-5r">
    {{ 'PAGES.OCCUPATION.ADD_OCCUPATION_FORM.LABEL_TOTAL_NUMBER_OF_PERSONS' | translate }}
</div>
<div class="s-label-12-16-regular s-gray-03 mb-1r">
    {{ 'PAGES.OCCUPATION.ADD_OCCUPATION_FORM.LABEL_INFO_NUMBER_OF_PERSONS' | translate }}
</div>

<form [formGroup]="form!">
    <ibm-label
        invalidText="{{ (isInvalidNumber('numberOfPersons') ? 'ERROR.GENERAL' : 'ERROR.REQUIRED') | translate }}"
        class="test2 mb-4-5r"
        [invalid]="isInvalid('numberOfPersons') || isInvalidNumber('numberOfPersons')"
        >Anzahl*
        <input
            ibmText
            type="number"
            [invalid]="isInvalid('numberOfPersons') || isInvalidNumber('numberOfPersons')"
            name="numberOfPersons"
            id="numberOfPersons"
            formControlName="numberOfPersons"
        />
    </ibm-label>
</form>
