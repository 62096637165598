<div class="dashboard-box-shadow sales-and-bookings tw-px-8 tw-py-6 border-1-gray tw-mb-6">
    <span class="s-label-12-22-semibold tw-mb-4 tw-text-scalaraPurple-01 letter-spacing-1-16px tw-uppercase">
        {{ 'PAGES.CONTACT.ACCOUNTING_DASHBOARD.LABEL_OPEN_ITEMS_OF_THE_UNITS_DUE' | translate }}
    </span>

    <div class="tw-mb-10">
        <div
            *ngFor="let items of dashboardDueItems; let i = index"
            (click)="setAdvancementsVisibility(i)"
            [ngClass]="{ 'tw-pointer-events-none': items.dueIitem.length === 0 }"
            class="tw-s-body-14-22-regular tw-flex tw-w-full tw-cursor-pointer tw-py-4 tw-border-b-[1px] tw-border-scalaraGray-05 tw-border-solid"
        >
            <div class="tw-flex tw-flex-col tw-w-full">
                <div class="tw-flex tw-justify-between">
                    <div>{{ items.title }}</div>
                    <div class="tw-s-body-14-22-bold">{{ items.total | eurocent }} €</div>
                </div>
                <ng-container *ngIf="items.isOpen">
                    <div
                        (click)="navigateAdvancementsIndex(items, dueItem)"
                        class="tw-grid tw-grid-cols-3 tw-mt-3 tw-py-1 hover:tw-bg-scalaraGray-06 tw-cursor-pointer"
                        *ngFor="let dueItem of items.dueIitem"
                    >
                        <div>{{ dueItem.unitName }}</div>
                        <div>{{ dueItem.accountDesc }}</div>
                        <div class="tw-text-end">{{ dueItem.sumAmount | eurocent }} €</div>
                    </div>
                </ng-container>
            </div>

            <div class="tw-w-6 tw-h-6 tw-fill-scalaraGray-02 tw-ml-8" [ngClass]="{ 'rotate-180': items.isOpen }">
                <svg-icon src="/assets/icons/24_arrow-down.svg"></svg-icon>
            </div>
        </div>
    </div>

    <div>
        <div class="tw-text-scalaraGray-03 tw-s-label-12-16-regular tw-mb-8">
            {{ 'PAGES.CONTACT.ACCOUNTING_DASHBOARD.LABEL_RECEIVABLES_LIABILITIES_PER_MONTH' | translate }}
        </div>

        <div *ngIf="!barChartData" class="tw-flex tw-space-x-1">
            <div class="tw-fill-scalaraBlue-01 tw-w-6 tw-h-6">
                <svg-icon src="/assets/icons/24_information.svg"></svg-icon>
            </div>
            <div class="tw-s-body-14-22-regular">
                {{ 'PAGES.CONTACT.ACCOUNTING_DASHBOARD.LABEL_EMPTY_OPEN_ITEMS' | translate }}
            </div>
        </div>

        <canvas height="80px" baseChart [type]="barChartType" [data]="barChartData" [options]="barChartOptions">
        </canvas>
    </div>
</div>
