<div class="tw-px-6 tw-py-6 lg:tw-px-10">
    <div class="tw-s-head-28-36-bold tw-mb-14">
        {{ 'COMPONENTS.SIDENAV_SETTINGS.LABEL_DATA_MIGRATION' | translate }}
    </div>

    <!-- STEP 1 -->
    <div class="tw-mb-20 tw-max-w-[888px]">
        <div class="tw-flex tw-items-center tw-space-x-3">
            <div
                class="tw-h-10 tw-w-10 tw-rounded-full tw-bg-scalaraGreen-03 tw-flex tw-justify-center tw-items-center"
            >
                <div class="tw-s-head-16-24-bold tw-text-scalaraGreen-01">1.</div>
            </div>

            <div class="tw-s-head-14-22-bold">{{ 'PAGES.MIGRATION.TITLE_STEP1' | translate }}</div>
        </div>

        <div class="tw-s-head-14-22-regular tw-mt-4 tw-whitespace-break-spaces tw-mb-10">
            <span>{{ 'PAGES.MIGRATION.TEXT_STEP1' | translate }}</span>
        </div>

        <div class="tw-flex tw-space-x-4">
            <div
                (click)="downloadTemplate('WEG')"
                class="tile tw-flex tw-items-center tw-justify-between tw-border-[1px] tw-border-scalaraGray-05 tw-border-solid tw-p-4 tw-w-full tw-cursor-pointer"
            >
                <div class="tw-flex tw-items-center">
                    <div class="icon-container icon-container--72 icon-container--green">
                        <svg-icon src="/assets/icons/40_buildings-WEG.svg"></svg-icon>
                    </div>
                    <div class="tw-s-head-14-22-semibold tw-ml-5">Vorlage WEG Immobilien</div>
                </div>

                <div class="tw-fill-scalaraGray-02 tw-h-6 tw-w-6">
                    <svg-icon src="/assets/icons/24_download.svg"></svg-icon>
                </div>

                <!-- <app-scalara-button variant="icon-only" svgName="24_download.svg"></app-scalara-button> -->
            </div>

            <div
                (click)="downloadTemplate('MV')"
                class="tile tw-flex tw-items-center tw-justify-between tw-border-[1px] tw-border-scalaraGray-05 tw-border-solid tw-p-4 tw-w-full tw-cursor-pointer"
            >
                <div class="tw-flex tw-items-center">
                    <div class="icon-container icon-container--72 icon-container--purple">
                        <svg-icon src="/assets/icons/40_building-MV.svg"></svg-icon>
                    </div>
                    <div class="tw-s-head-14-22-semibold tw-ml-5">Vorlage MV Immobilien</div>
                </div>

                <div class="tw-fill-scalaraGray-02 tw-h-6 tw-w-6">
                    <svg-icon src="/assets/icons/24_download.svg"></svg-icon>
                </div>

                <!-- <app-scalara-button variant="icon-only" svgName="24_download.svg"></app-scalara-button> -->
            </div>
        </div>
    </div>

    <!-- STEP 2 -->
    <div class="tw-mb-20 tw-max-w-[888px]">
        <div class="tw-flex tw-items-center tw-space-x-3">
            <div
                class="tw-h-10 tw-w-10 tw-rounded-full tw-bg-scalaraGreen-03 tw-flex tw-justify-center tw-items-center"
            >
                <div class="tw-s-head-16-24-bold tw-text-scalaraGreen-01">2.</div>
            </div>

            <div class="tw-s-head-14-22-bold">{{ 'PAGES.MIGRATION.TITLE_STEP2' | translate }}</div>
        </div>

        <div class="tw-s-head-14-22-regular tw-mt-4 tw-whitespace-break-spaces tw-mb-8">
            <span>{{ 'PAGES.MIGRATION.TEXT_STEP2' | translate }}</span>
        </div>

        <form [formGroup]="form">
            <ibm-radio-group
                formControlName="propertyType"
                aria-label="radiogroup"
                orientation="vertical"
                class="tw-mb-10"
                [disabled]="vm && vm.importProcessStep === ImportProcessStepsEnum.WAITINGFORMIGRATION ? true : false"
            >
                <ibm-radio value="WEG" [checked]="true"> WEG Immobilien </ibm-radio>
                <ibm-radio class="tw-mt-2" value="MV"> MV Immobilien </ibm-radio>
                <!-- TODO: add texts in de.json -->
            </ibm-radio-group>
        </form>

        <div class="tw-mb-6">
            <div
                class="tw-s-label-12-22-semibold tw-text-scalaraGray-03 tw-mb-3"
                [ngClass]="{
                    'tw-text-scalaraGray-04': vm?.importProcessStep === ImportProcessStepsEnum.WAITINGFORMIGRATION
                }"
            >
                Formate: .xlsx, .xls
            </div>
            <div class="file-area">
                <input
                    type="file"
                    ondrop="this.value=null;"
                    onclick="this.value=null"
                    class="file-uploader file-uploader__input"
                    (change)="fileChangeEvent()"
                    [disabled]="
                        vm?.importProcessStep === ImportProcessStepsEnum.UPLOADING ||
                        vm?.importProcessStep === ImportProcessStepsEnum.WAITINGFORMIGRATION
                    "
                    [accept]="['.xlsx', '.xls', '.XLSX', '.XLS']"
                    #fileInput
                />

                <div
                    class="tw-min-h-[118px] tw-p-6 tw-flex tw-flex-col tw-items-center tw-justify-center tw-bg-scalaraGray-06"
                    [ngClass]="{
                        'tw-bg-scalaraGray-07': vm?.importProcessStep === ImportProcessStepsEnum.WAITINGFORMIGRATION
                    }"
                >
                    <ng-container *ngIf="vm?.importProcessStep === ImportProcessStepsEnum.UPLOADING; else inputBox">
                        <ibm-loading [isActive]="true" size="sm"></ibm-loading>
                    </ng-container>
                    <ng-template #inputBox>
                        <div
                            class="tw-h-10 tw-w-10 tw-fill-scalaraGray-03 tw-mb-2"
                            [ngClass]="{
                                'tw-fill-scalaraGray-04':
                                    vm?.importProcessStep === ImportProcessStepsEnum.WAITINGFORMIGRATION
                            }"
                        >
                            <svg-icon src="/assets/icons/40_upload.svg"></svg-icon>
                        </div>
                        <span
                            [ngClass]="{
                                'tw-text-scalaraGray-04':
                                    vm?.importProcessStep === ImportProcessStepsEnum.WAITINGFORMIGRATION
                            }"
                            class="s-body-14-22-regular"
                        >
                            {{ 'PAGES.CREATE_CREDITOR.ADD_DOCUMENT_INPUT' | translate }}</span
                        >
                    </ng-template>
                </div>
            </div>
        </div>
        <!-- TODO: create the other box with status, its missing the migration status -->
        <ng-container
            *ngIf="
                vm &&
                (vm.importProcessStep === ImportProcessStepsEnum.WAITINGFORMIGRATION ||
                    vm.importProcessStep === ImportProcessStepsEnum.MIGRATING)
            "
        >
            <!-- Box with PENDING file -->
            <div class="tw-border-[1px] tw-border-solid tw-border-scalaraGray-05 tw-p-8">
                <div
                    class="tw-bg-scalaraGreen-03 tw-border-b-[1px] tw-border-solid tw-border-scalaraGray-05 tw-pl-2 tw-pr-6 tw-py-3 tw-flex tw-justify-between tw-items-center tw-mb-10"
                >
                    <div class="tw-flex">
                        <div class="tw-w-6 tw-h-6 tw-fill-scalaraGreen-01">
                            <svg-icon src="/assets/icons/24_checkbox-check.svg"></svg-icon>
                        </div>
                        <div class="tw-s-body-14-22-regular tw-ml-2">{{ vm.importInfo?.fileName }}</div>
                    </div>

                    <div
                        *ngIf="vm.importInfo && vm.importInfo.createdAt"
                        class="tw-text-scalaraGray-03 tw-s-label-12-16-regular"
                    >
                        Hochgeladen am {{ vm.importInfo.createdAt | dateTimeFormat }}
                    </div>
                </div>

                <ng-container *ngIf="vm.importProcessStep === ImportProcessStepsEnum.WAITINGFORMIGRATION">
                    <div class="tw-s-head-14-22-regular">
                        Sie können die Migration jetzt starten. Dieser Prozess kann einige Minuten dauern und läuft im
                        Hintergrund. Arbeiten Sie in der Zwischenzeit einfach weiter.
                    </div>

                    <div class="tw-flex tw-justify-between tw-mt-12">
                        <app-scalara-button (click)="deleteMigrationFile()" variant="outline">{{
                            'CONTROLS.BUTTONS.CANCEL' | translate
                        }}</app-scalara-button>
                        <app-scalara-button (click)="startMigration(vm.importInfo)"
                            >Migration jetzt starten</app-scalara-button
                        >
                    </div>
                </ng-container>

                <ng-container *ngIf="vm.importProcessStep === ImportProcessStepsEnum.MIGRATING">
                    <div *ngIf="migrationProgress$ | async as migrationProgress">
                        <div class="tw-flex tw-justify-between">
                            <div class="tw-s-head-14-22-regular tw-mb-4">
                                Daten werden migriert. Dies kann einige Minuten dauern.
                            </div>

                            <div class="tw-s-label-12-16-regular tw-text-scalaraGray-03">
                                {{ migrationProgress.imported }} von {{ migrationProgress.total }} Objekten migriert
                            </div>
                        </div>

                        <div class="file file-progress__wrapper">
                            <div
                                class="file file-progress__amount"
                                [style.width]="migrationProgress.progress + '%'"
                            ></div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <!--  -->
        </ng-container>

        <!-- Box with ERROR file -->
        <div *ngIf="vm?.notificationError?.errorMessage">
            <div
                [ngClass]="{
                    'tw-border-[1px] tw-border-solid tw-border-scalaraGray-05 tw-p-8':
                        vm?.notificationError?.errorDetails
                }"
            >
                <app-notification type="error">
                    <div class="tw-s-body-14-22-regular">{{ vm?.notificationError?.errorMessage }}</div>
                </app-notification>
                <div class="tw-mt-10" *ngIf="vm?.notificationError?.errorDetails">
                    <app-scalara-accordion
                        headerLabelClass="tw-s-head-14-22-regular"
                        headingLabel="Der Upload ist fehlgeschlagen."
                        [isOpenedByDefault]="false"
                    >
                        <div scalara-accordion-content>{{ vm?.notificationError?.errorDetails }}</div>
                    </app-scalara-accordion>
                </div>
            </div>
        </div>
        <!--  -->

        <!-- Notification with migration status -->
        <!-- <ng-container *ngIf="vm?.migrationStatus"> -->
        <ng-container *ngIf="vm?.importProcessStep === ImportProcessStepsEnum.SUCCESS">
            <app-notification type="success">
                <div class="tw-flex tw-justify-between">
                    <div class="tw-s-body-14-22-regular">
                        Migration erfolgreich. Für weitere Infos schauen Sie bitte in das unten stehende Protokoll.
                    </div>
                    <div (click)="closeNotification()" class="tw-w-6 tw-h-6 tw-fill-scalaraGray-02 tw-cursor-pointer">
                        <svg-icon src="/assets/icons/24_close.svg"></svg-icon>
                    </div>
                </div>
            </app-notification>
        </ng-container>

        <ng-container *ngIf="vm?.importProcessStep === ImportProcessStepsEnum.ERROR">
            <app-notification type="error">
                <div class="tw-flex tw-justify-between">
                    <div class="tw-s-body-14-22-regular">
                        Migration fehlgeschlagen. Für weitere Infos schauen Sie bitte in das unten stehende Protokoll.
                    </div>
                    <div (click)="closeNotification()" class="tw-w-6 tw-h-6 tw-fill-scalaraGray-02 tw-cursor-pointer">
                        <svg-icon src="/assets/icons/24_close.svg"></svg-icon>
                    </div>
                </div>
            </app-notification>
        </ng-container>
        <!-- </ng-container> -->
    </div>

    <!-- PROTOCOL TABLE  -->
    <div>
        <div class="tw-flex tw-justify-between">
            <div class="tw-flex tw-items-center tw-space-x-3 tw-mb-4">
                <div class="icon-container icon-container--40 icon-container--green">
                    <svg-icon src="/assets/icons/24_dokument.svg"></svg-icon>
                </div>
                <div class="tw-s-head-14-22-bold">{{ 'PAGES.MIGRATION.LABEL_PROTOCOL' | translate }}</div>
            </div>
            <app-search searchInputId="migrationTable"></app-search>
        </div>

        <app-table
            *ngIf="protocolTableModel$ | async as tableModel"
            (customButton)="handlingEvent($event)"
            emptyText="PAGES.MIGRATION.LABEL_EMPTY_TABLE"
            [header]="tableModel.header"
            [data]="tableModel.data"
            tableSearchId="migrationTable"
        >
        </app-table>
    </div>
</div>

<ng-template #expandedTemplate let-dataTable="data">
    <div class="tw-s-head-14-22-regular tw-py-4" *ngIf="dataTable.errorMessage">{{ dataTable.errorMessage }}</div>
    <pre><div class="tw-s-head-14-22-regular tw-py-4" *ngFor="let row of dataTable.validationError">{{ row }}</div></pre>

    <div
        class="tw-s-head-14-22-regular tw-space-y-2 tw-py-4"
        *ngIf="!dataTable.errorMessage && getImportStatistics(dataTable.importStatistics) as importStatistics"
    >
        <div>
            {{ importStatistics.properties.processed }} von {{ importStatistics.properties.total }} Immobilien migriert
        </div>
        <div>
            {{ importStatistics.ownerships.processed }} von {{ importStatistics.ownerships.total }} WEG-Einheiten
            migriert
        </div>
        <div>
            {{ importStatistics.units.processed }} von {{ importStatistics.units.total }} Sondereigentümern migriert
        </div>
        <div>
            {{ importStatistics.mvUnits.processed }} von {{ importStatistics.mvUnits.total }} Miet-Einheiten migriert
        </div>
        <div>{{ importStatistics.persons.processed }} von {{ importStatistics.persons.total }} Personen migriert</div>
        <div>
            {{ importStatistics.bankAccounts.processed }} von {{ importStatistics.bankAccounts.total }} Geldkonten
            migriert
        </div>
        <div>
            {{ importStatistics.rentContracts.processed }} von {{ importStatistics.rentContracts.total }} Miet-Verträgen
            migriert
        </div>
    </div>
</ng-template>
