/**
 * SCALARA Accounting Module
 * The SCALARA Accounting API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EconomicPlanDto { 
    createdAt: string;
    updatedAt: string;
    id: string;
    ledgerId: string;
    status: EconomicPlanDto.StatusEnum;
    startDate: string;
    endDate: string;
    type: EconomicPlanDto.TypeEnum;
    isAutoFollowUpPlan: boolean;
}
export namespace EconomicPlanDto {
    export type StatusEnum = 'DRAFT' | 'ACTIVATING' | 'ACTIVE' | 'OUTDATED';
    export const StatusEnum = {
        Draft: 'DRAFT' as StatusEnum,
        Activating: 'ACTIVATING' as StatusEnum,
        Active: 'ACTIVE' as StatusEnum,
        Outdated: 'OUTDATED' as StatusEnum
    };
    export type TypeEnum = 'STANDARD' | 'DIFFERING_ADVANCEMENTS';
    export const TypeEnum = {
        Standard: 'STANDARD' as TypeEnum,
        DifferingAdvancements: 'DIFFERING_ADVANCEMENTS' as TypeEnum
    };
}


