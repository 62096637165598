import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ListItem } from 'carbon-components-angular';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { environment } from 'src/environments/environment';
import { isFileValid, uuid } from '../../../core/utils/common';
import { FileInfoDto, FilesService } from '../../../generated-sources/file';
import { ExistingFile } from './existing-files/existing-files.component';

@Component({
    selector: 'app-file-uploader',
    templateUrl: './file-uploader.component.html',
    styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent implements OnInit, OnDestroy {
    public files: File[] = [];
    public filesProgress: number[] = [];
    public fileUuids: string[] = [];
    public fileCategories: string[] = [];
    public fileAccounts: string[] = [];
    public fileTypesSelected: string[] = [];
    public fileTypes: ListItem[] = [];

    public isFileExtensionValid = true;
    public isFileTooBig = false;
    public areAllFilesLoaded = false;
    public validFilesText: string[] = [];

    public readonly fileSizeInMb = environment.maxFileSizeInMB;

    private fileServiceRequests: Subscription[] = [];
    private unsubscribe$ = new Subject<void>();

    public constructor(
        private filesService: FilesService,
        private toastService: ToastService,
        private translateService: TranslateService
    ) {}

    @Output() public filesLoaded = new EventEmitter<boolean>();
    @Output() public fileIdsLoaded = new EventEmitter<string[]>();
    @Output() public updatedFileCategories = new EventEmitter<string[]>();
    @Output() public updatedFileTypes = new EventEmitter<string[]>();
    @Output() public updatedFileAccounts = new EventEmitter<string[]>();
    @Input() public categories?: ListItem[] = [];
    @Input() public accountsList?: ListItem[];

    @Input() public isAccountsDisabledOnInit = false;
    @Input() public isCategoryDisable = false;
    @Input() public showButtonFromParent = false;
    @Input() public reverseOrderFilesButton = false;
    @Input() public textInput = '';
    @Input() public validFiles = [
        'jpg',
        'jpeg',
        'png',
        'pdf',
        'csv',
        'xlsx',
        'xls',
        'xlsm',
        'XLSM',
        'XLS',
        'XLSX',
        'CSV',
        'PDF',
        'JPG',
        'PNG',
        'JPEG',
    ];
    @Input() public selectFileType = false;
    @Input() public allowedOnlyOneFile = false;
    @Input() public defaultCategory?: string;

    // To edit || delete existing files
    @Input() public existingFiles: ExistingFile[] = [];
    @Output() public updateExistingFiles = new EventEmitter<ExistingFile[]>();

    @ViewChild('fileInput') public fileInput: any;

    public ngOnInit(): void {
        this.validFilesText = this.validFiles
            .filter((format) => format.match(/[a-z]/))
            .map((format) => (format = ' .' + format));

        if (this.selectFileType) {
            this.fileTypes = [
                {
                    content: this.translateService.instant('ENTITIES.RECEIPT.LABEL_TYPE_INVOICE'),
                    selected: true,
                    value: 'INVOICE',
                },
                {
                    content: this.translateService.instant('ENTITIES.RECEIPT.LABEL_TYPE_PERMANENT_INVOICE'),
                    selected: false,
                    value: 'PERMANENT_INVOICE',
                },
                {
                    content: this.translateService.instant('ENTITIES.RECEIPT.LABEL_TYPE_RECEIPT'),
                    selected: false,
                    value: 'RECEIPT',
                },
                {
                    content: this.translateService.instant('ENTITIES.RECEIPT.LABEL_TYPE_DOCUMENT'),
                    selected: false,
                    value: 'DOCUMENT',
                },
            ];
        }
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public fileChangeEvent(): void {
        this.isFileExtensionValid = true;
        this.isFileTooBig = false;

        const fileValidate = (file: File): void => {
            if (file && isFileValid(file.name, this.validFiles)) {
                if (file.size < this.fileSizeInMb * 1024 * 1024) {
                    if (this.allowedOnlyOneFile) {
                        this.files = [file];
                    } else {
                        this.files.push(file);
                    }
                    this.filesProgress.push(0);
                    this.filesLoaded?.emit(false);
                } else {
                    this.isFileTooBig = true;
                }
            } else {
                this.isFileExtensionValid = false;
            }
        };

        if (this.allowedOnlyOneFile) {
            const file = this.fileInput.nativeElement.files[0];
            fileValidate(file);
        } else {
            Array.from([...this.fileInput.nativeElement.files]).forEach((file: File) => {
                fileValidate(file);
            });
        }

        this.prepareFilesList();
    }

    /**
     * tagging the selected File as "canceled" with '-1' and stopping the upload of the selected File.
     * The complete removal of "canceled" Files of the different lists
     * happens in the "removingCanceledFiles" function
     *
     * @param index index of the selected file in the arrays.
     */
    public cancelFile(index: number): void {
        this.filesProgress[index] = -1;
        this.fileServiceRequests[index].unsubscribe();
        if (this.areFilesFullyUploaded()) {
            this.removingCanceledFiles();
        }
        if (this.files.length == 0) {
            this.filesLoaded.emit(false);
        }
        this.fileIdsLoaded.emit(this.fileUuids);
    }

    public prepareFilesList(): void {
        for (let i = 0; i < this.files.length; i++) {
            if (this.filesProgress[i] < 100) {
                const key = uuid();
                this.fileUuids.push(key);
                if (this.categories) {
                    this.fileCategories.push(this.defaultCategory ?? 'OTHER');
                }
                this.fileServiceRequests[i] = this.filesService
                    .uploadFile(key, this.files[i], 'events', true)
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe({
                        next: (response: HttpEvent<FileInfoDto>) => {
                            if (response.type === HttpEventType.UploadProgress) {
                                const progress = Math.ceil((response.loaded / this.files[i].size) * 100);
                                if (progress >= 100) {
                                    this.filesProgress[i] = 100;
                                } else {
                                    if (this.filesProgress[i] < progress) {
                                        this.filesProgress[i] = progress;
                                    }
                                }
                            } else {
                                if (response.type === HttpEventType.Response) {
                                    this.filesProgress[i] = 101;
                                }
                                if (this.areFilesFullyUploaded()) {
                                    /* By not removing canceled files from the lists until all others are uploaded,
                                     *  We make sure the progress obtained on every HttpEvent gets added to its correspondent file
                                     */
                                    this.removingCanceledFiles();

                                    this.fileIdsLoaded.emit(this.fileUuids);
                                    this.updatedFileCategories.emit(this.fileCategories);
                                    this.filesLoaded.emit(true);
                                }
                            }
                        },
                        error: (err) => {
                            this.toastService.showError(err.error['message']);
                        },
                    });
            }
        }
    }

    public areFilesFullyUploaded(): boolean {
        let isFullyUploaded = this.filesProgress.length > 0;
        for (let i = 0; i < this.filesProgress.length; i++) {
            if (this.filesProgress[i] > -1 && this.filesProgress[i] < 101) {
                isFullyUploaded = false;
            }
        }
        return isFullyUploaded;
    }

    /**
     * Removing already tagged as "canceled" files from the arrays
     */
    private removingCanceledFiles(): void {
        for (let i = 0; i < this.filesProgress.length; i++) {
            if (this.filesProgress[i] == -1) {
                this.files.splice(i, 1);
                this.fileUuids.splice(i, 1);
                this.filesProgress.splice(i, 1);
                this.fileServiceRequests.splice(i, 1);
                this.fileCategories.splice(i, 1);
            }
        }
    }

    public onSelectCategory($event: any, i: number): void {
        this.fileCategories[i] = $event.item['value'];
        this.updatedFileCategories.emit(this.fileCategories);
    }

    public onSelectType($event: any, i: number): void {
        this.fileTypesSelected[i] = $event.item['value'];
        this.updatedFileTypes.emit(this.fileTypesSelected);
    }

    public onSelectAccount($event: any, i: number): void {
        this.fileAccounts[i] = $event && $event.item && $event.item['value'] ? $event.item['value'] : undefined;
        this.updatedFileAccounts.emit(this.fileAccounts);
    }

    public updateExistingFilesList(newExistingList: ExistingFile[]): void {
        this.updateExistingFiles.emit(newExistingList);
    }
}
