<app-overlay-container
    headlineTitle="PAGES.OCCUPATION.OVERVIEW.LABEL_ADD_OCCUPATION_BUTTON"
    [sizeIcon]="72"
    image="40_dokument.svg"
    colorIcon="green"
>
    <ibm-loading *ngIf="isLoading" [isActive]="true" [size]="'normal'" [overlay]="true"></ibm-loading>
    <form [hidden]="isLoading" [formGroup]="form">
        <div [hidden]="selectedStep !== 1">
            <!-- Nutzung hinzufügen - STEP 1 -->
            <div class="tw-grid tw-grid-cols-3 tw-gap-8 mb-5r">
                <ng-container
                    *ngTemplateOutlet="
                        occupationTypeCard;
                        context: {
                            typeTitle: 'Vermietung',
                            icon: '40_dokument.svg',
                            type: 'RENTING'
                        }
                    "
                ></ng-container>
                <ng-container
                    *ngTemplateOutlet="
                        occupationTypeCard;
                        context: { typeTitle: 'Leerstand', icon: '40_door-open.svg', type: 'VACANCY' }
                    "
                ></ng-container>
                <ng-container
                    *ngTemplateOutlet="
                        occupationTypeCard;
                        context: {
                            typeTitle: 'Selbstnutzung',
                            icon: '40_person.svg',
                            type: 'OWN_OCCUPATION'
                        }
                    "
                ></ng-container>
            </div>

            <div *ngIf="typeSelected === 'RENTING'">
                <div>
                    <div class="s-head-14-22-regular mb-1-5r">
                        {{ 'PAGES.OCCUPATION.ADD_OCCUPATION_FORM.LABEL_SELECT_TYPE' | translate }}
                    </div>

                    <ibm-radio-group aria-label="radiogroup" orientation="vertical" formControlName="rentType">
                        <ibm-radio [checked]="true" value="PRIVATE">
                            {{ 'PAGES.OCCUPATION.ADD_OCCUPATION_FORM.LABEL_RENTED_PRIVATELY' | translate }}
                        </ibm-radio>
                        <ibm-radio value="COMMERCIAL">
                            {{ 'PAGES.OCCUPATION.ADD_OCCUPATION_FORM.LABEL_COMPANY_RENTED' | translate }}
                        </ibm-radio>
                    </ibm-radio-group>
                </div>
                <div>
                    <div class="s-head-14-22-regular tw-mb-4 tw-mt-1">
                        {{ 'PAGES.OCCUPATION.ADD_OCCUPATION_FORM.LABEL_SELECT_OPTED_FOR_VAT' | translate }}
                    </div>

                    <ibm-checkbox formControlName="optedForVAT">
                        {{ 'PAGES.OCCUPATION.ADD_OCCUPATION_FORM.LABEL_VAT_CHECKBOX' | translate }}
                    </ibm-checkbox>
                </div>
            </div>

            <div>
                <div class="s-head-16-24-bold mb-1-5r">
                    {{ 'PAGES.OCCUPATION.ADD_OCCUPATION_FORM.LABEL_SELECT_UNIT' | translate }}
                </div>

                <ng-container formArrayName="ownerships">
                    <ng-container *ngFor="let item of ownershipsControl.controls; let i = index" [formGroupName]="i">
                        <ng-container *ngIf="i > 0">
                            <hr class="mt-2r" />
                            <div class="d-flex justify-content-end">
                                <app-scalara-button
                                    (click)="removeOwnership(i)"
                                    variant="ghost"
                                    svgName="24_close.svg"
                                    height="40px"
                                ></app-scalara-button>
                            </div>
                        </ng-container>

                        <div class="mb-1-5r">
                            <app-combo-box
                                [size]="'md'"
                                id="building"
                                [appendInline]="true"
                                label="Gebäude*"
                                [items]="i === 0 ? buildingList[i] || [] : buildingAfterUnitSelectedList[i] || []"
                                placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT' | translate }}"
                                invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                                (selected)="findOwnderships(i)"
                                formControlName="building"
                                (cleared)="clearPropertyInput(i)"
                                [invalid]="isInvalidArray(i, 'building')"
                            >
                                <ibm-dropdown-list></ibm-dropdown-list>
                            </app-combo-box>
                        </div>

                        <app-combo-box
                            [size]="'md'"
                            id="ownership"
                            [appendInline]="true"
                            label="Einheit*"
                            [items]="i === 0 ? ownershipsList[i] || [] : ownershipsAfterUnitSelectedList[i] || []"
                            formControlName="ownership"
                            placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT' | translate }}"
                            [invalid]="isInvalidUnit(i)"
                            (selected)="i === 0 ? findSelectionUnit() : onSelectOwnership($event, i)"
                            [invalidText]="'Eine Einheit darf nicht mehr als einmal zugeordnet werden'"
                        >
                            <ibm-dropdown-list></ibm-dropdown-list>
                        </app-combo-box>
                    </ng-container>
                </ng-container>

                <div class="mt-1-5r mb-4-5r">
                    <app-scalara-button (click)="addNewOwnership()" variant="ghost" svgName="24_add.svg"
                        >Weitere Einheit</app-scalara-button
                    >
                </div>
            </div>

            <div>
                <div class="s-head-16-24-bold mb-0-5r">
                    {{ 'PAGES.OCCUPATION.ADD_OCCUPATION_FORM.LABEL_DATE_INTERVAL' | translate }}
                </div>
                <div class="s-label-12-16-regular s-gray-03 mb-1r">
                    {{ 'PAGES.OCCUPATION.ADD_OCCUPATION_FORM.DATE_INTERVAL_TEXT' | translate }}
                </div>
                <div class="mb-1-5r">
                    <app-date-range-picker></app-date-range-picker>
                </div>
                <ng-container *ngIf="typeSelected === 'VACANCY'">
                    <div class="d-flex">
                        <div class="s-head-16-24-bold tw-mb-2 tw-mr-2">
                            {{ 'PAGES.OCCUPATION.ADD_OCCUPATION_FORM.LABEL_TOTAL_NUMBER_OF_PERSONS' | translate }}
                        </div>
                        <app-tooltip position="top">
                            <div
                                content
                                class="demo-banner_tooltip-content tw-w-72 lg:tw-w-[42rem] tw-p-4 lg:tw-px-12 lg:tw-py-8"
                            >
                                {{
                                    'PAGES.OCCUPATION.ADD_OCCUPATION_FORM.LABEL_TOTAL_NUMBER_OF_PERSONS_INFO'
                                        | translate
                                }}
                            </div>
                            <div hoverable-item class="persons-info-icon-container">
                                <svg-icon src="/assets/icons/24_information.svg"></svg-icon>
                            </div>
                        </app-tooltip>
                    </div>
                    <div class="s-label-12-16-regular s-gray-03 mb-1r">
                        {{ 'PAGES.OCCUPATION.ADD_OCCUPATION_FORM.LABEL_INFO_NUMBER_OF_PERSONS' | translate }}
                    </div>

                    <ibm-label
                        invalidText="{{
                            (isInvalidNumber('numberOfPersons') ? 'ERROR.GENERAL' : 'ERROR.REQUIRED') | translate
                        }}"
                        [invalid]="isInvalid('numberOfPersons') || isInvalidNumber('numberOfPersons')"
                        class="input-small mb-4-5r"
                        >Anzahl*
                        <input
                            ibmText
                            type="number"
                            name="numberOfPersons"
                            id="numberOfPersons"
                            formControlName="numberOfPersons"
                            [invalid]="isInvalid('numberOfPersons') || isInvalidNumber('numberOfPersons')"
                        />
                    </ibm-label>
                    <div class="s-head-16-24-bold mb-0-5r">Sonstiges</div>
                    <div class="s-label-12-16-regular s-gray-03 mb-1r">
                        {{ 'PAGES.OCCUPATION.ADD_OCCUPATION_FORM.LABEL_EXTRA_MISCELLANEOUS_INFO' | translate }}
                    </div>

                    <ibm-label>
                        Sonstiges
                        <textarea
                            ibmTextArea
                            rows="3"
                            aria-label="textarea"
                            maxlength="100000"
                            formControlName="miscellaneousInfo"
                        ></textarea>
                    </ibm-label>
                </ng-container>

                <ng-container *ngIf="typeSelected === 'RENTING'">
                    <div class="d-flex">
                        <ibm-label
                            [invalid]="isInvalidNumber('minimumTermInMonth')"
                            invalidText="{{ 'ERROR.GENERAL' | translate }}"
                            class="mr-2r w-50p"
                            >Mindestlaufzeit in Monaten
                            <input
                                ibmText
                                type="number"
                                name="minimumTermInMonth"
                                id="minimumTermInMonth"
                                formControlName="minimumTermInMonth"
                                [invalid]="isInvalidNumber('minimumTermInMonth')"
                            />
                        </ibm-label>

                        <ibm-label
                            [invalid]="isInvalidNumber('automaticExtensionInMonth')"
                            class="w-50p"
                            invalidText="{{ 'ERROR.GENERAL' | translate }}"
                            >Automatische Verlängerung in Monaten
                            <input
                                ibmText
                                type="number"
                                name="automaticExtensionInMonth"
                                id="automaticExtensionInMonth"
                                formControlName="automaticExtensionInMonth"
                                [invalid]="isInvalidNumber('automaticExtensionInMonth')"
                            />
                        </ibm-label>
                    </div>

                    <ibm-label
                        [invalid]="isInvalidNumber('cancellationPeriodInMonth')"
                        invalidText="{{ 'ERROR.GENERAL' | translate }}"
                        class="input-medium"
                        >Kündigungsfrist in Monaten
                        <input
                            ibmText
                            type="number"
                            name="cancellationPeriodInMonth"
                            id="cancellationPeriodInMonth"
                            formControlName="cancellationPeriodInMonth"
                            [invalid]="isInvalidNumber('cancellationPeriodInMonth')"
                        />
                    </ibm-label>
                </ng-container>
            </div>
            <!-- END Nutzunng hinzufügen - STEP 1 -->
        </div>

        <!-- switch between steps -->

        <!-- step 2  -->
        <div [hidden]="selectedStep !== 2">
            <div class="s-head-16-24-bold mb-1-25r">Mieter anlegen</div>
            <div class="tw-grid tw-grid-cols-2 tw-gap-8 tw-mb-16">
                <app-card-selection
                    iconColor="green"
                    iconSrc="40_edit.svg"
                    label="{{ 'PAGES.RELATIONS.ADD_RELATION_OVERLAY.LABEL_ADD_PERSON' | translate }}"
                    [selected]="!isExistingUserSelected"
                    (click)="switchExistingUserSelection(false)"
                ></app-card-selection>
                <app-card-selection
                    iconColor="green"
                    iconSrc="40_person.svg"
                    label="   {{ 'PAGES.RELATIONS.ADD_RELATION_OVERLAY.LABEL_SELECT_EXISTING_PERSON' | translate }}"
                    [selected]="isExistingUserSelected"
                    (click)="switchExistingUserSelection(true)"
                ></app-card-selection>
            </div>

            <div [hidden]="isExistingUserSelected">
                <app-user-infos-form></app-user-infos-form>
            </div>

            <div [hidden]="!isExistingUserSelected">
                <app-existing-persons-combo-box
                    (handlePersonSelection)="onSelectPerson($event)"
                ></app-existing-persons-combo-box>
            </div>
        </div>

        <!-- step 2  -->

        <ng-container *ngIf="selectedStep === 3">
            <app-add-occupation-step3
                [tenants]="occupationForm.tenants || []"
                (removeTenant)="removeTenant($event)"
                (addNewTenant)="addNewTenant()"
                [form]="form"
                [existingPersons]="existingPersons"
                (openEditPersonForm)="openEditPersonForm($event)"
                [typeSelected]="typeSelected"
            ></app-add-occupation-step3>
        </ng-container>

        <div [hidden]="selectedStep !== 4">
            <app-add-occupation-step4 [occupationType]="typeSelected" [form]="form"></app-add-occupation-step4>
        </div>

        <!-- switch between steps -->

        <ng-container *ngIf="typeSelected === 'VACANCY' || selectedStep === 4">
            <div class="s-head-16-24-bold mb-0-5r mt-4-5r">
                {{ 'PAGES.OCCUPATION.ADD_OCCUPATION_FORM.DOCUMENT_UPLOAD_TITLE' | translate }}
            </div>

            <app-file-uploader
                #fileUpload
                (filesLoaded)="updateLoadingFilesStatus($event)"
                (fileIdsLoaded)="updateFileIdsLodaded($event)"
                [textInput]="'PAGES.ADD_TICKET.INPUT_AREA'"
                [categories]="categories"
                (updatedFileCategories)="updatedFileCategories($event)"
            ></app-file-uploader>
        </ng-container>
        <!-- textInput: Dokument zum Hochladen hier hinziehen oder klicken.  -->

        <div class="mt-7-5r d-flex justify-space-between">
            <app-scalara-button (click)="abort()" variant="outline">
                {{ (selectedStep > 1 ? 'CONTROLS.BUTTONS.BACK' : 'CONTROLS.BUTTONS.CANCEL') | translate }}
            </app-scalara-button>

            <div class="d-flex">
                <div class="mr-1-5r">
                    <app-scalara-button
                        [disabled]="!form.valid"
                        (click)="onSubmitAndClose()"
                        [variant]="typeSelected === 'VACANCY' || selectedStep === 4 ? 'default' : 'ghost'"
                        height="48px"
                    >
                        {{
                            typeSelected === 'VACANCY' || selectedStep === 4
                                ? ('CONTROLS.BUTTONS.SAVE' | translate)
                                : 'Speichern & schließen'
                        }}
                    </app-scalara-button>
                </div>

                <app-scalara-button
                    *ngIf="typeSelected !== 'VACANCY' && selectedStep !== 4"
                    (click)="onSubmitAndContinue()"
                    variant="default"
                    [disabled]="disableButtonSubmit()"
                >
                    Speichern & weiter
                </app-scalara-button>
            </div>
        </div>
    </form>
</app-overlay-container>

<!-- templates  -->
<ng-template #occupationTypeCard let-typeTitle="typeTitle" let-icon="icon" let-type="type">
    <app-card-selection
        iconColor="green"
        [iconSrc]="icon"
        [label]="typeTitle"
        [selected]="typeSelected === type"
        (click)="switchOccupationType(type)"
    ></app-card-selection>
</ng-template>
