import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ListItem, RadioChange } from 'carbon-components-angular';
import { valuesIn } from 'lodash';
import { firstValueFrom, Subject, takeUntil, tap } from 'rxjs';
import { LedgerDto, LedgersService } from 'src/app/generated-sources/accounting';

import { ToastService } from 'src/app/core/services/toast.service';
import { LedgerCustomService } from '../services/ledger-custom.service';
@Component({
    selector: 'app-ledger-settings',
    templateUrl: './ledger-settings.component.html',
    styleUrls: ['./ledger-settings.component.scss'],
})
export class LedgerSettingsComponent implements OnInit, OnDestroy {
    public form: FormGroup = new FormGroup({});
    public ledgerCalculationType$ = new Subject<ListItem[]>();
    public ledgerCalculationType?: LedgerDto.OpenItemClosingStrategyEnum;
    public unsubscribe$ = new Subject<void>();
    public descriptionText = '';
    public descriptionTextHeadline = '';
    public radioButtonListItems: string[] = [];
    public ledgerId?: string;

    public constructor(
        private translateService: TranslateService,
        private ledgerCustomService: LedgerCustomService,
        private formBuilder: FormBuilder,
        private ledgerService: LedgersService,
        private toasterService: ToastService
    ) {}
    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public ledger$ = this.ledgerCustomService.getLedger$().pipe(
        tap((ledger) => {
            if (ledger?.type) {
                this.ledgerCalculationType = ledger.openItemClosingStrategy;
                this.ledgerId = ledger.id;
                console.log(this.ledgerCalculationType);
            }
        }),
        takeUntil(this.unsubscribe$)
    );

    public ngOnInit(): void {
        // this.form = this.formBuilder.group({
        //     ledgerCalculationType: new FormControl('', [Validators.required]),
        // });
        this.ledger$.subscribe(() => {
            this.form.updateValueAndValidity();
            this.radioButtonListItems = valuesIn(LedgerDto.OpenItemClosingStrategyEnum);
            this.form = this.formBuilder.group({
                ledgerCalculationType: new FormControl(this.ledgerCalculationType, [Validators.required]),
            });
            this.descriptionText = this.translateService.instant(
                `ENTITIES.LEDGER.CALCULATION_TYPE.DESCRIPTION_${this.ledgerCalculationType}`
            );
            this.descriptionTextHeadline = this.translateService.instant(
                `ENTITIES.LEDGER.CALCULATION_TYPE.HEADLINE_${this.ledgerCalculationType}`
            );
            console.log(this.descriptionText);
        });
    }

    public async onSubmit(): Promise<void> {
        if (this.ledgerCalculationType && this.ledgerId) {
            console.log(this.ledgerCalculationType);
            try {
                await firstValueFrom(
                    this.ledgerService.setSettings(this.ledgerId, {
                        openItemClosingStrategy: this.ledgerCalculationType,
                    })
                );
                this.toasterService.showSuccess('Einstellungen gespeichert!');
            } catch (error) {
                this.toasterService.showError('Fehler');
            }
        }
    }

    public onRadioChange($event: RadioChange): void {
        this.ledgerCalculationType = $event.value as LedgerDto.OpenItemClosingStrategyEnum;
        this.descriptionText = this.translateService.instant(
            `ENTITIES.LEDGER.CALCULATION_TYPE.DESCRIPTION_${this.ledgerCalculationType}`
        );
        this.descriptionTextHeadline = this.translateService.instant(
            `ENTITIES.LEDGER.CALCULATION_TYPE.HEADLINE_${this.ledgerCalculationType}`
        );
        this.form.updateValueAndValidity();
    }
    public ledgerCalculationEnum = LedgerDto.OpenItemClosingStrategyEnum;
}
