<div
    (click)="downloadTaxReport()"
    class="tile tw-flex tw-items-center tw-justify-between tw-border-[1px] tw-border-scalaraGray-05 tw-border-solid tw-p-4 tw-cursor-pointer tw-mt-11 tw-max-w-[436px]"
    [ngClass]="{ 'tw-pointer-events-none': isLoading }"
>
    <div class="tw-flex tw-items-center">
        <div
            class="icon-container icon-container--72 icon-container--green"
            [ngClass]="{ 'icon-container--green': !isLoading, 'icon-container--gray': isLoading }"
        >
            <svg-icon src="/assets/icons/navi_40_report.svg"></svg-icon>
        </div>
        <div class="tw-s-head-14-22-semibold tw-ml-5">Steuerreport herunterladen (.xlsx)</div>
    </div>

    <div *ngIf="!isLoading" class="tw-fill-scalaraGray-02 tw-h-6 tw-w-6">
        <svg-icon src="/assets/icons/24_download.svg"></svg-icon>
    </div>

    <div *ngIf="isLoading" class="">
        <ibm-loading [overlay]="false" size="sm"> </ibm-loading>
    </div>
</div>
