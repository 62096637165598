import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
    public constructor(private datePipe: DatePipe) {}

    public transform(value: string | Date | undefined): string {
        if (!value) {
            return '';
        }
        const formattedDate = this.datePipe.transform(value, 'dd.MM.yyyy');
        return `${formattedDate}`;
    }
}
