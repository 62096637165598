/**
 * SCALARA Accounting Module
 * The SCALARA Accounting API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LedgerDto { 
    id: string;
    propertyId: string;
    ownershipIds: Array<string>;
    openingBalanceBookingId?: string;
    type: LedgerDto.TypeEnum;
    /**
     * Unfortunately the Property-Type will not be genreated into the OpenApi-Spec of the accounting service. (Probably because it is an interface(which is a typescript-type) and not a class?) A workaround would have been to copy the Property-Type into the accounting service, but we decided against it, because then we would have to keep the types in sync
     */
    property: object;
    directDebitBankAccountId: string;
    creditorIdentifier: string;
    creditorDescription: string;
    openItemClosingStrategy: LedgerDto.OpenItemClosingStrategyEnum;
}
export namespace LedgerDto {
    export type TypeEnum = 'WEG' | 'SEV' | 'MV';
    export const TypeEnum = {
        Weg: 'WEG' as TypeEnum,
        Sev: 'SEV' as TypeEnum,
        Mv: 'MV' as TypeEnum
    };
    export type OpenItemClosingStrategyEnum = 'DUE_DATE_FIRST' | 'KOSTENTRAGUNG_FIRST' | 'ERHALTUNGSRUECKLAGE_FIRST' | 'QUOTELUNG';
    export const OpenItemClosingStrategyEnum = {
        DueDateFirst: 'DUE_DATE_FIRST' as OpenItemClosingStrategyEnum,
        KostentragungFirst: 'KOSTENTRAGUNG_FIRST' as OpenItemClosingStrategyEnum,
        ErhaltungsruecklageFirst: 'ERHALTUNGSRUECKLAGE_FIRST' as OpenItemClosingStrategyEnum,
        Quotelung: 'QUOTELUNG' as OpenItemClosingStrategyEnum
    };
}


