<div class="file-area">
    <div class="tw-s-label-12-22-semibold tw-text-scalaraGray-03 tw-mb-3">
        {{
            'ACCOUNTING.ADD_RECEIPT.FORMATS_VAR'
                | translate
                    : {
                          formats: formatsLabel,
                          filesize: fileSizeInMb
                      }
        }}*
    </div>
    <input
        type="file"
        (change)="fileChangeEvent($event)"
        ondrop="this.value=null;"
        onclick="this.value=null"
        class="file-uploader file-uploader__input"
        [accept]="acceptFormats"
    />
    <div class="tw-min-h-[118px] tw-p-6 tw-flex tw-flex-col tw-items-center tw-justify-center tw-bg-scalaraGray-06">
        <svg-icon class="tw-w-10 tw-fill-scalaraGray-03" src="/assets/icons/40_upload.svg"></svg-icon>
        <span class="success s-body-14-22-regular">{{ inputLabel }}</span>
    </div>
</div>
