import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';

export class CustomUrlSerializer implements UrlSerializer {
    private defaultUrlSerializer: DefaultUrlSerializer = new DefaultUrlSerializer();

    public serialize(tree: UrlTree): string {
        // Get the serialized URL from the default serializer
        let serializedUrl: string = this.defaultUrlSerializer.serialize(tree);
        serializedUrl = serializedUrl
            .replace(/%40/gi, '@')
            .replace(/%3A/gi, ':')
            .replace(/%24/gi, '$')
            .replace(/%2C/gi, ',')
            .replace(/%3B/gi, ';')
            .replace(/%3D/gi, '=')
            .replace(/%3F/gi, '?')
            .replace(/%20/gi, ' ')
            .replace(/%26/gi, '&')
            .replace(/%2F/gi, '/')
            .replace(/=$/, ''); // Remove the '=' character at the end of the URL

        return serializedUrl;
    }

    public parse(url: string): UrlTree {
        return this.defaultUrlSerializer.parse(this.serialize(this.defaultUrlSerializer.parse(url)));
    }
}
