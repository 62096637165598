import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject, combineLatest, interval, map, of, startWith, switchMap, takeUntil, tap } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { getPropertyTypeInfos, isFileValid } from 'src/app/core/utils/common';
import { PropertyCustomService } from 'src/app/features/property/services/property-custom.service';
import {
    ImportFilesService,
    ImportInfo,
    ImportStatistics,
    OwnValidationError,
    Property,
} from 'src/app/generated-sources/base';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { CellTemplate } from 'src/app/shared/table/enums/cell-template';
import { TableItem } from 'src/app/shared/table/interfaces/table-item';
import { DeleteMigrationOverlayComponent } from '../migration-delete-migration-overlay/migration-deletemigration-overlay.component';

@Component({
    selector: 'app-migration-overview',
    templateUrl: './migration-overview.component.html',
    styleUrls: ['./migration-overview.component.scss'],
})
export class MigrationOverviewComponent implements OnInit, OnDestroy {
    @ViewChild('fileInput') public fileInput: any;

    @ViewChild('expandedTemplate')
    public expandedTemplate?: TemplateRef<any>;

    public form = new UntypedFormGroup({
        propertyType: new UntypedFormControl('WEG'),
    });

    public runningImportId?: string;

    public propertyType$ =
        this.form.get('propertyType')?.valueChanges.pipe(startWith(Property.PropertyTypeEnum.Weg)) ??
        of(Property.PropertyTypeEnum.Weg);

    public importInfo$ = new BehaviorSubject<ImportInfo | undefined>(undefined);

    public stopGetImportStastistcs$ = new Subject<void>();
    private unsubscripe$ = new Subject<void>();

    public importProcessStep$ = new BehaviorSubject<ImportProcessSteps>(ImportProcessSteps.EMPTY);

    public notificationError$ = new BehaviorSubject<{ errorMessage?: string; errorDetails?: string } | undefined>(
        undefined
    );

    public migrationProgress$ = new BehaviorSubject<{ imported: number; total: number; progress: number }>({
        imported: 0,
        total: 0,
        progress: 0,
    });

    public vm?: {
        importInfo?: ImportInfo;
        propertyType: Property.PropertyTypeEnum;
        notificationError?: { errorMessage?: string; errorDetails?: string };
        importProcessStep: ImportProcessSteps;
    };

    public vm$ = combineLatest([
        this.propertyType$,
        this.importInfo$,
        this.notificationError$,
        this.importProcessStep$,
    ]).pipe(
        tap(([propertyType, importInfo, notificationError, importProcessStep]) => {
            this.vm = {
                importInfo,
                propertyType,
                notificationError,
                importProcessStep,
            };
        })
    );

    public refreshProtocolTable$ = new BehaviorSubject<void>(undefined);

    public protocolTableModel$ = this.refreshProtocolTable$.pipe(
        switchMap(() => this.importFilesService.findCurrentNonFinishedImportInfo()),
        tap((pendingImportInfo) => {
            this.importInfo$.next((pendingImportInfo as ImportInfo) ?? undefined);
            if (pendingImportInfo && pendingImportInfo.processingStatus === ImportInfo.ProcessingStatusEnum.Pending) {
                this.importProcessStep$.next(ImportProcessSteps.WAITINGFORMIGRATION);
                console.log('CURRENT STATUS ' + this.importProcessStep$.value);
            }
            if (pendingImportInfo && pendingImportInfo.processingStatus !== ImportInfo.ProcessingStatusEnum.Pending) {
                this.stopGetImportStastistcs$.next();
                this.importProcessStep$.next(ImportProcessSteps.MIGRATING);
                console.log('CURRENT STATUS ' + this.importProcessStep$.value);
                this.startUpdateInterval(pendingImportInfo.id);
            }
            if (!pendingImportInfo) {
                this.stopGetImportStastistcs$.next();
                this.importProcessStep$.next(ImportProcessSteps.EMPTY);
                this.propertyCustomService.refresh();
                console.log('CURRENT STATUS ' + this.importProcessStep$.value);
            }
        }),
        switchMap(() => {
            return this.importFilesService.findAll();
        }),
        map((files) => {
            return {
                header: [
                    'ENTITIES.COMMON.STATUS',
                    'ENTITIES.COMMON.LABEL_FILE_NAME',
                    'PAGES.MIGRATION.LABEL_MIGRATION_AT',
                    'ENTITIES.PROPERTY.LABEL_MANAGEMENT_TYPE',
                    '',
                ],
                data: files
                    // .filter((file) => file.processingStatus !== ImportInfo.ProcessingStatusEnum.Pending)
                    .map((file) => this.createRow(file)),
            };
        })
    );

    public constructor(
        private importFilesService: ImportFilesService,
        private toastService: ToastService,
        private translateService: TranslateService,
        private overlayService: OverlayService,
        private propertyCustomService: PropertyCustomService
    ) {}
    public ngOnDestroy(): void {
        this.unsubscripe$.next();
    }

    public ngOnInit(): void {
        this.vm$.subscribe();
    }

    public downloadTemplate(propertyType: Property.PropertyTypeEnum): void {
        this.importFilesService.getImportTemplate(propertyType).subscribe({
            next: (response) => {
                const link = document.createElement('a');
                const blob = new Blob([response], { type: '.zip' });
                link.href = window.URL.createObjectURL(blob);
                link.download = `Vorlage_${propertyType}_Immobilien.zip`;
                link.click();
            },
            error: (e) => {
                this.toastService.showError(e.message);
            },
        });
    }

    public fileChangeEvent(): void {
        const file = this.fileInput.nativeElement.files[0];

        if (file.size / (1024 * 1024) > 10) {
            this.notificationError$.next({
                errorMessage: this.translateService.instant('ACCOUNTING.ADD_RECEIPT.INVALID_SIZE', { filesize: 10 }),
            });
            return;
        }

        if (!isFileValid(file.name, ['xlsx', 'xls', 'XLS', 'XLSX'])) {
            this.notificationError$.next({
                errorMessage: this.translateService.instant('ACCOUNTING.ADD_RECEIPT.INVALID_FILE', {
                    formats: '.xlsx, .xlsx',
                }),
            });
            return;
        }
        this.notificationError$.next(undefined);

        if (file && this.vm?.propertyType) {
            this.importProcessStep$.next(ImportProcessSteps.UPLOADING);
            // this.isUploadingAndCheckingFile$.next(true);
            this.importFilesService.acceptImportFile(this.vm?.propertyType, file).subscribe({
                next: (importInfo) => {
                    this.importInfo$.next(importInfo);
                    this.importProcessStep$.next(ImportProcessSteps.WAITINGFORMIGRATION);
                    if (importInfo.processingStatus === 'FAILED') {
                        this.toastService.showError('Datei Upload fehlgeschlagen.');
                    } else {
                        this.toastService.showSuccess('Datei erfolgreich hochgeladen.');
                    }
                    this.notificationError$.next(undefined);
                },
                error: (e) => {
                    const errorMessage = e?.error?.message;
                    this.notificationError$.next({
                        errorMessage: errorMessage ? file.name : 'Dateiupload fehlgeschlagen.',
                        errorDetails: e?.error?.message,
                    });
                    this.toastService.showError('Datei Upload fehlgeschlagen.');
                    this.importProcessStep$.next(ImportProcessSteps.EMPTY);
                },
            });
        }
    }

    public startMigration(importInfo: ImportInfo | undefined): void {
        if (!importInfo) {
            return;
        }
        this.importProcessStep$.next(ImportProcessSteps.MIGRATING);
        this.runningImportId = importInfo.id;

        this.importFilesService.importFile(importInfo.id).subscribe({
            next: () => {
                this.toastService.showSuccess('Migration erfolgreich gestartet');
                this.refreshProtocolTable$.next();
            },
            error: (e) => {
                this.toastService.showError('Migration Starten fehlgeschlagen: ' + e?.error?.message);
                this.importProcessStep$.next(ImportProcessSteps.ERROR);
                this.refreshProtocolTable$.next();
            },
        });
    }

    private startUpdateInterval(id: string): void {
        interval(5000)
            .pipe(
                takeUntil(this.stopGetImportStastistcs$),
                switchMap(() => this.importFilesService.getImportInfoStatistics(id)),
                tap((importStatistics) => {
                    const total = Object.values(importStatistics).reduce((acc, current) => acc + current.total, 0);
                    const imported = Object.values(importStatistics).reduce(
                        (acc, current) => acc + current.processed,
                        0
                    );
                    console.log('UPDATE DATA');
                    const progress = (imported * 100) / total;
                    this.migrationProgress$.next({ total, progress, imported });
                    this.refreshProtocolTable$.next();
                })
            )
            .subscribe();
    }

    public deleteMigrationFile(id?: string): void {
        if (!id) {
            id = this.vm?.importInfo?.id;
        }
        if (id) {
            this.importFilesService.removeImportInfo(id).subscribe({
                next: () => {
                    this.toastService.showSuccess('Datei erfolgreich gelöscht');
                    this.refreshProtocolTable$.next();
                    this.importProcessStep$.next(this.ImportProcessStepsEnum.EMPTY);
                },
                error: (e) => {
                    if (e) {
                        this.toastService.showError(e.error['message']);
                    }
                },
            });
        }
    }

    private determineStatusSrc(status: ImportInfo.ProcessingStatusEnum): { textColor: string; iconSrc: string } {
        if (status === ImportInfo.ProcessingStatusEnum.Successful) {
            return {
                textColor: 's-green-01',
                iconSrc: '/assets/icons/24_closed.svg',
            };
        } else if (
            status === ImportInfo.ProcessingStatusEnum.Ongoing ||
            status === ImportInfo.ProcessingStatusEnum.Pending ||
            status === ImportInfo.ProcessingStatusEnum.SqlImported
        ) {
            return {
                textColor: 's-blue-01',
                iconSrc: '/assets/icons/24_progress.svg',
            };
        } else {
            return {
                textColor: 's-red-01',
                iconSrc: '/assets/icons/24_error.svg',
            };
        }
    }

    private createRow(item: ImportInfo): TableItem[] {
        return [
            {
                data: {
                    label: this.translateService.instant('ENTITIES.MIGRATION.LABEL_' + item.processingStatus),
                    ...this.determineStatusSrc(item.processingStatus),
                    extraData: {},
                },
                template: CellTemplate.DynamicIcon,
                expandedData: {
                    errorMessage: item.errorMessage,
                    validationError: this.createStructuredValidationError(item),
                    importStatistics: item.importStatistics,
                },
                expandedTemplate: this.expandedTemplate,
            },
            {
                data: {
                    label: item.fileName,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: item.updatedAt ? item.updatedAt : '',
                },
                template: CellTemplate.DateTime,
            },
            {
                data: {
                    label: item.propertyType,
                    textColor: getPropertyTypeInfos(item.propertyType).color,
                },
                template: CellTemplate.contentWithTagStyle,
            },
            this.addDeleteButton(item),
        ];
    }
    private createStructuredValidationError(item: ImportInfo): string[] {
        const rows: string[] = [];
        if (item.validationErrors?.errors) {
            for (const row of item.validationErrors.errors) {
                let text = `Zeile ${(row.row ?? 0) + 3}:`;
                (Object.keys(row) as (keyof typeof row)[]).forEach((key) => {
                    if (typeof row[key] === 'object') {
                        const array = row[key] as [OwnValidationError];
                        for (const error of array) {
                            const errorText = Object.values(error.constraints as object).join('" , "');
                            text += `\n Attribut: ${error.property} mit Wert "${error.value}"     Fehler: "${errorText} "`;
                        }
                    }
                });
                rows.push(text);
            }
        }
        return rows;
    }

    private addDeleteButton(item: ImportInfo): TableItem {
        if (
            item.processingStatus === ImportInfo.ProcessingStatusEnum.Failed ||
            item.processingStatus === ImportInfo.ProcessingStatusEnum.Failed
        ) {
            return {
                data: {
                    label: '',
                    textColor: 's-gray-02',
                    iconSrc: '/assets/icons/24_delete.svg',
                    extraData: {
                        showOnlyWithHover: true,
                        eventName: 'deleteMigration',
                        importInfo: item,
                    },
                },
                template: CellTemplate.iconButton,
            };
        }
        return {
            data: {
                label: '',
            },
            template: CellTemplate.Default,
        };
    }

    public closeNotification(): void {
        this.importProcessStep$.next(ImportProcessSteps.EMPTY);
    }

    public getImportStatistics(importStatistics: any): ImportStatistics {
        return importStatistics as ImportStatistics;
    }

    public handlingEvent($event: any): void {
        if ($event.extraData && $event.extraData.eventName === 'deleteMigration') {
            this.openDeleteMigrationOverlay($event);
        }
    }

    public openDeleteMigrationOverlay($event: any): void {
        const data = {
            importInfo: $event.extraData.importInfo,
        };

        const ref = this.overlayService.open(DeleteMigrationOverlayComponent, {
            data,
        });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscripe$)).subscribe(() => {
            ref.close();
        });
        ref.saveEmitter$.pipe(takeUntil(this.unsubscripe$)).subscribe(() => {
            this.refreshProtocolTable$.next();
            this.propertyCustomService.refresh();
        });
    }

    public ImportProcessStepsEnum = ImportProcessSteps;
}

export enum ImportProcessSteps {
    // Opened Page and there is no ongoing migration
    EMPTY = 'EMPTY',
    // State while Uploading a file
    UPLOADING = 'UPLOADING',
    //Successful Uploaded and pending for the cklick
    WAITINGFORMIGRATION = 'WAITING_FOR_MIGRATION',
    //Migration is running and Status can be requested reqularly
    MIGRATING = 'MIGRATING',
    //Successful migration
    SUCCESS = 'SUCCESS',
    // read below :-)
    ERROR = 'ERROR',
}
