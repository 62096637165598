import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, of, switchMap } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { formatDateYYYYMMDDWithoutHours } from 'src/app/core/utils/common';
import { Occupation, OccupationService, UpdateOccupationDto } from 'src/app/generated-sources/base';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';

@Component({
    selector: 'app-edit-occupation-period',
    templateUrl: './edit-occupation-period.component.html',
    styleUrls: ['./edit-occupation-period.component.scss'],
})
export class EditOccupationPeriodComponent extends OverlayChildComponent implements OnInit, OnDestroy {
    public occupation?: Occupation;
    public propertyId = '';
    public unsubscribe$ = new Subject<void>();
    public currentYear = new Date().getFullYear();
    public _startDateArray: Date[] = [];
    public _endDateArray: Date[] = [];
    public showOccupationDurationWarning = false;
    public isEndOccupation = false;
    public isLoading = false;
    public showWarningMessage = false;

    public constructor(
        private occupationService: OccupationService,
        private toast: ToastService,
        private translateService: TranslateService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.occupation = this.config?.data.occupation;
        this.propertyId = this.config?.data.propertyId;

        this._startDateArray = this.occupation?.from ? [new Date(this.occupation?.from)] : [];
        this._endDateArray = this.occupation?.to ? [new Date(this.occupation?.to)] : [];

        this.isEndOccupation = this.config?.data.isEndOccupation;
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public submit(): void {
        console.log(this.showWarningMessage);
        if (!this.occupation?.id || !this.propertyId) {
            return;
        }

        this.isLoading = true;

        const updateOccupationDurationDto: UpdateOccupationDto = {
            from: this.startDate ? formatDateYYYYMMDDWithoutHours(this.startDate) : undefined,
            to: this.endDate ? formatDateYYYYMMDDWithoutHours(this.endDate) : null!,
        };

        const updateOccupationObs = this.updateOccupationObs(
            this.propertyId,
            this.occupation.id,
            updateOccupationDurationDto
        );

        let obs;

        if (this.showWarningMessage) {
            obs = updateOccupationObs;
        } else {
            obs = this.occupationService
                .showOccupationRentReceivablesWarning(this.propertyId, this.occupation?.id, updateOccupationDurationDto)
                .pipe(
                    switchMap((showOccupationDurationWarning) => {
                        this.showOccupationDurationWarning = showOccupationDurationWarning;

                        if (showOccupationDurationWarning || !this.occupation?.id) {
                            return of(null);
                        }

                        return this.updateOccupationObs(
                            this.propertyId,
                            this.occupation.id,
                            updateOccupationDurationDto
                        );
                    })
                );
        }

        obs.subscribe({
            next: () => {
                this.isLoading = false;
                this.updateWarningMessageVisibility();
                if (this.showOccupationDurationWarning && this.showWarningMessage) {
                    return;
                }

                this.toast.showSuccess(
                    this.translateService.instant(
                        this.isEndOccupation
                            ? 'PAGES.OCCUPATION.END.SUCCESS_TOAST'
                            : 'PAGES.OCCUPATION.EDIT.EDIT_OCCUPATION_PERIOD_TOAST_SUCCESS'
                    )
                );
                this.saveEmitter$.next();
            },
            error: (error: any) => {
                this.toast.showError(error.error['message']);
                this.isLoading = false;
            },
        });
    }

    public isDateInputValid(): boolean {
        if (this.endDate && this.startDate) {
            return this.endDate.getTime() > this.startDate.getTime();
        }

        return true;
    }

    public get startDate(): Date | null {
        return this._startDateArray.length ? this._startDateArray[0] : null;
    }

    public get endDate(): Date | null {
        return this._endDateArray.length ? this._endDateArray[0] : null;
    }

    public updateOccupationObs(
        propertyId: string,
        occupationId: string,
        updateOccupationDurationDto: UpdateOccupationDto
    ): Observable<Occupation> {
        return this.occupationService.updateOccupation(propertyId, occupationId, updateOccupationDurationDto);
    }

    public updateWarningMessageVisibility(): void {
        this.showWarningMessage = !this.showWarningMessage;
    }
}
