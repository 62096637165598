<ng-container *ngIf="this.variant === 'header'">
    <th
        *ngFor="let column of this.columns; let i = index; let first = first; let last = last"
        [id]="column.key"
        class="s-label-12-22-semibold tw-px-2 tw-h-8 tw-pb-4 tw-border-b tw-border-scalaraGray-05 tw-border-solid
        {{ first ? 'tw-text-left' : 'tw-text-right' }}  {{ column.columnCellClass }}"
    >
        {{ column.label }}
    </th>
</ng-container>
<ng-container *ngIf="this.variant === 'body'">
    <ng-container *ngFor="let column of this.columns; let i = index; let first = first; let last = last">
        <!-- apply basic classes only if the value is present, so that there will be no cells with no value, but height 32px-->

        <ng-container *ngIf="getRowCell(column.key) as cell; else emptyCell">
            <ng-container *ngIf="show$ | async">
                <ng-container *ngIf="cell.type === 'value'">
                    <td
                        [routerLink]="cell.link"
                        class="{{ (cell.value || cell.value === 0) && 'tw-px-2 tw-h-8 tw-align-middle' }} {{
                            first ? 'tw-text-left' : 'tw-text-right'
                        }} {{ cell.link ? 'pointer' : '' }}
            {{ cell.rowCellClass }}"
                    >
                        <ng-container *ngIf="!cell?.wrapWithPre">{{ cell.value ?? '' }}</ng-container>
                        <ng-container *ngIf="cell?.wrapWithPre">
                            <pre>{{ cell?.value ?? '' }}</pre>
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container *ngIf="cell.type === 'component'">
                    <td
                        class="{{ cell?.rowCellClass }}"
                        dynamicComponentWrapper
                        [component]="cell.component"
                        [componentData]="cell.componentData"
                    ></td>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-container>

<ng-template #emptyCell><td></td></ng-template>
