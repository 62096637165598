/**
 * SCALARA Accounting Module
 * The SCALARA Accounting API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BankAccountDto } from './bankAccountDto';


export interface PaymentDto { 
    id: string;
    counterpartName: string;
    counterpartIban: string;
    counterpartBic?: string;
    purpose?: string;
    executionDate: string;
    approvalDate?: string;
    amount: number;
    type: PaymentDto.TypeEnum;
    webformStatus: PaymentDto.WebformStatusEnum;
    paymentStatus: PaymentDto.PaymentStatusEnum;
    errorMessage?: string;
    sepaPurposeCode?: PaymentDto.SepaPurposeCodeEnum;
    bankAccount: BankAccountDto;
    creditorIdentifier: string;
    property?: object;
    mandateId?: string;
    counterpartPersonId?: string;
    ledgerId: string;
}
export namespace PaymentDto {
    export type TypeEnum = 'MONEY_TRANSFER' | 'DIRECT_DEBIT';
    export const TypeEnum = {
        MoneyTransfer: 'MONEY_TRANSFER' as TypeEnum,
        DirectDebit: 'DIRECT_DEBIT' as TypeEnum
    };
    export type WebformStatusEnum = 'OPEN' | 'ERROR' | 'DONE';
    export const WebformStatusEnum = {
        Open: 'OPEN' as WebformStatusEnum,
        Error: 'ERROR' as WebformStatusEnum,
        Done: 'DONE' as WebformStatusEnum
    };
    export type PaymentStatusEnum = 'OPEN' | 'PROCESSING' | 'ERROR' | 'DONE' | 'EXPORTED' | 'INCOMPLETE';
    export const PaymentStatusEnum = {
        Open: 'OPEN' as PaymentStatusEnum,
        Processing: 'PROCESSING' as PaymentStatusEnum,
        Error: 'ERROR' as PaymentStatusEnum,
        Done: 'DONE' as PaymentStatusEnum,
        Exported: 'EXPORTED' as PaymentStatusEnum,
        Incomplete: 'INCOMPLETE' as PaymentStatusEnum
    };
    export type SepaPurposeCodeEnum = 'RENT' | 'REFU' | 'SCVE';
    export const SepaPurposeCodeEnum = {
        Rent: 'RENT' as SepaPurposeCodeEnum,
        Refu: 'REFU' as SepaPurposeCodeEnum,
        Scve: 'SCVE' as SepaPurposeCodeEnum
    };
}


