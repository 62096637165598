import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ListItem } from 'carbon-components-angular';
import { Subject, finalize, switchMap, takeUntil, tap } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import {
    AccountDto,
    AccountsService,
    OesCarryoverUpdateDto,
    OperationsExpenseStatementsService,
} from 'src/app/generated-sources/accounting';

@Component({
    selector: 'app-oes-carryover-costs',
    templateUrl: './oes-carryover-costs.component.html',
    styleUrls: ['./oes-carryover-costs.component.scss'],
})
export class OesCarryoverCostsComponent implements OnInit {
    @Input() public ledgerId = '';
    @Input() public oesId = '';
    @Output() public submitAndContinue = new EventEmitter<void>();
    @Output() public submitAndClose = new EventEmitter<void>();
    @Output() public closePage = new EventEmitter<void>();

    public accounts: AccountDto[] = [];
    public accountList: ListItem[] = [];
    public form: UntypedFormGroup = this.formBuilder.group({
        accounts: this.formBuilder.array([]),
    });
    public isLoading = false;
    private unsubscribe$ = new Subject<void>();

    public constructor(
        private accountsService: AccountsService,
        private formBuilder: UntypedFormBuilder,
        private operationsExpenseStatementsService: OperationsExpenseStatementsService,
        private toastService: ToastService
    ) {}

    public ngOnInit(): void {
        this.isLoading = true;
        this.accountsService
            .findAll(this.ledgerId)
            .pipe(
                switchMap((accounts) => {
                    this.accounts = accounts.filter((item) => item.type === 'EXPENSE');
                    this.accountList = this.accounts.map((item) => {
                        return {
                            content: item.name,
                            value: item.id,
                            selected: false,
                        };
                    });
                    return this.operationsExpenseStatementsService.getCarryoverCosts(this.ledgerId, this.oesId);
                }),
                tap((costs) => {
                    if (costs.items?.length > 0) {
                        costs.items?.map((cost) => {
                            const selectedAccount = this.accountList.find((item, index) => {
                                if (item['value'] === cost.accountId) {
                                    this.accountList[index].selected = true;
                                    return true;
                                }
                                return false;
                            });
                            this.addNewAccount(selectedAccount, cost.amount);
                        });
                    } else {
                        this.addNewAccount();
                    }

                    this.isLoading = false;
                })
            )
            .subscribe();
    }

    public get accountsControl(): UntypedFormArray {
        return this.form.controls['accounts'] as UntypedFormArray;
    }

    public getAccountItemForm(index: number): UntypedFormGroup {
        return this.accountsControl.at(index) as UntypedFormGroup;
    }

    public addNewAccount(account?: ListItem, amout?: number): void {
        this.accountsControl.push(
            this.formBuilder.group({
                accountList: [this.accountList],
                amount: [amout || null],
                selectedAccount: [account || null],
            })
        );
    }

    public removeAccount(index: number): void {
        this.accountsControl.removeAt(index);
    }

    public sumTotal(): number {
        let sumTotal = 0;
        this.accountsControl.value?.map((item: any) => {
            sumTotal = sumTotal + item.amount || 0;
        });
        return sumTotal;
    }

    public abort(): void {
        this.closePage.emit();
    }

    public isInvalidForm(): boolean {
        return false;
    }

    public submit(saveAndClose = false): void {
        const oesCarryoverUpdateDto: OesCarryoverUpdateDto = {
            items: this.accountsControl?.value.map((item: any) => {
                return {
                    accountId: item.selectedAccount?.value,
                    amount: item.amount,
                };
            }),
        };

        this.operationsExpenseStatementsService
            .updateCarryoverCosts(this.ledgerId, this.oesId, oesCarryoverUpdateDto)
            .pipe(
                takeUntil(this.unsubscribe$),
                finalize(() => (this.isLoading = false))
            )
            .subscribe({
                next: () => {
                    if (saveAndClose) {
                        this.submitAndClose.emit();
                    } else {
                        this.submitAndContinue.emit();
                    }
                },
                error: (error) => {
                    if (error) {
                        this.toastService.showError(error.error['message']);
                    }
                },
            });
    }
}
