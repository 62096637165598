import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RadioTileSelectorOption } from 'src/app/core/models/radio-tile-selector-option.model';

@Component({
    selector: 'app-radio-tile-selector',
    templateUrl: './radio-tile-selector.component.html',
    styleUrls: ['./radio-tile-selector.component.scss'],
})
export class RadioTileSelectorComponent implements OnInit {
    public isOptionSelected: boolean[] = [false];

    @Input()
    public tileSelectorOptions: RadioTileSelectorOption[] = [];

    @Output()
    public optionSelected = new EventEmitter<number>();

    public ngOnInit(): void {
        for (let i = 0; i < this.tileSelectorOptions.length; i++) {
            if (i == 0) {
                this.isOptionSelected[i] = true;
            } else {
                this.isOptionSelected[i] = false;
            }
        }
    }

    public selectOption(index: number): void {
        for (let i = 0; i < this.tileSelectorOptions.length; i++) {
            if (i == index) {
                this.isOptionSelected[i] = true;
                this.optionSelected.emit(index);
            } else {
                this.isOptionSelected[i] = false;
            }
        }
    }
}
