/**
 * SCALARA Accounting Module
 * The SCALARA Accounting API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BookingDto } from './bookingDto';


export interface OpenItemDto { 
    dueDate: string;
    id: string;
    createdAt: string;
    updatedAt: string;
    description: string;
    amount: number;
    outstandingBalance: number;
    category?: OpenItemDto.CategoryEnum;
    state: OpenItemDto.StateEnum;
    ledgerId: string;
    bookings?: Array<BookingDto>;
    type?: OpenItemDto.TypeEnum;
    purpose?: string;
    vatRate?: number;
    vatAmount?: number;
    bookingAmount?: number;
}
export namespace OpenItemDto {
    export type CategoryEnum = 'EXPENSE' | 'REVENUE' | 'LIABILITY' | 'RECEIVABLE';
    export const CategoryEnum = {
        Expense: 'EXPENSE' as CategoryEnum,
        Revenue: 'REVENUE' as CategoryEnum,
        Liability: 'LIABILITY' as CategoryEnum,
        Receivable: 'RECEIVABLE' as CategoryEnum
    };
    export type StateEnum = 'OPEN' | 'PARTLY_CLOSED' | 'CLOSED';
    export const StateEnum = {
        Open: 'OPEN' as StateEnum,
        PartlyClosed: 'PARTLY_CLOSED' as StateEnum,
        Closed: 'CLOSED' as StateEnum
    };
    export type TypeEnum = 'ADVANCEMENT' | 'RECEIVABLE' | 'LIABILITY' | 'RENT_RECEIVABLE';
    export const TypeEnum = {
        Advancement: 'ADVANCEMENT' as TypeEnum,
        Receivable: 'RECEIVABLE' as TypeEnum,
        Liability: 'LIABILITY' as TypeEnum,
        RentReceivable: 'RENT_RECEIVABLE' as TypeEnum
    };
}


