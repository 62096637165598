<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"></ibm-loading>

<app-signin-signup-container routeLink="sign-up" *ngIf="this.flowType">
    <div>
        <h1 class="s-head-28-36-bold mb-3r mt-2r">{{ this.conditionalTextLabels[this.flowType].headerLabel }}</h1>
        <p class="mb-2r">{{ 'PAGES.RESET_PASSWORD.GIVE_EMAIL.SUBHEADER' | translate }}</p>
        <form (submit)="onSubmit()" [formGroup]="form">
            <!-- EMAIL -->
            <ibm-label [invalid]="emailInvalid" invalidText="{{ 'CONTROLS.FORMCONTROLS.EMAIL.INVALID' | translate }}">
                {{ 'CONTROLS.FORMCONTROLS.EMAIL.LABEL' | translate }}*
                <input
                    ibmText
                    type="text"
                    placeholder=""
                    name="email"
                    id="email"
                    formControlName="email"
                    [invalid]="emailInvalid"
                />
            </ibm-label>

            <div class="d-flex justify-space-between mt-3r align-items-center">
                <a routerLink="/sign-in">{{ 'CONTROLS.LINKS.BACK_TO_LOGIN' | translate }}</a>
                <app-scalara-button type="submit" variant="default" [disabled]="form.invalid">
                    {{ this.conditionalTextLabels[this.flowType].buttonLabel }}
                </app-scalara-button>
            </div>
        </form>
    </div>
</app-signin-signup-container>
