<div *ngIf="!data.excluded" class="d-flex justify-content-end">
    <app-tooltip position="left">
        <div content class="delete-button_tooltip-content s-gray-01">
            Sie können diese Kosten für diese Nutzung entfernen. Die Kosten werden nicht auf die restlichen Nutzungen
            aufgeteilt.
        </div>

        <div hoverable-item class="hoverable-item">
            <app-scalara-button
                (click)="deleteAccount()"
                variant="icon-only"
                height="40px"
                svgName="24_close.svg"
            ></app-scalara-button>
        </div>
    </app-tooltip>
</div>

<div class="d-flex align-items-center justify-content-end" *ngIf="data.excluded">
    <app-tooltip position="left">
        <div content class="delete-button_tooltip-content s-gray-01">
            Sie haben diese Kosten für diese Einheit entfernt.
        </div>
        <div hoverable-item class="hoverable-item">
            <div class="tw-s-head-14-22-regular tw-text-scalaraGray-04 mr-0-75r">Entfernt</div>
        </div>
    </app-tooltip>

    <app-tooltip position="left">
        <div content class="delete-button_tooltip-content s-gray-01">
            Sie haben diese Kosten für diese Einheit entfernt, können dies aber rückgängig machen.
        </div>
        <div hoverable-item class="hoverable-item">
            <app-scalara-button
                (click)="includeAccount()"
                variant="icon-only"
                height="40px"
                svgName="24_cancel.svg"
            ></app-scalara-button>
        </div>
    </app-tooltip>
</div>
