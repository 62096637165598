import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface MultiSelectorList {
    content: string;
    selected: boolean;
    id: string;
}

@Component({
    selector: 'app-multi-selector-list',
    templateUrl: './multi-selector-list.component.html',
    styleUrls: ['./multi-selector-list.component.scss'],
})
export class MultiSelectorListComponent {
    @Input() public itemList: MultiSelectorList[] = [];
    @Output() public updateSelectedItems = new EventEmitter<MultiSelectorList[]>();

    public constructor() {}

    public onSelectItem(index: number): void {
        this.itemList[index].selected = !this.itemList[index].selected;
        this.updateSelectedItems.emit(this.itemList);
    }
}
