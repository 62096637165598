<app-signin-signup-container routeLink="sign-in" rightImgSrc="/assets/images/sign_up_image.png">
    <div class="sign-up-container">
        <h1 class="s-head-28-36-bold mb-3r mt-5r">{{ 'PAGES.SIGN_UP.HEADER' | translate }}</h1>
        <form (submit)="onSignUp()" [formGroup]="form">
            <app-person-type-and-name-form></app-person-type-and-name-form>

            <div class="s-body-14-22-bold pb-1-5r pt-2r">{{ 'PAGES.SIGN_UP.LOGIN_DATA' | translate }}</div>

            <!-- EMAIL -->
            <ibm-label [invalid]="emailInvalid" invalidText="{{ 'CONTROLS.FORMCONTROLS.EMAIL.INVALID' | translate }}">
                {{ 'CONTROLS.FORMCONTROLS.EMAIL.LABEL' | translate }}*
                <input
                    ibmText
                    type="text"
                    placeholder=""
                    name="email"
                    id="email"
                    formControlName="email"
                    [invalid]="emailInvalid"
                />
            </ibm-label>
            <!-- PASSWORD -->
            <app-password-input
                [form]="form"
                inputName="password"
                label="{{ 'CONTROLS.FORMCONTROLS.PASSWORD.LABEL' | translate }}*"
            ></app-password-input>
            <!-- PASSWORD WIEDERHOLEN -->
            <app-password-input
                [form]="form"
                inputName="confirmPassword"
                label="{{ 'CONTROLS.FORMCONTROLS.CONFIRM_PASSWORD.LABEL' | translate }}*"
            ></app-password-input>

            <div class="mt-1r"></div>

            <!-- Wie sind Sie auf uns gekommen?* -->
            <ibm-label>
                <ibm-select
                    [ngClass]="'s-body-14-20-regular'"
                    formControlName="registrationInfo"
                    [invalid]="sourcesInvalid"
                    invalidText="{{ 'CONTROLS.FORMCONTROLS.SOURCE.INVALID' | translate }}"
                    label="{{ 'CONTROLS.FORMCONTROLS.SOURCE.LABEL' | translate }} *"
                >
                    <option value="" selected>
                        {{ 'CONTROLS.FORMCONTROLS.SOURCE.DEFAULT_OPTION' | translate }}
                    </option>
                    <option *ngFor="let source of sources" value="{{ source }}">{{ source }}</option>
                </ibm-select>
            </ibm-label>

            <!-- Checkbox -->
            <div class="s-body-14-22-regular">
                <div class="s-body-14-22-regular mb-1r">
                    {{ 'PAGES.SIGN_UP.CHECKBOX_MESSAGE_PART1' | translate }}
                    <a target="_blank" href="https://www.scalara.de/terms">
                        {{ 'CONTROLS.LINKS.PRIVACY_POLICY' | translate }}
                    </a>
                    &nbsp;
                    {{ 'PAGES.SIGN_UP.CHECKBOX_MESSAGE_PART2' | translate }}
                </div>
                <ibm-checkbox formControlName="generalTerms" class="sign-up-checkbox">
                    <span>{{ 'PAGES.SIGN_UP.GENERAL_TERMS_PART1' | translate }}</span>
                    <a target="_blank" href="https://www.scalara.de/terms"
                        >{{ 'CONTROLS.LINKS.GENERAL_TERMS' | translate }}.</a
                    >
                </ibm-checkbox>
                <small class="s-red-01 checkbox-error" *ngIf="generalTermsInvalid">
                    {{ 'PAGES.SIGN_UP.ERROR.GENERAL_TERMS' | translate }}
                </small>
            </div>

            <div class="d-flex justifiy-content-end mt-4r">
                <app-scalara-button type="submit" variant="default" [disabled]="form.invalid">
                    {{ 'CONTROLS.BUTTONS.REGISTER' | translate }}
                </app-scalara-button>
            </div>
        </form>
    </div>
</app-signin-signup-container>

<ibm-toast *ngIf="notification" [notificationObj]="notification"></ibm-toast>
