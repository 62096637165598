import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from 'src/app/core/services/toast.service';
import { Building } from 'src/app/generated-sources/base';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';

@Component({
    selector: 'app-delete-building-overlay',
    templateUrl: './delete-building-overlay.component.html',
    styleUrls: ['./delete-building-overlay.component.scss'],
})
export class DeleteBuildingOverlayComponent extends OverlayChildComponent implements OnInit {
    public buildingToDelete?: Building;
    public propertyId = '';

    public constructor(
        private translateService: TranslateService,
        private toastService: ToastService,
        private router: Router
    ) {
        super();
    }

    public ngOnInit(): void {
        this.buildingToDelete = this.config?.data.building;
        this.propertyId = this.config?.data.propertyId;
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public onSubmit(): void {
        this.toastService.showSuccess(this.translateService.instant('PAGES.DELETE_BUILDING.SUCCESS_TOAST'));
        this.saveEmitter$.next();
        this.router.navigate(['/properties', this.propertyId, 'base-data']);
    }
}
