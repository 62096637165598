import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-icon-info-box',
    templateUrl: './icon-info-box.component.html',
    styleUrls: ['./icon-info-box.component.scss'],
})
export class IconInfoBoxComponent {
    @Input() public iconName?: string;
    @Input() public iconColor?: 'green' | 'orange' | 'purple' | 'red' | 'blue' | 'gray';
}
