/**
 * SCALARA Base Module
 * The SCALARA API for User, Person and Property resources
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Property } from './property';


export interface Unit { 
    id: string;
    type: Unit.TypeEnum;
    name: string;
    buildingId: string;
    buildingName: string;
    location: string;
    ownershipId?: string;
    property?: Property;
    createdAt?: string;
    updatedAt?: string;
    permissionRoles: Array<Unit.PermissionRolesEnum>;
    heatedSquaremeters?: number;
    squaremeters?: number;
    isNotResidential?: boolean;
    roomCount?: string;
    condition?: string;
    inventory?: string;
    additionalInformation?: string;
}
export namespace Unit {
    export type TypeEnum = 'APARTMENT' | 'BUSINESS' | 'PARKING_SPACE' | 'BASEMENT' | 'GARDEN' | 'TERRACE' | 'CONSERVATORY' | 'OTHER';
    export const TypeEnum = {
        Apartment: 'APARTMENT' as TypeEnum,
        Business: 'BUSINESS' as TypeEnum,
        ParkingSpace: 'PARKING_SPACE' as TypeEnum,
        Basement: 'BASEMENT' as TypeEnum,
        Garden: 'GARDEN' as TypeEnum,
        Terrace: 'TERRACE' as TypeEnum,
        Conservatory: 'CONSERVATORY' as TypeEnum,
        Other: 'OTHER' as TypeEnum
    };
    export type PermissionRolesEnum = 'PROPERTY_MANAGER' | 'OWNER' | 'SERVICE_PROVIDER' | 'ADVISER' | 'CO_OWNER' | 'TENANT';
    export const PermissionRolesEnum = {
        PropertyManager: 'PROPERTY_MANAGER' as PermissionRolesEnum,
        Owner: 'OWNER' as PermissionRolesEnum,
        ServiceProvider: 'SERVICE_PROVIDER' as PermissionRolesEnum,
        Adviser: 'ADVISER' as PermissionRolesEnum,
        CoOwner: 'CO_OWNER' as PermissionRolesEnum,
        Tenant: 'TENANT' as PermissionRolesEnum
    };
}


