import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ListItem } from 'carbon-components-angular';
import { Subject, takeUntil, tap } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import {
    CreateWssDto,
    EconomicPlanDto,
    EconomicPlansService,
    WegSettlementStatementsService,
    WssDto,
} from 'src/app/generated-sources/accounting';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';

@Component({
    selector: 'app-add-annual-statement',
    templateUrl: './add-annual-statement.component.html',
    styleUrls: ['./add-annual-statement.component.scss'],
})
export class AddAnnualStatementComponent extends OverlayChildComponent implements OnDestroy, OnInit {
    private unsubscribe$ = new Subject<void>();
    public isLoading = false;

    public economicPlansList: ListItem[] = [];

    public currentYear = new Date().getFullYear();
    public currentYearendDate = new Date(this.currentYear, 11, 31);

    public isChoosingEconomicPlan = true;
    public economicPlanAlreadyInUse = false;

    public form: UntypedFormGroup = new UntypedFormGroup({});

    public ledgerId = '';
    public annualStatements: WssDto[] = [];
    public selectedEconomicPlanId = '';

    public constructor(
        private toastService: ToastService,
        private economicPlanService: EconomicPlansService,
        private translateService: TranslateService,
        private formBuilder: UntypedFormBuilder,
        private annualStatementsService: WegSettlementStatementsService,
        private datePipe: DatePipe,
        private router: Router
    ) {
        super();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public ngOnInit(): void {
        this.isLoading = true;
        this.ledgerId = this.config?.data?.ledgerId;
        this.annualStatements = this.config?.data?.annualStatements;
        this.economicPlanService
            .findAll(this.ledgerId)
            .pipe(
                tap((economicPlans: EconomicPlanDto[]) => {
                    this.prepareEconomicPlansItemList(economicPlans);
                    this.isLoading = false;
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();

        this.form = this.createForm();
    }

    private prepareEconomicPlansItemList(economicPlans: EconomicPlanDto[]): void {
        const list: ListItem[] = [];
        const currentYearLastDay = this.datePipe.transform(this.currentYearendDate, 'dd.MM.yyyy') || '';
        economicPlans.map((economicPlan: EconomicPlanDto) => {
            const economicPlanEndDate = this.datePipe.transform(economicPlan.endDate, 'dd.MM.yyyy');
            if (
                economicPlan.status === EconomicPlanDto.StatusEnum.Active &&
                economicPlanEndDate! <= currentYearLastDay
            ) {
                const content =
                    this.datePipe.transform(economicPlan.startDate, 'dd.MM.yyyy') + ' – ' + economicPlanEndDate;

                list.push({
                    content: content,
                    selected: false,
                    value: economicPlan.id,
                    startDate: economicPlan.startDate,
                    endDate: economicPlan.endDate,
                });
            }
        });
        if (list.length) {
            list[0].selected = true;
            this.selectedEconomicPlanId = list[0]['value'];
            this.economicPlanAlreadyInUse = this.isEconomicPlanAlreadyInAnnualStatement(list[0]['value']);
        }
        this.economicPlansList = list;
    }

    public createForm(): UntypedFormGroup {
        return this.formBuilder.group({
            economicPlanSelection: ['', [Validators.required]],
        });
    }

    public onSelectEconomicPlanYear($event: any): void {
        this.selectedEconomicPlanId = $event.item.value;
        this.economicPlanAlreadyInUse = this.isEconomicPlanAlreadyInAnnualStatement($event.item.value);
    }

    public changeToConfirmation(): void {
        this.isChoosingEconomicPlan = false;
    }

    public isEconomicPlanAlreadyInAnnualStatement(selectedEconomicPlanId: string): boolean {
        const index = this.annualStatements.findIndex(
            (annualStatement: WssDto) => annualStatement.economicPlanId === selectedEconomicPlanId
        );
        if (index >= 0) {
            return true;
        } else {
            return false;
        }
    }

    public onSubmit(): void {
        this.isLoading = true;

        const createWssDto: CreateWssDto = {
            economicPlanId: this.selectedEconomicPlanId,
        };

        this.annualStatementsService
            .create(this.ledgerId, createWssDto)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: () => {
                    this.router.navigate(['/accounting', 'ledger', this.ledgerId, 'annual-statements']);
                    this.isLoading = false;
                    this.toastService.showSuccess(
                        this.translateService.instant('PAGES.ANNUAL_STATEMENT.ADD.TOAST_SUCCESS_MESSAGE')
                    );
                    this.saveEmitter$.next();
                },
                error: (error: any) => {
                    if (error) {
                        this.toastService.showError(error.error['message']);
                    }
                },
            });
    }

    public abort(): void {
        if (this.isChoosingEconomicPlan) {
            this.cancelEmitter$.next();
        } else {
            this.isChoosingEconomicPlan = true;
        }
    }
}
