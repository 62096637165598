import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    BehaviorSubject,
    Observable,
    ObservedValueOf,
    Subject,
    catchError,
    combineLatest,
    filter,
    map,
    of,
    shareReplay,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import {
    PaymentItem,
    Reserve,
    SettlementRow,
    SimpleOwnershipSettlementDto,
    WegSettlementStatementsService,
} from 'src/app/generated-sources/accounting';
import { FiletoView } from 'src/app/shared/components/file-viewer/file-viewer.component';
import { Column, RowData } from 'src/app/shared/components/simple-table/simple-table-row/simple-table-row.component';
import { EurocentPipe } from 'src/app/shared/pipes/eurocent.pipe';
import { TableComponent } from 'src/app/shared/table/components/table/table.component';
import { CellTemplate } from 'src/app/shared/table/enums/cell-template';
import { HeaderTemplate } from 'src/app/shared/table/enums/header-template';
import { TableItem } from 'src/app/shared/table/interfaces/table-item';
import { TableModel } from 'src/app/shared/table/interfaces/table-model';
import { AccountingFilterCustomService } from '../../accounting/components/services/accounting-filter-custom.service';
import { CustomWssService } from '../custom-wss.service';

type ColumnKey =
    | 'name'
    | 'totalAmount'
    | 'distributionKeyName'
    | 'distributionKeyTotal'
    | 'distributionShare'
    | 'ownershipAmount';

type PaymentColoumKey = 'paymentDate' | 'paymentDescription' | 'paymentTotalAmount' | 'paymentSubItemAmount';

type SummaryColumnKey = 'totalAmount' | 'ownershipAmount';

type TableData = {
    columns: Column<ColumnKey | PaymentColoumKey>[];
    rows: RowData<ColumnKey | PaymentColoumKey>[];
};

@Component({
    selector: 'app-annual-statement-individual-tab',
    templateUrl: './annual-statement-individual-tab.component.html',
    styleUrls: ['./annual-statement-individual-tab.component.scss'],
})
export class AnnualStatementIndividualTabComponent implements OnInit, OnDestroy {
    public constructor(
        public wssService: WegSettlementStatementsService,
        public euroCent: EurocentPipe,
        public datePipe: DatePipe,
        public customWssService: CustomWssService,
        private translateService: TranslateService,
        private toastService: ToastService,
        private accountingFilterCustomService: AccountingFilterCustomService
    ) {}

    public unsubscribe$ = new Subject<void>();
    public isLoading = false;
    public ledgerId$ = this.customWssService.getLedgerId$().pipe(filter(Boolean));
    public wss$ = this.customWssService.getWss$().pipe(filter(Boolean));
    public selectedSettlementOwnershipId$ = new BehaviorSubject<string | null>(null);
    public fileToView?: FiletoView;
    public showModal = false;
    public wssPdfConfig$ = this.accountingFilterCustomService.getPdfPrintingConfig$().pipe(filter(Boolean));
    public isOpenDocumentPartSelection = false;

    @ViewChild(TableComponent)
    public tableComponent!: TableComponent;

    public ownershipSettlements$ = combineLatest([this.ledgerId$, this.wss$]).pipe(
        map(([ledgerId, wss]) => ({
            ledgerId,
            wss,
        })),
        switchMap(({ ledgerId, wss }) => {
            if (ledgerId && wss) {
                return this.wssService.getOwnershipSettlements(ledgerId, wss.id);
            }
            return of([]);
        }),

        shareReplay({ bufferSize: 1, refCount: true })
    );

    public wssTable$: Observable<TableModel> = this.ownershipSettlements$.pipe(
        map((wss) => {
            return {
                header: [
                    'ENTITIES.OWNERSHIP.LABEL_ENTITY',
                    {
                        data: {
                            key: 'PAGES.ANNUAL_STATEMENT.TABS.TABLE.LABEL_SETTLEMENT_PEAK',
                            params: {},
                        },
                        template: HeaderTemplate.RightAligned,
                    },
                    {
                        data: { key: 'PAGES.ECONOMIC_PLAN.INDIVIDUAL_ECONOMIC_PLAN_OVERVIEW.LABEL_PDF', params: {} },
                        template: HeaderTemplate.RightAligned,
                    },
                ],
                data: wss.map((data) => this.createRowOwnershipAdvancementsTable(data)),
            };
        })
    );

    //  simple settlement has only basic info coming from the list of settlements
    public selectedSimpleSettlement$ = combineLatest([
        this.ownershipSettlements$,
        this.selectedSettlementOwnershipId$,
    ]).pipe(
        map(([ownershipSettlements, selectedSettlementOwnershipId]) => {
            const selectedItem =
                ownershipSettlements.find((i) => i.ownershipId === selectedSettlementOwnershipId) ?? null;
            return selectedItem;
        })
    );

    //  details about settlement
    public selectedSettlement$ = combineLatest([this.ledgerId$, this.wss$, this.selectedSettlementOwnershipId$])
        .pipe(
            switchMap(([ledgerId, wss, selectedSettlementOwnershipId]) => {
                if (ledgerId && wss && selectedSettlementOwnershipId) {
                    return this.wssService.getOwnershipSettlement(ledgerId, wss.id, selectedSettlementOwnershipId);
                }
                return of(null);
            })
        )
        .pipe(shareReplay({ bufferSize: 1, refCount: true }));

    public nonApportionableHomeCostsTableData$ = this.selectedSettlement$.pipe(
        map((settlement) => {
            if (settlement === null) {
                return null;
            }

            const homeCostsTableData: TableData = {
                columns: [
                    {
                        key: 'name',
                        label: 'Nicht-umlagefähige Kosten',
                        columnCellClass: '[&&]:tw-s-head-16-24-bold [&&]:tw-text-scalaraGray-01',
                    },
                    { key: 'totalAmount', label: 'Verteilungsrelevante Beträge in €' },
                    { key: 'distributionKeyName', label: 'Verteilungsschlüssel' },
                    { key: 'distributionKeyTotal', label: 'Gesamt-VTS' },
                    { key: 'distributionShare', label: 'Anteil der Einheit' },
                    { key: 'ownershipAmount', label: 'Betrag der Einheit in €' },
                ],
                rows: [
                    {
                        cells: [
                            {
                                key: 'name',
                                value: '',
                                type: 'value',
                            },
                        ],
                        rowClass: '[&&]:tw-h-8',
                    },

                    ...settlement.nonApportionableHomeRelatedCosts.map(
                        (r) =>
                            ({
                                cells: [
                                    {
                                        key: 'name',
                                        value:
                                            r.accountName + (r.accountDescription ? ', ' + r.accountDescription : ''),
                                        type: 'value',
                                    },
                                    {
                                        key: 'totalAmount',
                                        value: r.totalAmount,
                                        pipeToApply: 'eurocent',
                                        type: 'value',
                                    },
                                    { key: 'distributionKeyName', value: r.distributionKeyName, type: 'value' },
                                    {
                                        key: 'distributionKeyTotal',
                                        value: r.distributionKeyTotal,
                                        pipeToApply:
                                            r.distributionKeyBase === SettlementRow.DistributionKeyBaseEnum.Consumption
                                                ? 'eurocent'
                                                : 'number',
                                        type: 'value',
                                    },
                                    {
                                        key: 'distributionShare',
                                        value: r.distributionShare,
                                        pipeToApply:
                                            r.distributionKeyBase === SettlementRow.DistributionKeyBaseEnum.Consumption
                                                ? 'eurocent'
                                                : 'number',
                                        type: 'value',
                                    },
                                    {
                                        key: 'ownershipAmount',
                                        value: r.ownershipAmount,
                                        pipeToApply: 'eurocent',
                                        type: 'value',
                                    },
                                ],
                            } as RowData<ColumnKey>)
                    ),
                    {
                        cells: [
                            {
                                key: 'name',
                                value: '',
                                type: 'value',
                            },
                        ],
                        rowClass: '[&&]:tw-h-8',
                    },

                    {
                        rowClass:
                            '[&&]:tw-s-head-14-22-bold  [&&]:tw-border-t [&&]:tw-border-solid [&&]:tw-border-scalaraGray-01',
                        cells: [
                            {
                                key: 'name',
                                value: 'Gesamt',
                                rowCellClass: '[&&]:tw-pt-4',
                                type: 'value',
                            },
                            {
                                key: 'totalAmount',
                                value: this.sumItemTable(
                                    settlement.nonApportionableHomeRelatedCosts.map((item) => item.totalAmount)
                                ),
                                rowCellClass: '[&&]:tw-pt-4',
                                type: 'value',
                                pipeToApply: 'eurocent',
                            },
                            {
                                key: 'ownershipAmount',
                                value: this.sumItemTable(
                                    settlement.nonApportionableHomeRelatedCosts.map((item) => item.ownershipAmount)
                                ),
                                rowCellClass: '[&&]:tw-pt-4',
                                type: 'value',
                                pipeToApply: 'eurocent',
                            },
                        ],
                    },
                ],
            };

            return homeCostsTableData;
        })
    );

    public apportionableHomeCostsTableData$ = this.selectedSettlement$.pipe(
        map((settlement) => {
            if (settlement === null) {
                return null;
            }

            const homeCostsTableData: TableData = {
                columns: [
                    {
                        key: 'name',
                        label: 'Umlagefähige Kosten',
                        columnCellClass: '[&&]:tw-s-head-16-24-bold [&&]:tw-text-scalaraGray-01',
                    },
                    { key: 'totalAmount', label: 'Verteilungsrelevante Beträge in €' },
                    { key: 'distributionKeyName', label: 'Verteilungsschlüssel' },
                    { key: 'distributionKeyTotal', label: 'Gesamt-VTS' },
                    { key: 'distributionShare', label: 'Anteil der Einheit' },
                    { key: 'ownershipAmount', label: 'Betrag der Einheit in €' },
                ],
                rows: [
                    {
                        cells: [
                            {
                                key: 'name',
                                value: '',
                                type: 'value',
                            },
                        ],
                        rowClass: '[&&]:tw-h-8',
                    },

                    ...settlement.apportionableHomeRelatedCosts.map(
                        (r) =>
                            ({
                                cells: [
                                    {
                                        key: 'name',
                                        value:
                                            r.accountName + (r.accountDescription ? ', ' + r.accountDescription : ''),
                                        type: 'value',
                                    },
                                    {
                                        key: 'totalAmount',
                                        value: r.totalAmount,
                                        pipeToApply: 'eurocent',
                                        type: 'value',
                                    },
                                    { key: 'distributionKeyName', value: r.distributionKeyName, type: 'value' },
                                    {
                                        key: 'distributionKeyTotal',
                                        value: r.distributionKeyTotal,
                                        pipeToApply:
                                            r.distributionKeyBase === SettlementRow.DistributionKeyBaseEnum.Consumption
                                                ? 'eurocent'
                                                : 'number',
                                        type: 'value',
                                    },
                                    {
                                        key: 'distributionShare',
                                        value: r.distributionShare,
                                        pipeToApply:
                                            r.distributionKeyBase === SettlementRow.DistributionKeyBaseEnum.Consumption
                                                ? 'eurocent'
                                                : 'number',
                                        type: 'value',
                                    },
                                    {
                                        key: 'ownershipAmount',
                                        value: r.ownershipAmount,
                                        pipeToApply: 'eurocent',
                                        type: 'value',
                                    },
                                ],
                            } as RowData<ColumnKey>)
                    ),
                    {
                        cells: [
                            {
                                key: 'name',
                                value: '',
                                type: 'value',
                            },
                        ],
                        rowClass: '[&&]:tw-h-8',
                    },

                    {
                        rowClass:
                            '[&&]:tw-s-head-14-22-bold  [&&]:tw-border-t [&&]:tw-border-solid [&&]:tw-border-scalaraGray-01',
                        cells: [
                            {
                                key: 'name',
                                value: 'Gesamt',
                                rowCellClass: '[&&]:tw-pt-4',
                                type: 'value',
                            },
                            {
                                key: 'totalAmount',
                                value: this.sumItemTable(
                                    settlement.apportionableHomeRelatedCosts.map((item) => item.totalAmount)
                                ),
                                rowCellClass: '[&&]:tw-pt-4',
                                type: 'value',
                                pipeToApply: 'eurocent',
                            },
                            {
                                key: 'ownershipAmount',
                                value: this.sumItemTable(
                                    settlement.apportionableHomeRelatedCosts.map((item) => item.ownershipAmount)
                                ),
                                rowCellClass: '[&&]:tw-pt-4',
                                type: 'value',
                                pipeToApply: 'eurocent',
                            },
                        ],
                    },
                ],
            };

            return homeCostsTableData;
        })
    );

    public tableData$ = combineLatest([this.wss$, this.selectedSettlement$]).pipe(
        map(([wss, settlement]) => {
            if (settlement === null) {
                return null;
            }

            const tableData: TableData = {
                columns: [
                    {
                        key: 'name',
                        label: 'Abrechnung über die Vorschüsse zur Kostentragung',
                        columnCellClass: '[&&]:tw-s-head-16-24-bold [&&]:tw-text-scalaraGray-01',
                    },
                    { key: 'totalAmount', label: 'Verteilungsrelevante Beträge in €' },
                    { key: 'distributionKeyName', label: 'Verteilungsschlüssel' },
                    { key: 'distributionKeyTotal', label: 'Gesamt-VTS' },
                    { key: 'distributionShare', label: 'Anteil der Einheit' },
                    { key: 'ownershipAmount', label: 'Betrag der Einheit in €' },
                ],
                rows: [
                    //  revenue subtable heading
                    {
                        cells: [
                            {
                                key: 'name',
                                value: 'Einnahmen',
                                rowCellClass: '[&&]:tw-s-head-14-22-bold [&&]:tw-pt-12 [&&]:tw-pb-4',
                                type: 'value',
                            },
                        ],
                    },
                    //revenues rows
                    ...settlement.revenues
                        .filter(
                            (data) =>
                                data.totalAmount > 0 ||
                                data.totalAmount < 0 ||
                                data.ownershipAmount > 0 ||
                                data.ownershipAmount < 0
                        )
                        .map(
                            (r) =>
                                ({
                                    cells: [
                                        {
                                            key: 'name',
                                            value:
                                                r.accountName +
                                                (r.accountDescription ? ', ' + r.accountDescription : ''),
                                            type: 'value',
                                        },
                                        {
                                            key: 'totalAmount',
                                            value: r.totalAmount,
                                            pipeToApply: 'eurocent',
                                            type: 'value',
                                        },
                                        { key: 'distributionKeyName', value: r.distributionKeyName, type: 'value' },
                                        {
                                            key: 'distributionKeyTotal',
                                            value: r.distributionKeyTotal,
                                            pipeToApply:
                                                r.distributionKeyBase ===
                                                SettlementRow.DistributionKeyBaseEnum.Consumption
                                                    ? 'eurocent'
                                                    : 'number',
                                            type: 'value',
                                        },
                                        {
                                            key: 'distributionShare',
                                            value: r.distributionShare,
                                            pipeToApply:
                                                r.distributionKeyBase ===
                                                SettlementRow.DistributionKeyBaseEnum.Consumption
                                                    ? 'eurocent'
                                                    : 'number',
                                            type: 'value',
                                        },
                                        {
                                            key: 'ownershipAmount',
                                            value: r.ownershipAmount,
                                            pipeToApply: 'eurocent',
                                            type: 'value',
                                        },
                                    ],
                                } as RowData<ColumnKey>)
                        ),
                    // //  spacingrow and revenue zwischensumme
                    { cells: [{ key: 'name', value: '', rowCellClass: '[&&]:tw-h-2', type: 'value' }] },
                    {
                        rowClass: '[&&]:tw-s-head-14-22-bold [&&]:tw-bg-scalaraGray-06 [&&]:tw-h-10',
                        cells: [
                            {
                                key: 'name',
                                value: 'Zwischensumme',
                                type: 'value',
                            },
                            {
                                key: 'totalAmount',
                                value: this.calculateSummaries(settlement).revenueSummary.totalAmount,
                                pipeToApply: 'eurocent',
                                type: 'value',
                            },
                            {
                                key: 'ownershipAmount',
                                value: this.calculateSummaries(settlement).revenueSummary.ownershipAmount,
                                pipeToApply: 'eurocent',
                                type: 'value',
                            },
                        ],
                    },
                    // expenseGroups
                    ...settlement.expenseGroups.reduce((acc: any, item) => {
                        acc.push({
                            cells: [
                                {
                                    key: 'name',
                                    value: item.groupName,
                                    rowCellClass: '[&&]:tw-s-head-14-22-bold [&&]:tw-pt-12 [&&]:tw-pb-4',
                                    type: 'value',
                                },
                            ],
                        });
                        item.rows.forEach((row) => {
                            acc.push({
                                cells: [
                                    {
                                        key: 'name',
                                        value:
                                            row.accountName +
                                            (row.accountDescription ? ', ' + row.accountDescription : ''),
                                        type: 'value',
                                    },
                                    {
                                        key: 'totalAmount',
                                        value: row.totalAmount,
                                        pipeToApply: 'eurocent',
                                        type: 'value',
                                    },
                                    { key: 'distributionKeyName', value: row.distributionKeyName, type: 'value' },
                                    {
                                        key: 'distributionKeyTotal',
                                        value: row.distributionKeyTotal ?? '',
                                        pipeToApply:
                                            row.distributionKeyBase ===
                                            SettlementRow.DistributionKeyBaseEnum.Consumption
                                                ? 'eurocent'
                                                : 'number',
                                        type: 'value',
                                    },
                                    {
                                        key: 'distributionShare',
                                        value: row.distributionShare ?? '',
                                        pipeToApply:
                                            row.distributionKeyBase ===
                                            SettlementRow.DistributionKeyBaseEnum.Consumption
                                                ? 'eurocent'
                                                : 'number',
                                        type: 'value',
                                    },
                                    {
                                        key: 'ownershipAmount',
                                        value: row.ownershipAmount,
                                        pipeToApply: 'eurocent',
                                        type: 'value',
                                    },
                                ],
                            });
                        });
                        acc.push({
                            rowClass: '[&&]:tw-s-head-14-22-bold [&&]:tw-bg-scalaraGray-06 [&&]:tw-h-10',
                            cells: [
                                {
                                    key: 'name',
                                    value: 'Zwischensumme',
                                    type: 'value',
                                },
                                {
                                    key: 'distributionKeyTotal',
                                    value: item.distributionKeyTotal,
                                    pipeToApply: 'eurocent',
                                    type: 'value',
                                },
                                {
                                    key: 'totalAmount',
                                    value: item.totalAmount,
                                    pipeToApply: 'eurocent',
                                    type: 'value',
                                },
                                {
                                    key: 'ownershipAmount',
                                    value: item.ownershipAmount,
                                    pipeToApply: 'eurocent',
                                    type: 'value',
                                },
                            ],
                        });
                        return acc;
                    }, []),
                    //

                    // //  Abrechnungssumme Kostentragung part
                    { cells: [{ key: 'name', value: '', rowCellClass: '[&&]:tw-h-8', type: 'value' }] },
                    {
                        cells: [
                            { key: 'name', value: 'Abrechnungssumme Kostentragung', type: 'value' },
                            {
                                key: 'totalAmount',
                                value: settlement.sumCostAllocationTotal,
                                pipeToApply: 'eurocent',
                                type: 'value',
                            },
                            {
                                key: 'ownershipAmount',
                                value: settlement.sumCostAllocationOwnership,
                                pipeToApply: 'eurocent',
                                type: 'value',
                            },
                        ],
                    },
                    {
                        cells: [
                            { key: 'name', value: 'abzgl. beschlossener Vorschüsse', type: 'value' },
                            {
                                key: 'totalAmount',
                                value: settlement.sumAdvancementsTotal,
                                pipeToApply: 'eurocent',
                                type: 'value',
                            },
                            {
                                key: 'ownershipAmount',
                                value: settlement.sumAdvancementsOwnership,
                                pipeToApply: 'eurocent',
                                type: 'value',
                            },
                        ],
                    },
                    {
                        cells: [
                            {
                                key: 'name',
                                value: '',
                                rowCellClass: '[&&]:tw-h-6',
                                type: 'value',
                            },
                        ],
                        rowClass: '[&&]:tw-border-b [&&]:tw-border-solid [&&]:tw-border-scalaraGray-01',
                    },
                    { cells: [{ key: 'name', value: '', rowCellClass: '[&&]:tw-h-4', type: 'value' }] },
                    {
                        cells: [
                            { key: 'name', value: 'Abrechnungsspitze Kostentragung', type: 'value' },
                            {
                                key: 'totalAmount',
                                value: settlement.settlementPeakTotal,
                                pipeToApply: 'eurocent',
                                type: 'value',
                            },
                            {
                                key: 'ownershipAmount',
                                value: settlement.settlementPeakOwnership,
                                pipeToApply: 'eurocent',
                                type: 'value',
                            },
                        ],
                        rowClass: '[&&]:tw-s-head-14-22-bold',
                    },
                    // //  reserves single accounts
                    ...settlement.reserves.map((i) => this.reservesSingleAccountToRows(i)).flat(),
                ],
            };
            return tableData;
        })
    );

    public paymentsTableData$ = combineLatest([this.wss$, this.selectedSettlement$]).pipe(
        map(([wss, settlement]) => {
            if (settlement === null) {
                return null;
            }

            const tableData: TableData = {
                columns: [
                    {
                        key: 'paymentDate',
                        label: '',
                        columnCellClass: '[&&]:tw-s-head-16-24-bold [&&]:tw-text-scalaraGray-01',
                    },
                    { key: 'paymentDescription', label: '' },
                    { key: 'paymentTotalAmount', label: '' },
                    { key: 'paymentSubItemAmount', label: '' },
                ],
                rows: [
                    { cells: [{ key: 'name', value: '', rowCellClass: '[&&]:tw-h-20', type: 'value' }] },
                    {
                        cells: [
                            {
                                key: 'paymentDate',
                                value: 'Übersicht der Zahlungen für das Abrechnungsjahr',
                                rowCellClass: '[&&]:tw-s-head-16-24-bold [&&]:tw-pb-6',
                                type: 'value',
                            },
                            {
                                key: 'paymentDescription',
                                value: '',
                                rowCellClass:
                                    '[&&]:tw-pt-8 [&&]:tw-pb-4 [&&]:tw-text-right [&&]:tw-text-scalaraGray-03 [&&]:tw-s-label-12-22-semibold',
                                type: 'value',
                            },
                            {
                                key: 'paymentTotalAmount',
                                value: 'Zahlungsbetrag in €',
                                rowCellClass:
                                    '[&&]:tw-pt-8 [&&]:tw-pb-4 [&&]:tw-text-right [&&]:tw-text-scalaraGray-03 [&&]:tw-s-label-12-22-semibold',
                                type: 'value',
                            },
                            {
                                key: 'paymentSubItemAmount',
                                value: 'Buchungsbetrag in €',
                                rowCellClass:
                                    '[&&]:tw-pt-8 [&&]:tw-pb-4 [&&]:tw-text-right [&&]:tw-text-scalaraGray-03 [&&]:tw-s-label-12-22-semibold',
                                type: 'value',
                            },
                        ],
                    },
                    // TODO: fix dates
                    ...this.createPaymentRows(settlement.payments),
                    { cells: [{ key: 'paymentDate', value: '', rowCellClass: '[&&]:tw-h-2', type: 'value' }] },
                    {
                        cells: [
                            {
                                key: 'paymentDate',
                                value: 'Zahlungseingang gesamt',
                                rowCellClass: '[&&]:tw-h-10 [&&]:tw-bg-scalaraGray-06',
                                type: 'value',
                            },
                            {
                                key: 'paymentDescription',
                                value: '',
                                pipeToApply: 'eurocent',
                                rowCellClass: '[&&]:tw-h-10 [&&]:tw-bg-scalaraGray-06',
                                type: 'value',
                            },
                            {
                                key: 'paymentTotalAmount',
                                value: '',
                                rowCellClass: '[&&]:tw-h-10 [&&]:tw-bg-scalaraGray-06',
                                type: 'value',
                            },
                            {
                                key: 'paymentSubItemAmount',
                                value: settlement.paymentsTotal,
                                pipeToApply: 'eurocent',
                                rowCellClass: '[&&]:tw-h-10 [&&]:tw-bg-scalaraGray-06',
                                type: 'value',
                            },
                        ],
                        rowClass: '[&&]:tw-s-head-14-22-bold',
                    },
                    { cells: [{ key: 'paymentDate', value: '', rowCellClass: '[&&]:tw-h-10', type: 'value' }] },
                    //economic plan advancements
                    {
                        cells: [
                            { key: 'paymentDate', value: 'Geschuldete Vorschüsse laut Wirtschaftsplan', type: 'value' },
                        ],
                        rowClass: '[&&]:tw-h-10 s-head-14-22-semibold',
                    },
                    ...settlement.economicPlanAdvancements.map(
                        (i): RowData<PaymentColoumKey> => ({
                            cells: [
                                { key: 'paymentDate', value: i.name, type: 'value' },
                                { key: 'paymentDescription', value: '', type: 'value' },
                                {
                                    key: 'paymentTotalAmount',
                                    value:
                                        i.ratesPerYear && i.amountPerRate
                                            ? `${i.ratesPerYear} x ${this.euroCent.transform(i.amountPerRate)}`
                                            : '',

                                    type: 'value',
                                },
                                {
                                    key: 'paymentSubItemAmount',
                                    value: i.amountPerYear,
                                    pipeToApply: 'eurocent',
                                    type: 'value',
                                },
                            ],
                        })
                    ),
                    { cells: [{ key: 'paymentDate', value: '', rowCellClass: '[&&]:tw-h-2', type: 'value' }] },
                    {
                        cells: [
                            {
                                key: 'paymentDate',
                                value: 'Gesamt',
                                rowCellClass: '[&&]:tw-bg-scalaraGray-06',
                                type: 'value',
                            },
                            {
                                key: 'paymentDescription',
                                value: '',
                                rowCellClass: '[&&]:tw-bg-scalaraGray-06',
                                type: 'value',
                            },
                            {
                                key: 'paymentTotalAmount',
                                value: '',
                                rowCellClass: '[&&]:tw-bg-scalaraGray-06',
                                type: 'value',
                            },
                            {
                                key: 'paymentSubItemAmount',
                                value: settlement.economicPlanAdvancementsTotal,
                                pipeToApply: 'eurocent',
                                rowCellClass: '[&&]:tw-bg-scalaraGray-06',
                                type: 'value',
                            },
                        ],
                        rowClass: '[&&]:tw-h-10 s-head-14-22-bold',
                    },
                    { cells: [{ key: 'paymentDate', value: '', rowCellClass: '[&&]:tw-h-10', type: 'value' }] },

                    // TODO: fix date format
                    {
                        cells: [
                            {
                                key: 'paymentDate',
                                value: `Saldo per ${this.datePipe.transform(wss?.economicPlan.endDate, 'dd.MM.yyyy')}`,
                                rowCellClass:
                                    '[&&]:tw-pt-4 [&&]:tw-border-t  [&&]:tw-border-scalaraGray-01 [&&]:tw-border-solid',
                                type: 'value',
                            },
                            {
                                key: 'paymentDescription',
                                value: '',
                                pipeToApply: 'eurocent',
                                rowCellClass:
                                    '[&&]:tw-pt-4 [&&]:tw-border-t  [&&]:tw-border-scalaraGray-01 [&&]:tw-border-solid',
                                type: 'value',
                            },
                            {
                                key: 'paymentTotalAmount',
                                value: settlement.totalBalance > 0 ? 'Überzahlung' : 'Rückstand',
                                rowCellClass:
                                    '[&&]:tw-pt-4 [&&]:tw-border-t  [&&]:tw-border-scalaraGray-01 [&&]:tw-border-solid',
                                type: 'value',
                            },
                            {
                                key: 'paymentSubItemAmount',
                                value: settlement.totalBalance,
                                pipeToApply: 'eurocent',
                                rowCellClass:
                                    '[&&]:tw-pt-4 [&&]:tw-border-t  [&&]:tw-border-scalaraGray-01 [&&]:tw-border-solid',
                                type: 'value',
                            },
                        ],
                        rowClass: 's-head-14-22-bold',
                    },
                    {
                        cells: [
                            {
                                key: 'paymentDate',
                                value: `(nicht relevant für die Berechnung der Abrechnungsspitze)`,
                                rowCellClass: '[&&]:tw-pt-4',
                                type: 'value',
                            },
                            {
                                key: 'paymentDescription',
                                value: '',
                                pipeToApply: 'eurocent',
                                rowCellClass: '[&&]:tw-pt-4',
                                type: 'value',
                            },
                        ],
                        rowClass: 's-head-14-22-bold',
                    },
                ],
            };
            return tableData;
        })
    );

    public vm$ = combineLatest([
        this.ownershipSettlements$,
        this.selectedSettlementOwnershipId$,
        this.selectedSimpleSettlement$,
        this.selectedSettlement$,
        this.tableData$,
        this.ledgerId$,
        this.wss$,
        this.wssTable$,
        this.apportionableHomeCostsTableData$,
        this.nonApportionableHomeCostsTableData$,
        this.wssPdfConfig$,
        this.paymentsTableData$,
    ]).pipe(
        map(
            ([
                ownershipSettlements,
                selectedSettlementOwnershipId,
                selectedSimpleSettlement,
                selectedSettlement,
                tableData,
                ledgerId,
                wss,
                wssTable,
                apportionableHomeCostsTableData,
                nonApportionableHomeCostsTableData,
                wssPdfConfig,
                paymentsTableData,
            ]) => ({
                ownershipSettlements,
                selectedSettlementOwnershipId,
                selectedSimpleSettlement,
                selectedSettlement,
                tableData,
                ledgerId,
                wss,
                wssTable,
                apportionableHomeCostsTableData,
                nonApportionableHomeCostsTableData,
                wssPdfConfig,
                paymentsTableData,
            })
        )
    );
    public vm: null | ObservedValueOf<typeof this.vm$> = null;

    public selectSettlement(event: any | null): void {
        if (this.vm?.wss.status !== 'ACTIVE') {
            const clickedRow = event !== null ? this.tableComponent.model.row(event) : undefined;

            this.selectedSettlementOwnershipId$.next(clickedRow ? clickedRow[0].data.extraData.ownershipId : null);
        }
    }

    public ngOnInit(): void {
        this.isLoading = true;
        this.vm$.pipe(takeUntil(this.unsubscribe$)).subscribe((vm) => {
            this.vm = vm;
            this.isLoading = false;
        });
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public calculateSummaries(selectedSettlement: ObservedValueOf<typeof this.vm$>['selectedSettlement']): {
        revenueSummary: {
            [K in SummaryColumnKey]: number;
        };
    } {
        return {
            //  summary of all i.revenues totalamouns
            revenueSummary: {
                totalAmount: selectedSettlement?.revenues.reduce((acc, cur) => acc + cur.totalAmount, 0) ?? 0,
                ownershipAmount: selectedSettlement?.revenues.reduce((acc, cur) => acc + cur.ownershipAmount, 0) ?? 0,
            },
        };
    }

    public reservesSingleAccountToRows(reserve: Reserve): RowData<ColumnKey>[] {
        const headingRow: RowData<ColumnKey> = {
            cells: [
                {
                    key: 'name',
                    value: `Abrechnung über die Vorschüsse zur ${reserve.accountName}`,
                    rowCellClass: '[&&]:tw-pt-20 [&&]:tw-pb-5 [&&]:tw-s-head-16-24-bold ',
                    type: 'value',
                },
            ],
        };
        const bookingsRows = reserve.bookings
            .filter(
                (data) =>
                    data.totalAmount > 0 || data.totalAmount < 0 || data.ownershipAmount > 0 || data.ownershipAmount < 0
            )
            .map(
                (b): RowData<ColumnKey> => ({
                    cells: [
                        { key: 'name', value: b.accountName, type: 'value' },
                        { key: 'totalAmount', value: b.totalAmount, pipeToApply: 'eurocent', type: 'value' },
                        { key: 'distributionKeyName', value: b.distributionKeyName, type: 'value' },
                        {
                            key: 'distributionKeyTotal',
                            value: b.distributionKeyTotal,
                            pipeToApply:
                                b.distributionKeyBase === SettlementRow.DistributionKeyBaseEnum.Consumption
                                    ? 'eurocent'
                                    : 'number',
                            type: 'value',
                        },
                        {
                            key: 'distributionShare',
                            value: b.distributionShare,
                            pipeToApply:
                                b.distributionKeyBase === SettlementRow.DistributionKeyBaseEnum.Consumption
                                    ? 'eurocent'
                                    : 'number',
                            type: 'value',
                        },
                        { key: 'ownershipAmount', value: b.ownershipAmount, pipeToApply: 'eurocent', type: 'value' },
                    ],
                })
            );
        const spacingRow: RowData<ColumnKey> = {
            cells: [{ key: 'name', value: '', rowCellClass: '[&&]:tw-h-7', type: 'value' }],
        };

        const summaryRow: RowData<ColumnKey> = {
            rowClass: '[&&]:tw-s-head-14-22-bold  [&&]:tw-border-t [&&]:tw-border-solid [&&]:tw-border-scalaraGray-01',
            cells: [
                {
                    key: 'name',
                    value: `Abrechnungsspitze ${reserve.accountName}`,
                    rowCellClass: '[&&]:tw-pt-4',
                    type: 'value',
                },
                {
                    key: 'totalAmount',
                    value: reserve.settlementPeakTotal,
                    rowCellClass: '[&&]:tw-pt-4',
                    type: 'value',
                },
                {
                    key: 'ownershipAmount',
                    value: reserve.settlementPeakOwnership,
                    rowCellClass: '[&&]:tw-pt-4',
                    type: 'value',
                },
            ],
        };
        return [headingRow, ...bookingsRows, spacingRow, summaryRow];
    }

    public handleModal(): void {
        this.showModal = !this.showModal;
    }

    //  get pdfs as one merged into single pdf or separated pdfs as zip
    public downloadAllPdfs(flow: 'pdf' | 'zip'): void {
        if (!this.vm?.ledgerId || !this.vm?.wss) {
            return;
        }
        this.isLoading = true;
        const methodToCall =
            flow === 'pdf' ? this.wssService.getAllOwnershipSettlementPdf : this.wssService.getAllPdfInZip;
        methodToCall
            .bind(this.wssService)(this.vm?.ledgerId, this.vm?.wss.id, this.vm?.wssPdfConfig)
            .pipe(
                catchError(() => {
                    this.toastService.showError(this.translateService.instant('COMPONENTS.TOAST.TOAST_ERROR'));
                    return of(null);
                }),
                tap(() => (this.isLoading = false))
            )
            .subscribe((data: any) => {
                const link = document.createElement('a');
                link.href = data.url;
                link.click();
            });
    }

    public handleFile(event: any): void {
        if (!this.vm?.ledgerId || !this.vm?.wss || !event.extraData.wss) {
            return;
        }
        this.isLoading = true;
        const { startDate, endDate } = this.vm.wss.economicPlan ?? {};
        const startMonth = startDate?.substring(5, 7) || '';
        const endMonth = endDate?.substring(5, 7) || '';
        const year = startDate?.substring(0, 4) || '';

        const datesAreValid = startMonth && endMonth && year;
        const fileNamePrefix = datesAreValid ? `${startMonth}_${year}-${endMonth}_${year}_` : '';
        const fileNamePostFix = event.extraData?.wss?.ownershipName ? `_${event.extraData.wss.ownershipName}` : '';
        const fileName = `${fileNamePrefix}Jahresabschluss${fileNamePostFix}.pdf`.replace(/\s+/g, '_');

        this.wssService
            .getOwnershipSettlementPdf(
                this.vm?.ledgerId,
                this.vm?.wss.id,
                event.extraData?.wss.ownershipId,
                this.vm?.wssPdfConfig
            )
            .pipe(
                tap((data: any) => {
                    if (event.isDownload) {
                        const link = document.createElement('a');
                        link.href = data.url;
                        link.download = fileName;
                        link.click();
                    } else {
                        this.fileToView = { fileName: fileName, file: data.url };
                        this.showModal = true;
                    }
                }),
                catchError(() => {
                    this.toastService.showError(this.translateService.instant('COMPONENTS.TOAST.TOAST_ERROR'));
                    return of(null);
                }),
                tap(() => (this.isLoading = false))
            )
            .subscribe();
    }

    private createRowOwnershipAdvancementsTable(data: SimpleOwnershipSettlementDto): TableItem[] {
        return [
            {
                data: {
                    label: `${data.ownershipName} - ${data.owners}` || '',
                    extraData: { ...data }, // ATTENTION: DO NOT REMOVE THIS INFORMATION, IN THIS POSITION OF THE ARRAY, BECAUSE IT IS BEING USED IN A FUNCTION (this.ownershipAdvancementsTable.data[event][2].data.extraData)
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: data.settlementPeak,
                },
                template: CellTemplate.EuroCent,
            },
            {
                data: {
                    label: '',
                    extraData: { wss: data },
                },
                template: CellTemplate.filesActions,
            },
        ];
    }

    public sumItemTable(values: number[]): number {
        let sum = 0;
        for (let i = 0; i < values.length; i++) {
            if (!isNaN(values[i])) {
                sum += values[i];
            }
        }

        return sum;
    }

    public areOutstandingAmountsPresent(): boolean {
        return Math.abs(this.vm?.selectedSettlement?.totalOutstandingBalanceWithSettlementPeak ?? 0) > 0;
    }

    public setFilterItemsVisibility(clickedOutside = false): void {
        if (clickedOutside) {
            this.isOpenDocumentPartSelection = false;
            return;
        }
        this.isOpenDocumentPartSelection = !this.isOpenDocumentPartSelection;
    }

    private createPaymentRows(payments: PaymentItem[]): RowData<PaymentColoumKey>[] {
        const rows: RowData<PaymentColoumKey>[] = [];
        payments.forEach((payment) => {
            let transactionLink;
            if (payment.bankTransactionId) {
                transactionLink = `/accounting/ledger/${this.vm?.ledgerId}/bank-accounts/${payment.bankAccountId}/bank-transactions/${payment.bankTransactionId}`;
            }
            rows.push({
                cells: [
                    {
                        key: 'paymentDate',
                        value: payment.paymentDate,
                        pipeToApply: 'date',
                        type: 'value',
                        link: transactionLink,
                    },
                    { key: 'paymentDescription', value: payment.description, type: 'value', link: transactionLink },
                    {
                        key: 'paymentTotalAmount',
                        value: payment.amount,
                        pipeToApply: 'eurocent',
                        type: 'value',
                        link: transactionLink,
                    },
                    {
                        key: 'paymentSubItemAmount',
                        value: '',
                        pipeToApply: 'eurocent',
                        type: 'value',
                        link: transactionLink,
                    },
                ],
                rowClass: '[&&]:tw-h-10 s-head-14-22-semibold',
            });
            payment.subItems.forEach((subpayment) => {
                let bookingLink;
                if (subpayment.bookingId) {
                    bookingLink = `/accounting/ledger/${this.vm?.ledgerId}/bookings/${subpayment.bookingId}`;
                }
                rows.push({
                    cells: [
                        { key: 'paymentDate', value: '', pipeToApply: 'date', type: 'value', link: bookingLink },
                        { key: 'paymentDescription', value: subpayment.description, type: 'value', link: bookingLink },
                        {
                            key: 'paymentTotalAmount',
                            value: '',
                            pipeToApply: 'eurocent',
                            type: 'value',
                            link: bookingLink,
                        },
                        {
                            key: 'paymentSubItemAmount',
                            value: subpayment.amount,
                            pipeToApply: 'eurocent',
                            type: 'value',
                            link: bookingLink,
                        },
                    ],
                });
            });
        });

        return rows;
    }
}
