import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { AuthService } from 'src/app/core/services/auth.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { formControl, formControlHasError } from 'src/app/core/utils/common';
import { AuthSuccessFlowTypeEnum } from '../../types/authSuccess';
import { GiveEmailFlowType, GiveEmailFlowTypesObj } from '../../types/giveEmail';

/**
 * Is used to get email from user and make conditional cognito API calls (reset password or resend confirmation email)
 */
@Component({
    selector: 'app-reset-password-give-email',
    templateUrl: 'reset-password-give-email.component.html',
    styleUrls: ['./reset-password-give-email.component.scss'],
})
export class ResetPasswordGiveEmailComponent implements OnInit {
    public constructor(
        public formBuilder: UntypedFormBuilder,
        private authService: AuthService,
        private toaster: ToastService,
        private translate: TranslateService,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    public flowType?: GiveEmailFlowType;
    public isLoading = false;
    public form: UntypedFormGroup = this.formBuilder.group({
        email: ['', [Validators.required]],
    });

    public conditionalTextLabels: Record<GiveEmailFlowType, { headerLabel: string; buttonLabel: string }> = {
        'reset-password': {
            headerLabel: this.translate.instant('PAGES.RESET_PASSWORD.HEADER'),
            buttonLabel: this.translate.instant('CONTROLS.BUTTONS.CONTINUE'),
        },
        'resend-verification-link': {
            headerLabel: this.translate.instant('PAGES.RESEND_EMAIL.HEADER'),
            buttonLabel: this.translate.instant('CONTROLS.BUTTONS.SEND_EMAIL'),
        },
    };

    public ngOnInit(): void {
        this.route.queryParamMap.subscribe((params) => {
            this.flowType = params.get('flowType') as GiveEmailFlowType;
        });
        if (!this.flowType || !Object.values(GiveEmailFlowTypesObj).includes(this.flowType as GiveEmailFlowType)) {
            throw new Error('you must provide proper flowType for this page');
        }
    }

    public onSubmit(): void {
        this.isLoading = true;
        const email = this.form.get('email')?.value;
        const userData = { Username: email, Pool: this.authService.getUserPool() };
        const cognitoUser = new CognitoUser(userData);

        if (this.flowType === 'reset-password') {
            cognitoUser.forgotPassword({
                onSuccess: () => {
                    this.toaster.showSuccess(this.translate.instant('PAGES.RESET_PASSWORD.GIVE_EMAIL.TOAST_SUCCESS'));
                    this.router.navigate([`/reset-password/set-password`], { queryParams: { email } });
                },
                onFailure: () => {
                    this.toaster.showError(this.translate.instant('PAGES.RESET_PASSWORD.GIVE_EMAIL.TOAST_ERROR'));
                },
            });
        }

        if (this.flowType === 'resend-verification-link') {
            cognitoUser.resendConfirmationCode((err) => {
                if (err) {
                    this.toaster.showError(this.translate.instant('PAGES.RESEND_EMAIL.TOAST_ERROR'));
                    return;
                }
                this.toaster.showSuccess(this.translate.instant('PAGES.RESEND_EMAIL.TOAST_SUCCESS'));
                this.router.navigate(['/auth-success'], {
                    queryParams: { flowType: AuthSuccessFlowTypeEnum.resendVerificationLink },
                });
            });
        }

        this.isLoading = false;
    }

    public get emailInvalid(): boolean {
        return formControlHasError(formControl(this.form, 'email'), 'required');
    }
}
