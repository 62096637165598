import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { initHubspot } from './app/core/scripts/initHubspot';
import { environment } from './environments/environment';
import './patch-combobox';

initGooglePlaces(environment.googleApiKey);
initHubspot();

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
