<app-delete-overlay
    headline="PAGES.DISTRIBUTION_KEYS.DELETE.HEADLINE"
    title="PAGES.DISTRIBUTION_KEYS.DELETE.QUESTION"
    subtitle="PAGES.DISTRIBUTION_KEYS.DELETE.WARNING_1"
    (closeOverlay)="abort()"
    (submitOverlay)="submit()"
>
    <ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>
    <div class="d-flex align-items-center files mb-7-5r">
        <span class="s-body-14-22-regular s-gray-01"> {{ oesDistributionKeyDescription }}</span>
    </div>
</app-delete-overlay>
