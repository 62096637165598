import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    Output,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, map, take } from 'rxjs';
import { SearchInput, TableSearchService } from '../../services/table-search.service';

/**
 * Search input component. Emits the input which the desired table will filter its rows from
 * via tableSearchService service.
 *
 * By default, Table component listens to tableSearchService to get the latest search input.
 * By default, on every page that has at least one table, a search input is expected.
 *
 * If no search input is expected for a table, bind the table with an empty tableSearchId,
 * so the table doesn't subscribe unneededly to the serivce. Example:
 *  <app-table
 *   [emptyText]="'PAGES.PROPERTY.OVERVIEW.LABEL_EMPTY_TABLE'"
 *   [header]="tableModel.header"
 *   [data]="tableModel.data"
 *   [tableSearchId]="''"
 *   >
 *  </app-table>
 *
 *  If multiple search inputs are present on the same page,
 *  input the same values for [searchInputId] and [tableSearchId]
 *  in order to connect the desired search input with its table. Example:
 *
 *  <app-search [searchInputId]="'example'"></app-search>
 *
 *  <app-table
 *   [emptyText]="'PAGES.PROPERTY.OVERVIEW.LABEL_EMPTY_TABLE'"
 *   [header]="tableModel.header"
 *   [data]="tableModel.data"
 *   [tableSearchId]="'example'"
 *   >
 *  </app-table>
 *
 */
@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnDestroy, AfterViewInit {
    private unsubscribe$ = new Subject<void>();

    public searchValue = '';
    public isSearchOpen = false;

    @ViewChild('searchWrapper')
    public searchWrapper?: ElementRef;
    @ViewChild('openSearchButton')
    public openSearchButton?: ElementRef;

    /**
     * When using multiple searches on the same parent, a unique ID per search must be provided
     * example on advancements-overview.component.html
     */
    @Input() public searchInputId = 'default';
    @Input() public closedByDefault = true;
    @Input() public isFullwidth = false;

    @Output() public inputSearch = new EventEmitter<any>();

    public searchInputFromUrl$ = this.route.queryParams.pipe(
        map((queryParams) => {
            const queryParamsKeys = Object.keys(queryParams);
            const queryParamTableSearchKey = queryParamsKeys.find((key) => key.startsWith('tableSearch_'));
            const queryParamSearchInputValue = queryParams[queryParamTableSearchKey ?? ''];
            const arrOfQueryParamTableSearchKey = queryParamTableSearchKey?.split('_') ?? [''];
            const queryParamSearchId = arrOfQueryParamTableSearchKey[arrOfQueryParamTableSearchKey.length - 1] ?? '';
            const searchInput: SearchInput = {
                searchInput: queryParamSearchInputValue,
                searchInputId: queryParamSearchId,
            };
            return searchInput;
        })
    );

    public searchInputFromService$ = this.tableSearchService.getCurrentInputSearch$();

    public constructor(
        private tableSearchService: TableSearchService,
        private route: ActivatedRoute,
        private cdref: ChangeDetectorRef
    ) {
        //  take very first value from url and set it to the service
        //  it is important to take only first value, because otherwise we will get infinite loop
        //  (update state -> update url -> update state -> update url and so on)
        this.searchInputFromUrl$.pipe(take(1)).subscribe((searchInput) => {
            this.tableSearchService.setSearchInput(searchInput.searchInput, searchInput.searchInputId);
        });
    }

    public ngAfterViewInit(): void {
        this.searchInputFromService$.subscribe((searchInput) => {
            // set input value to the one from service
            if (searchInput && searchInput.searchInputId === this.searchInputId) {
                this.searchValue = searchInput.searchInput;
                //  to fix ExpressionChangedAfterItHasBeenCheckedError
                this.cdref.detectChanges();
            }
        });
    }

    @HostListener('document:click', ['$event.target'])
    public onClick(target: any): void {
        const clickedInside = this.searchWrapper?.nativeElement.contains(target);
        if (this.isSearchOpen && !clickedInside && !this.openSearchButton?.nativeElement.contains(target)) {
            this.changeSearchDisplay();
        }
    }

    public emitInputValue($event: any): void {
        const newValue = $event.target.value;
        this.inputSearch.emit(newValue);
        this.tableSearchService.setSearchInput(newValue, this.searchInputId);
        this.searchValue = newValue;
    }
    public changeSearchDisplay(): void {
        this.isSearchOpen = !this.isSearchOpen;
        if (this.isSearchOpen) {
            setTimeout(() => document.getElementById(this.searchInputId)!.focus(), 0);
        }
    }

    public clearInput(): void {
        this.searchValue = '';
        this.inputSearch.emit('');
        this.tableSearchService.setSearchInput(this.searchValue, this.searchInputId);
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }
}
