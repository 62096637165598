<ng-container>
    <app-combo-box
        [size]="'xl'"
        id="person"
        placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT_2' | translate }}"
        [appendInline]="true"
        label="Person"
        [items]="personsListItems"
        (selected)="onSelect()"
        [formControl]="selectedPersonForm"
        invalidText="{{ 'ERROR.REQUIRED' | translate }}"
        (search)="invalidatePerson()"
        [invalid]="!isValidPerson() && selectedPersonForm.touched"
    >
        <ibm-dropdown-list></ibm-dropdown-list>
    </app-combo-box>
</ng-container>
