import { TableHeaderItem, TableItem } from 'carbon-components-angular';
import { CustomTableItem } from './CustomTableItem';

export class FilterableHeaderItem extends TableHeaderItem {
    // custom filter function
    public override filter(item: TableItem): boolean {
        return false;
        /*
        return !(typeof item.data === "string" && item.data.toLowerCase().indexOf(this.filterData.data.toLowerCase()) >= 0 ||
            typeof item.data.label === "string" && item.data.label && item.data.label.toLowerCase().indexOf(this.filterData.data.toLowerCase()) >= 0);

         */
    }

    /*
    public override set filterCount(n: number) {}
     public override get filterCount(): 1 | 0 {
        return (this.filterData && this.filterData.data && this.filterData.data.length > 0) ? 1 : 0;
    }
    */

    //custom sort function for transactions
    public sortTransactionStatus(statusOne: string, statusTwo: string): -1 | 0 | 1 {
        let valueToReturn: -1 | 0 | 1;
        switch (statusOne) {
            case 'unverbucht':
                valueToReturn = -1;
                break;
            case 'teilverbucht':
                if (statusTwo === 'unverbucht') {
                    valueToReturn = 1;
                } else {
                    valueToReturn = -1;
                }
                break;
            case 'verbucht':
                valueToReturn = 1;
                break;
            default:
                valueToReturn = 0;
        }

        return valueToReturn;
    }

    //custom sort function for open items
    public sortOpenItemStatus(statusOne: string, statusTwo: string): -1 | 0 | 1 {
        let valueToReturn: -1 | 0 | 1;

        switch (statusOne) {
            case 'offen (fällig)':
                if (statusOne === statusTwo) {
                    valueToReturn = 0;
                } else {
                    valueToReturn = -1;
                }
                break;
            case 'teilgeschlossen (fällig)':
                if (statusTwo === 'offen (fällig)') {
                    valueToReturn = 1;
                } else {
                    valueToReturn = -1;
                }
                break;
            case 'offen':
                if (statusTwo === 'offen (fällig)' || statusTwo === 'teilgeschlossen (fällig)') {
                    valueToReturn = 1;
                } else {
                    valueToReturn = -1;
                }
                break;
            case 'teilgeschlossen':
                if (
                    statusTwo === 'offen (fällig)' ||
                    statusTwo === 'teilgeschlossen (fällig)' ||
                    statusTwo === 'offen'
                ) {
                    valueToReturn = 1;
                } else {
                    valueToReturn = -1;
                }
                break;
            case 'geschlossen':
                valueToReturn = 1;
                break;
            default:
                valueToReturn = 0;
        }

        return valueToReturn;
    }

    // used for custom sorting
    public override compare(one: CustomTableItem, two: CustomTableItem): -1 | 0 | 1 {
        console.log('run compare');
        let valueOne = one.data.label || one.data || ' ';
        let valueTwo = two.data.label || two.data || ' ';
        if (typeof valueOne === 'string') {
            valueOne = valueOne.toLowerCase();
        }
        if (typeof valueTwo === 'string') {
            valueTwo = valueTwo.toLowerCase();
        }

        //  coerce to number if we compare column with number values
        if (!isNaN(Number(one.data.label)) && !isNaN(Number(two.data.label))) {
            valueOne = Number(one.data.label);
            valueTwo = Number(two.data.label);
        }

        // TODO: need general refactoring of the table (if possible)
        // flag for custom sorting is passed in each cell in TableItem[] which is not the best way
        // this flag should be passed in TableHeader, custom sorting function aswell
        if (one.data?.extraData?.enableCustomSorting && one.data?.extraData?.bankTransaction) {
            return this.sortTransactionStatus(valueOne, valueTwo);
        }
        if (one.data?.extraData?.enableCustomSorting && one.data?.extraData?.openItem) {
            return this.sortOpenItemStatus(valueOne, valueTwo);
        }
        if (one.data?.extraData?.enableCustomSorting) {
            console.error('there is custom sorting enabled, but no object to work with');
        }

        //  do general sorting if there in other case, when there is !enableCustomSorting
        if (valueOne > valueTwo) {
            return 1;
        } else if (valueOne < valueTwo) {
            return -1;
        } else {
            return 0;
        }
    }
}
