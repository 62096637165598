import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import {
    BreadcrumbModule,
    ButtonModule,
    DropdownModule,
    GridModule,
    IconModule,
    InputModule,
    LoadingModule,
    RadioModule,
    SelectModule,
    TableModule,
    UIShellModule,
} from 'carbon-components-angular';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SharedModule } from '../../shared/shared.module';
import { ConfirmSurrogateComponent } from './confirm-surrogate/confirm-surrogate.component';
import { SurrogateComponent } from './surrogate.component';

@NgModule({
    declarations: [SurrogateComponent, ConfirmSurrogateComponent],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        InputModule,
        SelectModule,
        GridModule,
        RadioModule,
        TranslateModule,
        ButtonModule,
        LoadingModule,
        TableModule,
        UIShellModule,
        SharedModule,
        BreadcrumbModule,
        DropdownModule,
        ImageCropperModule,
        AngularSvgIconModule,
        IconModule,
    ],
})
export class SurrogateModule {}
