<div class="d-flex align-items-center mt-1-5r p-1r mb-1-5r bg-gray">
    <div class="d-flex align-items-center">
        <app-icon-bubble
            [size]="40"
            [color]="'green'"
            [image]="'24_linked.svg'"
            class="ml-0-5r mr-1-5r"
        ></app-icon-bubble>
        <span class="mr-0-75r s-head-16-24-bold">{{ recommendedBookings.length }}</span
        >{{ 'PAGES.BANK_TRANSACTIONS.LABEL_RECOMMENDED_BOOKINGS' | translate }}
    </div>
    <div class="ml-auto d-flex align-items-center">
        <span *ngIf="dateLatestUpdate[0]" class="mr-1-5r s-label-12-16-regular"
            >Stand {{ dateLatestUpdate[0] + ' ' + dateLatestUpdate[1] }} Uhr</span
        >
        <app-scalara-button
            class="mr-1-5r"
            [height]="'48px'"
            [variant]="'ghost'"
            type="button"
            (click)="updateSearch()"
        >
            {{ 'CONTROLS.BUTTONS.UPDATE_SEARCH' | translate }}
        </app-scalara-button>
        <app-scalara-button class="mr-1-5r" type="button" (click)="submitRecommendedBookings()">
            {{ 'PAGES.BANK_TRANSACTIONS.BUTTON_BOOK_RECOMMENDED_BOOKINGS' | translate }}
        </app-scalara-button>
    </div>
</div>
<div class="mb-1-5r pl-1r">
    <ibm-checkbox [checked]="isSelectAllChecked" (change)="updateBookingSelection($event)">
        <span class="ml-0-5r">{{ 'CONTROLS.BUTTONS.SELECT_ALL' | translate }}</span>
    </ibm-checkbox>
</div>
<div ibmCol *ngIf="form" [formGroup]="form">
    <div
        ibmRow
        *ngFor="let booking of recommendedBookings; let i = index"
        class="recommended-bookings recommended-bookings__item mb-1r"
        [ngClass]="{ 'recommended-bookings--not-selected': !isBookingSelected(i) }"
    >
        <div class="px-1r pt-0-75r border-right-1-gray">
            <ibm-checkbox [formControlName]="i"> </ibm-checkbox>
        </div>
        <div ibmCol>
            <!-- ITEM LEFT HEADER  -->
            <div ibmRow class="clickable" (click)="changeBookingDisplay(i)">
                <div class="px-1r pt-1r pb-1-5r">
                    <div class="s-label-12-22-regular">
                        {{ recommendedBookings[i].bankTransactionInfo.bankBookingDate | date : 'dd.MM.yyyy' }}
                    </div>
                </div>
                <div ibmCol class="pt-1r pb-1-5r pr-2-5r">
                    <div class="d-flex mb-0-5r">
                        <span class="s-body-14-22-bold">{{
                            recommendedBookings[i].bankTransactionInfo.counterpartName
                        }}</span>
                        <div class="ml-auto s-body-14-22-bold pr-1-5r">
                            {{ recommendedBookings[i].bankTransactionInfo.amount | eurocent }} €
                        </div>
                    </div>
                    <span>{{ recommendedBookings[i].bankTransactionInfo.purpose }}</span>
                </div>
            </div>
            <!-- ITEM LEFT CONTENT  -->
            <div ibmRow *ngIf="isBookingOpen[i]">
                <ng-container
                    *ngTemplateOutlet="
                        LEFT_CONTENT;
                        context: { bookingIndex: 0, bankTransactionInfo: recommendedBookings[i].bankTransactionInfo }
                    "
                ></ng-container>
            </div>
        </div>
        <div ibmCol class="border-left-1-gray pos-relative">
            <div class="status-icon" (click)="changeBookingDisplay(i)">
                <svg-icon
                    src="{{ isBookingSelected(i) ? '/assets/icons/24_linked.svg' : '/assets/icons/24_not-linked.svg' }}"
                ></svg-icon>
            </div>
            <!-- ITEM RIGHT HEADER  -->
            <div ibmRow class="clickable" (click)="changeBookingDisplay(i)">
                <div ibmCol class="pl-2-5r pt-1r pb-1-5r">
                    <div class="d-flex mb-0-5r">
                        <span class="s-body-14-22-bold">{{ recommendedBookings[i].recommendationGroup.name }}</span>
                        <div class="ml-auto s-body-14-22-bold">
                            {{ recommendedBookings[i].recommendationGroup.amount | eurocent }} €
                        </div>
                    </div>
                    <app-tag color="{{ isBookingSelected(i) ? 'purple' : 'gray' }}">
                        {{ recommendedBookings[i].bookingTypeLabel }}
                    </app-tag>
                </div>
                <div class="px-1-5r pt-1r pb-1-5r">
                    <div class="d-flex" [ngClass]="{ 'rotate-180': isBookingOpen[i] }">
                        <svg-icon class="ml-auto collapse-icon" src="/assets/icons/24_arrow-down.svg"></svg-icon>
                    </div>
                </div>
            </div>
            <!-- ITEM RIGHT CONTENT  -->
            <div ibmRow *ngIf="isBookingOpen[i]">
                <ng-container
                    *ngTemplateOutlet="
                        RIGHT_CONTENT;
                        context: { bookingIndex: i, recommendationGroup: recommendedBookings[i].recommendationGroup }
                    "
                ></ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #LEFT_CONTENT let-bookingIndex="bookingIndex" let-bankTransactionInfo="bankTransactionInfo">
    <div class="ml-7-5r">
        <div ibmRow>
            <div ibmCol>
                <div class="py-1r">
                    <div ibmRow class="mx-0r">
                        <div class="info-field__label s-label-12-22-semibold pb-0-5r">
                            {{ 'ACCOUNTING.BANK_TRANSACTION_DETAILS.COUNTERPART_NAME' | translate }}
                        </div>
                        <div ibmCol class="s-body-14-22-regular pb-0-5r">
                            {{ bankTransactionInfo.counterpartName }}
                        </div>
                    </div>
                    <div ibmRow class="mx-0r">
                        <div class="info-field__label s-label-12-22-semibold pb-0-5r">
                            {{ 'ACCOUNTING.BANK_TRANSACTION_DETAILS.PURPOSE' | translate }}
                        </div>
                        <div ibmCol class="s-body-14-22-regular pb-0-5r">
                            {{ bankTransactionInfo.purpose }}
                        </div>
                    </div>
                    <div ibmRow class="mx-0r">
                        <div class="info-field__label s-label-12-22-semibold pb-0-5r">
                            {{ 'ACCOUNTING.BANK_TRANSACTION_DETAILS.IBAN' | translate }}
                        </div>
                        <div ibmCol class="s-body-14-22-regular pb-0-5r">
                            {{ bankTransactionInfo.counterpartIban | ibanFormatter }}
                        </div>
                    </div>
                    <div ibmRow class="mx-0r">
                        <div class="info-field__label s-label-12-22-semibold pb-0-5r">
                            {{ 'ACCOUNTING.BANK_TRANSACTION_DETAILS.COUNTERPART_BIC' | translate }}
                        </div>
                        <div ibmCol class="s-body-14-22-regular pb-0-5r">
                            {{ bankTransactionInfo.counterpartBic }}
                        </div>
                    </div>
                    <div ibmRow class="mx-0r">
                        <div class="info-field__label s-label-12-22-semibold pb-0-5r">
                            {{ 'ACCOUNTING.BANK_TRANSACTION_DETAILS.COUNTERPART_BANKNAME' | translate }}
                        </div>
                        <div ibmCol class="s-body-14-22-regular pb-0-5r">
                            {{ bankTransactionInfo.counterpartBankName }}
                        </div>
                    </div>
                    <div ibmRow class="mx-0r">
                        <div class="info-field__label s-label-12-22-semibold pb-0-5r">
                            {{ 'ACCOUNTING.BANK_TRANSACTION_DETAILS.BANK_BOOKING_DATE' | translate }}
                        </div>
                        <div ibmCol class="s-body-14-22-regular pb-0-5r">
                            {{ bankTransactionInfo.bankBookingDate | date : 'dd.MM.yyyy' }}
                        </div>
                    </div>
                    <div ibmRow class="mx-0r">
                        <div class="info-field__label s-label-12-22-semibold pb-0-5r">
                            {{ 'ACCOUNTING.BANK_TRANSACTION_DETAILS.VALUE_DATE' | translate }}
                        </div>
                        <div ibmCol class="s-body-14-22-regular pb-0-5r">
                            {{ bankTransactionInfo.valueDate | date : 'dd.MM.yyyy' }}
                        </div>
                    </div>
                    <div ibmRow class="mx-0r">
                        <div class="info-field__label s-label-12-22-semibold pb-0-5r">
                            {{ 'ACCOUNTING.BANK_TRANSACTION_DETAILS.TOTAL_AMOUNT' | translate }}
                        </div>
                        <div ibmCol class="s-body-14-22-regular pb-0-5r">
                            {{ bankTransactionInfo.amount | eurocent }}
                        </div>
                    </div>
                    <div ibmRow class="mx-0r">
                        <div class="info-field__label s-label-12-22-semibold pb-0-5r">
                            {{ 'ACCOUNTING.BANK_TRANSACTION_DETAILS.OPEN_AMOUNT' | translate }}
                        </div>
                        <div ibmCol class="s-body-14-22-regular pb-0-5r">
                            {{ bankTransactionInfo.amountNotAssignedToBookings | eurocent }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #RIGHT_CONTENT let-bookingIndex="bookingIndex" let-recommendationGroup="recommendationGroup">
    <div ibmCol *ngIf="recommendationGroup.subgroups.length" class="px-0">
        <div
            class="ml-2-5r mr-1-5r"
            [ngClass]="{ 'border-bottom-1-gray': i < recommendationGroup.subgroups.length - 1 }"
            *ngFor="let subgroup of recommendationGroup.subgroups; let i = index"
        >
            <div ibmRow class="mx-0r clickable" (click)="changeBookingContentDisplay(bookingIndex, i)">
                <div ibmCol class="pl-0r pt-1r pb-1r">
                    <div class="d-flex">
                        <span class="s-body-14-22-regular">{{ subgroup.name }}</span>
                        <div class="ml-auto s-body-14-22-bold">{{ subgroup.amount | eurocent }} €</div>
                    </div>
                </div>
                <div class="px-1-5r pt-1r pb-1r">
                    <div class="d-flex" [ngClass]="{ 'rotate-180': isBookingContentOpen[bookingIndex][i] }">
                        <svg-icon class="ml-auto collapse-icon" src="/assets/icons/24_arrow-down.svg"></svg-icon>
                    </div>
                </div>
            </div>
            <div ibmRow *ngIf="isBookingContentOpen[bookingIndex][i]" class="pt-1-5r">
                <div ibmCol>
                    <div
                        *ngFor="
                            let recommendationItem of recommendationGroup.subgroups[i].recommendationItems;
                            let j = index
                        "
                        class="py-0-5r"
                    >
                        <div ibmRow class="mx-0r">
                            <div class="info-field__label s-label-12-22-semibold pb-0-5r">
                                {{ 'ENTITIES.OPEN_ITEM.GROUP' | translate }}
                            </div>
                            <div ibmCol class="s-body-14-22-regular pb-0-5r">
                                {{ recommendationItem.purpose }}
                            </div>
                        </div>
                        <div ibmRow class="mx-0r">
                            <div class="info-field__label s-label-12-22-semibold pb-0-5r">
                                {{ 'ENTITIES.OPEN_ITEM.DESCRIPTION' | translate }}
                            </div>
                            <div ibmCol class="s-body-14-22-regular pb-0-5r">
                                {{ recommendationItem.description }}
                            </div>
                        </div>
                        <div ibmRow class="mx-0r">
                            <div class="info-field__label s-label-12-22-semibold pb-0-5r">
                                {{ 'ENTITIES.OPEN_ITEM.DUE_DATE' | translate }}
                            </div>
                            <div
                                ibmCol
                                class="s-body-14-22-regular pb-0-5r"
                                [ngClass]="{ 's-red-01': recommendationItem.openItemInfo.dueDate <= today }"
                            >
                                {{ recommendationItem.openItemInfo.dueDate | date : 'dd.MM.yyyy' }}
                            </div>
                        </div>
                        <div ibmRow class="mx-0r">
                            <div class="info-field__label s-label-12-22-semibold pb-0-5r">
                                {{ 'ENTITIES.OPEN_ITEM.AMOUNT' | translate }}
                            </div>
                            <div ibmCol class="s-body-14-22-regular pb-0-5r">
                                {{ recommendationItem.openItemInfo.amount | eurocent }}
                            </div>
                        </div>
                        <div ibmRow class="mx-0r">
                            <div
                                class="info-field__label s-label-12-22-semibold pb-0-5r"
                                [ngClass]="{
                                    'border-bottom-1-gray':
                                        j < recommendationGroup.subgroups[i].recommendationItems.length - 1
                                }"
                            >
                                {{ 'ENTITIES.OPEN_ITEM.OUTSTANDING_BALANCE' | translate }}
                            </div>
                            <div
                                ibmCol
                                class="s-body-14-22-regular pb-0-5r"
                                [ngClass]="{
                                    'border-bottom-1-gray':
                                        j < recommendationGroup.subgroups[i].recommendationItems.length - 1
                                }"
                            >
                                {{ recommendationItem.openItemInfo.outstandingBalance | eurocent }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div ibmCol *ngIf="!recommendationGroup.subgroups.length">
        <div class="ml-1-5r mr-1-5r" [ngClass]="{ 'border-bottom-1-gray': false }">
            <div
                class="py-0-5r"
                *ngFor="let recommendationItem of recommendationGroup.recommendationItems; let j = index"
            >
                <div ibmRow class="mb-0-5r mx-0r">
                    <div class="info-field__label s-label-12-22-semibold">
                        {{ 'ENTITIES.OPEN_ITEM.GROUP' | translate }}
                    </div>
                    <div ibmCol class="s-body-14-22-regular">
                        {{ recommendationItem.purpose }}
                    </div>
                </div>
                <div ibmRow class="mb-0-5r mx-0r">
                    <div class="info-field__label s-label-12-22-semibold">
                        {{ 'ENTITIES.OPEN_ITEM.DESCRIPTION' | translate }}
                    </div>
                    <div ibmCol class="s-body-14-22-regular">
                        {{ recommendationItem.description }}
                    </div>
                </div>
                <div ibmRow class="mb-0-5r mx-0r">
                    <div class="info-field__label s-label-12-22-semibold">
                        {{ 'ENTITIES.OPEN_ITEM.DUE_DATE' | translate }}
                    </div>
                    <div
                        ibmCol
                        class="s-body-14-22-regular"
                        [ngClass]="{ 's-red-01': recommendationItem.openItemInfo.dueDate <= today }"
                    >
                        {{ recommendationItem.openItemInfo.dueDate | date : 'dd.MM.yyyy' }}
                    </div>
                </div>
                <div ibmRow class="mb-0-5r mx-0r">
                    <div class="info-field__label s-label-12-22-semibold">
                        {{ 'ENTITIES.OPEN_ITEM.AMOUNT' | translate }}
                    </div>
                    <div ibmCol class="s-body-14-22-regular">
                        {{ recommendationItem.openItemInfo.amount | eurocent }}
                    </div>
                </div>
                <div ibmRow class="mb-0-5r mx-0r">
                    <div
                        class="info-field__label s-label-12-22-semibold pb-0-5r"
                        [ngClass]="{ 'border-bottom-1-gray': j < recommendationGroup.recommendationItems.length - 1 }"
                    >
                        {{ 'ENTITIES.OPEN_ITEM.OUTSTANDING_BALANCE' | translate }}
                    </div>
                    <div
                        ibmCol
                        class="s-body-14-22-regular pb-0-5r"
                        [ngClass]="{
                            'border-bottom-1-gray': j < recommendationGroup.recommendationItems.length - 1
                        }"
                    >
                        {{ recommendationItem.openItemInfo.outstandingBalance | eurocent }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
