<div class="p-05"></div>

<div ibmGrid class="bx--grid--full-width">
    <div ibmRow>
        <div ibmCol
             [ngClass]="['s-head-14-22-regular', 'mb-08']"
             [columnNumbers]="{'md': 8, 'lg': 16}">
            Wir freuen uns über Ihre Benutzung der SCALARA-Plattform.
            Der Schutz Ihrer personenbezogenen Daten ist ein wichtiges Anliegen für uns.
            Nachstehend informieren wir Sie gemäß Art. 12, 13 und 21 der Datenschutzgrundverordnung (DSGVO) über den
            Umgang mit Ihren personenbezogenen Daten bei der Nutzung unserer mobilen Apps oder unserer Web-Apps
            (nachfolgend zusammen die „SCALARA-Plattform“ genannt). Personenbezogene Daten sind alle Informationen,
            die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen. Darunter fallen z.B.
            Informationen wie der Name, die Anschrift, die Telefonnummer, das Geburtsdatum oder die IP-Adresse.
        </div>

        <div ibmCol
             [ngClass]="['s-head-14-22-bold', 'mb-08']"
             [columnNumbers]="{'md': 8, 'lg': 16}">
            A. Verantwortlicher
        </div>

        <div ibmCol
             [ngClass]="['s-head-14-22-regular', 'mb-08']"
             [columnNumbers]="{'md': 8, 'lg': 16}">
            SCALARA GmbH <br />
            Immendorfer Str. 2 <br />
            50321 Brühl <br /> <br />
            E-Mail: info@scalara.de
        </div>

        <div ibmCol
             [ngClass]="['s-head-14-22-bold', 'mb-08']"
             [columnNumbers]="{'md': 8, 'lg': 16}">
            B. Zwecke und Rechtsgrundlagen der Datenverarbeitung
        </div>

        <div ibmCol
             [ngClass]="['s-head-14-22-regular', 'mb-08']"
             [columnNumbers]="{'md': 8, 'lg': 16}">
            I.           Technische Bereitstellung der Plattform
        </div>

        <div ibmCol
             [ngClass]="['s-head-14-22-regular', 'mb-08']"
             [columnNumbers]="{'md': 8, 'lg': 16}">
            Wenn Sie erfahren möchten, wie wir personenbezogene Daten verarbeiten,
            wenn Sie auf www.scalara.de surfen, lesen Sie bitte die Datenschutzerklärung für die SCALARA-Website. <br />

            Zum Zweck der technischen Bereitstellung der SCALARA-Plattform sendet Ihr Browser bzw. die mobile App
            automatisch Informationen an unseren Webserver, ohne dass wir darauf einen Einfluss haben.
            Folgende Informationen überträgt der Browser bzw. ggfs. die mobile App technisch bedingt bei
            jeder Nutzung der SCALARA-Plattform: <br />
            <ul>
                <li>IP-Adresse,</li>
                <li>Hostname (z.B. Internet Service Provider, Unternehmen, Hochschule),</li>
                <li>Browser und Betriebssystem,</li>
                <li>Datum und Uhrzeit des Zugriffs,</li>
                <li>angeforderte Website,</li>
                <li>vorher besuchte Website.</li>
            </ul>
            <br />
            Die vorübergehende Speicherung Ihrer IP-Adresse durch unser System ist notwendig,
            um die Daten an Ihren Browser bzw. an die mobile App zu übermitteln.
            Hierfür muss die IP-Adresse zwangsläufig für die Nutzungsdauer gespeichert werden.
            Die weitere Speicherung der oben genannten Daten in Log-Files erfolgt,
            um die Funktionsfähigkeit der SCALARA-Plattform sicherzustellen.
            Zudem dienen uns diese Daten zur Optimierung der SCALARA-Plattform und zur Gewährleistung der
            Sicherheit unserer informationstechnischen Systeme (z.B. Angriffserkennung).
            Eine Auswertung der Daten zu Marketingzwecken findet in diesem Zusammenhang nicht statt.
        </div>

        <div ibmCol
             [ngClass]="['s-head-14-22-regular', 'mb-08']"
             [columnNumbers]="{'md': 8, 'lg': 16}">

            Ferner setzen wir Cookies ein, um Ihnen die SCALARA-Plattform zur Nutzung zur Verfügung zu stellen. Bei Cookies handelt es sich um Textdateien, die ein Browser beim Aufruf einer Website auf dem Computersystem speichert. Ein Cookie enthält eine charakteristische Zeichenfolge, die eine eindeutige Identifizierung des Browsers beim erneuten Aufrufen der Website ermöglicht. Wir setzen diese Cookies unter anderem dazu ein, Ihnen die SCALARA-Plattform mit ihren technischen Funktionen zur Verfügung zu stellen. Einige Funktionen der SCALARA-Plattform können wir ohne den Einsatz von Cookies nicht anbieten. In den Cookies werden dabei folgende Informationen gespeichert und an uns übermittelt:

            Der Login-Status, damit Sie sich nicht bei jedem Aufruf erneut einloggen müssen,
            die von Ihnen ausgewählte Sprache.
            Wir verarbeiten Ihre personenbezogenen Daten zur technischen Bereitstellung der SCALARA-Plattform aufgrund von Art. 6 Abs. 1 Uabs. 1 Buchst. b) DSGVO, um Ihnen die SCALARA-Plattform technisch zur Verfügung stellen zu können.

            II.          Datenverarbeitung bei der Nutzung der SCALARA-Plattform

            Neben der Datenverarbeitung für die technische Bereitstellung der SCALARA-Plattform verarbeiten wir weitere Daten, wenn Sie sich zur Nutzung der SCALARA-Plattform anmelden bzw. einloggen.

            Die SCALARA-Plattform bietet ihren Nutzer*innen die Möglichkeit, einen Account zu erstellen. Je nach den Geschäftsbeziehungen der oder des jeweiligen Nutzers oder Nutzerin zu einer, einem oder mehreren anderen Nutzer*innen, bietet die Plattform unterschiedliche Funktionen. So kann eine oder ein Verwalter*in z.B. die Kontaktdaten von Eigentümer*innen oder Mieter*innen der von ihr oder ihm verwalteten Immobilien und von Dienstleister*innen verwalten. Sind diese Eigentümer*innen, Mieter*innen oder Dienstleister*innen selbst angemeldete Nutzer*innen der SCALARA-Plattform, können die Nutzer*innen auch untereinander kommunizieren.

            Nachfolgend informieren wir Sie über die Datenverarbeitung bei der Nutzung der SCALARA-Plattform:

            1.          Anmeldung und Erstellung eines Nutzerprofils

            Für die Anmeldung an der SCALARA-Plattform und die Erstellung Ihres Nutzerprofils verarbeiten wir die von Ihnen angegebenen Daten. Die für die Anmeldung erforderlichen Daten sind mit einem Sternchen (*) markiert.

            Im Rahmen der Anmeldung und Erstellung eines Nutzerprofils verarbeiten wir Ihre Daten

            ·            nach Art. 6 Abs. 1 Uabs. 1 Buchst. b) DSGVO, soweit die Daten für die Anmeldung oder Erstellung des Nutzerprofils erforderlich sind oder

            ·            aufgrund Ihrer Einwilligung nach Art. 6 Abs. 1 Uabs. 1 Buchst. a) DSGVO, soweit sie freiwillig Daten angeben, die für die Anmeldung und die Erstellung eines Nutzerprofils nicht erforderlich sind.

            2.          Log-In

            Wenn Sie sich in die SCALARA-Plattform einloggen, verarbeiten wir Ihren Nutzernamen und Ihr Passwort für den Login auf der Grundlage von Art. 6 Abs. 1 Uabs. 1 Buchst. b) DSGVO, um Ihnen den Log-In zu ermöglichen.

            3.          Nutzung zur Kontaktverwaltung durch Verwalter*innen

            Nutzt die Verwalterin oder der Verwalter die SCALARA-Plattform als Kontaktbuch, verarbeiten wir diese Kontaktdaten nicht für die Erstellung eines Nutzerkontos, sondern wir werden als Auftragsverarbeiter auf Weisung der Verwalterin oder des Verwalters tätig und diese oder dieser ist für die Datenverarbeitung verantwortlich. Sie oder er kann auch über Einzelheiten der durch ihn verarbeiteten Daten informieren.

            4.          Übernahme der Kontrolle durch einen Nutzer

            Wenn eine Nutzerin oder ein Nutzer, deren oder dessen Kontaktdaten bereits von einer Verwalterin oder einem Verwalter in der SCALARA-Plattform verarbeitet werden, sich anmeldet und mit dieser Verwalterin oder diesem Verwalter in eine Geschäftsbeziehung tritt, kann die Nutzerin oder der Nutzer die Kontrolle über ihre oder seine Daten übernehmen. Wir verarbeiten diese dann nicht mehr für die Verwalterin oder den Verwalter im Rahmen einer Auftragsverarbeitung, sondern aufgrund unseres Nutzerverhältnisses mit der jeweiligen Nutzerin oder dem jeweiligen Nutzer und diese oder dieser entscheidet, welche Daten sie oder er für die Verwalterin oder den Verwalter oder andere Geschäftskontakte freigeben möchte.

            Wir verarbeiten diese Daten nach Art. 6 Abs. 1 Uabs. 1 Buchst. b) DSGVO.

            5.          Geschäftsbeziehungen

            SCALARA ist nach Geschäftsbeziehungen organisiert. Eine Geschäftsbeziehung besteht z.B. zwischen Mieter*innen und Eigentürmer*innen, zwischen Mieter*innen und Verwalter*innen oder zwischen Verwalter*innen und Eigentümer*innen. Nutzer*innen können für jede Geschäftsbeziehung Daten eingeben und die anderen an der Geschäftsbeziehung Beteiligten können dann nur diese Daten einsehen.

            Im Rahmen von Geschäftsbeziehungen verarbeiten wir Ihre Daten nach Art. 6 Abs. 1 Uabs. 1 Buchst. b) DSGVO zur Bereitstellung der Funktion der Geschäftsbeziehungen.

            6.          Kontakt und Dokumente

            Wenn Sie über die SCALARA-Plattform Kontakt zur/zum Verwalter*in aufnehmen oder wenn Ihr Verwalter Ihnen Dokumente zur Verfügung stellt, verarbeiten wir Ihre Daten (insbesondere Ihren Namen und die von Ihnen eingegebenen bzw. bereitgestellten Daten) im Auftrag der Verwalterin oder des Verwalters auf einer Grundlage eines Auftragsverarbeitungsvertrags mit ihr oder ihm. Einzelheiten über die Datenverarbeitung durch Ihre Verwalterin oder Ihren Verwalter erfahren Sie von dieser oder diesem.

            7.          Newsletter

            Mit Ihrer Einwilligung nutzen wir Ihre Daten um Ihnen unseren E-Mail Newsletter zuzusenden. Dafür verwenden wir Ihre E-Mailadresse und ggfs. auch Ihren Namen für eine persönlichere Ansprache.

            Bei der Anmeldung zu unserem Newsletter setzen wir ein Double Opt In-Verfahren. Das bedeutet, dass Sie nach der Anmeldung und der Erteilung Ihrer Einwilligung eine Bestätigungs-E-Mail erhalten. Damit überprüfen wir, dass sich auch wirklich der Inhaber der E-Mailadresse für den Newsletter angemeldet hat.

            Wir nutzen hierfür auch weitere Informationen, wie beispielsweise Empfangs- und Lesebestätigungen von E-Mails, Informationen über Ihren Computer und Verbindung zum Internet, Betriebssystem und Plattform und Ihre Nutzung von SCALARA. Diese Informationen nutzen wir ausschließlich in pseudonymisierter Form. Durch die Analyse und Auswertung dieser Informationen ist es uns möglich, das Nutzungsverhalten zu analysieren und unseren Newsletter zu optimieren.

            Wir verarbeiten Ihre Daten für den Versand von Newslettern und die Personalisierung der Ansprache aufgrund Ihrer Einwilligung gemäß Art. 6 Abs. 1 Uabs. 1 Buchst. a) DSGVO. Sie können die Einwilligung jederzeit uns gegenüber widerrufen, indem Sie den Abbestellen-Button am Ende des Newsletters anklicken.

            Für den Newsletter-Versand setzen wir „Mailchimp“ von Rocket Science Group, LLC, 675 Ponce De Leon Ave NE #5000, Atlanta, GA 30308, USA ein. Mit Ihrer Einwilligung in den Newsletterversand haben Sie auch eingewilligt, dass die Datenverarbeitung teilweise in den USA stattfinden kann. Die Datenschutzerklärung zu Mailchimp finden Sie unter: https://mailchimp.com/legal/privacy/

            C.         Kategorien von Empfängern

            Innerhalb von SCALARA erhalten nur diejenigen Zugriff auf Ihre Daten, die diese zur Erfüllung unserer vertraglichen und gesetzlichen Pflichten brauchen. Auch von uns eingesetzte Dienstleister und Erfüllungsgehilfen können zu diesen Zwecken Daten erhalten. Dies sind z.B. IT-Dienstleister, Telekommunikationsdienstleister oder externe Partner zur Durchführung von Buchungen. Die Weitergabe von Daten basiert entweder auf einer rechtlichen Erlaubnis oder auf einem Auftragsverarbeitungsvertrag, mit welchem wir den Schutz Ihrer personenbezogenen Daten sicherstellen.

            D.         Drittlandtransfer

            Grundsätzlich übertragen wir Ihre personenbezogenen Daten nicht in Länder außerhalb der EU bzw. des EWR (sog. Drittländer) oder an internationale Organisationen. Sollten wir ausnahmsweise personenbezogene Daten in Drittländer übertragen, für die es keinen Angemessenheitsbeschluss der Europäischen Kommission nach Art. 45 DSGVO gibt, stellen wir durch geeignete Garantien nach Art. 46 DSGVO sicher, dass auch bei Ihrer Datenverarbeitung im unsicheren Drittland ein angemessenes Datenschutzniveau eingehalten wird.

            E.         Dauer der Speicherung

            I.           Technische Bereitstellung der Plattform

            Bei der technischen Bereitstellung der Website erhobene Daten speichern wir ausschließlich für die Dauer des Besuchs unserer Website. Nachdem Sie unsere Website verlassen haben, werden Ihre personenbezogen Daten unverzüglich gelöscht.

            Von uns gesetzte Cookies Haben die nachfolgend angegebene Lebensdauer:

            Session Cookie (für den Login)
            Sentry Crash Reporting Cookie (zur Analyse von Fehlverhalten der Plattform)
            II.          Nutzung der SCALARA-Plattform

            Bei einer aktiven Nutzung der SCALARA-Plattform speichern wir Ihre personenbezogenen Daten solange Sie ein Nutzungskonto haben. Zusätzlich speichern wir Ihre personenbezogenen Daten ggfs. bis zum Eintritt der Verjährung etwaiger rechtlicher Ansprüche aus der Beziehung mit Ihnen, um sie gegebenenfalls als Beweismittel einzusetzen. Die Verjährungsfrist beträgt in der Regel zwischen einem und drei Jahren, kann aber auch bis zu 30 Jahre betragen.

            Spätestens mit Eintritt der Verjährung löschen wir Ihre personenbezogenen Daten, es sei denn, es liegt eine gesetzliche Aufbewahrungspflicht, zum Beispiel aus dem Handelsgesetzbuch (§§ 238, 257 Abs. 4 HGB) oder aus der Abgabenordnung (§ 147 Abs. 3, 4 AO) vor. Diese Aufbewahrungspflichten können zwei bis zehn Jahre betragen. Für diesen Zeitraum werden die Daten allein für den Fall einer Überprüfung durch die Finanzverwaltung erneut verarbeitet.

            Soweit Sie als Mieter*in oder Eigentümer*in Ihrere Verwalterin oder Ihrem Verwalter personenbezogene Daten (Dokumente, Kontaktdaten) zur Verfügung gestellt haben, speichern wir diese Daten ggfs. weiterhin als Auftragsverarbeiter Ihrer Verwalterin oder Ihres Verwalters. Wenden Sie sich bitte an Ihre Verwalterin oder Ihren Verwalter, um weitere Informationen über die Speicherung personenbezogener Daten in ihrem oder seinem Auftrag zu erfahren.

            F.         Profiling

            Wir verarbeiten Ihre Daten teilweise automatisiert mit dem Ziel, bestimmte persönliche Aspekte zu bewerten (Profiling).

            G.        Ihre Rechte als betroffene Person

            Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie ein „Betroffener“ im Sinne der DSGVO. Ihnen stehen folgende Rechte gegenüber uns als Verantwortlicher zu:

            I.           Recht auf Auskunft

            Sie können Auskunft darüber verlangen, ob wir personenbezogene Daten von Ihnen verarbeiten. Ist dies der Fall, so haben Sie ein Recht auf Auskunft über diese personenbezogene Daten sowie auf weitere mit der Verarbeitung zusammenhängende Informationen (Art. 15 DSGVO). Bitte beachten Sie, dass dieses Auskunftsrecht in bestimmten Fällen eingeschränkt oder ausgeschlossen sein kann.

            II.          Recht auf Berichtigung

            Für den Fall, dass personenbezogene Daten über Sie nicht (mehr) zutreffend oder unvollständig sind, können Sie eine Berichtigung und gegebenenfalls Vervollständigung dieser Daten verlangen (Art. 16 DSGVO).

            III.        Recht auf Löschung oder Einschränkung der Verarbeitung

            Bei Vorliegen der gesetzlichen Voraussetzungen können Sie die Löschung Ihrer personenbezogenen Daten (Art. 17 DSGVO) oder die Einschränkung der Verarbeitung dieser Daten (Art. 18 DSGVO) verlangen. Das Recht auf Löschung nach Art. 17 Abs. 1 und 2 DSGVO besteht jedoch unter anderem dann nicht, wenn die Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist (Art. 17 Abs. 3 Buchst. b) DSGVO).

            IV.        Widerspruchsrecht

            Textfeld 2

            V.         Recht auf Datenübertragbarkeit

            Sie sind berechtigt, unter den Voraussetzungen von Art. 20 DSGVO von uns zu verlangen, dass wir Ihnen die Sie betreffenden personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu übergeben.

            VI.        Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung

            Sie haben das Recht, Ihre Einwilligung jederzeit zu widerrufen. Der Widerruf wirkt erst für die Zukunft; das heißt, durch den Widerruf wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitungen nicht berührt.

            VII.      Recht auf Beschwerde bei einer Aufsichtsbehörde

            Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelf steht einem Betroffenen (Ihnen) das Recht auf Beschwerde bei einer Aufsichtsbehörde – insbesondere in dem Mitgliedsstaat Ihres Aufenthaltsortes – zu, wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer personenbezogenen Daten durch uns gegen die DSGVO verstößt.

            Die für uns zuständige Aufsichtsbehörde ist:

            Landesbeauftragte/r für Datenschutz und Informationsfreiheit
            Nordrhein-Westfalen
            Kavalleriestr. 2 – 4
            40213 Düsseldorf

            Tel.: 0211/38424-0
            Fax: 0211/38424-10
            E-Mail: poststelle@ldi.nrw.de

            Wir empfehlen Ihnen allerdings, eine Beschwerde zunächst immer an uns zu richten. Ihre Anträge über die Ausübung Ihrer Rechte sollten nach Möglichkeit schriftlich an die oben angegebene Anschrift adressiert werden.

            H.         Umfang Ihrer Pflichten zur Bereitstellung von Daten

            Grundsätzlich sind Sie nicht verpflichtet, uns Ihre personenbezogenen Daten mitzuteilen. Wenn Sie dies jedoch nicht tun, werden wir Ihnen unsere Website nicht oder nicht mehr in vollem Funktionsumfang zur Verfügung stellen können, Ihre Anfragen an uns nicht beantworten können und mit Ihnen keinen Vertrag eingehen können.

            I.           Änderungen

            Wie behalten uns das Recht vor, diese Datenschutzerklärung jederzeit zu ändern. Etwaige Änderungen werden durch Veröffentlichung der geänderten Datenschutzerklärung auf unserer Website bekannt gemacht. Soweit nicht ein anderes bestimmt ist, werden solche Änderungen sofort wirksam. Bitte prüfen Sie daher diese Datenschutzerklärung regelmäßig, um die jeweils aktuellste Version einzusehen.

            Zuletzt aktualisiert im Februar 2021

        </div>
    </div>
</div>
