<div ibmRow class="tw-mb-12">
    <div ibmCol [columnNumbers]="{ md: 4, lg: 2 }" class="s-head-28-36-bold mb-04">
        {{ 'PAGES.ECONOMICS.LABEL_MV_ECONOMICS' | translate }}
    </div>

    <div ibmCol [columnNumbers]="{ md: 12, lg: 14 }">
        <div class="text-right">
            <app-accounting-date-picker-selector></app-accounting-date-picker-selector>
        </div>
    </div>
</div>
<ibm-tabs [followFocus]="true" [cacheActive]="true">
    <ibm-tab heading="{{ 'PAGES.ECONOMICS.LABEL_MV_INCOME_AND_EXPENSE' | translate }}">
        <app-mv-income-and-expense-iae-tab></app-mv-income-and-expense-iae-tab>
    </ibm-tab>
    <ibm-tab heading="{{ 'PAGES.ECONOMICS.LABEL_MV_TAX_REPORT' | translate }}">
        <app-mv-income-and-expense-tax-report-tab></app-mv-income-and-expense-tax-report-tab>
    </ibm-tab>
    <!-- <ibm-tab heading="{{ 'PAGES.ECONOMICS.LABEL_MV_RENT_ACCOUNT_REPORTS' | translate }}"> -->
    <!-- <app-annual-statement-individual-tab></app-annual-statement-individual-tab> -->
    <!-- </ibm-tab> -->
</ibm-tabs>
