import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import {
    BreadcrumbModule,
    ButtonModule,
    CheckboxModule,
    DatePickerModule,
    DialogModule,
    DropdownModule,
    GridModule,
    InlineLoadingModule,
    InputModule,
    LoadingModule,
    NotificationModule,
    NumberModule,
    RadioModule,
    SelectModule,
    TableModule,
    TabsModule,
} from 'carbon-components-angular';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxViewerModule } from 'ngx-viewer';
import { EurocentPipe } from 'src/app/shared/pipes/eurocent.pipe';
import { SharedModule } from 'src/app/shared/shared.module';
import { ActivateAnnualStatementComponent } from './activate-annual-statement/activate-annual-statement.component';
import { AddAnnualStatementConsumptionKeyComponent } from './add-annual-statement-consumption-key/add-annual-statement-consumption-key.component';
import { AddAnnualStatementDistributionKeyComponent } from './add-annual-statement-distribution-key/add-annual-statement-distribution-key.component';
import { AddAnnualStatementComponent } from './add-annual-statement/add-annual-statement.component';
import { AddWssDistributionKeyComponent } from './add-wss-distribution-key/add-wss-distribution-key.component';
import { AnnualStatementConsumptionTabComponent } from './annual-statement-consumption-tab/annual-statement-consumption-tab.component';
import { AnnualStatementDetailsComponent } from './annual-statement-details/annual-statement-details.component';
import { AnnualStatementDistributionKeyDetailsComponent } from './annual-statement-distribution-key-details/annual-statement-distribution-key-details.component';
import { AnnualStatementDistributionKeysTabComponent } from './annual-statement-distribution-keys-tab/annual-statement-distribution-keys-tab.component';
import { AnnualStatementIndividualTabComponent } from './annual-statement-individual-tab/annual-statement-individual-tab.component';
import { AnnualStatementOverviewComponent } from './annual-statement-overview/annual-statement-overview.component';
import { AnnualStatementTabsComponent } from './annual-statement-tabs/annual-statement-tabs.component';
import { AssetReportComponent } from './asset-report/asset-report.component';
import { BillingResultsOverviewComponent } from './billing-results-overview/billing-results-overview.component';
import { DeleteAnnualStatementDistributionKeyComponent } from './delete-annual-statement-distribution-key/delete-annual-statement-distribution-key.component';
import { DeleteAnnualStatementComponent } from './delete-annual-statement/delete-annual-statement.component';
import { EditAnnualStatementComponent } from './edit-annual-statement/edit-annual-statement.component';
import { IncomeAndExpenseStatementComponent } from './income-and-expense-statement/income-and-expense-statement.component';
import { PdfPrintConfigSeletionComponent } from './pdf-print-config-seletion/pdf-print-config-seletion.component';
import { ReservesDevelopmentComponent } from './reserves-development/reserves-development.component';

@NgModule({
    declarations: [
        AnnualStatementOverviewComponent,
        AnnualStatementDetailsComponent,
        AddAnnualStatementComponent,
        DeleteAnnualStatementComponent,
        ActivateAnnualStatementComponent,
        AnnualStatementTabsComponent,
        IncomeAndExpenseStatementComponent,
        BillingResultsOverviewComponent,
        ReservesDevelopmentComponent,
        AssetReportComponent,
        AnnualStatementIndividualTabComponent,
        EditAnnualStatementComponent,
        AnnualStatementConsumptionTabComponent,
        AddWssDistributionKeyComponent,
        AddAnnualStatementConsumptionKeyComponent,
        AnnualStatementDistributionKeysTabComponent,
        AddAnnualStatementDistributionKeyComponent,
        AnnualStatementDistributionKeyDetailsComponent,
        DeleteAnnualStatementDistributionKeyComponent,
        PdfPrintConfigSeletionComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        TableModule,
        GridModule,
        ButtonModule,
        LoadingModule,
        InlineLoadingModule,
        DatePickerModule,
        SelectModule,
        FormsModule,
        ReactiveFormsModule,
        NotificationModule,
        SharedModule,
        RouterModule,
        DropdownModule,
        InputModule,
        NumberModule,
        AngularSvgIconModule,
        BreadcrumbModule,
        DialogModule,
        CheckboxModule,
        RadioModule,
        ImageCropperModule,
        NgxExtendedPdfViewerModule,
        NgxViewerModule,
        TabsModule,
    ],
    providers: [EurocentPipe, DatePipe],
})
export class AnnualStatementModule {}
