/**
 * SCALARA Accounting Module
 * The SCALARA Accounting API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EconomicPlanDto } from './economicPlanDto';


export interface WssDto { 
    id: string;
    createdAt: string;
    updatedAt: string;
    economicPlanId: string;
    economicPlan: EconomicPlanDto;
    status: WssDto.StatusEnum;
    processingStatus: WssDto.ProcessingStatusEnum;
    dataRefreshedAt: string | null;
    movableAssetDescription?: string;
    realEstateDescription: string;
    needsRecalculation?: boolean;
}
export namespace WssDto {
    export type StatusEnum = 'DRAFT' | 'IN_REVIEW' | 'ACTIVE';
    export const StatusEnum = {
        Draft: 'DRAFT' as StatusEnum,
        InReview: 'IN_REVIEW' as StatusEnum,
        Active: 'ACTIVE' as StatusEnum
    };
    export type ProcessingStatusEnum = 'BLANK' | 'PROCESSING_REQUESTED' | 'PROCESSING' | 'READY';
    export const ProcessingStatusEnum = {
        Blank: 'BLANK' as ProcessingStatusEnum,
        ProcessingRequested: 'PROCESSING_REQUESTED' as ProcessingStatusEnum,
        Processing: 'PROCESSING' as ProcessingStatusEnum,
        Ready: 'READY' as ProcessingStatusEnum
    };
}


