<ibm-tabs [followFocus]="true" [cacheActive]="true">
    <ibm-tab
        heading="{{ 'PAGES.ANNUAL_STATEMENT.TABS.HEADINGS.FULL_STATEMENT' | translate }}"
        *ngIf="wss?.status !== 'ACTIVE'"
    >
        <div *ngIf="this.calculateState().showing === 'listItems'">
            <div class="tw-mt-16 tw-px-4 tw-py-[18px] tw-s-label-12-22-semibold tw-text-scalaraGray-03">
                Bestandteile des Jahresabschlusses
            </div>
            <ng-container>
                <div
                    class="tw-cursor-pointer hover:tw-bg-scalaraGray-06 tw-px-4 tw-py-[18px] tw-border-t tw-border-solid tw-border-scalaraGray-05 tw-s-body-14-22-regular"
                    *ngFor="let completeTabItem of completeStatementTabItems"
                    (click)="selectCompleteStatementTabItem(completeTabItem)"
                >
                    <div>{{ completeTabItem.label }}</div>
                </div>
            </ng-container>
        </div>
        <div *ngIf="this.calculateState().showing === 'selectedComponent'">
            <app-scalara-button
                class="tw-inline-block tw-mb-10 tw-mt-10"
                svgName="24_arrow-left.svg"
                variant="ghost"
                (click)="unselectCompleteStatementTabItem()"
                >{{ 'PAGES.ANNUAL_STATEMENT.DETAILS.LABEL_BUTTON_BACK_OVERVIEW' | translate }}</app-scalara-button
            >
        </div>
        <ng-container #vcr_complete_statement></ng-container>
    </ibm-tab>
    <ibm-tab heading="{{ 'PAGES.ANNUAL_STATEMENT.TABS.HEADINGS.INDIVIDUAL_STATEMENT' | translate }}">
        <app-annual-statement-individual-tab></app-annual-statement-individual-tab>
    </ibm-tab>
    <ibm-tab
        heading="{{ 'PAGES.ANNUAL_STATEMENT.TABS.HEADINGS.CONSUMPTIONS' | translate }}"
        *ngIf="wss?.status !== 'ACTIVE'"
    >
        <app-annual-statement-consumption-tab
            (openAddDistributionKeyOverlay)="openAddDistributionKeyOverlay('CONSUMPTION')"
            [ledgerId]="ledgerId!"
            [wss]="wss"
            [triggerRefresh]="triggerTabRefreshValue"
        ></app-annual-statement-consumption-tab>
    </ibm-tab>
    <ibm-tab
        heading="{{ 'PAGES.ANNUAL_STATEMENT.TABS.HEADINGS.DISTRIBUTION_KEYS' | translate }}"
        *ngIf="wss?.status !== 'ACTIVE'"
    >
        <app-annual-statement-distribution-keys-tab
            (openAddDistributionKeyOverlay)="openAddDistributionKeyOverlay('CONSUMPTION')"
            [editMode]="false"
            [wss]="wss"
        ></app-annual-statement-distribution-keys-tab>
    </ibm-tab>
</ibm-tabs>
