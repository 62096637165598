/**
 * SCALARA Accounting Module
 * The SCALARA Accounting API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PdfBackgroundDto { 
    createdAt: string;
    document: string;
    updatedAt: string;
    personId: string;
    type: PdfBackgroundDto.TypeEnum;
}
export namespace PdfBackgroundDto {
    export type TypeEnum = 'COVER_LETTER' | 'CONTENT_VERTICAL' | 'CONTENT_LANDSCAPE';
    export const TypeEnum = {
        CoverLetter: 'COVER_LETTER' as TypeEnum,
        ContentVertical: 'CONTENT_VERTICAL' as TypeEnum,
        ContentLandscape: 'CONTENT_LANDSCAPE' as TypeEnum
    };
}


