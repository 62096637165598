/**
 * SCALARA Base Module
 * The SCALARA API for User, Person and Property resources
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateSharedOwnershipDto { 
    category?: CreateSharedOwnershipDto.CategoryEnum;
    title?: string;
    buildingId: string;
    specialRightsOwnershipId?: string;
}
export namespace CreateSharedOwnershipDto {
    export type CategoryEnum = 'Abstellraum' | 'Außenfläche' | 'Garage' | 'Garten' | 'Grünfläche' | 'Gewerbeeinheit' | 'Heizungsraum' | 'Kellerraum' | 'Speicher' | 'Stellplatz' | 'Technikraum' | 'Wohnung' | 'Sonstiges';
    export const CategoryEnum = {
        Abstellraum: 'Abstellraum' as CategoryEnum,
        Auenflche: 'Außenfläche' as CategoryEnum,
        Garage: 'Garage' as CategoryEnum,
        Garten: 'Garten' as CategoryEnum,
        Grnflche: 'Grünfläche' as CategoryEnum,
        Gewerbeeinheit: 'Gewerbeeinheit' as CategoryEnum,
        Heizungsraum: 'Heizungsraum' as CategoryEnum,
        Kellerraum: 'Kellerraum' as CategoryEnum,
        Speicher: 'Speicher' as CategoryEnum,
        Stellplatz: 'Stellplatz' as CategoryEnum,
        Technikraum: 'Technikraum' as CategoryEnum,
        Wohnung: 'Wohnung' as CategoryEnum,
        Sonstiges: 'Sonstiges' as CategoryEnum
    };
}


