import {
    AfterContentInit,
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ComboBox } from 'carbon-components-angular';

@Component({
    selector: 'app-combo-box',
    templateUrl: './combo-box.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: ComboBoxComponent,
            multi: true,
        },
    ],
})
export class ComboBoxComponent extends ComboBox implements OnChanges, AfterViewInit, AfterContentInit, OnDestroy {
    @Output() public cleared = new EventEmitter<void>();
    @Input() public clearable = true;

    public override label = '';

    public override ngOnChanges(changes: any): void {
        super.ngOnChanges(changes);
    }

    public override ngAfterContentInit(): void {
        super.ngAfterContentInit();
    }

    public override ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public override ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public override onSearch($event: Event, shouldEmitSearch = true): void {
        if (!$event.target) {
            return;
        }

        const searchString = ($event.target as HTMLInputElement).value;
        if (shouldEmitSearch) {
            this.search.emit(searchString);
        }

        this.showClearButton = !!searchString;
        this.view.filterBy(searchString);

        if (searchString !== '') {
            this.openDropdown();
        } else {
            this.selectedValue = '';
            if (
                this.type === 'multi' &&
                (this.selectionFeedback === 'top' || this.selectionFeedback === 'top-after-reopen')
            ) {
                this.view.reorderSelected();
            }
        }
    }

    public override clearInput(event: any): void {
        super.clearInput(event);
        this.cleared.emit();
    }

    public override toggleDropdown(): void {
        setTimeout(() => {
            super.toggleDropdown();
        }, 0);
    }

    public override onSubmit(event: any): void {
        super.onSubmit(event);
    }

    public stopPropagation(event: any): void {
        event.stopPropagation();
    }
}
