import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbItem } from 'carbon-components-angular';
import {
    BehaviorSubject,
    Subject,
    combineLatest,
    distinctUntilChanged,
    filter,
    map,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs';
import { BreadCrumbService } from 'src/app/core/services/breadcrumb.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { LedgerCustomService } from 'src/app/features/accounting/components/services/ledger-custom.service';
import { BookingRulesService, GetBookingRuleDto } from 'src/app/generated-sources/accounting';
import { CustomListItem } from 'src/app/shared/components/custom-dropdown/custom-dropdown.component';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { AddEditBankingTransactionsBookingRulesOverlayComponent } from '../add-edit-banking-transactions-booking-rules-overlay/add-edit-banking-transactions-booking-rules-overlay.component';

@Component({
    selector: 'app-banking-transactions-booking-rule-detail',
    templateUrl: './banking-transactions-booking-rule-detail.component.html',
    styleUrls: ['./banking-transactions-booking-rule-detail.component.scss'],
})
export class BankingTransactionsBookingRuleDetailComponent implements OnInit, OnDestroy {
    public breadcrumbs: BreadcrumbItem[] = [];
    public ledgerId = '';
    public statusList: CustomListItem[] = [];
    public bookingRule?: GetBookingRuleDto;
    public isLoading = false;
    public refreshBookingRule$ = new BehaviorSubject<void>(undefined);
    private unsubscribe$ = new Subject<void>();

    private bookingRuleId$ = this.route.url.pipe(
        map(() => {
            return this.route.snapshot.paramMap.get('bookingRuleId') || '';
        }),
        distinctUntilChanged()
    );

    private ledgerId$ = this.ledgerCustomService.getLedgerId$().pipe(
        filter(Boolean),
        tap((ledgerId) => (this.ledgerId = ledgerId))
    );

    public bookingRule$ = combineLatest([this.ledgerId$, this.bookingRuleId$, this.refreshBookingRule$]).pipe(
        switchMap(([ledgerId, bookingRuleId]) => {
            return this.bookingRulesService.findOne(ledgerId, bookingRuleId);
        }),
        tap((bookingRule) => {
            this.bookingRule = bookingRule;
            this.updateBreadCrumbs();
            this.updateStatusList();
        })
    );

    public constructor(
        private breadcrumbService: BreadCrumbService,
        private route: ActivatedRoute,
        private bookingRulesService: BookingRulesService,
        private ledgerCustomService: LedgerCustomService,
        private toastService: ToastService,
        private overlayService: OverlayService,
        private router: Router
    ) {}

    public ngOnInit(): void {
        this.bookingRule$.subscribe();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public updateBreadCrumbs(): void {
        const breadcrumb: BreadcrumbItem[] = [
            {
                content: 'Umsätze',
                route: [`/accounting/ledger/${this.ledgerId}/bank-transactions`],
            },
            {
                content: 'Buchungsregel Details',
                route: [`/accounting/ledger/${this.ledgerId}/bank-transactions/booking-rules/${this.bookingRule?.id}`],
                current: true,
            },
        ];
        this.breadcrumbService.resetBreadCrumbs();
        this.breadcrumbService.updateBreadCrumbs(breadcrumb);
        this.breadcrumbs = this.breadcrumbService.getCurrentBreadCrumbs();
    }

    public updateStatusList(): void {
        this.statusList = [
            {
                icon: '/assets/icons/24_closed.svg',
                color: 'green',
                value: GetBookingRuleDto.StatusEnum.Activated,
                selected: this.bookingRule?.status === GetBookingRuleDto.StatusEnum.Activated,
                content: 'Aktiviert',
            },
            {
                icon: '/assets/icons/24_not-available.svg',
                color: 'gray',
                value: GetBookingRuleDto.StatusEnum.Deactivated,
                selected: this.bookingRule?.status === GetBookingRuleDto.StatusEnum.Deactivated,
                content: 'Deaktiviert',
            },
        ];
    }

    public updateStatus(status: CustomListItem): void {
        this.updateBookingRuleStatus(status.value as GetBookingRuleDto.StatusEnum);
    }

    public updateBookingRuleStatus(newStatus: GetBookingRuleDto.StatusEnum): void {
        if (!this.bookingRule) {
            return;
        }

        this.isLoading = true;

        this.bookingRulesService.updateBookingRuleStatus(this.ledgerId, this.bookingRule?.id, newStatus).subscribe({
            next: () => {
                this.refreshBookingRule$.next();
                this.isLoading = false;
                if (newStatus === GetBookingRuleDto.StatusEnum.Rejected) {
                    this.router.navigate(['/accounting', 'ledger', this.ledgerId, 'bank-transactions']);
                }
            },
            error: (error) => {
                this.isLoading = false;

                if (error) {
                    this.toastService.showError(error.error['message']);
                }
            },
        });
    }

    public openEditBookingRuleOverlay(): void {
        const ref = this.overlayService.open(AddEditBankingTransactionsBookingRulesOverlayComponent, {
            data: { bookingRule: this.bookingRule },
        });

        ref.saveEmitter$.subscribe(() => {
            this.refreshBookingRule$.next();
        });

        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
    }

    public getBookingDefinition(): string {
        if (this.bookingRule?.guidedBookingDefinitionId === 1 || this.bookingRule?.guidedBookingDefinitionId === 1004) {
            return 'Geldeingang – Einnahme';
        }

        if (this.bookingRule?.guidedBookingDefinitionId === 2 || this.bookingRule?.guidedBookingDefinitionId === 1005) {
            return 'Geldausgang – Ausgabe';
        }

        return '';
    }
}
