<div
    [class]="
        'tile tw-flex tw-flex-col tw-items-center tw-justify-center tw-p-6 tw-border-solid tw-border-[1px] tw-border-scalaraGray-05 tw-w-full tw-relative ' +
        minHeight
    "
    [ngClass]="{ 'tw-border-scalaraGreen-01 tw-border-2 tile--shadow': selected }"
>
    <div *ngIf="selected" class="tw-bg-scalaraGreen-01 tw-w-6 tw-h-6 tw-absolute tw-top-0 tw-right-0 tw-rounded-bl-lg">
        <div class="tw-fill-scalaraWhite tw-w-6 tw-h-6">
            <svg-icon src="/assets/icons/24_checkbox-check.svg"></svg-icon>
        </div>
    </div>
    <app-icon-bubble
        [size]="72"
        [color]="selected ? iconColor : iconColorNotSelectedItem"
        [image]="iconSrc"
    ></app-icon-bubble>

    <div class="tw-s-body-14-22-bold tw-mt-4 tw-text-center">
        {{ label }}
    </div>
</div>
