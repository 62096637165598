import { Location } from '@angular/common';
import { Component, EventEmitter, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Temporal } from '@js-temporal/polyfill';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbItem } from 'carbon-components-angular';
import {
    BehaviorSubject,
    ObservedValueOf,
    Subject,
    catchError,
    combineLatest,
    filter,
    firstValueFrom,
    map,
    shareReplay,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { BreadCrumbService } from 'src/app/core/services/breadcrumb.service';
import { MediaQueriesService } from 'src/app/core/services/media-queries.service.ts.service';
import { ToastService } from 'src/app/core/services/toast.service';
import {
    asString,
    formatDateWithoutHhMmSs,
    formatYYYYMMDD_to_DDMMYYYY,
    getNameFromPerson,
    getServiceProviderType,
    phoneNumberTypeFromEnum,
} from 'src/app/core/utils/common';
import { formatDateDDMMYYYY } from 'src/app/core/utils/dateUtils';
import { PersonLocalService } from 'src/app/features/property/services/person-local.service';
import { EditProfileFormComponent } from 'src/app/features/user-management/components/edit-profile-form/edit-profile-form.component';
import {
    AdviserRelationDto,
    BankAccount,
    OwnerRelationDto,
    PersonsControllerFindAllRelations200ResponseInner,
    PhoneNumber,
    Property,
    ResidentRelationDto,
    ServiceProviderRelationDto,
    TenantRelationDto,
    UserDto,
    UsersService,
} from 'src/app/generated-sources/base';
import { PersonsService } from 'src/app/generated-sources/base/api/persons.service';
import { Person } from 'src/app/generated-sources/base/model/person';
import { countries } from 'src/app/shared/country-data';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { CellTemplate } from 'src/app/shared/table/enums/cell-template';
import { HeaderItem } from 'src/app/shared/table/interfaces/header-item';
import { TableItem } from 'src/app/shared/table/interfaces/table-item';
import { TableModel } from 'src/app/shared/table/interfaces/table-model';
import { environment } from 'src/environments/environment';
import { AddBankAccountOverlayComponent } from '../add-bank-account-overlay/add-bank-account-overlay.component';
import { DeleteBankAccountOverlayComponent } from '../delete-bank-account-overlay/delete-bank-account-overlay.component';
import { AddUserAvatarOverlayComponent } from './add-user-avatar-overlay/add-user-avatar-overlay.component';

type RelationDTO = PersonsControllerFindAllRelations200ResponseInner;

@Component({
    selector: 'app-contact-detail-view',
    templateUrl: './contact-detail-view.component.html',
    styleUrls: ['./contact-detail-view.component.scss'],
})
export class ContactDetailViewComponent implements OnInit, OnDestroy {
    public formatDateDDMMYYYY = formatDateDDMMYYYY;
    private unsubscribe$ = new Subject<void>();
    private triggerUserService = new Subject<void>();

    public year = Temporal.Now.plainDateISO().year;
    public person?: Person;
    public user?: UserDto;
    public isLoading = true;
    public isLoggedUserPropertyManager = false;
    public personId = '';
    public breadcrumbs: BreadcrumbItem[] = [];
    public personname = '';
    public isOwnProfile = false;
    public tableModelServiceProvider: TableModel = { data: [], header: [] };
    public tableModelOwner: TableModel = { data: [], header: [] };
    public tableModelTenant: TableModel = { data: [], header: [] };
    public tableModelResident: TableModel = { data: [], header: [] };
    public mergedTablesData?: {
        type: 'owner' | 'serviceProvider' | 'tenant' | 'resident';
        relation: OwnerRelationDto | ServiceProviderRelationDto | TenantRelationDto | ResidentRelationDto;
        data: TableItem[];
        header: (string | HeaderItem)[];
    }[];
    public rolesCollapsed = {
        tenant: false,
        serviceProvider: false,
        owner: false,
        resident: false,
    };
    public services: string[] = [];

    public personId$ = this.route.paramMap.pipe(
        map((paramMap) => {
            const personId = paramMap.get('personId');
            return personId ?? null;
        }),
        filter(Boolean)
    );
    public person$ = new BehaviorSubject<Person | null>(null);
    public personSelf$ = this.personLocalService.getPerson$();
    public isOwnProfile$ = combineLatest([this.personSelf$, this.personId$]).pipe(
        map(([personSelf, personId]) => personSelf.id === personId)
    );
    public refreshBankAccounts$ = new BehaviorSubject<undefined>(undefined);
    public bankAccounts$ = combineLatest([this.personId$, this.refreshBankAccounts$]).pipe(
        switchMap(([personId]) => {
            return this.personService.findAllBankAccounts(personId);
        })
    );
    public currentLayout$ = this.mediaQueries.getCurrentLayoutMode();

    public allowToAddRemoveBankAccounts$ = combineLatest([this.person$, this.personSelf$, this.isOwnProfile$]).pipe(
        map(([person, personSelf, isOwnProfile]) => {
            // always show for own profile
            if (isOwnProfile) {
                return true;
            }
            //show for verwalter if person is not registered
            if (personSelf.personRoles.includes('IS_PROPERTY_MANAGER') && !person?.hasRegisteredUser) {
                return true;
            }
            return false;
        })
    );

    public breadcrumbs$ = combineLatest([this.person$, this.personSelf$, this.isOwnProfile$]).pipe(
        map(([person, personSelf, isOwnProfile]) => {
            this.breadcrumbService.resetBreadCrumbs();

            if (!person) {
                return;
            }
            const name = isOwnProfile ? 'Mein Profil' : getNameFromPerson(person);

            const breadcrumbs: BreadcrumbItem[] = [
                {
                    content: name,
                    route: [`/contacts/${person?.id}`],
                },
            ];
            isOwnProfile
                ? null
                : breadcrumbs.unshift({
                      content: this.translateService.instant('NAVIGATION.CONTACTS'),
                      route: [`/contacts`],
                  });

            this.breadcrumbService.updateBreadCrumbs(breadcrumbs);
            return this.breadcrumbService.getCurrentBreadCrumbs();
        })
    );

    public vm$ = combineLatest([
        this.personId$,
        this.bankAccounts$,
        this.currentLayout$,
        this.allowToAddRemoveBankAccounts$,
        this.breadcrumbs$,
    ]).pipe(
        map(([personId, bankAccounts, currentLayout, allowToAddRemoveBankAccounts, breadcrumbs]) => {
            return {
                personId,
                bankAccounts,
                currentLayout,
                allowToAddRemoveBankAccounts,
                breadcrumbs,
            };
        }),
        shareReplay({ bufferSize: 1, refCount: true }),
        takeUntil(this.unsubscribe$)
    );
    public vm: ObservedValueOf<typeof this.vm$> | null = null;

    @Output() public overlayIsClosed = new EventEmitter<void>();

    public readonly environment = environment;

    public constructor(
        private overlayService: OverlayService,
        private usersService: UsersService,
        private route: ActivatedRoute,
        public router: Router,
        public translateService: TranslateService,
        public authService: AuthService,
        public toastService: ToastService,
        private location: Location,
        private personService: PersonsService,
        private personLocalService: PersonLocalService,
        public mediaQueries: MediaQueriesService,
        public breadcrumbService: BreadCrumbService
    ) {}

    @ViewChild('label', { static: true })
    public label?: TemplateRef<any>;

    public ngOnInit(): void {
        this.loadUser();

        this.initTableHeader();

        this.isLoading = true;

        this.vm$.subscribe((vm) => {
            this.vm = vm;
        });

        this.route.paramMap
            .pipe(
                tap((paramMap) => {
                    const personId = paramMap.get('personId');
                    if (personId && personId !== this.personId) {
                        this.personId = personId;
                        this.loadPerson();
                    }
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }

    public filterRelation(relations: RelationDTO[], relationType: string): RelationDTO[] {
        return relations.filter((relation) => relation.relationType === relationType);
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    private async loadPerson(): Promise<void> {
        this.isLoading = true;
        const personSelf = await firstValueFrom(this.personLocalService.getPerson$());

        const isOwnProfile = this.personId === personSelf.id;
        this.isOwnProfile = isOwnProfile;

        this.personService
            .findOne(this.personId)
            .pipe(
                tap((person) => {
                    this.person = person;
                    this.person$.next(person);
                    this.isLoggedUserPropertyManager = Object.values(person.permissionRoles).includes(
                        Person.PermissionRolesEnum.PropertyManager
                    );
                    this.initBreadcrumbs();
                    this.personname = this.person ? getNameFromPerson(this.person) : '';
                }),
                switchMap(() => this.personService.findAllRelations(this.personId)),
                catchError(() => []),
                tap(() => (this.isLoading = false)),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((relations) => {
                const serviceProviders = this.filterRelation(
                    relations,
                    'IS_SERVICE_PROVIDER'
                ) as ServiceProviderRelationDto[];
                const owners = this.filterRelation(relations, 'IS_OWNER') as OwnerRelationDto[];
                const adviders = this.filterRelation(relations, 'IS_ADVISER') as AdviserRelationDto[];
                const tenants = this.filterRelation(relations, 'IS_TENANT') as TenantRelationDto[];
                const residents = this.filterRelation(relations, 'IS_RESIDENT') as ResidentRelationDto[];

                this.services = [];

                // create service providers table
                this.tableModelServiceProvider.data = serviceProviders.map((relation) => {
                    this.services.push(
                        relation.serviceProvided
                            ? getServiceProviderType(this.translateService, relation.serviceProvided)
                            : ''
                    );
                    return this.createRow(relation, true);
                });

                // create owners table
                this.tableModelOwner.data = owners.map((relation) => {
                    const isAdviser = adviders.find(
                        (adviser) =>
                            adviser.relationType === 'IS_ADVISER' &&
                            adviser.property?.id === relation.ownership.property?.id
                    )
                        ? true
                        : false;
                    return this.createRow(relation, false, isAdviser);
                });

                // create tenants table
                this.tableModelTenant.data = tenants.map((relation) => {
                    return this.createRowTenant(relation);
                });

                // create resident table
                this.tableModelResident.data = residents.map((relation) => {
                    return this.createRow(relation, false, false);
                });

                this.mergedTablesData = [
                    ...this.tableModelOwner.data.map((item, index) => ({
                        type: 'owner' as const,
                        relation: owners[index],
                        data: item,
                        header: this.tableModelOwner.header,
                    })),
                    ...this.tableModelServiceProvider.data.map((item, index) => ({
                        type: 'serviceProvider' as const,
                        relation: serviceProviders[index],
                        data: item,
                        header: this.tableModelServiceProvider.header,
                    })),
                    ...this.tableModelTenant.data.map((item, index) => ({
                        type: 'tenant' as const,
                        relation: tenants[index],
                        data: item,
                        header: this.tableModelServiceProvider.header,
                    })),
                    ...this.tableModelResident.data.map((item, index) => ({
                        type: 'resident' as const,
                        relation: residents[index],
                        data: item,
                        header: this.tableModelResident.header,
                    })),
                ];
            });
    }

    public async loadUser(): Promise<void> {
        this.isLoading = true;

        this.usersService.findAll().subscribe((user) => {
            this.user = user;
            this.isLoading = false;
        });
    }

    public returnphoneNumberTypeFromEnum(arg0: PhoneNumber): string {
        return `${arg0.phoneNumber} (${phoneNumberTypeFromEnum(arg0.type)})`;
    }

    public openOverlay(): void {
        const data = {
            componentParent: 'app-contact',
            extraData: { personId: this.personId },
        };

        const ref = this.overlayService.open(EditProfileFormComponent, { data });
        ref.saveEmitter$.subscribe(() => {
            this.loadPerson();
        });
        ref.cancelEmitter$.subscribe(() => {
            ref.close();
        });
    }

    public getPersonFullAddress(person: Person): string | undefined {
        if (person?.address?.streetName && !person?.address?.streetNumber) {
            return person?.address?.streetName;
        }
        if (!person?.address?.streetName && person?.address?.streetNumber) {
            return person?.address?.streetNumber;
        }
        if (person?.address?.streetName && person?.address?.streetNumber) {
            return `${person?.address?.streetName} ${person?.address?.streetNumber}`;
        }
        return undefined;
    }

    public getCountryNameFromCode(code: string): string {
        if (!code) {
            return '';
        }
        return countries.filter((country) => country.code === code)[0].name;
    }

    private initBreadcrumbs(): void {
        if (this.location.path().split('/')[1] === 'my-profile') {
            this.breadcrumbs = [
                {
                    content: 'Mein Profil',
                    route: [`/my-profile/${this.personId}`],
                },
            ];
        } else {
            this.breadcrumbs = [
                {
                    content: 'Kontakte',
                    route: ['contacts'],
                },
                {
                    content: this.person ? getNameFromPerson(this.person) : '',
                    route: [`/contacts/${this.personId}`],
                    current: true,
                },
            ];
        }
    }

    private initTableHeader(): void {
        this.tableModelServiceProvider.header = [
            'ENTITIES.PROPERTY.LABEL_ENTITY',
            'ENTITIES.SERVICE_PROVIDER.SERVICE_TYPE',
            'ENTITIES.RELATION_WITH_TIMECONSTRAINT.LABEL_FROM_TO',
            '',
        ];
        this.tableModelOwner.header = [
            'ENTITIES.PROPERTY.LABEL_ENTITY',
            'ENTITIES.OWNERSHIP.LABEL_ENTITY',
            'ENTITIES.RELATION_WITH_TIMECONSTRAINT.LABEL_FROM_TO',
            '',
        ];
        this.tableModelTenant.header = [
            'ENTITIES.PROPERTY.LABEL_ENTITY',
            'ENTITIES.OCCUPATION.LABEL_ENTITY',
            'ENTITIES.RELATION_WITH_TIMECONSTRAINT.LABEL_FROM_TO',
            '',
        ];
        this.tableModelResident.header = [
            'ENTITIES.PROPERTY.LABEL_ENTITY',
            'ENTITIES.OWNERSHIP.LABEL_ENTITY',
            'ENTITIES.RELATION_WITH_TIMECONSTRAINT.LABEL_FROM_TO',
            '',
        ];
    }

    public getCurrentRelationshipLabel(data: RelationDTO): string {
        const currentDate = new Date();
        const startDateToCalculateLabel = new Date(data.from!);
        startDateToCalculateLabel.setHours(0);
        startDateToCalculateLabel.setMinutes(0);
        const endDateToCalculateLabel = data.to ? new Date(data.to) : null;

        if (
            (!endDateToCalculateLabel && startDateToCalculateLabel <= currentDate) ||
            (endDateToCalculateLabel &&
                endDateToCalculateLabel >= currentDate &&
                startDateToCalculateLabel <= currentDate)
        ) {
            let relationType = 'Dienstleister';
            if (data.relationType === 'IS_RESIDENT') {
                relationType = 'Bewohner';
            }
            if (data.relationType === 'IS_OWNER') {
                relationType = 'Eigentümer';
            }

            if (data.relationType === 'IS_TENANT') {
                relationType = 'Mieter';
            }

            return `Aktueller ${relationType}`;
        }
        return '';
    }

    public createRowTenant(data: TenantRelationDto): TableItem[] {
        const currentRelationshipLabel = this.getCurrentRelationshipLabel(data);
        const propertyName = data.occupation.property?.name;

        const link = `/properties/${data.occupation.property?.id}/occupations/${data?.occupation?.id}/${
            data.occupation.occupationNumber ? 'commit' : 'draft'
        }`;
        return [
            {
                data: {
                    label: propertyName ?? 'Keine Immobilie gefunden',
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: data.occupation.occupationNumber,
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: `${formatDateWithoutHhMmSs(data.occupation.from)} – ${
                        data.occupation.to ? formatDateWithoutHhMmSs(data.occupation.to) : 'unbefristet'
                    }`,
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: '',
                    extraData: {
                        currentRelationshipLabel,
                    },
                    link,
                },
                template: this.label,
            },
        ];
    }

    public createRow(data: RelationDTO, isServiceProvider: boolean, isAdviser?: boolean): TableItem[] {
        const currentRelationshipLabel = this.getCurrentRelationshipLabel(data);

        let labelOwnershipOrService;
        let link;
        let propertyName;

        if (isServiceProvider) {
            const serviceProvider = data as ServiceProviderRelationDto;
            labelOwnershipOrService = getServiceProviderType(this.translateService, serviceProvider.serviceProvided);
            link = `/properties/${serviceProvider.property?.id}`;
            propertyName = serviceProvider.property?.name;
        } else {
            const owner = data as OwnerRelationDto;
            labelOwnershipOrService = owner.ownership?.name;
            if (owner.ownership.property?.propertyType === Property.PropertyTypeEnum.Mv) {
                link = `/properties/${owner.ownership.property?.id}`;
            } else {
                link = `/properties/${owner.ownership.property?.id}/ownerships/${owner.ownership?.id}`;
            }
            propertyName = owner.ownership.property?.name;
        }

        return [
            {
                data: {
                    label: propertyName ?? 'Keine Immobilie gefunden',
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: labelOwnershipOrService,
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: `${formatDateWithoutHhMmSs(data.from)} – ${formatDateWithoutHhMmSs(data.to)}`,
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: '',
                    link,
                    extraData: {
                        isAdviser,
                        currentRelationshipLabel,
                    },
                },
                template: this.label,
            },
        ];
    }

    public toggleRolesCollapsed(key: keyof typeof this.rolesCollapsed): void {
        this.rolesCollapsed[key] = !this.rolesCollapsed[key];
    }

    public inviteContact(): void {
        this.personService
            .invite(this.personId)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.toastService.showSuccess(
                    this.translateService.instant('PAGES.CONTACT.DETAIL_VIEW.INVITE_TOAST_SUCCESS')
                );

                this.loadPerson();
            });
    }
    public getServiceProviderType = getServiceProviderType;
    public asString = asString;

    public showBankAccounts(): boolean {
        //  always show heading
        return true;
    }

    public async openAddBankAccountOverlay(): Promise<void> {
        const personId = await firstValueFrom(this.personId$);
        const data = {
            personId,
        };

        const ref = this.overlayService.open(AddBankAccountOverlayComponent, { data });
        ref.saveEmitter$.subscribe(() => {
            this.refreshBankAccounts$.next(undefined);
        });
        ref.cancelEmitter$.subscribe(() => {
            ref.close();
        });
    }

    public async onBankAccountDelete(bankAccount: BankAccount): Promise<void> {
        const personId = await firstValueFrom(this.personId$);
        const data = { bankAccount, personId };
        const ref = this.overlayService.open(DeleteBankAccountOverlayComponent, { data });
        ref.saveEmitter$.subscribe(() => {
            this.refreshBankAccounts$.next(undefined);
        });
        ref.cancelEmitter$.subscribe(() => {
            ref.close();
        });
    }

    public async openAddAvatarOverlay(): Promise<void> {
        const data = { person: this.person };
        const ref = this.overlayService.open(AddUserAvatarOverlayComponent, { data });
        ref.saveEmitter$.subscribe(() => {
            this.loadPerson();
        });
        ref.cancelEmitter$.subscribe(() => {
            ref.close();
        });
    }

    public deleteUserAvatar(): void {
        if (!this.person) {
            return;
        }
        this.personService.update(this.person.id, { imgFull: '' }).subscribe({
            next: () => {
                this.toastService.showSuccess('Erfolgreich gelöscht');
                this.isLoading = false;
                this.loadPerson();
            },
            error: (error) => {
                if (error) {
                    this.toastService.showError(error.error['message']);
                }
                this.isLoading = false;
            },
        });
    }

    public formatDate(date?: string): string | undefined {
        return date ? formatYYYYMMDD_to_DDMMYYYY(date) : undefined;
    }
}
