import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { TooltipKey } from 'src/app/features/account-settings/services/custom-tooltip.service';
import { AddAdviserOverlayComponent } from 'src/app/features/relations/add-adviser-overlay/add-adviser-overlay.component';
import { AdvisersComponent } from 'src/app/features/relations/advisers/advisers.component';
import { Person, Property } from 'src/app/generated-sources/base';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { OverlayRef } from 'src/app/shared/overlay/utils/overlay-ref';
import { TableModel } from 'src/app/shared/table/interfaces/table-model';
import { PersonLocalService } from '../../services/person-local.service';

@Component({
    selector: 'app-advisers-overview',
    templateUrl: './advisers-overview.component.html',
    styleUrls: ['./advisers-overview.component.scss'],
})
export class AdvisersOverviewComponent implements OnInit {
    public isLoading = false;
    public isManagerOfThisProperty = false;
    private unsubscribe$ = new Subject<void>();
    private ownershipId = '';
    private propertyId = '';
    public tableModel: TableModel = { data: [], header: [] };

    @ViewChild(AdvisersComponent) public advisersComponent?: AdvisersComponent;

    public constructor(
        private route: ActivatedRoute,
        private overlayService: OverlayService,
        private personLocalService: PersonLocalService
    ) {}

    public ngOnInit(): void {
        this.propertyId = String(this.route.parent?.snapshot.paramMap.get('id'));
        this.ownershipId = String(this.route.snapshot.paramMap.get('ownershipId'));

        this.personLocalService
            .getRolesOfProperty()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((roles: Property.PermissionRolesEnum[] | null) => {
                this.isManagerOfThisProperty = roles
                    ? Object.values(roles).includes(Person.PermissionRolesEnum.PropertyManager)
                    : false;
            });
    }

    public openAddOwnerOverlay(): void {
        const data = {
            componentParent: 'app-advisers-overview',
            extraData: { propertyId: this.propertyId, ownershipId: this.ownershipId },
        };

        const ref = this.overlayService.open(AddAdviserOverlayComponent, { data });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

        this.saveEmittersUpdate(ref);
    }

    private saveEmittersUpdate(ref: OverlayRef): void {
        ref.saveEmitter$.subscribe(() => {
            this.advisersComponent?.loadAdvisers();
        });
        ref.cancelEmitter$.subscribe(() => {
            ref.close();
        });
    }

    public TooltipKey = TooltipKey;
}
