import { Injectable } from '@angular/core';
import { PersonLocalService } from 'src/app/features/property/services/person-local.service';
import { UserDto } from 'src/app/generated-sources/base';
import { environment } from 'src/environments/environment';
import userflow from 'userflow.js';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root',
})
export class UserflowCustomService {
    public constructor(public personLocalService: PersonLocalService, public authService: AuthService) {
        this.resetOnLogout();
    }

    public subscription?: any;

    public init(): void {
        userflow.init(environment.userflowToken);
        if (environment.demoEnvironment) {
            userflow.identifyAnonymous();
            return;
        }

        const sub = this.authService.getScalaraUser().subscribe((scalaraUser) => {
            if (scalaraUser) {
                this.identify(scalaraUser);
            }
        });

        this.subscription = sub;
    }

    private identify(user: UserDto): void {
        userflow.identify(user.id, {
            name: user.contactName,
            email: user.loginEmail,
        });
    }

    private resetOnLogout(): void {
        this.authService
            .isLoggedIn()
            .asObservable()
            .subscribe((isLoggedIn) => {
                if (isLoggedIn === false) {
                    userflow.reset();
                    this.subscription?.unsubscribe();
                }
            });
    }
}
