import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export enum TooltipKey {
    tooltipsInProperties = 'tooltipsInProperties',
    tooltipsInAccounting = 'tooltipsInAccounting',
    tooltipsInListGenerator = 'tooltipsInListGenerator',
    tooltipsInCommunication = 'tooltipsInCommunication',
    tooltipsInContacts = 'tooltipsInContacts',
    tooltipsInMyAccount = 'tooltipsInMyAccount',
}

type TooltipState = Record<TooltipKey, boolean>;

@Injectable({
    providedIn: 'root',
})
export class CustomTooltipService {
    private readonly DEFAULT_STATE: TooltipState = {
        tooltipsInProperties: true,
        tooltipsInAccounting: true,
        tooltipsInListGenerator: true,
        tooltipsInCommunication: true,
        tooltipsInContacts: true,
        tooltipsInMyAccount: true,
    };

    public getTooltipState$(): Observable<TooltipState> {
        return this.state$.asObservable();
    }

    public constructor() {
        this.setStateFromLocalStorage();
    }

    private setStateFromLocalStorage(): void {
        const keys = Object.values(TooltipKey);
        const localStorageState: TooltipState = {} as any;
        keys.forEach((key) => {
            const value = localStorage.getItem(key);
            // if value for key is not present in localStorage, set it to default value
            localStorageState[key] = value !== null ? JSON.parse(value) : this.DEFAULT_STATE[key];
        });
        this.state$.next(localStorageState);
    }

    public updateTooltipState(tooltipKey: TooltipKey, value: boolean): void {
        this.state$.next({
            ...this.state$.getValue(),
            [tooltipKey]: value,
        });
        localStorage.setItem(tooltipKey, JSON.stringify(value));
    }

    private state$ = new BehaviorSubject<TooltipState>(this.DEFAULT_STATE);
}
