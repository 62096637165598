<form [formGroup]="personTypeAndNameForm">
    <fieldset class="bx--fieldset">
        <legend class="bx--label">
            <span class="s-head-14-22-semibold">
                {{ 'USER_MANAGEMENT.EDIT_PROFILE_FORM.LABEL_TYPE' | translate }}
            </span>
        </legend>

        <ibm-radio-group
            aria-label="radiogroup"
            orientation="vertical"
            formControlName="type"
            (change)="onRadioChange($event)"
        >
            <ibm-radio [value]="personTypeDeclared.COMPANY" [checked]="true">
                {{ 'ENTITIES.PERSON.LABEL_PERSON_TYPE_COMPANY' | translate }}
            </ibm-radio>
            <ibm-radio [value]="personTypeDeclared.NAT_PERSON">
                {{ 'ENTITIES.PERSON.LABEL_PERSON_TYPE_NAT_PERSON' | translate }}
            </ibm-radio>
        </ibm-radio-group>
    </fieldset>

    <div *ngIf="personTypeAndNameForm.get('type')!.value === personTypeDeclared.NAT_PERSON" class="d-flex gap-2r">
        <ibm-label [invalid]="isInvalid('firstName')" invalidText="{{ 'ERROR.REQUIRED' | translate }}">
            {{ 'ENTITIES.PERSON.LABEL_FIRSTNAME' | translate }}*
            <input ibmText type="firstName" name="firstName" id="firstName" formControlName="firstName" />
        </ibm-label>

        <ibm-label [invalid]="isInvalid('lastName')" invalidText="{{ 'ERROR.REQUIRED' | translate }}">
            {{ 'ENTITIES.PERSON.LABEL_LASTNAME' | translate }}*
            <input ibmText type="lastName" name="lastName" id="lastName" formControlName="lastName" />
        </ibm-label>
    </div>

    <div *ngIf="personTypeAndNameForm.get('type')!.value === personTypeDeclared.COMPANY">
        <ibm-label [invalid]="isInvalid('companyName')" invalidText="{{ 'ERROR.REQUIRED' | translate }}">
            {{ 'ENTITIES.PERSON.LABEL_COMPANY_NAME' | translate }}*
            <input ibmText type="companyName" name="companyName" id="companyName" formControlName="companyName" />
        </ibm-label>
    </div>

    <div class="cols-70-30 lg-d-grid">
        <div class="lg-pr-1-5r">
            <app-scalara-input-address-autocomplete
                formControlName="streetName"
                label="{{ 'ENTITIES.ADDRESS.LABEL_STREET_NAME' | translate }} *"
                [invalid]="isInvalid('streetName')"
                invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                (autocompletePlaceSelected)="onAutocompletePlaceSelected($event)"
            ></app-scalara-input-address-autocomplete>
        </div>
        <ibm-label [invalid]="isInvalid('streetNumber')" invalidText="{{ 'ERROR.REQUIRED' | translate }}">
            {{ 'ENTITIES.ADDRESS.LABEL_STREET_NUMBER' | translate }}*
            <input ibmText type="streetNumber" name="streetNumber" id="streetNumber" formControlName="streetNumber" />
        </ibm-label>
    </div>

    <div class="cols-30-70 lg-d-grid">
        <div class="lg-pr-1-5r">
            <ibm-label [invalid]="isInvalid('zipCode')" invalidText="{{ 'ERROR.REQUIRED' | translate }}">
                {{ 'ENTITIES.ADDRESS.LABEL_ZIP_CODE' | translate }}*
                <input ibmText type="zipCode" name="zipCode" id="zipCode" formControlName="zipCode" />
            </ibm-label>
        </div>

        <ibm-label [invalid]="isInvalid('area')" invalidText="{{ 'ERROR.REQUIRED' | translate }}">
            {{ 'ENTITIES.ADDRESS.LABEL_AREA' | translate }}*
            <input ibmText type="area" name="area" id="area" formControlName="area" />
        </ibm-label>
    </div>
</form>
