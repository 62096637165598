/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, ObservedValueOf, Subject, combineLatest, map, startWith, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { controlInFormGroupIsInvalid } from 'src/app/core/utils/formValidationHelpers';
import { LedgerCustomService } from 'src/app/features/accounting/components/services/ledger-custom.service';
import { PropertyCustomService } from 'src/app/features/property/services/property-custom.service';
import { OverlayChildComponent } from '../../overlay/components/overlay-child/overlay-child.component';

//'PROPERTY' | 'OWNERSHIP' key is used fot mapping in de.json,
//  we use here ownership for both ownerhip and unit
export type RemoveEntityOverlayComponentConfig = {
    data: {
        entityName: string;
        entityType: 'PROPERTY' | 'OWNERSHIP' | 'ACCOUNTING';
        deleteEntityCallback: () => any;
    };
};

@Component({
    selector: 'app-remove-entity-overlay',
    templateUrl: './remove-entity-overlay.component.html',
    styleUrls: ['./remove-entity-overlay.component.scss'],
})
export class RemoveEntityOverlayComponent extends OverlayChildComponent implements OnInit, OnDestroy {
    public constructor(
        private toastService: ToastService,
        private translateService: TranslateService,
        private propertyCustomService: PropertyCustomService,
        private ledgerCustomService: LedgerCustomService
    ) {
        super();
    }

    public form = new UntypedFormGroup({
        entityName: new UntypedFormControl(null, [Validators.required, this.createAsyncValidator()]),
    });

    public unsubscribe$ = new Subject<void>();

    // populate from config
    private entityType$ = new BehaviorSubject<RemoveEntityOverlayComponentConfig['data']['entityType']>('PROPERTY');
    private entityName$ = new BehaviorSubject<string>('');
    private deleteEntityCallback$ = new BehaviorSubject<() => any>(() => {});

    // calculated
    public formIsValid$ = this.form.statusChanges.pipe(
        map((status) => status === 'VALID'),
        // startWith is important here,
        // cause statusChanges do not emit value until user interact with form
        startWith(false)
    );
    private entityTypeTranslated$ = this.entityType$.pipe(
        map((entityType) => this.translateService.instant(`ENTITIES.${entityType.toUpperCase()}.LABEL_ENTITY`))
    );
    private heading$ = this.entityTypeTranslated$.pipe(map((entityType) => `${entityType} löschen?`));
    private title$ = this.entityTypeTranslated$.pipe(
        map((entityType) => `Möchten Sie diese ${entityType} wirklich löschen?`)
    );
    private successToastMessage$ = combineLatest([this.entityName$, this.entityTypeTranslated$]).pipe(
        map(([name, type]) => `${type} ${name} erfolgreich gelöscht`)
    );
    private subtitle$ = combineLatest([this.entityName$, this.entityTypeTranslated$]).pipe(
        map(
            ([
                name,
                type,
            ]) => `Achtung! Der Vorgang kann nicht rückgängig gemacht werden. Bitte schreiben Sie den Namen von
        ${type}: '${name}'`
        )
    );
    private isLoading$ = new BehaviorSubject<boolean>(false);
    private vm$ = combineLatest([
        this.entityName$,
        this.entityTypeTranslated$,
        this.deleteEntityCallback$,
        this.heading$,
        this.title$,
        this.subtitle$,
        this.formIsValid$,
        this.isLoading$,
        this.successToastMessage$,
        this.entityType$,
    ]).pipe(
        map(
            ([
                entityName,
                entityTypeTranslated,
                deleteEntityCallback,
                heading,
                title,
                subtitle,
                formIsValid,
                isLoading,
                successToastMessage,
                entityType,
            ]) => ({
                entityName,
                entityTypeTranslated,
                deleteEntityCallback,
                heading,
                title,
                subtitle,
                formIsValid,
                isLoading,
                successToastMessage,
                entityType,
            })
        ),
        takeUntil(this.unsubscribe$)
    );
    public vm: ObservedValueOf<typeof this.vm$> | null = null;

    public async submit(): Promise<void> {
        try {
            this.isLoading$.next(true);
            await this.vm?.deleteEntityCallback();
            this.toastService.showSuccess(this.vm?.successToastMessage);
        } catch (e) {
            this.toastService.showError('Fehler beim Löschen der Einheit');
        } finally {
            this.vm?.entityType === 'PROPERTY'
                ? this.propertyCustomService.refresh()
                : this.ledgerCustomService.refresh();
            this.isLoading$.next(false);
        }
        this.saveEmitter$.next();
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public ngOnInit(): void {
        this.vm$.subscribe((vm) => {
            this.vm = vm;
        });

        this.populateObsFromConfig();
    }

    public populateObsFromConfig(): void {
        const configTyped = { ...this.config } as RemoveEntityOverlayComponentConfig;

        if (configTyped?.data?.entityName) {
            this.entityName$.next(configTyped.data.entityName);
        }

        if (configTyped?.data?.entityType) {
            this.entityType$.next(configTyped.data.entityType);
        }

        if (configTyped?.data?.deleteEntityCallback) {
            this.deleteEntityCallback$.next(configTyped.data.deleteEntityCallback);
        }
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private createAsyncValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!this.vm?.entityName) {
                return null;
            }
            const entityName = this.vm?.entityName;
            return control.value === entityName ? null : { entityNameNotMatch: true };
        };
    }

    public controlInFormGroupIsInvalid = controlInFormGroupIsInvalid;
}
