import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ListItem } from 'carbon-components-angular';
import { firstValueFrom } from 'rxjs';
import { ResolveType } from 'src/app/core/models/ts-utils';
import { ToastService } from 'src/app/core/services/toast.service';
import { getPersonDescriptionCombobox } from 'src/app/core/utils/common';
import { BankTransactionDto } from 'src/app/generated-sources/accounting';
import {
    CreateBankAccountDto,
    IbanPersonRecommendationDto,
    Person,
    PersonsService,
    RecommendedPersonsDto,
} from 'src/app/generated-sources/base';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';

type BankTransactionDetails = ResolveType<
    Pick<BankTransactionDto, 'counterpartName' | 'counterpartIban'> &
        Partial<Pick<BankTransactionDto, 'counterpartBankName' | 'counterpartBic'>>
>;

//  ibanPersonRecommendation comes from BE when user creates booking with connected bank-transaction
//  personRecommendation comes from overlay of creating a payment, in this case we do not show combobox, but show person description
export type AddBankAccountRecommendationOverlayComponentConfig = {
    ibanPersonRecommendation?: IbanPersonRecommendationDto;
    personRecommendation?: Person;
    bankTransaction: BankTransactionDetails;
    isAttachedToBookings: boolean;
};

@Component({
    selector: 'app-add-bank-account-recommendation-overlay',
    templateUrl: './add-bank-account-recommendation-overlay.component.html',
    styleUrls: ['./add-bank-account-recommendation-overlay.component.scss'],
})
export class AddBankAccountRecommendationOverlayComponent extends OverlayChildComponent implements OnInit {
    public constructor(private personsService: PersonsService, private toastService: ToastService) {
        super();
    }

    public readonly transactionDetailToShow: { i18Key: string; valueKey: keyof BankTransactionDetails }[] = [
        { i18Key: 'ACCOUNTING.BANK_TRANSACTION_DETAILS.COUNTERPART_NAME', valueKey: 'counterpartName' },
        { i18Key: 'ACCOUNTING.BANK_TRANSACTION_DETAILS.IBAN', valueKey: 'counterpartIban' },
        { i18Key: 'ACCOUNTING.BANK_TRANSACTION_DETAILS.BANK_NAME', valueKey: 'counterpartBankName' },
        { i18Key: 'ACCOUNTING.BANK_TRANSACTION_DETAILS.COUNTERPART_BIC', valueKey: 'counterpartBic' },
    ];

    public typedConfig: AddBankAccountRecommendationOverlayComponentConfig = {} as any;
    public isLoading = false;

    public form = new UntypedFormGroup({ personListItem: new UntypedFormControl(null) });
    public comboBoxItems: ListItem[] = [];
    public personRecommendationDescription?: string;

    public recommendationToComboBoxItem(ibanPersonRecommendationDto: IbanPersonRecommendationDto): ListItem[] {
        const firstRecommendedPerson: RecommendedPersonsDto | undefined =
            ibanPersonRecommendationDto.recommendations?.find((recommendation) => recommendation.recommended);
        const listItems: ListItem[] =
            ibanPersonRecommendationDto.recommendations?.map((recommendation: RecommendedPersonsDto) => {
                return {
                    content: getPersonDescriptionCombobox(recommendation.person),
                    selected: recommendation.person.id === firstRecommendedPerson?.person?.id,
                    person: recommendation.person,
                };
            }) ?? [];

        return listItems;
    }

    public initFormValue(): void {
        const selectedItem = this.comboBoxItems.find((item) => item.selected);
        this.form.setValue({ personListItem: selectedItem ?? '' });
        this.form.updateValueAndValidity();
    }

    public resetFormValue(): void {
        this.form.setValue({ personListItem: '' });
        this.form.updateValueAndValidity();
    }

    public ngOnInit(): void {
        this.typedConfig = this.config?.data as AddBankAccountRecommendationOverlayComponentConfig;
        if (this.typedConfig?.ibanPersonRecommendation) {
            this.comboBoxItems = this.recommendationToComboBoxItem(this.typedConfig?.ibanPersonRecommendation);
            this.form.controls['personListItem'].setValidators([Validators.required]);
            this.form.controls['personListItem'].setErrors(null);
            this.form.controls['personListItem'].updateValueAndValidity();
        }

        if (this.typedConfig.personRecommendation) {
            this.personRecommendationDescription = getPersonDescriptionCombobox(this.typedConfig.personRecommendation);
        }

        this.initFormValue();
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public async submit(): Promise<void> {
        if (!this.form.valid) {
            return;
        }
        try {
            const person = this.typedConfig?.ibanPersonRecommendation
                ? this.form.value.personListItem.person
                : this.typedConfig.personRecommendation;
            const BT = this.typedConfig.bankTransaction;
            const createBankAccountDto: CreateBankAccountDto = {
                iban: BT.counterpartIban,
                accountHolder: BT.counterpartName,
                bic: BT.counterpartBic,
                bankName: BT.counterpartBankName,
                isAttachedToBookings: this.typedConfig.isAttachedToBookings,
            };
            this.isLoading = true;
            await firstValueFrom(this.personsService.addBankAccount(person.id, createBankAccountDto));
            this.toastService.showSuccess('Erfolgreich verknüpft');
            this.saveEmitter$.next();
        } catch (e) {
            this.toastService.showError('Es ist ein Fehler aufgetreten');
        } finally {
            this.isLoading = false;
        }
    }
}
