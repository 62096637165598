import { DatePipe, formatNumber } from '@angular/common';
import { Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject, combineLatest, map } from 'rxjs';
import { formatNumberWVDD } from 'src/app/core/utils/common';
import { EurocentPipe } from 'src/app/shared/pipes/eurocent.pipe';
import { TableSearchService } from 'src/app/shared/table/services/table-search.service';

export type Column<TKey> = {
    key: TKey;
    label: string;
    columnCellClass?: string;
};
export type RowData<TKey> = { cells: CellData<TKey>[]; rowClass?: string };
export type CellData<TKey> =
    | {
          key: TKey;
          type: 'value';
          value: unknown;
          rowCellClass?: string;
          wrapWithPre?: boolean;
          pipeToApply?: 'eurocent' | 'date' | 'number' | 'number-WVDD';
          link?: string;
      }
    | {
          key: TKey;
          type: 'component';
          component: any;
          componentData?: any;
          rowCellClass?: string;
      };

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[simpleTableRow]',
    templateUrl: './simple-table-row.component.html',
    styleUrls: ['./simple-table-row.component.scss'],
})
export class SimpleTableRowComponent implements OnChanges {
    public constructor(
        public euroCent: EurocentPipe,
        public date: DatePipe,
        private tableSearchService: TableSearchService
    ) {}
    @HostBinding('class') public hostClass?: string = '';

    @Input() public columns: Column<unknown>[] = [];
    @Input() public rowData?: RowData<unknown> = {} as RowData<unknown>;
    @Input() public variant: 'header' | 'body' = 'body';
    @Input() public tableSearchId = 'default';

    private tableSearchId$ = new BehaviorSubject<string | null>(null);
    private tableSearch$ = this.tableSearchService.getCurrentInputSearch$();
    public show$ = combineLatest([this.tableSearchId$, this.tableSearch$]).pipe(
        map(([tableSearchId, tableSearch]) => {
            if (!tableSearchId) {
                return true;
            }

            if (tableSearch && tableSearch.searchInputId.includes(tableSearchId)) {
                const searchValue = tableSearch.searchInput.toLowerCase().trim();

                const values = this.rowData?.cells.map((c) => {
                    const cell = this.getRowCell(c.key);
                    if (!cell) {
                        console.warn('NO CELL FOUND WTF');
                        return '';
                    }
                    return cell.type === 'value' ? cell.value : '';
                });

                return values?.some((i) => String(i).toLowerCase().trim().includes(searchValue));
            }

            return true;
        })
    );

    public getRowCell(columnKey: any): CellData<any> | null {
        if (this.rowData?.cells && this.rowData?.cells?.length > 0 && this.columns && this.variant) {
            const originRowCell = this.rowData?.cells.find((cell) => cell.key === columnKey) ?? null;
            const rowCell = originRowCell ? Object.assign({}, originRowCell) : null;

            try {
                if (rowCell && rowCell.type === 'value') {
                    if (rowCell?.pipeToApply === 'eurocent' && typeof rowCell.value === 'number') {
                        rowCell.value = this.euroCent.transform(rowCell.value);
                    }
                    if (rowCell?.pipeToApply === 'date' && typeof rowCell.value === 'string') {
                        rowCell.value = this.date.transform(rowCell.value, 'dd.MM.yyyy');
                    }
                    if (rowCell?.pipeToApply === 'number' && typeof rowCell.value === 'number') {
                        rowCell.value = formatNumber(rowCell.value, 'de-DE', '1.0-3');
                    }
                    if (rowCell?.pipeToApply === 'number-WVDD' && typeof rowCell.value === 'number') {
                        rowCell.value = formatNumberWVDD(rowCell.value);
                    }
                }
            } catch (e) {
                console.log('error in row pipe: ', e);
            }

            return rowCell;
        }
        return null;
    }

    public isValueCell<TKey>(cellData: CellData<TKey>): cellData is { key: TKey; type: 'value'; value: unknown } {
        return cellData.type === 'value';
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['tableSearchId']) {
            this.tableSearchId$.next(this.tableSearchId);
        }
    }
}
