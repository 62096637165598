import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
    BehaviorSubject,
    Subject,
    catchError,
    combineLatest,
    filter,
    of,
    switchMap,
    takeUntil,
    takeWhile,
    tap,
} from 'rxjs';
import { StatusService } from 'src/app/core/services/status.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { formatDateYYYYMMDDWithoutHours, sortByDateCallback } from 'src/app/core/utils/common';
import {
    BookingRecommendationDto,
    BookingRecommendationInfoDto,
    BookingRecommendationVerdictDto,
    BookingRecommendationsService,
    LedgerStatusInfoDto,
    SaveVerdictsDto,
} from 'src/app/generated-sources/accounting';
import { DateTimeFormatPipe } from 'src/app/shared/pipes/date-time-format.pipe';
import { AccountingFilterCustomService } from '../../../../services/accounting-filter-custom.service';
import { BusinessYearDates } from '../../../../services/economic-plan-custom.service';
import { LedgerCustomService } from '../../../../services/ledger-custom.service';

@Component({
    selector: 'app-banking-transactions-recommended-bookings',
    templateUrl: './banking-transactions-recommended-bookings.component.html',
    styleUrls: ['./banking-transactions-recommended-bookings.component.scss'],
})
export class BankingTransactionsRecommendedBookingsComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();
    public refresh$ = new BehaviorSubject(null);

    public dateLatestUpdate: string[] = ['', ''];
    public today = new Date().toDateString();

    public recommendedBookings: BookingRecommendationDto[] = [];
    public savedBookings: string[] = [];
    public isBookingOpen: boolean[] = [false];
    public isBookingContentOpen: boolean[][] = [[false]];
    public dates?: BusinessYearDates;
    public form: UntypedFormGroup = new UntypedFormGroup({});
    public hasBookingSubGroups = false;
    public ledgerId = '';
    public isLoading = false;
    public isSelectAllChecked = false;

    @Output() public recommendedBookingsEmitter = new EventEmitter<number>();

    public constructor(
        private formBuilder: UntypedFormBuilder,
        private toastService: ToastService,
        private statusService: StatusService,
        private datePipe: DateTimeFormatPipe,
        private translateService: TranslateService,
        private ledgerCustomService: LedgerCustomService,
        private bookingRecommendationsService: BookingRecommendationsService,
        private accountingFilterCustomService: AccountingFilterCustomService
    ) {}

    public ledgerId$ = this.ledgerCustomService.getLedgerId$().pipe(
        filter(Boolean),
        tap((ledgerId) => (this.ledgerId = ledgerId))
    );

    public datePickerSelectedDates$ = this.accountingFilterCustomService.getDatePickerSelectedDates$();

    public recommendedBookings$ = combineLatest([this.ledgerId$, this.datePickerSelectedDates$, this.refresh$]).pipe(
        tap(() => (this.isLoading = true)),
        switchMap(([ledgerId, dates]) => {
            this.ledgerId = ledgerId;
            this.dates = dates;
            return this.bookingRecommendationsService.findAll(
                this.ledgerId,
                formatDateYYYYMMDDWithoutHours(dates.startDate),
                formatDateYYYYMMDDWithoutHours(dates.endDate)
            );
        }),
        tap((recommendedBookings: BookingRecommendationDto[]) => {
            this.initListRecommendedBookings(recommendedBookings);
        }),
        catchError(() => {
            this.toastService.showError(this.translateService.instant('COMPONENTS.TOAST.TOAST_ERROR'));
            return of(null);
        })
    );

    public status$ = this.statusService.getStatusObservable().pipe(
        tap((statusInfo: LedgerStatusInfoDto) => {
            this.dateLatestUpdate = this.datePipe
                .transform(statusInfo.bookingRecommendationInfo.lastRecommendationGenerationDate)
                .split(' – ');
            if (
                statusInfo.bookingRecommendationInfo.recommendationGenerationStatus ===
                BookingRecommendationInfoDto.RecommendationGenerationStatusEnum.Finished
            ) {
                this.refreshBookingRecommendations();
            }
        }),
        takeUntil(this.unsubscribe$),
        takeWhile(
            (statusInfo: LedgerStatusInfoDto) =>
                statusInfo.bookingRecommendationInfo.recommendationGenerationStatus ===
                    BookingRecommendationInfoDto.RecommendationGenerationStatusEnum.Pending ||
                statusInfo.bookingRecommendationInfo.recommendationGenerationStatus ===
                    BookingRecommendationInfoDto.RecommendationGenerationStatusEnum.Running
        )
    );

    public ngOnInit(): void {
        this.isLoading = true;
        this.isSelectAllChecked = true;
        this.recommendedBookings$
            .pipe(
                tap(() => {
                    this.isLoading = false;
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
        this.status$.subscribe();
        this.statusService.getLastStatus();
        this.refreshBookingRecommendations();
    }

    public refreshBookingRecommendations(): void {
        this.refresh$.next(null);
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public initForm(): void {
        const form = this.formBuilder.group({});
        for (let i = 0; i < this.recommendedBookings.length; i++) {
            if (!this.savedBookings.length) {
                form?.addControl(i.toString(), new UntypedFormControl(this.isSelectAllChecked));
            } else {
                const isBookingSaved = !!this.savedBookings.find(
                    (bookingId) => bookingId === this.recommendedBookings[i].id
                );
                form?.addControl(i.toString(), new UntypedFormControl(isBookingSaved));
            }
        }
        this.form = form;
    }

    public updateSearch(): void {
        this.bookingRecommendationsService
            .requestRecommendationGeneration(this.ledgerId)
            .pipe(
                /* We want to save the selected bookings, so the time the user invested on checking them
                 is not wasted.*/
                tap(() => this.saveBookingsSelection()),
                switchMap(() => this.status$),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }

    private saveBookingsSelection(): void {
        this.savedBookings = [];
        this.isSelectAllChecked = false;
        for (let i = 0; i < this.recommendedBookings.length; i++) {
            if ((this.form?.get(i.toString()) as UntypedFormControl).value) {
                this.savedBookings.push(this.recommendedBookings[i].id);
            }
        }
    }

    public isBookingSelected(index: number): boolean {
        return (this.form?.get(index.toString()) as UntypedFormControl)?.value;
    }

    public changeBookingDisplay(index: number): void {
        this.isBookingOpen[index] = !this.isBookingOpen[index];
    }

    public changeBookingContentDisplay(indexBooking: number, indexContent: number): void {
        this.isBookingContentOpen[indexBooking][indexContent] = !this.isBookingContentOpen[indexBooking][indexContent];
    }

    public updateBookingSelection($event: any): void {
        for (let i = 0; i < this.recommendedBookings.length; i++) {
            (this.form?.get(i.toString()) as UntypedFormControl).patchValue($event.checked);
        }
    }

    public submitRecommendedBookings(): void {
        const selectedBookings: BookingRecommendationVerdictDto[] = [];
        const unselectedBookings: BookingRecommendationDto[] = [];
        for (let i = 0; i < this.recommendedBookings.length; i++) {
            if ((this.form?.get(i.toString()) as UntypedFormControl).value) {
                const selectedBooking: BookingRecommendationVerdictDto = {
                    id: this.recommendedBookings[i].id,
                    verdict: BookingRecommendationVerdictDto.VerdictEnum.Accepted,
                };
                selectedBookings.push(selectedBooking);
            } else {
                unselectedBookings.push(this.recommendedBookings[i]);
            }
        }

        const verdicts: SaveVerdictsDto = {
            verdicts: selectedBookings,
        };

        this.isSelectAllChecked = false;
        this.initListRecommendedBookings(unselectedBookings);

        this.bookingRecommendationsService.saveVerdict(this.ledgerId, verdicts).subscribe({
            next: () => {
                this.toastService.showSuccess(
                    this.translateService.instant('PAGES.BANK_TRANSACTIONS.RECOMMENDED_BOOKINGS_SUCCESS_TOAST')
                );
                this.isSelectAllChecked = false;
                this.initListRecommendedBookings(unselectedBookings);
            },
            error: (error) => {
                if (error) {
                    this.toastService.showError(this.translateService.instant('COMPONENTS.TOAST.TOAST_ERROR'));
                }
            },
        });
    }

    private initListRecommendedBookings(recommendedBookings: BookingRecommendationDto[]): void {
        this.recommendedBookingsEmitter.emit(recommendedBookings.length);
        this.recommendedBookings = recommendedBookings.sort((a, b) =>
            sortByDateCallback(a.bankTransactionInfo.bankBookingDate, b.bankTransactionInfo.bankBookingDate, 'desc')
        );
        this.isBookingOpen = Array(recommendedBookings.length).fill(false);
        recommendedBookings.forEach((recommendedBooking: BookingRecommendationDto, index) => {
            this.isBookingContentOpen[index] = Array(recommendedBooking.recommendationGroup.subgroups.length).fill(
                false
            );
        });

        // Avoiding null errors for the first form initialization
        if (this.isSelectAllChecked) {
            this.initForm();
        } else {
            // after the first form initialization, we set the form after retrieving the new data
            setTimeout(() => {
                this.initForm();
            }, 0);
        }
    }
}
