import { Component, Input, OnChanges, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject, catchError, of, switchMap, takeUntil, tap } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { formatDateWithoutHhMmSs, getNameFromPerson, getOccupationTypeLabel } from 'src/app/core/utils/common';
import {
    FullOesDto,
    OperationsExpenseStatementsService,
    SimpleOccupationOesDto,
} from 'src/app/generated-sources/accounting';
import { Occupation } from 'src/app/generated-sources/base';
import { FiletoView } from 'src/app/shared/components/file-viewer/file-viewer.component';
import { EurocentPipe } from 'src/app/shared/pipes/eurocent.pipe';
import { TableComponent } from 'src/app/shared/table/components/table/table.component';
import { CellTemplate } from 'src/app/shared/table/enums/cell-template';
import { HeaderTemplate } from 'src/app/shared/table/enums/header-template';
import { TableItem } from 'src/app/shared/table/interfaces/table-item';
import { TableModel } from 'src/app/shared/table/interfaces/table-model';

@Component({
    selector: 'app-oes-statements-tab',
    templateUrl: './oes-statements-tab.component.html',
    styleUrls: ['./oes-statements-tab.component.scss'],
})
export class OesStatementsTabComponent implements OnChanges, OnDestroy {
    @Input() public ledgerId = '';
    @Input() public oesId = '';
    @Input() public oesDate = '';
    @Input() public oesActivationDate = '';
    @Input() public oesLengthInDays = 0;
    @Input() public oesStatus?: FullOesDto.StatusEnum;

    public unsubscribe$ = new Subject<void>();

    @Input() public set triggerRefresh(val: number) {
        this.refresh$.next(val);
    }
    public refresh$ = new BehaviorSubject(1);

    public tableModel: TableModel = { data: [], header: [] };
    public oes?: FullOesDto;
    public undistributedAmounts = 0;
    public isLoading = false;
    public showModal = false;
    public fileToView?: FiletoView;

    public currentOccupationId$ = new BehaviorSubject<string | undefined>(undefined);

    @ViewChild('occupationDetails', { static: true })
    public occupationDetails?: TemplateRef<any>;

    @ViewChild(TableComponent)
    public tableComponent!: TableComponent;

    public constructor(
        private operationsExpenseStatementsService: OperationsExpenseStatementsService,
        private eurocentPipe: EurocentPipe,
        private translateService: TranslateService,
        private toastService: ToastService
    ) {}

    public ngOnChanges(): void {
        this.initTableHeader();
        this.isLoading = true;
        this.operationsExpenseStatementsService
            .findOne(this.ledgerId, this.oesId)
            .pipe(
                switchMap((oes) => {
                    this.oes = oes;
                    return this.operationsExpenseStatementsService.findAllOccupationOes(this.ledgerId, this.oesId);
                }),
                tap((occupations) => {
                    let sumOwnShare = 0;
                    this.tableModel.data = occupations.map((item) => {
                        sumOwnShare = sumOwnShare + item.ownShare;
                        return this.createRow(item);
                    });
                    this.undistributedAmounts = this.oes?.totalCostAmount ? this.oes?.totalCostAmount - sumOwnShare : 0;
                    this.isLoading = false;
                })
            )
            .subscribe();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    private initTableHeader(): void {
        this.tableModel.header = [
            'PAGES.OPERATIONS_EXPENSE_STATEMENTS.DETAIL.LABEL_OCCUPATION',
            {
                data: { key: 'PAGES.OPERATIONS_EXPENSE_STATEMENTS.DETAIL.LABEL_OCCUPATION_PERIOD', params: {} },
                width: '200px',
            },
            {
                data: { key: 'PAGES.OPERATIONS_EXPENSE_STATEMENTS.DETAIL.LABEL_TOTAL_COST', params: {} },
                template: HeaderTemplate.RightAligned,
            },
            {
                data: { key: 'PAGES.OPERATIONS_EXPENSE_STATEMENTS.DETAIL.LABEL_ADVANCE', params: {} },
                template: HeaderTemplate.RightAligned,
            },
            {
                data: { key: 'PAGES.OPERATIONS_EXPENSE_STATEMENTS.DETAIL.LABEL_RESULT', params: {} },
                template: HeaderTemplate.RightAligned,
            },
            '',
            {
                data: { key: 'PAGES.OPERATIONS_EXPENSE_STATEMENTS.DETAIL.LABEL_PDF', params: {} },
                template: HeaderTemplate.RightAligned,
            },
        ];
    }

    private createRow(item: SimpleOccupationOesDto): TableItem[] {
        let resultLabel = '';

        if (item.balanceAmount > 0) {
            resultLabel = 'Erstattung';
        }
        if (item.balanceAmount < 0) {
            resultLabel = 'Nachzahlung';
        }

        return [
            {
                data: {
                    label: '',
                    extraData: { occupation: item.occupation }, //ATTENTION: don't remove this because it is being used to pass the occupation id to when clicking on the row
                },
                template: this.occupationDetails,
            },
            {
                data: {
                    label: `${formatDateWithoutHhMmSs(item.occupation.from)} – ${
                        item.occupation.to ? formatDateWithoutHhMmSs(item.occupation.to) : 'unbefristet'
                    }`,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: this.eurocentPipe.transform(item.ownShare || 0),
                    rightAligned: true,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: this.eurocentPipe.transform(item.totalAdvancementAmount || 0),
                    rightAligned: true,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: item.balanceAmount || 0,
                    rightAligned: true,
                },
                template: CellTemplate.EuroCent,
            },
            {
                data: {
                    label: resultLabel,
                    rightAligned: true,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: '',
                    extraData: { ...item },
                },
                template: CellTemplate.filesActions,
            },
        ];
    }

    public getOccupationDescription(occupation: Occupation): {
        occupationDetail: string;
        occupationNumberAndTenants: string;
    } {
        const tenants = occupation.tenants
            .map((tenant) => (tenant && (tenant.firstName || tenant.companyName) ? getNameFromPerson(tenant) : ''))
            .join(', ');

        return {
            occupationDetail: getOccupationTypeLabel(
                occupation?.occupationType,
                occupation.rentDetails?.rentType,
                false
            ),
            occupationNumberAndTenants:
                occupation.occupationNumber + (occupation.occupationType === 'VACANCY' ? '' : ', ' + tenants),
        };
    }

    public closeModal(): void {
        this.showModal = false;
    }

    public handleFile(event: any): void {
        if (!event.extraData?.occupation.id) {
            return;
        }
        this.isLoading = true;
        this.operationsExpenseStatementsService
            .getOccupationOesPdf(this.ledgerId, this.oesId, event.extraData?.occupation.id)
            .pipe(
                tap((data: any) => {
                    const fileName = data.fileName.replace('_pdf', '.pdf');
                    if (event.isDownload) {
                        const link = document.createElement('a');
                        link.href = data.url;
                        link.click();
                    } else {
                        this.fileToView = { fileName: fileName, file: data.url };
                        this.showModal = true;
                    }
                }),
                catchError(() => {
                    this.toastService.showError(this.translateService.instant('COMPONENTS.TOAST.TOAST_ERROR'));
                    return of(null);
                }),
                tap(() => (this.isLoading = false))
            )
            .subscribe();
    }

    public openOesOccupation(event: any): void {
        const clickedRow = event !== undefined ? this.tableComponent.model.row(event) : undefined;

        this.currentOccupationId$.next(clickedRow ? String(clickedRow[0].data.extraData.occupation?.id) : undefined);
    }

    public downloadAllPdfs(): void {
        this.isLoading = true;
        this.operationsExpenseStatementsService
            .getAllOwnershipSettlementPdf(this.ledgerId, this.oesId)
            .pipe(
                catchError(() => {
                    this.toastService.showError(this.translateService.instant('COMPONENTS.TOAST.TOAST_ERROR'));
                    return of(null);
                }),
                takeUntil(this.unsubscribe$),
                tap(() => (this.isLoading = false))
            )
            .subscribe((data: any) => {
                const link = document.createElement('a');
                link.href = data.url;
                link.click();
            });
    }
}
