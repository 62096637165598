import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { AuthService } from 'src/app/core/services/auth.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { formControl, formControlHasError } from 'src/app/core/utils/common';
import { passwordConfirmInvalid, passwordInvalid } from 'src/app/core/utils/formValidationHelpers';
import { PasswordValidators } from 'src/app/shared/validators/password-validators';
import { AuthSuccessFlowTypeEnum } from '../../types/authSuccess';

@Component({
    selector: 'app-reset-password-set-password',
    templateUrl: './reset-password-set-password.component.html',
    styleUrls: ['./reset-password-set-password.component.scss'],
})
export class ResetPasswordSetPasswordComponent implements OnInit {
    public email: null | string = null;
    public static MIN_PASSWORD_LENGTH = 8;
    public constructor(
        public formBuilder: UntypedFormBuilder,
        private authService: AuthService,
        private toaster: ToastService,
        private translate: TranslateService,
        private route: ActivatedRoute,
        private router: Router
    ) {}
    public isLoading = false;
    public form: UntypedFormGroup = this.formBuilder.group(
        {
            code: ['', [Validators.required]],
        },
        { validators: [PasswordValidators.passwordMatch] }
    );

    public ngOnInit(): void {
        this.route.queryParamMap.subscribe((params) => {
            this.email = params.get('email');
        });
    }

    public onSubmit(): void {
        this.isLoading = true;
        const userData = { Username: this.email ?? '', Pool: this.authService.getUserPool() };
        const cognitoUser = new CognitoUser(userData);
        const code = this.form.get('code')?.value;
        const password = this.form.get('password')?.value;
        cognitoUser.confirmPassword(code, password, {
            onSuccess: () => {
                this.router.navigate(['/auth-success'], {
                    queryParams: { flowType: AuthSuccessFlowTypeEnum.resetPassword },
                });
                this.isLoading = false;
            },
            onFailure: () => {
                this.setCodeError(true);
                this.isLoading = false;
            },
        });
    }

    public setCodeError(val: boolean): void {
        const errors = this.form.get('code')?.errors;
        this.form.controls['code'].setErrors({ ...errors, incorrect: val });
    }

    public get passwordInvalid(): boolean {
        return passwordInvalid({ form: this.form });
    }

    public get passwordConfirmInvalid(): boolean {
        return passwordConfirmInvalid({ form: this.form });
    }

    public get codeControlIsInvalid(): boolean {
        const control = formControl(this.form, 'code');
        const isInvalid = formControlHasError(control, 'required') || formControlHasError(control, 'incorrect');
        return isInvalid;
    }
}
