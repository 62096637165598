/**
 * SCALARA Accounting Module
 * The SCALARA Accounting API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Account } from './account';
import { LedgerToOwnership } from './ledgerToOwnership';
import { BankAccount } from './bankAccount';
import { BankAccountLedger } from './bankAccountLedger';


export interface Ledger { 
    personId: string;
    propertyId: string;
    accounts: Array<Account>;
    ledgerToOwnerships: Array<LedgerToOwnership>;
    openingBalanceBookingId?: string;
    startAccountingPeriod: string;
    type: Ledger.TypeEnum;
    rentReceivableAutoGenerationStatus: Ledger.RentReceivableAutoGenerationStatusEnum;
    directDebitBankAccount: BankAccount;
    directDebitBankAccountId: object;
    creditorIdentifier: string;
    creditorDescription: string;
    bankAccountLedgers: Array<BankAccountLedger>;
    ledgerNumber: number;
    openItemClosingStrategy: Ledger.OpenItemClosingStrategyEnum;
    id: string;
    createdAt: string;
    updatedAt: string;
}
export namespace Ledger {
    export type TypeEnum = 'WEG' | 'SEV' | 'MV';
    export const TypeEnum = {
        Weg: 'WEG' as TypeEnum,
        Sev: 'SEV' as TypeEnum,
        Mv: 'MV' as TypeEnum
    };
    export type RentReceivableAutoGenerationStatusEnum = 'NOT_ACTIVATED' | 'IN_PROGRESS' | 'ACTIVATED';
    export const RentReceivableAutoGenerationStatusEnum = {
        NotActivated: 'NOT_ACTIVATED' as RentReceivableAutoGenerationStatusEnum,
        InProgress: 'IN_PROGRESS' as RentReceivableAutoGenerationStatusEnum,
        Activated: 'ACTIVATED' as RentReceivableAutoGenerationStatusEnum
    };
    export type OpenItemClosingStrategyEnum = 'DUE_DATE_FIRST' | 'KOSTENTRAGUNG_FIRST' | 'ERHALTUNGSRUECKLAGE_FIRST' | 'QUOTELUNG';
    export const OpenItemClosingStrategyEnum = {
        DueDateFirst: 'DUE_DATE_FIRST' as OpenItemClosingStrategyEnum,
        KostentragungFirst: 'KOSTENTRAGUNG_FIRST' as OpenItemClosingStrategyEnum,
        ErhaltungsruecklageFirst: 'ERHALTUNGSRUECKLAGE_FIRST' as OpenItemClosingStrategyEnum,
        Quotelung: 'QUOTELUNG' as OpenItemClosingStrategyEnum
    };
}


