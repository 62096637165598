import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { getNameFromPerson } from 'src/app/core/utils/common';
import { Person, TicketsService } from 'src/app/generated-sources/base';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';

@Component({
    selector: 'app-delete-ticket-service-provider',
    templateUrl: './delete-ticket-service-provider.component.html',
    styleUrls: ['./delete-ticket-service-provider.component.scss'],
})
export class DeleteTicketServiceProviderComponent extends OverlayChildComponent implements OnInit {
    private unsubscribe$ = new Subject<void>();
    public personToDelete?: Person;
    public allPerson?: Person[];
    public ticketId = '';
    public constructor(
        private ticketService: TicketsService,
        private toastService: ToastService,
        private translateService: TranslateService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.personToDelete = this.config?.data.personToDelete;
        this.allPerson = this.config?.data.allPerson;
        this.ticketId = this.config?.data.ticketId;
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public onSubmit(): void {
        this.ticketService
            .update(this.ticketId, {
                serviceProviderIds: this.allPerson
                    ?.filter((person) => person.id !== this.personToDelete?.id)
                    .map((person) => person.id),
            })
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: () => {
                    this.toastService.showSuccess(
                        this.translateService.instant('PAGES.TICKET_DETAILS.TOAST_SUCCESS_DELETE_SERVICE_PROVIDER')
                    );
                    this.saveEmitter$.next();
                },
                error: (error) => {
                    if (error) {
                        this.toastService.showError(error.error['message']);
                    }
                },
            });
    }

    public getNameFromPerson(person: Person): string {
        return getNameFromPerson(person);
    }
}
