import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, switchMap } from 'rxjs';
import { TicketDto, TicketsService } from 'src/app/generated-sources/base';

@Injectable({
    providedIn: 'root',
})
export class TicketsCustomService {
    private constructor(private ticketsService: TicketsService) {}

    public getTickets$(ticketStatus?: TicketDto.StatusEnum): Observable<TicketDto[]> {
        let ticketsObservable$ = null as unknown as Observable<TicketDto[]>;

        if (ticketStatus === 'OPEN') {
            ticketsObservable$ = this.openTickets$;
        } else if (ticketStatus === 'CLOSED') {
            ticketsObservable$ = this.closedTickets$;
        } else if (ticketStatus === 'IN_PROGRESS') {
            ticketsObservable$ = this.inProgressTickets$;
        } else {
            ticketsObservable$ = this.tickets$;
        }
        return ticketsObservable$;
    }

    public refreshTickets(): void {
        this.refreshTicketsTrigger$.next(null);
    }

    private readonly refreshTicketsTrigger$ = new BehaviorSubject<null>(null);

    private readonly tickets$ = combineLatest([this.refreshTicketsTrigger$]).pipe(
        switchMap(() => this.ticketsService.findAll())
    );
    private readonly openTickets$ = combineLatest([this.refreshTicketsTrigger$]).pipe(
        switchMap(() => this.ticketsService.findAll('OPEN'))
    );
    private readonly closedTickets$ = combineLatest([this.refreshTicketsTrigger$]).pipe(
        switchMap(() => this.ticketsService.findAll('CLOSED'))
    );
    private readonly inProgressTickets$ = combineLatest([this.refreshTicketsTrigger$]).pipe(
        switchMap(() => this.ticketsService.findAll('IN_PROGRESS'))
    );
}
