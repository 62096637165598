<div class="main-container" [ngClass]="{ 'grid-two-columns': this.rightImgSrc }">
    <div class="left-container">
        <div class="left-container__header">
            <a href="/" class="image-link-wrapper">
                <img src="/assets/icons/logo-black.svg" alt="SCALARA GMBH" height="64px" />
            </a>
            <!-- hide on demo and while registering via invitation -->
            <span class="d-flex h-min-content" *ngIf="!environment.demoEnvironment && !isRegisterPage">
                <ng-container *ngIf="this.routeLink === 'sign-in'">
                    <span>{{ 'COMPONENTS.SIGN_IN_SIGN_UP_CONTAINER.ALREADY_REGISTERED' | translate }}&nbsp;</span>
                    <a [routerLink]="'/' + this.routeLink" class="data-table-link">{{
                        'COMPONENTS.SIGN_IN_SIGN_UP_CONTAINER.TO_LOGIN' | translate
                    }}</a>
                </ng-container>
                <ng-container *ngIf="this.routeLink === 'sign-up'">
                    <span>{{ 'COMPONENTS.SIGN_IN_SIGN_UP_CONTAINER.NOT_REGISTERED' | translate }}&nbsp;</span>
                    <a [routerLink]="'/' + this.routeLink" class="data-table-link">{{
                        'COMPONENTS.SIGN_IN_SIGN_UP_CONTAINER.TO_SIGNUP' | translate
                    }}</a>
                </ng-container>
            </span>
        </div>
        <div
            class="ng-content-wrapper"
            #ngContentWrapper
            [ngStyle]="{ maxWidth: containerWidthStyle }"
            [ngClass]="{ 'ng-content-wrapper--register': isRegisterPage }"
        >
            <ng-content></ng-content>
        </div>
        <app-footer></app-footer>
    </div>
    <div
        class="right-image-container"
        [ngClass]="{ 'right-image-container--demo': environment.demoEnvironment }"
        *ngIf="this.rightImgSrc"
    >
        <div class="img-container"><img [src]="this.rightImgSrc" alt="scalara-login-image" /></div>
    </div>
</div>
