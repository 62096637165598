import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
    BehaviorSubject,
    Subject,
    combineLatest,
    distinctUntilChanged,
    filter,
    map,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs';
import { PropertyCustomService } from 'src/app/features/property/services/property-custom.service';
import { TechnicalEquipmentDto, TechnicalEquipmentService } from 'src/app/generated-sources/base';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { AddEditTechnicalEquipmentOverlayComponent } from '../add-edit-technical-equipment-overlay/add-edit-technical-equipment-overlay.component';
import { TechnicalEquipmentDetailsOverlayComponent } from '../technical-equipment-details-overlay/technical-equipment-details-overlay.component';

@Component({
    selector: 'app-technical-equipment-index',
    templateUrl: './technical-equipment-index.component.html',
    styleUrls: ['./technical-equipment-index.component.scss'],
})
export class TechnicalEquipmentIndexComponent implements OnInit, OnDestroy {
    public unsubscribe$ = new Subject<void>();
    public refreshTechnicalEquipments$ = new BehaviorSubject<void>(undefined);

    public buildingId = '';
    public propertyId = '';

    public buildingId$ = this.route.url.pipe(
        map(() => {
            const buildingId = this.route.snapshot.paramMap.get('buildingId');
            this.buildingId = buildingId ?? '';
            return buildingId;
        }),
        filter(Boolean),
        distinctUntilChanged()
    );

    public propertyId$ = this.propertyCustomService.getPropertyId$().pipe(
        filter(Boolean),
        tap((propertyId) => {
            this.propertyId = propertyId ?? '';
        })
    );

    public technicalEquipmentItems: TechnicalEquipmentDto[] = [];

    public constructor(
        private overlayService: OverlayService,
        private technicalEquipmentService: TechnicalEquipmentService,
        private route: ActivatedRoute,
        private propertyCustomService: PropertyCustomService
    ) {}

    public ngOnInit(): void {
        combineLatest([this.propertyId$, this.buildingId$, this.refreshTechnicalEquipments$])
            .pipe(
                switchMap(([propertyId, buildingId]) =>
                    this.technicalEquipmentService.findAllBuildingTechnicalEquipment(propertyId, buildingId)
                ),
                tap((equipments) => {
                    this.technicalEquipmentItems = equipments;
                })
            )
            .subscribe();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public openAddTechnicalEquipmentOverlay(): void {
        const ref = this.overlayService.open(AddEditTechnicalEquipmentOverlayComponent, {
            data: { buildingId: this.buildingId, propertyId: this.propertyId },
        });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.refreshTechnicalEquipments$.next();
        });
    }

    public openTechnicalEquipmentDetailsOverlay(item: TechnicalEquipmentDto): void {
        const ref = this.overlayService.open(TechnicalEquipmentDetailsOverlayComponent, {
            data: {
                technicalEquipment: item,
                buildingId: this.buildingId,
                propertyId: this.propertyId,
                technicalEquipmentsIdList: this.technicalEquipmentItems.map((item) => item.id),
            },
            overlayType: 'half-page',
        });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.refreshTechnicalEquipments$.next();
            ref.close();
        });

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.refreshTechnicalEquipments$.next();
        });
    }
}
