import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbItem } from 'carbon-components-angular';
import {
    BehaviorSubject,
    Subject,
    combineLatest,
    distinctUntilChanged,
    filter,
    firstValueFrom,
    map,
    of,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs';
import { getNameFromPerson, unitTypeNameFromEnum } from 'src/app/core/utils/common';
import { TooltipKey } from 'src/app/features/account-settings/services/custom-tooltip.service';
import { MVUnit, Occupation, OccupationService, Person, Unit, UnitsService } from 'src/app/generated-sources/base';
import {
    RemoveEntityOverlayComponent,
    RemoveEntityOverlayComponentConfig,
} from 'src/app/shared/components/remove-entity-overlay/remove-entity-overlay.component';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { PropertyCustomService } from '../../../services/property-custom.service';
import { DocumentsFlow } from '../../documents/add-document-overlay/add-document-overlay.component';
import { AddEditMvUnitComponent } from '../add-edit-mv-unit/add-edit-mv-unit.component';

@Component({
    selector: 'app-mv-unit-details',
    templateUrl: './mv-unit-details.component.html',
    styleUrls: ['./mv-unit-details.component.scss'],
})
export class MvUnitDetailsComponent implements OnInit, OnDestroy {
    public unit?: MVUnit;
    public breadcrumbs: BreadcrumbItem[] = [];
    public isLoading = false;
    public propertyId = '';
    public isPropertyManager = false;
    public currentOccupationMVProperty?: Occupation;
    public currentTenants?: { person: Person; unit: Unit }[] = [];

    private unsubscribe$ = new Subject<void>();
    private refreshUnits$ = new BehaviorSubject<void>(undefined);

    public propertyId$ = this.propertyCustomService.getPropertyId$().pipe(
        filter(Boolean),
        tap((propertyId) => (this.propertyId = propertyId))
    );

    public unitId$ = this.route.url.pipe(
        map(() => {
            return this.route.snapshot.paramMap.get('unitId');
        }),
        filter(Boolean),
        distinctUntilChanged()
    );

    private unit$ = combineLatest([this.propertyId$, this.unitId$, this.refreshUnits$]).pipe(
        switchMap(([propertyId, unitId]) => {
            this.isLoading = true;
            return this.unitsService.findOneMVUnit(propertyId, unitId);
        }),
        tap((unit) => {
            this.unit = unit;
            this.isPropertyManager = Object.values(unit.permissionRoles).includes(
                Person.PermissionRolesEnum.PropertyManager
            );
        }),
        switchMap(() => {
            if (!this.unit?.designatedOwnershipId) {
                return of();
            }
            return this.occupationService.findAllOccupationsForExactOwnershipSet(
                this.propertyId,
                this.unit?.designatedOwnershipId
            );
        }),
        tap((occupation) => {
            const uniqueIDs: any = {};

            occupation.reduce((result: any, currentOccupation: Occupation) => {
                currentOccupation.unitsIncluded.forEach((unit: Unit) => {
                    if (!uniqueIDs[unit.id]) {
                        uniqueIDs[unit.id] = true;
                        if (!result[unit.id]) {
                            occupation.map((item) => {
                                const findUnit = item.unitsIncluded.find((item) => item.id === unit.id);
                                if (findUnit) {
                                    const currentDate = new Date().getTime();
                                    const from = new Date(item.from).getTime();
                                    const to = item.to ? new Date(item.to).getTime() : null;

                                    if (
                                        (to && currentDate >= from && currentDate <= to) ||
                                        (!to && currentDate >= from)
                                    ) {
                                        this.currentOccupationMVProperty = item;
                                        const tenants = item.tenants.map((item) => {
                                            return { person: item, unit: unit };
                                        });
                                        this.currentTenants?.push(...tenants);
                                    }
                                }
                            });
                        }
                    }
                });
                return result;
            }, {});
        }),
        takeUntil(this.unsubscribe$)
    );

    public constructor(
        private unitsService: UnitsService,
        private overlayService: OverlayService,
        private router: Router,
        private route: ActivatedRoute,
        private translateService: TranslateService,
        private propertyCustomService: PropertyCustomService,
        private occupationService: OccupationService
    ) {}

    public ngOnInit(): void {
        this.unit$.subscribe(() => {
            this.breadcrumbs = [
                {
                    content: this.translateService.instant('PROPERTY.COMMON.UNITS'),
                    route: [`/properties/${this.propertyId}/ownerships`],
                },
                {
                    content: this.unit?.name ?? '',
                    route: [`/properties/${this.propertyId}/unit/${this.unit?.id}`],
                    current: true,
                },
            ];
            this.isLoading = false;
        });
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public openDeleteEntityOverlay(): void {
        const data: RemoveEntityOverlayComponentConfig = {
            data: {
                entityName: this.unit?.name ?? '',
                entityType: 'OWNERSHIP',
                deleteEntityCallback: async () => {
                    await firstValueFrom(this.unitsService.removeMVUnit(this.propertyId, this.unit?.id || ''));
                },
            },
        };
        const ref = this.overlayService.open(RemoveEntityOverlayComponent, data);
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            ref.close();
        });

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.router.navigate([`/properties/${this.propertyId}/ownerships`], { relativeTo: this.route });
        });
    }

    public openEditComponent(): void {
        const ref = this.overlayService.open(AddEditMvUnitComponent, {
            data: {
                editingMode: true,
                mvUnit: this.unit,
                propertyId: this.propertyId,
            },
        });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            ref.close();
        });

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.refreshUnits$.next());
    }

    public getOccupationTypeLabel(occupationType: Occupation.OccupationTypeEnum, isPrivate?: boolean): string {
        if (occupationType === 'RENTING') {
            return isPrivate ? 'Vermietung, privat' : 'Vermietung, Gewerblich';
        } else {
            return occupationType === 'OWN_OCCUPATION' ? 'Selbstnutzung' : 'Leerstand';
        }
    }

    public updateAdditionalInformation(event: any): void {
        if (!this.propertyId || !this.unit) {
            return;
        }

        this.unitsService
            .updateMVUnit(this.propertyId, this.unit?.id, {
                type: this.unit.type,
                buildingId: this.unit.buildingId,
                name: this.unit.name,
                additionalInformation: event ?? '',
            })
            .subscribe(() => this.refreshUnits$.next());
    }

    public tooltipInfoDocuments: { tooltipKey: TooltipKey; tooltipValue: string } = {
        tooltipKey: TooltipKey.tooltipsInProperties,
        tooltipValue: this.translateService.instant('PAGES.TOOLTIPS.OWNERSHIP_DETAIL.DOCUMENTS_TOOLTIP'),
    };
    public DocumentsFlow = DocumentsFlow;

    public getNameFromPerson(person: Person): string {
        return getNameFromPerson(person);
    }

    public getTypeUnitName(unit: Unit): string {
        return unitTypeNameFromEnum(unit);
    }
}
