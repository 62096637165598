<ibm-loading *ngIf="isInitialLoading" [isActive]="isInitialLoading" [size]="'normal'" [overlay]="isInitialLoading">
</ibm-loading>

<div class="overview-container">
    <div
        class="d-flex flex-direction-column lg-flex-direction-row justify-space-between align-items-start"
        [class.mb-2r]="vm?.currentLayoutMode === 'desktop'"
    >
        <ibm-breadcrumb [items]="breadcrumbs" [noTrailingSlash]="true"> </ibm-breadcrumb>
        <div class="action-buttons mb-1-5r d-none lg-d-flex">
            <app-scalara-button
                *ngIf="havePermission('EDIT')"
                (click)="openAddTicketOverlay()"
                class="mr-1r"
                [variant]="'ghost'"
                svgName="24_edit.svg"
                >{{ 'CONTROLS.BUTTONS.EDIT' | translate }}</app-scalara-button
            >
            <app-scalara-button
                *ngIf="havePermission('DELETE')"
                (click)="openDeleteTicketOverlay()"
                [variant]="'ghost'"
                svgName="24_delete.svg"
                >{{ 'CONTROLS.BUTTONS.REMOVE' | translate }}</app-scalara-button
            >
        </div>
    </div>

    <div class="d-flex flex-direction-column lg-flex-direction-row container">
        <div class="d-flex action-buttons mb-1-5r lg-d-none">
            <app-scalara-button
                *ngIf="havePermission('EDIT')"
                (click)="openAddTicketOverlay()"
                class="mr-1r"
                [variant]="'ghost'"
                svgName="24_edit.svg"
                >{{ 'CONTROLS.BUTTONS.EDIT' | translate }}</app-scalara-button
            >
            <app-scalara-button
                *ngIf="havePermission('DELETE')"
                (click)="openDeleteTicketOverlay()"
                [variant]="'ghost'"
                svgName="24_delete.svg"
                >{{ 'CONTROLS.BUTTONS.REMOVE' | translate }}</app-scalara-button
            >
        </div>

        <div class="mb-1-5r lg-d-none">
            <app-ticket-status-dropdown
                [disabled]="!havePermission('CHANGE_STATE')"
                (refreshTicketPage)="loadTicket()"
                [ticket]="ticket"
            ></app-ticket-status-dropdown>
        </div>

        <div *ngIf="ticket" class="d-flex flex-direction-column">
            <!-- ticket detail  -->
            <div class="ticket-container">
                <div class="d-flex flex-direction-column lg-flex-direction-row justify-space-between mb-2r">
                    <a class="s-gray-01 ticket-owner" [href]="'/contacts/' + ticket.owner.id">
                        <app-headline-with-icon
                            color="green"
                            image="24_person.svg"
                            [text]="getNameFromPerson(ticket.owner)"
                            textType="body"
                            textWeight="regular"
                            borderColor="gray"
                            [userAvatar]="ticket.owner.imgSmall ?? undefined"
                        ></app-headline-with-icon
                    ></a>

                    <span class="s-body-14-22-regular s-gray-03 date-ticket">{{
                        ticket.createdAt || '' | date : 'dd.MM.yyyy – HH:mm'
                    }}</span>
                </div>

                <div class="s-head-28-36-bold mb-1-5r">{{ ticket.title }}</div>

                <p class="s-body-14-22-regular mb-3r pre-wrap mr-1-5r">{{ ticket.description }}</p>

                <ng-container *ngIf="tableModel.data.length > 0">
                    <div class="s-label-12-22-semibold s-gray-03 mb-1r">
                        {{ 'ENTITIES.TICKET.ATTACHMENT' | translate }}
                    </div>
                    <app-table
                        [emptyText]="'PAGES.DOCUMENTS.EMPTY_TABLE'"
                        [header]="tableModel.header"
                        [data]="tableModel.data"
                        (handleAction)="handleActions($event)"
                        [isLoading]="!isInitialLoading && isLoading"
                    >
                    </app-table>
                </ng-container>

                <ng-container *ngIf="commentsTableModel.data.length > 0">
                    <div class="s-label-12-22-semibold s-gray-03 mb-1r">
                        {{ 'ENTITIES.TICKET.ATTACHMENTS_FROM_COMMENTS' | translate }}
                    </div>
                    <app-table
                        [emptyText]="'PAGES.DOCUMENTS.EMPTY_TABLE'"
                        [header]="commentsTableModel.header"
                        [data]="commentsTableModel.data"
                        (handleAction)="handleActions($event)"
                        [isLoading]="!isInitialLoading && isLoading"
                    >
                    </app-table>
                </ng-container>
            </div>
            <!-- end ticket detail  -->

            <!-- comments  || ticket details -->
            <div class="d-none lg-d-block comments-container">
                <app-ticket-comments
                    (refreshTicketPage)="loadTicket()"
                    [commentsOriginalArray]="comments"
                    [ticketId]="ticket.id || ''"
                ></app-ticket-comments>
            </div>

            <div class="lg-d-none mb-3r">
                <app-scalara-accordion headingLabel="Details" [isOpenedByDefault]="false">
                    <div scalara-accordion-content>
                        <app-ticket-detail
                            [img]="propertyImg"
                            [ticket]="ticket"
                            (refresh)="loadTicket()"
                        ></app-ticket-detail>
                    </div>
                </app-scalara-accordion>
            </div>
            <!-- end comments  || ticket details -->
        </div>

        <!-- -->
        <div class="d-flex flex-direction-column" [class.w-100]="vm?.currentLayoutMode === 'desktop'">
            <div class="d-none lg-d-block mb-2-5r">
                <app-ticket-status-dropdown
                    [disabled]="!havePermission('CHANGE_STATE')"
                    (refreshTicketPage)="loadTicket()"
                    [ticket]="ticket"
                ></app-ticket-status-dropdown>
            </div>

            <!-- comments  || ticket details -->
            <div class="d-none lg-d-block">
                <app-ticket-detail [img]="propertyImg" [ticket]="ticket" (refresh)="loadTicket()"></app-ticket-detail>
            </div>

            <div class="lg-d-none">
                <app-ticket-comments
                    (refreshTicketPage)="loadTicket()"
                    [commentsOriginalArray]="comments"
                    [ticketId]="ticket?.id || ''"
                ></app-ticket-comments>
            </div>
            <!-- end comments  || ticket details -->

            <!--  -->
        </div>
    </div>
</div>

<ng-container *ngIf="fileToView && showModal">
    <app-file-viewer [fileToView]="fileToView" (closeViewer)="handleModal()"></app-file-viewer>
</ng-container>
