<div ibmRow [formGroup]="dateRangeForm">
    <div ibmCol class="mb-06">
        <ibm-date-picker
            label="{{ 'ENTITIES.RELATION_WITH_TIMECONSTRAINT.LABEL_FROM' | translate }}*"
            id="start"
            [placeholder]="'tt.mm.jjjj'"
            [size]="'xl'"
            [language]="'de'"
            [dateFormat]="'d.m.Y'"
            [invalid]="!this.dateRangeForm.valid"
            invalidText="Das Startdatum muss vor den Enddatum liegen"
            formControlName="startDate"
        >
        </ibm-date-picker>
    </div>
    <div ibmCol class="mb-06">
        <ibm-date-picker
            label="{{ 'ENTITIES.RELATION_WITH_TIMECONSTRAINT.LABEL_TO' | translate }}"
            id="end"
            [placeholder]="'tt.mm.jjjj'"
            [size]="'xl'"
            [language]="'de'"
            [dateFormat]="'d.m.Y'"
            [invalid]="!this.dateRangeForm.valid"
            invalidText="Das Startdatum muss vor den Enddatum liegen"
            formControlName="endDate"
        >
        </ibm-date-picker>
    </div>
</div>
