/**
 * Verteilt eine Summe gemäß vorgegebener Verteilung und rundet die Ergebnisse.
 * Dabei wird sichergestellt, dass die Summe der Anteile gleich der Summe ist,
 * Dafür werden einzelne anteile ggfs. in die falsche Richtung gerundet. 
 * Für die "falsche" Rundung werden die Anteile ausgewählt, die dadurch am geringsten von ihrem eigentlichen Wert abweichen.
**/
export function sumStableDistribution(amount: number, shares: number[]): number[] {
    const sum = shares.reduce((a, b) => a + b, 0);
    const distribution = shares.map((s) => ({
        shareAmount: (s / sum) * amount,
        roundedShareAmount: Math.round((s / sum) * amount),
        diff: (s * amount) / sum - Math.round((s * amount) / sum), //wenn abgerundet, positiv, wenn aufgerundet, negativ
        used: false,
    }));
    const roundedSum = distribution.reduce((a, b) => a + b.roundedShareAmount, 0);
    let totalDiff = amount - roundedSum; //wenn positiv müssen wir irgendwo was dazulegen
    while (totalDiff !== 0) {
        const sign = Math.sign(totalDiff);
        totalDiff -= sign;
        //wir suchen den Eintrag bei dem es am knappsten war dass er in die für uns ungünstige Richtung gerundet wurde
        const biggestDiff = findMax(
            distribution.filter((d) => !d.used),
            (d) => sign * d.diff,
        );
        //und runden ihn anders rum
        biggestDiff.roundedShareAmount += sign;
        //und merken uns dass wir ihn schon bearbeitet haben
        biggestDiff.used = true;
    }
    return distribution.map((d) => d.roundedShareAmount);
}

function findMax<T>(list: T[], mapFn: (item: T) => number): T {
    return list.reduce((prev, curr) => (mapFn(prev) > mapFn(curr) ? prev : curr), list[0]);
}
