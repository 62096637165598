<div class="tw-px-6 tw-py-6 lg:tw-px-10">
    <ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>
    <div class="tw-mb-12">
        <div class="tw-s-head-28-36-bold tw-mb-5">
            {{ 'COMPONENTS.SIDENAV_SETTINGS.LABEL_PDF_CONFIGURATIONS' | translate }}
        </div>

        <div class="tw-s-head-14-22-regular tw-whitespace-break-spaces">
            <span>{{ 'PAGES.PDF_CONFIGURATIONS.SUB_HEADER' | translate }}</span>
        </div>
    </div>

    <!-- DOWNLOAD TEMPLATE AND INSTRUCTIONS -->
    <div class="tw-mb-20">
        <div class="tw-s-head-14-22-bold tw-mb-4">
            {{ 'PAGES.PDF_CONFIGURATIONS.DOWNLOAD_TEMPLATE_INSTRUCTIONS' | translate }}
        </div>
        <div
            (click)="downloadTemplateExample()"
            class="tile tw-flex tw-items-center tw-justify-between tw-border-[1px] tw-border-scalaraGray-05 tw-border-solid tw-p-4 tw-w-full tw-cursor-pointer tw-max-w-[436px]"
        >
            <div class="tw-flex tw-items-center">
                <app-icon-bubble [size]="72" color="green" image="navi_40_report.svg"></app-icon-bubble>
                <div class="tw-s-head-14-22-semibold tw-ml-5">
                    {{ 'PAGES.PDF_CONFIGURATIONS.TEMPLATE_AND_INSTRUCTIONS' | translate }}
                </div>
            </div>

            <div class="tw-fill-scalaraGray-02 tw-h-6 tw-w-6">
                <svg-icon src="/assets/icons/24_download.svg"></svg-icon>
            </div>
        </div>
    </div>

    <!-- UPLOAD PDF -->
    <div class="tw-mb-14">
        <div class="tw-flex tw-items-center tw-space-x-3 tw-mb-6">
            <app-icon-bubble [size]="40" color="green" image="navi_40_report.svg"></app-icon-bubble>
            <div class="tw-s-head-14-22-bold">{{ 'PAGES.PDF_CONFIGURATIONS.YOUR_STORAGE_FILES' | translate }}</div>
        </div>

        <div class="tw-space-y-6 tw-flex tw-flex-col">
            <app-pdf-configurations-file-input
                pdfTypeTitle="Anschreiben Seite 1"
                pdfBackgroundType="COVER_LETTER"
                (refreshFiles)="refreshFiles()"
                [currentFile]="pdfCoverLetter"
            ></app-pdf-configurations-file-input>

            <app-pdf-configurations-file-input
                pdfTypeTitle="Folgeseite Seite 2 Hochformat"
                pdfBackgroundType="CONTENT_VERTICAL"
                (refreshFiles)="refreshFiles()"
                [currentFile]="pdfContentVertical"
            ></app-pdf-configurations-file-input>

            <app-pdf-configurations-file-input
                pdfTypeTitle="Folgeseite Seite 2 Querformat"
                pdfBackgroundType="CONTENT_LANDSCAPE"
                (refreshFiles)="refreshFiles()"
                [currentFile]="pdfContentLandscape"
            ></app-pdf-configurations-file-input>
        </div>
    </div>
</div>
