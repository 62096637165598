<div class="property-container p-2r app-property-overview">
    <div class="mb-2-5r d-flex justify-space-between">
        <div class="s-head-28-36-bold">{{ 'PROPERTY.OVERVIEW.HEADLINE' | translate }}</div>
        <app-scalara-button
            *ngIf="isPropertyManager"
            variant="ghost"
            (click)="openAddPropertyOverlay()"
            svgName="24_add.svg"
        >
            {{ 'PAGES.PROPERTY.OVERVIEW.LABEL_ADD_PROPERTY_BUTTON' | translate }}
        </app-scalara-button>
    </div>

    <ibm-tabs [followFocus]="true" [cacheActive]="true">
        <ibm-tab heading="Alle">
            <div class="mt-1r">
                <ng-container *ngTemplateOutlet="propertyTable; context: { propertyType: 'ALL' }"></ng-container>
            </div>
        </ibm-tab>

        <ibm-tab [heading]="customTabHeadingWEG" [context]="contextWEG" title="WEG Immobilen">
            <div class="mt-1r">
                <ng-container *ngTemplateOutlet="propertyTable; context: { propertyType: 'WEG' }"></ng-container>
            </div>

            <ng-template #customTabHeadingWEG>
                <ng-container *ngTemplateOutlet="customTabTemplate; context: contextWEG"> </ng-container>
            </ng-template>
        </ibm-tab>

        <ibm-tab [heading]="customTabHeadingMV" [context]="contextMV" title="MV Immobilen">
            <div class="mt-1r">
                <ng-container *ngTemplateOutlet="propertyTable; context: { propertyType: 'MV' }"></ng-container>
            </div>

            <ng-template #customTabHeadingMV>
                <ng-container *ngTemplateOutlet="customTabTemplate; context: contextMV"> </ng-container>
            </ng-template>
        </ibm-tab>

        <ibm-tab [heading]="customTabHeadingSEV" [context]="contextSEV" title="SEV Immobilen">
            <div class="mt-1r">
                <ng-container *ngTemplateOutlet="propertyTable; context: { propertyType: 'SEV' }"></ng-container>
            </div>

            <ng-template #customTabHeadingSEV>
                <ng-container *ngTemplateOutlet="customTabTemplate; context: contextSEV"> </ng-container>
            </ng-template>
        </ibm-tab>
    </ibm-tabs>
</div>

<ng-template #customTabTemplate let-title="title" let-tagTitle="tagTitle" let-tagColor="tagColor">
    <app-tag [color]="tagColor">{{ tagTitle }}</app-tag>
    <span class="ml-0-5r s-label-12-16-regular s-gray-03">{{ title }}</span>
</ng-template>

<ng-template #propertyTable let-propertyType="propertyType">
    <div class="mb-1r d-flex justify-content-end">
        <app-search [searchInputId]="propertyType"></app-search>
    </div>
    <app-table
        [emptyText]="'PAGES.PROPERTY.OVERVIEW.LABEL_EMPTY_TABLE'"
        [header]="tableHeader"
        [data]="filterDataByPropertyType(propertyType)"
        [tableSearchId]="propertyType"
        [isLoading]="isLoading"
    >
    </app-table>
</ng-template>

<div class="info-box-mobile p-1r">
    <app-notification type="info">
        <div class="s-body-14-22-regular">
            {{ 'PAGES.PROPERTY.OVERVIEW.INFO_MOBILE' | translate }}
        </div>
    </app-notification>
</div>
