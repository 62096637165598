import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { getAdressFromPerson, getNameFromPerson } from 'src/app/core/utils/common';
import { Person } from 'src/app/generated-sources/base';

@Component({
    selector: 'app-small-person-infobox',
    templateUrl: './small-person-infobox.component.html',
    styleUrls: ['./small-person-infobox.component.scss'],
})
export class SmallPersonInfoboxComponent implements OnInit, OnChanges {
    @Input() public personDto?: Person;
    @Input() public options = ['name', 'address', 'email', 'phone', 'type'];
    public name = 'Kein DTO hinterlegt';
    public address = 'Keine Adresse hinterlegt';
    public email = 'Keine Email hinterlegt';
    public phone = 'Keine Telefonnummer hinterlegt';
    public type = '';

    public ngOnInit(): void {
        this.setValues();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.setValues();
    }

    private setValues(): void {
        if (this.personDto) {
            this.name = getNameFromPerson(this.personDto);
            this.address = getAdressFromPerson(this.personDto);
            this.email = this.personDto.email ?? '';
            // this.phone = this.personDto.phoneOne ?? '';
            this.type = this.personDto.type;
        }
    }
}
