<div>
    <div
        (click)="onSelectItem(index)"
        *ngFor="let item of itemList; let index = index"
        [ngClass]="{ 'tw-bg-scalaraGray-07': item.selected, 'hover:tw-bg-scalaraGreen-03': !item.selected }"
        class="tw-border-solid tw-h-12 tw-px-3 tw-border-t-[1px] tw-border-scalaraGray-05 tw-flex tw-justify-between tw-items-center tw-group tw-cursor-pointer"
    >
        <div>{{ item.content }}</div>

        <div
            [ngClass]="{ 'tw-invisible group-hover:tw-visible': !item.selected }"
            class="tw-w-6 tw-h-6 tw-fill-scalaraGreen-01"
        >
            <svg-icon [src]="item.selected ? '/assets/icons/24_check.svg' : '/assets/icons/24_add.svg'"></svg-icon>
        </div>
    </div>
</div>
