import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject, switchMap, takeUntil, tap } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { formControl, formControlHasError } from 'src/app/core/utils/common';
import {
    CreateDistributionKeyDto,
    OwnershipForDistributionKeyDto,
    WegSettlementStatementsService,
} from 'src/app/generated-sources/accounting';
import { Ownership, OwnershipsService } from 'src/app/generated-sources/base';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { LedgerCustomService } from '../../accounting/components/services/ledger-custom.service';

@Component({
    selector: 'app-add-annual-statement-consumption-key',
    templateUrl: './add-annual-statement-consumption-key.component.html',
    styleUrls: ['./add-annual-statement-consumption-key.component.scss'],
})
export class AddAnnualStatementConsumptionKeyComponent extends OverlayChildComponent implements OnInit {
    private unsubscribe$ = new Subject<void>();
    public isLoading = false;
    public ownerships: Ownership[] = [];
    public ledgerId = '';
    public wssId = '';
    public form: UntypedFormGroup = this.formBuilder.group({
        description: ['', [Validators.required]],
        ownerships: this.formBuilder.array([]),
    });

    public distributionSum = 0;
    public selectedOwnerships = 0;

    public constructor(
        private annualStatementsService: WegSettlementStatementsService,
        private ownershipsService: OwnershipsService,
        private ledgerCustomService: LedgerCustomService,
        private formBuilder: UntypedFormBuilder,
        private toastService: ToastService,
        private overlayService: OverlayService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.ledgerId = this.config?.data.ledgerId;
        this.wssId = this.config?.data.wssId;
        this.isLoading = true;
        this.createForm();

        this.ledgerCustomService
            .getLedgerPropertyId$()
            .pipe(
                switchMap((propertyId) => {
                    return this.ownershipsService.findAll(propertyId!);
                }),
                tap((ownerships: Ownership[]) => {
                    this.ownerships = ownerships;

                    this.populateAndSubscribeToForm();
                    this.isLoading = false;
                })
            )
            .subscribe();
    }

    public createForm(): void {
        this.form = this.formBuilder.group({
            description: [null, [Validators.required]],
            ownershipsUsageShare: this.formBuilder.array([]),
        });
    }

    private populateAndSubscribeToForm(): void {
        for (let i = 0; i < this.ownerships.length; i++) {
            this.ownershipsUsageShare.push(
                this.formBuilder.group({
                    enableShare: this.formBuilder.control({ value: false, disabled: false }),
                    amountShare: this.formBuilder.control({ value: 0.0, disabled: false }),
                })
            );

            this.ownershipsUsageShare.at(i).valueChanges.subscribe((changes: any) => {
                this.calculateSum();
            });
        }
    }

    public get ownershipsUsageShare(): UntypedFormArray {
        return this.form.controls['ownershipsUsageShare'] as UntypedFormArray;
    }

    public getOwnershipUsage(index: number): UntypedFormGroup {
        return this.ownershipsUsageShare.at(index) as UntypedFormGroup;
    }

    public isInvalid(controlName: string): boolean {
        return formControlHasError(formControl(this.form, controlName), 'required');
    }

    public onCheckboxChange($event: any, index: number): void {
        const input = this.getOwnershipUsage(index).get('amountShare')?.value;
        if ($event.checked) {
            this.selectedOwnerships++;
            this.getOwnershipUsage(index).get('amountShare')?.enable();
            this.distributionSum += input;
        } else {
            this.selectedOwnerships--;
            this.getOwnershipUsage(index).get('amountShare')?.disable();
            this.distributionSum -= input;
        }
    }

    public isInvalidForm(): boolean {
        return this.form.invalid || this.selectedOwnerships === 0 || this.calculateSum() === 0;
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public submit(closeOverlay = false): void {
        this.isLoading = true;
        const ownerships = this.getSelectedOwnerships();
        const createWssDistributionKeyDto: CreateDistributionKeyDto = {
            description: this.form.value.description,
            distributionBase: 'CONSUMPTION',
            ownerships: ownerships,
        };

        this.annualStatementsService
            .createDistributionKey(this.ledgerId, this.wssId, createWssDistributionKeyDto)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: () => {
                    this.toastService.showSuccess('Verbrauch erfolgreich erstellt.');
                    this.saveEmitter$.next();
                    this.isLoading = false;
                },
                error: (error) => {
                    this.isLoading = false;
                    if (error) {
                        this.toastService.showError(error.error['message']);
                    }
                },
            });
    }

    public calculateSum(): number {
        let sum = 0;
        const array = this.ownershipsUsageShare.value;
        for (let i = 0; i < array.length; i++) {
            if (array[i].enableShare) {
                sum += array[i].amountShare;
            }
        }
        return sum;
    }

    private getSelectedOwnerships(): OwnershipForDistributionKeyDto[] {
        const selectedOwnerships: OwnershipForDistributionKeyDto[] = [];
        const array = this.ownershipsUsageShare.value;

        for (let i = 0; i < array.length; i++) {
            if (array[i].enableShare) {
                selectedOwnerships.push({ id: this.ownerships[i].id, distributionBaseShare: array[i].amountShare });
            }
        }

        return selectedOwnerships;
    }
}
