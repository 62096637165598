<div class="d-flex ticket-icons mr-1r">
    <div class="mr-2r formated-icon d-flex align-items-center">
        <svg-icon src="/assets/icons/24_attachment.svg"></svg-icon>
        <span class="ml-0-5r s-body-14-20-regular s-gray-03">{{ amountFiles }}</span>
    </div>
    <div class="formated-icon d-flex align-items-center icon-with-badge">
        <svg-icon src="/assets/icons/24_chat.svg"></svg-icon>
        <span *ngIf="showBadge" class="badge"></span>
        <span class="ml-0-5r s-body-14-20-regular s-gray-03">{{ amountComments }}</span>
    </div>
</div>
