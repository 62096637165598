<div ibmGrid class="bx--grid--full-width">
    <div ibmRow class="mb-4r">
        <div ibmCol></div>
        <div ibmCol [columnNumbers]="{ md: 8, lg: 8 }">
            <div ibmRow class="headline-wrapper">
                <div class="mr-1-5r headline-wrapper__icon">
                    <app-icon-bubble [size]="72" [color]="'green'" [image]="'40_document_check.svg'"></app-icon-bubble>
                </div>
                <div class="headline-wrapper__title">
                    <div class="s-head-28-36-bold">
                        {{ 'PAGES.ANNUAL_STATEMENT.ACTIVATE.HEADLINE' | translate }}
                    </div>
                </div>
            </div>
        </div>
        <div ibmCol></div>
    </div>
    <div ibmRow>
        <div ibmCol></div>
        <div ibmCol [columnNumbers]="{ md: 8, lg: 8 }">
            <div ibmRow class="mb-1-5r s-body-14-20-regular align-items-center">
                <div ibmCol>
                    <div>{{ 'PAGES.ANNUAL_STATEMENT.ACTIVATE.TEXT' | translate }}</div>
                </div>
            </div>
            <div ibmRow *ngIf="isOverlapping">
                <div ibmCol>
                    <app-notification
                        type="warning"
                        subtitle="{{ 'ENTITIES.ANNUAL_STATEMENT.OVERLAP_WARNING' | translate }}"
                    >
                    </app-notification>
                </div>
            </div>
            <div ibmRow></div>
            <!-- START BUTTON ROW -->
            <div ibmRow class="mt-11">
                <div ibmCol>
                    <button [ibmButton]="'tertiary'" type="reset" (click)="abort()">
                        {{ 'CONTROLS.BUTTONS.CANCEL' | translate }}
                    </button>
                </div>

                <div ibmCol class="text-right">
                    <button [ibmButton]="'primary'" type="submit" (click)="onSubmit()">
                        {{ 'COMMON.BTN_SAVE' | translate }}
                    </button>
                </div>
            </div>
            <!-- END BUTTON ROW -->
        </div>
        <div ibmCol></div>
    </div>
</div>
