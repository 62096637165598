/**
 * SCALARA Accounting Module
 * The SCALARA Accounting API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { AssetReportDto } from '../model/assetReportDto';
// @ts-ignore
import { BalanceComparisonDto } from '../model/balanceComparisonDto';
// @ts-ignore
import { ChangeStatusDto } from '../model/changeStatusDto';
// @ts-ignore
import { CreateDistributionKeyDto } from '../model/createDistributionKeyDto';
// @ts-ignore
import { CreateWssDto } from '../model/createWssDto';
// @ts-ignore
import { DistributionUpdateDto } from '../model/distributionUpdateDto';
// @ts-ignore
import { IncomeAndExpenditureDto } from '../model/incomeAndExpenditureDto';
// @ts-ignore
import { OwnershipSettlementDto } from '../model/ownershipSettlementDto';
// @ts-ignore
import { ReservesDevelopmentDto } from '../model/reservesDevelopmentDto';
// @ts-ignore
import { SettlementResultsOverviewDto } from '../model/settlementResultsOverviewDto';
// @ts-ignore
import { SimpleOwnershipSettlementDto } from '../model/simpleOwnershipSettlementDto';
// @ts-ignore
import { UpdateWssDto } from '../model/updateWssDto';
// @ts-ignore
import { WssDistributionDto } from '../model/wssDistributionDto';
// @ts-ignore
import { WssDistributionKeyDto } from '../model/wssDistributionKeyDto';
// @ts-ignore
import { WssDto } from '../model/wssDto';
// @ts-ignore
import { WssPdfGenerationConfigDto } from '../model/wssPdfGenerationConfigDto';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {
    WegSettlementStatementsServiceInterface
} from './wegSettlementStatements.serviceInterface';



@Injectable({
  providedIn: 'root'
})
export class WegSettlementStatementsService implements WegSettlementStatementsServiceInterface {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param ledgerId 
     * @param wssId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public _delete(ledgerId: string, wssId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public _delete(ledgerId: string, wssId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public _delete(ledgerId: string, wssId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public _delete(ledgerId: string, wssId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (ledgerId === null || ledgerId === undefined) {
            throw new Error('Required parameter ledgerId was null or undefined when calling _delete.');
        }
        if (wssId === null || wssId === undefined) {
            throw new Error('Required parameter wssId was null or undefined when calling _delete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/ledgers/${this.configuration.encodeParam({name: "ledgerId", value: ledgerId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/wss/${this.configuration.encodeParam({name: "wssId", value: wssId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param ledgerId 
     * @param wssId 
     * @param changeStatusDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changeStatus(ledgerId: string, wssId: string, changeStatusDto: ChangeStatusDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<WssDto>;
    public changeStatus(ledgerId: string, wssId: string, changeStatusDto: ChangeStatusDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<WssDto>>;
    public changeStatus(ledgerId: string, wssId: string, changeStatusDto: ChangeStatusDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<WssDto>>;
    public changeStatus(ledgerId: string, wssId: string, changeStatusDto: ChangeStatusDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (ledgerId === null || ledgerId === undefined) {
            throw new Error('Required parameter ledgerId was null or undefined when calling changeStatus.');
        }
        if (wssId === null || wssId === undefined) {
            throw new Error('Required parameter wssId was null or undefined when calling changeStatus.');
        }
        if (changeStatusDto === null || changeStatusDto === undefined) {
            throw new Error('Required parameter changeStatusDto was null or undefined when calling changeStatus.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/ledgers/${this.configuration.encodeParam({name: "ledgerId", value: ledgerId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/wss/${this.configuration.encodeParam({name: "wssId", value: wssId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/changeStatus`;
        return this.httpClient.request<WssDto>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: changeStatusDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param ledgerId 
     * @param createWssDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public create(ledgerId: string, createWssDto: CreateWssDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<WssDto>;
    public create(ledgerId: string, createWssDto: CreateWssDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<WssDto>>;
    public create(ledgerId: string, createWssDto: CreateWssDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<WssDto>>;
    public create(ledgerId: string, createWssDto: CreateWssDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (ledgerId === null || ledgerId === undefined) {
            throw new Error('Required parameter ledgerId was null or undefined when calling create.');
        }
        if (createWssDto === null || createWssDto === undefined) {
            throw new Error('Required parameter createWssDto was null or undefined when calling create.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/ledgers/${this.configuration.encodeParam({name: "ledgerId", value: ledgerId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/wss`;
        return this.httpClient.request<WssDto>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: createWssDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param ledgerId 
     * @param wssId 
     * @param createDistributionKeyDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createDistributionKey(ledgerId: string, wssId: string, createDistributionKeyDto: CreateDistributionKeyDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<WssDistributionKeyDto>;
    public createDistributionKey(ledgerId: string, wssId: string, createDistributionKeyDto: CreateDistributionKeyDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<WssDistributionKeyDto>>;
    public createDistributionKey(ledgerId: string, wssId: string, createDistributionKeyDto: CreateDistributionKeyDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<WssDistributionKeyDto>>;
    public createDistributionKey(ledgerId: string, wssId: string, createDistributionKeyDto: CreateDistributionKeyDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (ledgerId === null || ledgerId === undefined) {
            throw new Error('Required parameter ledgerId was null or undefined when calling createDistributionKey.');
        }
        if (wssId === null || wssId === undefined) {
            throw new Error('Required parameter wssId was null or undefined when calling createDistributionKey.');
        }
        if (createDistributionKeyDto === null || createDistributionKeyDto === undefined) {
            throw new Error('Required parameter createDistributionKeyDto was null or undefined when calling createDistributionKey.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/ledgers/${this.configuration.encodeParam({name: "ledgerId", value: ledgerId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/wss/${this.configuration.encodeParam({name: "wssId", value: wssId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/distribution-keys`;
        return this.httpClient.request<WssDistributionKeyDto>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: createDistributionKeyDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param ledgerId 
     * @param wssId 
     * @param distributionKeyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteDistributionKey(ledgerId: string, wssId: string, distributionKeyId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public deleteDistributionKey(ledgerId: string, wssId: string, distributionKeyId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteDistributionKey(ledgerId: string, wssId: string, distributionKeyId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteDistributionKey(ledgerId: string, wssId: string, distributionKeyId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (ledgerId === null || ledgerId === undefined) {
            throw new Error('Required parameter ledgerId was null or undefined when calling deleteDistributionKey.');
        }
        if (wssId === null || wssId === undefined) {
            throw new Error('Required parameter wssId was null or undefined when calling deleteDistributionKey.');
        }
        if (distributionKeyId === null || distributionKeyId === undefined) {
            throw new Error('Required parameter distributionKeyId was null or undefined when calling deleteDistributionKey.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/ledgers/${this.configuration.encodeParam({name: "ledgerId", value: ledgerId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/wss/${this.configuration.encodeParam({name: "wssId", value: wssId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/distribution-keys/${this.configuration.encodeParam({name: "distributionKeyId", value: distributionKeyId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param ledgerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findAll(ledgerId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<WssDto>>;
    public findAll(ledgerId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<WssDto>>>;
    public findAll(ledgerId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<WssDto>>>;
    public findAll(ledgerId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (ledgerId === null || ledgerId === undefined) {
            throw new Error('Required parameter ledgerId was null or undefined when calling findAll.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/ledgers/${this.configuration.encodeParam({name: "ledgerId", value: ledgerId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/wss`;
        return this.httpClient.request<Array<WssDto>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param ledgerId 
     * @param wssId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findOne(ledgerId: string, wssId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<WssDto>;
    public findOne(ledgerId: string, wssId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<WssDto>>;
    public findOne(ledgerId: string, wssId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<WssDto>>;
    public findOne(ledgerId: string, wssId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (ledgerId === null || ledgerId === undefined) {
            throw new Error('Required parameter ledgerId was null or undefined when calling findOne.');
        }
        if (wssId === null || wssId === undefined) {
            throw new Error('Required parameter wssId was null or undefined when calling findOne.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/ledgers/${this.configuration.encodeParam({name: "ledgerId", value: ledgerId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/wss/${this.configuration.encodeParam({name: "wssId", value: wssId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<WssDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves a temporary download link for all wss settelement Statements
     * @param ledgerId 
     * @param wssId 
     * @param wssPdfGenerationConfigDto pdf Print configuration
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllOwnershipSettlementPdf(ledgerId: string, wssId: string, wssPdfGenerationConfigDto: WssPdfGenerationConfigDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public getAllOwnershipSettlementPdf(ledgerId: string, wssId: string, wssPdfGenerationConfigDto: WssPdfGenerationConfigDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public getAllOwnershipSettlementPdf(ledgerId: string, wssId: string, wssPdfGenerationConfigDto: WssPdfGenerationConfigDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public getAllOwnershipSettlementPdf(ledgerId: string, wssId: string, wssPdfGenerationConfigDto: WssPdfGenerationConfigDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (ledgerId === null || ledgerId === undefined) {
            throw new Error('Required parameter ledgerId was null or undefined when calling getAllOwnershipSettlementPdf.');
        }
        if (wssId === null || wssId === undefined) {
            throw new Error('Required parameter wssId was null or undefined when calling getAllOwnershipSettlementPdf.');
        }
        if (wssPdfGenerationConfigDto === null || wssPdfGenerationConfigDto === undefined) {
            throw new Error('Required parameter wssPdfGenerationConfigDto was null or undefined when calling getAllOwnershipSettlementPdf.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/ledgers/${this.configuration.encodeParam({name: "ledgerId", value: ledgerId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/wss/${this.configuration.encodeParam({name: "wssId", value: wssId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/pdfDownloadLink`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: wssPdfGenerationConfigDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves a temporary download link for a zip file containing all wss settlement PDFs
     * @param ledgerId 
     * @param wssId 
     * @param wssPdfGenerationConfigDto pdf Print configuration
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllPdfInZip(ledgerId: string, wssId: string, wssPdfGenerationConfigDto: WssPdfGenerationConfigDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public getAllPdfInZip(ledgerId: string, wssId: string, wssPdfGenerationConfigDto: WssPdfGenerationConfigDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public getAllPdfInZip(ledgerId: string, wssId: string, wssPdfGenerationConfigDto: WssPdfGenerationConfigDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public getAllPdfInZip(ledgerId: string, wssId: string, wssPdfGenerationConfigDto: WssPdfGenerationConfigDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (ledgerId === null || ledgerId === undefined) {
            throw new Error('Required parameter ledgerId was null or undefined when calling getAllPdfInZip.');
        }
        if (wssId === null || wssId === undefined) {
            throw new Error('Required parameter wssId was null or undefined when calling getAllPdfInZip.');
        }
        if (wssPdfGenerationConfigDto === null || wssPdfGenerationConfigDto === undefined) {
            throw new Error('Required parameter wssPdfGenerationConfigDto was null or undefined when calling getAllPdfInZip.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/ledgers/${this.configuration.encodeParam({name: "ledgerId", value: ledgerId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/wss/${this.configuration.encodeParam({name: "wssId", value: wssId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/allPdfZipDownloadLink`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: wssPdfGenerationConfigDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param ledgerId 
     * @param wssId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAssetReport(ledgerId: string, wssId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AssetReportDto>;
    public getAssetReport(ledgerId: string, wssId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AssetReportDto>>;
    public getAssetReport(ledgerId: string, wssId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AssetReportDto>>;
    public getAssetReport(ledgerId: string, wssId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (ledgerId === null || ledgerId === undefined) {
            throw new Error('Required parameter ledgerId was null or undefined when calling getAssetReport.');
        }
        if (wssId === null || wssId === undefined) {
            throw new Error('Required parameter wssId was null or undefined when calling getAssetReport.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/ledgers/${this.configuration.encodeParam({name: "ledgerId", value: ledgerId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/wss/${this.configuration.encodeParam({name: "wssId", value: wssId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/assetReport`;
        return this.httpClient.request<AssetReportDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param ledgerId 
     * @param wssId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBalanceComparison(ledgerId: string, wssId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<BalanceComparisonDto>;
    public getBalanceComparison(ledgerId: string, wssId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<BalanceComparisonDto>>;
    public getBalanceComparison(ledgerId: string, wssId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<BalanceComparisonDto>>;
    public getBalanceComparison(ledgerId: string, wssId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (ledgerId === null || ledgerId === undefined) {
            throw new Error('Required parameter ledgerId was null or undefined when calling getBalanceComparison.');
        }
        if (wssId === null || wssId === undefined) {
            throw new Error('Required parameter wssId was null or undefined when calling getBalanceComparison.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/ledgers/${this.configuration.encodeParam({name: "ledgerId", value: ledgerId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/wss/${this.configuration.encodeParam({name: "wssId", value: wssId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/balance-comparison`;
        return this.httpClient.request<BalanceComparisonDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param ledgerId 
     * @param wssId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDistribution(ledgerId: string, wssId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<WssDistributionDto>;
    public getDistribution(ledgerId: string, wssId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<WssDistributionDto>>;
    public getDistribution(ledgerId: string, wssId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<WssDistributionDto>>;
    public getDistribution(ledgerId: string, wssId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (ledgerId === null || ledgerId === undefined) {
            throw new Error('Required parameter ledgerId was null or undefined when calling getDistribution.');
        }
        if (wssId === null || wssId === undefined) {
            throw new Error('Required parameter wssId was null or undefined when calling getDistribution.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/ledgers/${this.configuration.encodeParam({name: "ledgerId", value: ledgerId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/wss/${this.configuration.encodeParam({name: "wssId", value: wssId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/distribution`;
        return this.httpClient.request<WssDistributionDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param ledgerId 
     * @param wssId 
     * @param distributionBase 
     * @param excludeDistributionBase 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDistributionKeys(ledgerId: string, wssId: string, distributionBase?: string, excludeDistributionBase?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<WssDistributionKeyDto>>;
    public getDistributionKeys(ledgerId: string, wssId: string, distributionBase?: string, excludeDistributionBase?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<WssDistributionKeyDto>>>;
    public getDistributionKeys(ledgerId: string, wssId: string, distributionBase?: string, excludeDistributionBase?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<WssDistributionKeyDto>>>;
    public getDistributionKeys(ledgerId: string, wssId: string, distributionBase?: string, excludeDistributionBase?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (ledgerId === null || ledgerId === undefined) {
            throw new Error('Required parameter ledgerId was null or undefined when calling getDistributionKeys.');
        }
        if (wssId === null || wssId === undefined) {
            throw new Error('Required parameter wssId was null or undefined when calling getDistributionKeys.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (distributionBase !== undefined && distributionBase !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>distributionBase, 'distributionBase');
        }
        if (excludeDistributionBase !== undefined && excludeDistributionBase !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>excludeDistributionBase, 'excludeDistributionBase');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/ledgers/${this.configuration.encodeParam({name: "ledgerId", value: ledgerId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/wss/${this.configuration.encodeParam({name: "wssId", value: wssId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/distribution-keys`;
        return this.httpClient.request<Array<WssDistributionKeyDto>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param ledgerId 
     * @param wssId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIncomeAndExpenditure(ledgerId: string, wssId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<IncomeAndExpenditureDto>;
    public getIncomeAndExpenditure(ledgerId: string, wssId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<IncomeAndExpenditureDto>>;
    public getIncomeAndExpenditure(ledgerId: string, wssId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<IncomeAndExpenditureDto>>;
    public getIncomeAndExpenditure(ledgerId: string, wssId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (ledgerId === null || ledgerId === undefined) {
            throw new Error('Required parameter ledgerId was null or undefined when calling getIncomeAndExpenditure.');
        }
        if (wssId === null || wssId === undefined) {
            throw new Error('Required parameter wssId was null or undefined when calling getIncomeAndExpenditure.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/ledgers/${this.configuration.encodeParam({name: "ledgerId", value: ledgerId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/wss/${this.configuration.encodeParam({name: "wssId", value: wssId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/incomeAndExpenditure`;
        return this.httpClient.request<IncomeAndExpenditureDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param ledgerId 
     * @param wssId 
     * @param ownershipId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOwnershipSettlement(ledgerId: string, wssId: string, ownershipId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<OwnershipSettlementDto>;
    public getOwnershipSettlement(ledgerId: string, wssId: string, ownershipId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<OwnershipSettlementDto>>;
    public getOwnershipSettlement(ledgerId: string, wssId: string, ownershipId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<OwnershipSettlementDto>>;
    public getOwnershipSettlement(ledgerId: string, wssId: string, ownershipId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (ledgerId === null || ledgerId === undefined) {
            throw new Error('Required parameter ledgerId was null or undefined when calling getOwnershipSettlement.');
        }
        if (wssId === null || wssId === undefined) {
            throw new Error('Required parameter wssId was null or undefined when calling getOwnershipSettlement.');
        }
        if (ownershipId === null || ownershipId === undefined) {
            throw new Error('Required parameter ownershipId was null or undefined when calling getOwnershipSettlement.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/ledgers/${this.configuration.encodeParam({name: "ledgerId", value: ledgerId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/wss/${this.configuration.encodeParam({name: "wssId", value: wssId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/ownership-settlements/${this.configuration.encodeParam({name: "ownershipId", value: ownershipId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<OwnershipSettlementDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves a temporary download link for a wss settelement
     * @param ledgerId 
     * @param wssId 
     * @param ownershipId 
     * @param wssPdfGenerationConfigDto pdf Print configuration
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOwnershipSettlementPdf(ledgerId: string, wssId: string, ownershipId: string, wssPdfGenerationConfigDto: WssPdfGenerationConfigDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public getOwnershipSettlementPdf(ledgerId: string, wssId: string, ownershipId: string, wssPdfGenerationConfigDto: WssPdfGenerationConfigDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public getOwnershipSettlementPdf(ledgerId: string, wssId: string, ownershipId: string, wssPdfGenerationConfigDto: WssPdfGenerationConfigDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public getOwnershipSettlementPdf(ledgerId: string, wssId: string, ownershipId: string, wssPdfGenerationConfigDto: WssPdfGenerationConfigDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (ledgerId === null || ledgerId === undefined) {
            throw new Error('Required parameter ledgerId was null or undefined when calling getOwnershipSettlementPdf.');
        }
        if (wssId === null || wssId === undefined) {
            throw new Error('Required parameter wssId was null or undefined when calling getOwnershipSettlementPdf.');
        }
        if (ownershipId === null || ownershipId === undefined) {
            throw new Error('Required parameter ownershipId was null or undefined when calling getOwnershipSettlementPdf.');
        }
        if (wssPdfGenerationConfigDto === null || wssPdfGenerationConfigDto === undefined) {
            throw new Error('Required parameter wssPdfGenerationConfigDto was null or undefined when calling getOwnershipSettlementPdf.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/ledgers/${this.configuration.encodeParam({name: "ledgerId", value: ledgerId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/wss/${this.configuration.encodeParam({name: "wssId", value: wssId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/ownership-settlements/${this.configuration.encodeParam({name: "ownershipId", value: ownershipId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/pdfDownloadLink`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: wssPdfGenerationConfigDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param ledgerId 
     * @param wssId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOwnershipSettlements(ledgerId: string, wssId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<SimpleOwnershipSettlementDto>>;
    public getOwnershipSettlements(ledgerId: string, wssId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<SimpleOwnershipSettlementDto>>>;
    public getOwnershipSettlements(ledgerId: string, wssId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<SimpleOwnershipSettlementDto>>>;
    public getOwnershipSettlements(ledgerId: string, wssId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (ledgerId === null || ledgerId === undefined) {
            throw new Error('Required parameter ledgerId was null or undefined when calling getOwnershipSettlements.');
        }
        if (wssId === null || wssId === undefined) {
            throw new Error('Required parameter wssId was null or undefined when calling getOwnershipSettlements.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/ledgers/${this.configuration.encodeParam({name: "ledgerId", value: ledgerId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/wss/${this.configuration.encodeParam({name: "wssId", value: wssId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/ownership-settlements`;
        return this.httpClient.request<Array<SimpleOwnershipSettlementDto>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param ledgerId 
     * @param wssId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReservesDevelopment(ledgerId: string, wssId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ReservesDevelopmentDto>;
    public getReservesDevelopment(ledgerId: string, wssId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ReservesDevelopmentDto>>;
    public getReservesDevelopment(ledgerId: string, wssId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ReservesDevelopmentDto>>;
    public getReservesDevelopment(ledgerId: string, wssId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (ledgerId === null || ledgerId === undefined) {
            throw new Error('Required parameter ledgerId was null or undefined when calling getReservesDevelopment.');
        }
        if (wssId === null || wssId === undefined) {
            throw new Error('Required parameter wssId was null or undefined when calling getReservesDevelopment.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/ledgers/${this.configuration.encodeParam({name: "ledgerId", value: ledgerId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/wss/${this.configuration.encodeParam({name: "wssId", value: wssId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/reservesDevelopment`;
        return this.httpClient.request<ReservesDevelopmentDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param ledgerId 
     * @param wssId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSettlementResultsOverview(ledgerId: string, wssId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<SettlementResultsOverviewDto>;
    public getSettlementResultsOverview(ledgerId: string, wssId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<SettlementResultsOverviewDto>>;
    public getSettlementResultsOverview(ledgerId: string, wssId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<SettlementResultsOverviewDto>>;
    public getSettlementResultsOverview(ledgerId: string, wssId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (ledgerId === null || ledgerId === undefined) {
            throw new Error('Required parameter ledgerId was null or undefined when calling getSettlementResultsOverview.');
        }
        if (wssId === null || wssId === undefined) {
            throw new Error('Required parameter wssId was null or undefined when calling getSettlementResultsOverview.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/ledgers/${this.configuration.encodeParam({name: "ledgerId", value: ledgerId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/wss/${this.configuration.encodeParam({name: "wssId", value: wssId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/settlementResultsOverview`;
        return this.httpClient.request<SettlementResultsOverviewDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param ledgerId 
     * @param wssId 
     * @param updateWssDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public update(ledgerId: string, wssId: string, updateWssDto: UpdateWssDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<WssDto>;
    public update(ledgerId: string, wssId: string, updateWssDto: UpdateWssDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<WssDto>>;
    public update(ledgerId: string, wssId: string, updateWssDto: UpdateWssDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<WssDto>>;
    public update(ledgerId: string, wssId: string, updateWssDto: UpdateWssDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (ledgerId === null || ledgerId === undefined) {
            throw new Error('Required parameter ledgerId was null or undefined when calling update.');
        }
        if (wssId === null || wssId === undefined) {
            throw new Error('Required parameter wssId was null or undefined when calling update.');
        }
        if (updateWssDto === null || updateWssDto === undefined) {
            throw new Error('Required parameter updateWssDto was null or undefined when calling update.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/ledgers/${this.configuration.encodeParam({name: "ledgerId", value: ledgerId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/wss/${this.configuration.encodeParam({name: "wssId", value: wssId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<WssDto>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateWssDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param ledgerId 
     * @param wssId 
     * @param distributionUpdateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDistribution(ledgerId: string, wssId: string, distributionUpdateDto: DistributionUpdateDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<WssDistributionDto>;
    public updateDistribution(ledgerId: string, wssId: string, distributionUpdateDto: DistributionUpdateDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<WssDistributionDto>>;
    public updateDistribution(ledgerId: string, wssId: string, distributionUpdateDto: DistributionUpdateDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<WssDistributionDto>>;
    public updateDistribution(ledgerId: string, wssId: string, distributionUpdateDto: DistributionUpdateDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (ledgerId === null || ledgerId === undefined) {
            throw new Error('Required parameter ledgerId was null or undefined when calling updateDistribution.');
        }
        if (wssId === null || wssId === undefined) {
            throw new Error('Required parameter wssId was null or undefined when calling updateDistribution.');
        }
        if (distributionUpdateDto === null || distributionUpdateDto === undefined) {
            throw new Error('Required parameter distributionUpdateDto was null or undefined when calling updateDistribution.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/ledgers/${this.configuration.encodeParam({name: "ledgerId", value: ledgerId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/wss/${this.configuration.encodeParam({name: "wssId", value: wssId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/distribution`;
        return this.httpClient.request<WssDistributionDto>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: distributionUpdateDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
