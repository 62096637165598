<ibm-loading *ngIf="isInitialLoading" [isActive]="true" [size]="'normal'" [overlay]="true"> </ibm-loading>

<div ibmRow class="mt-06">
    <div ibmCol>
        <ibm-breadcrumb [items]="breadcrumbs" [noTrailingSlash]="true"> </ibm-breadcrumb>
    </div>

    <div class="w-100 align-items-center action-container">
        <div class="icons-button-container">
            <app-scalara-button
                class="tw-ml-2"
                variant="ghost"
                (click)="getAccountStatement()"
                svgName="24_download.svg"
                [isLoading]="isDownloading"
            >
                Kontoauszug
            </app-scalara-button>
            <app-scalara-button
                [variant]="'ghost'"
                type="button"
                *ngIf="originalTypeName === 'EXPENSE' || originalTypeName === 'REVENUE'"
                (click)="openAddAccount(false)"
                svgName="24_duplicate.svg"
            >
                {{ 'COMMON.COPY' | translate }}
            </app-scalara-button>

            <app-scalara-button [variant]="'ghost'" type="button" (click)="openAddAccount(true)" svgName="24_edit.svg">
                {{ 'COMMON.EDIT' | translate }}
            </app-scalara-button>
        </div>
        <app-accounting-date-picker-selector></app-accounting-date-picker-selector>
    </div>
</div>

<div class="mb-11" *ngIf="account">
    <div ibmRow class="mb-04">
        <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }" class="info-field__label s-label-12-22-semibold">
            {{ 'COMMON.LABEL_DESCRIPTION' | translate }}
        </div>
    </div>
    <div ibmRow [ngClass]="{ 'mb-04': account.apportionable !== null, 'mb-08': account.apportionable === null }">
        <div ibmCol class="s-head-28-36-bold">{{ account.name }}</div>
    </div>

    <div
        *ngIf="originalTypeName === 'EXPENSE'"
        [ngClass]="{ 'label-green': account.apportionable, 'label-grey': !account.apportionable }"
        class="mb-08 label d-inline-block s-label-12-16-regular"
    >
        {{
            (account.apportionable
                ? 'ACCOUNTING.ACCOUNT_DETAILS.APPORTIONABLE'
                : 'ACCOUNTING.ACCOUNT_DETAILS.NOT_APPORTIONABLE'
            ) | translate
        }}
    </div>

    <div ibmRow class="mb-04">
        <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }" class="info-field__label s-label-12-22-semibold">
            {{ 'ACCOUNTING.ACCOUNT_DETAILS.ADDITIONAL_DESCRIPTION' | translate }}
        </div>
        <div ibmCol class="s-body-14-22-regular">
            {{ account.description }}
        </div>
    </div>

    <div ibmRow class="mb-04">
        <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }" class="info-field__label s-label-12-22-semibold">
            {{ 'ACCOUNTING.ACCOUNT_DETAILS.TYPE' | translate }}
        </div>
        <div ibmCol class="s-body-14-22-regular">
            {{ account.type }}
        </div>
    </div>

    <div ibmRow class="mb-04">
        <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }" class="info-field__label s-label-12-22-semibold">
            {{ 'ACCOUNTING.ACCOUNT_DETAILS.GROUP' | translate }}
        </div>
        <div ibmCol class="s-body-14-22-regular">
            {{ account.accountGroup.name }}
        </div>
    </div>
</div>
<div>
    <app-account-booking-calculation-selector
        [toggleSwitchCallbacks]="toggleSwitchCallbacks"
    ></app-account-booking-calculation-selector>
</div>

<app-booking-table
    [accountBalance]="account?.balance"
    searchId="accountDetailBookings"
    [bookings]="accountBookings"
    [isLoading]="isTableLoading"
    [showReceiptColumn]="true"
    [showBookingsFilter]="true"
    [isAccountBookingDto]="true"
></app-booking-table>

<div class="p-10"></div>
