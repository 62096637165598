import { Component, Input, OnChanges, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ToastService } from '../../../core/services/toast.service';
import { formatDateWithoutHhMmSs } from '../../../core/utils/common';
import { PersonCountDto } from '../../../generated-sources/base';
import { OverlayService } from '../../overlay/services/overlay.service';
import { CellTemplate } from '../../table/enums/cell-template';
import { TableItem } from '../../table/interfaces/table-item';
import { TableModel } from '../../table/interfaces/table-model';
import { PersonAmountAndTimeFrameOverlayComponent } from '../person-amount-and-time-frame-overlay/person-amount-and-time-frame-overlay.component';

@Component({
    selector: 'app-person-amount-and-time-frame-table',
    templateUrl: './person-amount-and-time-frame-table.component.html',
    styleUrls: ['./person-amount-and-time-frame-table.component.scss'],
})
export class PersonAmountAndTimeFrameTableComponent implements OnInit, OnDestroy, OnChanges {
    private unsubscribe$ = new Subject<void>();
    public tableModel: TableModel = { data: [], header: [] };
    @ViewChild('actions', { static: true })
    public actions?: TemplateRef<any>;
    @Input()
    public personAmountList: PersonCountDto[] = [];

    @Input()
    public personAmountCallbacks: PersonAmountCallbacks = {
        addPersonsAmountCallback: () => {
            return;
        },
        editPersonsAmountCallback: () => {
            return;
        },
        deletePersonsAmountCallback: () => {
            return;
        },
    };

    public constructor(private overlayService: OverlayService, private toastService: ToastService) {}

    public ngOnInit(): void {
        this.initialize();
    }
    public ngOnChanges(): void {
        this.initialize();
    }

    private initialize(): void {
        this.tableModel.header = [
            'COMMON.LABEL_START_DATE',
            'ENTITIES.OWNERSHIP.LABEL_PERSONS',
            'COMPONENTS.PERSON_AMOUNT.LABEL_PERSON_DAYS',
            '',
        ];
        this.tableModel.data = this.personAmountList.map((persons) => {
            return this.createRow(persons);
        });
    }

    public openAddPersonAmountOverlay(): void {
        console.log('OpenAdd personAmount');
        const ref = this.overlayService.open(PersonAmountAndTimeFrameOverlayComponent, {
            data: {
                callback: this.personAmountCallbacks.addPersonsAmountCallback,
            },
        });

        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe();
    }
    public openEditPersonAmountOverlay(data: PersonCountDto): void {
        console.log('OpenAdd Edit Person Amount' + JSON.stringify(data));
        const ref = this.overlayService.open(PersonAmountAndTimeFrameOverlayComponent, {
            data: {
                callback: this.personAmountCallbacks.editPersonsAmountCallback,
                personAmountWithTimeFrame: data,
            },
        });

        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe();
    }
    public async deletePersonAmount(id: string): Promise<void> {
        await this.personAmountCallbacks.deletePersonsAmountCallback(id);
    }

    private createRow(item: PersonCountDto): TableItem[] {
        return [
            {
                data: {
                    label: item.from ? formatDateWithoutHhMmSs(item.from) : '–',
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: item.count ?? '–',
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: item.personMultDays && item.personMultDays >= 0 ? item.personMultDays : '–',
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: '',
                    extraData: {
                        id: item.id,
                        data: item,
                        rightAligned: true,
                    },
                },
                template: this.actions,
            },
        ];
    }
    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }
}

/** CallBack Object for transfering the correct interface-Calls over
 * @function: addPersonsAmountCallback -> add-callback + whole Object of PersonCountDTO
 * @function: editPersonsAmountCallback -> edit-callback add-callback + whole Object of PersonCountDTO
 * @function: deletePersonsAmountCallback -> delete-callback + ID-Parameter
 *
 */
export type PersonAmountCallbacks = {
    addPersonsAmountCallback: (occupationPersonDurationthing: PersonCountDto) => void;
    editPersonsAmountCallback: (occupationPersonDurationthing: PersonCountDto) => void;
    deletePersonsAmountCallback: (id: string) => void;
};
