<ibm-loading *ngIf="isInitialLoading" [isActive]="isInitialLoading" [size]="'normal'" [overlay]="isInitialLoading">
</ibm-loading>

<div ibmRow class="mb-10">
    <div ibmCol [columnNumbers]="{ md: 4, lg: 2 }" class="s-head-28-36-bold mb-04">
        {{ 'PAGES.BOOKINGS.BOOKINGS_OVERVIEW.HEADLINE' | translate }}
    </div>

    <div ibmCol [columnNumbers]="{ md: 12, lg: 14 }">
        <div class="text-right">
            <app-accounting-date-picker-selector></app-accounting-date-picker-selector>
            <button ibmButton="primary" class="button-primary-40 mr-05 ml-1r" (click)="openAddBookingOverlay()">
                <svg-icon src="/assets/icons/24_add.svg"></svg-icon>
                {{ 'PAGES.BOOKINGS.BOOKINGS_OVERVIEW.BUTTON_ADD_BOOKING' | translate }}
            </button>
        </div>
    </div>
</div>

<div ibmRow class="mb-04">
    <div ibmCol>
        <app-booking-table
            searchId="bookingTable"
            [bookings]="bookings"
            [isLoading]="isLoading"
            [showReceiptColumn]="true"
            [showBookingsFilter]="true"
        ></app-booking-table>
    </div>
</div>
