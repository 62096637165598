import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ListItem } from 'carbon-components-angular';
import { finalize, Subject, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { getLocalISOTime } from 'src/app/core/utils/common';
import { CreateRelationToPersonDto, Person, PropertiesService } from 'src/app/generated-sources/base';
import { DateRangePickerComponent } from 'src/app/shared/components/date-range-picker/date-range-picker.component';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';
import { TooltipKey } from '../../account-settings/services/custom-tooltip.service';
import { ExistingPersonsComboBoxComponent } from '../existing-persons-combo-box/existing-persons-combo-box.component';
import { DateIntervalDto } from '../interfaces';

@Component({
    selector: 'app-add-adviser-overlay',
    templateUrl: './add-adviser-overlay.component.html',
    styleUrls: ['./add-adviser-overlay.component.scss'],
})
export class AddAdviserOverlayComponent extends OverlayChildComponent implements OnInit, OnDestroy, AfterViewInit {
    public durationForm: UntypedFormGroup = new UntypedFormGroup({});
    private unsubscribe$ = new Subject<void>();
    public isLoading = false;
    public currentPersons: ListItem[] = [];
    public propertyID = '';
    public selectedPerson = {} as Person;

    @ViewChild(DateRangePickerComponent)
    public dateRangePickerChildComponent?: DateRangePickerComponent;
    @ViewChild(ExistingPersonsComboBoxComponent)
    public existingPersonsComboBox?: ExistingPersonsComboBoxComponent;

    public constructor(
        private toast: ToastService,
        private router: Router,
        private propertiesService: PropertiesService,
        public translateService: TranslateService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.propertyID = this.config?.data?.extraData.propertyId;
    }

    public ngAfterViewInit(): void {
        if (!this.dateRangePickerChildComponent || !this.existingPersonsComboBox) {
            console.warn('some of mandatory children are not initialised');
            return;
        }

        this.durationForm.addControl('dateRangeForm', this.dateRangePickerChildComponent.dateRangeForm);
        this.dateRangePickerChildComponent.dateRangeForm.setParent(this.durationForm);
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    private createRealtionWithTimeConstraintObject(): DateIntervalDto {
        const startDate = this.durationForm.get('dateRangeForm')?.value['startDate'][0];
        const endDate = this.durationForm.get('dateRangeForm')?.value['endDate'][0];

        const datesObj: DateIntervalDto = {
            from: getLocalISOTime(startDate),
        };
        if (endDate) {
            datesObj.to = getLocalISOTime(endDate);
        }
        return datesObj;
    }

    public isFormsValid(): boolean {
        return !!this.selectedPerson.id;
    }

    public onSubmit(): void {
        this.isLoading = true;
        if (!this.isFormsValid()) {
            this.isLoading = false;
            return;
        }

        const createRelationToPersonDto: CreateRelationToPersonDto = {
            personId: this.selectedPerson.id,
            ...this.createRealtionWithTimeConstraintObject(),
        };

        this.propertiesService
            .createNewAdviser(this.propertyID, createRelationToPersonDto)
            .pipe(
                takeUntil(this.unsubscribe$),
                finalize(() => (this.isLoading = false))
            )
            .subscribe({
                next: () => {
                    this.router.navigate(['/properties', this.propertyID, 'advisers']);
                    this.toast.showSuccess(this.translateService.instant('PAGES.RELATIONS.ADVISER.SUCCESS_TOAST'));
                    this.saveEmitter$.next();
                },
                error: (error: any) => {
                    if (error && error.status === 400) {
                        this.toast.showError(this.translateService.instant('PAGES.RELATIONS.ADVISER.ERROR_MESSAGE'));
                    } else {
                        this.toast.showError(error.error['message']);
                    }
                },
            });
    }

    public onSelectPerson($event: Person): void {
        this.selectedPerson = $event || {};
    }

    public TooltipKey = TooltipKey;
}
