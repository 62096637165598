import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'imageBase64',
})
export class ImageBase64Pipe implements PipeTransform {
    public transform(src?: string | undefined): string | undefined {
       if (src) {return "data:image/jpeg;base64," + src }
       else {return undefined}
    }
}
