import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbItem } from 'carbon-components-angular';
import { Observable, Subject, combineLatest, switchMap, takeUntil, tap } from 'rxjs';
import { BreadCrumbService } from 'src/app/core/services/breadcrumb.service';
import { getBankTransactionState } from 'src/app/core/utils/common';
import {
    BankAccountDto,
    BankAccountsService,
    BankTransactionDto,
    BankTransactionsService,
    BookingDto,
    ReceiptDto,
} from 'src/app/generated-sources/accounting';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { BankingTransactionsMarkBookedOverlayComponent } from '../banking-transactions-mark-booked-overlay/banking-transactions-mark-booked-overlay.component';

@Component({
    selector: 'app-banking-transactions-detail',
    templateUrl: './banking-transactions-detail.component.html',
    styleUrls: ['./banking-transactions-detail.component.scss'],
})
export class BankingTransactionsDetailComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();
    public ledgerId = '';
    private bankTransactionId = '';
    private bankAccountId = '';
    public bankAccount = {} as BankAccountDto;

    public bankTransaction?: BankTransactionDto;
    public isLoading = false;

    public breadcrumbs: BreadcrumbItem[] = [];
    public receipts: ReceiptDto[] = [];

    public constructor(
        private translateService: TranslateService,
        private bankAccountsService: BankAccountsService,
        private bankTransactionsService: BankTransactionsService,
        private overlayService: OverlayService,
        private breadcrumbService: BreadCrumbService,
        private route: ActivatedRoute
    ) {}

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public ngOnInit(): void {
        this.isLoading = true;
        this.initBreadCrumbs();
    }

    private initBreadCrumbs(): void {
        // TODO: isolate api calls from initBreadcrumbs
        this.getBankTransactionDetailInfo()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                const breadcrumbLabel = this.translateService.instant('ACCOUNTING.BANK_TRANSACTION_DETAILS.BREADCRUMB');
                const breadcrumb: BreadcrumbItem[] = [
                    {
                        content: breadcrumbLabel,
                        current: true,
                        route: [
                            `/accounting/ledger/${this.ledgerId}/bank-accounts/${this.bankAccountId}/bank-transactions/${this.bankTransactionId}`,
                        ],
                    },
                ];
                this.breadcrumbService.updateBreadCrumbs(breadcrumb);
                this.breadcrumbs = this.breadcrumbService.getCurrentBreadCrumbs();
            });
        this.getAccountDetailInfo().pipe(takeUntil(this.unsubscribe$)).subscribe();
    }

    private getBankTransactionDetailInfo(): Observable<BankTransactionDto> {
        return combineLatest([this.route.parent!.paramMap, this.route.paramMap]).pipe(
            tap((params: ParamMap[]) => {
                this.ledgerId = String(params[0].get('id'));
                this.bankAccountId = this.route.snapshot.params['bankAccountId'];
                this.bankTransactionId = this.route.snapshot.params['bankTransactionId'];
            }),
            switchMap(() =>
                this.bankTransactionsService.findOneBankTransaction(
                    this.bankAccountId,
                    this.bankTransactionId,
                    this.ledgerId
                )
            ),
            tap((bankTransaction: BankTransactionDto) => {
                const receiptsArray: ReceiptDto[][] = [];

                bankTransaction.bookings?.map((booking: BookingDto) => {
                    receiptsArray.push(booking.receipts || []);
                });

                this.receipts = Array.prototype.concat.apply([], receiptsArray);

                this.bankTransaction = bankTransaction;
                this.isLoading = false;
            }),
            takeUntil(this.unsubscribe$)
        );
    }

    public refreshBankTransactionDetailInfo(): void {
        this.isLoading = true;
        this.bankTransactionsService
            .findOneBankTransaction(this.bankAccountId, this.bankTransactionId)
            .subscribe((bankTransaction: BankTransactionDto) => {
                const receiptsArray: ReceiptDto[][] = [];

                bankTransaction.bookings?.map((booking: BookingDto) => {
                    receiptsArray.push(booking.receipts || []);
                });

                this.receipts = Array.prototype.concat.apply([], receiptsArray);

                this.bankTransaction = bankTransaction;

                this.isLoading = false;
            });
    }

    public getAccountDetailInfo(): Observable<BankAccountDto> {
        return combineLatest([this.route.parent!.paramMap, this.route.paramMap]).pipe(
            tap((params: ParamMap[]) => {
                this.ledgerId = String(params[0].get('id'));
                this.bankAccountId = this.route.snapshot.params['bankAccountId'];
                this.bankTransactionId = this.route.snapshot.params['bankTransactionId'];
            }),
            switchMap(() => this.bankAccountsService.findOne(this.bankAccountId, this.ledgerId)),
            tap((bankAccount: BankAccountDto) => {
                this.bankAccount = bankAccount;
            }),
            takeUntil(this.unsubscribe$)
        );
    }

    public openMarkAsBookedOverlay(): void {
        const data = {
            bankTransaction: this.bankTransaction,
        };
        const ref = this.overlayService.open(BankingTransactionsMarkBookedOverlayComponent, {
            data,
        });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.refreshBankTransactionDetailInfo());
    }

    public showIgnoreButton(): boolean {
        if (this.bankTransaction) {
            const status = getBankTransactionState(this.bankTransaction);
            return status !== 'ok' && status !== 'warning';
        }
        return false;
    }
}
