<div class="tw-mt-11 tw-w-[638px]">
    <ng-container>
        <div class="pl-0-5r">
            <app-base-documents-list [items]="(pdfList$ | async) || []"></app-base-documents-list>
        </div>
    </ng-container>
</div>
<table *ngIf="vm && vm.tableData" class="tw-w-[638px] tw-mt-12">
    <ng-container *ngFor="let row of vm.tableData.rows">
        <tr simpleTableRow [columns]="vm.tableData.columns" [rowData]="row" [class]="row.rowClass"></tr>
    </ng-container>
</table>

<div class="tw-w-[638px] tw-mt-16 tw-border-2 tw-border-solid [&&]:tw-border-scalaraGray-05">
    <div class="tw-flex tw-justify-between tw-pl-6 tw-pt-7 tw-pr-">
        <div class="s-head-16-24-bold">Schlüssigkeitskontrolle</div>
        <div *ngIf="isBankAccountCompletelyBooked" class="tw-mr-6">
            <app-icon-bubble image="24_check.svg" [size]="32"></app-icon-bubble>
        </div>
        <div *ngIf="!isBankAccountCompletelyBooked" class="tw-mr-6">
            <app-icon-bubble image="24_attention.svg" [size]="32" color="red"></app-icon-bubble>
        </div>
    </div>
    <table *ngIf="vm && vm.tableDataReasonabilityCheck" class="tw-w-[579px] tw-mt-9 tw-ml-6 tw-mb-8">
        <ng-container *ngFor="let row of vm.tableDataReasonabilityCheck.rows">
            <tr
                simpleTableRow
                [columns]="vm.tableDataReasonabilityCheck.columns"
                [rowData]="row"
                [class]="row.rowClass"
            ></tr>
        </ng-container>
    </table>
</div>
