import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IbanFormatterPipe } from 'angular-iban';
import { ListItem } from 'carbon-components-angular';
import {
    BehaviorSubject,
    Observable,
    Subject,
    combineLatest,
    distinctUntilChanged,
    firstValueFrom,
    map,
    of,
    shareReplay,
    startWith,
    switchMap,
    take,
    takeUntil,
    tap,
} from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import {
    bankAccountTypeName,
    formControl,
    formControlHasError,
    formatDateYYYYMMDDWithoutHours,
    getPersonDescriptionCombobox,
} from 'src/app/core/utils/common';
import {
    BankAccountDto,
    BankAccountsService,
    CreatePaymentDto,
    EconomicPlanDetailsDto,
    LedgerDto,
    LedgersService,
    Payment,
    PaymentDto,
    PaymentsService,
    UpdatePaymentDto,
} from 'src/app/generated-sources/accounting';
import {
    BankAccount as BaseBankAccount,
    Person,
    PersonsService,
    PropertiesService,
} from 'src/app/generated-sources/base';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { OverlayRef } from 'src/app/shared/overlay/utils/overlay-ref';
import {
    AddBankAccountRecommendationOverlayComponent,
    AddBankAccountRecommendationOverlayComponentConfig,
} from '../../../add-bank-account-recommendation-overlay/add-bank-account-recommendation-overlay.component';
import { LedgerCustomService } from '../../../services/ledger-custom.service';

// FIXME: fix typing
export type AddPaymentComponentConfig = {
    data: {
        paymentType: PaymentDto.TypeEnum | 'recurringTransfer' | 'recurringDebit';
        editMode?: boolean;
        paymentId?: string;
    };
};

@Component({
    selector: 'app-banking-payments-add-edit-overlay',
    templateUrl: './banking-payments-add-edit-overlay.component.html',
    styleUrls: ['./banking-payments-add-edit-overlay.component.scss'],
})
export class BankingPaymentsAddEditOverlayComponent extends OverlayChildComponent implements OnInit, OnDestroy {
    public form = this.formBuilder.group({
        counterpartName: [null, [Validators.required]],
        counterpartIban: [null, [Validators.required]],
        counterpartBic: [null],
        counterpartPersonId: [null],
        purpose: [null],
        executionDate: [null],
        amount: [null, [Validators.required]],
        bankName: [null],
        mandateId: [null],
        firstExecutionDate: [null],
        lastExecutionDate: [null],
        executionRhythm: [null],
        unlimitedToEconomicPlan: [false],
    });

    //  list of items to render inside of combo-box form control
    public rateIntervalListItems = Object.entries(EconomicPlanDetailsDto.RateIntervalEnum).map(([_, value]) => {
        return {
            content: this.translateService.instant('ENTITIES.RATE_INTERVAL.LABEL_' + value),
            selected: false,
            value,
        };
    });

    public config$ = new BehaviorSubject<AddPaymentComponentConfig | null>(null);
    public localConfig?: AddPaymentComponentConfig;

    public creditorsList: ListItem[] = [];
    public personsList: ListItem[] = [];
    public personsBankAccountList: ListItem[] = [];
    public ledgerId?: string;
    public headline = '';
    // TODO: check paymentType types
    public paymentType: Payment.TypeEnum | 'recurringTransfer' | 'recurringDebit' = 'MONEY_TRANSFER';
    public persons?: Person[] = [];
    public personsBaseBankAccount?: BaseBankAccount[];
    public editMode = false;
    public isLoading = true;

    public selectedBank$ = new Subject<BankAccountDto>();
    public selectedBank?: BankAccountDto;
    private ibanFormatterPipe = new IbanFormatterPipe();

    private selectedIban$ = this.form.get('counterpartIban')?.valueChanges || '';
    private unsubscribe$ = new Subject<void>();
    private selectedPersonId$ = new BehaviorSubject<string | undefined>(undefined);

    public mandateList?: ListItem[];
    public mandateList$: Observable<ListItem[]> = combineLatest([this.selectedIban$, this.selectedPersonId$]).pipe(
        switchMap(([iban, selectedPersonId]) => {
            const findBank = this.personsBaseBankAccount?.find((bank) => bank.iban === iban);

            this.form.patchValue({
                counterpartBic: findBank && findBank.bic ? findBank.bic : undefined,
            });

            if (iban && selectedPersonId) {
                return this.personsService.findAllSepaMandates(selectedPersonId, iban);
            }
            return of([]);
        }),
        map((mandates) => {
            if (mandates?.length > 0) {
                return mandates.map((mandate) => {
                    return {
                        selected: false,
                        content: mandate.mandateId,
                        id: mandate.mandateId,
                    };
                });
            }

            return [];
        }),
        map((mandates) => {
            this.mandateList = mandates;
            return [];
        })
    );

    private async generateMandatesList(iban?: string, personId?: string, existingMandateId?: string): Promise<void> {
        this.mandateList = [];
        if (!personId && existingMandateId) {
            this.mandateList?.push({
                selected: true,
                content: existingMandateId,
                id: existingMandateId,
            });
            return;
        }
        if (personId && iban) {
            const mandates = await firstValueFrom(this.personsService.findAllSepaMandates(personId, iban));
            this.mandateList = mandates.map((mandate) => ({
                selected: mandate.mandateId === existingMandateId,
                content: mandate.mandateId,
                id: mandate.mandateId,
            }));
        }
        this.form.patchValue({ mandateId: this.mandateList.find((mandate) => mandate.selected)?.content });
    }

    public personBankAccountList = this.selectedPersonId$.pipe(
        switchMap((personId) => {
            if (personId) {
                this.generateMandatesList();
                return this.personsService.findAllBankAccounts(personId);
            }

            return of([]);
        }),
        tap((bankAccount) => {
            this.personsBankAccountList = [];
            this.personsBaseBankAccount = bankAccount;

            this.form.patchValue({
                counterpartIban: '',
            });

            if (bankAccount && bankAccount.length > 1) {
                this.personsBankAccountList = bankAccount.map((bankAccount) => {
                    return {
                        selected: false,
                        content:
                            this.ibanFormatterPipe.transform(bankAccount.iban) +
                            (bankAccount.bankName ? ', ' + bankAccount.bankName : ''),
                        id: bankAccount.iban,
                    };
                });
            } else {
                if (bankAccount?.length === 1) {
                    this.form.patchValue({
                        counterpartIban: bankAccount[0]?.iban,
                    });
                    this.generateMandatesList(bankAccount[0]?.iban, this.selectedPersonId$.getValue());
                }
            }
        })
    );

    public bankAccounts$ = this.ledgerCustomService.getLedgerId$().pipe(
        switchMap((ledgerId) => {
            if (ledgerId) {
                return this.bankAccountService.findAll(ledgerId);
            }
            return of([]);
        }),
        map((bankAccounts) => {
            return bankAccounts.filter((account) => account.type !== 'ManuallyAddedBankAccount');
        }),
        takeUntil(this.unsubscribe$)
    );

    public constructor(
        private formBuilder: UntypedFormBuilder,
        private toastService: ToastService,
        private bankAccountService: BankAccountsService,
        private ledgerCustomService: LedgerCustomService,
        private ledgersService: LedgersService,
        private paymentsService: PaymentsService,
        private personsService: PersonsService,
        private overlayService: OverlayService,
        private datePipe: DatePipe,
        private route: ActivatedRoute,
        private propertiesService: PropertiesService,
        private translateService: TranslateService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.config$.next(this.config as AddPaymentComponentConfig);
        this.localConfig = this.config as AddPaymentComponentConfig;
        this.paymentType = this.localConfig?.data.paymentType as Payment.TypeEnum;
        this.personBankAccountList.subscribe();
        this.selectedBank$.subscribe((bank) => {
            this.selectedBank = bank;
        });

        this.headline =
            this.localConfig.data.paymentType === 'DIRECT_DEBIT'
                ? 'PAGES.ADD_BANK_ORDER.HEADLINE_ADD_DIRECT_DEBIT'
                : 'PAGES.ADD_BANK_ORDER.HEADLINE_ADD_TRANSFER';

        if (this.paymentType === 'DIRECT_DEBIT') {
            this.form.controls['mandateId'].setValidators([Validators.required]);
            this.form.controls['mandateId'].setErrors(null);
            this.form.controls['mandateId'].updateValueAndValidity();
        }

        this.loadAddandEditPaymentFlow(this.localConfig.data.paymentId);
    }
    private async loadAddandEditPaymentFlow(paymentId?: string): Promise<void> {
        // Wenn Überweisung lade den Creditor
        // if (this.paymentType === 'DIRECT_DEBIT') {
        //     this.creditorsService
        //         .findAll()
        //         .pipe(
        //             tap((creditors) => {
        //                 this.creditorsList = creditors.map((creditor) => ({
        //                     value: creditor.id,
        //                     content: `${creditor.creditorName}, ${creditor.creditorId}, ${this.datePipe.transform(
        //                         creditor.date,
        //                         'dd.MM.yyyy'
        //                     )}`,
        //                     selected: creditors.length === 1 || existingPayment.creditor?.id === creditor.id,
        //                 }));
        //                 if (creditors.length === 1 && this.creditorsList.length > 0) {
        //                     this.form.patchValue({ creditorId: this.creditorsList[0] });
        //                 }
        //             }),
        //             takeUntil(this.unsubscribe$)
        //         )
        //         .subscribe();
        // }
        // Lade alle Personen
        let ledger: LedgerDto | null;

        ledger = await firstValueFrom(this.ledgerCustomService.getLedger$().pipe(take(1)));
        if (ledger) {
            await this.updatePersonsAndPersonList(ledger);
        }

        let existingPayment: PaymentDto;
        // Wenn eine existierende Zahlung geladen wurde existiert "paymentID"
        if (paymentId) {
            existingPayment = await firstValueFrom(this.paymentsService.findOne(paymentId));
            // ändern zu counterpartPersonId
            console.log(existingPayment.executionDate);
            if (!this.route?.snapshot.paramMap.get('id')) {
                ledger = await firstValueFrom(this.ledgersService.findOne(existingPayment.ledgerId));
                await this.updatePersonsAndPersonList(ledger);
            }
            // Wenn eine Person Manuell eingegeben wurde aber keine PersonId Vorhanden ist. Wir einfach in dieListe gesetzt und als selected angezeigt
            if (!existingPayment.counterpartPersonId) {
                this.personsList.push({
                    selected: true,
                    content: existingPayment.counterpartName,
                    id: undefined,
                });
            }
            // Wenn eine PersonId vorhanden ist wird Sie aus den Personen des Ledgers gesucht und selectiert.
            if (this.personsList && existingPayment.counterpartPersonId) {
                this.personsList = this.personsList.map((person) => {
                    if (person['id'] === existingPayment.counterpartPersonId) {
                        return {
                            ...person,
                            selected: true,
                        };
                    }
                    return { ...person };
                });
            }

            await this.generateMandatesList(
                existingPayment.counterpartIban,
                existingPayment.counterpartPersonId,
                existingPayment.mandateId
            );

            this.form.patchValue({
                counterpartName: existingPayment.counterpartName,
                counterpartIban: existingPayment.counterpartIban,
                counterpartBic: existingPayment.counterpartBic,
                counterpartPersonId: existingPayment.counterpartPersonId,
                purpose: existingPayment.purpose,
                executionDate: new Date(existingPayment.executionDate),
                amount: existingPayment.amount,
                bankName: existingPayment.bankAccount.bankName,
                firstExecutionDate: '',
                lastExecutionDate: '',
                executionRhythm: '',
                unlimitedToEconomicPlan: [false],
            });

            this.form.markAsDirty();
            this.form.updateValueAndValidity();

            this.selectedBank = existingPayment.bankAccount as unknown as BankAccountDto;
        }

        this.patchFormFx$.subscribe(() => (this.isLoading = false));
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    private async updatePersonsAndPersonList(ledger: LedgerDto): Promise<void> {
        this.ledgerId = ledger?.id;
        const relations = await firstValueFrom(this.propertiesService.findAllRelations(ledger.propertyId));
        this.persons = relations
            .filter((value, index, self) => index === self.findIndex((t) => t.person?.id === value.person?.id))
            .map((relation) => relation.person);
        this.personsList = this.persons.map((person) => ({
            selected: false,
            content: getPersonDescriptionCombobox(person),
            id: person.id,
        }));
    }
    public abort(): void {
        this.cancelEmitter$.next();
    }

    public submit(): void {
        // console.log({ form: this.form });
        // console.log('form valid: ', this.form.status);
        // return;

        // TODO: add conditional API call here for recurring/non-recurring payment

        if (!this.selectedBank || !this.ledgerId) {
            return;
        }

        if (this.localConfig?.data.editMode) {
            const updatePayment: UpdatePaymentDto = {
                counterpartName: this.form.get('counterpartName')?.value,
                counterpartIban: this.form.get('counterpartIban')?.value,
                counterpartBic: this.form.get('counterpartBic')?.value || undefined,
                amount: this.form.get('amount')?.value,
                // TODO: fix type here
                // creditorId: this.form.get('creditorId')?.value?.value || undefined,
                purpose: this.form.get('purpose')?.value,
                executionDate:
                    this.form.get('executionDate')?.value && this.form.get('executionDate')?.value[0]
                        ? formatDateYYYYMMDDWithoutHours(this.form.get('executionDate')?.value[0])
                        : undefined,
                mandateId: this.form.get('mandateId')?.value || undefined,
                counterpartPersonId: this.selectedPersonId$.getValue() ?? undefined,
            };
            this.updatePayment(updatePayment);

            return;
        }

        const createOrUpdatePayment: CreatePaymentDto = {
            counterpartName: this.form.get('counterpartName')?.value,
            counterpartIban: this.form.get('counterpartIban')?.value,
            counterpartBic: this.form.get('counterpartBic')?.value || undefined,
            amount: this.form.get('amount')?.value,
            // TODO: fix type here
            type: this.paymentType as CreatePaymentDto.TypeEnum,
            bankAccountId: this.selectedBank.id,
            // creditorId: this.form.get('creditorId')?.value?.value || undefined,
            purpose: this.form.get('purpose')?.value,
            executionDate:
                this.form.get('executionDate')?.value && this.form.get('executionDate')?.value[0]
                    ? formatDateYYYYMMDDWithoutHours(this.form.get('executionDate')?.value[0])
                    : undefined,
            mandateId: this.form.get('mandateId')?.value || undefined,
            counterpartPersonId: this.form.get('counterpartPersonId')?.value,
            ledgerId: this.ledgerId,
        };
        this.createNewPayment(createOrUpdatePayment);
    }

    private createNewPayment(payment: CreatePaymentDto): void {
        this.paymentsService
            .createPayment(payment)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: () => {
                    this.handleSuccess(payment);
                },
                error: (error) => {
                    this.handleError(error);
                },
            });
    }
    private updatePayment(payment: UpdatePaymentDto): void {
        if (this.localConfig?.data.paymentId) {
            this.paymentsService
                .update(this.localConfig.data.paymentId, payment as UpdatePaymentDto)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe({
                    next: () => {
                        this.handleSuccess(payment);
                    },
                    error: (error) => {
                        this.handleError(error);
                    },
                });
        }
    }

    private handleSuccess(payment: CreatePaymentDto | UpdatePaymentDto): void {
        this.toastService.showSuccess('Auftrag erfolgreich hinzugefügt.');
        this.openAddBankAccountRecommendationOverlay({
            personRecommendation: this.persons?.find((person) => this.selectedPersonId$.getValue() === person.id),
            payment: payment,
            beforeOpen: () => this.overlayService.removeOverlayComponentFromBody(),
            onClose: () => this.saveEmitter$.next(),
        });
    }

    private handleError(error: any): void {
        if (error) {
            if (error.error['message'].includes('isIBAN')) {
                this.toastService.showError('Ungültige IBAN');
                return;
            }
            if (error.error['message'].includes('isBIC')) {
                this.toastService.showError('Ungültige BIC');
                return;
            }
            this.toastService.showError(error.error['message']);
        }
    }

    public openAddBankAccountRecommendationOverlay({
        personRecommendation,
        payment,
        beforeOpen,
        onClose,
    }: {
        personRecommendation: Person | undefined;
        payment: CreatePaymentDto | UpdatePaymentDto | undefined;
        beforeOpen?: () => void;
        onClose?: () => void;
    }): void {
        beforeOpen ? beforeOpen() : null;

        const findPersonBankAccount = this.personsBaseBankAccount?.find(
            (bankAccount) => bankAccount.iban === payment?.counterpartIban
        );

        if (!payment || !personRecommendation || findPersonBankAccount || !payment.counterpartIban) {
            onClose ? onClose() : null;
            return undefined;
        }

        const configData: AddBankAccountRecommendationOverlayComponentConfig = {
            personRecommendation,
            bankTransaction: { counterpartIban: payment.counterpartIban, counterpartName: payment.counterpartName },
            isAttachedToBookings: false,
        };
        const ref: OverlayRef = this.overlayService.open(AddBankAccountRecommendationOverlayComponent, {
            data: {
                ...configData,
            },
        });
        ref.saveEmitter$.subscribe((data) => {
            onClose ? onClose() : null;
        });
        ref.cancelEmitter$.subscribe(() => {
            ref.close();
            onClose ? onClose() : null;
        });
    }

    public selectBank(bank: BankAccountDto): void {
        this.selectedBank$.next(bank);
    }

    public isInvalidForm(controlName: string): boolean {
        return formControlHasError(formControl(this.form, controlName), 'required');
    }

    public getBankAccountTypeName(bankAccount: BankAccountDto): string {
        return bankAccountTypeName(bankAccount);
    }

    public onSelectPerson(event: any): void {
        this.form.patchValue({
            counterpartName: '',
        });

        if (event.item?.id) {
            const selectedPerson = this.persons?.find((person) => person.id === event.item?.id);

            this.form.patchValue({
                counterpartName: selectedPerson?.firstName
                    ? selectedPerson.firstName + ' ' + selectedPerson.lastName
                    : selectedPerson?.companyName,
            });
        } else {
            this.form.patchValue({ counterpartName: event.target.value });
        }
        this.form.patchValue({ counterpartPersonId: event.item?.id ?? undefined });
        this.selectedPersonId$.next(event.item?.id ?? undefined);
    }

    public onSelectMandateId(event: any): void {
        if (event.item?.id) {
            this.form.patchValue({
                mandateId: event.item?.id,
            });
        } else {
            this.form.patchValue({ mandateId: event.target.value });
        }
    }

    public onSelectPersonBankAccount(event: any): void {
        if (event.item?.id) {
            this.form.patchValue({
                counterpartIban: event.item?.id,
            });
        }
    }

    public flow$ = this.config$.asObservable().pipe(
        map((config) => {
            return config?.data && ['recurringTransfer', 'recurringDebit'].includes(config.data.paymentType)
                ? 'recurring'
                : 'base';
        }),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    public unlimitedToEconomicPlanFormValue$ = this.form.valueChanges.pipe(
        map((form) => {
            return form.unlimitedToEconomicPlan;
        }),
        distinctUntilChanged(),
        startWith(this.form.get('unlimitedToEconomicPlan')?.value ?? false)
    );

    public lastExecutionDateIsRequiredByFormValues$ = this.unlimitedToEconomicPlanFormValue$.pipe(map((i) => !i));

    //  observable effect to modify form depending on few conditions (flow and form.unlimitedToEconomicPlan value)
    public patchFormFx$ = combineLatest([this.flow$, this.lastExecutionDateIsRequiredByFormValues$]).pipe(
        tap(([flow, lastExecutionDateIsRequiredByFormValues]) => {
            this.patchFormControls({ flow, lastExecutionDateIsRequiredByFormValues });
        }),
        takeUntil(this.unsubscribe$)
    );

    public patchFormControls({
        flow,
        lastExecutionDateIsRequiredByFormValues,
    }: {
        flow: 'recurring' | 'base';
        lastExecutionDateIsRequiredByFormValues: boolean;
    }): void {
        const obj: Record<string, { isRequired: boolean }> = {
            firstExecutionDate: { isRequired: flow === 'recurring' },
            lastExecutionDate: {
                isRequired: flow === 'recurring' && lastExecutionDateIsRequiredByFormValues,
            },
            executionRhythm: { isRequired: flow === 'recurring' },
            unlimitedToEconomicPlan: { isRequired: flow === 'recurring' },
        };

        const objKeys = Object.keys(obj) as (keyof typeof obj)[];

        for (const key of objKeys) {
            const isRequired = obj[key].isRequired;

            const formControl = this.form.get(key);
            const validators = [Validators.required];
            isRequired ? formControl?.addValidators(validators) : formControl?.removeValidators(validators);
            formControl?.updateValueAndValidity();
        }
        this.form.updateValueAndValidity();
    }
}
