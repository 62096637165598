import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, switchMap, tap } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { PdfBackgroundDto, PdfConfigurationsService } from 'src/app/generated-sources/accounting';

@Component({
    selector: 'app-pdf-configurations-index',
    templateUrl: './pdf-configurations-index.component.html',
    styleUrls: ['./pdf-configurations-index.component.scss'],
})
export class PdfConfigurationsIndexComponent implements OnInit {
    public isLoading = false;
    public refreshPdfCurrentTemplates$ = new BehaviorSubject<void>(undefined);
    public pdfCoverLetter?: PdfBackgroundDto;
    public pdfContentVertical?: PdfBackgroundDto;
    public pdfContentLandscape?: PdfBackgroundDto;

    public constructor(
        private pdfConfigurationsService: PdfConfigurationsService,
        private toastService: ToastService
    ) {}

    public ngOnInit(): void {
        this.getAllBackgroundTemplates();
    }

    private getAllBackgroundTemplates(): void {
        this.refreshPdfCurrentTemplates$
            .pipe(
                tap(() => (this.isLoading = true)),
                switchMap(() => this.pdfConfigurationsService.getAllBackgroundTemplates()),
                tap((backgroundTemplates) => {
                    this.pdfCoverLetter = backgroundTemplates.find(
                        (pdf) => pdf.type === PdfBackgroundDto.TypeEnum.CoverLetter
                    );
                    this.pdfContentVertical = backgroundTemplates.find(
                        (pdf) => pdf.type === PdfBackgroundDto.TypeEnum.ContentVertical
                    );
                    this.pdfContentLandscape = backgroundTemplates.find(
                        (pdf) => pdf.type === PdfBackgroundDto.TypeEnum.ContentLandscape
                    );
                })
            )
            .subscribe({
                next: () => {
                    this.isLoading = false;
                },
                error: (error) => {
                    this.isLoading = false;
                    this.toastService.showError(error.message);
                },
            });
    }

    public downloadTemplateExample(): void {
        const link = document.createElement('a');
        const filespath = [
            {
                url: '/assets/pdfs/Hintergrundvorlagen_Seite2-Querformat.pdf',
                fileName: 'Hintergrundvorlagen_Seite2-Querformat.pdf',
            },
            {
                url: '/assets/pdfs/Hintergrundvorlagen_Seite2-Hochformat.pdf',
                fileName: 'Hintergrundvorlagen_Seite2-Hochformat.pdf',
            },
            {
                url: '/assets/pdfs/Hintergrundvorlagen_Seite1.pdf',
                fileName: 'Hintergrundvorlagen_Seite1.pdf',
            },
        ];

        filespath.map((file) => {
            link.href = file.url;
            link.download = file.fileName;
            link.click();
        });
    }

    public refreshFiles(): void {
        this.refreshPdfCurrentTemplates$.next();
    }
}
