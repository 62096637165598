import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, switchMap, takeUntil, tap } from 'rxjs';
import { getPropertyTypeInfos } from 'src/app/core/utils/common';
import { MediaQueriesService } from 'src/app/shared/services/media-queries.service';
import { Property } from '../../../../generated-sources/base';
import { OverlayService } from '../../../../shared/overlay/services/overlay.service';
import { CellTemplate } from '../../../../shared/table/enums/cell-template';
import { TableItem } from '../../../../shared/table/interfaces/table-item';
import { TableModel } from '../../../../shared/table/interfaces/table-model';
import { ExtendedPerson, PersonLocalService } from '../../services/person-local.service';
import { PropertyCustomService } from '../../services/property-custom.service';
import { AddPropertySelectionComponent } from '../add-property-selection/add-property-selection.component';
@Component({
    selector: 'app-property-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();

    public tableModel: TableModel[] = [{ data: [], header: [] }];
    public tableHeader: string[] = [];

    //tableModel[0] => all properties
    //tableModel[1] => web properties
    //tableModel[2] => mv properties
    //tableModel[3] => sev properties

    public isLoading = false;
    public isPropertyManager = false;
    public person?: ExtendedPerson | null;

    public contextWEG = {
        title: 'Immobilien',
        tagColor: 'green',
        tagTitle: 'WEG',
    };

    public contextMV = {
        title: 'Immobilien',
        tagColor: 'purple',
        tagTitle: 'MV',
    };

    public contextSEV = {
        title: 'Immobilien',
        tagColor: 'orange',
        tagTitle: 'SEV',
    };

    @ViewChild('customTabHeading', { static: true })
    public customTabHeading?: TemplateRef<any>;

    @ViewChild('propertyTable', { static: true })
    public propertyTable?: TemplateRef<any>;

    public constructor(
        private overlayService: OverlayService,
        private personLocalService: PersonLocalService,
        public mediaQueriesService: MediaQueriesService,
        public router: Router,
        private propertyCustomService: PropertyCustomService
    ) {}

    public ngOnInit(): void {
        this.isLoading = true;
        this.initTableHeader();

        this.propertyCustomService
            .getProperties$()
            .pipe(
                tap((properties: Property[]) => {
                    const sev: Property[] = [];
                    const mv: Property[] = [];
                    const weg: Property[] = [];
                    const all: Property[] = [];

                    properties.map((property: Property) => {
                        switch (property.propertyType) {
                            case 'WEG_SEV':
                                sev.push(property);
                                weg.push(property);
                                break;
                            case 'SEV':
                                sev.push(property);
                                break;
                            case 'MV':
                                mv.push(property);
                                break;
                            case 'WEG':
                                weg.push(property);
                                break;
                            default:
                                all.push(property);
                                break;
                        }

                        all.push(property);
                    });

                    const sevTableModel = {
                        data: sev.map((p: Property) => this.createRow(p)),
                        header: [],
                    };

                    const mvTableModel = {
                        data: mv.map((p: Property) => this.createRow(p)),
                        header: [],
                    };

                    const wegTableModel = {
                        data: weg.map((p: Property) => this.createRow(p)),
                        header: [],
                    };

                    const allTableModel = {
                        data: all.map((p: Property) => this.createRow(p)),
                        header: [],
                    };

                    this.tableModel[0] = allTableModel;
                    this.tableModel[1] = wegTableModel;
                    this.tableModel[2] = mvTableModel;
                    this.tableModel[3] = sevTableModel;
                }),
                switchMap(() => this.personLocalService.getPerson$()),
                tap((person) => {
                    this.isPropertyManager = person.personRoles.includes('IS_PROPERTY_MANAGER');
                }),
                tap(() => (this.isLoading = false)),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public openAddPropertyOverlay(): void {
        const ref = this.overlayService.open(AddPropertySelectionComponent, {});
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.propertyCustomService.refresh());
    }

    private initTableHeader(): void {
        this.tableHeader = [
            'ENTITIES.PROPERTY.LABEL_IMG_FULL',
            'ENTITIES.PROPERTY.LABEL_NAME',
            'ENTITIES.ADDRESS.LABEL_STREET_AND_NUMBER',
            'ENTITIES.ADDRESS.LABEL_ZIP_CODE',
            'ENTITIES.ADDRESS.LABEL_AREA',
            'ENTITIES.PROPERTY.LABEL_MANAGEMENT_TYPE',
        ];
    }

    private createRow(data: Property): TableItem[] {
        let tags: any = [];
        const link = `/properties/${data.id}/base-data`;

        if (data.propertyType == Property.PropertyTypeEnum.WegSev) {
            tags = [
                {
                    label: 'WEG',
                    textColor: getPropertyTypeInfos('WEG').color,
                    link: link,
                },
                {
                    label: 'SEV',
                    textColor: getPropertyTypeInfos('SEV').color,
                    link: link,
                },
            ];
        }

        return [
            {
                data: {
                    label: data.imgSmall ? data.imgSmall : '',
                    link,
                },
                template: CellTemplate.Image,
            },
            {
                data: {
                    label: data.name,
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: `${data.address.streetName} ${data.address.streetNumber}`,
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: data.address.zipCode,
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: data.address.area,
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: tags.length ? '' : data.propertyType,
                    link,
                    textColor: tags.length ? '' : getPropertyTypeInfos(data.propertyType).color,
                    extraData: tags.length ? tags : null,
                },
                template: CellTemplate.contentWithTagStyle,
            },
        ];
    }

    public filterDataByPropertyType(propertyType: Property.PropertyTypeEnum): TableItem[][] {
        if (propertyType === 'WEG') {
            return this.tableModel[1]?.data;
        } else if (propertyType === 'MV') {
            return this.tableModel[2]?.data;
        } else if (propertyType === 'SEV') {
            return this.tableModel[3]?.data;
        } else {
            return this.tableModel[0]?.data;
        }
    }
}
