import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ListItem } from 'carbon-components-angular';
import { Subject, switchMap, takeUntil, tap } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { formControl, formControlHasError } from 'src/app/core/utils/common';
import {
    CreateDistributionKeyDto,
    LedgerDto,
    LedgersService,
    OwnershipForDistributionKeyDto,
    WegSettlementStatementsService,
    WssDistributionKeyDto,
} from 'src/app/generated-sources/accounting';
import { Ownership, OwnershipsService } from 'src/app/generated-sources/base';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';

export interface MockOwnership {
    id: string;
    location: string;
    distributionShare: number;
    description: string;
}

export interface DistributionOwnership extends Ownership {
    consumption: number;
}

type OwnershipBase = 'fraction' | 'heatedSquaremeters' | 'squaremeters' | 'persons' | 'OWNERSHIPS';
@Component({
    selector: 'app-add-annual-statement-distribution-key',
    templateUrl: './add-annual-statement-distribution-key.component.html',
    styleUrls: ['./add-annual-statement-distribution-key.component.scss'],
})
export class AddAnnualStatementDistributionKeyComponent extends OverlayChildComponent implements OnInit {
    private unsubscribe$ = new Subject<void>();
    public isLoading = false;
    public descriptionInvalid = false;
    public currentBase = '';

    public wssId = '';
    public ledgerId = '';
    public propertyId = '';

    public distributionSum = 0;
    public selectedOwnerships = 0;
    public ownerships: Ownership[] = [];

    public currentDistributionKey?: WssDistributionKeyDto;
    public distributionKeys: WssDistributionKeyDto[] = [];
    public distributionKeysShare: number[] = [];
    public toastSuccessMessage?: string;
    public distributionBaseListItems: ListItem[] = [];

    public form: UntypedFormGroup = new UntypedFormGroup({});
    public usageArray?: UntypedFormArray;

    public constructor(
        private formBuilder: UntypedFormBuilder,
        private toastService: ToastService,
        private translateService: TranslateService,
        private ledgerService: LedgersService,
        private annualStatementService: WegSettlementStatementsService,
        private ownershipsService: OwnershipsService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.wssId = this.config?.data?.wssId;
        this.ledgerId = this.config?.data?.ledgerId;
        this.distributionKeys = this.config?.data?.distributionKeys;
        this.createForm();

        this.toastSuccessMessage = this.translateService.instant('PAGES.DISTRIBUTION_KEYS.ADD.TOAST_SUCCESS_MESSAGE');

        this.ledgerService
            .findOne(this.ledgerId)
            .pipe(
                tap((ledger: LedgerDto) => {
                    this.propertyId = ledger.propertyId;
                }),
                switchMap(() => this.ownershipsService.findAll(this.propertyId)),
                tap((ownerships: Ownership[]) => {
                    this.ownerships = ownerships;
                    this.populateAndSubscribeToForm();
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => (this.isLoading = false));

        this.initSelector();
    }

    private initSelector(): void {
        this.distributionBaseListItems = [
            {
                content: this.translateService.instant('ENTITIES.OWNERSHIP.LABEL_FRACTION'),
                selected: true,
                value: 'FRACTION',
            },
            {
                content: this.translateService.instant('ENTITIES.OWNERSHIP.LABEL_ENTITY_PLURAL'),
                selected: false,
                value: 'OWNERSHIPS',
            },
            {
                content: this.translateService.instant('ENTITIES.OWNERSHIP.LABEL_SQUARE_METERS'),
                selected: false,
                value: 'SQUARE_METERS',
            },
            {
                content: this.translateService.instant('ENTITIES.OWNERSHIP.LABEL_HEATED_SQUARE_METERS'),
                selected: false,
                value: 'HEATED_SQUARE_METERS',
            },
            {
                content: this.translateService.instant('ENTITIES.OWNERSHIP.LABEL_PERSONS'),
                selected: false,
                value: 'PERSONS',
            },
        ];
    }

    public get ownershipsUsageShare(): UntypedFormArray {
        return this.form.controls['ownershipsUsageShare'] as UntypedFormArray;
    }

    public getOwnershipUsage(index: number): UntypedFormGroup {
        return this.ownershipsUsageShare.at(index) as UntypedFormGroup;
    }

    private populateAndSubscribeToForm(): void {
        /*
      /* for starting a ibm-checkbox in a disabled state, an upgrade of the carbon-components-angular package
      /* was required. 4.57.6 -> 4.70.0
      /* https://github.com/carbon-design-system/carbon-components-angular/pull/2323
      */

        for (let i = 0; i < this.ownerships.length; i++) {
            this.ownershipsUsageShare.push(
                this.formBuilder.group({
                    enableShare: this.formBuilder.control({ value: false, disabled: true }),
                })
            );
            this.getOwnershipUsage(i).get('enableShare')?.disable();

            this.ownershipsUsageShare.at(i).valueChanges.subscribe((changes: any) => {
                this.calculateSum();
            });
        }
    }

    public createForm(): void {
        this.form = this.formBuilder.group({
            description: [null, [Validators.required]],
            distributionBase: [null, [Validators.required]],
            ownershipsUsageShare: this.formBuilder.array([]),
        });
    }

    public get formArray(): UntypedFormArray {
        return this.form?.get('ownershipsUsageShare') as UntypedFormArray;
    }

    public onSelectDistributionBase($event: any): void {
        this.currentBase = $event.item.value;
        this.currentDistributionKey = this.distributionKeys.find(
            (distributionKey: WssDistributionKeyDto) => distributionKey.distributionBase === this.currentBase
        );
        const parsedType = this.parseBaseType(this.currentBase);
        this.distributionSum = 0;
        this.selectedOwnerships = 0;
        const array = this.ownershipsUsageShare;
        if (parsedType !== 'OWNERSHIPS') {
            for (let i = 0; i < array.length; i++) {
                if ((this.ownerships[i][parsedType] as number) === null) {
                    this.getOwnershipUsage(i).get('enableShare')?.disable();
                    this.getOwnershipUsage(i).get('enableShare')?.setValue(false);
                } else {
                    this.getOwnershipUsage(i).get('enableShare')?.enable();
                    if (array.controls[i].value && array.controls[i].value.enableShare) {
                        this.selectedOwnerships++;
                        this.distributionSum += this.ownerships[i][parsedType] as number;
                    }
                }
            }
        } else {
            for (let i = 0; i < array.length; i++) {
                this.getOwnershipUsage(i).get('enableShare')?.enable();
            }
            this.calculateSum();
        }
    }

    public isInvalid(controlName: string): boolean {
        return formControlHasError(formControl(this.form, controlName), 'required');
    }

    public onCheckboxChange($event: any, index: number): void {
        const parsedType = this.parseBaseType(this.currentBase);
        if ($event.checked) {
            this.selectedOwnerships++;
            if (parsedType !== 'OWNERSHIPS') {
                this.distributionSum += this.ownerships[index][parsedType] as number;
            }
        } else {
            this.selectedOwnerships--;
            if (parsedType !== 'OWNERSHIPS') {
                this.distributionSum -= this.ownerships[index][parsedType] as number;
            }
        }
    }

    public calculateSum(): number {
        let sum = 0;
        const array = this.ownershipsUsageShare.value;
        for (let i = 0; i < array.length; i++) {
            if (array[i].enableShare) {
                sum += array[i].amountShare;
            }
        }
        return sum;
    }

    private getSelectedOwnerships(): OwnershipForDistributionKeyDto[] {
        const selectedOwnerships: OwnershipForDistributionKeyDto[] = [];
        const parsedType = this.parseBaseType(this.currentBase);
        const array = this.ownershipsUsageShare.value;
        for (let i = 0; i < array.length; i++) {
            if (array[i].enableShare) {
                selectedOwnerships.push({ id: this.ownerships[i].id });
            }
        }
        return selectedOwnerships;
    }

    public onSubmit(): void {
        this.isLoading = true;
        const ownerships = this.getSelectedOwnerships();

        const createWssDistributionKeyDto: CreateDistributionKeyDto = {
            description: this.form?.get('description')?.value,
            distributionBase: this.form?.get('distributionBase')?.value.value,
            ownerships: ownerships,
        };

        this.annualStatementService
            .createDistributionKey(this.ledgerId, this.wssId, createWssDistributionKeyDto)
            .subscribe({
                next: () => {
                    this.toastService.showSuccess(this.toastSuccessMessage);
                    this.saveEmitter$.next();
                    this.isLoading = false;
                },
                error: (error) => {
                    this.isLoading = false;

                    if (error) {
                        this.toastService.showError(error.error['message']);
                    }
                },
            });
    }

    private parseBaseType(base: string): OwnershipBase {
        if (base === 'FRACTION') {
            return 'fraction';
        }
        if (base === 'OWNERSHIPS') {
            return 'OWNERSHIPS';
        }
        if (base === 'PERSONS') {
            return 'persons';
        }
        if (base === 'SQUARE_METERS') {
            return 'squaremeters';
        } else {
            return 'heatedSquaremeters';
        }
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }
}
