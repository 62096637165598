<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<app-overlay-container
    headlineTitle="PAGES.BOOKINGS.CANCEL_BOOKING_OVERLAY.TITLE"
    [sizeIcon]="72"
    image="40_error.svg"
    colorIcon="red"
>
    <div class="s-head-16-24-bold mb-2r">
        {{ 'PAGES.BOOKINGS.CANCEL_BOOKING_OVERLAY.HEADLINE' | translate }}
    </div>

    <div class="s-head-14-22-regular mb-4r">
        <div>{{ 'PAGES.BOOKINGS.CANCEL_BOOKING_OVERLAY.TEXT_LIST_DESCRIPTION' | translate }}</div>
        <ul class="list mb-2r">
            <li>{{ 'PAGES.BOOKINGS.CANCEL_BOOKING_OVERLAY.TEXT_LIST_ITEM_1' | translate }}</li>
            <li>{{ 'PAGES.BOOKINGS.CANCEL_BOOKING_OVERLAY.TEXT_LIST_ITEM_2' | translate }}</li>
            <li>{{ 'PAGES.BOOKINGS.CANCEL_BOOKING_OVERLAY.TEXT_LIST_ITEM_3' | translate }}</li>
        </ul>
        <div>{{ 'PAGES.BOOKINGS.CANCEL_BOOKING_OVERLAY.TEXT_INFO' | translate }}</div>
    </div>

    <form (keydown.enter)="$event.preventDefault()" [formGroup]="form">
        <div class="tw-w-full">
            <ibm-label [invalid]="!form.valid && form.dirty" invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                >{{ 'PAGES.BOOKINGS.CANCEL_BOOKING_OVERLAY.LABEL_REASON' | translate }}*
                <input
                    ibmText
                    type="reason"
                    name="reason"
                    id="reason"
                    formControlName="reason"
                    [invalid]="!form.valid && form.dirty"
                />
            </ibm-label>
        </div>

        <div class="tw-flex tw-justify-between tw-mt-[120px]">
            <app-scalara-button (click)="abort()" variant="outline" height="48px">
                {{ 'COMMON.BTN_CANCEL' | translate }}
            </app-scalara-button>
            <app-scalara-button (click)="submit()" variant="danger" height="48px" [disabled]="!form.valid">
                {{ 'PAGES.BOOKINGS.CANCEL_BOOKING_OVERLAY.CANCEL_CONFIRMATION' | translate }}
            </app-scalara-button>
        </div>
    </form>
</app-overlay-container>
