import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, finalize, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';
import { EconomicPlanDto, EconomicPlansService } from '../../../../../../../generated-sources/accounting';
import { EconomicPlanCustomService } from '../../../../services/economic-plan-custom.service';

@Component({
    selector: 'app-weg-economic-plan-delete',
    templateUrl: './weg-economic-plan-delete.component.html',
    styleUrls: ['./weg-economic-plan-delete.component.scss'],
})
export class WegEconomicPlanDeleteComponent extends OverlayChildComponent implements OnInit {
    private unsubscribe$ = new Subject<void>();
    public isLoading = false;
    public economicPlanId = '';
    public ledgerId = '';
    public toastSuccessMessage?: string;
    public economicPlan?: EconomicPlanDto;

    public constructor(
        private economicPlansService: EconomicPlansService,
        private toastService: ToastService,
        private translateService: TranslateService,
        private router: Router,
        private economicPlanCustomService: EconomicPlanCustomService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.economicPlan = this.config?.data?.economicPlan;
        this.economicPlanId = this.config?.data?.economicPlan.id;
        this.ledgerId = this.config?.data?.ledgerId;

        this.translateService
            .get('ACCOUNTING.DELETE_ECONOMIC_PLAN.TOAST_SUCCESS_MESSAGE')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((result) => (this.toastSuccessMessage = result));
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public submit(): void {
        this.isLoading = true;

        this.economicPlansService
            .deleteEconomicPlan(this.ledgerId, this.economicPlanId)
            .pipe(
                takeUntil(this.unsubscribe$),
                finalize(() => (this.isLoading = false))
            )
            .subscribe({
                next: () => {
                    this.toastService.showSuccess(this.toastSuccessMessage);
                    this.router.navigate(['/accounting', 'ledger', this.ledgerId, 'economic-plans']);
                    this.economicPlanCustomService.refreshEconomicPlanList();
                    this.abort();
                },
                error: (error) => {
                    if (error) {
                        this.toastService.showError(error.error['message']);
                    }
                },
            });
    }
}
