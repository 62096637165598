<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<app-overlay-container
    [headlineTitle]="'PAGES.CHANGE_INVOICE_INFO_OVERLAY.TITLE'"
    containerSize="small"
    *ngIf="!isLoading"
>
    <form (submit)="onSubmit()" [formGroup]="form">
        <ibm-label
            [invalid]="this.controlInFormGroupIsInvalid(form, 'invoiceRecipient')"
            invalidText="{{ 'CONTROLS.FORMCONTROLS.GENERAL.REQUIRED' | translate }}"
            class="mb-1r"
        >
            <span class="">{{ 'CONTROLS.FORMCONTROLS.INVOICE_RECIPIENT.LABEL' | translate }}* </span>

            <input
                ibmText
                type="text"
                name="invoiceRecipient"
                id="invoiceRecipient"
                formControlName="invoiceRecipient"
                [invalid]="this.controlInFormGroupIsInvalid(form, 'invoiceRecipient')"
                class="d-block"
            />
        </ibm-label>
        <ibm-label
            [invalid]="this.controlInFormGroupIsInvalid(form, 'email')"
            invalidText="{{ 'CONTROLS.FORMCONTROLS.EMAIL.INVALID' | translate }}"
            class="mb-1r"
        >
            <span class="">{{ 'CONTROLS.FORMCONTROLS.EMAIL_INVOICES.LABEL' | translate }}* </span>

            <input
                ibmText
                type="text"
                name="email"
                id="email"
                formControlName="email"
                [invalid]="this.controlInFormGroupIsInvalid(form, 'email')"
                class="d-block"
            />
        </ibm-label>
        <ibm-label
            [invalid]="this.controlInFormGroupIsInvalid(form, 'taxId')"
            invalidText="{{ 'CONTROLS.FORMCONTROLS.TAX_ID.INVALID' | translate }}"
        >
            <span class="">{{ 'CONTROLS.FORMCONTROLS.TAX_ID.LABEL' | translate }} </span>

            <input
                ibmText
                type="text"
                name="taxId"
                id="taxId"
                formControlName="taxId"
                [invalid]="this.controlInFormGroupIsInvalid(form, 'taxId')"
                class="d-block"
            />
        </ibm-label>

        <div class="buttons-wrapper mt-5r">
            <app-scalara-button (click)="this.onCancel()" variant="outline" [fullWidth]="true">{{
                'CONTROLS.BUTTONS.CANCEL' | translate
            }}</app-scalara-button>
            <app-scalara-button
                type="submit"
                [disabled]="this.form.invalid"
                [fullWidth]="true"
                class="buttons-wrapper_button--with-padding"
                >{{ 'CONTROLS.BUTTONS.SAVE' | translate }}</app-scalara-button
            >
        </div>
    </form>
</app-overlay-container>
