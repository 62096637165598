<ibm-loading *ngIf="isLoading" [isActive]="true" [size]="'normal'" [overlay]="true"></ibm-loading>
<div class="pb-1-5r">
    <div class="d-flex justify-space-between mb-5r">
        <div class="s-head-28-36-bold">
            {{ 'PAGES.OPERATIONS_EXPENSE_STATEMENTS.OVERVIEW.HEADLINE' | translate }}
        </div>
        <app-scalara-button
            variant="ghost"
            svgName="24_add.svg"
            (click)="openAddOperationsExpenseStatementsServiceOverlay()"
        >
            {{ 'PAGES.OPERATIONS_EXPENSE_STATEMENTS.OVERVIEW.LABEL_ADD_OPERATIONS_EXPENSE_STATEMENTS' | translate }}
        </app-scalara-button>
    </div>

    <div
        *ngIf="operationsExpenseStatements.length === 0 && !isLoading"
        class="empty d-flex align-items-center py-3-5r px-2-5r"
    >
        <svg-icon src="/assets/icons/24_information.svg"></svg-icon>
        <div class="s-body-14-22-regular ml-0-5r">
            {{ 'PAGES.OPERATIONS_EXPENSE_STATEMENTS.OVERVIEW.EMPTY' | translate }}
        </div>
    </div>

    <div *ngIf="operationsExpenseStatements.length > 0">
        <ng-container *ngFor="let item of operationsExpenseStatements">
            <div
                [routerLink]="'/accounting/ledger/' + ledgerId + '/operations-expense-statements/' + item.id"
                class="tile tile--shadow mb-1-5r"
            >
                <div
                    *ngIf="item.status === 'DRAFT'"
                    class="w-100 py-0-5r pr-1-5r text-right label--blue s-head-14-22-regular"
                >
                    {{ 'PAGES.OPERATIONS_EXPENSE_STATEMENTS.OVERVIEW.INVOICING_IN_CREATION' | translate }}
                </div>
                <div class="tile__header px-2-5r">
                    <app-icon-bubble
                        *ngIf="item.status === 'DRAFT'"
                        [size]="72"
                        [color]="'blue'"
                        [image]="'40_dokument.svg'"
                        class="mr-2r"
                    ></app-icon-bubble>
                    <app-icon-bubble
                        *ngIf="false"
                        [size]="72"
                        [color]="'gray'"
                        [image]="'40_dokument.svg'"
                        class="mr-2r"
                    ></app-icon-bubble>
                    <app-icon-bubble
                        *ngIf="item.status === 'ACTIVE' || item.status === 'ACTIVATING'"
                        [size]="72"
                        [color]="'green'"
                        [image]="'40_dokument_check.svg'"
                        class="mr-2r"
                    ></app-icon-bubble>
                    <div class="headline-wrapper_title">
                        <div class="s-gray-03 s-label-12-22-semibold mb-0-5r">
                            {{ 'PAGES.OPERATIONS_EXPENSE_STATEMENTS.OVERVIEW.LABEL_YEAR' | translate }}
                        </div>
                        <div class="s-head-16-24-bold">
                            {{ item.startDate | date : 'dd.MM.yyyy' }} –
                            {{ item.endDate | date : 'dd.MM.yyyy' }}

                            <app-tag
                                *ngIf="item.status === 'ACTIVE' || item.status === 'ACTIVATING'"
                                class="ml-2r"
                                color="green"
                            >
                                {{ 'PAGES.OPERATIONS_EXPENSE_STATEMENTS.OVERVIEW.ACTIVE_TAG' | translate }}
                            </app-tag>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
