<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"></ibm-loading>

<app-signin-signup-container routeLink="sign-up" [rightImgSrc]="rightImgSrc">
    <div class="sign-in-container">
        <h1 class="s-head-28-36-bold mb-3r">{{ 'PAGES.SIGN_IN.HEADER' | translate }}</h1>
        <form (submit)="onSignIn()" [formGroup]="form">
            <!-- EMAIL -->
            <ibm-label [invalid]="emailInvalid" invalidText="{{ 'CONTROLS.FORMCONTROLS.EMAIL.INVALID' | translate }}">
                {{ 'CONTROLS.FORMCONTROLS.EMAIL.LABEL' | translate }}*
                <input
                    ibmText
                    type="text"
                    placeholder=""
                    name="email"
                    id="email"
                    formControlName="email"
                    [invalid]="emailInvalid"
                />
            </ibm-label>
            <!-- PASSWORD -->
            <ibm-label
                [invalid]="passwordInvalid"
                invalidText="{{ 'CONTROLS.FORMCONTROLS.PASSWORD.INVALID' | translate }}"
            >
                <span class="d-flex justify-space-between align-items-center"
                    >{{ 'CONTROLS.FORMCONTROLS.PASSWORD.LABEL' | translate }}*
                </span>

                <input
                    ibmText
                    type="password"
                    name="password"
                    id="password"
                    formControlName="password"
                    [invalid]="passwordInvalid"
                    class="d-block"
                />
            </ibm-label>
            <form [formGroup]="formNewPassword" *ngIf="userAttributes$ | async; else submitButton">
                <!-- NEW PASSWORD -->
                <app-password-input
                    [form]="formNewPassword"
                    inputName="password"
                    label="{{ 'CONTROLS.FORMCONTROLS.PASSWORD.LABEL_FOR_NEW_PASSWORD' | translate }}*"
                ></app-password-input>

                <!-- PASSWORD WIEDERHOLEN -->
                <app-password-input
                    [form]="formNewPassword"
                    inputName="confirmPassword"
                    label="{{ 'CONTROLS.FORMCONTROLS.CONFIRM_PASSWORD.LABEL_FOR_NEW_PASSWORD' | translate }}*"
                ></app-password-input>

                <div class="action-button-wrapper mt-3r">
                    <app-scalara-button
                        (click)="changePassword()"
                        variant="default"
                        [disabled]="formNewPassword.invalid || form.invalid"
                        [fullWidth]="true"
                    >
                        {{ 'CONTROLS.BUTTONS.SIGN_IN' | translate }}
                    </app-scalara-button>
                </div>
            </form>

            <!-- hide links in demo -->
            <ng-container *ngIf="!environment.demoEnvironment">
                <a
                    class="d-block mb-0-5r"
                    routerLink="/reset-password/give-email"
                    [queryParams]="{ flowType: this.giveEmailFlowTypeObjDeclared.resetPassword }"
                    >{{ 'CONTROLS.LINKS.FORGOT_PASSWORD' | translate }}</a
                >

                <a
                    routerLink="/reset-password/give-email"
                    [queryParams]="{ flowType: this.giveEmailFlowTypeObjDeclared.resendVerificationLink }"
                    >{{ 'CONTROLS.LINKS.RESEND_VERIFICATION' | translate }}</a
                >
            </ng-container>

            <ng-template #submitButton>
                <div class="action-button-wrapper mt-3r">
                    <app-scalara-button type="submit" variant="default" [disabled]="form.invalid" [fullWidth]="true">
                        {{ 'CONTROLS.BUTTONS.SIGN_IN' | translate }}
                    </app-scalara-button>
                </div></ng-template
            >
        </form>
    </div>
</app-signin-signup-container>
