<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<app-overlay-container
    [headlineTitle]="ticketToEdit ? 'PAGES.ADD_TICKET.HEADLINE_EDIT' : 'PAGES.ADD_TICKET.HEADLINE'"
    [sizeIcon]="72"
    image="40_document_edit.svg"
    colorIcon="green"
    containerSize="small"
    *ngIf="!isLoading"
    [tooltipText]="getTooltipText()"
>
    <form (submit)="onSubmit()" [formGroup]="form">
        <div class="mb-1-5r">
            <app-combo-box
                [size]="'md'"
                id="property"
                placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT' | translate }}"
                [appendInline]="true"
                label="{{ 'ENTITIES.PROPERTY.LABEL_ENTITY' | translate }}*"
                [items]="properties"
                formControlName="property"
                (selected)="findOwnderships()"
                (cleared)="clearPropertyInput()"
            >
                <ibm-dropdown-list></ibm-dropdown-list>
            </app-combo-box>
        </div>

        <div class="mb-1-5r">
            <ng-container formArrayName="ownerships">
                <ng-container *ngIf="propertyControlIsNotEmpty">
                    <ng-container *ngFor="let item of ownershipControl.controls; let i = index">
                        <ng-container *ngIf="i > 0">
                            <hr class="mt-2r" />
                            <div class="d-flex justify-content-end">
                                <app-scalara-button
                                    (click)="removeOwnership(i)"
                                    variant="ghost"
                                    svgName="24_close.svg"
                                    height="40px"
                                ></app-scalara-button>
                            </div>
                        </ng-container>

                        <div class="mb-1r" [formGroup]="getOwnershipItemForm(i)">
                            <app-combo-box
                                [size]="'md'"
                                id="ownership"
                                placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT' | translate }}"
                                [appendInline]="true"
                                [label]="getLabelInput()"
                                [items]="ownerships"
                                formControlName="ownership"
                                [disabled]="
                                    (form.value.property || ticketToEdit?.property?.id) && ownerships.length > 0
                                        ? false
                                        : true
                                "
                                [invalid]="isInvalid(i)"
                                [invalidText]="'Eine Einheit darf nicht mehr als einmal zugeordnet werden'"
                                (selected)="onSelectOwnership($event, i)"
                            >
                                <ibm-dropdown-list></ibm-dropdown-list>
                            </app-combo-box>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>

            <div *ngIf="ispropertyManager && propertyControlIsNotEmpty" class="add-ownership-button">
                <app-scalara-button
                    [disabled]="
                        (form.value.property || ticketToEdit?.property?.id) && ownerships.length > 0 ? false : true
                    "
                    (click)="addNewOwnership()"
                    variant="ghost"
                    svgName="24_add.svg"
                    >{{ getLabelButton() | translate }}</app-scalara-button
                >
            </div>
        </div>

        <div class="mb-1-5r">
            <ibm-label>
                {{ 'ENTITIES.TICKET.TITLE' | translate }}*
                <input ibmText type="name" name="title" id="title" formControlName="title" />
            </ibm-label>
        </div>

        <div class="mb-3r">
            <ibm-label>
                {{ 'PAGES.ADD_TICKET.DESCRIPTION' | translate }}
                <textarea
                    rows="3"
                    ibmTextArea
                    aria-label="textarea"
                    formControlName="description"
                    maxlength="100000"
                ></textarea>
            </ibm-label>
        </div>

        <div class="mb-5r w-100" [class.mb-7-5r]="vm?.currentLayoutMode === 'desktop'">
            <div class="s-body-14-22-bold mb-1r">{{ 'ENTITIES.TICKET.ATTACHMENTS' | translate }}</div>
            <app-file-uploader
                #fileUpload
                (filesLoaded)="updateLoadingFilesStatus($event)"
                (fileIdsLoaded)="updateFileIdsLodaded($event)"
                [showButtonFromParent]="vm?.currentLayoutMode === 'mobile'"
                [reverseOrderFilesButton]="true"
                [textInput]="'PAGES.ADD_TICKET.INPUT_AREA'"
                [validFiles]="[
                    'jpg',
                    'jpeg',
                    'png',
                    'pdf',
                    'PDF',
                    'JPG',
                    'PNG',
                    'JPEG',
                    'eml',
                    'emlx',
                    'msg',
                    'EML',
                    'EMLX',
                    'MSG'
                ]"
            ></app-file-uploader>
        </div>

        <div class="d-flex flex-direction-column lg-flex-direction-row justify-space-between buttons">
            <app-scalara-button (click)="abort()" variant="outline">{{
                'CONTROLS.BUTTONS.CANCEL' | translate
            }}</app-scalara-button>
            <app-scalara-button [disabled]="form.invalid || !areFilesFullyUploaded" class="mb-1r" type="submit">{{
                (ticketToEdit ? 'CONTROLS.BUTTONS.SAVE' : 'CONTROLS.BUTTONS.CREATE') | translate
            }}</app-scalara-button>
        </div>
    </form>
</app-overlay-container>
