import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    ObservedValueOf,
    Subject,
    combineLatest,
    lastValueFrom,
    map,
    of,
    shareReplay,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs';
import { formatDateWithoutHhMmSs, formatDateYYYYMMDDWithoutHours } from 'src/app/core/utils/common';
import {
    EconomicsService,
    IncomeAndExpenditureDtoBankAccount,
    IncomeAndExpenditureDtoLedgerAccount,
} from 'src/app/generated-sources/accounting';
import { BaseDocumentsListItem } from 'src/app/shared/components/base-documents-list/base-documents-list.component';
import { Column, RowData } from 'src/app/shared/components/simple-table/simple-table-row/simple-table-row.component';
import { AccountingFilterCustomService } from '../../services/accounting-filter-custom.service';
import { LedgerCustomService } from '../../services/ledger-custom.service';

type ColumnKey = 'name' | 'amount' | 'distributionAmount' | 'planAmount';
type TableData = {
    columns: Column<ColumnKey>[];
    rows: RowData<ColumnKey>[];
};
@Component({
    selector: 'app-mv-income-and-expense-iae-tab',
    templateUrl: './mv-income-and-expense-iae-tab.component.html',
    styleUrls: ['./mv-income-and-expense-iae-tab.component.scss'],
})
export class MvIncomeAndExpenseIaeTabComponent implements OnInit, OnDestroy {
    public ledger$ = this.ledgersCustomService.getLedger$();
    public unsubscribe$ = new Subject<void>();
    public datepicker$ = this.accountsFilterCustomService.getDatePickerSelectedDates$();
    public isBankAccountCompletelyBooked = false;

    public constructor(
        private ledgersCustomService: LedgerCustomService,
        private accountsFilterCustomService: AccountingFilterCustomService,
        private accountingEconomicsService: EconomicsService
    ) {}

    public ngOnInit(): void {
        this.vm$.pipe(takeUntil(this.unsubscribe$)).subscribe((vm) => {
            this.vm = vm;
        });
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
    public iaE$ = combineLatest([this.ledger$, this.datepicker$]).pipe(
        switchMap(([ledger, datepicker]) => {
            if (ledger) {
                return this.accountingEconomicsService.getMVIncomeAndExpense(
                    ledger?.id,
                    formatDateYYYYMMDDWithoutHours(datepicker.startDate),
                    formatDateYYYYMMDDWithoutHours(datepicker.endDate)
                );
            }
            return of(null);
        }),
        takeUntil(this.unsubscribe$),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    public pdfList$ = combineLatest([this.ledger$, this.datepicker$]).pipe(
        map(([ledger, datepicker]) => {
            //ToDo Accounting Service anpassen lassen um ein vernünftiges Property EnitityObject im Ledger zu haben.
            //Dann den Namen und Adresse in den Dateinamen
            const fileNameBase = `Einnahmen & Ausgabenrechnung (`;

            const fileName =
                fileNameBase +
                formatDateWithoutHhMmSs(datepicker.startDate) +
                ' – ' +
                formatDateWithoutHhMmSs(datepicker.endDate) +
                ')';
            // fileName = fileName.split(' ').join('_');

            if (!ledger) {
                return;
            }
            const pdf$ = this.accountingEconomicsService
                .getMVIncomeAndExpensePDF(
                    ledger?.id,
                    formatDateYYYYMMDDWithoutHours(datepicker.startDate),
                    formatDateYYYYMMDDWithoutHours(datepicker.endDate)
                )
                .pipe(tap(() => console.log('subscribed pdf')));

            const getLink = (): Promise<{ url: string; fileName: string }> => {
                return lastValueFrom(pdf$);
            };

            const item: BaseDocumentsListItem = { fileName: fileName, getLink };
            return [item];
        }),
        takeUntil(this.unsubscribe$)
    );

    public tableData$ = this.iaE$.pipe(
        map((iaE) => {
            if (!iaE) {
                return;
            }
            const table: TableData = {
                columns: [
                    { key: 'name', label: '' },
                    { key: 'amount', label: '' },
                ],
                rows: [
                    {
                        cells: [
                            {
                                key: 'name',
                                value: ``,
                                type: 'value',
                                rowCellClass: ' [&&]:tw-s-head-16-24-bold [&&]:tw-pt-8 ',
                            },
                            {
                                key: 'amount',
                                value: `Beträge in €`,
                                type: 'value',
                                rowCellClass: ' [&&]:tw-s-12-22 [&&]:tw-text-scalaraGray-03 [&&]:tw-pt-8 ',
                            },
                        ],
                    },
                    {
                        cells: [
                            {
                                key: 'name',
                                value: `Geldkontenstände zum Start des Zeitraums`,
                                type: 'value',
                                rowCellClass: ' [&&]:tw-s-head-16-24-bold ',
                            },
                            {
                                key: 'amount',
                                value: ``,
                                type: 'value',
                                rowCellClass: ' [&&]:tw-s-12-22 [&&]:tw-text-scalaraGray-03 ',
                            },
                        ],
                    },
                    ...iaE.bankAccountsInitial
                        .map((i: any) => {
                            return this.mapBankAccountCells(i);
                        })
                        .flat(),
                    {
                        cells: [
                            {
                                key: 'name',
                                type: 'value',
                                value: '',
                                rowCellClass: '[&&]:tw-h-4 ',
                            },
                        ],
                        rowClass: '[&&]:tw-border-b [&&]:tw-border-solid [&&]:tw-border-scalaraGray-01',
                    },
                    {
                        cells: [
                            {
                                key: 'name',
                                type: 'value',
                                value: 'Gesamtgeldkontobestände',
                                rowCellClass: '[&&]:tw-pt-2',
                            },
                            {
                                key: 'amount',
                                type: 'value',
                                value: iaE.bankAccountsInitialTotal,
                                rowCellClass: '[&&]:tw-pt-2',
                                pipeToApply: 'eurocent',
                            },
                        ],
                        rowClass: '[&&]:tw-s-head-14-22-bold',
                    },

                    //Überschrift
                    {
                        cells: [
                            {
                                key: 'name',
                                type: 'value',
                                value: 'Übersicht Buchhaltungskonten',
                                rowCellClass: '[&&]:tw-s-head-16-24-bold [&&]:tw-pt-14 [&&]:tw-pb-4',
                            },
                        ],
                    },
                    //Einnahmen
                    {
                        cells: [
                            {
                                key: 'name',
                                type: 'value',
                                value: 'Einnahmen',
                                rowCellClass: '[&&]:tw-s-head-14-22-bold [&&]:tw-pb-3',
                            },
                        ],
                    },
                    ...iaE.revenues.map((i: any) => {
                        return this.mapAccountCells(i);
                    }),
                    { cells: [{ key: 'name', type: 'value', value: '', rowCellClass: '[&&]:tw-h-2' }] },
                    {
                        cells: [
                            { key: 'name', type: 'value', value: 'Summe Einnahmen' },
                            {
                                key: 'amount',
                                type: 'value',
                                value: iaE.revenuesTotal,
                                pipeToApply: 'eurocent',
                            },
                        ],
                        rowClass: '[&&]:tw-s-head-14-22-bold [&&]:tw-bg-scalaraGray-06 [&&]:tw-h-10',
                    },

                    //Ausgaben
                    { cells: [{ key: 'name', type: 'value', value: '', rowCellClass: '[&&]:tw-h-14' }] },
                    {
                        cells: [
                            {
                                key: 'name',
                                type: 'value',
                                value: 'Ausgaben',
                                rowCellClass: '[&&]:tw-s-head-14-22-bold [&&]:tw-pb-4',
                            },
                        ],
                    },
                    ...iaE.expenditures.map((i: any) => {
                        return this.mapAccountCells(i);
                    }),
                    { cells: [{ key: 'name', type: 'value', value: '', rowCellClass: '[&&]:tw-h-2' }] },
                    {
                        cells: [
                            { key: 'name', type: 'value', value: 'Summe Ausgaben, nicht umlagefähige Beträge' },
                            {
                                key: 'amount',
                                type: 'value',
                                value: iaE.expendituresTotal,
                                pipeToApply: 'eurocent',
                            },
                        ],
                        rowClass: '[&&]:tw-s-head-14-22-bold [&&]:tw-bg-scalaraGray-06 [&&]:tw-h-10',
                    },
                    //Summe E&A
                    {
                        cells: [
                            {
                                key: 'name',
                                type: 'value',
                                value: '',
                                rowCellClass: '[&&]:tw-h-6',
                            },
                        ],
                        rowClass: '[&&]:tw-border-b [&&]:tw-border-solid [&&]:tw-border-scalaraGray-01',
                    },
                    {
                        cells: [
                            {
                                key: 'name',
                                type: 'value',
                                value: iaE.surplus > 0 ? `Überschuss` : `Unterdeckung`,
                                rowCellClass: '[&&]:tw-pt-2',
                            },
                            {
                                key: 'amount',
                                type: 'value',
                                value: iaE.surplus,
                                rowCellClass: '[&&]:tw-pt-2',
                                pipeToApply: 'eurocent',
                            },
                        ],
                        rowClass: '[&&]:tw-s-head-14-22-bold',
                    },
                    //Eigentümer-Auszahlungen / Einzahlungen
                    {
                        cells: [
                            {
                                key: 'name',
                                type: 'value',
                                value: '',
                                rowCellClass: '[&&]:tw-h-11',
                            },
                        ],
                    },
                    ...iaE.ownerWithdrawals.map((i: any) => {
                        return this.mapAccountCells(i);
                    }),
                    //Gesamtergebnis
                    {
                        cells: [
                            {
                                key: 'name',
                                type: 'value',
                                value: '',
                                rowCellClass: '[&&]:tw-h-1',
                            },
                        ],
                        rowClass: '[&&]:tw-border-b [&&]:tw-border-solid [&&]:tw-border-scalaraGray-03',
                    },
                    {
                        cells: [
                            {
                                key: 'name',
                                type: 'value',
                                value: '',
                                rowCellClass: '[&&]:tw-h-2',
                            },
                        ],
                        rowClass: '[&&]:tw-border-b [&&]:tw-border-solid [&&]:tw-border-scalaraGray-01',
                    },
                    {
                        cells: [
                            {
                                key: 'name',
                                type: 'value',
                                value: `Ergebnis Buchhaltungskonten`,
                                rowCellClass: '[&&]:tw-pt-2',
                            },
                            {
                                key: 'amount',
                                type: 'value',
                                value: iaE.bookingsTotal,
                                rowCellClass: '[&&]:tw-pt-2',
                                pipeToApply: 'eurocent',
                            },
                        ],
                        rowClass: '[&&]:tw-s-head-14-22-bold',
                    },
                ],
            };

            return table;
        }),
        takeUntil(this.unsubscribe$)
    );

    public tableDataReasonabilityCheck$ = this.iaE$.pipe(
        map((iaE) => {
            if (!iaE) {
                return;
            }

            const completeResult = iaE.plausibilityCheckSum;
            if (completeResult === 0) {
                this.isBankAccountCompletelyBooked = true;
            }

            const table: TableData = {
                columns: [
                    { key: 'name', label: '' },
                    { key: 'amount', label: '' },
                ],
                rows: [
                    {
                        cells: [
                            {
                                key: 'name',
                                value: `Geldkontenstände zum Start des Zeitraums`,
                                type: 'value',
                                rowCellClass: '',
                            },
                            {
                                key: 'amount',
                                value: iaE?.bankAccountsInitialTotal,
                                type: 'value',
                                rowCellClass: '',
                                pipeToApply: 'eurocent',
                            },
                        ],
                        rowClass: '[&&]:hover:tw-bg-scalaraGray-06',
                    },
                    {
                        cells: [
                            {
                                key: 'name',
                                type: 'value',
                                value: `Ergebnis Buchhaltungskonten`,
                                rowCellClass: '',
                            },
                            {
                                key: 'amount',
                                type: 'value',
                                value: iaE?.bookingsTotal,
                                rowCellClass: '',
                                pipeToApply: 'eurocent',
                            },
                        ],
                        rowClass: '[&&]:hover:tw-bg-scalaraGray-06',
                    },
                    {
                        cells: [
                            {
                                key: 'name',
                                value: 'Saldo von Geldkontenbewegungen andere Buchhaltungen',
                                type: 'value',
                            },
                            {
                                key: 'amount',
                                value: iaE?.bookingsTotalFromOtherLedgers,
                                type: 'value',
                                pipeToApply: 'eurocent',
                            },
                        ],
                        rowClass: '[&&]:hover:tw-bg-scalaraGray-06',
                    },
                    {
                        cells: [
                            {
                                key: 'name',
                                type: 'value',
                                value: '',
                                rowCellClass: '',
                            },
                        ],
                        rowClass: '[&&]:tw-border-b [&&]:tw-border-solid [&&]:tw-border-scalaraGray-01',
                    },
                    {
                        cells: [
                            {
                                key: 'name',
                                type: 'value',
                                value: 'Ergebnis',
                                rowCellClass: '[&&]:tw-pt-2',
                            },
                            {
                                key: 'amount',
                                type: 'value',
                                value: completeResult,
                                rowCellClass: '[&&]:tw-pt-2',
                                pipeToApply: 'eurocent',
                            },
                        ],
                        rowClass: '[&&]:tw-s-head-14-22-bold [&&]:tw-mb-12',
                    },
                    {
                        cells: [
                            {
                                key: 'name',
                                type: 'value',
                                value: '',
                                rowCellClass: '',
                            },
                        ],
                        rowClass: '[&&]:tw-h-12',
                    },
                    {
                        cells: [
                            {
                                key: 'name',
                                value: `Geldkontenstände zum Ende des Zeitraums`,
                                type: 'value',
                            },
                            {
                                key: 'amount',
                                value: ``,
                                type: 'value',
                                rowCellClass: ' [&&]:tw-s-12-22 [&&]:tw-text-scalaraGray-03 ',
                            },
                        ],
                        rowClass: '[&&]:tw-s-head-14-22-bold [&&]:tw-pt-12',
                    },
                    ...iaE.bankAccountsFinal
                        .map((i: any) => {
                            return this.mapBankAccountCells(i);
                        })
                        .flat(),
                    {
                        cells: [
                            {
                                key: 'name',
                                type: 'value',
                                value: '',
                                rowCellClass: '[&&]:tw-h-4 ',
                            },
                        ],
                        rowClass: '[&&]:tw-border-b [&&]:tw-border-solid [&&]:tw-border-scalaraGray-01',
                    },
                    {
                        cells: [
                            {
                                key: 'name',
                                type: 'value',
                                value: 'Geldkontenbestände gesamt',
                                rowCellClass: '[&&]:tw-pt-2',
                            },
                            {
                                key: 'amount',
                                type: 'value',
                                value: iaE.bankAccountsFinalTotal,
                                rowCellClass: '[&&]:tw-pt-2',
                                pipeToApply: 'eurocent',
                            },
                        ],
                        rowClass: '[&&]:tw-s-head-14-22-bold',
                    },
                ],
            };
            return table;
        }),
        takeUntil(this.unsubscribe$)
    );

    private mapAccountCells(account: IncomeAndExpenditureDtoLedgerAccount): RowData<ColumnKey> {
        return {
            cells: [
                {
                    key: 'name',
                    value: account.name + (account.description ? ', ' + account.description : ''),
                    type: 'value',
                },
                { key: 'amount', value: account.amount, type: 'value', pipeToApply: 'eurocent' },
            ],
            rowClass: '[&&]:hover:tw-bg-scalaraGray-06',
        };
    }

    private mapBankAccountCells(bankAccount: IncomeAndExpenditureDtoBankAccount): RowData<ColumnKey> {
        return {
            cells: [
                { key: 'name', value: bankAccount.accountName, type: 'value' },
                { key: 'amount', type: 'value', value: bankAccount.accountBalance, pipeToApply: 'eurocent' },
            ],
            rowClass: '[&&]:hover:tw-bg-scalaraGray-06',
        };
    }

    public vm$ = combineLatest([this.ledger$, this.tableData$, this.tableDataReasonabilityCheck$]).pipe(
        map(([ledger, tableData, tableDataReasonabilityCheck]) => ({ ledger, tableData, tableDataReasonabilityCheck }))
    );
    public vm: ObservedValueOf<typeof this.vm$> | null = null;
}
