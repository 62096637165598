<app-overlay-container
    [headlineTitle]="headline"
    [sizeIcon]="72"
    [image]="headlineIcon"
    [colorIcon]="colorIcon"
    [containerSize]="containerSize"
    [translateHeadlineTitle]="translateInputs"
>
    <div class="s-head-16-24-bold mb-2r">
        {{ title }}
    </div>

    <div class="s-head-14-22-regular tw-mb-10 tw-whitespace-break-spaces">
        <span>{{ subtitle }}</span>
    </div>

    <div class="mb-5r" [class.mb-7-5r]="vm?.currentLayoutMode === 'desktop'">
        <ng-content></ng-content>
    </div>

    <div class="d-flex flex-direction-column lg-flex-direction-row justify-space-between buttons">
        <app-scalara-button (click)="abort()" variant="outline">{{
            'CONTROLS.BUTTONS.CANCEL' | translate
        }}</app-scalara-button>
        <app-scalara-button
            (click)="onSubmit()"
            class="mb-1r"
            type="button"
            [variant]="submitButtonVariant"
            [disabled]="disableButton"
            >{{ submitButtonLabel | translate }}</app-scalara-button
        >
    </div>
</app-overlay-container>
