import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {Subject, takeUntil} from "rxjs";

@Component({
    selector: 'app-page',
    templateUrl: './page.component.html',
    styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();

    public isLoggedIn = false;

    public constructor(
        private authService: AuthService
    ) { }

    public ngOnInit(): void {
        this.authService.isLoggedIn()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                (isLoggedIn: boolean) => this.isLoggedIn = isLoggedIn
            );
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }
}
