<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<div ibmRow class="mb-6-25r">
    <div ibmCol [columnNumbers]="{ md: 4, lg: 2 }" class="s-head-28-36-bold">
        <app-text-with-tooltip
            class="tw-inline-block"
            [label]="'ENTITIES.ECONOMIC_PLAN.LABEL_ENTITY' | translate"
            [labelClass]="''"
            [tooltipKey]="TooltipKey.tooltipsInAccounting"
            [tooltipInnerHtml]="'PAGES.TOOLTIPS.ECONOMIC_PLAN_OVERVIEW' | translate"
        ></app-text-with-tooltip>
    </div>
    <div ibmCol class="d-flex justify-content-end">
        <app-scalara-button
            *ngIf="isPropertyManager"
            variant="ghost"
            (click)="startEconomicPlanCreation()"
            svgName="24_add.svg"
        >
            {{ 'PAGES.ECONOMIC_PLAN.OVERVIEW.START_CREATION' | translate }}
        </app-scalara-button>
    </div>
</div>

<div ibmRow *ngIf="economicPlans.length === 0 && !isLoading" class="empty d-flex align-items-center py-3-5r px-2-5r">
    <svg-icon src="/assets/icons/24_information.svg"></svg-icon>
    <div class="s-body-14-22-regular ml-0-5r">{{ 'PAGES.ECONOMIC_PLAN.OVERVIEW.EMPTY' | translate }}</div>
</div>

<ng-container *ngIf="economicPlans.length > 0">
    <ng-container *ngFor="let economicPlan of economicPlans; let i = index">
        <app-tooltip
            size="m"
            position="top"
            *ngIf="economicPlan.status === 'ACTIVATING'"
            class="tooltip--white tile__icon expandable-tile-selector__tooltip"
        >
            <div content class="tooltip-box">
                {{ 'PAGES.ECONOMIC_PLAN.OVERVIEW.ACTIVATION_INFO' | translate }}
            </div>
            <div hoverable-item>
                <div ibmRow class="tile tile--shadow tile--not-allowed mb-1-5r">
                    <div class="w-100 py-0-5r pr-1-5r text-right label--purple s-head-14-22-regular">
                        {{ 'PAGES.ECONOMIC_PLAN.OVERVIEW.PLAN_IN_ACTIVATION' | translate }}
                    </div>
                    <div class="tile__header px-2-5r">
                        <app-icon-bubble
                            [size]="72"
                            [color]="'purple'"
                            [image]="'40_document_loading.svg'"
                            class="mr-2r"
                        ></app-icon-bubble>
                        <div class="headline-wrapper_title">
                            <div class="s-gray-03 s-label-12-22-semibold">
                                {{ 'ENTITIES.ECONOMIC_PLAN.LABEL_YEAR' | translate }}
                            </div>
                            <div class="s-head-16-24-bold">
                                {{ economicPlan.startDate | date : 'dd.MM.yyyy' }} –
                                {{ economicPlan.endDate | date : 'dd.MM.yyyy' }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </app-tooltip>
        <div
            ibmRow
            *ngIf="economicPlan.status !== 'ACTIVATING'"
            class="tile tile--shadow mb-1-5r"
            [routerLink]="'/accounting/ledger/' + ledgerId + '/economic-plans/' + economicPlan.id"
            [queryParams]="{ economicPlanType: economicPlan.type }"
        >
            <div
                *ngIf="economicPlan.status === 'DRAFT'"
                class="w-100 py-0-5r pr-1-5r text-right label--blue s-head-14-22-regular"
            >
                {{ 'PAGES.ECONOMIC_PLAN.OVERVIEW.PLAN_IN_CREATION' | translate }}
            </div>
            <div class="tile__header px-2-5r">
                <app-icon-bubble
                    *ngIf="economicPlan.status === 'DRAFT'"
                    [size]="72"
                    [color]="'blue'"
                    [image]="'40_dokument.svg'"
                    class="mr-2r"
                ></app-icon-bubble>
                <app-icon-bubble
                    *ngIf="economicPlan.status === 'OUTDATED'"
                    [size]="72"
                    [color]="'gray'"
                    [image]="'40_document_error.svg'"
                    class="mr-2r"
                ></app-icon-bubble>
                <app-icon-bubble
                    *ngIf="economicPlan.status === 'ACTIVE'"
                    [size]="72"
                    [color]="'green'"
                    [image]="'40_dokument_check.svg'"
                    class="mr-2r"
                ></app-icon-bubble>
                <div class="headline-wrapper_title">
                    <div class="s-gray-03 s-label-12-22-semibold">
                        {{ 'ENTITIES.ECONOMIC_PLAN.LABEL_YEAR' | translate }}
                    </div>
                    <div class="s-head-16-24-bold">
                        {{ economicPlan.startDate | date : 'dd.MM.yyyy' }} –
                        {{ economicPlan.endDate | date : 'dd.MM.yyyy' }}

                        <app-tag *ngIf="economicPlan.status === 'ACTIVE'" class="ml-2r" color="green">
                            {{ 'PAGES.ECONOMIC_PLAN.OVERVIEW.ACTIVE_TAG' | translate }}
                        </app-tag>
                    </div>
                </div>
                <div *ngIf="economicPlan.isAutoFollowUpPlan" class="follow-up d-flex ml-auto align-items-center">
                    <div
                        class="d-flex"
                        [ngClass]="{
                            's-gray-04': economicPlan.status === 'OUTDATED',
                            's-blue-01': economicPlan.status === 'ACTIVE'
                        }"
                    >
                        <svg-icon src="/assets/icons/24_dokument.svg"></svg-icon>
                        <div
                            class="s-label-12-22-semibold ml-0-5r tw-mr-3"
                            [ngClass]="{
                                's-gray-04': economicPlan.status === 'OUTDATED',
                                's-blue-01': economicPlan.status === 'ACTIVE'
                            }"
                        >
                            {{ 'PAGES.ECONOMIC_PLAN.OVERVIEW.LABEL_AUTOMATIC_FOLLOW_UP_PLAN' | translate }}
                        </div>
                    </div>
                    <app-tooltip
                        position="bottom-right-aligned"
                        class="tooltip--white tile__icon expandable-tile-selector__tooltip"
                    >
                        <div content class="tooltip-box tw-w-[450px] tw-px-[48px] tw-py-[32px]">
                            {{ 'PAGES.ECONOMIC_PLAN.OVERVIEW.INFO_AUTOMATIC_FOLLOW_UP_PLAN' | translate }}
                        </div>
                        <div hoverable-item>
                            <svg-icon src="/assets/icons/24_information.svg"></svg-icon>
                        </div>
                    </app-tooltip>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>
<div class="mb-10r"></div>
