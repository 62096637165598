<ibm-loading *ngIf="isInitialLoading" [isActive]="isInitialLoading" [size]="'normal'" [overlay]="isInitialLoading">
</ibm-loading>

<div class="pb-1-5r">
    <div class="s-head-28-36-bold mb-3-625r">
        <app-text-with-tooltip
            class="tw-inline-block"
            [label]="'PAGES.OPEN_ITEMS.HEADLINE' | translate"
            [labelClass]="''"
            [tooltipKey]="TooltipKey.tooltipsInAccounting"
            [tooltipInnerHtml]="'PAGES.TOOLTIPS.OPEN_ITEMS.HEADLINE' | translate"
        ></app-text-with-tooltip>
    </div>

    <ibm-tabs [followFocus]="true" [cacheActive]="true">
        <ibm-tab heading="Forderungen">
            <div class="mt-1-5r mb-2-5r">
                <div class="mb-2-5r d-flex justify-content-end">
                    <app-search [searchInputId]="'Forderungen'"></app-search>
                    <div class="ml-1r mr-1r styled-filter">
                        <ibm-dropdown (selected)="onSelectFilter($event, false)" placeholder="Select">
                            <ibm-dropdown-list [items]="filter"></ibm-dropdown-list>
                        </ibm-dropdown>
                    </div>
                    <app-scalara-button (click)="openBookingOverlay(4, false)" variant="default" svgName="24_add.svg">{{
                        'PAGES.OPEN_ITEMS.ADD_RECEIVABLES_BUTTON' | translate
                    }}</app-scalara-button>
                </div>
                <div
                    *ngIf="selectedFilterReceivablesTable !== 'closed'"
                    class="info-open-items-box p-1r d-flex justify-space-between mb-2-5r"
                >
                    <div class="s-body-14-22-regular">
                        {{ 'PAGES.OPEN_ITEMS.YOU_HAVE' | translate
                        }}<span class="s-head-16-24-bold ml-1r">{{ amountReceivables }}&nbsp;</span
                        ><span>{{ 'PAGES.OPEN_ITEMS.OPEN_RECEIVABLES' | translate }}</span>
                    </div>
                    <div class="s-label-12-22-semibold s-gray-03">
                        {{ 'PAGES.OPEN_ITEMS.OPEN_AMOUNT' | translate }}
                        <span class="s-head-16-24-bold ml-1r s-gray-01">{{ sumReceivables | eurocent }}</span>
                    </div>
                </div>
                <div>
                    <app-table
                        [emptyText]="'ACCOUNTING.OPEN-ITEMS.EMPTY_TABLE'"
                        [header]="receivablesTable.header"
                        [data]="receivablesTable.data"
                        [tableSearchId]="'Forderungen'"
                        (searchResult)="updateFilteredSum($event)"
                        [isLoading]="isLoading"
                    >
                    </app-table>
                </div>
            </div>
        </ibm-tab>

        <ibm-tab heading="Verbindlichkeiten">
            <div class="mt-1-5r mb-2-5r">
                <div class="mb-2-5r d-flex justify-content-end">
                    <app-search [searchInputId]="'Verbindlichkeiten'"></app-search>
                    <div class="ml-1r mr-1r styled-filter">
                        <ibm-dropdown (selected)="onSelectFilter($event, true)" placeholder="Select">
                            <ibm-dropdown-list [items]="filter"></ibm-dropdown-list>
                        </ibm-dropdown>
                    </div>
                    <app-scalara-button (click)="openBookingOverlay(5, true)" variant="default" svgName="24_add.svg">{{
                        'PAGES.OPEN_ITEMS.ADD_LIABILITIES_BUTTON' | translate
                    }}</app-scalara-button>
                </div>
                <div
                    *ngIf="selectedFilterLiabilitiesTable !== 'closed'"
                    class="info-open-items-box p-1r d-flex justify-space-between mb-2-5r"
                >
                    <div class="s-body-14-22-regular">
                        {{ 'PAGES.OPEN_ITEMS.YOU_HAVE' | translate
                        }}<span class="s-head-16-24-bold ml-1r">{{ amountLiabilities }}&nbsp;</span
                        ><span>{{ 'PAGES.OPEN_ITEMS.OPEN_LIABILITIES' | translate }}</span>
                    </div>
                    <div class="s-label-12-22-semibold s-gray-03">
                        {{ 'PAGES.OPEN_ITEMS.OPEN_AMOUNT' | translate }}
                        <span class="s-head-16-24-bold ml-1r s-gray-01">{{ sumLiabilities | eurocent }}</span>
                    </div>
                </div>
                <div>
                    <app-table
                        [emptyText]="'ACCOUNTING.OPEN-ITEMS.EMPTY_TABLE'"
                        [header]="liabilitiesTable.header"
                        [data]="liabilitiesTable.data"
                        [tableSearchId]="'Verbindlichkeiten'"
                        (searchResult)="updateFilteredSum($event)"
                    >
                    </app-table>
                </div>
            </div>
        </ibm-tab>
    </ibm-tabs>
</div>
