<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<div ibmGrid class="bx--grid--full-width">
    <div ibmRow class="mb-10">
        <div ibmCol></div>
        <div ibmCol [columnNumbers]="{ md: 8, lg: 8 }">
            <div ibmRow class="headline-wrapper">
                <div class="mr-06 headline-wrapper__icon">
                    <app-icon-bubble
                        *ngIf="isChoosingDate"
                        [size]="72"
                        [color]="'green'"
                        [image]="'40_document_edit.svg'"
                    ></app-icon-bubble>
                    <app-icon-bubble
                        *ngIf="!isChoosingDate"
                        [size]="72"
                        [color]="'blue'"
                        [image]="'40_information.svg'"
                    ></app-icon-bubble>
                </div>
                <div class="headline-wrapper__title">
                    <div class="s-head-28-36-bold">
                        {{ 'PAGES.ECONOMIC_PLAN.ADD.HEADLINE' | translate }}
                    </div>
                </div>
            </div>
        </div>
        <div ibmCol></div>
    </div>

    <div ibmRow>
        <div ibmCol></div>
        <div ibmCol [columnNumbers]="{ md: 8, lg: 8 }">
            <form (submit)="onSubmit()" (keydown.enter)="$event.preventDefault()" [formGroup]="form">
                <div ibmRow *ngIf="isChoosingDate" class="mb-5r">
                    <div ibmCol>
                        <div class="s-head-16-24-bold mb-2r">
                            {{ 'PAGES.ECONOMIC_PLAN.ADD.CHOOSE_PLAN_TYPE' | translate }}
                        </div>
                        <ibm-radio-group aria-label="radiogroup" orientation="vertical" formControlName="type">
                            <ibm-radio value="STANDARD" [checked]="true">
                                {{ 'PAGES.ECONOMIC_PLAN.ADD.PLAN_TYPE_OPTION_1' | translate }}
                            </ibm-radio>
                            <ibm-radio value="DIFFERING_ADVANCEMENTS">
                                {{ 'PAGES.ECONOMIC_PLAN.ADD.PLAN_TYPE_OPTION_2' | translate }}
                            </ibm-radio>
                        </ibm-radio-group>

                        <div class="mt-2r" *ngIf="form.value.type === 'DIFFERING_ADVANCEMENTS'">
                            <app-notification type="info">
                                {{ 'PAGES.ECONOMIC_PLAN.ADD.INFO_BOX_OPTION_2' | translate }}
                            </app-notification>
                        </div>
                    </div>
                </div>

                <div ibmRow class="pl-1r mb-2r align-items-center">
                    <span class="s-body-14-22-regular mr-1r">
                        {{
                            isChoosingDate
                                ? ('PAGES.ECONOMIC_PLAN.ADD.MAIN_TITLE' | translate)
                                : ('PAGES.ECONOMIC_PLAN.ADD.INFO_EDIT_TITLE' | translate)
                        }}
                    </span>
                    <ng-container *ngIf="isChoosingDate && !customInterval">
                        <span class="mr-1r s-body-14-22-bold"
                            >{{ startDate | date : 'dd.MM.yyyy' }} – {{ endDate | date : 'dd.MM.yyyy' }}</span
                        >
                        <span class="s-body-14-22-regular">{{
                            'ACCOUNTING.ADD_LEDGER_FORM.IS_X_DAYS' | translate : { differDays: this.differDays }
                        }}</span>
                    </ng-container>
                </div>
                <div ibmRow *ngIf="isChoosingDate" class="mb-1-5r s-body-14-20-regular align-items-center pl-1r">
                    <ibm-checkbox (change)="onChangeCheckbox($event)"></ibm-checkbox>
                    {{ 'PAGES.ECONOMIC_PLAN.ADD.LABEL_CHECKBOX' | translate }}
                </div>
                <div ibmRow *ngIf="!isChoosingDate" class="mb-1-5r s-body-14-20-regular align-items-center">
                    <div ibmCol>
                        <div>{{ 'PAGES.ECONOMIC_PLAN.ADD.INFO_EDIT_TEXT' | translate }}</div>
                        <div>{{ 'PAGES.ECONOMIC_PLAN.ADD.INFO_EDIT_TEXT_2' | translate }}</div>
                    </div>
                </div>

                <!-- START DATEPICKER -->
                <div ibmRow *ngIf="customInterval && isChoosingDate" class="mb-1-5r">
                    <div ibmCol class="mb-1-5r">
                        <ibm-date-picker
                            label="{{ 'COMMON.LABEL_START_DATE' | translate }} *"
                            id="start"
                            [value]="[startDate]"
                            [placeholder]="'tt.mm.jjjj'"
                            [size]="'xl'"
                            [language]="'de'"
                            [dateFormat]="'d.m.Y'"
                            [invalid]="!isDateInputValid()"
                            invalidText="{{ 'ACCOUNTING.ERROR.INVALID_DATE' | translate }}"
                            (valueChange)="changeStartDate($event)"
                            formControlName="start"
                        >
                        </ibm-date-picker>
                    </div>
                    <div ibmCol class="mb-1-5r">
                        <ibm-date-picker
                            label="{{ 'COMMON.LABEL_FINISH_DATE' | translate }} *"
                            id="end"
                            [value]="[endDate]"
                            [placeholder]="'tt.mm.jjjj'"
                            [size]="'xl'"
                            [language]="'de'"
                            [dateFormat]="'d.m.Y'"
                            [invalid]="!isDateInputValid()"
                            invalidText="{{ 'ACCOUNTING.ERROR.INVALID_DATE' | translate }}"
                            (valueChange)="changeEndDate($event)"
                            formControlName="end"
                        >
                        </ibm-date-picker>
                    </div>
                </div>
                <!-- END DATEPICKER -->
                <div ibmRow *ngIf="customInterval" class="s-body-14-22-regular">
                    <div ibmCol class="s-body-14-22-regular mb-2-5r">
                        {{ 'ACCOUNTING.ADD_LEDGER_FORM.IS_X_DAYS' | translate : { differDays: this.differDays } }}
                    </div>
                </div>

                <div ibmRow *ngIf="isTrunkYear()">
                    <div ibmCol class="mb-1r">
                        <app-notification
                            type="warning"
                            subtitle="{{ 'ACCOUNTING.ADD_LEDGER_FORM.FISCAL_YEAR_WARNING' | translate }}"
                        >
                        </app-notification>
                    </div>
                </div>
                <div ibmRow *ngIf="isOverlap()">
                    <div ibmCol="mb-1r">
                        <app-notification
                            type="warning"
                            subtitle="{{ 'ENTITIES.ECONOMIC_PLAN.OVERLAP_WARNING' | translate }}"
                        >
                        </app-notification>
                    </div>
                </div>
            </form>
            <div ibmRow></div>
            <!-- START BUTTON ROW -->
            <div ibmRow class="mt-5r mb-5r">
                <div ibmCol>
                    <button *ngIf="!isChoosingDate" [ibmButton]="'tertiary'" type="reset" (click)="abort()">
                        {{ 'CONTROLS.BUTTONS.BACK' | translate }}
                    </button>

                    <button *ngIf="isChoosingDate" [ibmButton]="'tertiary'" type="reset" (click)="abort()">
                        {{ 'CONTROLS.BUTTONS.CANCEL' | translate }}
                    </button>
                </div>

                <div ibmCol class="text-right">
                    <button
                        *ngIf="isChoosingDate"
                        [ibmButton]="'primary'"
                        type="submit"
                        (click)="changeToConfirmation()"
                        [disabled]="customInterval && !isDateInputValid()"
                    >
                        {{ 'CONTROLS.BUTTONS.CONTINUE' | translate }}
                    </button>
                    <button *ngIf="!isChoosingDate" [ibmButton]="'primary'" type="submit" (click)="onSubmit()">
                        {{ 'PAGES.ECONOMIC_PLAN.ADD.BUTTON_CONFIRMATION' | translate }}
                    </button>
                </div>
            </div>
            <!-- END BUTTON ROW -->
        </div>
        <div ibmCol></div>
    </div>
</div>
