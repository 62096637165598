<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<ng-container *ngIf="(currentOccupationId$ | async) === undefined">
    <div
        *ngIf="!isLoading && undistributedAmounts > 0"
        class="mt-3-5r mb-2-5r d-flex s-body-14-22-regular px-3r py-2-5r costs-card"
    >
        <div class="mr-0-75r">Unverteilte Beträge:</div>
        <div class="mr-0-75r">{{ undistributedAmounts || 0 | eurocent }} €</div>

        <!-- <app-tooltip size="m" position="top" class="tooltip--white tile__icon expandable-tile-selector__tooltip">
            <div content class="demo-banner_tooltip-content">
                Es gibt unverteilte Beträge die Sie nicht auf die Nutzungen verteilt haben. Diese Beträge müssen vom
                Eigentümer übernommen werden.
            </div>
        </app-tooltip> -->

        <app-tooltip position="top">
            <div content class="tooltip-content-formated">
                Es gibt unverteilte Beträge die Sie nicht auf die Nutzungen verteilt haben. Diese Beträge müssen vom
                Eigentümer übernommen werden.
            </div>

            <div hoverable-item class="formated-icon">
                <svg-icon src="/assets/icons/24_information.svg"></svg-icon>
            </div>
        </app-tooltip>

        <!-- <div class="d-flex justify-space-between s-body-14-22-regular">
            <div>Gesamtvorauszahlungen</div>
            <div>{{ oes?.totalAdvancementAmount || 0 | eurocent }} €</div>
        </div>

        <hr class="mt-1r mb-1r" />

        <div class="d-flex justify-space-between s-body-14-22-bold">
            <div>Abrechnungsergebnis</div>
            <div>{{ oes?.balanceAmount || 0 | eurocent }} €</div>
        </div> -->
    </div>

    <div class="mt-2-5r mb-1r d-flex justify-content-end">
        <app-search class="mr-0-5r" [searchInputId]="'oesOccupationTableId'"></app-search>
        <app-scalara-button (click)="downloadAllPdfs()" [variant]="'ghost'" type="button" svgName="24_download.svg">
            Alle Herunterladen
        </app-scalara-button>
    </div>

    <app-table
        emptyText="PAGES.OCCUPATION.OVERVIEW.LABEL_EMPTY_TABLE"
        [header]="tableModel.header"
        [data]="tableModel.data"
        tableSearchId="oesOccupationTableId"
        (rowClick)="openOesOccupation($event)"
        (handleFile)="handleFile($event)"
    >
    </app-table>
</ng-container>

<ng-container *ngIf="currentOccupationId$ | async as currentOccupationId"
    ><app-oes-individual-costs-for-occupation
        [occupationId]="currentOccupationId"
        [ledgerId]="ledgerId"
        [oesId]="oesId"
        (showStatementComponent)="openOesOccupation($event)"
        [oesDate]="oesDate"
        [oesActivationDate]="oesActivationDate"
        [oesLengthInDays]="oesLengthInDays"
        [oesStatus]="oesStatus"
        [triggerRefresh]="refresh$.value"
    ></app-oes-individual-costs-for-occupation>
</ng-container>

<ng-template #occupationDetails let-data="data">
    <ng-container *ngIf="getOccupationDescription(data.extraData?.occupation) as occupationInfo">
        <div class="d-flex align-items-center p-0-75r">
            <div class="s-body-14-22-regular ml-0-5r">
                <div class="s-gray-03">{{ occupationInfo.occupationDetail }}</div>
                <div>{{ occupationInfo.occupationNumberAndTenants }}</div>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-container *ngIf="fileToView && showModal">
    <app-file-viewer [fileToView]="fileToView" (closeViewer)="closeModal()"></app-file-viewer>
</ng-container>
