<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<div ibmGrid *ngIf="!isLoading" class="bx--grid--full-width edit-economic-plan">
    <div ibmRow>
        <div ibmCol></div>
        <div
            ibmCol
            [columnNumbers]="{ md: hideDescriptionTextInputs ? 14 : 10, lg: hideDescriptionTextInputs ? 14 : 10 }"
        >
            <div ibmRow>
                <app-headline-with-icon
                    color="blue"
                    image="40_document_edit.svg"
                    size="xl"
                    text="{{ 'PAGES.ANNUAL_STATEMENT.EDIT.HEADLINE' | translate }}"
                ></app-headline-with-icon>
            </div>
        </div>
        <div ibmCol></div>
    </div>
    <div ibmRow *ngIf="!isLoading">
        <div ibmCol></div>
        <div ibmCol [columnNumbers]="{ md: 14, lg: 14 }">
            <form (keydown.enter)="$event.preventDefault()" [formGroup]="form">
                <div [ngClass]="{ 'd-none': !hideDescriptionTextInputs }">
                    <div ibmRow>
                        <div ibmCol>
                            <div ibmRow class="s-head-18-26-bold mt-4r">
                                {{ 'PAGES.ANNUAL_STATEMENT.EDIT.TITLE_1' | translate }}
                            </div>
                        </div>
                    </div>
                    <ng-container *ngFor="let accountGroup of this.accountsList; let i = index">
                        <ng-container *ngIf="accountGroup.length > 0">
                            <div ibmRow class="s-head-16-24-bold mb-2r mt-5r">
                                {{ 'PAGES.ANNUAL_STATEMENT.EDIT.ACCOUNT_GROUP_TYPE_' + i | translate }}
                            </div>
                            <div
                                ibmRow
                                class="account-row account-row__header d-flex align-items-center justify-space-between s-label-12-22-semibold mb-1r"
                            >
                                <div ibmCol [columnNumbers]="{ md: 4, lg: 4 }">
                                    <span>{{ 'ENTITIES.ECONOMIC_PLAN.LABEL_ACCOUNT' | translate }}</span>
                                </div>
                                <div ibmCol [columnNumbers]="{ md: 4, lg: 4 }" class="text-right">
                                    <span>{{
                                        'PAGES.ANNUAL_STATEMENT.EDIT.LABEL_DISTRIBUTION_KEY_WP' | translate
                                    }}</span>
                                </div>
                                <div ibmCol [columnNumbers]="{ md: 4, lg: 4 }">
                                    <span>{{ 'PAGES.ANNUAL_STATEMENT.EDIT.LABEL_DISTRIBUTION_KEY' | translate }}</span>
                                </div>
                                <div ibmCol [columnNumbers]="{ md: 2, lg: 2 }" class="text-right">
                                    <span>{{
                                        'ENTITIES.ANNUAL_STATEMENT.LABEL_DISTRIBUTION_KEY_TOTAL_AMOUNT' | translate
                                    }}</span>
                                </div>
                                <div ibmCol [columnNumbers]="{ md: 2, lg: 2 }" class="text-right">
                                    <span>{{ 'PAGES.ANNUAL_STATEMENT.EDIT.LABEL_TOTAL_AMOUNT' | translate }}</span>
                                </div>
                            </div>
                            <ng-container [formArrayName]="accountGroups[i]">
                                <ng-container
                                    *ngFor="let account of accountGroup; let j = index"
                                    [formGroup]="getAccountForm(accountGroups[i], j)"
                                >
                                    <div
                                        ibmRow
                                        class="account-row account-row__content d-flex align-items-center justify-space-between py-1r"
                                    >
                                        <div ibmCol [columnNumbers]="{ md: 4, lg: 4 }" class="pl-0r">
                                            <span
                                                >{{ accountsList[i][j].accountName
                                                }}{{
                                                    accountsList[i][j].accountDescription
                                                        ? ', ' + accountsList[i][j].accountDescription
                                                        : ''
                                                }}</span
                                            >
                                        </div>
                                        <div ibmCol [columnNumbers]="{ md: 4, lg: 4 }" class="text-right">
                                            {{ accountsList[i][j].distributionKeyFromEconomicPlan.name }}
                                        </div>
                                        <div ibmCol [columnNumbers]="{ md: 4, lg: 4 }">
                                            <app-combo-box
                                                size="md"
                                                id="distributionKey"
                                                [items]="distributionKeysListItems"
                                                formControlName="distributionKey"
                                                (selected)="onSelectDistributionKey($event, i, j)"
                                                placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT' | translate }}"
                                            >
                                                <ibm-dropdown-list></ibm-dropdown-list>
                                            </app-combo-box>
                                        </div>
                                        <div ibmCol [columnNumbers]="{ md: 2, lg: 2 }" class="text-right">
                                            {{
                                                applyEuroPipe(accountsList[i][j].currentDistributionKey.id)
                                                    ? (accountsList[i][j].totalDistribution | eurocent)
                                                    : (accountsList[i][j].totalDistribution
                                                      | number : '1.0-3' : 'de-De')
                                            }}
                                        </div>
                                        <div ibmCol [columnNumbers]="{ md: 2, lg: 2 }" class="text-right">
                                            {{ accountsList[i][j].totalAmount | eurocent }}
                                        </div>
                                    </div>
                                </ng-container>
                                <div ibmRow class="amount-group py-0-75r align-items-center mt-1r">
                                    <div ibmCol [columnNumbers]="{ md: 10, lg: 10 }"></div>
                                    <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }" class="s-head-14-22-bold text-right">
                                        {{ 'ENTITIES.ECONOMIC_PLAN.LABEL_SHARED_AMOUNT' | translate }}
                                    </div>
                                    <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }" class="s-head-14-22-bold text-right">
                                        {{ this.accountGroupsCurrentAmount[i] | eurocent }}
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <div ibmRow class="total-amount pt-2r mb-4r mt-2-5r s-head-16-24-bold text-right">
                        <div ibmCol [columnNumbers]="{ md: 10, lg: 10 }"></div>
                        <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }">
                            {{ 'PAGES.ANNUAL_STATEMENT.EDIT.LABEL_TOTAL_ANNUAL_AMOUNT' | translate }}
                        </div>
                        <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }">{{ totalAnnualAmount | eurocent }}</div>
                    </div>
                </div>

                <div ibmRow [ngClass]="{ 'd-none': hideDescriptionTextInputs === true }">
                    <div ibmCol></div>
                    <div ibmCol [columnNumbers]="{ md: 12, lg: 12 }">
                        <div ibmRow>
                            <div ibmCol>
                                <div class="s-head-16-24-bold mt-4r mb-4r">
                                    {{ 'PAGES.ANNUAL_STATEMENT.EDIT.TITLE_2' | translate }}
                                </div>
                            </div>
                        </div>
                        <div ibmRow>
                            <div ibmCol>
                                <div class="s-head-16-24-bold mb-1-5r">
                                    {{ 'PAGES.ANNUAL_STATEMENT.EDIT.QUESTION_MOVABLE_ASSET' | translate }}
                                </div>
                            </div>
                        </div>

                        <div class="mb-4r">
                            <ibm-label>
                                {{ 'PAGES.ANNUAL_STATEMENT.EDIT.LABEL_MOVABLE_ASSET' | translate }}
                                <textarea
                                    ibmTextArea
                                    aria-label="textarea"
                                    formControlName="movableAssetDescription"
                                    maxlength="255"
                                ></textarea>
                            </ibm-label>
                        </div>

                        <div ibmRow>
                            <div ibmCol>
                                <div class="s-head-16-24-bold mb-1-5r">
                                    {{ 'PAGES.ANNUAL_STATEMENT.EDIT.QUESTION_REAL_STATE' | translate }}
                                </div>
                            </div>
                        </div>

                        <div class="mb-10">
                            <ibm-label>
                                {{ 'PAGES.ANNUAL_STATEMENT.EDIT.LABEL_REAL_STATE' | translate }}
                                <textarea
                                    ibmTextArea
                                    aria-label="textarea"
                                    formControlName="realEstateDescription"
                                    maxlength="255"
                                ></textarea>
                            </ibm-label>
                        </div>
                    </div>
                    <div ibmCol></div>
                </div>

                <!-- START BUTTON ROW -->
                <div ibmRow class="mb-10r">
                    <div ibmCol *ngIf="!hideDescriptionTextInputs" [columnNumbers]="{ md: 2, lg: 2 }"></div>
                    <div
                        ibmCol
                        [columnNumbers]="{
                            md: !hideDescriptionTextInputs ? 3 : 8,
                            lg: !hideDescriptionTextInputs ? 3 : 8
                        }"
                    >
                        <button [ibmButton]="'tertiary'" type="reset" (click)="abort($event)">
                            {{ 'CONTROLS.BUTTONS.CANCEL' | translate }}
                        </button>
                    </div>

                    <div
                        ibmCol
                        class="text-right"
                        [columnNumbers]="{
                            md: !hideDescriptionTextInputs ? 9 : 8,
                            lg: !hideDescriptionTextInputs ? 9 : 8
                        }"
                    >
                        <button
                            [ibmButton]="'ghost'"
                            type="button"
                            class="mr-1r"
                            *ngIf="hideDescriptionTextInputs"
                            (click)="onSubmit(true)"
                        >
                            {{ 'CONTROLS.BUTTONS.SAVE_AND_CLOSE' | translate }}
                        </button>
                        <button
                            [ibmButton]="'primary'"
                            type="button"
                            (click)="hideDescriptionTextInputs ? onSubmit(false) : submitDescriptions(true)"
                        >
                            {{
                                (hideDescriptionTextInputs ? 'CONTROLS.BUTTONS.SAVE_AND_CONTINUE' : 'COMMON.BTN_SAVE')
                                    | translate
                            }}
                        </button>
                    </div>
                    <div ibmCol *ngIf="!hideDescriptionTextInputs" [columnNumbers]="{ md: 2, lg: 2 }"></div>
                </div>
                <!-- END BUTTON ROW -->
            </form>
        </div>
        <div ibmCol></div>
    </div>
</div>
