import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-toggle-switch',
    templateUrl: './toggle-switch.component.html',
    styleUrls: ['./toggle-switch.component.scss'],
})
export class ToggleSwitchComponent implements OnInit {
    @Input()
    public toggleInformation?: ToggleSwitchInformation;
    @Input()
    public leftSelected = true;
    @Input()
    public callBacks?: ToggleSwitchCallbacks;
    public infoText = '';

    public constructor() {}

    public ngOnInit(): void {
        this.infoText = this.leftSelected
            ? this.toggleInformation?.leftToggleInfo ?? ''
            : this.toggleInformation?.rightToggleInfo ?? '';
    }

    public switchA(): void {
        this.leftSelected = true;
        this.infoText = this.toggleInformation?.leftToggleInfo ?? '';
        this.callBacks?.leftCallback();
    }
    public switchB(): void {
        this.leftSelected = false;
        this.infoText = this.toggleInformation?.rightToggleInfo ?? '';
        this.callBacks?.rightCallback();
    }
}

/**
 * The ToggleSwitchCallbacks type in TypeScript defines an object with two properties, leftCallback and
 * rightCallback, which are functions with no parameters and void return type.
 * @property leftCallback - The `leftCallback` property is a function that will be called when the
 * toggle switch is switched to the left position.
 * @property rightCallback - The `rightCallback` property in the `ToggleSwitchCallbacks` type is a
 * function that takes no arguments and returns void. It is a callback function that is intended to be
 * executed when the toggle switch is switched to the right position.
 */
export type ToggleSwitchCallbacks = {
    leftCallback: () => void;
    rightCallback: () => void;
};

/**
 * The ToggleSwitchInformation type in TypeScript defines the structure for a toggle switch component
 * with optional headline and information for both left and right toggle options.
 * @property {string} headline - The `headline` property in the `ToggleSwitchInformation` type is a
 * string that represents the main title or heading for the toggle switch component. It is optional,
 * meaning it may or may not be provided when creating an instance of `ToggleSwitchInformation`.
 * @property {string} leftToggleText - The `leftToggleText` property in the `ToggleSwitchInformation`
 * type represents the text displayed on the left toggle switch button. It is a required property and
 * must be a string value.
 * @property {string} leftToggleInfo - The `leftToggleInfo` property in the `ToggleSwitchInformation`
 * type is an optional string that provides additional information or details about the left toggle
 * option in a toggle switch component. It is not required to be provided, but can be included to give
 * users more context about the left toggle option.
 * @property {string} rightToggleText - The `rightToggleText` property in the `ToggleSwitchInformation`
 * type represents the text displayed on the right toggle switch button in a toggle switch component.
 * @property {string} rightToggleInfo - The `rightToggleInfo` property in the `ToggleSwitchInformation`
 * type is an optional string that provides additional information or details related to the right
 * toggle option in a toggle switch component. It is not required to be provided when creating an
 * instance of `ToggleSwitchInformation`, but can be included if needed
 */
export type ToggleSwitchInformation = {
    headline?: string;
    leftToggleText: string;
    leftToggleInfo?: string;
    rightToggleText: string;
    rightToggleInfo?: string;
};
