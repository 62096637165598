import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil, tap } from 'rxjs';
import {
    ToggleSwitchCallbacks,
    ToggleSwitchInformation,
} from 'src/app/shared/components/toggle-switch/toggle-switch.component';
import { AccountingFilterCustomService } from '../../services/accounting-filter-custom.service';

@Component({
    selector: 'app-account-booking-calculation-selector',
    templateUrl: './account-booking-calculation-selector.component.html',
    styleUrls: ['./account-booking-calculation-selector.component.scss'],
})
export class AccountBookingCalculationSelectorComponent implements OnInit, OnDestroy {
    public toggleSwitchInformation: ToggleSwitchInformation = {
        headline: this.translateService.instant('ACCOUNTING.COMMON.BOOKING_CALCULATION_MODE_HEADLINE'),
        leftToggleText: this.translateService.instant('ACCOUNTING.COMMON.BOOKING_CALCULATION_MODE_DATE'),
        leftToggleInfo: this.translateService.instant('ACCOUNTING.COMMON.BOOKING_CALCULATION_MODE_DATE_INFO'),
        rightToggleText: this.translateService.instant('ACCOUNTING.COMMON.BOOKING_CALCULATION_MODE_EFFECTIVE'),
        rightToggleInfo: this.translateService.instant('ACCOUNTING.COMMON.BOOKING_CALCULATION_MODE_EFFECTIVE_INFO'),
    };
    @Input()
    public toggleSwitchCallbacks?: ToggleSwitchCallbacks;
    public unsubscribe$ = new Subject<void>();
    public leftSelected = true;

    public bookingsCalculationMode$ = this.accountingFilterCustomService.getBookingsCalculationMode$().pipe(
        tap((mode) => {
            if (mode === 'BOOKING_DATE') {
                this.leftSelected = true;
            } else {
                this.leftSelected = false;
            }
        }),
        takeUntil(this.unsubscribe$)
    );

    public constructor(
        public translateService: TranslateService,
        private accountingFilterCustomService: AccountingFilterCustomService
    ) {}

    public ngOnInit(): void {
        this.bookingsCalculationMode$.subscribe();
    }
    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }
}
