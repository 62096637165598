import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastService } from '../../../core/services/toast.service';
import { formControl, formControlHasError, formatDateYYYYMMDDWithoutHours } from '../../../core/utils/common';
import { PersonCountDto } from '../../../generated-sources/base';
import { OverlayChildComponent } from '../../overlay/components/overlay-child/overlay-child.component';

@Component({
    selector: 'app-person-amount-and-time-frame-overlay',
    templateUrl: './person-amount-and-time-frame-overlay.component.html',
    styleUrls: ['./person-amount-and-time-frame-overlay.component.scss'],
})
export class PersonAmountAndTimeFrameOverlayComponent extends OverlayChildComponent implements OnInit {
    public isLoading = false;
    public currentYear = new Date().getFullYear();
    @Input()
    public personAmountWithTimeFrame?: PersonCountDto;
    public form: UntypedFormGroup = new UntypedFormGroup({});
    public headLineTitle = 'COMPONENTS.PERSON_AMOUNT.OVERLAY_HEADLINE_ADD';
    public personDays365 = 0;
    public callback?: () => void = () => {
        return;
    };

    public createForm(): UntypedFormGroup {
        return this.formBuilder.group({
            start: [
                this.personAmountWithTimeFrame?.from ? new Date(this.personAmountWithTimeFrame?.from) : new Date(),
                [Validators.required],
            ],
            personAmount: [this.personAmountWithTimeFrame?.count ?? null, [Validators.required]],
        });
    }

    public constructor(private formBuilder: UntypedFormBuilder, private toastService: ToastService) {
        super();
    }

    public changeStartDate($event: any): void {
        if (this.personAmountWithTimeFrame?.from) {
            this.personAmountWithTimeFrame.from = $event[0];
        }
    }

    public async submit(): Promise<void> {
        const data = {
            from: formatDateYYYYMMDDWithoutHours(this.form.value.start),
            count: Number(this.form.value.personAmount),
        };

        this.isLoading = true;

        if (this.config?.data.personAmountWithTimeFrame && this.personAmountWithTimeFrame) {
            try {
                await this.config?.data.callback({ ...this.personAmountWithTimeFrame, ...data });
                this.toastService.showSuccess('Personenanzahl erfolgreich geändert!');
                this.saveEmitter$.next();
                this.isLoading = false;
            } catch (error) {
                this.toastService.showError((error as HttpErrorResponse).error.message);
                this.isLoading = false;
            }
            return;
        }
        try {
            await this.config?.data.callback(data);
            this.toastService.showSuccess('Personenanzahl erfolgreich hinzugefügt!');
            this.saveEmitter$.next();
            this.isLoading = false;
        } catch (error) {
            this.toastService.showError((error as HttpErrorResponse).error.message);
            this.isLoading = false;
        }
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public ngOnInit(): void {
        this.callback = this.config?.data.callback;
        if (this.config?.data.personAmountWithTimeFrame) {
            this.personAmountWithTimeFrame = this.config?.data.personAmountWithTimeFrame;
            this.headLineTitle = 'COMPONENTS.PERSON_AMOUNT.OVERLAY_HEADLINE_EDIT';
        }
        this.form = this.createForm();
    }
    public isInvalidForm(controlName: string): boolean {
        return formControlHasError(formControl(this.form, controlName), 'required');
    }
}
