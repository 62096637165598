<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<div
    *ngIf="!isLoading && annualStatement?.status === 'DRAFT'"
    class="d-flex tw-justify-between top-banner top-banner--blue py-3-5r px-2-5r"
>
    <div class="tw-flex">
        <svg-icon src="/assets/icons/40_document_edit.svg" class="info-icon mr-1-5r"></svg-icon>
        <div ibmCol [columnNumbers]="{ md: 11, lg: 11 }" class="mr-2r">
            <div class="mt-0-25r mb-1-5r s-head-18-26-bold s-blue-01">
                {{ 'PAGES.ANNUAL_STATEMENT.DETAILS.EDIT_MODE_BANNER_TITLE' | translate }}
            </div>
            <div class="s-body-14-22-regular">
                {{ 'PAGES.ANNUAL_STATEMENT.DETAILS.EDIT_MODE_BANNER_TEXT' | translate }}
            </div>
        </div>
    </div>
    <app-scalara-button
        *ngIf="!isLoading && editMode && isPropertyManager"
        (click)="switchToReviewAnnualStatement()"
        variant="blue"
        class="ml-1r"
        [disabled]="isEditButtonDisabled"
    >
        {{ 'PAGES.ANNUAL_STATEMENT.DETAILS.REVIEW_PLAN' | translate }}
    </app-scalara-button>
</div>
<div
    *ngIf="!isLoading && annualStatement?.status === 'IN_REVIEW'"
    class="tw-flex tw-justify-between top-banner top-banner--orange py-3-5r px-2-5r"
>
    <div class="tw-flex">
        <svg-icon src="/assets/icons/40_document_inspect.svg" class="info-icon mr-1-5r"></svg-icon>
        <div ibmCol [columnNumbers]="{ md: 11, lg: 11 }" class="mr-2r">
            <div class="mt-0-25r mb-1-5r s-head-18-26-bold s-orange-01">
                {{ 'PAGES.ANNUAL_STATEMENT.DETAILS.REVIEW_MODE_BANNER_TITLE' | translate }}
            </div>
            <div class="s-body-14-22-regular">
                {{ 'PAGES.ANNUAL_STATEMENT.DETAILS.REVIEW_MODE_BANNER_TEXT_1' | translate }}
            </div>
            <div class="s-body-14-22-regular">
                {{ 'PAGES.ANNUAL_STATEMENT.DETAILS.REVIEW_MODE_BANNER_TEXT_2' | translate }}
            </div>
        </div>
    </div>

    <app-scalara-button
        (click)="openActivateAnnualStatementOverlay()"
        variant="orange"
        class="ml-1r"
        [disabled]="isEditButtonDisabled"
        [height]="'40px'"
    >
        {{ 'PAGES.ANNUAL_STATEMENT.DETAILS.ACTIVATE_PLAN' | translate }}
    </app-scalara-button>
</div>

<div class="mt-1-5r mb-3-5r tw-flex tw-justify-between">
    <div>
        <ibm-breadcrumb [items]="breadcrumbs" [noTrailingSlash]="true"> </ibm-breadcrumb>
    </div>

    <div class="d-flex tw-items-center">
        <div
            *ngIf="annualStatement?.dataRefreshedAt && annualStatement?.status !== 'ACTIVE'"
            class="tw-mr-2 tw-flex tw-items-center"
        >
            <svg-icon
                *ngIf="annualStatement?.needsRecalculation"
                class="tw-mr-2 icon icon__red"
                src="/assets/icons/24_attention.svg
            "
            ></svg-icon>
            <div
                class="tw-s-label-12-16-regular"
                [ngClass]="{
                    'tw-text-scalaraRed-01': annualStatement?.needsRecalculation,
                    'tw-text-scalaraGray-03': !annualStatement?.needsRecalculation
                }"
            >
                Stand {{ annualStatement?.dataRefreshedAt ?? '' | dateTimeFormat }} Uhr
            </div>
        </div>
        <ng-container *ngIf="annualStatement?.status === 'DRAFT' && isPropertyManager">
            <app-scalara-button *ngIf="!isLoading" class="tw-ml-2" variant="ghost" (click)="updateWss()">
                {{ 'PAGES.ANNUAL_STATEMENT.DETAILS.LABEL_UPDATE' | translate }}
            </app-scalara-button>

            <app-scalara-button
                *ngIf="!isLoading && editMode"
                class="mr-1-5r"
                [variant]="'ghost'"
                type="button"
                (click)="openDeleteAnnualStatementOverlay()"
                svgName="24_delete.svg"
            >
                {{ 'PAGES.ANNUAL_STATEMENT.DETAILS.DELETE_PLAN' | translate }}
            </app-scalara-button>
            <app-scalara-button
                *ngIf="!isLoading && editMode"
                type="button"
                (click)="openEditAnnualStatementOverlay()"
                svgName="24_edit.svg"
                [disabled]="isEditButtonDisabled"
            >
                {{ 'PAGES.ANNUAL_STATEMENT.DETAILS.EDIT_PLAN' | translate }}
            </app-scalara-button>
        </ng-container>
        <ng-container *ngIf="annualStatement?.status === 'IN_REVIEW' && isPropertyManager">
            <app-scalara-button [variant]="'ghost'" type="button" (click)="switchToDraftAnnualStatement()">
                {{ 'PAGES.ANNUAL_STATEMENT.DETAILS.BACK_TO_DRAFT_PLAN' | translate }}
            </app-scalara-button>
        </ng-container>
    </div>
</div>

<div ibmRow class="mb-2-5r" *ngIf="!isLoading && ledgerId && annualStatement?.status === 'DRAFT'">
    <div ibmCol>
        <div class="info-box">
            <div class="mb-07 s-head-16-24-bold">{{ 'PAGES.ANNUAL_STATEMENT.DETAILS.STEPS_TITLE' | translate }}</div>
            <div class="d-flex align-items-center mb-1r">
                <div class="mr-06">
                    <div class="icon-container icon-container--40 icon-container--green">
                        <div class="s-green-01 s-head-18-26-bold">1</div>
                    </div>
                </div>
                <div style="white-space: pre-wrap" class="s-body-14-22-regular">
                    <span>{{ 'PAGES.ANNUAL_STATEMENT.DETAILS.STEPS_1' | translate }}</span>
                </div>
                <div class="d-flex align-items-center ml-1r">
                    <svg-icon
                        class="mr-0-75r icon"
                        [ngClass]="isBalanceOk ? 'icon__green' : 'icon__red'"
                        [src]="
                            isBalanceOk ? '/assets/icons/24_checkbox-check-green.svg' : '/assets/icons/24_attention.svg'
                        "
                    ></svg-icon>
                    <span class="s-label-12-22-semibold" [ngClass]="isBalanceOk ? 's-green-01' : 's-red-01'">
                        {{
                            (isBalanceOk
                                ? 'PAGES.ANNUAL_STATEMENT.DETAILS.STEPS_1_OK'
                                : 'PAGES.ANNUAL_STATEMENT.DETAILS.STEPS_1_WARNING'
                            ) | translate
                        }}
                    </span>
                </div>
            </div>
            <div class="d-flex align-items-center mb-1r">
                <div class="mr-06">
                    <div class="icon-container icon-container--40 icon-container--green">
                        <div class="s-green-01 s-head-18-26-bold">2</div>
                    </div>
                </div>
                <div style="white-space: pre-wrap" class="s-body-14-22-regular">
                    <span>{{ 'PAGES.ANNUAL_STATEMENT.DETAILS.STEPS_2' | translate }}</span>
                </div>
            </div>
            <div class="d-flex align-items-center mb-1r">
                <div class="mr-06">
                    <div class="icon-container icon-container--40 icon-container--green">
                        <div class="s-green-01 s-head-18-26-bold">3</div>
                    </div>
                </div>
                <div class="s-body-14-22-regular">
                    <span>{{ 'PAGES.ANNUAL_STATEMENT.DETAILS.STEPS_3' | translate }}</span>
                    <button
                        (click)="openAddConsumptionDistributionKeyOverlay()"
                        class="s-body-14-22-regular custom-button-link"
                    >
                        {{ 'PAGES.ANNUAL_STATEMENT.DETAILS.STEPS_3_LINK' | translate }}
                    </button>
                </div>
            </div>
            <div class="d-flex align-items-center mb-1r">
                <div class="mr-06">
                    <div class="icon-container icon-container--40 icon-container--green">
                        <div class="s-green-01 s-head-18-26-bold">4</div>
                    </div>
                </div>
                <div class="s-body-14-22-regular">
                    <span>{{ 'PAGES.ANNUAL_STATEMENT.DETAILS.STEPS_4' | translate }}</span>
                    <button
                        (click)="openEditAnnualStatementOverlay()"
                        href=""
                        class="s-body-14-22-regular custom-button-link"
                    >
                        {{ 'PAGES.ANNUAL_STATEMENT.DETAILS.STEPS_4_LINK' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="s-label-12-22-semibold mb-0-5r" *ngIf="!isLoading && ledgerId && annualStatement?.status === 'DRAFT'">
    {{ 'PAGES.ANNUAL_STATEMENT.DETAILS.BALANCE_BOX_LABEL' | translate }}
    {{ annualStatement?.economicPlan?.endDate | date : 'dd.MM.yyyy' }}
</div>
<div ibmRow class="mb-2-5r" *ngIf="annualStatement?.status === 'DRAFT'">
    <div ibmCol>
        <div class="balance-box tile expandable-tile-selector">
            <div class="tile__header" (click)="changeBalanceBoxDisplay()">
                <div ibmCol>
                    <div ibmRow>
                        <div ibmCol [columnNumbers]="{ md: 11, lg: 11 }" class="s-label-12-22-semibold">
                            {{ 'ENTITIES.ANNUAL_STATEMENT.LABEL_TOTAL_AVAILABLE_FUNDS' | translate }}
                        </div>
                        <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }" class="s-label-12-22-semibold p-0 text-right">
                            {{ 'ACCOUNTING.COMMON.AMOUNT' | translate }}
                        </div>
                        <div ibmCol [columnNumbers]="{ md: 2, lg: 2 }" class="p-0 pl-1r d-flex justify-content-center">
                            <div *ngIf="!isBalanceOk">
                                <app-icon-bubble
                                    [size]="40"
                                    [color]="'red'"
                                    [image]="'24_attention.svg'"
                                ></app-icon-bubble>
                            </div>
                            <div *ngIf="isBalanceOk">
                                <app-icon-bubble
                                    [size]="40"
                                    [color]="'green'"
                                    [image]="'24_checkbox-check-green.svg'"
                                ></app-icon-bubble>
                            </div>
                        </div>
                    </div>
                    <div ibmRow>
                        <div ibmCol [columnNumbers]="{ md: 11, lg: 11 }" class="s-head-16-24-bold">
                            {{ 'ACCOUNTING.COMMON.CASH_ACCOUNTS' | translate }}
                        </div>
                        <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }" class="s-head-16-24-bold p-0 text-right">
                            {{ balance?.totalAmountBankAccounts! | eurocent }}
                        </div>
                        <div ibmCol [columnNumbers]="{ md: 2, lg: 2 }"></div>
                    </div>
                </div>
                <div ibmCol>
                    <div ibmRow class="mb-1r">
                        <div ibmCol [columnNumbers]="{ md: 11, lg: 11 }" class="s-label-12-22-semibold">
                            {{ 'ENTITIES.ANNUAL_STATEMENT.LABEL_TOTAL_AVAILABLE_FUNDS' | translate }}
                        </div>
                        <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }" class="s-label-12-22-semibold p-0 text-right">
                            {{ 'ACCOUNTING.COMMON.AMOUNT' | translate }}
                        </div>
                        <div ibmCol [columnNumbers]="{ md: 2, lg: 2 }" class="p-0 pl-1r">
                            <div class="tile__icon" [ngClass]="{ 'rotate-180': isBalanceBoxOpen }">
                                <svg-icon src="/assets/icons/24_arrow-down.svg"></svg-icon>
                            </div>
                        </div>
                    </div>
                    <div ibmRow>
                        <div ibmCol [columnNumbers]="{ md: 11, lg: 11 }" class="s-head-16-24-bold">
                            {{ 'ACCOUNTING.COMMON.ACCOUNTING_ACCOUNTS' | translate }}
                        </div>
                        <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }" class="s-head-16-24-bold p-0 text-right">
                            {{ balance?.totalAmountLedgerAccounts! | eurocent }}
                        </div>
                        <div ibmCol [columnNumbers]="{ md: 2, lg: 2 }"></div>
                    </div>
                </div>
            </div>
            <div *ngIf="isBalanceBoxOpen" class="d-flex tile__body">
                <div ibmCol>
                    <div ibmRow *ngFor="let account of balance?.bankAccounts" class="mb-1r">
                        <div ibmCol [columnNumbers]="{ md: 11, lg: 11 }">
                            {{ account.accountName }}
                        </div>
                        <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }" class="p-0 text-right p-0">
                            {{ account.accountBalance | eurocent }}
                        </div>
                        <div ibmCol [columnNumbers]="{ md: 2, lg: 2 }"></div>
                    </div>
                </div>
                <div ibmCol>
                    <div ibmRow *ngFor="let account of balance?.ledgerAccounts" class="mb-1r">
                        <div ibmCol [columnNumbers]="{ md: 11, lg: 11 }">
                            {{ account.accountName }}
                        </div>
                        <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }" class="p-0 text-right">
                            {{ account.accountBalance | eurocent }}
                        </div>
                        <div ibmCol [columnNumbers]="{ md: 2, lg: 2 }"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="tw-flex tw-justify-between">
    <div ibmRow *ngIf="!isLoading">
        <div ibmCol>
            <div class="s-label-12-22-semibold mb-0-5r">
                {{ 'ENTITIES.ANNUAL_STATEMENT.LABEL_ACCOUNTING_PERIOD' | translate }} /
                {{ 'ENTITIES.ECONOMIC_PLAN.LABEL_YEAR' | translate }}
            </div>
            <div class="s-head-28-36-bold tw-pb-16">
                {{ annualStatementDates }}
            </div>
        </div>
    </div>
</div>

<div class="tw-pt-10">
    <app-annual-statement-tabs
        [wss]="annualStatement"
        [ledgerId]="ledgerId"
        [triggerTabRefreshValue]="refresh"
    ></app-annual-statement-tabs>
</div>
