import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ListItem } from 'carbon-components-angular';
import { BehaviorSubject, Subject, filter, firstValueFrom, of, switchMap, takeUntil, tap } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { TooltipKey } from 'src/app/features/account-settings/services/custom-tooltip.service';
import {
    AccountDto,
    AccountsService,
    BookingsService,
    CreateReceiptDto,
    ReceiptsService,
} from 'src/app/generated-sources/accounting';
import { FileUploaderComponent } from 'src/app/shared/components/file-uploader/file-uploader.component';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';
import { environment } from 'src/environments/environment';
import { LedgerCustomService } from '../../services/ledger-custom.service';

export type AddReceiptConfig = { data: { bookingId?: string } };

@Component({
    selector: 'app-receipts-add-receipt-overlay',
    templateUrl: './receipts-add-receipt-overlay.component.html',
    styleUrls: ['./receipts-add-receipt-overlay.component.scss'],
})
export class ReceiptsAddReceiptOverlayComponent extends OverlayChildComponent implements OnDestroy, OnInit {
    public readonly fileSizeInMb = environment.maxFileSizeInMB; //TO-DO -> move into file-uploader.component
    public isLoading = false;

    public configTyped?: AddReceiptConfig;

    //  if booking id is given we add receipt to the given booking
    //  if no booking -> get accounts list -> user is allowed to choose account -> general receipt is created with selected account
    //  no need accountsList (since user do not allowed to choose account)
    private bookingId$ = new BehaviorSubject<string | null>(null);

    public fileUuids: string[] = [];
    public fileCategories: string[] = [];
    public fileAccounts: string[] = [];
    public fileTypesSelected: string[] = [];

    @ViewChild('fileUpload')
    public fileUploader?: FileUploaderComponent;

    public areFilesFullyUploaded = false;
    private unsubscribe$ = new Subject<void>();
    public accountsList: ListItem[] = [];

    public constructor(
        private toastService: ToastService,
        private receiptsService: ReceiptsService,
        private translateService: TranslateService,
        private accountsService: AccountsService,
        private ledgerCustomService: LedgerCustomService,
        private bookingsService: BookingsService
    ) {
        super();
    }

    public populatedAccountsList$ = this.bookingId$.pipe(
        switchMap((bookingId) => {
            //  if bookingId is given => no need to get accounts and populate list
            if (typeof bookingId === 'string') {
                return of(null);
            }
            return this.ledgerCustomService.getLedgerId$().pipe(
                takeUntil(this.unsubscribe$),
                filter(Boolean),
                switchMap((ledgerId) => this.accountsService.findAll(ledgerId)),
                tap((accounts: AccountDto[]) => {
                    const sortByNameCallback = (a: AccountDto, b: AccountDto): 1 | -1 | 0 => {
                        const nameA = a.name.toLocaleLowerCase();
                        const nameB = b.name.toLocaleLowerCase();
                        return nameA > nameB ? 1 : nameA === nameB ? 0 : -1;
                    };

                    accounts.sort(sortByNameCallback).map((account) => {
                        this.accountsList.push({
                            content: `${account.name}${account.description ? `, ${account.description}` : ''}`,
                            selected: false,
                            value: account.id,
                        });
                    });
                }),
                takeUntil(this.unsubscribe$)
            );
        })
    );

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public ngOnInit(): void {
        this.configTyped = this.config as AddReceiptConfig;
        this.bookingId$.next(this.configTyped.data.bookingId ?? null);

        this.populatedAccountsList$.subscribe({
            next: () => {
                this.isLoading = false;
            },
            error: (error) => {
                if (error) {
                    this.toastService.showError(error.error['message']);
                }
                this.isLoading = false;
            },
        });
    }

    public async onSubmit(): Promise<void> {
        this.isLoading = true;
        const bookingId = await firstValueFrom(this.bookingId$);
        const ledgerId = await firstValueFrom(this.ledgerCustomService.getLedgerId$());

        if (!ledgerId) {
            console.warn('no ledger given');
            return;
        }

        const receipts = this.fileUuids.map((fileUuid, i) => ({
            fileStorageId: fileUuid,
            type: (this.fileTypesSelected[i] as CreateReceiptDto.TypeEnum) || CreateReceiptDto.TypeEnum.Invoice,
            ...(bookingId ? {} : { accountId: this.fileAccounts[i] }),
        }));

        const successMessage = this.translateService.instant('ACCOUNTING.ADD_RECEIPT.MESSAGE_SUCCESS');
        const generalErrorMessage = this.translateService.instant('COMPONENTS.TOAST.TOAST_ERROR');

        if (bookingId) {
            try {
                console.log('adding receipts to booking', { ledgerId: ledgerId, bookingId, receipts });
                await firstValueFrom(this.bookingsService.addReceipts(ledgerId, bookingId, receipts));

                this.toastService.showSuccess(successMessage);
                this.saveEmitter$.next();
            } catch (e: any) {
                this.toastService.showError(generalErrorMessage);
            } finally {
                this.isLoading = false;
            }
            return undefined;
        }

        try {
            console.log('adding receipts to receipts service', { ledgerId: ledgerId, receipts });
            await firstValueFrom(this.receiptsService.createReceipt(ledgerId, receipts));
            this.toastService.showSuccess(successMessage);
            this.saveEmitter$.next();
        } catch (e: any) {
            this.toastService.showError(generalErrorMessage);
        } finally {
            this.isLoading = false;
        }
    }

    public updateLoadingFilesStatus($event: boolean): void {
        this.areFilesFullyUploaded = $event;
    }

    public updateFileIdsLodaded($event: string[]): void {
        this.fileUuids = $event;
    }

    public updateFileType($event: any): void {
        this.fileTypesSelected = $event;
    }

    public updateFileAccount($event: any): void {
        this.fileAccounts = $event;
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public TooltipKey = TooltipKey;
}
