<ibm-loading *ngIf="isInitialLoading" [isActive]="isInitialLoading" [size]="'normal'" [overlay]="isInitialLoading">
</ibm-loading>

<div ibmRow class="mb-10">
    <div ibmCol [columnNumbers]="{ md: 16, lg: 16 }" class="s-head-28-36-bold mb-04">
        <div class="d-flex justify-space-between">
            <span>{{ 'ACCOUNTING.ACCOUNT_CHARTS.HEADLINE' | translate }}</span>
            <div ibmCol class="buttons-container d-flex justify-content-end">
                <app-scalara-button
                    class="mr-05"
                    variant="ghost"
                    (click)="getAccountStatement()"
                    svgName="24_download.svg"
                    [isLoading]="isDownloading"
                    >Kontoauszüge</app-scalara-button
                >
                <app-scalara-button
                    (click)="openOpeningBalanceOverlay()"
                    class="mr-05"
                    variant="ghost"
                    type="button"
                    svgName="24_euro.svg"
                >
                    {{ 'ACCOUNTING.ACCOUNT_CHARTS.ADD_OPENING_BALANCES_BUTTON' | translate }}
                </app-scalara-button>
                <app-scalara-button (click)="openAddReserveOverlay()" variant="ghost" svgName="24_add.svg">
                    {{ 'ACCOUNTING.ACCOUNT_CHARTS.ADD_RESERVE_BUTTON' | translate }}
                </app-scalara-button>
            </div>
        </div>
    </div>
</div>

<div ibmRow class="mb-11" *ngIf="!isInitialLoading && bankAccounts.length === 0">
    <div ibmCol>
        <app-notification type="error">
            <div class="s-body-14-22-regular">
                <span
                    >{{
                        'ACCOUNTING.ACCOUNT_CHARTS.INFO_SYNC_BANK_ACCOUNTS'
                            | translate
                                : {
                                      propertyType:
                                          ledger?.type === 'MV'
                                              ? 'Miethausverwaltung'
                                              : ledger?.type === 'WEG'
                                              ? 'WEG'
                                              : 'Sondereigentumsverwaltung'
                                  }
                    }}
                </span>
                <a href="/user-management/accounts" class="s-gray-01 s-link-14-22-regular">{{
                    'PAGES.BANK_ACCOUNTS.EMPTY_TABLE_LINK_TEXT' | translate
                }}</a>
            </div>
        </app-notification>
    </div>
</div>

<div>
    <app-account-booking-calculation-selector
        [toggleSwitchCallbacks]="toggleSwitchCallbacks"
    ></app-account-booking-calculation-selector>
</div>

<div ibmRow>
    <div ibmCol class="mb-1r d-flex justify-content-end">
        <app-search [searchInputId]="'accountChartsComponentTable1'"></app-search>
        <div class="text-right tw-ml-4">
            <app-accounting-date-picker-selector></app-accounting-date-picker-selector>
        </div>
    </div>
</div>

<div ibmRow class="mb-04" *ngFor="let model of tableModel">
    <div ibmCol>
        <app-text-with-tooltip
            class="tw-block tw-mb-8"
            [label]="model.title"
            [labelClass]="'tw-s-head-18-26-bold'"
            [tooltipKey]="tooltipKey.tooltipsInAccounting"
            [tooltipInnerHtml]="model.titleTooltip"
        ></app-text-with-tooltip>
        <app-table
            [emptyText]="''"
            [itemsPerPage]="1000"
            [header]="model.header"
            [data]="model.data"
            (openAddAccount)="openAddAccount($event)"
            [tableSearchId]="'accountChartsComponentTable1'"
            [isLoading]="!isInitialLoading && isLoading"
        ></app-table>
    </div>
</div>
