<div class="tw-h-full tw-hidden lg:tw-flex side-bar tw-fixed">
    <div class="menu-icons tw-flex tw-flex-col tw-justify-between">
        <div class="tw-flex tw-flex-col">
            <a [routerLink]="['']" class="tw-flex tw-items-center tw-justify-center tw-p-3 tw-mb-2">
                <svg-icon class="logo" src="/assets/icons/Bildmarke.svg"></svg-icon>
            </a>

            <app-tooltip [useDefaultStyles]="false" position="right">
                <div
                    hoverable-item
                    class="tw-p-2 menu-icons__item"
                    [routerLinkActive]="['active-route']"
                    routerLink="properties"
                >
                    <svg-icon src="/assets/icons/navi_40_immobilien.svg"></svg-icon>
                </div>

                <div content>
                    <ng-container *ngTemplateOutlet="contentTooltip; context: { label: 'NAVIGATION.PROPERTIES' }">
                    </ng-container>
                </div>
            </app-tooltip>

            <app-tooltip [useDefaultStyles]="false" position="right">
                <div
                    hoverable-item
                    *ngIf="hasUserAccountingAccess"
                    class="tw-p-2 menu-icons__item"
                    [routerLinkActive]="['active-route']"
                    routerLink="accounting"
                >
                    <svg-icon src="/assets/icons/navi_40_finanzen.svg"></svg-icon>
                </div>

                <div content>
                    <ng-container *ngTemplateOutlet="contentTooltip; context: { label: 'NAVIGATION.FINANCE' }">
                    </ng-container>
                </div>
            </app-tooltip>

            <app-tooltip [useDefaultStyles]="false" position="right">
                <div
                    class="tw-p-2 menu-icons__item"
                    hoverable-item
                    [routerLinkActive]="['active-route']"
                    routerLink="communications"
                >
                    <svg-icon src="/assets/icons/navi_40_kommunikation.svg"></svg-icon>
                </div>

                <div content>
                    <ng-container *ngTemplateOutlet="contentTooltip; context: { label: 'NAVIGATION.COMMUNICATION' }">
                    </ng-container>
                </div>
            </app-tooltip>

            <app-tooltip [useDefaultStyles]="false" position="right">
                <div
                    class="tw-p-2 menu-icons__item"
                    hoverable-item
                    [routerLinkActive]="['active-route']"
                    routerLink="contacts"
                >
                    <svg-icon src="/assets/icons/navi_40_kontakte_kontakte.svg"></svg-icon>
                </div>

                <div content>
                    <ng-container *ngTemplateOutlet="contentTooltip; context: { label: 'NAVIGATION.CONTACTS' }">
                    </ng-container>
                </div>
            </app-tooltip>

            <app-tooltip [useDefaultStyles]="false" position="right">
                <div
                    class="tw-p-2 menu-icons__item"
                    hoverable-item
                    [routerLinkActive]="['active-route']"
                    routerLink="list-generator"
                >
                    <svg-icon src="/assets/icons/navi_40_report.svg"></svg-icon>
                </div>

                <div content>
                    <ng-container *ngTemplateOutlet="contentTooltip; context: { label: 'NAVIGATION.LIST_GENERATOR' }">
                    </ng-container>
                </div>
            </app-tooltip>

            <!-- <app-tooltip [useDefaultStyles]="false" position="right">
                <div
                    class="tw-p-2 menu-icons__item"
                    hoverable-item
                    [routerLinkActive]="['active-route']"
                    (click)="redirectToAppPostBox()"
                >
                    <svg-icon src="/assets/icons/navi_40_postkorb.svg"></svg-icon>
                </div>

                <div content>
                    <ng-container *ngTemplateOutlet="contentTooltip; context: { label: 'NAVIGATION.POST_BOX' }">
                    </ng-container>
                </div>
            </app-tooltip> -->
        </div>

        <div
            appClickOutside
            (clickOutside)="isMenuVisible = false"
            (click)="toggleMenu()"
            class="tw-p-2 menu-icons__profile-item tw-bottom-0 tw-z-50"
        >
            <div *ngIf="personImage">
                <img class="tw-rounded-full tw-h-10 tw-w-10" src="{{ personImage | imageBase64 }}" alt="" />
            </div>
            <div class="profile-icon" *ngIf="!personImage">
                <svg-icon src="/assets/icons/24_person.svg"></svg-icon>
            </div>
        </div>

        <div
            [ngClass]="{ 'show-menu': isMenuVisible }"
            class="burguer-menu tw-left-[60px] tw-bottom-4 tw-absolute tw-hidden"
        >
            <ng-container *ngFor="let item of headerMenuItems; let last = last">
                <div [class]="item.headerItemClassName">
                    <div
                        class="tw-flex tw-px-4 tw-py-3 burguer-menu__item"
                        (click)="item.onClick && item.onClick()"
                        [routerLink]="item.getRoute ? item.getRoute() : []"
                        [routerLinkActive]="!item.onClick ? ['active-route-profile'] : ''"
                    >
                        <svg-icon
                            *ngIf="item.svgType === 'string'"
                            [src]="item.svgSrc"
                            class="burguer-menu__icon"
                        ></svg-icon>
                        <ng-container *ngIf="item.svgType === 'template'">
                            <ng-container *ngTemplateOutlet="item.svgTemplate"></ng-container>
                        </ng-container>
                        <span class="ml-0-75r">{{ item.labelTranslationKey | translate }}</span>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #contentTooltip let-label="label">
    <div
        class="tw-px-4 tw-py-[6px] tw-bg-scalaraGray-01 s-white s-body-14-22-semibold tw-rounded-[10px] tw-rounded-bl-none tw-border-none"
    >
        {{ label | translate }}
    </div>
</ng-template>

<!-- MOBILE -->
<ibm-header
    [brand]="LogoRef"
    class="ibm-header-wrapper lg:tw-hidden"
    [ngClass]="{ 'ibm-header-wrapper--demo': environment.demoEnvironment }"
>
    <ibm-header-navigation>
        <div class="d-flex w-100">
            <div class="active-route-label s-body-14-22-semibold">
                {{ vm?.activeRouteLabel }}
            </div>
            <div class="d-none">
                <ibm-header-item [route]="['properties']" [routerLinkActive]="['active']" [useRouter]="true">
                    {{ 'NAVIGATION.PROPERTIES' | translate }}
                </ibm-header-item>
                <ibm-header-item
                    *ngIf="hasUserAccountingAccess"
                    [route]="['accounting']"
                    [routerLinkActive]="['active']"
                    [useRouter]="true"
                >
                    {{ 'NAVIGATION.FINANCE' | translate }}
                </ibm-header-item>
                <ibm-header-item [route]="['communications']" [routerLinkActive]="['active']" [useRouter]="true">
                    {{ 'NAVIGATION.COMMUNICATION' | translate }}
                </ibm-header-item>
                <ibm-header-item [route]="['contacts']" [routerLinkActive]="['active']" [useRouter]="true">
                    {{ 'NAVIGATION.CONTACTS' | translate }}
                </ibm-header-item>
            </div>
        </div>

        <ibm-header-menu [icon]="MenuIcon" class="burger-menu-items">
            <ng-container *ngFor="let item of headerMenuItems; let last = last">
                <ng-container *ngIf="last">
                    <div class="menu-divider"></div>
                </ng-container>
                <ibm-header-item
                    [route]="item.getRoute ? item.getRoute() : []"
                    [useRouter]="item.getRoute !== undefined"
                    [class]="item.headerItemClassName"
                    (click)="item.onClick && item.onClick()"
                >
                    <!-- render svg-icon or template with svg (some icons exist only as directives -> should be used as
                    templates) -->
                    <ng-container>
                        <svg-icon
                            *ngIf="item.svgType === 'string'"
                            [src]="item.svgSrc"
                            class="header-menu-icon"
                        ></svg-icon>
                        <ng-container *ngIf="item.svgType === 'template'">
                            <ng-container *ngTemplateOutlet="item.svgTemplate"></ng-container>
                        </ng-container>
                        <span class="ml-0-75r">{{ item.labelTranslationKey | translate }}</span>
                    </ng-container>
                </ibm-header-item>
            </ng-container>
            <div class="lg-d-none notification-wrapper">
                <app-notification type="info" direction="column">
                    <div class="s-body-14-22-regular">
                        {{ 'PAGES.PROPERTY.OVERVIEW.INFO_MOBILE' | translate }}
                    </div>
                </app-notification>
            </div>
        </ibm-header-menu>
    </ibm-header-navigation>
</ibm-header>

<ng-template #LogoRef>
    <a [routerLink]="['']" class="logo-wrapper">
        <picture class="logo">
            <source srcset="/assets/icons/logo-mini.svg" media="(max-width: 1055px)" />
            <img src="/assets/icons/logo-white.svg" alt="SCALARA GMBH Logo" height="48px" />
        </picture>
    </a>
</ng-template>

<ng-template #MenuIcon>
    <!-- due to no access to js opened/closed state of menu, we have to use two icons for mobile with proper styles
    depending on aria attributes -->
    <svg-icon
        *ngIf="vm?.currentLayoutMode === 'mobile'"
        src="/assets/icons/24_mobile-burgermenu.svg"
        class="hide-expanded icon-24"
    ></svg-icon>
    <svg-icon
        *ngIf="vm?.currentLayoutMode === 'mobile'"
        src="/assets/icons/24_close.svg"
        class="icon-24 show-expanded"
    ></svg-icon>
</ng-template>
