import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, finalize, forkJoin, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import {
    BookingsService,
    CreateCancellationBookingDto,
    ReceiptDto,
    ReceiptsService,
} from 'src/app/generated-sources/accounting';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';

interface ReceiptFormatedDto extends ReceiptDto {
    disabledToDelete: boolean;
}
@Component({
    selector: 'app-open-item-delete-overlay',
    templateUrl: './open-item-delete-overlay.component.html',
    styleUrls: ['./open-item-delete-overlay.component.scss'],
})
export class OpenItemDeleteOverlayComponent extends OverlayChildComponent implements OnInit {
    public isLoading = false;
    public receipts: ReceiptFormatedDto[] = [];
    public form: UntypedFormGroup = new UntypedFormGroup({});
    public isReceivables = false;
    public bookingId = '';
    public ledgerId = '';
    public toastSuccessMessage = this.translateService.instant('PAGES.OPEN_ITEMS.DELETE_OPEN_ITEM.SUCCESS_TOAST');
    private unsubscribe$ = new Subject<void>();

    public constructor(
        private fb: UntypedFormBuilder,
        private bookingsService: BookingsService,
        private toastService: ToastService,
        private translateService: TranslateService,
        private receiptsService: ReceiptsService,
        private router: Router
    ) {
        super();
    }

    public ngOnInit(): void {
        this.receipts = this.config?.data?.receipts.map((receipt: ReceiptFormatedDto) => {
            if (receipt.bookings && receipt.bookings?.length > 1) {
                receipt.disabledToDelete = true;
            }
            return receipt;
        });

        this.isReceivables = this.config?.data?.isReceivables;
        this.bookingId = this.config?.data?.bookingId;
        this.ledgerId = this.config?.data?.ledgerId;
        this.form = this.createForm();
    }

    public createForm(): UntypedFormGroup {
        const group = this.fb.group({});

        this.receipts.forEach((receipt) => {
            group.addControl(receipt.id, new UntypedFormControl(false));
        });

        return group;
    }

    public submit(): void {
        this.isLoading = true;

        const cancellationDTO: CreateCancellationBookingDto = {
            bookingId: this.bookingId,
            cancellationReason: '',
        };

        const receiptsIdToDelete = Object.keys(this.form.value).filter(
            (item: string) => this.form.value[item] === true
        );

        const requests = [this.bookingsService.createCancellationBooking(this.ledgerId, cancellationDTO)];

        if (receiptsIdToDelete.length > 0) {
            requests.push(this.receiptsService.deleteReceipts(this.ledgerId, receiptsIdToDelete));
        }

        forkJoin(requests)
            .pipe(
                takeUntil(this.unsubscribe$),
                finalize(() => (this.isLoading = false))
            )
            .subscribe({
                next: () => {
                    this.router.navigate([`/accounting/ledger/${this.ledgerId}/open-items`]);
                    this.toastService.showSuccess(this.toastSuccessMessage);
                    this.saveEmitter$.next();
                },
                error: (error) => {
                    if (error) {
                        this.toastService.showError(error.error['message']);
                    }
                },
            });
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }
}
