<app-overlay-container
    headlineTitle="Fehlgeschlagene Migration löschen?"
    [sizeIcon]="72"
    image="24_delete.svg"
    colorIcon="red"
    containerSize="small"
    [translateHeadlineTitle]="false"
>
    <div class="pre-wrap s-head-14-22-regular tw-mb-6">
        <span
            >Wenn Sie den alten und fehlgeschlagenen Import löschen, dann werden zusätzlich folgende Inhalte gelöscht:
            <li>Die Importdatei aus dem Dateispeicher</li>
            <li>Bereits erstellte Immobilien inklusive der Einheiten</li>
            <li>Eigentümer & Mieter</li>
            <li>Das Protokoll des Imports</li>
        </span>
    </div>

    <div class="d-flex align-items-center tw-p-4 s-head-14-22-bold bg-gray">
        <span class="s-body-14-22-regular tw-ml-3 s-gray-01">
            {{ importInfo?.fileName }} vom {{ importInfo?.createdAt | dateTimeFormat }}</span
        >
    </div>

    <div class="d-flex justify-space-between tw-mt-[120px]">
        <app-scalara-button (click)="abort()" variant="outline">
            {{ 'CONTROLS.BUTTONS.CANCEL' | translate }}
        </app-scalara-button>

        <app-scalara-button (click)="submit()" class="mb-1r" type="button" variant="danger">Löschen</app-scalara-button>
    </div>
</app-overlay-container>
