/**
 * SCALARA Accounting Module
 * The SCALARA Accounting API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { AccountBookingDto } from '../model/accountBookingDto';
// @ts-ignore
import { AccountDto } from '../model/accountDto';
// @ts-ignore
import { AccountStatementDto } from '../model/accountStatementDto';
// @ts-ignore
import { AccountsPerHeadingDto } from '../model/accountsPerHeadingDto';
// @ts-ignore
import { DuplicateAccountDto } from '../model/duplicateAccountDto';
// @ts-ignore
import { UpdateAccountDto } from '../model/updateAccountDto';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {
    AccountsServiceInterface
} from './accounts.serviceInterface';



@Injectable({
  providedIn: 'root'
})
export class AccountsService implements AccountsServiceInterface {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Duplicates an account based on a given account with possible adjustment of the description and the apportionable flag
     * @param ledgerId 
     * @param accountId 
     * @param duplicateAccountDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public duplicateAccount(ledgerId: string, accountId: string, duplicateAccountDto: DuplicateAccountDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AccountDto>;
    public duplicateAccount(ledgerId: string, accountId: string, duplicateAccountDto: DuplicateAccountDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AccountDto>>;
    public duplicateAccount(ledgerId: string, accountId: string, duplicateAccountDto: DuplicateAccountDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AccountDto>>;
    public duplicateAccount(ledgerId: string, accountId: string, duplicateAccountDto: DuplicateAccountDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (ledgerId === null || ledgerId === undefined) {
            throw new Error('Required parameter ledgerId was null or undefined when calling duplicateAccount.');
        }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling duplicateAccount.');
        }
        if (duplicateAccountDto === null || duplicateAccountDto === undefined) {
            throw new Error('Required parameter duplicateAccountDto was null or undefined when calling duplicateAccount.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (accountId !== undefined && accountId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>accountId, 'accountId');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/ledgers/${this.configuration.encodeParam({name: "ledgerId", value: ledgerId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/accounts`;
        return this.httpClient.request<AccountDto>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: duplicateAccountDto,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param ledgerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findAll(ledgerId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<AccountDto>>;
    public findAll(ledgerId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<AccountDto>>>;
    public findAll(ledgerId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<AccountDto>>>;
    public findAll(ledgerId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (ledgerId === null || ledgerId === undefined) {
            throw new Error('Required parameter ledgerId was null or undefined when calling findAll.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/ledgers/${this.configuration.encodeParam({name: "ledgerId", value: ledgerId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/accounts`;
        return this.httpClient.request<Array<AccountDto>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param ledgerId 
     * @param id 
     * @param excludeCancelledBookings excluded cancelled and cancellation bookings
     * @param startDate 
     * @param endDate 
     * @param bookingCalculationMode booking calculation mode from EFFECTIVE_DATE,BOOKING_DATE. Defaults to BOOKING_DATE
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findAllForAccount(ledgerId: string, id: string, excludeCancelledBookings: boolean, startDate?: string, endDate?: string, bookingCalculationMode?: 'EFFECTIVE_DATE' | 'BOOKING_DATE', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<AccountBookingDto>>;
    public findAllForAccount(ledgerId: string, id: string, excludeCancelledBookings: boolean, startDate?: string, endDate?: string, bookingCalculationMode?: 'EFFECTIVE_DATE' | 'BOOKING_DATE', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<AccountBookingDto>>>;
    public findAllForAccount(ledgerId: string, id: string, excludeCancelledBookings: boolean, startDate?: string, endDate?: string, bookingCalculationMode?: 'EFFECTIVE_DATE' | 'BOOKING_DATE', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<AccountBookingDto>>>;
    public findAllForAccount(ledgerId: string, id: string, excludeCancelledBookings: boolean, startDate?: string, endDate?: string, bookingCalculationMode?: 'EFFECTIVE_DATE' | 'BOOKING_DATE', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (ledgerId === null || ledgerId === undefined) {
            throw new Error('Required parameter ledgerId was null or undefined when calling findAllForAccount.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling findAllForAccount.');
        }
        if (excludeCancelledBookings === null || excludeCancelledBookings === undefined) {
            throw new Error('Required parameter excludeCancelledBookings was null or undefined when calling findAllForAccount.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (excludeCancelledBookings !== undefined && excludeCancelledBookings !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>excludeCancelledBookings, 'excludeCancelledBookings');
        }
        if (startDate !== undefined && startDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDate, 'startDate');
        }
        if (endDate !== undefined && endDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDate, 'endDate');
        }
        if (bookingCalculationMode !== undefined && bookingCalculationMode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>bookingCalculationMode, 'bookingCalculationMode');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/ledgers/${this.configuration.encodeParam({name: "ledgerId", value: ledgerId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/accounts/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/bookings`;
        return this.httpClient.request<Array<AccountBookingDto>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param ledgerId 
     * @param startDate 
     * @param endDate 
     * @param bookingCalculationMode booking calculation mode from EFFECTIVE_DATE,BOOKING_DATE. Defaults to EFFECTIVE_DATE
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findAllGrouped(ledgerId: string, startDate?: string, endDate?: string, bookingCalculationMode?: 'EFFECTIVE_DATE' | 'BOOKING_DATE', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<AccountsPerHeadingDto>>;
    public findAllGrouped(ledgerId: string, startDate?: string, endDate?: string, bookingCalculationMode?: 'EFFECTIVE_DATE' | 'BOOKING_DATE', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<AccountsPerHeadingDto>>>;
    public findAllGrouped(ledgerId: string, startDate?: string, endDate?: string, bookingCalculationMode?: 'EFFECTIVE_DATE' | 'BOOKING_DATE', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<AccountsPerHeadingDto>>>;
    public findAllGrouped(ledgerId: string, startDate?: string, endDate?: string, bookingCalculationMode?: 'EFFECTIVE_DATE' | 'BOOKING_DATE', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (ledgerId === null || ledgerId === undefined) {
            throw new Error('Required parameter ledgerId was null or undefined when calling findAllGrouped.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (startDate !== undefined && startDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDate, 'startDate');
        }
        if (endDate !== undefined && endDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDate, 'endDate');
        }
        if (bookingCalculationMode !== undefined && bookingCalculationMode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>bookingCalculationMode, 'bookingCalculationMode');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/ledgers/${this.configuration.encodeParam({name: "ledgerId", value: ledgerId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/accounts/accountGroups`;
        return this.httpClient.request<Array<AccountsPerHeadingDto>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param ledgerId 
     * @param id 
     * @param startDate 
     * @param endDate 
     * @param bookingCalculationMode booking calculation mode from EFFECTIVE_DATE,BOOKING_DATE. Defaults to EFFECTIVE_DATE
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findOne(ledgerId: string, id: string, startDate?: string, endDate?: string, bookingCalculationMode?: 'EFFECTIVE_DATE' | 'BOOKING_DATE', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AccountDto>;
    public findOne(ledgerId: string, id: string, startDate?: string, endDate?: string, bookingCalculationMode?: 'EFFECTIVE_DATE' | 'BOOKING_DATE', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AccountDto>>;
    public findOne(ledgerId: string, id: string, startDate?: string, endDate?: string, bookingCalculationMode?: 'EFFECTIVE_DATE' | 'BOOKING_DATE', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AccountDto>>;
    public findOne(ledgerId: string, id: string, startDate?: string, endDate?: string, bookingCalculationMode?: 'EFFECTIVE_DATE' | 'BOOKING_DATE', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (ledgerId === null || ledgerId === undefined) {
            throw new Error('Required parameter ledgerId was null or undefined when calling findOne.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling findOne.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (startDate !== undefined && startDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDate, 'startDate');
        }
        if (endDate !== undefined && endDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDate, 'endDate');
        }
        if (bookingCalculationMode !== undefined && bookingCalculationMode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>bookingCalculationMode, 'bookingCalculationMode');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/ledgers/${this.configuration.encodeParam({name: "ledgerId", value: ledgerId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/accounts/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<AccountDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param ledgerId 
     * @param id 
     * @param startDate 
     * @param endDate 
     * @param bookingCalculationMode booking calculation mode from EFFECTIVE_DATE,BOOKING_DATE. Defaults to BOOKING_DATE
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAccountStatement(ledgerId: string, id: string, startDate?: string, endDate?: string, bookingCalculationMode?: 'EFFECTIVE_DATE' | 'BOOKING_DATE', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<AccountStatementDto>>;
    public getAccountStatement(ledgerId: string, id: string, startDate?: string, endDate?: string, bookingCalculationMode?: 'EFFECTIVE_DATE' | 'BOOKING_DATE', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<AccountStatementDto>>>;
    public getAccountStatement(ledgerId: string, id: string, startDate?: string, endDate?: string, bookingCalculationMode?: 'EFFECTIVE_DATE' | 'BOOKING_DATE', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<AccountStatementDto>>>;
    public getAccountStatement(ledgerId: string, id: string, startDate?: string, endDate?: string, bookingCalculationMode?: 'EFFECTIVE_DATE' | 'BOOKING_DATE', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (ledgerId === null || ledgerId === undefined) {
            throw new Error('Required parameter ledgerId was null or undefined when calling getAccountStatement.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getAccountStatement.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (startDate !== undefined && startDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDate, 'startDate');
        }
        if (endDate !== undefined && endDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDate, 'endDate');
        }
        if (bookingCalculationMode !== undefined && bookingCalculationMode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>bookingCalculationMode, 'bookingCalculationMode');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/ledgers/${this.configuration.encodeParam({name: "ledgerId", value: ledgerId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/accounts/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/account-statement`;
        return this.httpClient.request<Array<AccountStatementDto>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gives back the account statement in PDF
     * @param ledgerId 
     * @param id 
     * @param startDate 
     * @param endDate 
     * @param bookingCalculationMode booking calculation mode from EFFECTIVE_DATE,BOOKING_DATE. Defaults to BOOKING_DATE
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAccountStatementsPdf(ledgerId: string, id: string, startDate: string, endDate: string, bookingCalculationMode?: 'EFFECTIVE_DATE' | 'BOOKING_DATE', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf', context?: HttpContext}): Observable<Blob>;
    public getAccountStatementsPdf(ledgerId: string, id: string, startDate: string, endDate: string, bookingCalculationMode?: 'EFFECTIVE_DATE' | 'BOOKING_DATE', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf', context?: HttpContext}): Observable<HttpResponse<Blob>>;
    public getAccountStatementsPdf(ledgerId: string, id: string, startDate: string, endDate: string, bookingCalculationMode?: 'EFFECTIVE_DATE' | 'BOOKING_DATE', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf', context?: HttpContext}): Observable<HttpEvent<Blob>>;
    public getAccountStatementsPdf(ledgerId: string, id: string, startDate: string, endDate: string, bookingCalculationMode?: 'EFFECTIVE_DATE' | 'BOOKING_DATE', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/pdf', context?: HttpContext}): Observable<any> {
        if (ledgerId === null || ledgerId === undefined) {
            throw new Error('Required parameter ledgerId was null or undefined when calling getAccountStatementsPdf.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getAccountStatementsPdf.');
        }
        if (startDate === null || startDate === undefined) {
            throw new Error('Required parameter startDate was null or undefined when calling getAccountStatementsPdf.');
        }
        if (endDate === null || endDate === undefined) {
            throw new Error('Required parameter endDate was null or undefined when calling getAccountStatementsPdf.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (startDate !== undefined && startDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDate, 'startDate');
        }
        if (endDate !== undefined && endDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDate, 'endDate');
        }
        if (bookingCalculationMode !== undefined && bookingCalculationMode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>bookingCalculationMode, 'bookingCalculationMode');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/pdf'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/ledgers/${this.configuration.encodeParam({name: "ledgerId", value: ledgerId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/accounts/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/account-statement-pdf`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gives back all ledger account statements in PDF
     * @param ledgerId 
     * @param startDate 
     * @param endDate 
     * @param bookingCalculationMode booking calculation mode from EFFECTIVE_DATE,BOOKING_DATE. Defaults to BOOKING_DATE
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllAccountStatementsPdfs(ledgerId: string, startDate: string, endDate: string, bookingCalculationMode?: 'EFFECTIVE_DATE' | 'BOOKING_DATE', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf', context?: HttpContext}): Observable<Blob>;
    public getAllAccountStatementsPdfs(ledgerId: string, startDate: string, endDate: string, bookingCalculationMode?: 'EFFECTIVE_DATE' | 'BOOKING_DATE', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf', context?: HttpContext}): Observable<HttpResponse<Blob>>;
    public getAllAccountStatementsPdfs(ledgerId: string, startDate: string, endDate: string, bookingCalculationMode?: 'EFFECTIVE_DATE' | 'BOOKING_DATE', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf', context?: HttpContext}): Observable<HttpEvent<Blob>>;
    public getAllAccountStatementsPdfs(ledgerId: string, startDate: string, endDate: string, bookingCalculationMode?: 'EFFECTIVE_DATE' | 'BOOKING_DATE', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/pdf', context?: HttpContext}): Observable<any> {
        if (ledgerId === null || ledgerId === undefined) {
            throw new Error('Required parameter ledgerId was null or undefined when calling getAllAccountStatementsPdfs.');
        }
        if (startDate === null || startDate === undefined) {
            throw new Error('Required parameter startDate was null or undefined when calling getAllAccountStatementsPdfs.');
        }
        if (endDate === null || endDate === undefined) {
            throw new Error('Required parameter endDate was null or undefined when calling getAllAccountStatementsPdfs.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (startDate !== undefined && startDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDate, 'startDate');
        }
        if (endDate !== undefined && endDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDate, 'endDate');
        }
        if (bookingCalculationMode !== undefined && bookingCalculationMode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>bookingCalculationMode, 'bookingCalculationMode');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/pdf'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/ledgers/${this.configuration.encodeParam({name: "ledgerId", value: ledgerId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/accounts/all/account-statement-pdf`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates an account based on passed values
     * @param ledgerId 
     * @param id 
     * @param updateAccountDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public update(ledgerId: string, id: string, updateAccountDto: UpdateAccountDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AccountDto>;
    public update(ledgerId: string, id: string, updateAccountDto: UpdateAccountDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AccountDto>>;
    public update(ledgerId: string, id: string, updateAccountDto: UpdateAccountDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AccountDto>>;
    public update(ledgerId: string, id: string, updateAccountDto: UpdateAccountDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (ledgerId === null || ledgerId === undefined) {
            throw new Error('Required parameter ledgerId was null or undefined when calling update.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling update.');
        }
        if (updateAccountDto === null || updateAccountDto === undefined) {
            throw new Error('Required parameter updateAccountDto was null or undefined when calling update.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/ledgers/${this.configuration.encodeParam({name: "ledgerId", value: ledgerId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/accounts/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<AccountDto>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateAccountDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
