import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { BreadcrumbItem } from 'carbon-components-angular';
import { BehaviorSubject, Observable, Subject, combineLatest, map, of, switchMap, takeUntil, tap } from 'rxjs';
import { BreadCrumbService } from 'src/app/core/services/breadcrumb.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { GetStatus, bankAccountTypeName, getStatusTransaction } from 'src/app/core/utils/common';
import {
    BankAccountDto,
    BankTransactionForListDto,
    PaymentDto,
    PaymentsService,
} from 'src/app/generated-sources/accounting';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { CellTemplate } from 'src/app/shared/table/enums/cell-template';
import { HeaderTemplate } from 'src/app/shared/table/enums/header-template';
import { TableItem } from 'src/app/shared/table/interfaces/table-item';
import { TableModel } from 'src/app/shared/table/interfaces/table-model';
import { AddBookingSelectionOverlayComponent } from '../../../bookings/components/add-booking-selection-overlay/add-booking-selection-overlay.component';
import { LedgerCustomService } from '../../../services/ledger-custom.service';
import { BankingTransactionsMarkBookedOverlayComponent } from '../../transactions/banking-transactions-mark-booked-overlay/banking-transactions-mark-booked-overlay.component';
import { BankingPaymentDeleteOverlayComponent } from '../banking-payment-delete-overlay/banking-payment-delete-overlay.component';
import {
    AddPaymentComponentConfig,
    BankingPaymentsAddEditOverlayComponent,
} from '../banking-payments-add-edit-overlay/banking-payments-add-edit-overlay.component';
import { PaymentCustomService } from '../service/payment-custom.service';

@Component({
    selector: 'app-banking-payment-detail',
    templateUrl: './banking-payment-detail.component.html',
    styleUrls: ['./banking-payment-detail.component.scss'],
})
export class BankingPaymentDetailComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();
    public breadcrumbs: BreadcrumbItem[] = [];
    public refresh$ = new BehaviorSubject<void>(undefined);
    public ledgerIdObs = this.ledgerCustomService.getLedgerId$();
    public payment?: PaymentDto;
    public ledgerId = '';
    public routerlink = '';

    public paymentObs = combineLatest([this.ledgerIdObs, this.route.paramMap, this.refresh$]).pipe(
        switchMap(([ledgerId]) => {
            this.ledgerId = ledgerId || '';
            return this.paymentsService.findOne(this.route.snapshot.params['paymentId']);
        }),
        tap((payment) => {
            this.payment = payment;
            if (payment?.bankAccount) {
                this.routerlink = `/accounting/ledger/${this.ledgerId}/payment-approvals/${payment.bankAccount.bic}`;
            }
        }),
        takeUntil(this.unsubscribe$)
    );

    public bankTransactionsTable: Observable<TableModel | null> = this.paymentObs.pipe(
        switchMap((payment) => {
            if (!payment) {
                return of([]);
            }
            return this.paymentsService.findTransactionsForPayment(payment.id);
        }),
        map((transactions) => {
            if (transactions.length === 0) {
                return null;
            }
            return {
                header: [
                    'ACCOUNTING.BANK_TRANSACTION_DETAILS.STATUS',
                    'ACCOUNTING.BANK_TRANSACTION_DETAILS.COUNTERPART_NAME',
                    'ACCOUNTING.BANK_TRANSACTION_DETAILS.PURPOSE',
                    'ACCOUNTING.BANK_TRANSACTION_DETAILS.VALUE_DATE',
                    {
                        data: { key: 'ACCOUNTING.BANK_TRANSACTION_DETAILS.TOTAL_AMOUNT', params: {} },
                        template: HeaderTemplate.RightAligned,
                    },
                    {
                        data: { key: 'ACCOUNTING.BANK_TRANSACTION_DETAILS.OPEN_AMOUNT', params: {} },
                        template: HeaderTemplate.RightAligned,
                    },
                    '',
                    '',
                ],
                data: transactions.map((transaction) => this.createRow(transaction)),
            };
        })
    );

    public constructor(
        private route: ActivatedRoute,
        private paymentsService: PaymentsService,
        private overlayService: OverlayService,
        private breadcrumbService: BreadCrumbService,
        private paymentCustomService: PaymentCustomService,
        private ledgerCustomService: LedgerCustomService,
        private router: Router,
        private toastService: ToastService
    ) {}

    public ngOnInit(): void {
        this.paymentObs.subscribe((payment) => {
            this.breadcrumbService.resetBreadCrumbs();
            const newBreadcrumb = [
                {
                    content: 'Aufträge',
                    route: [`/accounting/ledger/${this.ledgerId}/payments`],
                },
                {
                    content: 'Auftragsdetails',
                    route: [`/accounting/ledger/${this.ledgerId}/payments/${payment.id}`],
                    current: true,
                },
            ];
            this.breadcrumbService.updateBreadCrumbs(newBreadcrumb);
            this.breadcrumbs = this.breadcrumbService.getCurrentBreadCrumbs();
        });

        this.route.queryParamMap
            .pipe(
                switchMap((params: ParamMap) => {
                    const webformIdParam = params.get('webformId');
                    const webformUpdateParam = params.get('update');
                    if (webformUpdateParam && webformIdParam) {
                        return this.paymentsService.updatePaymentsStatus();
                    } else {
                        return of();
                    }
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: () => {
                    this.router.navigate([], {
                        relativeTo: this.route,
                        replaceUrl: true,
                    });
                    this.refresh$.next();
                },
                error: (error) => {
                    this.router.navigate([], {
                        relativeTo: this.route,
                        replaceUrl: true,
                    });
                    this.refresh$.next();
                    this.toastService.showError(error.error['message']);
                },
            });
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public openAddEditPaymentOverlay(
        paymentType: PaymentDto.TypeEnum | 'recurringTransfer' | 'recurringDebit',
        editMode?: boolean,
        paymentId?: string
    ): void {
        const config: AddPaymentComponentConfig = { data: { paymentType, editMode, paymentId } };
        const ref = this.overlayService.open(BankingPaymentsAddEditOverlayComponent, config);
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.refresh$.next());
    }

    public getBankAccountTypeName(bankAccount: BankAccountDto): string {
        return bankAccountTypeName(bankAccount);
    }

    public openDeletePaymentOverlay(): void {
        const ref = this.overlayService.open(BankingPaymentDeleteOverlayComponent, {
            data: { payment: this.payment, parentComponent: 'payments-details' },
        });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe();
    }

    public getRestAmount(amountAssignedToBookings: number, amount: number): number {
        return amount - ((amountAssignedToBookings ?? 0) * Math.abs(amount)) / amount;
    }

    private createRow(transactionItem: BankTransactionForListDto): TableItem[] {
        const statusInfo: GetStatus = getStatusTransaction(
            Math.abs(transactionItem.amount),
            Math.abs(transactionItem.amountAssignedToBookings || 0),
            transactionItem.isFullyBooked
        );

        const isIgnored = statusInfo.status === 'Ignoriert';
        const link = `/accounting/ledger/${this.ledgerId}/bank-accounts/${transactionItem.bankAccountId}/bank-transactions/${transactionItem.id}`;
        const restAmount = this.getRestAmount(transactionItem.amountAssignedToBookings || 0, transactionItem.amount);

        let purpose = transactionItem.purpose?.replace(/[+-]/g, ' ') ?? '';
        purpose = purpose.length >= 150 ? purpose.substring(0, 150) + '...' : purpose;

        return [
            {
                data: {
                    label: statusInfo.status,
                    textColor: statusInfo.color,
                    iconSrc: statusInfo.iconSrc,
                    link,
                    extraData: { bankTransaction: transactionItem, enableCustomSorting: true },
                },
                template: CellTemplate.DynamicIcon,
            },
            {
                data: {
                    label: transactionItem.counterpartName,
                    textColor: isIgnored ? 's-gray-03' : 's-gray-01',
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: purpose,
                    textColor: isIgnored ? 's-gray-03' : 's-gray-01',
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: transactionItem.valueDate,
                    textColor: isIgnored ? 's-gray-03' : 's-gray-01',
                    link,
                },
                template: CellTemplate.Date,
            },
            {
                data: {
                    label: transactionItem.amount,
                    textColor: isIgnored ? 's-gray-03' : 's-gray-01',
                    link,
                },
                template: CellTemplate.EuroCent,
            },
            {
                data: {
                    label: restAmount,
                    textColor: isIgnored ? 's-gray-03' : 's-gray-01',
                    link,
                },
                template: CellTemplate.EuroCent,
            },
            ...[
                statusInfo.status === 'Teilverbucht' || statusInfo.status === 'Verbucht'
                    ? {
                          data: {
                              label: '',
                              link,
                          },
                          template: CellTemplate.Default,
                      }
                    : {
                          data: {
                              label: '',
                              textColor: 's-gray-02',
                              iconSrc: isIgnored ? '/assets/icons/24_add-cycle.svg' : '/assets/icons/24_ignor.svg',
                              extraData: {
                                  showOnlyWithHover: true,
                                  eventName: 'markBankTransaction',
                                  bankTransaction: transactionItem,
                              },
                          },
                          template: CellTemplate.iconButton,
                      },
            ],
            {
                data: {
                    label: restAmount === 0 || transactionItem.amount === 0 || isIgnored ? '' : 'Verbuchen',
                    extraData: transactionItem,
                    rightAligned: true,
                },
                template:
                    restAmount === 0 || transactionItem.amount === 0 || isIgnored
                        ? CellTemplate.Default
                        : CellTemplate.button,
            },
        ];
    }

    public handlingEvent($event: any): void {
        if ($event.extraData && $event.extraData.eventName === 'markBankTransaction') {
            this.openMarkAsBookedOverlay($event);
        } else {
            this.openAddBookingOverlay($event);
        }
    }

    public openMarkAsBookedOverlay($event: any): void {
        const data = {
            bankTransaction: $event.extraData.bankTransaction,
        };
        const ref = this.overlayService.open(BankingTransactionsMarkBookedOverlayComponent, {
            data,
        });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.refresh$.next());
    }

    public openAddBookingOverlay($event: any): void {
        const data = {
            ledgerId: this.ledgerId,
            bankTransactionId: $event.extraData.id,
        };
        const ref = this.overlayService.open(AddBookingSelectionOverlayComponent, {
            data,
        });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.refresh$.next());
    }

    public getStatusIcon(): string {
        switch (this.payment?.paymentStatus) {
            case 'ERROR':
                return '/assets/icons/24_open.svg';

            case 'OPEN':
                return '/assets/icons/24_progress.svg';
            case 'DONE':
                return '/assets/icons/24_closed.svg';
            case 'INCOMPLETE':
                return '/assets/icons/24_attention.svg';
            case 'PROCESSING':
                return '/assets/icons/24_closed.svg';
            case 'EXPORTED':
                return '/assets/icons/24_export.svg';
            default:
                return '/assets/icons/24_progress.svg';
        }
    }
}
