import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ListItem } from 'carbon-components-angular';
import { Subject, switchMap, takeUntil, tap } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import {
    DistributionKeyDto,
    EconomicPlanDetailsAccountDto,
    EconomicPlanDetailsDto,
    EconomicPlansService,
    UpdateEconomicPlanDetailsDto,
} from 'src/app/generated-sources/accounting';
import { OwnershipAdvancementAmountDto } from 'src/app/generated-sources/accounting/model/ownershipAdvancementAmountDto';
import { OwnershipAdvancementAmountsDto } from 'src/app/generated-sources/accounting/model/ownershipAdvancementAmountsDto';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';
import { EurocentPipe } from 'src/app/shared/pipes/eurocent.pipe';

export type AccountsKey = Exclude<keyof EconomicPlanDetailsDto, ['maturity', 'rateinterval']>;

@Component({
    selector: 'app-weg-economic-plan-edit',
    templateUrl: './weg-economic-plan-edit.component.html',
    styleUrls: ['./weg-economic-plan-edit.component.scss'],
})
export class WegEconomicPlanEditComponent extends OverlayChildComponent implements OnInit, OnDestroy {
    public isLoading = false;
    private unsubscribe$ = new Subject<void>();

    public economicPlanId = '';
    public ledgerId = '';

    public planDetails?: EconomicPlanDetailsDto;
    public accountsList: EconomicPlanDetailsAccountDto[][] = [];

    public accountGroups: AccountsKey[] = [
        'revenueAccounts',
        'apportionableExpenseAccounts',
        'expenseAccounts',
        'reserveFundAccounts',
        'individualReserveFundAccounts',
    ];
    public areUncheckedAccountsHidden: boolean[] = [false, false, false, false, false];
    public accountGroupsCurrentAmount: number[] = [];
    public totalAnnualAmount = 0;

    public maturityListItems: ListItem[] = [];
    public rateIntervalListItems: ListItem[] = [];
    public distributionKeysListItems: ListItem[] = [];
    public distributionKeys: DistributionKeyDto[] = [];
    public isDifferingAdvancements?: boolean;
    public showAjustAdvances = false;
    public advancements: OwnershipAdvancementAmountDto[] = [];

    public form: UntypedFormGroup = new UntypedFormGroup({});

    public constructor(
        private toastService: ToastService,
        private economicPlansService: EconomicPlansService,
        private translateService: TranslateService,
        private formBuilder: UntypedFormBuilder,
        private eurocentPipe: EurocentPipe
    ) {
        super();
    }

    public ngOnInit(): void {
        this.isLoading = true;
        this.ledgerId = this.config?.data?.ledgerId;
        this.economicPlanId = this.config?.data?.economicPlanId;

        this.economicPlansService
            .getEconomicPlanDetails(this.ledgerId, this.economicPlanId)
            .pipe(
                tap((planDetails: EconomicPlanDetailsDto) => {
                    this.isDifferingAdvancements = planDetails.type === 'DIFFERING_ADVANCEMENTS';
                    this.planDetails = planDetails;
                    this.populateAccounts(planDetails);
                }),
                switchMap(() => this.economicPlansService.getAllDistributionKeys(this.ledgerId, this.economicPlanId)),
                tap((distributionKeys: DistributionKeyDto[]) => {
                    this.initSelectors(distributionKeys);
                    this.form = this.createForm();
                    this.updateSharedAmounts();
                    this.isLoading = false;
                }),
                switchMap(() => this.form.valueChanges),
                tap(() => {
                    this.updateSharedAmounts();
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }

    public onSubmit(): void {
        this.isLoading = true;
        const createReceiptDto: UpdateEconomicPlanDetailsDto = {
            accounts: this.getAccountsDetails(),
            maturity: this.form.get('maturity')?.value.value,
            rateInterval: this.form.get('rateInterval')?.value.value,
        };

        this.economicPlansService
            .addEconomicPlanDetails(this.ledgerId, this.economicPlanId, createReceiptDto)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: () => {
                    this.isLoading = false;
                    this.toastService.showSuccess(
                        this.translateService.instant('PAGES.ECONOMIC_PLAN.EDIT.TOAST_SUCCESS_MESSAGE')
                    );
                    this.saveEmitter$.next();
                },
                error: (error) => {
                    if (error) {
                        this.toastService.showError(error.error['message']);
                    }
                },
            });
    }

    public submitDifferingAdvancements(ownershipAdvancementAmountsDto: OwnershipAdvancementAmountsDto): void {
        this.isLoading = true;

        this.economicPlansService
            .addDifferingAdvancements(this.ledgerId, this.economicPlanId, ownershipAdvancementAmountsDto)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: () => {
                    this.isLoading = false;
                    this.toastService.showSuccess(
                        this.translateService.instant('PAGES.ECONOMIC_PLAN.EDIT.TOAST_SUCCESS_MESSAGE')
                    );
                    this.saveEmitter$.next();
                },
                error: (error) => {
                    if (error) {
                        this.toastService.showError(error.error['message']);
                    }
                },
            });
    }

    public handleAdjustAdvancesOverlay(): void {
        if (!this.showAjustAdvances === true) {
            this.isLoading = true;
            const createReceiptDto: UpdateEconomicPlanDetailsDto = {
                accounts: this.getAccountsDetails(),
                maturity: this.form.get('maturity')?.value.value,
                rateInterval: this.form.get('rateInterval')?.value.value,
            };

            this.economicPlansService
                .addEconomicPlanDetails(this.ledgerId, this.economicPlanId, createReceiptDto)
                .pipe(
                    switchMap(() =>
                        this.economicPlansService.getOwnershipAdvancementAmounts(this.ledgerId, this.economicPlanId)
                    )
                )
                .subscribe((advancements) => {
                    this.advancements = advancements;
                    this.showAjustAdvances = !this.showAjustAdvances;
                    this.isLoading = false;
                });
        } else {
            this.showAjustAdvances = !this.showAjustAdvances;
        }
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public populateAccounts(planDetails: EconomicPlanDetailsDto): void {
        for (let i = 0; i < this.accountGroups.length; i++) {
            this.accountsList.push(planDetails[this.accountGroups[i]] as EconomicPlanDetailsAccountDto[]);
            let sum = 0;
            this.accountsList[i].map(
                (account: EconomicPlanDetailsAccountDto) =>
                    (sum += account.forecastCurrentYear ? account.forecastCurrentYear : 0)
            );
            this.accountGroupsCurrentAmount.push(sum);
        }
    }

    private initSelectors(distributionKeys: DistributionKeyDto[]): void {
        this.maturityListItems = Object.values(EconomicPlanDetailsDto.MaturityEnum).map((day, index) => {
            const item = {
                content: this.translateService.instant('PAGES.ECONOMIC_PLAN.EDIT.LABEL_DAY_OF_THE_MONTH', {
                    day: day,
                }),
                selected: false,
                value: index + 1,
            };

            if (day === '1.') {
                item.selected = true;
            }
            return item;
        });

        this.rateIntervalListItems = [
            {
                content: this.translateService.instant('PAGES.ECONOMIC_PLAN.EDIT.LABEL_MONTHLY'),
                selected: true,
                value: 'MONTHLY',
            },
            {
                content: this.translateService.instant('PAGES.ECONOMIC_PLAN.EDIT.LABEL_QUARTERLY'),
                selected: false,
                value: 'QUARTERLY',
            },
            {
                content: this.translateService.instant('PAGES.ECONOMIC_PLAN.EDIT.LABEL_SEMI_ANNUAL'),
                selected: false,
                value: 'SEMI_ANNUAL',
            },
            {
                content: this.translateService.instant('PAGES.ECONOMIC_PLAN.EDIT.LABEL_ANNUAL'),
                selected: false,
                value: 'ANNUAL',
            },
        ];

        const items: ListItem[] = [];

        const alreadyUsedDistributionKeys = this.accountsList.flat().map((x) => x.distributionKeyId);
        // should only show where all distributionbaseshares are set for all ownerships (and all keys which have already been used)
        const distributionKeysFiltered = distributionKeys.filter(
            (item) => alreadyUsedDistributionKeys.includes(item.id) || item.totalDistribution
        );

        for (let i = 0; i < distributionKeysFiltered.length; i++) {
            const isSelected = i == 0 ? true : false;
            items.push({
                content: distributionKeysFiltered[i].description,
                selected: isSelected,
                value: distributionKeysFiltered[i].id,
                totalDistribution: distributionKeysFiltered[i].totalDistribution,
                distributionBase: distributionKeysFiltered[i].distributionBase,
            });
        }
        this.distributionKeysListItems = [...items];
    }

    public createForm(): UntypedFormGroup {
        let form: UntypedFormGroup = new UntypedFormGroup({});

        const rateInterval = this.rateIntervalListItems.find(
            (distKey: ListItem) => this.planDetails?.rateInterval === distKey['value']
        );

        const maturity = this.maturityListItems.find((distKey: ListItem) => {
            const maturity: string = this.planDetails?.maturity || '';
            if (maturity === 'FIRST_DAY_OF_THE_MONTH') {
                return 1 === distKey['value'];
            } else if (maturity === 'MID_OF_THE_MONTH') {
                return 15 === distKey['value'];
            }
            return this.planDetails?.maturity === distKey['value'];
        });

        rateInterval!.selected = true;
        maturity!.selected = true;

        form = this.formBuilder.group({
            maturity: this.formBuilder.control(maturity || this.maturityListItems[0]),
            rateInterval: this.formBuilder.control(rateInterval || this.rateIntervalListItems[0]),
            revenueAccounts: this.formBuilder.array([]),
            expenseAccounts: this.formBuilder.array([]),
            apportionableExpenseAccounts: this.formBuilder.array([]),
            reserveFundAccounts: this.formBuilder.array([]),
            individualReserveFundAccounts: this.formBuilder.array([]),
        });

        for (let i = 0; i < this.accountGroups.length; i++) {
            for (let j = 0; j < this.accountsList[i].length; j++) {
                const distItem = this.distributionKeysListItems.find(
                    (distKey: ListItem) => this.accountsList[i][j].distributionKeyId === distKey['value']
                );

                distItem!.selected = true;

                (form.get(this.accountGroups[i]) as UntypedFormArray).push(
                    this.formBuilder.group({
                        enableAccount: this.formBuilder.control({
                            value: this.accountsList[i][j].isRelevant,
                            disabled: false,
                        }),
                        distributionKey: this.formBuilder.control({
                            value: distItem || this.distributionKeysListItems[0],
                            disabled: !this.accountsList[i][j].isRelevant || false,
                        }),
                        amountShare: this.formBuilder.control({
                            value: this.accountsList[i][j].forecastCurrentYear
                                ? this.eurocentPipe.transform(this.accountsList[i][j].forecastCurrentYear as number)
                                : 0,
                            disabled: !this.accountsList[i][j].isRelevant || false,
                        }),
                    })
                );
            }
        }

        return form;
    }

    public getAccountForm(type: string, index: number): UntypedFormGroup {
        return (this.form.controls[type] as UntypedFormArray).at(index) as UntypedFormGroup;
    }

    public onCheckboxChange($event: any, accountGroup: string, i: number): void {
        if ($event.checked) {
            this.getAccountForm(accountGroup, i).get('distributionKey')?.enable();
            this.getAccountForm(accountGroup, i).get('amountShare')?.enable();
        } else {
            this.getAccountForm(accountGroup, i).get('distributionKey')?.disable();
            this.getAccountForm(accountGroup, i).get('amountShare')?.disable();
        }
    }

    public displayCheckboxChange($event: any, index: number): void {
        if ($event.checked) {
            this.areUncheckedAccountsHidden[index] = true;
        } else {
            this.areUncheckedAccountsHidden[index] = false;
        }
    }

    public isAccountHidden(accountGroupIndex: number, accountIndex: number): boolean {
        if (
            this.areUncheckedAccountsHidden[accountGroupIndex] &&
            this.getAccountForm(this.accountGroups[accountGroupIndex], accountIndex).get('enableAccount')?.value ==
                false
        ) {
            return true;
        }
        return false;
    }

    public onSelectDistributionKey($event: any, i: number, j: number): void {
        this.accountsList[i][j].totalDistribution = $event.item.totalDistribution;
        this.accountsList[i][j].distributionBase = $event.item.distributionBase;
    }

    public getAccountsDetails(): EconomicPlanDetailsAccountDto[] {
        const accountsDetails: EconomicPlanDetailsAccountDto[] = [];

        for (let i = 0; i < this.accountsList.length; i++) {
            for (let j = 0; j < this.accountsList[i].length; j++) {
                const accountValues = this.getAccountForm(this.accountGroups[i], j).getRawValue();

                let forecastCurrentYear = 0;
                if (accountValues.enableAccount) {
                    if (accountValues.amountShare && typeof accountValues.amountShare === 'string') {
                        forecastCurrentYear = Number(accountValues.amountShare.replace(/[,.]+/g, ''));
                    } else {
                        this.getAccountForm(this.accountGroups[i], j)
                            .get('amountShare')
                            ?.setValue(this.eurocentPipe.transform(accountValues.amountShare as number));
                        forecastCurrentYear = accountValues.amountShare;
                    }
                }

                accountsDetails.push({
                    isRelevant: accountValues.enableAccount,
                    accountId: this.accountsList[i][j].accountId || '',
                    accountName: this.accountsList[i][j].accountName || '',
                    accountDescription: this.accountsList[i][j].accountDescription,
                    distributionKeyId: accountValues.distributionKey.value,
                    distributionKeyName: accountValues.distributionKey.content,
                    totalDistribution: this.accountsList[i][j].totalDistribution,
                    forecastPreviousYear: accountValues.forecastPreviousYear,
                    forecastCurrentYear: forecastCurrentYear,
                    distributionBase: EconomicPlanDetailsAccountDto.DistributionBaseEnum.Fraction, // Just some value, which is not used later
                });
            }
        }
        return accountsDetails;
    }

    public updateSharedAmounts(): void {
        let totalAmount = 0;
        for (let i = 0; i < this.accountGroups.length; i++) {
            let amountGroup = 0;
            for (let j = 0; j < this.accountsList[i].length; j++) {
                if (this.getAccountForm(this.accountGroups[i], j).get('enableAccount')?.value == true) {
                    const share = this.getAccountForm(this.accountGroups[i], j).get('amountShare')?.value;
                    if (typeof share === 'string') {
                        amountGroup += Number(share.replace(/[,.]+/g, ''));
                    } else {
                        amountGroup += share;
                    }
                }
            }
            this.accountGroupsCurrentAmount[i] = amountGroup;
            if (i === 0) {
                totalAmount -= amountGroup;
            } else {
                totalAmount += amountGroup;
            }
        }
        this.totalAnnualAmount = totalAmount;
    }

    public get costAllocation(): number {
        return (
            -1 * this.accountGroupsCurrentAmount[0] +
            this.accountGroupsCurrentAmount[1] +
            this.accountGroupsCurrentAmount[2]
        );
    }

    public get isWrongCostsAllocation(): boolean {
        return this.costAllocation < 0;
    }
}
