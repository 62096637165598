import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, of, shareReplay, switchMap } from 'rxjs';
import { PropertiesService, Property } from 'src/app/generated-sources/base';

@Injectable({
    providedIn: 'root',
})
export class PropertyCustomService {
    private constructor(private propertiesService: PropertiesService) {
        this.properties$.subscribe();
    }

    private refresh$ = new BehaviorSubject<void>(undefined);

    public getPropertyId$(): Observable<string | null> {
        return this.propertyId$.asObservable();
    }

    public getProperty$(): Observable<Property | null> {
        return this.property$;
    }

    public getProperties$(): Observable<Property[]> {
        return this.properties$;
    }

    public setPropertyId(newPropertyId: string): void {
        this.propertyId$.next(newPropertyId);
    }

    public refresh(): void {
        this.refresh$.next();
    }

    private readonly propertyId$ = new BehaviorSubject<string | null>(null);

    private readonly property$ = combineLatest([this.propertyId$, this.refresh$]).pipe(
        switchMap(([propertyId]) => {
            return propertyId ? this.propertiesService.findOne(propertyId, true) : of(null);
        }),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    private readonly properties$ = this.refresh$.pipe(
        switchMap(() => this.propertiesService.findAll()),
        shareReplay({ bufferSize: 1, refCount: true })
    );
}
