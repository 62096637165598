import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ListItem } from 'carbon-components-angular';
import { Subject, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import {
    calculateDifferDays,
    getLocalISOTime,
    isDateInputOverlappingOtherPlans,
    isTrunkYear,
    isValidBusinessYear,
} from 'src/app/core/utils/common';
import { CreateEconomicPlanDto, EconomicPlanDto, EconomicPlansService } from 'src/app/generated-sources/accounting';
import { FileUploaderComponent } from 'src/app/shared/components/file-uploader/file-uploader.component';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';
import { EconomicPlanCustomService } from '../../../services/economic-plan-custom.service';

@Component({
    selector: 'app-weg-economic-plan-add',
    templateUrl: './weg-economic-plan-add.component.html',
    styleUrls: ['./weg-economic-plan-add.component.scss'],
})
export class WegEconomicPlanAddComponent extends OverlayChildComponent implements OnDestroy, OnInit {
    private unsubscribe$ = new Subject<void>();
    public isLoading = false;

    @ViewChild('fileUpload')
    public fileUploader?: FileUploaderComponent;

    public areFilesFullyUploaded = false;

    public categories: ListItem[] = [];

    public currentYear = new Date().getFullYear();

    public startDate = new Date(this.currentYear, 0, 1);
    public endDate = new Date(this.currentYear, 11, 31);
    public differDays?: number;

    public isChoosingDate = true;
    public customInterval = false;

    public form: UntypedFormGroup = new UntypedFormGroup({});

    public ledgerId = '';
    public economicPlans: EconomicPlanDto[] = [];

    public constructor(
        private toastService: ToastService,
        private economicPlanService: EconomicPlansService,
        private translateService: TranslateService,
        private formBuilder: UntypedFormBuilder,
        private economicPlanCustomService: EconomicPlanCustomService,
        private router: Router
    ) {
        super();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public ngOnInit(): void {
        this.ledgerId = this.config?.data?.ledgerId;
        this.economicPlans = this.config?.data?.economicPlans;

        this.initNonOverlappingDates();

        if (this.isOverlap()) {
            this.startDate = new Date(this.currentYear + 1, 0, 1);
            this.endDate = new Date(this.currentYear + 1, 11, 31);
        }

        this.form = this.createForm();

        this.differDays = calculateDifferDays(this.startDate, this.endDate);

        this.categories = [
            {
                content: this.translateService.instant('ENTITIES.ECONOMIC_PLAN.LABEL_PLAN_TYPE_SINGLE'),
                selected: false,
                value: 'single',
            },
            {
                content: this.translateService.instant('ENTITIES.ECONOMIC_PLAN.LABEL_PLAN_TYPE_TOTAL'),
                selected: false,
                value: 'total',
            },
            {
                content: this.translateService.instant('ENTITIES.ECONOMIC_PLAN.LABEL_PLAN_TYPE_OTHER'),
                selected: true,
                value: 'other',
            },
        ];
    }

    public createForm(): UntypedFormGroup {
        return this.formBuilder.group({
            start: [[this.startDate], [Validators.required]],
            end: [[this.endDate], [Validators.required]],
            type: ['STANDARD', [Validators.required]],
        });
    }

    public onChangeCheckbox($event: any): void {
        if ($event.checked) {
            this.customInterval = true;
        } else {
            this.customInterval = false;
            this.startDate = new Date(this.currentYear + 1, 0, 1);
            this.endDate = new Date(this.currentYear + 1, 11, 31);
            this.initNonOverlappingDates();
        }
    }

    public initNonOverlappingDates(): void {
        let actualYear = this.currentYear;
        while (this.isOverlap()) {
            this.startDate = new Date(actualYear + 1, 0, 1);
            this.endDate = new Date(actualYear + 1, 11, 31);
            actualYear += 1;
        }
    }

    public changeStartDate($event: any): void {
        this.startDate = $event[0];
        this.differDays = calculateDifferDays(this.startDate, this.endDate);
    }

    public changeEndDate($event: any): void {
        this.endDate = $event[0];
        this.differDays = calculateDifferDays(this.startDate, this.endDate);
    }

    public isDateInputValid(): boolean {
        const yearStart = this.form.get('start')?.value[0]?.getFullYear();
        const yearEnd = this.form.get('end')?.value[0]?.getFullYear();

        return yearStart && yearEnd && this.isValidBusinessYear();
    }

    public isValidBusinessYear(): boolean {
        return isValidBusinessYear(this.startDate, this.endDate);
    }

    public isTrunkYear(): boolean {
        return isTrunkYear(this.startDate, this.endDate);
    }

    public changeToConfirmation(): void {
        this.isChoosingDate = false;
    }

    public isOverlap(): boolean {
        if (this.startDate && this.endDate) {
            return isDateInputOverlappingOtherPlans(this.startDate, this.endDate, this.economicPlans);
        } else {
            return false;
        }
    }

    public onSubmit(): void {
        this.isLoading = true;

        const createEconomicPlanDto: CreateEconomicPlanDto = {
            startDate: getLocalISOTime(this.startDate),
            endDate: getLocalISOTime(this.endDate),
            type: this.form.value.type,
        };

        this.economicPlanService
            .createEconomicPlan(this.ledgerId, createEconomicPlanDto)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (economicPlan: EconomicPlanDto) => {
                    this.isLoading = false;
                    this.toastService.showSuccess(
                        this.translateService.instant('PAGES.ECONOMIC_PLAN.ADD.TOAST_SUCCESS_MESSAGE')
                    );
                    this.saveEmitter$.next();

                    this.economicPlanCustomService.refreshEconomicPlanList();

                    const navigationExtras: NavigationExtras = { queryParams: { economicPlanType: economicPlan.type } };

                    this.router.navigate(
                        ['/accounting', 'ledger', this.ledgerId, 'economic-plans', economicPlan.id],
                        navigationExtras
                    );
                },
                error: (error) => {
                    if (error) {
                        this.toastService.showError(error.error['message']);
                    }
                },
            });
    }

    public abort(): void {
        if (this.isChoosingDate) {
            this.cancelEmitter$.next();
        } else {
            this.isChoosingDate = true;
        }
    }
}
