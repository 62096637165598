<ibm-loading *ngIf="isLoading" [isActive]="true" [size]="'normal'" [overlay]="true"></ibm-loading>

<div class="main-container pt-2r pb-10r px-2-5r">
    <h1 class="s-head-28-36-bold">{{ 'PAGES.ABO.BILLING' | translate }}</h1>
    <h2 class="s-head-16-24-bold mt-4r">{{ 'PAGES.ABO.MY_ABO' | translate }}</h2>
    <div
        class="subscriptions-ownerships-container mt-1-5r"
        *ngIf="this.vm"
        [ngClass]="{ 'subscriptions-ownerships-container--with-ownerships': this.showOwnershipInfo() }"
    >
        <div class="s-label-12-22-semibold ownerships-heading" *ngIf="showOwnershipInfo()">
            {{ 'PAGES.ABO.OWNERSHIPS_COUNT' | translate }}
        </div>
        <div class="s-head-28-36-bold ownerships-count" *ngIf="showOwnershipInfo()">
            {{ this.vm.accountMetrics.numberOfOwnerships }}
        </div>
        <div class="possible-ownerships" *ngIf="showPossibleOwnershipsBox()">
            <div *ngIf="vm.possibleOwnershipsToCreate > 0">
                {{ 'PAGES.ABO.OWNERSHIPS_COUNT_MESSAGE' | translate : { value: vm.possibleOwnershipsToCreate } }}
            </div>
            <div *ngIf="vm.possibleOwnershipsToCreate < 1">
                <span>{{
                    (getCurrentAboType(this.vm.currentSubscription) === 'paid'
                        ? 'PAGES.ABO.YOU_HAVE_TO_UPGRADE'
                        : 'PAGES.ABO.YOU_HAVE_TO_SUBSCRIBE'
                    ) | translate
                }}</span>
            </div>
        </div>

        <span class="s-label-12-22-semibold subscriptions-heading">Ihr aktuelles Abo</span>

        <div class="current-subscription">
            <app-subscription-row
                [subscription]="this.vm.currentSubscription"
                [currentSubscription]="this.vm.currentSubscription"
                (subscriptionChanged)="this.handleSubscriptionChanged()"
                [rowType]="'current'"
                *ngIf="this.getCurrentAboType(this.vm.currentSubscription) !== 'none'"
            ></app-subscription-row>
            <div *ngIf="this.getCurrentAboType(this.vm.currentSubscription) === 'none'">
                {{ 'PAGES.ABO.NO_ABO' | translate }}
            </div>
        </div>

        <div class="possible-upgrades" *ngIf="showSuggestedSubscriptions()">
            <app-scalara-accordion
                [headingLabel]="
                    this.translateService.instant(
                        this.getCurrentAboType(this.vm.currentSubscription) === 'none'
                            ? 'PAGES.ABO.POSSIBLE_SUBSCRIPTIONS'
                            : 'PAGES.ABO.POSSIBLE_UPGRADES'
                    )
                "
                [isOpenedByDefault]="this.getCurrentAboType(this.vm.currentSubscription) === 'none' ? true : false"
                [headerLabelClass]="'s-label-12-22-semibold'"
            >
                <div scalara-accordion-content>
                    <div *ngFor="let subscription of this.vm.suggestedSubscriptions; let last = last">
                        <app-subscription-row
                            [subscription]="subscription"
                            [currentSubscription]="this.vm.currentSubscription"
                            (subscriptionChanged)="this.handleSubscriptionChanged()"
                        ></app-subscription-row>
                        <div *ngIf="!last">
                            <hr class="mt-1r mb-1r" />
                        </div>
                    </div>
                    <div *ngIf="vm.suggestedSubscriptions.length < 1">
                        <div class="individual-offer">
                            <span class="icon-container">
                                <svg-icon src="/assets/icons/24_information.svg"></svg-icon>
                            </span>
                            <span class="pl-1r">
                                {{
                                    'PAGES.ABO.INDIVIDUAL_OFFER'
                                        | translate : { value: vm.currentSubscription?.maxOwnerships ?? 0 }
                                }}</span
                            >
                        </div>
                    </div>
                </div>
            </app-scalara-accordion>
        </div>
    </div>

    <div class="mt-5r" *ngIf="this.vm">
        <app-headline-with-icon
            text="Rechnungsinformationen"
            textType="head"
            textClass="headline-with-icon__text"
            color="green"
            image="24_information.svg"
        ></app-headline-with-icon>
        <div class="d-flex justify-space-between">
            <div class="infos-grid mt-1-25r">
                <div class="s-label-12-22-semibold">{{ 'PAGES.ABO.RECIPIENT' | translate }}</div>
                <div class="{{ this.vm.invoiceInformation.invoiceRecipient ? '' : 's-body-14-22-italic' }}">
                    {{ this.vm.invoiceInformation.invoiceRecipient || 'nicht angegeben' }}
                </div>
                <div class="s-label-12-22-semibold">E-Mail für Rechnungen</div>
                <div class="{{ this.vm.invoiceInformation.email ? '' : 's-body-14-22-italic' }}">
                    {{ this.vm.invoiceInformation.email || 'nicht angegeben' }}
                </div>
                <div class="s-label-12-22-semibold">Ust-ID</div>
                <div class="{{ this.vm.invoiceInformation.taxId ? '' : 's-body-14-22-italic' }}">
                    {{ this.vm.invoiceInformation.taxId || 'nicht angegeben' }}
                </div>
            </div>
            <div>
                <app-scalara-button
                    variant="ghost"
                    svgName="24_edit.svg"
                    (click)="changeInvoiceInformationCallback()"
                    >{{ 'CONTROLS.BUTTONS.EDIT' | translate }}</app-scalara-button
                >
            </div>
        </div>
    </div>
    <div class="mt-4r">
        <app-headline-with-icon
            text="Rechnungen"
            textType="head"
            textClass="headline-with-icon__text"
            color="green"
            image="24_dokument.svg"
        ></app-headline-with-icon>
        <app-table
            [emptyText]="'PAGES.ABO.TABLE.EMPTY_TABLE'"
            [header]="this.vm?.tableModel?.header"
            [data]="this.vm?.tableModel?.data"
            (handleFile)="handleFile($event)"
            [isLoading]="isLoading"
        >
        </app-table>
    </div>

    <ng-container *ngIf="this.vm && this.vm.fileViewerData.fileToView && this.vm.fileViewerData.showModal">
        <app-file-viewer
            [fileToView]="this.vm.fileViewerData.fileToView"
            (closeViewer)="closeFileViewerModal()"
        ></app-file-viewer>
    </ng-container>
</div>
