<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<div *ngIf="occupationStatus === 'draft'" class="d-flex py-3-5r px-2-5r info-incomplete-data-box justify-space-between">
    <div class="d-flex">
        <div class="document-icon mr-1-5r">
            <svg-icon src="/assets/icons/40_document_attention.svg"></svg-icon>
        </div>
        <div class="mt-0-5r">
            <div class="s-purple-01 s-head-18-26-bold">Unvollständige Daten</div>
            <div class="mt-1-5r s-body-14-22-regular">
                Sie haben die Daten zu dieser Nutzung noch nicht vollständig eingegeben. Vervollständigen Sie Ihre
                Eingaben in dem Sie auf “Daten vervollständigen” klicken.
            </div>
        </div>
    </div>
    <app-scalara-button [variant]="'purple'" (click)="openEditOverlay()"> Daten vervollständigen </app-scalara-button>
</div>

<div>
    <div class="mt-1-25r mb-3r d-flex justify-space-between">
        <ibm-breadcrumb [items]="breadcrumbs" [noTrailingSlash]="true"> </ibm-breadcrumb>
        <div class="d-flex justify-content-end">
            <app-scalara-button (click)="openDeleteOccupationOverlay()" variant="ghost" svgName="24_delete.svg">
                {{ 'CONTROLS.BUTTONS.REMOVE' | translate }}
            </app-scalara-button>
            <app-scalara-button
                *ngIf="occupationStatus === 'commit' && !occupation?.to"
                (click)="openEditOccupationPeriodOverlay(true)"
                variant="ghost"
                svgName="24_document_error.svg"
            >
                Nutzung beenden
            </app-scalara-button>
        </div>
    </div>

    <div class="card">
        <div
            class="py-0-75r px-2r card-head d-flex justify-content-end"
            [ngClass]="cardTitle === 'Leerstand' ? 'card-head__gray' : 'card-head__green'"
        >
            <svg-icon
                [src]="cardTitle === 'Leerstand' ? '/assets/icons/24_open.svg' : '/assets/icons/24_closed.svg'"
                class="formated-icon mr-0-5r icon-card"
                [ngClass]="cardTitle === 'Leerstand' ? 'icon-card__gray' : 'icon-card__green'"
            ></svg-icon>
            <div [ngClass]="cardTitle === 'Leerstand' ? 's-gray-03' : 's-green-01'" class="s-label-12-22-semibold">
                {{ cardTitle }}
            </div>
        </div>
        <div class="two-column-grid-container">
            <div class="py-2r px-2-5r">
                <div class="d-flex align-items-center">
                    <app-icon-bubble
                        [size]="72"
                        color="green"
                        [image]="'40_document_loading.svg'"
                        class="mr-1-5r"
                    ></app-icon-bubble>
                    <div>
                        <div class="s-label-12-22-semibold s-gray-03 mb-0-5r">Nutzung</div>
                        <div class="s-head-18-26-bold">{{ occupation?.occupationNumber || 'Entwurf' }}</div>
                    </div>
                </div>

                <div class="s-label-12-22-semibold mt-2-5r mb-0-5r">Einheiten</div>

                <ng-container *ngIf="unitsOccupationFrom && unitsOccupationFrom.length > 0">
                    <a
                        [routerLink]="
                            getUnitLink(propertyType && propertyType === 'MV' ? unit.id : unit.ownershipId || '')
                        "
                        class="py-0-5r d-flex unit-item"
                        *ngFor="let unit of unitsOccupationFrom"
                    >
                        <div class="s-body-14-22-semibold mr-1-5r">
                            {{ getTypeUnitName(unit) + ' ' + unit?.name }}
                        </div>
                        <div class="s-body-14-22-regular">
                            {{ unit?.buildingName + ', ' + unit?.location }}
                        </div>
                    </a>
                </ng-container>

                <ng-container *ngIf="occupation">
                    <a
                        [routerLink]="
                            getUnitLink(propertyType && propertyType === 'MV' ? unit.id : unit.ownershipId || '')
                        "
                        class="py-0-5r d-flex unit-item"
                        *ngFor="let unit of occupation?.unitsIncluded"
                    >
                        <div class="s-body-14-22-semibold mr-1-5r">
                            {{ getTypeUnitName(unit) + ' ' + unit?.name }}
                        </div>
                        <div class="s-body-14-22-regular">
                            {{ unit?.buildingName + ', ' + unit?.location }}
                        </div>
                    </a>
                </ng-container>

                <div class="mt-4r d-flex tw-items-center">
                    <div class="mr-2r">
                        <div class="s-label-12-22-semibold mb-0-75r s-gray-03">Zeitraum</div>
                        <ng-container *ngIf="(occupation || occupationForm)?.occupationType === 'RENTING'">
                            <div class="s-label-12-22-semibold mb-0-75r s-gray-03">Mindestlaufzeit</div>
                            <div class="s-label-12-22-semibold mb-0-75r s-gray-03">Automatische Verlängerung</div>
                            <div class="s-label-12-22-semibold mb-0-75r s-gray-03">Kündigungsfrist</div>
                            <div class="s-label-12-22-semibold mb-0-75r s-gray-03">Umsatzsteuerberechtigt</div>
                        </ng-container>
                    </div>
                    <div>
                        <div class="tw-flex tw-justify-between tw-items-center tw-space-x-2 mb-0-75r">
                            <div class="s-body-14-22-regular">
                                {{
                                    ((occupationForm || occupation)?.from || '' | dateFormat) +
                                        ' – ' +
                                        ((occupationForm || occupation)?.to && (occupationForm || occupation)?.to !== ''
                                            ? ((occupationForm || occupation)?.to || '' | dateFormat)
                                            : 'unbefristet')
                                }}

                                <app-tag
                                    *ngIf="isOccupationFinished((occupationForm || occupation)?.to)"
                                    class="ml-0-75r"
                                    >Beendet</app-tag
                                >
                            </div>
                            <app-scalara-button
                                *ngIf="occupationStatus === 'commit'"
                                height="40px"
                                variant="icon-only"
                                svgName="24_edit.svg"
                                (click)="openEditOccupationPeriodOverlay()"
                            ></app-scalara-button>
                        </div>
                        <ng-container *ngIf="(occupation || occupationForm)?.occupationType === 'RENTING'">
                            <div class="s-body-14-22-regular mb-0-75r">
                                {{ (occupationForm || occupation)?.minimumTermInMonth || '–' }}
                            </div>
                            <div class="s-body-14-22-regular mb-0-75r">
                                {{ (occupationForm || occupation)?.automaticExtensionInMonth || '–' }}
                            </div>
                            <div class="s-body-14-22-regular mb-0-75r">
                                {{ (occupationForm || occupation)?.cancellationPeriodInMonth || '–' }}
                            </div>
                            <div class="s-body-14-22-regular mb-0-75r">
                                {{ occupation?.rentDetails?.optedForVAT ? 'Ja' : 'Nein' || '–' }}
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div
                *ngIf="(occupationForm || occupation)?.occupationType !== 'VACANCY'"
                class="second-column py-2r px-2-5r pl-2r"
            >
                <div class="s-label-12-22-semibold s-gray-03 mb-0-75r">Mieter</div>
                <div
                    *ngIf="occupation?.tenants?.length === 0 && personsOccupationFrom?.length === 0"
                    class="s-body-14-22-italic s-gray-03 mb-0-75r"
                >
                    Nicht angegeben
                </div>
                <div
                    [routerLink]="['/contacts', person?.id]"
                    class="d-flex justify-space-between mb-0-75r tw-cursor-pointer"
                    *ngFor="let person of occupation?.tenants || personsOccupationFrom; first as first"
                >
                    <app-headline-with-icon
                        [color]="person?.hasRegisteredUser ? 'green' : 'gray'"
                        image="24_person.svg"
                        [text]="person ? getNameFromPerson(person) : ''"
                        textType="body"
                        textWeight="regular"
                        borderColor="gray"
                        [userAvatar]="person?.imgSmall ?? undefined"
                    ></app-headline-with-icon>
                    <!-- && isLoggedUserPropertyManager && !isOwnProfile && !person?.hasRegisteredUser -->
                    <!-- <app-scalara-button
                        *ngIf="person?.email"
                        class="ml-1r"
                        type="button"
                        (click)="inviteContact(person.id)"
                        height="40px"
                        variant="ghost"
                    >
                        <span *ngIf="!person?.lastInvited"> Einladen </span>
                        <span *ngIf="person?.lastInvited">
                            {{ 'PAGES.CONTACT.DETAIL_VIEW.INVITE_AGAIN' | translate }}
                        </span>
                    </app-scalara-button> -->
                </div>

                <div
                    *ngIf="
                        occupation?.tenants?.length === 0 &&
                        personsOccupationFrom?.length === 0 &&
                        occupationForm?.numberOfPersons === 0
                    "
                    class="s-body-14-22-italic"
                >
                    {{ 'PAGES.OWNERSHIP.DETAIL_VIEW.LABEL_NOT_SPECIFIED' | translate }}
                </div>

                <div class="s-label-12-22-semibold s-gray-03 mb-0-75r mt-2r">Aktuelle Anzahl der Personen</div>
                <div class="s-body-14-22-regular" *ngIf="occupationForm?.numberOfPersons">
                    {{ occupationForm?.numberOfPersons }} ab dem
                    {{ occupationForm?.from | dateFormat }}
                </div>
                <div class="s-body-14-22-regular" *ngIf="occupation && occupation?.personCount">
                    {{ findDateInbetween(occupation.personCount) }}
                </div>
                <div *ngIf="!occupationForm?.numberOfPersons && !occupation?.personCount" class="s-body-14-22-italic">
                    {{ 'PAGES.OWNERSHIP.DETAIL_VIEW.LABEL_NOT_SPECIFIED' | translate }}
                </div>
            </div>
        </div>
    </div>
    <div class="tw-mt-16">
        <app-person-amount-and-time-frame-table
            *ngIf="occupation"
            [personAmountList]="occupation.personCount"
            [personAmountCallbacks]="personAmountCallbacks"
        ></app-person-amount-and-time-frame-table>
    </div>

    <div class="mt-4r" *ngIf="(occupation || occupationForm)?.occupationType !== 'VACANCY' && showRentCard()">
        <div class="d-flex justify-space-between">
            <app-headline-with-icon
                color="green"
                image="24_euro.svg"
                [text]="
                    (occupation || occupationForm)?.occupationType !== 'OWN_OCCUPATION' ? 'Mietzahlungen' : 'Zahlung'
                "
            ></app-headline-with-icon>
            <app-scalara-button
                *ngIf="occupationStatus === 'commit'"
                [variant]="'ghost'"
                type="button"
                svgName="24_edit.svg"
                (click)="openEditRentPaymentOverlay()"
            >
                {{ 'COMMON.EDIT' | translate }}
            </app-scalara-button>
        </div>

        <!-- <div *ngIf="occupationStatus === 'commit'" class="s-body-14-22-italic mt-1-5r">
            {{ 'PAGES.OWNERSHIP.DETAIL_VIEW.LABEL_NOT_SPECIFIED' | translate }}
        </div> -->

        <div class="d-flex mt-1-5r mb-2r" *ngIf="occupation?.rentDetails && occupation?.rentDetails?.dueWorkingDay">
            <div class="s-label-12-22-semibold mb-0-75r s-gray-03 mr-5-25r">Fällig am</div>
            <div class="s-body-14-22-regular mb-0-75r">
                {{
                    (occupationForm?.dueWorkingDay || occupation?.rentDetails?.dueWorkingDay) +
                        '. ' +
                        'Werktag des Monats'
                }}
            </div>
        </div>

        <div class="d-flex mt-1-5r" *ngIf="!occupation?.rentDetails">
            <div>
                <div class="s-label-12-22-semibold mb-0-75r s-gray-03 mr-5-25r">Typ</div>
                <div class="s-label-12-22-semibold mb-0-75r s-gray-03 mr-5-25r">Fällig am</div>
            </div>

            <div>
                <div class="s-body-14-22-italic mb-0-75r">
                    {{ 'PAGES.OWNERSHIP.DETAIL_VIEW.LABEL_NOT_SPECIFIED' | translate }}
                </div>
                <div class="s-body-14-22-italic mb-0-75r">
                    {{ 'PAGES.OWNERSHIP.DETAIL_VIEW.LABEL_NOT_SPECIFIED' | translate }}
                </div>
            </div>
        </div>

        <!--  -->
        <ng-container *ngIf="occupationStatus === 'commit'">
            <div
                *ngFor="let rentInterval of occupation?.rentDetails?.rentIntervals"
                class="mt-1-5r s-body-14-22-regular rent-card p-2r d-flex"
            >
                <div>
                    <div class="s-label-12-22-semibold s-gray-03 mr-3-5r mb-0-75r">Gültig ab</div>
                    <div class="s-body-14-22-regular mr-3-5r">
                        {{ rentInterval.startingDate || '' | dateFormat }}
                    </div>
                </div>

                <div>
                    <div class="s-label-12-22-semibold s-gray-03 mr-3-5r mb-0-75r">
                        {{
                            occupation?.rentDetails?.rentCalculationMethod === 'BLANKET_RENT'
                                ? 'Pauschalmiete'
                                : 'Kaltmiete'
                        }}
                    </div>
                    <div
                        *ngIf="rentInterval.rent && rentInterval.rent > 0; else empty"
                        class="s-body-14-22-regular mr-3-5r"
                    >
                        {{ rentInterval.rent | eurocent }} €
                    </div>
                </div>

                <div
                    *ngIf="
                        ['BASE_RENT_TOTAL_PREPAID', 'BLANKET_RENT_CONSUMPTION'].includes(
                            occupation?.rentDetails?.rentCalculationMethod ?? ''
                        )
                    "
                >
                    <div class="s-label-12-22-semibold s-gray-03 mr-3-5r mb-0-75r">Vorauszahlung</div>

                    <div
                        class="s-body-14-22-regular mr-3-5r"
                        *ngIf="
                            ['BASE_RENT_TOTAL_PREPAID', 'BLANKET_RENT_CONSUMPTION'].includes(
                                occupation?.rentDetails?.rentCalculationMethod ?? ''
                            )
                        "
                    >
                        {{ rentInterval.prepaidSum ?? 0 | eurocent }} €
                    </div>
                </div>

                <div *ngIf="occupation?.rentDetails?.rentCalculationMethod === 'BASE_RENT_OPERATIONAL_COST_HEATING'">
                    <div class="s-label-12-22-semibold s-gray-03 mr-3-5r mb-0-75r">Betriebskosten</div>
                    <div
                        *ngIf="rentInterval.operationalCosts && rentInterval.operationalCosts > 0; else empty"
                        class="s-body-14-22-regular mr-3-5r"
                    >
                        {{ rentInterval.operationalCosts | eurocent }} €
                    </div>
                </div>

                <div *ngIf="occupation?.rentDetails?.rentCalculationMethod === 'BASE_RENT_OPERATIONAL_COST_HEATING'">
                    <div class="s-label-12-22-semibold s-gray-03 mr-3-5r mb-0-75r">Heizkosten</div>
                    <div
                        *ngIf="rentInterval.heatingCosts && rentInterval.heatingCosts > 0; else empty"
                        class="s-body-14-22-regular mr-3-5r"
                    >
                        {{ rentInterval.heatingCosts | eurocent }} €
                    </div>
                </div>

                <div>
                    <div class="s-label-12-22-semibold s-gray-03 mr-3-5r mb-0-75r">Gesamt</div>
                    <div class="s-body-14-22-bold mr-3-5r">{{ getPrepaidSum(rentInterval) | eurocent }} €</div>
                </div>
                <div *ngIf="occupation?.rentDetails?.percentageTaxIncluded">
                    <div class="s-label-12-22-semibold s-gray-03 mr-3-5r mb-0-75r">
                        {{ 'ACCOUNTING.COMMON.TAX_SHARE' | translate }}
                    </div>
                    <div class="s-body-14-22-regular mr-3-5r">
                        <span>{{ calculateTaxShareAmount(rentInterval) | eurocent }}€ </span>({{
                            occupation?.rentDetails?.percentageTaxIncluded | number
                        }}%)
                    </div>
                </div>
            </div>
        </ng-container>
    </div>

    <div
        class="mt-4r d-flex justify-space-between"
        *ngIf="(occupation || occupationForm)?.occupationType === 'RENTING'"
    >
        <div class="tw-w-full">
            <app-headline-with-icon
                color="green"
                image="24_information.svg"
                text="Details zum Mietverhältnis"
            ></app-headline-with-icon>

            <div class="tw-container tw-mx-auto tw-mt-8">
                <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-[auto,1fr]">
                    <div class="">
                        <div
                            class="tw-space-y-3 tw-mb-6 tw-pb-6 tw-border-b-[1px] tw-border-solid tw-border-scalaraGray-05"
                        >
                            <div class="s-label-12-22-semibold s-gray-03">Kautionstyp</div>
                            <ng-container *ngIf="occupation?.rentDetails?.depositType !== 'NONE'">
                                <div class="s-label-12-22-semibold s-gray-03">Kautionsbetrag</div>
                                <div class="s-label-12-22-semibold s-gray-03">Fällig am</div>
                            </ng-container>
                        </div>

                        <div
                            class="tw-space-y-3 tw-mb-6 tw-pb-6 tw-border-b-[1px] tw-border-solid tw-border-scalaraGray-05"
                        >
                            <div class="s-label-12-22-semibold s-gray-03">Kleinstreparatur Einzelbetrag</div>
                            <div class="s-label-12-22-semibold s-gray-03">Kleinstreparatur Jahresgesamtbetrag</div>
                        </div>

                        <div class="s-label-12-22-semibold s-gray-03">Sonstiges</div>
                    </div>

                    <div>
                        <div
                            class="tw-space-y-3 tw-mb-6 tw-pb-6 tw-border-b-[1px] tw-border-solid tw-border-scalaraGray-05"
                        >
                            <div *ngIf="occupation?.rentDetails?.depositType" class="s-body-14-22-regular tw-pl-10">
                                {{ getDepositTypeLabel(occupation?.rentDetails?.depositType!) }}
                            </div>
                            <ng-container *ngIf="occupation?.rentDetails?.depositType !== 'NONE'">
                                <div *ngIf="!occupation?.rentDetails?.depositType" class="s-body-14-22-italic tw-pl-10">
                                    {{ 'PAGES.OWNERSHIP.DETAIL_VIEW.LABEL_NOT_SPECIFIED' | translate }}
                                </div>

                                <div
                                    *ngIf="occupation?.rentDetails?.depositAmount"
                                    class="s-body-14-22-regular tw-pl-10"
                                >
                                    {{ occupation?.rentDetails?.depositAmount || 0 | eurocent }} €
                                </div>

                                <div
                                    *ngIf="!occupation?.rentDetails?.depositAmount"
                                    class="s-body-14-22-italic tw-pl-10"
                                >
                                    {{ 'PAGES.OWNERSHIP.DETAIL_VIEW.LABEL_NOT_SPECIFIED' | translate }}
                                </div>

                                <div
                                    *ngIf="occupation?.rentDetails?.depositDueDate"
                                    class="s-body-14-22-regular tw-pl-10"
                                >
                                    {{ occupation?.rentDetails?.depositDueDate || '' | dateFormat }}
                                </div>

                                <div
                                    *ngIf="!occupation?.rentDetails?.depositDueDate"
                                    class="s-body-14-22-italic tw-pl-10"
                                >
                                    {{ 'PAGES.OWNERSHIP.DETAIL_VIEW.LABEL_NOT_SPECIFIED' | translate }}
                                </div>
                            </ng-container>
                        </div>

                        <div
                            class="tw-space-y-3 tw-mb-6 tw-pb-6 tw-border-b-[1px] tw-border-solid tw-border-scalaraGray-05"
                        >
                            <div>
                                <div
                                    *ngIf="occupation?.rentDetails?.oneTimeSmallRepairsAmount"
                                    class="s-body-14-22-regular tw-pl-10"
                                >
                                    {{ occupation?.rentDetails?.oneTimeSmallRepairsAmount! | eurocent }} €
                                </div>

                                <div
                                    *ngIf="!occupation?.rentDetails?.oneTimeSmallRepairsAmount"
                                    class="s-body-14-22-italic tw-pl-10"
                                >
                                    {{ 'PAGES.OWNERSHIP.DETAIL_VIEW.LABEL_NOT_SPECIFIED' | translate }}
                                </div>
                            </div>
                            <div>
                                <div
                                    *ngIf="occupation?.rentDetails?.annualSmallRepairsAmount"
                                    class="s-body-14-22-regular tw-pl-10"
                                >
                                    {{ occupation?.rentDetails?.annualSmallRepairsAmount! | eurocent }} €
                                </div>

                                <div
                                    *ngIf="!occupation?.rentDetails?.annualSmallRepairsAmount"
                                    class="s-body-14-22-italic tw-pl-10"
                                >
                                    {{ 'PAGES.OWNERSHIP.DETAIL_VIEW.LABEL_NOT_SPECIFIED' | translate }}
                                </div>
                            </div>
                        </div>

                        <div>
                            <div
                                *ngIf="(occupationForm || occupation)?.miscellaneousInfo"
                                class="s-body-14-22-regular tw-pl-10 tw-max-w-[634px]"
                            >
                                {{ (occupationForm || occupation)?.miscellaneousInfo }}
                            </div>

                            <div
                                *ngIf="!occupationForm?.miscellaneousInfo && !occupation?.miscellaneousInfo"
                                class="s-body-14-22-italic tw-pl-10"
                            >
                                {{ 'PAGES.OWNERSHIP.DETAIL_VIEW.LABEL_NOT_SPECIFIED' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <app-scalara-button
            *ngIf="occupationStatus === 'commit'"
            (click)="openEditDetailsOverlay()"
            variant="icon-only"
            height="40px"
            svgName="24_edit.svg"
        ></app-scalara-button>
    </div>

    <div class="mt-4r">
        <app-document-table title="PAGES.DOCUMENTS.TABLE_TITLE" [inputData]="tableInputData"></app-document-table>
    </div>
</div>

<ng-template #empty><div>–</div></ng-template>
