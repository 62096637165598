import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { GridModule, LoadingModule, TabsModule } from 'carbon-components-angular';
import { NgChartsModule } from 'ng2-charts';
import { SharedModule } from 'src/app/shared/shared.module';
import { OperationAccountingStatisticsComponent } from './accounting-statistics/operation-accounting-statistics.component';
import { OperationBaseStatisticsComponent } from './base-statistics/operation-base-statistics.component';
import { OperationStatisticsOverviewComponent } from './operation-statistics-overview/operation-statistics-overview.component';

@NgModule({
    declarations: [
        OperationAccountingStatisticsComponent,
        OperationBaseStatisticsComponent,
        OperationStatisticsOverviewComponent,
    ],
    imports: [
        CommonModule,
        GridModule,
        LoadingModule,
        SharedModule,
        TranslateModule,
        TabsModule,
        NgChartsModule,
        AngularSvgIconModule,
    ],
})
export class OperationStatisticsModule {}
