import { Component, OnInit } from '@angular/core';
import { Subject, takeUntil, tap } from 'rxjs';
import { LedgerDto } from 'src/app/generated-sources/accounting';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { LedgerCustomService } from '../../services/ledger-custom.service';
import { AddConsumptionDistributionComponent } from '../add-consumption-distribution/add-consumption-distribution.component';
import { AddOesDistributionKeyComponent } from '../add-oes-distribution-key/add-oes-distribution-key.component';

@Component({
    selector: 'app-edit-operation-expense-statement',
    templateUrl: './edit-operation-expense-statement.component.html',
    styleUrls: ['./edit-operation-expense-statement.component.scss'],
})
export class EditOperationExpenseStatementComponent extends OverlayChildComponent implements OnInit {
    private unsubscribe$ = new Subject<void>();
    public isLoading = false;
    public ledgerId = '';
    public propertyId = '';
    public propertyType?: LedgerDto.TypeEnum;
    public oesId = '';
    public selectedStep = 1;
    public componentToShow:
        | 'add_consumption'
        | 'add_distribution_key'
        | 'distribute_costs'
        | 'select_bank_account'
        | 'carryover_costs_sev' = 'add_consumption';

    public constructor(private overlayService: OverlayService, private ledgerCustomService: LedgerCustomService) {
        super();
    }

    public ngOnInit(): void {
        this.ledgerId = this.config?.data.ledgerId;
        this.oesId = this.config?.data.oesId;
        this.componentToShow = this.config?.data.componentToShow || 'add_consumption';

        this.isLoading = true;
        this.ledgerCustomService
            .getLedger$()
            .pipe(
                tap((ledger) => {
                    this.propertyId = ledger?.propertyId || '';
                    this.propertyType = ledger?.type;
                    this.isLoading = false;
                }),

                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }

    public abort(): void {
        this.selectedStep--;
        if (this.componentToShow === 'add_distribution_key') {
            this.componentToShow = 'add_consumption';
            return;
        }

        if (this.componentToShow === 'distribute_costs' || this.componentToShow === 'carryover_costs_sev') {
            this.componentToShow = 'add_distribution_key';
            return;
        }
        if (this.componentToShow === 'select_bank_account') {
            this.componentToShow = 'distribute_costs';
            return;
        }

        this.cancelEmitter$.next();
    }

    public onSubmitAndClose(): void {
        this.saveEmitter$.next();
    }

    public onSubmitAndContinue(): void {
        this.selectedStep++;
        if (this.componentToShow === 'add_consumption') {
            this.componentToShow = 'add_distribution_key';
            return;
        }
        if (
            (this.componentToShow === 'add_distribution_key' && this.propertyType === 'MV') ||
            this.componentToShow === 'carryover_costs_sev'
        ) {
            this.componentToShow = 'distribute_costs';
            return;
        }
        if (this.componentToShow === 'add_distribution_key' && this.propertyType === 'SEV') {
            this.componentToShow = 'carryover_costs_sev';
            return;
        }
        if (this.componentToShow === 'distribute_costs') {
            this.componentToShow = 'select_bank_account';
            return;
        }
    }

    public addNewConsumption(): void {
        this.overlayService.removeOverlayComponentFromBody();

        const ref = this.overlayService.open(AddConsumptionDistributionComponent, {
            data: { ledgerId: this.ledgerId, oesId: this.oesId, isEditOesOverlay: true },
        });

        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe({
            next: () => {
                this.saveEmitter$.next();
            },
        });
    }

    public addNewDistributionKey(): void {
        this.overlayService.removeOverlayComponentFromBody();

        const ref = this.overlayService.open(AddOesDistributionKeyComponent, {
            data: { ledgerId: this.ledgerId, oesId: this.oesId, isEditOesOverlay: true },
        });

        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe({
            next: () => {
                this.saveEmitter$.next();
            },
        });
    }
}
