<div class="tw-relative">
    <div class="tw-flex">
        <div class="tw-relative">
            <app-scalara-button
                appClickOutside
                (clickOutside)="setFilterItemsVisibility(true)"
                (click)="setFilterItemsVisibility()"
                [variant]="filterButtonVariant"
                svgName="24_filter.svg"
                height="40px"
            ></app-scalara-button>

            <div
                *ngIf="countSelectedFilter > 0"
                class="tw-w-4 tw-h-4 tw-text-[10px] tw-font-bold tw-rounded-full tw-bg-scalaraBlue-01 tw-text-scalaraWhite tw-flex tw-items-center tw-justify-center tw-absolute tw-top-1 tw-right-0"
            >
                {{ countSelectedFilter }}
            </div>

            <div
                (click)="stopPropagation($event)"
                *ngIf="showFilterItems"
                class="tw-bg-scalaraWhite tw-p-6 tw-min-w-[332px] tw-right-0 tw-space-y-4 tw-absolute tw-z-10 tile tile--not-clickable tile--static"
            >
                <div *ngFor="let item of filterItems">
                    <ibm-checkbox [checked]="item.selected" (change)="onCheckboxChange($event, item.id)"
                        ><span class="tw-ml-2">{{ item.content }}</span>
                    </ibm-checkbox>
                </div>
            </div>
        </div>
        <button
            class="hover:tw-bg-scalaraBlue-03 tw-bg-transparent tw-border-none tw-s-label-12-16-regular tw-px-2 tw-py-3 tw-text-scalaraBlue-01 tw-cursor-pointer tw-ml-1"
            *ngIf="countSelectedFilter > 0"
            (click)="deleteFilter()"
        >
            Filter löschen
        </button>
    </div>
</div>
