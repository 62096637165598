<div id="overlay-container"></div>
<div id="notification-container"></div>

<div class="app-container">
    <div class="tw-flex tw-flex-row">
        <ng-container *ngIf="isLoggedIn">
            <div *ngIf="environment.demoEnvironment" class="demo-banner tw-w-full lg:tw-ml-[59px]">
                <div>
                    <app-tooltip position="bottom">
                        <div
                            content
                            class="demo-banner_tooltip-content tw-w-72 lg:tw-w-[42rem] tw-p-4 lg:tw-px-12 lg:tw-py-8"
                        >
                            {{ 'COMPONENTS.DEMO_INFO_BOX.TOOLTIP_TEXT' | translate }}
                        </div>
                        <div hoverable-item class="hoverable-item">
                            <span>{{ 'COMPONENTS.DEMO_INFO_BOX.TOOLTIP_LABEL' | translate }}</span>
                            <div hoverable-item class="icon-container">
                                <svg-icon src="/assets/icons/24_information.svg"></svg-icon>
                            </div>
                        </div>
                    </app-tooltip>
                </div>
            </div>
            <app-navigation></app-navigation>
            <!-- padding to compensate app-navigation + demo banner header height -->
            <div class="lg:tw-pl-[60px]"></div>
        </ng-container>
        <div class="tw-w-full">
            <div
                [ngClass]="{
                    'tw-pt-[5.5rem]': environment.demoEnvironment && isLoggedIn,
                    'tw-pt-12': !environment.demoEnvironment
                }"
                class="lg:tw-hidden"
            ></div>
            <div
                class="tw-hidden lg:tw-block"
                [ngClass]="{ 'tw-pt-[40px]': environment.demoEnvironment && isLoggedIn }"
            ></div>
            <app-page></app-page>
        </div>
    </div>
</div>
