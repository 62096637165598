<div
    class="file"
    *ngIf="
        (!categories || categories.length === 0) &&
            (!fileTypes || fileTypes.length === 0) &&
            (!accountsList || accountsList.length === 0);
        else fileWithSelectors
    "
>
    <div class="d-flex align-items-center">
        <svg-icon *ngIf="fileProgress === 101" src="/assets/icons/24_checkbox-check-green.svg"></svg-icon>
        <div class="px-05 s-body-14-20-regular file__name-wrapper">
            <div class="file__name-text">{{ file?.name }}</div>
        </div>
        <div class="delete p-03 ml-auto" (click)="cancelFile()">
            <svg-icon src="/assets/icons/24_close.svg"></svg-icon>
        </div>
    </div>
    <div *ngIf="fileProgress < 101" class="file file-progress__wrapper">
        <div class="file file-progress__amount" [style.width]="fileProgress + '%'"></div>
    </div>
</div>

<ng-template #fileWithSelectors>
    <div>
        <div ibmRow class="align-items-end">
            <div
                ibmCol
                class="file-name-column"
                [columnNumbers]="
                    fileTypes!.length > 0 && ((accountsList && accountsList.length > 0) || isDisabledOnInit)
                        ? { md: 6, lg: 6 }
                        : { md: 8, lg: 8 }
                "
            >
                <div class="file">
                    <div class="d-flex align-items-center">
                        <svg-icon
                            *ngIf="fileProgress === 101"
                            src="/assets/icons/24_checkbox-check-green.svg"
                        ></svg-icon>
                        <div class="px-05 s-body-14-20-regular file__name-wrapper">
                            <div class="file__name-text">{{ file?.name }}</div>
                        </div>
                        <div class="ml-auto">
                            <app-scalara-button
                                variant="icon-only"
                                svgName="24_close.svg"
                                (click)="cancelFile()"
                            ></app-scalara-button>
                        </div>
                    </div>
                    <div *ngIf="fileProgress < 101" class="file file-progress__wrapper">
                        <div class="file file-progress__amount" [style.width]="fileProgress + '%'"></div>
                    </div>
                </div>
            </div>
            <div
                ibmCol
                *ngIf="fileTypes && fileTypes.length > 0"
                [columnNumbers]="
                    (accountsList && accountsList.length > 0) || isDisabledOnInit ? { md: 4, lg: 4 } : { md: 8, lg: 8 }
                "
                class="mt-1-5r"
            >
                <app-combo-box
                    [size]="'md'"
                    label="{{ 'ENTITIES.DOCUMENT.LABEL_TYPE' | translate }}*"
                    [items]="fileTypes"
                    (selected)="onSelectType($event)"
                    placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT' | translate }}"
                    [clearable]="false"
                >
                    <ibm-dropdown-list></ibm-dropdown-list>
                </app-combo-box>
            </div>
            <div ibmCol *ngIf="categories && categories.length > 0" [columnNumbers]="{ md: 8, lg: 8 }" class="mt-1-5r">
                <app-combo-box
                    [size]="'md'"
                    label="{{ 'ENTITIES.DOCUMENT.CATEGORY' | translate }}*"
                    [items]="categories"
                    (selected)="onSelectCategory($event)"
                    placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT' | translate }}"
                    [clearable]="false"
                    [disabled]="isCategoryDisable"
                >
                    <ibm-dropdown-list></ibm-dropdown-list>
                </app-combo-box>
            </div>
            <div
                ibmCol
                *ngIf="(accountsList && accountsList.length > 0) || isDisabledOnInit"
                [columnNumbers]="{ md: 6, lg: 6 }"
                class="mt-1-5r"
            >
                <app-combo-box
                    [size]="'md'"
                    label="{{ 'ENTITIES.DOCUMENT.LABEL_ACCOUNT' | translate }}"
                    [items]="accountsList || []"
                    (selected)="onSelectAccount($event)"
                    placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT' | translate }}"
                    [disabled]="(isDisabledOnInit && accountsList && accountsList.length < 2) || false"
                >
                    <ibm-dropdown-list></ibm-dropdown-list>
                </app-combo-box>
            </div>
        </div>
    </div>
</ng-template>
