import { Component, Input, NgZone, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-scalara-accordion',
    templateUrl: './scalara-accordion.component.html',
    styleUrls: ['./scalara-accordion.component.scss'],
})
export class ScalaraAccordionComponent implements OnInit {
    public constructor(private ngZone: NgZone) {}
    @Input() public headingLabel = '';
    @Input() public isOpenedByDefault = false;
    @Input() public headerClass = '';
    @Input() public headerLabelClass = 's-head-14-22-bold';
    @Input() public useDefaultStyles = true;
    @Input() public iconToOpen = '/assets/icons/24_arrow-down.svg';
    @Input() public iconToClose?: string;

    public isOpen$ = new BehaviorSubject<boolean>(false);

    public onHeaderClick(): void {
        this.ngZone.run(() => this.isOpen$.next(!this.isOpen$.getValue()));
    }

    public ngOnInit(): void {
        this.ngZone.run(() => this.isOpen$.next(this.isOpenedByDefault));
    }
}
