import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ListItem } from 'carbon-components-angular';
import { Subject } from 'rxjs';
import { getNameFromPerson } from 'src/app/core/utils/common';
import { Occupation } from 'src/app/generated-sources/base';
import { onSubmitDeleteRentReceivablesCallback } from '../ledger-reasonability-checks.component';

@Component({
    selector: 'app-ledger-reasonability-checks-delete-rent-receivables',
    templateUrl: './ledger-reasonability-checks-delete-rent-receivables.component.html',
    styleUrls: ['./ledger-reasonability-checks-delete-rent-receivables.component.scss'],
})
export class LedgerReasonabilityChecksDeleteRentReceivablesComponent implements OnInit, OnChanges {
    // public ledgerId: string | undefined;
    public deleteRentReceivablesFormGroup: FormGroup = new FormGroup({});
    public occupationsList$ = new Subject<ListItem[]>();

    @Input()
    public deleteRangeStartDate = new Date(new Date().getFullYear(), 0, 1);
    @Input()
    public deleteRangeEndDate = new Date(new Date().getFullYear(), 11, 31);
    @Input()
    public selectedOccupationId?: string;
    @Input()
    public occupationsList: Occupation[] = [];
    @Input()
    public onSubmitCallback?: onSubmitDeleteRentReceivablesCallback;

    public constructor(
        private translateService: TranslateService,

        private formBuilder: FormBuilder
    ) {}

    public ngOnInit(): void {
        this.deleteRentReceivablesFormGroup = this.formBuilder.group({
            occupations: new FormControl('', [Validators.required]),
            deleteRangeStartDate: new FormControl(this.deleteRangeStartDate, [Validators.required]),
            deleteRangeEndDate: new FormControl(this.deleteRangeEndDate, [Validators.required]),
        });
        this.deleteRentReceivablesFormGroup.updateValueAndValidity();
        this.updateOccupationListItems(this.occupationsList);
    }
    public ngOnChanges(): void {
        this.updateOccupationListItems(this.occupationsList);
    }

    public onSubmit(): void {
        if (
            this.selectedOccupationId &&
            this.deleteRangeStartDate &&
            this.deleteRangeEndDate &&
            this.onSubmitCallback
        ) {
            this.onSubmitCallback(
                this.selectedOccupationId,
                this.deleteRangeStartDate.toISOString(),
                this.deleteRangeEndDate.toISOString()
            );
        }
    }

    private updateOccupationListItems(occupationList: Occupation[]): void {
        this.occupationsList$.next(
            occupationList.map((occupation) => {
                return {
                    content: `${occupation.occupationNumber} ${occupation.id} ${this.getTenantNames(occupation)}`,
                    value: occupation.id,
                    selected: false,
                    id: occupation.id,
                };
            })
        );
    }

    public onSelectedOccupation($event: any): void {
        this.selectedOccupationId = $event.item['value'];
    }

    public onChangeDeleteRangeStartDate($event: any): void {
        this.deleteRangeStartDate = $event[0];
    }
    public onChangeDeleteRangeEndDate($event: any): void {
        this.deleteRangeEndDate = $event[0];
    }
    private getTenantNames(occupation: Occupation): string {
        return occupation.tenants
            .map((tenant) => (tenant && (tenant.firstName || tenant.companyName) ? getNameFromPerson(tenant) : ''))
            .join(', ');
    }
}
