<app-details-container [options]="options">
    <div ibmCol [columnNumbers]="{ md: 11, lg: 11, xl: 11 }" class="py-07 pb-09 pl-09">
        <div ibmRow>
            <div ibmCol [columnNumbers]="{ md: 3, lg: 3, xl: 3 }">
                <div class="mb-04 s-label-12-22-semibold">{{ 'ACCOUNTING.OPEN-ITEMS.TYPE' | translate }}</div>
            </div>
            <div class="s-body-14-22-bold" ibmCol [columnNumbers]="{ md: 8, lg: 8, xl: 8 }">
                {{ 'ENTITIES.OPEN_ITEM.' + openItem.type | translate }}
            </div>
        </div>
        <div ibmRow *ngIf="openItemType !== 'RECEIVABLE' && openItemType !== 'LIABILITY'">
            <div ibmCol [columnNumbers]="{ md: 3, lg: 3, xl: 3 }">
                <div class="mb-07 s-label-12-22-semibold">
                    {{ 'ACCOUNTING.OPEN-ITEMS.DETAILS.ACCOUNTING_TRANSACTION' | translate }}
                </div>
            </div>
            <div ibmCol [columnNumbers]="{ md: 8, lg: 8, xl: 8 }">
                <div class="s-head-14-22-regular mb-07">{{ additionalOpenItemData.bookingDescription || '–' }}</div>
            </div>
        </div>
        <div ibmRow>
            <div ibmCol [columnNumbers]="{ md: 3, lg: 3, xl: 3 }">
                <div class="s-label-12-22-semibold">
                    {{ 'ACCOUNTING.OPEN-ITEMS.DETAILS.DESCRIPTION' | translate }}
                </div>
            </div>
            <div ibmCol [columnNumbers]="{ md: 8, lg: 8, xl: 8 }">
                <div class="s-head-14-22-regular mb-04">{{ openItem.description }}</div>
            </div>
        </div>
        <div ibmRow>
            <div ibmCol [columnNumbers]="{ md: 3, lg: 3, xl: 3 }">
                <div class="mb-04 s-label-12-22-semibold">{{ 'ENTITIES.OPEN_ITEM.DUE_DATE' | translate }}</div>
            </div>
            <div ibmCol [columnNumbers]="{ md: 8, lg: 8, xl: 8 }">
                <div class="s-head-14-22-regular mb-04" [ngClass]="{ 'colored-text': additionalOpenItemData.isDue }">
                    {{ openItem.dueDate | dateFormat }}
                </div>
            </div>
        </div>
        <div ibmRow class="tw-mb-2">
            <div ibmCol [columnNumbers]="{ md: 3, lg: 3, xl: 3 }">
                <div class="s-label-12-22-semibold">{{ 'ACCOUNTING.OPEN-ITEMS.CREATED_AT' | translate }}</div>
            </div>
            <div ibmCol [columnNumbers]="{ md: 8, lg: 8, xl: 8 }">
                <div class="s-head-14-22-regular">{{ openItem.createdAt | dateFormat }}</div>
            </div>
        </div>

        <div ibmRow *ngIf="openItem.vatAmount && openItem.vatRate">
            <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }" class="info-field__label s-label-12-22-semibold">
                {{ 'ACCOUNTING.COMMON.TAX_SHARE' | translate }}
            </div>
            <div ibmCol class="s-body-14-22-regular">
                <span class="">{{ openItem.vatAmount | eurocent }}€ </span> ({{ openItem.vatRate }}%)
            </div>
        </div>
    </div>
    <div ibmCol [columnNumbers]="{ md: 5, lg: 5, xl: 5 }" class="border-left py-07 pl-07 pb-09 pr-09">
        <div class="flex-container mb-03">
            <span class="s-label-12-22-semibold">{{ 'ACCOUNTING.OPEN-ITEMS.DETAILS.TOTAL_AMOUNT' | translate }}</span>
            <span class="s-head-18-26-bold">{{ openItem.amount | eurocent }} €</span>
        </div>
        <div class="flex-container mb-04">
            <span class="s-label-12-22-semibold">{{ 'ACCOUNTING.OPEN-ITEMS.DETAILS.PARTLY_CLOSED' | translate }}</span>
            <span class="s-body-14-22-regular">{{ openItem.amount - openItem.outstandingBalance | eurocent }} €</span>
        </div>
        <div class="flex-container">
            <span class="s-label-12-22-semibold">{{ 'ACCOUNTING.OPEN-ITEMS.DETAILS.OPEN_AMOUNT' | translate }}</span>
            <span class="s-body-14-22-bold colored-text">{{ openItem.outstandingBalance | eurocent }} €</span>
        </div>
    </div>
</app-details-container>
