<ibm-loading *ngIf="this.isLoading" [isActive]="true" [size]="'normal'" [overlay]="true"></ibm-loading>

<div class="tw-m-auto tw-max-w-[736px] tw-w-full tw-p-4 tw-pb-28">
    <app-notification
        type="info"
        title="{{ 'PAGES.ADD_BANK_ACCOUNT_RECOMMENDATION_OVERLAY.INFO_MESSAGE' | translate }}"
    ></app-notification>

    <div class="tw-space-y-3 tw-mt-10">
        <div *ngFor="let detail of transactionDetailToShow" class="tw-flex tw-w-full">
            <div class="tw-s-label-12-22-semibold tw-w-28 tw-shrink-0">{{ detail.i18Key | translate }}</div>
            <div class="tw-s-body-14-22-regular">{{ typedConfig.bankTransaction[detail.valueKey] || '–' }}</div>
        </div>
    </div>

    <div class="tw-s-body-14-22-bold tw-mt-10 tw-flex">
        <div>
            {{ 'PAGES.ADD_BANK_ACCOUNT_RECOMMENDATION_OVERLAY.CONNECT_MESSAGE' | translate }}
        </div>
        <app-tooltip position="top">
            <div
                hoverable-item
                class="tw-ml-3 tw-w-6 tw-h-6 tw-flex tw-items-center tw-justify-center tw-content-center tw-fill-scalaraGray-04"
            >
                <svg-icon src="/assets/icons/24_information.svg" class="tw-w-6 tw-h-6 tw-block"> </svg-icon>
            </div>
            <div content class="tw-px-10 tw-py-8 tw-s-head-14-22-regular tw-w-[575px]">
                {{ 'PAGES.ADD_BANK_ACCOUNT_RECOMMENDATION_OVERLAY.TOOLTIP_MESSAGE' | translate }}
            </div>
        </app-tooltip>
    </div>

    <div class="tw-mt-4">
        {{ 'PAGES.ADD_BANK_ACCOUNT_RECOMMENDATION_OVERLAY.RECOMMENDATION_BY_USERS_DATA' | translate }}
    </div>

    <form [formGroup]="form" class="tw-mt-4" (submit)="submit()">
        <app-combo-box
            *ngIf="comboBoxItems.length > 0; else person"
            [items]="comboBoxItems"
            id="person"
            [size]="'md'"
            [appendInline]="true"
            label="Kontakt*"
            formControlName="personListItem"
            (search)="resetFormValue()"
            [invalid]="!form.valid"
            invalidText="{{ 'ACCOUNTING.ERROR.INVALID_AMOUNT' | translate }}"
        >
            <ibm-dropdown-list></ibm-dropdown-list>
        </app-combo-box>

        <div
            class="tw-mt-28 tw-w-full tw-flex tw-flex-col-reverse tw-items-center md:tw-flex-row md:tw-justify-between"
        >
            <app-scalara-button (click)="abort()" height="48px" variant="outline">{{
                'CONTROLS.BUTTONS.NO_THANKS' | translate
            }}</app-scalara-button>
            <app-scalara-button type="submit" height="48px" class="tw-mb-4 md:tw-mb-0" [disabled]="!form.valid">{{
                'CONTROLS.BUTTONS.LINK' | translate
            }}</app-scalara-button>
        </div>
    </form>
</div>

<ng-template #row></ng-template>

<ng-template #person>
    <div class="tw-s-body-14-22-regular">{{ personRecommendationDescription }}</div>
</ng-template>
