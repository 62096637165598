import {
    AfterContentChecked,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ListItem } from 'carbon-components-angular';
import { Subject, concat, finalize, from, last, merge, mergeMap, takeUntil, tap, timer } from 'rxjs';
import { UnitTypes, formControl, formControlHasError } from 'src/app/core/utils/common';
import { controlIsInvalid } from 'src/app/core/utils/formValidationHelpers';
import { TooltipKey } from 'src/app/features/account-settings/services/custom-tooltip.service';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';
import { ToastService } from '../../../../../core/services/toast.service';
import {
    Building,
    BuildingsService,
    CreateOwnershipDto,
    OwnershipsService,
    Unit,
    UnitsService,
    UpdateOwnershipDto,
} from '../../../../../generated-sources/base';
import { ComboBoxComponent } from '../../../../../shared/components/combo-box/combo-box.component';

@Component({
    selector: 'app-add-edit-ownership-form',
    templateUrl: './add-edit-ownership-form.component.html',
    styleUrls: ['./add-edit-ownership-form.component.scss'],
})
export class AddEditOwnershipFormComponent
    extends OverlayChildComponent
    implements OnInit, OnDestroy, AfterContentChecked
{
    private propertyId = '';
    private unsubscribe$ = new Subject<void>();
    public buildingList: ListItem[] = [];
    public form: UntypedFormGroup = new UntypedFormGroup({});
    public isLoading = true;
    public currentBuildings: (ListItem & { id?: string })[] = [];
    public isNameRequired = false;

    public unitsTypeList: ListItem[] = [];
    public editingMode = false;
    private ownershipId = '';
    public savedUnit: any = [];
    private unitsToDelete: any = [];
    private submitMessage = '';

    public unitChangeSubject: Subject<{ type: string; index: number }> = new Subject<{ type: string; index: number }>();

    @ViewChildren(ComboBoxComponent)
    public comboBoxTypeUnit?: QueryList<ComboBoxComponent>;

    @ViewChildren(ComboBoxComponent)
    public comboBoxBuilding?: QueryList<ComboBoxComponent>;

    public constructor(
        private formBuilder: UntypedFormBuilder,
        private toast: ToastService,
        private buildingsService: BuildingsService,
        private ownershipsService: OwnershipsService,
        private unitsService: UnitsService,
        private translateService: TranslateService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        super();
    }

    public ngOnInit(): void {
        this.propertyId = this.config?.data.propertyId;

        this.editingMode = this.config?.data?.editingMode;
        this.ownershipId = this.config?.data?.ownership?.id;
        this.form = this.createForm();

        this.isLoading = true;
        this.buildingsService
            .findAll(this.propertyId)
            .pipe(
                tap((buildings: Building[]) => {
                    this.buildingList = buildings.map((item) => {
                        return {
                            content: item.name,
                            selected: false,
                            value: item.id,
                        };
                    });

                    this.unitsTypeList = UnitTypes.map((item) => {
                        return {
                            content: item.name,
                            selected: false,
                            value: item.type,
                        };
                    });

                    if (this.config?.data.editingMode) {
                        this.config?.data.units.map((unit: Unit) => {
                            this.addUnitToEdit(unit);
                        });
                    }

                    this.isLoading = false;
                }),
                takeUntil(this.unsubscribe$),
                finalize(() => (this.isLoading = false))
            )
            .subscribe();

        if (this.config?.data.editingMode) {
            this.createPopulateForm();
            this.submitMessage = this.translateService.instant('PAGES.OWNERSHIP.ADD_OWNERSHIP.MESSAGE_SUCCESS');
        }
    }

    public ngAfterContentChecked(): void {
        this.changeDetectorRef.detectChanges();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public createPopulateForm(): void {
        const objectToEdit = {
            fraction: String(this.config?.data?.ownership?.fraction),
            name: this.config?.data?.ownership?.name,
            squaremeters:
                this.config?.data?.ownership?.squaremeters === null || !this.config?.data?.ownership?.squaremeters
                    ? ''
                    : String(this.config?.data?.ownership?.squaremeters),
            heatedSquaremeters:
                this.config?.data?.ownership?.heatedSquaremeters === null ||
                !this.config?.data?.ownership?.heatedSquaremeters
                    ? ''
                    : String(this.config?.data?.ownership?.heatedSquaremeters),
        };

        this.form.patchValue(objectToEdit);
    }

    public addUnitToEdit(unit: Unit): void {
        const findBuildingItemList = this.buildingList.find((item) => item['value'] === unit.buildingId);
        const findUnitTypeItemList = this.unitsTypeList.find((item) => item['value'] === unit.type);
        if (findUnitTypeItemList) {
            findUnitTypeItemList['selected'] = true;
        }

        if (findBuildingItemList) {
            findBuildingItemList['selected'] = true;
        }

        this.units.push(
            this.formBuilder.group({
                id: new UntypedFormControl(unit.id, [Validators.required]),
                type: new UntypedFormControl(findUnitTypeItemList, [Validators.required]),
                name: new UntypedFormControl(unit.name),
                building: new UntypedFormControl(findBuildingItemList, [Validators.required]),
                location: new UntypedFormControl(unit.location, [Validators.required]),
                squaremeters: new UntypedFormControl(unit.squaremeters, [Validators.min(0), Validators.max(1000000)]),
                heatedSquaremeters: new UntypedFormControl(unit.heatedSquaremeters, [
                    Validators.min(0),
                    Validators.max(1000000),
                ]),
                roomCount: new UntypedFormControl(unit.roomCount),
                isNotResidential: new UntypedFormControl(unit.isNotResidential),
                condition: new UntypedFormControl(unit.condition),
                inventory: new UntypedFormControl(unit.inventory),
                additionalInformation: new UntypedFormControl(unit.additionalInformation),
            })
        );
    }

    public createForm(): UntypedFormGroup {
        return this.formBuilder.group({
            fraction: [null, [Validators.required, Validators.min(1), Validators.max(1000000)]],
            name: ['', [Validators.required]],
            units: this.formBuilder.array(
                this.editingMode
                    ? []
                    : [
                          this.formBuilder.group({
                              type: new UntypedFormControl('', [Validators.required]),
                              name: new UntypedFormControl(''),
                              building: new UntypedFormControl('', [Validators.required]),
                              location: new UntypedFormControl('', [Validators.required]),
                              squaremeters: new UntypedFormControl(null, [Validators.min(0), Validators.max(1000000)]),
                              heatedSquaremeters: new UntypedFormControl(null, [
                                  Validators.min(0),
                                  Validators.max(1000000),
                              ]),

                              roomCount: new UntypedFormControl(''),
                              isNotResidential: new UntypedFormControl(undefined),
                              condition: new UntypedFormControl(''),
                              inventory: new UntypedFormControl(''),
                              additionalInformation: new UntypedFormControl(''),
                          }),
                      ]
            ),
            squaremeters: [null, [Validators.min(0), Validators.max(1000000)]],
            heatedSquaremeters: [null, [Validators.min(0), Validators.max(1000000)]],
            persons: this.editingMode ? [] : [null, [Validators.min(0), Validators.max(1000000)]],
        });
    }

    public get units(): UntypedFormArray {
        return this.form.get('units') as UntypedFormArray;
    }

    public addUnit(): void {
        this.units.push(
            this.formBuilder.group({
                type: new UntypedFormControl('', [Validators.required]),
                name: new UntypedFormControl('', [Validators.required]),
                building: new UntypedFormControl('', [Validators.required]),
                location: new UntypedFormControl('', [Validators.required]),
                squaremeters: new UntypedFormControl(null, [Validators.min(0), Validators.max(1000000)]),
                heatedSquaremeters: new UntypedFormControl(null, [Validators.min(0), Validators.max(1000000)]),
                roomCount: new UntypedFormControl(''),
                isNotResidential: new UntypedFormControl(undefined),
                condition: new UntypedFormControl(''),
                inventory: new UntypedFormControl(''),
                additionalInformation: new UntypedFormControl(''),
            })
        );

        this.setValidadorsBasedOnUnit();
    }

    private createOwnership(fraction: number, units: Unit[]): void {
        const createOwnershipDto: CreateOwnershipDto = {
            fraction: fraction,
            name: this.form.value.name,
            units,
            squaremeters: this.getNumberForFraction('squaremeters')!,
            heatedSquaremeters: this.getNumberForFraction('heatedSquaremeters')!,
            persons: this.getNumberForFraction('persons')!,
        };

        this.ownershipsService
            .create(this.propertyId, createOwnershipDto)
            .pipe(
                finalize(() => (this.isLoading = false)),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: () => {
                    this.toast.showSuccess('Einheit angelegt');
                    this.saveEmitter$.next();
                },
                error: (error) => {
                    if (error) {
                        this.toast.showError(error.error['message']);
                        this.isLoading = false;
                    }
                },
            });
    }

    private updateOwnership(fraction: number, units: Unit[]): void {
        //Create or Update Units
        const createOrUpdateUnitsObservable = from(units).pipe(
            mergeMap((unit) => {
                const unitData = {
                    type: unit.type,
                    name: unit.name,
                    buildingId: unit.buildingId,
                    location: unit.location,
                    squaremeters: unit.squaremeters,
                    heatedSquaremeters: unit.heatedSquaremeters,
                    isNotResidential: unit.isNotResidential,
                    roomCount: unit.roomCount,
                    condition: unit.condition,
                    inventory: unit.inventory,
                    additionalInformation: unit.additionalInformation,
                };
                if (unit.id) {
                    return this.unitsService.update(this.propertyId, this.ownershipId, unit.id, unitData);
                } else {
                    return this.unitsService.create(this.propertyId, this.ownershipId, unitData);
                }
            })
        );
        //Delete Units
        const deleteUnitsObservable = from(this.unitsToDelete as string[]).pipe(
            mergeMap((unitId) => this.unitsService.remove(this.propertyId, this.ownershipId, unitId))
        );
        //Update ownerships
        const updateOwnershipDto: UpdateOwnershipDto = {
            fraction: fraction,
            name: this.form.value.name,
            squaremeters: this.getNumberForFraction('squaremeters')!,
            heatedSquaremeters: this.getNumberForFraction('heatedSquaremeters')!,
        };
        const updateOwnershipObservable = this.ownershipsService.update(
            this.propertyId,
            this.ownershipId,
            updateOwnershipDto
        );
        //Combine all observables. Note, that createBuildingsObservable needs to be finished first. The remaining observables can run simultaneously
        const combinedObservable = concat(
            merge(createOrUpdateUnitsObservable, deleteUnitsObservable, updateOwnershipObservable)
        );
        combinedObservable.pipe(last()).subscribe({
            next: () => {
                this.toast.showSuccess(this.submitMessage);
                this.saveEmitter$.next();
            },
            error: (error) => {
                if (error) {
                    this.toast.showError(error.error['message']);
                    this.isLoading = false;
                }
            },
        });
    }

    public onSubmit(): void {
        if (this.form.invalid || !this.propertyId) {
            this.toast.showError('Eingaben inkorrekt.');
            return;
        }

        this.isLoading = true;

        if (this.units.value.length === 1 && !this.units.value[0].name) {
            this.units.value[0].name = this.form.value.name;
        }

        const units: Unit[] = this.form.value.units.map((unit: any) => {
            return {
                ...unit,
                buildingId: unit.building.value || unit.building,
                type: unit.type.value || unit.type,
                isNotResidential: (unit.type.value || unit.type) === 'APARTMENT' ? !!unit.isNotResidential : undefined,
            };
        });

        const fraction = this.getNumberForFraction('fraction') || 0;

        if (this.editingMode) {
            this.updateOwnership(fraction, units);
        } else {
            this.createOwnership(fraction, units);
        }
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public isInvalid(controlName: string): boolean {
        return formControlHasError(formControl(this.form, controlName), 'required');
    }

    public isInvalidNumber(controlName: string): boolean {
        return (
            formControlHasError(formControl(this.form, controlName), 'min') ||
            formControlHasError(formControl(this.form, controlName), 'max')
        );
    }

    public isInvalidOwnerShip(index: number, controlName: string): boolean {
        const control = this.units.at(index).get(controlName) as UntypedFormControl;
        return controlIsInvalid(control);
    }

    public removeUnit(index: number): void {
        if (
            this.editingMode &&
            ((this.form?.get('units') as UntypedFormArray)?.at(index) as UntypedFormGroup).value?.id
        ) {
            this.savedUnit.push(((this.form?.get('units') as UntypedFormArray)?.at(index) as UntypedFormGroup).value);
            this.unitsToDelete.push(
                ((this.form?.get('units') as UntypedFormArray)?.at(index) as UntypedFormGroup)?.value?.id
            );
        }

        this.units.removeAt(index);
        this.setValidadorsBasedOnUnit();
    }

    public backUnit(unit: any): void {
        this.units.push(
            this.formBuilder.group({
                id: new UntypedFormControl(unit.id, [Validators.required]),
                type: new UntypedFormControl(unit.type, [Validators.required]),
                name: new UntypedFormControl(unit.name),
                building: new UntypedFormControl(unit.building, [Validators.required]),
                location: new UntypedFormControl(unit.location, [Validators.required]),
                squaremeters: new UntypedFormControl(unit.squaremeters, [Validators.min(0), Validators.max(1000000)]),
                heatedSquaremeters: new UntypedFormControl(unit.heatedSquaremeters, [
                    Validators.min(0),
                    Validators.max(1000000),
                ]),
                roomCount: new UntypedFormControl(unit.roomCount),
                isNotResidential: new UntypedFormControl(unit.isNotResidential),
                condition: new UntypedFormControl(unit.condition),
                inventory: new UntypedFormControl(unit.inventory),
                additionalInformation: new UntypedFormControl(unit.additionalInformation),
            })
        );

        timer(100).subscribe(() => {
            this.unitsToDelete = this.unitsToDelete.filter((unitToDelete: string) => unitToDelete !== unit.id);
            this.savedUnit = this.savedUnit.filter((unitToDelete: any) => unitToDelete.id !== unit.id);
        });

        this.setValidadorsBasedOnUnit();
    }

    private getNumberForFraction(formName: string): number | null {
        if (!this.form?.value?.[formName]) {
            return formName === 'squaremeters' || formName === 'persons' || formName === 'heatedSquaremeters'
                ? null
                : 0;
        }
        return Number(this.form.value?.[formName].split(',').join('.'));
    }

    public setValidadorsBasedOnUnit(): void {
        if (this.units.value.length > 1) {
            this.units.value.map((item: any, index: number) => {
                const nameUnitControl = (this.units?.at(index) as UntypedFormGroup).get('name') as UntypedFormControl;

                nameUnitControl.setValidators(Validators.required);

                nameUnitControl.setErrors(null);
                nameUnitControl.updateValueAndValidity();
            });

            this.isNameRequired = true;
        } else {
            this.units.value.map((item: any, index: number) => {
                const nameUnitControl = (this.units?.at(index) as UntypedFormGroup).get('name') as UntypedFormControl;

                nameUnitControl.clearValidators();

                nameUnitControl.setErrors(null);
                nameUnitControl.updateValueAndValidity();
            });
            this.isNameRequired = false;
        }
    }

    public tooltipKey = TooltipKey.tooltipsInProperties;
}
