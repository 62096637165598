import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ISignUpResult } from 'amazon-cognito-identity-js';
import { ToastContent } from 'carbon-components-angular';
import { finalize, Subject, takeUntil } from 'rxjs';
import { PersonTypeAndNameFormComponent } from 'src/app/shared/person-type-and-name-form/person-type-and-name-form.component';
import { AuthService } from '../../../../core/services/auth.service';
import { ToastService } from '../../../../core/services/toast.service';
import { formControl, formControlHasError } from '../../../../core/utils/common';
import { PasswordValidators } from '../../../../shared/validators/password-validators';
import { VerificationService } from '../../services/verification.service';
import { AuthSuccessFlowTypeEnum } from '../types/authSuccess';

@Component({
    selector: 'app-sign-up',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();

    public notification?: ToastContent;

    public readonly sources = [
        'Empfehlung eines Eigentümers',
        'Empfehlung einer anderen Verwaltung',
        'Internetanzeige',
        'Internetsuche',
        'Verwaltermesse / -tag',
        'Sonstige',
    ] as const;

    public form: UntypedFormGroup = new UntypedFormGroup({});
    public static MIN_PASSWORD_LENGTH = 8;
    public isLoading = false;

    @ViewChild(PersonTypeAndNameFormComponent)
    public personTypeAndNameFormComponent?: PersonTypeAndNameFormComponent;

    public constructor(
        private router: Router,
        private authService: AuthService,
        private verificationService: VerificationService,
        private formBuilder: UntypedFormBuilder,
        private toast: ToastService
    ) {}

    public ngOnInit(): void {
        this.isLoading = true;
        this.form = this.createForm();

        this.verificationService
            .getSignUpResult()
            .pipe(
                finalize(() => (this.isLoading = false)),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: (result: ISignUpResult | Error) => {
                    if ('user' in result) {
                        this.toast.showSuccess('Anmeldung erfolgreich', 'Bitte verifizieren Sie ihre E-Mail Adresse.');
                        this.router.navigate(['/auth-success'], {
                            queryParams: { flowType: AuthSuccessFlowTypeEnum.newSignUp },
                        });
                    } else {
                        this.toast.showError('Anmeldung fehlgeschlagen', 'E-Mail Adresse bereits registriert.');
                    }
                },
            });
    }

    public ngAfterViewInit(): void {
        if (!this.personTypeAndNameFormComponent) {
            console.warn('PersonTypeAndNameFormComponent is not initialised');
            return;
        }
        this.form.addControl('personTypeAndName', this.personTypeAndNameFormComponent.personTypeAndNameForm);
        this.personTypeAndNameFormComponent.personTypeAndNameForm.setParent(this.form);
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public onSignUp(): void {
        if (this.form.invalid) {
            return;
        }
        this.isLoading = true;
        const email = this.form.value.email.trim().toLowerCase();
        const { password, registrationInfo, personTypeAndName } = this.form.value;
        this.verificationService.signUp({
            email,
            password,
            source: registrationInfo,
            personData: personTypeAndName,
        });
    }

    private createForm(): UntypedFormGroup {
        return this.formBuilder.group(
            {
                email: ['', [Validators.email, Validators.required]],
                registrationInfo: ['', [Validators.required]],
                generalTerms: [false, Validators.requiredTrue],
            },
            {
                validators: [PasswordValidators.passwordMatch],
            }
        );
    }

    public get emailInvalid(): boolean {
        return (
            formControlHasError(formControl(this.form, 'email'), 'required') ||
            formControlHasError(formControl(this.form, 'email'), 'email')
        );
    }

    public get generalTermsInvalid(): boolean {
        return formControlHasError(formControl(this.form, 'generalTerms'), 'required');
    }

    public get sourcesInvalid(): boolean {
        return formControlHasError(formControl(this.form, 'registrationInfo'), 'required');
    }
}
