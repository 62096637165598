<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>
<app-overlay-container
    headlineTitle="PAGES.BANK_TRANSACTIONS.BOOKING_RULES.ADD_BOOKING_RULES_OVERLAY.HEADLINE"
    containerSize="small"
>
    <div class="tw-flex tw-justify-between tw-space-x-8">
        <div class="tw-w-full" *ngFor="let typeItem of guidedBookingTypeShort">
            <app-card-selection
                [iconColor]="typeItem.category === 'REVENUE' ? 'green' : 'orange'"
                [iconSrc]="typeItem.category === 'REVENUE' ? '40_geldeingang.svg' : '40_geldausgang.svg'"
                [label]="typeItem.category === 'REVENUE' ? 'Geldeingang' : 'Geldausgang'"
                [selected]="selectedType?.id === typeItem.id"
                (click)="selectType(typeItem)"
            ></app-card-selection>
        </div>
    </div>

    <form [formGroup]="form" class="tw-mt-16">
        <ibm-label [invalid]="isInvalidForm('name')" invalidText="{{ 'ERROR.REQUIRED' | translate }}">
            Bezeichnung der Buchungsregel*
            <input formControlName="name" ibmText name="name" id="name" [invalid]="isInvalidForm('name')" />
        </ibm-label>

        <div class="tw-mt-8 tw-mb-4">
            <div class="tw-s-head-14-22-bold tw-mb-6">
                Geben Sie die Daten zu dem Umsatz an (Füllen Sie mindestens eins der Felder aus)
            </div>
            <div class="tw-s-label-12-16-regular tw-mb-2">
                Geben Sie ein oder mehrere Stichwörter ein, für den Abgleich mit dem Verwendungszweck des Umsatzes. Alle
                Stichwörter die Sie hier angeben, müssen im Verwendungszweck vorhanden sein.
            </div>

            <app-tag-input formControlName="keywords" label="Stichwörter"></app-tag-input>
        </div>

        <div class="tw-mb-4">
            <app-number-input
                label="Betrag in €*"
                invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                formControlName="amount"
                [remainClearOnBlur]="true"
            ></app-number-input>
        </div>

        <div class="tw-mb-4">
            <ibm-label invalidText="{{ 'ERROR.REQUIRED' | translate }}">
                Kontoinhaber
                <input formControlName="counterpartName" ibmText name="counterpartName" id="counterpartName" />
            </ibm-label>
        </div>

        <ibm-label invalidText="{{ 'ERROR.REQUIRED' | translate }}">
            IBAN
            <input formControlName="counterpartIban" ibmText name="counterpartIban" id="counterpartIban" />
        </ibm-label>

        <div class="tw-my-8">
            <div class="tw-s-head-14-22-bold tw-mb-6">Wählen Sie das Buchhaltungskonto aus</div>
            <app-combo-box
                [size]="'md'"
                id="property"
                placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT' | translate }}"
                [disabled]="!selectedType"
                [appendInline]="true"
                [label]="
                    (getAdditionalParameter('bookingAccount')?.label ??
                        ('ACCOUNTING.ADD_BOOKING_FORM.LABEL_ACCOUNT' | translate)) +
                    '*'
                "
                [items]="bookingAccountList"
                formControlName="accountToBookInId"
                [invalid]="isInvalidForm('accountToBookInId')"
                invalidText="{{ 'ERROR.REQUIRED' | translate }}"
            >
                <ibm-dropdown-list></ibm-dropdown-list>
            </app-combo-box>
        </div>

        <div *ngIf="vat" class="tw-flex tw-justify-between">
            <ibm-checkbox formControlName="vatCheckbox">
                {{ 'ACCOUNTING.COMMON.TAX_RATE_OPTION' | translate }}
            </ibm-checkbox>
            <ng-container *ngIf="checkCheckBoxSelected('vatCheckbox')">
                <div class="tw-flex">
                    <div class="tw-s-label-12-22-semibold tw-text-scalaraGray-03 tw-mr-6">
                        {{ vat.label }}
                    </div>
                    <ibm-radio-group formControlName="vat" aria-label="radiogroup" orientation="horizontal">
                        <ibm-radio [value]="vat.valueOptions[0].id">
                            {{ vat.valueOptions[0].label }}
                        </ibm-radio>
                        <ibm-radio [checked]="true" [value]="vat.valueOptions[1].id">
                            {{ vat.valueOptions[1].label }}
                        </ibm-radio>
                    </ibm-radio-group>
                </div>

                <div class="tw-text-right">
                    {{ calculateTaxShareSum() | eurocent }}
                </div>
            </ng-container>
        </div>

        <div *ngIf="showLabourAmount()" class="tw-flex tw-justify-between tw-mt-4">
            <ibm-checkbox formControlName="labourCheckbox">
                {{ labourAmount?.label }}
            </ibm-checkbox>
            <app-number-input
                label="{{ 'ACCOUNTING.COMMON.SALARY_AMOUNT' | translate }} {{
                    this.labourAmount?.isOptional ? '' : '*'
                }}"
                formControlName="labourAmount"
                *ngIf="checkCheckBoxSelected('labourCheckbox')"
                [invalid]="isLabourAmountInvalid()"
                invalidText="{{ 'ACCOUNTING.ERROR.INVALID_AMOUNT_SALARY_SHARE' | translate }}"
            ></app-number-input>
        </div>
    </form>

    <div *ngIf="showNotificationError" class="tw-mt-8">
        <app-notification type="error">
            <div class="tw-s-body-14-22-regular">
                {{ 'PAGES.BANK_TRANSACTIONS.BOOKING_RULES.ADD_BOOKING_RULES_OVERLAY.NOTIFICATION_ERROR' | translate }}
            </div>
        </app-notification>
    </div>

    <div class="tw-flex tw-justify-between tw-mt-[120px]">
        <app-scalara-button (click)="abort()" variant="outline">{{
            'CONTROLS.BUTTONS.CANCEL' | translate
        }}</app-scalara-button>
        <app-scalara-button [disabled]="form.invalid" (click)="submit()">{{
            'CONTROLS.BUTTONS.SAVE' | translate
        }}</app-scalara-button>
    </div>
</app-overlay-container>
