import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ListItem } from 'carbon-components-angular';
import { Subject, of, switchMap, takeUntil, tap } from 'rxjs';
import { TooltipKey } from 'src/app/features/account-settings/services/custom-tooltip.service';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { EurocentPipe } from 'src/app/shared/pipes/eurocent.pipe';
import { CellTemplate } from 'src/app/shared/table/enums/cell-template';
import { HeaderTemplate } from 'src/app/shared/table/enums/header-template';
import { TableItem } from 'src/app/shared/table/interfaces/table-item';
import { TableModel } from 'src/app/shared/table/interfaces/table-model';
import { GetStatus, getStatusOpenItems, sortOpenItems } from '../../../../../../../core/utils/common';
import {
    LedgerDto,
    LedgersService,
    OpenItemDto,
    OpenItemsService,
} from '../../../../../../../generated-sources/accounting';
import { AddGuidedBookingOverlayComponent } from '../../add-guided-booking-overlay/add-guided-booking-overlay.component';

interface FilterListItem extends ListItem {
    status: 'due' | 'open' | 'closed';
}

@Component({
    selector: 'app-open-items-index',
    templateUrl: './open-items-index.component.html',
    styleUrls: ['./open-items-index.component.scss'],
})
export class OpenItemsIndexComponent extends OverlayChildComponent implements OnInit {
    public isLoading = false;
    public isInitialLoading = false;

    public ledgerId?: string;
    private unsubscribe$ = new Subject<void>();

    public amountDueItem = 0;
    public amountOpenItem = 0;
    public receivables: OpenItemDto[] = [];
    public liabilities: OpenItemDto[] = [];
    public ledger?: LedgerDto;
    public liabilitiesTable: TableModel = { data: [], header: [] };
    public receivablesTable: TableModel = { data: [], header: [] };
    public amountLiabilities = 0;
    public amountReceivables = 0;
    public sumLiabilities = 0;
    public sumReceivables = 0;
    public selectedFilterLiabilitiesTable: 'due' | 'open' | 'closed' = 'open';
    public selectedFilterReceivablesTable: 'due' | 'open' | 'closed' = 'open';

    public constructor(
        private route: ActivatedRoute,
        public translateService: TranslateService,
        private openItemsService: OpenItemsService,
        private ledgersService: LedgersService,
        private overlayService: OverlayService,
        private eurocentPipe: EurocentPipe
    ) {
        super();
    }

    public filter: FilterListItem[] = [
        {
            content: 'Alle offenen',
            status: 'open',
            selected: true,
        },
        {
            content: 'Fällige',
            status: 'due',
            selected: false,
        },
        {
            content: 'Geschlossene',
            status: 'closed',
            selected: false,
        },
    ];

    public ngOnInit(): void {
        this.isInitialLoading = true;
        this.initTableHeaders();

        this.loadData();
    }

    public loadData(): void {
        this.isLoading = true;
        this.route.parent?.paramMap
            .pipe(
                switchMap((params: ParamMap) => this.ledgersService.findOne(String(params.get('id')))),
                tap((ledger: LedgerDto) => {
                    this.ledger = ledger;
                    this.ledgerId = ledger.id;
                    return of(ledger);
                }),
                switchMap(() => this.openItemsService.findReceivables(this.ledgerId || '')),
                tap((receivables: OpenItemDto[]) => {
                    this.receivables = sortOpenItems(receivables);
                    this.sumReceivables = 0;
                    this.receivablesTable.data = this.receivables
                        .filter((item) => item.state !== 'CLOSED')
                        .map((item) => {
                            this.sumReceivables = this.sumReceivables + item.outstandingBalance;
                            return this.createRow(item, true);
                        });
                    this.amountReceivables = this.receivablesTable.data.length;
                }),
                switchMap(() => this.openItemsService.findLiabilities(this.ledgerId || '')),
                tap((liabilities: OpenItemDto[]) => {
                    this.liabilities = sortOpenItems(liabilities);
                    this.sumLiabilities = 0;
                    this.liabilitiesTable.data = this.liabilities
                        .filter((item) => item.state !== 'CLOSED')
                        .map((item) => {
                            this.sumLiabilities = this.sumLiabilities + item.outstandingBalance;
                            return this.createRow(item, false);
                        });
                    this.amountLiabilities = this.liabilitiesTable.data.length;
                    this.isLoading = false;
                    this.isInitialLoading = false;
                }),

                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }

    public onSelectFilter($event: any, isExpense: boolean): void {
        if (isExpense) {
            this.sumLiabilities = 0;
            this.selectedFilterLiabilitiesTable = $event.item.status;
            if ($event.item.status === 'open') {
                this.liabilitiesTable.data = this.liabilities
                    .filter((item) => item.state !== 'CLOSED')
                    .map((item) => {
                        this.sumLiabilities = this.sumLiabilities + item.outstandingBalance;
                        return this.createRow(item, false);
                    });
            } else {
                this.liabilitiesTable.data = this.liabilities
                    .filter((item) => {
                        return (
                            getStatusOpenItems(this.translateService, item.state, item.dueDate).statusType ===
                            $event.item.status
                        );
                    })
                    .map((item) => {
                        this.sumLiabilities = this.sumLiabilities + item.outstandingBalance;
                        return this.createRow(item, false);
                    });
            }
            this.amountLiabilities = this.liabilitiesTable.data.length;
        } else {
            this.sumReceivables = 0;
            this.selectedFilterReceivablesTable = $event.item.status;
            if ($event.item.status === 'open') {
                this.receivablesTable.data = this.receivables
                    .filter((item) => item.state !== 'CLOSED')
                    .map((item) => {
                        this.sumReceivables = this.sumReceivables + item.outstandingBalance;
                        return this.createRow(item, true);
                    });
            } else {
                this.receivablesTable.data = this.receivables
                    .filter((item) => {
                        return (
                            getStatusOpenItems(this.translateService, item.state, item.dueDate).statusType ===
                            $event.item.status
                        );
                    })
                    .map((item) => {
                        this.sumReceivables = this.sumReceivables + item.outstandingBalance;
                        return this.createRow(item, true);
                    });
            }
            this.amountReceivables = this.receivablesTable.data.length;
        }
    }

    private initTableHeaders(): void {
        this.liabilitiesTable.header = [
            'ENTITIES.OPEN_ITEM.STATUS',
            {
                data: { key: 'ENTITIES.OPEN_ITEM.DESCRIPTION', params: {} },
                width: '300px',
            },
            'ENTITIES.OPEN_ITEM.DUE_DATE',
            'ACCOUNTING.OPEN-ITEMS.CREATED_AT',
            {
                data: { key: 'ENTITIES.BOOKING.LABEL_TAX_SHARE', params: {} },
                width: '120px',
                template: HeaderTemplate.RightAligned,
            },
            {
                data: { key: 'ENTITIES.OPEN_ITEM.AMOUNT', params: {} },
                template: HeaderTemplate.RightAligned,
            },
            {
                data: { key: 'ENTITIES.OPEN_ITEM.OUTSTANDING_BALANCE', params: {} },
                template: HeaderTemplate.RightAligned,
            },
        ];
        this.receivablesTable.header = [
            'ENTITIES.OPEN_ITEM.STATUS',
            {
                data: { key: 'ENTITIES.OPEN_ITEM.DESCRIPTION', params: {} },
                width: '300px',
            },
            'ENTITIES.OPEN_ITEM.DUE_DATE',
            'ACCOUNTING.OPEN-ITEMS.CREATED_AT',
            {
                data: { key: 'ENTITIES.BOOKING.LABEL_TAX_SHARE', params: {} },
                width: '120px',
                template: HeaderTemplate.RightAligned,
            },
            {
                data: { key: 'ENTITIES.OPEN_ITEM.AMOUNT', params: {} },
                template: HeaderTemplate.RightAligned,
            },
            {
                data: { key: 'ENTITIES.OPEN_ITEM.OUTSTANDING_BALANCE', params: {} },
                template: HeaderTemplate.RightAligned,
            },
        ];
    }

    private createRow(openItem: OpenItemDto, isReceivables: boolean): TableItem[] {
        const link = `/accounting/ledger/${this.ledgerId}/open-items/${openItem.id}`;
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const dueDate = new Date(openItem.dueDate);
        dueDate.setHours(0, 0, 0, 0);

        const statusInfo: GetStatus = getStatusOpenItems(this.translateService, openItem.state, openItem.dueDate);
        const queryParams = {
            pageTitle: 'Merkposten Details',
            openItemType: isReceivables ? 'RECEIVABLE' : 'LIABILITY',
        };
        return [
            {
                data: {
                    label: statusInfo.status,
                    link,
                    textColor: statusInfo.color,
                    iconSrc: statusInfo.iconSrc,
                    extraData: { openItem, enableCustomSorting: true, queryParams },
                },
                template: CellTemplate.DynamicIcon,
            },
            {
                data: {
                    label: openItem.description,
                    link,
                    extraData: { queryParams },
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: openItem.dueDate,
                    link,
                    extraData: { queryParams },
                    textColor: dueDate <= today && openItem.state !== 'CLOSED' ? 's-red-01' : '',
                },
                template: CellTemplate.Date,
            },
            {
                data: {
                    label: openItem.createdAt,
                    link,
                    extraData: { queryParams },
                },
                template: CellTemplate.Date,
            },
            {
                data: {
                    label:
                        openItem.vatRate && openItem.vatAmount
                            ? `${this.eurocentPipe.transform(openItem.vatAmount)}€ (${openItem.vatRate}%)`
                            : '',
                    link,
                    rightAligned: true,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: openItem.amount,
                    link,
                    extraData: { queryParams },
                },
                template: CellTemplate.EuroCent,
            },
            {
                data: {
                    label: openItem.outstandingBalance,
                    link,
                    extraData: { queryParams },
                },
                template: CellTemplate.EuroCent,
            },
        ];
    }

    public openBookingOverlay(id: number, isExpense: boolean): void {
        const ref = this.overlayService.open(AddGuidedBookingOverlayComponent, {
            data: {
                ledgerId: this.ledgerId,
                selectedBooking: { id, isExpense },
            },
        });

        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.loadData());
    }

    public updateFilteredSum(filteredSearchTable: any): void {
        let summRef: 'sumLiabilities' | 'sumReceivables';
        if (filteredSearchTable.searchId === 'Verbindlichkeiten') {
            summRef = 'sumLiabilities';
        } else {
            summRef = 'sumReceivables';
        }

        this[summRef] = 0;
        filteredSearchTable.data.forEach((item: TableItem[]) => {
            const amountToAdd = item[5].data.label as number; // outstanding balance
            this[summRef] = this[summRef] + amountToAdd;
        });
    }

    public TooltipKey = TooltipKey;
}
