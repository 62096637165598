import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSerializer } from '@angular/router';
import { GeneralTermsComponent } from './core/components/general-terms/general-terms.component';
import { LegalNoticeComponent } from './core/components/legal-notice/legal-notice.component';
import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';
import { PrivacyPolicyComponent } from './core/components/privacy-policy/privacy-policy.component';
import { AuthGuard } from './core/guards/auth.guard';
import { RoleGuard } from './core/guards/role.guard';
import { ScalaraSubscriptionsGuard } from './core/guards/subscriptions.guard';
import { AuthService } from './core/services/auth.service';
import { CustomUrlSerializer } from './custom-url-serializer';
import { AccountSettingsComponent } from './features/account-settings/components/account-settings/account-settings.component';
import { MigrationOverviewComponent } from './features/account-settings/components/migration-overview/migration-overview.component';
import { PdfConfigurationsIndexComponent } from './features/account-settings/components/pfd-configurations/pdf-configurations-index/pdf-configurations-index.component';
import { SettingsDetailViewComponent } from './features/account-settings/components/settings-detail-view/settings-detail-view.component';
import { UsersOverviewComponent } from './features/account-settings/components/users-overview/users-overview.component';
import { AccountChartsComponent } from './features/accounting/components/accounts/account-charts/account-charts.component';
import { AccountDetailComponent } from './features/accounting/components/accounts/account-detail/account-detail.component';
import { AdvancementsOverviewComponent } from './features/accounting/components/advancements-overview/advancements-overview.component';
import { BankAccountDetailsComponent } from './features/accounting/components/bank-account-details/bank-account-details.component';
import { BankingAccountsIndexComponent } from './features/accounting/components/banking/banking-settings/banking-accounts-index/banking-accounts-index.component';
import { BankingPaymentDetailComponent } from './features/accounting/components/banking/payments/banking-payment-detail/banking-payment-detail.component';
import { BankingPaymentsBankConnectionApprovablePaymentsIndexComponent } from './features/accounting/components/banking/payments/banking-payments-bank-connection-approvable-payments-index/banking-payments-bank-connection-approvable-payments-index.component';
import { BankingPaymentsIndexComponent } from './features/accounting/components/banking/payments/banking-payments-index/banking-payments-index.component';
import { BankingPaymentsPaymentsApprovalsSelectionComponent } from './features/accounting/components/banking/payments/banking-payments-payments-approvals-selection/banking-payments-payments-approvals-selection.component';
import { BankingTransactionsDetailComponent } from './features/accounting/components/banking/transactions/banking-transactions-detail/banking-transactions-detail.component';
import { BankingTransactionsBookingRuleDetailComponent } from './features/accounting/components/banking/transactions/banking-transactions-index/banking-transactions-booking-rules/banking-transactions-booking-rule-detail/banking-transactions-booking-rule-detail.component';
import { BankingTransactionsIndexComponent } from './features/accounting/components/banking/transactions/banking-transactions-index/banking-transactions-index.component';
import { BookingDetailComponent } from './features/accounting/components/bookings/components/booking-detail/booking-detail.component';
import { BookingsIndexComponent } from './features/accounting/components/bookings/components/bookings-index/bookings-index.component';
import { OpenItemDetailsComponent } from './features/accounting/components/bookings/components/open-items/open-item-details/open-item-details.component';
import { OpenItemsIndexComponent } from './features/accounting/components/bookings/components/open-items/open-items-index/open-items-index.component';
import { AccountingDashboardComponent } from './features/accounting/components/dashboard/dashboard.component';
import { LedgerDetailViewComponent } from './features/accounting/components/ledger-detail-view/ledger-detail-view.component';
import { LedgerReasonabilityChecksComponent } from './features/accounting/components/ledger-reasonability-checks/ledger-reasonability-checks.component';
import { LedgerSettingsComponent } from './features/accounting/components/ledger-settings/ledger-settings.component';
import { MvIncomeAndExpenseIndexComponent } from './features/accounting/components/mv-income-and-expense/mv-income-and-expense-index.component';
import { DistributionKeyDetailComponent as OperationsExpenseStatementsDistributionKeyDetail } from './features/accounting/components/operations-expense-statements/distribution-key-detail/distribution-key-detail.component';
import { OperationsExpenseStatementsDetailsComponent } from './features/accounting/components/operations-expense-statements/operations-expense-statements-details/operations-expense-statements-details.component';
import { OperationsExpenseStatementsOverviewComponent } from './features/accounting/components/operations-expense-statements/operations-expense-statements-overview/operations-expense-statements-overview.component';
import { OverviewComponent as AccountingOverviewComponent } from './features/accounting/components/overview/overview.component';
import { ReceiptsIndexComponent } from './features/accounting/components/receipts/receipts-index/receipts-index.component';
import { WegEconomicPlanDetailsComponent } from './features/accounting/components/weg-economic-plan/weg-economic-plan-index/weg-economic-plan-details/weg-economic-plan-details.component';
import { WegEconomicPlanDistributionKeyDetailComponent } from './features/accounting/components/weg-economic-plan/weg-economic-plan-index/weg-economic-plan-details/weg-economic-plan-distribution-keys/weg-economic-plan-distribution-key-detail/weg-economic-plan-distribution-key-detail.component';
import { WegEconomicPlanIndexComponent } from './features/accounting/components/weg-economic-plan/weg-economic-plan-index/weg-economic-plan-index.component';
import { AnnualStatementDetailsComponent } from './features/annual-statement/annual-statement-details/annual-statement-details.component';
import { AnnualStatementDistributionKeyDetailsComponent } from './features/annual-statement/annual-statement-distribution-key-details/annual-statement-distribution-key-details.component';
import { AnnualStatementOverviewComponent } from './features/annual-statement/annual-statement-overview/annual-statement-overview.component';
import { AuthSuccessComponent } from './features/auth/components/auth-success/auth-success.component';
import { RegisterInvitationComponent } from './features/auth/components/register-invitation/register-invitation.component';
import { ResetPasswordGiveEmailComponent } from './features/auth/components/reset-password/reset-password-give-email/reset-password-give-email.component';
import { ResetPasswordSetPasswordComponent } from './features/auth/components/reset-password/reset-password-set-password/reset-password-set-password.component';
import { SignInComponent } from './features/auth/components/sign-in/sign-in.component';
import { SignUpComponent } from './features/auth/components/sign-up/sign-up.component';
import { TicketOverviewComponent } from './features/communication/components/ticket-overview/ticket-overview.component';
import { TicketsIndexComponent } from './features/communication/components/tickets-index/tickets-index.component';
import { ContactDetailViewComponent } from './features/contacts/components/contact-detail-view/contact-detail-view.component';
import { ContactsIndexComponent as ContactsDashboardComponent } from './features/contacts/components/contacts-index/contacts-index.component';
import { SepaMandatesOverviewComponent } from './features/contacts/components/sepa-mandates-overview/sepa-mandates-overview.component';
import { ListGeneratorDetailsComponent } from './features/list-generator/components/list-generator-details/list-generator-details.component';
import { ListGeneratorOverviewComponent } from './features/list-generator/components/list-generator-overview/list-generator-overview.component';
import { DepositOverviewComponent } from './features/occupation/components/deposit-overview/deposit-overview.component';
import { OccupationDetailsComponent } from './features/occupation/components/occupation-details/occupation-details.component';
import { OperationStatisticsOverviewComponent } from './features/operations/statistics/operation-statistics-overview/operation-statistics-overview.component';
import { AdvisersOverviewComponent } from './features/property/components/advisers-overview/advisers-overview.component';
import { DocumentsOverviewComponent } from './features/property/components/documents-overview/documents-overview.component';
import { OccupationsOverviewComponent } from './features/property/components/occupations-overview/occupations-overview.component';
import { OverviewComponent as PropertyOverviewComponent } from './features/property/components/overview/overview.component';
import { PropertyBuldingDetailComponent } from './features/property/components/property-base-data/building/property-bulding-detail/property-bulding-detail.component';
import { PropertyBaseDataIndexComponent } from './features/property/components/property-base-data/property-base-data-index/property-base-data-index.component';
import { PropertyDetailViewComponent } from './features/property/components/property-detail-view/property-detail-view.component';
import { PropertyOwnershipsComponent } from './features/property/components/property-ownerships/property-ownerships.component';
import { ServiceProvidersOverviewComponent } from './features/property/components/service-providers-overview/service-providers-overview.component';
import { MvUnitDetailsComponent } from './features/property/components/unit-ownership/mv-unit-details/mv-unit-details.component';
import { WegOwnershipDetailsComponent } from './features/property/components/unit-ownership/weg-ownership-details/weg-ownership-details.component';
import { SubscriptionsComponent } from './features/subscriptions/components/subscriptions/subscriptions.component';
import { ConfirmSurrogateComponent } from './features/surrogate/confirm-surrogate/confirm-surrogate.component';
import { SurrogateComponent } from './features/surrogate/surrogate.component';
import { AccountsViewComponent } from './features/user-management/components/accounts-view/accounts-view.component';

const routes: Routes = [
    { path: 'sign-in', component: SignInComponent, canActivate: [AuthGuard], data: { title: 'Login' } },
    { path: 'sign-up', component: SignUpComponent, canActivate: [AuthGuard], data: { title: 'Registrieren' } },
    { path: 'register', component: RegisterInvitationComponent, data: { title: 'Aktivierung' } },
    {
        path: 'reset-password/give-email',
        component: ResetPasswordGiveEmailComponent,
        canActivate: [AuthGuard],
        data: { title: 'Passwort Zurücksetzen' },
    },
    {
        path: 'reset-password/set-password',
        component: ResetPasswordSetPasswordComponent,
        canActivate: [AuthGuard],
        data: { title: 'Passwort Zurücksetzen' },
    },
    {
        path: 'auth-success',
        component: AuthSuccessComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'settings',
        component: SettingsDetailViewComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', redirectTo: 'account-settings', pathMatch: 'full' },
            {
                path: 'account-settings',
                component: AccountSettingsComponent,
                canActivate: [AuthGuard],
                data: { title: 'Einstellungen' },
            },
            {
                path: 'users',
                component: UsersOverviewComponent,
                canActivate: [AuthGuard],
                data: { title: 'Einstellungen' },
            },
            {
                path: 'migration',
                component: MigrationOverviewComponent,
                canActivate: [AuthGuard],
                data: { title: 'Einstellungen' },
            },
            {
                path: 'pdf-configurations',
                component: PdfConfigurationsIndexComponent,
                canActivate: [AuthGuard],
                data: { title: 'Einstellungen' },
            },
        ],
        data: { title: 'Einstellungen' },
    },
    { path: 'subscriptions', component: SubscriptionsComponent, canActivate: [AuthGuard], data: { title: 'Abo' } },
    { path: 'legal-notice', component: LegalNoticeComponent },
    { path: 'general-terms', component: GeneralTermsComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    {
        path: 'user-management/accounts',
        component: AccountsViewComponent,
        canActivate: [AuthGuard, ScalaraSubscriptionsGuard],
        data: { title: 'Bankverbindungen' },
    },
    {
        path: 'user-management/surrogate',
        component: SurrogateComponent,
        canActivate: [AuthGuard, ScalaraSubscriptionsGuard],
    },
    {
        path: 'user-management/operations',
        component: OperationStatisticsOverviewComponent,
        canActivate: [AuthGuard, ScalaraSubscriptionsGuard],
    },
    {
        path: 'user-management/surrogate/:personId',
        component: ConfirmSurrogateComponent,
        canActivate: [AuthGuard, ScalaraSubscriptionsGuard],
    },
    {
        path: 'properties',
        component: PropertyOverviewComponent,
        canActivate: [AuthGuard, ScalaraSubscriptionsGuard],
        data: { title: 'Immobilien' },
    },
    {
        path: 'properties/:id',
        component: PropertyDetailViewComponent,
        canActivate: [AuthGuard, ScalaraSubscriptionsGuard],
        children: [
            { path: '', redirectTo: 'base-data', pathMatch: 'full' },
            {
                path: 'base-data',
                component: PropertyBaseDataIndexComponent,
                canActivate: [AuthGuard, ScalaraSubscriptionsGuard],
            },
            {
                path: 'ownerships',
                component: PropertyOwnershipsComponent,
                canActivate: [AuthGuard, ScalaraSubscriptionsGuard],
            },
            {
                path: 'ownerships/:ownershipId',
                component: WegOwnershipDetailsComponent,
                canActivate: [AuthGuard, ScalaraSubscriptionsGuard],
            },
            {
                path: 'unit/:unitId',
                component: MvUnitDetailsComponent,
                canActivate: [AuthGuard, ScalaraSubscriptionsGuard],
            },
            {
                path: 'advisers',
                component: AdvisersOverviewComponent,
                canActivate: [AuthGuard, ScalaraSubscriptionsGuard],
            },
            {
                path: 'service-providers',
                component: ServiceProvidersOverviewComponent,
                canActivate: [AuthGuard, ScalaraSubscriptionsGuard],
            },
            {
                path: 'documents',
                component: DocumentsOverviewComponent,
                canActivate: [AuthGuard, ScalaraSubscriptionsGuard],
            },
            {
                path: 'occupations',
                component: OccupationsOverviewComponent,
                canActivate: [AuthGuard, ScalaraSubscriptionsGuard],
            },
            {
                path: 'occupations-deposit-overview',
                component: DepositOverviewComponent,
                canActivate: [AuthGuard, ScalaraSubscriptionsGuard],
            },
            {
                path: 'occupations/:occupationsId/:occupationStatus',
                component: OccupationDetailsComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'building-details/:buildingId',
                component: PropertyBuldingDetailComponent,
                canActivate: [AuthGuard, ScalaraSubscriptionsGuard],
            },
        ],
    },
    {
        path: 'accounting',
        component: AccountingOverviewComponent,
        canActivate: [AuthGuard, RoleGuard, ScalaraSubscriptionsGuard],
        data: { title: 'Buchhaltung' },
    },
    {
        path: 'accounting/payment-approvals',
        component: BankingPaymentsBankConnectionApprovablePaymentsIndexComponent,
        canActivate: [AuthGuard, RoleGuard, ScalaraSubscriptionsGuard],
    },
    {
        path: 'accounting/payment-approvals/:bankConnectionBIC',
        component: BankingPaymentsPaymentsApprovalsSelectionComponent,
        canActivate: [AuthGuard, RoleGuard, ScalaraSubscriptionsGuard],
    },
    {
        path: 'accounting/ledger/:id',
        component: LedgerDetailViewComponent,
        canActivate: [AuthGuard, RoleGuard, ScalaraSubscriptionsGuard],
        children: [
            { path: '', redirectTo: 'account-charts', pathMatch: 'full', data: { title: 'Buchhaltung' } },
            {
                path: 'dashboard',
                component: AccountingDashboardComponent,
                canActivate: [AuthGuard, RoleGuard, ScalaraSubscriptionsGuard],
            },
            {
                path: 'account-charts',
                component: AccountChartsComponent,
                canActivate: [AuthGuard, RoleGuard, ScalaraSubscriptionsGuard],
            },
            {
                path: 'bookings',
                component: BookingsIndexComponent,
                canActivate: [AuthGuard, RoleGuard, ScalaraSubscriptionsGuard],
            },
            {
                path: 'bookings/:bookingId',
                component: BookingDetailComponent,
                canActivate: [AuthGuard, RoleGuard, ScalaraSubscriptionsGuard],
            },
            {
                path: 'bank-accounts',
                component: BankingAccountsIndexComponent,
                canActivate: [AuthGuard, RoleGuard, ScalaraSubscriptionsGuard],
            },
            {
                path: 'bank-accounts/:bankAccountId',
                component: BankAccountDetailsComponent,
                canActivate: [AuthGuard, RoleGuard, ScalaraSubscriptionsGuard],
            },
            {
                path: 'bank-accounts/:bankAccountId/bank-transactions/:bankTransactionId',
                component: BankingTransactionsDetailComponent,
                canActivate: [AuthGuard, RoleGuard, ScalaraSubscriptionsGuard],
            },
            {
                path: 'account-charts/:accountId',
                component: AccountDetailComponent,
                canActivate: [AuthGuard, RoleGuard, ScalaraSubscriptionsGuard],
            },
            {
                path: 'receipts',
                component: ReceiptsIndexComponent,
                canActivate: [AuthGuard, RoleGuard, ScalaraSubscriptionsGuard],
            },

            {
                path: 'bank-transactions',
                component: BankingTransactionsIndexComponent,
                canActivate: [AuthGuard, RoleGuard, ScalaraSubscriptionsGuard],
            },
            {
                path: 'bank-transactions/:bankTransactionId/bank-accounts/:bankAccountId',
                component: BankingTransactionsDetailComponent,
                canActivate: [AuthGuard, RoleGuard, ScalaraSubscriptionsGuard],
            },
            {
                path: 'bank-transactions/booking-rules/:bookingRuleId',
                component: BankingTransactionsBookingRuleDetailComponent,
                canActivate: [AuthGuard, RoleGuard, ScalaraSubscriptionsGuard],
            },
            {
                path: 'economic-plans',
                component: WegEconomicPlanIndexComponent,
                canActivate: [AuthGuard, RoleGuard, ScalaraSubscriptionsGuard],
            },
            {
                path: 'economic-plans/:economicPlanId',
                component: WegEconomicPlanDetailsComponent,
                canActivate: [AuthGuard, RoleGuard, ScalaraSubscriptionsGuard],
            },
            {
                path: 'economic-plans/:economicPlanId/distribution-keys/:distributionKeyId',
                component: WegEconomicPlanDistributionKeyDetailComponent,
                canActivate: [AuthGuard, RoleGuard, ScalaraSubscriptionsGuard],
            },
            {
                path: 'annual-statements',
                component: AnnualStatementOverviewComponent,
                canActivate: [AuthGuard, RoleGuard, ScalaraSubscriptionsGuard],
            },
            {
                path: 'annual-statements/:annualStatementId',
                component: AnnualStatementDetailsComponent,
                canActivate: [AuthGuard, RoleGuard, ScalaraSubscriptionsGuard],
            },
            {
                path: 'annual-statements/:annualStatementId/distribution-keys/:distributionKeyId',
                component: AnnualStatementDistributionKeyDetailsComponent,
                canActivate: [AuthGuard, RoleGuard, ScalaraSubscriptionsGuard],
            },
            {
                path: 'open-items',
                component: OpenItemsIndexComponent,
                canActivate: [AuthGuard, RoleGuard, ScalaraSubscriptionsGuard],
            },
            {
                path: 'open-items/:openItemId',
                component: OpenItemDetailsComponent,
                canActivate: [AuthGuard, RoleGuard, ScalaraSubscriptionsGuard],
            },
            {
                path: 'advancements',
                component: AdvancementsOverviewComponent,
                canActivate: [AuthGuard, RoleGuard, ScalaraSubscriptionsGuard],
            },
            {
                path: 'advancements/:openItemId',
                component: OpenItemDetailsComponent,
                canActivate: [AuthGuard, RoleGuard, ScalaraSubscriptionsGuard],
            },
            {
                path: 'operations-expense-statements',
                component: OperationsExpenseStatementsOverviewComponent,
                canActivate: [AuthGuard, RoleGuard, ScalaraSubscriptionsGuard],
            },
            {
                path: 'operations-expense-statements/:oesId',
                component: OperationsExpenseStatementsDetailsComponent,
                canActivate: [AuthGuard, RoleGuard, ScalaraSubscriptionsGuard],
            },
            {
                path: 'operations-expense-statements/:oesId/distribution-key/:distributionKeyId',
                component: OperationsExpenseStatementsDistributionKeyDetail,
                canActivate: [AuthGuard, RoleGuard, ScalaraSubscriptionsGuard],
            },
            {
                path: 'payments',
                component: BankingPaymentsIndexComponent,
                canActivate: [AuthGuard, RoleGuard, ScalaraSubscriptionsGuard],
            },
            {
                path: 'payments/:paymentId',
                component: BankingPaymentDetailComponent,
                canActivate: [AuthGuard, RoleGuard, ScalaraSubscriptionsGuard],
            },
            {
                path: 'payment-approvals',
                component: BankingPaymentsBankConnectionApprovablePaymentsIndexComponent,
                canActivate: [AuthGuard, RoleGuard, ScalaraSubscriptionsGuard],
            },
            {
                path: 'payment-approvals/:bankConnectionBIC',
                component: BankingPaymentsPaymentsApprovalsSelectionComponent,
                canActivate: [AuthGuard, RoleGuard, ScalaraSubscriptionsGuard],
            },
            {
                path: 'economics',
                component: MvIncomeAndExpenseIndexComponent,
                canActivate: [AuthGuard, RoleGuard, ScalaraSubscriptionsGuard],
            },
            {
                path: 'reasonability-checks',
                component: LedgerReasonabilityChecksComponent,
                canActivate: [AuthGuard, RoleGuard, ScalaraSubscriptionsGuard],
            },
            {
                path: 'ledger-settings',
                component: LedgerSettingsComponent,
                canActivate: [AuthGuard, RoleGuard, ScalaraSubscriptionsGuard],
            },
        ],
    },
    {
        path: 'communications',
        component: TicketsIndexComponent,
        canActivate: [AuthGuard, ScalaraSubscriptionsGuard],
        data: { title: 'Kommunikation' },
    },
    {
        path: 'communications/:ticketId',
        component: TicketOverviewComponent,
        canActivate: [AuthGuard, ScalaraSubscriptionsGuard],
    },
    {
        path: 'contacts',
        component: ContactsDashboardComponent,
        canActivate: [AuthGuard, ScalaraSubscriptionsGuard],
        data: { title: 'Kontakte' },
    },
    {
        path: 'contacts/:personId',
        component: ContactDetailViewComponent,
        canActivate: [AuthGuard, ScalaraSubscriptionsGuard],
        data: { title: 'Person' },
    },
    {
        path: 'contacts/:personId/sepaMandates',
        component: SepaMandatesOverviewComponent,
        canActivate: [AuthGuard, ScalaraSubscriptionsGuard],
    },
    {
        path: 'my-profile/:personId',
        component: ContactDetailViewComponent,
        canActivate: [AuthGuard],
        data: { title: 'Mein Profil' },
    },
    {
        path: 'my-profile/:personId/sepaMandates',
        component: SepaMandatesOverviewComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'list-generator',
        component: ListGeneratorOverviewComponent,
        canActivate: [AuthGuard, ScalaraSubscriptionsGuard],
        data: { title: 'Berichte' },
    },
    {
        path: 'list-generator/details',
        component: ListGeneratorDetailsComponent,
        canActivate: [AuthGuard, ScalaraSubscriptionsGuard],
    },
    { path: '', redirectTo: 'properties', pathMatch: 'full' },
    { path: '**', component: PageNotFoundComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [AuthService, { provide: UrlSerializer, useClass: CustomUrlSerializer }],
})
export class AppRoutingModule {}
