import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { filter, map, Observable } from 'rxjs';
import { PersonLocalService } from 'src/app/features/property/services/person-local.service';

@Injectable({
    providedIn: 'root',
})
export class RoleGuard implements CanActivate {
    public constructor(private router: Router, private personsLocalService: PersonLocalService) {}

    public canActivate(): Observable<boolean> {
        return this.personsLocalService.getPerson$().pipe(
            // filter out default null value of BehaviorSubject
            filter(Boolean),
            map((person) => {
                const isPropertyManager = person.personRoles.includes('IS_PROPERTY_MANAGER');
                const isOwner = person.personRoles.includes('IS_OWNER');

                const isAllowed = isOwner || isPropertyManager;
                if (isAllowed) {
                    return true;
                }
                this.router.navigate(['/properties']);
                return false;
            })
        );
    }
}
