import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AngularIbanModule } from 'angular-iban';
import { AngularSvgIconModule } from 'angular-svg-icon';
import {
    BreadcrumbModule,
    ButtonModule,
    CheckboxModule,
    DatePickerModule,
    DialogModule,
    DropdownModule,
    GridModule,
    InlineLoadingModule,
    InputModule,
    LoadingModule,
    NotificationModule,
    NumberModule,
    RadioModule,
    SelectModule,
    TableModule,
    TabsModule,
} from 'carbon-components-angular';
import { NgChartsModule } from 'ng2-charts';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxViewerModule } from 'ngx-viewer';
import { EurocentPipe } from 'src/app/shared/pipes/eurocent.pipe';
import { SharedModule } from 'src/app/shared/shared.module';
import { AccoutingDatePickerSelectorComponent } from './components/accounting-date-picker-selector/accounting-date-picker-selector.component';
import { AccountBookingCalculationSelectorComponent } from './components/accounts/account-booking-calculation-selector/account-booking-calculation-selector.component';
import { AccountChartsComponent } from './components/accounts/account-charts/account-charts.component';
import { AccountDetailComponent } from './components/accounts/account-detail/account-detail.component';
import { AddAccountFormComponent } from './components/add-account-form/add-account-form.component';
import { AddBankAccountRecommendationOverlayComponent } from './components/add-bank-account-recommendation-overlay/add-bank-account-recommendation-overlay.component';
import { AddBankTransactionComponent } from './components/add-bank-transaction/add-bank-transaction.component';
import { AddLedgerFormComponent } from './components/add-ledger-form/add-ledger-form.component';
import { AddManuallyBankAccountComponent } from './components/add-manually-bank-account/add-manually-bank-account.component';
import { AddReserveFormComponent } from './components/add-reserve-form/add-reserve-form.component';
import { AdjustAdvancesComponent } from './components/adjust-advances/adjust-advances.component';
import { AdvancementsOverviewComponent } from './components/advancements-overview/advancements-overview.component';
import { BankAccountDetailsComponent } from './components/bank-account-details/bank-account-details.component';
import { BankConnectionExpiredNotificationComponent } from './components/bank-connection-expired-notification/bank-connection-expired-notification.component';
import { BankingAccountsAccountsComponent } from './components/banking/banking-settings/banking-accounts-index/banking-accounts-accounts/banking-accounts-accounts.component';
import { BankingAccountsIndexComponent } from './components/banking/banking-settings/banking-accounts-index/banking-accounts-index.component';
import { BankingDebitConfigurationComponent } from './components/banking/banking-settings/banking-debit-configuration/banking-debit-configuration.component';
import { BankingPaymentDeleteOverlayComponent } from './components/banking/payments/banking-payment-delete-overlay/banking-payment-delete-overlay.component';
import { BankingPaymentDetailComponent } from './components/banking/payments/banking-payment-detail/banking-payment-detail.component';
import { BankingPaymentsAddEditOverlayComponent } from './components/banking/payments/banking-payments-add-edit-overlay/banking-payments-add-edit-overlay.component';
import { BankingPaymentsBankConnectionApprovablePaymentsIndexComponent } from './components/banking/payments/banking-payments-bank-connection-approvable-payments-index/banking-payments-bank-connection-approvable-payments-index.component';
import { BankingPaymentsIndexComponent } from './components/banking/payments/banking-payments-index/banking-payments-index.component';
import { BankingPaymentsApprovalTableComponent } from './components/banking/payments/banking-payments-payments-approvals-selection/bank-payment-approvals/banking-payments-approval-table/banking-payments-approval-table.component';
import { BankingPaymentsPaymentsApprovalsSelectionComponent } from './components/banking/payments/banking-payments-payments-approvals-selection/banking-payments-payments-approvals-selection.component';
import { BankingTransactionDetailCardComponent } from './components/banking/transactions/banking-transaction-detail-card/banking-transaction-detail-card.component';
import { BankingTransactionsDetailComponent } from './components/banking/transactions/banking-transactions-detail/banking-transactions-detail.component';
import { AddEditBankingTransactionsBookingRulesOverlayComponent } from './components/banking/transactions/banking-transactions-index/banking-transactions-booking-rules/add-edit-banking-transactions-booking-rules-overlay/add-edit-banking-transactions-booking-rules-overlay.component';
import { BankingTransactionsBookingRuleDetailComponent } from './components/banking/transactions/banking-transactions-index/banking-transactions-booking-rules/banking-transactions-booking-rule-detail/banking-transactions-booking-rule-detail.component';
import { BankingTransactionsBookingRulesIndexComponent } from './components/banking/transactions/banking-transactions-index/banking-transactions-booking-rules/banking-transactions-booking-rules-index/banking-transactions-booking-rules-index.component';
import { BankingTransactionsIndexComponent } from './components/banking/transactions/banking-transactions-index/banking-transactions-index.component';
import { BankingTransactionsRecommendedBookingsComponent } from './components/banking/transactions/banking-transactions-index/banking-transactions-recommended-bookings/banking-transactions-recommended-bookings.component';
import { BankingTransactionsTransactionsComponent } from './components/banking/transactions/banking-transactions-index/banking-transactions-transactions/banking-transactions-transactions.component';
import { BankingTransactionsMarkBookedOverlayComponent } from './components/banking/transactions/banking-transactions-mark-booked-overlay/banking-transactions-mark-booked-overlay.component';
import { AddBookingSelectionOverlayComponent } from './components/bookings/components/add-booking-selection-overlay/add-booking-selection-overlay.component';
import { AddGuidedBookingOverlayComponent } from './components/bookings/components/add-guided-booking-overlay/add-guided-booking-overlay.component';
import { BookingRuleSuggestionCardComponent } from './components/bookings/components/add-guided-booking-overlay/booking-rule-suggestion-card/booking-rule-suggestion-card.component';
import { BookingDetailComponent } from './components/bookings/components/booking-detail/booking-detail.component';
import { BookingsIndexComponent } from './components/bookings/components/bookings-index/bookings-index.component';
import { CancellationBookingOverlayComponent } from './components/bookings/components/cancellation-booking-overlay/cancellation-booking-overlay.component';
import { OpenItemDeleteOverlayComponent } from './components/bookings/components/open-items/open-item-delete-overlay/open-item-delete-overlay.component';
import { AddBookingItemFormComponent } from './components/bookings/shared/add-booking-item-form/add-booking-item-form.component';
import { BookingTableComponent } from './components/bookings/shared/booking-table/booking-table.component';
import { BusinessYearSelectorComponent } from './components/business-year-selector/business-year-selector.component';
import { AccountingDashboardComponent } from './components/dashboard/dashboard.component';
import { DetailsContainerComponent } from './components/details-container/details-container.component';
import { ReceiptsAddReceiptOverlayComponent } from './components/receipts/receipts-add-receipt-overlay/receipts-add-receipt-overlay.component';
import { WegEconomicPlanAddComponent } from './components/weg-economic-plan/weg-economic-plan-index/weg-economic-plan-add/weg-economic-plan-add.component';
import { WegEconomicPlanActivateComponent } from './components/weg-economic-plan/weg-economic-plan-index/weg-economic-plan-details/weg-economic-plan-activate/weg-economic-plan-activate.component';
import { WegEconomicPlanDistributionKeyAddComponent } from './components/weg-economic-plan/weg-economic-plan-index/weg-economic-plan-details/weg-economic-plan-distribution-keys/weg-economic-plan-distribution-key-add/weg-economic-plan-distribution-key-add.component';
import { WegEconomicPlanDistributionKeyDeleteComponent } from './components/weg-economic-plan/weg-economic-plan-index/weg-economic-plan-details/weg-economic-plan-distribution-keys/weg-economic-plan-distribution-key-delete/weg-economic-plan-distribution-key-delete.component';
import { WegEconomicPlanDistributionKeyDetailComponent } from './components/weg-economic-plan/weg-economic-plan-index/weg-economic-plan-details/weg-economic-plan-distribution-keys/weg-economic-plan-distribution-key-detail/weg-economic-plan-distribution-key-detail.component';
import { WegEconomicPlanDistributionKeysComponent } from './components/weg-economic-plan/weg-economic-plan-index/weg-economic-plan-details/weg-economic-plan-distribution-keys/weg-economic-plan-distribution-keys.component';

import { WegEconomicPlanDetailsTableComponent } from './components/weg-economic-plan/weg-economic-plan-index/weg-economic-plan-details/weg-economic-plan-details-tab/weg-economic-plan-details-table/weg-economic-plan-details-table.component';

import { FileSelectorComponent } from './components/file-selector/file-selector.component';

// // import { ScalaraComponentsModule } from 'ng-components/dist/scalara-components';
// import { ScalaraComponentsModule } from 'scalara-components';
import { OpenItemDetailsCardComponent } from './components/bookings/components/open-items/open-item-details/open-item-details-card/open-item-details-card.component';
import { OpenItemDetailsComponent } from './components/bookings/components/open-items/open-item-details/open-item-details.component';
import { OpenItemsIndexComponent } from './components/bookings/components/open-items/open-items-index/open-items-index.component';
import { BookingsTableFilterComponent } from './components/bookings/shared/bookings-table-filter/bookings-table-filter.component';
import { DashboardDueOpenItemsComponent } from './components/dashboard/dashboard-due-open-items/dashboard-due-open-items.component';
import { LedgerDetailViewComponent } from './components/ledger-detail-view/ledger-detail-view.component';
import { LedgerReasonabilityChecksAddAdditionalBankaccountComponent } from './components/ledger-reasonability-checks/ledger-reasonability-checks-add-additional-bankaccount/ledger-reasonability-checks-add-additional-bankaccount.component';
import { LedgerReasonabilityChecksDeleteRentReceivablesComponent } from './components/ledger-reasonability-checks/ledger-reasonability-checks-delete-rent-receivables/ledger-reasonability-checks-delete-rent-receivables.component';
import { LedgerReasonabilityChecksRegenerateRentReceivablesComponent } from './components/ledger-reasonability-checks/ledger-reasonability-checks-regenerate-rent-receivables/ledger-reasonability-checks-regenerate-rent-receivables.component';
import { LedgerReasonabilityChecksSetStartAmountComponent } from './components/ledger-reasonability-checks/ledger-reasonability-checks-set-start-amount/ledger-reasonability-checks-set-start-amount.component';
import { LedgerReasonabilityChecksComponent } from './components/ledger-reasonability-checks/ledger-reasonability-checks.component';
import { LedgerSettingsComponent } from './components/ledger-settings/ledger-settings.component';
import { MvIncomeAndExpenseIaeTabComponent } from './components/mv-income-and-expense/mv-income-and-expense-iae-tab/mv-income-and-expense-iae-tab.component';
import { MvIncomeAndExpenseIndexComponent } from './components/mv-income-and-expense/mv-income-and-expense-index.component';
import { MvIncomeAndExpenseTaxReportTabComponent } from './components/mv-income-and-expense/mv-income-and-expense-tax-report-tab/mv-income-and-expense-tax-report-tab.component';
import { OpeningBalanceComponent } from './components/opening-balance/opening-balance.component';
import { ActivateOesComponent } from './components/operations-expense-statements/activate-oes/activate-oes.component';
import { AddAccountDistributionCostsComponent } from './components/operations-expense-statements/add-account-distribution-costs/add-account-distribution-costs.component';
import { AddConsumptionDistributionComponent } from './components/operations-expense-statements/add-consumption-distribution/add-consumption-distribution.component';
import { AddOesDistributionKeyComponent } from './components/operations-expense-statements/add-oes-distribution-key/add-oes-distribution-key.component';
import { AddOperationsExpenseStatementsComponent } from './components/operations-expense-statements/add-operations-expense-statements/add-operations-expense-statements.component';
import { DeleteOesDistributionKeyComponent } from './components/operations-expense-statements/delete-oes-distribution-key/delete-oes-distribution-key.component';
import { DeleteOesOccupationDistributionComponent } from './components/operations-expense-statements/delete-oes-occupation-distribution/delete-oes-occupation-distribution.component';
import { DeleteOperationsExpenseStatementsComponent } from './components/operations-expense-statements/delete-operations-expense-statements/delete-operations-expense-statements.component';
import { DistributionKeyDetailComponent as DistributionKeyOperationsExpenseStatementsDetailComponent } from './components/operations-expense-statements/distribution-key-detail/distribution-key-detail.component';
import { EditOperationExpenseStatementComponent } from './components/operations-expense-statements/edit-operation-expense-statement/edit-operation-expense-statement.component';
import { OesCarryoverCostsComponent } from './components/operations-expense-statements/oes-carryover-costs/oes-carryover-costs.component';
import { OesIndividualCostsForOccupationComponent } from './components/operations-expense-statements/oes-individual-costs-for-occupation/oes-individual-costs-for-occupation.component';
import { OesSelectBankAccountComponent } from './components/operations-expense-statements/oes-select-bank-account/oes-select-bank-account.component';
import { OesStatementsTabComponent } from './components/operations-expense-statements/oes-statements-tab/oes-statements-tab.component';
import { OperationsExpenseStatementsConsumptionTabComponent } from './components/operations-expense-statements/operations-expense-statements-consumption-tab/operations-expense-statements-consumption-tab.component';
import { OperationsExpenseStatementsDetailsComponent } from './components/operations-expense-statements/operations-expense-statements-details/operations-expense-statements-details.component';
import { OperationsExpenseStatementsDistributionKeyTabComponent } from './components/operations-expense-statements/operations-expense-statements-distribution-key-tab/operations-expense-statements-distribution-key-tab.component';
import { OperationsExpenseStatementsOverviewComponent } from './components/operations-expense-statements/operations-expense-statements-overview/operations-expense-statements-overview.component';
import { OverviewComponent } from './components/overview/overview.component';
import { ReceiptsDeleteReceiptOverlayComponent } from './components/receipts/receipts-delete-receipt-overlay/receipts-delete-receipt-overlay.component';
import { ReceiptsIndexComponent } from './components/receipts/receipts-index/receipts-index.component';
import { WegEconomicPlanDeleteComponent } from './components/weg-economic-plan/weg-economic-plan-index/weg-economic-plan-details/weg-economic-plan-delete/weg-economic-plan-delete.component';
import { WegEconomicPlanDetailsTabComponent } from './components/weg-economic-plan/weg-economic-plan-index/weg-economic-plan-details/weg-economic-plan-details-tab/weg-economic-plan-details-tab.component';
import { WegEconomicPlanDetailsComponent } from './components/weg-economic-plan/weg-economic-plan-index/weg-economic-plan-details/weg-economic-plan-details.component';
import { WegEconomicPlanEditComponent } from './components/weg-economic-plan/weg-economic-plan-index/weg-economic-plan-details/weg-economic-plan-edit/weg-economic-plan-edit.component';
import { WegEconomicPlanIndividualPlanIndexComponent } from './components/weg-economic-plan/weg-economic-plan-index/weg-economic-plan-details/weg-economic-plan-individual-plan-index/weg-economic-plan-individual-plan-index.component';
import { WegEconomicPlanIndividualPlanTabComponent } from './components/weg-economic-plan/weg-economic-plan-index/weg-economic-plan-details/weg-economic-plan-individual-plan-index/weg-economic-plan-individual-plan-tab/weg-economic-plan-individual-plan-tab.component';
import { WegEconomicPlanResetOverlayComponent } from './components/weg-economic-plan/weg-economic-plan-index/weg-economic-plan-details/weg-economic-plan-reset-overlay/weg-economic-plan-reset-overlay.component';
import { WegEconomicPlanIndexComponent } from './components/weg-economic-plan/weg-economic-plan-index/weg-economic-plan-index.component';
@NgModule({
    declarations: [
        OverviewComponent,
        AddLedgerFormComponent,
        LedgerDetailViewComponent,
        AccountChartsComponent,
        AddGuidedBookingOverlayComponent,
        BookingsIndexComponent,
        BookingDetailComponent,
        AddBookingItemFormComponent,
        BankAccountDetailsComponent,
        AccountDetailComponent,
        BankingTransactionsDetailComponent,
        BankingAccountsAccountsComponent,
        AddBookingSelectionOverlayComponent,
        OpenItemDetailsComponent,
        OpenItemDetailsCardComponent,
        BookingTableComponent,
        AddAccountFormComponent,
        AccountingDashboardComponent,
        ReceiptsIndexComponent,
        ReceiptsAddReceiptOverlayComponent,
        DetailsContainerComponent,
        BankingTransactionDetailCardComponent,
        BankingTransactionsIndexComponent,
        OpeningBalanceComponent,
        AddReserveFormComponent,
        FileSelectorComponent,
        WegEconomicPlanIndexComponent,
        WegEconomicPlanDetailsComponent,
        WegEconomicPlanDeleteComponent,
        WegEconomicPlanDetailsComponent,
        WegEconomicPlanAddComponent,
        CancellationBookingOverlayComponent,
        WegEconomicPlanDetailsTableComponent,
        WegEconomicPlanDetailsTabComponent,
        WegEconomicPlanEditComponent,
        WegEconomicPlanActivateComponent,
        WegEconomicPlanDetailsComponent,
        WegEconomicPlanDistributionKeysComponent,
        WegEconomicPlanDistributionKeyAddComponent,
        WegEconomicPlanDistributionKeyDetailComponent,
        WegEconomicPlanDistributionKeyDeleteComponent,
        OpenItemsIndexComponent,
        AdvancementsOverviewComponent,
        OpenItemDeleteOverlayComponent,
        AdjustAdvancesComponent,
        AccoutingDatePickerSelectorComponent,
        BankConnectionExpiredNotificationComponent,
        WegEconomicPlanIndividualPlanTabComponent,
        OperationsExpenseStatementsOverviewComponent,
        AddOperationsExpenseStatementsComponent,
        OperationsExpenseStatementsDetailsComponent,
        DeleteOperationsExpenseStatementsComponent,
        OperationsExpenseStatementsConsumptionTabComponent,
        AddConsumptionDistributionComponent,
        DistributionKeyOperationsExpenseStatementsDetailComponent,
        DeleteOesDistributionKeyComponent,
        OperationsExpenseStatementsDistributionKeyTabComponent,
        AddOesDistributionKeyComponent,
        EditOperationExpenseStatementComponent,
        AddAccountDistributionCostsComponent,
        OesSelectBankAccountComponent,
        OesCarryoverCostsComponent,
        OesStatementsTabComponent,
        OesIndividualCostsForOccupationComponent,
        DeleteOesOccupationDistributionComponent,
        ActivateOesComponent,
        ReceiptsDeleteReceiptOverlayComponent,
        WegEconomicPlanIndividualPlanIndexComponent,
        BankingTransactionsTransactionsComponent,
        BankingTransactionsRecommendedBookingsComponent,
        AddManuallyBankAccountComponent,
        AddBankTransactionComponent,
        AddBankAccountRecommendationOverlayComponent,
        BankingPaymentsIndexComponent,
        BankingPaymentsAddEditOverlayComponent,
        BankingPaymentsBankConnectionApprovablePaymentsIndexComponent,
        BankingPaymentsPaymentsApprovalsSelectionComponent,
        BankingTransactionsMarkBookedOverlayComponent,
        BankingAccountsIndexComponent,
        BankingPaymentDetailComponent,
        BankingPaymentDeleteOverlayComponent,
        BankingPaymentsApprovalTableComponent,
        DashboardDueOpenItemsComponent,
        BankingDebitConfigurationComponent,
        BookingsTableFilterComponent,
        WegEconomicPlanResetOverlayComponent,
        BankingTransactionsBookingRulesIndexComponent,
        AddEditBankingTransactionsBookingRulesOverlayComponent,
        BankingTransactionsBookingRuleDetailComponent,
        MvIncomeAndExpenseIndexComponent,
        MvIncomeAndExpenseIaeTabComponent,
        MvIncomeAndExpenseTaxReportTabComponent,
        BookingRuleSuggestionCardComponent,
        AccountBookingCalculationSelectorComponent,
        LedgerReasonabilityChecksComponent,
        BusinessYearSelectorComponent,
        LedgerReasonabilityChecksAddAdditionalBankaccountComponent,
        LedgerReasonabilityChecksSetStartAmountComponent,
        LedgerReasonabilityChecksRegenerateRentReceivablesComponent,
        LedgerReasonabilityChecksDeleteRentReceivablesComponent,
        LedgerSettingsComponent,
    ],
    exports: [AccoutingDatePickerSelectorComponent, BusinessYearSelectorComponent],
    imports: [
        CommonModule,
        TranslateModule,
        TableModule,
        GridModule,
        ButtonModule,
        LoadingModule,
        InlineLoadingModule,
        DatePickerModule,
        SelectModule,
        FormsModule,
        ReactiveFormsModule,
        NotificationModule,
        SharedModule,
        RouterModule,
        DropdownModule,
        InputModule,
        NumberModule,
        AngularSvgIconModule,
        BreadcrumbModule,
        DialogModule,
        CheckboxModule,
        RadioModule,
        ImageCropperModule,
        NgxExtendedPdfViewerModule,
        NgxViewerModule,
        TabsModule,
        AngularIbanModule,
        NgChartsModule,
    ],
    providers: [EurocentPipe, DatePipe],
})
export class AccountingModule {}
