import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
    BehaviorSubject,
    Subject,
    combineLatest,
    distinctUntilChanged,
    filter,
    map,
    of,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs';
import { PropertyCustomService } from 'src/app/features/property/services/property-custom.service';
import { EnergyCertificate, EnergyCertificatesService } from 'src/app/generated-sources/base';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { AddEditEnergyCertificateOverlayComponent } from '../add-edit-energy-certificate-overlay/add-edit-energy-certificate-overlay.component';
import { DeleteEnergyCertificateOverlayComponent } from '../delete-energy-certificate-overlay/delete-energy-certificate-overlay.component';

@Component({
    selector: 'app-energy-certificate-index',
    templateUrl: './energy-certificate-index.component.html',
    styleUrls: ['./energy-certificate-index.component.scss'],
})
export class EnergyCertificateIndexComponent implements OnInit, OnDestroy {
    public buildingId = '';
    public propertyId = '';

    public buildingId$ = this.route.url.pipe(
        map(() => {
            const buildingId = this.route.snapshot.paramMap.get('buildingId');
            this.buildingId = buildingId ?? '';
            return buildingId;
        }),
        filter(Boolean),
        distinctUntilChanged()
    );

    public propertyId$ = this.propertyCustomService.getPropertyId$().pipe(
        filter(Boolean),
        tap((propertyId) => {
            this.propertyId = propertyId ?? '';
        })
    );

    public refresh$ = new BehaviorSubject<void>(undefined);
    public energyCertificates: EnergyCertificate[] = [];

    public energycertificate$ = combineLatest([this.propertyId$, this.buildingId$, this.refresh$]).pipe(
        switchMap(([propertyId, buildingId]) => {
            if (propertyId && buildingId) {
                return this.energyCertificatesService.findAll(propertyId, buildingId);
            }

            return of();
        }),
        tap((data) => {
            this.energyCertificates = data;
        })
    );

    private unsubscribe$ = new Subject<void>();

    public constructor(
        private overlayService: OverlayService,
        private energyCertificatesService: EnergyCertificatesService,
        private route: ActivatedRoute,
        private propertyCustomService: PropertyCustomService
    ) {}

    public ngOnInit(): void {
        this.energycertificate$.subscribe();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public openAddEditEnergyCertificateOverlay(energyCertificate?: EnergyCertificate): void {
        const ref = this.overlayService.open(AddEditEnergyCertificateOverlayComponent, {
            data: {
                buildingId: this.buildingId,
                propertyId: this.propertyId,
                energyCertificateToEdit: energyCertificate,
            },
        });

        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.refresh$.next());
    }

    public openDeleteEnergyCertificate(energyCertificate: EnergyCertificate): void {
        const ref = this.overlayService.open(DeleteEnergyCertificateOverlayComponent, {
            data: { buildingId: this.buildingId, propertyId: this.propertyId, energyCertificate: energyCertificate },
        });

        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.refresh$.next());
    }
}
