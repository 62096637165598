import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ListItem } from 'carbon-components-angular';
import { Subject, filter, switchMap, takeUntil, tap } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { formControl, formControlHasError } from 'src/app/core/utils/common';
import { PropertyCustomService } from 'src/app/features/property/services/property-custom.service';
import {
    Building,
    BuildingsService,
    CreateSharedOwnershipDto,
    OwnershipsService,
    SharedOwnership,
    SharedOwnershipService,
} from 'src/app/generated-sources/base';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';

@Component({
    selector: 'app-add-edit-shared-ownership-overlay',
    templateUrl: './add-edit-shared-ownership-overlay.component.html',
    styleUrls: ['./add-edit-shared-ownership-overlay.component.scss'],
})
export class AddEditSharedOwnershipOverlayComponent extends OverlayChildComponent implements OnInit, OnDestroy {
    public unsubscribe$ = new Subject<void>();

    public currentBuilding?: Building;
    public propertyId = '';
    public isLoading = false;
    public sharedOwnershipToEdit?: SharedOwnership;
    public buildingList: ListItem[] = [];
    public ownershipList: ListItem[] = [];
    public sharedOwnershipCategoriesList: ListItem[] = [];

    public form = this.formBuilder.group({
        category: [],
        title: [],
        hasSpecialRightsToBuildingProperty: [],
        building: ['', Validators.required],
        ownership: [''],
    });

    public propertyId$ = this.propertyCustomService.getPropertyId$().pipe(
        filter(Boolean),
        tap((propertyId) => {
            this.propertyId = propertyId;
        })
    );

    // if there is no current building, then the user have to selected a bulding from the dropdown
    public buildings$ = this.propertyId$.pipe(
        switchMap((propertyId) => this.buildingsService.findAll(propertyId)),
        tap((buildings) => {
            this.buildingList = buildings.map((building) => {
                return {
                    content: building.name,
                    value: building.id,
                    selected: this.sharedOwnershipToEdit?.buildingInfo.id === building.id,
                };
            });

            if (this.sharedOwnershipToEdit) {
                this.form.patchValue({ building: this.buildingList.find((item) => item.selected) });
            }
        })
    );

    public ownership$ = this.propertyId$.pipe(
        switchMap((propertyId) => this.ownershipsService.findAll(propertyId)),
        tap((ownership) => {
            this.ownershipList = ownership.map((ownership) => {
                return {
                    content: ownership.name,
                    value: ownership.id,
                    selected: this.sharedOwnershipToEdit?.ownershipInfo?.id === ownership.id,
                };
            });

            if (this.sharedOwnershipToEdit) {
                this.form.patchValue({ ownership: this.ownershipList.find((item) => item.selected) });
            }
        })
    );

    public constructor(
        private buildingsService: BuildingsService,
        private translateService: TranslateService,
        private toastService: ToastService,
        private formBuilder: UntypedFormBuilder,
        private ownershipsService: OwnershipsService,
        private propertyCustomService: PropertyCustomService,
        private sharedOwnershipService: SharedOwnershipService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.currentBuilding = this.config?.data?.currentBuilding;
        this.isLoading = true;

        if (this.config?.data?.sharedOwnershipToEdit) {
            this.sharedOwnershipToEdit = this.config?.data.sharedOwnershipToEdit;
            this.form.patchValue({
                title: this.sharedOwnershipToEdit?.title,
                hasSpecialRightsToBuildingProperty: this.sharedOwnershipToEdit?.ownershipInfo ? 'true' : 'false',
                category: this.sharedOwnershipToEdit?.category,
            });
        }

        // if there is current building, that means that the shared ownership is being created from building details page,
        if (this.currentBuilding) {
            this.buildingList = [
                {
                    content: this.currentBuilding.name,
                    selected: true,
                    value: this.currentBuilding.id,
                },
            ];

            this.form.patchValue({ building: this.buildingList[0] });
        } else {
            this.buildings$.subscribe();
        }

        this.ownership$.subscribe(() => (this.isLoading = false));

        this.sharedOwnershipCategoriesList = Object.values(CreateSharedOwnershipDto.CategoryEnum).map((item) => {
            return {
                content: item,
                selected: this.sharedOwnershipToEdit?.category === item,
                value: item,
            };
        });

        if (this.sharedOwnershipToEdit) {
            this.form.patchValue({ category: this.sharedOwnershipCategoriesList.find((item) => item.selected) });
        }
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public submit(): void {
        this.isLoading = true;

        const createSharedOwnershipDto: CreateSharedOwnershipDto = {
            category: this.form.get('category')?.value?.value ?? this.form.get('category')?.value,
            title: this.form.get('title')?.value,
            specialRightsOwnershipId:
                this.form.get('hasSpecialRightsToBuildingProperty')?.value === 'true'
                    ? this.form.get('ownership')?.value.value
                    : undefined,
            buildingId: this.form.get('building')?.value?.value ?? '',
        };

        if (this.sharedOwnershipToEdit) {
            this.sharedOwnershipService
                .updateSharedOwnership(this.propertyId, this.sharedOwnershipToEdit.id, createSharedOwnershipDto)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe({
                    next: () => {
                        this.toastService.showSuccess(
                            this.translateService.instant('PAGES.ADD_TECHNICAL_EAQUIPMENT.EDIT_SUCCESS_TOAST')
                        );
                        this.saveEmitter$.next();
                        this.isLoading = false;
                    },
                    error: (error) => {
                        if (error) {
                            this.toastService.showError(error.error['message']);
                        }
                        this.isLoading = false;
                    },
                });

            return;
        }

        this.sharedOwnershipService
            .addSharedOwnership(this.propertyId, createSharedOwnershipDto)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: () => {
                    this.toastService.showSuccess(
                        this.translateService.instant('PAGES.ADD_TECHNICAL_EAQUIPMENT.SUCCESS_TOAST')
                    );
                    this.saveEmitter$.next();
                    this.isLoading = false;
                },
                error: (error) => {
                    if (error) {
                        this.toastService.showError(error.error['message']);
                    }
                    this.isLoading = false;
                },
            });
    }

    public isInvalidForm(controlName: string): boolean {
        return formControlHasError(formControl(this.form, controlName), 'required');
    }
}
