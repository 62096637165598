<div *ngIf="isLoading" class="is-loading">
    <div class="d-flex align-items-center justify-content-center mt-4r">
        <ibm-inline-loading> </ibm-inline-loading>
    </div>
</div>

<div *ngIf="!isEditOesOverlay" class="mt-3-75r mb-4r">
    <div class="mb-0-5r s-head-16-24-bold">Standard Verteilerschlüssel</div>

    <app-table
        emptyText="PAGES.OPERATIONS_EXPENSE_STATEMENTS.DETAILS_OPERATIONS_EXPENSE_STATEMENTS.EMPTY_CONSUMPTION_TABLE"
        [header]="tableModel.header"
        [data]="tableModel.data"
    >
    </app-table>
</div>

<div>
    <div class="mb-0-5r s-head-16-24-bold">
        {{
            isEditOesOverlay
                ? 'Haben Sie schon alle individuelle Verteilerschlüssel erstellt?'
                : 'Individuelle Verteilerschlüssel'
        }}
    </div>
    <div
        [ngClass]="{ 'mb-4r': isEditOesOverlay, 'mb-1-5r': !isEditOesOverlay }"
        class="d-flex justify-space-between mb-1-5r"
    >
        <div class="s-body-14-22-regular">
            Hier können Sie weitere Verteilerschlüssel erstellen, z.B. um eine kleinere Verteilergruppe aus bestimmten
            Nutzungen anzulegen.
        </div>

        <app-scalara-button
            *ngIf="!isEditOesOverlay"
            variant="ghost"
            svgName="24_add.svg"
            (click)="openAddConsumptionDistributionKeyOverlay()"
        >
            {{ 'CONTROLS.BUTTONS.ADD' | translate }}
        </app-scalara-button>
    </div>

    <div *ngIf="isEditOesOverlay" class="s-body-14-22-regular mb-0-5r">
        Ihre bisher erstellten individuellen verteilerschlüssel:
    </div>

    <app-table
        emptyText="PAGES.OPERATIONS_EXPENSE_STATEMENTS.DETAILS_OPERATIONS_EXPENSE_STATEMENTS.EMPTY_DISTRIBUTION_KEY_TABLE"
        [header]="tableModelIndividualDistributionKey.header"
        [data]="tableModelIndividualDistributionKey.data"
    >
    </app-table>
</div>
