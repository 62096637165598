<div ibmRow>
    <div ibmCol *ngFor="let item of tileSelectorOptions; let i = index" class="tile__wrapper">
        <div
            class="tile tile--box-selection"
            [ngClass]="{
                'tile--selected': isOptionSelected[i],
                '': !isOptionSelected[i]
            }"
            (click)="selectOption(i)"
        >
            <div class="pt-3r pb-1-5r">
                <div
                    [ngClass]="[
                        isOptionSelected[i] ? 'icon-container--' + tileSelectorOptions[i].color : 'icon-container--gray'
                    ]"
                    class="icon-container icon-container--72"
                >
                    <svg-icon src="/assets/icons/{{ tileSelectorOptions[i].iconSrc }}"></svg-icon>
                </div>
            </div>
            <div [ngClass]="{ 'mb-5-5r': !isOptionSelected[i] }" class="headline">
                {{ tileSelectorOptions[i].description | translate }}
            </div>
            <div
                *ngIf="isOptionSelected[i]"
                class="icon-container icon-container--green icon-container--40 icon-selected"
            >
                <svg-icon src="/assets/icons/24_checkbox-check-green.svg"></svg-icon>
            </div>
        </div>
    </div>
</div>
