<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<div ibmRow [ngClass]="{ 'mb-10': (bankAccounts | async)?.length }">
    <div ibmCol [columnNumbers]="{ md: 4, lg: 2 }" class="s-head-28-36-bold mb-04">
        {{ 'ACCOUNTING.BANK_TRANSACTION_DETAILS.MOVEMENTS' | translate }}
    </div>

    <div ibmCol [columnNumbers]="{ md: 12, lg: 14 }">
        <div class="text-right">
            <app-accounting-date-picker-selector></app-accounting-date-picker-selector>
        </div>
    </div>
</div>

<div class="tw-my-10" *ngIf="(bankAccounts | async)?.length === 0">
    <app-notification type="info">
        <div class="s-body-14-22-regular">
            <span>{{ 'PAGES.BANK_ACCOUNTS.EMPTY_TABLE' | translate }} </span>
            <a href="/user-management/accounts" class="s-gray-01 s-link-14-22-regular">{{
                'PAGES.BANK_ACCOUNTS.EMPTY_TABLE_LINK_TEXT' | translate
            }}</a>
        </div>
    </app-notification>
</div>

<app-bank-connection-expired-notification
    [bankAccounts]="(this.bankAccounts | async) ?? undefined"
></app-bank-connection-expired-notification>

<ibm-tabs [followFocus]="true" [cacheActive]="true">
    <ibm-tab heading="{{ 'PAGES.BANK_TRANSACTIONS.TAB_TITLE_1' | translate }}">
        <app-banking-transactions-transactions></app-banking-transactions-transactions>
    </ibm-tab>
    <ibm-tab [heading]="recommendedBookingsHeading" [active]="openSuggestedTabOnInit">
        <app-banking-transactions-recommended-bookings
            (recommendedBookingsEmitter)="updateAmountRecommendedBookings($event)"
        ></app-banking-transactions-recommended-bookings>
    </ibm-tab>

    <ibm-tab [heading]="bookingsRuleHeading" [active]="openBookingRuleSuggestionOnInit">
        <app-banking-transactions-booking-rules-index
            (updateAmountBookingsRule)="updateAmountBookingsRule($event)"
        ></app-banking-transactions-booking-rules-index>
    </ibm-tab>
</ibm-tabs>

<ng-template #recommendedBookingsHeading>
    <span class="mr-0-5r">{{ 'PAGES.BANK_TRANSACTIONS.TAB_TITLE_2' | translate }}</span>
    <app-tag *ngIf="amountRecommendedBookings > 0" [color]="'green'">{{ amountRecommendedBookings }}</app-tag>
    <app-tag *ngIf="amountRecommendedBookings === 0" [color]="'gray'">{{ amountRecommendedBookings }}</app-tag>
</ng-template>

<ng-template #bookingsRuleHeading>
    <span class="mr-0-5r">{{ 'PAGES.BANK_TRANSACTIONS.TAB_TITLE_3' | translate }}</span>
    <app-tag *ngIf="amountBookingsRule > 0" [color]="'green'">{{ amountBookingsRule }}</app-tag>
    <app-tag *ngIf="amountBookingsRule === 0" [color]="'gray'">{{ amountBookingsRule }}</app-tag>
</ng-template>

<div class="p-10"></div>
