<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"></ibm-loading>
<app-overlay-container
    [headlineTitle]="
        sharedOwnershipToEdit
            ? 'PAGES.ADD_EDIT_COMMON_OWNERSHIPS.HEADLINE'
            : 'PAGES.ADD_EDIT_COMMON_OWNERSHIPS.HEADLINE'
    "
    [sizeIcon]="72"
    image="40_edit.svg"
    colorIcon="green"
    containerSize="small"
>
    <form [formGroup]="form">
        <div class="tw-flex tw-flex-col tw-gap-6 tw-mb-10">
            <app-combo-box
                placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT' | translate }}"
                [appendInline]="true"
                label="{{ 'ENTITIES.SHARED_OWNERSHIP.LABEL_CATEGORY' | translate }}"
                [items]="sharedOwnershipCategoriesList"
                formControlName="category"
            >
                <ibm-dropdown-list></ibm-dropdown-list>
            </app-combo-box>

            <ibm-label invalidText="{{ 'ERROR.REQUIRED' | translate }}">
                {{ 'COMMON.LABEL_DESCRIPTION' | translate }}
                <input ibmText name="title" id="title" formControlName="title" />
            </ibm-label>

            <app-combo-box
                placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT' | translate }}"
                [appendInline]="true"
                label="{{ 'ENTITIES.SHARED_OWNERSHIP.LABEL_ASSIGNMENT' | translate }}*"
                [items]="buildingList"
                formControlName="building"
                [invalid]="isInvalidForm('building')"
                invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                [disabled]="!!currentBuilding"
            >
                <ibm-dropdown-list></ibm-dropdown-list>
            </app-combo-box>
        </div>

        <div class="tw-mb-5">
            <div class="tw-s-head-14-22-regular tw-mb-6">
                {{ 'PAGES.ADD_EDIT_COMMON_OWNERSHIPS.TEXT_CHECKBOX_ARE_THERE_SPECIAL_RIGHTS' | translate }}
            </div>

            <ibm-radio-group
                aria-label="radiogroup"
                orientation="vertical"
                formControlName="hasSpecialRightsToBuildingProperty"
            >
                <ibm-radio value="false" [checked]="true"> Nein </ibm-radio>
                <ibm-radio value="true"> Ja </ibm-radio>
            </ibm-radio-group>
        </div>

        <app-combo-box
            *ngIf="form.get('hasSpecialRightsToBuildingProperty')?.value === 'true'"
            placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT' | translate }}"
            [appendInline]="true"
            label="{{ 'ENTITIES.OWNERSHIP.LABEL_ENTITY' | translate }}*"
            [items]="ownershipList"
            formControlName="ownership"
            [invalid]="isInvalidForm('ownership')"
            invalidText="{{ 'ERROR.REQUIRED' | translate }}"
        >
            <ibm-dropdown-list></ibm-dropdown-list>
        </app-combo-box>

        <div class="d-flex justify-space-between mt-7-5r">
            <app-scalara-button (click)="abort()" variant="outline">
                {{ 'CONTROLS.BUTTONS.CANCEL' | translate }}
            </app-scalara-button>

            <app-scalara-button [disabled]="form.invalid" (click)="submit()" variant="default">
                {{ 'CONTROLS.BUTTONS.SAVE' | translate }}
            </app-scalara-button>
        </div>
    </form>
</app-overlay-container>
