<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<div ibmGrid class="bx--grid--full-width">
    <div ibmRow class="mb-10">
        <div ibmCol></div>
        <div ibmCol [columnNumbers]="{ md: 10, lg: 10 }">
            <div ibmRow class="headline-wrapper">
                <div class="mr-06 headline-wrapper__icon">
                    <app-icon-bubble [size]="72" [color]="'green'" [image]="'40_upload.svg'"></app-icon-bubble>
                </div>
                <div class="headline-wrapper__title">
                    <div class="s-head-28-36-bold">
                        <app-text-with-tooltip
                            class="tw-inline-block"
                            [label]="'ACCOUNTING.ADD_RECEIPT.HEADLINE' | translate"
                            [labelClass]="''"
                            [tooltipKey]="TooltipKey.tooltipsInAccounting"
                            [tooltipInnerHtml]="'PAGES.TOOLTIPS.ADD_RECEIPT.HEADLINE' | translate"
                        ></app-text-with-tooltip>
                    </div>
                </div>
            </div>
        </div>
        <div ibmCol></div>
    </div>
    <div>
        <div ibmRow>
            <div ibmCol></div>
            <div ibmCol [columnNumbers]="{ md: 10, lg: 10 }">
                <div ibmRow>
                    <div ibmCol>
                        <app-file-uploader
                            #fileUpload
                            (filesLoaded)="updateLoadingFilesStatus($event)"
                            (fileIdsLoaded)="updateFileIdsLodaded($event)"
                            (updatedFileTypes)="updateFileType($event)"
                            [selectFileType]="true"
                            [accountsList]="accountsList"
                            (updatedFileAccounts)="updateFileAccount($event)"
                        ></app-file-uploader>
                    </div>
                </div>
                <div ibmRow></div>
                <!-- START BUTTON ROW -->
                <div ibmRow class="mt-11">
                    <div ibmCol>
                        <button [ibmButton]="'tertiary'" type="reset" (click)="abort()">
                            {{ 'COMMON.BTN_CANCEL' | translate }}
                        </button>
                    </div>

                    <div ibmCol class="text-right">
                        <button
                            [ibmButton]="'primary'"
                            type="submit"
                            (click)="onSubmit()"
                            [disabled]="!areFilesFullyUploaded"
                        >
                            {{ 'COMMON.BTN_SAVE' | translate }}
                        </button>
                    </div>
                </div>
                <!-- END BUTTON ROW -->
            </div>
            <div ibmCol></div>
        </div>
    </div>
</div>
