<div class="s-head-28-36-bold" [ngClass]="{ 'tw-mb-12': bankAccounts?.length }">Übersicht</div>
<div class="tw-my-10" *ngIf="bankAccounts?.length === 0">
    <app-notification type="info">
        <div class="s-body-14-22-regular">
            <span>{{ 'PAGES.BANK_ACCOUNTS.EMPTY_TABLE' | translate }} </span>
            <a href="/user-management/accounts" class="s-gray-01 s-link-14-22-regular">{{
                'PAGES.BANK_ACCOUNTS.EMPTY_TABLE_LINK_TEXT' | translate
            }}</a>
        </div>
    </app-notification>
</div>

<div>
    <ibm-tabs [followFocus]="true" [cacheActive]="true">
        <ibm-tab heading="Geldkonten">
            <app-banking-accounts-accounts
                [bankAccounts]="this.bankAccounts ?? undefined"
                (updateBankAccounts)="updateBankAccounts()"
            ></app-banking-accounts-accounts>
        </ibm-tab>
        <ibm-tab heading="Lastschriftkonfiguration">
            <app-banking-debit-configuration [bankAccounts]="this.bankAccounts ?? undefined">
            </app-banking-debit-configuration>
        </ibm-tab>
    </ibm-tabs>
</div>
