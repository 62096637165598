<app-delete-overlay
    title="PAGES.DELETE_PAYMENT.TITLE"
    [headline]="ctx.getHeadline()"
    subtitle="PAGES.DELETE_PAYMENT.SUBTITLE"
    (closeOverlay)="abort()"
    (submitOverlay)="submit()"
    [headlineIcon]="ctx.getOverlayInputs().iconSrc"
    [colorIcon]="ctx.getOverlayInputs().iconColor"
    [submitButtonLabel]="ctx.getButtonInputs().label"
    [submitButtonVariant]="ctx.getButtonInputs().buttonVariant"
>
    <div class="tw-p-4 tw-bg-scalaraGray-06 tw-flex tw-items-center tw-mb-[120px]">
        <div class="tw-s-label-12-22-semibold tw-text-scalaraGray-03 tw-flex tw-flex-col tw-gap-2">
            <div>
                {{ 'ACCOUNTING.BANK_TRANSACTION_DETAILS.TYPE' | translate }}
            </div>
            <div>
                {{
                    (payment?.type === 'MONEY_TRANSFER'
                        ? 'ACCOUNTING.BANK_TRANSACTION_DETAILS.RECIPIENTS'
                        : 'ACCOUNTING.BANK_TRANSACTION_DETAILS.COUNTERPART_NAME'
                    ) | translate
                }}
            </div>
            <div>
                {{ 'ACCOUNTING.BANK_TRANSACTION_DETAILS.IBAN' | translate }}
            </div>
            <div>
                {{ 'ACCOUNTING.BANK_TRANSACTION_DETAILS.PURPOSE' | translate }}
            </div>
            <div>
                {{ 'ENTITIES.COMMON.LABEL_AMOUNT_TABLE' | translate }}
            </div>
            <ng-container *ngIf="!ctx.isRecurring">
                <div class="tw-mt-6">
                    {{ 'ACCOUNTING.BANK_TRANSACTION_DETAILS.COUNTERPART_NAME' | translate }}
                </div>
                <div>
                    {{ 'ACCOUNTING.BANK_TRANSACTION_DETAILS.IBAN' | translate }}
                </div>
            </ng-container>
            <ng-container *ngIf="ctx.isRecurring">
                <div>
                    {{ 'ENTITIES.PAYMENT.LABEL_FIRST_EXECUTION_DATE' | translate }}
                </div>
                <div>
                    {{ 'ENTITIES.PAYMENT.LABEL_RHYTHM' | translate }}
                </div>
                <div>
                    {{ 'ENTITIES.PAYMENT.LABEL_LAST_EXECUTION_DATE' | translate }}
                </div>
            </ng-container>
        </div>

        <div class="tw-ml-8 tw-s-body-14-22-regular tw-flex tw-flex-col tw-gap-2">
            <div>{{ 'ENTITIES.PAYMENT.LABEL_' + payment?.type | translate }}</div>
            <div>{{ payment?.counterpartName }}</div>
            <div>{{ payment?.counterpartIban | ibanFormatter }}</div>
            <div>{{ payment?.purpose ?? '-' }}</div>
            <div>{{ payment?.amount || 0 | eurocent }}</div>

            <ng-container *ngIf="!ctx.isRecurring">
                <div class="tw-mt-6">{{ payment?.bankAccount?.holderName }}</div>
                <div>{{ payment?.bankAccount?.iban | ibanFormatter }}</div>
            </ng-container>
            <ng-container *ngIf="ctx.isRecurring">
                <div>TODO: populate data</div>
                <div>TODO: populate data</div>
                <div>TODO: populate data</div>
            </ng-container>
        </div>
    </div>
</app-delete-overlay>
