import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbItem } from 'carbon-components-angular';
import { BehaviorSubject, Subject, combineLatest, firstValueFrom, of, switchMap, takeUntil, tap } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import {
    calculateTaxAmount,
    formatDateWithoutHhMmSs,
    getNameFromPerson,
    unitTypeNameFromEnum,
} from 'src/app/core/utils/common';
import { DeleteOccupationComponent } from 'src/app/features/property/components/delete-occupation/delete-occupation.component';
import { DocumentsFlow } from 'src/app/features/property/components/documents/add-document-overlay/add-document-overlay.component';
import { InputData } from 'src/app/features/property/components/documents/document-table/document-table.component';
import { EditOccupationPeriodComponent } from 'src/app/features/property/components/edit-occupation-period/edit-occupation-period.component';
import { PropertyCustomService } from 'src/app/features/property/services/property-custom.service';
import {
    Occupation,
    OccupationForm,
    OccupationService,
    Person,
    PersonCountDto,
    PersonsService,
    Property,
    RentDetails,
    RentInterval,
    Unit,
} from 'src/app/generated-sources/base';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { PersonAmountCallbacks } from '../../../../shared/components/person-amount-and-time-frame-table/person-amount-and-time-frame-table.component';
import { AddOccupationComponent } from '../add-occupation/add-occupation.component';
import { EditOccupationDetailsComponent } from '../edit-occupation-details/edit-occupation-details.component';
import { EditRentPaymentComponent } from '../edit-rent-payment/edit-rent-payment.component';

@Component({
    selector: 'app-occupation-details',
    templateUrl: './occupation-details.component.html',
    styleUrls: ['./occupation-details.component.scss'],
})
export class OccupationDetailsComponent implements OnInit, OnDestroy {
    public breadcrumbs: BreadcrumbItem[] = [];
    public propertyId = '';
    public occupationId = '';
    public isLoading = false;
    public occupation?: Occupation;
    public occupationForm?: OccupationForm;
    public occupationStatus = 'draft';
    public cardTitle = '';
    public unitsOccupationFrom?: Unit[];
    public personsOccupationFrom?: Person[];
    public propertyType?: Property.PropertyTypeEnum;

    public personAmountCallbacks: PersonAmountCallbacks = {
        addPersonsAmountCallback: async (personCountObj: PersonCountDto) => {
            //occupation-servie.post
            //unit-service.post
            if (this.occupation?.id) {
                await firstValueFrom(
                    this.occupationService.addPersonCount(this.propertyId, this.occupation?.id, personCountObj)
                );
                this.initOccupation();
            }
        },
        editPersonsAmountCallback: async (personCountObj: PersonCountDto) => {
            if (this.occupation?.id && personCountObj.id) {
                await firstValueFrom(
                    this.occupationService.updatePersonCount(
                        this.propertyId,
                        this.occupation?.id,
                        personCountObj.id,
                        personCountObj
                    )
                );
                this.unsubscribe$.next();
                this.initOccupation();
            }
            return;
        },
        deletePersonsAmountCallback: async (id: string) => {
            if (this.occupation?.id) {
                try {
                    await firstValueFrom(
                        this.occupationService.removePersonCount(this.propertyId, this.occupation?.id, id)
                    );
                    this.initOccupation();
                } catch (e) {
                    this.toastService.showError((e as HttpErrorResponse).error.message);
                }
            }
            return;
        },
    };

    public tableInputData?: InputData;
    public refresh$ = new BehaviorSubject<void>(undefined);

    private unsubscribe$ = new Subject<void>();

    public constructor(
        private route: ActivatedRoute,
        private overlayService: OverlayService,
        private occupationService: OccupationService,
        private personService: PersonsService,
        private toastService: ToastService,
        private translateService: TranslateService,
        private propertyCustomService: PropertyCustomService
    ) {}
    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public ngOnInit(): void {
        this.initOccupation();
    }

    private initOccupation(isCommit?: boolean): void {
        this.isLoading = true;
        combineLatest([this.route.parent!.paramMap, this.route.paramMap])
            .pipe(
                switchMap((params: ParamMap[]) => {
                    this.propertyId = String(params[0].get('id'));
                    this.occupationId = String(params[1].get('occupationsId'));
                    this.occupationStatus = String(params[1].get('occupationStatus'));
                    return this.occupationStatus === 'draft' && !isCommit
                        ? this.occupationService.findOneOccupationForm(this.propertyId, this.occupationId)
                        : this.occupationService.findOneOccupation(this.propertyId, this.occupationId);
                }),
                tap((occupation: Occupation | OccupationForm) => {
                    if (this.occupationStatus === 'draft') {
                        this.occupationForm = occupation as OccupationForm;
                        if (
                            this.occupationForm?.tenants &&
                            this.occupationForm?.tenants?.length > 0 &&
                            this.occupationForm?.tenants[0] === null
                        ) {
                            this.occupationForm.tenants = [];
                        }
                        if (this.occupationForm?.occupationType === 'RENTING') {
                            this.cardTitle =
                                this.occupationForm.rentType === 'PRIVATE'
                                    ? 'Vermietung, privat'
                                    : 'Vermietung, Gewerblich';
                        } else {
                            this.cardTitle =
                                this.occupationForm?.occupationType === 'OWN_OCCUPATION'
                                    ? 'Selbstnutzung'
                                    : 'Leerstand';
                        }
                    } else {
                        this.occupation = occupation as Occupation;
                        if (
                            this.occupation?.tenants &&
                            this.occupation?.tenants?.length > 0 &&
                            this.occupation?.tenants[0] === null
                        ) {
                            this.occupation.tenants = [];
                        }

                        if (this.occupation?.occupationType === 'RENTING') {
                            this.cardTitle =
                                this.occupation?.rentDetails?.rentType === 'PRIVATE'
                                    ? 'Vermietung, privat'
                                    : 'Vermietung, Gewerblich';
                        } else {
                            this.cardTitle =
                                this.occupation?.occupationType === 'OWN_OCCUPATION' ? 'Selbstnutzung' : 'Leerstand';
                        }
                    }
                }),
                switchMap(() => {
                    return this.occupationStatus === 'draft'
                        ? this.occupationService.findSelectedUnitsInfo(this.propertyId, this.occupationId)
                        : of([]);
                }),
                tap((units) => {
                    if (units.length > 0) {
                        this.unitsOccupationFrom = units;
                    }
                }),
                switchMap(() => {
                    if (
                        this.occupationForm &&
                        this.occupationForm.tenantIds &&
                        this.occupationForm.tenantIds.length > 0
                    ) {
                        const ids = this.occupationForm.tenantIds.map((item) => item);
                        return this.personService.findAll(ids.join(','));
                    }
                    return of([]);
                }),
                tap((persons) => {
                    this.personsOccupationFrom =
                        this.occupationForm?.tenants && this.occupationForm?.tenants?.length > 0
                            ? this.occupationForm?.tenants?.concat(persons || [])
                            : persons;
                }),
                switchMap(() => {
                    return this.propertyCustomService.getProperty$();
                }),
                tap((property) => {
                    if (property) {
                        this.propertyType = property.propertyType;
                    }
                    this.populateInputData();
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => {
                const lastRoutePart = this.occupation?.occupationNumber ? 'commit' : 'draft';
                this.breadcrumbs = [
                    {
                        content: 'Mietverwaltung',
                        route: ['properties', this.propertyId, 'occupations'],
                    },
                    {
                        content: this.occupation?.occupationNumber || 'Entwurf',
                        route: ['properties', this.propertyId, 'occupations', this.occupationId, lastRoutePart],
                        current: true,
                    },
                ];
                this.isLoading = false;
            });
    }

    public openDeleteOccupationOverlay(): void {
        const data = {
            occupation: this.occupation || this.occupationForm,
            propertyId: this.propertyId,
            occupationStatus: this.occupationStatus,
        };
        const ref = this.overlayService.open(DeleteOccupationComponent, { data });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe();
    }

    public getNameFromPerson(person: Person): string {
        return getNameFromPerson(person);
    }

    public inviteContact(personId: string): void {
        this.personService
            .invite(personId)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.toastService.showSuccess(
                    this.translateService.instant('PAGES.CONTACT.DETAIL_VIEW.INVITE_TOAST_SUCCESS')
                );
            });
    }

    public convertToNumber(value: string): number | undefined {
        if (!value) {
            return undefined;
        }
        return Number(value);
    }

    public getTypeUnitName(unit: Unit): string {
        return unitTypeNameFromEnum(unit);
    }

    public openEditOverlay(): void {
        const data = {
            occupation: this.occupationForm,
            propertyId: this.propertyId,
            occupationStatus: this.occupationStatus,
        };
        const ref = this.overlayService.open(AddOccupationComponent, { data });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
            this.initOccupation(data === 'commit');
        });
    }

    public openEditRentPaymentOverlay(): void {
        const data = {
            occupation: this.occupation,
            propertyId: this.propertyId,
            occupationStatus: this.occupationStatus,
        };
        const ref = this.overlayService.open(EditRentPaymentComponent, { data });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.initOccupation());
    }

    public getDepositTypeLabel(depositType: RentDetails.DepositTypeEnum): string {
        switch (depositType) {
            case 'NONE':
                return 'Keine Kaution';
            case 'BANK_TRANSFER':
                return 'Banküberweisung';
            case 'CASH_PAYMENT':
                return 'Barzahlung';
            case 'PLEDGED_SAVING_ACCOUNT':
                return 'Verpfändetes Sparkonto';
            case 'BANK_GUARANTEE':
                return 'Bankbürgschaft';
            case 'DEPOSIT_INSURANCE':
                return 'Kautionsversicherung';
            case 'PARENTAL_GUARANTEE':
                return 'Elternbürgschaft';
            default:
                return 'Banküberweisung';
        }
    }

    public getPrepaidSum(rentInterval: RentInterval): number {
        const { rent, operationalCosts, heatingCosts, prepaidSum } = rentInterval;
        return Number(prepaidSum || 0) + Number(rent || 0) + Number(operationalCosts || 0) + Number(heatingCosts || 0);
    }

    public calculateTaxShareAmount(rentInterval: RentInterval): number {
        let taxRate = 0;
        if (this.occupation?.rentDetails?.percentageTaxIncluded === RentDetails.PercentageTaxIncludedEnum._19) {
            taxRate = 19;
        } else {
            if (this.occupation?.rentDetails?.percentageTaxIncluded === RentDetails.PercentageTaxIncludedEnum._7) {
                taxRate = 7;
            }
        }
        return calculateTaxAmount(taxRate, this.getPrepaidSum(rentInterval));
    }

    public showRentCard(): boolean {
        return this.occupation &&
            this.occupation.rentDetails &&
            this.occupation.rentDetails.rentIntervals.length > 0 &&
            this.occupation.rentDetails.rentIntervals[0] !== null
            ? true
            : false;
    }

    public getUnitLink(id: string): string {
        return (
            '/properties/' +
            this.propertyId +
            (this.propertyType === Property.PropertyTypeEnum.Mv ? '/unit/' : '/ownerships/') +
            id
        );
    }

    public isOccupationFinished(date?: string): boolean {
        if (!date) {
            return false;
        }

        return new Date(date).getTime() <= new Date().getTime();
    }

    public openEditDetailsOverlay(): void {
        const ref = this.overlayService.open(EditOccupationDetailsComponent, {
            data: { occupation: this.occupation, propertyId: this.propertyId },
        });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.initOccupation());
    }

    public populateInputData(): void {
        this.tableInputData = {
            flow: this.occupationStatus === 'commit' ? DocumentsFlow.occupation : DocumentsFlow.occupationForm,
            occupationId: (this.occupationStatus === 'commit' ? this.occupation?.id : this.occupationForm?.id) ?? '',
        };
    }
    public findDateInbetween(obj: PersonCountDto[]): string {
        const today = new Date();
        for (let i = 0; i < obj.length; i++) {
            const startDate = new Date(obj[i].from!);
            let endDate;
            if (i < obj.length - 1) {
                endDate = new Date(obj[i + 1].from!);
                endDate.setDate(endDate.getDate() - 1);
            } else {
                endDate = startDate;
            }
            if (startDate == endDate && today > startDate) {
                return `${obj[i].count} - seit ${formatDateWithoutHhMmSs(startDate)}`;
            }

            if (today >= startDate && today <= endDate) {
                return `${obj[i].count} - seit ${formatDateWithoutHhMmSs(startDate)}`;
            }
        }

        return ``;
    }

    public DocumentsFlow = DocumentsFlow;

    public openEditOccupationPeriodOverlay(isEndOccupation?: boolean): void {
        const ref = this.overlayService.open(EditOccupationPeriodComponent, {
            data: { occupation: this.occupation, propertyId: this.propertyId, isEndOccupation: isEndOccupation },
        });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.initOccupation());
    }
}
