import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { GridModule, IconModule, NotificationModule, UIShellModule } from 'carbon-components-angular';
import { AuthModule } from '../features/auth/auth.module';
import { SharedModule } from '../shared/shared.module';
import { GeneralTermsComponent } from './components/general-terms/general-terms.component';
import { LegalNoticeComponent } from './components/legal-notice/legal-notice.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PageComponent } from './components/page/page.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { EnsureImportedOnceModule } from './ensure-imported-once';

@NgModule({
    declarations: [
        PageNotFoundComponent,
        NavigationComponent,
        PageComponent,
        LegalNoticeComponent,
        GeneralTermsComponent,
        PrivacyPolicyComponent,
    ],
    imports: [
        CommonModule,
        UIShellModule,
        RouterModule,
        TranslateModule,
        GridModule,
        AuthModule,
        IconModule,
        SharedModule,
        AngularSvgIconModule,
        NotificationModule,
    ],
    exports: [NavigationComponent, PageComponent],
})
export class CoreModule extends EnsureImportedOnceModule {
    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    public constructor(@SkipSelf() @Optional() parent: CoreModule) {
        super(parent);
    }
}
