import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { combineLatest, of, switchMap, tap } from 'rxjs';
import { getMonthAndYearFromDate } from 'src/app/core/utils/common';
import { CustomerStatisticDto, PlatformStatisticDto } from 'src/app/generated-sources/base';
import {
    AccountingPlatformStatisticsDto,
    MonthlyBookingsDto,
    StatisticsService,
} from '../../../../generated-sources/accounting';

@Component({
    selector: 'app-operation-accounting-statistics',
    templateUrl: './operation-accounting-statistics.component.html',
    styleUrls: ['./operation-accounting-statistics.component.scss'],
})
export class OperationAccountingStatisticsComponent implements OnInit {
    public isLoading = false;
    public accountingPlatformStatistics?: AccountingPlatformStatisticsDto;
    public accountingMonthlyBookings?: MonthlyBookingsDto[];
    public accountingMonthlyBankBookings?: MonthlyBookingsDto[];
    public bankTransactionCounts: number[] = [];
    public valuemap: Map<string, Map<string, number>> = new Map();
    private bookingsCountLabel = 'Anzahl der Buchungen';
    private bookingsAmountLabel = 'Gesamtbetrag aller Buchungen';
    private bankBookingsCountLabel = 'Anzahl der Bankbuchungen';
    private bankBookingsAmountLabel = 'Gesamtbetrag der Bankbuchungen';
    public labelsArray: string[] = [];
    public labelsSet: Set<any> = new Set();
    public valuesMap: any = {};

    public statisticsCollapsed = {
        property: false,
        customer: false,
    };

    public lineChartType: ChartType = 'line';
    @ViewChild(BaseChartDirective) public chart?: BaseChartDirective;

    @Input() public platformStatistics?: PlatformStatisticDto;
    @Input() public customerSubscriptions?: CustomerStatisticDto[];

    public constructor(private statisticsAccountingService: StatisticsService) {}

    public lineChartData?: ChartConfiguration['data'];

    public lineChartOptions: ChartConfiguration['options'] = {
        elements: {
            line: {
                tension: 0.5,
            },
        },
        scales: {
            // We use this empty structure as a placeholder for dynamic theming.
            y: {
                position: 'left',
                type: 'linear',
            },
            y1: {
                position: 'right',
                type: 'linear',
                grid: {
                    drawOnChartArea: false, // only want the grid lines for one axis to show up
                },
            },
        },
    };

    public ngOnInit(): void {
        this.isLoading = true;
        this.getAccountingPlatformStatistics();
    }

    private async getAccountingPlatformStatistics(): Promise<void> {
        await this.statisticsAccountingService
            .getAccountingPlatformStatistics()
            .pipe(tap((result) => (this.accountingPlatformStatistics = result)))
            .subscribe(() => (this.isLoading = false));
    }

    public accountingMonthlyBookings$ = this.statisticsAccountingService.getMonthlyBookings();
    public getAccountingBookingWithTransactionssMonthly$ =
        this.statisticsAccountingService.getMonthlyTransactionsWithBookings();
    public chartConfig$ = combineLatest([
        this.accountingMonthlyBookings$,
        this.getAccountingBookingWithTransactionssMonthly$,
    ]).pipe(
        switchMap(([resultA, resultB]) => {
            const dateCallbaskSort = (a: MonthlyBookingsDto, b: MonthlyBookingsDto): number => {
                const dateA = new Date(a.month);
                const dateB = new Date(b.month);
                // sort dateA and date B
                if (dateA === dateB) {
                    return 0;
                }
                return dateA > dateB ? 1 : -1;
            };

            resultA.forEach((monthly) => {
                monthly.month = getMonthAndYearFromDate(monthly.month);
                monthly.amount = monthly.amount / 100;
            });
            resultB.forEach((monthly) => {
                monthly.month = getMonthAndYearFromDate(monthly.month);
                monthly.amount = monthly.amount / 100;
            });

            // ChartConfiguration['data']
            return of<any>({
                datasets: [
                    {
                        data: [...resultA].sort(dateCallbaskSort),
                        label: this.bookingsCountLabel,
                        parsing: {
                            xAxisKey: 'month',
                            yAxisKey: 'count',
                        },
                        yAxisID: 'y1',
                    },
                    {
                        data: [...resultA].sort(dateCallbaskSort),
                        label: this.bookingsAmountLabel,
                        parsing: {
                            xAxisKey: 'month',
                            yAxisKey: 'amount',
                        },
                    },
                    {
                        data: [...resultB].sort(dateCallbaskSort),
                        label: this.bankBookingsCountLabel,
                        parsing: {
                            xAxisKey: 'month',
                            yAxisKey: 'count',
                        },
                        yAxisID: 'y1',
                    },
                    {
                        data: [...resultB].sort(dateCallbaskSort),
                        label: this.bankBookingsAmountLabel,
                        parsing: {
                            xAxisKey: 'month',
                            yAxisKey: 'amount',
                        },
                    },
                ],
            });
        })
    );

    public toggleStatisticsCollapsed(key: keyof typeof this.statisticsCollapsed): void {
        this.statisticsCollapsed[key] = !this.statisticsCollapsed[key];
    }
}
