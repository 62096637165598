/**
 * Example:
 *
 * const arr = [
 *   { name: 'Alice', age: 20, group: 'A' },
 *   { name: 'Bob', age: 22, group: 'B' },
 *   { name: 'Charlie', age: 23, group: 'A' },
 *   { name: 'Dave', age: 21, group: 'B' }
 * ];
 *
 * const grouped = groupObjectsToObject(arr, obj => obj.group);
 *
 * console.log(grouped);
 *
 * Output:
 * {
 *   'A': [
 *     { name: 'Alice', age: 20, group: 'A' },
 *     { name: 'Charlie', age: 23, group: 'A' }
 *   ],
 *   'B': [
 *     { name: 'Bob', age: 22, group: 'B' },
 *     { name: 'Dave', age: 21, group: 'B' }
 *   ]
 * }
 */
export function groupToObject<T>(arr: T[], groupingFn: (obj: T) => string): Record<string, T[]> {
    const result: Record<string, T[]> = {};
    for (const obj of arr) {
        const key = groupingFn(obj);
        if (!result[key]) {
            result[key] = [];
        }
        result[key].push(obj);
    }
    return result;
}

/*
 * Same as above but the values are mapped in the end with the mappingFn.
 */
export function groupToObjectAndMap<T, V>(
    arr: T[],
    groupingFn: (obj: T) => string,
    mappingFn: (obj: T) => V
): Record<string, V[]> {
    const grouped = groupToObject(arr, groupingFn);
    return Object.fromEntries(Object.entries(grouped).map(([k, v]) => [k, v.map(mappingFn)]));
}

/**
 * Example:
 *
 * const arr = [
 *   { name: 'Alice', age: 20, group: 'A', location: 'NY' },
 *   { name: 'Bob', age: 22, group: 'B', location: 'LA' },
 *   { name: 'Charlie', age: 23, group: 'A', location: 'NY' },
 *   { name: 'Dave', age: 21, group: 'B', location: 'LA' },
 *   { name: 'Eve', age: 25, group: 'A', location: 'LA' }
 * ];
 *
 * const grouped = groupObjectsToArray(arr, obj => ({ group: obj.group, location: obj.location }));
 *
 * console.log(grouped);
 *
 * Output:
 * [
 *   { key: { group: 'A', location: 'NY' }, values: [
 *     { name: 'Alice', age: 20, group: 'A', location: 'NY' },
 *     { name: 'Charlie', age: 23, group: 'A', location: 'NY' }
 *   ]},
 *   { key: { group: 'B', location: 'LA' }, values: [
 *     { name: 'Bob', age: 22, group: 'B', location: 'LA' },
 *     { name: 'Dave', age: 21, group: 'B', location: 'LA' }
 *   ]},
 *   { key: { group: 'A', location: 'LA' }, values: [
 *     { name: 'Eve', age: 25, group: 'A', location: 'LA' }
 *   ]}
 * ]
 */
export function groupToArray<T, U extends object | string>(arr: T[], fn: (obj: T) => U): { key: U; values: T[] }[] {
    const result: Record<string, T[]> = {};
    let keyType: string;
    for (const obj of arr) {
        const key = fn(obj);
        keyType = typeof key;
        const stringKey = keyType === 'string' ? (key as string) : JSON.stringify(key);
        if (!result[stringKey]) {
            result[stringKey] = [];
        }
        result[stringKey].push(obj);
    }
    return Object.entries(result).map(([k, values]) => ({
        key: keyType === 'object' ? JSON.parse(k) : k,
        values,
    }));
}

// export function replaceUmlaute(str: string): string {
//     if (!str) {
//         return str;
//     }
//     const umlautMap = {
//         ä: 'a',
//         ö: 'o',
//         ü: 'u',
//         Ä: 'A',
//         Ö: 'O',
//         Ü: 'U',
//         ß: 'ss',
//     };

//     const regexPattern = '[' + Object.keys(umlautMap).join() + ']';
//     const regex = new RegExp(regexPattern, 'g');

//     return str.replace(regex, (match) => umlautMap[match]);
// }

// export function unifyUmlaute(str: string): string {
//     if (!str) return str;
//     return replaceUmlaute(str).toUpperCase();
// }

export function removeDuplicates<T>(array: T[], identityFunction: (item: T) => any): T[] {
    const identities = new Set<any>();
    return array.filter((item) => {
        const identity = identityFunction(item);
        if (identities.has(identity)) {
            return false; // Duplicate found, filter out this item
        } else {
            identities.add(identity); // New identity, keep this item
            return true;
        }
    });
}

export function compressUUID(uuid: string): string {
    return (uuid ?? '').replace(/-/g, '').substring(0, 32);
}

// export function getCountryNameFromCode(code: string): string {
//     if (!code) {
//         return '';
//     }
//     return countries.find((country) => country.code === code).name;
// }

export function propertyHasSetter(obj: any, key: any): boolean {
    const propertyDescriptor = Object.getOwnPropertyDescriptor(obj, key);
    return (propertyDescriptor && typeof propertyDescriptor.set === 'function') ?? false;
}

export function canOverwriteProperty(obj: object, property: string): boolean {
    const descriptor = Object.getOwnPropertyDescriptor(obj, property);
    if (!descriptor) {
        // Property does not exist on the object
        return false;
    }

    // A property can be overwritten if it is writable or configurable
    return !!descriptor.writable || !!descriptor.configurable;
}

export function isEmpty(obj: any): boolean {
    return obj === null || obj === undefined || obj === '';
}

// export function convertToEuroString(amount: number, showEuroSymbol = true) {
//     if (isNaN(amount) || typeof amount !== 'number') {
//         return '';
//     }
//     amount = amount === -0 ? 0 : amount;
//     return `${(amount / 100).toLocaleString('de-DE', { minimumFractionDigits: 2 })}${showEuroSymbol ? ' €' : ''}`;
// }

export function isSubset<T>(subset: T[], superset: T[]): boolean {
    return subset.every((elem) => superset.includes(elem));
}

export function deleteElementsByIndices<T>(list: T[], indices: number[]): void {
    // Sort indices array in descending order to avoid index shifting issues
    indices.sort((a, b) => b - a);

    // Remove elements from the array by index
    indices.forEach((index) => list.splice(index, 1));
}

// export function getConcatenatedOwners(relations: OwnerRelationDto[]): string {
//     relations = removeDuplicates(relations, (x) => x.person.id);
//     return relations
//         .map((ownershipOwner) =>
//             ownershipOwner.person.firstName
//                 ? `${ownershipOwner.person.firstName} ${ownershipOwner.person.lastName}`
//                 : ownershipOwner.person.companyName
//         )
//         .join(', ');
// }
