import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-signin-signup-container',
    templateUrl: './signin-signup-container.component.html',
    styleUrls: ['./signin-signup-container.component.scss'],
})
export class SigninSignupContainerComponent {
    @Input() public routeLink: 'sign-in' | 'sign-up' = 'sign-in';
    @Input() public rightImgSrc?: string;
    @Input() public containerWidthStyle = '31.25rem';
    @Input() public isRegisterPage = false;

    public readonly environment = environment;
}
