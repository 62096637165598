/**
 * SCALARA Accounting Module
 * The SCALARA Accounting API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OpenItemDto } from './openItemDto';
import { BankTransactionDto } from './bankTransactionDto';
import { BookingItemDto } from './bookingItemDto';
import { GuidedBookingInfoDto } from './guidedBookingInfoDto';
import { ReceiptDto } from './receiptDto';


export interface BookingDto { 
    bookingDate: string;
    effectiveFrom: string;
    effectiveTo: string;
    createdAt?: string;
    updatedAt?: string;
    id: string;
    ledgerId?: string;
    description: string;
    amount: number;
    labourAmount: number;
    bankTransactionId?: string;
    type?: BookingDto.TypeEnum;
    isCancelled?: boolean;
    cancelledBookingId?: string;
    isOpeningBooking?: boolean;
    category?: BookingDto.CategoryEnum;
    isCancelable?: boolean;
    cancellationBookingId?: string;
    hasConnectedReceipts?: boolean;
    isCreatedByEconomicPlan: boolean;
    receipts?: Array<ReceiptDto>;
    items?: Array<BookingItemDto>;
    guidedBookingInfo?: GuidedBookingInfoDto;
    openItems?: Array<OpenItemDto>;
    bankTransaction?: BankTransactionDto;
    vatRate?: number;
    vatAmount?: number;
    accountName?: string;
    accountDescription?: string;
    /**
     * In case that we are the displaying the booking belonging to an openitem, this field tells how much each openItem was reduced by this booking
     */
    openItemAmount?: number;
}
export namespace BookingDto {
    export type TypeEnum = 'MANUAL' | 'GUIDED' | 'OPENING_BALANCE' | 'CANCELLATION' | 'OES_CARRYOVER' | 'WEG_SETTLEMENT';
    export const TypeEnum = {
        Manual: 'MANUAL' as TypeEnum,
        Guided: 'GUIDED' as TypeEnum,
        OpeningBalance: 'OPENING_BALANCE' as TypeEnum,
        Cancellation: 'CANCELLATION' as TypeEnum,
        OesCarryover: 'OES_CARRYOVER' as TypeEnum,
        WegSettlement: 'WEG_SETTLEMENT' as TypeEnum
    };
    export type CategoryEnum = 'EXPENSE' | 'REVENUE' | 'LIABILITY' | 'RECEIVABLE';
    export const CategoryEnum = {
        Expense: 'EXPENSE' as CategoryEnum,
        Revenue: 'REVENUE' as CategoryEnum,
        Liability: 'LIABILITY' as CategoryEnum,
        Receivable: 'RECEIVABLE' as CategoryEnum
    };
}


