import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-additional-information-input',
    templateUrl: './additional-information-input.component.html',
    styleUrls: ['./additional-information-input.component.scss'],
})
export class AdditionalInformationInputComponent implements OnInit {
    @Input() public additionalInformation?: string | undefined;
    @Output() public updateAdditionalInformation = new EventEmitter<string | undefined>();

    public showInput$ = new Subject<boolean>();

    public constructor() {}

    public ngOnInit(): void {}

    public showInput(): void {
        this.showInput$.next(true);
    }

    public submit(): void {
        this.showInput$.next(false);
        this.updateAdditionalInformation.emit(this.additionalInformation);
    }

    public removeInfo(): void {
        this.additionalInformation = undefined;
        this.updateAdditionalInformation.emit(this.additionalInformation);
    }
}
