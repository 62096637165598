import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ListItem } from 'carbon-components-angular';
import { Observable, Subject, finalize, takeUntil, tap } from 'rxjs';
import { RadioTileSelectorOption } from 'src/app/core/models/radio-tile-selector-option.model';
import { ToastService } from 'src/app/core/services/toast.service';
import { formControl, formControlHasError } from 'src/app/core/utils/common';
import { CreateLedgerDto, LedgerDto, LedgersService } from 'src/app/generated-sources/accounting';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';
import { CellTemplate } from 'src/app/shared/table/enums/cell-template';
import { TableItem } from 'src/app/shared/table/interfaces/table-item';
import {
    Ownership,
    Person,
    PotentialLedgerPropertyDto,
    PotentialLedgersService,
    Property,
} from '../../../../generated-sources/base';

@Component({
    selector: 'app-add-ledger-form',
    templateUrl: './add-ledger-form.component.html',
    styleUrls: ['./add-ledger-form.component.scss'],
})
export class AddLedgerFormComponent extends OverlayChildComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();

    public form: UntypedFormGroup = new UntypedFormGroup({});
    public isLoading = false;
    public isInitialLoading = true;
    public selectedLedgerType: PotentialLedgerPropertyDto.LedgerTypesEnum =
        PotentialLedgerPropertyDto.LedgerTypesEnum.Weg;
    public selectedPropertyType: PotentialLedgerPropertyDto.LedgerTypesEnum =
        PotentialLedgerPropertyDto.LedgerTypesEnum.Weg;
    public isTileSelectionVisible = false;
    public potentialLedgersProperties: PotentialLedgerPropertyDto[] = [];
    public toastSuccessMessage?: string;
    public currentProperties: ListItem[] = [];
    public relatedOwnerships: ListItem[] = [];
    public relatedOwnershipsTable: TableItem[][] = []; //multi-selector data
    public relatedOWnershipsTableHeader: string[] = [];
    public selectedPotentialLedgerProperty?: ListItem;
    public selectedRelatedOwnership?: ListItem;
    public selectedOwnershipIds: string[] = [];

    public tileSelectorOptions: RadioTileSelectorOption[] = [
        {
            description: 'ACCOUNTING.ADD_LEDGER_FORM.TILE_OPTION_WEG',
            iconSrc: '40_buildings-WEG.svg',
            color: 'green',
        },
        {
            description: 'ACCOUNTING.ADD_LEDGER_FORM.TILE_OPTION_SEV',
            iconSrc: '40_door.svg',
            color: 'orange',
        },
    ];

    public constructor(
        private formBuilder: UntypedFormBuilder,
        private ledgerService: LedgersService,
        private translateService: TranslateService,
        private router: Router,
        private toastService: ToastService,
        private potentialLedgersService: PotentialLedgersService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.isInitialLoading = true;
        this.form = this.createForm();
        this.initTableHeader();

        this.translateService
            .get('ACCOUNTING.ADD_LEDGER_FORM.OPEN_LEDGER_SUCCESS_NOTIFICATION')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((result) => (this.toastSuccessMessage = result));

        this.potentialLedgersService
            .findAllPropertiesWithPotentialLedgers()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((properties: PotentialLedgerPropertyDto[]) => {
                this.potentialLedgersProperties = properties;
                this.currentProperties = this.potentialLedgersProperties.map((potentialLedgerProperty) => ({
                    selected: false,
                    content: this.getFullPropertyText(potentialLedgerProperty.property),
                    propertyId: potentialLedgerProperty.property.id,
                    ledgerTypes: potentialLedgerProperty.ledgerTypes,
                    propertyType: potentialLedgerProperty.property.propertyType,
                }));
                this.isInitialLoading = false;
            });
    }

    public createForm(): UntypedFormGroup {
        return this.formBuilder.group({
            propertyId: [null, [Validators.required]],
        });
    }

    public isInvalid(controlName: string): boolean {
        return formControlHasError(formControl(this.form, controlName), 'required');
    }

    public isInvalidProperty(controlName: string): boolean {
        const control = this.form.get(controlName) as UntypedFormControl;
        return formControlHasError(control, 'required');
    }

    public onSelectProperty($event: any): void {
        this.isLoading = true;
        this.selectedRelatedOwnership = undefined;
        this.relatedOwnerships = [];
        this.relatedOwnershipsTable = [];
        this.selectedOwnershipIds = [];

        const ledgerTypes: string[] = $event.item.ledgerTypes;
        this.form.get('propertyId')?.patchValue($event.item['propertyId']);
        this.selectedPotentialLedgerProperty = $event.item;
        this.selectedPropertyType = $event.item.propertyType;
        this.isTileSelectionVisible = ledgerTypes.length === 2 && this.selectedPropertyType !== 'SEV';

        if (ledgerTypes.indexOf('MV') >= 0) {
            this.selectedLedgerType = PotentialLedgerPropertyDto.LedgerTypesEnum.Mv;
        } else {
            if (this.isTileSelectionVisible || !(ledgerTypes.indexOf('SEV') >= 0)) {
                this.selectedLedgerType = PotentialLedgerPropertyDto.LedgerTypesEnum.Weg;
            } else {
                this.selectedLedgerType = PotentialLedgerPropertyDto.LedgerTypesEnum.Sev;
            }
        }

        if (ledgerTypes.indexOf('SEV') >= 0) {
            this.getListRelatedOwnerships($event.item.propertyId)
                .pipe(
                    tap((relatedOwnerships: Ownership[]) => {
                        this.relatedOwnerships = relatedOwnerships.map((relatedOwnership: Ownership) => ({
                            selected: false,
                            content: this.prepareContentRelatedOwnershipsList(relatedOwnership),
                            propertyId: relatedOwnership.property?.id,
                            ownershipId: relatedOwnership.id,
                            description: relatedOwnership.name,
                            ownerName: relatedOwnership.location,
                        }));
                        this.isLoading = false;
                    }),
                    takeUntil(this.unsubscribe$)
                )
                .subscribe();
        } else {
            this.isLoading = false;
        }
    }

    public onSelectRelatedOwnership($event: any): void {
        this.selectedRelatedOwnership = $event.item;
        this.isLoading = true;

        this.selectedLedgerType = PotentialLedgerPropertyDto.LedgerTypesEnum.Sev;
        if (this.selectedPotentialLedgerProperty) {
            this.getListRelatedOwnerships(
                this.selectedPotentialLedgerProperty['propertyId'],
                $event.item['ownershipId']
            )
                .pipe(
                    tap((relatedOwnerships: Ownership[]) => {
                        this.relatedOwnershipsTable = relatedOwnerships
                            .filter((ownership: Ownership) => ownership.id != $event.item['ownershipId'])
                            .map((relatedOwnership: Ownership) => {
                                return this.createRow({
                                    selected: false,
                                    content: [
                                        relatedOwnership.name,
                                        relatedOwnership.building,
                                        relatedOwnership.location,
                                    ].join(', '),
                                    propertyId: relatedOwnership.property?.id,
                                    ownershipId: relatedOwnership.id,
                                });
                            });
                        this.isLoading = false;
                    }),
                    takeUntil(this.unsubscribe$)
                )
                .subscribe();
        }
    }

    public onRowClick($event: any): void {
        //retrieving ownership Id of that row
        const ownershipId = $event.data[1].data.link;
        const indexOfId = this.selectedOwnershipIds.indexOf(ownershipId);
        if (indexOfId != -1) {
            this.selectedOwnershipIds.splice(indexOfId, 1);
        } else {
            this.selectedOwnershipIds.push(ownershipId);
        }
        console.log(this.selectedOwnershipIds);
    }

    public invalidatePropertyId(): void {
        // Invalidate if user manually inputs or deletes stuff
        this.form.get('propertyId')?.patchValue(null);
    }

    private getFullPropertyText(property: Property): string {
        return `${property.name}, ${property.address.streetName} ${property.address.streetNumber}, ${property.address.zipCode}, ${property.address.area}`;
    }

    private getListRelatedOwnerships(propertyId: string, ownershipId?: string): Observable<Ownership[]> {
        return this.potentialLedgersService.findAllOwnershipsWithPotentialLedgers(propertyId, ownershipId);
    }

    private initTableHeader(): void {
        this.relatedOWnershipsTableHeader = ['', ''];
    }

    private createRow(item: ListItem): TableItem[] {
        const itemDataTable = [
            {
                data: {
                    label: item['content'],
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: 'row-available',
                    link: item['ownershipId'], //storing ownership Id of that row
                },
                template: CellTemplate.iconWithStatusItem,
            },
        ];
        return itemDataTable;
    }

    public switchLedgerTypeSelection(index: number): void {
        if (index == 0) {
            this.selectedLedgerType = PotentialLedgerPropertyDto.LedgerTypesEnum.Weg;
        } else {
            this.selectedLedgerType = PotentialLedgerPropertyDto.LedgerTypesEnum.Sev;
            this.selectedRelatedOwnership = undefined;
            this.relatedOwnershipsTable = [];
            this.selectedOwnershipIds = [];
        }
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public onSubmit(): void {
        if (this.form.invalid) {
            return;
        }

        if (this.selectedPotentialLedgerProperty) {
            this.isLoading = true;
            const dto: CreateLedgerDto = {
                type: this.selectedLedgerType,
                propertyId: this.selectedPotentialLedgerProperty['propertyId'],
            };

            if (this.selectedRelatedOwnership) {
                const ownerships = this.selectedOwnershipIds;
                ownerships.push(this.selectedRelatedOwnership['ownershipId']);
                dto.ownershipIds = ownerships;
            }

            this.ledgerService
                .createLedger(dto)
                .pipe(
                    takeUntil(this.unsubscribe$),
                    finalize(() => (this.isLoading = false))
                )
                .subscribe({
                    next: (ledger: LedgerDto) => {
                        this.router.navigate(['/accounting', 'ledger', ledger.id, 'dashboard']);
                        this.toastService.showSuccess(this.toastSuccessMessage!);
                        this.saveEmitter$.next();
                    },
                    error: (error) => {
                        if (error) {
                            this.toastService.showError(error.error['message']);
                        }
                    },
                });
        } else {
            console.error('potential ledger property is undefined!');
        }
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    private prepareContentRelatedOwnershipsList(relatedOwnership: Ownership): string {
        const content = [];

        if (relatedOwnership.name) {
            content.push(relatedOwnership.name);
        }
        if (relatedOwnership.building) {
            content.push(relatedOwnership.building);
        }
        if (relatedOwnership.location) {
            content.push(relatedOwnership.location);
        }

        relatedOwnership.owners.map((owner: Person) => {
            const ownerInfo = [];
            if (owner.firstName) {
                ownerInfo.push(owner.firstName);
            }
            if (owner.lastName) {
                ownerInfo.push(owner.lastName);
            }
            if (owner.companyName) {
                ownerInfo.push(owner.companyName);
            }

            content.push(ownerInfo.join(' '));
        });
        return content.join(', ');
    }
}
