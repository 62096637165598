<div class="tw-flex tw-flex-col">
    <div class="tw-s-head-14-22-bold tw-mx-1 tw-mb-2 tw-flex-row" *ngIf="toggleInformation?.headline">
        {{ toggleInformation?.headline }}
    </div>
    <div class="tw-flex tw-flex-row tw-justify-start tw-mx-0 tw-pd-0 tw-h-[42px]">
        <div
            class="tw-border-scalaraGray-06 tw-border-solid tw-border-t-[1px] tw-border-l-[1px] tw-border-b-[1px]"
            [ngClass]="{
                'tw-bg-scalaraGray-06': this.leftSelected
            }"
        >
            <app-tooltip position="bottom">
                <div content class="tw-px-12 tw-py-8 tw-w-[400px]">
                    {{ toggleInformation?.leftToggleInfo }}
                </div>
                <div hoverable-item>
                    <app-scalara-button (click)="switchA()" class="mr-05" variant="ghost">{{
                        toggleInformation?.leftToggleText
                    }}</app-scalara-button>
                </div></app-tooltip
            >
        </div>

        <div
            class="tw-border-scalaraGray-06 tw-border-solid tw-border-t-[1px] tw-border-r-[1px] tw-border-b-[1px]"
            [ngClass]="{
                'tw-bg-scalaraGray-06': !this.leftSelected
            }"
        >
            <app-tooltip position="bottom">
                <div content class="tw-px-12 tw-py-8 tw-w-[400px]">
                    {{ toggleInformation?.rightToggleInfo }}
                </div>
                <div hoverable-item>
                    <app-scalara-button (click)="switchB()" class="mr-05" variant="ghost" type="button">{{
                        toggleInformation?.rightToggleText
                    }}</app-scalara-button>
                </div></app-tooltip
            >
        </div>
    </div>
</div>
