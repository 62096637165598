import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ListItem } from 'carbon-components-angular';
import { Subject, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { TicketDto, TicketsService, UpdateTicketDto } from 'src/app/generated-sources/base';

interface StatusListItem extends ListItem {
    icon: string;
    iconColor: string;
    value: string;
}

@Component({
    selector: 'app-ticket-status-dropdown',
    templateUrl: './ticket-status-dropdown.component.html',
    styleUrls: ['./ticket-status-dropdown.component.scss'],
})
export class TicketStatusDropdownComponent implements OnChanges {
    public selectedStatus = '';
    public selectedStatusDisabled?: StatusListItem;
    private unsubscribe$ = new Subject<void>();

    @Input() public ticket?: TicketDto;
    @Input() public disabled = false;
    @Output() public refreshTicketPage = new EventEmitter<void>();

    public statusList: StatusListItem[] = [
        {
            content: this.translateService.instant('ENTITIES.TICKET.OPEN'),
            selected: false,
            value: 'OPEN',
            icon: '24_open.svg',
            iconColor: 'orange',
        },
        {
            content: this.translateService.instant('ENTITIES.TICKET.IN_PROGRESS'),
            selected: false,
            value: 'IN_PROGRESS',
            icon: '24_progress.svg',
            iconColor: 'blue',
        },
        {
            content: this.translateService.instant('ENTITIES.TICKET.CLOSED'),
            selected: false,
            value: 'CLOSED',
            icon: '24_closed.svg',
            iconColor: 'green',
        },
    ];

    public constructor(
        private ticketService: TicketsService,
        private toastService: ToastService,
        private translateService: TranslateService
    ) {}

    public ngOnChanges(): void {
        this.statusList = this.statusList.map((item) => {
            if (item.value === this.ticket?.status) {
                item.selected = true;
                this.selectedStatus = this.ticket.status.toLowerCase();
            } else {
                item.selected = false;
            }

            return item;
        });
        if (this.disabled) {
            this.selectedStatusDisabled = this.statusList.find((item) => item.value === this.ticket?.status);
        }
    }

    public onChangeStatus($event: any): void {
        this.selectedStatus = $event.item.value.toLowerCase();
        const patchTicketDto: UpdateTicketDto = {
            status: $event.item.value,
        };

        this.ticketService
            .update(this.ticket?.id || '', patchTicketDto)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: () => {
                    this.refreshTicketPage.emit();
                },
                error: (error) => {
                    if (error) {
                        this.toastService.showError(error.error['message']);
                    }
                },
            });
    }
}
