import { ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ListItem, RadioChange } from 'carbon-components-angular';
import { PersonType } from 'src/app/core/models/person-type.enum';
import { ValidatorPatterns, phoneNumberTypeFromEnum } from 'src/app/core/utils/common';
import { controlInFormGroupIsInvalid } from 'src/app/core/utils/formValidationHelpers';
import { TooltipKey } from 'src/app/features/account-settings/services/custom-tooltip.service';
import { Address, Person, PhoneNumber } from 'src/app/generated-sources/base';
import { AutocompletePlaceSelectedItem } from '../components/scalara-input-address-autocomplete/scalara-input-address-autocomplete.component';
import { Country } from '../country';
import { countries } from '../country-data';

type FlowType = 'add-owner' | 'add-service-provider';

@Component({
    selector: 'app-user-infos-form',
    templateUrl: './person-infos-form.component.html',
    styleUrls: ['./person-infos-form.component.scss'],
})
export class PersonInfosFormComponent implements OnChanges, OnInit {
    public constructor(
        private formBuilder: UntypedFormBuilder,
        private cd: ChangeDetectorRef,
        public translateService: TranslateService
    ) {}
    @Input() public person?: Person;
    //  flow is used to decide what tooltip to show
    @Input() public flowType?: FlowType;
    @Input() public existingAddress?: Address;

    public phoneNoteList: ListItem[] = Object.entries(PhoneNumber.TypeEnum).map((item) => {
        return {
            content: phoneNumberTypeFromEnum(item[1]),
            selected: false,
            value: item[1],
        };
    });

    public tooltipI18Config: Record<FlowType, string> = {
        'add-owner': 'PAGES.TOOLTIPS.PERSON_INFOS_FORM.LABEL_TYPE_ADD_OWNER',
        'add-service-provider': 'PAGES.TOOLTIPS.PERSON_INFOS_FORM.LABEL_TYPE_SERVICE_PROVIDER',
    };

    public personTypeDeclared = PersonType;
    public countries: Country[] = countries;
    public personForm: UntypedFormGroup = new UntypedFormGroup({});

    public ngOnInit(): void {
        this.personForm = this.createPersonForm();
        if (this.person) {
            this.personForm = this.createPersonForm();
            this.setValidadorsBasedOnType({ personType: this.person.type as any });
        }
    }

    public ngOnChanges(): void {
        if (this.person) {
            this.personForm = this.createPersonForm();
            this.setValidadorsBasedOnType({ personType: this.person.type as any });
        }
    }

    private createPersonForm(): UntypedFormGroup {
        return this.formBuilder.group({
            type: [this.person?.type ?? this.personTypeDeclared.NAT_PERSON, [Validators.required]],
            firstName: [this.person?.firstName ?? null, [Validators.required]],
            lastName: [this.person?.lastName ?? null, [Validators.required]],
            companyName: [this.person?.companyName ?? null],
            email: [this.person?.email ?? null, [Validators.pattern(ValidatorPatterns.EMAIL)]],
            secondaryEmail: [this.person?.secondaryEmail ?? null, [Validators.pattern(ValidatorPatterns.EMAIL)]],
            address: this.formBuilder.group({
                streetName: [
                    (this.person?.address?.streetName || this.existingAddress?.streetName) ?? null,
                    [Validators.required],
                ],
                streetNumber: [
                    (this.person?.address?.streetNumber || this.existingAddress?.streetNumber) ?? null,
                    [Validators.required],
                ],
                streetAddition: [
                    (this.person?.address?.streetAddition || this.existingAddress?.streetAddition) ?? null,
                ],
                zipCode: [
                    (this.person?.address?.zipCode || this.existingAddress?.zipCode) ?? null,
                    [Validators.required],
                ],
                area: [(this.person?.address?.area || this.existingAddress?.area) ?? null, [Validators.required]],
                country: ['DE', [Validators.required]],
            }),
            website: [this.person?.website],
            miscellaneousInfo: [this.person?.miscellaneousInfo],
            birthday: [this.person?.birthday ? [new Date(this.person?.birthday)] : null],
            companyRegistrationNumber: [this.person?.companyRegistrationNumber],
            vatIdentificationNumber: [this.person?.vatIdentificationNumber],
            taxNumber: [this.person?.taxNumber],
            phoneNumbers: this.populatePhones(),
        });
    }

    public populatePhones(): UntypedFormArray {
        const phones = this.person?.phoneNumbers?.map((item) => {
            const itemInPhoneNoteItems = this.phoneNoteList.find((itemList) => itemList['value'] === item.type);
            if (itemInPhoneNoteItems) {
                itemInPhoneNoteItems.selected = true;
            }
            return this.formBuilder.group({
                phoneNumber: [item.phoneNumber],
                phoneNumberType: [itemInPhoneNoteItems],
            });
        });

        return this.formBuilder.array(
            phones && phones.length > 0
                ? phones
                : [
                      this.formBuilder.group({
                          phoneNumber: [''],
                          phoneNumberType: [''],
                      }),
                  ]
        );
    }

    public onRadioChange($event: RadioChange): void {
        this.setValidadorsBasedOnType({ personType: $event.value as 'COMPANY' | 'NAT_PERSON' });
    }

    public setValidadorsBasedOnType({ personType }: { personType: 'COMPANY' | 'NAT_PERSON' }): void {
        if (personType == PersonType.COMPANY) {
            this.personForm.controls['companyName'].setValidators(Validators.required);
            this.personForm.controls['firstName'].clearValidators();
            this.personForm.controls['lastName'].clearValidators();
        } else {
            this.personForm.controls['companyName'].clearValidators();
            this.personForm.controls['firstName'].setValidators(Validators.required);
            this.personForm.controls['lastName'].setValidators(Validators.required);
        }

        this.personForm.controls['companyName'].setErrors(null);
        this.personForm.controls['firstName'].setErrors(null);
        this.personForm.controls['lastName'].setErrors(null);
        this.personForm.controls['companyName'].updateValueAndValidity();
        this.personForm.controls['firstName'].updateValueAndValidity();
        this.personForm.controls['lastName'].updateValueAndValidity();
    }

    public isInvalid(controlName: string): boolean {
        return controlInFormGroupIsInvalid(this.personForm, controlName);
    }

    public isAddressFieldInvalid(addressSubfieldName: string): boolean {
        return controlInFormGroupIsInvalid(this.personForm.get('address') as UntypedFormGroup, addressSubfieldName);
    }

    public onAutocompletePlaceSelected(item: AutocompletePlaceSelectedItem): void {
        const addressControl = this.personForm.controls['address'];
        addressControl.patchValue({
            streetName: item.formData.streetName,
            streetNumber: item.formData.streetNumber,
            zipCode: item.formData.postalCode,
            area: item.formData.city,
            country: item.formData.countryCode,
        });
        const formControlsLabels = ['streetName', 'streetNumber', 'zipCode', 'area', 'country'];
        formControlsLabels.forEach((label) => {
            const control = addressControl.get(label);
            if (!control) {
                return;
            }
            control.markAsTouched();
            control.markAsDirty();
            control.updateValueAndValidity();
        });
        this.cd.detectChanges();
    }

    public get tooltipInnerHtml(): string {
        const flowType = this.flowType;
        if (!flowType) {
            return '';
        }
        return this.translateService.instant(this.tooltipI18Config[flowType]);
    }

    public TooltipKey = TooltipKey;

    // PHONES CONTROL
    public get phonesControl(): UntypedFormArray {
        return this.personForm.controls['phoneNumbers'] as UntypedFormArray;
    }

    public addNewPhone(): void {
        this.phonesControl.push(
            this.formBuilder.group({
                phoneNumber: [''],
                phoneNumberType: [''],
            })
        );
    }

    public removePhone(index: number): void {
        this.phonesControl.removeAt(index);
    }

    public phoneControlItem(index: number): UntypedFormGroup {
        return this.phonesControl.at(index) as UntypedFormGroup;
    }

    public showBirtdayInput(): boolean {
        return (
            !!(this.person?.personRoles.includes('IS_OWNER') && this.personForm?.get('type')?.value === 'NAT_PERSON') ||
            this.flowType === 'add-owner'
        );
    }

    public showInputsForServiceProvider(): boolean {
        return this.person?.personRoles.includes('IS_SERVICE_PROVIDER') || this.flowType === 'add-service-provider';
    }

    public isPhoneFormControlInvalid(index: number, controlName: string): boolean {
        const control = this.phoneControlItem(index).get(controlName) as UntypedFormControl;
        return control.touched ? control.invalid : false;
    }

    public setPhoneValidation(index: number): void {
        if (this.phoneControlItem(index)?.get('phoneNumber')?.value) {
            this.phoneControlItem(index)?.get('phoneNumberType')?.setValidators(Validators.required);
        } else {
            this.phoneControlItem(index)?.get('phoneNumberType')?.clearValidators();
        }

        this.phoneControlItem(index)?.get('phoneNumberType')?.setErrors(null);
        this.phoneControlItem(index)?.get('phoneNumberType')?.updateValueAndValidity();
    }
}
