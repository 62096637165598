<form [formGroup]="form!">
    <div class="tw-grid tw-grid-cols-4 tw-gap-8 mb-5r" *ngIf="occupationType === 'RENTING'">
        <ng-container
            *ngTemplateOutlet="
                typeCard;
                context: {
                    typeTitle: 'Kaltmiete + Betriebskosten + Heizkosten',
                    icon: '40_euro.svg',
                    type: 'BASE_RENT_OPERATIONAL_COST_HEATING'
                }
            "
        ></ng-container>
        <ng-container
            *ngTemplateOutlet="
                typeCard;
                context: {
                    typeTitle: 'Kaltmiete + Gesamtvorauszahlung',
                    icon: '40_euro.svg',
                    type: 'BASE_RENT_TOTAL_PREPAID'
                }
            "
        ></ng-container>
        <ng-container
            *ngTemplateOutlet="
                typeCard;
                context: {
                    typeTitle: 'Pauschalmiete + Verbrauchsabhängig',
                    icon: '40_euro.svg',
                    type: 'BLANKET_RENT_CONSUMPTION'
                }
            "
        ></ng-container>
        <ng-container
            *ngTemplateOutlet="
                typeCard;
                context: {
                    typeTitle: 'Pauschalmiete',
                    icon: '40_euro.svg',
                    type: 'BLANKET_RENT'
                }
            "
        ></ng-container>
    </div>

    <div class="s-label-12-16-regular mb-2r s-gray-03" *ngIf="occupationType === 'OWN_OCCUPATION'">
        Wenn der Eigentümer Miete oder Nebenkosten zahlt, können Sie dies hier angeben. (Eigentümer in Selbsnutzung
        können auch Vorauszahlungen leisten.)
    </div>

    <ng-container formArrayName="rentIntervals">
        <ng-container *ngFor="let item of rentIntervalsControl.controls; let i = index" [formGroupName]="i">
            <ng-container *ngIf="i > 0">
                <hr class="mt-2r" />
                <div class="d-flex justify-content-end mb-0-5r">
                    <app-scalara-button
                        (click)="removeRentInterval(i)"
                        variant="ghost"
                        svgName="24_close.svg"
                        height="40px"
                    ></app-scalara-button>
                </div>
            </ng-container>
            <div>
                <div class="d-flex mb-1r">
                    <app-number-input
                        *ngIf="rentCalculationMethod !== 'BLANKET_RENT'"
                        [label]="'Kaltmiete' + (occupationType === 'RENTING' ? '*' : '')"
                        invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                        formControlName="rent"
                        class="input-small mr-1r"
                    ></app-number-input>

                    <app-number-input
                        *ngIf="rentCalculationMethod === 'BLANKET_RENT'"
                        label="Pauschalmiete*"
                        invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                        formControlName="rent"
                        class="input-small mr-1r"
                    ></app-number-input>

                    <app-number-input
                        *ngIf="['BASE_RENT_TOTAL_PREPAID', 'BLANKET_RENT_CONSUMPTION'].includes(rentCalculationMethod)"
                        [label]="'Vorauszahlung' + (occupationType === 'RENTING' ? '*' : '')"
                        invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                        formControlName="prepaidSum"
                        class="input-small mr-1r"
                    ></app-number-input>

                    <app-number-input
                        *ngIf="rentCalculationMethod === 'BASE_RENT_OPERATIONAL_COST_HEATING'"
                        [label]="'Betriebskosten' + (occupationType === 'RENTING' ? '*' : '')"
                        invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                        formControlName="operationalCosts"
                        class="input-small mr-1r"
                    ></app-number-input>

                    <app-number-input
                        *ngIf="rentCalculationMethod === 'BASE_RENT_OPERATIONAL_COST_HEATING'"
                        [label]="'Heizkosten' + (occupationType === 'RENTING' ? '*' : '')"
                        invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                        formControlName="heatingCosts"
                        class="input-small mr-1r"
                    ></app-number-input>

                    <ibm-date-picker
                        [label]="'Gültig ab' + (occupationType === 'RENTING' ? '*' : '')"
                        [id]="'startingDate' + '-' + i"
                        [placeholder]="'tt.mm.jjjj'"
                        [size]="'md'"
                        [language]="'de'"
                        [dateFormat]="'d.m.Y'"
                        class="mr-1r"
                        invalidText="Wählen Sie ein späteres Datum."
                        formControlName="startingDate"
                        [invalid]="isValidDate(i)"
                    >
                    </ibm-date-picker>

                    <div class="total-amount-container">
                        <div class="s-label-12-22-semibold mb-1r s-gray-03">Gesamt</div>
                        <div class="s-body-14-22-bold">{{ getPrepaidSum(i) | eurocent }} €</div>
                    </div>
                </div>
                <div
                    class="d-flex justify-content-end"
                    *ngIf="showPercentageTaxIncluded && occupationType !== 'OWN_OCCUPATION'"
                >
                    <div class="s-label-12-22-semibold s-gray-03 mr-1-75r">Steueranteil</div>
                    <div class="s-body-14-22-regular">{{ getTaxSum(i) | eurocent }} €</div>
                </div>
            </div>
        </ng-container>
    </ng-container>

    <div class="mb-2r">
        <app-scalara-button (click)="addRendInterval()" variant="ghost" svgName="24_add.svg"
            >Mietänderung hinzufügen</app-scalara-button
        >
    </div>

    <div
        *ngIf="occupationType === 'RENTING'"
        class="s-body-14-20-regular align-items-center d-flex justify-space-between"
    >
        <ibm-checkbox (change)="onCheckboxChange()">Anteil Umsatzsteuer enthalten</ibm-checkbox>

        <div *ngIf="showPercentageTaxIncluded" class="d-flex">
            <div class="s-label-12-22-semibold mr-1-5r">Steuersatz</div>

            <ibm-radio-group aria-label="radiogroup" orientation="horizontal" formControlName="percentageTaxIncluded">
                <ibm-radio class="mr-1-5r" value="7."> 7%</ibm-radio>
                <ibm-radio [checked]="true" value="19."> 19% </ibm-radio>
            </ibm-radio-group>
        </div>
    </div>

    <div class="mb-4-5r">
        <app-combo-box
            [size]="'md'"
            id="dueWorkingDay"
            [appendInline]="true"
            [label]="'Fälligkeit der Mietzahlung' + (getRentIntervalItemForm(0).value.rent ? '*' : '')"
            [items]="dueWorkingDayList"
            formControlName="dueWorkingDay"
            placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT' | translate }}"
            invalidText="{{ 'ERROR.REQUIRED' | translate }}"
            [invalid]="isInvalid('dueWorkingDay')"
            [disabled]="disabledDueWorkingDayDropdown()"
        >
            <ibm-dropdown-list></ibm-dropdown-list>
        </app-combo-box>
    </div>

    <ng-container *ngIf="occupationType === 'RENTING'">
        <div class="s-head-16-24-bold mb-1-5r">Kaution</div>
        <app-combo-box
            [size]="'md'"
            id="depositType"
            [appendInline]="true"
            label="Kautionstyp*"
            [items]="depositTypeList"
            formControlName="depositType"
            placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT' | translate }}"
            invalidText="{{ 'ERROR.REQUIRED' | translate }}"
            [class.mb-4-5r]="form?.value?.depositType?.value === 'NONE'"
            [invalid]="isInvalid('depositType')"
            (selected)="selectedDepositType($event)"
        >
            <ibm-dropdown-list></ibm-dropdown-list>
        </app-combo-box>
        <div *ngIf="form?.value?.depositType?.value !== 'NONE'" class="mt-2r mb-4-5r d-flex justify-space-between">
            <app-number-input
                label="Betrag*"
                invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                formControlName="depositAmount"
                class="mr-2r w-100"
                [invalid]="isInvalid('depositAmount')"
            ></app-number-input>

            <ibm-date-picker
                label="Fällig am*"
                id="depositDueDate"
                [placeholder]="'tt.mm.jjjj'"
                [size]="'md'"
                [language]="'de'"
                [dateFormat]="'d.m.Y'"
                class="w-100"
                formControlName="depositDueDate"
                [invalid]="isInvalid('depositDueDate')"
            >
            </ibm-date-picker>
        </div>

        <div class="s-head-16-24-bold mb-1-5r">
            {{ 'PAGES.OCCUPATION.ADD_OCCUPATION_FORM.LABEL_SMALL_REPAIR' | translate }}
        </div>

        <app-number-input
            label="{{ 'PAGES.OCCUPATION.ADD_OCCUPATION_FORM.LABEL_INDIVIDUAL_REPAIR' | translate }}"
            invalidText="{{ 'ERROR.REQUIRED' | translate }}"
            formControlName="oneTimeSmallRepairsAmount"
        ></app-number-input>

        <div class="tw-mt-2">
            <app-number-input
                label="{{ 'PAGES.OCCUPATION.ADD_OCCUPATION_FORM.LABEL_ANNUAL_REPAIR' | translate }}"
                invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                formControlName="annualSmallRepairsAmount"
            ></app-number-input>
        </div>
    </ng-container>

    <div class="s-head-16-24-bold mb-0-5r mt-4-5r">Sonstiges</div>
    <div class="s-label-12-16-regular s-gray-03 mb-1-5r">Weitere Infos/Bestandteile können Sie hier notieren</div>

    <ibm-label class="mb-0-5r">
        Sonstiges
        <textarea
            ibmTextArea
            rows="3"
            aria-label="textarea"
            maxlength="100000"
            formControlName="miscellaneousInfo"
        ></textarea>
    </ibm-label>
</form>

<ng-template #typeCard let-typeTitle="typeTitle" let-icon="icon" let-type="type">
    <app-card-selection
        iconColor="green"
        [iconSrc]="icon"
        [label]="typeTitle"
        [selected]="rentCalculationMethod === type"
        (click)="switchRentCalculationMethod(type)"
        minHeight="tw-min-h-[204px]"
    ></app-card-selection>
</ng-template>
