<ibm-loading
    *ngIf="isInitialLoading$ | async as isInitialLoading"
    [isActive]="isInitialLoading"
    [size]="'normal'"
    [overlay]="isInitialLoading"
>
</ibm-loading>
<ng-container *ngIf="(isInitialLoading$ | async) === false">
    <div class="s-head-28-36-bold mb-5r d-flex justify-space-between">
        <app-text-with-tooltip
            class="tw-inline-block"
            [label]="headline"
            [labelClass]="''"
            [tooltipKey]="TooltipKey.tooltipsInProperties"
            [tooltipInnerHtml]="tooltipText.headline"
        ></app-text-with-tooltip>

        <div *ngIf="propertyType === 'MV'">
            <app-tooltip position="left" [isDisabled]="possibleOwnershipsToCreate > 1">
                <div content class="demo-banner_tooltip-content">
                    <span>{{ 'PAGES.ABO.OWNERSHIPS_TOOLTIP_MESSAGE' | translate }}</span>
                    <div class="link" (click)="this.goToSubscriptions()">{{ 'PAGES.ABO.LINK_LABEL' | translate }}</div>
                </div>
                <div hoverable-item class="hoverable-item">
                    <app-scalara-button
                        *ngIf="isPropertyManager"
                        variant="ghost"
                        (click)="openAddOwnershipOverlay()"
                        svgName="24_add.svg"
                        [disabled]="possibleOwnershipsToCreate < 1"
                    >
                        {{ 'PAGES.OWNERSHIP.OVERVIEW.BUTTON_ADD_OWNERSHIP' | translate }}
                    </app-scalara-button>
                </div>
            </app-tooltip>
        </div>
    </div>

    <div class="mb-5r">
        <div
            class="notification-wrapper"
            *ngIf="!this.ownershipsNotificationMessageWasClosed && this.possibleOwnershipsToCreate < 1"
        >
            <!-- uncomment if need close button -->
            <!-- <app-scalara-button
                type="button"
                variant="ghost"
                svgName="24_close.svg"
                (click)="this.handleNotificationMessage()"
                >Schliessen</app-scalara-button
            > -->
            <app-notification type="warning">
                <span>{{ 'PAGES.ABO.OWNERSHIPS_NOTIFICATION' | translate }} </span>
                <div class="link" (click)="this.goToSubscriptions()">{{ 'PAGES.ABO.LINK_LABEL' | translate }}</div>
            </app-notification>
        </div>
        <div class="d-flex justify-space-between align-items-center mt-1r">
            <div *ngIf="propertyType !== 'MV'" class="s-head-16-24-bold">Wohn-/Teileigentumseinheiten</div>

            <div class="tw-flex">
                <app-search
                    [searchInputId]="propertyType === 'MV' ? 'mvPropertyOwnerships' : 'wegPropertyOwnerships'"
                ></app-search>
                <app-tooltip position="left" [isDisabled]="possibleOwnershipsToCreate > 1">
                    <div content class="demo-banner_tooltip-content">
                        <span>{{ 'PAGES.ABO.OWNERSHIPS_TOOLTIP_MESSAGE' | translate }}</span>
                        <div class="link" (click)="this.goToSubscriptions()">
                            {{ 'PAGES.ABO.LINK_LABEL' | translate }}
                        </div>
                    </div>
                    <div hoverable-item class="hoverable-item">
                        <app-scalara-button
                            *ngIf="isPropertyManager && propertyType !== 'MV'"
                            variant="ghost"
                            (click)="openAddOwnershipOverlay()"
                            svgName="24_add.svg"
                            [disabled]="possibleOwnershipsToCreate < 1"
                        >
                            {{ 'PAGES.OWNERSHIP.OVERVIEW.BUTTON_ADD_OWNERSHIP' | translate }}
                        </app-scalara-button>
                    </div>
                </app-tooltip>
            </div>
        </div>

        <div
            *ngIf="propertyType !== 'MV'"
            class="tw-my-4 tw-bg-scalaraGray-06 tw-p-4 tw-w-full tw-flex tw-justify-end tw-items-center"
        >
            <div class="tw-s-label-12-22-semibold tw-mr-4 tw-text-scalaraGray-03">MEA Gesamt</div>
            <div class="tw-s-head-16-24-bold">{{ fractionAmount }}</div>
        </div>

        <app-table
            emptyText="PAGES.OWNERSHIP.OVERVIEW.EMPTY_TABLE"
            [header]="tableModel.header"
            [data]="tableModel.data"
            [itemsPerPage]="1000"
            [isLoading]="isLoading"
            [tableSearchId]="propertyType === 'MV' ? 'mvPropertyOwnerships' : 'wegPropertyOwnerships'"
        >
        </app-table>
    </div>

    <app-document-table
        [showButton]="isPropertyManager"
        [tooltipInfo]="tooltipInfoForTable"
        [inputData]="{ flow: DocumentsFlow.property, fileReferenceEnum: 'OWNERSHIPS' }"
    ></app-document-table>
</ng-container>
