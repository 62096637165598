<ibm-loading *ngIf="isLoading" [isActive]="true" [size]="'normal'" [overlay]="true"></ibm-loading>
<div class="mt-1r mb-1r d-flex justify-content-end tw-space-x-2">
    <app-search [searchInputId]="'ownershipAdvancementsTableId'"></app-search>
    <app-scalara-button (click)="downloadAllPdfs('pdf')" [variant]="'ghost'" type="button" svgName="24_download.svg">
        Alle Herunterladen
    </app-scalara-button>
    <app-scalara-button (click)="downloadAllPdfs('zip')" [variant]="'ghost'" type="button" svgName="24_download.svg">
        Alle Herunterladen in zip
    </app-scalara-button>
</div>

<app-table
    [header]="ownershipAdvancementsTable.header"
    [data]="ownershipAdvancementsTable.data"
    tableSearchId="ownershipAdvancementsTableId"
    (handleFile)="handleOwnershipAdvancementsFile($event)"
    (rowClick)="openIndividualEconomicPlanTab($event)"
    class="clickable-rows"
>
</app-table>

<ng-container *ngIf="fileToView && showModal">
    <app-file-viewer [fileToView]="fileToView" (closeViewer)="closeModal()"></app-file-viewer>
</ng-container>

<ng-container *ngIf="!isLoading && ownershipAdvancementAmountDto.length === 0">
    <app-notification class="w-100" type="error">{{
        'PAGES.ECONOMIC_PLAN.DETAILS_TAB.MESSAGES.ERROR_MESSAGE' | translate
    }}</app-notification>
</ng-container>
