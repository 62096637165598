import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, debounceTime } from 'rxjs';

export type SearchInput = { searchInput: string; searchInputId: string };
@Injectable({
    providedIn: 'root',
})
export class TableSearchService {
    private constructor(private router: Router, private route: ActivatedRoute, private ngZone: NgZone) {}

    public getCurrentInputSearch$(): Observable<SearchInput | null> {
        return this.searchInput$.asObservable().pipe(debounceTime(250));
    }

    public setSearchInput(newSearchInput: string, searchInputId: string): void {
        this.searchInput$.next({ searchInput: newSearchInput, searchInputId: searchInputId });
        this.setSearchInputDataInUrl({ searchInputId, newSearchInput });
    }

    public setSearchInputDataInUrl({
        searchInputId,
        newSearchInput,
    }: {
        searchInputId: string;
        newSearchInput: string;
    }): void {
        const queryParamsSnapshot = this.route.snapshot.queryParams;

        //  url?tableSearch_ALL=searchValue&filterId=1 ->
        //  we want to remove table search related params (tableSearch_ALL) from url and keep other params (filterId)
        const existingParamsExcludingTable = Object.entries(queryParamsSnapshot)
            .filter(([key]) => key.startsWith('tableSearch_') === false)
            .map(([key, value]) => ({ [key]: value }))
            .reduce((acc, curr) => ({ ...acc, ...curr }), {});

        const newTableParam = { [`tableSearch_${searchInputId}`]: newSearchInput };

        // this hack is needed to make navigation component work properly
        setTimeout(
            () =>
                this.router.navigate([], {
                    relativeTo: this.route,
                    queryParams: { ...existingParamsExcludingTable, ...newTableParam },
                    replaceUrl: true,
                }),
            0
        );
    }

    private readonly searchInput$ = new BehaviorSubject<SearchInput | null>(null);
}
