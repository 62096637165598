import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ListItem } from 'carbon-components-angular';
import { Observable, Subject, forkJoin, switchMap, takeUntil, tap } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { getAdressFromPerson, getNameFromPerson, getServiceProviderType } from 'src/app/core/utils/common';
import {
    CommentDto,
    CreateCommentDto,
    Person,
    PropertiesService,
    RelationsService,
    ServiceProviderRelationDto,
    TicketDto,
    TicketsService,
} from 'src/app/generated-sources/base';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';
import { CellTemplate } from 'src/app/shared/table/enums/cell-template';
import { TableMultiSelectorComponent } from '../../../../shared/components/table-multi-selector/table-multi-selector.component';
import { HeaderItem } from '../../../../shared/table/interfaces/header-item';
import { TableItem } from '../../../../shared/table/interfaces/table-item';

interface ServiceProviderDto extends ServiceProviderRelationDto {
    servicesProvider?: string;
}

interface AllServiceProviderDto extends ServiceProviderRelationDto {
    servicesProvider?: string;
    completeAddress?: string;
}
@Component({
    selector: 'app-add-service-provider-overlay',
    templateUrl: './add-service-provider.component.html',
    styleUrls: ['./add-service-provider.component.scss'],
})
export class AddServiceProviderComponent extends OverlayChildComponent implements OnInit {
    private unsubscribe$ = new Subject<void>();
    public ticket?: TicketDto;
    public header?: (string | HeaderItem)[];
    public selectedOwnershipIds: string[] = [];
    public relatedOWnershipsTableHeader: string[] = [];
    public relatedOwnershipsTable: TableItem[][] = [];

    public serviceProvider: ServiceProviderDto[] = [];
    public allServiceProvider: AllServiceProviderDto[] = [];
    public isCollapsed = true;

    public serviceProviderList: ListItem[] = [];

    public form: UntypedFormGroup = new UntypedFormGroup({});

    @ViewChild('serviceProviderItem', { static: true })
    public serviceProviderItem?: TemplateRef<any>;

    public constructor(
        private propertiesService: PropertiesService,
        public translateService: TranslateService,
        private ticketService: TicketsService,
        private toastService: ToastService,
        private relationsService: RelationsService,
        private formBuilder: UntypedFormBuilder
    ) {
        super();
    }

    @ViewChild('TableMultiSelectorComponent')
    public tableMultiSelectorComponent!: TableMultiSelectorComponent;

    public ngOnInit(): void {
        this.form = this.createForm();
        this.ticket = this.config?.data.ticket;
        this.propertiesService
            .findServiceProviderRelations(this.ticket?.property.id || '')
            .pipe(
                tap((serviceProvider) => {
                    this.serviceProvider = serviceProvider
                        .map((value, index, self) => {
                            const item: ServiceProviderDto = { ...value };
                            index ===
                                self.findIndex((t) => {
                                    if (t.person?.id === value.person?.id) {
                                        if (item.servicesProvider) {
                                            item.servicesProvider =
                                                item.servicesProvider +
                                                ', ' +
                                                getServiceProviderType(this.translateService, t.serviceProvided);
                                        } else {
                                            item.servicesProvider = getServiceProviderType(
                                                this.translateService,
                                                t.serviceProvided
                                            );
                                        }
                                    }
                                });
                            return item;
                        })
                        .filter(
                            (value, index, self) => index === self.findIndex((t) => t.person.id === value.person.id)
                        );

                    this.relatedOwnershipsTable = this.serviceProvider.map((serviceProvider) => {
                        return this.createRow(serviceProvider);
                    });
                }),
                switchMap(() => this.relationsService.findAll('IS_SERVICE_PROVIDER')),
                tap((serviceProvider) => {
                    const item = serviceProvider as ServiceProviderRelationDto[];
                    this.allServiceProvider = item
                        .map((value, index, self) => {
                            const item: AllServiceProviderDto = { ...value };
                            index ===
                                self.findIndex((t) => {
                                    if (t.person?.id === value.person?.id) {
                                        if (item.servicesProvider) {
                                            item.servicesProvider =
                                                item.servicesProvider +
                                                ', ' +
                                                getServiceProviderType(this.translateService, t.serviceProvided);
                                            item.completeAddress = getAdressFromPerson(t.person);
                                        } else {
                                            item.servicesProvider = getServiceProviderType(
                                                this.translateService,
                                                t.serviceProvided
                                            );
                                        }
                                    }
                                });
                            return item;
                        })
                        .filter(
                            (value, index, self) => index === self.findIndex((t) => t.person.id === value.person.id)
                        );

                    this.serviceProviderList = this.allServiceProvider.map((item) => {
                        return {
                            content:
                                getNameFromPerson(item.person) +
                                ', ' +
                                item.servicesProvider +
                                ', ' +
                                getAdressFromPerson(item.person),
                            selected: false,
                            value: item.person.id,
                        };
                    });
                })
            )
            .subscribe();

        this.initTableHeader();
    }

    public createForm(): UntypedFormGroup {
        return this.formBuilder.group({
            comment: [null],
            serviceProvider: [],
        });
    }

    private createRow(item: ServiceProviderDto): TableItem[] {
        return [
            {
                data: {
                    label: '',
                    extraData: {
                        person: item.person,
                        serviceProvided: item.servicesProvider,
                    },
                },
                template: this.serviceProviderItem,
            },
            {
                data: {
                    label: 'row-available',
                    link: item.person.id,
                },
                template: CellTemplate.iconWithStatusItem,
            },
        ];
    }

    private initTableHeader(): void {
        this.relatedOWnershipsTableHeader = ['PAGES.TICKET_DETAILS.TABLE_HEADER_SERVICE_PROVIDER', ''];
    }

    public onRowClick($event: any): void {
        const ownershipId = $event.data[1].data.link;
        const indexOfId = this.selectedOwnershipIds.indexOf(ownershipId);
        if (indexOfId != -1) {
            this.selectedOwnershipIds.splice(indexOfId, 1);
        } else {
            this.selectedOwnershipIds.push(ownershipId);
        }
    }

    public getServiceProvidedLabel(serviceProvided: ServiceProviderRelationDto.ServiceProvidedEnum): string {
        if (!serviceProvided) {
            return '';
        }
        return getServiceProviderType(this.translateService, serviceProvided);
    }

    public getNameFromPerson(person?: Person): string {
        if (!person) {
            return '';
        }
        return getNameFromPerson(person) + ',';
    }

    public onSubmit(): void {
        if (this.form.value.serviceProvider) {
            this.selectedOwnershipIds.push(this.form.value.serviceProvider.value);
        }
        const obs: (Observable<TicketDto> | Observable<CommentDto>)[] = [
            this.ticketService.update(this.ticket?.id || '', { serviceProviderIds: this.selectedOwnershipIds }),
        ];

        if (this.form.value.comment) {
            const createCommentDto: CreateCommentDto = {
                description: this.form.value.comment,
                fileStorageIds: [],
            };

            obs.push(this.ticketService.createComment(this.ticket?.id || '', createCommentDto));
        }

        forkJoin(obs)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: () => {
                    this.toastService.showSuccess(this.translateService.instant('PAGES.ADD_TICKET.TOAST_SUCCESS_EDIT'));
                    this.saveEmitter$.next();
                },
                error: (error) => {
                    if (error) {
                        this.toastService.showError(error.error['message']);
                    }
                },
            });
    }

    public onCancel(): void {
        this.cancelEmitter$.next();
    }

    public changeSelectDisplay(): void {
        this.isCollapsed = !this.isCollapsed;
    }
}
