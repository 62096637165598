import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

export type ButtonVariant =
    | 'default'
    | 'danger'
    | 'purple'
    | 'blue'
    | 'orange'
    | 'outline'
    | 'ghost'
    | 'icon-only'
    | 'icon-only-blue';
type Height = '48px' | '40px';

@Component({
    selector: 'app-scalara-button',
    templateUrl: './scalara-button.component.html',
    styleUrls: ['./scalara-button.component.scss'],
})
export class ScalaraButtonComponent implements OnInit {
    @Input() public type: 'button' | 'submit' = 'button';
    @Input() public variant: ButtonVariant = 'default';
    @Input() public disabled = false;
    @Input() public svgName? = '';
    //  will be overriden in some cases in order to not allow some variants + sizes
    @Input() public height?: Height;
    @Input() public fullWidth = false;
    @Input() public buttonStyles = '';
    @Input() public onClick: (...args: any) => any = (): null => null;
    @Input() public isLoading = false;
    @Input() public iconStyle?: string;

    public sanitizedUrl!: SafeUrl;

    public constructor(private sanitizer: DomSanitizer) {}

    //  check button variants and sizes
    //  https://www.figma.com/file/8eSEgIwf5xpiNFtdipZny6/Design-System-%2F-Components-Colors-Typo?node-id=0%3A1&t=yWLP9hCzpMIAF1la-0
    public ngOnInit(): void {
        this.sanitizer.bypassSecurityTrustHtml('');
        //  if height is set by input, do not override it
        if (this.height) {
            return;
        }

        //  set height for (all buttons with icon and text) or (all ghost buttons)
        if ((this.variant !== 'icon-only' && this.svgName) || this.variant === 'ghost') {
            this.height = '40px';
            return;
        }
        if (['purple', 'blue'].includes(this.variant)) {
            this.height = '40px';
            return;
        }
        this.height = '48px';
    }
}
