<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<div ibmGrid class="bx--grid--full-width pb-10r" *ngIf="!this.isLoading">
    <div ibmRow>
        <div ibmCol></div>
        <div ibmCol [columnNumbers]="{ md: 7, lg: 7 }" class="px-00">
            <div ibmRow class="s-head-28-36-bold mb-5r">
                <div class="d-flex align-items-center">
                    <div class="mr-06">
                        <app-icon-bubble [size]="72" [color]="'red'" [image]="'40_delete.svg'"></app-icon-bubble>
                    </div>
                    <div>
                        {{ isReceivables ? 'Forderung' : 'Verbindlichkeit' }}
                        {{ 'PAGES.OPEN_ITEMS.DELETE_OPEN_ITEM.DELETE' | translate }}
                    </div>
                </div>
            </div>

            <div ibmRow class="s-head-16-24-bold mb-2r">
                {{
                    'PAGES.OPEN_ITEMS.DELETE_OPEN_ITEM.DELETE_CONFIRMATION'
                        | translate : { type: isReceivables ? 'Forderung' : 'Verbindlichkeit' }
                }}
            </div>

            <div ibmRow class="s-head-14-22-regular mb-3-5r">
                {{ 'PAGES.OPEN_ITEMS.DELETE_OPEN_ITEM.WARNING' | translate }}
            </div>

            <div *ngIf="receipts.length > 0" ibmRow class="s-body-14-22-regular mb-1r">
                {{ 'PAGES.OPEN_ITEMS.DELETE_OPEN_ITEM.SELECT_THE_RECEIPT' | translate }}
            </div>
            <form [formGroup]="form" class="mb-7-5r">
                <div *ngFor="let receipt of receipts" ibmRow class="receipts-container mb-0-5r">
                    <app-tooltip position="bottom" *ngIf="receipt.disabledToDelete">
                        <div content>
                            Dieser Beleg kann nicht gelöscht werden, da er anderweitig verknüpft ist oder unabhängig des
                            Merkposten hochgeladen wurde.
                        </div>
                        <div hoverable-item>
                            <div class="d-flex align-items-center receipt-disabled">
                                <ibm-checkbox [formControlName]="receipt.id" [disabled]="receipt.disabledToDelete">
                                </ibm-checkbox>
                                <svg-icon
                                    class="d-flex align-items-center ml-1-5r"
                                    src="/assets/icons/24_dokument.svg"
                                ></svg-icon>
                                <span class="s-body-14-20-regular ml-0-75r s-gray-03">
                                    {{ receipt?.fileName }}
                                </span>
                            </div>
                        </div>
                    </app-tooltip>
                    <div *ngIf="!receipt.disabledToDelete" class="d-flex align-items-center receipt-enabled">
                        <ibm-checkbox [formControlName]="receipt.id"> </ibm-checkbox>
                        <svg-icon
                            class="d-flex align-items-center ml-1-5r"
                            src="/assets/icons/24_dokument.svg"
                        ></svg-icon>
                        <span class="s-body-14-20-regular ml-0-75r s-gray-01"> {{ receipt?.fileName }} </span>
                    </div>
                </div>
            </form>
            <div ibmRow class="d-flex justify-space-between">
                <button (click)="abort()" [ibmButton]="'tertiary'" type="reset">
                    {{ 'COMMON.BTN_CANCEL' | translate }}
                </button>
                <button [ibmButton]="'danger'" (click)="submit()">
                    {{ 'ACCOUNTING.DELETE_ECONOMIC_PLAN.DELETE_BUTTON' | translate }}
                </button>
            </div>
        </div>
        <div ibmCol></div>
    </div>
</div>
