<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"></ibm-loading>
<app-overlay-container
    headlineTitle="PAGES.RELATIONS.OWNER.LABEL_ADD"
    containerSize="small"
    image="40_add-person.svg"
    colorIcon="green"
    [sizeIcon]="72"
>
    <div class="pb-5r">
        <div class="tw-grid tw-grid-cols-2 tw-gap-8">
            <app-card-selection
                iconColor="green"
                iconSrc="40_edit.svg"
                label="{{ 'PAGES.RELATIONS.ADD_RELATION_OVERLAY.LABEL_ADD_PERSON' | translate }}"
                [selected]="!isExistingUserSelected"
                (click)="switchExistingUserSelection(false)"
            ></app-card-selection>
            <app-card-selection
                iconColor="green"
                iconSrc="40_person.svg"
                label="   {{ 'PAGES.RELATIONS.ADD_RELATION_OVERLAY.LABEL_SELECT_EXISTING_PERSON' | translate }}"
                [selected]="isExistingUserSelected"
                (click)="switchExistingUserSelection(true)"
            ></app-card-selection>
        </div>
    </div>

    <!-- use css hidden instead of ngIf because its broken -->
    <div [hidden]="this.isExistingUserSelected">
        <app-user-infos-form flowType="add-owner"></app-user-infos-form>
    </div>

    <div [hidden]="!this.isExistingUserSelected">
        <app-existing-persons-combo-box
            (handlePersonSelection)="onSelectPerson($event)"
            (isLoadingEvent)="isExistingPersonsLoading($event)"
        ></app-existing-persons-combo-box>
    </div>

    <div class="mb-06">
        <hr class="mt-5r" />
        <div class="s-head-16-24-bold pt-3r pb-2r">
            <app-text-with-tooltip
                class="tw-inline-block"
                [label]="'PAGES.RELATIONS.ADD_RELATION_OVERLAY.LABEL_RELATION_INTERVAL_HEADLINE' | translate"
                [labelClass]="''"
                [tooltipKey]="TooltipKey.tooltipsInProperties"
                [tooltipInnerHtml]="'PAGES.TOOLTIPS.ADD_RELATION_OVERLAY.LABEL_RELATION_INTERVAL_HEADLINE' | translate"
            ></app-text-with-tooltip>
        </div>
        <app-date-range-picker></app-date-range-picker>
        <hr />
    </div>

    <div class="mb-06">
        <div class="tw-s-head-16-24-bold tw-mb-6">Ansprechpartner bei mehreren Eigentümern je Einheit</div>
        <ibm-checkbox [checked]="true" [(ngModel)]="isContactPerson"
            ><span class="tw-ml-2">Diesen Kontakt als Hauptansprechpartner vermerken</span>
        </ibm-checkbox>
    </div>

    <div class="tw-flex tw-justify-between tw-mt-[120px] tw-mb-20">
        <app-scalara-button (click)="abort()" variant="outline">{{
            'CONTROLS.BUTTONS.CANCEL' | translate
        }}</app-scalara-button>

        <app-scalara-button (click)="onSubmit()" [disabled]="!this.isFormsValid()">
            {{ 'CONTROLS.BUTTONS.SAVE' | translate }}</app-scalara-button
        >
    </div>
</app-overlay-container>
