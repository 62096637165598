import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
    MissingTranslationHandler,
    MissingTranslationHandlerParams,
    TranslateLoader,
    TranslateModule,
    TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AuthInterceptor } from './core/interceptors/AuthInterceptor';
import { InjectorService } from './core/services/injector.service';
import { FeaturesModule } from './features/features.module';
import { OccupationModule } from './features/occupation/occupation.module';
import { UserManagementModule } from './features/user-management/user-management.module';
import { AccountingApiModule, Configuration as ConfigurationAccounting } from './generated-sources/accounting';
import { ApiModule, Configuration as ConfigurationApi } from './generated-sources/base';
import { Configuration as ConfigurationFile, fileApiModule } from './generated-sources/file';
import { SharedModule } from './shared/shared.module';
registerLocaleData(localeDe, 'de-DE', localeDeExtra);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// load localization on app initialization
export function appInitializerFactory(translate: TranslateService): () => Promise<any> {
    return (): Promise<any> => {
        translate.setDefaultLang('de');
        return firstValueFrom(translate.use('de'));
    };
}

export class MyMissingTranslationHandler implements MissingTranslationHandler {
    public handle(params: MissingTranslationHandlerParams): string {
        console.warn({
            msg: 'missing translation',
            key: params.key,
            translations: params.translateService.store.translations.de,
        });
        return 'missing translation for: ' + params.key;
    }
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        AngularSvgIconModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
            missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler },
        }),
        FeaturesModule,
        UserManagementModule,
        CoreModule,
        SharedModule,
        ApiModule.forRoot(() => {
            return new ConfigurationApi({
                basePath: environment.apiUrl,
            });
        }),
        AccountingApiModule.forRoot(() => {
            return new ConfigurationAccounting({
                basePath: environment.accountingUrl,
            });
        }),
        fileApiModule.forRoot(() => {
            return new ConfigurationFile({
                basePath: environment.fileStorageUrl,
            });
        }),
        OccupationModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    public constructor(injector: Injector) {
        InjectorService.setInjector(injector);
    }
}
