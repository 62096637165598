import { Component, Input, OnInit } from '@angular/core';
import { AccountDto } from 'src/app/generated-sources/accounting';

@Component({
    selector: 'app-apportionable-label',
    templateUrl: './apportionable-label.component.html',
    styleUrls: ['./apportionable-label.component.scss'],
})
export class ApportionableLabelComponent {
    @Input() public account?: AccountDto = undefined;
}
