<div class="tw-flex tw-justify-between tw-mb-6">
    <app-headline-with-icon
        color="green"
        image="24_technische-aussattung.svg"
        text="Technische Ausstattung"
    ></app-headline-with-icon>

    <div class="tw-flex tw-space-x-2">
        <app-search></app-search>
        <app-scalara-button variant="ghost" svgName="24_add.svg" (click)="openAddTechnicalEquipmentOverlay()">
            {{ 'CONTROLS.BUTTONS.ADD' | translate }}
        </app-scalara-button>
    </div>
</div>

<div class="tw-grid tw-grid-cols-3 tw-gap-6" *ngIf="technicalEquipmentItems.length > 0; else notSpecified">
    <div
        class="tile tw-p-6 tw-flex tw-space-x-3"
        (click)="openTechnicalEquipmentDetailsOverlay(item)"
        *ngFor="let item of technicalEquipmentItems"
    >
        <div>
            <div class="tw-fill-scalaraGreen-01 tw-w-10 tw-h-10">
                <svg-icon [src]="'/assets/icons/40_technical-equipment-' + item.equipmentTypeId + '.svg'"></svg-icon>
            </div>
        </div>
        <div class="tw-mt-3 tw-space-y-1">
            <div class="tw-s-body-14-22-bold">{{ item.technicalEquipmentLabel }}</div>
            <div class="tw-s-body-14-22-regular">
                {{ item.year }}<span *ngIf="item.year && item.preservationStatus">, </span>
                {{ item.preservationStatus }}
            </div>
            <span class="tw-s-body-14-22-regular" *ngFor="let spec of item.specificationArray; let last = last">
                {{ spec }}<span *ngIf="!last">,</span>
            </span>
        </div>
    </div>
</div>

<ng-template #notSpecified>
    <div class="s-body-14-22-italic">
        {{ 'PAGES.OWNERSHIP.DETAIL_VIEW.LABEL_NOT_SPECIFIED' | translate }}
    </div>
</ng-template>
