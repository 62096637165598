/**
 * SCALARA Accounting Module
 * The SCALARA Accounting API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OwnershipForDistributionKeyDto } from './ownershipForDistributionKeyDto';


export interface WssDistributionKeyDto { 
    isInheritedFromEconomicPlan: boolean;
    totalDistribution: number;
    id: string;
    description: string;
    distributionBase: WssDistributionKeyDto.DistributionBaseEnum;
    distributionGroup: WssDistributionKeyDto.DistributionGroupEnum;
    ownerships: Array<OwnershipForDistributionKeyDto>;
}
export namespace WssDistributionKeyDto {
    export type DistributionBaseEnum = 'FRACTION' | 'OWNERSHIPS' | 'PERSONS' | 'SQUARE_METERS' | 'HEATED_SQUARE_METERS' | 'CONSUMPTION';
    export const DistributionBaseEnum = {
        Fraction: 'FRACTION' as DistributionBaseEnum,
        Ownerships: 'OWNERSHIPS' as DistributionBaseEnum,
        Persons: 'PERSONS' as DistributionBaseEnum,
        SquareMeters: 'SQUARE_METERS' as DistributionBaseEnum,
        HeatedSquareMeters: 'HEATED_SQUARE_METERS' as DistributionBaseEnum,
        Consumption: 'CONSUMPTION' as DistributionBaseEnum
    };
    export type DistributionGroupEnum = 'ALL_OWNERSHIPS' | 'NOT_ALL_OWNERSHIPS';
    export const DistributionGroupEnum = {
        AllOwnerships: 'ALL_OWNERSHIPS' as DistributionGroupEnum,
        NotAllOwnerships: 'NOT_ALL_OWNERSHIPS' as DistributionGroupEnum
    };
}


