import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-file-input',
    templateUrl: './file-input.component.html',
    styleUrls: ['./file-input.component.scss'],
})
export class FileInputComponent {
    public readonly fileSizeInMb = environment.maxFileSizeInMB;

    @Input() public acceptFormats: string[] = [];
    @Input() public inputLabel = '';
    @Output() public updateUploadedFile = new EventEmitter<string>();

    public fileChangeEvent(event: any): void {
        this.updateUploadedFile.emit(event);
    }

    public get formatsLabel(): string {
        return this.acceptFormats.join(', ');
    }
}
