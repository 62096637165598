import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class CustomGoogleServicesService {
    public constructor() {
        initGooglePlaces(environment.googleApiKey).then((data) => {
            this.googlePlacesInitialized$.next(true);
        });
    }

    private googlePlacesInitialized$ = new BehaviorSubject(false);

    public getGooglePlacesInitialized$(): Observable<boolean> {
        return this.googlePlacesInitialized$.asObservable();
    }
}
