<div ibmRow class="mt-06 mb-11">
    <div ibmCol>
        <ibm-breadcrumb [items]="breadcrumbs" [noTrailingSlash]="true"> </ibm-breadcrumb>
    </div>
</div>

<div class="mb-2r" *ngIf="bankAccount?.dataOutdated">
    <app-notification type="error">
        <div>
            <span>{{ 'PAGES.BANK_ACCOUNT_DETAILS.EXPIRE_ERROR_NOTE' | translate }}</span>
            <a routerLink="/user-management/accounts" class="s-gray-01 s-link-14-22-regular">{{
                'PAGES.BANK_ACCOUNT_DETAILS.LINK_BANK_CONNECTIONS' | translate
            }}</a>
            <span>{{ 'PAGES.BANK_ACCOUNT_DETAILS.EXPIRE_NOTE_UPDATE' | translate }}</span>
        </div>
    </app-notification>
</div>

<app-bank-connection-expired-notification [bankAccount]="this.bankAccount"></app-bank-connection-expired-notification>

<div class="mb-11" *ngIf="bankAccount">
    <div class="tw-flex tw-justify-between tw-mb-8">
        <div class="tw-flex">
            <div class="tw-bg-scalaraGray-07 tw-mr-4 tw-w-[72px] tw-h-[72px] tw-flex tw-items-center tw-justify-center">
                <img
                    *ngIf="bankAccount.bankLogoUrl"
                    [src]="bankAccount.bankLogoUrl"
                    alt=""
                    class="tw-max-w-full tw-max-h-full tw-p-2"
                />
                <svg-icon
                    *ngIf="!bankAccount.bankLogoUrl"
                    class="tw-fill-scalaraGray-04 tw-h-10 tw-w-10"
                    src="/assets/icons/40_bank.svg"
                ></svg-icon>
            </div>
            <div>
                <div class="info-field__label s-label-12-22-semibold tw-mb-2">
                    {{ 'ACCOUNTING.BANK_ACCOUNT_DETAILS.IBAN' | translate }}
                </div>
                <div class="s-head-28-36-bold">{{ bankAccount.iban | ibanFormatter }}</div>
            </div>
        </div>

        <div>
            <div class="s-label-12-22-semibold mr-05 text-right tw-mb-2">
                {{ 'ACCOUNTING.COMMON.BALANCE' | translate : { date: bankAccount.balanceDate || '' | dateFormat } }}
            </div>

            <div *ngIf="bankAccount" class="s-head-28-36-bold text-right mr-05">
                {{ bankAccount.balance || 0 | eurocent }}
            </div>
        </div>
    </div>

    <div ibmRow class="mb-04">
        <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }" class="info-field__label s-label-12-22-semibold">
            {{ 'ACCOUNTING.BANK_ACCOUNT_DETAILS.BANK_NAME' | translate }}
        </div>
        <div ibmCol class="s-body-14-22-regular">
            {{ bankAccount.bankName }}
        </div>
    </div>

    <div ibmRow class="mb-04">
        <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }" class="info-field__label s-label-12-22-semibold">
            {{ 'ACCOUNTING.BANK_ACCOUNT_DETAILS.BIC' | translate }}
        </div>
        <div ibmCol class="s-body-14-22-regular">
            {{ bankAccount.bic }}
        </div>
    </div>

    <div ibmRow class="mb-04">
        <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }" class="info-field__label s-label-12-22-semibold">
            {{ 'ACCOUNTING.BANK_ACCOUNT_DETAILS.TYPE' | translate }}
        </div>
        <div ibmCol class="s-body-14-22-regular">
            {{ bankAccount.type }}
        </div>
    </div>

    <div ibmRow class="mb-04">
        <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }" class="info-field__label s-label-12-22-semibold">
            {{ 'ACCOUNTING.BANK_ACCOUNT_DETAILS.NAME' | translate }}
        </div>
        <div ibmCol class="s-body-14-22-regular">
            {{ bankAccount.name }}
        </div>
    </div>
    <div ibmRow class="mb-04">
        <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }" class="info-field__label s-label-12-22-semibold">
            {{ 'ACCOUNTING.BANK_ACCOUNT_DETAILS.COUNTERPART_BANKNAME' | translate }}
        </div>
        <div ibmCol class="s-body-14-22-regular">
            {{ bankAccount.holderName }}
        </div>
    </div>

    <div *ngIf="bankAccount.lastSuccessfulUpdate" ibmRow class="mb-04">
        <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }" class="info-field__label s-label-12-22-semibold">
            Datenstand vom
        </div>
        <div ibmCol class="s-body-14-22-regular tw-flex">
            {{ bankAccount.lastSuccessfulUpdate | dateTimeFormat }}
            <div class="formated-icon tw-ml-3" *ngIf="bankAccount.type !== 'ManuallyAddedBankAccount'">
                <svg-icon
                    *ngIf="!bankAccount.dataOutdated; else attentionIcon"
                    class="tw-fill-scalaraGreen-01"
                    src="/assets/icons/24_cloud-connection.svg"
                ></svg-icon>
                <ng-template #attentionIcon>
                    <app-tooltip position="left">
                        <div content class="s-gray-01 tooltip-content p-2r">
                            {{ 'PAGES.BANK_ACCOUNTS.TOOLTIP_DATA_OUTDATED' | translate }}
                        </div>

                        <div hoverable-item>
                            <svg-icon class="tw-fill-scalaraRed-01" src="/assets/icons/24_attention.svg"></svg-icon>
                        </div>
                    </app-tooltip>
                </ng-template>
            </div>
        </div>
    </div>
</div>

<div ibmRow class="mb-09">
    <div ibmCol class="d-flex align-items-center">
        <div
            *ngIf="amountUnbookedTransaction > 0"
            class="icon-container icon-container--40 icon-container--orange mr-04"
        >
            <svg-icon src="/assets/icons/24_attention.svg"></svg-icon>
        </div>
        <div *ngIf="amountUnbookedTransaction === 0" class="icon-container icon-container--40 mr-04">
            <svg-icon src="/assets/icons/24_closed.svg"></svg-icon>
        </div>
        <div class="s-body-14-22-regular">{{ 'ACCOUNTING.OPEN-ITEMS.COUNT_INTRO' | translate }}</div>
        <div class="s-body-14-22-regular ml-04">
            <span class="s-body-14-22-bold">{{ amountUnbookedTransaction }}</span>
            {{ 'ACCOUNTING.BANK_TRANSACTION_DETAILS.UNBOOKED_TRANSACTION' | translate }}
        </div>
        <div class="ml-auto d-flex justify-content-end">
            <app-search [searchInputId]="'bankAccountTransactions'"></app-search>
        </div>
        <app-scalara-button variant="ghost" (click)="openAddTransactionOverlay()" svgName="24_add.svg">
            {{ 'PAGES.BANK_ACCOUNT_DETAILS.LABEL_UPLOAD_BANK_TRANSACTION' | translate }}
        </app-scalara-button>
        <div class="ml-1r mr-1r">
            <div class="d-flex align-items-center">
                <app-accounting-date-picker-selector></app-accounting-date-picker-selector>
            </div>
        </div>
    </div>
</div>

<div ibmRow class="mb-04">
    <div ibmCol>
        <app-table
            (customButton)="handlingEvent($event)"
            [emptyText]="'ACCOUNTING.BANK_TRANSACTION_DETAILS.EMPTY_TABLE'"
            [header]="tableModel.header"
            [data]="tableModel.data"
            [tableSearchId]="'bankAccountTransactions'"
            [isLoading]="isLoading"
        >
        </app-table>
    </div>
</div>

<div class="p-10"></div>
