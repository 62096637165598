/**
 * SCALARA Accounting Module
 * The SCALARA Accounting API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AccountDto } from './accountDto';


export interface GetBookingRuleDto { 
    createdAt: string;
    updatedAt: string;
    activatedAt?: string;
    deactivatedAt?: string;
    lastUsedAt?: string;
    name: string;
    id: string;
    amount?: number;
    keywords?: Array<string>;
    counterpartIban?: string;
    counterpartName?: string;
    isAutogenerated: boolean;
    status: GetBookingRuleDto.StatusEnum;
    matchesCount: number;
    labourAmount?: number;
    vat?: number;
    guidedBookingDefinitionId: number;
    accountToBookIn: AccountDto;
}
export namespace GetBookingRuleDto {
    export type StatusEnum = 'ACTIVATED' | 'DEACTIVATED' | 'REJECTED' | 'PENDING' | 'OUTDATED';
    export const StatusEnum = {
        Activated: 'ACTIVATED' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum,
        Rejected: 'REJECTED' as StatusEnum,
        Pending: 'PENDING' as StatusEnum,
        Outdated: 'OUTDATED' as StatusEnum
    };
}


