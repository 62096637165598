<app-delete-overlay
    title="PAGES.ANNUAL_STATEMENT.DELETE_OVERLAY.SUBHEADLINE"
    headline="PAGES.ANNUAL_STATEMENT.DELETE_OVERLAY.HEADLINE"
    subtitle="PAGES.ANNUAL_STATEMENT.DELETE_OVERLAY.TEXT"
    (closeOverlay)="abort()"
    (submitOverlay)="onSubmit()"
>
    <div class="d-flex align-items-center files mb-7-5r">
        <span class="s-body-14-22-regular s-gray-01"> {{ annualStatementDates }}</span>
    </div>
</app-delete-overlay>
