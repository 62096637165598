import { Component, DoCheck, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, filter, takeUntil, tap } from 'rxjs';
import {
    bankAccountTypeName,
    formatYYYYMMDD_to_DDMMYYYY,
    getBankTransactionState,
    getRestAmount,
} from 'src/app/core/utils/common';
import { BankAccountDto, BankTransactionDto } from 'src/app/generated-sources/accounting';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { AddBookingSelectionOverlayComponent } from '../../../bookings/components/add-booking-selection-overlay/add-booking-selection-overlay.component';
import { DetailsContainerComponent } from '../../../details-container/details-container.component';
import { LedgerCustomService } from '../../../services/ledger-custom.service';

@Component({
    selector: 'app-banking-transaction-detail-card',
    templateUrl: './banking-transaction-detail-card.component.html',
    styleUrls: ['./banking-transaction-detail-card.component.scss'],
})
export class BankingTransactionDetailCardComponent implements DoCheck, OnDestroy, OnInit {
    @Input() public bankTransaction: BankTransactionDto | undefined = undefined;
    @Input() public bankAccount = {} as BankAccountDto;
    @Input() public isSimpleView = false;
    @Output() public overlayIsClosed = new EventEmitter<void>();

    private unsubscribe$ = new Subject<void>();
    public options: DetailsContainerComponent['options'] = {} as DetailsContainerComponent['options'];
    public gridContent = {} as { topContent: string[]; bottomContent: string[] };
    public bookingsAmountSummary = 0;
    public ledgerId = '';

    public ledgerId$ = this.ledgerCustomService.getLedgerId$().pipe(
        filter(Boolean),
        tap((ledgerId) => (this.ledgerId = ledgerId)),
        takeUntil(this.unsubscribe$)
    );

    public constructor(
        private translateService: TranslateService,
        private overlayService: OverlayService,
        private ledgerCustomService: LedgerCustomService
    ) {}

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public ngOnInit(): void {
        this.ledgerId$.subscribe();
    }

    public ngDoCheck(): void {
        if (this.bankTransaction) {
            this.options = {
                type: 'transaction',
                state: getBankTransactionState(this.bankTransaction),
            };
            if (Object.keys(this.bankAccount).length > 0) {
                this.populateContent(this.bankTransaction, this.bankAccount);
            }

            this.bookingsAmountSummary = this.bankTransaction.amountAssignedToBookings ?? 0;
        }
    }

    public getRestAmount(bankTransaction: BankTransactionDto): number {
        return getRestAmount(bankTransaction);
    }

    public openAddBookingOverlay(): void {
        const data = {
            ledgerId: this.ledgerId,
            bankTransactionId: this.bankTransaction?.id,
        };
        const ref = this.overlayService.open(AddBookingSelectionOverlayComponent, {
            data,
        });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.overlayIsClosed.emit());
    }

    public populateContent(bankTransaction: BankTransactionDto, bankAccount: BankAccountDto): void {
        const content = {
            topContentItems: [
                {
                    label: this.translateService.instant('ACCOUNTING.BANK_TRANSACTION_DETAILS.COUNTERPART_NAME'),
                    value: bankTransaction.counterpartName,
                    showInSimpleView: true,
                },
                {
                    label: this.translateService.instant('ACCOUNTING.BANK_TRANSACTION_DETAILS.PURPOSE'),
                    value: bankTransaction.purpose,
                    showInSimpleView: true,
                },
                {
                    label: this.translateService.instant('ACCOUNTING.BANK_TRANSACTION_DETAILS.IBAN'),
                    value: bankTransaction.counterpartIban,
                    showInSimpleView: true,
                },
                {
                    label: this.translateService.instant('ACCOUNTING.BANK_TRANSACTION_DETAILS.COUNTERPART_BIC'),
                    value: bankTransaction.counterpartBic,
                },
                {
                    label: this.translateService.instant('ACCOUNTING.BANK_TRANSACTION_DETAILS.COUNTERPART_BANKNAME'),
                    value: bankTransaction.counterpartBankName,
                },
                {
                    label: this.translateService.instant('ACCOUNTING.BANK_TRANSACTION_DETAILS.BANK_BOOKING_DATE'),
                    value: formatYYYYMMDD_to_DDMMYYYY(bankTransaction.bankBookingDate),
                },
                {
                    label: this.translateService.instant('ACCOUNTING.BANK_TRANSACTION_DETAILS.VALUE_DATE'),
                    value: formatYYYYMMDD_to_DDMMYYYY(bankTransaction.valueDate),
                    showInSimpleView: true,
                },
                {
                    label: this.translateService.instant('ACCOUNTING.BANK_TRANSACTION_DETAILS.TYPE'),
                    value:
                        bankTransaction.type === 'manually-imported-transaction'
                            ? 'Manuell hinzugefügte Transaktion'
                            : bankTransaction.type,
                },
            ],
            bottomContentItems: [
                {
                    label: this.translateService.instant(
                        'ACCOUNTING.BANK_TRANSACTION_DETAILS.COUNTERPART_NAME'
                    ) as string,
                    value: bankAccount.holderName,
                    showInSimpleView: true,
                },
                {
                    label: this.translateService.instant('ACCOUNTING.BANK_TRANSACTION_DETAILS.IBAN'),
                    value: bankAccount.iban,
                    showInSimpleView: true,
                },
                {
                    label: this.translateService.instant('ACCOUNTING.BANK_TRANSACTION_DETAILS.ACCOUNT_TYPE'),
                    value: bankAccountTypeName(bankAccount),
                },
                {
                    label: this.translateService.instant('ACCOUNTING.BANK_TRANSACTION_DETAILS.COUNTERPART_BANKNAME'),
                    value: bankAccount.bankName,
                    showInSimpleView: true,
                },
            ],
        };
        const gridContent = { topContent: [] as string[], bottomContent: [] as string[] };
        for (const topItem of content.topContentItems) {
            if (this.isSimpleView && !topItem.showInSimpleView) {
                continue;
            }
            gridContent.topContent.push(topItem.label);
            gridContent.topContent.push(topItem.value);
        }
        for (const bottomItem of content.bottomContentItems) {
            if (this.isSimpleView && !bottomItem.showInSimpleView) {
                continue;
            }
            gridContent.bottomContent.push(bottomItem.label);
            gridContent.bottomContent.push(bottomItem.value);
        }
        this.gridContent = gridContent;
    }
}
