import { Component } from '@angular/core';
import { AccountingFilterCustomService } from '../../../services/accounting-filter-custom.service';

@Component({
    selector: 'app-bookings-table-filter',
    templateUrl: './bookings-table-filter.component.html',
    styleUrls: ['./bookings-table-filter.component.scss'],
})
export class BookingsTableFilterComponent {
    public bookingsFilter$ = this.accountingFilterCustomService.getBookingsFilter$();

    public constructor(private accountingFilterCustomService: AccountingFilterCustomService) {}

    public updateBookingFilter(event: { id: string; selected: boolean }): void {
        this.accountingFilterCustomService.setBookingsFilter(event.id, event.selected);
    }

    public resetFilter(): void {
        this.accountingFilterCustomService.resetBookingsFilter();
    }
}
