<app-delete-overlay
    title="PAGES.DELETE_USER.TITLE"
    headline="PAGES.DELETE_USER.HEADLINE"
    subtitle="PAGES.DELETE_USER.SUBTITLE"
    (closeOverlay)="abort()"
    (submitOverlay)="submit()"
    submitButtonLabel="PAGES.DELETE_USER.SUBMIT_BUTTON"
    headlineIcon="40_error.svg"
>
    <div class="tw-p-4 tw-bg-scalaraGray-06 tw-flex tw-items-center tw-mb-[120px] tw-gap-28">
        <div class="tw-s-label-12-22-semibold tw-text-scalaraGray-03">
            <div class="tw-mb-2">Name</div>
            <div>Email</div>
        </div>

        <div class="tw-s-body-14-22-regular">
            <div class="tw-mb-2">{{ user?.name }}</div>
            <div>{{ user?.email }}</div>
        </div>
    </div>
</app-delete-overlay>
