import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
    BehaviorSubject,
    Observable,
    Subject,
    combineLatest,
    filter,
    lastValueFrom,
    map,
    of,
    shareReplay,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs';
import {
    EconomicPlanDetailsDto,
    EconomicPlansService,
    OwnershipAdvancementAmountDto,
    SingleEconomicPlanDetailsDto,
} from 'src/app/generated-sources/accounting';
import { Ownership, PropertiesService } from 'src/app/generated-sources/base';
import { BaseDocumentsListItem } from 'src/app/shared/components/base-documents-list/base-documents-list.component';
import { EurocentPipe } from 'src/app/shared/pipes/eurocent.pipe';
import { EconomicPlanCustomService } from '../../../../../services/economic-plan-custom.service';
import { LedgerCustomService } from '../../../../../services/ledger-custom.service';

type EconomicPlanValue = EconomicPlanDetailsDto | SingleEconomicPlanDetailsDto | null;

type AccountsKey = keyof Pick<
    EconomicPlanDetailsDto,
    | 'revenueAccounts'
    | 'apportionableExpenseAccounts'
    | 'expenseAccounts'
    | 'reserveFundAccounts'
    | 'individualReserveFundAccounts'
>;

@Component({
    selector: 'app-weg-economic-plan-individual-plan-tab',
    templateUrl: './weg-economic-plan-individual-plan-tab.component.html',
    styleUrls: ['./weg-economic-plan-individual-plan-tab.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WegEconomicPlanIndividualPlanTabComponent implements OnInit, OnChanges, OnDestroy {
    // public selectedOwnershipId$ = new BehaviorSubject('');
    public unsubscribe$ = new Subject<void>();
    public isLoading = true;
    public ownershipAdvancementsAmounts: OwnershipAdvancementAmountDto[] = [];
    public economicPlan: SingleEconomicPlanDetailsDto | null = null;
    public maturity?: string = undefined;
    public pdfList: BaseDocumentsListItem[] = [];
    public selectedOwnership$ = new BehaviorSubject<Ownership | undefined>(undefined);

    @Input() public set triggerRefresh(val: unknown) {
        this.refresh$.next(null);
    }
    @Input() public economicPlanType = '';
    @Input() public selectedOwnership: Ownership | undefined = undefined;
    @Output() public handleIndividualEconomicPlanDetailPage = new EventEmitter();

    public refresh$ = new BehaviorSubject(null);

    public accountGroups: AccountsKey[] = [
        'revenueAccounts',
        'apportionableExpenseAccounts',
        'expenseAccounts',
        'reserveFundAccounts',
        'individualReserveFundAccounts',
    ];

    public constructor(
        private economicPlansService: EconomicPlansService,
        private route: ActivatedRoute,
        private translateService: TranslateService,
        private changeDetectorRef: ChangeDetectorRef,
        private economicPlanCustomService: EconomicPlanCustomService,
        private ledgerCustomService: LedgerCustomService,
        private propertiesService: PropertiesService
    ) {}

    public translations = {
        ownershipLabelEntity: this.translateService.instant('ENTITIES.OWNERSHIP.LABEL_ENTITY') + ' ',
        reserveLabel: this.translateService.instant('PAGES.ECONOMIC_PLAN.DETAILS_TAB.RESERVE_LABEL'),
    };

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['selectedOwnership']) {
            this.selectedOwnership$.next(changes['selectedOwnership'].currentValue);
        }
    }

    public ngOnInit(): void {
        this.economicPlan$.pipe(takeUntil(this.unsubscribe$)).subscribe((economicPlan) => {
            this.economicPlan = this.formatConsumptionDistributionKeys(economicPlan);

            const maturityString: string = this.economicPlan?.maturity || '';
            if (maturityString === 'FIRST_DAY_OF_THE_MONTH') {
                this.maturity = '1.';
            } else if (maturityString === 'MID_OF_THE_MONTH') {
                this.maturity = '15.';
            } else {
                this.maturity = `${this.economicPlan?.maturity}.`;
            }

            this.isLoading = false;
            this.changeDetectorRef.detectChanges();
        });

        this.pdfList$.pipe(takeUntil(this.unsubscribe$)).subscribe((x) => {
            this.pdfList = x;
            this.changeDetectorRef.detectChanges();
        });
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public ledgerId$ = this.route.parent!.params.pipe(
        switchMap((params) => {
            const ledgerId = params['id'];
            return of(ledgerId);
        }),
        takeUntil(this.unsubscribe$)
    );

    public economicPlanId$ = this.route.params.pipe(
        switchMap((params) => {
            const economicPlanId = params['economicPlanId'];
            return of(economicPlanId);
        }),
        takeUntil(this.unsubscribe$)
    );

    public economicPlan$: Observable<EconomicPlanValue> = combineLatest([
        this.ledgerId$,
        this.economicPlanId$,
        this.selectedOwnership$,
        this.refresh$,
    ]).pipe(
        tap(() => (this.isLoading = true)),
        switchMap(([ledgerId, economicPlanId, selectedOwnership]) => {
            //  do not do initial api call with empty ownershipId for single economic plan
            if (!selectedOwnership?.id) {
                return of(null);
            }
            return this.economicPlansService.getSingleEconomicPlanDetails(
                ledgerId,
                economicPlanId,
                selectedOwnership.id
            );
        }),
        //  remove irrelevant accounts
        map((economicPlan: EconomicPlanValue) => {
            if (economicPlan === null) {
                return null;
            }
            const economicPlanWithoutIrrelevantAccounts: EconomicPlanValue = {
                ...economicPlan,
            } as const;
            const keys = [
                'apportionableExpenseAccounts',
                'expenseAccounts',
                'individualReserveFundAccounts',
                'reserveFundAccounts',
                'revenueAccounts',
            ] as const;

            for (const key of keys) {
                economicPlanWithoutIrrelevantAccounts[key].forEach(
                    (item) =>
                        (item.accountName = item.accountName.replace(
                            'Geplante Geldmittel',
                            this.translations.reserveLabel
                        ))
                );
                economicPlanWithoutIrrelevantAccounts[key] = economicPlanWithoutIrrelevantAccounts[key].filter(
                    //  item.isRelevant is not set (===undefined) for single WP Accounts, do not filter them out
                    (item) => item.isRelevant === undefined || item.isRelevant === true
                );
            }
            return economicPlanWithoutIrrelevantAccounts;
        }),
        takeUntil(this.unsubscribe$),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    public economicPlans$ = this.economicPlanCustomService.getEconomicPlans$();

    public ledger$ = this.ledgerCustomService.getLedger$();

    public property$ = this.ledger$.pipe(
        filter(Boolean),
        switchMap((x) => {
            return this.propertiesService.findOne(x.propertyId);
        })
    );

    public pdfList$ = combineLatest([
        this.ledgerId$,
        this.economicPlanId$,
        this.economicPlans$,
        this.selectedOwnership$,
        this.property$,
    ]).pipe(
        map(([ledgerId, economicPlanId, economicPlans, selectedOwnership, property]) => {
            if (!selectedOwnership?.id) {
                return [];
            }
            const economicPlan = economicPlans.find((x) => x.id === economicPlanId);

            const startMonth = economicPlan?.startDate.substring(5, 7) ?? '';
            const endMonth = economicPlan?.endDate.substring(5, 7) ?? '';
            const year = economicPlan?.startDate.substring(0, 4) ?? '';

            const datesAreValid = startMonth && endMonth && year;

            const fileNamePrefix = datesAreValid ? `${startMonth}_${year}-${endMonth}_${year}_` : '';
            const fileNamePostFix = this.selectedOwnership ? `_${this.selectedOwnership.name}` : '';
            const fileNameBase = `Wirtschaftsplan_${property.propertyType}_${property.address.streetName}_${property.address.streetNumber}`;

            let fileName = fileNamePrefix + fileNameBase + fileNamePostFix + '.pdf';
            fileName = fileName.split(' ').join('_');

            const pdf$ = this.economicPlansService.getDownloadLinkForOwnershipEconomicPlanPDF(
                ledgerId,
                economicPlanId,
                selectedOwnership?.id
            );

            const getLink = (): Promise<{ url: string }> => {
                return lastValueFrom(pdf$);
            };

            const item: BaseDocumentsListItem = { fileName, getLink };
            return [item];
        }),
        takeUntil(this.unsubscribe$)
    );

    public getOverviewRows(economicPlan: SingleEconomicPlanDetailsDto): [string, string][] {
        const euroCent = new EurocentPipe();
        const format = (val: number): string => {
            return euroCent.transform(val) + ' €';
        };
        if (economicPlan === null) {
            return [];
        }
        let costsLabel = format(economicPlan.costsAllocation);
        if (economicPlan.differingAdvancement !== undefined && economicPlan.differingAdvancement !== null) {
            const costs = economicPlan.differingCostsAllocation;
            if (isNaN(costs)) {
                costsLabel = '0,00 €';
            } else {
                costsLabel = format(costs);
            }
        }
        const revenueLabel = format(economicPlan.reserveFundAccountsTotalAmount);
        const returnValues: [string, string][] = [
            ['Kostentragung', costsLabel],
            ['Erhaltungsrücklage', revenueLabel],
        ];
        economicPlan.individualReserveFundAccounts.forEach((account) => {
            returnValues.push([account.accountName, format(account.ownershipDistributionAmount ?? 0)]);
        });
        return returnValues;
    }

    public formatConsumptionDistributionKeys(economicPlan: any): any {
        const single = economicPlan;
        if (economicPlan) {
            for (let i = 0; i < this.accountGroups.length; i++) {
                for (let j = 0; j < economicPlan[this.accountGroups[i]].length; j++) {
                    if (economicPlan[this.accountGroups[i]][j].distributionBase === 'CONSUMPTION') {
                        if (single) {
                            single[this.accountGroups[i]][j].totalDistribution =
                                single[this.accountGroups[i]][j].totalDistribution / 100;

                            single[this.accountGroups[i]][j].ownershipDistributionShare =
                                single[this.accountGroups[i]][j].ownershipDistributionShare / 100;
                        }
                    }
                }
            }
        }
        return single;
    }

    public rateStringToNumber(rate: EconomicPlanDetailsDto['rateInterval']): number {
        switch (rate) {
            case 'ANNUAL':
                return 1;
            case 'MONTHLY':
                return 12;
            case 'QUARTERLY':
                return 4;
            case 'SEMI_ANNUAL':
                return 2;
            default:
                return 1;
        }
    }

    public backPage(): void {
        this.handleIndividualEconomicPlanDetailPage.emit();
    }
}
