import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, map, Observable, shareReplay, switchMap } from 'rxjs';
import { ExtendedSubscriptionDto } from 'src/app/core/guards/subscriptions.guard';
import { ToastService } from 'src/app/core/services/toast.service';
import { OperationsService, SubscriptionsService } from 'src/app/generated-sources/base';

@Injectable({
    providedIn: 'root',
})
export class CustomSubscriptionsService {
    public constructor(
        private subscriptionsService: SubscriptionsService,
        private toastService: ToastService,
        private operationsService: OperationsService
    ) {}

    private triggerSubscriptions$ = new BehaviorSubject<null>(null);

    private subscriptions$ = this.triggerSubscriptions$.pipe(
        switchMap(() => this.subscriptionsService.getSubscriptions()),
        map((subs) => subs.map((i) => ({ ...i, tsType: 'subscriptionDto' })) as ExtendedSubscriptionDto[]),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    private currentSubscription$ = this.subscriptions$.pipe(
        map((subscriptions) => {
            if (subscriptions.length > 0) {
                return subscriptions[0];
            }
            return null;
        }),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    private triggerAccountMetrics$ = new BehaviorSubject<void>(undefined);

    private accountMetrics$ = this.triggerAccountMetrics$.pipe(
        switchMap(() => this.operationsService.getAccountMetrics()),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    private possibleOwnershipsToCreate$ = combineLatest([this.currentSubscription$, this.accountMetrics$]).pipe(
        map(([currentSubscription, accountMetrics]) => {
            if (currentSubscription === null) {
                return 0;
            }
            return currentSubscription.maxOwnerships - accountMetrics.numberOfOwnerships;
        })
    );

    public getSubscriptions$(): Observable<ExtendedSubscriptionDto[]> {
        return this.subscriptions$;
    }
    public getCurrentSubscription$(): typeof this.currentSubscription$ {
        return this.currentSubscription$;
    }
    public triggerSubscriptionsRefresh(): void {
        this.triggerSubscriptions$.next(null);
    }
    public triggerAccountMetricsRefresh(): void {
        this.triggerAccountMetrics$.next(undefined);
    }
    public getAccountMetrics$(): typeof this.accountMetrics$ {
        return this.accountMetrics$;
    }
    public getPossibleOwnershipsToCreate$(): typeof this.possibleOwnershipsToCreate$ {
        return this.possibleOwnershipsToCreate$;
    }
}
