<div class="overlay-container px-2r">
    <ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

    <div class="s-head-28-36-bold mb-4r">{{ 'PAGES.PROPERTY.ADD_PROPERTY.LABEL_HEADLINE' | translate }}</div>

    <div class="s-body-14-22-regular mb-1-5r">
        {{ 'PAGES.PROPERTY.ADD_PROPERTY.LABEL_SELECT_MANAGEMENT_TYPE' | translate }}
    </div>

    <div class="tile expandable-tile-selector mb-1-5r" (click)="goToSelectedTypeProperty(typeEnum.Weg)">
        <div class="d-flex">
            <div class="tile__header">
                <div class="d-flex headline-wrapper">
                    <div class="mr-06 headline-wrapper__icon">
                        <div class="icon-container icon-container--72 icon-container--green">
                            <svg-icon src="/assets/icons/40_buildings-WEG.svg"></svg-icon>
                        </div>
                    </div>
                    <div class="headline-wrapper__title">
                        <div class="s-head-18-26-bold mb-0-5r">
                            {{ 'PAGES.PROPERTY.ADD_PROPERTY.LABEL_WEG' | translate }}
                        </div>
                        <div class="s-head-14-22-regular">
                            {{ 'PAGES.PROPERTY.ADD_PROPERTY.LABEL_WEG_SUBTITLE' | translate }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="tile__icon rotate-270">
                <svg-icon src="/assets/icons/24_arrow-down.svg"></svg-icon>
            </div>
        </div>
    </div>

    <div class="tile expandable-tile-selector mb-1-5r" (click)="goToSelectedTypeProperty(typeEnum.Mv)">
        <div class="d-flex">
            <div class="tile__header">
                <div class="d-flex headline-wrapper">
                    <div class="mr-06 headline-wrapper__icon">
                        <div class="icon-container icon-container--72 icon-container--purple">
                            <svg-icon src="/assets/icons/40_building-MV.svg"></svg-icon>
                        </div>
                    </div>
                    <div class="headline-wrapper__title">
                        <div class="s-head-18-26-bold">{{ 'PAGES.PROPERTY.ADD_PROPERTY.LABEL_MV' | translate }}</div>
                    </div>
                </div>
            </div>
            <div class="tile__icon rotate-270">
                <svg-icon src="/assets/icons/24_arrow-down.svg"></svg-icon>
            </div>
        </div>
    </div>

    <div class="tile expandable-tile-selector" (click)="goToSelectedTypeProperty(typeEnum.Sev)">
        <div class="d-flex">
            <div class="tile__header">
                <div class="d-flex headline-wrapper">
                    <div class="mr-06 headline-wrapper__icon">
                        <div class="icon-container icon-container--72 icon-container--orange">
                            <svg-icon src="/assets/icons/40_door.svg"></svg-icon>
                        </div>
                    </div>
                    <div class="headline-wrapper__title">
                        <div class="s-head-18-26-bold mb-0-5r">
                            {{ 'PAGES.PROPERTY.ADD_PROPERTY.LABEL_SEV' | translate }}
                        </div>
                        <div class="s-head-14-22-regular">
                            {{ 'PAGES.PROPERTY.ADD_PROPERTY.LABEL_SEV_SUBTITLE' | translate }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="tile__icon rotate-270">
                <svg-icon src="/assets/icons/24_arrow-down.svg"></svg-icon>
            </div>
        </div>
    </div>
</div>
