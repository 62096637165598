<footer class="main-container">
    <div class="copyright">© {{ year }} SCALARA GmbH</div>

    <a href="https://www.scalara.de/terms">
        {{ 'FOOTER.PRIVACY_POLICE' | translate }}
    </a>
    <a href="https://www.scalara.de/impressum">
        {{ 'FOOTER.LEGAL_NOTICE' | translate }}
    </a>
</footer>
