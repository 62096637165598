<div class="tile tile--shadow tile--not-clickable tile--static tw-p-8">
    <ng-container *ngIf="!disableCheckbox" [ngTemplateOutlet]="bookingRuleCheckbox"></ng-container>

    <div *ngIf="disableCheckbox">
        <app-tooltip position="top">
            <div content class="tw-w-[478px] tw-px-12 tw-py-8">
                <span
                    >Sie haben zwei Buchhaltungskonten ausgewählt. Eine Buchungsregel kann nur für ein
                    Ziel-Buchhaltungskonto erstellt werden.
                </span>
            </div>
            <div hoverable-item>
                <ng-container [ngTemplateOutlet]="bookingRuleCheckbox"></ng-container>
            </div>
        </app-tooltip>
    </div>

    <div class="tw-mt-11" *ngIf="createBookingRuleChecked">
        <div class="tw-s-body-14-22-bold tw-mb-4">Zusammenfassung</div>

        <div class="tw-space-y-3">
            <div class="tw-grid tw-grid-cols-4">
                <div class="tw-s-label-12-22-semibold tw-text-scalaraGray-03">
                    {{ 'ENTITIES.COMMON.LABEL_AMOUNT' | translate }}
                </div>
                <div class="tw-s-body-14-22-regular">{{ bookingRulesSuggestion?.amount || 0 | eurocent }} €</div>
            </div>

            <div class="tw-grid tw-grid-cols-4" *ngIf="bookingRulesSuggestion?.amountLabour">
                <div class="tw-s-label-12-22-semibold tw-text-scalaraGray-03">
                    {{ 'ENTITIES.COMMON.LABEL_LABOUR_AMOUNT' | translate }}
                </div>
                <div class="tw-s-body-14-22-regular">{{ bookingRulesSuggestion?.amountLabour || 0 | eurocent }}€</div>
            </div>

            <div class="tw-grid tw-grid-cols-4" *ngIf="bookingRulesSuggestion?.vat">
                <div class="tw-s-label-12-22-semibold tw-text-scalaraGray-03">
                    {{ 'ENTITIES.COMMON.LABEL_TAX_SHARE' | translate }}
                </div>
                <div class="tw-s-body-14-22-regular">{{ bookingRulesSuggestion?.vat }}</div>
            </div>

            <div class="tw-grid tw-grid-cols-4">
                <div class="tw-s-label-12-22-semibold tw-text-scalaraGray-03">
                    {{ 'ENTITIES.BANK_ACCOUNT.IBAN' | translate }}
                </div>
                <div class="tw-s-body-14-22-regular">{{ bookingRulesSuggestion?.iban || '' }}</div>
            </div>

            <div class="tw-grid tw-grid-cols-4">
                <div class="tw-s-label-12-22-semibold tw-text-scalaraGray-03">
                    {{ 'PAGES.BANK_TRANSACTIONS.BOOKING_RULES.LABEL_DESTINATION_BOOKING_ACCOUNT' | translate }}
                </div>
                <div class="tw-s-body-14-22-regular">{{ bookingRulesSuggestion?.bookingAccountDescription || '' }}</div>
            </div>
        </div>

        <div class="tw-mt-8">
            <div class="tw-s-body-14-22-bold tw-mb-3">Geben Sie die Bezeichnung der Regel an</div>

            <ibm-label invalidText="{{ 'ERROR.REQUIRED' | translate }}">
                Bezeichnung der Buchungsregel*
                <input [(ngModel)]="bookingRuleDescription" (change)="checkBoxChange()" ibmText name="name" id="name" />
            </ibm-label>

            <div class="tw-flex tw-space-x-2 tw-mt-6">
                <svg-icon
                    class="tw-h-6 tw-w-6 tw-fill-scalaraBlue-01"
                    src="/assets/icons/24_information.svg"
                ></svg-icon>
                <div class="tw-s-body-14-22-regular">
                    Die Regel wird direkt aktiviert. Sobald sich der Umsatz wiederholt, wird er automatisch für Sie
                    verbucht.
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #bookingRuleCheckbox>
    <ibm-checkbox
        [disabled]="disableCheckbox"
        [checked]="false"
        [(ngModel)]="createBookingRuleChecked"
        (change)="checkBoxChange($event)"
        ><span class="tw-ml-1">Aus dieser Buchung eine Buchungsregel erstellen</span>
    </ibm-checkbox>
</ng-template>
