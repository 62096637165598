<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<div class="tw-p-8">
    <div ibmRow class="mb-10">
        <div ibmCol [columnNumbers]="{ md: 16, lg: 16 }" class="s-head-28-36-bold mb-04">
            <span>{{ 'PAGES.STATISTICS.LABEL_HEADLINE' | translate }}</span>
        </div>
    </div>
    <div ibmRow class="mb-10 mt-06">
        <div ibmCol></div>
        <div ibmCol [columnNumbers]="{ md: 16, lg: 16 }">
            <div class="px-2-5r" class="tabs-wrapper">
                <ibm-tabs [followFocus]="true" [cacheActive]="true">
                    <ibm-tab heading="Base-Service">
                        <div class="pt-2r">
                            <app-operation-base-statistics
                                [platformStatistics]="platformStatistics!"
                                [customerSubscriptions]="customerSubscriptions!"
                            ></app-operation-base-statistics>
                        </div>
                    </ibm-tab>
                    <ibm-tab heading="Accounting">
                        <div class="pt-2r">
                            <app-operation-accounting-statistics
                                [platformStatistics]="platformStatistics!"
                                [customerSubscriptions]="customerSubscriptions!"
                            ></app-operation-accounting-statistics>
                        </div>
                    </ibm-tab>
                </ibm-tabs>
            </div>
        </div>
        <div ibmCol></div>
    </div>
</div>
