<button
    class="search search__open"
    [hidden]="closedByDefault ? isSearchOpen || searchValue : true"
    (click)="changeSearchDisplay()"
    #openSearchButton
>
    <svg-icon src="/assets/icons/24_search.svg"></svg-icon>
</button>
<div
    [class]="'search search__input-wrapper ' + (isFullwidth ? '' : 'tw-max-w-[400px]')"
    [hidden]="closedByDefault ? !isSearchOpen && !searchValue : false"
    #searchWrapper
>
    <div class="d-flex align-items-center">
        <svg-icon src="/assets/icons/24_search.svg"></svg-icon>
        <input
            [class]="isFullwidth ? 'tw-w-full' : 'tw-w-[360px]'"
            id="{{ searchInputId }}"
            (keyup)="emitInputValue($event)"
            [value]="this.searchValue"
        />
        <svg-icon class="search search__clear" (click)="clearInput()" src="/assets/icons/24_close.svg"></svg-icon>
    </div>
</div>
