import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, finalize, takeUntil } from 'rxjs';
import { PersonType } from 'src/app/core/models/person-type.enum';
import { ToastService } from 'src/app/core/services/toast.service';
import { formatDateYYYYMMDDWithoutHours, getLocalISOTime } from 'src/app/core/utils/common';
import { OwnershipsService, Person, PropertiesService } from 'src/app/generated-sources/base';
import { DateRangePickerComponent } from 'src/app/shared/components/date-range-picker/date-range-picker.component';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';
import { PersonInfosFormComponent } from 'src/app/shared/person-infos-form/person-infos-form.component';
import { TooltipKey } from '../../account-settings/services/custom-tooltip.service';
import { ExistingPersonsComboBoxComponent } from '../existing-persons-combo-box/existing-persons-combo-box.component';
import { DateIntervalDto } from '../interfaces';

@Component({
    selector: 'app-add-owner-overlay',
    templateUrl: './add-owner-overlay.component.html',
    styleUrls: ['./add-owner-overlay.component.scss'],
})
export class AddOwnerOverlayComponent extends OverlayChildComponent implements OnInit, OnDestroy, AfterViewInit {
    public durationForm: UntypedFormGroup = new UntypedFormGroup({});
    public createUserForm: UntypedFormGroup = new UntypedFormGroup({});
    private unsubscribe$ = new Subject<void>();
    public isExistingUserSelected = false;
    public isLoading = false;
    public isPersonsListLoading = false;
    public callReason = 'Eigentum';
    public personTypeDeclared = PersonType;
    private propertyID = '';
    private ownershipID = '';
    public selectedPerson = {} as Person;
    public isMv = false;
    public isContactPerson = true;

    @ViewChild(PersonInfosFormComponent)
    public personInfosFormComponent?: PersonInfosFormComponent;
    @ViewChild(DateRangePickerComponent)
    public dateRangePickerChildComponent?: DateRangePickerComponent;
    @ViewChild(ExistingPersonsComboBoxComponent)
    public existingPersonsComboBox?: ExistingPersonsComboBoxComponent;

    public constructor(
        private ownershipsService: OwnershipsService,
        private toast: ToastService,
        private router: Router,
        private propertiesService: PropertiesService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.ownershipID = this.config?.data?.extraData.ownershipId;
        this.propertyID = this.config?.data?.extraData.propertyId;
        this.isMv = this.config?.data?.isMv;
    }

    public ngAfterViewInit(): void {
        if (!this.personInfosFormComponent || !this.dateRangePickerChildComponent || !this.existingPersonsComboBox) {
            console.warn('come of mandaroty children are not initialised');
            return;
        }

        this.createUserForm.addControl('personForm', this.personInfosFormComponent.personForm);
        this.personInfosFormComponent.personForm.setParent(this.createUserForm);

        this.durationForm.addControl('dateRangeForm', this.dateRangePickerChildComponent.dateRangeForm);
        this.dateRangePickerChildComponent.dateRangeForm.setParent(this.durationForm);
    }

    public isFormsValid(): boolean {
        if (!this.durationForm.valid) {
            return false;
        }
        if (!this.isExistingUserSelected && this.createUserForm.valid) {
            return true;
        }
        if (this.isExistingUserSelected && this.isValidPerson()) {
            return true;
        }
        return false;
    }

    public isExistingPersonsLoading($event: any): void {
        if ($event === true) {
            this.isPersonsListLoading = true;
        } else {
            this.isPersonsListLoading = false;
            this.isLoading = false;
        }
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }
    public onSubmit(): void {
        this.isLoading = true;
        if (!this.isFormsValid()) {
            this.isLoading = false;
            return;
        }

        this.createRelation();
    }

    private createRelation(): void {
        const ownershipOwner = {
            ...this.createRealtionWithTimeConstraintObject(),
            person: this.isExistingUserSelected ? undefined : this.createPersonDto(),
            personId: this.isExistingUserSelected ? this.selectedPerson.id : undefined,
            isContactPerson: this.isContactPerson,
        };

        if (this.isMv) {
            this.propertiesService
                .createMVOwner(this.propertyID, ownershipOwner)
                .pipe(
                    takeUntil(this.unsubscribe$),
                    finalize(() => (this.isLoading = false))
                )
                .subscribe({
                    next: () => {
                        this.router.navigate(['/properties', this.propertyID]);
                        this.toast.showSuccess('Eigentümer erfolgreich Angelegt');
                        this.saveEmitter$.next();
                    },
                    error: (error) => {
                        if (error) {
                            this.toast.showError(error.error['message']);
                        }
                    },
                });
        } else {
            this.ownershipsService
                .createNewOwnershipOwner(this.propertyID, this.ownershipID, ownershipOwner)
                .pipe(
                    takeUntil(this.unsubscribe$),
                    finalize(() => (this.isLoading = false))
                )
                .subscribe({
                    next: () => {
                        this.router.navigate(['/properties', this.propertyID, 'ownerships', this.ownershipID]);
                        this.toast.showSuccess('Eigentümer erfolgreich Angelegt');
                        this.saveEmitter$.next();
                    },
                    error: (error) => {
                        if (error) {
                            this.toast.showError(error.error['message']);
                        }
                    },
                });
        }
    }

    private createRealtionWithTimeConstraintObject(): DateIntervalDto {
        const startDate = this.durationForm.get('dateRangeForm')?.value['startDate'][0];
        const endDate = this.durationForm.get('dateRangeForm')?.value['endDate'][0];
        const datesObj: DateIntervalDto = {
            from: getLocalISOTime(startDate),
        };
        if (endDate) {
            datesObj.to = getLocalISOTime(endDate);
        }
        return datesObj;
    }

    private createPersonDto(): Person {
        const userForm = this.createUserForm.get('personForm')?.value;
        userForm.phoneNumbers =
            userForm.phoneNumbers && userForm.phoneNumbers.length > 0 && userForm.phoneNumbers[0]?.phoneNumber
                ? userForm.phoneNumbers.map((item: any) => {
                      return {
                          phoneNumber: item.phoneNumber,
                          type: item.phoneNumberType?.value ?? item.phoneNumberType,
                      };
                  })
                : [];
        userForm.birthday =
            userForm.type === 'NAT_PERSON' && userForm.birthday && userForm.birthday.length > 0 && userForm.birthday[0]
                ? formatDateYYYYMMDDWithoutHours(userForm.birthday[0])
                : undefined;
        return userForm;
    }

    public switchExistingUserSelection(isExistingSelected: boolean): void {
        if (isExistingSelected) {
            this.isExistingUserSelected = true;
            if (this.isPersonsListLoading) {
                this.isLoading = true;
            }
        } else {
            this.isExistingUserSelected = false;
        }
    }

    public isValidPerson(): boolean {
        return Boolean(this.selectedPerson.id);
    }

    public onSelectPerson($event: Person): void {
        this.selectedPerson = $event || {};
    }

    public TooltipKey = TooltipKey;
}
