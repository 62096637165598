import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ListItem } from 'carbon-components-angular';
import { Subject, of, switchMap, tap } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { calculateTaxAmount, formControl, formControlHasError, sortAccountList } from 'src/app/core/utils/common';
import { LedgerCustomService } from 'src/app/features/accounting/components/services/ledger-custom.service';
import { RadioAdditionalParameter } from 'src/app/features/accounting/interfaces';
import {
    AccountDto,
    AdditionalParameterDefinitionDto,
    BookingRulesService,
    CreateBookingRuleDto,
    GetBookingRuleDto,
    GuidedBookingTypeShortDto,
    GuidedBookingsService,
    LedgerDto,
} from 'src/app/generated-sources/accounting';
import { Property } from 'src/app/generated-sources/base';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';

@Component({
    selector: 'app-add-edit-banking-transactions-booking-rules-overlay',
    templateUrl: './add-edit-banking-transactions-booking-rules-overlay.component.html',
    styleUrls: ['./add-edit-banking-transactions-booking-rules-overlay.component.scss'],
})
export class AddEditBankingTransactionsBookingRulesOverlayComponent
    extends OverlayChildComponent
    implements OnInit, OnDestroy
{
    public isLoading = false;
    public ledger?: LedgerDto;
    public guidedBookingTypeShort: GuidedBookingTypeShortDto[] = [];
    public selectedType?: GuidedBookingTypeShortDto;
    public bookingAccountList: ListItem[] = [];
    public additionalParameterDefinition?: AdditionalParameterDefinitionDto[];
    public vat?: RadioAdditionalParameter;
    public labourAmount?: AdditionalParameterDefinitionDto;
    public bookingRuleToEdit?: GetBookingRuleDto;
    public showNotificationError = false;

    public form: UntypedFormGroup = this.formBuilder.group({
        vatCheckbox: [false],
        vat: [],
        labourCheckbox: [false],
        labourAmount: [],
        amount: [null],
        counterpartIban: [],
        counterpartName: [],
        accountToBookInId: [null, Validators.required],
        name: [null, Validators.required],
        keywords: [],
    });

    private unsubscribe$ = new Subject<void>();
    public selectedTypeId$ = new Subject<number>();
    public getGuidedBookingType$ = this.selectedTypeId$.pipe(
        switchMap((selectedTypeId) => {
            if (!this.ledger?.id) {
                return of(null);
            }
            return this.guidedBookingsService.getGuidedBookingType(this.ledger.id, selectedTypeId);
        }),
        tap((guidedBookingType) => {
            const bookingAccount = guidedBookingType?.additionalParameters.find(
                (param) => param.name === 'bookingAccount'
            );

            this.bookingAccountList =
                bookingAccount?.valueOptions
                    ?.map((item) => {
                        const account = item as AccountDto;
                        return {
                            content: `${account.name}${account.description ? ', ' + account.description : ''}`,
                            selected: this.bookingRuleToEdit?.accountToBookIn.id === account.id,
                            value: account.id,
                        };
                    })
                    .sort(sortAccountList) ?? [];

            this.additionalParameterDefinition = guidedBookingType?.additionalParameters;

            this.vat =
                guidedBookingType?.id !== 1005
                    ? (this.getAdditionalParameter('vat') as RadioAdditionalParameter)
                    : undefined;
            if (this.vat) {
                this.form.patchValue({ vat: this.vat.valueOptions[1].id });
            }

            this.labourAmount = this.getAdditionalParameter('labourAmount');
        })
    );

    public constructor(
        private guidedBookingsService: GuidedBookingsService,
        private ledgerCustomService: LedgerCustomService,
        private formBuilder: UntypedFormBuilder,
        private bookingRulesService: BookingRulesService,
        private toastService: ToastService,
        private router: Router
    ) {
        super();
    }

    public ngOnInit(): void {
        this.isLoading = true;
        if (this.config?.data.bookingRule) {
            this.bookingRuleToEdit = this.config?.data.bookingRule;

            this.populateFormWithBookingRuleToEdit();
        }

        this.ledgerCustomService
            .getLedger$()
            .pipe(
                switchMap((ledger) => {
                    if (!ledger) {
                        return of([]);
                    }

                    this.ledger = ledger;

                    return this.guidedBookingsService.getGuidedBookingTypes(ledger.id);
                }),
                tap((guidedBookingTypeShort) => {
                    if (this.ledger?.type === Property.PropertyTypeEnum.Weg) {
                        this.guidedBookingTypeShort = guidedBookingTypeShort.filter(
                            (item) => item.id === 1 || item.id === 2
                        );
                    } else {
                        this.guidedBookingTypeShort = guidedBookingTypeShort.filter(
                            (item) => item.id === 1004 || item.id === 1005
                        );
                    }

                    if (this.bookingRuleToEdit) {
                        const selectedType = this.guidedBookingTypeShort.find(
                            (item) => item.id === this.bookingRuleToEdit?.guidedBookingDefinitionId
                        );

                        if (selectedType) {
                            this.selectType(selectedType);
                        }
                    }
                })
            )
            .subscribe({
                next: () => {
                    this.isLoading = false;
                },
                error: (error) => {
                    this.isLoading = false;

                    if (error) {
                        this.toastService.showError(error.error['message']);
                    }
                },
            });

        this.getGuidedBookingType$.subscribe();
    }

    public populateFormWithBookingRuleToEdit(): void {
        this.form.patchValue({
            vatCheckbox: !!this.bookingRuleToEdit?.vat,
            vat: this.bookingRuleToEdit?.vat
                ? this.vat?.valueOptions.find((item) => item.amount === this.bookingRuleToEdit?.vat)
                : undefined,
            labourCheckbox: !!this.bookingRuleToEdit?.labourAmount,
            labourAmount: this.bookingRuleToEdit?.labourAmount ?? undefined,
            amount: this.bookingRuleToEdit?.amount ?? undefined,
            counterpartIban: this.bookingRuleToEdit?.counterpartIban ?? undefined,
            counterpartName: this.bookingRuleToEdit?.counterpartName ?? undefined,
            accountToBookInId: this.bookingRuleToEdit?.accountToBookIn.id ?? undefined,
            name: this.bookingRuleToEdit?.name ?? undefined,
            keywords: this.bookingRuleToEdit?.keywords ?? [],
        });
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public submit(): void {
        if (!this.selectedType || !this.ledger) {
            return;
        }

        const vat = this.vat?.valueOptions.find((item) => item.id === this.form.get('vat')?.value);

        const createBookingRuleDto: CreateBookingRuleDto = {
            amount: this.form.get('amount')?.value,
            keywords: this.form.get('keywords')?.value,
            counterpartIban: this.form.get('counterpartIban')?.value,
            counterpartName: this.form.get('counterpartName')?.value,
            labourAmount: this.form.get('labourAmount')?.value ?? undefined,
            vat: vat?.amount ?? undefined,
            guidedBookingDefinitionId: this.selectedType.id,
            accountToBookInId: this.form.get('accountToBookInId')?.value?.value,
            name: this.form.get('name')?.value,
        };

        const { counterpartIban, counterpartName, amount, keywords } = createBookingRuleDto;

        if (!counterpartIban && !counterpartName && !amount && (keywords?.length === 0 || !keywords)) {
            this.showNotificationError = true;
            return;
        }
        this.isLoading = true;

        if (this.bookingRuleToEdit) {
            this.bookingRulesService
                .updateBookingRule(this.ledger.id, this.bookingRuleToEdit.id, createBookingRuleDto)
                .subscribe({
                    next: (bookingRule) => {
                        this.toastService.showSuccess('Buchungsregel erfolgreich Verändert');
                        this.isLoading = false;
                        this.saveEmitter$.next();

                        this.router.navigate([
                            'accounting',
                            'ledger',
                            this.ledger?.id,
                            'bank-transactions',
                            'booking-rules',
                            bookingRule.id,
                        ]);
                    },
                    error: (error) => {
                        this.isLoading = false;

                        if (error) {
                            this.toastService.showError(error.error['message']);
                        }
                    },
                });

            return;
        }

        this.bookingRulesService.createBookingRule(this.ledger.id, createBookingRuleDto).subscribe({
            next: () => {
                this.toastService.showSuccess('Buchungsregel erfolgreich hinzugefügt.');
                this.isLoading = false;
                this.saveEmitter$.next();
            },
            error: (error) => {
                this.isLoading = false;

                if (error) {
                    this.toastService.showError(error.error['message']);
                }
            },
        });
    }

    public selectType(type: GuidedBookingTypeShortDto): void {
        this.selectedType = type;
        this.selectedTypeId$.next(type.id);
    }

    public getAdditionalParameter(
        name: 'labourAmount' | 'bookingAccount' | 'vat'
    ): AdditionalParameterDefinitionDto | undefined {
        return this.additionalParameterDefinition?.find((item) => item.name === name);
    }

    public checkCheckBoxSelected(controlName: string): boolean {
        return this.form?.get(controlName)?.value;
    }

    public calculateTaxShareSum(): number {
        const vatId = this.form?.get('vat')?.value;
        const shareAmount = this.form?.get('amount')?.value;
        let taxAmount = 0;
        if (shareAmount === '0,00') {
            return 0;
        }
        if (this.vat) {
            if (this.vat.valueOptions.findIndex((option) => option.id == vatId) === 0) {
                taxAmount = calculateTaxAmount(this.vat.valueOptions[0].amount, shareAmount);
            } else {
                taxAmount = calculateTaxAmount(this.vat.valueOptions[1].amount, shareAmount);
            }
        }

        return Number(taxAmount);
    }

    public isInvalidForm(controlName: string): boolean {
        return formControlHasError(formControl(this.form, controlName), 'required');
    }

    public showLabourAmount(): boolean {
        if (this.labourAmount && this.form.get('amount')?.value > 0) {
            return true;
        }
        return false;
    }

    public isLabourAmountInvalid(): boolean {
        return this.form.get('labourAmount')?.value > this.form.get('amount')?.value;
    }
}
