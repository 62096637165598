import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Subject, combineLatest, interval, map, of, switchMap, takeUntil, takeWhile, tap } from 'rxjs';
import { PersonLocalService } from 'src/app/features/property/services/person-local.service';
import { HubSpotService } from 'src/app/generated-sources/base';
import { UsersService } from 'src/app/generated-sources/base/api/users.service';
import { OperationsGroups } from 'src/app/shared/permissions.enum';
import { LayoutMode, MediaQueriesService } from 'src/app/shared/services/media-queries.service';
import { environment } from 'src/environments/environment';
import { ScalaraSubscriptionsGuard } from '../../guards/subscriptions.guard';
import { AuthService } from '../../services/auth.service';
import { UserflowCustomService } from '../../services/userflow-custom.service';

type HeaderMenuItem =
    | {
          getRoute?: () => string[];
          labelTranslationKey: string;
          headerItemClassName?: string;
          onClick?: () => void;
      } & ({ svgSrc: string; svgType: 'string' } | { svgTemplate: TemplateRef<any> | null; svgType: 'template' });

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnDestroy, OnInit {
    private unsubscribe$ = new Subject<void>();
    public surrogate = false;
    public operations = false;
    public hasUserAccountingAccess = false;
    public personId = '';
    public personImage?: string | null;
    public isMenuVisible = false;
    public vm?: {
        activeRouteLabel: string;
        currentLayoutMode: LayoutMode;
    };

    public readonly environment = environment;

    public signOut?: () => void;

    public person$ = this.personLocalService.getPerson$();
    public activeRouteLabel$ = new BehaviorSubject<string>('');
    public vm$ = combineLatest([this.activeRouteLabel$, this.mediaQueriesService.getCurrentLayoutMode()]).pipe(
        map(([activeRouteLabel, currentLayoutMode]) => ({
            activeRouteLabel,
            currentLayoutMode,
        }))
    );

    public constructor(
        private router: Router,
        private authService: AuthService,
        private usersService: UsersService,
        private personLocalService: PersonLocalService,
        public route: Router,
        public mediaQueriesService: MediaQueriesService,
        public hubSpotService: HubSpotService,
        public subscriptionGuard: ScalaraSubscriptionsGuard,
        public userflowCustomService: UserflowCustomService
    ) {
        this.signOut = function (): void {
            authService.signOut();
            //  set subscription guard to default state
            //  (here should land fixed role guard or created separate service for resetting guards)
            subscriptionGuard.resetSubscriptionGuard();
            router.navigate(['/sign-in']);
        };
    }

    public headerMenuItems: HeaderMenuItem[] = [];

    public ngOnInit(): void {
        if (this.authService.getUserGroupsFromIdToken != null) {
            const groups: string[] = this.authService.getUserGroupsFromIdToken() ?? [];

            this.surrogate = groups.includes(OperationsGroups.SURROGATE);
            this.operations = groups.includes(OperationsGroups.STATISTIC);
        }

        this.personId = this.authService.getStorage().scalaraUser?.personId ?? '';

        if (!this.personId) {
            this.usersService.findAll().subscribe((user) => {
                if (user) {
                    this.personId = user.personId;
                    this.authService.storeScalaraUser(user);
                }
            });
        }

        // update route label when transition is triggered
        this.route.events.pipe(takeUntil(this.unsubscribe$)).subscribe((event) => {
            if (event instanceof NavigationEnd) {
                //  here we want to search for active route label via css query, but have to do it asynchronously
                interval(50)
                    .pipe(
                        switchMap(() => {
                            const results = document.querySelectorAll('.bx--header__menu-item--current');
                            if (results[0]?.textContent) {
                                this.activeRouteLabel$.next(results[0]?.textContent);
                            }
                            return of(results);
                        }),
                        takeWhile((results) => results.length < 1),
                        takeUntil(this.unsubscribe$)
                    )
                    .subscribe();
            }
        });

        this.vm$.pipe(takeUntil(this.unsubscribe$)).subscribe((vm) => {
            this.vm = vm;
        });

        this.hubSpotService
            .hubspottoken()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (token) => {
                    try {
                        if (environment.demoEnvironment) {
                            //  if widget is present remove
                            const widget: undefined | { remove: () => void } = (window as any)?.HubSpotConversations
                                ?.widget;
                            if (widget) {
                                console.warn('removing widget');
                                widget.remove();
                            }
                            this.userflowCustomService.init();
                            return;
                        }
                        (window as any).hsConversationsSettings = {
                            identificationEmail: token.email,
                            identificationToken: token.token,
                        };
                        const status = (window as any).HubSpotConversations.widget.status();
                        (window as any)?.HubSpotConversations?.clear({ resetWidget: true });

                        if (status.loaded) {
                            (window as any)?.HubSpotConversations?.widget?.refresh();
                        } else {
                            (window as any)?.HubSpotConversations?.widget?.load();
                        }
                    } catch (e) {
                        console.warn('error with hubspot widget', e);
                    }
                    this.userflowCustomService.init();
                },
                error: (error) => {
                    console.warn('error with hubspot widget, initializing userflow without hubspot', error);
                    this.userflowCustomService.init();
                },
            });

        this.setPersonAndAccess();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        //  navigation is getting destoyed when user logs out, so we need to remove the widget
        (window as any)?.HubSpotConversations?.widget?.remove();
    }

    public setPersonAndAccess(): void {
        this.headerMenuItems = [
            {
                getRoute: (): string[] => ['communications'],
                headerItemClassName: 'lg-d-none',
                labelTranslationKey: 'NAVIGATION.COMMUNICATION',
                svgType: 'string',
                svgSrc: 'assets/icons/chat.svg',
            },
            {
                getRoute: (): string[] => ['my-profile', this.personId],
                labelTranslationKey: 'NAVIGATION.MY_PROFILE',
                svgType: 'string',
                svgSrc: 'assets/icons/24_person.svg',
            },
            {
                getRoute: (): string[] => ['settings'],
                labelTranslationKey: 'NAVIGATION.SETTINGS',
                svgType: 'string',
                svgSrc: 'assets/icons/24_settings.svg',
            },
            {
                getRoute: (): string[] => ['subscriptions'],
                labelTranslationKey: 'NAVIGATION.BILLING',
                headerItemClassName: 'd-none lg-d-block',
                svgType: 'string',
                svgSrc: 'assets/icons/24_abo.svg',
            },
            {
                getRoute: (): string[] => ['user-management', 'operations'],
                headerItemClassName: this.operations ? 'd-none lg-d-block' : 'd-none',
                labelTranslationKey: 'NAVIGATION.OPERATIONS',
                svgType: 'string',
                svgSrc: 'assets/icons/24_machine-learning-model.svg',
            },
            {
                getRoute: (): string[] => ['user-management', 'surrogate'],
                headerItemClassName: this.surrogate ? 'd-none lg-d-block' : 'd-none',
                labelTranslationKey: 'NAVIGATION.SURROGATES',
                svgType: 'string',
                svgSrc: 'assets/icons/24_analytics-reference.svg',
            },

            {
                headerItemClassName: '',
                labelTranslationKey: 'NAVIGATION.LOGOUT',
                svgType: 'string',
                svgSrc: 'assets/icons/logout.svg',
                onClick: this.signOut,
            },
        ];

        this.person$
            .pipe(
                tap((person) => {
                    const isPropertyManager = person.personRoles.includes('IS_PROPERTY_MANAGER');
                    const isOwner = person.personRoles.includes('IS_OWNER');
                    this.hasUserAccountingAccess = isPropertyManager || isOwner;

                    if (isPropertyManager) {
                        // sometimes double display of "Bank Accounts is prevented"
                        if (this.headerMenuItems[2].labelTranslationKey !== 'NAVIGATION.ACCOUNTS') {
                            this.headerMenuItems.splice(2, 0, {
                                getRoute: (): string[] => ['user-management', 'accounts'],
                                headerItemClassName: 'd-none lg-d-block',
                                labelTranslationKey: 'NAVIGATION.ACCOUNTS',
                                svgType: 'string',
                                svgSrc: 'assets/icons/24_bank.svg',
                            });
                        }
                    }
                    this.personImage = person.imgSmall;
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }

    public toggleMenu(): void {
        this.isMenuVisible = !this.isMenuVisible;
    }

    // public redirectToAppPostBox(): void {
    //     if (this.environment.production) {
    //         return;
    //     }

    //     if (this.environment.demoEnvironment) {
    //         return;
    //     }

    //     window.location.href = this.environment.appPostBoxLocalUrl;
    // }
}
