import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ListItem } from 'carbon-components-angular';
import { ToastService } from 'src/app/core/services/toast.service';
import { formControl, formControlHasError, formatDateYYYYMMDDWithoutHours } from 'src/app/core/utils/common';
import { Occupation, OccupationForm, OccupationService, UpdateOccupationDto } from 'src/app/generated-sources/base';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';

@Component({
    selector: 'app-edit-occupation-details',
    templateUrl: './edit-occupation-details.component.html',
    styleUrls: ['./edit-occupation-details.component.scss'],
})
export class EditOccupationDetailsComponent extends OverlayChildComponent implements OnInit {
    public occupationDetails?: Occupation;
    public propertyId?: string;
    public isLoading = false;
    public form: UntypedFormGroup = new UntypedFormGroup({});

    public depositTypeList: ListItem[] = [];

    public constructor(
        private formBuilder: UntypedFormBuilder,
        private translateService: TranslateService,
        private occupantsService: OccupationService,
        private toastService: ToastService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.occupationDetails = this.config?.data.occupation;
        this.propertyId = this.config?.data.propertyId;

        this.depositTypeList = Object.values(OccupationForm.DepositTypeEnum).map((depositType) => {
            return {
                content: this.translateService.instant(`ENTITIES.OCCUPATION.LABEL_${depositType}_DEPOSIT`),
                value: depositType,
                selected: depositType === this.occupationDetails?.rentDetails?.depositType ? true : false,
            };
        });
        this.form = this.formBuilder.group({
            depositType: [this.depositTypeList.find((item) => item.selected === true), Validators.required],
            depositAmount: [this.occupationDetails?.rentDetails?.depositAmount],
            depositDueDate: [[this.occupationDetails?.rentDetails?.depositDueDate]],
            oneTimeSmallRepairsAmount: [this.occupationDetails?.rentDetails?.oneTimeSmallRepairsAmount],
            annualSmallRepairsAmount: [this.occupationDetails?.rentDetails?.annualSmallRepairsAmount],
            miscellaneousInfo: [this.occupationDetails?.miscellaneousInfo],
        });
    }

    public onSubmit(): void {
        if (!this.occupationDetails || !this.propertyId) {
            return;
        }
        const updateOccupationDto: UpdateOccupationDto = {
            depositType: this.form.value.depositType?.value,
            depositAmount: this.form.value.depositAmount,
            depositDueDate: formatDateYYYYMMDDWithoutHours(this.form.value.depositDueDate[0]),
            oneTimeSmallRepairsAmount: this.form.value.oneTimeSmallRepairsAmount,
            annualSmallRepairsAmount: this.form.value.annualSmallRepairsAmount,
            miscellaneousInfo: this.form.value.miscellaneousInfo,
        };

        this.isLoading = true;
        this.occupantsService
            .updateOccupation(this.config?.data.propertyId, this.occupationDetails.id, updateOccupationDto)
            .subscribe({
                next: () => {
                    this.isLoading = false;
                    this.toastService.showSuccess(this.translateService.instant('PAGES.OCCUPATION.EDIT.TOAST_SUCCESS'));
                    this.saveEmitter$.next();
                },
                error: (error) => {
                    this.isLoading = false;
                    if (error) {
                        this.toastService.showError(this.translateService.instant('COMPONENTS.TOAST.TOAST_ERROR'));
                    }
                },
            });
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public selectedDepositType(event: any): void {
        if (event.item.value !== 'NONE') {
            this.form?.controls['depositDueDate'].setValidators(Validators.required);
            this.form?.controls['depositAmount'].setValidators(Validators.required);
        } else {
            this.form?.controls['depositDueDate'].clearValidators();
            this.form?.controls['depositAmount'].clearValidators();
        }

        this.form?.controls['depositDueDate'].setErrors(null);
        this.form?.controls['depositDueDate'].updateValueAndValidity();

        this.form?.controls['depositAmount'].setErrors(null);
        this.form?.controls['depositAmount'].updateValueAndValidity();
    }

    public isInvalid(controlName: string): boolean {
        if (this.form) {
            return formControlHasError(formControl(this.form, controlName), 'required');
        }
        return false;
    }
}
