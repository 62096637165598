import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, finalize, takeUntil, tap } from 'rxjs';
import { formatDateWithoutHhMmSs, getNameFromPerson, getServiceProviderType } from 'src/app/core/utils/common';
import { RelationUpdateDto } from 'src/app/features/relations/interfaces';
import { Person, PropertiesService, Property, ServiceProviderRelationDto } from 'src/app/generated-sources/base';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { OverlayRef } from 'src/app/shared/overlay/utils/overlay-ref';
import { CellTemplate } from 'src/app/shared/table/enums/cell-template';
import { TableItem } from 'src/app/shared/table/interfaces/table-item';
import { TableModel } from 'src/app/shared/table/interfaces/table-model';
import { PersonLocalService } from '../../property/services/person-local.service';
import { AddServiceProviderOverlayComponent } from '../add-service-provider-overlay/add-service-provider-overlay.component';
import { DeleteRelationOverlayComponent } from '../delete-relation-overlay/delete-relation-overlay.component';
import { EditRelationOverlayComponent } from '../edit-relation-overlay/edit-relation-overlay.component';

export interface RelationUpdateFormatedDto extends RelationUpdateDto {
    serviceType: string;
}
@Component({
    selector: 'app-service-provider',
    templateUrl: './service-provider.component.html',
    styleUrls: ['./service-provider.component.scss'],
})
export class ServiceProviderComponent implements OnInit {
    public isLoading = false;
    public isInitialLoading = false;
    public isManagerOfThisProperty? = false;
    private unsubscribe$ = new Subject<void>();
    private ownershipId = '';
    private propertyId = '';
    public tableModel: TableModel = { data: [], header: [] };
    public originalRelation: RelationUpdateDto = {
        personId: '',
        propertyId: '',
        ownershipId: '',
        relationId: '',
        parent: '',
        startDate: new Date(),
    };

    @Input() public showHeader = true;

    @ViewChild('actions', { static: true })
    public actions?: TemplateRef<any>;

    public constructor(
        private route: ActivatedRoute,
        private overlayService: OverlayService,
        private propertiesService: PropertiesService,
        private translateService: TranslateService,
        private personLocalService: PersonLocalService
    ) {}

    public ngOnInit(): void {
        this.isInitialLoading = true;
        this.propertyId = String(this.route.parent?.snapshot.paramMap.get('id'));
        this.ownershipId = String(this.route.snapshot.paramMap.get('ownershipId'));
        this.loadServiceProviders();
        this.initTableHeader();

        this.personLocalService
            .getRolesOfProperty()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((roles: Property.PermissionRolesEnum[] | null) => {
                this.isManagerOfThisProperty = roles
                    ? Object.values(roles).includes(Person.PermissionRolesEnum.PropertyManager)
                    : false;
            });
    }

    public loadServiceProviders(): void {
        this.isLoading = true;
        this.propertiesService
            .findServiceProviderRelations(this.propertyId)
            .pipe(
                tap(
                    (serviceProviders) =>
                        (this.tableModel.data = serviceProviders.map((serviceProvider) =>
                            this.createRow(serviceProvider)
                        ))
                ),
                finalize(() => {
                    this.isLoading = false;
                    this.isInitialLoading = false;
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }

    private createRow(data: ServiceProviderRelationDto): TableItem[] {
        const link = `/contacts/${data.person.id}`;
        const currentDate = new Date();
        let tableLabel = '';

        const isEditable = Object.values(data.permissionRoles).includes(Person.PermissionRolesEnum.PropertyManager);

        const transferObject: RelationUpdateFormatedDto = {
            personId: data.person.id,
            propertyId: this.propertyId,
            ownershipId: this.ownershipId, // é necessario?
            relationId: data.id ?? '',
            parent: 'serviceProvider', // qual?
            startDate: new Date(data.from),
            person: data.person,
            ...(data.to ? { endDate: new Date(data.to) } : undefined),
            serviceType: data.serviceProvided,
        };

        const startDateToCalculateLabel = new Date(data.from);
        startDateToCalculateLabel.setHours(0);
        startDateToCalculateLabel.setMinutes(0);

        const endDateToCalculateLabel = data.to ? new Date(data.to) : null;
        if (endDateToCalculateLabel) {
            endDateToCalculateLabel.setHours(23);
            endDateToCalculateLabel.setMinutes(59);
        }

        if (
            (!endDateToCalculateLabel && startDateToCalculateLabel <= currentDate) ||
            (endDateToCalculateLabel &&
                endDateToCalculateLabel >= currentDate &&
                startDateToCalculateLabel <= currentDate)
        ) {
            tableLabel = 'Aktueller Dienstleister';
        }

        const serviceProviderType = getServiceProviderType(this.translateService, data.serviceProvided);
        return [
            {
                data: {
                    label: data.person ? getNameFromPerson(data.person) : 'Keine Person gefunden',
                    link,
                    extraData: {
                        isUserRegistered: data.person.hasRegisteredUser,
                        img: data.person.imgSmall,
                    },
                },
                template: data.person.imgSmall ? CellTemplate.imageTableItemWithLabel : CellTemplate.personWithAvatar,
            },
            {
                data: {
                    label: `${formatDateWithoutHhMmSs(data.from)} – ${formatDateWithoutHhMmSs(data.to)}`,
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: `${tableLabel}`,
                    link,
                    textColor: 'green',
                },
                template: CellTemplate.contentWithTagStyle,
            },
            {
                data: {
                    label: serviceProviderType,
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: '',
                    extraData: {
                        transferObject,
                        rightAligned: true,
                    },
                },
                template: isEditable ? this.actions : CellTemplate.Default,
            },
        ];
    }

    public openAddOwnerOverlay(): void {
        const data = {
            componentParent: 'app-service-providers-overview',
            extraData: { propertyId: this.propertyId, ownershipId: this.ownershipId },
        };

        const ref = this.overlayService.open(AddServiceProviderOverlayComponent, { data });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

        this.saveEmittersUpdate(ref);
    }

    private initTableHeader(): void {
        this.tableModel.header = [
            'ENTITIES.PERSON.LABEL_FIRSTNAME_LASTNAME_COMPANY_NAME',
            'ENTITIES.RELATION_WITH_TIMECONSTRAINT.LABEL_FROM_TO',
            '',
            'ENTITIES.SERVICE_PROVIDER.SERVICE_TYPE',
            '',
        ];
    }

    private saveEmittersUpdate(ref: OverlayRef): void {
        ref.saveEmitter$.subscribe(() => {
            this.loadServiceProviders();
        });
        ref.cancelEmitter$.subscribe(() => {
            ref.close();
        });
    }

    public openEditRelationOvervlay(rowData: RelationUpdateDto): void {
        const data = {
            extraData: {
                relation: rowData,
            },
        };
        const ref = this.overlayService.open(EditRelationOverlayComponent, { data });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
        this.saveEmittersUpdate(ref);
    }

    public openDeleteRelationOvervlay(rowData: RelationUpdateDto): void {
        const data = {
            relation: rowData,
        };
        const ref = this.overlayService.open(DeleteRelationOverlayComponent, { data });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
        this.saveEmittersUpdate(ref);
    }
}
