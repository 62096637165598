import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, finalize, switchMap, takeUntil, tap } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { formatNumber, getLocalISOTime, isNotFuture } from 'src/app/core/utils/common';
import { TooltipKey } from 'src/app/features/account-settings/services/custom-tooltip.service';
import {
    AccountAmountDto,
    AccountDto,
    AccountsService,
    LedgerDto,
    LedgersService,
    OpenItemsService,
    OpeningBalanceDto,
    RentReceivableAutoGenerationStatusDto,
} from 'src/app/generated-sources/accounting';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { TableModel } from 'src/app/shared/table/interfaces/table-model';
import { LedgerCustomService } from '../services/ledger-custom.service';

type OverlayAccountKey = Exclude<keyof OpeningBalanceDto, 'bookingDate' | 'startAccountingPeriod'>;
interface OverlayAccountValue extends AccountDto {
    openingBalance: number;
}
type OverlayAccounts = { [key in OverlayAccountKey]: OverlayAccountValue[] };

@Component({
    selector: 'app-opening-balance',
    templateUrl: './opening-balance.component.html',
    styleUrls: ['./opening-balance.component.scss'],
})
export class OpeningBalanceComponent extends OverlayChildComponent implements OnInit, OnDestroy {
    public constructor(
        private route: ActivatedRoute,
        private formBuilder: UntypedFormBuilder,
        private ledgersService: LedgersService,
        private ledgerCustomService: LedgerCustomService,
        private accountsService: AccountsService,
        private translateService: TranslateService,
        private toastService: ToastService,
        private openItemsService: OpenItemsService,
        private overlayService: OverlayService
    ) {
        super();
    }
    public openingBalance?: OpeningBalanceDto = undefined;
    public isLoading = false;
    public isLedgerMVorSEV = false;
    public form: UntypedFormGroup = new UntypedFormGroup({});
    private unsubscribe$ = new Subject<void>();
    public ledgerId?: string;
    public ledger?: LedgerDto;
    public rentReceivableAutoGenerationStatus?: RentReceivableAutoGenerationStatusDto.StatusEnum;
    public overlayAccounts: OverlayAccounts = {
        availableFunds: [],
        expensesAndRevenues: [],
        receivables: [],
    };
    public assetsTableModel: TableModel = { data: [], header: [] };
    public toastMessages = {
        success: this.translateService.instant('ACCOUNTING.OPENING_BALANCE.SUCCESSMESSAGE'),
        error: this.translateService.instant('ACCOUNTING.OPENING_BALANCE.ERRORMESSAGE'),
    };
    public openingBalanceDate: Date | null = null;
    public startAccountingPeriod: Date | null = null;

    public isNotFuture = isNotFuture;

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    //  we had to hardcode tooltip here, because for such a small part of the app we do not want to do changes on BE,
    //  so that BE deliveres tooltip text conditionally depending on the opened page 'Kontenrahmen' or 'Anfangsbestande eingeben'

    public tooltipsConfig: {
        accountName: 'Verfügbare Geldmittel der Erhaltungsrücklage';
        tooltipText: string;
    }[] = [
        {
            accountName: 'Verfügbare Geldmittel der Erhaltungsrücklage',
            tooltipText:
                'Hier tragen Sie die Summe der tatsächlich vorhandenen Geldmittel aller Bankkonten (Giro-, Festgeld- und Tagesgeldkonten) ein, auch ggfs. vorhandene Bargeldkassen. ',
        },
    ];

    public ngOnInit(): void {
        this.isLoading = true;
        this.ledgerId = this.config?.data.ledgerId;
        this.isLedgerMVorSEV = this.config?.data.isLedgerMVorSEV;
        this.ledgerCustomService
            .getLedger$()
            .pipe(
                switchMap((ledger: LedgerDto | null) => {
                    if (ledger) {
                        this.ledger = ledger;
                    }
                    return this.getOpeningBalance();
                }),
                switchMap((openingBalance) => {
                    this.openingBalance = openingBalance;
                    this.openingBalanceDate =
                        openingBalance.bookingDate !== null ? new Date(openingBalance.bookingDate) : null;

                    if (this.config?.data.isLedgerMVorSEV) {
                        this.startAccountingPeriod =
                            openingBalance.startAccountingPeriod && openingBalance.startAccountingPeriod !== null
                                ? new Date(openingBalance.startAccountingPeriod)
                                : null;
                    }

                    return this.findAccounts();
                }),
                tap((accounts) => {
                    this.overlayAccounts = this.getOverlayAccounts(accounts);
                    this.form = this.createForm({ overlayAccounts: this.overlayAccounts });
                    this.isLoading = false;
                }),
                switchMap(() => this.openItemsService.getRentReceivableGenerationStatus(this.ledger!.id)),
                tap((status: RentReceivableAutoGenerationStatusDto) => {
                    this.rentReceivableAutoGenerationStatus = status.status;
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }

    public onSubmit(): void {
        const payload: OpeningBalanceDto = {
            availableFunds: [],
            expensesAndRevenues: [],
            receivables: [],
            bookingDate: getLocalISOTime(this.form.get('bookingDate')!.value),
        };

        if (this.config?.data.isLedgerMVorSEV) {
            payload.startAccountingPeriod = getLocalISOTime(this.form.get('startDate')!.value);
        }

        for (let i = 0; i < this.overlayAccounts.availableFunds.length; i++) {
            payload.availableFunds.push({
                accountId: this.overlayAccounts.availableFunds[i].id,
                amount: this.getFormattedFormInputValue({ formArrayName: 'availableFunds', inputIndex: i }),
            });
        }
        for (let i = 0; i < this.overlayAccounts.expensesAndRevenues.length; i++) {
            payload.expensesAndRevenues.push({
                accountId: this.overlayAccounts.expensesAndRevenues[i].id,
                amount: this.getFormattedFormInputValue({ formArrayName: 'expensesAndRevenues', inputIndex: i }),
            });
        }
        for (let i = 0; i < this.overlayAccounts.receivables.length; i++) {
            payload.receivables?.push({
                accountId: this.overlayAccounts.receivables[i].id,
                amount: this.getFormattedFormInputValue({ formArrayName: 'receivables', inputIndex: i }),
            });
        }

        this.isLoading = true;
        this.ledgersService
            .createOrUpdateOpeningBalance(this.ledgerId!, payload)
            .pipe(
                takeUntil(this.unsubscribe$),
                finalize(() => (this.isLoading = false))
            )
            .subscribe({
                next: () => {
                    this.toastService.showSuccess(this.toastMessages.success);
                    this.saveEmitter$.next();
                },
                error: () => {
                    this.toastService.showError(this.toastMessages.error);
                },
            });
    }

    public createForm({ overlayAccounts }: { overlayAccounts: OverlayAccounts }): UntypedFormGroup {
        //  date-picker sets form value as array, so we need to use them aswell to stay consistent
        const defaultBookingDateValue = this.openingBalanceDate ? [this.openingBalanceDate] : [null];

        const startDate = new Date(new Date().getFullYear(), 0, 1);

        const formGroup: UntypedFormGroup = this.formBuilder.group({
            bookingDate: [defaultBookingDateValue, [Validators.required]],
            availableFunds: new UntypedFormArray(
                overlayAccounts.availableFunds.map(
                    (item) => new UntypedFormControl(formatNumber(item.openingBalance), [Validators.required])
                )
            ),
            expensesAndRevenues: new UntypedFormArray(
                overlayAccounts.expensesAndRevenues.map(
                    (item) => new UntypedFormControl(formatNumber(item.openingBalance), [Validators.required])
                )
            ),

            //recievable formcontrol is expected in all cases, but only shown when needed
            receivables: new UntypedFormArray(
                overlayAccounts.receivables.map(
                    (item) => new UntypedFormControl(formatNumber(item.openingBalance), [Validators.required])
                )
            ),
        });

        if (this.config?.data.isLedgerMVorSEV) {
            const startDateControl = new UntypedFormControl(this.startAccountingPeriod || startDate, Validators.required);
            formGroup.addControl('startDate', startDateControl);
        }

        return formGroup;
    }

    public getFormArray(name: string): UntypedFormArray {
        return this.form?.get(name) as UntypedFormArray;
    }

    public findAccounts(): Observable<AccountDto[]> {
        return this.accountsService.findAll(this.ledgerId!);
    }

    public getOpeningBalance(): Observable<OpeningBalanceDto> {
        return this.ledgersService.getOpeningBalance(this.ledgerId!);
    }

    /**
     * Creates overlayAccounts from openingBalance and list of all accounts
     *
     * @returns {OverlayAccounts} Returns overlayAccounts object
     */
    public getOverlayAccounts(accounts: AccountDto[]): OverlayAccounts {
        //  find openingBalance item and account item with same id and combine
        const accountAmountToOverlayAccountValue = (accountAmount: AccountAmountDto): OverlayAccountValue => {
            const account = accounts.find((account) => account.id === accountAmount.accountId);
            if (!account) {
                throw new Error('accounts in opening balance and all accounts list are not the same');
            }
            return { ...account, openingBalance: accountAmount.amount };
        };

        const sortByNameCallback = (a: OverlayAccountValue, b: OverlayAccountValue): 1 | -1 | 0 => {
            const nameA = a.name.toLocaleLowerCase();
            const nameB = b.name.toLocaleLowerCase();
            return nameA > nameB ? 1 : nameA === nameB ? 0 : -1;
        };

        if (!this.openingBalance) {
            throw new Error('there is no opening balance');
        }

        const availableFunds = this.openingBalance.availableFunds
            .map(accountAmountToOverlayAccountValue)
            .sort(sortByNameCallback);
        const expensesAndRevenues = this.openingBalance.expensesAndRevenues
            .map(accountAmountToOverlayAccountValue)
            .sort(sortByNameCallback);
        let receivables: OverlayAccountValue[] = [];
        if (this.openingBalance.receivables) {
            receivables = this.openingBalance.receivables
                .map(accountAmountToOverlayAccountValue)
                .sort(sortByNameCallback);
        }

        return { availableFunds, expensesAndRevenues, receivables };
    }

    public abort(): void {
        this.overlayService.removeOverlayComponentFromBody();
    }

    public getFormattedFormInputValue({
        formArrayName,
        inputIndex,
    }: {
        formArrayName: string;
        inputIndex: number;
    }): number {
        //  use parseFloat to coerse form default string values to number (for example '0,00' to 0)
        const formValue = this.getFormArray(formArrayName).value[inputIndex];

        return typeof formValue === 'string' ? this.parseFloatAsCurrencyInteger(formValue) : formValue;
    }

    //Takes input string like 18.217,20 and returns 1821700
    //Se also https://scalara.atlassian.net/wiki/spaces/DEV/blog/2023/11/30/168099846/Be+carefull+when+parsing+floats
    public parseFloatAsCurrencyInteger(input: string): number {
        const remove1000Separator = input.replace(/\./g, '');
        const replaceCommaWithDot = remove1000Separator.replace(/,/g, '.');
        const parsedFloat = parseFloat(replaceCommaWithDot);
        return Math.round(parsedFloat * 100);
    }
    public isDateFirstOfJanuary(): boolean {
        const formDateValue: unknown = this.form.controls['bookingDate']?.value[0];
        if (formDateValue instanceof Date && formDateValue.getDate() === 1 && formDateValue.getMonth() === 0) {
            return true;
        }
        return false;
    }

    //  hide 'expensesAndRevenues' table if 01.01.YYYY
    public isTableHidden(tableKey: string): boolean {
        return (
            (this.isDateFirstOfJanuary() && tableKey === 'expensesAndRevenues') ||
            (tableKey === 'receivables' && this.openingBalance && !this.openingBalance.receivables?.length) ||
            (tableKey === 'receivables' && !this.config?.data.isLedgerMVorSEV) ||
            (tableKey === 'receivables' &&
                this.config?.data.isLedgerMVorSEV &&
                this.rentReceivableAutoGenerationStatus !== 'NOT_ACTIVATED')
        );
    }

    //  hide kostentragung asset if 01.01.YYYY
    public isCellHidden({
        overlayAccountsArrayKey,
        overlayAccountsArrayIndex,
    }: {
        overlayAccountsArrayKey: string;
        overlayAccountsArrayIndex: number;
    }): boolean {
        const account: AccountDto =
            this.overlayAccounts[overlayAccountsArrayKey as OverlayAccountKey][overlayAccountsArrayIndex];
        return (
            !this.isLedgerMVorSEV &&
            this.isDateFirstOfJanuary() &&
            account.accountGroup.name === 'Kostentragung' &&
            account.type === 'ASSET'
        );
    }

    public isDateInputValid(): boolean {
        if (this.form.get('startDate') && this.form.get('bookingDate') && this.form.get('bookingDate')?.value[0]) {
            const startDate = getLocalISOTime(this.form.get('startDate')!.value);
            const bookingDate = getLocalISOTime(this.form.get('bookingDate')!.value);
            return startDate <= bookingDate;
        } else {
            return true;
        }
    }

    public getTooltipTextForAccount(account: AccountDto): string {
        const text = this.tooltipsConfig.find(
            (item) => item.accountName.toLowerCase().trim() == account.name.toLowerCase().trim()
        )?.tooltipText;
        return text ?? '';
    }

    public TooltipKey = TooltipKey;
}
