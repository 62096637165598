import { Component, OnInit } from '@angular/core';
import { Subject, takeUntil, tap } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { OperationsExpenseStatementsService, SimpleOesDto } from 'src/app/generated-sources/accounting';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';
import { LedgerCustomService } from '../../services/ledger-custom.service';

@Component({
    selector: 'app-activate-oes',
    templateUrl: './activate-oes.component.html',
    styleUrls: ['./activate-oes.component.scss'],
})
export class ActivateOesComponent extends OverlayChildComponent implements OnInit {
    private unsubscribe$ = new Subject<void>();
    public existStatementForPeriod = false;
    public operationsExpenseStatements: SimpleOesDto[] = [];
    public oesDateFrom = 0;
    public oesDateTo = 0;
    public ledgerId = '';
    public oesId = '';

    public constructor(
        private operationsExpenseStatementsService: OperationsExpenseStatementsService,
        private toastService: ToastService,
        public ledgerCustomService: LedgerCustomService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.operationsExpenseStatements = this.config?.data.operationsExpenseStatements;
        this.oesDateFrom = this.config?.data.oesDateFrom;
        this.oesDateTo = this.config?.data.oesDateTo;
        this.ledgerId = this.config?.data.ledgerId;
        this.oesId = this.config?.data.oesId;

        this.operationsExpenseStatementsService
            .findAll(this.ledgerId)
            .pipe(
                tap((operationsExpenseStatements) => {
                    this.operationsExpenseStatements = operationsExpenseStatements;
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }

    public submit(): void {
        this.validateOes();
        if (this.existStatementForPeriod) {
            return;
        }

        this.operationsExpenseStatementsService
            .activate(this.ledgerId, this.oesId)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: () => {
                    this.toastService.showSuccess('Abrechnung erfolgreich aktiviert.');
                    this.saveEmitter$.next();
                },
                error: (error) => {
                    if (error) {
                        this.toastService.showError(error.error['message']);
                    }
                },
            });
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public validateOes(): void {
        const thereIsSamePeriod = this.operationsExpenseStatements.find((item) => {
            const startDate = new Date(item.startDate).getTime();
            const endDate = new Date(item.endDate).getTime();

            if (this.oesDateFrom && this.oesDateTo) {
                this.oesDateFrom = new Date(this.oesDateFrom).getTime();
                this.oesDateTo = new Date(this.oesDateTo).getTime();

                return (
                    (startDate < this.oesDateFrom && endDate > this.oesDateFrom) ||
                    (startDate > this.oesDateFrom && startDate < this.oesDateTo)
                );
            }
            return false;
        });
        if (thereIsSamePeriod) {
            this.existStatementForPeriod = true;
        } else {
            this.existStatementForPeriod = false;
        }
    }
}
