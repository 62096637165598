<div class="mb-2r mt-1-5r d-flex">
    <div class="mt-1r">
        <div ibmCol class="d-flex align-items-center">
            <div
                *ngIf="amountUnbookedTransaction > 0"
                class="icon-container icon-container--40 icon-container--orange mr-04"
            >
                <svg-icon src="/assets/icons/24_attention.svg"></svg-icon>
            </div>
            <div *ngIf="amountUnbookedTransaction === 0" class="icon-container icon-container--40 mr-04">
                <svg-icon src="/assets/icons/24_closed.svg"></svg-icon>
            </div>
            <div class="s-body-14-22-regular">{{ 'ACCOUNTING.OPEN-ITEMS.COUNT_INTRO' | translate }}</div>
            <div class="s-body-14-22-regular ml-04">
                <span class="s-body-14-22-bold">{{ amountUnbookedTransaction }}</span>
                {{ 'ACCOUNTING.BANK_TRANSACTION_DETAILS.UNBOOKED_TRANSACTION' | translate }}
            </div>
        </div>
    </div>
    <div class="d-flex ml-auto">
        <div>
            <app-search [searchInputId]="'bankTransactionOverviewTable'"></app-search>
        </div>
        <div class="ml-1r styled-filter" *ngIf="filterItems">
            <ibm-dropdown (selected)="onSelectFilter($event)" [displayValue]="dropdownRenderer" placeholder="Select">
                <ibm-dropdown-list [items]="filterItems"></ibm-dropdown-list>
            </ibm-dropdown>
            <ng-template #dropdownRenderer let-item="item">
                <div *ngIf="item && item.content" class="styled-icon-calender">
                    {{ item.content }}
                </div>
            </ng-template>
        </div>
    </div>
</div>
<div ibmRow class="mb-04">
    <div ibmCol class="mb-04 w-100">
        <app-table
            *ngIf="!bankTransactions.length"
            [emptyText]="'PAGES.BANK_ACCOUNTS.EMPTY_TABLE'"
            [header]="tableModel.header"
            [data]="tableModel.data"
            [emptyTextLinkLabel]="'PAGES.BANK_ACCOUNTS.EMPTY_TABLE_LINK_TEXT'"
            [emptyTextLink]="'/user-management/accounts'"
            [tableSearchId]="'bankTransactionOverviewTable'"
            [isLoading]="isLoading"
        >
        </app-table>
        <app-table
            *ngIf="bankTransactions.length"
            (customButton)="handlingEvent($event)"
            [emptyText]="'ACCOUNTING.BANK_TRANSACTION_DETAILS.EMPTY_TABLE'"
            [header]="tableModel.header"
            [data]="tableModel.data"
            [tableSearchId]="'bankTransactionOverviewTable'"
            [isLoading]="isLoading"
        >
        </app-table>
    </div>
</div>
