import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
    BehaviorSubject,
    Observable,
    ObservedValueOf,
    Subject,
    combineLatest,
    map,
    shareReplay,
    switchMap,
    take,
    takeUntil,
    takeWhile,
    tap,
    timer,
} from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { formatDateDDMMYYYY } from 'src/app/core/utils/dateUtils';
import { OperationsService, Product, SubscriptionsService } from 'src/app/generated-sources/base';
import { FiletoView } from 'src/app/shared/components/file-viewer/file-viewer.component';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { CellTemplate } from 'src/app/shared/table/enums/cell-template';
import { TableModel } from 'src/app/shared/table/interfaces/table-model';
import { PersonLocalService } from '../../../property/services/person-local.service';
import { getCurrentAboType } from '../../helpers/helpers';
import { CustomSubscriptionsService } from '../../services/custom-subscriptions.service';
import { ChangeInvoiceInformationOverlayComponent } from '../change-invoice-information-overlay/change-invoice-information-overlay.component';

export type ExtendedProduct = Product & { tsType: 'product' };

@Component({
    selector: 'app-subscriptions',
    templateUrl: './subscriptions.component.html',
    styleUrls: ['./subscriptions.component.scss'],
})
export class SubscriptionsComponent implements OnDestroy, OnInit {
    public constructor(
        public personLocalService: PersonLocalService,
        public operationsService: OperationsService,
        public subscriptionsService: SubscriptionsService,
        public overlayService: OverlayService,
        public translateService: TranslateService,
        public toastService: ToastService,
        public router: Router,
        public route: ActivatedRoute,
        private changeDetectorRef: ChangeDetectorRef,
        public customSubscriptionsService: CustomSubscriptionsService
    ) {}

    public isLoading = false;

    public unsubscribe$ = new Subject<void>();
    public unsubscribeTimer$ = new Subject<void>();
    public person$ = this.personLocalService.getPerson$();
    public accountMetrics$ = this.customSubscriptionsService.getAccountMetrics$();
    public possibleOwnershipsToCreate$ = this.customSubscriptionsService.getPossibleOwnershipsToCreate$();

    public subscriptions$ = this.customSubscriptionsService.getSubscriptions$();
    public currentSubscription$ = this.customSubscriptionsService.getCurrentSubscription$();

    public triggerSuggestedSubscriptions$ = new BehaviorSubject<null>(null);
    public suggestedSubscriptions$ = this.triggerSuggestedSubscriptions$.pipe(
        tap(() => (this.isLoading = true)),
        takeUntil(this.unsubscribe$),
        switchMap(() => this.subscriptionsService.getSubscribableProducts()),
        // map products to extendedproducts
        map((products) => products.map((i) => ({ ...i, tsType: 'product' })) as ExtendedProduct[]),
        // sort by maxOwnerships, show first the ones with the lowest maxOwnerships
        // sort by price, lowest to highest -> introduced because of yearly subscriptions (conflict fix)
        map(
            (products) =>
                products.slice().sort((a, b) => {
                    const numA = parseInt(a.price.split(/[^0-9]+/).filter(Boolean)[0]);
                    const numB = parseInt(b.price.split(/[^0-9]+/).filter(Boolean)[0]);
                    return numA - numB;
                }),
            shareReplay({ bufferSize: 1, refCount: true })
        )
    );

    public triggerInvoiceInformation$ = new BehaviorSubject<null>(null);

    public invoiceInformation$ = this.triggerInvoiceInformation$.pipe(
        tap(() => console.log('triggered invoice')),
        tap(() => (this.isLoading = true)),
        switchMap(() => this.subscriptionsService.getInvoiceInformation()),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    public invoices$ = this.triggerInvoiceInformation$.pipe(
        switchMap(() => this.subscriptionsService.getInvoices()),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    public tableModel$: Observable<TableModel> = this.invoices$.pipe(
        map((invoices) => {
            const tableModel: TableModel = {
                header: ['PAGES.ABO.TABLE.COLUMN_LABEL', 'PAGES.ABO.TABLE.COLUMN_DATE', ''],
                data: invoices.map((x) => [
                    {
                        data: {
                            label: x.invoiceLabel,
                        },
                        template: CellTemplate.Default,
                    },

                    {
                        data: {
                            label: `${this.formatDate(x.periodStart)} - ${this.formatDate(x.periodEnd)}`,
                        },
                        template: CellTemplate.Default,
                    },
                    {
                        data: {
                            label: '',
                            extraData: { invoice: x },
                        },
                        template: CellTemplate.filesActions,
                    },
                ]),
            };
            return tableModel;
        })
    );

    public fileViewerData$ = new BehaviorSubject<{ fileToView: FiletoView | null; showModal: boolean }>({
        fileToView: null,
        showModal: false,
    });

    public vm$ = combineLatest([
        this.person$,
        this.accountMetrics$,
        this.possibleOwnershipsToCreate$,
        this.subscriptions$,
        this.currentSubscription$,
        this.suggestedSubscriptions$,
        this.invoiceInformation$,
        this.invoices$,
        this.tableModel$,
        this.fileViewerData$,
    ]).pipe(
        tap(() => (this.isLoading = true)),
        map(
            ([
                person,
                accountMetrics,
                possibleOwnershipsToCreate,
                subscriptions,
                currentSubscription,
                suggestedSubscriptions,
                invoiceInformation,
                invoices,
                tableModel,
                fileViewerData,
            ]) => {
                return {
                    person,
                    accountMetrics,
                    possibleOwnershipsToCreate,
                    subscriptions,
                    currentSubscription,
                    suggestedSubscriptions,
                    invoiceInformation,
                    invoices,
                    tableModel,
                    fileViewerData,
                    currentAboType: getCurrentAboType(currentSubscription),
                };
            }
        ),
        tap(() => (this.isLoading = false))
    );

    public vm: null | ObservedValueOf<typeof this.vm$> = null;

    public showOwnershipInfo(): boolean {
        // do not show for family / basic / none
        const notShow =
            this.vm?.currentSubscription &&
            ['basic', 'none'].includes(this.getCurrentAboType(this.vm?.currentSubscription));

        if (notShow) {
            return false;
        }
        return true;
    }

    public showPossibleOwnershipsBox(): boolean {
        if (!this.vm) {
            return false;
        }

        return this.showOwnershipInfo() && !['family', 'beta'].includes(this.vm.currentAboType);
    }

    public showSuggestedSubscriptions(): boolean {
        if (this.vm?.currentAboType && ['basic', 'family', 'beta'].includes(this.vm?.currentAboType)) {
            return false;
        }

        return true;
    }

    public get changeInvoiceInformationCallback(): () => void {
        return (): void => {
            const ref = this.overlayService.open(ChangeInvoiceInformationOverlayComponent, {
                data: { invoiceInformation$: this.invoiceInformation$ },
            });
            ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
                ref.close();
            });

            ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
                this.triggerInvoiceInformation$.next(null);
            });
        };
    }

    public getCurrentAboType = getCurrentAboType;

    public handleFile(event: any): void {
        const flow: 'download' | 'view' = event.isDownload ? 'download' : 'view';
        const fileName = event.extraData.invoice.invoiceLabel;

        const downloadLink$ = new Subject<string>();

        this.subscriptionsService
            .getDownloadLink(event.extraData.invoice.id)
            .pipe(take(1), takeUntil(this.unsubscribe$))
            .subscribe({
                next: (x) => {
                    downloadLink$.next(x.url as unknown as string);
                },
                error: (e) => console.log({ e }),
            });

        downloadLink$.pipe(take(1), takeUntil(this.unsubscribe$)).subscribe((url) => {
            if (flow === 'view') {
                this.fileViewerData$.next({
                    showModal: true,
                    fileToView: { fileName, file: url },
                });
                return;
            }

            const linkDomElement = document.createElement('a');
            linkDomElement.href = url;
            linkDomElement.download = fileName;
            linkDomElement.click();
        });
    }

    public closeFileViewerModal(): void {
        this.fileViewerData$.next({ fileToView: null, showModal: false });
    }

    public formatDate = formatDateDDMMYYYY;

    public ngOnInit(): void {
        this.vm$.pipe(takeUntil(this.unsubscribe$)).subscribe({
            next: (vm) => {
                this.vm = vm;
            },
            error: (err) => {
                console.warn({ err });
                this.toastService.showError('Fehler beim Laden der Daten');
                this.isLoading = false;
            },
        });

        this.route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
            if (params['checkoutResult'] === 'success') {
                const subscriptionsRefreshTimer = timer(2000, 5000);
                subscriptionsRefreshTimer
                    .pipe(
                        takeUntil(this.unsubscribe$),
                        takeUntil(this.unsubscribeTimer$),
                        takeWhile(() => Boolean(!this.vm?.currentSubscription))
                    )
                    .subscribe({
                        next: () => {
                            if (this.vm?.currentSubscription) {
                                this.removeRefreshTimer();
                                return;
                            }

                            this.handleSubscriptionChanged();
                        },
                        complete: () => {
                            //  if there was no subscription, but we finally got one -> reload page to make navigation working
                            // TODO: refactor with new role guard and local person service, so we can trigger navigation without reloading

                            this.removeRefreshTimer();
                            setTimeout(() => window.location.reload(), 0);
                        },
                    });

                return;
            }
            //  remove timer if there is no ?checkoutResult=success param
            this.removeRefreshTimer();
        });
    }

    public removeRefreshTimer(): void {
        this.router.navigate([], {
            queryParams: { checkoutResult: null },
            queryParamsHandling: 'merge',
        });
        this.unsubscribeTimer$.next();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.unsubscribeTimer$.next();
    }

    public handleSubscriptionChanged(): void {
        this.customSubscriptionsService.triggerSubscriptionsRefresh();
        this.triggerSuggestedSubscriptions$.next(null);
    }
}
