import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, switchMap, takeUntil, tap } from 'rxjs';
import { getPropertyTypeInfos } from 'src/app/core/utils/common';
import { MenuItem } from 'src/app/shared/sidenav/interfaces/menu-item';
import { CellTemplate } from 'src/app/shared/table/enums/cell-template';
import { TableItem } from 'src/app/shared/table/interfaces/table-item';
import { TableModel } from 'src/app/shared/table/interfaces/table-model';
import { Person, Property } from '../../../../generated-sources/base';
import { PersonLocalService } from '../../services/person-local.service';
import { PropertyCustomService } from '../../services/property-custom.service';

@Component({
    selector: 'app-property-view',
    templateUrl: './property-detail-view.component.html',
    styleUrls: ['./property-detail-view.component.scss'],
})
export class PropertyDetailViewComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();

    public property?: Property;
    public isLoading = false;
    public id?: string;
    public propertyType?: Property.PropertyTypeEnum;
    public propertiesTable: TableModel = { data: [], header: [] };
    public menuItems: MenuItem[] = [];

    public constructor(
        private route: ActivatedRoute,
        public translate: TranslateService,
        public personLocalService: PersonLocalService,
        private propertyCustomService: PropertyCustomService
    ) {}

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public ngOnInit(): void {
        this.isLoading = true;

        this.propertiesTable.header = [
            'ENTITIES.PROPERTY.LABEL_IMG_FULL',
            'ENTITIES.PROPERTY.LABEL_NAME',
            'ENTITIES.ADDRESS.LABEL_STREET_AND_NUMBER',
            'ENTITIES.ADDRESS.LABEL_ZIP_CODE',
            'ENTITIES.ADDRESS.LABEL_AREA',
            'ENTITIES.PROPERTY.LABEL_MANAGEMENT_TYPE',
        ];

        this.propertyCustomService
            .getProperties$()
            .pipe(
                tap((properties) => {
                    this.propertiesTable.data = properties.map((property) => {
                        return this.createPropertyRow(property);
                    });
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();

        this.route.paramMap
            .pipe(
                switchMap((params: ParamMap) => {
                    this.id = String(params.get('id'));
                    this.propertyCustomService.setPropertyId(this.id);
                    return this.propertyCustomService.getProperty$();
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((result: Property | null) => {
                if (result) {
                    if (
                        result.permissionRoles.length === 1 &&
                        Object.values(result.permissionRoles).includes(Person.PermissionRolesEnum.ServiceProvider)
                    ) {
                        this.menuItems = [
                            {
                                textPath: 'PROPERTY.COMMON.BASE_DATA',
                                link: 'base-data',
                            },
                            {
                                textPath: 'PROPERTY.COMMON.SERVICE_PROVIDER',
                                link: 'service-providers',
                            },
                        ];
                    } else {
                        const occupationChildren = [
                            {
                                textPath: 'COMPONENTS.SIDENAV_ACCOUNTING.LABEL_OCCUPATIONS_CONTRACTS',
                                link: 'occupations',
                            },
                        ];
                        if (
                            Object.values(result.permissionRoles).includes(
                                Person.PermissionRolesEnum.PropertyManager
                            ) ||
                            Object.values(result.permissionRoles).includes(Person.PermissionRolesEnum.Owner)
                        ) {
                            occupationChildren.push({
                                textPath: 'COMPONENTS.SIDENAV_ACCOUNTING.LABEL_OCCUPATIONS_DEPOSIT_OVERVIEW',
                                link: 'occupations-deposit-overview',
                            });
                        }

                        this.menuItems = [
                            {
                                textPath: 'PROPERTY.COMMON.BASE_DATA',
                                link: 'base-data',
                            },
                            {
                                textPath:
                                    result.propertyType !== 'MV'
                                        ? 'PROPERTY.COMMON.UNITS_OWNERS'
                                        : 'PROPERTY.COMMON.UNITS',
                                link: 'ownerships',
                            },
                            {
                                textPath:
                                    result.propertyType === 'MV'
                                        ? 'PROPERTY.COMMON.OCCUPATIONS'
                                        : 'PROPERTY.COMMON.SEV',
                                link: '',
                                children: occupationChildren,
                            },

                            {
                                textPath: 'PROPERTY.COMMON.ADVISERS',
                                link: 'advisers',
                            },
                            {
                                textPath: 'PROPERTY.COMMON.SERVICE_PROVIDER',
                                link: 'service-providers',
                            },
                            {
                                textPath: 'PROPERTY.COMMON.DOCUMENTS',
                                link: 'documents',
                            },
                        ];

                        const propTypesToShowAdvisers: Property.PropertyTypeEnum[] = ['WEG', 'WEG_SEV'];

                        if (!propTypesToShowAdvisers.includes(result.propertyType)) {
                            this.menuItems.splice(3, 1);
                        }
                    }

                    this.property = result;
                    this.propertyType = result.propertyType;
                    this.isLoading = false;
                    this.personLocalService.setRolesOfProperty(result.permissionRoles);
                }
            });
    }

    private createPropertyRow(data: Property): TableItem[] {
        let tags: any = [];

        if (data.propertyType == Property.PropertyTypeEnum.WegSev) {
            tags = [
                {
                    label: 'WEG',
                    textColor: getPropertyTypeInfos('WEG').color,
                },
                {
                    label: 'SEV',
                    textColor: getPropertyTypeInfos('SEV').color,
                },
            ];
        }

        return [
            {
                data: {
                    label: data.imgSmall ? data.imgSmall : '',
                    extraData: data, // ATTENTION: do not remove this extra data, as it is being used in the property switch component
                },
                template: CellTemplate.Image,
            },
            {
                data: {
                    label: data.name,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: `${data.address.streetName} ${data.address.streetNumber}`,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: data.address.zipCode,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: data.address.area,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: tags.length ? '' : data.propertyType,
                    textColor: tags.length ? '' : getPropertyTypeInfos(data.propertyType).color,
                    extraData: tags.length ? tags : null,
                },
                template: CellTemplate.contentWithTagStyle,
            },
        ];
    }
}
