import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject, filter, map, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './core/services/auth.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();

    public isLoggedIn = false;

    public readonly environment = environment;

    public constructor(private authService: AuthService, private router: Router, private titleService: Title) {}

    public ngOnInit(): void {
        this.authService
            .isLoggedIn()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((isLoggedIn: boolean) => (this.isLoggedIn = isLoggedIn));
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => {
                    let route: ActivatedRoute = this.router.routerState.root;
                    let routeTitle = '';
                    while (route?.firstChild) {
                        route = route.firstChild;
                    }
                    if (route.snapshot.data['title']) {
                        routeTitle = route?.snapshot.data['title'];
                    }
                    return routeTitle;
                })
            )
            .subscribe((title: string) => {
                if (title) {
                    this.titleService.setTitle(`${environment.appTitleTag} - ${title}`);
                }
            });
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }
}
