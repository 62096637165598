/**
 * SCALARA Accounting Module
 * The SCALARA Accounting API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RentReceivableAutoGenerationStatusDto { 
    status: RentReceivableAutoGenerationStatusDto.StatusEnum;
}
export namespace RentReceivableAutoGenerationStatusDto {
    export type StatusEnum = 'NOT_ACTIVATED' | 'IN_PROGRESS' | 'ACTIVATED';
    export const StatusEnum = {
        NotActivated: 'NOT_ACTIVATED' as StatusEnum,
        InProgress: 'IN_PROGRESS' as StatusEnum,
        Activated: 'ACTIVATED' as StatusEnum
    };
}


