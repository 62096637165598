<ibm-loading *ngIf="isLoading" [isActive]="true" size="normal" [overlay]="true"></ibm-loading>
<div class="tw-flex tw-justify-between tw-mb-11">
    <ibm-breadcrumb [items]="breadcrumbs" [noTrailingSlash]="true"> </ibm-breadcrumb>

    <div class="tw-flex tw-space-x-2">
        <!-- <app-scalara-button variant="ghost" svgName="24_delete.svg">{{
            'CONTROLS.BUTTONS.REMOVE' | translate
        }}</app-scalara-button> -->
        <app-scalara-button (click)="openEditBookingRuleOverlay()" variant="ghost" svgName="24_edit.svg">{{
            'CONTROLS.BUTTONS.EDIT' | translate
        }}</app-scalara-button>
    </div>
</div>

<div class="tw-flex tw-justify-between">
    <div class="tw-mr-12 tw-w-full">
        <app-detail-card
            [dividerIconColor]="bookingRule?.status === 'ACTIVATED' ? 'green' : 'gray'"
            [tagLabel]="bookingRule?.isAutogenerated ? 'Automatischer Vorschlag' : ''"
        >
            <div top-container>
                <div class="tw-mb-12">
                    <div class="tw-s-label-12-22-semibold tw-text-scalaraGray-03 tw-mb-2">Buchungsregel</div>
                    <div *ngIf="bookingRule?.name && bookingRule?.name !== '–'" class="tw-s-head-18-26-bold">
                        {{ bookingRule?.name }}
                    </div>
                    <div *ngIf="!bookingRule?.name || bookingRule?.name === '–'" class="s-body-14-22-italic">
                        {{ 'PAGES.OWNERSHIP.DETAIL_VIEW.LABEL_NOT_SPECIFIED' | translate }}
                    </div>
                </div>

                <div class="tw-s-body-14-22-bold tw-mb-4">Umsatzdetails</div>

                <div class="tw-space-y-2">
                    <div class="tw-grid tw-grid-cols-3">
                        <div class="tw-s-label-12-22-semibold tw-text-scalaraGray-03 tw-col-span-1">Stichwörter</div>
                        <div class="tw-s-body-14-22-regular tw-col-span-2">
                            <span *ngFor="let label of bookingRule?.keywords; let last = last">
                                {{ label }}<span *ngIf="!last">, </span>
                            </span>

                            <span *ngIf="!bookingRule?.keywords || bookingRule?.keywords?.length === 0">–</span>
                        </div>
                    </div>

                    <div class="tw-grid tw-grid-cols-3">
                        <div class="tw-s-label-12-22-semibold tw-text-scalaraGray-03 tw-col-span-1">Betrag</div>
                        <div class="tw-s-body-14-22-regular tw-col-span-2">
                            {{
                                bookingRule && bookingRule.amount && bookingRule.amount > 0
                                    ? (bookingRule.amount | eurocent) + ' €'
                                    : '–'
                            }}
                        </div>
                    </div>

                    <div class="tw-grid tw-grid-cols-3">
                        <div class="tw-s-label-12-22-semibold tw-text-scalaraGray-03 tw-col-span-1">Kontoinhaber</div>
                        <div class="tw-s-body-14-22-regular tw-col-span-2">
                            {{ bookingRule?.counterpartName || '–' }}
                        </div>
                    </div>

                    <div class="tw-grid tw-grid-cols-3">
                        <div class="tw-s-label-12-22-semibold tw-text-scalaraGray-03 tw-col-span-1">IBAN</div>
                        <div class="tw-s-body-14-22-regular tw-col-span-2">
                            {{ bookingRule?.counterpartIban || '–' }}
                        </div>
                    </div>
                </div>
            </div>

            <div bottom-container>
                <div class="tw-s-body-14-22-bold tw-mb-4">Buchungsdetails</div>

                <div class="tw-space-y-2">
                    <div class="tw-grid tw-grid-cols-3">
                        <div class="tw-s-label-12-22-semibold tw-text-scalaraGray-03 tw-col-span-1">
                            Geschäftsvorfall
                        </div>
                        <div class="tw-s-body-14-22-regular tw-col-span-2">
                            {{ getBookingDefinition() }}
                        </div>
                    </div>

                    <div class="tw-grid tw-grid-cols-3">
                        <div class="tw-s-label-12-22-semibold tw-text-scalaraGray-03 tw-col-span-1">
                            Buchhaltungskonto
                        </div>
                        <div class="tw-s-body-14-22-regular tw-col-span-2">
                            {{ bookingRule?.accountToBookIn?.name }}, {{ bookingRule?.accountToBookIn?.description }}
                        </div>
                    </div>

                    <div *ngIf="bookingRule?.labourAmount" class="tw-grid tw-grid-cols-3">
                        <div class="tw-s-label-12-22-semibold tw-text-scalaraGray-03 tw-col-span-1">
                            Lohnanteil an Dienstleistung
                        </div>
                        <div class="tw-s-body-14-22-regular tw-col-span-2">
                            {{ bookingRule?.labourAmount || 0 | eurocent }} €
                        </div>
                    </div>

                    <div *ngIf="bookingRule?.vat" class="tw-grid tw-grid-cols-3">
                        <div class="tw-s-label-12-22-semibold tw-text-scalaraGray-03 tw-col-span-1">Umsatzsteuer</div>
                        <div class="tw-s-body-14-22-regular tw-col-span-2">{{ bookingRule?.vat }} %</div>
                    </div>
                </div>
            </div>
        </app-detail-card>
    </div>

    <div class="tw-flex tw-flex-col" *ngIf="bookingRule?.status !== 'PENDING'; else acceptOrReject">
        <app-custom-dropdown [items]="statusList" (updateSelectedItems)="updateStatus($event)"></app-custom-dropdown>
        <div class="tw-flex tw-justify-between tw-mt-6">
            <div class="tw-s-label-12-22-semibold tw-text-scalaraGray-03">Letzte Ausführung am</div>
            <div class="tw-s-body-14-22-regular tw-text-right">
                {{ bookingRule?.lastUsedAt | dateFormat }}
            </div>
        </div>
        <div class="tw-flex tw-justify-between tw-mt-3">
            <div class="tw-s-label-12-22-semibold tw-text-scalaraGray-03">Bisherige Ausführungen</div>
            <div class="tw-s-body-14-22-regular tw-text-right">{{ bookingRule?.matchesCount }}</div>
        </div>
    </div>

    <ng-template #acceptOrReject>
        <div class="tw-flex tw-flex-col tw-space-y-4 tw-min-w-[252px]">
            <app-scalara-button [fullWidth]="true" height="40px" (click)="updateBookingRuleStatus('ACTIVATED')"
                >Annehmen</app-scalara-button
            >
            <app-scalara-button [fullWidth]="true" variant="ghost" (click)="updateBookingRuleStatus('REJECTED')"
                >Ablehnen</app-scalara-button
            >
        </div>
    </ng-template>
</div>
