import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject, switchMap, takeUntil, tap } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import {
    bankAccountTypeName,
    formControl,
    formControlHasError,
    formatDateYYYYMMDDWithoutHours,
} from 'src/app/core/utils/common';
import {
    BankAccountDto,
    BankAccountsService,
    OperationsExpenseStatementsService,
    SettlementBankAccountInfoDto,
    UpdateOesDto,
} from 'src/app/generated-sources/accounting';

@Component({
    selector: 'app-oes-select-bank-account',
    templateUrl: './oes-select-bank-account.component.html',
    styleUrls: ['./oes-select-bank-account.component.scss'],
})
export class OesSelectBankAccountComponent implements OnInit {
    private unsubscribe$ = new Subject<void>();

    public isLoading = false;
    public bankAccounts: BankAccountDto[] = [];
    public selectedBankAccount?: BankAccountDto;
    public showAddNewBankAccount = false;
    public form: UntypedFormGroup = this.formBuilder.group({
        accountHolderName: [null],
        bankName: [null],
        iban: [null],
        bic: [null],
        outstandingBalanceDeadline: [null, [Validators.required]],
    });
    @Output() public submitAndClose = new EventEmitter<void>();
    @Output() public closePage = new EventEmitter<void>();

    @Input() public ledgerId = '';
    @Input() public oesId = '';
    @Input() public propertyId = '';

    public constructor(
        private bankAccountService: BankAccountsService,
        private formBuilder: UntypedFormBuilder,
        private operationsExpenseStatementsService: OperationsExpenseStatementsService,
        private toastService: ToastService
    ) {}

    public ngOnInit(): void {
        this.getBankAccounts();
    }

    private getBankAccounts(): void {
        this.isLoading = true;
        this.bankAccountService
            .findAll(this.propertyId)
            .pipe(
                switchMap((bankAccounts: BankAccountDto[]) => {
                    this.bankAccounts = bankAccounts;
                    return this.operationsExpenseStatementsService.findOne(this.ledgerId, this.oesId);
                }),
                tap((oes) => {
                    if (!this.isSettlementEmpty(oes.settlementBankAccountInfo)) {
                        const findBankAccount = this.bankAccounts.find(
                            (item) => item.iban === oes.settlementBankAccountInfo.iban
                        );
                        if (findBankAccount) {
                            this.selectedBankAccount = findBankAccount;
                            this.form.patchValue({
                                outstandingBalanceDeadline: [
                                    new Date(oes.settlementBankAccountInfo.outstandingBalanceDeadline),
                                ],
                            });
                        } else {
                            this.form.patchValue({
                                accountHolderName: oes.settlementBankAccountInfo.accountHolderName,
                                bankName: oes.settlementBankAccountInfo.bankName,
                                iban: oes.settlementBankAccountInfo.iban,
                                bic: oes.settlementBankAccountInfo.bic,
                                outstandingBalanceDeadline: [
                                    new Date(oes.settlementBankAccountInfo.outstandingBalanceDeadline),
                                ],
                            });
                            this.showAddNewBankAccount = true;
                            this.setValidators(true);
                        }
                    }
                    this.isLoading = false;
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }

    public submit(): void {
        this.isLoading = true;
        const outstandingBalanceDeadline =
            this.form.value.outstandingBalanceDeadline && this.form.value.outstandingBalanceDeadline.length > 0
                ? formatDateYYYYMMDDWithoutHours(this.form.value.outstandingBalanceDeadline[0])
                : formatDateYYYYMMDDWithoutHours(new Date());
        const updateOesDto: UpdateOesDto = {
            settlementBankAccountInfo: {
                accountHolderName: this.selectedBankAccount?.holderName || this.form.value.accountHolderName || '',
                bankName: this.selectedBankAccount?.bankName || this.form.value.bankName || '',
                iban: this.selectedBankAccount?.iban || this.form.value.iban || '',
                bic: this.selectedBankAccount?.bic || this.form.value.bic || '',
                outstandingBalanceDeadline: outstandingBalanceDeadline,
            },
        };
        this.operationsExpenseStatementsService
            .update(this.ledgerId, this.oesId, updateOesDto)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: () => {
                    this.toastService.showSuccess('Abrechnung erfolgreich bearbeitet.');
                    this.submitAndClose.emit();
                    this.isLoading = false;
                },
                error: (error: Error) => {
                    this.toastService.showError(error.message);
                    this.isLoading = false;
                },
            });
    }

    public isInvalidForm(controlName: string): boolean {
        return formControlHasError(formControl(this.form, controlName), 'required');
    }

    public disabledButton(): boolean {
        if (this.showAddNewBankAccount) {
            return this.form.invalid;
        }
        return this.form.invalid || !this.selectedBankAccount?.id;
    }

    public abort(): void {
        this.closePage.emit();
    }

    public getBankTypeLabel(item: BankAccountDto): string {
        return bankAccountTypeName(item);
    }

    public selectBankAccount(bankAccount: BankAccountDto): void {
        if (!this.showAddNewBankAccount) {
            this.selectedBankAccount = bankAccount;
        }
    }

    public addNewBankAccount(): void {
        this.showAddNewBankAccount = true;
        this.selectedBankAccount = undefined;

        this.setValidators(true);
    }

    public closeNewBankAccount(): void {
        this.showAddNewBankAccount = false;
        this.setValidators(false);
    }

    public setValidators(isBankDataRequired: boolean): void {
        if (isBankDataRequired) {
            this.form.controls['accountHolderName'].setValidators([Validators.required]);
            this.form.controls['bankName'].setValidators([Validators.required]);
            this.form.controls['iban'].setValidators([Validators.required]);
            this.form.controls['bic'].setValidators([Validators.required]);
        } else {
            this.form.controls['accountHolderName'].clearValidators();
            this.form.controls['bankName'].clearValidators();
            this.form.controls['iban'].clearValidators();
            this.form.controls['bic'].clearValidators();
        }

        this.form.controls['accountHolderName'].setErrors(null);
        this.form.controls['accountHolderName'].updateValueAndValidity();

        this.form.controls['bankName'].setErrors(null);
        this.form.controls['bankName'].updateValueAndValidity();

        this.form.controls['iban'].setErrors(null);
        this.form.controls['iban'].updateValueAndValidity();

        this.form.controls['bic'].setErrors(null);
        this.form.controls['bic'].updateValueAndValidity();
    }

    private isSettlementEmpty(settlement: SettlementBankAccountInfoDto): boolean {
        return Object.values(settlement).every((x) => x === null || x === '');
    }
}
