/**
 * SCALARA Base Module
 * The SCALARA API for User, Person and Property resources
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Address } from './address';


export interface Property { 
    id: string;
    name: string;
    address: Address;
    createdAt?: string;
    updatedAt?: string;
    /**
     * This is either null or a base64 encoded jpeg image
     */
    imgFull?: string | null;
    /**
     * This is either null or a base64 encoded jpeg image
     */
    imgSmall?: string | null;
    permissionRoles: Array<Property.PermissionRolesEnum>;
    propertyType: Property.PropertyTypeEnum;
    plotArea?: string;
    district?: string;
    consecutiveNumberOfProperties?: number;
    cadastralDistrict?: string;
    landParcel?: string;
    economicType?: string;
    additionalInformation?: string;
}
export namespace Property {
    export type PermissionRolesEnum = 'PROPERTY_MANAGER' | 'OWNER' | 'SERVICE_PROVIDER' | 'ADVISER' | 'CO_OWNER' | 'TENANT';
    export const PermissionRolesEnum = {
        PropertyManager: 'PROPERTY_MANAGER' as PermissionRolesEnum,
        Owner: 'OWNER' as PermissionRolesEnum,
        ServiceProvider: 'SERVICE_PROVIDER' as PermissionRolesEnum,
        Adviser: 'ADVISER' as PermissionRolesEnum,
        CoOwner: 'CO_OWNER' as PermissionRolesEnum,
        Tenant: 'TENANT' as PermissionRolesEnum
    };
    export type PropertyTypeEnum = 'WEG' | 'SEV' | 'MV' | 'WEG_SEV';
    export const PropertyTypeEnum = {
        Weg: 'WEG' as PropertyTypeEnum,
        Sev: 'SEV' as PropertyTypeEnum,
        Mv: 'MV' as PropertyTypeEnum,
        WegSev: 'WEG_SEV' as PropertyTypeEnum
    };
}


