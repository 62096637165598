<button
    [disabled]="this.disabled"
    class="button button--variant-{{ this.variant }} button--height-{{ this.height }} {{ buttonStyles }}"
    [ngClass]="{
        'button--full-width': this.fullWidth
    }"
    type="{{ this.type }}"
    (click)="onClick()"
>
    <ng-container *ngIf="isLoading">
        <div class="icon-container--loading">
            <ibm-inline-loading> </ibm-inline-loading>
        </div>
    </ng-container>
    <ng-container *ngIf="!isLoading">
        <div *ngIf="this.svgName" [class]="'icon-container ' + iconStyle">
            <svg-icon [src]="'/assets/icons/' + this.svgName"></svg-icon>
        </div>
        <ng-content></ng-content>
    </ng-container>
</button>
