/**
 * SCALARA Accounting Module
 * The SCALARA Accounting API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateReceiptDto { 
    fileStorageId: string;
    accountId?: string;
    type?: CreateReceiptDto.TypeEnum;
}
export namespace CreateReceiptDto {
    export type TypeEnum = 'INVOICE' | 'PERMANENT_INVOICE' | 'RECEIPT' | 'DOCUMENT';
    export const TypeEnum = {
        Invoice: 'INVOICE' as TypeEnum,
        PermanentInvoice: 'PERMANENT_INVOICE' as TypeEnum,
        Receipt: 'RECEIPT' as TypeEnum,
        Document: 'DOCUMENT' as TypeEnum
    };
}


