<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<div ibmGrid class="bx--grid--full-width pb-10r" *ngIf="!this.isLoading">
    <div ibmRow>
        <div ibmCol [columnNumbers]="{ md: 4, lg: 4 }"></div>
        <div ibmCol [columnNumbers]="{ md: 8, lg: 8 }" class="px-00">
            <div ibmRow class="s-head-28-36-bold mb-5r">
                <div class="d-flex align-items-center">
                    <div class="mr-1-5r">
                        <app-icon-bubble [size]="72" [color]="'red'" [image]="'40_delete.svg'"></app-icon-bubble>
                    </div>
                    <div>{{ 'PAGES.DISTRIBUTION_KEYS.DELETE.HEADLINE' | translate }}</div>
                </div>
            </div>

            <div ibmRow class="s-head-16-24-bold mb-2r">
                <span>{{ 'PAGES.DISTRIBUTION_KEYS.DELETE.QUESTION' | translate }}</span>
            </div>

            <div ibmRow class="s-head-14-22-regular mb-1r">
                <span>{{ 'PAGES.DISTRIBUTION_KEYS.DELETE.WARNING_1' | translate }}</span>
            </div>
            <div ibmRow class="s-head-14-22-regular mb-3-5r">
                <span>{{ 'PAGES.DISTRIBUTION_KEYS.DELETE.WARNING_2' | translate }}</span>
            </div>

            <div ibmRow class="mb-7-5r text-info d-flex align-items-center ">
                <span class="s-body-14-20-regular ml-0-75r">{{ distributionKeyDescription }}</span>
            </div>

            <div ibmRow class="d-flex justify-space-between">
                <button [ibmButton]="'tertiary'" type="reset" (click)="abort()">
                    {{ 'COMMON.BTN_CANCEL' | translate }}
                </button>

                <button [ibmButton]="'danger'" (click)="submit()">
                    {{ 'CONTROLS.BUTTONS.OVERLAY_DELETE' | translate }}
                </button>
            </div>
        </div>
        <div ibmCol [columnNumbers]="{ md: 4, lg: 4 }"></div>
    </div>
</div>
