import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject, combineLatest, map, takeUntil } from 'rxjs';
import { LayoutMode, MediaQueriesService } from 'src/app/core/services/media-queries.service.ts.service';

@Component({
    selector: 'app-overlay-container',
    templateUrl: './overlay-container.component.html',
    styleUrls: ['./overlay-container.component.scss'],
})
export class OverlayContainerComponent implements OnInit, OnChanges {
    private unsubscribe$ = new Subject<void>();
    public translatedHeadlineTitle?: string;

    @Input() public headlineTitle = '';
    @Input() public translateHeadlineTitle = true;
    @Input() public colorIcon: 'blue' | 'green' | 'orange' | 'purple' | 'gray' | 'red' | 'no-fill' = 'green';
    @Input() public sizeIcon: 24 | 32 | 40 | 72 = 72;
    @Input() public image = '';
    @Input() public containerSize: 'default' | 'small' | 'large' = 'default';
    @Input() public tooltipText = '';
    @Input() public ignoreHeadline = false;

    public vm?: {
        activeRouteLabel: string;
        currentLayoutMode: LayoutMode;
    };

    public activeRouteLabel$ = new BehaviorSubject<string>('');
    public vm$ = combineLatest([this.activeRouteLabel$, this.mediaQueriesService.getCurrentLayoutMode()]).pipe(
        map(([activeRouteLabel, currentLayoutMode]) => ({
            activeRouteLabel,
            currentLayoutMode,
        }))
    );

    public constructor(private translateService: TranslateService, private mediaQueriesService: MediaQueriesService) {}

    public ngOnInit(): void {
        this.vm$.pipe(takeUntil(this.unsubscribe$)).subscribe((vm) => {
            this.vm = vm;
        });
    }

    public ngOnChanges(): void {
        if (this.tooltipText) {
            this.tooltipText = this.translateService.instant(this.tooltipText);
        }
        if (this.translateHeadlineTitle) {
            this.translatedHeadlineTitle = this.translateService.instant(this.headlineTitle);
        }
    }
}
