import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'eurocent',
})
export class EurocentPipe implements PipeTransform {
    public transform(value: number): string {
        if ((isNaN(value) || !value) && value !== 0) {
            return '–';
        }

        const divided = value / 100;
        return new Intl.NumberFormat('de-DE', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(divided);
    }
}
