import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subject, takeUntil, tap } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { formControl, formControlHasError } from 'src/app/core/utils/common';
import { TooltipKey } from 'src/app/features/account-settings/services/custom-tooltip.service';
import { CreateReserveFundDto, LedgersService } from 'src/app/generated-sources/accounting';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';
import { CellTemplate } from 'src/app/shared/table/enums/cell-template';
import { TableItem } from 'src/app/shared/table/interfaces/table-item';
import { TableModel } from 'src/app/shared/table/interfaces/table-model';

@Component({
    selector: 'app-add-reserve-form',
    templateUrl: './add-reserve-form.component.html',
    styleUrls: ['./add-reserve-form.component.scss'],
})
export class AddReserveFormComponent extends OverlayChildComponent implements OnInit {
    private unsubscribe$ = new Subject<void>();

    private toastSuccessMessage?: string;

    public form: UntypedFormGroup = new UntypedFormGroup({});

    public reserveTableModel: TableModel = { data: [], header: [] };

    public isLoading = false;

    public ledgerId = '';

    public disableButton = false;

    public constructor(
        private formBuilder: UntypedFormBuilder,
        private translateService: TranslateService,
        private ledgersService: LedgersService,
        private toastService: ToastService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.isLoading = true;
        this.ledgerId = this.config?.data.ledgerId;
        this.form = this.formBuilder.group({
            purpose: ['', [Validators.required]],
        });

        this.form.get('purpose')?.valueChanges.subscribe(() => {
            if (!formControlHasError(formControl(this.form, 'purpose'), 'incorrect')) {
                this.disableButton = false;
            }
        });

        this.toastSuccessMessage = this.translateService.instant('ACCOUNTING.ADD_RESERVE.SUCCESS_TOAST');

        this.initTableHeader();
        this.initTableData();
    }

    public onSubmit(): void {
        this.disableButton = true;
        const createReserveFundDto: CreateReserveFundDto = {
            fundName: this.form.get('purpose')!.value,
        };

        this.ledgersService.createReserveFund(this.ledgerId, createReserveFundDto).subscribe({
            next: () => {
                this.toastService.showSuccess(this.toastSuccessMessage);
                this.saveEmitter$.next();
            },
            error: (error: any) => {
                this.form.controls['purpose'].setErrors({ incorrect: true });
            },
        });
    }

    public isInvalid(controlName: string): boolean {
        return (
            formControlHasError(formControl(this.form, controlName), 'required') ||
            formControlHasError(formControl(this.form, controlName), controlName) ||
            formControlHasError(formControl(this.form, controlName), 'incorrect')
        );
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    private initTableHeader(): void {
        const titleKeys = ['ACCOUNTING.ADD_RESERVE.DESCRIPTION', 'ACCOUNTING.ADD_RESERVE.ADDITIONAL_DESCRIPTION', ''];
        this.reserveTableModel.header = titleKeys;
    }

    private initTableData(): void {
        forkJoin([
            this.translateService.get('ACCOUNTING.ADD_RESERVE.TABLE_CONTENT_1'),
            this.translateService.get('ACCOUNTING.ADD_RESERVE.TABLE_CONTENT_2'),
            this.translateService.get('ACCOUNTING.ADD_RESERVE.TABLE_CONTENT_3'),
        ])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((data: string[]) => {
                this.reserveTableModel.data = data.map((i: string) => this.createRow(i));
            });
        this.isLoading = false;
    }

    private createRow(item: string): TableItem[] {
        return [
            {
                data: {
                    label: item,
                },
                template: CellTemplate.Bold,
            },
            {
                data: {
                    label: '–',
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: 'row-selected',
                },
                template: CellTemplate.iconWithStatusItem,
            },
        ];
    }

    public TooltipKey = TooltipKey;
}
