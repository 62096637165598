/**
 * SCALARA Base Module
 * The SCALARA API for User, Person and Property resources
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TechnicalEquipmentFileDto { 
    fileStorageId: string;
    fileCategory: TechnicalEquipmentFileDto.FileCategoryEnum;
}
export namespace TechnicalEquipmentFileDto {
    export type FileCategoryEnum = 'Anleitung' | 'Beschreibung' | 'Fotos' | 'Rahmenvertrag' | 'Technische Unterlagen' | 'Wartungsvertrag';
    export const FileCategoryEnum = {
        Anleitung: 'Anleitung' as FileCategoryEnum,
        Beschreibung: 'Beschreibung' as FileCategoryEnum,
        Fotos: 'Fotos' as FileCategoryEnum,
        Rahmenvertrag: 'Rahmenvertrag' as FileCategoryEnum,
        TechnischeUnterlagen: 'Technische Unterlagen' as FileCategoryEnum,
        Wartungsvertrag: 'Wartungsvertrag' as FileCategoryEnum
    };
}


