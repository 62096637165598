<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<div ibmGrid class="bx--grid--full-width" *ngIf="!isLoading">
    <div ibmRow>
        <div ibmCol></div>
        <div ibmCol [columnNumbers]="{ md: 8, lg: 8 }">
            <div ibmRow class="headline-wrapper mb-3r">
                <div class="mr-06 headline-wrapper__icon">
                    <app-icon-bubble [size]="72" [color]="'blue'" [image]="'40_document_edit.svg'"></app-icon-bubble>
                </div>
                <div class="headline-wrapper__title">
                    <div class="s-head-28-36-bold">
                        {{ 'PAGES.ECONOMIC_PLAN.ADJUST_ADVANCES.HEADLINE' | translate }}
                    </div>
                </div>
            </div>
        </div>
        <div ibmCol></div>
    </div>

    <div ibmRow>
        <div ibmCol></div>
        <div ibmCol [columnNumbers]="{ md: 8, lg: 8 }">
            <div ibmRow>
                <div ibmCol>
                    <div class="s-body-14-22-regular mb-4r">
                        {{ 'PAGES.ECONOMIC_PLAN.ADJUST_ADVANCES.SUBHEADER' | translate }}
                    </div>
                </div>
            </div>

            <div
                ibmRow
                class="s-label-12-22-semibold d-flex align-items-center justify-space-between align-items-center mb-1-5r"
            >
                <div ibmCol>{{ 'PAGES.ECONOMIC_PLAN.ADJUST_ADVANCES.UNIT' | translate }}</div>
                <div ibmCol class="text-right mr-4r">
                    {{ 'PAGES.ECONOMIC_PLAN.ADJUST_ADVANCES.CALCULATED_TOTAL' | translate }}
                </div>
                <div class="text-right" ibmCol>
                    {{ 'PAGES.ECONOMIC_PLAN.ADJUST_ADVANCES.ADJUSTMENT_TOTAL' | translate }}
                </div>
            </div>
            <form class="mb-6r" (submit)="onSubmit()" (keydown.enter)="$event.preventDefault()" [formGroup]="form">
                <ng-container formArrayName="advancement">
                    <ng-container *ngFor="let item of advancement.controls; let i = index">
                        <div
                            ibmRow
                            [formGroup]="getAdvancementItemForm(i)"
                            class="d-flex align-items-center justify-space-between align-items-center mb-1-5r"
                        >
                            <div ibmCol>{{ item.value.ownershiName }}</div>
                            <div ibmCol class="text-right mr-4r">
                                {{ item.value.calculatedAdvancement }}
                            </div>
                            <div ibmCol>
                                <app-number-input
                                    [remainClearOnBlur]="true"
                                    [allowNegatives]="false"
                                    formControlName="differingAdvancement"
                                ></app-number-input>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </form>

            <div ibmRow class="mb-7-5r">
                <div ibmCol>
                    <app-notification type="info"
                        >{{ 'PAGES.ECONOMIC_PLAN.ADJUST_ADVANCES.INFO_BOX' | translate }}
                    </app-notification>
                </div>
            </div>

            <div ibmRow class="mb-5r">
                <div ibmCol>
                    <button [ibmButton]="'tertiary'" type="reset" (click)="abort()">
                        {{ 'CONTROLS.BUTTONS.BACK' | translate }}
                    </button>
                </div>
                <div ibmCol class="text-right">
                    <button [ibmButton]="'primary'" type="submit" (click)="onSubmit()">
                        {{ 'COMMON.BTN_SAVE' | translate }}
                    </button>
                </div>
            </div>
        </div>
        <div ibmCol></div>
    </div>
</div>
