import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, finalize, takeUntil, tap } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { Person } from 'src/app/generated-sources/base';
import { OperationsService } from 'src/app/generated-sources/base/api/operations.service';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { CellTemplate } from 'src/app/shared/table/enums/cell-template';
import { TableItem } from 'src/app/shared/table/interfaces/table-item';
import { TableModel } from 'src/app/shared/table/interfaces/table-model';

@Component({
    selector: 'app-surrogate',
    templateUrl: './surrogate.component.html',
    styleUrls: ['./surrogate.component.scss'],
})
export class SurrogateComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();

    public tableModel: TableModel = { data: [], header: [] };
    public isLoading = false;
    public personsTable?: Person[];
    public imgSmall = '';

    public constructor(
        private overlayService: OverlayService,
        private translateService: TranslateService,
        private operationsService: OperationsService,
        private toast: ToastService,
        private router: Router,
        private authService: AuthService
    ) {}

    public ngOnInit(): void {
        this.isLoading = true;
        this.initTableHeader();

        this.operationsService
            .findAll()
            .pipe(
                tap((persons: Person[]) => (this.tableModel.data = persons.map((person) => this.createRow(person)))),
                finalize(() => (this.isLoading = false)),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    private initTableHeader(): void {
        this.tableModel.header = ['ID', 'Firmenname / Name', 'Email-Adresse der Person', 'Account-Typ', 'Adresse'];
    }

    public resetSurrogateToHome(): void {
        this.operationsService.sendSurrogateHome().subscribe((result) => {
            if (result) {
                this.toast.showSuccess('Vertretung zurückgesetzt ');
                this.authService.signOut();
                this.router.navigate(['/sign-in']);
            } else {
                this.toast.showError('Fehler beim Zurücksetzen');
            }
        });
    }

    private createRow(data: Person): TableItem[] {
        const link = `${data.id}`;
        return [
            {
                data: {
                    label: `${data.id}`,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: `${data.companyName} / ${data.firstName} ${data.lastName}`,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: `${data.email}`,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: data.type,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: `${data.address?.streetName} ${data.address?.streetNumber}, ${data.address?.zipCode}, ${data.address?.area}`,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: '',
                    link,
                },
                template: CellTemplate.editButton,
            },
        ];
    }
}
