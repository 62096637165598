<ibm-loading *ngIf="isInitialLoading" [isActive]="isInitialLoading" [size]="'normal'" [overlay]="isInitialLoading">
</ibm-loading>

<div ibmRow class="mb-5r">
    <div ibmCol [columnNumbers]="{ md: 3, lg: 3, xl: 3 }">
        <div class="s-head-28-36-bold">{{ 'PAGES.RECEIPTS.HEADLINE' | translate }}</div>
    </div>
    <div ibmCol>
        <div class="d-flex justify-content-end">
            <div class="receipts-autocomplete mr-1r">
                <app-combo-box
                    [size]="'md'"
                    id="distributionBase"
                    [appendInline]="true"
                    [items]="accountList"
                    (selected)="onSelectAccount($event)"
                    placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT' | translate }}"
                >
                    <ibm-dropdown-list></ibm-dropdown-list>
                </app-combo-box>
            </div>
            <app-accounting-date-picker-selector></app-accounting-date-picker-selector>
            <button ibmButton="primary" class="button-primary-40 ml-1r" (click)="openAddReceiptOverlay()">
                <svg-icon src="/assets/icons/24_add.svg"></svg-icon>
                {{ 'PAGES.RECEIPTS.BUTTON_ADD_RECEIPT' | translate }}
            </button>
        </div>
    </div>
</div>

<ibm-tabs [followFocus]="true" [cacheActive]="true">
    <ibm-tab heading=" {{ 'COMMON.LABEL_NOT_LINKED' | translate }}">
        <div ibmRow class="mt-1r mb-1r d-flex justify-content-end">
            <app-search [searchInputId]="'notLinkedReceiptsTable'"></app-search>
        </div>
        <div ibmRow>
            <div ibmCol>
                <app-table
                    [emptyText]="'PAGES.RECEIPTS.EMPTY_TABLE'"
                    [header]="tableModel.header"
                    [data]="dataNotLinkedReceipts"
                    (handleFile)="handleReceipt($event)"
                    [tableSearchId]="'notLinkedReceiptsTable'"
                    [isLoading]="!isInitialLoading && isLoading"
                >
                </app-table>
            </div>
        </div>
    </ibm-tab>
    <ibm-tab heading=" {{ 'COMMON.LABEL_ALL' | translate }}" (selected)="onAllReceiptsTabSelected()">
        <div ibmRow class="mt-1r mb-1r d-flex justify-content-end">
            <app-search [searchInputId]="'receiptsOverviewTable'"></app-search>
        </div>
        <div ibmRow>
            <div ibmCol>
                <app-table
                    [emptyText]="'PAGES.RECEIPTS.EMPTY_TABLE'"
                    [header]="tableModel.header"
                    [data]="tableModel.data"
                    (handleFile)="handleReceipt($event)"
                    [tableSearchId]="'receiptsOverviewTable'"
                    [isLoading]="!isInitialLoading && isLoading"
                >
                </app-table>
            </div>
        </div>
    </ibm-tab>
</ibm-tabs>

<ng-container *ngIf="fileToView && showModal">
    <app-file-viewer [fileToView]="fileToView" (closeViewer)="handleModal()"></app-file-viewer>
</ng-container>

<ng-template #receiptExpandedTemplate let-dataTable="data">
    <div>
        <div ibmRow *ngIf="dataTable['isLoading']" class="pos-relative">
            <div class="is-loading is-loading--bg-transparent">
                <div class="d-flex align-items-center justify-content-center mt-4r">
                    <ibm-inline-loading> </ibm-inline-loading>
                </div>
            </div>
        </div>
        <div class="d-flex align-items-center mt-08 mb-05">
            <div class="s-gray-01 s-body-14-22-semibold ml-03">
                {{ 'PAGES.RECEIPTS.LINKED_BOOKINGS_TITLE' | translate }}
            </div>
        </div>

        <div ibmRow class="custom-simple-table mb-11">
            <table>
                <tr class="s-gray-03 s-label-12-22-semibold">
                    <th>{{ 'PAGES.RECEIPTS.LABEL_DESCRIPTION' | translate }}</th>
                    <th>{{ 'ENTITIES.BOOKING.LABEL_BOOKING_DATE' | translate }}</th>
                    <th class="custom-simple-table__text-right">
                        {{ 'ENTITIES.BOOKING.LABEL_AMOUNT_TABLE' | translate }}
                    </th>
                </tr>
                <tr
                    [routerLink]="'/accounting/ledger/' + ledgerId + '/bookings/' + booking.id"
                    *ngFor="let booking of dataTable['bookings']"
                    class="s-body-14-22-regular link-row"
                >
                    <td>
                        {{ booking.description }}
                    </td>
                    <td>
                        {{ booking.bookingDate | dateFormat }}
                    </td>
                    <td class="custom-simple-table__text-right">
                        {{ booking.amount | eurocent }}
                    </td>
                </tr>
                <div
                    class="d-flex align-items-center table-empty-text s-body-14-22-regular"
                    *ngIf="dataTable['bookings'] && dataTable['bookings'].length < 1"
                >
                    <svg-icon src="/assets/icons/24_information.svg"></svg-icon>
                    {{ 'PAGES.RECEIPTS.NOT_LINKED_TABLE' | translate }}
                </div>
            </table>
        </div>
    </div>
</ng-template>
