import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { SharedOwnership } from 'src/app/generated-sources/base';
import { TableComponent } from 'src/app/shared/table/components/table/table.component';
import { TableModel } from 'src/app/shared/table/interfaces/table-model';

export interface SharedOwnershipCallbacks {
    addOverlay: () => void;
    detailsOverlay: (sharedOwnership: SharedOwnership) => void;
}

@Component({
    selector: 'app-shared-ownership-index',
    templateUrl: './shared-ownership-index.component.html',
    styleUrls: ['./shared-ownership-index.component.scss'],
})
export class SharedOwnershipIndexComponent implements OnInit, OnDestroy {
    @Input() public tableModel: TableModel = { data: [], header: [] };
    @Input() public sharedOwnershipCallbacks?: SharedOwnershipCallbacks;
    @Input() public isPropertyManager = false;

    @ViewChild(TableComponent)
    public tableComponent?: TableComponent;

    private unsubscribe$ = new Subject<void>();

    public constructor() {}

    public ngOnInit(): void {}

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public openAddEditCommonOwnershipOverlay(): void {
        this.sharedOwnershipCallbacks?.addOverlay();
    }

    public openCommonOwnershipDetailsOverlay(event: any): void {
        const clickedRow = event !== null ? this.tableComponent?.model.row(event) : undefined;

        this.sharedOwnershipCallbacks?.detailsOverlay(
            clickedRow ? clickedRow[0]?.data?.extraData?.sharedOwnership : null
        );
    }
}
