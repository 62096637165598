import { Component, ElementRef, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
    BehaviorSubject,
    Subject,
    combineLatest,
    filter,
    finalize,
    firstValueFrom,
    of,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { PropertyTypeInfosInterface, getPropertyTypeInfos } from 'src/app/core/utils/common';
import { TooltipKey } from 'src/app/features/account-settings/services/custom-tooltip.service';
import {
    Building,
    BuildingsService,
    Person,
    PropertiesService,
    Property,
    SharedOwnership,
    SharedOwnershipService,
} from 'src/app/generated-sources/base';
import {
    RemoveEntityOverlayComponent,
    RemoveEntityOverlayComponentConfig,
} from 'src/app/shared/components/remove-entity-overlay/remove-entity-overlay.component';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { CellTemplate } from 'src/app/shared/table/enums/cell-template';
import { TableItem } from 'src/app/shared/table/interfaces/table-item';
import { TableModel } from 'src/app/shared/table/interfaces/table-model';
import { PropertyCustomService } from '../../../services/property-custom.service';
import { AddEditPropertyFormComponent } from '../../add-edit-property-form/add-edit-property-form.component';
import { DocumentsFlow } from '../../documents/add-document-overlay/add-document-overlay.component';
import { AddEditBuildingOverlayComponent } from '../building/add-edit-building-overlay/add-edit-building-overlay.component';
import { AddEditSharedOwnershipOverlayComponent } from '../shared-ownership/add-edit-shared-ownership-overlay/add-edit-shared-ownership-overlay.component';
import { SharedOwnershipDetailsComponent } from '../shared-ownership/shared-ownership-details/shared-ownership-details.component';
import { SharedOwnershipCallbacks } from '../shared-ownership/shared-ownership-index/shared-ownership-index.component';

@Component({
    selector: 'app-property-base-data-index',
    templateUrl: './property-base-data-index.component.html',
    styleUrls: ['./property-base-data-index.component.scss'],
})
export class PropertyBaseDataIndexComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();
    private refresh$ = new Subject<void>();
    private refreshSharedOwnership$ = new BehaviorSubject<void>(undefined);

    public id?: string;
    public isLoading = false;
    public property?: Property;
    public person?: Person;
    public buildings: Building[] = [];

    public isPropertyManager = false;
    public isTenant = false;
    public isServiceProvider = false;

    public isImageMenuOpen = false;

    public sharedOwnershipTable: TableModel = { data: [], header: [] };
    public sharedOwnershipIdList?: string[] = []; // To send to the overlay to do the pagination

    @ViewChild('overlayMenu')
    public overlayMenu?: ElementRef;
    @ViewChild('overlayMenuCloseButton')
    public overlayMenuCloseButton?: ElementRef;
    @ViewChild('overlayMenuOpenButton')
    public overlayMenuOpenButton?: ElementRef;
    @ViewChild('buildingComponent', { static: true })
    public buildingComponent?: TemplateRef<any>;

    public sharedOwnershipCallbacks: SharedOwnershipCallbacks = {
        addOverlay: () => {
            this.openAddEditSharedOwnershipOverlay();
        },
        detailsOverlay: (sharedOwnership) => {
            if (sharedOwnership) {
                this.openSharedOwnershipDetailsOverlay(sharedOwnership);
            }
        },
    };

    public propertyId$ = this.propertyCustomService.getPropertyId$().pipe(filter(Boolean));

    public propertyOverview$ = this.propertyId$.pipe(
        switchMap((propertyId) => {
            if (!propertyId) {
                return of();
            }
            return this.propertiesService.getPropertySummary(propertyId);
        })
    );

    public findSharedOwnership$ = combineLatest([this.propertyId$, this.refreshSharedOwnership$]).pipe(
        switchMap(([propertyId]) => {
            return this.sharedOwnershipService.getSharedOwnershipForProperty(propertyId);
        }),
        tap((sharedOwnership) => {
            this.sharedOwnershipIdList = sharedOwnership.map((item) => item.id);
            this.sharedOwnershipTable.header = [
                'ENTITIES.SHARED_OWNERSHIP.LABEL_CATEGORY',
                'ENTITIES.PROPERTY.LABEL_NAME',
                'ENTITIES.OWNERSHIP.LABEL_BUILDING',
                'ENTITIES.OWNERSHIP.LABEL_ENTITY',
            ];

            this.sharedOwnershipTable.data = sharedOwnership.map((item) => this.createSharedOwnershipTableRow(item));
        })
    );

    public constructor(
        private propertiesService: PropertiesService,
        public route: ActivatedRoute,
        public router: Router,
        private overlayService: OverlayService,
        private toastService: ToastService,
        private translateService: TranslateService,
        private buildingsService: BuildingsService,
        private propertyCustomService: PropertyCustomService,
        private sharedOwnershipService: SharedOwnershipService
    ) {}

    @HostListener('document:click', ['$event.target'])
    public onClick(target: any): void {
        const clickedInside = this.overlayMenu?.nativeElement.contains(target);
        if (this.isImageMenuOpen && (!clickedInside || this.overlayMenuCloseButton?.nativeElement.contains(target))) {
            this.changeImageMenuDisplay();
        }
        if (!this.isImageMenuOpen && this.overlayMenuOpenButton?.nativeElement.contains(target)) {
            this.changeImageMenuDisplay();
        }
    }

    public ngOnInit(): void {
        this.isLoading = true;
        this.refresh$
            .pipe(
                switchMap(() => {
                    return this.propertyCustomService.getProperty$();
                }),
                tap((property: Property | null) => {
                    if (property) {
                        this.id = property.id;
                        this.property = property;
                        this.isPropertyManager = property.permissionRoles
                            ? Object.values(property.permissionRoles).includes(
                                  Person.PermissionRolesEnum.PropertyManager
                              )
                            : false;

                        this.isTenant = property.permissionRoles
                            ? Object.values(property.permissionRoles).includes(Person.PermissionRolesEnum.Tenant)
                            : false;

                        this.isServiceProvider = property.permissionRoles
                            ? Object.values(property.permissionRoles).includes(
                                  Person.PermissionRolesEnum.ServiceProvider
                              )
                            : false;
                    }
                }),
                switchMap((property: Property | null) => {
                    return this.buildingsService.findAll(property?.id || '');
                }),
                tap((buildings) => {
                    this.buildings = buildings.sort(this.sortBuildings);
                }),
                tap(() => (this.isLoading = false)),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
        this.refresh$.next();
        this.findSharedOwnership$.subscribe();
    }

    public sortBuildings(a: Building, b: Building): -1 | 0 | 1 {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
        }
        return 0;
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public removeProperty(): void {
        const data: RemoveEntityOverlayComponentConfig = {
            data: {
                entityName: this.property?.name ?? '',
                entityType: 'PROPERTY',
                deleteEntityCallback: async () => {
                    await firstValueFrom(this.propertiesService.remove(this.property?.id || ''));
                },
            },
        };
        const ref = this.overlayService.open(RemoveEntityOverlayComponent, data);
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            ref.close();
        });

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.propertyCustomService.refresh();
            this.router.navigate(['/properties'], { relativeTo: this.route });
        });
    }

    public editDetails(): void {
        this.openOverlay('editDetails');
    }

    public editImage(): void {
        this.openOverlay('editImage');
    }

    public uploadImage(): void {
        this.openOverlay('uploadImage');
    }

    public openOverlay(editingMode: string): void {
        const ref = this.overlayService.open(AddEditPropertyFormComponent, {
            data: { propertyId: this.property?.id, editingMode: editingMode },
        });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            ref.close();
        });

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.propertyCustomService.refresh();
        });
        this.changeImageMenuDisplay();
    }

    public removeImage(): void {
        let successRemovedMessage = '';
        if (this.property?.id) {
            this.propertiesService
                .update(this.property.id, { imgFull: '', propertyType: this.property.propertyType })
                .pipe(
                    switchMap(() => this.translateService.get('PROPERTY.DETAIL.TOAST_SUCCESS_MESSAGE_FOTO_REMOVE')),
                    tap((successMessage) => (successRemovedMessage = successMessage)),
                    finalize(() => (this.isLoading = false)),
                    takeUntil(this.unsubscribe$)
                )
                .subscribe(() => {
                    this.changeImageMenuDisplay();
                    this.toastService.showSuccess(successRemovedMessage);
                    this.propertyCustomService.refresh();
                });
        }
    }

    public changeImageMenuDisplay(): void {
        this.isImageMenuOpen = !this.isImageMenuOpen;
    }

    public openEditBuildingOverlay(): void {
        const ref = this.overlayService.open(AddEditBuildingOverlayComponent, {
            data: { propertyId: this.property?.id, property: this.property },
        });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            ref.close();
        });

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.propertyCustomService.refresh();
        });
    }

    public propertyInfos(): PropertyTypeInfosInterface | undefined {
        return this.property ? getPropertyTypeInfos(this.property?.propertyType) : undefined;
    }

    public tooltipKey = TooltipKey.tooltipsInProperties;

    public tooltipInfo: { tooltipKey: TooltipKey; tooltipValue: string } = {
        tooltipKey: this.tooltipKey,
        tooltipValue: this.translateService.instant('PAGES.TOOLTIPS.PROPERTY_BASE_DATA.DOCUMENTS'),
    };

    public DocumentsFlow = DocumentsFlow;

    public updateAdditionalInformation(event: any): void {
        if (!this.property) {
            return;
        }
        this.propertiesService
            .update(this.property?.id, { additionalInformation: event ?? '' })
            .subscribe(() => this.propertyCustomService.refresh());
    }

    public navigateToBuildingDetails(buldingId: string): void {
        if (this.isPropertyManager) {
            this.router.navigate(['/properties', this.property?.id, 'building-details', buldingId]);
        }
    }

    public openAddEditSharedOwnershipOverlay(): void {
        const ref = this.overlayService.open(AddEditSharedOwnershipOverlayComponent, {});

        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.refreshSharedOwnership$.next());
    }

    public openSharedOwnershipDetailsOverlay(sharedOwnership: SharedOwnership): void {
        const ref = this.overlayService.open(SharedOwnershipDetailsComponent, {
            data: {
                id: sharedOwnership.id,
                propertyId: this.property?.id,
                buildingId: sharedOwnership.buildingInfo.id,
                sharedOwnerhsipIdList: this.sharedOwnershipIdList,
            },
            overlayType: 'half-page',
        });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.refreshSharedOwnership$.next();
            ref.close();
        });

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.refreshSharedOwnership$.next();
        });
    }

    private createSharedOwnershipTableRow(item: SharedOwnership): TableItem[] {
        return [
            {
                data: {
                    label: item.category ?? '',
                    extraData: { sharedOwnership: item },
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: item.title ?? '',
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: item.buildingInfo?.name ?? '',
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: item.ownershipInfo?.name ?? '',
                },
                template: CellTemplate.Default,
            },
        ];
    }
}
