import { ExtendedSubscriptionDto } from 'src/app/core/guards/subscriptions.guard';

const BETA_TEST_MAX_OWNERSHIPS = 9999;

export const getCurrentAboType = (
    currentSubscription: ExtendedSubscriptionDto | null
): 'basic' | 'paid' | 'family' | 'beta' | 'none' => {
    if (currentSubscription === null) {
        return 'none';
    }
    if (currentSubscription.maxOwnerships === 0) {
        return 'basic';
    }
    if (currentSubscription.maxOwnerships === BETA_TEST_MAX_OWNERSHIPS) {
        return 'beta';
    }
    if (currentSubscription.maxOwnerships > BETA_TEST_MAX_OWNERSHIPS) {
        return 'family';
    }
    return 'paid';
};
