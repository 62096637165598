<ibm-loading *ngIf="isInitialLoading" [isActive]="isInitialLoading" [size]="'normal'" [overlay]="isInitialLoading">
</ibm-loading>
<div class="py-2r px-2-5r lg-d-block d-none">
    <div class="d-flex justify-space-between w-100 mb-2-5r">
        <div class="s-head-28-36-bold">{{ 'ENTITIES.TICKET.LABEL_ENTITY' | translate }}</div>
        <app-scalara-button (click)="openFormOverlay()" variant="default" svgName="24_add.svg">
            {{ 'PAGES.TICKET_OVERVIEW.ADD_TICKET_BUTTON' | translate }}
        </app-scalara-button>
    </div>

    <div>
        <ibm-tabs [followFocus]="true" [cacheActive]="true">
            <ibm-tab heading="{{ 'ENTITIES.TICKET.OPEN' | translate }}">
                <div class="mt-1r mb-0-75r">
                    <div class="mb-1r d-flex justify-content-end">
                        <app-search [searchInputId]="'open'"></app-search>
                    </div>
                    <app-table
                        [emptyText]="'PAGES.TICKET_OVERVIEW.EMPTY_TABLE'"
                        [header]="openTicketsTable.header"
                        [data]="openTicketsTable.data"
                        [tableSearchId]="'open'"
                        [isLoading]="!isInitialLoading && isLoading"
                    >
                    </app-table>
                </div>
            </ibm-tab>
            <ibm-tab
                heading="{{ 'ENTITIES.TICKET.IN_PROGRESS' | translate }}"
                (selected)="onTabSelected('IN_PROGRESS')"
            >
                <div class="mt-1r mb-0-75r">
                    <div class="mb-1r d-flex justify-content-end">
                        <app-search [searchInputId]="'in_progress'"></app-search>
                    </div>
                    <app-table
                        [emptyText]="'PAGES.TICKET_OVERVIEW.EMPTY_TABLE'"
                        [header]="ticketsInProgressTable.header"
                        [data]="ticketsInProgressTable.data"
                        [tableSearchId]="'in_progress'"
                        [isLoading]="!isInitialLoading && isLoading"
                    >
                    </app-table>
                </div>
            </ibm-tab>
            <ibm-tab heading="{{ 'ENTITIES.TICKET.CLOSED' | translate }}" (selected)="onTabSelected('CLOSED')">
                <div class="mt-1r mb-0-75r">
                    <div class="mb-1r d-flex justify-content-end">
                        <app-search [searchInputId]="'closed'"></app-search>
                    </div>
                    <app-table
                        [emptyText]="'PAGES.TICKET_OVERVIEW.EMPTY_TABLE'"
                        [header]="closedTicketsTable.header"
                        [data]="closedTicketsTable.data"
                        [tableSearchId]="'closed'"
                        [isLoading]="!isInitialLoading && isLoading"
                    >
                    </app-table>
                </div>
            </ibm-tab>
            <ibm-tab heading="{{ 'ENTITIES.TICKET.ALL' | translate }}" (selected)="onTabSelected('ALL')">
                <div class="mt-1r mb-0-75r">
                    <div class="mb-1r d-flex justify-content-end">
                        <app-search [searchInputId]="'all'"></app-search>
                    </div>
                    <app-table
                        [emptyText]="'PAGES.TICKET_OVERVIEW.EMPTY_TABLE'"
                        [header]="tableModel.header"
                        [data]="tableModel.data"
                        [tableSearchId]="'all'"
                        [isLoading]="!isInitialLoading && isLoading"
                    >
                    </app-table>
                </div>
            </ibm-tab>
        </ibm-tabs>
    </div>
</div>

<!-- MOBILE VERSION -->
<div class="py-2r px-1-25r container lg-d-none">
    <div class="w-100 d-flex align-items-center">
        <div class="w-100 mr-1-5r">
            <app-combo-box
                [size]="'md'"
                id="distributionBase"
                [appendInline]="true"
                label=""
                [items]="statusList"
                invalidText="{{ 'ACCOUNTING.ERROR.INVALID_AMOUNT' | translate }}"
                placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT' | translate }}"
                (selected)="onSelectStatus($event)"
            >
                <ibm-dropdown-list></ibm-dropdown-list>
            </app-combo-box>
        </div>
        <app-scalara-button
            (click)="openFormOverlay()"
            variant="default"
            svgName="24_add.svg"
            height="40px"
            class="button-mobile"
        ></app-scalara-button>
    </div>

    <hr class="mt-2r mb-1-5r" />

    <ng-container *ngIf="ticketsFiltered.length > 0; else empty">
        <ng-container *ngFor="let ticket of ticketsFiltered">
            <a [href]="'/communications/' + ticket.id" class="">
                <div class="s-head-16-24-bold mb-0-5r">{{ ticket.title }}</div>
                <div class="s-body-14-22-regular mb-0-5r">
                    {{ ticket.property.name }}
                </div>
                <div class="s-body-14-22-regular mb-0-5r">{{ ticket.owner.firstName }} {{ ticket.owner.lastName }}</div>
                <div class="s-body-14-22-regular mb-1r">
                    {{ ticket.createdAt }}
                </div>

                <div class="d-flex justify-space-between align-items-center">
                    <app-ticket-detail-icons
                        [showBadge]="false"
                        [amountComments]="ticket.numberOfComments"
                        [amountFiles]="ticket.numberOfAttachments"
                    ></app-ticket-detail-icons>
                    <app-status-with-icon
                        [textColor]="ticket.iconColor"
                        [label]="ticket.statusLabel"
                        [iconSrc]="ticket.iconSrc"
                        [showNewTag]="false"
                    ></app-status-with-icon>
                </div>
            </a>

            <hr class="mt-1-5r mb-1-5r" />
        </ng-container>
    </ng-container>

    <ng-template #empty>
        <div class="d-flex align-items-center empty-label">
            <svg-icon class="formated-icon mr-1r" src="/assets/icons/24_information.svg"></svg-icon>
            <label class="s-body-14-22-regular">{{ 'PAGES.TICKET_OVERVIEW.EMPTY_TABLE' | translate }}</label>
        </div>
    </ng-template>
</div>
<!-- END MOBILE VERSION -->

<!-- custom templates  -->
<ng-template #commentsInfos let-data="data">
    <app-ticket-detail-icons
        [showBadge]="data.extraData?.unreadComment"
        [amountComments]="data.extraData?.numberOfComments"
        [amountFiles]="data.extraData?.numberOfAttachments"
    ></app-ticket-detail-icons>
</ng-template>
