import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Person } from 'src/app/generated-sources/base';

@Component({
    selector: 'app-headline-with-icon',
    templateUrl: './headline-with-icon.component.html',
    styleUrls: ['./headline-with-icon.component.scss'],
})
export class HeadlineWithIconComponent implements OnInit {
    public template = '';
    public pixelSize = 40;
    public distance = 'pl-1r';
    public textFormat = 's-head-14-22-bold';
    // m (default) = 40px Variant L = 72px Variant s = 32px variant
    @Input() public size = 'm';
    // filename! (not path) of the icon to be used
    @Input() public image = '40_error.svg';
    // currently gray (default), orange, blue, green
    @Input() public color = 'gray';

    // currently gray (default), green
    @Input() public borderColor = 'none';
    //The Text to display
    @Input() public text = '';
    @Input() public withLabel = false;
    @Input() public person?: Person;
    @Input() public textType: 'head' | 'body' = 'head';
    @Input() public textWeight: 'bold' | 'regular' = 'bold';
    @Input() public textClass = '';
    @Input() public userAvatar?: string;

    public constructor(private sanitizer: DomSanitizer) {}

    public ngOnInit(): void {
        this.calculateSize();
        this.sanitizer.bypassSecurityTrustHtml('');
    }

    private calculateSize(): void {
        switch (this.size) {
            case 's':
                this.pixelSize = 24;
                break;
            case 'm':
                this.pixelSize = 40;
                this.distance = 'pl-1r';
                this.textFormat = `s-${this.textType}-14-22-${this.textWeight}`;
                break;
            case 'ml':
                this.pixelSize = 72;
                this.distance = 'pl-1r';
                this.textFormat = `s-${this.textType}-16-24-${this.textWeight}`;
                break;
            case 'l':
                this.pixelSize = 72;
                this.distance = 'pl-1-5r';
                this.textFormat = `s-${this.textType}-18-26-${this.textWeight}`;
                break;
            case 'xl':
                this.pixelSize = 72;
                this.distance = 'pl-1-5r';
                this.textFormat = `s-${this.textType}-28-36-${this.textWeight}`;
                break;
            case 'xxl':
                this.pixelSize = 160;
                this.distance = 'pl-3r';
                this.textFormat = `s-${this.textType}-28-36-${this.textWeight}`;
                break;
            default:
                this.pixelSize = 40;
                this.distance = 'pl-1r';
                this.textFormat = `s-${this.textType}-14-22-${this.textWeight}`;
        }
    }
}
