import { Component, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { formControl, formControlHasError } from 'src/app/core/utils/common';
import { UserManagementService } from 'src/app/generated-sources/base';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';

@Component({
    selector: 'app-add-user',
    templateUrl: './add-user.component.html',
    styleUrls: ['./add-user.component.scss'],
})
export class AddUserComponent extends OverlayChildComponent implements OnDestroy {
    private unsubscribe$ = new Subject<void>();
    public form: UntypedFormGroup = this.formBuilder.group({
        name: [null, [Validators.required]],
        email: [null, [Validators.required]],
    });

    public constructor(
        private translateService: TranslateService,
        private toastService: ToastService,
        private formBuilder: UntypedFormBuilder,
        public userManagementService: UserManagementService
    ) {
        super();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public submit(): void {
        this.userManagementService
            .createManagedUser({ name: this.form.value.name, email: this.form.value.email })
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: () => {
                    this.toastService.showSuccess(this.translateService.instant('PAGES.ADD_USER.SUCCESS_TOAST'));
                    this.saveEmitter$.next();
                },
                error: (error) => {
                    if (error) {
                        this.toastService.showError(error.error['message']);
                    }
                },
            });
    }

    public isInvalidForm(controlName: string): boolean {
        return formControlHasError(formControl(this.form, controlName), 'required');
    }
}
