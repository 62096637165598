<div class="subscription-row {{ this.class }}" *ngIf="this.subscription">
    <div class="colored-wrapper mr-1-5r colored-wrapper--{{ this.getColoredWrapperColor() }}">
        {{ this.getSubscriptionLabel() }}
    </div>
    <div
        class="d-flex align-items-center justify-space-between flex-grow"
        *ngIf="
            this.isShowingCurrentSubscription && this.currentSubscription?.status === 'canceled';
            else showUsualSubscriptionRow
        "
    >
        <div class="d-flex align-items-center s-red-01">
            <div *ngIf="currentSubscription && currentSubscription?.cancellationDate">
                {{ 'PAGES.ABO.UP_TO' | translate }} {{ formatDate(currentSubscription.cancellationDate) }}
            </div>
        </div>
        <div class="pr-1r s-red-01">{{ 'PAGES.ABO.CANCEL.CANCELLED' | translate }}</div>
    </div>
</div>

<ng-template #showUsualSubscriptionRow>
    <div class="d-flex align-items-center justify-space-between flex-grow">
        <div class="d-flex align-items-center">
            <div *ngIf="this.showDetails" class="s-head-18-26-bold mr-1r">{{ subscription?.price }}</div>
            <div *ngIf="this.showDetails" class="text-grey s-label-12-16-regular mr-1r">
                {{ subscription?.additionalInformation }}
            </div>
            <div *ngIf="this.showIcon" class="icon-container">
                <svg-icon src="/assets/icons/24_checkbox-check-green.svg"></svg-icon>
            </div>
        </div>
        <div *ngIf="this.showDetails && getButtonText() as buttonText">
            <app-scalara-button (click)="this.buttonCallback()" variant="ghost"> {{ buttonText }} </app-scalara-button>
        </div>
    </div>
</ng-template>
