import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbItem } from 'carbon-components-angular';
import { Observable, Subject, combineLatest, of, switchMap, take, takeUntil, tap } from 'rxjs';
import { BreadCrumbService } from 'src/app/core/services/breadcrumb.service';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { CellTemplate } from 'src/app/shared/table/enums/cell-template';
import { HeaderTemplate } from 'src/app/shared/table/enums/header-template';
import { TableItem } from 'src/app/shared/table/interfaces/table-item';
import { TableModel } from 'src/app/shared/table/interfaces/table-model';
import {
    GetStatus,
    accountTypeNameFromEnum,
    getColorOfBookingTypeTag,
    getLabelFromBookingType,
    getStatusOpenItems,
    getStatusTransaction,
    showEffectiveYear,
} from '../../../../../../core/utils/common';
import {
    BankTransactionDto,
    BookingDto,
    BookingItemDto,
    BookingsService,
    OpenItemDto,
} from '../../../../../../generated-sources/accounting';
import {
    AddReceiptConfig,
    ReceiptsAddReceiptOverlayComponent,
} from '../../../receipts/receipts-add-receipt-overlay/receipts-add-receipt-overlay.component';
import { CancellationBookingOverlayComponent } from '../cancellation-booking-overlay/cancellation-booking-overlay.component';

@Component({
    selector: 'app-booking-detail',
    templateUrl: './booking-detail.component.html',
    styleUrls: ['./booking-detail.component.scss'],
})
export class BookingDetailComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();
    public ledgerId = '';
    private bookingId = '';

    public debits: BookingItemDto[] = [];
    public credits: BookingItemDto[] = [];

    public booking?: BookingDto;
    public connectedBooking?: BookingDto;
    public isLoading = false;
    public tableModel: TableModel = { data: [], header: [] };
    public cancellationTableModel: TableModel = { data: [], header: [] };
    public connectedTableModel: TableModel = { data: [], header: [] };
    public transactionTableModel: TableModel = { data: [], header: [] };
    public breadcrumbs: BreadcrumbItem[] = [];

    public title = '';
    public subtitle = '';
    public isBookingJustCancelled = false;
    public isCancelable = true;
    public connectedBookingId = '';
    public effectiveYear = '';

    public constructor(
        private translateService: TranslateService,
        private bookingsService: BookingsService,
        private breadcrumbService: BreadCrumbService,
        private overlayService: OverlayService,
        private route: ActivatedRoute,
        private datePipe: DatePipe
    ) {}

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public ngOnInit(): void {
        this.isLoading = true;
        this.initTableHeadersTransaction();
        this.getBookingDetailInfo().subscribe(() => {
            this.initBreadCrumbs();
        });
    }

    private initBreadCrumbs(): void {
        const breadcrumb: BreadcrumbItem[] = [
            {
                content: this.translateService.instant('PAGES.BOOKINGS.BOOKINGS_OVERVIEW.HEADLINE'),
                route: [`/accounting/ledger/${this.ledgerId}/bookings`],
                current: true,
            },
            {
                content: this.translateService.instant('PAGES.BOOKINGS.BOOKING_DETAILS.BREADCRUMB'),
                current: true,
                route: [`/accounting/ledger/${this.ledgerId}/bookings/${this.bookingId}`],
            },
        ];
        this.breadcrumbService.resetBreadCrumbs();
        this.breadcrumbService.updateBreadCrumbs(breadcrumb);
        this.breadcrumbs = this.breadcrumbService.getCurrentBreadCrumbs();
    }

    public openCancelBookingOverlay(): void {
        const ref = this.overlayService.open(CancellationBookingOverlayComponent, {
            data: { ledgerId: this.ledgerId, bookingId: this.bookingId },
        });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

        ref.saveEmitter$
            .pipe(
                switchMap(() => this.bookingsService.findOne(this.ledgerId, this.bookingId)),
                tap((booking: BookingDto) => {
                    this.booking = booking;
                    this.processBooking(booking);
                    this.isBookingJustCancelled = true;
                    this.connectedBookingId = booking.cancellationBookingId || '';
                }),
                switchMap(() => this.bookingsService.findOne(this.ledgerId, this.connectedBookingId)),
                tap((cancellationBooking: BookingDto) => {
                    this.connectedBooking = cancellationBooking;
                    this.isLoading = false;
                    this.initTableHeadersCancellation();
                    this.connectedTableModel.data = [this.createRowCancellation(cancellationBooking)];
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }

    private getBookingDetailInfo(): Observable<BookingDto> {
        return combineLatest([this.route.parent!.paramMap, this.route.paramMap]).pipe(
            tap((params: ParamMap[]) => {
                this.ledgerId = String(params[0].get('id'));
                this.bookingId = String(params[1].get('bookingId'));
            }),
            switchMap(() => this.bookingsService.findOne(this.ledgerId, this.bookingId)),
            tap((booking) => {
                this.booking = booking;
                this.processBooking(booking);
            }),
            tap((booking) => {
                this.tableModel.data =
                    booking?.openItems?.map((item: OpenItemDto) => {
                        return this.createRow(item);
                    }) || [];
                // prepare data for transaction table
                if (booking && booking.bankTransaction) {
                    this.transactionTableModel.data = [this.createRowTransaction(booking.bankTransaction)];
                }
            }),
            switchMap((booking) => {
                if (booking.cancelledBookingId !== null) {
                    return this.bookingsService.findOne(this.ledgerId, booking.cancelledBookingId!);
                }
                if (booking.cancellationBookingId !== null) {
                    return this.bookingsService.findOne(this.ledgerId, booking.cancellationBookingId!);
                } else {
                    return of(booking);
                }
            }),
            tap((connectedBooking: BookingDto) => {
                if (this.booking?.cancelledBookingId !== null || this.booking?.cancellationBookingId) {
                    this.connectedBooking = connectedBooking;
                    this.initTableHeadersCancellation();
                    this.connectedTableModel.data = [this.createRowCancellation(connectedBooking)];
                }
                this.isLoading = false;
            }),
            takeUntil(this.unsubscribe$)
        );
    }

    private processBooking(booking: BookingDto): void {
        this.credits = [];
        this.debits = [];
        if (booking.isCancelable == false) {
            this.isCancelable = booking.isCancelable;
        }
        booking?.items?.map((item: BookingItemDto) => {
            const processedItem: BookingItemDto = item;
            processedItem.account.type = accountTypeNameFromEnum(item.account);
            if (item.credit) {
                this.credits.push(processedItem);
            } else {
                this.debits.push(processedItem);
            }
        });

        this.effectiveYear = showEffectiveYear(booking.effectiveFrom, booking.effectiveTo);

        this.tableModel.header = [
            'ENTITIES.OPEN_ITEM.STATUS',
            'ACCOUNTING.COMMON.DESCRIPTION',
            {
                data: { key: 'ENTITIES.OPEN_ITEM.DESCRIPTION', params: {} },
                width: '300px',
            },
            'ENTITIES.OPEN_ITEM.DUE_DATE',
            {
                data: { key: 'ACCOUNTING.OPEN-ITEMS.CREATED_AT', params: {} },
            },
            {
                data: { key: 'ENTITIES.OPEN_ITEM.AMOUNT', params: {} },
                template: HeaderTemplate.RightAligned,
            },
            {
                data: { key: 'ENTITIES.OPEN_ITEM.BOOKING_AMOUNT', params: {} },
                template: HeaderTemplate.RightAligned,
            },
            {
                data: { key: 'ENTITIES.OPEN_ITEM.OUTSTANDING_BALANCE', params: {} },
                template: HeaderTemplate.RightAligned,
            },
        ];
    }

    private initTableHeadersTransaction(): void {
        this.transactionTableModel.header = [
            'ACCOUNTING.BANK_TRANSACTION_DETAILS.STATUS',
            'ACCOUNTING.BANK_TRANSACTION_DETAILS.COUNTERPART_NAME',
            'ACCOUNTING.BANK_TRANSACTION_DETAILS.PURPOSE',
            'ACCOUNTING.BANK_TRANSACTION_DETAILS.VALUE_DATE',
            {
                data: { key: 'ACCOUNTING.BANK_TRANSACTION_DETAILS.TOTAL_AMOUNT', params: {} },
                template: HeaderTemplate.RightAligned,
            },
            {
                data: { key: 'ACCOUNTING.BANK_TRANSACTION_DETAILS.OPEN_AMOUNT', params: {} },
                template: HeaderTemplate.RightAligned,
            },
        ];
    }

    private initTableHeadersCancellation(): void {
        this.cancellationTableModel.header = [
            'ENTITIES.BOOKING.LABEL_TYPE',
            'PAGES.BOOKINGS.BOOKING_DETAILS.CANCEL_BOOKING_TABLE_HEADER_REASON',
            'PAGES.BOOKINGS.BOOKING_DETAILS.CANCEL_BOOKING_TABLE_HEADER_TEXT',
            'ENTITIES.BOOKING.LABEL_BOOKING_DATE',
            'ENTITIES.BOOKING.LABEL_CREATED_AT',
            { data: { key: 'ENTITIES.BOOKING.LABEL_AMOUNT', params: {} }, template: HeaderTemplate.RightAligned },
        ];
    }

    public getRestAmount(amountAssignedToBookings: number, amount: number): number {
        return amount - ((amountAssignedToBookings ?? 0) * Math.abs(amount)) / amount;
    }

    private createRowCancellation(booking: BookingDto): TableItem[] {
        const link = `/accounting/ledger/${this.ledgerId}/bookings/${booking.id}`;
        const tagLabel =
            getLabelFromBookingType(
                this.translateService,
                booking.type,
                booking.category === BookingDto.CategoryEnum.Expense
            ) || '';

        return [
            {
                data: {
                    label: tagLabel,
                    link,
                    textColor: getColorOfBookingTypeTag(tagLabel),
                },
                template: CellTemplate.contentWithTagStyle,
            },
            {
                data: {
                    label: booking.description, //mocking up
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: booking.description,
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: booking.bookingDate,
                    link,
                },
                template: CellTemplate.Date,
            },
            {
                data: {
                    label: booking.createdAt || '',
                    link,
                },
                template: CellTemplate.Date,
            },
            {
                data: {
                    label: booking.amount,
                    link,
                },
                template: CellTemplate.EuroCent,
            },
        ];
    }

    private createRowTransaction(transationItem: BankTransactionDto): TableItem[] {
        const link = `/accounting/ledger/${this.ledgerId}/bank-accounts/${transationItem.bankAccountId}/bank-transactions/${transationItem.id}`;

        const statusInfo: GetStatus = getStatusTransaction(
            Math.abs(transationItem.amount),
            Math.abs(transationItem.amountAssignedToBookings || 0)
        );

        let purpose = transationItem.purpose?.replace(/[+-]/g, ' ') ?? '';
        purpose = purpose.length >= 150 ? purpose.substring(0, 150) + '...' : purpose;

        return [
            {
                data: {
                    label: statusInfo.status,
                    textColor: statusInfo.color,
                    iconSrc: statusInfo.iconSrc,
                    link,
                    extraData: { bankTransaction: transationItem, enableCustomSorting: true },
                },
                template: CellTemplate.DynamicIcon,
            },
            {
                data: {
                    label: transationItem.counterpartName,
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: purpose,
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: transationItem.valueDate,
                    link,
                },
                template: CellTemplate.Date,
            },
            {
                data: {
                    label: transationItem.amount,
                    link,
                },
                template: CellTemplate.EuroCent,
            },
            {
                data: {
                    label: this.getRestAmount(transationItem.amountAssignedToBookings || 0, transationItem.amount),
                    link,
                },
                template: CellTemplate.EuroCent,
            },
        ];
    }

    private createRow(openItem: OpenItemDto): TableItem[] {
        const link =
            openItem.type === 'ADVANCEMENT'
                ? `/accounting/ledger/${this.ledgerId}/advancements/${openItem.id}`
                : `/accounting/ledger/${this.ledgerId}/open-items/${openItem.id}`;
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const dueDate = new Date(openItem.dueDate);
        dueDate.setHours(0, 0, 0, 0);

        const statusInfo: GetStatus = getStatusOpenItems(this.translateService, openItem.state, openItem.dueDate);
        const queryParams =
            openItem.type === 'ADVANCEMENT'
                ? {
                      pageTitle: 'Vorschuss Details',
                      openItemType: 'ADVANCEMENT',
                  }
                : {
                      pageTitle: 'Merkposten Details',
                      openItemType: openItem.type === 'RECEIVABLE' ? 'RECEIVABLE' : 'LIABILITY',
                  };
        return [
            {
                data: {
                    label: statusInfo.status,
                    link,
                    textColor: statusInfo.color,
                    iconSrc: statusInfo.iconSrc,
                    extraData: { openItem, enableCustomSorting: true, queryParams },
                },
                template: CellTemplate.DynamicIcon,
            },
            {
                data: {
                    label: this.translateService.instant('ENTITIES.OPEN_ITEM.' + openItem.type),
                    link,
                    extraData: { queryParams },
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: openItem.description,
                    link,
                    extraData: { queryParams },
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: openItem.dueDate,
                    link,
                    textColor: dueDate <= today && openItem.state !== 'CLOSED' ? 's-red-01' : '',
                    extraData: { queryParams },
                },
                template: CellTemplate.Date,
            },
            {
                data: {
                    label: openItem.createdAt,
                    link,
                    extraData: { queryParams },
                },
                template: CellTemplate.Date,
            },

            {
                data: {
                    label: openItem.amount,
                    link,
                    extraData: { queryParams },
                },
                template: CellTemplate.EuroCent,
            },
            {
                data: {
                    label: openItem.bookingAmount ?? '',
                    link,
                    extraData: { queryParams },
                },
                template: CellTemplate.EuroCent,
            },
            {
                data: {
                    label: openItem.outstandingBalance,
                    link,
                    extraData: { queryParams },
                },
                template: CellTemplate.EuroCent,
            },
        ];
    }

    public getDebitCreditValue(debit: BookingItemDto['debit'], credit: BookingItemDto['credit']): number {
        const value = debit ?? credit;
        if (value === null) {
            return 0;
        }
        return value;
    }

    public addReceipt(): void {
        if (!this.booking) {
            console.warn('no ledger or booking');
            return;
        }

        const data: AddReceiptConfig = {
            // ledgerId: this.ledgerId,
            data: {
                bookingId: this.booking.id,
            },
        };

        const ref = this.overlayService.open(ReceiptsAddReceiptOverlayComponent, data);
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.getBookingDetailInfo().pipe(take(1), takeUntil(this.unsubscribe$)).subscribe();
        });
    }
}
