<ibm-table-container *ngIf="model && model.data">
    <div *ngIf="isLoading" class="is-loading">
        <div class="d-flex align-items-center justify-content-center mt-2r mb-2r">
            <ibm-inline-loading> </ibm-inline-loading>
        </div>
    </div>
    <ibm-table
        (sort)="doSort($event)"
        [model]="model"
        [showSelectionColumn]="false"
        (rowClick)="clickedRow($event)"
        [striped]="isZebraTable"
        [sortable]="sortable"
        [ngClass]="{ tableWithoutHeader: header && header.length < 1 }"
    >
        <tbody>
            <tr>
                <td colspan="10" class="table-empty-text">
                    <ng-container *ngIf="!searchValue || searchValue === 'initial_empty_search_value'">
                        <div class="d-flex align-items-center s-body-14-22-regular" *ngIf="emptyTextLink">
                            <svg-icon class="icon-info" src="/assets/icons/24_information.svg"></svg-icon>
                            {{ emptyText! | translate }}
                            <a [routerLink]="emptyTextLink" queryParamsHandling="merge">{{
                                emptyTextLinkLabel! | translate
                            }}</a>
                        </div>
                        <div class="d-flex align-items-center s-body-14-22-regular" *ngIf="!emptyTextLink">
                            <svg-icon
                                *ngIf="emptyText"
                                class="icon-info"
                                src="/assets/icons/24_information.svg"
                            ></svg-icon>
                            {{ emptyText! | translate }}
                        </div>
                    </ng-container>
                </td>
            </tr>
        </tbody>
    </ibm-table>
    <div class="p-06"></div>

    <div *ngIf="data && data!.length >= itemsPerPage" ibmRow>
        <div ibmCol class="pagination-wrapper">
            <app-pagination
                [model]="paginationTableModel"
                [rowsPerPage]="itemsPerPage"
                (selectPage)="selectPage($event)"
            >
            </app-pagination>
        </div>
    </div>
</ibm-table-container>

<ng-template #boldTableItem let-data="data">
    <a [routerLink]="data.link" queryParamsHandling="merge" class="data-table-link s-body-14-22-semibold">{{
        data.label
    }}</a>
</ng-template>

<ng-template #customTableItem let-data="data">
    <a
        [routerLink]="data.link"
        queryParamsHandling="merge"
        [ngClass]="{ 'text-right': data.rightAligned }"
        [queryParams]="data.extraData && data.extraData.queryParams ? data.extraData.queryParams : {}"
        [class]="'data-table-link ' + data.textColor"
        >{{ data.label }}</a
    >
</ng-template>

<ng-template #formattedCentTableItem let-data="data">
    <a
        [routerLink]="data.link"
        queryParamsHandling="merge"
        [queryParams]="data.extraData && data.extraData.queryParams ? data.extraData.queryParams : {}"
        [class]="
            'data-table-link s-body-14-22-semibold  tw-justify-end tw-w-full' + (data.textColor ? data.textColor : '')
        "
        [ngClass]="{ 'color-red': data.label < 0 }"
        >{{ data.label | eurocent }}</a
    >
</ng-template>

<ng-template #formattedDateTableItem let-data="data">
    <a
        [routerLink]="data.link"
        queryParamsHandling="merge"
        [queryParams]="data.extraData && data.extraData.queryParams ? data.extraData.queryParams : {}"
        [class]="'data-table-link ' + data.textColor"
        >{{ data.label | dateFormat }}</a
    >
</ng-template>

<ng-template #formattedDateTimeTableItem let-data="data">
    <a [routerLink]="data.link" queryParamsHandling="merge" class="data-table-link">{{
        data.label | date : 'dd.MM.yyyy – HH:mm'
    }}</a>
</ng-template>

<ng-template #rightAlignedHeaderItem let-data="data">
    <div class="w-100 text-right pre-wrap">{{ data.label }}</div>
</ng-template>

<!-- TODO: Not implemented -->
<ng-template #customHeaderItem let-data="data">
    <div class="">{{ data.label }}</div>
</ng-template>

<!-- TODO: Not implemented -->
<ng-template #boldTHeaderItem let-data="data">
    <div class="">{{ data.label }}</div>
</ng-template>

<ng-template #dynamicComponentHeader let-data="data">
    <div
        dynamicComponentWrapper
        [component]="data.extraData.component"
        [componentData]="data.extraData.componentData"
        [unpackComponentData]="true"
        class="[&&]:tw-pr-2 {{ data.extraData?.wrapperClass ?? '' }}"
    ></div>
</ng-template>

<ng-template #notFoundText let-data="data">
    <div class="s-body-14-22-italic data-table-link">
        {{ data.label }}
    </div>
</ng-template>

<ng-template #imageTableItem let-data="data">
    <a [routerLink]="data.link" queryParamsHandling="merge" class="data-table-link">
        <img
            class="tw-rounded-full tw-h-10 tw-w-10"
            *ngIf="data.label; else defaultPic"
            src="{{ data.label | imageBase64 }}"
            alt=""
    /></a>
</ng-template>

<ng-template #imageTableItemWithLabel let-data="data">
    <a [routerLink]="data.link" queryParamsHandling="merge" class="data-table-link d-flex align-items-center">
        <img
            class="tw-rounded-full tw-h-10 tw-w-10"
            *ngIf="data.extraData.img; else defaultPic"
            src="{{ data.extraData.img | imageBase64 }}"
            alt=""
        />
        <span class="ml-05" *ngIf="data.label">{{ data.label }}</span>
    </a>
</ng-template>

<ng-template #defaultPic>
    <div class="default-foto tw-rounded-full tw-h-10 tw-w-10">
        <svg-icon src="/assets/icons/40_building.svg"></svg-icon>
    </div>
</ng-template>

<ng-template #iconWithTooltipItem let-data="data">
    <div class="w-100 text-right">
        <a *ngIf="data.link" [routerLink]="data.link" queryParamsHandling="merge" class="data-table-link action-icon">
            <app-tooltip [position]="'bottom'">
                <div content>{{ data.label }}</div>
                <div hoverable-item class="hoverable-item">
                    <app-scalara-button [variant]="'icon-only'">
                        <svg-icon
                            src="/assets/icons/24_arrow-right.svg"
                            svgClass="tw-h-6 tw-w-6 tw-fill-scalaraGray-03"
                        ></svg-icon
                    ></app-scalara-button>
                </div>
            </app-tooltip>
        </a>
    </div>
</ng-template>

<ng-template #iconWithStatusItem let-data="data">
    <div class="w-100 text-right row-status-icon-container">
        <div
            class="row-status-icon"
            [ngClass]="{
                'row-disabled': data.label === 'row-disabled',
                'row-selected': data.label === 'row-selected',
                'row-available': data.label === 'row-available'
            }"
        ></div>
    </div>
</ng-template>

<ng-template #dynamicIconItem let-data="data">
    <app-status-with-icon
        [link]="data.link"
        [queryParams]="data.extraData && data.extraData.queryParams ? data.extraData.queryParams : {}"
        [textColor]="data.textColor"
        [label]="data.label"
        [iconSrc]="data.iconSrc"
        [showNewTag]="data.extraData?.showNewTag"
        [rightAligned]="data.extraData?.rightAligned"
        (handleButtonClick)="onClickDynamicIconItem(data)"
    ></app-status-with-icon>
</ng-template>

<ng-template #accountChartsButtonsItem let-data="data">
    <div class="w-100 text-right">
        <div class="icons-button-container">
            <button
                *ngIf="
                    data.extraData &&
                    (data.extraData.account.type === 'EXPENSE' || data.extraData.account.type === 'REVENUE')
                "
                (click)="openAddAccountEvent(data, false)"
                class="button-icon"
            >
                <svg-icon src="/assets/icons/24_duplicate.svg"></svg-icon>
            </button>
            <button (click)="openAddAccountEvent(data, true)" class="button-icon">
                <svg-icon src="/assets/icons/24_edit.svg"></svg-icon>
            </button>
        </div>
    </div>
</ng-template>

<ng-template #iconButtonItem let-data="data">
    <div
        class="w-100 icons-button-container"
        [ngClass]="{
            'icon-button-item--transparent': data.extraData.showOnlyWithHover,
            'icons-button-item': !data.extraData.showOnlyWithHover
        }"
    >
        <button (click)="customButtonEvent(data)" class="button-icon">
            <svg-icon [class]="'fill-' + data.textColor" [src]="data.iconSrc"></svg-icon>
        </button>
    </div>
</ng-template>

<ng-template #editButtonItem let-data="data">
    <div class="w-100 text-right">
        <app-tooltip position="bottom">
            <div content>Vertreten</div>
            <div hoverable-item>
                <button class="button-icon">
                    <a [routerLink]="data.link" queryParamsHandling="merge" class="data-table-link"
                        ><svg-icon src="/assets/icons/24_transaction.svg"></svg-icon>
                    </a>
                </button>
            </div>
        </app-tooltip>
    </div>
</ng-template>

<ng-template #buttonItem let-data="data">
    <div [ngClass]="{ 'text-right': data.rightAligned }" class="p-03">
        <button
            class="button-item-styled"
            [ibmButton]="'ghost'"
            size="sm"
            type="button"
            (click)="customButtonEvent(data)"
        >
            {{ data.label }}
        </button>
    </div>
</ng-template>

<ng-template #textAndButtonItem let-data="data">
    <div *ngIf="data.extraData.id" class="p-03 d-flex align-items-center justify-content-end">
        <div class="mr-1-5r">{{ data.extraData.text }}</div>
        <app-scalara-button [variant]="'ghost'" type="button" (click)="customButtonEvent(data)">
            {{ data.label }}
        </app-scalara-button>
    </div>
</ng-template>

<ng-template #twoActionsButtonsItem let-data="data">
    <div class="d-flex align-items-center justify-content-end">
        <button
            *ngIf="data.extraData.showEditButton"
            (click)="customButtonEvent(data)"
            class="icon-with-action-styled icon-with-action-styled__gray-02"
        >
            <svg-icon src="/assets/icons/24_edit.svg"></svg-icon>
        </button>
        <app-tooltip position="bottom">
            <div content>{{ data.label }}</div>
            <div hoverable-item>
                <button
                    class="icon-with-action-styled icon-with-action-styled__gray-02"
                    [disabled]="data.extraData.disabledButton"
                    [routerLink]="data.link"
                    queryParamsHandling="merge"
                >
                    <svg-icon src="/assets/icons/24_arrow-right.svg"></svg-icon>
                </button>
            </div>
        </app-tooltip>
    </div>
</ng-template>

<ng-template #apportionableLabelItem let-data="data">
    <app-apportionable-label [account]="data.extraData.account"></app-apportionable-label>
</ng-template>

<ng-template #filesActionsItem let-data="data">
    {{ data.label }}
    <div class="w-100 text-right">
        <div
            [ngClass]="{
                'icon-button-receipts--transparent': data.extraData.showOnlyWithHover,
                'icons-button-receipts': !data.extraData.showOnlyWithHover
            }"
        >
            <app-scalara-button
                (click)="handleFiles(data, 'delete'); $event.stopPropagation()"
                variant="icon-only"
                height="40px"
                svgName="24_delete.svg"
                [disabled]="data.extraData.disabled"
                *ngIf="data.extraData.canDelete && !data.extraData.hideDeleteButton"
            ></app-scalara-button>

            <app-scalara-button
                (click)="handleFiles(data, 'download'); $event.stopPropagation()"
                variant="icon-only"
                height="40px"
                svgName="24_download.svg"
                [disabled]="data.extraData.disabled"
            ></app-scalara-button>

            <app-scalara-button
                (click)="handleFiles(data, 'view'); $event.stopPropagation()"
                variant="icon-only"
                height="40px"
                svgName="24_eye.svg"
                [disabled]="data.extraData.disabled"
            ></app-scalara-button>
        </div>
    </div>
</ng-template>

<ng-template #actionsItem let-data="data">
    {{ data.label }}
    <div class="w-100 text-right">
        <div
            [ngClass]="{
                'icon-button-receipts--transparent': data.extraData.showOnlyWithHover,
                'icons-button-receipts': !data.extraData.showOnlyWithHover
            }"
        >
            <button *ngIf="!data.extraData.hiddenDeleteButton" (click)="handleActions(data, 'delete')">
                <svg-icon src="/assets/icons/24_delete.svg"></svg-icon>
            </button>
            <button (click)="handleActions(data, 'download')">
                <svg-icon src="/assets/icons/24_download.svg"></svg-icon>
            </button>
            <button
                *ngIf="showViewFileButton(data.extraData.fileName || data.extraData.filename)"
                (click)="handleActions(data, 'view')"
            >
                <svg-icon src="/assets/icons/24_eye.svg"></svg-icon>
            </button>
        </div>
    </div>
</ng-template>

<!-- <ng-template #textWithIconItem let-data="data">
    <a
        [routerLink]="data.link"
        class="data-table-link text-with-icon text-with-icon__green"
        [class.text-with-icon__green]="data.extraData?.color === 'green'"
        [class.s-green-01]="data.extraData?.color === 'green'"
    >
        <svg-icon [src]="data.iconSrc"></svg-icon>
        {{ data.label }}
        {{ data.extraData?.color }}
    </a>
</ng-template> -->

<ng-template #textWithIconItem let-data="data">
    <a
        [routerLink]="data.link"
        queryParamsHandling="merge"
        class="data-table-link text-with-icon"
        [class.text-with-icon--green01]="data.extraData?.color === 'green'"
        [class.s-green-01]="data.extraData?.color === 'green'"
        [class.text-with-icon--gray03]="data.extraData?.color === 'gray-03'"
        [class.s-gray-03]="data.extraData?.color === 'gray-03'"
        [class.s-body-14-22-semibold]="data.extraData?.fontWeight === 'semi-bold'"
    >
        <svg-icon [src]="data.iconSrc"></svg-icon>
        {{ data.label }}
    </a>
</ng-template>

<ng-template #personWithAvatar let-data="data">
    <a [routerLink]="data.link" queryParamsHandling="merge" class="data-table-link d-flex align-items-center">
        <div
            class="avatar-container avatar-container--40"
            [ngClass]="{
                'avatar-container--green': data.extraData && data.extraData.isUserRegistered
            }"
        >
            <svg-icon src="/assets/icons/24_person.svg"></svg-icon>
        </div>
        <div class="ml-05">{{ data.label }}</div>
    </a>
</ng-template>

<ng-template #contentWithTagStyle let-data="data">
    <a
        *ngIf="!data.extraData && data.label"
        [routerLink]="data.link"
        queryParamsHandling="merge"
        class="data-table-link"
    >
        <app-tag [color]="data.textColor"> {{ data.label }} </app-tag>
    </a>
    <ng-container *ngIf="data.extraData">
        <a [routerLink]="data.link" queryParamsHandling="merge" class="data-table-link d-flex align-items-center">
            <app-tag *ngFor="let tag of data.extraData" [color]="tag.textColor" class="mr-0-5r">
                {{ tag.label }}
            </app-tag>
        </a>
    </ng-container>
</ng-template>

<ng-template #textWithCancelledLabel let-data="data">
    <a [routerLink]="data.link" queryParamsHandling="merge" class="data-table-link"
        >{{ data.label }} <span class="s-red-01 ml-0-5r s-label-12-22-semibold">Storniert</span></a
    >
</ng-template>

<ng-template #isSimpleLinkedItem let-data="data">
    <a [routerLink]="data.link" queryParamsHandling="merge">
        <div *ngIf="data.label === 'linked'" class="icon-linked icon-linked--not [&&]:tw-flex [&&]:tw-px-1">
            <svg-icon src="/assets/icons/24_dokument.svg"></svg-icon>
        </div>
    </a>
</ng-template>

<ng-template #dynamicComponentItem let-data="data" class="kek">
    <div
        dynamicComponentWrapper
        [component]="data.extraData.component"
        [componentData]="data.extraData.componentData"
        [unpackComponentData]="true"
        class="[&&]:tw-pr-2 {{ data.extraData.wrapperClass }}"
        [ngClass]="{ showOnRowHover: data.extraData.showOnRowHover }"
    ></div>
</ng-template>

<ng-template #badgeItem let-data="data">
    <div class="tw-flex tw-justify-center">
        <div
            class="tw-w-2 tw-h-2 tw-bg-scalara{{ data.textColor }}-01  tw-rounded-full"
            [ngClass]="{
                'tw-bg-scalaraGreen-01': data.textColor === 'green',
                'tw-bg-scalaraOrange-01': data.textColor === 'orange',
                'tw-bg-scalaraRed-01': data.textColor === 'red'
            }"
        ></div>
    </div>
</ng-template>
