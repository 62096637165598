<div class="tw-min-w-[264px]">
    <ibm-dropdown
        [displayValue]="dropdownRenderer"
        placeholder="Select"
        (selected)="onChangeStatus($event)"
        *ngIf="!disabled"
        [class]="selectedItem?.color"
    >
        <ibm-dropdown-list [listTpl]="dropdownRenderer" [items]="items"></ibm-dropdown-list>
    </ibm-dropdown>
    <ng-template #dropdownRenderer let-item="item">
        <div *ngIf="item && item.content" class="tw-flex tw-items-center tw-justify-between tw-w-full">
            <div class="tw-flex tw-items-center tw-space-x-2">
                <div
                    [ngClass]="{
                        'tw-fill-scalaraGray-03': item.color === 'gray',
                        'tw-fill-scalaraOrange-01': item.color === 'orange',
                        'tw-fill-scalaraGreen-02': item.color === 'green',
                        'tw-fill-scalaraPurple-01': item.color === 'purple'
                    }"
                    class="tw-fill-scalaraGray-01 tw-w-6 tw-h-6"
                >
                    <svg-icon [src]="item.icon"></svg-icon>
                </div>
                <span
                    [ngClass]="{
                        'tw-text-scalaraGray-03': item.color === 'gray',
                        'tw-text-scalaraOrange-01': item.color === 'orange',
                        'tw-text-scalaraGreen-02': item.color === 'green',
                        'tw-text-scalaraPurple-01': item.color === 'purple'
                    }"
                >
                    {{ item.content }}</span
                >
            </div>
        </div>
    </ng-template>
</div>
