/**
 * SCALARA Accounting Module
 * The SCALARA Accounting API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EconomicPlanDetailsAccountDto } from './economicPlanDetailsAccountDto';


export interface EconomicPlanDetailsDto { 
    createdAt: string;
    updatedAt: string;
    id: string;
    ledgerId: string;
    status: EconomicPlanDetailsDto.StatusEnum;
    startDate: string;
    endDate: string;
    type: EconomicPlanDetailsDto.TypeEnum;
    isAutoFollowUpPlan: boolean;
    maturity: EconomicPlanDetailsDto.MaturityEnum;
    rateInterval: EconomicPlanDetailsDto.RateIntervalEnum;
    revenueAccounts: Array<EconomicPlanDetailsAccountDto>;
    expenseAccounts: Array<EconomicPlanDetailsAccountDto>;
    apportionableExpenseAccounts: Array<EconomicPlanDetailsAccountDto>;
    reserveFundAccounts: Array<EconomicPlanDetailsAccountDto>;
    individualReserveFundAccounts: Array<EconomicPlanDetailsAccountDto>;
}
export namespace EconomicPlanDetailsDto {
    export type StatusEnum = 'DRAFT' | 'ACTIVATING' | 'ACTIVE' | 'OUTDATED';
    export const StatusEnum = {
        Draft: 'DRAFT' as StatusEnum,
        Activating: 'ACTIVATING' as StatusEnum,
        Active: 'ACTIVE' as StatusEnum,
        Outdated: 'OUTDATED' as StatusEnum
    };
    export type TypeEnum = 'STANDARD' | 'DIFFERING_ADVANCEMENTS';
    export const TypeEnum = {
        Standard: 'STANDARD' as TypeEnum,
        DifferingAdvancements: 'DIFFERING_ADVANCEMENTS' as TypeEnum
    };
    export type MaturityEnum = '1.' | '2.' | '3.' | '4.' | '5.' | '6.' | '7.' | '8.' | '9.' | '10.' | '11.' | '12.' | '13.' | '14.' | '15.' | '16.' | '17.' | '18.' | '19.' | '20.' | '21.' | '22.' | '23.' | '24.' | '25.' | '26.' | '27.' | '28.' | '29.' | '30.' | '31.';
    export const MaturityEnum = {
        _1: '1.' as MaturityEnum,
        _2: '2.' as MaturityEnum,
        _3: '3.' as MaturityEnum,
        _4: '4.' as MaturityEnum,
        _5: '5.' as MaturityEnum,
        _6: '6.' as MaturityEnum,
        _7: '7.' as MaturityEnum,
        _8: '8.' as MaturityEnum,
        _9: '9.' as MaturityEnum,
        _10: '10.' as MaturityEnum,
        _11: '11.' as MaturityEnum,
        _12: '12.' as MaturityEnum,
        _13: '13.' as MaturityEnum,
        _14: '14.' as MaturityEnum,
        _15: '15.' as MaturityEnum,
        _16: '16.' as MaturityEnum,
        _17: '17.' as MaturityEnum,
        _18: '18.' as MaturityEnum,
        _19: '19.' as MaturityEnum,
        _20: '20.' as MaturityEnum,
        _21: '21.' as MaturityEnum,
        _22: '22.' as MaturityEnum,
        _23: '23.' as MaturityEnum,
        _24: '24.' as MaturityEnum,
        _25: '25.' as MaturityEnum,
        _26: '26.' as MaturityEnum,
        _27: '27.' as MaturityEnum,
        _28: '28.' as MaturityEnum,
        _29: '29.' as MaturityEnum,
        _30: '30.' as MaturityEnum,
        _31: '31.' as MaturityEnum
    };
    export type RateIntervalEnum = 'MONTHLY' | 'QUARTERLY' | 'SEMI_ANNUAL' | 'ANNUAL';
    export const RateIntervalEnum = {
        Monthly: 'MONTHLY' as RateIntervalEnum,
        Quarterly: 'QUARTERLY' as RateIntervalEnum,
        SemiAnnual: 'SEMI_ANNUAL' as RateIntervalEnum,
        Annual: 'ANNUAL' as RateIntervalEnum
    };
}


