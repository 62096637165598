import { Component, Input, OnInit } from '@angular/core';
import { Pagination } from 'carbon-components-angular';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent extends Pagination implements OnInit {

    @Input() public rowsPerPage = 15; // default value never used, because it uses the Table Component value

    public visiblePaginationPages: number[] = [];

    public ngOnInit(): void {
        this.itemsPerPage = this.rowsPerPage;
        this.updatePaginationComponent();
    }

    public setVisiblePaginationPages(page: number): void {
        this.selectPage.emit(page);
        let rangeStart, rangeEnd, i: number;
        const pages: number[] = [];

        if (this.currentPage <= 3) {
            rangeStart = 2;
        } else {
            rangeStart = this.currentPage - 1;
        }

        if (this.currentPage + 1 >= this.lastPage) {
            rangeEnd = this.lastPage - 1;
        } else {
            rangeEnd = this.currentPage + 1;
        }

        if (rangeStart <= 3) {
            for (i = 2; i < rangeStart; i++) {
                pages.push(i);
            }
        }

        for (i = rangeStart; i <= rangeEnd; i++) {
            pages.push(i);
        }

        if (rangeEnd >= this.lastPage - 1) {
            for (i = rangeEnd + 1; i <= this.lastPage - 1; i++) {
                pages.push(i);
            }
        }

        this.visiblePaginationPages = pages;
    }

    private updatePaginationComponent(): void {
        if (!this.currentPage) {
            this.setVisiblePaginationPages(1);
        } else {
            this.setVisiblePaginationPages(this.currentPage);
        }
    }
}
