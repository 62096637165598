import { TranslateService } from '@ngx-translate/core';
import { BankAccount } from 'src/app/generated-sources/base';
import { ContactDetailRow } from './components/contact-detail-row/contact-detail-row.component';

export const bankAccountToContactDetailRow = (
    bankAccount: BankAccount | undefined,
    translateService: TranslateService
): ContactDetailRow[] => {
    if (!bankAccount) {
        return [];
    }
    const rows: ContactDetailRow[] = [
        [
            { type: 'value', value: translateService.instant('ENTITIES.BANK_ACCOUNT.ACCOUNT_OWNER') },
            { type: 'value', value: bankAccount?.accountHolder ?? '-' },
        ],
        [
            { type: 'value', value: translateService.instant('ENTITIES.BANK_ACCOUNT.IBAN') },
            { type: 'value', value: bankAccount?.iban ?? '-' },
        ],
        [
            { type: 'value', value: translateService.instant('ENTITIES.BANK_ACCOUNT.BANK_NAME') },
            { type: 'value', value: bankAccount?.bankName ?? '-' },
        ],
        [
            { type: 'value', value: translateService.instant('ENTITIES.BANK_ACCOUNT.BIC') },
            { type: 'value', value: bankAccount?.bic ?? '-' },
        ],
    ];
    return rows;
};
