import { Component, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject, of, switchMap, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { formControl, formControlHasError } from 'src/app/core/utils/common';
import { BankAccountsService, CreateManuallyAddedBankAccountDto } from 'src/app/generated-sources/accounting';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';
import { LedgerCustomService } from '../services/ledger-custom.service';

@Component({
    selector: 'app-add-manually-bank-account',
    templateUrl: './add-manually-bank-account.component.html',
    styleUrls: ['./add-manually-bank-account.component.scss'],
})
export class AddManuallyBankAccountComponent extends OverlayChildComponent implements OnDestroy {
    private unsubscribe$ = new Subject<void>();

    public form: UntypedFormGroup = this.formBuilder.group({
        holderName: [null, [Validators.required]],
        bankName: [null, [Validators.required]],
        iban: [null, [Validators.required]],
        bic: [null, [Validators.required]],
        name: [null],
    });

    public constructor(
        private bankAccountService: BankAccountsService,
        private formBuilder: UntypedFormBuilder,
        public toastService: ToastService,
        private ledgerCustomService: LedgerCustomService,
        private translateService: TranslateService
    ) {
        super();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public isInvalidForm(controlName: string): boolean {
        return formControlHasError(formControl(this.form, controlName), 'required');
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public submit(): void {
        this.ledgerCustomService
            .getLedgerId$()
            .pipe(
                switchMap((ledgerId) => {
                    if (!ledgerId) {
                        return of([]);
                    }
                    const createManuallyAddedBankAccountDto: CreateManuallyAddedBankAccountDto = {
                        iban: this.form.value.iban,
                        bic: this.form.value.bic,
                        holderName: this.form.value.holderName,
                        bankName: this.form.value.bankName,
                        name: this.form.value.name,
                        ledgerId: ledgerId,
                    };

                    return this.bankAccountService.createBankAccountManually(createManuallyAddedBankAccountDto);
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: () => {
                    this.toastService.showSuccess(
                        this.translateService.instant('PAGES.BANK_ACCOUNTS.ADD_NEW_BANK_ACCOUNT_SUCCESS_TOAST')
                    );
                    this.saveEmitter$.next();
                },
                error: () => {
                    this.toastService.showError(this.translateService.instant('COMPONENTS.TOAST.TOAST_ERROR'));
                },
            });
    }
}
