/**
 * SCALARA Base Module
 * The SCALARA API for User, Person and Property resources
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Address } from './address';
import { PhoneNumber } from './phoneNumber';
import { BankAccount } from './bankAccount';


export interface Person { 
    id: string;
    /**
     * This is either null or a base64 encoded jpeg image
     */
    imgSmall?: string | null;
    type: Person.TypeEnum;
    firstName?: string;
    lastName?: string;
    companyName?: string;
    birthday?: string;
    email?: string;
    secondaryEmail?: string;
    phoneNumbers?: Array<PhoneNumber>;
    website?: string;
    registrationInfo: string;
    createdAt?: string;
    updatedAt?: string;
    address?: Address;
    lastInvited?: string;
    hasRegisteredUser: boolean;
    permissionRoles: Array<Person.PermissionRolesEnum>;
    personRoles: Array<Person.PersonRolesEnum>;
    bankAccounts?: Array<BankAccount>;
    isContactPerson?: boolean;
    miscellaneousInfo?: string;
    companyRegistrationNumber?: string;
    vatIdentificationNumber?: string;
    taxNumber?: string;
}
export namespace Person {
    export type TypeEnum = 'NAT_PERSON' | 'COMPANY';
    export const TypeEnum = {
        NatPerson: 'NAT_PERSON' as TypeEnum,
        Company: 'COMPANY' as TypeEnum
    };
    export type PermissionRolesEnum = 'PROPERTY_MANAGER' | 'OWNER' | 'SERVICE_PROVIDER' | 'ADVISER' | 'CO_OWNER' | 'TENANT';
    export const PermissionRolesEnum = {
        PropertyManager: 'PROPERTY_MANAGER' as PermissionRolesEnum,
        Owner: 'OWNER' as PermissionRolesEnum,
        ServiceProvider: 'SERVICE_PROVIDER' as PermissionRolesEnum,
        Adviser: 'ADVISER' as PermissionRolesEnum,
        CoOwner: 'CO_OWNER' as PermissionRolesEnum,
        Tenant: 'TENANT' as PermissionRolesEnum
    };
    export type PersonRolesEnum = 'IS_PROPERTY_MANAGER' | 'IS_OWNER' | 'IS_SERVICE_PROVIDER' | 'IS_ADVISER' | 'IS_CO_OWNER' | 'IS_TENANT' | 'IS_PERSON_DRAFT' | 'IS_RESIDENT';
    export const PersonRolesEnum = {
        PropertyManager: 'IS_PROPERTY_MANAGER' as PersonRolesEnum,
        Owner: 'IS_OWNER' as PersonRolesEnum,
        ServiceProvider: 'IS_SERVICE_PROVIDER' as PersonRolesEnum,
        Adviser: 'IS_ADVISER' as PersonRolesEnum,
        CoOwner: 'IS_CO_OWNER' as PersonRolesEnum,
        Tenant: 'IS_TENANT' as PersonRolesEnum,
        PersonDraft: 'IS_PERSON_DRAFT' as PersonRolesEnum,
        Resident: 'IS_RESIDENT' as PersonRolesEnum
    };
}


