<app-delete-overlay
    title="PAGES.DELETE_RECEIPT.TITLE"
    headline="PAGES.DELETE_RECEIPT.HEADLINE"
    subtitle="PAGES.DELETE_RECEIPT.SUBTITLE"
    (closeOverlay)="abort()"
    (submitOverlay)="submit()"
>
    <ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>
    <div class="d-flex align-items-center files mb-7-5r">
        <svg-icon class="d-flex align-items-center ml-1-25r" src="/assets/icons/24_dokument.svg"></svg-icon>
        <span class="s-body-14-22-regular ml-0-75r s-gray-01"> {{ fileName }}</span>
    </div>
</app-delete-overlay>
