/**
 * SCALARA Base Module
 * The SCALARA API for User, Person and Property resources
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RentInterval } from './rentInterval';


export interface RentDetails { 
    id: string;
    rentCalculationMethod: RentDetails.RentCalculationMethodEnum;
    rentType: RentDetails.RentTypeEnum;
    optedForVAT?: boolean;
    dueWorkingDay: number;
    depositType: RentDetails.DepositTypeEnum;
    depositAmount?: number;
    depositDueDate?: string;
    oneTimeSmallRepairsAmount?: number;
    annualSmallRepairsAmount?: number;
    percentageTaxIncluded: RentDetails.PercentageTaxIncludedEnum;
    rentIntervals: Array<RentInterval>;
}
export namespace RentDetails {
    export type RentCalculationMethodEnum = 'BLANKET_RENT' | 'BASE_RENT_OPERATIONAL_COST_HEATING' | 'BASE_RENT_TOTAL_PREPAID' | 'BLANKET_RENT_CONSUMPTION';
    export const RentCalculationMethodEnum = {
        BlanketRent: 'BLANKET_RENT' as RentCalculationMethodEnum,
        BaseRentOperationalCostHeating: 'BASE_RENT_OPERATIONAL_COST_HEATING' as RentCalculationMethodEnum,
        BaseRentTotalPrepaid: 'BASE_RENT_TOTAL_PREPAID' as RentCalculationMethodEnum,
        BlanketRentConsumption: 'BLANKET_RENT_CONSUMPTION' as RentCalculationMethodEnum
    };
    export type RentTypeEnum = 'PRIVATE' | 'COMMERCIAL';
    export const RentTypeEnum = {
        Private: 'PRIVATE' as RentTypeEnum,
        Commercial: 'COMMERCIAL' as RentTypeEnum
    };
    export type DepositTypeEnum = 'NONE' | 'BANK_TRANSFER' | 'CASH_PAYMENT' | 'PLEDGED_SAVING_ACCOUNT' | 'BANK_GUARANTEE' | 'DEPOSIT_INSURANCE' | 'PARENTAL_GUARANTEE';
    export const DepositTypeEnum = {
        None: 'NONE' as DepositTypeEnum,
        BankTransfer: 'BANK_TRANSFER' as DepositTypeEnum,
        CashPayment: 'CASH_PAYMENT' as DepositTypeEnum,
        PledgedSavingAccount: 'PLEDGED_SAVING_ACCOUNT' as DepositTypeEnum,
        BankGuarantee: 'BANK_GUARANTEE' as DepositTypeEnum,
        DepositInsurance: 'DEPOSIT_INSURANCE' as DepositTypeEnum,
        ParentalGuarantee: 'PARENTAL_GUARANTEE' as DepositTypeEnum
    };
    export type PercentageTaxIncludedEnum = '0.' | '7.' | '19.';
    export const PercentageTaxIncludedEnum = {
        _0: '0.' as PercentageTaxIncludedEnum,
        _7: '7.' as PercentageTaxIncludedEnum,
        _19: '19.' as PercentageTaxIncludedEnum
    };
}


