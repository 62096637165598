import { Component, Input } from '@angular/core';
import { MediaQueriesService } from '../../services/media-queries.service';

@Component({
    selector: 'app-password-tooltip',
    templateUrl: './password-tooltip.component.html',
    styleUrls: ['./password-tooltip.component.scss'],
})
export class PasswordTooltipComponent {
    @Input() public requirementErrors: boolean[] = [true];

    public constructor(public mediaQueriesService: MediaQueriesService) {}
}
