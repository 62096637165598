<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"></ibm-loading>
<app-overlay-container
    [headlineTitle]="
        energyCertificateToEdit
            ? 'PAGES.ADD_EDIT_ENERGY_CERTIFICATE.EDIT_HEADLINE'
            : 'PAGES.ADD_EDIT_ENERGY_CERTIFICATE.HEADLINE'
    "
    [sizeIcon]="72"
    image="40_edit.svg"
    colorIcon="green"
    containerSize="small"
>
    <form [formGroup]="form" class="tw-flex tw-flex-col tw-gap-6 tw-mb-10">
        <app-combo-box
            placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT' | translate }}"
            [appendInline]="true"
            label="Energieausweistyp"
            [items]="energyCertificateTypeList"
            formControlName="certificateType"
            invalidText="{{ 'ERROR.REQUIRED' | translate }}"
            [invalid]="isInvalid('certificateType')"
        >
            <ibm-dropdown-list></ibm-dropdown-list>
        </app-combo-box>

        <ibm-label invalidText="{{ 'ERROR.REQUIRED' | translate }}" [invalid]="isInvalid('energyValueInkWh')">
            Energiekennwert in kwh*
            <input
                ibmText
                type="number"
                name="energyValueInkWh"
                id="energyValueInkWh"
                formControlName="energyValueInkWh"
                [invalid]="isInvalid('energyValueInkWh')"
            />
        </ibm-label>

        <div class="tw-flex tw-justify-between tw-space-x-8">
            <div class="tw-w-full">
                <ibm-date-picker
                    label="{{ 'ENTITIES.ENERGY_CERTIFICATE.LABEL_DATE_ISSUE' | translate }}"
                    id="issueDate"
                    [placeholder]="'tt.mm.jjjj'"
                    [language]="'de'"
                    [dateFormat]="'d.m.Y'"
                    formControlName="issueDate"
                >
                </ibm-date-picker>
            </div>

            <div class="tw-w-full">
                <ibm-date-picker
                    label="{{ 'ENTITIES.ENERGY_CERTIFICATE.LABEL_VALID_UNTIL' | translate }}"
                    id="validUntil"
                    [placeholder]="'tt.mm.jjjj'"
                    [language]="'de'"
                    [dateFormat]="'d.m.Y'"
                    formControlName="validUntil"
                >
                </ibm-date-picker>
            </div>
        </div>

        <ibm-label>
            {{ 'ENTITIES.ENERGY_CERTIFICATE.LABEL_ENERGY_EFFICIENCY_CLASS' | translate }}
            <input
                ibmText
                name="energyEfficiencyClass"
                id="energyEfficiencyClass"
                formControlName="energyEfficiencyClass"
            />
        </ibm-label>
    </form>

    <div class="tw-mt-[76px]">
        <div class="tw-s-head-14-22-bold tw-mb-4">Dokument anhängen</div>

        <app-file-uploader
            #fileUpload
            (filesLoaded)="updateLoadingFilesStatus($event)"
            (fileIdsLoaded)="updateFileIdsLodaded($event)"
            (updatedFileCategories)="updatedFileCategories($event)"
            [categories]="fileCategories"
            [isCategoryDisable]="true"
            [validFiles]="['jpg', 'jpeg', 'png', 'pdf', 'PDF', 'JPG', 'PNG', 'JPEG']"
            defaultCategory="ENERGY_CERTIFICATE"
            [existingFiles]="existingFiles"
            (updateExistingFiles)="updateExistingFilesList($event)"
        ></app-file-uploader>
    </div>

    <div class="d-flex justify-space-between mt-7-5r">
        <app-scalara-button (click)="abort()" variant="outline">
            {{ 'CONTROLS.BUTTONS.CANCEL' | translate }}
        </app-scalara-button>

        <app-scalara-button [disabled]="form.invalid || !areFilesFullyUploaded" (click)="submit()" variant="default">
            {{ 'CONTROLS.BUTTONS.SAVE' | translate }}
        </app-scalara-button>
    </div>
</app-overlay-container>
