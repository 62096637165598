import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { BankAccountDto } from 'src/app/generated-sources/accounting';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { bankAccountTypeName } from '../../../../../../../core/utils/common';
import { AddManuallyBankAccountComponent } from '../../../../add-manually-bank-account/add-manually-bank-account.component';

@Component({
    selector: 'app-banking-accounts-accounts',
    templateUrl: './banking-accounts-accounts.component.html',
    styleUrls: ['./banking-accounts-accounts.component.scss'],
})
export class BankingAccountsAccountsComponent {
    private unsubscribe$ = new Subject<void>();
    public propertyId = '';
    public today = '';
    public isLoading = false;

    @Input() public bankAccounts?: BankAccountDto[];
    @Output() public updateBankAccounts = new EventEmitter<void>();

    public constructor(private overlayService: OverlayService) {}

    public getBankAccountTypeName(bankAccount: BankAccountDto): string {
        return bankAccountTypeName(bankAccount);
    }

    public openAddBankAccountOverlay(): void {
        const ref = this.overlayService.open(AddManuallyBankAccountComponent, {});
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.updateBankAccounts.emit());
    }
}
