import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ListItem } from 'carbon-components-angular';
import { Subject, finalize, takeUntil } from 'rxjs';
import { PersonType } from 'src/app/core/models/person-type.enum';
import { ToastService } from 'src/app/core/services/toast.service';
import { getLocalISOTime, getServiceProviderType } from 'src/app/core/utils/common';
import {
    CreateServiceProviderRelationDto,
    Person,
    PropertiesService,
    ServiceProviderRelationDto,
} from 'src/app/generated-sources/base';
import { DateRangePickerComponent } from 'src/app/shared/components/date-range-picker/date-range-picker.component';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';
import { PersonInfosFormComponent } from 'src/app/shared/person-infos-form/person-infos-form.component';
import { ExistingPersonsComboBoxComponent } from '../existing-persons-combo-box/existing-persons-combo-box.component';
import { DateIntervalDto } from '../interfaces';

@Component({
    selector: 'app-add-service-provider-overlay',
    templateUrl: './add-service-provider-overlay.component.html',
    styleUrls: ['./add-service-provider-overlay.component.scss'],
})
export class AddServiceProviderOverlayComponent
    extends OverlayChildComponent
    implements OnInit, OnDestroy, AfterViewInit
{
    public createUserForm: UntypedFormGroup = new UntypedFormGroup({});
    private unsubscribe$ = new Subject<void>();
    public isExistingUserSelected = false;
    public isLoading = false;
    public callReason = 'Eigentum';
    public personTypeDeclared = PersonType;
    public currentPersons: ListItem[] = [];
    private propertyID = '';
    public selectedPerson = {} as Person;

    public defaultFromDate = new Date(new Date().getFullYear(), 0, 1);

    public serviceForm = this.formBuilder.group({
        servicesProvided: this.formBuilder.array([
            this.formBuilder.group(
                {
                    serviceProvided: ['', Validators.required],
                    from: [[this.defaultFromDate], Validators.required],
                    to: [[undefined]],
                },
                { validators: [this.createDateRangeValidator()] }
            ),
        ]),
    });

    public servicesList: ListItem[] = [];

    public services: ServiceProviderRelationDto.ServiceProvidedEnum[] = Object.values(
        ServiceProviderRelationDto.ServiceProvidedEnum
    );

    @ViewChild(PersonInfosFormComponent)
    public personInfosFormComponent?: PersonInfosFormComponent;
    @ViewChild(DateRangePickerComponent)
    public dateRangePickerChildComponent?: DateRangePickerComponent;
    @ViewChild(ExistingPersonsComboBoxComponent)
    public existingPersonsComboBox?: ExistingPersonsComboBoxComponent;

    public constructor(
        private toast: ToastService,
        private router: Router,
        private translateService: TranslateService,
        private propertiesService: PropertiesService,
        private formBuilder: UntypedFormBuilder
    ) {
        super();
    }

    public ngOnInit(): void {
        this.propertyID = this.config?.data?.extraData.propertyId;

        this.servicesList = this.services
            .map((item) => ({
                selected: false,
                content: getServiceProviderType(this.translateService, item),
                id: item,
            }))
            .sort((a, b) => a.content.localeCompare(b.content));
    }

    public ngAfterViewInit(): void {
        if (!this.personInfosFormComponent || !this.existingPersonsComboBox) {
            console.warn('come of mandaroty children are not initialised');
            return;
        }

        this.createUserForm.addControl('personForm', this.personInfosFormComponent.personForm);
        this.personInfosFormComponent.personForm.setParent(this.createUserForm);
    }

    public isFormsValid(): boolean {
        if (!this.serviceForm.valid) {
            return false;
        }
        if (!this.isExistingUserSelected && this.createUserForm.valid) {
            return true;
        }
        if (this.isExistingUserSelected && this.isValidPerson()) {
            return true;
        }
        return false;
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }
    public onSubmit(): void {
        this.isLoading = true;
        if (!this.isFormsValid()) {
            this.isLoading = false;
            return;
        }

        this.createUserAndAddToProperty();
    }

    public createUserAndAddToProperty(): void {
        const servicesProvided = this.servicesControl.value?.map((service: any, index: number) => {
            return {
                ...this.createRealtionWithTimeConstraintObject(index),
                serviceProvided: service?.serviceProvided?.id,
            };
        });

        const serviceProvider: CreateServiceProviderRelationDto = {
            person: this.isExistingUserSelected ? undefined : this.createPersonDto(),
            servicesProvided: servicesProvided,
            personId: this.isExistingUserSelected ? this.selectedPerson.id : undefined,
        };

        this.propertiesService
            .createNewServiceProvider(this.propertyID, serviceProvider)
            .pipe(
                takeUntil(this.unsubscribe$),
                finalize(() => (this.isLoading = false))
            )
            .subscribe({
                next: () => {
                    this.router.navigate(['/properties', this.propertyID, 'service-providers']);
                    this.toast.showSuccess('Dienstleister erfolgreich Angelegt');
                    this.saveEmitter$.next();
                },
                error: (error) => {
                    if (error) {
                        this.toast.showError(error.error['message']);
                    }
                },
            });
    }

    private createRealtionWithTimeConstraintObject(index: number): DateIntervalDto {
        const startDate = this.getServiceProvidedItemForm(index).get('from')?.value[0];
        const endDate = this.getServiceProvidedItemForm(index).get('to')?.value[0];
        const datesObj: DateIntervalDto = {
            from: getLocalISOTime(startDate),
        };
        if (endDate) {
            datesObj.to = getLocalISOTime(endDate);
        }
        return datesObj;
    }

    private createPersonDto(): Person {
        const userForm = this.createUserForm.get('personForm')?.value;
        userForm.phoneNumbers =
            userForm.phoneNumbers && userForm.phoneNumbers.length > 0 && userForm.phoneNumbers[0]?.phoneNumber
                ? userForm.phoneNumbers.map((item: any) => {
                      return {
                          phoneNumber: item.phoneNumber,
                          type: item.phoneNumberType?.value ?? item.phoneNumberType,
                      };
                  })
                : [];
        return userForm;
    }

    public switchExistingUserSelection(isExistingSelected: boolean): void {
        if (isExistingSelected) {
            this.isExistingUserSelected = true;
        } else {
            this.isExistingUserSelected = false;
        }
    }

    public isValidPerson(): boolean {
        return Boolean(this.selectedPerson.id);
    }

    public onSelectPerson($event: Person): void {
        this.selectedPerson = $event || {};
    }

    // services control
    public get servicesControl(): FormArray {
        return this.serviceForm.controls['servicesProvided'] as FormArray;
    }

    public addNewService(): void {
        this.servicesControl.push(
            this.formBuilder.group(
                {
                    serviceProvided: ['', Validators.required],
                    from: [[this.defaultFromDate], Validators.required],
                    to: [[undefined]],
                },
                { validators: [this.createDateRangeValidator()] }
            )
        );
    }

    public removeService(index: number): void {
        this.servicesControl.removeAt(index);
    }

    public getServiceProvidedItemForm(index: number): UntypedFormGroup {
        return this.servicesControl?.at(index) as UntypedFormGroup;
    }

    // TODO: fix
    public createDateRangeValidator(): any {
        return (form: UntypedFormGroup): ValidationErrors | null => {
            const startDate: Date = form.get('from')?.value[0];
            const endDate: Date = form.get('to')?.value[0];

            if (startDate && endDate) {
                const isInvalid = endDate < startDate;
                console.log({ isInvalid });
                return isInvalid ? { dateRange: true } : null;
            }

            return null;
        };
    }
}
