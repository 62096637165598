import { Component, EventEmitter, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject, combineLatest, of, switchMap, takeUntil, tap } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { BookingRulesService, GetBookingRuleDto } from 'src/app/generated-sources/accounting';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { CellTemplate } from 'src/app/shared/table/enums/cell-template';
import { HeaderTemplate } from 'src/app/shared/table/enums/header-template';
import { TableItem } from 'src/app/shared/table/interfaces/table-item';
import { TableModel } from 'src/app/shared/table/interfaces/table-model';
import { LedgerCustomService } from '../../../../../services/ledger-custom.service';
import { AddEditBankingTransactionsBookingRulesOverlayComponent } from '../add-edit-banking-transactions-booking-rules-overlay/add-edit-banking-transactions-booking-rules-overlay.component';

@Component({
    selector: 'app-banking-transactions-booking-rules-index',
    templateUrl: './banking-transactions-booking-rules-index.component.html',
    styleUrls: ['./banking-transactions-booking-rules-index.component.scss'],
})
export class BankingTransactionsBookingRulesIndexComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();

    @Output() public updateAmountBookingsRule = new EventEmitter<number>();

    public automaticBookingRulesTable: TableModel = { data: [], header: [] };
    public manualBookingRulesTable: TableModel = { data: [], header: [] };
    public ledgerId = '';
    public isLoading = false;

    public ledgerId$ = this.ledgerCustomService.getLedgerId$();

    public refreshBookingsRule$ = new BehaviorSubject<void>(undefined);

    public bookingRules$ = combineLatest([this.ledgerId$, this.refreshBookingsRule$]).pipe(
        switchMap(([ledgerId]) => {
            if (!ledgerId) {
                return of([]);
            }

            this.ledgerId = ledgerId;
            return this.bookingRulesService.findAll(ledgerId);
        }),
        tap((bookingsRule) => {
            this.updateAmountBookingsRule.emit(
                bookingsRule.filter((item) => item.status === GetBookingRuleDto.StatusEnum.Pending).length
            );

            this.automaticBookingRulesTable.data = bookingsRule
                .filter((item) => item.isAutogenerated && item.status !== GetBookingRuleDto.StatusEnum.Rejected)
                .map((item) => {
                    return this.createRowAutomaticBookingsRule(item);
                });

            this.manualBookingRulesTable.data = bookingsRule
                .filter((item) => !item.isAutogenerated)
                .map((item) => {
                    return this.createRowManualBookingRule(item);
                });
        })
    );

    @ViewChild('acceptOrRejectBookingRuleButtons', { static: true })
    public acceptOrRejectBookingRuleButtons?: TemplateRef<any>;

    public constructor(
        private overlayService: OverlayService,
        private ledgerCustomService: LedgerCustomService,
        private bookingRulesService: BookingRulesService,
        private toastService: ToastService
    ) {}

    public ngOnInit(): void {
        this.automaticBookingRulesTable.header = this.initTableHeaders(true);
        this.manualBookingRulesTable.header = this.initTableHeaders();

        this.bookingRules$.subscribe();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    private initTableHeaders(isBookingRuleRecommendation = false): any[] {
        const header = [
            'ENTITIES.COMMON.STATUS',
            'PROPERTY.COMMON.DESCRIPTION',
            {
                data: { key: 'ENTITIES.COMMON.LABEL_AMOUNT_TABLE', params: {} },
                template: HeaderTemplate.RightAligned,
            },
            'PAGES.BANK_TRANSACTIONS.BOOKING_RULES.LABEL_DESTINATION_BOOKING_ACCOUNT',
            'PAGES.BANK_TRANSACTIONS.BOOKING_RULES.LABEL_LAST_EXECUTION_DATE',
        ];

        if (isBookingRuleRecommendation) {
            header.push('');
        }
        return header;
    }

    public getBookingRuleIconInfo(status: GetBookingRuleDto.StatusEnum): {
        label: string;
        color: string;
        iconSrc: string;
        textColor?: string;
    } {
        switch (status) {
            case 'ACTIVATED':
                return { label: 'Aktiviert', color: 's-green-01', iconSrc: '/assets/icons/24_closed.svg' };

            case 'DEACTIVATED':
                return {
                    label: 'Deaktiviert',
                    color: 's-gray-03',
                    iconSrc: '/assets/icons/24_not-available.svg',
                    textColor: 's-gray-03',
                };
            case 'PENDING':
                return {
                    label: 'Automatischer Vorschlag',
                    color: 's-blue-01',
                    iconSrc: '/assets/icons/24_open.svg',
                    textColor: 's-blue-01',
                };
            default:
                return { label: 'Deaktiviert', color: 's-gray-03', iconSrc: '/assets/icons/24_not-available.svg' };
        }
    }

    private createRowAutomaticBookingsRule(data: GetBookingRuleDto): TableItem[] {
        const link = `/accounting/ledger/${this.ledgerId}/bank-transactions/booking-rules/${data.id}`;
        const account = `${data.accountToBookIn.name ?? '-'}, ${data.accountToBookIn.description ?? ''}`;

        const status = this.getBookingRuleIconInfo(data.status);

        return [
            {
                data: {
                    label: status.label,
                    link,
                    textColor: status.color,
                    iconSrc: status.iconSrc,
                },
                template: CellTemplate.DynamicIcon,
            },
            {
                data: {
                    label: data.name === '–' ? 'nicht angegeben' : data.name,
                    link,
                    textColor: status.textColor,
                },
                template: data.name === '–' ? CellTemplate.NotFoundText : CellTemplate.Default,
            },
            {
                data: {
                    label: data.amount || '',
                    link,
                    textColor: status.textColor,
                },
                template: CellTemplate.EuroCent,
            },
            {
                data: {
                    label: account,
                    link,
                    textColor: status.textColor,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: data.lastUsedAt || '',
                    link,
                    textColor: status.textColor,
                },
                template: CellTemplate.Date,
            },
            {
                data: {
                    label: '',
                    link,
                    textColor: status.textColor,
                    extraData: { bookingRule: data },
                },
                template:
                    data.status === GetBookingRuleDto.StatusEnum.Pending
                        ? this.acceptOrRejectBookingRuleButtons
                        : CellTemplate.Default,
            },
        ];
    }

    private createRowManualBookingRule(data: GetBookingRuleDto): TableItem[] {
        const link = `/accounting/ledger/${this.ledgerId}/bank-transactions/booking-rules/${data.id}`;
        const account = `${data.accountToBookIn.name ?? '-'}, ${data.accountToBookIn.description ?? ''}`;

        const isActivated = data.status === GetBookingRuleDto.StatusEnum.Activated;

        return [
            {
                data: {
                    label: isActivated ? 'Aktiviert' : 'Deaktiviert',
                    link,
                    textColor: isActivated ? 's-green-01' : 's-gray-03',
                    iconSrc: isActivated ? '/assets/icons/24_closed.svg' : '/assets/icons/24_not-available.svg',
                },
                template: CellTemplate.DynamicIcon,
            },
            {
                data: {
                    label: data.name || '',
                    link,
                    textColor: !isActivated ? 's-gray-03' : '',
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: data.amount || '',
                    link,
                    textColor: !isActivated ? 's-gray-03' : '',
                },
                template: CellTemplate.EuroCent,
            },
            {
                data: {
                    label: account,
                    link,
                    textColor: !isActivated ? 's-gray-03' : '',
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: data.lastUsedAt || '',
                    link,
                    textColor: !isActivated ? 's-gray-03' : '',
                },
                template: CellTemplate.Date,
            },
        ];
    }

    public openAddBankingTransactionsBookingRulesOverlay(): void {
        const ref = this.overlayService.open(AddEditBankingTransactionsBookingRulesOverlayComponent, {
            data: {},
        });
        ref.saveEmitter$.subscribe(() => {
            this.refreshBookingsRule$.next(undefined);
        });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
    }

    public accepctOrRecejectBookingRule(bookingRule: GetBookingRuleDto, newStatus: GetBookingRuleDto.StatusEnum): void {
        if (!this.ledgerId) {
            return;
        }

        this.isLoading = true;

        this.bookingRulesService.updateBookingRuleStatus(this.ledgerId, bookingRule.id, newStatus).subscribe({
            next: () => {
                this.refreshBookingsRule$.next();
                this.toastService.showSuccess(
                    newStatus === GetBookingRuleDto.StatusEnum.Activated
                        ? 'Buchungsregel erfolgreich angenommen'
                        : 'Buchungsregel erfolgreich abgelehnt'
                );
                this.isLoading = false;
            },
            error: (error) => {
                this.refreshBookingsRule$.next();
                if (error) {
                    this.toastService.showError(error.error['message']);
                }
                this.isLoading = false;
            },
        });
    }
}
