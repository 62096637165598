<app-signin-signup-container routeLink="sign-in" [isRegisterPage]="true" rightImgSrc="/assets/images/sign_up_image.png">
    <div class="sign-up-container">
        <h1 class="s-head-28-36-bold mb-3r">{{ 'PAGES.REGISTER_INVITATION.HEADER' | translate }}</h1>
        <div class="tile tile--shadow tile--not-clickable text-center align-items-center p-2r mb-1-5r">
            <div class="avatar-container avatar-container--40 mx-auto mb-1r">
                <svg-icon src="/assets/icons/24_person.svg"></svg-icon>
            </div>
            <div class="s-label-12-22-semibold mb-0-5r">{{ 'PAGES.REGISTER_INVITATION.EMAIL_LABEL' | translate }}</div>
            <div class="s-head-16-24-bold line-break-anywhere">{{ invitationEmail }}</div>
        </div>
        <app-notification type="info">
            {{ 'PAGES.REGISTER_INVITATION.NOTIFICATION' | translate }}
        </app-notification>
        <form (submit)="onSignUp()" [formGroup]="form" class="mt-2-5r">
            <!-- PASSWORD -->
            <app-password-input
                [form]="form"
                inputName="password"
                label="{{ 'CONTROLS.FORMCONTROLS.PASSWORD.LABEL' | translate }}*"
            ></app-password-input>
            <!-- PASSWORD WIEDERHOLEN -->
            <app-password-input
                [form]="form"
                inputName="confirmPassword"
                label="{{ 'CONTROLS.FORMCONTROLS.CONFIRM_PASSWORD.LABEL' | translate }}*"
            ></app-password-input>

            <!-- Checkbox -->
            <div class="s-body-14-22-regular">
                <div class="s-body-14-22-regular mb-1r">
                    {{ 'PAGES.SIGN_UP.CHECKBOX_MESSAGE_PART1' | translate }}
                    <a target="_blank" href="https://www.scalara.de/terms">
                        {{ 'CONTROLS.LINKS.PRIVACY_POLICY' | translate }}
                    </a>
                    &nbsp;
                    {{ 'PAGES.SIGN_UP.CHECKBOX_MESSAGE_PART2' | translate }}
                </div>
                <ibm-checkbox formControlName="generalTerms" class="sign-up-checkbox">
                    <span>{{ 'PAGES.SIGN_UP.GENERAL_TERMS_PART1' | translate }}</span>
                    <a target="_blank" href="https://www.scalara.de/terms"
                        >{{ 'CONTROLS.LINKS.GENERAL_TERMS' | translate }}.</a
                    >
                </ibm-checkbox>
                <small class="s-red-01 checkbox-error" *ngIf="generalTermsInvalid">
                    {{ 'PAGES.SIGN_UP.ERROR.GENERAL_TERMS' | translate }}
                </small>
            </div>

            <div class="action-button-wrapper mt-4r">
                <app-scalara-button type="submit" variant="default" [disabled]="form.invalid" [fullWidth]="true">
                    {{ 'CONTROLS.BUTTONS.REGISTER' | translate }}
                </app-scalara-button>
            </div>
        </form>
    </div>
</app-signin-signup-container>

<ibm-toast *ngIf="notification" [notificationObj]="notification"></ibm-toast>
