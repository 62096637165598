<app-overlay-container
    headlineTitle="{{
        isFullyBooked
            ? 'PAGES.BANK_TRANSACTIONS.MARK_AS_BOOKED.HEADLINE_REMOVE'
            : 'PAGES.BANK_TRANSACTIONS.MARK_AS_BOOKED.HEADLINE_APPLY'
    }}"
    [sizeIcon]="72"
    image="{{ isFullyBooked ? '24_add-cycle.svg' : '24_ignor.svg' }}"
    colorIcon="green"
    containerSize="small"
>
    <div class="s-head-16-24-bold tw-mb-8">
        {{
            isFullyBooked
                ? ('PAGES.BANK_TRANSACTIONS.MARK_AS_BOOKED.TEXT_TITLE_REMOVE' | translate)
                : ('PAGES.BANK_TRANSACTIONS.MARK_AS_BOOKED.TEXT_TITLE_APPLY' | translate)
        }}
    </div>

    <div class="pre-wrap s-head-14-22-regular tw-mb-6">
        <span>{{
            isFullyBooked
                ? ('PAGES.BANK_TRANSACTIONS.MARK_AS_BOOKED.TEXT_BODY_REMOVE' | translate)
                : ('PAGES.BANK_TRANSACTIONS.MARK_AS_BOOKED.TEXT_BODY_APPLY' | translate)
        }}</span>
    </div>

    <div class="d-flex align-items-center tw-p-4 s-head-14-22-bold bg-gray" *ngIf="bankTransaction">
        <span class="s-body-14-22-regular tw-ml-3 s-gray-01">
            {{ bankTransaction.counterpartName }}, {{ bankTransaction.purpose }}, {{ bankTransaction.valueDate }},
            {{ bankTransaction.amount ? (bankTransaction.amount | eurocent) + ' €' : (0 | eurocent) + ' €' }}</span
        >
    </div>

    <div class="d-flex justify-space-between tw-mt-[120px]">
        <app-scalara-button (click)="abort()" variant="outline">
            {{ 'CONTROLS.BUTTONS.CANCEL' | translate }}
        </app-scalara-button>

        <app-scalara-button (click)="submit()" [variant]="'default'" type="submit">
            {{
                isFullyBooked
                    ? ('PAGES.BANK_TRANSACTIONS.MARK_AS_BOOKED.SUBMIT_REMOVE' | translate)
                    : ('PAGES.BANK_TRANSACTIONS.MARK_AS_BOOKED.SUBMIT_APPLY' | translate)
            }}
        </app-scalara-button>
    </div>
</app-overlay-container>
