import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ListItem } from 'carbon-components-angular';
import { EnergyCertificateFile, TechnicalEquipmentFile } from 'src/app/generated-sources/base';

export interface ExistingFile {
    fileName: string;
    category: EnergyCertificateFile.FileCategoryEnum | TechnicalEquipmentFile.FileCategoryEnum;
    key: string;
}

@Component({
    selector: 'app-existing-files',
    templateUrl: './existing-files.component.html',
    styleUrls: ['./existing-files.component.scss'],
})
export class ExistingFilesComponent implements OnInit {
    @Input() public existingFiles: ExistingFile[] = [];
    @Input() public categories?: ListItem[] = [];
    @Input() public isCategoryDisable = false;

    @Output() public updateExistingFilesList = new EventEmitter<ExistingFile[]>();

    public form?: UntypedFormGroup;

    public constructor(private fb: UntypedFormBuilder) {}

    public ngOnInit(): void {
        this.form = this.fb.group({
            files: this.fb.array(this.existingFiles.map((file) => this.createFileGroup(file))),
        });
    }

    private createFileGroup(file: ExistingFile): UntypedFormGroup {
        return this.fb.group({
            fileName: new UntypedFormControl(file.fileName),
            category: new UntypedFormControl(this.getFileCategory(file.category)),
            key: new UntypedFormControl(file.key),
        });
    }

    public getFileCategory(category: string): ListItem | undefined {
        let listItem = this.categories?.find((item) => item['value'] === category);

        if (listItem) {
            listItem = { ...listItem, selected: true };
        }

        return listItem;
    }

    public getFileFormGroup(index: number): UntypedFormGroup {
        return this.files.at(index) as UntypedFormGroup;
    }

    public get files(): UntypedFormArray {
        return this.form?.get('files') as UntypedFormArray;
    }

    public removeFile(index: number): void {
        this.files.removeAt(index);
        this.updateExistingFile();
    }

    public onSelectCategory(index: number, event: any): void {
        this.updateExistingFile();
    }

    public updateExistingFile(): void {
        const updatedList = this.files.value?.map((formItem: any) => {
            return {
                fileName: formItem.fileName,
                category: formItem.category?.value,
                key: formItem.key,
            };
        });

        this.updateExistingFilesList.emit(updatedList);
    }
}
