<div class="pb-1-5r">
    <div class="s-head-28-36-bold mb-3-625r tw-flex tw-justify-between">
        <app-text-with-tooltip
            class="tw-inline-block"
            [label]="
                (isLedgerMVorSEV ? 'PAGES.OPEN_ITEMS.RENT_HEADLINE' : 'PAGES.OPEN_ITEMS.ADVANCEMENT_HEADLINE')
                    | translate
            "
            [labelClass]="''"
            [tooltipKey]="TooltipKey.tooltipsInAccounting"
            [tooltipInnerHtml]="tooltipInnerHtml.headline"
        ></app-text-with-tooltip>
        <app-accounting-date-picker-selector></app-accounting-date-picker-selector>
    </div>

    <ibm-tabs [followFocus]="true" [cacheActive]="true">
        <ibm-tab heading="Forderungen">
            <div *ngIf="isLoading" class="is-loading">
                <div class="d-flex align-items-center justify-content-center mt-4r">
                    <ibm-inline-loading> </ibm-inline-loading>
                </div>
            </div>
            <div class="mt-1-5r">
                <div class="mb-2-5r d-flex justify-content-end">
                    <app-search [searchInputId]="'RECEIVABLES'"></app-search>
                    <div class="ml-1r mr-1r styled-filter">
                        <ibm-dropdown (selected)="onSelectFilter($event)" placeholder="Select">
                            <ibm-dropdown-list [items]="filter"></ibm-dropdown-list>
                        </ibm-dropdown>
                    </div>
                </div>

                <div
                    *ngIf="(filterAdvancementStatus$ | async) !== 'PAYED'"
                    class="info-open-items-box p-1r d-flex justify-space-between mb-2-5r"
                >
                    <div class="s-body-14-22-regular">
                        {{ 'PAGES.OPEN_ITEMS.YOU_HAVE' | translate
                        }}<span class="s-head-16-24-bold ml-1r"
                            >{{ (receivablesTable | async)?.data?.length }}&nbsp;</span
                        ><span>{{ 'PAGES.OPEN_ITEMS.OPEN_RECEIVABLES' | translate }}</span>
                    </div>
                    <div class="s-label-12-22-semibold s-gray-03">
                        {{ 'PAGES.OPEN_ITEMS.OPEN_AMOUNT' | translate }}
                        <span class="s-head-16-24-bold ml-1r s-gray-01">{{ sumReceivables | eurocent }}</span>
                    </div>
                </div>
                <div *ngIf="receivablesTable | async as table">
                    <app-table
                        [emptyText]="isLedgerMVorSEV && status !== 'ACTIVATED' ? '' : 'PAGES.OPEN_ITEMS.EMPTY_TABLE'"
                        [header]="table.header"
                        [data]="table.data"
                        tableSearchId="RECEIVABLES"
                        (searchResult)="updateFilteredSum($event)"
                        [isLoading]="isLoading"
                    >
                    </app-table>
                </div>
            </div>
        </ibm-tab>

        <ibm-tab heading="Verbindlichkeiten" (selected)="onTabSelected()" [active]="openLiabilitiesTabOnInit">
            <div *ngIf="isLoading" class="is-loading">
                <div class="d-flex align-items-center justify-content-center mt-4r">
                    <ibm-inline-loading> </ibm-inline-loading>
                </div>
            </div>
            <div class="mt-1-5r" *ngIf="showLiabilitiesTable$ | async">
                <div class="mb-2-5r d-flex justify-content-end">
                    <app-search [searchInputId]="'LIABILITIES'"></app-search>
                    <div class="ml-1r mr-1r styled-filter">
                        <ibm-dropdown (selected)="onSelectFilter($event)" placeholder="Select">
                            <ibm-dropdown-list [items]="filter"></ibm-dropdown-list>
                        </ibm-dropdown>
                    </div>
                </div>
                <div
                    *ngIf="(filterAdvancementStatus$ | async) !== 'PAYED'"
                    class="info-open-items-box p-1r d-flex justify-space-between mb-2-5r"
                >
                    <div class="s-body-14-22-regular">
                        {{ 'PAGES.OPEN_ITEMS.YOU_HAVE' | translate
                        }}<span class="s-head-16-24-bold ml-1r"
                            >{{ (liabilitiesTable | async)?.data?.length }}&nbsp;</span
                        ><span>{{ 'PAGES.OPEN_ITEMS.OPEN_LIABILITIES' | translate }}</span>
                    </div>
                    <div class="s-label-12-22-semibold s-gray-03">
                        {{ 'PAGES.OPEN_ITEMS.OPEN_AMOUNT' | translate }}
                        <span class="s-head-16-24-bold ml-1r s-gray-01">{{ sumLiabilities | eurocent }}</span>
                    </div>
                </div>
                <div *ngIf="liabilitiesTable | async as table">
                    <app-table
                        [emptyText]="isLedgerMVorSEV && status !== 'ACTIVATED' ? '' : 'PAGES.OPEN_ITEMS.EMPTY_TABLE'"
                        [header]="table.header"
                        [data]="table.data"
                        tableSearchId="LIABILITIES"
                        (searchResult)="updateFilteredSum($event)"
                        [isLoading]="isLoading"
                    >
                    </app-table>
                </div>
            </div>
        </ibm-tab>
    </ibm-tabs>
    <ng-container *ngIf="isLedgerMVorSEV">
        <ng-container *ngIf="status === 'NOT_ACTIVATED'">
            <ng-container *ngTemplateOutlet="NOT_ACTIVATED"></ng-container>
        </ng-container>
        <ng-container *ngIf="status === 'IN_PROGRESS'">
            <ng-container *ngTemplateOutlet="IN_PROGRESS"></ng-container>
        </ng-container>
    </ng-container>
</div>

<ng-template #NOT_ACTIVATED>
    <app-notification type="warning" *ngIf="noActivatedInfo$ | async as noActivatedInfo">
        <div class="s-body-14-22-bold mb-1-5r">
            {{ 'PAGES.OPEN_ITEMS.EMPTY_RENT_HEADLINE' | translate }}
        </div>
        <div class="s-body-14-22-regular mb-2r">
            {{ 'PAGES.OPEN_ITEMS.' + noActivatedInfo.text1 | translate }}
            <a class="s-gray-01" [routerLink]="'/accounting/ledger/' + ledgerId + '/account-charts'">{{
                'COMPONENTS.SIDENAV_ACCOUNTING.LABEL_ACCOUNT_CHARTS' | translate
            }}</a>
            {{ 'PAGES.OPEN_ITEMS.' + noActivatedInfo.text2 | translate }}
        </div>
        <app-scalara-button
            [disabled]="noActivatedInfo?.buttonDisabled ?? false"
            (click)="executeRentGeneration()"
            class="notification-button mb-2r"
            >{{ 'PAGES.OPEN_ITEMS.EMPTY_RENT_BUTTON' | translate }}</app-scalara-button
        >
    </app-notification>
</ng-template>

<ng-template #IN_PROGRESS>
    <div class="empty d-flex align-items-center py-3-5r justify-content-center">
        <div class="s-body-14-22-regular">
            {{ 'PAGES.OPEN_ITEMS.RENT_GENERATION_IN_PROGRESS' | translate }}
        </div>
    </div>
</ng-template>
