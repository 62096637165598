import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, finalize, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { WegSettlementStatementsService } from 'src/app/generated-sources/accounting';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';

@Component({
    selector: 'app-delete-annual-statement-distribution-key',
    templateUrl: './delete-annual-statement-distribution-key.component.html',
    styleUrls: ['./delete-annual-statement-distribution-key.component.scss'],
})
export class DeleteAnnualStatementDistributionKeyComponent extends OverlayChildComponent implements OnInit {
    private unsubscribe$ = new Subject<void>();
    public isLoading = false;
    public annualStatementId = '';
    public distributionKeyId = '';
    public distributionKeyDescription = '';
    public toastSuccessMessage?: string;
    public ledgerId = '';

    public constructor(
        private toastService: ToastService,
        private translateService: TranslateService,
        private router: Router,
        private annualStatementService: WegSettlementStatementsService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.distributionKeyId = this.config?.data?.distributionKeyId;
        this.annualStatementId = this.config?.data?.annualStatementId;
        this.ledgerId = this.config?.data?.ledgerId;
        this.distributionKeyDescription = this.config?.data?.distributionKeyDescription;

        this.translateService
            .get('PAGES.DISTRIBUTION_KEYS.DELETE.TOAST_SUCCESS_MESSAGE')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((result) => (this.toastSuccessMessage = result));
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public submit(): void {
        this.isLoading = true;
        this.annualStatementService
            .deleteDistributionKey(this.ledgerId, this.annualStatementId, this.distributionKeyId)
            .pipe(
                takeUntil(this.unsubscribe$),
                finalize(() => (this.isLoading = false))
            )
            .subscribe({
                next: () => {
                    this.toastService.showSuccess(this.toastSuccessMessage);
                    this.saveEmitter$.next();
                    this.router.navigate([
                        '/accounting',
                        'ledger',
                        this.ledgerId,
                        'annual-statements',
                        this.annualStatementId,
                    ]);
                },
                error: (error) => {
                    if (error) {
                        this.toastService.showError(error.error['message']);
                    }
                },
            });
    }
}
