export enum CellTemplate {
    EuroCent = 'formattedCentTableItem',
    Default = 'customTableItem',
    Bold = 'boldTableItem',
    Date = 'formattedDateTableItem',
    DateTime = 'formattedDateTimeTableItem',
    NotFoundText = 'notFoundText',
    Image = 'imageTableItem',
    IconWithTooltip = 'iconWithTooltipItem',
    iconWithStatusItem = 'iconWithStatusItem',
    DynamicIcon = 'dynamicIconItem',
    iconButton = 'iconButtonItem',
    editButton = 'editButtonItem',
    button = 'buttonItem',
    textAndButton = 'textAndButtonItem',
    personWithAvatar = 'personWithAvatar',
    contentWithTagStyle = 'contentWithTagStyle',
    twoActionsButtons = 'twoActionsButtonsItem',
    apportionableLabel = 'apportionableLabelItem',
    filesActions = 'filesActionsItem',
    textWithIcon = 'textWithIconItem',
    CancelledBooking = 'textWithCancelledLabel',
    Actions = 'actionsItem',
    isSimpleLinkedItem = 'isSimpleLinkedItem',
    dynamicComponentItem = 'dynamicComponentItem',
    accountChartsButtons = 'accountChartsButtonsItem',
    badge = 'badgeItem',
    imageTableItemWithLabel = 'imageTableItemWithLabel',
}
