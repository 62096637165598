<app-overlay-details
    [overlayCallbacks]="overlayCallbacks"
    title="Gemeinschaftseigentum"
    tagColor="red"
    tagLabel="Sondernutzungsrechte"
    [itemListForPagination]="sharedOwnerhsipIdList"
    [currentItemId]="(sharedOwnershipCurrentId$ | async) ?? undefined"
    (changeItemOnOverlay)="findNewSharedOwnershipId($event)"
>
    <div class="tw-space-y-2">
        <div class="tw-grid tw-grid-cols-4">
            <div class="tw-col-span-1 tw-s-label-12-22-semibold tw-text-scalaraGray-03">
                {{ 'ENTITIES.SHARED_OWNERSHIP.LABEL_CATEGORY' | translate }}
            </div>
            <div
                *ngIf="sharedOwnership?.category; else notSpecifiedLabel"
                class="tw-col-start-2 tw-col-span-full tw-s-body-14-22-regular"
            >
                {{ sharedOwnership?.category }}
            </div>
        </div>

        <div class="tw-grid tw-grid-cols-4">
            <div class="tw-col-span-1 tw-s-label-12-22-semibold tw-text-scalaraGray-03">
                {{ 'ENTITIES.PROPERTY.LABEL_NAME' | translate }}
            </div>
            <div
                *ngIf="sharedOwnership?.title; else notSpecifiedLabel"
                class="tw-col-start-2 tw-col-span-full tw-s-body-14-22-regular"
            >
                {{ sharedOwnership?.title }}
            </div>
        </div>

        <div class="tw-grid tw-grid-cols-4">
            <div class="tw-col-span-1 tw-s-label-12-22-semibold tw-text-scalaraGray-03">
                {{ 'ENTITIES.SHARED_OWNERSHIP.LABEL_ASSIGNMENT' | translate }}
            </div>
            <div class="tw-col-start-2 tw-col-span-full tw-s-body-14-22-regular">
                {{ sharedOwnership?.buildingInfo?.name }}
            </div>
        </div>

        <!-- <div class="tw-grid tw-grid-cols-4">
            <div class="tw-col-span-1 tw-s-label-12-22-semibold tw-text-scalaraGray-03">Lage</div>
            <div class="tw-col-span-3 tw-s-body-14-22-regular">Vorne</div>
        </div> -->

        <div class="tw-grid tw-grid-cols-4" *ngIf="sharedOwnership?.ownershipInfo">
            <div class="tw-col-span-1 tw-s-label-12-22-semibold tw-text-scalaraGray-03">
                {{ 'ENTITIES.SHARED_OWNERSHIP.LABEL_SPECIAL_RIGHTS' | translate }}
            </div>
            <div class="tw-col-start-2 tw-col-span-full tw-s-body-14-22-regular">
                {{ sharedOwnership?.ownershipInfo?.name }}
            </div>
        </div>
    </div>
</app-overlay-details>

<ng-template #notSpecifiedLabel>
    <div class="s-body-14-22-italic">
        {{ 'PAGES.OWNERSHIP.DETAIL_VIEW.LABEL_NOT_SPECIFIED' | translate }}
    </div>
</ng-template>
