import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ListItem } from 'carbon-components-angular';
import {
    BehaviorSubject,
    Observable,
    Subject,
    combineLatest,
    filter,
    interval,
    map,
    shareReplay,
    switchMap,
    takeUntil,
    takeWhile,
    tap,
} from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { TooltipKey } from 'src/app/features/account-settings/services/custom-tooltip.service';
import { Property } from 'src/app/generated-sources/base';
import { EurocentPipe } from 'src/app/shared/pipes/eurocent.pipe';
import { CellTemplate } from 'src/app/shared/table/enums/cell-template';
import { HeaderTemplate } from 'src/app/shared/table/enums/header-template';
import { TableItem } from 'src/app/shared/table/interfaces/table-item';
import {
    GetStatus,
    formatDateYYYYMMDDWithoutHours,
    getStatusOpenItems,
    getTypeOpenItem,
    isNotFuture,
    sortOpenItems,
} from '../../../../core/utils/common';
import {
    LedgersService,
    OpenItemDto,
    OpenItemsService,
    RentReceivableAutoGenerationStatusDto,
} from '../../../../generated-sources/accounting';
import { AccountingFilterCustomService } from '../services/accounting-filter-custom.service';
import { EconomicPlanCustomService } from '../services/economic-plan-custom.service';
import { LedgerCustomService } from '../services/ledger-custom.service';

type Status = 'DUE' | 'OPEN' | 'PAYED';
interface FilterListItem extends ListItem {
    status: Status;
}

@Component({
    selector: 'app-advancements-overview',
    templateUrl: './advancements-overview.component.html',
    styleUrls: ['./advancements-overview.component.scss'],
})
export class AdvancementsOverviewComponent implements OnInit, OnDestroy {
    public ledgerId?: string;
    private unsubscribe$ = new Subject<void>();

    public isLoading = false;
    public isLedgerMVorSEV = true;
    public isNotFuture = isNotFuture;

    public showLiabilitiesTable$ = new BehaviorSubject<boolean>(false);

    public status: RentReceivableAutoGenerationStatusDto.StatusEnum =
        RentReceivableAutoGenerationStatusDto.StatusEnum.Activated;

    public ledger$ = this.ledgerCustomService.getLedger$().pipe(filter(Boolean));
    public datepicker$ = this.accountsFilterCustomService.getDatePickerSelectedDates$();

    public sumReceivables = 0;
    public sumLiabilities = 0;

    public filterAdvancementStatus$ = new BehaviorSubject<Status>('DUE');
    public openLiabilitiesTabOnInit = false;

    public filter: FilterListItem[] = [
        {
            content: 'Alle offenen',
            status: 'OPEN',
            selected: false,
        },
        {
            content: 'Fällige',
            status: 'DUE',
            selected: true,
        },
        {
            content: 'Geschlossene',
            status: 'PAYED',
            selected: false,
        },
    ];

    private openItemsByType(type: 'RECEIVABLES' | 'LIABILITIES'): Observable<OpenItemDto[]> {
        return combineLatest([
            this.ledger$.pipe(filter(Boolean)),
            this.filterAdvancementStatus$,
            this.datepicker$,
        ]).pipe(
            switchMap(([ledger, status, datepicker]) => {
                const isLedgerMVorSEV =
                    ledger?.type === Property.PropertyTypeEnum.Mv || ledger?.type === Property.PropertyTypeEnum.Sev;
                const dates = datepicker;
                const startDate = formatDateYYYYMMDDWithoutHours(dates.startDate);
                const endDate = formatDateYYYYMMDDWithoutHours(dates.endDate);
                return isLedgerMVorSEV
                    ? this.openItemsService.findRentReceivables(ledger.id, status, startDate, endDate, type)
                    : this.openItemsService.findAdvancements(ledger.id, status, startDate, endDate, type);
            }),
            shareReplay({ bufferSize: 1, refCount: true }),
            takeUntil(this.unsubscribe$)
        );
    }

    public openItemsReceivables$ = this.openItemsByType('RECEIVABLES');

    public openItemsLiabilities$ = this.openItemsByType('LIABILITIES');

    public liabilitiesTable = combineLatest([
        this.ledger$.pipe(filter(Boolean)),
        this.openItemsLiabilities$,
        this.filterAdvancementStatus$,
    ]).pipe(
        map(([ledger, openItems, status]) => {
            const sortedFiltered = sortOpenItems(openItems);
            const header = [
                'ENTITIES.OPEN_ITEM.STATUS',
                {
                    data: { key: 'COMMON.LABEL_DESCRIPTION', params: {} },
                    width: '300px',
                },
                'ENTITIES.OPEN_ITEM.DUE_DATE',
                'ACCOUNTING.OPEN-ITEMS.CREATED_AT',
                {
                    data: { key: 'ENTITIES.BOOKING.LABEL_TAX_SHARE', params: {} },
                    width: '120px',
                    template: HeaderTemplate.RightAligned,
                },
                {
                    data: { key: 'ENTITIES.OPEN_ITEM.AMOUNT', params: {} },
                    template: HeaderTemplate.RightAligned,
                },
                {
                    data: { key: 'ENTITIES.OPEN_ITEM.OUTSTANDING_BALANCE', params: {} },
                    template: HeaderTemplate.RightAligned,
                },
            ];
            if (!this.isLedgerMVorSEV) {
                header.splice(4, 1);

                header[1] = {
                    data: { key: 'ENTITIES.OPEN_ITEM.DESCRIPTION', params: {} },
                    width: '300px',
                };
                header.splice(1, 0, 'ENTITIES.OPEN_ITEM.GROUP');
            }
            this.sumLiabilities = 0;
            return {
                header,
                data: sortedFiltered.map((item) => {
                    this.sumLiabilities =
                        this.sumLiabilities + (status === 'PAYED' ? item.amount : item.outstandingBalance);
                    return this.createRow(item, ledger?.id ?? '');
                }),
            };
        })
    );

    public receivablesTable = combineLatest([
        this.ledger$.pipe(filter(Boolean)),
        this.openItemsReceivables$,
        this.filterAdvancementStatus$,
    ]).pipe(
        map(([ledger, openItems, status]) => {
            const sortedFiltered = sortOpenItems(openItems);
            const header = [
                'ENTITIES.OPEN_ITEM.STATUS',
                {
                    data: { key: 'COMMON.LABEL_DESCRIPTION', params: {} },
                    width: '300px',
                },
                'ENTITIES.OPEN_ITEM.DUE_DATE',
                'ACCOUNTING.OPEN-ITEMS.CREATED_AT',
                {
                    data: { key: 'ENTITIES.BOOKING.LABEL_TAX_SHARE', params: {} },
                    width: '120px',
                    template: HeaderTemplate.RightAligned,
                },
                {
                    data: { key: 'ENTITIES.OPEN_ITEM.AMOUNT', params: {} },
                    template: HeaderTemplate.RightAligned,
                },
                {
                    data: { key: 'ENTITIES.OPEN_ITEM.OUTSTANDING_BALANCE', params: {} },
                    template: HeaderTemplate.RightAligned,
                },
            ];
            if (!this.isLedgerMVorSEV) {
                header.splice(4, 1);

                header[1] = {
                    data: { key: 'ENTITIES.OPEN_ITEM.DESCRIPTION', params: {} },
                    width: '300px',
                };
                header.splice(1, 0, 'ENTITIES.OPEN_ITEM.GROUP');
            }

            this.sumReceivables = 0;
            return {
                header,
                data: sortedFiltered.map((item) => {
                    this.sumReceivables =
                        this.sumReceivables + (status === 'PAYED' ? item.amount : item.outstandingBalance);
                    return this.createRow(item, ledger?.id ?? '');
                }),
            };
        })
    );

    public constructor(
        public translateService: TranslateService,
        private openItemsService: OpenItemsService,
        private toastService: ToastService,
        private ledgerCustomService: LedgerCustomService,
        private cd: ChangeDetectorRef,
        private ledgersService: LedgersService,
        private eurocentPipe: EurocentPipe,
        private accountsFilterCustomService: AccountingFilterCustomService,
        private economicPlanCustomService: EconomicPlanCustomService,
        private router: Router
    ) {
        const routeState = this.router.getCurrentNavigation();
        if (routeState && routeState.extras.state) {
            this.openLiabilitiesTabOnInit = routeState.extras.state['openLiabilitiesTabOnInit'];
        }
    }

    public refreshData$ = new BehaviorSubject<void>(undefined);
    public wrapWithRefresh<TData>(obs: Observable<TData>): Observable<TData> {
        return this.refreshData$.pipe(switchMap(() => obs));
    }

    public initialStatus$ = this.ledger$.pipe(
        switchMap((ledger) => this.openItemsService.getRentReceivableGenerationStatus(ledger!.id)),
        shareReplay({ bufferSize: 1, refCount: true })
    );
    public statusFromRefreshPipe$ = new BehaviorSubject<RentReceivableAutoGenerationStatusDto.StatusEnum | null>(null);

    //  we do get status on init and then refresh status every 5 sec when generation is called
    //  the status from pipe should override the initial one
    public status$: Observable<RentReceivableAutoGenerationStatusDto.StatusEnum> = combineLatest([
        this.initialStatus$,
        this.statusFromRefreshPipe$,
    ]).pipe(
        map(([initialStatus, status]) => {
            if (status) {
                return status;
            }
            return initialStatus.status;
        }),
        tap((status) => (this.status = status))
    );

    public openingBalance$ = this.ledger$.pipe(switchMap((ledger) => this.ledgersService.getOpeningBalance(ledger.id)));

    public noActivatedInfo$: Observable<{ text1: string; text2: string; buttonDisabled: boolean } | undefined> =
        combineLatest([this.status$, this.openingBalance$]).pipe(
            map(([status, openingBalance]) => {
                if (status === 'NOT_ACTIVATED') {
                    return {
                        text1: openingBalance.bookingDate
                            ? 'NO_ACTIVATED_OPENING_BALANCE_TEXT_1'
                            : 'NO_ACTIVATED_NO_OPENING_BALANCE_TEXT_1',
                        text2: openingBalance.bookingDate
                            ? 'NO_ACTIVATED_OPENING_BALANCE_TEXT_2'
                            : 'NO_ACTIVATED_NO_OPENING_BALANCE_TEXT_2',
                        buttonDisabled: openingBalance.bookingDate ? false : true,
                    };
                }

                return undefined;
            })
        );

    // status chain is as follows: NOT_ACTIVATED -> IN_PROGRESS -> ACTIVATED
    // If the advancements are being generated currently,
    // we refresh advancements list Every 5 seconds until the advancements are activated.
    public refreshStatusPipe$ = combineLatest([this.ledger$, interval(5000)]).pipe(
        takeUntil(this.unsubscribe$),
        takeWhile(() => this.status == RentReceivableAutoGenerationStatusDto.StatusEnum.InProgress),
        switchMap(([ledger]) => this.openItemsService.getRentReceivableGenerationStatus(ledger!.id)),
        tap((status) => {
            this.statusFromRefreshPipe$.next(status.status);

            if (status.status == RentReceivableAutoGenerationStatusDto.StatusEnum.Activated) {
                this.toastService.showSuccess(
                    this.translateService.instant('PAGES.OPEN_ITEMS.RENT_GENERATION_SUCCESS_TOAST')
                );
                this.refreshData$.next();
            }
            this.cd.detectChanges();
        })
    );

    public ngOnInit(): void {
        this.status$.pipe(takeUntil(this.unsubscribe$)).subscribe((status) => (this.status = status));

        this.ledger$
            .pipe(
                filter(Boolean),
                tap((ledger) => {
                    this.ledgerId = ledger.id;
                    this.isLedgerMVorSEV =
                        ledger?.type === Property.PropertyTypeEnum.Mv || ledger?.type === Property.PropertyTypeEnum.Sev;
                })
            )
            .subscribe();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private createRow(openItem: OpenItemDto, ledgerId: string): TableItem[] {
        const link = `/accounting/ledger/${ledgerId}/advancements/${openItem.id}`;
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const dueDate = new Date(openItem.dueDate);
        dueDate.setHours(0, 0, 0, 0);

        const statusInfo: GetStatus = getStatusOpenItems(this.translateService, openItem.state, openItem.dueDate);
        const queryParams = {
            pageTitle: this.isLedgerMVorSEV ? 'Mietzahlungsdetails' : 'Vorschuss Details',
            openItemType: 'ADVANCEMENT',
        };

        const type =
            getTypeOpenItem(this.translateService, openItem.type) + (openItem.purpose ? ' ' + openItem.purpose : '');

        if (this.isLedgerMVorSEV) {
            return [
                {
                    data: {
                        label: statusInfo.status,
                        link,
                        textColor: statusInfo.color,
                        iconSrc: statusInfo.iconSrc,
                        extraData: { openItem, enableCustomSorting: true, queryParams },
                    },
                    template: CellTemplate.DynamicIcon,
                },
                {
                    data: {
                        label: openItem.description,
                        link,
                        extraData: { queryParams },
                    },
                    template: CellTemplate.Default,
                },
                {
                    data: {
                        label: openItem.dueDate,
                        link,
                        extraData: { queryParams },
                        textColor: dueDate <= today && openItem.state !== 'CLOSED' ? 's-red-01' : '',
                    },
                    template: CellTemplate.Date,
                },
                {
                    data: {
                        label: openItem.createdAt,
                        link,
                        extraData: { queryParams },
                    },
                    template: CellTemplate.Date,
                },
                {
                    data: {
                        label: this.calculateTaxShareAmount(openItem.vatRate, openItem.vatAmount),
                        link,
                        rightAligned: true,
                    },
                    template: CellTemplate.Default,
                },
                {
                    data: {
                        label: openItem.amount,
                        link,
                        extraData: { queryParams },
                    },
                    template: CellTemplate.EuroCent,
                },
                {
                    data: {
                        label: openItem.outstandingBalance,
                        link,
                        extraData: { queryParams },
                    },
                    template: CellTemplate.EuroCent,
                },
            ];
        }

        return [
            {
                data: {
                    label: statusInfo.status,
                    link,
                    textColor: statusInfo.color,
                    iconSrc: statusInfo.iconSrc,
                    extraData: { openItem, enableCustomSorting: true, queryParams },
                },
                template: CellTemplate.DynamicIcon,
            },
            {
                data: {
                    label: type,
                    link,
                    extraData: { queryParams },
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: openItem.description,
                    link,
                    extraData: { queryParams },
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: openItem.dueDate,
                    link,
                    extraData: { queryParams },
                    textColor: dueDate <= today && openItem.state !== 'CLOSED' ? 's-red-01' : '',
                },
                template: CellTemplate.Date,
            },
            {
                data: {
                    label: openItem.createdAt,
                    link,
                    extraData: { queryParams },
                },
                template: CellTemplate.Date,
            },
            {
                data: {
                    label: openItem.amount,
                    link,
                    extraData: { queryParams },
                },
                template: CellTemplate.EuroCent,
            },
            {
                data: {
                    label: openItem.outstandingBalance,
                    link,
                    extraData: { queryParams },
                },
                template: CellTemplate.EuroCent,
            },
        ];
    }

    public onTabSelected(): void {
        if (this.showLiabilitiesTable$.getValue()) {
            return;
        }
        this.showLiabilitiesTable$.next(true);
    }

    public updateFilteredSum(filteredSearchTable: any): void {
        //TODO: IS NOT WORKING
        // let summRef: 'sumLiabilities' | 'sumReceivables';
        // if (filteredSearchTable.searchId === 'LIABILITIES') {
        //     summRef = 'sumLiabilities';
        // } else {
        //     summRef = 'sumReceivables';
        // }
        // this[summRef] = 0;
        // filteredSearchTable.data.forEach((item: TableItem[]) => {
        //     const amountToAdd =
        //         filteredSearchTable.searchId.searchInputId === 'gezahlt'
        //             ? (item[5].data.label as number) // amount
        //             : (item[6].data.label as number); // outstanding balance
        //     this[summRef] = this[summRef] + amountToAdd;
        // });
        // console.log(this.sumLiabilities);
        // console.log(this.sumReceivables);
    }

    public executeRentGeneration(): void {
        if (!this.ledgerId) {
            return;
        }
        this.openItemsService
            .startRentReceivableGeneration(this.ledgerId)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (value) => {
                    this.status = value.status;
                    this.refreshStatusPipe$.subscribe();
                },
                error: (error) => {
                    if (error) {
                        this.toastService.showError(error.error['message']);
                    }
                },
            });
    }

    public calculateTaxShareAmount(vatRate: number | undefined, vatAmount: number | undefined): string {
        if (!vatRate || !vatAmount) {
            return '';
        }

        const calculatedvatRate = this.eurocentPipe.transform(vatAmount);
        return `${calculatedvatRate}€ (${vatRate}%)`;
    }

    public get tooltipInnerHtml(): { headline: string } {
        const headlineInnerHtml = this.isLedgerMVorSEV
            ? ''
            : this.translateService.instant('PAGES.TOOLTIPS.ADVANCEMENTS_OVERVIEW.HEADLINE');
        return { headline: headlineInnerHtml };
    }

    public TooltipKey = TooltipKey;

    public onSelectFilter(event: any): void {
        const status = event.item?.status;

        this.filterAdvancementStatus$.next(status);
    }
}
