import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, finalize, takeUntil, tap } from 'rxjs';
import { formatDateWithoutHhMmSs, getNameFromPerson } from 'src/app/core/utils/common';
import { DeleteRelationOverlayComponent } from 'src/app/features/relations/delete-relation-overlay/delete-relation-overlay.component';
import { RelationUpdateDto } from 'src/app/features/relations/interfaces';
import { AdviserRelationDto, Person, PropertiesService, Property } from 'src/app/generated-sources/base';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { OverlayRef } from 'src/app/shared/overlay/utils/overlay-ref';
import { CellTemplate } from 'src/app/shared/table/enums/cell-template';
import { TableItem } from 'src/app/shared/table/interfaces/table-item';
import { TableModel } from 'src/app/shared/table/interfaces/table-model';
import { PersonLocalService } from '../../property/services/person-local.service';
import { AddAdviserOverlayComponent } from '../add-adviser-overlay/add-adviser-overlay.component';
import { EditRelationOverlayComponent } from '../edit-relation-overlay/edit-relation-overlay.component';

@Component({
    selector: 'app-advisers',
    templateUrl: './advisers.component.html',
    styleUrls: ['./advisers.component.scss'],
})
export class AdvisersComponent implements OnInit {
    public isLoading = false;
    public isInitialLoading = false;
    private unsubscribe$ = new Subject<void>();
    private propertyId = '';

    public isManagerOfThisProperty = false;

    public originalRelation: RelationUpdateDto = {
        personId: '',
        propertyId: '',
        ownershipId: '',
        relationId: '',
        parent: '',
        startDate: new Date(),
    };

    @Input() public showHeader = true;

    @ViewChild('actions', { static: true })
    public actions?: TemplateRef<any>;

    public tableModel: TableModel = { data: [], header: [] };

    public constructor(
        private route: ActivatedRoute,
        private overlayService: OverlayService,
        private translateService: TranslateService,
        private propertiesService: PropertiesService,
        private personLocalService: PersonLocalService
    ) {}

    public ngOnInit(): void {
        this.isInitialLoading = true;
        this.propertyId = String(this.route.parent?.snapshot.paramMap.get('id'));

        this.loadAdvisers();
        this.initTableHeader();

        this.personLocalService
            .getRolesOfProperty()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((roles: Property.PermissionRolesEnum[] | null) => {
                this.isManagerOfThisProperty = roles
                    ? Object.values(roles).includes(Person.PermissionRolesEnum.PropertyManager)
                    : false;
            });
    }

    public loadAdvisers(): void {
        this.isLoading = true;
        this.propertiesService
            .findAdviserRelations(this.propertyId)
            .pipe(
                tap(
                    (advisers) =>
                        (this.tableModel.data = advisers.map((serviceProvider) => this.createRow(serviceProvider)))
                ),
                finalize(() => {
                    this.isLoading = false;
                    this.isInitialLoading = false;
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }

    private createRow(data: AdviserRelationDto): TableItem[] {
        const link = `/contacts/${data.person.id}`;
        const currentDate = new Date();
        let tableLabel = '';

        const transferObject: RelationUpdateDto = {
            personId: data.person.id,
            propertyId: this.propertyId,
            relationId: data.id ?? '',
            parent: 'advisers',
            startDate: new Date(data.from),
            person: data.person,
            ...(data.to ? { endDate: new Date(data.to) } : undefined),
        };

        const startDateToCalculateLabel = new Date(data.from);
        startDateToCalculateLabel.setHours(0);
        startDateToCalculateLabel.setMinutes(0);

        const endDateToCalculateLabel = data.to ? new Date(data.to) : null;
        if (endDateToCalculateLabel) {
            endDateToCalculateLabel.setHours(23);
            endDateToCalculateLabel.setMinutes(59);
        }

        if (
            (!endDateToCalculateLabel && startDateToCalculateLabel <= currentDate) ||
            (endDateToCalculateLabel &&
                endDateToCalculateLabel >= currentDate &&
                startDateToCalculateLabel <= currentDate)
        ) {
            tableLabel = this.translateService.instant('PAGES.RELATIONS.ADVISER.CURRENT_ADVISER');
        }

        return [
            {
                data: {
                    label: data.person ? getNameFromPerson(data.person) : 'Keine Person gefunden',
                    link,
                    extraData: { isUserRegistered: data.person.hasRegisteredUser },
                },
                template: CellTemplate.personWithAvatar,
            },
            {
                data: {
                    label: `${formatDateWithoutHhMmSs(data.from)} – ${formatDateWithoutHhMmSs(data.to)}`,
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: `${tableLabel}`,
                    link,
                    textColor: 'green',
                },
                template: CellTemplate.contentWithTagStyle,
            },
            {
                data: {
                    label: '',
                    extraData: {
                        transferObject,
                        rightAligned: true,
                    },
                },
                template: this.isManagerOfThisProperty ? this.actions : CellTemplate.Default,
            },
        ];
    }

    public openAddOwnerOverlay(): void {
        const data = {
            componentParent: 'app-advisers-overview',
            extraData: { propertyId: this.propertyId },
        };
        const ref = this.overlayService.open(AddAdviserOverlayComponent, { data });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
        this.saveEmittersUpdate(ref);
    }

    private initTableHeader(): void {
        this.tableModel.header = [
            'ENTITIES.PERSON.LABEL_FIRSTNAME_LASTNAME_COMPANY_NAME',
            'ENTITIES.RELATION_WITH_TIMECONSTRAINT.LABEL_FROM_TO',
            '',
            '',
        ];
    }

    private saveEmittersUpdate(ref: OverlayRef): void {
        ref.saveEmitter$.subscribe(() => {
            this.loadAdvisers();
        });
        ref.cancelEmitter$.subscribe(() => {
            ref.close();
        });
    }

    public openEditRelationOvervlay(rowData: RelationUpdateDto): void {
        const data = {
            extraData: {
                relation: rowData,
            },
        };
        const ref = this.overlayService.open(EditRelationOverlayComponent, { data });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
        this.saveEmittersUpdate(ref);
    }

    public openDeleteRelationOvervlay(rowData: RelationUpdateDto): void {
        const data = {
            relation: rowData,
        };
        const ref = this.overlayService.open(DeleteRelationOverlayComponent, { data });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
        this.saveEmittersUpdate(ref);
    }
}
