<div *ngIf="isLoading" class="is-loading">
    <div class="d-flex align-items-center justify-content-center mt-4r">
        <ibm-inline-loading> </ibm-inline-loading>
    </div>
</div>

<div [class.mt-3-75r]="!isEditOesOverlay">
    <div class="mb-0-5r s-head-16-24-bold">
        {{
            isEditOesOverlay
                ? 'Haben Sie schon alle Verbräuche erfasst?'
                : ('PAGES.OPERATIONS_EXPENSE_STATEMENTS.DETAILS_OPERATIONS_EXPENSE_STATEMENTS.LABEL_CONSUMPTION_DISTRIBUTION'
                  | translate)
        }}
    </div>
    <div
        class="d-flex justify-space-between mb-1-5r"
        [ngClass]="{ 'mb-1-5r': !isEditOesOverlay, 'mb-4r': isEditOesOverlay }"
    >
        <div class="s-body-14-22-regular">
            {{
                'PAGES.OPERATIONS_EXPENSE_STATEMENTS.DETAILS_OPERATIONS_EXPENSE_STATEMENTS.INFO_CONSUMPTION_DISTRIBUTION'
                    | translate
            }}
        </div>

        <app-scalara-button
            *ngIf="!isEditOesOverlay"
            variant="ghost"
            svgName="24_add.svg"
            (click)="openAddConsumptionDistributionKeyOverlay()"
        >
            {{ 'CONTROLS.BUTTONS.ADD' | translate }}
        </app-scalara-button>
    </div>

    <div *ngIf="isEditOesOverlay" class="s-body-14-22-regular mb-1r">Ihre bisher angegebenen Verbräuche:</div>

    <app-table
        emptyText="PAGES.OPERATIONS_EXPENSE_STATEMENTS.DETAILS_OPERATIONS_EXPENSE_STATEMENTS.EMPTY_CONSUMPTION_TABLE"
        [header]="tableModel.header"
        [data]="tableModel.data"
    >
    </app-table>
</div>
