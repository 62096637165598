import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Temporal } from '@js-temporal/polyfill';
import { TranslateService } from '@ngx-translate/core';
import { ListItem } from 'carbon-components-angular';
import { BaseState } from 'src/app/features/accounting/components/details-container/details-container.component';
import {
    AccountDto,
    BankAccount,
    BankAccountDto,
    BankTransactionDto,
    BankTransactionForListDto,
    BookingDto,
    EconomicPlanDto,
    OpenItemDto,
    SimpleOccupationOesDtoPerson,
    SimpleOccupationOesDtoUnit,
} from '../../generated-sources/accounting';
import {
    CreatePropertyFileDto,
    Occupation,
    OccupationForm,
    OwnerRelationDto,
    Person,
    Property,
    ServiceProviderRelationDto,
    Unit,
} from '../../generated-sources/base';
import { PersonType } from '../models/person-type.enum';

export function formControlHasError(control: UntypedFormControl, error: string): boolean {
    return control.hasError(error) && control.dirty;
}

export function formControl(form: UntypedFormGroup, key: string): UntypedFormControl {
    return form.controls[key] as UntypedFormControl;
}

export function unitTypeNameFromEnum(unit: Unit | SimpleOccupationOesDtoUnit): string {
    return UnitTypes.find((type) => type.type === unit.type)?.name || ' ';
}

export const UnitTypes = [
    { type: 'APARTMENT', name: 'Wohnung' },
    { type: 'BUSINESS', name: 'Gewerbe' },
    { type: 'PARKING_SPACE', name: 'Stellplatz' },
    { type: 'BASEMENT', name: 'Keller' },
    { type: 'GARDEN', name: 'Garten' },
    { type: 'TERRACE', name: 'Terrasse' },
    { type: 'CONSERVATORY', name: 'Wintergarten' },
    { type: 'OTHER', name: 'Sonstiges' },
];

export const AccountTypes = [
    { type: 'ASSET', name: 'Geldmittel' },
    { type: 'THIRD PARTY RECEIVABLE SERVICES', name: 'Forderung aus Leistung' },
    { type: 'OCCUPATION RECEIVABLE', name: 'Forderung aus Nutzung' },
    { type: 'EQUITY', name: 'Eigenkapital' },
    { type: 'THIRD PARTY PAYABLE TRANSITORY', name: 'Durchlaufende Posten' },
    { type: 'THIRD PARTY PAYABLE SERVICES', name: 'Verbindlichkeiten aus Leistung' },
    { type: 'EXPENSE', name: 'Ausgaben' },
    { type: 'REVENUE', name: 'Einnahmen' },
    { type: 'OWNERSHIP RECEIVABLE', name: 'Forderungen gegenüber Einheit' },
    { type: 'UNIT PAYABLE TRANSITORY', name: 'Verbindlichkeiten gegenüber Einheit' },
    { type: 'LOAN', name: 'Darlehen' },
    { type: 'RECEIVABLE', name: 'Forderungen' },
    { type: 'TRANSFER', name: 'Umbuchungen' },
    { type: 'FORWARD_BALANCE', name: 'Eröffnungsbilanz' },
    { type: 'LIABILITY', name: 'Verbindlichkeiten' },
    { type: 'OCCUPATION PAYABLE TRANSITORY', name: 'Verbindlichkeit gegenüber Nutzung' },
    { type: 'OCCUPATION EXPENSE', name: 'Ausgaben einer Nutzung' },
];

export function phoneNumberTypeFromEnum(key: string): string {
    return PhoneNumberTypes.find((type) => type.type === key)?.name || ' ';
}

export const PhoneNumberTypes = [
    { type: 'PRIVATE_MOBILE', name: 'privat, Mobil' },
    { type: 'PRIVATE_LANDLINE', name: 'privat, Festnetz' },
    { type: 'COMPANY_MOBILE', name: 'geschäftlich, Mobil' },
    { type: 'COMPANY_LANDLINE', name: 'geschäftlich, Festnetz' },
    { type: 'FAX', name: 'Fax' },
    { type: 'OTHER', name: 'Sonstiges' },
];

export enum ValidatorPatterns {
    EMAIL = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$',
    PHONE = '^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$',
}

const BankAccountTypes = [
    { type: 'Checking', name: 'Girokonto' },
    { type: 'Savings', name: 'Sparkonto' },
    { type: 'CreditCard', name: 'Kreditkarte' },
    { type: 'Security', name: 'Wertpapierkonto' },
    { type: 'Loan', name: 'Darlehenskonto' },
    { type: 'Membership', name: 'Mitgliedskonto' },
    { type: 'Bausparen', name: 'Bausparkonto' },
    { type: 'ManuallyAddedBankAccount', name: 'Manuell hinzugefügtes Konto' },
];

const PersonTypes = [
    { type: 'NAT_PERSON', name: 'Natürliche Person' },
    { type: 'COMPANY', name: 'Personenvereinigung' },
];

export function personTypeNameFromEnum(person: Person): string {
    return PersonTypes.find((type) => type.type === person.type)?.name || person.type;
}

export function accountTypeNameFromEnum(account: AccountDto): string {
    return AccountTypes.find((type) => type.type === account.type)?.name || account.type;
}

export function replaceAccountGroupNameIfGlobal(account: AccountDto): string {
    return account.accountGroup.name === 'global' ? '–' : account.accountGroup.name;
}

export function bankAccountTypeName(account: BankAccountDto | BankAccount): string {
    return BankAccountTypes.find((type) => type.type === account.type)?.name || account.type;
}

// https://stackoverflow.com/questions/10830357/javascript-toisostring-ignores-timezone-offset
export function getLocalISOTime(date: Date | string): string {
    const d = new Date(date);
    const utcd = Date.UTC(
        d.getFullYear(),
        d.getMonth(),
        d.getDate(),
        d.getHours(),
        d.getMinutes(),
        d.getSeconds(),
        d.getMilliseconds()
    );

    // removed localOffset like mentioned in the link
    return new Date(utcd).toISOString().replace('.000Z', '');
}

export function getNowWithoutHhMmSs(): Date {
    const now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    now.setMilliseconds(0);

    return now;
}

export function formatDateWithoutHhMmSs(date: Date | string | undefined): string {
    if (date) {
        date = new Date(date);
        const day = date.getDate() <= 9 ? `0${date.getDate()}` : date.getDate();
        const month = date.getMonth() + 1 <= 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
        const fullyear = date.getFullYear();
        return `${day}.${month}.${fullyear}`;
    }
    return '';
}

export function notFuture(control: UntypedFormControl): DateIsInFutureError | null {
    const now = getNowWithoutHhMmSs();
    const date = new Date(control.value);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);

    if (date > now) {
        return {
            dateInFuture: {
                dateInput: date,
            },
        };
    }
    return null;
}

export function notPast(control: UntypedFormControl): DateIsInPastError | null {
    const now = getNowWithoutHhMmSs();
    const date = new Date(control.value);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);

    if (date < now) {
        return {
            dateInPast: {
                dateInput: date,
            },
        };
    }
    return null;
}

export function formatYYYYMMDD_to_DDMMYYYY(dateString: string): string {
    const parts = dateString.split('-');
    const day = parts[2];
    const month = parts[1];
    const fullyear = parts[0];

    return `${day}.${month}.${fullyear}`;
}

export function getCurrentDateDDMMYYYY(): string {
    const now = new Date();
    const day = now.getDate() <= 9 ? `0${now.getDate()}` : now.getDate();
    const month = now.getMonth() + 1 <= 9 ? `0${now.getMonth() + 1}` : now.getMonth() + 1;
    const fullyear = now.getFullYear();

    return `${day}.${month}.${fullyear}`;
}

export function getMonthAndYearFromDate(dateString: string): string {
    const date = new Date(dateString);
    const month = date.getMonth() + 1 <= 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const fullyear = date.getFullYear();

    return `01-${month}-${fullyear}`;
}

/**
 *
 * @returns Return actually YYYY-MM-DD (JS does not allow '-'-Character in identifiers)
 */
export function getCurrentDateYYYY_MM_DD(): string {
    const today = new Date();
    return new Date(today.getTime() - today.getTimezoneOffset() * 60000).toISOString().split('T')[0];
}

interface DateIsInFutureError {
    dateInFuture: { dateInput: Date };
}

interface DateIsInPastError {
    dateInPast: { dateInput: Date };
}
export interface GetStatus {
    status: string;
    iconSrc: string;
    color: string;
    statusType?: 'due' | 'closed';
}
export function getStatusOpenItems(
    translateService: TranslateService,
    status: OpenItemDto['state'],
    date: string
): GetStatus {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const dueDate = new Date(date);
    dueDate.setHours(0, 0, 0, 0);

    let statusToReturn: GetStatus = { status: '', iconSrc: '', color: '' };

    switch (status) {
        case 'OPEN':
            if (dueDate <= today) {
                statusToReturn = {
                    status: translateService.instant('ENTITIES.OPEN_ITEM.STATUS_OPEN_DUE'),
                    iconSrc: '/assets/icons/24_open.svg',
                    color: 's-red-01',
                    statusType: 'due',
                };
            } else {
                statusToReturn = {
                    status: translateService.instant('ENTITIES.OPEN_ITEM.STATUS_OPEN'),
                    iconSrc: '/assets/icons/24_open.svg',
                    color: 's-orange-01',
                };
            }
            break;
        case 'PARTLY_CLOSED':
            if (dueDate <= today) {
                statusToReturn = {
                    status: translateService.instant('ENTITIES.OPEN_ITEM.STATUS_PARTLY_CLOSED_DUE'),
                    iconSrc: '/assets/icons/24_partly-closed.svg',
                    color: 's-red-01',
                    statusType: 'due',
                };
            } else {
                statusToReturn = {
                    status: translateService.instant('ENTITIES.OPEN_ITEM.STATUS_PARTLY_CLOSED'),
                    iconSrc: '/assets/icons/24_partly-closed.svg',
                    color: 's-purple-01',
                };
            }
            break;
        case 'CLOSED':
            statusToReturn = {
                status: translateService.instant('ENTITIES.OPEN_ITEM.STATUS_CLOSED'),
                iconSrc: '/assets/icons/24_closed.svg',
                color: 's-green-01',
                statusType: 'closed',
            };
            break;
        default:
            statusToReturn = {
                status: translateService.instant('ENTITIES.OPEN_ITEM.STATUS_OPEN'),
                iconSrc: '/assets/icons/24_open.svg',
                color: 's-orange-01',
            };
    }

    return statusToReturn;
}

// sort open items
function formatStatus(status: OpenItemDto['state'], date: string): 0 | 1 | 2 | 3 | 4 {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const dueDate = new Date(date);
    dueDate.setHours(0, 0, 0, 0);

    let valueToReturn: 0 | 1 | 2 | 3 | 4 | 5;

    if (status === 'OPEN') {
        valueToReturn = dueDate <= today ? 0 : 1;
    } else if (status === 'PARTLY_CLOSED') {
        valueToReturn = dueDate <= today ? 2 : 3;
    } else {
        valueToReturn = 4;
    }

    return valueToReturn;
}

export function getStatusTransaction(
    amount: number,
    amountAssignedToBookings: number,
    isFullyBooked?: boolean
): GetStatus {
    let statusToReturn: GetStatus = { status: '', iconSrc: '', color: '' };

    if (
        (amountAssignedToBookings == 0 && isFullyBooked) ||
        (amount !== 0 && amountAssignedToBookings == 0 && isFullyBooked)
    ) {
        statusToReturn = {
            status: 'Ignoriert',
            iconSrc: '/assets/icons/24_ignor.svg',
            color: 's-gray-03',
        };

        return statusToReturn;
    }

    if (amountAssignedToBookings > 0 && amount > amountAssignedToBookings) {
        statusToReturn = {
            status: 'Teilverbucht',
            iconSrc: '/assets/icons/24_partly-closed.svg',
            color: 's-purple-01',
        };
    } else if (amount >= amountAssignedToBookings && amountAssignedToBookings > 0) {
        statusToReturn = {
            status: 'Verbucht',
            iconSrc: '/assets/icons/24_closed.svg',
            color: 's-green-01',
        };
    } else {
        statusToReturn = {
            status: 'Unverbucht',
            iconSrc: '/assets/icons/24_open.svg',
            color: 's-orange-01',
        };
    }

    return statusToReturn;
}

function sortByStatus(openItemOne: OpenItemDto, openItemTwo: OpenItemDto): -1 | 0 | 1 {
    let valueToReturn: -1 | 0 | 1 = 0;
    const statusOne = formatStatus(openItemOne.state, openItemOne.dueDate);
    const statusTwo = formatStatus(openItemTwo.state, openItemTwo.dueDate);

    switch (statusOne) {
        case 0: //'offen (fällig)'
            if (statusOne === statusTwo) {
                valueToReturn = 0;
            } else {
                valueToReturn = -1;
            }
            break;
        case 2: // 'teilgeschlossen (fällig)'
            if (statusTwo === 0) {
                valueToReturn = 1;
            } else {
                valueToReturn = -1;
            }
            break;
        case 1: //'offen'
            if (statusTwo === 0 || statusTwo === 2) {
                valueToReturn = 1;
            } else {
                valueToReturn = -1;
            }
            break;
        case 3: //'teilgeschlossen'
            if (statusTwo === 0 || statusTwo === 2 || statusTwo === 1) {
                valueToReturn = 1;
            } else {
                valueToReturn = -1;
            }
            break;
        case 4:
            valueToReturn = 1;
            break;
        default:
            valueToReturn = 0;
    }

    return valueToReturn;
}

export function grouplySortOpenItemsByDateCallback(openItemOne: OpenItemDto, openItemTwo: OpenItemDto): -1 | 0 | 1 {
    const statusOne = formatStatus(openItemOne.state, openItemOne.dueDate);
    const statusTwo = formatStatus(openItemTwo.state, openItemTwo.dueDate);
    //  do not sort items with different states
    if (statusOne !== statusTwo) {
        return 0;
    }

    const date1 = new Date(openItemOne.dueDate);
    const date2 = new Date(openItemTwo.dueDate);
    if (date1 > date2) {
        return 1;
    }

    if (date1 < date2) {
        return -1;
    }

    return 0;
}

export function sortOpenItems(openItem: OpenItemDto[]): OpenItemDto[] {
    return openItem.sort(sortByStatus).sort(grouplySortOpenItemsByDateCallback);
}

export function defaultSortTransaction(transactions: BankTransactionForListDto[]): BankTransactionForListDto[] {
    const transactionsSortedByDate = [...transactions].sort((a, b) =>
        sortByDateCallback(a.valueDate, b.valueDate, 'desc')
    );
    return transactionsSortedByDate;
}

function sortByTransactionStatusCallback(
    transactionOne: BankTransactionForListDto,
    transactionTwo: BankTransactionForListDto
): -1 | 0 | 1 {
    let valueToReturn: -1 | 0 | 1 = 0;

    const statusOne = formatTransactionStatus(
        Math.abs(transactionOne.amount),

        Math.abs(transactionOne.amountAssignedToBookings || 0)
    );

    const statusTwo = formatTransactionStatus(
        Math.abs(transactionTwo.amount),

        Math.abs(transactionTwo.amountAssignedToBookings || 0)
    );

    if (statusOne === 0) {
        valueToReturn = -1;
    } else if (statusOne === 2 && statusTwo !== 0) {
        valueToReturn = -1;
    } else if (statusOne === 1) {
        valueToReturn = 1;
    } else {
        valueToReturn = 0;
    }

    return valueToReturn;
}

export function sortByDateCallback(dateStringA: string, dateStringB: string, mode: 'asc' | 'desc'): number {
    const dateA = new Date(dateStringA);
    const dateB = new Date(dateStringB);

    return mode === 'asc' ? dateA.valueOf() - dateB.valueOf() : dateB.valueOf() - dateA.valueOf();
}

function formatTransactionStatus(amount: number, amountAssignedToBookings: number): 0 | 1 | 2 {
    if (amountAssignedToBookings > 0 && amount > amountAssignedToBookings) {
        return 2; // Teilverbucht
    } else if (amount >= amountAssignedToBookings && amountAssignedToBookings > 0) {
        return 1; //Verbucht
    } else {
        return 0; //Unverbucht
    }
}

export function getNameFromPerson(person: Person | SimpleOccupationOesDtoPerson): string {
    if (!person) {
        return '–';
    }
    if (person.type == PersonType.COMPANY) {
        if (person.companyName) {
            return `${person.companyName}`;
        }
        return 'Firmenname';
    } else {
        if (person.firstName != null && person.lastName != null) {
            return `${person.firstName} ${person.lastName}`;
        }
        if (person.firstName == null && person.lastName != null) {
            return `${person.lastName}`;
        }
        if (person.firstName && !person.lastName) {
            return `${person.firstName}`;
        }
        return 'Vorname / Nachname';
    }
}

export function getAdressFromPerson(person: Person): string {
    if (person.address) {
        return `${person.address.streetName} ${person.address.streetNumber}, ${person.address.zipCode} ${person.address.area}`;
    }
    return 'Person hat keine Addresse';
}

export function getPersonDescriptionCombobox(data: Person | OwnerRelationDto): string {
    const person = 'person' in data ? data.person : data;
    const name = getNameFromPerson(person);
    const email: string | null = person.email ?? null;
    const address = [
        person.address?.streetName,
        person.address?.streetNumber,
        person.address?.area,
        person.address?.zipCode,
    ]
        .filter((entry) => entry)
        .join(' ');
    const postFix = email ? ` (${email})` : address ? ` (${address})` : '';
    return name + postFix;
}

export function formatNumber(amount: number): string {
    const value = new Intl.NumberFormat('de-DE', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(amount / 100);
    return value;
}

export function isNotFuture({ form, controlName }: { form: UntypedFormGroup; controlName: string }): boolean {
    return formControlHasError(formControl(form, controlName), 'dateInFuture');
}

/**
 * Generates a Universally Unique Identifier
 * @returns uuid
 */
export function uuid(): string {
    let uuidValue = '',
        k,
        randomValue;
    for (k = 0; k < 32; k++) {
        randomValue = (Math.random() * 16) | 0;

        if (k == 8 || k == 12 || k == 16 || k == 20) {
            uuidValue += '-';
        }
        uuidValue += (k == 12 ? 4 : k == 16 ? (randomValue & 3) | 8 : randomValue).toString(16);
    }
    return uuidValue;
}

export function isFileValid(fileName: string, allowedExtensions: string[]): boolean {
    if (fileName) {
        const a = fileName.split('.');
        const fileExtension = a[a.length - 1];
        return allowedExtensions.indexOf(fileExtension) >= 0;
    } else {
        return false;
    }
}

export function getColorOfBookingTypeTag(label: string): string {
    if (label == 'Einnahme') {
        return 'green';
    }
    if (label == 'Storno') {
        return 'red';
    }
    if (label == 'Ausgabe') {
        return 'orange';
    }
    if (label == 'Manuell') {
        return 'blue';
    }
    if (label == 'Anfangsbestand') {
        return 'gray';
    }
    return 'gray';
}

export function getColorOfPersonTypeTag(person: Person | undefined): string {
    if (!person) {
        return 'gray';
    }

    if (person.type == PersonType.NAT_PERSON) {
        return 'green';
    }
    if (person.type == PersonType.COMPANY) {
        return 'purple';
    }
    return 'gray';
}

export function getLabelFromBookingType(
    translateService: TranslateService,
    type?: BookingDto.TypeEnum,
    isExpense?: boolean
): string {
    if (type === BookingDto.TypeEnum.Manual) {
        return translateService.instant('ENTITIES.BOOKING.LABEL_TYPE_MANUAL');
    }
    if (type === BookingDto.TypeEnum.OpeningBalance) {
        return translateService.instant('ENTITIES.BOOKING.LABEL_TYPE_OPENING_BALANCE');
    }
    if (type === BookingDto.TypeEnum.Cancellation) {
        return translateService.instant('ENTITIES.BOOKING.LABEL_TYPE_CANCELLATION');
    }
    if (type === BookingDto.TypeEnum.Guided && isExpense) {
        return translateService.instant('ENTITIES.BOOKING.LABEL_TYPE_IS_EXPENSE');
    }
    if (type === BookingDto.TypeEnum.Guided && !isExpense) {
        return translateService.instant('ENTITIES.BOOKING.LABEL_TYPE_IS_NOT_EXPENSE');
    }
    return '';
}

export function calculateDifferDays(inputStartDate: Date, inputEndDate: Date): number {
    const startDate = Temporal.PlainDate.from({
        year: inputStartDate.getFullYear(),
        month: inputStartDate.getMonth() + 1,
        day: inputStartDate.getDate(),
    });

    const endDate = Temporal.PlainDate.from({
        year: inputEndDate.getFullYear(),
        month: inputEndDate.getMonth() + 1,
        day: inputEndDate.getDate(),
    });

    return startDate.until(endDate).days + 1;
}

export function isValidBusinessYear(startDate: Date, endDate: Date): boolean {
    // https://stackoverflow.com/questions/8609261/how-to-determine-one-year-from-now-in-javascript
    const startDatePlusOneYear = new Date(startDate);
    startDatePlusOneYear.setFullYear(startDatePlusOneYear.getFullYear() + 1);
    return endDate < startDatePlusOneYear && startDate <= endDate;
}

export function isTrunkYear(startDate: Date, endDate: Date): boolean {
    const calculatedMaxEndDate = new Date(startDate);
    calculatedMaxEndDate.setFullYear(calculatedMaxEndDate.getFullYear() + 1);
    calculatedMaxEndDate.setDate(calculatedMaxEndDate.getDate() - 1);
    return endDate.getTime() < calculatedMaxEndDate.getTime() && isValidBusinessYear(startDate, endDate);
}

export function isDateInputOverlappingOtherPlans(
    startDate: Date,
    endDate: Date,
    economicPlans: EconomicPlanDto[]
): boolean {
    let overlap = false;
    let i = 0;
    const inputStartTime = startDate.getTime();
    const inputEndTime = endDate.getTime();
    while (!overlap && i < economicPlans.length) {
        const intervalStartTime = new Date(economicPlans[i].startDate).getTime();
        const intervalEndTime = new Date(economicPlans[i].endDate).getTime();
        overlap =
            (intervalStartTime < inputStartTime && inputEndTime < intervalEndTime) ||
            (intervalStartTime < inputStartTime && inputStartTime < intervalEndTime) ||
            (intervalStartTime < inputEndTime && inputEndTime < intervalEndTime);
        i++;
    }

    return overlap;
}

export function getTypeOpenItem(translateService: TranslateService, type?: OpenItemDto.TypeEnum): string {
    if (type === OpenItemDto.TypeEnum.Advancement) {
        return translateService.instant('ENTITIES.OPEN_ITEM.ADVANCEMENT');
    }
    if (type === OpenItemDto.TypeEnum.Liability) {
        return translateService.instant('ENTITIES.OPEN_ITEM.LIABILITY');
    }
    if (type === OpenItemDto.TypeEnum.Receivable) {
        return translateService.instant('ENTITIES.OPEN_ITEM.RECEIVABLE');
    }
    return '';
}
export interface GetStatusBankConnection {
    status: 'soon' | 'expired' | 'notExpired' | 'undefined';
    daysLeftToExpire: number;
}

export function getStatusBankConnection(consentExpiresAt: string): GetStatusBankConnection {
    let status: 'soon' | 'expired' | 'notExpired' | 'undefined' = 'undefined';
    const today = new Date();
    const consentExpiresDate = new Date(consentExpiresAt);

    consentExpiresDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    const daysLeftToExpire = Math.floor((consentExpiresDate.getTime() - today.getTime()) / 1000 / 60 / 60 / 24);

    if (daysLeftToExpire > 0 && daysLeftToExpire <= 7) {
        status = 'soon';
    } else if (daysLeftToExpire <= 0) {
        status = 'expired';
    } else {
        status = 'notExpired';
    }
    return { status, daysLeftToExpire };
}

export function getServiceProviderType(
    translateService: TranslateService,
    type: ServiceProviderRelationDto.ServiceProvidedEnum
): string {
    return translateService.instant(`ENTITIES.SERVICE_PROVIDER.${type}`);
}

export function sortAccountList(a: ListItem, b: ListItem): -1 | 0 | 1 {
    if (a.content.toLowerCase() < b.content.toLowerCase()) {
        return -1;
    }
    if (a.content.toLowerCase() > b.content.toLowerCase()) {
        return 1;
    }
    return 0;
}

export function getFileCategory(
    translateService: TranslateService,
    type: CreatePropertyFileDto.FileCategoryEnum
): string {
    return translateService.instant(`ENTITIES.DOCUMENT.${type}`);
}

export function genereateBasicFileCategoriesListItems(translateService: TranslateService): ListItem[] {
    const array: ListItem[] = [];
    for (const value of Object.values(CreatePropertyFileDto.FileCategoryEnum)) {
        array.push({
            content: translateService.instant(`ENTITIES.DOCUMENT.${value}`),
            selected: false,
            value: value,
        });
    }
    return array;
}

export function asString(input: unknown): string {
    return String(input);
}
export interface PropertyTypeInfosInterface {
    iconSrc: string;
    label: 'Mietverwaltung' | 'Sondereigentumsverwaltung' | 'Wohnungseigentumsverwaltung';
    color: 'orange' | 'green' | 'purple';
}

export function getPropertyTypeInfos(propertyType: Property.PropertyTypeEnum): PropertyTypeInfosInterface {
    if (propertyType === 'SEV') {
        return { color: 'orange', label: 'Sondereigentumsverwaltung', iconSrc: '40_door.svg' };
    } else if (propertyType === 'MV') {
        return { color: 'purple', label: 'Mietverwaltung', iconSrc: '40_building-MV.svg' };
    }
    return { color: 'green', label: 'Wohnungseigentumsverwaltung', iconSrc: '40_buildings-WEG.svg' };
}

export function calculateTaxAmount(rate: number, amount: number): number {
    return amount - amount / (rate / 100 + 1);
}

export function formatDateYYYYMMDDWithoutHours(date: Date | string | undefined): string {
    if (date) {
        date = new Date(date);
        const day = date.getDate() <= 9 ? `0${date.getDate()}` : date.getDate();
        const month = date.getMonth() + 1 <= 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
        const fullyear = date.getFullYear();
        return `${fullyear}-${month}-${day}`;
    }
    return '';
}

//This is helpful whean dealing with dates (without time) from typeorm
//Becase typeorm return the dates without time as 00:00 in the local timezone (When Date.toIsoString() is called this leads to a day before the actual date)
//Example input: 2022-12-31T23:00:00.000Z output => 2023-01-01
export function convertToISODateString(date: Date): string {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0];
}

export function getOccupationTypeLabel(
    occupationType?: Occupation.OccupationTypeEnum,
    rentType?: OccupationForm.RentTypeEnum,
    showRentType = true
): string {
    if (!occupationType) {
        return 'Vermietung';
    }
    if (occupationType === 'RENTING') {
        if (showRentType) {
            return rentType === 'PRIVATE' ? 'Vermietung, privat' : 'Vermietung, Gewerblich';
        } else {
            return 'Vermietung';
        }
    } else {
        return occupationType === 'OWN_OCCUPATION' ? 'Selbstnutzung' : 'Leerstand';
    }
    //
}
export function isValueSet(value: unknown): boolean {
    return value !== undefined && value !== null;
}

export function getRestAmount(bankTransaction: BankTransactionDto): number {
    if (!bankTransaction) {
        return 0;
    }
    const bookingsAmountSummary = bankTransaction.amountAssignedToBookings;
    const restAmount =
        bankTransaction.amount -
        ((bookingsAmountSummary ?? 0) * Math.abs(bankTransaction.amount)) / bankTransaction.amount;

    if ((isNaN(restAmount) || !restAmount) && restAmount !== 0) {
        return 0;
    }

    return restAmount;
}

// If the effective year is a full year, we show only the year number.
export function showEffectiveYear(effectiveFrom: string, effectiveTo: string): string {
    const startDate = formatDateWithoutHhMmSs(effectiveFrom)?.split('.');
    const finishDate = formatDateWithoutHhMmSs(effectiveTo)?.split('.');
    if (startDate && finishDate) {
        if (
            startDate[0] === '01' &&
            startDate[1] === '01' &&
            finishDate[0] === '31' &&
            finishDate[1] === '12' &&
            startDate[2] === finishDate[2]
        ) {
            return startDate[2];
        } else {
            return startDate.join('.') + ' – ' + finishDate.join('.');
        }
    } else {
        return '';
    }
}

export function isCurrentDateWithinRange(from: string, to?: string): boolean {
    const currentDate = new Date();

    const startDate = new Date(from);
    startDate.setHours(0);
    startDate.setMinutes(0);

    const endDate = to ? new Date(to) : null;
    if (endDate) {
        endDate.setHours(23);
        endDate.setMinutes(59);
    }

    return (!endDate && startDate <= currentDate) || (endDate && endDate >= currentDate && startDate <= currentDate)
        ? true
        : false;
}

export function getBankTransactionState(bankTransaction: BankTransactionDto): BaseState {
    const transactionAmount = bankTransaction.amount;
    const bookingsAmountSummary = bankTransaction.amountAssignedToBookings;
    if (bookingsAmountSummary == 0 && bankTransaction.isFullyBooked) {
        return 'ignored';
    }
    if (bookingsAmountSummary === undefined || bookingsAmountSummary === 0) {
        return 'attention';
    }
    if (bookingsAmountSummary < Math.abs(transactionAmount)) {
        return 'warning';
    }
    return 'ok';
}

export function formatNumberWVDD(n: number): string {
    if (!n) {
        return '';
    }
    return n.toLocaleString('de-DE', { maximumFractionDigits: 8 });
}

//When calculating with floating point numbers we can easily get strange results
//For example 0.1 + 0.2 = 0.30000000000000004
//or 12.14 + 12.12 = 24.259999999999998
//With this approach we create a new number which is printed out in the expected way

export function stabilizeNumber(input: number): number {
    if (!input.toFixed) {
        return input;
    }
    return Number(input.toFixed(8));
}
