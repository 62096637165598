<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<div ibmGrid class="bx--grid--full-width pb-10r" *ngIf="!this.isLoading">
    <div ibmRow>
        <div ibmCol></div>
        <div ibmCol [columnNumbers]="{ md: 10, lg: 10 }" class="px-00">
            <div ibmRow [ngClass]="['s-head-28-36-bold', 'mb-11']">
                <div class="d-flex align-items-center">
                    <div class="mr-06">
                        <app-icon-bubble image="40_euro.svg" [size]="72" [fillNone]="false"></app-icon-bubble>
                    </div>
                    <div>{{ 'ACCOUNTING.OPENING_BALANCE.HEADLINE' | translate }}</div>
                </div>
            </div>

            <form (submit)="onSubmit()" (keydown.enter)="$event.preventDefault()" [formGroup]="form">
                <div *ngIf="config?.data.isLedgerMVorSEV" class="s-head-16-24-bold mb-1-5r">
                    {{ 'ACCOUNTING.OPENING_BALANCE.PERIOD_HEADER' | translate }}
                </div>
                <div *ngIf="config?.data.isLedgerMVorSEV" ibmRow class="mb-4r">
                    <div ibmCol [columnNumbers]="{ md: 6, lg: 6 }">
                        <ibm-date-picker
                            label="{{ 'COMMON.LABEL_START_DATE' | translate }}*"
                            id="startDate"
                            [placeholder]="'tt.mm.jjjj'"
                            [size]="'md'"
                            [language]="'de'"
                            [dateFormat]="'d.m.Y'"
                            [invalid]="form.dirty && !isDateInputValid()"
                            invalidText="{{ 'ACCOUNTING.ERROR.INVALID_BOOKINGDATE' | translate }}"
                            formControlName="startDate"
                        >
                        </ibm-date-picker>
                    </div>
                </div>
                <div class="s-head-16-24-bold mb-1-5r">
                    {{ 'ACCOUNTING.OPENING_BALANCE.DATE_HEADER' | translate }}
                </div>
                <div ibmRow class="mb-04">
                    <div ibmCol [columnNumbers]="{ md: 6, lg: 6 }">
                        <ibm-date-picker
                            [label]="dateLabelWithTooltip"
                            id="bookingDate"
                            [placeholder]="'tt.mm.jjjj'"
                            [size]="'md'"
                            [language]="'de'"
                            [dateFormat]="'d.m.Y'"
                            [invalid]="form.dirty && !isDateInputValid()"
                            invalidText="{{ 'ACCOUNTING.ERROR.INVALID_BOOKINGDATE' | translate }}"
                            formControlName="bookingDate"
                        >
                        </ibm-date-picker>
                    </div>
                </div>
                <div ibmRow class="mb-04">
                    <div ibmCol>
                        <div class="d-flex align-items-center mt-1r mb-6r">
                            <svg-icon src="/assets/icons/24_information.svg" class="svg-container"></svg-icon>
                            <span class="s-head-14-22-regular ml-0-75r">{{
                                (config?.data.isLedgerMVorSEV
                                    ? 'ACCOUNTING.OPENING_BALANCE.DATE_SUBTEXT_2'
                                    : 'ACCOUNTING.OPENING_BALANCE.DATE_SUBTEXT_1'
                                ) | translate
                            }}</span>
                        </div>
                        <div *ngFor="let overlayAccountsGroup of overlayAccounts | keyvalue">
                            <!-- hide whole expensesAndRevenues table if date is 01.01.YYYY -->
                            <div
                                [formArrayName]="overlayAccountsGroup.key"
                                class="mb-6r"
                                [hidden]="isTableHidden(overlayAccountsGroup.key)"
                            >
                                <div class="s-head-16-24-bold mb-1r">
                                    {{
                                        'ACCOUNTING.OPENING_BALANCE.' +
                                            overlayAccountsGroup.key.toUpperCase() +
                                            '.HEADER' | translate
                                    }}
                                </div>
                                <div class="s-head-14-22-regular mb-2r">
                                    {{
                                        'ACCOUNTING.OPENING_BALANCE.' +
                                            overlayAccountsGroup.key.toUpperCase() +
                                            '.SUBHEADER' | translate
                                    }}
                                </div>
                                <div class="custom-table">
                                    <ng-container>
                                        <div class="custom-table__header-item s-label-12-22-semibold">
                                            {{ 'ACCOUNTING.OPENING_BALANCE.NAME' | translate }}
                                        </div>
                                        <div class="custom-table__header-item s-label-12-22-semibold">
                                            {{ 'ACCOUNTING.OPENING_BALANCE.NAME' | translate }}
                                        </div>
                                        <div class="custom-table__header-item s-label-12-22-semibold"></div>
                                        <div class="custom-table__header-item s-label-12-22-semibold">
                                            {{ 'ACCOUNTING.OPENING_BALANCE.AMOUNT' | translate }}
                                        </div>
                                    </ng-container>
                                    <!-- hide cells via isCellHidden function -->
                                    <ng-container
                                        *ngFor="
                                            let control of getFormArray(overlayAccountsGroup.key)?.controls;
                                            let index = index
                                        "
                                    >
                                        <div
                                            class="custom-table__data-item s-body-14-22-semibold"
                                            [hidden]="
                                                isCellHidden({
                                                    overlayAccountsArrayKey: overlayAccountsGroup.key,
                                                    overlayAccountsArrayIndex: index
                                                })
                                            "
                                        >
                                            <app-text-with-tooltip
                                                class="tw-inline-block"
                                                [label]="overlayAccountsGroup.value[index].name"
                                                [labelClass]="''"
                                                [tooltipKey]="TooltipKey.tooltipsInAccounting"
                                                [tooltipInnerHtml]="
                                                    getTooltipTextForAccount(overlayAccountsGroup.value[index])
                                                "
                                            ></app-text-with-tooltip>
                                        </div>
                                        <div
                                            class="custom-table__data-item s-body-14-22-semibold"
                                            [hidden]="
                                                isCellHidden({
                                                    overlayAccountsArrayKey: overlayAccountsGroup.key,
                                                    overlayAccountsArrayIndex: index
                                                })
                                            "
                                        >
                                            {{ overlayAccountsGroup.value[index].description || '–' }}
                                        </div>
                                        <div
                                            class="custom-table__data-item"
                                            [hidden]="
                                                isCellHidden({
                                                    overlayAccountsArrayKey: overlayAccountsGroup.key,
                                                    overlayAccountsArrayIndex: index
                                                })
                                            "
                                        >
                                            <app-apportionable-label
                                                [account]="overlayAccountsGroup.value[index]"
                                            ></app-apportionable-label>
                                        </div>
                                        <div
                                            class="custom-table__data-item custom-table__data-item--input-container"
                                            [hidden]="
                                                isCellHidden({
                                                    overlayAccountsArrayKey: overlayAccountsGroup.key,
                                                    overlayAccountsArrayIndex: index
                                                })
                                            "
                                        >
                                            <app-number-input
                                                [invalid]="false"
                                                invalidText="{{ 'ACCOUNTING.ERROR.INVALID_AMOUNT' | translate }}"
                                                formControlName="{{ index }}"
                                                class="app-number-input"
                                            ></app-number-input>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div ibmRow class="buttons-container">
                    <div ibmCol>
                        <button [ibmButton]="'tertiary'" (click)="abort()">
                            {{ 'ACCOUNTING.COMMON.BTN_CANCEL' | translate }}
                        </button>
                    </div>

                    <div ibmCol class="text-right">
                        <button
                            [ibmButton]="'primary'"
                            [disabled]="!this.form.valid || !this.form.get('bookingDate')?.value[0]"
                            type="submit"
                        >
                            {{ 'ACCOUNTING.COMMON.BTN_SAVE' | translate }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <div ibmCol></div>
    </div>
</div>

<ng-template #dateLabelWithTooltip>
    <app-text-with-tooltip
        class="tw-inline-block"
        label="{{ 'ACCOUNTING.OPENING_BALANCE.DATE' | translate }}{{ '*' }}"
        [labelClass]="''"
        [tooltipKey]="TooltipKey.tooltipsInAccounting"
        [tooltipInnerHtml]="'PAGES.TOOLTIPS.OPENING_BALANCE.DATE' | translate"
    ></app-text-with-tooltip
></ng-template>
