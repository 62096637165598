import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GuidedBookingTypeShortDto } from 'src/app/generated-sources/accounting';

@Component({
    selector: 'app-expandable-tile-selector',
    templateUrl: './expandable-tile-selector.component.html',
    styleUrls: ['./expandable-tile-selector.component.scss'],
})
export class ExpandableTileSelectorComponent {
    @Input()
    public selectorTitle = '';

    @Input()
    public selectorDescription = '';

    @Input()
    public isExpense = false;

    @Input()
    public tileSelectorOptions: GuidedBookingTypeShortDto[] = [];

    @Output()
    public optionSelected = new EventEmitter<GuidedBookingTypeShortDto>();

    public isSelectOpen = false;

    public bookingTypes: string[] = [
        'Geldeingang buchen',
        'Geldausgang buchen',
        'Mietzahlungen',
        'Umbuchen',
        'Abgrenzen',
        'Forderung eröffnen',
        'Vorschüsse',
        'Vorratsbrennstoff',
    ];

    public changeSelectDisplay(): void {
        this.isSelectOpen = !this.isSelectOpen;
    }

    public emitSelection(selectedOption: GuidedBookingTypeShortDto): void {
        this.optionSelected.emit(selectedOption);
    }

    public isKnownGroup(selectorTitle: string): boolean {
        return this.bookingTypes.includes(selectorTitle);
    }
}
