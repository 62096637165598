<ibm-loading *ngIf="isLoading" [isActive]="true" [size]="'normal'" [overlay]="true"></ibm-loading>
<div [ngClass]="{ 'tw-px-10': !ledgerId, 'tw-pt-6': !ledgerId, 'tw-pb-10': !ledgerId }">
    <div [class]="ledgerId ? 'tw-mb-6' : 'tw-mb-12'">
        <ibm-breadcrumb [items]="breadcrumbs" [noTrailingSlash]="true"> </ibm-breadcrumb>
    </div>

    <div class="tw-flex tw-mb-8 tw-items-center">
        <div class="tw-bg-scalaraGray-07 tw-mr-10 tw-w-[72px] tw-h-[72px] tw-flex tw-items-center tw-justify-center">
            <img
                *ngIf="bankConnection?.bankLogoUrl"
                [src]="bankConnection?.bankLogoUrl"
                alt=""
                class="tw-max-w-full tw-max-h-full tw-p-2"
            />
            <svg-icon
                *ngIf="!bankConnection?.bankLogoUrl"
                class="tw-fill-scalaraGray-04 tw-h-10 tw-w-10"
                src="/assets/icons/40_bank.svg"
            ></svg-icon>
        </div>
        <div class="s-head-14-22-bold tw-mr-4">{{ bankConnection?.bankName }}</div>
        <div class="s-head-14-22-bold">{{ bankConnection?.bic }}</div>
    </div>

    <div>
        <ibm-tabs [followFocus]="true" [cacheActive]="true">
            <ibm-tab [heading]="customTabHeadingMoneyTransfer">
                <ng-template #customTabHeadingMoneyTransfer>
                    <ng-container
                        *ngTemplateOutlet="
                            customTabTemplate;
                            context: { title: 'Überweisungen', paymentAmount: openPaymentsMoneyTransfer }
                        "
                    >
                    </ng-container>
                </ng-template>
                <div>
                    <div class="tw-flex tw-justify-between tw-mt-6 tw-mb-4 tw-items-center">
                        <div>
                            <ibm-checkbox
                                [checked]="isSelectAllChecked"
                                (change)="selectAllPayments($event, 'MONEY_TRANSFER')"
                            >
                                <span class="ml-0-5r">{{ 'CONTROLS.BUTTONS.SELECT_ALL' | translate }}</span>
                            </ibm-checkbox>
                        </div>
                        <div class="tw-flex tw-space-x-4">
                            <app-search searchInputId="MONEY_TRANSFER"></app-search>
                            <app-scalara-button
                                (click)="exportPayments('MONEY_TRANSFER')"
                                svgName="24_download.svg"
                                variant="ghost"
                                >Exportieren
                            </app-scalara-button>
                        </div>
                    </div>

                    <ng-container>
                        <div class="tw-mb-2">
                            <app-banking-payments-approval-table
                                [paymentTable]="moneyTransferTable"
                                (openWebForm)="openWebForm($event)"
                                [paymentType]="'MONEY_TRANSFER'"
                                [ledgerId]="ledgerId"
                            ></app-banking-payments-approval-table>
                        </div>
                    </ng-container>
                </div>
            </ibm-tab>
            <ibm-tab [heading]="customTabHeadingDirectMoney">
                <ng-template #customTabHeadingDirectMoney>
                    <ng-container
                        *ngTemplateOutlet="
                            customTabTemplate;
                            context: { title: 'Lastschriften', paymentAmount: openPaymentsDirectDebit }
                        "
                    >
                    </ng-container>
                </ng-template>
                <div>
                    <div class="tw-flex tw-justify-between tw-mt-6 tw-mb-4 tw-items-center">
                        <div>
                            <!-- [checked]="isSelectAllChecked"  -->
                            <ibm-checkbox
                                [checked]="isSelectAllChecked"
                                (change)="selectAllPayments($event, 'DIRECT_DEBIT')"
                            >
                                <span class="ml-0-5r">{{ 'CONTROLS.BUTTONS.SELECT_ALL' | translate }}</span>
                            </ibm-checkbox>
                        </div>
                        <div class="tw-flex tw-space-x-4">
                            <app-search searchInputId="DIRECT_DEBIT"></app-search>
                            <app-scalara-button
                                (click)="exportPayments('DIRECT_DEBIT')"
                                svgName="24_download.svg"
                                variant="ghost"
                                >Exportieren
                            </app-scalara-button>
                        </div>
                    </div>

                    <ng-container>
                        <div class="tw-mb-2">
                            <app-banking-payments-approval-table
                                [paymentTable]="directDebitTable"
                                (openWebForm)="openWebForm($event)"
                                [paymentType]="'DIRECT_DEBIT'"
                                [ledgerId]="ledgerId"
                            ></app-banking-payments-approval-table>
                        </div>
                    </ng-container>
                </div>
            </ibm-tab>
        </ibm-tabs>
    </div>
</div>

<ng-template #deletePayment let-data="data">
    <div class="tw-p-2 tw-flex tw-justify-end tw-items-center">
        <app-scalara-button
            (click)="openDeletePaymentOverlay(data.payment)"
            variant="icon-only"
            height="40px"
            svgName="24_delete.svg"
        ></app-scalara-button>
    </div>
</ng-template>

<ng-template #addEditPayment let-data="data">
    <div class="tw-p-2 tw-flex tw-justify-end tw-items-center">
        <app-scalara-button
            (click)="openAddEditPaymentOverlay(data.payment)"
            variant="icon-only"
            height="40px"
            svgName="24_edit.svg"
        ></app-scalara-button>
    </div>
</ng-template>

<ng-template #checkboxPayment let-data="data">
    <div class="tw-flex tw-justify-start tw-items-center tw-pl-4">
        <ibm-checkbox
            [disabled]="data?.disabled"
            [formControl]="paymentControl(data.paymentType, data.payment?.id)"
            (change)="onCheckboxChange($event, data.payment?.id, data.paymentType)"
        >
        </ibm-checkbox>
        <app-tooltip position="right">
            <div content class="tw-p-6 tw-w-[360px]">
                {{ 'PAGES.BANK_ACCOUNT_DEBIT_CONFIGURATION.INCOMPLETE_TOOLTIP' | translate }}
            </div>
            <div hoverable-item>
                <div class="tw-ml-2" *ngIf="data?.disabled">
                    <app-status-with-icon
                        [link]="data?.link"
                        textColor="s-red-01"
                        label="Unvollständig"
                        iconSrc="/assets/icons/24_attention.svg"
                    ></app-status-with-icon>
                </div></div
        ></app-tooltip>
    </div>
</ng-template>

<ng-template #customTabTemplate let-title="title" let-paymentAmount="paymentAmount">
    <span class="tw-mr-[6px] s-head-16-24-regular">{{ title }}</span>
    <app-tag [color]="paymentAmount === 0 ? 'gray' : 'green'">{{ paymentAmount }}</app-tag>
</ng-template>
