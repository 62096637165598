<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<div ibmRow class="mb-12">
    <div ibmCol></div>
    <div ibmCol [columnNumbers]="{ md: 10, lg: 10 }">
        <div ibmRow>
            <div class="d-flex headline-wrapper">
                <div class="mr-06 headline-wrapper__icon">
                    <app-icon-bubble [size]="72" [color]="'green'" [image]="'40_invest-reserve.svg'"></app-icon-bubble>
                </div>
                <div class="headline-wrapper__title">
                    <div class="s-head-28-36-bold">
                        <app-text-with-tooltip
                            class="tw-inline-block"
                            [label]="'ACCOUNTING.ADD_RESERVE.HEADLINE' | translate"
                            [labelClass]="''"
                            [tooltipKey]="TooltipKey.tooltipsInAccounting"
                            [tooltipInnerHtml]="'PAGES.TOOLTIPS.ADD_RESERVE_FORM.HEADLINE' | translate"
                        ></app-text-with-tooltip>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div ibmCol></div>
</div>
<form (keydown.enter)="$event.preventDefault()" [formGroup]="form">
    <div ibmRow class="pb-10r">
        <div ibmCol></div>
        <div ibmCol [columnNumbers]="{ md: 10, lg: 10 }">
            <div ibmRow class="mb-10">
                <ibm-label
                    [invalid]="isInvalid('purpose')"
                    invalidText="{{ 'ACCOUNTING.ADD_RESERVE.INVALID_RESERVE_NAME' | translate }}"
                    class="tw-w-full"
                    >{{ 'ACCOUNTING.ADD_RESERVE.LABEL' | translate }}*
                    <input
                        ibmText
                        type="purpose"
                        name="purpose"
                        id="purpose"
                        formControlName="purpose"
                        [invalid]="isInvalid('purpose')"
                    />
                </ibm-label>
            </div>
            <div ibmRow class="s-body-14-22-bold mb-04">{{ 'ACCOUNTING.ADD_RESERVE.INTRO_MAIN' | translate }}</div>
            <div ibmRow class="s-body-14-22-regular mb-07">
                {{ 'ACCOUNTING.ADD_RESERVE.INTRO_PARENTHESIS' | translate }}
            </div>

            <div ibmRow class="mt-08 mb-7-5r">
                <div class="custom-table w-100">
                    <ng-container>
                        <div class="custom-table__header-item s-label-12-22-semibold">
                            {{ 'ACCOUNTING.ADD_RESERVE.DESCRIPTION' | translate }}
                        </div>
                        <div class="custom-table__header-item s-label-12-22-semibold">
                            {{ 'ACCOUNTING.ADD_RESERVE.ADDITIONAL_DESCRIPTION' | translate }}
                        </div>
                        <div class="custom-table__header-item s-label-12-22-semibold"></div>
                    </ng-container>
                    <ng-container>
                        <div class="custom-table__data-item s-body-14-22-semibold">
                            {{ 'ACCOUNTING.ADD_RESERVE.TABLE_CONTENT_1' | translate }}
                        </div>
                        <div class="custom-table__data-item">–</div>
                        <div class="custom-table__data-item s-body-14-22-semibold">
                            <svg-icon src="/assets/icons/24_checkbox-check-green.svg"></svg-icon>
                        </div>
                    </ng-container>
                    <ng-container>
                        <div class="custom-table__data-item s-body-14-22-semibold">
                            {{ 'ACCOUNTING.ADD_RESERVE.TABLE_CONTENT_2' | translate }}
                        </div>
                        <div class="custom-table__data-item">–</div>
                        <div class="custom-table__data-item s-body-14-22-semibold">
                            <svg-icon src="/assets/icons/24_checkbox-check-green.svg"></svg-icon>
                        </div>
                    </ng-container>
                    <ng-container>
                        <div class="custom-table__data-item s-body-14-22-semibold">
                            {{ 'ACCOUNTING.ADD_RESERVE.TABLE_CONTENT_3' | translate }}
                        </div>
                        <div class="custom-table__data-item">–</div>
                        <div class="custom-table__data-item s-body-14-22-semibold">
                            <svg-icon src="/assets/icons/24_checkbox-check-green.svg"></svg-icon>
                        </div>
                    </ng-container>
                    <ng-container>
                        <div class="custom-table__data-item s-body-14-22-semibold">
                            {{ 'ACCOUNTING.ADD_RESERVE.TABLE_CONTENT_4' | translate }}
                        </div>
                        <div class="custom-table__data-item">–</div>
                        <div class="custom-table__data-item s-body-14-22-semibold">
                            <svg-icon src="/assets/icons/24_checkbox-check-green.svg"></svg-icon>
                        </div>
                    </ng-container>
                </div>
            </div>

            <!-- START BUTTON ROW -->
            <div ibmRow>
                <div ibmCol>
                    <button [ibmButton]="'tertiary'" type="reset" (click)="abort()">
                        {{ 'COMMON.BTN_CANCEL' | translate }}
                    </button>
                </div>

                <div ibmCol class="text-right">
                    <button [ibmButton]="'primary'" (click)="onSubmit()" [disabled]="form.invalid || disableButton">
                        {{ 'COMMON.BTN_SAVE' | translate }}
                    </button>
                </div>
            </div>
            <!-- END BUTTON ROW -->
        </div>
        <div ibmCol></div>
    </div>
</form>
