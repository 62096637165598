import { Component, EventEmitter, Input, Output } from '@angular/core';
import { getPropertyTypeInfos } from 'src/app/core/utils/common';
import { LedgerDto, PaymentDto } from 'src/app/generated-sources/accounting';
import { PaymentTableData } from '../../banking-payments-payments-approvals-selection.component';

@Component({
    selector: 'app-banking-payments-approval-table',
    templateUrl: './banking-payments-approval-table.component.html',
    styleUrls: ['./banking-payments-approval-table.component.scss'],
})
export class BankingPaymentsApprovalTableComponent {
    @Input() public paymentTable: PaymentTableData[] = [];
    @Input() public paymentType: PaymentDto.TypeEnum = 'MONEY_TRANSFER';
    @Input() public paymentIds: string[] = [];
    @Input() public ledgerId?: string;
    @Output() public openWebForm = new EventEmitter<{
        paymentsToApproveGroup: PaymentDto[];
        bankAccountId: string;
        paymentType: PaymentDto.TypeEnum;
    }>();

    public isExecutionDateExpired(date: string): boolean {
        const currentDate = new Date().setHours(0, 0, 0, 0);
        const executionDate = new Date(date).setHours(0, 0, 0, 0);

        return executionDate < currentDate;
    }

    public selectedPayments(payments: PaymentDto[]): number {
        return this.paymentIds.filter((paymentId) => {
            const findPaymentId = payments.find((payment) => payment.id === paymentId);
            return findPaymentId ? true : false;
        }).length;
    }

    public outputOpenWebForm(paymentsToApprove: PaymentDto[]): void {
        this.openWebForm.emit({
            paymentsToApproveGroup: paymentsToApprove,
            bankAccountId: paymentsToApprove[0]?.bankAccount?.id,
            paymentType: this.paymentType,
        });
    }

    public getTagColor(type: string): string {
        const typeProperty = type as LedgerDto.TypeEnum;
        return getPropertyTypeInfos(typeProperty).color;
    }
}
