<ibm-loading *ngIf="isLoading" [isActive]="true" [size]="'normal'" [overlay]="true"></ibm-loading>

<div class="main-container tw-px-6 tw-py-6 lg:tw-px-10">
    <h1 class="s-head-28-36-bold">Kontoeinstellungen</h1>
    <ng-container *ngIf="this.tooltipsVM$ | async as tooltipsVM">
        <h2 class="tw-s-head-16-24-bold tw-mt-14">Hilfe-Icons anzeigen</h2>
        <div class="tw-s-body-14-22-regular tw-mt-2 tw-mb-6">
            Hier können Sie wählen welche Informationen Ihnen in der App angezeigt werden sollen.
        </div>

        <ibm-checkbox
            *ngFor="let tooltip of tooltipsVM"
            [(checked)]="tooltip.checked"
            [id]="tooltip.key"
            (checkedChange)="onChangeCheckbox(tooltip.key, $event)"
            class="tw-mb-2 tw-block"
        >
            {{ tooltip.label }}
        </ibm-checkbox>
    </ng-container>
    <h2 class="s-head-16-24-bold tw-mt-14">Kontodaten / Login-Daten</h2>
    <ng-container *ngIf="showAccountDetails() && user">
        <div class="mt-1r row">
            <div class="s-label-12-22-semibold nowrap">{{ 'ENTITIES.PERSON.LABEL_EMAIL' | translate }}</div>
            <div class="flex-container">
                <app-person-data-label [label]="user.loginEmail"></app-person-data-label>
                <app-tooltip
                    *ngIf="mediaQueriesService.getCurrentLayoutMode() | async as layout"
                    [position]="layout === 'mobile' ? 'left' : 'top'"
                >
                    <div content class="tooltip-box">
                        {{ 'PAGES.CONTACT.DETAIL_VIEW.EMAIL_TOOLTIP' | translate }}
                    </div>
                    <div hoverable-item class="icon-container ml-0-5r">
                        <svg-icon src="/assets/icons/24_information.svg"></svg-icon></div
                ></app-tooltip>
            </div>
        </div>
        <div class="mt-0-5r row">
            <div class="s-label-12-22-semibold nowrap">{{ 'ENTITIES.PERSON.LABEL_PASSWORD' | translate }}</div>
            <div>
                <a (click)="this.openChangePasswordOverlay()">{{ 'CONTROLS.LINKS.CHANGE_PASSWORD' | translate }}</a>
            </div>
        </div>
    </ng-container>
    <h2 class="s-head-16-24-bold tw-mt-14">Konto löschen</h2>
    <div class="d-flex mt-1r align-items-center">
        <div class="icon-container icon-blue mr-1r"><svg-icon src="/assets/icons/24_information.svg"></svg-icon></div>
        <span>Um Ihr Konto zu löschen, wenden Sie sich bitte an support&#64;scalara.de</span>
    </div>
</div>
