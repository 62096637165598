import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { ExtendedSubscriptionDto } from 'src/app/core/guards/subscriptions.guard';
import { ToastService } from 'src/app/core/services/toast.service';
import { formatDateDDMMYYYY } from 'src/app/core/utils/dateUtils';
import { SubscriptionsService, UpdateSubscriptionDto } from 'src/app/generated-sources/base';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { getCurrentAboType } from '../../helpers/helpers';
import { ExtendedProduct } from '../subscriptions/subscriptions.component';
import { UpdateSubscriptionOverlayComponent } from '../update-subscription-overlay/update-subscription-overlay.component';

@Component({
    selector: 'app-subscription-row',
    templateUrl: './subscription-row.component.html',
    styleUrls: ['./subscription-row.component.scss'],
})
export class SubscriptionRowComponent implements OnInit, OnDestroy {
    public constructor(
        public subscriptionsService: SubscriptionsService,
        public toastService: ToastService,
        public overlayService: OverlayService,
        public detectChanges: ChangeDetectorRef,
        public translateService: TranslateService
    ) {}
    //  currentSubscription is users active subscription
    @Input() public currentSubscription!: ExtendedSubscriptionDto | null;
    //  subscription is rendered subscription, it could be current one or suggested one
    //  product is used for suggested subscription, we created extended ts types to differentiate them
    @Input() public subscription!: ExtendedSubscriptionDto | ExtendedProduct | null;
    @Input() public class = '';
    @Input() public rowType: 'suggestion' | 'current' = 'suggestion';
    @Output() public subscriptionChanged = new EventEmitter<void>();

    public unsubscribe$ = new Subject<void>();

    public ngOnInit(): void {
        this.currentAboType = getCurrentAboType(this.currentSubscription);
    }

    public get isShowingCurrentSubscription(): boolean {
        return this.rowType === 'current';
    }

    public getSubscriptionLabel(): string {
        if (!this.subscription) {
            console.warn('something wrong, this should not be rendered');
            return '';
        }
        if (this.subscription?.maxOwnerships === 0) {
            return 'Kostenlose Basisnutzung';
        }

        const name =
            this.subscription.tsType === 'subscriptionDto' ? this.subscription.productName : this.subscription.name;

        return name;
    }

    public get buttonCallback(): () => void {
        return (): void => {
            console.log('clicked button');

            // create checkout session if user has no subscription or has basic subscription
            if (
                this.currentAboType === 'none' ||
                (!this.isShowingCurrentSubscription && this.currentAboType === 'basic')
            ) {
                if (this.subscription === null) {
                    console.warn('no customer info or subscription, cannot create checkout session');
                    return;
                }
                this.subscriptionsService
                    .createCheckoutSession({
                        priceId: this.subscription?.priceId,
                    })
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe((res) => {
                        const link = document.createElement('a');
                        // TODO: ask if backend is fixed here
                        link.href = (res as any).url;
                        link.click();
                    });
                console.log('checkout session created');
                return;
            }

            if (!this.currentSubscription?.stripeId) {
                console.warn('no current subscription, cannot update subscription');
                return;
            }

            // cancel if rowtype is current

            const updateSubscription: UpdateSubscriptionDto = {
                productIdToSubscribe: this.rowType === 'suggestion' ? this.subscription?.stripeId : undefined,
            };

            const flow = this.rowType === 'suggestion' ? 'UPDATE' : undefined;

            if (flow) {
                this.openUpdateOverlay({ flow, updateSubscription });
            }
        };
    }

    //  is used for cancel and update subscription
    public openUpdateOverlay({
        flow,
        updateSubscription,
    }: {
        flow: 'UPDATE' | 'CANCEL';
        updateSubscription: UpdateSubscriptionDto;
    }): void {
        console.log('open overlay');
        const successMessages = {
            UPDATE: this.translateService.instant('PAGES.ABO.UPDATE.SUCCESS'),
            CANCEL: this.translateService.instant('PAGES.ABO.CANCEL.SUCCESS'),
        };
        const errorMessage = this.translateService.instant('PAGES.ABO.GENERAL_ERROR');

        const ref = this.overlayService.open(UpdateSubscriptionOverlayComponent, { data: { flow } });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            ref.close();
        });
        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.subscriptionsService.updateSubscription(updateSubscription).subscribe({
                next: () => {
                    this.toastService.showSuccess(successMessages[flow]);
                    this.subscriptionChanged.emit();
                },
                error: () => {
                    this.toastService.showError(errorMessage);
                },
            });
        });
    }

    public currentAboType = 'basic';

    public getButtonText(): string | undefined {
        if (!this.isShowingCurrentSubscription && this.currentAboType === 'basic') {
            return this.translateService.instant('PAGES.ABO.BUTTON_TEXT.SUBSCRIBE');
        }
        if (!this.isShowingCurrentSubscription && this.currentAboType === 'paid') {
            return this.translateService.instant('PAGES.ABO.BUTTON_TEXT.UPDATE');
        }
        if (!this.isShowingCurrentSubscription && this.currentAboType === 'none') {
            return this.translateService.instant('PAGES.ABO.BUTTON_TEXT.SUBSCRIBE');
        }

        return undefined;
    }

    // show price, description, button
    public get showDetails(): boolean {
        if (this.isShowingCurrentSubscription && ['basic', 'family'].includes(this.currentAboType)) {
            return false;
        }
        if (this.isShowingCurrentSubscription && this.currentAboType === 'paid') {
            return true;
        }
        if (!this.isShowingCurrentSubscription && ['basic', 'paid'].includes(this.currentAboType)) {
            return true;
        }
        if (!this.isShowingCurrentSubscription && this.currentAboType === 'none') {
            return true;
        }
        return true;
    }

    public get showIcon(): boolean {
        if (this.isShowingCurrentSubscription && ['paid', 'family'].includes(this.currentAboType)) {
            return true;
        }
        return false;
    }

    public getColoredWrapperColor(): 'green' | 'red' | 'purple' | 'none' | 'blue' {
        // red for expired and cancelled
        if (
            this.isShowingCurrentSubscription &&
            this.currentSubscription?.status &&
            ['canceled', 'expired'].includes(this.currentSubscription.status)
        ) {
            return 'red';
        }
        //blue for current basic
        if (this.isShowingCurrentSubscription && getCurrentAboType(this.currentSubscription) === 'basic') {
            return 'blue';
        }
        //green for current others than basic
        if (this.isShowingCurrentSubscription) {
            return 'green';
        }
        //purple for suggestions
        if (!this.isShowingCurrentSubscription) {
            return 'purple';
        }

        return 'none';
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public formatDate = (timestamp: string): string => {
        //  the date from backend(which comes from stripe) is weird, we have to *1000 it
        const date = new Date(Number(timestamp) * 1000).toISOString().split('T')[0];
        return formatDateDDMMYYYY(date);
    };
}
