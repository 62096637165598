import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbItem } from 'carbon-components-angular';
import {
    BehaviorSubject,
    Observable,
    Subject,
    combineLatest,
    distinctUntilChanged,
    filter,
    map,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs';
import { BreadCrumbService } from 'src/app/core/services/breadcrumb.service';
import { PersonLocalService } from 'src/app/features/property/services/person-local.service';
import { EconomicPlanDetailsDto, EconomicPlanDto, EconomicPlansService } from 'src/app/generated-sources/accounting';
import { Ownership, OwnershipsService, Person, Property } from 'src/app/generated-sources/base';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { LedgerCustomService } from '../../../services/ledger-custom.service';

import { WegEconomicPlanActivateComponent } from './weg-economic-plan-activate/weg-economic-plan-activate.component';
import { WegEconomicPlanDeleteComponent } from './weg-economic-plan-delete/weg-economic-plan-delete.component';
import { WegEconomicPlanEditComponent } from './weg-economic-plan-edit/weg-economic-plan-edit.component';
import { WegEconomicPlanResetOverlayComponent } from './weg-economic-plan-reset-overlay/weg-economic-plan-reset-overlay.component';

type TabsToShow = 'individualEconomicPlanTab' | 'distributionKeysTab';
type EconomicPlanValue = EconomicPlanDetailsDto | null;

@Component({
    selector: 'app-weg-economic-plan-details',
    templateUrl: './weg-economic-plan-details.component.html',
    styleUrls: ['./weg-economic-plan-details.component.scss'],
})
export class WegEconomicPlanDetailsComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();
    public triggerTabRefreshValue = 1;
    public isLoading = false;

    private ledgerId = '';
    private economicPlanId = '';
    public economicPlan?: EconomicPlanDto;
    public ownerships: Ownership[] = [];
    public economicPlanType = '';
    public isPropertyManager = false;
    public selectedOwnership$ = new BehaviorSubject<Ownership | undefined>(undefined);
    public refreshEconomicPlanDetails$ = new BehaviorSubject<void>(undefined);

    public ledgerId$ = this.ledgerCustomService.getLedgerId$();

    public economicPlanId$ = this.route.url.pipe(
        map(() => this.route.snapshot.paramMap.get('economicPlanId')),
        distinctUntilChanged()
    );

    public economicPlanType$ = this.route.url.pipe(
        map(() => this.route.snapshot.queryParamMap.get('economicPlanType')),
        distinctUntilChanged()
    );

    public economicPlanDetails$ = new BehaviorSubject<EconomicPlanValue | null>(null);
    public rateInterval$ = this.economicPlanDetails$.pipe(
        map((data) => {
            return data?.rateInterval;
        })
    );

    public showDetailsOrOverview$: Observable<'detail' | 'overview'> = this.selectedOwnership$.pipe(
        map((selectedOwnership) => {
            return selectedOwnership?.id ? 'detail' : 'overview';
        })
    );

    public editMode = true;
    public breadcrumbs: BreadcrumbItem[] = [];

    //  we want to render chosen tabs only when they are opened
    public tabsToShow: TabsToShow[] = [];

    public constructor(
        private breadcrumbService: BreadCrumbService,
        private translateService: TranslateService,
        private economicPlansService: EconomicPlansService,
        private ownershipsService: OwnershipsService,
        private route: ActivatedRoute,
        private overlayService: OverlayService,
        private datePipe: DatePipe,
        private ledgerCustomService: LedgerCustomService,
        private personLocalService: PersonLocalService
    ) {}

    public ngOnInit(): void {
        this.isLoading = true;

        combineLatest([this.ledgerId$, this.economicPlanId$, this.economicPlanType$, this.refreshEconomicPlanDetails$])
            .pipe(
                tap(([ledgerId, economicPlanId, economicPlanType]) => {
                    this.ledgerId = ledgerId ?? '';
                    this.economicPlanId = economicPlanId ?? '';
                    this.economicPlanType = economicPlanType ?? '';
                }),
                switchMap(() => this.personLocalService.getRolesOfProperty()),
                tap((roles: Property.PermissionRolesEnum[] | null) => {
                    this.isPropertyManager = roles
                        ? Object.values(roles).includes(Person.PermissionRolesEnum.PropertyManager)
                        : false;
                }),
                switchMap(() => this.economicPlansService.findOne(this.ledgerId, this.economicPlanId)),
                tap((economicPlan: EconomicPlanDto) => {
                    this.economicPlan = economicPlan;
                    this.editMode = economicPlan.status === EconomicPlanDto.StatusEnum.Draft;
                    this.initBreadCrumbs();
                }),
                switchMap(() => {
                    return this.ledgerCustomService.getLedgerPropertyId$();
                }),
                filter(Boolean),
                switchMap((propertyId) => this.ownershipsService.findAll(propertyId)),
                tap((ownerships) => {
                    this.ownerships = ownerships;
                }),
                switchMap(() => {
                    return this.economicPlansService.getEconomicPlanDetails(this.ledgerId, this.economicPlanId);
                }),
                tap((economicPlan) => {
                    this.economicPlanDetails$.next(economicPlan);
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => (this.isLoading = false));
    }

    private initBreadCrumbs(): void {
        EconomicPlanDto.StatusEnum.Active;
        const breadcrumb: BreadcrumbItem[] = [
            {
                content: this.translateService.instant('ENTITIES.ECONOMIC_PLAN.LABEL_ENTITY_PLURAL'),
                route: [`/accounting/ledger/${this.ledgerId}/economic-plans`],
            },
            {
                content:
                    this.datePipe.transform(this.economicPlan?.startDate, 'dd.MM.yyyy') +
                    ' – ' +
                    this.datePipe.transform(this.economicPlan?.endDate, 'dd.MM.yyyy'),
                route: [`/accounting/ledger/${this.ledgerId}/economic-plans/${this.economicPlanId}`],
                current: true,
            },
        ];
        this.breadcrumbService.resetBreadCrumbs();
        this.breadcrumbService.updateBreadCrumbs(breadcrumb);
        this.breadcrumbs = this.breadcrumbService.getCurrentBreadCrumbs();
    }

    public openActivateEconomicPlanOverlay(): void {
        const ref = this.overlayService.open(WegEconomicPlanActivateComponent, {
            data: { ledgerId: this.ledgerId, economicPlan: this.economicPlan },
        });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
    }

    public openEditEconomicPlanOverlay(): void {
        this.openOverlayAndRefreshOnSave$(WegEconomicPlanEditComponent, {
            data: { ledgerId: this.ledgerId, economicPlanId: this.economicPlanId },
        }).subscribe();
    }

    public openDeleteEconomicPlanOverlay(): void {
        this.openOverlayAndRefreshOnSave$(WegEconomicPlanDeleteComponent, {
            data: { ledgerId: this.ledgerId, economicPlan: this.economicPlan },
        }).subscribe();
    }

    public openOverlayAndRefreshOnSave$ = (
        ...openParams: Parameters<OverlayService['open']>
    ): Observable<EconomicPlanDto> => {
        const ref = this.overlayService.open(...openParams);
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.overlayService.removeOverlayComponentFromBody();
            ref.close();
        });

        return ref.saveEmitter$.pipe(
            switchMap(() => this.economicPlansService.findOne(this.ledgerId, this.economicPlanId)),
            tap((economicPlan: EconomicPlanDto) => {
                this.refreshTabs();
                this.economicPlan = economicPlan;
                this.editMode = economicPlan.status === EconomicPlanDto.StatusEnum.Draft;
                this.overlayService.removeOverlayComponentFromBody();
            }),
            takeUntil(this.unsubscribe$)
        );
    };

    public refreshTabs(): void {
        this.triggerTabRefreshValue = this.triggerTabRefreshValue + 1;
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public resetEconomicPlan(): void {
        const ref = this.overlayService.open(WegEconomicPlanResetOverlayComponent, {
            data: { economicPlanId: this.economicPlanId, ledgerId: this.ledgerId },
        });

        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe({
            next: () => {
                this.refreshEconomicPlanDetails$.next();
            },
        });
    }

    public get isEditButtonDisabled(): boolean {
        return this.ownerships.length < 1;
    }

    public onTabSelected(tab: TabsToShow): void {
        if (this.tabsToShow.includes(tab)) {
            return;
        }
        this.tabsToShow.push(tab);
    }

    public handleIndividualEconomicPlanDetailPage(event: any): void {
        this.selectedOwnership$.next(
            event ? this.ownerships.find((ownership) => ownership.id === event.ownershipId) : undefined
        );
    }
}
