<ibm-loading *ngIf="isLoading" [isActive]="true" [size]="'normal'" [overlay]="true"></ibm-loading>
<app-overlay-container
    headlineTitle="PAGES.OPERATIONS_EXPENSE_STATEMENTS.EDIT_OES.HEADLINE"
    [sizeIcon]="72"
    image="40_document_edit.svg"
    colorIcon="blue"
>
    <ng-container *ngIf="componentToShow === 'add_consumption'">
        <app-operations-expense-statements-consumption-tab
            [ledgerId]="ledgerId"
            [oesId]="oesId"
            [isEditOesOverlay]="true"
        ></app-operations-expense-statements-consumption-tab>

        <div class="mt-2-5r">
            <app-scalara-button (click)="addNewConsumption()" variant="ghost" svgName="24_add.svg"
                >Weiteren Verbrauch erfassen</app-scalara-button
            >
        </div>
    </ng-container>

    <ng-container *ngIf="componentToShow === 'add_distribution_key'">
        <app-operations-expense-statements-distribution-key-tab
            [ledgerId]="ledgerId"
            [oesId]="oesId"
            [isEditOesOverlay]="true"
        ></app-operations-expense-statements-distribution-key-tab>

        <div class="mt-2-5r">
            <app-scalara-button (click)="addNewDistributionKey()" variant="ghost" svgName="24_add.svg"
                >Verteilerschlüssel hinzufügen</app-scalara-button
            >
        </div>
    </ng-container>

    <ng-container *ngIf="componentToShow === 'distribute_costs'">
        <app-add-account-distribution-costs
            (closePage)="abort()"
            (submitAndContinue)="onSubmitAndContinue()"
            (submitAndClose)="onSubmitAndClose()"
            [ledgerId]="ledgerId"
            [oesId]="oesId"
        ></app-add-account-distribution-costs>
    </ng-container>

    <ng-container *ngIf="componentToShow === 'carryover_costs_sev'">
        <app-oes-carryover-costs
            (closePage)="abort()"
            (submitAndContinue)="onSubmitAndContinue()"
            (submitAndClose)="onSubmitAndClose()"
            [ledgerId]="ledgerId"
            [oesId]="oesId"
        ></app-oes-carryover-costs>
    </ng-container>

    <ng-container *ngIf="componentToShow === 'select_bank_account'">
        <app-oes-select-bank-account
            (closePage)="abort()"
            (submitAndClose)="onSubmitAndClose()"
            [ledgerId]="ledgerId"
            [oesId]="oesId"
            [propertyId]="propertyId"
        ></app-oes-select-bank-account
    ></ng-container>

    <!-- BUTTONS -->

    <div
        class="mt-7-5r d-flex justify-space-between"
        *ngIf="
            componentToShow !== 'distribute_costs' &&
            componentToShow !== 'select_bank_account' &&
            componentToShow !== 'carryover_costs_sev'
        "
    >
        <app-scalara-button (click)="abort()" variant="outline">
            {{ (selectedStep > 1 ? 'CONTROLS.BUTTONS.BACK' : 'CONTROLS.BUTTONS.CANCEL') | translate }}
        </app-scalara-button>

        <div class="d-flex">
            <div class="mr-1-5r">
                <app-scalara-button
                    (click)="onSubmitAndClose()"
                    [variant]="selectedStep === 4 ? 'default' : 'ghost'"
                    height="48px"
                >
                    {{ selectedStep === 4 ? ('CONTROLS.BUTTONS.SAVE' | translate) : 'Speichern & schließen' }}
                </app-scalara-button>
            </div>

            <app-scalara-button *ngIf="selectedStep !== 4" (click)="onSubmitAndContinue()" variant="default">
                Speichern & weiter
            </app-scalara-button>
        </div>
    </div>

    <!-- BUTTONS -->
</app-overlay-container>
