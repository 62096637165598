import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbItem } from 'carbon-components-angular';
import { Subject, takeUntil, tap } from 'rxjs';
import { BreadCrumbService } from 'src/app/core/services/breadcrumb.service';
import { sortByDateCallback } from 'src/app/core/utils/common';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { BookingDto } from '../../../../../../generated-sources/accounting';
import { BusinessYear } from '../../../services/economic-plan-custom.service';
import { LedgerCustomService } from '../../../services/ledger-custom.service';
import { BookingsCustomService } from '../../service/bookings-custom.service';
import { AddBookingSelectionOverlayComponent } from '../add-booking-selection-overlay/add-booking-selection-overlay.component';

@Component({
    selector: 'app-bookings-index',
    templateUrl: './bookings-index.component.html',
    styleUrls: ['./bookings-index.component.scss'],
})
export class BookingsIndexComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();

    public form: UntypedFormGroup = new UntypedFormGroup({});
    public selectedBusinessYear?: BusinessYear;
    public bookings?: BookingDto[];
    public ledgerId?: string;
    public isLoading = false;
    public isInitialLoading = false;

    public constructor(
        private ledgerCustomService: LedgerCustomService,
        private overlayService: OverlayService,
        private breadcrumbService: BreadCrumbService,
        public translateService: TranslateService,
        public bookingsCustomService: BookingsCustomService
    ) {}

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public ngOnInit(): void {
        this.isInitialLoading = true;
        this.ledgerCustomService
            .getLedgerId$()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((ledgerId) => {
                this.ledgerId = ledgerId ?? '';
                this.initBreadCrumb(ledgerId ?? '');
            });

        this.bookingsCustomService
            .getBookings$()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((bookings) => {
                this.bookings = bookings?.sort((a, b) => sortByDateCallback(a.bookingDate, b.bookingDate, 'desc'));
                this.isInitialLoading = false;
                this.isLoading = false;
            });
    }

    public openAddBookingOverlay(): void {
        const ref = this.overlayService.open(AddBookingSelectionOverlayComponent, {
            data: { ledgerId: this.ledgerId },
        });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

        ref.saveEmitter$
            .pipe(
                tap(() => {
                    this.isLoading = true;
                    this.bookingsCustomService.refreshBookings();
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }

    private initBreadCrumb(ledgerId: string): void {
        const breadcrumb: BreadcrumbItem[] = [
            {
                content: this.translateService.instant('PAGES.BOOKINGS.BOOKINGS_OVERVIEW.HEADLINE'),
                route: [`/accounting/ledger/${ledgerId}/bookings`],
                current: true,
            },
        ];
        this.breadcrumbService.resetBreadCrumbs();
        this.breadcrumbService.updateBreadCrumbs(breadcrumb);
    }
}
