<ibm-dropdown
    [class]="selectedStatus"
    [displayValue]="dropdownRenderer"
    placeholder="Select"
    (selected)="onChangeStatus($event)"
    *ngIf="!disabled"
>
    <ibm-dropdown-list [listTpl]="dropdownRenderer" [items]="statusList"></ibm-dropdown-list>
</ibm-dropdown>
<ng-template #dropdownRenderer let-item="item">
    <div *ngIf="item && item.content" class="styled-icon-calender d-flex align-items-center">
        <svg-icon [class]="item.iconColor" [src]="'/assets/icons/' + item.icon"></svg-icon>
        <span [class]="'s-body-14-20-regular s-' + item.iconColor + '-01'"> {{ item.content }}</span>
    </div>
</ng-template>

<div *ngIf="disabled && selectedStatusDisabled">
    <div [class]="'styled-icon-calender d-flex align-items-center disabled-status px-1r ' + selectedStatus">
        <svg-icon
            [class]="selectedStatusDisabled.iconColor"
            [src]="'/assets/icons/' + selectedStatusDisabled.icon"
        ></svg-icon>
        <span [class]="'s-body-14-20-regular s-' + selectedStatusDisabled.iconColor + '-01'">
            {{ selectedStatusDisabled.content }}</span
        >
    </div>
</div>
