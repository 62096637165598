<div class="p-05"></div>

<div ibmGrid class="bx--grid--full-width app-accounting-overview">
    <div ibmRow class="mb-2-5r">
        <div ibmCol [columnNumbers]="{ md: 5, lg: 12, xl: 12 }">
            <div class="s-head-28-36-bold">{{ 'ACCOUNTING.OVERVIEW.HEADLINE' | translate }}</div>
        </div>

        <div ibmCol [columnNumbers]="{ md: 3, lg: 4, xl: 4 }" class="d-flex justifiy-content-end">
            <app-scalara-button
                *ngIf="isPropertyManager"
                variant="ghost"
                (click)="openAddLedgerOverlay()"
                svgName="24_add.svg"
            >
                {{ 'ACCOUNTING.OVERVIEW.BUTTON_ADD_LEDGER' | translate }}
            </app-scalara-button>
        </div>
    </div>
    <div ibmRow>
        <div ibmCol>
            <div
                (click)="navigateToPaymentApprovalsOverview()"
                [ngClass]="{ 'disabled-tile': openPayments === 0 }"
                class="d-flex tile tile--shadow tw-px-6 tw-py-[18px] tw-mb-10 justify-space-between"
            >
                <div class="tw-flex tw-items-center">
                    <span class="s-head-28-36-bold tw-mr-3">{{ openPayments }}</span>
                    <span class="s-body-14-22-regular">
                        {{ 'PAGES.PAYMENT_APPROVALS.LABEL_WAITING_PAYMENTS' | translate }}
                    </span>
                </div>
                <app-scalara-button
                    *ngIf="openPayments > 0"
                    variant="ghost"
                    (click)="navigateToPaymentApprovalsOverview()"
                >
                    {{ 'CONTROLS.BUTTONS.CHECK_AND_APPROVE' | translate }}
                </app-scalara-button>
            </div>
        </div>
    </div>
    <div ibmRow>
        <div ibmCol>
            <ibm-tabs [followFocus]="true" [cacheActive]="true">
                <ibm-tab heading="Alle">
                    <div class="mt-1r">
                        <ng-container
                            *ngTemplateOutlet="propertyTable; context: { propertyType: 'ALL' }"
                        ></ng-container>
                    </div>
                </ibm-tab>

                <ibm-tab [heading]="customTabHeadingWEG" [context]="contextWEG">
                    <div class="mt-1r">
                        <ng-container
                            *ngTemplateOutlet="propertyTable; context: { propertyType: 'WEG' }"
                        ></ng-container>
                    </div>

                    <ng-template #customTabHeadingWEG>
                        <ng-container *ngTemplateOutlet="customTabTemplate; context: contextWEG"> </ng-container>
                    </ng-template>
                </ibm-tab>

                <ibm-tab [heading]="customTabHeadingMV" [context]="contextMV">
                    <div class="mt-1r">
                        <ng-container *ngTemplateOutlet="propertyTable; context: { propertyType: 'MV' }"></ng-container>
                    </div>

                    <ng-template #customTabHeadingMV>
                        <ng-container *ngTemplateOutlet="customTabTemplate; context: contextMV"> </ng-container>
                    </ng-template>
                </ibm-tab>

                <ibm-tab [heading]="customTabHeadingSEV" [context]="contextSEV">
                    <div class="mt-1r">
                        <ng-container
                            *ngTemplateOutlet="propertyTable; context: { propertyType: 'SEV' }"
                        ></ng-container>
                    </div>

                    <ng-template #customTabHeadingSEV>
                        <ng-container *ngTemplateOutlet="customTabTemplate; context: contextSEV"> </ng-container>
                    </ng-template>
                </ibm-tab>
            </ibm-tabs>
        </div>
    </div>
</div>

<ng-template #customTabTemplate let-title="title" let-tagTitle="tagTitle" let-amount="amount" let-tagColor="tagColor">
    <app-tag [color]="tagColor">{{ tagTitle }}</app-tag>
    <span class="ml-0-5r s-label-12-16-regular s-gray-03">{{ title }}</span>
</ng-template>

<ng-template #propertyTable let-propertyType="propertyType">
    <div class="mb-1r d-flex justify-content-end">
        <app-search [searchInputId]="propertyType"></app-search>
    </div>
    <app-table
        [emptyText]="'PAGES.PROPERTY.OVERVIEW.LABEL_EMPTY_TABLE'"
        [header]="tableHeader"
        [data]="filterDataByPropertyType(propertyType)"
        [tableSearchId]="propertyType"
        [isLoading]="isLoading"
    >
    </app-table>
</ng-template>
