<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>
<div class="mt-1-25r mb-5r d-flex justify-space-between">
    <ibm-breadcrumb [items]="breadcrumbs" [noTrailingSlash]="true"> </ibm-breadcrumb>
    <div class="d-flex justify-content-end">
        <app-scalara-button variant="ghost" svgName="24_delete.svg" (click)="openDeleteOverlay()">
            {{ 'PAGES.DISTRIBUTION_KEYS.DETAIL.REMOVE_BUTTON' | translate }}
        </app-scalara-button>
    </div>
</div>

<div class="d-flex align-items-center mb-5r">
    <div class="left-column">
        <div class="s-label-12-22-semibold mb-1r">
            {{ 'ENTITIES.DISTRIBUTION_KEY.LABEL_DESCRIPTION' | translate }}
        </div>
        <div class="s-label-12-22-semibold mb-1r">
            {{ 'ENTITIES.DISTRIBUTION_KEY.LABEL_DISTRIBUTION_BASE' | translate }}
        </div>
        <div class="s-label-12-22-semibold mb-1r">
            {{ 'ENTITIES.DISTRIBUTION_KEY.LABEL_DISTRIBUTION_GROUP' | translate }}
        </div>
    </div>

    <div>
        <div class="s-head-18-26-bold mb-1r">
            {{ oesDistributionKey?.description }}
        </div>
        <div class="s-body-14-22-regular mb-1r">
            {{ 'ENTITIES.OWNERSHIP.LABEL_' + oesDistributionKey?.distributionBase | translate }}
        </div>
        <div class="s-body-14-22-regular mb-1r">
            {{ 'ENTITIES.DISTRIBUTION_KEY.' + oesDistributionKey?.distributionGroup | translate }}
        </div>
    </div>
</div>

<div class="d-flex align-items-center mb-1r">
    <div class="left-column">
        <div class="s-label-12-22-semibold mb-1r">Nutzung</div>
    </div>

    <div>
        <div class="s-label-12-22-semibold mb-1r">
            {{ oesDistributionKey?.distributionBase === 'CONSUMPTION' ? 'Betrag in €' : 'Anteil' }}
        </div>
    </div>
</div>

<div class="d-flex align-items-center" *ngFor="let occupation of oesDistributionKey?.occupations">
    <div class="left-column">
        <div class="s-body-14-22-regular mb-1r">{{ getOccupationDescription(occupation.occupationId) }}</div>
    </div>

    <div>
        <div
            *ngIf="oesDistributionKey && occupation.distributionBaseShare !== undefined"
            class="s-body-14-22-regular mb-1r"
        >
            {{
                oesDistributionKey.distributionBase === 'CONSUMPTION'
                    ? (occupation.distributionBaseShare | eurocent)
                    : occupation.distributionBaseShare
            }}
        </div>
    </div>
</div>

<hr class="w-50 ml-0r" />

<div class="d-flex mt-1r">
    <div class="s-label-12-22-semibold left-column">
        {{ 'ENTITIES.DISTRIBUTION_KEY.LABEL_TOTAL_DISTRIBUTION' | translate }}
    </div>

    <div *ngIf="oesDistributionKey && oesDistributionKey.totalDistribution" class="s-body-14-22-bold">
        {{
            oesDistributionKey.distributionBase === 'CONSUMPTION'
                ? (oesDistributionKey.totalDistribution | eurocent)
                : oesDistributionKey.totalDistribution
        }}
    </div>
</div>
