//These function are meant to handle dates as string in the format 'yyyy-mm-dd'

export function getTodayString(): string {
    const today = new Date();
    return new Date(today.getTime() - today.getTimezoneOffset() * 60000).toISOString().split('T')[0];
}

export function addDays(date: string, days: number): string {
    const result = new Date(stripTime(date));
    result.setUTCDate(result.getUTCDate() + days);
    return result.toISOString().split('T')[0];
}

export function addMonths(date: string, months: number): string {
    const result = new Date(stripTime(date));
    result.setUTCMonth(result.getUTCMonth() + months);
    return result.toISOString().split('T')[0];
}

export function setDate(date: string, day: number): string {
    const result = new Date(stripTime(date));
    result.setUTCDate(day);
    return result.toISOString().split('T')[0];
}

export function getDaysOfMonth(date: string): number {
    const [year, month] = date.split('-');
    return new Date(Number(year), Number(month), 0).getDate();
}

//returns the number of days between from the first date to the second date.
//Example: from monday to tuesday returns 1, from monday to monday returns 0
//returns negative number if the second date is before the first date
export function getDaysBetween(date1: string, date2: string): number {
    return (new Date(stripTime(date2)).getTime() - new Date(stripTime(date1)).getTime()) / 1000 / 60 / 60 / 24;
}

export function stripTime(dateString: string): string {
    if (!dateString) {
        return dateString;
    }
    return dateString.substring(0, 10);
}

//example interal1 goes from 01.01.2000 to 31.12.2000. interval 2 goes from 05.05.2000 to 06.05.2000 => returns 2
export function getOverlappingDays(
    interval1From: string,
    interval1To: string,
    interval2From: string,
    interval2To: string
): number {
    const from = getMax(interval1From, interval2From);
    const to = getMin(interval1To, interval2To);

    // added to avoid returning 1 for cases where examples like interal1 goes from 01.01.2000 to 31.12.2000. interval 2 goes from 05.05.2001 to 06.05.2001 are given
    if (from > to) {
        return 0;
    }

    return Math.max(0, getDaysBetween(from, to)) + 1;
}

/**
 * returns the month name in German
 * acccepts the following formats:
 * 'yyyy-mm-dd' -> for example 2021-03-01 => März
 * 'mm' -> for example 03 => März
 * 'm' -> for example 3 => März
 **/
export function getMonthName(month: string): string {
    if (month.length === 10) {
        month = month.substring(5, 7);
    }
    if (month.length === 1) {
        month = '0' + month;
    }
    return new Date('2000-' + month + '-01').toLocaleString('de-DE', { month: 'long' });
}

//Returns the day within the month
export function getDate(date: string): string {
    return date.split('-')[2];
}

export function getMax(...dates: string[]): string {
    dates = dates.filter((x) => !!x);
    dates = dates.map((date) => stripTime(date));
    return (
        dates
            .filter((x) => x?.length >= 10)
            .sort()
            .pop()
            ?.substring(0, 10) ?? ''
    );
}

export function getMin(...dates: string[]): string {
    dates = dates.filter((x) => !!x);
    dates = dates.map((date) => stripTime(date));
    return (
        dates
            .filter((x) => x?.length >= 10)
            .sort()
            .reverse()
            .pop()
            ?.substring(0, 10) ?? ''
    );
}

//This is helpful whean dealing with dates (without time) from typeorm
//Becase typeorm return the dates without time as 00:00 in the local timezone (When Date.toIsoString() is called this leads to a day before the actual date)
//Example input: 2022-12-31T23:00:00.000Z output => 2023-01-01
export function convertToISODateString(date: Date): string {
    if (!date) {
        return '';
    }
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0];
}

//Checks if two time intervals overlap.
//Supports also time intervals with open ends (from1 or to1 or from2 or to2 can be falsy)
export function overlaps(from1: string, to1: string, from2: string, to2: string): boolean {
    from1 = stripTime(from1);
    to1 = stripTime(to1);
    from2 = stripTime(from2);
    to2 = stripTime(to2);
    return (!from1 || !to2 || from1 <= to2) && (!to1 || !from2 || to1 >= from2);
}

export function formatDate(date: string): string {
    const [year, month, day] = date.split('-');
    return `${day}.${month}.${year}`;
}

export function checkAndNormalizeDateString(date?: string | number | Date): string {
    if (!date) {
        return '';
    }
    date = new Date(date).toISOString(); // first conversion to avoid dealing with weird formats like Saturday, January 06, 2024
    if (typeof date === 'string') {
        date = stripTime(date);
    }
    const dateObj = new Date(date);
    if (isNaN(Number(dateObj))) {
        throw new Error();
    }
    return dateObj.toISOString().split('T')[0];
}

// export class BadDateFormatException extends BadRequestException {
//     public constructor(date1: string, date2?: string) {
//         if (date2) {
//             super(`Trying to parse bad date formats from: ${date1}, to: ${date2}`);
//         } else {
//             super(`Trying to parse bad date formate: ${date1}`);
//         }
//         // this.name = 'BadDateFormat';
//     }
// }
