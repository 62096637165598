<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<app-overlay-container
    [headlineTitle]="
        buildingToEdit
            ? 'PAGES.ADD_EDIT_BUILDING.EDIT_BUILDING_HEADLINE'
            : 'PAGES.ADD_EDIT_BUILDING.ADD_BUILDING_HEADLINE'
    "
    [sizeIcon]="72"
    image="40_building.svg"
    colorIcon="green"
>
    <form [formGroup]="form">
        <div>
            <ibm-label
                [invalid]="controlInFormGroupIsInvalid(form, 'name')"
                invalidText="{{ 'ERROR.REQUIRED' | translate }}"
            >
                Bezeichnung des Gebäudes*
                <input placeholder="z.B. Haus 1, Haus 2" ibmText name="name" id="name" formControlName="name" />
            </ibm-label>
        </div>

        <div class="tw-flex tw-w-full tw-space-x-8">
            <div class="tw-w-full">
                <app-scalara-input-address-autocomplete
                    formControlName="streetName"
                    label="{{ 'ENTITIES.ADDRESS.LABEL_STREET_NAME' | translate }} *"
                    [invalid]="controlInFormGroupIsInvalid(form, 'streetName')"
                    invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                    (autocompletePlaceSelected)="onAutocompletePlaceSelected($event)"
                ></app-scalara-input-address-autocomplete>
            </div>

            <ibm-label
                [invalid]="controlInFormGroupIsInvalid(form, 'streetNumber')"
                invalidText="{{ 'ERROR.REQUIRED' | translate }}"
            >
                {{ 'ENTITIES.ADDRESS.LABEL_STREET_NUMBER' | translate }}&nbsp;<span>*</span>
                <input
                    ibmText
                    name="streetNumber"
                    id="streetNumber"
                    formControlName="streetNumber"
                    [invalid]="controlInFormGroupIsInvalid(form, 'streetNumber')"
                />
            </ibm-label>
        </div>

        <ibm-label>
            {{ 'ENTITIES.ADDRESS.LABEL_STREET_ADDITION' | translate }}
            <input ibmText name="streetAddition" id="streetAddition" formControlName="streetAddition" />
        </ibm-label>

        <div class="tw-grid tw-grid-cols-2 tw-gap-8">
            <ibm-label
                [invalid]="controlInFormGroupIsInvalid(form, 'zipCode')"
                invalidText="{{ 'ERROR.REQUIRED' | translate }}"
            >
                {{ 'ENTITIES.ADDRESS.LABEL_ZIP_CODE' | translate }}&nbsp;<span>*</span>
                <input
                    ibmText
                    name="zipCode"
                    id="zipCode"
                    formControlName="zipCode"
                    [invalid]="controlInFormGroupIsInvalid(form, 'zipCode')"
                />
            </ibm-label>
            <ibm-label
                [invalid]="controlInFormGroupIsInvalid(form, 'area')"
                invalidText="{{ 'ERROR.REQUIRED' | translate }}"
            >
                {{ 'ENTITIES.ADDRESS.LABEL_AREA' | translate }}&nbsp;<span>*</span>
                <input
                    ibmText
                    name="area"
                    id="area"
                    formControlName="area"
                    [invalid]="controlInFormGroupIsInvalid(form, 'area')"
                />
            </ibm-label>
        </div>

        <div [class.mb-5r]="propertyType === 'SEV'">
            <ibm-select
                [invalid]="controlInFormGroupIsInvalid(form, 'country')"
                invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                label="{{ 'ENTITIES.ADDRESS.LABEL_COUNTRY' | translate }} *"
                name="country"
                id="country"
                formControlName="country"
            >
                <option value="" disabled>{{ 'COMMON.LABEL_PLEASE_SELECT' | translate }}</option>
                <option *ngFor="let country of countries" [value]="country.code">{{ country.name }}</option>
            </ibm-select>
        </div>

        <!-- START PROPERTY DETAILS -->
        <div class="tw-mt-16">
            <div class="tw-s-head-14-22-bold tw-mb-6">Grundstücksangaben</div>

            <ibm-label
                [invalid]="controlInFormGroupIsInvalid(form, 'plotArea')"
                invalidText="{{ 'ERROR.REQUIRED' | translate }}"
            >
                Grundstücksfläche in qm
                <input
                    ibmText
                    name="plotArea"
                    id="plotArea"
                    formControlName="plotArea"
                    [invalid]="controlInFormGroupIsInvalid(form, 'plotArea')"
                />
            </ibm-label>

            <div class="tw-grid tw-grid-cols-2 tw-gap-8">
                <ibm-label
                    [invalid]="controlInFormGroupIsInvalid(form, 'district')"
                    invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                >
                    Gemarkung
                    <input
                        ibmText
                        name="district"
                        id="district"
                        formControlName="district"
                        [invalid]="controlInFormGroupIsInvalid(form, 'district')"
                    />
                </ibm-label>
                <ibm-label
                    [invalid]="controlInFormGroupIsInvalid(form, 'consecutiveNumberOfProperties')"
                    invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                >
                    Laufende Nummer der Grundstücke
                    <input
                        ibmText
                        type="number"
                        name="consecutiveNumberOfProperties"
                        id="consecutiveNumberOfProperties"
                        formControlName="consecutiveNumberOfProperties"
                        [invalid]="controlInFormGroupIsInvalid(form, 'consecutiveNumberOfProperties')"
                    />
                </ibm-label>
            </div>

            <div class="tw-grid tw-grid-cols-2 tw-gap-8">
                <ibm-label
                    [invalid]="controlInFormGroupIsInvalid(form, 'cadastralDistrict')"
                    invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                >
                    Flur
                    <input
                        ibmText
                        name="cadastralDistrict"
                        id="cadastralDistrict"
                        formControlName="cadastralDistrict"
                        [invalid]="controlInFormGroupIsInvalid(form, 'cadastralDistrict')"
                    />
                </ibm-label>
                <ibm-label
                    [invalid]="controlInFormGroupIsInvalid(form, 'landParcel')"
                    invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                >
                    Flurstück
                    <input
                        ibmText
                        name="landParcel"
                        id="landParcel"
                        formControlName="landParcel"
                        [invalid]="controlInFormGroupIsInvalid(form, 'landParcel')"
                    />
                </ibm-label>
            </div>

            <ibm-label
                [invalid]="controlInFormGroupIsInvalid(form, 'economicType')"
                invalidText="{{ 'ERROR.REQUIRED' | translate }}"
            >
                Wirtschaftsart der Lage
                <input
                    ibmText
                    name="economicType"
                    id="economicType"
                    formControlName="economicType"
                    [invalid]="controlInFormGroupIsInvalid(form, 'economicType')"
                />
            </ibm-label>

            <ibm-label
                [invalid]="controlInFormGroupIsInvalid(form, 'constructionYear')"
                invalidText="{{ 'ERROR.GENERAL' | translate }}"
            >
                Baujahr
                <input
                    ibmText
                    type="number"
                    name="constructionYear"
                    id="constructionYear"
                    formControlName="constructionYear"
                    [invalid]="controlInFormGroupIsInvalid(form, 'constructionYear')"
                    min="1000"
                    max="2099"
                />
            </ibm-label>

            <ibm-label
                [invalid]="controlInFormGroupIsInvalid(form, 'floorCount')"
                invalidText="{{ 'ERROR.GENERAL' | translate }}"
            >
                Etagenanzahl
                <input
                    ibmText
                    type="number"
                    name="floorCount"
                    id="floorCount"
                    formControlName="floorCount"
                    [invalid]="controlInFormGroupIsInvalid(form, 'floorCount')"
                />
            </ibm-label>
        </div>
        <!-- END PROPERTY DETAILS -->
    </form>

    <div class="mt-7-5r d-flex justify-space-between">
        <app-scalara-button (click)="abort()" variant="outline">
            {{ 'COMMON.BTN_CANCEL' | translate }}
        </app-scalara-button>

        <app-scalara-button (click)="onSubmit()" variant="default" [disabled]="this.form.invalid">
            {{ 'COMMON.BTN_SAVE' | translate }}
        </app-scalara-button>
    </div>
</app-overlay-container>
