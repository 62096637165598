/**
 * SCALARA Accounting Module
 * The SCALARA Accounting API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SettlementRow { 
    accountId?: string;
    accountName: string;
    accountDescription: string;
    totalAmount: number;
    distributionKeyName: string;
    distributionKeyBase: SettlementRow.DistributionKeyBaseEnum;
    distributionKeyTotal: number;
    distributionShare: number;
    ownershipAmount: number;
}
export namespace SettlementRow {
    export type DistributionKeyBaseEnum = 'FRACTION' | 'OWNERSHIPS' | 'PERSONS' | 'SQUARE_METERS' | 'HEATED_SQUARE_METERS' | 'CONSUMPTION';
    export const DistributionKeyBaseEnum = {
        Fraction: 'FRACTION' as DistributionKeyBaseEnum,
        Ownerships: 'OWNERSHIPS' as DistributionKeyBaseEnum,
        Persons: 'PERSONS' as DistributionKeyBaseEnum,
        SquareMeters: 'SQUARE_METERS' as DistributionKeyBaseEnum,
        HeatedSquareMeters: 'HEATED_SQUARE_METERS' as DistributionKeyBaseEnum,
        Consumption: 'CONSUMPTION' as DistributionKeyBaseEnum
    };
}


