import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { formControl, formControlHasError } from './common';

//  checks if there any errors and returns true if there are and user interacted with input
export function controlInFormGroupIsInvalid(formGroup: UntypedFormGroup, controlName: string): boolean {
    const control = formGroup.controls[controlName];
    return controlIsInvalid(control);
}

export function controlIsInvalid(control: AbstractControl): boolean {
    if (control.errors && (control.touched || control.dirty)) {
        return true;
    }
    return false;
}

export function emailInvalid(formGroup: UntypedFormGroup): boolean {
    return formControlHasError(formControl(formGroup, 'email'), 'required');
}

export function passwordInvalid({ form }: { form: UntypedFormGroup }): boolean {
    const control = formControl(form, 'password');
    return (
        (control.dirty && formControlHasError(control, 'minlength')) ||
        formControlHasError(control, 'requiredNumber') ||
        formControlHasError(control, 'requiredCapitalCase') ||
        formControlHasError(control, 'requiredSmallCase') ||
        formControlHasError(control, 'requiredSpecialCharacter')
    );
}

export function passwordRequirementsErrors({ form }: { form: UntypedFormGroup }): boolean[] {
    const control = formControl(form, 'password');

    if (formControlHasError(control, 'required') || !form.dirty) {
        return [true, true, true, true, true];
    }

    return [
        formControlHasError(control, 'minlength'),
        formControlHasError(control, 'requiredCapitalCase'),
        formControlHasError(control, 'requiredSmallCase'),
        formControlHasError(control, 'requiredSpecialCharacter'),
        formControlHasError(control, 'requiredNumber'),
    ];
}

export function passwordConfirmInvalid({ form }: { form: UntypedFormGroup }): boolean {
    return (
        form.hasError('noMatch') && formControl(form, 'password').dirty && formControl(form, 'confirmPassword').dirty
    );
}
