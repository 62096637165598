<div *ngIf="isLoading" class="is-loading">
    <div class="d-flex align-items-center justify-content-center mt-4r">
        <ibm-inline-loading> </ibm-inline-loading>
    </div>
</div>

<ng-container>
    <div class="mb-5r pl-0-5r">
        <app-base-documents-list [items]="(pdfList$ | async) || []"></app-base-documents-list>
    </div>
</ng-container>

<ng-container *ngIf="showDetailsTable">
    <app-weg-economic-plan-details-table
        economicPlanType="complete"
        [economicPlan]="economicPlan!"
        [isLoading]="isLoading"
    ></app-weg-economic-plan-details-table>
</ng-container>

<ng-container *ngIf="showErrorMessage && !isLoading">
    <app-notification class="w-100" type="error">{{
        'PAGES.ECONOMIC_PLAN.DETAILS_TAB.MESSAGES.ERROR_MESSAGE' | translate
    }}</app-notification>
</ng-container>
