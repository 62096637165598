import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject, combineLatest, map, takeUntil } from 'rxjs';
import { LayoutMode, MediaQueriesService } from 'src/app/core/services/media-queries.service.ts.service';
import { ButtonVariant } from '../scalara-button/scalara-button.component';

@Component({
    selector: 'app-delete-overlay',
    templateUrl: './delete-overlay.component.html',
    styleUrls: ['./delete-overlay.component.scss'],
})
export class DeleteOverlayComponent implements OnInit {
    private unsubscribe$ = new Subject<void>();
    @Input() public title = '';
    @Input() public subtitle = '';
    @Input() public headline = '';
    @Input() public translateInputs = true;
    @Input() public disableButton = false;
    @Input() public submitButtonLabel = 'CONTROLS.BUTTONS.OVERLAY_REMOVE';
    @Input() public submitButtonVariant: ButtonVariant = 'danger';
    @Input() public headlineIcon = '40_delete.svg';
    @Input() public colorIcon: 'blue' | 'green' | 'orange' | 'purple' | 'gray' | 'red' | 'no-fill' = 'red';
    @Input() public containerSize: 'default' | 'small' | 'large' = 'small';

    @Output() public closeOverlay = new EventEmitter<boolean>();
    @Output() public submitOverlay = new EventEmitter<boolean>();

    public vm?: {
        activeRouteLabel: string;
        currentLayoutMode: LayoutMode;
    };

    public activeRouteLabel$ = new BehaviorSubject<string>('');
    public vm$ = combineLatest([this.activeRouteLabel$, this.mediaQueriesService.getCurrentLayoutMode()]).pipe(
        map(([activeRouteLabel, currentLayoutMode]) => ({
            activeRouteLabel,
            currentLayoutMode,
        }))
    );

    public constructor(private translateService: TranslateService, private mediaQueriesService: MediaQueriesService) {}

    public ngOnInit(): void {
        if (this.translateInputs) {
            this.title = this.translateService.instant(this.title);
            this.subtitle = this.translateService.instant(this.subtitle);
        }

        this.vm$.pipe(takeUntil(this.unsubscribe$)).subscribe((vm) => {
            this.vm = vm;
        });
    }

    public abort(): void {
        this.closeOverlay.emit();
    }

    public onSubmit(): void {
        this.submitOverlay.emit();
    }
}
