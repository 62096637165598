import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ListItem } from 'carbon-components-angular';
import { Subject, filter, tap } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { getLocalISOTime, getServiceProviderType } from 'src/app/core/utils/common';
import {
    OwnershipsService,
    PropertiesService,
    Property,
    RelationsService,
    ServiceProviderRelationDto,
    UpdateRelationToPersonDto,
    UpdateServiceProviderRelationDto,
} from 'src/app/generated-sources/base';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';
import { DateRangePickerComponent } from '../../../shared/components/date-range-picker/date-range-picker.component';
import { PropertyCustomService } from '../../property/services/property-custom.service';
import { DateIntervalDto, RelationUpdateDto } from '../interfaces';

export interface RelationUpdateFormatedDto extends RelationUpdateDto {
    serviceType?: string;
}
@Component({
    selector: 'app-edit-relation-overlay',
    templateUrl: './edit-relation-overlay.component.html',
    styleUrls: ['./edit-relation-overlay.component.scss'],
})
export class EditRelationOverlayComponent extends OverlayChildComponent implements OnInit, OnDestroy, AfterViewInit {
    public isLoading = false;
    public startDate: Date | undefined;
    public endDate: Date | undefined;
    public relationIntervalForm: UntypedFormGroup = new UntypedFormGroup({ isContactPerson: new UntypedFormControl([]) });
    private unsubscribe$ = new Subject<void>();
    public headline = '';
    public subHeadline = '';
    public servicesList: ListItem[] = [];
    public selectedServiceType = new UntypedFormControl('');
    public successMessage = this.translateService.instant('PAGES.RELATIONS.EDIT_RELATION_INTERVAL.SUCCESS_TOAST');

    public originalRelation: RelationUpdateFormatedDto = {
        personId: '',
        propertyId: '',
        ownershipId: '',
        relationId: '',
        parent: '',
        startDate: new Date(),
        endDate: new Date(),
    };

    public propertyType?: Property.PropertyTypeEnum;
    public propertyId = '';

    public property$ = this.propertyCustomService.getProperty$().pipe(
        filter(Boolean),
        tap((property) => {
            this.propertyType = property.propertyType;
            this.propertyId = property.id;
        })
    );

    public services: ServiceProviderRelationDto.ServiceProvidedEnum[] = Object.values(
        ServiceProviderRelationDto.ServiceProvidedEnum
    );

    public constructor(
        private toast: ToastService,
        private translateService: TranslateService,
        private relationsService: RelationsService,
        private propertiesService: PropertiesService,
        private propertyCustomService: PropertyCustomService,
        private ownershipsService: OwnershipsService,
        private route: ActivatedRoute
    ) {
        super();
    }

    @ViewChild(DateRangePickerComponent)
    public dateRangePickerChildComponent?: DateRangePickerComponent;

    public ngAfterViewInit(): void {
        if (!this.dateRangePickerChildComponent) {
            return;
        }
        this.relationIntervalForm.addControl('dateRangeForm', this.dateRangePickerChildComponent.dateRangeForm);
        this.dateRangePickerChildComponent.dateRangeForm.setParent(this.relationIntervalForm);
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public ngOnInit(): void {
        this.property$.subscribe();

        this.isLoading = true;
        this.originalRelation = this.config?.data?.extraData.relation;
        this.isLoading = false;

        this.relationIntervalForm.patchValue({ isContactPerson: !!this.originalRelation.person?.isContactPerson });

        this.determineHeadlines();

        if (this.originalRelation.parent === 'serviceProvider') {
            this.servicesList = this.services.map((item) => ({
                selected: this.originalRelation.serviceType === item,
                content: getServiceProviderType(this.translateService, item),
                id: item,
            }));

            this.selectedServiceType.setValue(this.originalRelation.serviceType);
        }
    }

    private determineHeadlines(): void {
        if (this.originalRelation.parent == 'ownership') {
            this.headline = 'PAGES.RELATIONS.EDIT_RELATION_INTERVAL.LABEL_HEADLINE_RELATION_TYPE_OWNER';
            this.subHeadline = 'PAGES.RELATIONS.EDIT_RELATION_INTERVAL.LABEL_SUB_HEADLINE_RELATION_TYPE_OWNER';
        } else if (this.originalRelation.parent == 'serviceProvider') {
            this.headline = 'PAGES.RELATIONS.EDIT_RELATION_INTERVAL.LABEL_HEADLINE_RELATION_TYPE_SERVICE_PROVIDER';
            this.subHeadline =
                'PAGES.RELATIONS.EDIT_RELATION_INTERVAL.LABEL_SUB_HEADLINE_RELATION_TYPE_SERVICE_PROVIDER';
        } else if (this.originalRelation.parent === 'advisers') {
            this.headline = 'PAGES.RELATIONS.EDIT_RELATION_INTERVAL.LABEL_HEADLINE_RELATION_TYPE_ADVISER';
            this.subHeadline = 'PAGES.RELATIONS.EDIT_RELATION_INTERVAL.LABEL_SUB_HEADLINE_RELATION_TYPE_ADVISER';
        } else if (this.originalRelation.parent == 'resident') {
            this.headline = 'PAGES.RELATIONS.EDIT_RELATION_INTERVAL.LABEL_HEADLINE_RELATION_TYPE_RESIDENT';
            this.subHeadline = 'PAGES.RELATIONS.EDIT_RELATION_INTERVAL.LABEL_SUB_HEADLINE_RELATION_TYPE_RESIDENT';
        }
    }

    public onSubmit(): void {
        this.isLoading = true;

        if (this.relationIntervalForm.invalid) {
            this.isLoading = false;
            return;
        }

        const startDate = this.relationIntervalForm.get('dateRangeForm')?.value['startDate'][0];
        const endDate = this.relationIntervalForm.get('dateRangeForm')?.value['endDate'][0];
        const datesObj: DateIntervalDto = {
            from: getLocalISOTime(startDate),
        };
        if (endDate) {
            datesObj.to = getLocalISOTime(endDate);
        }

        if (this.originalRelation?.parent !== 'serviceProvider') {
            this.updateRelation(datesObj);
        } else if (this.originalRelation?.parent == 'serviceProvider') {
            this.updateServiceProvider(datesObj);
        }
    }

    private updateRelation(datesObj: DateIntervalDto): void {
        const updateRelationToPerson: UpdateRelationToPersonDto = {
            ...datesObj,
            isContactPerson: !!this.relationIntervalForm?.get('isContactPerson')?.value,
            personId: this.originalRelation.person?.id ?? '',
        };

        if (this.originalRelation) {
            (this.propertyType === Property.PropertyTypeEnum.Mv
                ? this.propertiesService.updateMVOwner(
                      this.propertyId,
                      this.originalRelation.relationId,
                      updateRelationToPerson
                  )
                : this.ownershipsService.updateOwnershipOwner(
                      this.propertyId,
                      this.originalRelation.ownershipId ?? '',
                      this.originalRelation.relationId,
                      updateRelationToPerson
                  )
            ).subscribe({
                next: () => {
                    this.toast.showSuccess(this.successMessage);
                    this.saveEmitter$.next();
                    this.isLoading = false;
                },
                error: (error) => {
                    if (error) {
                        this.toast.showError(error.error['message']);
                    }
                    this.isLoading = false;
                },
            });
        }
    }

    private updateServiceProvider(datesObj: DateIntervalDto): void {
        if (this.originalRelation) {
            if (this.originalRelation.serviceType !== this.selectedServiceType.value) {
                const serviceProvider: UpdateServiceProviderRelationDto = {
                    ...datesObj,
                    serviceProvided: this.selectedServiceType.value,
                };

                this.propertiesService
                    .updateServiceProvider(
                        this.originalRelation.propertyId,
                        this.originalRelation.relationId,
                        serviceProvider
                    )
                    .subscribe({
                        next: () => {
                            this.toast.showSuccess(this.successMessage);
                            this.saveEmitter$.next();
                        },
                        error: (error: any) => {
                            if (error) {
                                this.toast.showError(error.error['message']);
                            }
                        },
                    });
            } else {
                this.updateRelation(datesObj);
            }
        }
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public onSelectServiceProvider($event: any): void {
        this.selectedServiceType.setValue($event?.item?.id);
    }
}
