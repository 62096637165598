<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<div class="tw-mt-6 tw-mb-20 tw-flex tw-justify-between">
    <ibm-breadcrumb [items]="breadcrumbs" [noTrailingSlash]="true"> </ibm-breadcrumb>
    <div *ngIf="isPropertyManager" class="tw-flex">
        <app-scalara-button variant="ghost" (click)="openDeleteEntityOverlay()" svgName="24_delete.svg">
            {{ 'CONTROLS.BUTTONS.REMOVE' | translate }}
        </app-scalara-button>
        <app-scalara-button variant="ghost" (click)="openEditComponent()" svgName="24_edit.svg">
            {{ 'COMMON.EDIT' | translate }}
        </app-scalara-button>
    </div>
</div>

<div class="tw-mb-8">
    <div class="tw-s-label-12-22-semibold tw-text-scalaraGray-03 tw-mb-2">
        {{ 'ENTITIES.OWNERSHIP.LABEL_NAME_LONG' | translate }}
    </div>

    <div class="tw-s-head-18-26-bold">{{ ownership?.name }}</div>
</div>

<div class="tw-grid tw-grid-cols-4 tw-mb-20">
    <div class="tw-col-start-1 tw-s-label-12-22-semibold tw-text-scalaraGray-03 tw-space-y-3">
        <div>{{ 'ENTITIES.OWNERSHIP.LABEL_FRACTION_LONG' | translate }}</div>
        <div>{{ 'ENTITIES.OWNERSHIP.LABEL_SQUARE_METERS' | translate }}</div>
        <div>{{ 'ENTITIES.OWNERSHIP.LABEL_HEATED_SQUARE_METERS' | translate }}</div>
    </div>

    <div class="tw-col-start-2 tw-s-body-14-22-regular tw-space-y-3">
        <div *ngIf="ownership?.fraction || ownership?.fraction === 0; else notSpecified">
            {{ ownership?.fraction | number : '0.1-5' : 'de-DE' }}
        </div>
        <div *ngIf="ownership?.squaremeters || ownership?.squaremeters === 0; else notSpecified">
            {{ ownership?.squaremeters | number : '' : 'de-DE' }}
        </div>

        <div *ngIf="ownership?.heatedSquaremeters || ownership?.heatedSquaremeters === 0; else notSpecified">
            {{ ownership?.heatedSquaremeters | number : '' : 'de-DE' }}
        </div>
    </div>
</div>

<div>
    <div class="tw-mb-20">
        <div class="tw-mb-6">
            <app-headline-with-icon
                color="green"
                image="24_linked.svg"
                text="{{ 'PAGES.OWNERSHIP.DETAIL_VIEW.CONTAINED_UNITS' | translate }}"
            ></app-headline-with-icon>
        </div>

        <app-table
            emptyText="PAGES.OWNERSHIP.OVERVIEW.EMPTY_TABLE"
            [header]="ownershipUnitsTable.header"
            [data]="ownershipUnitsTable.data"
            (rowClick)="openOwnershipUnitDetailsOverlay($event)"
        >
        </app-table>
    </div>

    <div class="tw-mb-16">
        <app-person-amount-and-time-frame-table
            *ngIf="ownership"
            [personAmountList]="ownership.personCount"
            [personAmountCallbacks]="personAmountCallbacks"
        ></app-person-amount-and-time-frame-table>
    </div>

    <div>
        <div class="tw-mb-6">
            <app-headline-with-icon
                color="green"
                image="24_linked.svg"
                text="{{ 'PAGES.OWNERSHIP.DETAIL_VIEW.LABEL_SPECIAL_RIGHTS_SHARED_OWNERSHIP' | translate }}"
            ></app-headline-with-icon>
        </div>

        <app-table
            emptyText="PAGES.PROPERTY_BASE_DATA_COMMON_OWNERSHIP.LABEL_EMPTY_TABLE"
            [header]="sharedOwnershipTable.header"
            [data]="sharedOwnershipTable.data"
        >
        </app-table>
    </div>

    <div class="tw-mt-20" *ngIf="currentTenants?.length! > 0">
        <div class="tw-mb-6 tw-flex">
            <div class="tw-s-head-14-22-bold">Aktuelle Mieter</div>
        </div>
        <div *ngFor="let tenant of currentTenants">
            <ng-container *ngIf="tenant.person">
                <div class="tw-mb-3 tw-flex tw-items-center">
                    <app-headline-with-icon
                        [color]="tenant.person && tenant.person.hasRegisteredUser ? 'green' : 'gray'"
                        image="24_person.svg"
                        [text]="getNameFromPerson(tenant.person)"
                        textType="body"
                        textWeight="regular"
                        borderColor="gray"
                    ></app-headline-with-icon>
                    <div class="tw-ml-4 tw-s-body-14-20-regular tw-text-scalaraGray-03">
                        {{ getTypeUnitName(tenant.unit) + ', ' + tenant.unit.name }}
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

    <div class="tw-mb-20">
        <app-owners [isEditable]="isPropertyManager" [tooltipInfo]="tooltipInfoOwner"> </app-owners>
    </div>

    <div class="tw-mb-20">
        <app-residents [isEditable]="isPropertyManager"> </app-residents>
    </div>

    <div class="tw-mb-20">
        <app-additional-information-input
            [additionalInformation]="ownership?.additionalInformation"
            (updateAdditionalInformation)="updateAdditionalInformation($event)"
        ></app-additional-information-input>
    </div>

    <div>
        <app-document-table
            [showButton]="isPropertyManager"
            [tooltipInfo]="tooltipInfoDocuments"
            [inputData]="{
                flow: DocumentsFlow.ownership,
                ownershipId: ownership?.id ?? ''
            }"
        ></app-document-table>
    </div>
</div>

<ng-template #notSpecified>
    <div class="s-body-14-22-italic">
        {{ 'PAGES.OWNERSHIP.DETAIL_VIEW.LABEL_NOT_SPECIFIED' | translate }}
    </div>
</ng-template>
