<span
    *ngIf="account && account.apportionable !== null"
    [ngClass]="{ 'label-green': account.apportionable, 'label-grey': !account.apportionable }"
    class="label s-label-12-16-regular"
>
    {{
        (account.apportionable
            ? 'ACCOUNTING.ACCOUNT_DETAILS.APPORTIONABLE'
            : 'ACCOUNTING.ACCOUNT_DETAILS.NOT_APPORTIONABLE'
        ) | translate
    }}
</span>
