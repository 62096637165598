<div class="tw-border-dashed tw-border-2 tw-border-scalaraGray-04 tw-mt-6 tw-p-2" [formGroup]="addbankAccountFormGroup">
    <div class="tw-flex-row tw-s-head-16-24-bold tw-mb-2">
        {{ 'ACCOUNTING.REASONABILITY_CHECK.HEADLINE_ATTACH_ADDITIONAL_ACCOUNT' | translate }}
    </div>
    <div class="tw-flex tw-justify-between tw-ml-2">
        <app-combo-box
            #comboBoxTypeBankAccount
            id="bankAccounts"
            [appendInline]="true"
            label="{{ 'ENTITIES.BANK_ACCOUNT.LABEL_ENTITY' | translate }}*"
            [items]="(bankAccountItemList$ | async) ?? []"
            formControlName="bankAccounts"
            placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT' | translate }}"
            invalidText="{{ 'ERROR.REQUIRED' | translate }}"
            (selected)="onSelectedBankAccount($event)"
            (change)="onSelectedBankAccount($event)"
            class="tw-w-[500px]"
        >
            <ibm-dropdown-list></ibm-dropdown-list>
        </app-combo-box>
        <div class="tw-w-[170px]">
            <label class="bx--label"> </label>
            <app-scalara-button
                class="mr-05 tw-w-[170px]"
                (click)="onSubmit()"
                [fullWidth]="true"
                svgName="24_add.svg"
                [disabled]="addbankAccountFormGroup.invalid"
                >{{ 'CONTROLS.BUTTONS.ADD' | translate }}</app-scalara-button
            >
        </div>
    </div>
    <!--  -->
</div>
