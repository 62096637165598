/**
 * SCALARA Base Module
 * The SCALARA API for User, Person and Property resources
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EnergyCertificateFileDto } from './energyCertificateFileDto';


export interface CreateEnergyCertificateDto { 
    issueDate?: string;
    validUntil?: string;
    certificateType: CreateEnergyCertificateDto.CertificateTypeEnum;
    energyValueInkWh: number;
    energyCertificateFiles?: Array<EnergyCertificateFileDto>;
    energyEfficiencyClass?: string;
}
export namespace CreateEnergyCertificateDto {
    export type CertificateTypeEnum = 'VERBRAUCHSAUSWEIS' | 'BEDARFSAUSWEIS';
    export const CertificateTypeEnum = {
        Verbrauchsausweis: 'VERBRAUCHSAUSWEIS' as CertificateTypeEnum,
        Bedarfsausweis: 'BEDARFSAUSWEIS' as CertificateTypeEnum
    };
}


