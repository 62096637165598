import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject, combineLatest, switchMap, takeUntil, tap } from 'rxjs';
import { SharedOwnership, SharedOwnershipService } from 'src/app/generated-sources/base';
import { OverlayCallbacks } from 'src/app/shared/components/overlay-details/overlay-details.component';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { AddEditSharedOwnershipOverlayComponent } from '../add-edit-shared-ownership-overlay/add-edit-shared-ownership-overlay.component';
import { DeleteSharedOwnershipOverlayComponent } from '../delete-shared-ownership-overlay/delete-shared-ownership-overlay.component';

@Component({
    selector: 'app-shared-ownership-details',
    templateUrl: './shared-ownership-details.component.html',
    styleUrls: ['./shared-ownership-details.component.scss'],
})
export class SharedOwnershipDetailsComponent extends OverlayChildComponent implements OnInit, OnDestroy {
    public buildingId = '';
    public propertyId = '';
    public sharedOwnershipId = '';
    public sharedOwnerhsipIdList?: string[];

    public sharedOwnership?: SharedOwnership;

    public refresh$ = new BehaviorSubject<void>(undefined);
    public unsubscribe$ = new Subject<void>();
    public sharedOwnershipCurrentId$ = new BehaviorSubject<string>('');

    public sharedOwnership$ = combineLatest([this.sharedOwnershipCurrentId$, this.refresh$]).pipe(
        switchMap(([sharedOwnershipCurrentId]) => {
            return this.sharedOwnershipService.getOneSharedOwnership(
                this.propertyId,
                this.buildingId,
                sharedOwnershipCurrentId
            );
        }),
        tap((data) => (this.sharedOwnership = data))
    );

    public overlayCallbacks: OverlayCallbacks = {
        deleteOverlay: () => {
            this.openDeleteOverlay();
        },
        editOverlay: () => {
            this.openEditOverlay();
        },
    };

    public constructor(private overlayService: OverlayService, private sharedOwnershipService: SharedOwnershipService) {
        super();
    }

    public ngOnInit(): void {
        this.buildingId = this.config?.data.buildingId;
        this.propertyId = this.config?.data.propertyId;
        this.sharedOwnershipId = this.config?.data.id;

        this.sharedOwnershipCurrentId$.next(this.sharedOwnershipId);
        this.sharedOwnerhsipIdList = this.config?.data.sharedOwnerhsipIdList;

        this.sharedOwnership$.subscribe();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public openDeleteOverlay(): void {
        const ref = this.overlayService.open(DeleteSharedOwnershipOverlayComponent, {
            data: {
                sharedOwnership: this.sharedOwnership,
                propertyId: this.propertyId,
            },
        });

        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.saveEmitter$.next());
    }

    public openEditOverlay(): void {
        const ref = this.overlayService.open(AddEditSharedOwnershipOverlayComponent, {
            data: {
                sharedOwnershipToEdit: this.sharedOwnership,
                buildingId: this.buildingId,
                propertyId: this.propertyId,
            },
        });

        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.refresh$.next());
    }

    public findNewSharedOwnershipId(id: string): void {
        this.sharedOwnershipCurrentId$.next(id);
    }
}
