import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ListItem } from 'carbon-components-angular';
import { Subject, firstValueFrom, tap } from 'rxjs';
import { ResolveType } from 'src/app/core/models/ts-utils';
import { ToastService } from 'src/app/core/services/toast.service';
import {
    CreateOccupationFilesDto,
    CreateOwnershipFilesDto,
    CreatePropertyFileDto,
    OccupationService,
    OwnershipsService,
    PropertiesService,
    Property,
} from 'src/app/generated-sources/base';
import { FileUploaderComponent } from 'src/app/shared/components/file-uploader/file-uploader.component';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';
import { environment } from 'src/environments/environment';
import { PropertyCustomService } from '../../../services/property-custom.service';

export enum DocumentsFlow {
    occupation = 'occupation',
    occupationForm = 'occupationForm',
    property = 'property',
    ownership = 'ownership',
}

//fileReferenceEnum is needed, but TS Typing is hard to maintain, so we would check it in runtime
export type OptionalDocumentsData =
    | {
          flow: DocumentsFlow.occupation | DocumentsFlow.occupationForm;
          occupationId: string;
      }
    | {
          flow: DocumentsFlow.property;
          fileReferenceEnum?: CreatePropertyFileDto.FileReferenceEnum;
      }
    | {
          flow: DocumentsFlow.ownership;
          ownershipId: string;
      };

export type AddDocumentConfig =
    | {
          data: ResolveType<
              {
                  propertyId: string;
              } & OptionalDocumentsData
          >;
      }
    | undefined;

@Component({
    selector: 'app-add-document-overlay',
    templateUrl: './add-document-overlay.component.html',
    styleUrls: ['./add-document-overlay.component.scss'],
})
export class AddDocumentOverlayComponent extends OverlayChildComponent implements OnInit, OnDestroy {
    public readonly fileSizeInMb = environment.maxFileSizeInMB;
    public unsubscribe$ = new Subject<void>();

    public categories: ListItem[] = [];

    @ViewChild('fileUpload')
    public fileUploader?: FileUploaderComponent;

    //  controlled via events from app-file-uploader
    public areFilesFullyUploaded = false;
    public fileUuids: string[] = [];
    public fileCategories: CreatePropertyFileDto.FileCategoryEnum[] = [];

    public isLoading = false;

    public configTyped?: AddDocumentConfig = undefined;

    public successMessage = 'Dokument/Datei wurde erfolgreich hochgeladen';

    public constructor(
        private toastService: ToastService,
        private propertiesService: PropertiesService,
        private ownershipService: OwnershipsService,
        private occupationService: OccupationService,
        private propertyCustomService: PropertyCustomService,
        private translateService: TranslateService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.configTyped = this.config as AddDocumentConfig;

        // document categories for Occupation
        if (
            DocumentsFlow.occupation === this.configTyped?.data.flow ||
            DocumentsFlow.occupationForm === this.configTyped?.data.flow
        ) {
            let categories: CreatePropertyFileDto.FileCategoryEnum[] = [];
            const excludedCategories: CreatePropertyFileDto.FileCategoryEnum[] = [
                'RESOLUTION_COLLECTION',
                'PRIVACY_POLICY',
                'SERVICE_CONTRACT',
                'QUESTIONNAIRE',
                'COMMUNITY_RULES',
                'EXPERT_REPORT',
                'HOUSE_RULES',
                'PROTOCOL',
                'SEPA_MANDATE',
                'SUPPLY_CONTRACT',
                'POWER_OF_ATTORNEY',
                'LIVING_SPACE_CALCULATION',
            ];

            categories = Object.values(CreatePropertyFileDto.FileCategoryEnum).filter((category) => {
                return !excludedCategories.includes(category);
            });

            this.categories = categories
                .map((item) => ({
                    selected: false,
                    value: item,
                    content: this.translateService.instant(`ENTITIES.DOCUMENT.${item}`),
                }))
                .sort(this.sortCategories);
        } else {
            this.propertyCustomService
                .getProperty$()
                .pipe(
                    tap((property) => {
                        let categories: CreatePropertyFileDto.FileCategoryEnum[] = [];
                        let excludedCategories: CreatePropertyFileDto.FileCategoryEnum[] = [];

                        if (this.configTyped?.data.flow === 'property') {
                            const fileReferenceEnum = this.configTyped.data?.fileReferenceEnum;

                            if (fileReferenceEnum === 'BASIC_DATA') {
                                //document categories for base data (BASISDATEN)
                                excludedCategories = [
                                    'PERSONAL_ID',
                                    'ACCEPTANCE_PROTOCOL',
                                    'GUARANTEE',
                                    'QUESTIONNAIRE',
                                    'RENTAL_AGREEMENT',
                                    'SEPA_MANDATE',
                                    'HANDOVER_PROTOCOL',
                                    'SOLVENCY_PROOF',
                                    'TENANT_SELF_DISCLOSURE',
                                    'SALARY_CERTIFICATE',
                                ];

                                if (property?.propertyType === Property.PropertyTypeEnum.Weg) {
                                    excludedCategories.push('POWER_OF_ATTORNEY');
                                } else {
                                    excludedCategories.push('RESOLUTION_COLLECTION');
                                    excludedCategories.push('COMMUNITY_RULES');
                                }
                            } else {
                                // document categories for ownership (EINHEITEN)
                                excludedCategories = [
                                    'PERSONAL_ID',
                                    'ACCEPTANCE_PROTOCOL',
                                    'RESOLUTION_COLLECTION',
                                    'GUARANTEE',
                                    'PRIVACY_POLICY',
                                    'QUESTIONNAIRE',
                                    'COMMUNITY_RULES',
                                    'RENTAL_AGREEMENT',
                                    'PROTOCOL',
                                    'SEPA_MANDATE',
                                    'POWER_OF_ATTORNEY',
                                    'HANDOVER_PROTOCOL',
                                    'SOLVENCY_PROOF',
                                    'TENANT_SELF_DISCLOSURE',
                                    'SALARY_CERTIFICATE',
                                ];
                            }
                        } else {
                            // document categories for ownership - units (Wohn-/Teileigentumseinheiten)
                            if (property?.propertyType === Property.PropertyTypeEnum.Weg) {
                                excludedCategories = [
                                    'ACCEPTANCE_PROTOCOL',
                                    'RESOLUTION_COLLECTION',
                                    'SERVICE_CONTRACT',
                                    'COMMUNITY_RULES',
                                    'HOUSE_RULES',
                                    'RENTAL_AGREEMENT',
                                    'PROTOCOL',
                                    'SUPPLY_CONTRACT',
                                    'HANDOVER_PROTOCOL',
                                    'SOLVENCY_PROOF',
                                    'TENANT_SELF_DISCLOSURE',
                                    'SALARY_CERTIFICATE',
                                    'DECLARATION_OF_DIVISION',
                                    'INSURANCE_POLICY',
                                    'ADMINISTRATOR_APPOINTMENT',
                                ];
                            } else {
                                // SEV - MV
                                excludedCategories = [
                                    'SALARY_CERTIFICATE',
                                    'TENANT_SELF_DISCLOSURE',
                                    'SOLVENCY_PROOF',
                                    'RENTAL_AGREEMENT',
                                    'GUARANTEE',
                                    'ACCEPTANCE_PROTOCOL',
                                    'PERSONAL_ID',
                                    'ADMINISTRATOR_APPOINTMENT',
                                    'HANDOVER_PROTOCOL',
                                    'RESOLUTION_COLLECTION',
                                    'QUESTIONNAIRE',
                                    'COMMUNITY_RULES',
                                    'SEPA_MANDATE',
                                    'DECLARATION_OF_DIVISION',
                                ];
                            }
                        }

                        categories = Object.values(CreatePropertyFileDto.FileCategoryEnum).filter((category) => {
                            return !excludedCategories.includes(category);
                        });

                        this.categories = categories
                            .map((item) => ({
                                selected: false,
                                value: item,
                                content: this.translateService.instant(`ENTITIES.DOCUMENT.${item}`),
                            }))
                            .sort(this.sortCategories);
                    })
                )
                .subscribe();
        }
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public async onSubmit(): Promise<void> {
        if (!this.configTyped) {
            console.warn('no config');
            return;
        }

        this.isLoading = true;

        if (
            DocumentsFlow.occupation === this.configTyped.data.flow ||
            DocumentsFlow.occupationForm === this.configTyped.data.flow
        ) {
            const createFilesDto: CreateOccupationFilesDto = {
                createFileDtos: this.fileUuids.map((uuid, index) => ({
                    fileStorageId: uuid,
                    fileCategory: this.fileCategories[index],
                })),
            };
            const method =
                this.configTyped.data.flow === 'occupation'
                    ? this.occupationService.createOccupationFiles
                    : this.occupationService.createOccupationFormFiles;
            try {
                await firstValueFrom(
                    method.bind(this.occupationService)(
                        this.configTyped.data.propertyId,
                        this.configTyped.data.occupationId,
                        createFilesDto
                    )
                );

                this.toastService.showSuccess(this.successMessage);
                this.saveEmitter$.next();
            } catch (e) {
                console.warn('error submitting occupation files');
                this.toastService.showError((e as any)?.message ?? 'Error');
            } finally {
                this.isLoading = false;
            }
            return;
        }

        if (this.configTyped.data.flow === 'property') {
            const fileReferenceEnum = this.configTyped.data?.fileReferenceEnum;
            if (!fileReferenceEnum) {
                console.warn('no file reference enum');
                return;
            }
            const createFilesDto = {
                createFileDtos: this.fileUuids.map((uuid, index) => ({
                    fileStorageId: uuid,
                    fileReference: fileReferenceEnum,
                    fileCategory: this.fileCategories[index],
                })),
            };

            try {
                await firstValueFrom(
                    this.propertiesService.createPropertyFiles(this.configTyped.data.propertyId, createFilesDto)
                );
                this.toastService.showSuccess(this.successMessage);
                this.saveEmitter$.next();
            } catch (e) {
                console.warn('error submitting property files');
                this.toastService.showError((e as any)?.message ?? 'Error');
            } finally {
                this.isLoading = false;
            }
            return;
        }

        if (this.configTyped.data.flow === 'ownership') {
            const createFilesDto: CreateOwnershipFilesDto = {
                createFileDtos: this.fileUuids.map((uuid, index) => ({
                    fileStorageId: uuid,
                    fileCategory: this.fileCategories[index],
                })),
            };

            try {
                await firstValueFrom(
                    this.ownershipService.createFiles(
                        this.configTyped.data.propertyId,
                        this.configTyped.data.ownershipId,
                        createFilesDto
                    )
                );
                this.toastService.showSuccess(this.successMessage);
                this.saveEmitter$.next();
            } catch (e) {
                console.warn('error submitting ownership files');
                this.toastService.showError((e as any)?.message ?? 'Error');
            } finally {
                this.isLoading = false;
            }
            return;
        }
    }

    public updateLoadingFilesStatus($event: boolean): void {
        this.areFilesFullyUploaded = $event;
    }

    public updateFileIdsLodaded($event: string[]): void {
        this.fileUuids = $event;
    }

    public updatedFileCategories($event: any): void {
        this.fileCategories = $event;
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public sortCategories(a: ListItem, b: ListItem): 1 | 0 | -1 {
        if (a.content === 'Sonstiges' || b.content === 'Sonstiges') {
            return 1;
        }

        if (a.content > b.content) {
            return 1;
        }
        if (a.content < b.content) {
            return -1;
        }
        return 0;
    }
}
