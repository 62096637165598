<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<!-- desktop version -->
<ng-container>
    <div class="pb-10r d-none lg-d-block contact-details-grid-container tw-px-4 lg:tw-px-10">
        <!--button row -->
        <div class="grid-buttons">
            <div class="tw-flex tw-justify-between">
                <div class="tw-mb-10"><ng-container *ngTemplateOutlet="breadcrumbs"></ng-container></div>

                <div class="d-flex justify-content-end align-items-center tw-mt-6 tw-mb-4">
                    <app-scalara-button
                        *ngIf="(isLoggedUserPropertyManager && !person?.hasRegisteredUser) || isOwnProfile"
                        [variant]="'ghost'"
                        svgName="24_edit.svg"
                        (click)="openOverlay()"
                    >
                        {{ 'CONTROLS.BUTTONS.EDIT' | translate }}
                    </app-scalara-button>
                    <app-scalara-button
                        *ngIf="
                            !isLoading &&
                            person?.email &&
                            isLoggedUserPropertyManager &&
                            !isOwnProfile &&
                            !person?.hasRegisteredUser
                        "
                        class="ml-1r"
                        type="button"
                        (click)="inviteContact()"
                        height="40px"
                    >
                        <span *ngIf="!person?.lastInvited">
                            {{ 'PAGES.CONTACT.DETAIL_VIEW.INVITE' | translate }}
                        </span>
                        <span *ngIf="person?.lastInvited">
                            {{ 'PAGES.CONTACT.DETAIL_VIEW.INVITE_AGAIN' | translate }}
                        </span>
                    </app-scalara-button>
                    <app-tooltip
                        position="bottom-right-aligned"
                        class="ml-1r"
                        *ngIf="!person?.email && !isOwnProfile && !person?.hasRegisteredUser"
                    >
                        <div content class="tooltip-box">
                            {{ 'PAGES.CONTACT.DETAIL_VIEW.EMAIL_NOT_GIVEN_TOOLTIP' | translate }}
                        </div>
                        <div hoverable-item class="d-flex">
                            <app-scalara-button type="button" [disabled]="true" (click)="inviteContact()" height="40px">
                                {{ 'PAGES.CONTACT.DETAIL_VIEW.INVITE' | translate }}
                            </app-scalara-button>
                        </div>
                    </app-tooltip>
                </div>
            </div>
        </div>
        <div class="mb-4r grid-avatar">
            <ng-container [ngTemplateOutlet]="avatar"></ng-container>
        </div>

        <!-- Verknupfungen -->
        <div class="grid-roles" *ngIf="mergedTablesData?.length ?? 0 > 0">
            <app-headline-with-icon
                color="green"
                size="m"
                text="{{ 'PAGES.CONTACT.DETAIL_VIEW.LABEL_HEADLINE_RELATIONS' | translate }}"
                [image]="'24_linked.svg'"
                class="tw-mb-4"
            ></app-headline-with-icon>
            <div class="tw-mt-4">
                <div *ngIf="tableModelOwner.data.length > 0">
                    <div (click)="toggleRolesCollapsed('owner')" class="collapse-container d-flex align-items-center">
                        <app-tag color="green" size="s">{{ tableModelOwner.data.length }}x</app-tag>

                        <div class="ml-1r s-head-14-22-bold">
                            {{ 'ENTITIES.RELATION.LABEL_RELATION_TYPE_OWNER' | translate }}
                        </div>

                        <svg-icon
                            [ngClass]="{ 'rotate-180': rolesCollapsed['owner'] }"
                            class="ml-auto icon icon__gray mr-04"
                            src="/assets/icons/24_arrow-down.svg"
                        ></svg-icon>
                    </div>

                    <div *ngIf="rolesCollapsed['owner']" class="pt-1r pb-1r">
                        <app-contact-roles-overview [tableModel]="tableModelOwner"></app-contact-roles-overview>
                    </div>
                    <hr class="mb-1r mt-0" />
                </div>

                <div *ngIf="tableModelServiceProvider.data.length > 0">
                    <div
                        (click)="toggleRolesCollapsed('serviceProvider')"
                        class="collapse-container d-flex align-items-center"
                    >
                        <app-tag color="green" size="s">{{ tableModelServiceProvider.data.length }}x</app-tag>
                        <div class="ml-1r s-head-14-22-bold">
                            {{ 'ENTITIES.SERVICE_PROVIDER.LABEL_ENTITY' | translate }}
                        </div>
                        <svg-icon
                            [ngClass]="{ 'rotate-180': rolesCollapsed['serviceProvider'] }"
                            class="ml-auto icon mr-04"
                            src="/assets/icons/24_arrow-down.svg"
                        ></svg-icon>
                    </div>

                    <div *ngIf="rolesCollapsed['serviceProvider']" class="pt-1r pb-1r">
                        <app-contact-roles-overview
                            [tableModel]="tableModelServiceProvider"
                        ></app-contact-roles-overview>
                    </div>
                    <hr class="mb-1r mt-0" />
                </div>

                <div *ngIf="tableModelTenant.data.length > 0">
                    <div (click)="toggleRolesCollapsed('tenant')" class="collapse-container d-flex align-items-center">
                        <app-tag color="green" size="s">{{ tableModelTenant.data.length }}x</app-tag>
                        <div class="ml-1r s-head-14-22-bold">{{ 'ENTITIES.OCCUPATION.LABEL_ENTITY' | translate }}</div>
                        <svg-icon
                            [ngClass]="{ 'rotate-180': rolesCollapsed['tenant'] }"
                            class="ml-auto icon mr-04"
                            src="/assets/icons/24_arrow-down.svg"
                        ></svg-icon>
                    </div>

                    <div *ngIf="rolesCollapsed['tenant']" class="pt-1r pb-1r">
                        <app-contact-roles-overview [tableModel]="tableModelTenant"></app-contact-roles-overview>
                    </div>
                    <hr class="mb-1r mt-0" />
                </div>

                <div *ngIf="tableModelResident.data.length > 0">
                    <div
                        (click)="toggleRolesCollapsed('resident')"
                        class="collapse-container d-flex align-items-center"
                    >
                        <app-tag color="green" size="s">{{ tableModelResident.data.length }}x</app-tag>
                        <div class="ml-1r s-head-14-22-bold">{{ 'ENTITIES.RESIDENT.LABEL_ENTITY' | translate }}</div>
                        <svg-icon
                            [ngClass]="{ 'rotate-180': rolesCollapsed['resident'] }"
                            class="ml-auto icon mr-04"
                            src="/assets/icons/24_arrow-down.svg"
                        ></svg-icon>
                    </div>

                    <div *ngIf="rolesCollapsed['resident']" class="pt-1r pb-1r">
                        <app-contact-roles-overview [tableModel]="tableModelResident"></app-contact-roles-overview>
                    </div>
                    <hr class="mb-1r mt-0" />
                </div>
            </div>
        </div>
        <!-- END Verknupfungen -->

        <!-- Zahlungverbindungen -->
        <ng-container *ngTemplateOutlet="bankAccounts"></ng-container>

        <!-- End Zahlungverbindungen -->
    </div>
</ng-container>

<!-- mobile version -->
<ng-container *ngIf="!isLoading">
    <div class="p-1-25r lg-d-none">
        <div class="tw-flex tw-justify-between">
            <div class="tw-mb-6"><ng-container *ngTemplateOutlet="breadcrumbs"></ng-container></div>
            <div class="d-flex justify-content-end align-items-center tw-pb-2">
                <div class="mobile-button-wrapper">
                    <app-scalara-button
                        *ngIf="(isLoggedUserPropertyManager && !person?.hasRegisteredUser) || isOwnProfile"
                        [svgName]="'24_edit.svg'"
                        [variant]="'ghost'"
                        (click)="openOverlay()"
                    ></app-scalara-button>
                </div>
            </div>
        </div>

        <!-- TODO: use template here -->
        <ng-container *ngTemplateOutlet="avatar"></ng-container>

        <div class="mobile-accordion__wrapper">
            <app-scalara-accordion [headingLabel]="'Kontaktinformationen'" [isOpenedByDefault]="false">
                <div scalara-accordion-content>
                    <ng-container [ngTemplateOutlet]="personInfos"></ng-container>
                </div>
            </app-scalara-accordion>
            <app-scalara-accordion
                [headingLabel]="'Verknüpfungen'"
                [isOpenedByDefault]="false"
                *ngIf="this.mergedTablesData && this.mergedTablesData.length > 0"
            >
                <div scalara-accordion-content>
                    <ng-container>
                        <div *ngFor="let item of this.mergedTablesData; let last = last">
                            <ng-container
                                *ngTemplateOutlet="
                                    row;
                                    context: {
                                        firstLabel: 'ENTITIES.RELATION.LABEL_RELATION_TYPE' | translate,
                                        secondLabel: getCurrentRelationshipLabel(item.relation)
                                    }
                                "
                            ></ng-container>

                            <ng-container
                                *ngTemplateOutlet="
                                    row;
                                    context: {
                                        firstLabel: 'ENTITIES.PROPERTY.LABEL_ENTITY' | translate,
                                        secondLabel: item.data[0].data.label
                                    }
                                "
                            ></ng-container>
                            <ng-container
                                *ngTemplateOutlet="
                                    row;
                                    context: {
                                        firstLabel: asString(item.header[1]) | translate,
                                        secondLabel: item.data[1].data.label
                                    }
                                "
                            ></ng-container>
                            <ng-container
                                *ngTemplateOutlet="
                                    row;
                                    context: {
                                        firstLabel: asString(item.header[2]) | translate,
                                        secondLabel: item.data[2].data.label
                                    }
                                "
                            ></ng-container>
                            <ng-container
                                *ngTemplateOutlet="
                                    row;
                                    context: {
                                        firstLabel: asString(item.header[3]) | translate,
                                        optionalTemplate: {
                                            template: label,
                                            context: {
                                                data: {
                                                    link: null,
                                                    extraData: item.data[3].data.extraData
                                                }
                                            }
                                        }
                                    }
                                "
                            ></ng-container>
                            <div class="roles-spacer" *ngIf="!last"></div>
                        </div>
                    </ng-container>
                </div>
            </app-scalara-accordion>
            <app-scalara-accordion
                [headingLabel]="'Zahlungverbindungen'"
                [isOpenedByDefault]="false"
                *ngIf="showBankAccounts()"
            >
                <div scalara-accordion-content class="tw-mt-[-1rem]">
                    <ng-container [ngTemplateOutlet]="bankAccounts"></ng-container>
                </div>
            </app-scalara-accordion>
        </div>
    </div>
    <div class="mb-10r"></div>
</ng-container>

<ng-template #label let-data="data">
    <a [routerLink]="data.link" class="data-table-link s-body-14-22-regular">
        <app-tag *ngIf="data.extraData.currentRelationshipLabel" [color]="'green'" class="mr-1r">
            {{ data.extraData.currentRelationshipLabel }}
        </app-tag>
        <app-tag *ngIf="data.extraData.isAdviser" [color]="'green'">
            {{ 'ENTITIES.RELATION.LABEL_RELATION_TYPE_ADVISER' | translate }}
        </app-tag>
    </a>
</ng-template>

<ng-template #row let-firstLabel="firstLabel" let-secondLabel="secondLabel" let-optionalTemplate="optionalTemplate">
    <div class="mobile-accordion__row-wrapper">
        <div class="mobile-accordion__first-column s-label-12-22-semibold">
            {{ firstLabel }}
        </div>
        <div class="mobile-accordion__second-column" *ngIf="!optionalTemplate">
            <app-person-data-label [label]="secondLabel"></app-person-data-label>
        </div>
        <ng-container *ngIf="optionalTemplate">
            <div class="mobile-accordion__second-column">
                <ng-container
                    *ngTemplateOutlet="optionalTemplate.template; context: optionalTemplate.context"
                ></ng-container>
            </div>
        </ng-container>
    </div>
</ng-template>

<ng-template #loginEmailTemplate>
    <div class="flex-container">
        <app-person-data-label [label]="this.user?.loginEmail"></app-person-data-label>
        <app-tooltip position="left">
            <div content class="tooltip-box">
                {{ 'PAGES.CONTACT.DETAIL_VIEW.EMAIL_TOOLTIP' | translate }}
            </div>
            <div hoverable-item class="icon-container">
                <svg-icon src="/assets/icons/24_information.svg"></svg-icon></div
        ></app-tooltip>
    </div>
</ng-template>

<ng-template #avatar>
    <div class="avatar__wrapper">
        <div
            class="avatar__icon-wrapper tw-relative tw-group"
            [ngClass]="{ 'avatar__icon-wrapper--gray': !person?.hasRegisteredUser }"
        >
            <img
                *ngIf="person?.imgSmall"
                src="{{ person && person.imgSmall ? (person.imgSmall | imageBase64) : '' }}"
                class="tw-h-full tw-w-full tw-rounded-full"
            />

            <svg-icon
                *ngIf="!person?.imgSmall"
                class="tw-h-[72px] tw-w-[72px]"
                [ngClass]="{
                    'tw-fill-scalaraGray-04': !person?.hasRegisteredUser,
                    'tw-fill-scalaraGreen-01': person?.hasRegisteredUser
                }"
                src="/assets/icons/72_person.svg"
            ></svg-icon>

            <div
                *ngIf="isOwnProfile"
                class="tw-absolute tw-cursor-pointer tw-hidden group-hover:tw-flex tw-bg-scalaraGray-01 tw-w-full tw-h-full tw-rounded-full tw-justify-center tw-items-center tw-bg-opacity-70"
            >
                <div class="tw-flex tw-space-x-4">
                    <div
                        (click)="deleteUserAvatar()"
                        *ngIf="person?.imgSmall"
                        class="tw-fill-scalaraWhite tw-w-10 tw-h-10"
                    >
                        <svg-icon src="/assets/icons/40_delete.svg"></svg-icon>
                    </div>

                    <div (click)="openAddAvatarOverlay()" class="tw-fill-scalaraWhite tw-w-10 tw-h-10">
                        <svg-icon src="/assets/icons/40_edit.svg"></svg-icon>
                    </div>
                </div>
            </div>
        </div>

        <p class="tw-s-head-18-26-bold lg:tw-s-head-28-36-bold">{{ personname }}</p>

        <!-- Persontype tag -->
        <div class="tw-mt-3 lg:tw-mt-[18px]">
            <div
                class="tagContainer"
                *ngIf="person?.type === 'NAT_PERSON'"
                class="label label-green d-inline-block s-label-12-16-regular"
            >
                {{ 'ENTITIES.PERSON.LABEL_PERSON_TYPE_NAT_PERSON' | translate }}
            </div>
            <div
                class="tagContainer"
                *ngIf="person?.type === 'COMPANY'"
                class="label label-purple d-inline-block s-label-12-16-regular"
            >
                {{ 'ENTITIES.PERSON.LABEL_PERSON_TYPE_COMPANY' | translate }}
            </div>
        </div>

        <div class="avatar-user-registered-infos tw-space-y-5 tw-mt-5 lg:tw-space-y-2 lg:tw-mt-2">
            <div *ngIf="person?.hasRegisteredUser" class="d-flex align-items-center tw-justify-center">
                <svg-icon src="/assets/icons/24_closed.svg" class="d-flex formated-icon registered"></svg-icon>
                <div class="ml-0-5r s-label-12-16-regular">
                    {{ 'PAGES.CONTACT.DETAIL_VIEW.USER_REGISTERED' | translate }}
                </div>
            </div>

            <div *ngIf="!person?.hasRegisteredUser" class="d-flex align-items-center tw-justify-center">
                <svg-icon src="/assets/icons/24_error.svg" class="d-flex formated-icon non-registered"></svg-icon>
                <div class="ml-0-5r s-label-12-16-regular">
                    {{ 'PAGES.CONTACT.DETAIL_VIEW.USER_NOT_REGISTERED' | translate }}
                </div>
            </div>

            <div
                *ngIf="person?.lastInvited && !person?.hasRegisteredUser"
                class="s-gray-04 s-label-12-16-regular d-flex align-items-center tw-justify-center"
            >
                {{ 'PAGES.CONTACT.DETAIL_VIEW.INVITED_ON' | translate }}
                {{ person?.lastInvited || '' | dateTimeFormat }}
            </div>

            <app-scalara-button
                class="tw-inline-block lg:tw-invisible tw-justify-center tw-flex"
                *ngIf="isLoggedUserPropertyManager && !isOwnProfile && !person?.hasRegisteredUser"
                type="button"
                (click)="inviteContact()"
                [disabled]="!person?.email"
            >
                <span *ngIf="!person?.lastInvited">
                    {{ 'PAGES.CONTACT.DETAIL_VIEW.INVITE' | translate }}
                </span>
                <span *ngIf="person?.lastInvited">
                    {{ 'PAGES.CONTACT.DETAIL_VIEW.INVITE_AGAIN' | translate }}
                </span>
            </app-scalara-button>

            <div
                class="px-1-5r mt-1-5r lg:tw-invisible"
                *ngIf="!person?.email && isLoggedUserPropertyManager && !isOwnProfile && !person?.hasRegisteredUser"
            >
                {{ 'PAGES.CONTACT.DETAIL_VIEW.EMAIL_NOT_REGISTERED' | translate }}
            </div>
        </div>

        <div class="avatar-person-infos">
            <ng-container [ngTemplateOutlet]="personInfos"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #personInfos>
    <div>
        <ng-container
            *ngTemplateOutlet="
                row;
                context: {
                    firstLabel: 'ENTITIES.ADDRESS.LABEL_STREET_AND_NUMBER' | translate,
                    secondLabel: getPersonFullAddress(person!)
                }
            "
        ></ng-container>

        <ng-container
            *ngTemplateOutlet="
                row;
                context: {
                    firstLabel: 'ENTITIES.ADDRESS.LABEL_STREET_ADDITION' | translate,
                    secondLabel: person?.address?.streetAddition
                }
            "
        ></ng-container>
        <ng-container
            *ngTemplateOutlet="
                row;
                context: {
                    firstLabel: 'ENTITIES.ADDRESS.LABEL_ZIP_CODE' | translate,
                    secondLabel: person?.address?.zipCode
                }
            "
        ></ng-container>
        <ng-container
            *ngTemplateOutlet="
                row;
                context: {
                    firstLabel: 'ENTITIES.ADDRESS.LABEL_AREA' | translate,
                    secondLabel: person?.address?.area
                }
            "
        ></ng-container>
        <ng-container
            *ngTemplateOutlet="
                row;
                context: {
                    firstLabel: 'ENTITIES.ADDRESS.LABEL_COUNTRY' | translate,
                    secondLabel: getCountryNameFromCode(person?.address?.country!)
                }
            "
        ></ng-container>

        <!-- only person owner -->
        <ng-container *ngIf="person?.personRoles?.includes('IS_OWNER') && person?.type === 'NAT_PERSON'">
            <ng-container
                *ngTemplateOutlet="
                    row;
                    context: {
                        firstLabel: 'ENTITIES.PERSON.LABEL_BIRTH_DATE' | translate,
                        secondLabel: formatDate(person?.birthday)
                    }
                "
            ></ng-container>
        </ng-container>

        <!--  -->
        <ng-container *ngIf="tableModelServiceProvider.data.length > 0">
            <hr class="tw-my-4" />
            <!-- service provider -->
            <ng-container
                *ngTemplateOutlet="
                    row;
                    context: {
                        firstLabel: 'ENTITIES.SERVICE_PROVIDER.LABEL_COMMERCIAL_REGISTER' | translate,
                        secondLabel: this.person?.companyRegistrationNumber
                    }
                "
            ></ng-container>
            <ng-container
                *ngTemplateOutlet="
                    row;
                    context: {
                        firstLabel: 'ENTITIES.SERVICE_PROVIDER.LABEL_UST_ID_NUMBER' | translate,
                        secondLabel: this.person?.vatIdentificationNumber
                    }
                "
            ></ng-container>
            <ng-container
                *ngTemplateOutlet="
                    row;
                    context: {
                        firstLabel: 'ENTITIES.SERVICE_PROVIDER.LABEL_TAX_NUMBER' | translate,
                        secondLabel: this.person?.taxNumber
                    }
                "
            ></ng-container>
        </ng-container>
        <hr class="tw-my-4" />

        <ng-container
            *ngTemplateOutlet="
                row;
                context: {
                    firstLabel: 'ENTITIES.PERSON.LABEL_PRIMARY_EMAIL' | translate,
                    secondLabel: person?.email
                }
            "
        ></ng-container>

        <ng-container
            *ngTemplateOutlet="
                row;
                context: {
                    firstLabel: 'ENTITIES.PERSON.LABEL_SECONDARY_EMAIL' | translate,
                    secondLabel: person?.secondaryEmail
                }
            "
        ></ng-container>
        <div *ngIf="person?.phoneNumbers && person?.phoneNumbers?.[0] !== null">
            <ng-container *ngFor="let phoneInfo of person?.phoneNumbers">
                <ng-container
                    *ngTemplateOutlet="
                        row;
                        context: {
                            firstLabel: 'ENTITIES.PERSON.LABEL_PHONE' | translate,
                            secondLabel: returnphoneNumberTypeFromEnum(phoneInfo)
                        }
                    "
                ></ng-container>
            </ng-container>
        </div>
        <hr class="tw-my-4" />
        <ng-container
            *ngTemplateOutlet="
                row;
                context: {
                    firstLabel: 'ENTITIES.PERSON.LABEL_MISC' | translate,
                    secondLabel: person?.miscellaneousInfo
                }
            "
        ></ng-container>

        <ng-container
            *ngTemplateOutlet="
                row;
                context: {
                    firstLabel: 'ENTITIES.PERSON.LABEL_WEBSITE' | translate,
                    secondLabel: person?.website
                }
            "
        ></ng-container>
    </div>
</ng-template>

<ng-template #bankAccounts>
    <div class="grid-bank-accounts" *ngIf="showBankAccounts()">
        <app-headline-with-icon
            color="green"
            size="m"
            text="{{ 'PAGES.CONTACT.DETAIL_VIEW.LABEL_HEADLINE_BANKACCOUNTS' | translate }}"
            [image]="'24_bank.svg'"
        ></app-headline-with-icon>
        <div class="grid-bank-accounts__add-button" *ngIf="vm?.allowToAddRemoveBankAccounts">
            <app-scalara-button variant="ghost" (click)="openAddBankAccountOverlay()" svgName="24_add.svg">
                {{ 'CONTROLS.BUTTONS.ADD' | translate }}
            </app-scalara-button>
        </div>

        <div class="grid-bank-accounts__info">
            <ng-container *ngIf="this.vm?.bankAccounts?.length ?? 0 > 0; else noBankAccountsMessage">
                <div *ngFor="let bankAccount of this.vm?.bankAccounts">
                    <app-bank-account-item
                        [bankAccount]="bankAccount"
                        (deleteClicked)="onBankAccountDelete(bankAccount)"
                        [showDeleteButton]="this.vm?.allowToAddRemoveBankAccounts ?? false"
                    >
                    </app-bank-account-item>
                </div>
            </ng-container>
            <div class="tw-mt-6">
                <a [routerLink]="'sepaMandates'">
                    Mandate {{ this.vm?.currentLayout === 'desktop' ? 'ansehen' : '' }}
                </a>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #noBankAccountsMessage>
    <div class="grid-bank-accounts__info">
        <div class="tw-flex tw-mt-8">
            <svg-icon
                class="tw-flex tw-justify-center tw-items-center tw-fill-scalaraBlue-01 tw-w-6 tw-h-6"
                src="/assets/icons/24_information.svg"
            ></svg-icon>
            <div class="tw-pl-3 tw-s-body-14-22-regular tw-inline-block">
                {{ 'PAGES.CONTACT.DETAIL_VIEW.NO_BANK_ACCOUNTS' | translate }}
            </div>
        </div>
    </div>
</ng-template>

<ng-template #breadcrumbs>
    <ibm-breadcrumb
        class="tw-block tw-mt-0 lg:tw-mt-5"
        *ngIf="vm && vm.breadcrumbs"
        [items]="vm.breadcrumbs"
        [noTrailingSlash]="true"
    >
    </ibm-breadcrumb>
</ng-template>
