import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Subject, of, switchMap, takeUntil, tap } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { PropertyService } from 'src/app/core/services/property.service';
import { getPropertyTypeInfos } from 'src/app/core/utils/common';
import { PersonLocalService } from 'src/app/features/property/services/person-local.service';
import { LedgerDto, LedgersService } from 'src/app/generated-sources/accounting';
import { Ownership, OwnershipsService, Person, PropertiesService, Property } from 'src/app/generated-sources/base';
import { OperationsGroups } from 'src/app/shared/permissions.enum';
import { MenuItem } from 'src/app/shared/sidenav/interfaces/menu-item';
import { CellTemplate } from 'src/app/shared/table/enums/cell-template';
import { TableItem } from 'src/app/shared/table/interfaces/table-item';
import { TableModel } from 'src/app/shared/table/interfaces/table-model';
import {
    LedgerCustomService,
    LedgerDtoWithExtendedProperty,
    PropertyWithExtendedName,
} from '../services/ledger-custom.service';

@Component({
    selector: 'app-ledger-detail-view',
    templateUrl: './ledger-detail-view.component.html',
    styleUrls: ['./ledger-detail-view.component.scss'],
})
export class LedgerDetailViewComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();

    public surrogate = false;
    public operations = false;
    public isLoading = true;
    public ledger?: LedgerDto;
    public propertyWithLedgerType?: Property;
    public ownershipNames?: string;
    public propertyWithLedgerTable: TableModel = { data: [], header: [] };
    public ledgers: LedgerDtoWithExtendedProperty[] = [];

    public menuItems: MenuItem[] = [];

    public person$ = this.personLocalService.getPerson$();

    public constructor(
        private ledgersService: LedgersService,
        private propertiesService: PropertiesService,
        private propertyService: PropertyService,
        private ownershipsService: OwnershipsService,
        private route: ActivatedRoute,
        private authService: AuthService,
        private ledgerCustomService: LedgerCustomService,
        private personLocalService: PersonLocalService
    ) {}

    public ngOnInit(): void {
        this.propertyWithLedgerTable.header = [
            'ENTITIES.PROPERTY.LABEL_IMG_FULL',
            'ENTITIES.PROPERTY.LABEL_NAME',
            'ENTITIES.ADDRESS.LABEL_STREET_AND_NUMBER',
            'ENTITIES.ADDRESS.LABEL_ZIP_CODE',
            'ENTITIES.ADDRESS.LABEL_AREA',
            'ENTITIES.PROPERTY.LABEL_MANAGEMENT_TYPE',
        ];

        if (this.authService.getUserGroupsFromIdToken != null) {
            const groups: string[] = this.authService.getUserGroupsFromIdToken() ?? [];

            this.surrogate = groups.includes(OperationsGroups.SURROGATE);
            this.operations = groups.includes(OperationsGroups.STATISTIC);
        }

        this.ledgerCustomService
            .getLedgersWithSevOwnershipsNames()
            .pipe(
                tap((ledgers) => {
                    this.ledgers = ledgers;
                    this.propertyWithLedgerTable.data = ledgers.map((ledger) => {
                        return this.createRow(ledger);
                    });
                })
            )
            .subscribe();

        this.route.paramMap
            .pipe(
                switchMap((params: ParamMap) => {
                    const ledgerId = params.get('id') as string;
                    this.ledgerCustomService.setLedgerId(ledgerId);
                    return this.ledgersService.findOne(ledgerId);
                }),
                switchMap((ledger: LedgerDto) => {
                    this.ledger = ledger;
                    this.ledgerCustomService.setLedgerPropertyId(ledger.propertyId);
                    return this.propertiesService.findOne(ledger.propertyId);
                }),
                tap((property: Property) => {
                    this.propertyWithLedgerType = property;
                    if (this.ledger) {
                        this.propertyWithLedgerType.propertyType = this.ledger?.type;
                    }
                    this.propertyService.setProperty(property);
                    this.personLocalService.setRolesOfProperty(property.permissionRoles);
                    this.fillMenuItems();
                }),
                switchMap((property: Property) => {
                    if (this.propertyWithLedgerType?.propertyType == 'SEV') {
                        return this.ownershipsService.findAll(property.id, this.ledger?.ownershipIds.join(','));
                    } else {
                        return of(null);
                    }
                }),
                tap((ownerships: Ownership[] | null) => {
                    if (ownerships) {
                        const ownershipsNames: string[] = [];
                        ownerships.map((ownership: Ownership) =>
                            ownership.name ? ownershipsNames.push(ownership.name) : null
                        );
                        this.ownershipNames = ownershipsNames.join(', ');
                    }
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => {
                this.isLoading = false;
            });
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private fillMenuItems(): void {
        if (
            this.propertyWithLedgerType &&
            (Object.values(this.propertyWithLedgerType.permissionRoles).includes(
                Person.PermissionRolesEnum.PropertyManager
            ) ||
                Object.values(this.propertyWithLedgerType.permissionRoles).includes(Person.PermissionRolesEnum.Adviser))
        ) {
            // FIXME: create an object containing items and condition when it should be shown
            if (this.ledger && this.ledger.type === Property.PropertyTypeEnum.Weg) {
                //if WEG AND PM
                this.menuItems = [
                    { textPath: 'COMPONENTS.SIDENAV_ACCOUNTING.LABEL_DASHBOARD', link: 'dashboard' },
                    {
                        textPath: 'COMPONENTS.SIDENAV_ACCOUNTING.LABEL_PAYMENTS',
                        link: '',
                        children: [
                            { textPath: 'COMPONENTS.SIDENAV_ACCOUNTING.LABEL_OVERVIEW', link: 'bank-accounts' },
                            {
                                textPath: 'COMPONENTS.SIDENAV_ACCOUNTING.LABEL_BANK_TRANSACTIONS',
                                link: 'bank-transactions',
                            },
                            {
                                textPath: 'COMPONENTS.SIDENAV_ACCOUNTING.LABEL_PAYMENT',
                                link: 'payments',
                            },
                        ],
                    },
                    { textPath: 'COMPONENTS.SIDENAV_ACCOUNTING.LABEL_ACCOUNT_CHARTS', link: 'account-charts' },
                    { textPath: 'COMPONENTS.SIDENAV_ACCOUNTING.LABEL_ECONOMIC_PLAN', link: 'economic-plans' },
                    {
                        textPath: 'COMPONENTS.SIDENAV_ACCOUNTING.LABEL_ACCOUNTING',
                        link: '',
                        children: [
                            { textPath: 'COMPONENTS.SIDENAV_ACCOUNTING.LABEL_RECEIPTS', link: 'receipts' },
                            { textPath: 'COMPONENTS.SIDENAV_ACCOUNTING.LABEL_BOOKINGS', link: 'bookings' },
                            { textPath: 'COMPONENTS.SIDENAV_ACCOUNTING.LABEL_WATCHLIST', link: 'open-items' },
                            { textPath: 'COMPONENTS.SIDENAV_ACCOUNTING.LABEL_ADVANCEMENTS', link: 'advancements' },
                        ],
                    },
                    {
                        textPath: 'COMPONENTS.SIDENAV_ACCOUNTING.LABEL_ADDITIONAL_COSTS',
                        link: 'annual-statements',
                    },
                    {
                        textPath: 'COMPONENTS.SIDENAV_ACCOUNTING.LABEL_SETTINGS',
                        link: 'ledger-settings',
                    },
                ];
            } else {
                // IF NOT WEG AND PM
                this.menuItems = [
                    { textPath: 'COMPONENTS.SIDENAV_ACCOUNTING.LABEL_DASHBOARD', link: 'dashboard' },
                    {
                        textPath: 'COMPONENTS.SIDENAV_ACCOUNTING.LABEL_PAYMENTS',
                        link: '',
                        children: [
                            { textPath: 'COMPONENTS.SIDENAV_ACCOUNTING.LABEL_OVERVIEW', link: 'bank-accounts' },
                            {
                                textPath: 'COMPONENTS.SIDENAV_ACCOUNTING.LABEL_BANK_TRANSACTIONS',
                                link: 'bank-transactions',
                            },
                            {
                                textPath: 'COMPONENTS.SIDENAV_ACCOUNTING.LABEL_PAYMENT',
                                link: 'payments',
                            },
                        ],
                    },
                    { textPath: 'COMPONENTS.SIDENAV_ACCOUNTING.LABEL_ACCOUNT_CHARTS', link: 'account-charts' },
                    {
                        textPath: 'COMPONENTS.SIDENAV_ACCOUNTING.LABEL_ACCOUNTING',
                        link: '',
                        children: [
                            { textPath: 'COMPONENTS.SIDENAV_ACCOUNTING.LABEL_RECEIPTS', link: 'receipts' },
                            { textPath: 'COMPONENTS.SIDENAV_ACCOUNTING.LABEL_BOOKINGS', link: 'bookings' },
                            { textPath: 'COMPONENTS.SIDENAV_ACCOUNTING.LABEL_WATCHLIST', link: 'open-items' },
                            { textPath: 'COMPONENTS.SIDENAV_ACCOUNTING.LABEL_RENT_ADVANCEMENTS', link: 'advancements' },
                        ],
                    },
                    {
                        textPath: 'COMPONENTS.SIDENAV_ACCOUNTING.LABEL_OPERATIONS_EXPENSE_STATEMENTS',
                        link: 'operations-expense-statements',
                    },
                    {
                        textPath: 'COMPONENTS.SIDENAV_ACCOUNTING.LABEL_ECONOMICS',
                        link: 'economics',
                    },
                ];
            }
        } else {
            if (this.ledger && this.ledger.type === Property.PropertyTypeEnum.Weg) {
                // IF NOT PM AND WEG
                this.menuItems = [
                    { textPath: 'COMPONENTS.SIDENAV_ACCOUNTING.LABEL_ECONOMIC_PLAN', link: 'economic-plans' },
                    {
                        textPath: 'COMPONENTS.SIDENAV_ACCOUNTING.LABEL_ADDITIONAL_COSTS',
                        link: 'annual-statements',
                    },
                ];
            } else {
                // IF NOT PM AND NOT WEG
                this.menuItems = [
                    {
                        textPath: 'COMPONENTS.SIDENAV_ACCOUNTING.LABEL_OPERATIONS_EXPENSE_STATEMENTS',
                        link: 'operations-expense-statements',
                    },
                ];
            }
        }
        if (this.surrogate) {
            this.menuItems.push({
                textPath: 'COMPONENTS.SIDENAV_ACCOUNTING.LABEL_REASONABILITY',
                link: 'reasonability-checks',
            });
        }
    }

    private createRow(data: LedgerDtoWithExtendedProperty): TableItem[] {
        const property = data.property as PropertyWithExtendedName;
        const propertyName = property.nameWithOwnerships;

        return [
            {
                data: {
                    label: property.imgSmall ? property.imgSmall : '',
                    extraData: data, // ATTENTION: do not remove this extra data, as it is being used in the property switch component
                },
                template: CellTemplate.Image,
            },
            {
                data: {
                    label: propertyName,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: `${property.address.streetName} ${property.address.streetNumber}`,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: property.address.zipCode,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: property.address.area,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: data.type,
                    textColor: getPropertyTypeInfos(data.type).color,
                },
                template: CellTemplate.contentWithTagStyle,
            },
        ];
    }
}
