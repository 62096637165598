import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-ticket-detail-icons',
    templateUrl: './ticket-detail-icons.component.html',
    styleUrls: ['./ticket-detail-icons.component.scss'],
})
export class TicketDetailIconsComponent {
    @Input() public amountComments = 0;
    @Input() public amountFiles = 0;
    @Input() public showBadge = false;
}
