import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { switchMap, tap } from 'rxjs';
import { getNameFromPerson, unitTypeNameFromEnum } from 'src/app/core/utils/common';
import {
    FullOccupationOesDto,
    FullOesDto,
    LedgerDto,
    OperationsExpenseStatementsService,
} from 'src/app/generated-sources/accounting';
import { Column, RowData } from 'src/app/shared/components/simple-table/simple-table-row/simple-table-row.component';
import { LedgerCustomService } from '../../services/ledger-custom.service';
import { DeleteOesOccupationDistributionComponent } from '../delete-oes-occupation-distribution/delete-oes-occupation-distribution.component';

type ColumnKey = 'distributionKeyUsed' | 'share' | 'valueDaysUse' | 'totalValueAllTenants' | 'factor';
type TableData = {
    columns: Column<ColumnKey>[];
    rows: RowData<ColumnKey>[];
};

type CostsColumnKey = 'costsDistribution' | 'totalValue' | 'distributionKey' | 'factor' | 'share' | 'componentToDelete';
type CostsTableData = {
    columns: Column<CostsColumnKey>[];
    rows: RowData<CostsColumnKey>[];
};

@Component({
    selector: 'app-oes-individual-costs-for-occupation',
    templateUrl: './oes-individual-costs-for-occupation.component.html',
    styleUrls: ['./oes-individual-costs-for-occupation.component.scss'],
})
export class OesIndividualCostsForOccupationComponent implements OnChanges {
    @Input() public occupationId = '';
    @Input() public ledgerId = '';
    @Input() public oesId = '';
    @Output() public showStatementComponent = new EventEmitter<void>();
    @Input() public oesDate = '';
    @Input() public oesActivationDate = '';
    @Input() public oesLengthInDays = 0;
    @Input() public oesStatus?: FullOesDto.StatusEnum;

    @Input() public triggerRefresh = 1;

    public occupation?: FullOccupationOesDto;
    public isLoading = false;
    public propertyId = '';
    public ledgerType?: LedgerDto.TypeEnum;

    public distributionKeyTableData: TableData = {
        columns: [
            {
                key: 'distributionKeyUsed',
                label: 'Verwendete Verteilerschlüssel',
                columnCellClass: '[&&]:tw-s-head-14-22-bold [&&]:tw-text-scalaraGray-01',
            },
            { key: 'share', label: 'Ihr Anteil' },
            { key: 'valueDaysUse', label: 'Anteil laut Nutzungstage' },
            { key: 'totalValueAllTenants', label: 'Gesamtwert' },
            { key: 'factor', label: 'Faktor' },
        ],
        rows: [],
    };

    public costsTables: CostsTableData[] = [];

    public homeCostsTableData: CostsTableData = {
        columns: [
            {
                key: 'costsDistribution',
                label: 'Umlagefähige Kosten',
                columnCellClass: '[&&]:tw-s-head-14-22-bold [&&]:tw-text-scalaraGray-01',
            },
            { key: 'totalValue', label: 'Gesamtwert in €' },
            { key: 'distributionKey', label: 'Verteilungsschlüssel' },
            { key: 'factor', label: 'Faktor' },
            { key: 'share', label: 'Ihr Anteil in €' },
        ],
        rows: [],
    };

    public constructor(
        private operationsExpenseStatementsService: OperationsExpenseStatementsService,
        private ledgerCustomService: LedgerCustomService
    ) {}

    public ngOnChanges(): void {
        this.init();
    }

    public init(): void {
        this.isLoading = true;
        this.ledgerCustomService
            .getLedger$()
            .pipe(
                switchMap((ledger) => {
                    this.ledgerType = ledger?.type;
                    return this.operationsExpenseStatementsService.findOccupationOes(
                        this.ledgerId,
                        this.oesId,
                        this.occupationId
                    );
                }),
                tap((occupation) => {
                    this.occupation = occupation;
                    this.distributionKeyTableData.rows = this.occupation.usedDistributionKeys?.map((key) => {
                        return {
                            cells: [
                                {
                                    key: 'distributionKeyUsed',
                                    type: 'value',
                                    value: key.description,
                                },
                                {
                                    key: 'share',
                                    type: 'value',
                                    value: key.distributionBaseShare,
                                    pipeToApply: key.distributionBase === 'CONSUMPTION' ? 'eurocent' : 'number-WVDD',
                                },
                                {
                                    key: 'valueDaysUse',
                                    type: 'value',
                                    value: key.usageDaysShare,
                                    pipeToApply: key.distributionBase === 'CONSUMPTION' ? 'eurocent' : 'number-WVDD',
                                },
                                {
                                    key: 'totalValueAllTenants',
                                    type: 'value',
                                    value: key.totalDistribution,
                                    pipeToApply: key.distributionBase === 'CONSUMPTION' ? 'eurocent' : 'number-WVDD',
                                },
                                { key: 'factor', type: 'value', value: key.factor, pipeToApply: 'number-WVDD' },
                            ],
                        };
                    });

                    this.distributionKeyTableData.rows?.unshift({
                        cells: [
                            {
                                key: 'distributionKeyUsed',
                                value: '',
                                type: 'value',
                            },
                        ],
                        rowClass: '[&&]:tw-h-8',
                    });

                    this.costsTables = this.buildCostsTableData(occupation);

                    this.homeCostsTableData.rows = this.occupation.homeRelatedCosts?.map((key) => {
                        return {
                            cells: [
                                {
                                    key: 'costsDistribution',
                                    value:
                                        key.accountName + (key.accountDescription ? ', ' + key.accountDescription : ''),
                                    type: 'value',
                                },
                                { key: 'totalValue', value: key.totalValue, type: 'value', pipeToApply: 'eurocent' },
                                { key: 'distributionKey', value: key.distributionKeyName, type: 'value' },
                                {
                                    key: 'factor',
                                    value: key.distributionKeyFactor,
                                    type: 'value',
                                    pipeToApply: 'number-WVDD',
                                },
                                { key: 'share', value: key.ownShare, type: 'value', pipeToApply: 'eurocent' },
                            ],
                            rowClass: key.excluded ? '[&&]:tw-text-scalaraGray-04' : '',
                        };
                    });

                    this.homeCostsTableData.rows?.unshift({
                        cells: [
                            {
                                key: 'costsDistribution',
                                value: '',
                                type: 'value',
                            },
                        ],
                        rowClass: '[&&]:tw-h-8',
                    });

                    this.homeCostsTableData.rows?.push(
                        {
                            cells: [
                                {
                                    key: 'costsDistribution',
                                    value: '',
                                    type: 'value',
                                },
                            ],
                            rowClass: '[&&]:tw-h-8',
                        },
                        {
                            cells: [
                                {
                                    key: 'costsDistribution',
                                    value: 'Summe',
                                    type: 'value',
                                    rowCellClass: '[&&]:tw-pt-4',
                                },
                                { key: 'totalValue', value: '', type: 'value', rowCellClass: '[&&]:tw-pt-4' },
                                { key: 'distributionKey', value: '', type: 'value', rowCellClass: '[&&]:tw-pt-4' },
                                {
                                    key: 'factor',
                                    value: '',
                                    type: 'value',
                                    rowCellClass: '[&&]:tw-pt-4',
                                    pipeToApply: 'number-WVDD',
                                },
                                {
                                    key: 'share',
                                    value: occupation.totalHomeRelatedCosts,
                                    type: 'value',
                                    rowCellClass: '[&&]:tw-pt-4',
                                    pipeToApply: 'eurocent',
                                },
                            ],
                            rowClass:
                                '[&&]:tw-border-solid [&&]:tw-border-t [&&]:tw-border-scalaraGray-01  [&&]:tw-s-head-16-24-bold [&&]:tw-text-scalaraGray-01 [&&]:tw-h-10',
                        }
                    );
                    this.isLoading = false;
                })
            )
            .subscribe();
    }

    protected buildCostsTableData(oes: FullOccupationOesDto): CostsTableData[] {
        const tables: CostsTableData[] = [];
        let currentTable: CostsTableData | null = null;
        for (const group of oes.costGroups) {
            currentTable = {
                columns: [
                    {
                        key: 'costsDistribution',
                        label: group.groupName,
                        columnCellClass: '[&&]:tw-s-head-14-22-bold [&&]:tw-text-scalaraGray-01',
                    },
                    { key: 'totalValue', label: 'Gesamtwert in €' },
                    { key: 'distributionKey', label: 'Verteilungsschlüssel' },
                    { key: 'factor', label: 'Faktor' },
                    { key: 'share', label: 'Ihr Anteil in €' },
                    { key: 'componentToDelete', label: '' },
                ],
                rows: [],
            };
            currentTable.rows = group.costDistributions
                ?.filter((data) => data.totalValue > 0 || data.totalValue < 0 || data.ownShare > 0 || data.ownShare < 0)
                .map((key) => ({
                    cells: [
                        {
                            key: 'costsDistribution',
                            value: key.accountName + (key.accountDescription ? ', ' + key.accountDescription : ''),
                            type: 'value',
                        },
                        { key: 'totalValue', value: key.totalValue, type: 'value', pipeToApply: 'eurocent' },
                        { key: 'distributionKey', value: key.distributionKeyName, type: 'value' },
                        {
                            key: 'factor',
                            value: key.distributionKeyFactor,
                            type: 'value',
                            pipeToApply: 'number-WVDD',
                        },
                        { key: 'share', value: key.ownShare, type: 'value', pipeToApply: 'eurocent' },
                        {
                            key: 'componentToDelete',
                            value: '',
                            type: 'component',
                            component:
                                this.oesStatus === 'DRAFT' ? DeleteOesOccupationDistributionComponent : undefined,
                            componentData:
                                this.oesStatus === 'DRAFT'
                                    ? {
                                          ledgerId: this.ledgerId,
                                          oesId: this.oesId,
                                          occupationId: this.occupationId,
                                          accountId: key.accountId,
                                          excluded: key.excluded,
                                          refreshTable: (): void => this.init(),
                                      }
                                    : undefined,
                        },
                    ],
                    rowClass: key.excluded ? '[&&]:tw-text-scalaraGray-04' : '',
                }));

            currentTable.rows.push(
                {
                    cells: [
                        {
                            key: 'costsDistribution',
                            value: 'Summe ' + group.groupName,
                            type: 'value',
                        },
                        {
                            key: 'totalValue',
                            value: group.totalValue,
                            type: 'value',
                            pipeToApply: 'eurocent',
                        },
                        { key: 'distributionKey', value: '', type: 'value' },
                        { key: 'factor', value: '', type: 'value', pipeToApply: 'number-WVDD' },
                        {
                            key: 'share',
                            value: group.ownShare,
                            type: 'value',
                            pipeToApply: 'eurocent',
                        },
                        { key: 'componentToDelete', value: '', type: 'value' },
                    ],
                    rowClass:
                        '[&&]:tw-bg-scalaraGray-06 [&&]:tw-s-head-14-22-bold [&&]:tw-text-scalaraGray-01 [&&]:tw-h-10  ',
                },
                {
                    cells: [
                        {
                            key: 'costsDistribution',
                            value: '',
                            type: 'value',
                        },
                    ],
                    rowClass: '[&&]:tw-h-8',
                },
                {
                    cells: [
                        {
                            key: 'costsDistribution',
                            value: '',
                            type: 'value',
                        },
                    ],
                    rowClass: '[&&]:tw-h-8',
                }
            );
            tables.push(currentTable);
        }
        //The following summaries are added to the last table
        currentTable?.rows?.push(
            {
                cells: [
                    {
                        key: 'costsDistribution',
                        value: 'Gesamtkosten',
                        type: 'value',
                    },
                    {
                        key: 'totalValue',
                        value: oes.totalValue,
                        type: 'value',
                        pipeToApply: 'eurocent',
                    },
                    { key: 'distributionKey', value: '', type: 'value' },
                    { key: 'factor', value: '', type: 'value', pipeToApply: 'number-WVDD' },
                    {
                        key: 'share',
                        value: oes.ownShare,
                        type: 'value',
                        pipeToApply: 'eurocent',
                    },
                    { key: 'componentToDelete', value: '', type: 'value' },
                ],
                rowClass: '[&&]:tw-s-head-14-22-bold [&&]:tw-text-scalaraGray-01 [&&]:tw-h-10  ',
            },
            {
                cells: [
                    {
                        key: 'costsDistribution',
                        value: 'Gesamtvorauszahlungen',
                        type: 'value',
                    },
                    { key: 'totalValue', value: '', type: 'value' },
                    { key: 'distributionKey', value: '', type: 'value' },
                    { key: 'factor', value: '', type: 'value', pipeToApply: 'number-WVDD' },
                    {
                        key: 'share',
                        value: oes.totalAdvancementAmount,
                        type: 'value',
                        pipeToApply: 'eurocent',
                    },
                    { key: 'componentToDelete', value: '', type: 'value' },
                ],
                rowClass: '[&&]:tw-s-head-14-22-bold [&&]:tw-text-scalaraGray-01 [&&]:tw-h-10  ',
            },

            {
                cells: [
                    {
                        key: 'costsDistribution',
                        value: 'Abrechnungsergebnis',
                        type: 'value',
                        rowCellClass: '[&&]:tw-pt-4',
                    },
                    { key: 'totalValue', value: '', type: 'value', rowCellClass: '[&&]:tw-pt-4' },
                    { key: 'distributionKey', value: '', type: 'value', rowCellClass: '[&&]:tw-pt-4' },
                    {
                        key: 'factor',
                        value: oes.balanceAmount > 0 ? 'Erstattung' : 'Nachzahlung',
                        type: 'value',
                        rowCellClass: '[&&]:tw-pt-4',
                        pipeToApply: 'number-WVDD',
                    },
                    {
                        key: 'share',
                        value: oes.balanceAmount,
                        type: 'value',
                        rowCellClass: '[&&]:tw-pt-4',
                        pipeToApply: 'eurocent',
                    },
                    { key: 'componentToDelete', value: '', type: 'value', rowCellClass: '[&&]:tw-pt-4' },
                ],
                rowClass:
                    '[&&]:tw-border-solid [&&]:tw-border-t [&&]:tw-border-scalaraGray-01  [&&]:tw-s-head-16-24-bold [&&]:tw-text-scalaraGray-01 [&&]:tw-h-10',
            }
        );
        return tables;
    }

    public backPage(): void {
        this.showStatementComponent.emit();
    }

    public getOccupationDescription(): {
        occupationDetail: string;
        occupationNumberAndTenants: string;
    } {
        const occupation = this.occupation?.occupation;
        const units = occupation?.unitsIncluded.map((unit) => unitTypeNameFromEnum(unit) + ' ' + unit.name).join(', ');
        const tenants = occupation?.tenants
            .map((tenant) => (tenant && (tenant.firstName || tenant.companyName) ? getNameFromPerson(tenant) : ''))
            .join(', ');

        return {
            occupationDetail: occupation?.occupationNumber + ', ' + units,
            occupationNumberAndTenants: occupation?.occupationType === 'VACANCY' ? '' : tenants || '-',
        };
    }
}
