import { Component, Input } from '@angular/core';

type Type = 'info' | 'error' | 'warning' | 'success' | 'attention';
@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent {
    @Input() public type?: Type;
    @Input() public title?: string;
    @Input() public subtitle?: string;
    @Input() public direction: 'row' | 'column' = 'row';
    @Input() public customIconSrc?: string;
    @Input() public iconSize: 's' | 'm' | 'l' = 's';

    public iconSrcMap: Record<Type, string> = {
        error: '24_error.svg',
        warning: '24_attention.svg',
        info: '24_information.svg',
        success: '24_closed.svg',
        attention: '24_attention.svg',
    };
}
