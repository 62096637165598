import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ListItem } from 'carbon-components-angular';
import { forkJoin, map, of, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { formatDateWithoutHhMmSs, getNameFromPerson } from 'src/app/core/utils/common';
import { AddOccupationComponent } from 'src/app/features/occupation/components/add-occupation/add-occupation.component';
import { MediaQueriesService } from 'src/app/shared/services/media-queries.service';
import { TableItem } from 'src/app/shared/table/interfaces/table-item';
import {
    Occupation,
    OccupationForm,
    OccupationService,
    Person,
    PersonsService,
    Property,
} from '../../../../generated-sources/base';
import { OverlayService } from '../../../../shared/overlay/services/overlay.service';
import { CellTemplate } from '../../../../shared/table/enums/cell-template';
import { TableModel } from '../../../../shared/table/interfaces/table-model';
import { PersonLocalService } from '../../services/person-local.service';
import { PropertyCustomService } from '../../services/property-custom.service';
@Component({
    selector: 'app-property-overview',
    templateUrl: './occupations-overview.component.html',
    styleUrls: ['./occupations-overview.component.scss'],
})
export class OccupationsOverviewComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();

    public tableHeader: any[] = [];
    public tableModel: TableModel[] = [{ data: [], header: [] }];
    public propertyType?: Property.PropertyTypeEnum;
    public showInfoBox = false;
    public filterControl?: ListItem;

    public filterItems: ListItem[] = [
        {
            content: 'Aktuell',
            selected: false,
            value: 'current',
        },
        {
            content: 'Beendet',
            selected: false,
            value: 'completed',
        },
        {
            content: 'Kommend',
            selected: false,
            value: 'coming',
        },
        {
            content: 'Alle',
            selected: true,
            value: 'all',
        },
    ];

    //tableModel[0] => Alle
    //tableModel[1] => Vermietung
    //tableModel[2] => Leerstand
    //tableModel[3] => Selbstnutzung

    public propertyId = '';
    public occupations: any[] = [];
    public unfinishedOccupations: OccupationForm[] = [];
    public persons: Person[] = [];

    @ViewChild('iconInfoTemplate', { static: true })
    public iconInfoTemplate?: TemplateRef<any>;

    public isLoading = false;
    public isInitialLoading = false;
    public person: Person | null = null;

    @ViewChild('propertyTable', { static: true })
    public propertyTable?: TemplateRef<any>;

    public constructor(
        private overlayService: OverlayService,
        public mediaQueriesService: MediaQueriesService,
        public occupationService: OccupationService,
        public router: Router,
        private propertyCustomService: PropertyCustomService,
        private personService: PersonsService,
        private personLocalService: PersonLocalService
    ) {}

    public ngOnInit(): void {
        this.isInitialLoading = true;
        this.tableModel[1] = { data: [], header: [] };
        this.tableModel[2] = { data: [], header: [] };
        this.tableModel[3] = { data: [], header: [] };
        this.initTableHeader();
        this.getOccupations();

        this.filterControl = {
            content: 'Alle',
            selected: true,
            value: 'all',
        };

        this.personLocalService
            .getPerson$()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((person) => (this.person = person));
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    private getOccupations(): void {
        this.isLoading = true;
        this.propertyCustomService
            .getProperty$()
            .pipe(
                tap((property) => {
                    this.propertyId = property?.id || '';
                    this.propertyType = property?.propertyType;
                }),
                switchMap(() =>
                    forkJoin([
                        this.occupationService.findAllOccupations(this.propertyId),
                        this.occupationService.findAllOccupationForms(this.propertyId),
                    ])
                ),
                switchMap(([occupations, occupationForm]) => {
                    //  if inside of occupation form tenants are drafts,
                    //  these persons exist in draft mode in form.tenants array
                    // const ids = occupationForm.map((item) => item.tenantIds).filter(Boolean);
                    const ids = occupationForm
                        .map((item) => item.tenantIds)
                        .flat()
                        .filter(Boolean);

                    if (ids.length > 0) {
                        return this.personService
                            .findAll(ids.join(','))
                            .pipe(map((persons) => ({ occupations, occupationForm, persons })));
                    }

                    return of({ occupations, occupationForm, persons: [] });
                }),
                tap(({ occupations, occupationForm, persons }) => {
                    this.persons = persons;
                    const filter = this.filterControl ? this.filterControl['value'] : 'all';

                    this.occupations = occupations.filter((occupation) => {
                        const today = new Date();
                        const from = new Date(occupation.from);
                        const to = occupation.to && occupation.to !== '' ? new Date(occupation.to) : undefined;

                        if (filter === 'all' || !filter) {
                            return true;
                        }
                        if (filter === 'coming') {
                            return from > today;
                        }
                        if (filter === 'current') {
                            if (to) {
                                return from < today && to > today;
                            }
                            return from < today;
                        }
                        if (filter === 'completed' && to) {
                            return to < today;
                        }

                        return false;
                    });
                    this.unfinishedOccupations = occupationForm.filter((occupation) => {
                        const today = new Date();
                        const from = occupation.from ? new Date(occupation.from) : undefined;
                        const to = occupation.to && occupation.to !== '' ? new Date(occupation.to) : undefined;

                        if (filter === 'all') {
                            return true;
                        }
                        if (filter === 'coming' && from) {
                            return from > today;
                        }
                        if (filter === 'current' && from) {
                            if (to) {
                                return from < today && to > today;
                            }
                            return from < today;
                        }
                        if (filter === 'completed' && to && from) {
                            return to < today;
                        }

                        return false;
                    });

                    // all
                    this.tableModel[0].data = this.occupations.map((item) => this.createRowCommitedOccupation(item));
                    this.tableModel[0].data = [
                        ...this.tableModel[0].data,
                        ...this.unfinishedOccupations.map((item) => this.createRow(item)),
                    ];

                    // RENTING
                    this.tableModel[1].data = this.occupations
                        .filter((item) => item.occupationType === 'RENTING')
                        .map((item) => this.createRowCommitedOccupation(item));
                    this.tableModel[1].data = [
                        ...this.tableModel[1].data,
                        ...this.unfinishedOccupations
                            .filter((item) => item.occupationType === 'RENTING')
                            .map((item) => this.createRow(item)),
                    ];

                    // VACANCY
                    this.tableModel[2].data = this.occupations
                        .filter((item) => item.occupationType === 'VACANCY')
                        .map((item) => this.createRowCommitedOccupation(item));

                    this.tableModel[2].data = [
                        ...this.tableModel[2].data,
                        ...this.unfinishedOccupations
                            .filter((item) => item.occupationType === 'VACANCY')
                            .map((item) => this.createRow(item)),
                    ];

                    // OWN_OCCUPATION
                    this.tableModel[3].data = this.occupations
                        .filter((item) => item.occupationType === 'OWN_OCCUPATION')
                        .map((item) => this.createRowCommitedOccupation(item));

                    this.tableModel[3].data = [
                        ...this.tableModel[3].data,
                        ...this.unfinishedOccupations
                            .filter((item) => item.occupationType === 'OWN_OCCUPATION')
                            .map((item) => this.createRow(item)),
                    ];
                }),
                switchMap(() => {
                    return this.occupationService.getFormUnitSelectionList(this.propertyId);
                }),
                tap((data) => {
                    this.showInfoBox = !data || data.length === 0;
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => {
                this.isLoading = false;
                this.isInitialLoading = false;
            });
    }

    public openAddOccupationOverlay(): void {
        const ref = this.overlayService.open(AddOccupationComponent, {});
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.getOccupations());
    }

    private initTableHeader(): void {
        this.tableHeader = [
            {
                data: { key: 'PAGES.OCCUPATION.OVERVIEW.LABEL_TYPE', params: {} },
                width: '300px',
            },
            'PAGES.OCCUPATION.OVERVIEW.LABEL_OCCUPATION_NUMBER',
            'PAGES.OCCUPATION.OVERVIEW.LABEL_UNITS',
            'PAGES.OCCUPATION.OVERVIEW.LABEL_PERSONS',
            'PAGES.OCCUPATION.OVERVIEW.LABEL_FROM_TO',
            '',
        ];
    }

    private getOccupationType(
        occupationType: OccupationForm.OccupationTypeEnum,
        rentType?: OccupationForm.RentTypeEnum
    ): string {
        if (occupationType === 'RENTING') {
            if (rentType === 'PRIVATE') {
                return 'Vermietung, privat';
            } else {
                return 'Vermietung, gewerblich';
            }
        } else if (occupationType === 'VACANCY') {
            return 'Leerstand';
        } else {
            return 'Selbstnutzung';
        }
    }

    private createRowCommitedOccupation(data: Occupation): TableItem[] {
        const link = `/properties/${this.propertyId}/occupations/${data.id}/commit`;
        const units = data.unitsIncluded.map((unit) => unit.name);
        const personNames = data.tenants?.map((p) => getNameFromPerson(p));
        return [
            {
                data: {
                    label: this.getOccupationType(
                        data.occupationType || 'RENTING',
                        data.rentDetails?.rentType || undefined
                    ),
                    link,
                    iconSrc:
                        data.occupationType === 'VACANCY' ? '/assets/icons/24_open.svg' : '/assets/icons/24_closed.svg',
                    extraData: { color: data.occupationType === 'VACANCY' ? undefined : 'green' },
                },
                template: CellTemplate.textWithIcon,
            },
            {
                data: {
                    label: data.occupationNumber,
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: units?.join(', ') || '',
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: personNames?.join(', ') || '',
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: `${formatDateWithoutHhMmSs(data.from)} – ${
                        data.to ? formatDateWithoutHhMmSs(data.to) : 'unbefristet'
                    }`,
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: '',
                    link,
                },
                template: CellTemplate.Default,
            },
        ];
    }

    private createRow(data: OccupationForm): TableItem[] {
        const link = `/properties/${this.propertyId}/occupations/${data.id}/draft`;
        const persons =
            data.tenants?.map((person) => {
                return getNameFromPerson(person);
            }) ?? [];
        const existingPerson: string[] =
            data.tenantIds
                ?.map((tenantId) => {
                    const findPerson = this.persons.find((person) => person.id === tenantId);
                    return findPerson ? getNameFromPerson(findPerson) : '';
                })
                .filter(Boolean) ?? [];

        const personWithExistingPerson = [...persons, ...existingPerson];
        return [
            {
                data: {
                    label: this.getOccupationType(data.occupationType || 'RENTING', data.rentType || undefined),
                    iconSrc:
                        data.occupationType === 'VACANCY' ? '/assets/icons/24_open.svg' : '/assets/icons/24_closed.svg',
                    link,
                    extraData: { color: data.occupationType === 'VACANCY' ? undefined : 'green' },
                },
                template: CellTemplate.textWithIcon,
            },
            {
                data: {
                    label: 'Entwurf',
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: data.unitsNames?.join(', ') || '',
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: personWithExistingPerson?.join(', ') || '',
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: `${formatDateWithoutHhMmSs(data.from)} – ${
                        data.to ? formatDateWithoutHhMmSs(data.to) : 'unbefristet'
                    }`,
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: '',
                    link,
                },
                template: this.iconInfoTemplate,
            },
        ];
    }

    public onSelectFilter(): void {
        this.getOccupations();
    }
}
