import { Component, DoCheck, Input } from '@angular/core';
import { OpenItemDto } from 'src/app/generated-sources/accounting';
import { EurocentPipe } from 'src/app/shared/pipes/eurocent.pipe';
import { DetailsContainerComponent } from '../../../../../details-container/details-container.component';
import { OpenItemsCustomService } from '../../../../service/open-items-custom.service';

export interface AdditionalOpenItemData {
    isDue: boolean;
    bookingDescription: string;
}

@Component({
    selector: 'app-open-item-details-card',
    templateUrl: './open-item-details-card.component.html',
    styleUrls: ['./open-item-details-card.component.scss'],
})
export class OpenItemDetailsCardComponent implements DoCheck {
    public openItemsCustomService = new OpenItemsCustomService();
    @Input() public openItem = {} as OpenItemDto;
    @Input() public additionalOpenItemData = {} as AdditionalOpenItemData;
    @Input() public openItemType?: OpenItemDto.TypeEnum;

    public options: DetailsContainerComponent['options'] = {} as DetailsContainerComponent['options'];

    public constructor(private eurocentPipe: EurocentPipe) {}

    public ngDoCheck(): void {
        this.options = {
            type: 'openItem',
            state: this.getOptionsState(),
            isDue: this.additionalOpenItemData.isDue,
        };
    }

    public getOptionsState(): DetailsContainerComponent['options']['state'] {
        if (this.openItem.state === 'OPEN') {
            return 'attention';
        }
        if (this.openItem.state === 'PARTLY_CLOSED') {
            return 'warning';
        }
        if (this.openItem.state === 'CLOSED') {
            return 'ok';
        }
        console.warn('error in getOptionsState');
        return 'attention';
    }
}
