import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';

export type DateRangePicker = {
    startDate: Date;
    endDate?: Date;
};

@Component({
    selector: 'app-date-range-picker',
    templateUrl: './date-range-picker.component.html',
    styleUrls: ['./date-range-picker.component.scss'],
})
export class DateRangePickerComponent implements OnInit {
    public constructor(private formBuilder: UntypedFormBuilder) {}

    @Input() public defaultDates: DateRangePicker = {
        startDate: new Date(new Date().getFullYear(), 0, 1),
        endDate: undefined,
    };

    public dateRangeForm: UntypedFormGroup = new UntypedFormGroup({});

    public ngOnInit(): void {
        this.dateRangeForm = this.formBuilder.group(
            {
                startDate: [[this.defaultDates.startDate], [Validators.required]],
                endDate: [[this.defaultDates.endDate]],
            },
            { validators: [this.createDateRangeValidator()] }
        );
    }

    public createDateRangeValidator(): any {
        return (form: UntypedFormGroup): ValidationErrors | null => {
            const startDate: Date = form.get('startDate')?.value[0];
            const endDate: Date = form.get('endDate')?.value[0];

            if (startDate && endDate) {
                const isInvalid = endDate < startDate;
                return isInvalid ? { dateRange: true } : null;
            }

            return null;
        };
    }
}
