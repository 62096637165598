<app-delete-overlay
    title="PAGES.DELETE_TICKET.TITLE"
    headline="PAGES.DELETE_TICKET.HEADLINE"
    subtitle="PAGES.DELETE_TICKET.SUBTITLE"
    (closeOverlay)="abort()"
    (submitOverlay)="onSubmit()"
>
    <div class="d-flex align-items-center ticket-name p-1r s-head-14-22-bold">
        {{ ticketName }}
    </div>
</app-delete-overlay>
