<div class="w-100 text-right formated-icon">
    <div class="icon-button-receipts--transparent w-100">
        <button (click)="openDeleteRelationOvervlayEvent()">
            <svg-icon src="/assets/icons/24_delete.svg"></svg-icon>
        </button>
        <button (click)="openEditRelationOvervlayEvent()">
            <svg-icon src="/assets/icons/24_edit.svg"></svg-icon>
        </button>
    </div>
</div>
