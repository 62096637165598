import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { LedgerStatusInfoDto } from 'src/app/generated-sources/accounting';

@Injectable({
    providedIn: 'root',
})
export class StatusService {
    public statusSubject = new Subject<LedgerStatusInfoDto>();
    private status?: LedgerStatusInfoDto;

    public setStatus(status: LedgerStatusInfoDto): void {
        this.status = status;
        this.statusSubject.next(this.status);
    }

    public getStatusObservable(): Observable<LedgerStatusInfoDto> {
        return this.statusSubject;
    }

    public getLastStatus(): void {
        if (this.status) {
            this.statusSubject.next(this.status);
        }
    }
}
