import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    BehaviorSubject,
    ObservedValueOf,
    Subject,
    combineLatest,
    map,
    shareReplay,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { getColorOfPersonTypeTag, getNameFromPerson, personTypeNameFromEnum } from 'src/app/core/utils/common';
import { PersonLocalService } from 'src/app/features/property/services/person-local.service';
import {
    Person,
    PersonsService,
    RelationsControllerFindAll200ResponseInner,
    RelationsService,
} from 'src/app/generated-sources/base';
import { DateTimeFormatPipe } from 'src/app/shared/pipes/date-time-format.pipe';
import { TableModel } from 'src/app/shared/table/interfaces/table-model';
import { CellTemplate } from '../../../../shared/table/enums/cell-template';
import { TableItem } from '../../../../shared/table/interfaces/table-item';

type RelationDTO = RelationsControllerFindAll200ResponseInner & {
    isOwnerAndServiceProvider?: boolean;
    isAdviser?: true;
};
@Component({
    selector: 'app-contacts-index',
    templateUrl: './contacts-index.component.html',
    styleUrls: ['./contacts-index.component.scss'],
})
export class ContactsIndexComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();

    private triggerPipe$: Subject<void> = new BehaviorSubject<void>(undefined);

    public tableModelHeader: TableModel['header'] = [];
    public isLoading = false;

    public alreadyInvited = false;
    public isPropertyManager = false;

    private relationTypesObj: Record<Person.PersonRolesEnum, string> = {
        IS_ADVISER: 'Adviser',
        IS_OWNER: 'Eigentümer',
        IS_CO_OWNER: 'Miteigentümer',
        IS_PROPERTY_MANAGER: 'Hausverwalter',
        IS_SERVICE_PROVIDER: 'Dienstleister',
        IS_TENANT: 'Mieter',
        IS_RESIDENT: 'Bewohner',
        IS_PERSON_DRAFT: '',
    };

    public person$ = this.personLocalService.getPerson$();

    //sort persons to show first registered users, then alpabetically in two groups
    public persons$ = this.triggerPipe$.pipe(
        tap(() => {
            this.isLoading = true;
        }),
        switchMap(() => this.personsService.findAll()),
        map((persons) => {
            const registeredPersons = persons.filter((p) => p.hasRegisteredUser).sort();
            const unregisteredPersons = persons.filter((p) => !p.hasRegisteredUser).sort();
            return [...registeredPersons, ...unregisteredPersons];
        }),
        tap(() => {
            this.isLoading = false;
        }),
        takeUntil(this.unsubscribe$),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    public tableModelData$ = this.persons$.pipe(
        map((persons) => {
            return persons.map((person) => this.createRow(person));
        })
    );

    public vm$ = combineLatest([this.person$, this.persons$, this.tableModelData$]).pipe(
        map(([person, persons, tableModelData]) => {
            return { person, persons, tableModelData };
        })
    );

    public vm: ObservedValueOf<typeof this.vm$> | null = null;

    @ViewChild('label', { static: true })
    public label?: TemplateRef<void>;

    public constructor(
        public translateService: TranslateService,
        private personsService: PersonsService,
        private personLocalService: PersonLocalService,
        private relationsService: RelationsService,
        private toastService: ToastService,
        private dateTimeFormatPipe: DateTimeFormatPipe
    ) {}

    public ngOnInit(): void {
        this.vm$.subscribe((vm) => {
            this.vm = vm;
        });

        this.initTableHeader();
    }

    public inviteContact($event: any): void {
        this.personsService
            .invite($event.extraData.id)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.toastService.showSuccess(
                    this.translateService.instant('PAGES.CONTACT.DETAIL_VIEW.INVITE_TOAST_SUCCESS')
                );
                this.triggerPipe$.next();
            });
    }

    private initTableHeader(): void {
        this.tableModelHeader = [
            'ENTITIES.PERSON.LABEL_COMPANY_NAME',
            'ENTITIES.RELATION.LABEL_RELATION_TYPE',
            'ENTITIES.PERSON.LABEL_PERSON_TYPE',
            '',
        ];
    }

    private createRow(person: Person): TableItem[] {
        const link = person.id;
        const roles = person.personRoles;
        const isAdviser = roles.includes('IS_ADVISER');
        //  map person roles to label, filter out draft persons and advisers (advicers are shown separately as labelTag)
        const personTypeLabelToFilterOut = ['IS_PERSON_DRAFT', 'IS_ADVISER'];
        const relationLabel = person.personRoles
            .filter((role) => personTypeLabelToFilterOut.includes(role) === false)
            .map((role) => {
                const roleLabel = this.relationTypesObj[role];
                return roleLabel;
            })
            .join(', ');

        const isInvitable = Object.values(person.permissionRoles).includes(Person.PermissionRolesEnum.PropertyManager);
        return [
            {
                data: {
                    label: person ? getNameFromPerson(person) : 'Keine Person gefunden',
                    link,
                    extraData: {
                        isUserRegistered: person.hasRegisteredUser,
                        img: person.imgSmall,
                    },
                },
                template: person.imgSmall ? CellTemplate.imageTableItemWithLabel : CellTemplate.personWithAvatar,
            },
            {
                data: {
                    label: relationLabel,
                    link,
                },
                template: isAdviser ? this.label : CellTemplate.Default,
            },
            {
                data: {
                    label: person ? personTypeNameFromEnum(person) : 'Keine Person gefunden',
                    link,
                    textColor: getColorOfPersonTypeTag(person),
                },
                template: CellTemplate.contentWithTagStyle,
            },
            {
                data: {
                    label: person.lastInvited
                        ? this.translateService.instant('PAGES.CONTACT.DETAIL_VIEW.INVITE_AGAIN')
                        : this.translateService.instant('PAGES.CONTACT.DETAIL_VIEW.INVITE'),
                    rightAligned: true,

                    extraData: {
                        text: person.lastInvited
                            ? this.translateService.instant('PAGES.CONTACT.DETAIL_VIEW.INVITED_ON') +
                              ' ' +
                              this.dateTimeFormatPipe.transform(person.lastInvited)
                            : '',
                        id:
                            person.email &&
                            isInvitable &&
                            !person.hasRegisteredUser &&
                            relationLabel !== this.relationTypesObj['IS_PROPERTY_MANAGER']
                                ? link
                                : '',
                        relationType: relationLabel,
                    },
                },
                template: CellTemplate.textAndButton,
            },
        ];
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }
}
