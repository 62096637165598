import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil, tap } from 'rxjs';
import { CustomerStatisticDto, OperationsService, PlatformStatisticDto } from 'src/app/generated-sources/base';

@Component({
    selector: 'app-operation-statistics-overview',
    templateUrl: './operation-statistics-overview.component.html',
    styleUrls: ['./operation-statistics-overview.component.scss'],
})
export class OperationStatisticsOverviewComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();
    public isLoading = false;
    public platformStatistics?: PlatformStatisticDto;
    public customerSubscriptions?: CustomerStatisticDto[];

    public constructor(private statisticsController: OperationsService) {}

    public ngOnInit(): void {
        this.isLoading = true;
        this.getPlatformStatistics();
        this.getSubscriptions();
    }

    private async getPlatformStatistics(): Promise<void> {
        await this.statisticsController
            .findPlatformDetails()
            .pipe(
                takeUntil(this.unsubscribe$),
                tap((result) => {
                    this.platformStatistics = result;
                    this.isLoading = false;
                })
            )
            .subscribe();
    }

    private async getSubscriptions(): Promise<void> {
        await this.statisticsController
            .getAllPersonsWithSubscription()
            .pipe(
                takeUntil(this.unsubscribe$),
                tap((result) => {
                    this.customerSubscriptions = result;
                })
            )
            .subscribe();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }
}
