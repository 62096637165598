import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ListItem } from 'carbon-components-angular';
import { Subject, takeUntil } from 'rxjs';
import { getNameFromPerson, getPropertyTypeInfos } from 'src/app/core/utils/common';
import { Property, TicketDto } from 'src/app/generated-sources/base';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { DateTimeFormatPipe } from 'src/app/shared/pipes/date-time-format.pipe';
import { CellTemplate } from 'src/app/shared/table/enums/cell-template';
import { TableItem } from 'src/app/shared/table/interfaces/table-item';
import { TableModel } from 'src/app/shared/table/interfaces/table-model';
import { TicketsCustomService } from '../../services/tickets-custom.service';
import { AddEditTicketFormComponent } from '../add-edit-ticket-form/add-edit-ticket-form.component';

export type TabsToShow = 'OPEN' | 'ALL' | 'IN_PROGRESS' | 'CLOSED';

export interface TicketWithIconInfos extends TicketDto {
    statusLabel: string;
    iconSrc: string;
    iconColor: string;
}
@Component({
    selector: 'app-tickets-index',
    templateUrl: './tickets-index.component.html',
    styleUrls: ['./tickets-index.component.scss'],
})
export class TicketsIndexComponent implements OnInit, OnDestroy {
    public tableModel: TableModel = { data: [], header: [] };

    public openTicketsTable: TableModel = { data: [], header: [] };
    public closedTicketsTable: TableModel = { data: [], header: [] };
    public ticketsInProgressTable: TableModel = { data: [], header: [] };

    public allTickets: TicketWithIconInfos[] = [];
    public openTickets: TicketWithIconInfos[] = [];
    public closedTickets: TicketWithIconInfos[] = [];
    public inProgressTickets: TicketWithIconInfos[] = [];
    public ticketsFiltered: any[] = [];

    public isLoading = false;
    public isInitialLoading = false;
    public tabsToShow: TabsToShow[] = [];

    public statusList: ListItem[] = [
        {
            selected: false,
            content: this.translateService.instant('ENTITIES.TICKET.ALL'),
            id: 'ALL',
        },
        {
            selected: true,
            content: this.translateService.instant('ENTITIES.TICKET.OPEN'),
            id: 'OPEN',
        },
        {
            selected: false,
            content: this.translateService.instant('ENTITIES.TICKET.IN_PROGRESS'),
            id: 'IN_PROGRESS',
        },
        {
            selected: false,
            content: this.translateService.instant('ENTITIES.TICKET.CLOSED'),
            id: 'CLOSED',
        },
    ];

    private unsubscribe$ = new Subject<void>();

    @ViewChild('commentsInfos', { static: true })
    public commentsInfos?: TemplateRef<any>;

    public constructor(
        private overlayService: OverlayService,
        private ticketsCustomService: TicketsCustomService,
        private dateTimeFormatPipe: DateTimeFormatPipe,
        private translateService: TranslateService
    ) {}

    public ngOnInit(): void {
        this.initTableHeader();

        this.tableModel.header = this.initTableHeader();
        this.ticketsInProgressTable.header = this.initTableHeader();
        this.closedTicketsTable.header = this.initTableHeader();
        this.openTicketsTable.header = this.initTableHeader();
        this.isInitialLoading = true;
        this.loadTickets('OPEN');
    }

    private compare(a: TicketDto, b: TicketDto): 1 | 0 | -1 {
        const date1 = new Date(a.createdAt).getTime();
        const date2 = new Date(b.createdAt).getTime();
        if (date1 > date2) {
            return -1;
        }
        if (date1 < date2) {
            return 1;
        }
        return 0;
    }

    public loadTickets(ticketType?: TabsToShow): void {
        this.isLoading = true;
        this.ticketsCustomService
            .getTickets$(ticketType === 'ALL' ? undefined : ticketType)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((tickets) => {
                let tableRef = null as unknown as TableModel;
                let ticketRef: 'allTickets' | 'openTickets' | 'closedTickets' | 'inProgressTickets';
                if (ticketType === 'OPEN') {
                    tableRef = this.openTicketsTable;
                    ticketRef = 'openTickets';
                } else if (ticketType === 'CLOSED') {
                    tableRef = this.closedTicketsTable;
                    ticketRef = 'closedTickets';
                } else if (ticketType === 'IN_PROGRESS') {
                    tableRef = this.ticketsInProgressTable;
                    ticketRef = 'inProgressTickets';
                } else {
                    tableRef = this.tableModel;
                    ticketRef = 'allTickets';
                }

                tickets = tickets.sort(this.compare);

                const formatedTicket = tickets.map((data) => {
                    let iconColor;
                    let iconSrc;
                    let statusLabel;

                    data.title = data.title?.length >= 200 ? data.title.slice(0, 200) + '...' : data.title;

                    if (data.status === 'IN_PROGRESS') {
                        iconColor = 's-blue-01';
                        iconSrc = '/assets/icons/24_progress.svg';
                        statusLabel = 'In Bearbeitung';
                    } else if (data.status === 'CLOSED') {
                        iconColor = 's-green-01';
                        iconSrc = '/assets/icons/24_closed.svg';
                        statusLabel = 'Abgeschlossen';
                    } else {
                        iconColor = 's-orange-01';
                        iconSrc = '/assets/icons/24_open.svg';
                        statusLabel = 'Offen';
                    }
                    const ticketFormated: TicketWithIconInfos = {
                        ...data,
                        statusLabel,
                        iconColor,
                        iconSrc,
                        createdAt: this.dateTimeFormatPipe.transform(data.createdAt),
                    };
                    this[ticketRef].push(ticketFormated);

                    return ticketFormated;
                });
                this.ticketsFiltered = this[ticketRef];
                tableRef.data = formatedTicket.map((data) => {
                    return this.createRow(data);
                });
                this.isLoading = false;
                this.isInitialLoading = false;
            });
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    private initTableHeader(): string[] {
        return [
            'ENTITIES.TICKET.STATUS',
            'ENTITIES.TICKET.TITLE',
            'ENTITIES.PROPERTY.LABEL_ENTITY',
            'ENTITIES.OCCUPATION.LABEL_MANAGEMENT_TYPE',
            'ENTITIES.TICKET.CREATOR',
            'ENTITIES.TICKET.CREATED_AT',
            '',
        ];
    }

    private createRow(data: TicketWithIconInfos): TableItem[] {
        const link = `${data.id}`;

        let tags: any = [];

        if (data.property?.propertyType == Property.PropertyTypeEnum.WegSev) {
            tags = [
                {
                    label: 'WEG',
                    textColor: getPropertyTypeInfos('WEG').color,
                    link: link,
                },
                {
                    label: 'SEV',
                    textColor: getPropertyTypeInfos('SEV').color,
                    link: link,
                },
            ];
        }
        return [
            {
                data: {
                    label: data.statusLabel,
                    link,
                    textColor: data.iconColor,
                    iconSrc: data.iconSrc,
                    extraData: { enableCustomSorting: true, showNewTag: false },
                },
                template: CellTemplate.DynamicIcon,
            },
            {
                data: {
                    label: data.title,
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: data.property.name,
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: tags.length ? '' : data.property?.propertyType,
                    link,
                    textColor: tags.length ? '' : getPropertyTypeInfos(data.property?.propertyType).color,
                    extraData: tags.length ? tags : null,
                },
                template: CellTemplate.contentWithTagStyle,
            },
            {
                data: {
                    label: data.owner ? getNameFromPerson(data.owner) : 'Keine Person gefunden',
                    link,
                    extraData: {
                        isUserRegistered: data.owner.hasRegisteredUser,
                        img: data.owner.imgSmall,
                    },
                },
                template: data.owner.imgSmall ? CellTemplate.imageTableItemWithLabel : CellTemplate.personWithAvatar,
            },
            {
                data: {
                    label: data.createdAt,
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: '',
                    link,
                    extraData: {
                        unreadComment: false,
                        numberOfAttachments: data.numberOfAttachments,
                        numberOfComments: data.numberOfComments,
                    },
                },
                template: this.commentsInfos,
            },
        ];
    }

    public openFormOverlay(): void {
        const ref = this.overlayService.open(AddEditTicketFormComponent, { data: { userType: 'Eigentümer' } });

        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.loadTickets('OPEN'));
    }

    public onTabSelected(tab: TabsToShow): void {
        if (this.tabsToShow.includes(tab)) {
            return;
        }

        if (tab === 'ALL') {
            this.loadTickets();
        } else if (tab === 'IN_PROGRESS') {
            this.loadTickets('IN_PROGRESS');
        } else if (tab === 'CLOSED') {
            this.loadTickets('CLOSED');
        }

        this.tabsToShow.push(tab);
    }

    public onSelectStatus(event: any): void {
        this.onTabSelected(event.item.id);

        if (event.item.id === 'OPEN') {
            this.ticketsFiltered = this.openTickets;
        } else if (event.item.id === 'CLOSED') {
            this.ticketsFiltered = this.closedTickets;
        } else if (event.item.id === 'IN_PROGRESS') {
            this.ticketsFiltered = this.inProgressTickets;
        } else {
            this.ticketsFiltered = this.allTickets;
        }
    }
}
