import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ListItem } from 'carbon-components-angular';
import { Observable, Subject, forkJoin, takeUntil, tap } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { UnitTypes, formControl, formControlHasError } from 'src/app/core/utils/common';
import {
    Building,
    BuildingsService,
    CreateMVUnitDto,
    MVUnit,
    Unit,
    UnitsService,
    UpdateMVUnitDto,
} from 'src/app/generated-sources/base';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';

@Component({
    selector: 'app-add-edit-mv-unit',
    templateUrl: './add-edit-mv-unit.component.html',
    styleUrls: ['./add-edit-mv-unit.component.scss'],
})
export class AddEditMvUnitComponent extends OverlayChildComponent implements OnInit {
    private unsubscribe$ = new Subject<void>();
    public unitsTypeList: ListItem[] = [];
    public buildingList: ListItem[] = [];
    public propertyId = '';
    public editingMode = false;
    public mvUnitToEdit?: MVUnit;
    public form: UntypedFormGroup = this.formBuilder.group({
        ownerships: this.formBuilder.array([
            this.formBuilder.group({
                type: ['', [Validators.required]],
                name: ['', [Validators.required]],
                location: ['', [Validators.required]],
                buildingId: ['', [Validators.required]],
                heatedSquaremeters: [''],
                squaremeters: [''],
                fraction: [''],
                roomCount: [''],
                isNotResidential: [undefined],
                condition: [''],
                inventory: [''],
                additionalInformation: [''],
            }),
        ]),
    });
    public isLoading = false;

    public constructor(
        private formBuilder: UntypedFormBuilder,
        private unitsService: UnitsService,
        private buildingsService: BuildingsService,
        private toastService: ToastService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.propertyId = this.config?.data.propertyId;
        this.editingMode = this.config?.data.editingMode;
        this.mvUnitToEdit = this.config?.data.mvUnit;

        this.buildingsService
            .findAll(this.propertyId)
            .pipe(
                tap((buildings: Building[]) => {
                    this.buildingList = buildings.map((item) => {
                        const selected = this.editingMode && this.mvUnitToEdit?.buildingId === item.id;
                        return {
                            content: item.name,
                            selected: selected,
                            value: item.id,
                        };
                    });

                    this.unitsTypeList = UnitTypes.map((item) => {
                        const selected = this.editingMode && this.mvUnitToEdit?.type === item.type;
                        return {
                            content: item.name,
                            selected: selected,
                            value: item.type,
                        };
                    });
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();

        if (this.editingMode) {
            this.getOwnershipItemForm(0).patchValue({
                type: this.mvUnitToEdit?.type,
                name: this.mvUnitToEdit?.name,
                location: this.mvUnitToEdit?.location,
                buildingId: this.mvUnitToEdit?.buildingId,
                heatedSquaremeters: this.mvUnitToEdit?.heatedSquaremeters,
                squaremeters: this.mvUnitToEdit?.squaremeters,
                fraction: this.mvUnitToEdit?.fraction,
                roomCount: this.mvUnitToEdit?.roomCount,
                isNotResidential: this.mvUnitToEdit?.isNotResidential,
                condition: this.mvUnitToEdit?.condition,
                inventory: this.mvUnitToEdit?.inventory,
                additionalInformation: this.mvUnitToEdit?.additionalInformation,
            });
        }
    }

    public validateNumbersFields(controlName: string): null | number {
        return this.getOwnershipItemForm(0).value[controlName] || this.getOwnershipItemForm(0).value[controlName] === 0
            ? Number(String(this.getOwnershipItemForm(0).value[controlName]).split(',').join('.'))
            : null;
    }

    public onSubmit(): void {
        this.isLoading = true;
        if (this.editingMode) {
            const updateMVUnitDto: UpdateMVUnitDto = {
                type: this.getOwnershipItemForm(0).value.type.value || this.getOwnershipItemForm(0).value.type,
                name: this.getOwnershipItemForm(0).value.name,
                location: this.getOwnershipItemForm(0).value.location,
                buildingId:
                    this.getOwnershipItemForm(0).value.buildingId.value ||
                    this.getOwnershipItemForm(0).value.buildingId,
                //squaremeters and heatedSquaremeters are string or numbers
                squaremeters: this.validateNumbersFields('squaremeters')!,
                heatedSquaremeters: this.validateNumbersFields('heatedSquaremeters')!,
                fraction: this.validateNumbersFields('fraction')!,
                //
                roomCount: this.getOwnershipItemForm(0).value.roomCount,
                isNotResidential:
                    this.getOwnershipItemForm(0).value.type.value ||
                    this.getOwnershipItemForm(0).value.type === Unit.TypeEnum.Apartment
                        ? !!this.getOwnershipItemForm(0).value.isNotResidential
                        : undefined,
                condition: this.getOwnershipItemForm(0).value.condition,
                inventory: this.getOwnershipItemForm(0).value.inventory,
                additionalInformation: this.getOwnershipItemForm(0).value.additionalInformation,
            };
            console.log({ updateMVUnitDto });
            this.unitsService
                .updateMVUnit(this.propertyId, this.mvUnitToEdit?.id || '', updateMVUnitDto)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe({
                    next: () => {
                        this.toastService.showSuccess('Einheit angelegt');
                        this.saveEmitter$.next();
                        this.isLoading = false;
                    },
                    error: (error) => {
                        this.isLoading = false;

                        if (
                            error.error['message'].includes('Unit') &&
                            error.error['message'].includes('already assigned to building')
                        ) {
                            this.toastService.showError(
                                'Die Bezeichnungen der Einheiten müssen innerhalb einer Immobilie eindeutig sein. Bitte wählen Sie eine andere Bezeichnung.'
                            );
                        } else {
                            this.toastService.showError(error.error['message']);
                        }
                    },
                });
        } else {
            const observables: Observable<MVUnit>[] = [];

            this.ownershipsControl.value.map((item: any) => {
                const createBuildingDto: CreateMVUnitDto = {
                    type: item.type.value,
                    name: item.name,
                    location: item.location,
                    buildingId: item.buildingId.value,

                    roomCount: item.roomCount,
                    isNotResidential: item.type.value === Unit.TypeEnum.Apartment ? !!item.isNotResidential : undefined,
                    condition: item.condition,
                    inventory: item.inventory,
                    additionalInformation: item.additionalInformation,

                    squaremeters:
                        item.squaremeters || item.squaremeters === 0
                            ? Number(String(item.squaremeters).split(',').join('.'))
                            : null!,

                    heatedSquaremeters:
                        item.heatedSquaremeters || item.heatedSquaremeters === 0
                            ? Number(String(item.heatedSquaremeters).split(',').join('.'))
                            : null!,

                    fraction:
                        item.fraction || item.fraction === 0
                            ? Number(String(item.fraction).split(',').join('.'))
                            : null!,
                };

                observables.push(this.unitsService.createMVUnit(this.propertyId, createBuildingDto));
            });

            forkJoin(observables)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe({
                    next: () => {
                        this.toastService.showSuccess('Einheit angelegt');
                        this.saveEmitter$.next();
                        this.isLoading = false;
                    },
                    error: (error) => {
                        this.isLoading = false;

                        if (
                            error.error['message'].includes('Unit') &&
                            error.error['message'].includes('already assigned to building')
                        ) {
                            this.toastService.showError(
                                'Die Bezeichnungen der Einheiten müssen innerhalb einer Immobilie eindeutig sein. Bitte wählen Sie eine andere Bezeichnung.'
                            );
                        } else {
                            this.toastService.showError(error.error['message']);
                        }
                    },
                });
        }
    }

    public isInvalid(index: number, controlName: string): boolean {
        return formControlHasError(formControl(this.getOwnershipItemForm(index), controlName), 'required');
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public addNewBuilding(): void {
        this.ownershipsControl.push(
            this.formBuilder.group({
                type: ['', [Validators.required]],
                name: ['', [Validators.required]],
                location: ['', [Validators.required]],
                buildingId: ['', [Validators.required]],
                heatedSquaremeters: [''],
                squaremeters: [''],
                fraction: [''],
                roomCount: [''],
                isNotResidential: [undefined],
                condition: [''],
                inventory: [''],
                additionalInformation: [''],
            })
        );
    }

    public removebuilding(index: number): void {
        this.ownershipsControl.removeAt(index);
    }

    public get ownershipsControl(): UntypedFormArray {
        return this.form.controls['ownerships'] as UntypedFormArray;
    }

    public getOwnershipItemForm(index: number): UntypedFormGroup {
        return this.ownershipsControl.at(index) as UntypedFormGroup;
    }
}
