<app-delete-overlay
    title="PAGES.OCCUPATION.DELETE.TITLE"
    headline="PAGES.OCCUPATION.DELETE.HEADLINE"
    subtitle="PAGES.OCCUPATION.DELETE.SUBTITLE"
    (closeOverlay)="abort()"
    (submitOverlay)="onSubmit()"
>
    <div class="d-flex align-items-center info-container p-1r s-body-14-22-regular">
        {{
            (occupation.occupationNumber ? occupation.occupationNumber + ', ' : '') +
                (occupation?.from || '' | dateFormat) +
                ' – ' +
                (occupation?.to && occupation?.to !== '' ? (occupation.to | dateFormat) : 'unbefristet')
        }}
    </div>
</app-delete-overlay>
