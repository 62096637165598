<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<app-overlay-container
    headlineTitle="PAGES.OCCUPATION.EDIT.LABEL_EDIT_OCCUPATION_DETAILS"
    [sizeIcon]="72"
    image="40_edit.svg"
    colorIcon="green"
    containerSize="small"
>
    <form [formGroup]="form" class="tw-space-y-14">
        <div>
            <div class="s-head-16-24-bold mb-1-5r">{{ 'ENTITIES.OCCUPATION.LABEL_DEPOSIT' | translate }}</div>
            <app-combo-box
                [size]="'md'"
                id="depositType"
                [appendInline]="true"
                label="Kautionstyp*"
                [items]="depositTypeList"
                formControlName="depositType"
                placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT' | translate }}"
                invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                (selected)="selectedDepositType($event)"
            >
                <ibm-dropdown-list></ibm-dropdown-list>
            </app-combo-box>
            <div *ngIf="form?.value?.depositType?.value !== 'NONE'" class="mt-2r d-flex justify-space-between">
                <app-number-input
                    label="Betrag*"
                    invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                    formControlName="depositAmount"
                    class="mr-2r w-100"
                    [invalid]="isInvalid('depositAmount')"
                ></app-number-input>

                <ibm-date-picker
                    label="Fällig am*"
                    id="depositDueDate"
                    [placeholder]="'tt.mm.jjjj'"
                    [size]="'md'"
                    [language]="'de'"
                    invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                    [dateFormat]="'d.m.Y'"
                    class="w-100"
                    formControlName="depositDueDate"
                    [invalid]="isInvalid('depositDueDate')"
                >
                </ibm-date-picker>
            </div>
        </div>

        <div>
            <div class="s-head-16-24-bold tw-mb-6">
                {{ 'PAGES.OCCUPATION.ADD_OCCUPATION_FORM.LABEL_SMALL_REPAIR' | translate }}
            </div>
            <app-number-input
                label="{{ 'PAGES.OCCUPATION.ADD_OCCUPATION_FORM.LABEL_INDIVIDUAL_REPAIR' | translate }}"
                invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                formControlName="oneTimeSmallRepairsAmount"
                [remainClearOnBlur]="true"
            ></app-number-input>

            <div class="tw-mt-2">
                <app-number-input
                    label="{{ 'PAGES.OCCUPATION.ADD_OCCUPATION_FORM.LABEL_ANNUAL_REPAIR' | translate }}"
                    invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                    formControlName="annualSmallRepairsAmount"
                    [remainClearOnBlur]="true"
                ></app-number-input>
            </div>
        </div>

        <div>
            <div class="s-head-16-24-bold tw-mb-2">{{ 'ENTITIES.OCCUPATION.LABEL_MISCELLANEOUS' | translate }}</div>
            <div class="tw-mb-6 tw-s-label-12-16-regular tw-text-scalaraGray-03">
                {{ 'PAGES.OCCUPATION.ADD_OCCUPATION_FORM.LABEL_EXTRA_MISCELLANEOUS_INFO' | translate }}
            </div>
            <ibm-label class="mb-0-5r">
                {{ 'ENTITIES.OCCUPATION.LABEL_MISCELLANEOUS' | translate }}
                <textarea
                    ibmTextArea
                    rows="3"
                    aria-label="textarea"
                    maxlength="100000"
                    formControlName="miscellaneousInfo"
                ></textarea>
            </ibm-label>
        </div>
    </form>
    <div class="mt-7-5r d-flex justify-space-between">
        <app-scalara-button (click)="abort()" [variant]="'outline'">
            {{ 'COMMON.BTN_CANCEL' | translate }}
        </app-scalara-button>

        <app-scalara-button (click)="onSubmit()" [variant]="'default'" type="submit" [disabled]="!form.valid">
            {{ 'COMMON.BTN_SAVE' | translate }}
        </app-scalara-button>
    </div>
</app-overlay-container>
