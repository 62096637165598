/**
 * SCALARA Accounting Module
 * The SCALARA Accounting API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EconomicPlanDetailsAccountDto { 
    /**
     * Verteilerschlüssel Bezeichnung
     */
    distributionKeyName: string;
    /**
     * Verteilungsgrundlage
     */
    distributionBase: EconomicPlanDetailsAccountDto.DistributionBaseEnum;
    /**
     * Gesamtverteiler
     */
    totalDistribution: number;
    /**
     * Planwert Vorjahr
     */
    forecastPreviousYear: number;
    /**
     * Relevant
     */
    isRelevant: boolean;
    /**
     * Konto Id
     */
    accountId: string;
    /**
     * Kontoname
     */
    accountName: string;
    /**
     * Kontobezeichnung
     */
    accountDescription: string;
    /**
     * Verteilerschlüssel Id
     */
    distributionKeyId: string;
    /**
     * Plansumme in €
     */
    forecastCurrentYear: number;
}
export namespace EconomicPlanDetailsAccountDto {
    export type DistributionBaseEnum = 'FRACTION' | 'OWNERSHIPS' | 'PERSONS' | 'SQUARE_METERS' | 'HEATED_SQUARE_METERS' | 'CONSUMPTION';
    export const DistributionBaseEnum = {
        Fraction: 'FRACTION' as DistributionBaseEnum,
        Ownerships: 'OWNERSHIPS' as DistributionBaseEnum,
        Persons: 'PERSONS' as DistributionBaseEnum,
        SquareMeters: 'SQUARE_METERS' as DistributionBaseEnum,
        HeatedSquareMeters: 'HEATED_SQUARE_METERS' as DistributionBaseEnum,
        Consumption: 'CONSUMPTION' as DistributionBaseEnum
    };
}


