import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Property } from 'src/app/generated-sources/base';

@Injectable({
    providedIn: 'root',
})
export class PropertyService {
    public propertySubject = new Subject<Property>();
    private property?: Property;

    // propertyType value contains the ledgerType of the accounting
    // where the property is being used
    public setProperty(property: Property): void {
        this.property = property;
        this.propertySubject.next(this.property);
    }

    public getPropertyObservable(): Observable<Property> {
        return this.propertySubject;
    }

    public getProperty(): void {
        if (this.property) {
            this.propertySubject.next(this.property);
        }
    }
}
