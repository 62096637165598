import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ListItem } from 'carbon-components-angular';
import { Subject, takeUntil, tap } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { controlInFormGroupIsInvalid } from 'src/app/core/utils/formValidationHelpers';
import {
    CreateBuildingTechnicalEquipmentDto,
    TechnicalEquipmentDefinitionDto,
    TechnicalEquipmentDto,
    TechnicalEquipmentFile,
    TechnicalEquipmentFileDto,
    TechnicalEquipmentFormMappingDto,
    TechnicalEquipmentService,
} from 'src/app/generated-sources/base';
import { ExistingFile } from 'src/app/shared/components/file-uploader/existing-files/existing-files.component';
import { FileUploaderComponent } from 'src/app/shared/components/file-uploader/file-uploader.component';
import { MultiSelectorList } from 'src/app/shared/components/multi-selector-list/multi-selector-list.component';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';
import { TableSearchService } from 'src/app/shared/table/services/table-search.service';

@Component({
    selector: 'app-add-edit-technical-equipment-overlay',
    templateUrl: './add-edit-technical-equipment-overlay.component.html',
    styleUrls: ['./add-edit-technical-equipment-overlay.component.scss'],
})
export class AddEditTechnicalEquipmentOverlayComponent extends OverlayChildComponent implements OnInit, OnDestroy {
    public form = this.formBuilder.group({
        year: ['', [Validators.min(1000), Validators.max(2099), Validators.pattern('^[0-9]*$')]],
        lastRenewed: [],
        preservationStatus: [],
        notes: [],
        specification: [],
        manufacturer: [],
        maintenanceInterval: [],
        serialNumber: [],
    });

    //  controlled via events from app-file-uploader
    @ViewChild('fileUpload')
    public fileUploader?: FileUploaderComponent;
    public categories: ListItem[] = [];

    public areFilesFullyUploaded = true;
    public fileUuids: string[] = [];
    public fileCategories = [];
    public existingFiles: ExistingFile[] = [];
    public existingFileToEdit: ExistingFile[] = [];

    public technicalEquipmentToEdit?: TechnicalEquipmentDto;
    public specificationList: MultiSelectorList[] = [];
    public showAllTechnicalEquimentItems = false;

    public propertyId = '';
    public buildingId = '';
    public isLoading = false;

    public selectedTechnicalEquiment?: TechnicalEquipmentDefinitionDto;

    public technicalEquipmentTypesList: TechnicalEquipmentDefinitionDto[] = [];
    public technicalEquipmentTypesFilteredList: TechnicalEquipmentDefinitionDto[] = [];

    // observables
    public unsubscribe$ = new Subject<void>();
    public showAddTechnicalEquimentDetailsForm$ = new Subject<boolean>();

    public equipmentsTypes$ = this.technicalEquipmentService.findBuildingEquipmentTypes().pipe(
        tap((equipmentTypes) => {
            equipmentTypes = this.sortEquipmentTypeByName(equipmentTypes);
            this.technicalEquipmentTypesList = equipmentTypes;
            this.technicalEquipmentTypesFilteredList = equipmentTypes;

            if (this.technicalEquipmentToEdit) {
                const equipmentType = this.technicalEquipmentTypesList.find(
                    (item) => item.id === this.technicalEquipmentToEdit?.equipmentTypeId
                );

                this.form.patchValue({
                    year: this.technicalEquipmentToEdit.year,
                    lastRenewed: this.technicalEquipmentToEdit.lastRenewed,
                    preservationStatus: this.technicalEquipmentToEdit.preservationStatus,
                    notes: this.technicalEquipmentToEdit.notes,
                    specification: this.technicalEquipmentToEdit.specificationArray,
                    manufacturer: this.technicalEquipmentToEdit.manufacturer,
                    maintenanceInterval: this.technicalEquipmentToEdit.maintenanceInterval,
                    serialNumber: this.technicalEquipmentToEdit.serialNumber,
                });

                this.technicalEquipmentToEdit.technicalEquipmentFiles?.map((file) => {
                    this.existingFiles.push({
                        fileName: file.filename ?? '',
                        key: file.fileStorageId,
                        category: file.fileCategory,
                    });
                });

                this.existingFileToEdit = this.existingFiles;

                if (equipmentType) {
                    this.selectTechnicalEquiment(equipmentType);
                    this.showTechnicalEquipmentForm();
                }
            }
        })
    );

    // search
    private tableSearch$ = this.tableSearchService.getCurrentInputSearch$().pipe(
        tap((search) => {
            if (!search?.searchInput) {
                this.technicalEquipmentTypesFilteredList = this.technicalEquipmentTypesList;
                return;
            }

            this.technicalEquipmentTypesFilteredList = this.technicalEquipmentTypesList.filter((equipment) => {
                return equipment.equipmentLabel?.toLowerCase().trim().includes(search?.searchInput);
            });
        })
    );

    public constructor(
        private formBuilder: UntypedFormBuilder,
        private translateService: TranslateService,
        private toastService: ToastService,
        private technicalEquipmentService: TechnicalEquipmentService,
        private tableSearchService: TableSearchService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.propertyId = this.config?.data.propertyId;
        this.buildingId = this.config?.data.buildingId;
        this.technicalEquipmentToEdit = this.config?.data.technicalEquipmentToEdit;

        this.equipmentsTypes$.subscribe();

        this.categories = Object.values(TechnicalEquipmentFileDto.FileCategoryEnum).map((item) => {
            return {
                content: item,
                selected: false,
                value: item,
            };
        });

        this.tableSearch$.subscribe();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public sortEquipmentTypeByName(
        technicalEquipmentTypes: TechnicalEquipmentDefinitionDto[]
    ): TechnicalEquipmentDefinitionDto[] {
        return technicalEquipmentTypes.sort((a, b) => {
            if (a.equipmentLabel < b.equipmentLabel) {
                return -1;
            }
            if (a.equipmentLabel > b.equipmentLabel) {
                return 1;
            }
            return 0;
        });
    }

    public selectTechnicalEquiment(technicalEquimentItem: TechnicalEquipmentDefinitionDto): void {
        this.selectedTechnicalEquiment = technicalEquimentItem;

        this.specificationList =
            technicalEquimentItem?.specificationChoices.map((item: string) => {
                return {
                    content: item,
                    selected: !!this.technicalEquipmentToEdit?.specificationArray?.find(
                        (equipment) => equipment === item
                    ),
                    id: item,
                };
            }) ?? [];
    }

    public changeTechnicalEquimentVisibility(): void {
        this.showAllTechnicalEquimentItems = !this.showAllTechnicalEquimentItems;
    }

    public showTechnicalEquipmentForm(): void {
        this.showAddTechnicalEquimentDetailsForm$.next(true);
    }

    public updateSelectedSpecification(newList: MultiSelectorList[]): void {
        this.form?.get('specification')?.patchValue(newList.filter((item) => item.selected).map((item) => item.id));
    }

    public updateLoadingFilesStatus($event: boolean): void {
        this.areFilesFullyUploaded = $event;
    }

    public updateFileIdsLodaded($event: string[]): void {
        this.fileUuids = $event;
        if (this.fileUuids.length === 0) {
            this.areFilesFullyUploaded = true;
        }
    }

    public updatedFileCategories($event: any): void {
        this.fileCategories = $event;
    }

    public backPage(): void {
        this.showAddTechnicalEquimentDetailsForm$.next(false);
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public submit(): void {
        if (!this.selectedTechnicalEquiment) {
            return;
        }
        this.isLoading = true;

        let files: TechnicalEquipmentFileDto[] = this.fileUuids.map((uuid, index) => ({
            fileStorageId: uuid,
            fileCategory: this.fileCategories[index] as TechnicalEquipmentFileDto.FileCategoryEnum,
        }));

        if (this.existingFileToEdit.length > 0) {
            const existingFileToUpdate = this.existingFileToEdit.map((file) => ({
                fileStorageId: file.key,
                fileCategory: file.category as TechnicalEquipmentFile.FileCategoryEnum,
            }));

            if (files.length > 0) {
                files.push(...existingFileToUpdate);
            } else {
                files = existingFileToUpdate;
            }
        }

        const createTechnicalEquipmentDto: CreateBuildingTechnicalEquipmentDto = {
            equipmentTypeId: this.selectedTechnicalEquiment.id,
            year: this.form.get('year')?.value ?? undefined,
            lastRenewed: this.form.get('lastRenewed')?.value ?? undefined,
            notes: this.form.get('notes')?.value ?? undefined,
            preservationStatus: this.form.get('preservationStatus')?.value ?? undefined,
            technicalEquipmentFiles: files.length > 0 ? files : undefined,
            specificationArray: this.form.get('specification')?.value ?? undefined,
            manufacturer: this.form.get('manufacturer')?.value ?? undefined,
            maintenanceInterval: this.form.get('maintenanceInterval')?.value ?? undefined,
            serialNumber: this.form.get('serialNumber')?.value ?? undefined,
        };

        (this.technicalEquipmentToEdit
            ? this.technicalEquipmentService.updateBuildingTechnicalEquipment(
                  this.propertyId,
                  this.buildingId,
                  this.technicalEquipmentToEdit.id,
                  createTechnicalEquipmentDto
              )
            : this.technicalEquipmentService.createBuildingTechnicalEquipment(
                  this.propertyId,
                  this.buildingId,
                  createTechnicalEquipmentDto
              )
        )
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: () => {
                    this.toastService.showSuccess(
                        this.translateService.instant(
                            this.technicalEquipmentToEdit
                                ? 'PAGES.ADD_TECHNICAL_EAQUIPMENT.EDIT_SUCCESS_TOAST'
                                : 'PAGES.ADD_TECHNICAL_EAQUIPMENT.SUCCESS_TOAST'
                        )
                    );
                    this.saveEmitter$.next();
                    this.isLoading = false;
                },
                error: (error) => {
                    if (error) {
                        this.toastService.showError(error.error['message']);
                    }
                    this.isLoading = false;
                },
            });
    }

    public getFormItem(key: TechnicalEquipmentFormMappingDto.KeyEnum): TechnicalEquipmentFormMappingDto | undefined {
        if (this.selectedTechnicalEquiment?.formFields) {
            return this.selectedTechnicalEquiment?.formFields.find(
                (item: TechnicalEquipmentFormMappingDto) => item.key === key
            );
        }
        return undefined;
    }

    public updateExistingFilesList(newExistingList: ExistingFile[]): void {
        this.existingFileToEdit = newExistingList;
    }

    public controlInFormGroupIsInvalid = controlInFormGroupIsInvalid;
}
