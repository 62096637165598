import { DatePipe } from '@angular/common';

//  input: "2023-03-20T12:25:40.157Z", output: "2023-03-20T00:00:00.000Z"
export function getDateWithoutHhMmSs(dateString: string): Date {
    const date = new Date(dateString);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
}

type ConfigurableFormatDateParams = {
    datePipeConstructorParams: ConstructorParameters<typeof DatePipe>;
    transformParams: Parameters<DatePipe['transform']>;
};

export function configurableFormatDate({
    datePipeConstructorParams,
    transformParams,
}: ConfigurableFormatDateParams): string {
    const datePipe = new DatePipe(...datePipeConstructorParams);
    return datePipe.transform(...transformParams) ?? 'ERROR';
}

export function formatDateDDMMYYYY(
    date: ConfigurableFormatDateParams['transformParams']['0'],
    locale?: string
): string {
    return configurableFormatDate({
        datePipeConstructorParams: [locale ?? 'de-De'],
        transformParams: [date, 'dd.MM.yyyy'],
    });
}

export function isDateWithinDateRange({
    date,
    startDate,
    endDate,
}: {
    date: string | Date;
    startDate: string | Date;
    endDate: string | Date;
}): boolean {
    if (typeof date === 'string') {
        date = getDateWithoutHhMmSs(date);
    }
    if (typeof startDate === 'string') {
        startDate = getDateWithoutHhMmSs(startDate);
    }
    if (typeof endDate === 'string') {
        endDate = getDateWithoutHhMmSs(endDate);
    }
    return date >= startDate && date <= endDate;
}
