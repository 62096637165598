<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"></ibm-loading>

<div class="main-container">
    <h1 class="s-head-28-36-bold mb-5r">{{ 'PAGES.CHANGE_PASSWORD.HEADING' | translate }}</h1>
    <form [formGroup]="form" (submit)="this.submit()">
        <!-- EXISTING PASSWORD -->
        <ibm-label
            [invalid]="existingPasswordInvalid"
            invalidText="{{ 'CONTROLS.FORMCONTROLS.EXISTING_PASSWORD.INVALID' | translate }}"
            class="mb-1r"
        >
            <span class="d-flex justify-space-between align-items-center"
                >{{ 'CONTROLS.FORMCONTROLS.EXISTING_PASSWORD.LABEL' | translate }}*
            </span>

            <input
                ibmText
                type="password"
                name="existingPassword"
                id="existingPassword"
                formControlName="existingPassword"
                [invalid]="existingPasswordInvalid"
                class="d-block"
            />
        </ibm-label>

        <!-- NEW PASSWORD -->
        <app-password-input
            [form]="form"
            inputName="password"
            label="{{ 'CONTROLS.FORMCONTROLS.PASSWORD.LABEL_FOR_NEW_PASSWORD' | translate }}*"
        ></app-password-input>

        <!-- PASSWORD WIEDERHOLEN -->
        <app-password-input
            [form]="form"
            inputName="confirmPassword"
            label="{{ 'CONTROLS.FORMCONTROLS.CONFIRM_PASSWORD.LABEL_FOR_NEW_PASSWORD' | translate }}*"
        ></app-password-input>

        <div class="buttons-wrapper">
            <app-scalara-button (click)="this.abort()" variant="outline" [fullWidth]="true">{{
                'CONTROLS.BUTTONS.CANCEL' | translate
            }}</app-scalara-button>
            <app-scalara-button
                type="submit"
                [disabled]="this.form.invalid"
                [fullWidth]="true"
                class="buttons-wrapper_button--with-padding"
                >{{ 'CONTROLS.BUTTONS.SAVE' | translate }}</app-scalara-button
            >
        </div>
    </form>
</div>
