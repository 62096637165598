import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbItem } from 'carbon-components-angular/breadcrumb';
import {
    BehaviorSubject,
    ObservedValueOf,
    Subject,
    catchError,
    combineLatest,
    filter,
    map,
    of,
    shareReplay,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs';
import { BreadCrumbService } from 'src/app/core/services/breadcrumb.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { getNameFromPerson } from 'src/app/core/utils/common';
import { BankAccount, PersonsService, SepaMandate } from 'src/app/generated-sources/base';

type SepaMandateExtended = { bankAccount: BankAccount } & SepaMandate;

@Component({
    selector: 'app-sepa-mandates-overview',
    templateUrl: './sepa-mandates-overview.component.html',
    styleUrls: ['./sepa-mandates-overview.component.scss'],
})
export class SepaMandatesOverviewComponent implements OnInit, OnDestroy {
    public constructor(
        public route: ActivatedRoute,
        public personsService: PersonsService,
        public toastService: ToastService,
        public translateService: TranslateService,
        public breadcrumbService: BreadCrumbService
    ) {}

    public unsubscribe$ = new Subject<void>();
    public isLoading$ = new BehaviorSubject<boolean>(false);
    public personId$ = this.route.params.pipe(map((params) => params['personId']));
    public person$ = this.personId$.pipe(
        tap(() => this.isLoading$.next(true)),
        switchMap((personId) => this.personsService.findOne(personId)),
        catchError(() => {
            this.toastService.showError('Es ist ein Fehler aufgetreten');
            this.isLoading$.next(false);
            return of(null);
        }),
        tap(() => this.isLoading$.next(false)),
        shareReplay({ refCount: true, bufferSize: 1 })
    );
    public bankAccounts$ = this.personId$.pipe(
        tap(() => this.isLoading$.next(true)),
        switchMap((personId) => {
            return this.personsService.findAllBankAccounts(personId);
        }),
        catchError(() => {
            this.toastService.showError('Es ist ein Fehler aufgetreten');
            this.isLoading$.next(false);
            return of(null);
        }),
        tap(() => this.isLoading$.next(false)),
        shareReplay({ refCount: true, bufferSize: 1 })
    );

    public sepaMandatesExtended$ = this.bankAccounts$.pipe(
        filter(Boolean),

        map((bankAccounts) => {
            const sepaMandatesExtended: SepaMandateExtended[] = [];
            bankAccounts?.map((bankAccount) => {
                const sepaMandates = bankAccount.sepaMandates?.filter(Boolean) ?? [];
                sepaMandates.map((sepaMandate) => {
                    //buggy be sends null value
                    if (!sepaMandate) {
                        return;
                    }
                    const i = { ...sepaMandate, bankAccount };
                    sepaMandatesExtended.push(i);
                });
            });

            return sepaMandatesExtended;
        })
    );

    public sepaMandateItemsVM$ = this.sepaMandatesExtended$.pipe(
        map((sepas) => {
            return sepas.map((sepa) => this.getSepaMandateItemVM(sepa)).flat();
        })
    );

    public breadcrumbs$ = this.person$.pipe(
        map((person) => {
            this.breadcrumbService.resetBreadCrumbs();
            const newBreadcrumbs: BreadcrumbItem[] = [
                {
                    content: this.translateService.instant('NAVIGATION.CONTACTS'),
                    route: [`/contacts`],
                },
                {
                    content: person ? getNameFromPerson(person) : 'Person',
                    route: [`/contacts/${person?.id}`],
                },
                {
                    content: 'Mandate',
                    route: [`/contacts/${person?.id}/sepaMandates`],
                    current: true,
                },
            ];
            this.breadcrumbService.updateBreadCrumbs(newBreadcrumbs);
            return this.breadcrumbService.getCurrentBreadCrumbs();
        })
    );

    public vm$ = combineLatest([
        this.personId$,
        this.person$,
        this.isLoading$,
        this.bankAccounts$,
        this.sepaMandatesExtended$,
        this.sepaMandateItemsVM$,
        this.breadcrumbs$,
    ]).pipe(
        map(([personId, person, isLoading, bankAccounts, sepaMandatesExtended, sepaMandateItemsVM, breadcrumbs]) => ({
            personId,
            person,
            isLoading,
            bankAccounts,
            sepaMandatesExtended,
            sepaMandateItemsVM,
            breadcrumbs,
        })),
        takeUntil(this.unsubscribe$)
    );
    public vm: ObservedValueOf<typeof this.vm$> | null = null;

    public getSepaMandateItemVM(sepaExtended: SepaMandateExtended): {
        rows: {
            firstLabel: string;
            secondLabel: string;
        }[];
    } {
        const t = this.translateService;
        const bankAccountDetails = [
            sepaExtended.bankAccount.accountHolder,
            sepaExtended.bankAccount.iban,
            sepaExtended.bankAccount?.bankName,
            sepaExtended.bankAccount?.bic,
        ].filter((i) => Boolean(i));
        const sepaMandateItemVM = [
            { firstLabel: t.instant('PAGES.SEPA_MANDATE.MANDATE_ID'), secondLabel: sepaExtended.mandateId },
            {
                firstLabel: t.instant('PAGES.SEPA_MANDATE.BANK_ACCOUNT_CONNECTION'),
                secondLabel: bankAccountDetails.join(', '),
            },
            {
                firstLabel: t.instant('ENTITIES.PROPERTY.LABEL_ENTITY'),
                secondLabel: sepaExtended.ledgerId ?? '-',
            },
        ];
        return { rows: sepaMandateItemVM };
    }

    public ngOnInit(): void {
        this.vm$.subscribe((vm) => {
            this.vm = vm;
            console.log({ vm });
        });
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public getNameFromPerson = getNameFromPerson;
}
