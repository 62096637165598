<div class="tw-my-8">
    <app-notification
        type="attention"
        title="Plausibilitätsprüfungen für die Buchhaltung (ADMIN-Mode!)"
        iconSize="s"
        subtitle="Hier machen nur admins was! Hawk Tua"
    ></app-notification>
</div>

<ibm-tabs>
    <ibm-tab heading="Buchungen & Bankkonten" [active]="activeTab === 'bookings'">
        <ibm-loading *ngIf="isLoading" [isActive]="isLoading"></ibm-loading>
        <div class="tw-w-[70%]">
            <!-- Alle Buchungen, deren Banktransaktionsdatum<>Buchungsdatum -->
            <div class="tw-flex tw-flex-col tw-mt-8">
                <div class="tw-flex tw-justify-between">
                    <div class="tw-s-body-14-22-bold">Banktransaktionsdatum <> Buchungsdatum</div>
                    <app-search searchInputId="bookingDateTransactionDate"></app-search>
                </div>
                <div>
                    <app-table
                        emptyText="ACCOUNTING.REASONABILITY_CHECK.EMPTY_TABLE"
                        *ngIf="bookingsTableData"
                        [header]="bookingsTableData.bookingDateTransactionDate.header"
                        [data]="bookingsTableData.bookingDateTransactionDate.data"
                        tableSearchId="bookingDateTransactionDate"
                    ></app-table>
                </div>
            </div>
            <!-- Alle Buchungen ohne Transaktion (bei Ledgern mit verbundenen Bankkonten) -->
            <div class="tw-flex tw-flex-col tw-mt-8">
                <div class="tw-flex tw-justify-between">
                    <div class="tw-s-body-14-22-bold">Buchungen ohne Transaktionen</div>
                    <app-search searchInputId="bookingsWithoutTranscations"></app-search>
                </div>
                <div>
                    <app-table
                        emptyText="ACCOUNTING.REASONABILITY_CHECK.EMPTY_TABLE"
                        *ngIf="bookingsTableData"
                        [header]="bookingsTableData.bookingsWithoutTranscations.header"
                        [data]="bookingsTableData.bookingsWithoutTranscations.data"
                        tableSearchId="bookingsWithoutTranscations"
                    ></app-table>
                </div>
            </div>

            <!-- Forderungen/Verbindlichkeiten wo da das Buchungsdatum (der eröffnenden Buchung) größer ist als das Fälligkeitsdatum-->
            <div class="tw-flex tw-flex-col tw-mt-8">
                <div class="tw-flex tw-justify-between">
                    <div class="tw-s-body-14-22-bold">Buchungsdatum > als Fälligkeitsdatum (bei Verb. / Forderung)</div>
                    <app-search searchInputId="receivableBookingDateCheck"></app-search>
                </div>
                <div>
                    <app-table
                        emptyText="ACCOUNTING.REASONABILITY_CHECK.EMPTY_TABLE"
                        *ngIf="bookingsTableData"
                        [header]="bookingsTableData.receivableBookingDateCheck.header"
                        [data]="bookingsTableData.receivableBookingDateCheck.data"
                        tableSearchId="receivableBookingDateCheck"
                    ></app-table>
                </div>
            </div>
            <!-- Banktransaktionen wo es keine andere ignorierte Buchgung mit gleichem wert gibt -->
            <div class="tw-flex tw-flex-col tw-mt-8">
                <div class="tw-flex tw-justify-between">
                    <div class="tw-s-body-14-22-bold">Fälschlicherweise ignorierte Transaktionen</div>
                    <app-search searchInputId="wronglyIgronedTransactions"></app-search>
                </div>
                <div>
                    <app-table
                        emptyText="ACCOUNTING.REASONABILITY_CHECK.EMPTY_TABLE"
                        *ngIf="transactionsTableData"
                        [header]="transactionsTableData.wronglyIgronedTransactions.header"
                        [data]="transactionsTableData.wronglyIgronedTransactions.data"
                        tableSearchId="wronglyIgronedTransactions"
                    ></app-table>
                </div>
            </div>
        </div>
    </ibm-tab>
    <ibm-tab heading="JA-Zeitraum" [active]="activeTab === 'wssRange'" *ngIf="ledger?.type === 'WEG'">
        <ibm-loading *ngIf="isLoading" [isActive]="isLoading"></ibm-loading>
        <div class="tw-w-[70%]">
            <div class="tw-mt-8 tw-flex tw-justify-start">
                <ibm-dropdown
                    (selected)="onSelectedWSS($event)"
                    placeholder="Jahresabschluss auswählen!"
                    label="Jahresabschluss"
                >
                    <ibm-dropdown-list [items]="(wSSItemList$ | async) ?? []"></ibm-dropdown-list>
                </ibm-dropdown>
            </div>
            <!-- Alle Buchungen im JA-Zeitraum, zu denen es keine Banktransaktion im gleichen Zeitraum gib-->
            <div class="tw-flex tw-flex-col tw-mt-8">
                <div class="tw-flex tw-justify-between">
                    <div class="tw-s-body-14-22-bold">Keine Banktransaktionen im Jahreszeitraum</div>
                    <app-search searchInputId="bookingsWithoutSameYearTransactions"></app-search>
                </div>
                <div>
                    <app-table
                        emptyText="ACCOUNTING.REASONABILITY_CHECK.EMPTY_TABLE"
                        *ngIf="bookingsTableData"
                        [header]="bookingsTableData.bookingsWithoutSameYearTransactions.header"
                        [data]="bookingsTableData.bookingsWithoutSameYearTransactions.data"
                        tableSearchId="bookingsWithoutSameYearTransactions"
                    ></app-table>
                </div>
            </div>
            <!-- Alle Banktransaktionen im JA-Zeitraum zu denen es keine Buchungen im Zeitraum gibt -->
            <div class="tw-flex tw-flex-col tw-mt-8">
                <div class="tw-flex tw-justify-between">
                    <div class="tw-s-body-14-22-bold">Transaktionen ohne Buchungen im Jahreszeitraum</div>
                    <app-search searchInputId="transactionsWithoutBookings"></app-search>
                </div>
                <div>
                    <app-table
                        emptyText="ACCOUNTING.REASONABILITY_CHECK.EMPTY_TABLE"
                        *ngIf="transactionsTableData"
                        [header]="transactionsTableData.transactionsWithoutBookings.header"
                        [data]="transactionsTableData.transactionsWithoutBookings.data"
                        tableSearchId="transactionsWithoutBookings"
                    ></app-table>
                </div>
            </div>
        </div>
    </ibm-tab>

    <ibm-tab heading="Actions" [active]="activeTab === 'actions'">
        <ibm-loading *ngIf="isLoading" [isActive]="isLoading"></ibm-loading>
        <!-- Setzen eines Bankkonto-Standes zum Datum X -->

        <app-ledger-reasonability-checks-set-start-amount
            [bankAccountList]="bankAccountListForLedger ?? []"
            [onSubmitCallback]="onSubmitSetBankaccountStartAmount"
        >
        </app-ledger-reasonability-checks-set-start-amount>
        <!-- Setzen eines Zusätzlichen Bankkontos -->
        <app-ledger-reasonability-checks-add-additional-bankaccount
            [bankAccountList]="bankAccountList ?? []"
            [onSubmitCallback]="onSubmitAddBankaccount"
        >
        </app-ledger-reasonability-checks-add-additional-bankaccount>
        <!-- Löschen von Mietforderungen einer Nutzung -->
        <app-ledger-reasonability-checks-delete-rent-receivables
            *ngIf="ledger?.type === 'MV'"
            [occupationsList]="occupationList ?? []"
            [onSubmitCallback]="onSubmitDeleteRentReceivables"
        >
        </app-ledger-reasonability-checks-delete-rent-receivables>
        <app-ledger-reasonability-checks-regenerate-rent-receivables
            *ngIf="ledger?.type === 'MV'"
            [occupationsList]="occupationList ?? []"
            [onSubmitCallback]="onSubmitRegenerateRentReceivables"
        >
        </app-ledger-reasonability-checks-regenerate-rent-receivables>
    </ibm-tab>
    <ibm-tab heading="Bankkonten" [active]="activeTab === 'bankAccounts'">
        <!-- Anfangesbestandsbuchung aller Rücklagen -->
        <div class="tw-flex tw-flex-col tw-mt-8" *ngIf="ledger?.type === 'WEG'">
            <div class="tw-flex tw-justify-between">
                <div class="tw-s-body-14-22-bold">Anfangesbestandsbuchung aller Rücklagen</div>
                <app-search searchInputId="initalBookingsOfReserves"></app-search>
            </div>
            <div>
                <app-table
                    emptyText="ACCOUNTING.REASONABILITY_CHECK.EMPTY_TABLE"
                    *ngIf="bookingsTableData"
                    [header]="bookingsTableData.initalBookingsOfReserves.header"
                    [data]="bookingsTableData.initalBookingsOfReserves.data"
                    tableSearchId="initalBookingsOfReserves"
                ></app-table>
            </div>
        </div>
        <!-- Jährliche Übersicht der Bankkonten-->
        <div class="tw-flex tw-flex-col tw-mt-8">
            <div class="tw-flex tw-justify-between">
                <div class="tw-s-body-14-22-bold">Bankkontostände zu Datumswerten</div>
                <app-search searchInputId="bankAccountYearlyAmounts"></app-search>
            </div>
            <div>
                <app-table
                    emptyText="ACCOUNTING.REASONABILITY_CHECK.EMPTY_BANK_TABLE"
                    *ngIf="bankAccountYearlyAmounts"
                    [header]="bankAccountYearlyAmounts.header"
                    [data]="bankAccountYearlyAmounts.data"
                    tableSearchId="bankAccountYearlyAmounts"
                ></app-table>
            </div>
        </div>
        <!-- Fakebuchungen für Kontostände-->
        <div class="tw-flex tw-flex-col tw-mt-8">
            <div class="tw-flex tw-justify-between">
                <div class="tw-s-body-14-22-bold">Bankkonten Andjustments</div>
                <app-search searchInputId="adjustmentBookings"></app-search>
            </div>
            <div>
                <app-table
                    emptyText="ACCOUNTING.REASONABILITY_CHECK.EMPTY_TABLE"
                    *ngIf="transactionsTableData"
                    [header]="transactionsTableData.adjustmentBookings.header"
                    [data]="transactionsTableData.adjustmentBookings.data"
                    tableSearchId="adjustmentBookings"
                ></app-table>
            </div>
        </div>
    </ibm-tab>
</ibm-tabs>

<!-- <ibm-dropdown (selected)="onSelectEconomicStatement($event)" [displayValue]="dropdownRenderer" placeholder="Select">
    <ibm-dropdown-list [items]="filterItems"></ibm-dropdown-list>
</ibm-dropdown> -->
