import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { NumberComponent } from 'carbon-components-angular';

@Component({
    selector: 'app-number-input',
    templateUrl: './number-input.component.html',
    styleUrls: ['./number-input.component.scss'],
})
export class NumberInputComponent implements ControlValueAccessor {
    @Input() public label = '';

    @Input() public orientation: 'right' | 'left' = 'right';

    @Input() public disabled = false;

    @Input() public disabledTooltipMessage = '';

    @Input() public invalid = false;

    @Input() public invalidText = '';

    @Input() public allowNegatives = true;

    /**
     * Sets to true or false if the input remains clear after removing its current value
     */
    @Input() public remainClearOnBlur = false;

    @Input() public id = `number-${NumberComponent.numberCount}`;

    /**
     * Variable used for creating unique ids for number input components.
     */
    public static numberCount = 0;

    public value: string | null = '0,00';

    public constructor(public ngControl: NgControl) {
        ngControl.valueAccessor = this;
        NumberComponent.numberCount++;
    }

    public writeValue(value: string | number): void {
        //  This method is called by the forms API to write to the view when programmatic
        //  changes from model to view are requested.
        //  allows to pass a eurocent number (f.e. new FormControl (1234, .....)))
        if (typeof value === 'number') {
            this.value = new Intl.NumberFormat('de-DE', {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(value / 100);
            return;
        }
        this.value = value;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public onInputBlur(event: Event): void {
        const target = event.target as HTMLInputElement;
        const split = target.value.split(',');

        let splitCombined;
        if (split.length === 1 && split[0] === '' && this.remainClearOnBlur) {
            this.onChange(null);
        } else {
            if (split.length === 1 || split[1] === '') {
                splitCombined = split[0] + ',00';
            } else {
                const decimalSplit = split[1].slice(0, 2);
                if (decimalSplit.length == 1) {
                    splitCombined = split[0] + decimalSplit + '0';
                } else {
                    splitCombined = split[0] + split[1].slice(0, 2);
                }
            }

            const valueAsCentsString = splitCombined.replace(/[,.]+/g, ''); // eslint-disable-line
            const valueAsCentsNumber = Number(valueAsCentsString);
            this.value = new Intl.NumberFormat('de-DE', {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(valueAsCentsNumber / 100);
            this.onChange(Number(valueAsCentsNumber));

            this.onChange(Number(valueAsCentsNumber));
        }
        this.onTouched();
    }

    public onChange(value: number | null): void {} // eslint-disable-line
    public onTouched(): void {} // eslint-disable-line

    public preventLetterInput(event: Event): void {
        const keyEvent = event as KeyboardEvent;
        const regex = this.allowNegatives
            ? new RegExp('[0-9,]|Tab|ArrowLeft|ArrowRight|Backspace|Delete+|-')
            : new RegExp('[0-9,]|Tab|ArrowLeft|ArrowRight|Backspace|Delete+');

        if (
            (keyEvent.type == 'keydown' && keyEvent.key == undefined) ||
            (!keyEvent.key.match(regex) && !keyEvent.ctrlKey && !keyEvent.metaKey)
        ) {
            event.preventDefault();
        }
    }
}
