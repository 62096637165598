<div class="overlay-background" [ngClass]="{ 'overlay-background--half': isHalfPage }">
    <div
        appClickOutside
        (clickOutside)="clickOutside()"
        class="overlay"
        [ngClass]="{ 'overlay--demo': environment.demoEnvironment, 'overlay--half': isHalfPage }"
    >
        <div class="d-flex justify-content-end p-06">
            <div class="tw-fill-scalaraGray-01 tw-w-4 tw-h-4 tw-cursor-pointer" (click)="closeOverlay($event)">
                <svg-icon src="/assets/icons/24_close.svg"></svg-icon>
            </div>
        </div>
        <div class="p-01"></div>

        <div (click)="clickInside($event)"><ng-template #child></ng-template></div>
    </div>
</div>
