/**
 * SCALARA Base Module
 * The SCALARA API for User, Person and Property resources
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PhoneNumber { 
    id?: string;
    phoneNumber: string;
    type: PhoneNumber.TypeEnum;
}
export namespace PhoneNumber {
    export type TypeEnum = 'PRIVATE_MOBILE' | 'PRIVATE_LANDLINE' | 'COMPANY_MOBILE' | 'COMPANY_LANDLINE' | 'FAX' | 'OTHER';
    export const TypeEnum = {
        PrivateMobile: 'PRIVATE_MOBILE' as TypeEnum,
        PrivateLandline: 'PRIVATE_LANDLINE' as TypeEnum,
        CompanyMobile: 'COMPANY_MOBILE' as TypeEnum,
        CompanyLandline: 'COMPANY_LANDLINE' as TypeEnum,
        Fax: 'FAX' as TypeEnum,
        Other: 'OTHER' as TypeEnum
    };
}


