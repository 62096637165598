import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { WegSettlementStatementsService } from 'src/app/generated-sources/accounting';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';

@Component({
    selector: 'app-delete-annual-statement',
    templateUrl: './delete-annual-statement.component.html',
    styleUrls: ['./delete-annual-statement.component.scss'],
})
export class DeleteAnnualStatementComponent extends OverlayChildComponent implements OnInit {
    public wssId = '';
    public annualStatementDates = '';
    public ledgerId = '';

    private unsubscribe$ = new Subject<void>();

    public constructor(
        private toastService: ToastService,
        private router: Router,
        private annualStatementsService: WegSettlementStatementsService,
        private translateService: TranslateService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.wssId = this.config?.data?.wssId;
        this.annualStatementDates = this.config?.data?.annualStatementDates;
        this.ledgerId = this.config?.data?.ledgerId;
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public onSubmit(): void {
        this.annualStatementsService
            ._delete(this.ledgerId, this.wssId)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: () => {
                    this.router.navigate([`accounting/ledger/${this.ledgerId}/annual-statements`]);
                    this.toastService.showSuccess(
                        this.translateService.instant('PAGES.ANNUAL_STATEMENT.DELETE_OVERLAY.TOAST_SUCCESS_MESSAGE')
                    );
                    this.saveEmitter$.next();
                },
                error: (error: any) => {
                    if (error) {
                        this.toastService.showError(
                            this.translateService.instant('PAGES.ANNUAL_STATEMENT.DELETE_OVERLAY.TOAST_ERROR')
                        );
                    }
                },
            });
    }
}
