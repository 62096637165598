import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import {
    ButtonModule,
    CheckboxModule,
    DatePickerModule,
    DialogModule,
    DropdownModule,
    GridModule,
    I18nModule,
    IconModule,
    InlineLoadingModule,
    InputModule,
    LoadingModule,
    PaginationModule,
    RadioModule,
    SelectModule,
    TableModule,
} from 'carbon-components-angular';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgxViewerModule } from 'ngx-viewer';
import { AdditionalInformationInputComponent } from './components/additional-information-input/additional-information-input.component';
import { ApportionableLabelComponent } from './components/apportionable-label/apportionable-label.component';
import { BaseDocumentsListComponent } from './components/base-documents-list/base-documents-list.component';
import { CardSelectionComponent } from './components/card-selection/card-selection.component';
import { ComboBoxComponent } from './components/combo-box/combo-box.component';
import { CustomDropdownComponent } from './components/custom-dropdown/custom-dropdown.component';
import { DeleteOverlayComponent } from './components/delete-overlay/delete-overlay.component';
import { DetailCardComponent } from './components/detail-card/detail-card.component';
import { ClickOutsideDirective } from './components/directives/click-outside.directive';
import { ClickStopPropagationDirective } from './components/directives/click-stop.directive';
import { DynamicComponentWrapperComponent } from './components/dynamic-component-wrapper/dynamic-component-wrapper.component';
import { ExpandableTileSelectorComponent } from './components/expandable-tile-selector/expandable-tile-selector.component';
import { FileInputComponent } from './components/file-input/file-input.component';
import { ExistingFilesComponent } from './components/file-uploader/existing-files/existing-files.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { FileViewerComponent } from './components/file-viewer/file-viewer.component';
import { FileComponent } from './components/file/file.component';
import { FilestorageDocumentsListComponent } from './components/filestorage-documents-list/filestorage-documents-list.component';
import { FilterComponent } from './components/filter/filter.component';
import { HeadlineWithIconComponent } from './components/headline-with-icon/headline-with-icon.component';
import { IconBubbleComponent } from './components/icon-bubble/icon-bubble.component';
import { IconInfoBoxComponent } from './components/icon-info-box/icon-info-box.component';
import { LogoHeaderComponent } from './components/logo-header/logo-header.component';
import { ModalComponent } from './components/modal/modal.component';
import { MultiSelectorListComponent } from './components/multi-selector-list/multi-selector-list.component';
import { NotificationComponent } from './components/notification/notification.component';
import { NumberInputComponent } from './components/number-input/number-input.component';
import { OverlayContainerComponent } from './components/overlay-container/overlay-container.component';
import { OverlayDetailsComponent } from './components/overlay-details/overlay-details.component';
import { PasswordInputComponent } from './components/password-input/password-input.component';
import { PasswordTooltipComponent } from './components/password-tooltip/password-tooltip.component';
import { PersonAmountAndTimeFrameOverlayComponent } from './components/person-amount-and-time-frame-overlay/person-amount-and-time-frame-overlay.component';
import { PersonAmountAndTimeFrameTableComponent } from './components/person-amount-and-time-frame-table/person-amount-and-time-frame-table.component';
import { RadioTileSelectorComponent } from './components/radio-tile-selector/radio-tile-selector.component';
import { ReceiptsTableComponent } from './components/receipts-table/receipts-table.component';
import { RemoveEntityOverlayComponent } from './components/remove-entity-overlay/remove-entity-overlay.component';
import { ScalaraAccordionComponent } from './components/scalara-accordion/scalara-accordion.component';
import { ScalaraButtonComponent } from './components/scalara-button/scalara-button.component';
import { ScalaraInputAddressAutocompleteComponent } from './components/scalara-input-address-autocomplete/scalara-input-address-autocomplete.component';
import { SimpleTableRowComponent } from './components/simple-table/simple-table-row/simple-table-row.component';
import { SmallPersonInfoboxComponent } from './components/small-person-infobox/small-person-infobox.component';
import { StatusWithIconComponent } from './components/status-with-icon/status-with-icon.component';
import { TableMultiSelectorComponent } from './components/table-multi-selector/table-multi-selector.component';
import { TagInputComponent } from './components/tag-input/tag-input.component';
import { TagComponent } from './components/tag/tag.component';
import { TextWithTooltipComponent } from './components/text-with-tooltip/text-with-tooltip.component';
import { ToggleSwitchComponent } from './components/toggle-switch/toggle-switch.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { OverlayComponent } from './overlay/components/overlay/overlay.component';
import { PersonInfosFormComponent } from './person-infos-form/person-infos-form.component';
import { PersonTypeAndNameFormComponent } from './person-type-and-name-form/person-type-and-name-form.component';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { DateTimeFormatPipe } from './pipes/date-time-format.pipe';
import { EurocentPipe } from './pipes/eurocent.pipe';
import { ImageBase64Pipe } from './pipes/image-base64.pipe';
import { PropertySwitchComponent } from './property-switch/property-switch.component';
import { SidenavComponent } from './sidenav/components/sidenav.component';
import { PaginationComponent } from './table/components/pagination/pagination.component';
import { SearchComponent } from './table/components/search/search.component';
import { TableComponent } from './table/components/table/table.component';

@NgModule({
    providers: [DatePipe, DateTimeFormatPipe],
    declarations: [
        LogoHeaderComponent,
        NotificationComponent,
        ComboBoxComponent,
        TableComponent,
        SidenavComponent,
        OverlayComponent,
        EurocentPipe,
        ImageBase64Pipe,
        NumberInputComponent,
        PaginationComponent,
        ExpandableTileSelectorComponent,
        TableMultiSelectorComponent,
        SmallPersonInfoboxComponent,
        HeadlineWithIconComponent,
        NumberInputComponent,
        IconBubbleComponent,
        ApportionableLabelComponent,
        FileComponent,
        FileUploaderComponent,
        ModalComponent,
        FileComponent,
        ModalComponent,
        ReceiptsTableComponent,
        IconBubbleComponent,
        PersonInfosFormComponent,
        FileViewerComponent,
        ScalaraButtonComponent,
        ClickStopPropagationDirective,
        PersonTypeAndNameFormComponent,
        TooltipComponent,
        PasswordTooltipComponent,
        TagComponent,
        IconInfoBoxComponent,
        DateFormatPipe,
        ScalaraAccordionComponent,
        SearchComponent,
        DeleteOverlayComponent,
        OverlayContainerComponent,
        StatusWithIconComponent,
        ScalaraAccordionComponent,
        DateTimeFormatPipe,
        AutoFocusDirective,
        ScalaraInputAddressAutocompleteComponent,
        PasswordInputComponent,
        RadioTileSelectorComponent,
        BaseDocumentsListComponent,
        SimpleTableRowComponent,
        DynamicComponentWrapperComponent,
        RemoveEntityOverlayComponent,
        PropertySwitchComponent,
        ClickOutsideDirective,
        TextWithTooltipComponent,
        AdditionalInformationInputComponent,
        FilterComponent,
        CardSelectionComponent,
        TagInputComponent,
        CustomDropdownComponent,
        DetailCardComponent,
        PersonAmountAndTimeFrameOverlayComponent,
        PersonAmountAndTimeFrameTableComponent,
        FileInputComponent,
        MultiSelectorListComponent,
        OverlayDetailsComponent,
        ExistingFilesComponent,
        FilestorageDocumentsListComponent,
        ToggleSwitchComponent,
    ],
    imports: [
        CommonModule,
        GridModule,
        IconModule,
        TranslateModule,
        RouterModule,
        TableModule,
        BrowserAnimationsModule,
        FormsModule,
        PaginationModule,
        I18nModule,
        AngularSvgIconModule,
        DatePickerModule,
        DialogModule,
        ButtonModule,
        ReactiveFormsModule,
        NgxExtendedPdfViewerModule,
        NgxViewerModule,
        RadioModule,
        InputModule,
        SelectModule,
        DropdownModule,
        LoadingModule,
        InlineLoadingModule,
        CheckboxModule,
    ],
    exports: [
        LogoHeaderComponent,
        NotificationComponent,
        ComboBoxComponent,
        TableComponent,
        SidenavComponent,
        OverlayComponent,
        EurocentPipe,
        ImageBase64Pipe,
        NumberInputComponent,
        ExpandableTileSelectorComponent,
        TableMultiSelectorComponent,
        SmallPersonInfoboxComponent,
        HeadlineWithIconComponent,
        ApportionableLabelComponent,
        FileComponent,
        FileUploaderComponent,
        ModalComponent,
        ReceiptsTableComponent,
        IconBubbleComponent,
        PersonInfosFormComponent,
        FileViewerComponent,
        ScalaraButtonComponent,
        ClickStopPropagationDirective,
        PersonTypeAndNameFormComponent,
        TooltipComponent,
        PasswordTooltipComponent,
        TagComponent,
        IconInfoBoxComponent,
        DateFormatPipe,
        ScalaraAccordionComponent,
        SearchComponent,
        DeleteOverlayComponent,
        OverlayContainerComponent,
        StatusWithIconComponent,
        ScalaraAccordionComponent,
        DateTimeFormatPipe,
        AutoFocusDirective,
        ScalaraInputAddressAutocompleteComponent,
        PasswordInputComponent,
        RadioTileSelectorComponent,
        BaseDocumentsListComponent,
        SimpleTableRowComponent,
        DynamicComponentWrapperComponent,
        PropertySwitchComponent,
        ClickOutsideDirective,
        TextWithTooltipComponent,
        AdditionalInformationInputComponent,
        FilterComponent,
        CardSelectionComponent,
        TagInputComponent,
        CustomDropdownComponent,
        DetailCardComponent,
        PersonAmountAndTimeFrameOverlayComponent,
        PersonAmountAndTimeFrameTableComponent,
        FileInputComponent,
        MultiSelectorListComponent,
        OverlayDetailsComponent,
        ExistingFilesComponent,
        FilestorageDocumentsListComponent,
        ToggleSwitchComponent,
    ],
})
export class SharedModule {}
