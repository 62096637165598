import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { OperationsExpenseStatementsService } from 'src/app/generated-sources/accounting';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';

@Component({
    selector: 'app-delete-operations-expense-statements',
    templateUrl: './delete-operations-expense-statements.component.html',
    styleUrls: ['./delete-operations-expense-statements.component.scss'],
})
export class DeleteOperationsExpenseStatementsComponent extends OverlayChildComponent implements OnInit {
    private unsubscribe$ = new Subject<void>();
    public oesId = '';
    public period?: { startDate: string; endDate: string };
    public ledgerId = '';

    public constructor(
        private operationsExpenseStatementsService: OperationsExpenseStatementsService,
        private router: Router,
        private toastService: ToastService,
        private translateService: TranslateService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.oesId = this.config?.data.id;
        this.period = {
            startDate: this.config?.data.startDate,
            endDate: this.config?.data.endDate,
        };
        this.ledgerId = this.config?.data.ledgerId;
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public onSubmit(): void {
        this.operationsExpenseStatementsService
            ._delete(this.ledgerId, this.oesId)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: () => {
                    this.router.navigate([`/accounting/ledger/${this.ledgerId}/operations-expense-statements`]);
                    this.toastService.showSuccess(
                        this.translateService.instant(
                            'PAGES.OPERATIONS_EXPENSE_STATEMENTS.DELETE_OPERATIONS_EXPENSE_STATEMENTS.SUCCESS_TOAST'
                        )
                    );
                    this.saveEmitter$.next();
                },
                error: (error: any) => {
                    if (error) {
                        this.toastService.showError(
                            this.translateService.instant('PAGES.OWNERSHIP.DELETE_OVERLAY.TOAST_ERROR')
                        );
                    }
                },
            });
    }
}
