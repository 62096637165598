<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<div ibmRow class="pb-5r">
    <div ibmCol [columnNumbers]="{ md: 2, lg: 2 }"></div>
    <div ibmCol [columnNumbers]="{ md: 12, lg: 12 }">
        <div ibmRow>
            <div ibmCol ibmCol [columnNumbers]="{ md: 4, lg: 4 }">
                <div class="tile tile--box-selection pt-3r pb-2r mt-1r">
                    <div class="pt-1r pb-1r headline s-head-28-36-bold">
                        <div>{{ accountingPlatformStatistics?.cashUnderManagement ?? 0 | eurocent }} €</div>
                    </div>
                    <div class="headline">
                        {{ 'PAGES.STATISTICS.LABEL_TILE_CASH_UNDER_MANAGEMENT' | translate }}
                    </div>
                </div>
            </div>
            <div ibmCol ibmCol [columnNumbers]="{ md: 4, lg: 4 }">
                <div class="tile tile--box-selection pt-3r pb-2r mt-1r">
                    <div class="pt-1r pb-1r headline s-head-28-36-bold">
                        <div>{{ accountingPlatformStatistics?.moneyPlannedWithScalara ?? 0 | eurocent }} €</div>
                    </div>
                    <div class="headline">
                        {{ 'PAGES.STATISTICS.LABEL_TILE_PLANNED_TRANSACTIONS_TOTAL' | translate }}
                    </div>
                </div>
            </div>
            <div ibmCol ibmCol [columnNumbers]="{ md: 4, lg: 4 }">
                <div class="tile tile--box-selection pt-3r pb-2r mt-1r">
                    <div class="pt-1r pb-1r headline s-head-28-36-bold">
                        <div>{{ accountingPlatformStatistics?.ownershipReceivablesPlanned ?? 0 | eurocent }} €</div>
                    </div>
                    <div class="headline">
                        {{ 'PAGES.STATISTICS.LABEL_TILE_PLANNED_RECIEVABLES' | translate }}
                    </div>
                </div>
            </div>
            <div ibmCol ibmCol [columnNumbers]="{ md: 4, lg: 4 }">
                <div class="tile tile--box-selection pt-3r pb-2r mt-1r">
                    <div class="pt-1r pb-1r headline s-head-28-36-bold">
                        {{ accountingPlatformStatistics?.plannedReserves ?? 0 | eurocent }} €
                    </div>

                    <div class="headline">
                        {{ 'PAGES.STATISTICS.LABEL_TILE_PLANNED_RESERVES' | translate }}
                    </div>
                </div>
            </div>
            <div ibmCol ibmCol [columnNumbers]="{ md: 4, lg: 4 }">
                <div class="tile tile--box-selection pt-3r pb-2r mt-1r">
                    <div class="pt-1r pb-1r headline s-head-28-36-bold">
                        {{ accountingPlatformStatistics?.bookedBankTransactions?.count ?? 0 }}
                    </div>
                    ({{ accountingPlatformStatistics?.bookedBankTransactions?.amount ?? 0 | eurocent }} €)

                    <div class="headline">
                        {{ 'PAGES.STATISTICS.LABEL_TILE_BOOKED_TRANSACTIONS' | translate }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div ibmCol [columnNumbers]="{ md: 2, lg: 2 }"></div>
</div>
<div ibmRow class="tw-pb-8">
    <div ibmCol [columnNumbers]="{ md: 2, lg: 2 }"></div>
    <div ibmCol [columnNumbers]="{ md: 12, lg: 12 }">
        <div
            *ngIf="customerSubscriptions && customerSubscriptions.length && accountingPlatformStatistics"
            class="tw-mb-6"
        >
            <div (click)="toggleStatisticsCollapsed('customer')" class="collapse-container d-flex align-items-center">
                <div class="ml-1r s-head-14-22-bold">
                    {{ 'PAGES.STATISTICS.LABEL_AVG' | translate }}
                    {{ 'PAGES.STATISTICS.LABEL_COLLAPSE_PER_CUSTOMER' | translate }}
                </div>
                <svg-icon
                    [ngClass]="{ 'rotate-180': statisticsCollapsed['customer'] }"
                    class="ml-auto icon icon__gray mr-04 justify-content-end"
                    src="/assets/icons/24_arrow-down.svg"
                ></svg-icon>
            </div>

            <div *ngIf="statisticsCollapsed['customer']" class="pt-1r pb-1r">
                <div ibmRow>
                    <div
                        *ngIf="accountingPlatformStatistics.cashUnderManagement && customerSubscriptions"
                        ibmCol
                        [columnNumbers]="{ md: 4, lg: 4 }"
                    >
                        <div class="tile tile--box-selection pt-3r pb-2r mt-1r">
                            <div class="pt-1r pb-1r headline s-head-28-36-bold">
                                {{
                                    accountingPlatformStatistics.cashUnderManagement / customerSubscriptions.length
                                        | eurocent
                                }}
                                €
                            </div>

                            <div class="headline">
                                {{ 'PAGES.STATISTICS.LABEL_TILE_CASH_UNDER_MANAGEMENT' | translate }}
                                {{ 'PAGES.STATISTICS.LABEL_COLLAPSE_PER_CUSTOMER' | translate }}
                            </div>
                        </div>
                    </div>
                    <div
                        *ngIf="accountingPlatformStatistics.moneyPlannedWithScalara"
                        ibmCol
                        [columnNumbers]="{ md: 4, lg: 4 }"
                    >
                        <div class="tile tile--box-selection pt-3r pb-2r mt-1r">
                            <div class="pt-1r pb-1r headline s-head-28-36-bold">
                                {{
                                    accountingPlatformStatistics.moneyPlannedWithScalara / customerSubscriptions.length
                                        | eurocent
                                }}
                                €
                            </div>
                            <div class="headline">
                                {{ 'PAGES.STATISTICS.LABEL_TILE_PLANNED_TRANSACTIONS' | translate }}
                                {{ 'PAGES.STATISTICS.LABEL_COLLAPSE_PER_CUSTOMER' | translate }}
                            </div>
                        </div>
                    </div>
                    <div
                        *ngIf="accountingPlatformStatistics.ownershipReceivablesPlanned"
                        ibmCol
                        [columnNumbers]="{ md: 4, lg: 4 }"
                    >
                        <div class="tile tile--box-selection pt-3r pb-2r mt-1r">
                            <div class="pt-1r pb-1r headline s-head-28-36-bold">
                                {{
                                    accountingPlatformStatistics.ownershipReceivablesPlanned /
                                        customerSubscriptions.length | eurocent
                                }}
                                €
                            </div>

                            <div class="headline">
                                {{ 'PAGES.STATISTICS.LABEL_TILE_PLANNED_RECIEVABLES' | translate }}
                                {{ 'PAGES.STATISTICS.LABEL_COLLAPSE_PER_CUSTOMER' | translate }}
                            </div>
                        </div>
                    </div>
                    <div *ngIf="accountingPlatformStatistics.plannedReserves" ibmCol [columnNumbers]="{ md: 4, lg: 4 }">
                        <div class="tile tile--box-selection pt-3r pb-2r mt-1r">
                            <div class="pt-1r pb-1r headline s-head-28-36-bold">
                                {{
                                    accountingPlatformStatistics.plannedReserves / customerSubscriptions.length
                                        | eurocent
                                }}
                                €
                            </div>

                            <div class="headline">
                                {{ 'PAGES.STATISTICS.LABEL_TILE_PLANNED_RESERVES' | translate }}
                                {{ 'PAGES.STATISTICS.LABEL_COLLAPSE_PER_CUSTOMER' | translate }}
                            </div>
                        </div>
                    </div>
                    <div
                        *ngIf="accountingPlatformStatistics.bookedBankTransactions"
                        ibmCol
                        [columnNumbers]="{ md: 4, lg: 4 }"
                    >
                        <div class="tile tile--box-selection pt-3r pb-2r mt-1r">
                            <div class="pt-1r pb-1r headline s-head-28-36-bold">
                                {{
                                    (accountingPlatformStatistics.bookedBankTransactions.count /
                                        customerSubscriptions.length) *
                                        100 | eurocent
                                }}
                            </div>

                            <div class="headline">
                                {{ 'PAGES.STATISTICS.LABEL_TILE_BOOKED_TRANSACTIONS' | translate }}
                                {{ 'PAGES.STATISTICS.LABEL_COLLAPSE_PER_CUSTOMER' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="mb-1r mt-0" />
        </div>
        <div
            *ngIf="
                platformStatistics &&
                platformStatistics.Property &&
                platformStatistics.Property > 0 &&
                accountingPlatformStatistics
            "
        >
            <div (click)="toggleStatisticsCollapsed('property')" class="collapse-container d-flex align-items-center">
                <div class="ml-1r s-head-14-22-bold">
                    {{ 'PAGES.STATISTICS.LABEL_AVG' | translate }}
                    {{ 'PAGES.STATISTICS.LABEL_COLLAPSE_PER_PROPERTY' | translate }}
                </div>

                <svg-icon
                    [ngClass]="{ 'rotate-180': statisticsCollapsed['property'] }"
                    class="ml-auto icon icon__gray mr-04 justify-content-end"
                    src="/assets/icons/24_arrow-down.svg"
                ></svg-icon>
            </div>

            <div *ngIf="statisticsCollapsed['property']" class="pt-1r pb-1r">
                <div ibmRow>
                    <div
                        *ngIf="accountingPlatformStatistics.cashUnderManagement"
                        ibmCol
                        [columnNumbers]="{ md: 4, lg: 4 }"
                    >
                        <div class="tile tile--box-selection pt-3r pb-2r mt-1r">
                            <div class="pt-1r pb-1r headline s-head-28-36-bold">
                                {{
                                    accountingPlatformStatistics.cashUnderManagement / platformStatistics.Property
                                        | eurocent
                                }}
                                €
                            </div>

                            <div class="headline">
                                {{ 'PAGES.STATISTICS.LABEL_TILE_CASH_UNDER_MANAGEMENT' | translate }}
                                {{ 'PAGES.STATISTICS.LABEL_COLLAPSE_PER_PROPERTY' | translate }}
                            </div>
                        </div>
                    </div>
                    <div
                        *ngIf="accountingPlatformStatistics.moneyPlannedWithScalara"
                        ibmCol
                        [columnNumbers]="{ md: 4, lg: 4 }"
                    >
                        <div class="tile tile--box-selection pt-3r pb-2r mt-1r">
                            <div class="pt-1r pb-1r headline s-head-28-36-bold">
                                {{
                                    accountingPlatformStatistics.moneyPlannedWithScalara / platformStatistics.Property
                                        | eurocent
                                }}
                                €
                            </div>

                            <div class="headline">
                                {{ 'PAGES.STATISTICS.LABEL_TILE_PLANNED_TRANSACTIONS' | translate }}
                                {{ 'PAGES.STATISTICS.LABEL_COLLAPSE_PER_PROPERTY' | translate }}
                            </div>
                        </div>
                    </div>
                    <div
                        *ngIf="accountingPlatformStatistics.ownershipReceivablesPlanned"
                        ibmCol
                        [columnNumbers]="{ md: 4, lg: 4 }"
                    >
                        <div class="tile tile--box-selection pt-3r pb-2r mt-1r">
                            <div class="pt-1r pb-1r headline s-head-28-36-bold">
                                {{
                                    accountingPlatformStatistics.ownershipReceivablesPlanned /
                                        platformStatistics.Property | eurocent
                                }}
                                €
                            </div>

                            <div class="headline">
                                {{ 'PAGES.STATISTICS.LABEL_TILE_PLANNED_RECIEVABLES' | translate }}
                                {{ 'PAGES.STATISTICS.LABEL_COLLAPSE_PER_PROPERTY' | translate }}
                            </div>
                        </div>
                    </div>
                    <div *ngIf="accountingPlatformStatistics.plannedReserves" ibmCol [columnNumbers]="{ md: 4, lg: 4 }">
                        <div class="tile tile--box-selection pt-3r pb-2r mt-1r">
                            <div class="pt-1r pb-1r headline s-head-28-36-bold">
                                {{
                                    accountingPlatformStatistics.plannedReserves / platformStatistics.Property
                                        | eurocent
                                }}
                                €
                            </div>

                            <div class="headline">
                                {{ 'PAGES.STATISTICS.LABEL_TILE_PLANNED_RESERVES' | translate }}
                                {{ 'PAGES.STATISTICS.LABEL_COLLAPSE_PER_PROPERTY' | translate }}
                            </div>
                        </div>
                    </div>
                    <div
                        *ngIf="accountingPlatformStatistics.bookedBankTransactions"
                        ibmCol
                        [columnNumbers]="{ md: 4, lg: 4 }"
                    >
                        <div class="tile tile--box-selection pt-3r pb-2r mt-1r">
                            <div class="pt-1r pb-1r headline s-head-28-36-bold">
                                {{
                                    (accountingPlatformStatistics.bookedBankTransactions.count /
                                        platformStatistics.Property) *
                                        100 | eurocent
                                }}
                            </div>

                            <div class="headline">
                                {{ 'PAGES.STATISTICS.LABEL_TILE_BOOKED_TRANSACTIONS' | translate }}
                                {{ 'PAGES.STATISTICS.LABEL_COLLAPSE_PER_PROPERTY' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="mb-1r mt-0" />
        </div>
    </div>
    <div ibmCol [columnNumbers]="{ md: 2, lg: 2 }"></div>
</div>
<div ibmRow class="pb-5r">
    <div ibmCol [columnNumbers]="{ md: 2, lg: 2 }"></div>
    <div ibmCol [columnNumbers]="{ md: 12, lg: 12 }">
        <ng-container *ngIf="chartConfig$ | async as chartConfig">
            <canvas baseChart [data]="chartConfig" [type]="lineChartType" [options]="lineChartOptions"></canvas
        ></ng-container>
    </div>
    <div ibmCol [columnNumbers]="{ md: 2, lg: 2 }"></div>
</div>
