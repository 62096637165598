<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>
<div ibmGrid class="bx--grid--full-width tw-px-10 tw-py-6">
    <div class="d-flex justify-space-between tw-mb-14">
        <div class="s-head-28-36-bold">{{ 'PAGES.USER_MANAGEMENT.ACCOUNTS_VIEW.HEADLINE' | translate }}</div>
        <!-- show tooltip only for demo environment -->
        <app-tooltip position="bottom" class="app-tooltip" [isDisabled]="!environment.demoEnvironment">
            <div hoverable-item class="hoverable-item">
                <app-scalara-button
                    (click)="addAccount()"
                    variant="ghost"
                    type="button"
                    height="40px"
                    svgName="24_add.svg"
                    [disabled]="environment.demoEnvironment"
                    >{{ 'PAGES.USER_MANAGEMENT.ACCOUNTS_VIEW.BUTTON_ADD_ACCOUNT' | translate }}</app-scalara-button
                >
                <svg-icon
                    src="/assets/icons/24_information.svg"
                    *ngIf="environment.demoEnvironment"
                    class="svg-icon"
                ></svg-icon>
            </div>
            <div content class="demo-banner_tooltip-content">
                {{ 'PAGES.USER_MANAGEMENT.ACCOUNTS_VIEW.BUTTON_ADD_ACCOUNT_DISABLED_TOOLTIP' | translate }}
            </div>
        </app-tooltip>
    </div>

    <div ibmRow *ngIf="!tableModel[0].bankConnection.id" class="empty d-flex align-items-center py-3-5r px-2-5r">
        <svg-icon src="/assets/icons/24_information.svg"></svg-icon>
        <div class="s-body-14-22-regular ml-0-5r">
            {{ 'PAGES.USER_MANAGEMENT.ACCOUNTS_VIEW.EMPTY' | translate }}
        </div>
    </div>

    <div *ngIf="tableModel[0].bankConnection.id">
        <div *ngFor="let model of tableModel; let index = index">
            <div>
                <div (click)="changeSelectDisplay(index)" class="collapse-container tw-p-2">
                    <div class="d-flex align-items-center s-head-16-24-bold">
                        <div
                            class="tw-bg-scalaraGray-07 tw-mr-4 tw-w-12 tw-h-12 tw-flex tw-items-center tw-justify-center"
                        >
                            <img
                                *ngIf="model.bankConnection.bankLogoUrl"
                                [src]="model.bankConnection.bankLogoUrl"
                                alt=""
                                class="tw-max-w-full tw-max-h-full tw-p-2"
                            />
                            <svg-icon
                                *ngIf="!model.bankConnection.bankLogoUrl"
                                class="tw-fill-scalaraGray-04 tw-h-6 tw-w-6"
                                src="/assets/icons/40_bank.svg"
                            ></svg-icon>
                        </div>

                        <span class="tw-mr-4">{{ model.bankConnection.bankName }}</span>
                        <span class="tw-mr-6"
                            >{{ 'ENTITIES.BANK_ACCOUNT.BIC' | translate }} {{ model.bankConnection.bic }}</span
                        >

                        <div class="d-flex align-items-center" *ngIf="model.isExpired && !environment.demoEnvironment">
                            <svg-icon class="mr-0-75r icon icon__red" src="/assets/icons/24_attention.svg"></svg-icon>
                            <span class="s-head-14-22-regular s-red-01">Keine aktuellen Transaktionsdaten</span>
                        </div>

                        <svg-icon
                            [ngClass]="{ 'rotate-180': isCollapsed[index] }"
                            class="ml-auto icon icon__gray mr-04"
                            src="/assets/icons/24_arrow-down.svg"
                        ></svg-icon>
                    </div>
                </div>
            </div>
            <div *ngIf="isCollapsed[index]">
                <div
                    class="d-flex justify-space-between info-box info-box__red mt-1r mb-3r align-items-center"
                    *ngIf="model.isExpired && !environment.demoEnvironment"
                >
                    <div class="s-body-14-22-regular tw-whitespace-break-spaces">
                        <span>{{ 'PAGES.USER_MANAGEMENT.ACCOUNTS_VIEW.MESSAGE_RENEW_CONNECTION' | translate }}</span>
                    </div>
                    <app-scalara-button
                        variant="default"
                        type="button"
                        height="40px"
                        (click)="updateAccount(model.bankConnection.id)"
                        >{{ 'PAGES.USER_MANAGEMENT.ACCOUNTS_VIEW.RENEW_CONNECTION' | translate }}</app-scalara-button
                    >
                </div>

                <div class="pl-1r mt-1-5r">
                    <div class="tw-flex tw-justify-between tw-items-center">
                        <div class="s-head-14-22-bold mb-1-5r">
                            {{ 'PAGES.USER_MANAGEMENT.ACCOUNTS_VIEW.ACCOUNTS_BANK_ACCOUNT' | translate }}
                        </div>

                        <app-scalara-button
                            *ngIf="!model.isExpired"
                            class="mr-0-5r"
                            variant="ghost"
                            type="button"
                            height="40px"
                            (click)="updateAccount(model.bankConnection.id)"
                            >{{ 'PAGES.USER_MANAGEMENT.ACCOUNTS_VIEW.EDIT_CONNECTION' | translate }}</app-scalara-button
                        >
                    </div>

                    <div ibmRow>
                        <div ibmCol class="mb-1r d-flex justify-content-end">
                            <app-search [searchInputId]="'userManagementAccountsView'"></app-search>
                        </div>
                    </div>
                    <app-table
                        [emptyText]="''"
                        [itemsPerPage]="1000"
                        [header]="model.header"
                        [data]="model.data"
                        (customButton)="openSynchronizePage($event)"
                        [tableSearchId]="'userManagementAccountsView'"
                        [isLoading]="isLoading"
                    ></app-table>
                </div>
            </div>
            <hr class="tw-mb-2 tw-mt-0" />
        </div>
    </div>
</div>

<ng-template #iconWithTooltip let-data="data">
    <app-tooltip position="left">
        <div content class="s-gray-01 p-1r">
            {{ 'PAGES.USER_MANAGEMENT.ACCOUNTS_VIEW.TOOLTIP_DATA_OUTDATED' | translate }}
        </div>

        <div hoverable-item>
            <svg-icon class="mr-0-75r icon" ngClass="icon__red" src="/assets/icons/24_attention.svg"></svg-icon>
        </div>
    </app-tooltip>
</ng-template>
