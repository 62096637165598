import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IbanFormatterPipe } from 'angular-iban';
import { BreadcrumbItem } from 'carbon-components-angular';
import {
    BehaviorSubject,
    Subject,
    catchError,
    combineLatest,
    distinctUntilChanged,
    forkJoin,
    map,
    of,
    switchMap,
    takeUntil,
} from 'rxjs';
import { BreadCrumbService } from 'src/app/core/services/breadcrumb.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { getCurrentDateYYYY_MM_DD } from 'src/app/core/utils/common';
import { BankAccountDto, Payment, PaymentDto, PaymentsService } from 'src/app/generated-sources/accounting';
import { Property } from 'src/app/generated-sources/base';
import { Column, RowData } from 'src/app/shared/components/simple-table/simple-table-row/simple-table-row.component';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { BankingPaymentDeleteOverlayComponent } from '../banking-payment-delete-overlay/banking-payment-delete-overlay.component';
import {
    AddPaymentComponentConfig,
    BankingPaymentsAddEditOverlayComponent,
} from '../banking-payments-add-edit-overlay/banking-payments-add-edit-overlay.component';
import { PaymentCustomService } from '../service/payment-custom.service';

export type PaymentTableKey =
    | 'checkComponent'
    | 'counterpartName'
    | 'porpuse'
    | 'type'
    | 'rythm'
    | 'executionDate'
    | 'amount'
    | 'deleteComponent'
    | 'addEditPaymentComponent'
    | 'iban';
export type PaymentTableData = {
    columns: Column<PaymentTableKey>[];
    rows: RowData<PaymentTableKey>[];
    header: { iban: string; ledgerType: string; ledgerName: string };
    payments: PaymentDto[];
};
@Component({
    selector: 'app-banking-payments-payments-approvals-selection',
    templateUrl: './banking-payments-payments-approvals-selection.component.html',
    styleUrls: ['./banking-payments-payments-approvals-selection.component.scss'],
})
export class BankingPaymentsPaymentsApprovalsSelectionComponent implements OnInit {
    private unsubscribe$ = new Subject<void>();
    public refresh$ = new BehaviorSubject<void>(undefined);
    public isSelectAllChecked = true;

    public breadcrumbs: BreadcrumbItem[] = [];

    public directDebits$ = new BehaviorSubject<PaymentDto[][]>([]);
    public transfers$ = new BehaviorSubject<PaymentDto[][]>([]);
    public bankConnection?: BankAccountDto;
    public openPaymentsMoneyTransfer = 0;
    public openPaymentsDirectDebit = 0;
    public ledgerId?: string;
    private ibanFormatterPipe = new IbanFormatterPipe();
    public isLoading = false;

    public moneyTransferPaymentsToApproveForm = new UntypedFormGroup({});
    public directDebitPaymentsToApproveForm = new UntypedFormGroup({});

    @ViewChild('deletePayment', { static: true })
    public deletePayment?: TemplateRef<any>;

    @ViewChild('addEditPayment', { static: true })
    public addEditPayment?: TemplateRef<any>;

    @ViewChild('checkboxPayment', { static: true })
    public checkboxPayment?: TemplateRef<any>;

    public moneyTransferTable: PaymentTableData[] = [];
    public directDebitTable: PaymentTableData[] = [];

    public webformUpdateParam$ = this.route.queryParamMap.pipe(
        map((params) => params.get('update')),
        distinctUntilChanged()
    );

    public ledgerId$ = this.route.url.pipe(
        map(() => {
            return this.route.parent?.snapshot.paramMap.get('id') ?? undefined;
        }),
        distinctUntilChanged()
    );

    public paymentObs$ = this.refresh$.asObservable().pipe(
        switchMap(() =>
            combineLatest([this.webformUpdateParam$, this.ledgerId$]).pipe(
                switchMap(([webformUpdateParam, ledgerId]) => {
                    this.ledgerId = ledgerId ?? undefined;
                    if (webformUpdateParam) {
                        return forkJoin([
                            this.paymentsService.updateWebformsStatus(),
                            this.paymentsService.updatePaymentsStatus(),
                        ]).pipe(
                            catchError((e) => {
                                console.log(e);
                                return of(null);
                            })
                        );
                    } else {
                        return of(null);
                    }
                }),
                switchMap(() => this.paymentsService.findAll('OPEN,EXPORTED,INCOMPLETE', this.ledgerId)),
                map((payments) => {
                    const bankConnectionBIC = this.route.snapshot.params['bankConnectionBIC'];
                    return payments.filter((payment) => {
                        return payment.bankAccount.bic === bankConnectionBIC;
                    });
                }),
                takeUntil(this.unsubscribe$)
            )
        )
    );

    public constructor(
        private breadcrumbService: BreadCrumbService,
        private overlayService: OverlayService,
        private router: Router,
        private route: ActivatedRoute,
        private paymentsService: PaymentsService,
        private toastService: ToastService,
        private paymentCustomService: PaymentCustomService
    ) {}

    private reducePayments(payments: PaymentDto[]): PaymentDto[][] {
        return payments.reduce((acc, currentPayment) => {
            let group = acc.find((group) =>
                group.some(
                    (p) =>
                        p.bankAccount.id === currentPayment.bankAccount.id &&
                        p.executionDate === currentPayment.executionDate
                )
            );

            if (!group) {
                group = [];
                acc.push(group);
            }

            group.push(currentPayment);

            return acc;
        }, [] as PaymentDto[][]);
    }

    public generatePaymentTable(payments: PaymentDto[], selectedAllPayments = false): PaymentTableData {
        const payment = payments && payments.length ? payments[0] : null;
        const property = payment?.property as Property;
        return {
            payments: payments,
            header: {
                iban: this.ibanFormatterPipe.transform(payment?.bankAccount?.iban),
                ledgerType: property.propertyType ?? '',
                ledgerName:
                    property && property.address
                        ? `${property.name}, ${property.address.streetName} ${property.address.streetNumber}, ${property.address.zipCode}, ${property.address.area}`
                        : '',
            },
            rows: payments?.map((key, index) => {
                const link = `/accounting/ledger/${key.ledgerId}/payments/${key.id}`;
                return {
                    cells: [
                        {
                            key: 'checkComponent',
                            type: 'component',
                            component: this.checkboxPayment,
                            componentData: {
                                payment: key,
                                paymentType: key.type,
                                disabled: key.paymentStatus === Payment.PaymentStatusEnum.Incomplete,
                                link,
                            },
                        },
                        {
                            key: 'counterpartName',
                            value: key.counterpartName,
                            type: 'value',
                            rowCellClass: '[&&]:tw-text-left',
                            link,
                        },
                        {
                            key: 'iban',
                            value: this.ibanFormatterPipe.transform(key.counterpartIban),
                            type: 'value',
                            rowCellClass: '[&&]:tw-text-left',
                            link,
                        },
                        {
                            key: 'porpuse',
                            value: key.purpose,
                            type: 'value',
                            rowCellClass: '[&&]:tw-text-left',
                            link,
                        },
                        {
                            key: 'amount',
                            value: key.amount,
                            type: 'value',
                            pipeToApply: 'eurocent',
                            rowCellClass: 'tw-s-body-14-22-bold',
                            link,
                        },
                        {
                            key: 'deleteComponent',
                            value: '',
                            type: 'component',
                            component: this.deletePayment,
                            componentData: {
                                payment: key,
                            },
                        },
                        {
                            key: 'addEditPaymentComponent',
                            value: '',
                            type: 'component',
                            component: this.addEditPayment,
                            componentData: {
                                payment: key,
                            },
                        },
                    ],
                    rowClass: `[&&]:tw-cursor-pointer [&&]:hover:tw-bg-scalaraGray-06 payment-table-row${
                        index + 1 === payments.length
                            ? ''
                            : ' [&&]:tw-border-b [&&]:tw-border-solid [&&]:tw-border-scalaraGray-05'
                    }`,
                };
            }),
            columns: [
                {
                    key: 'checkComponent',
                    label: '',
                    columnCellClass: '[&&]:tw-w-[56px]',
                },
                {
                    key: 'counterpartName',
                    label: 'Empfänger',
                    columnCellClass: '[&&]:tw-text-left [&&]:tw-w-[256px]',
                },
                { key: 'iban', label: 'IBAN', columnCellClass: '[&&]:tw-text-left [&&]:tw-w-[300px]' },
                { key: 'porpuse', label: 'Verwendungszweck', columnCellClass: '[&&]:tw-text-left' },
                { key: 'amount', label: 'Betrag in €', columnCellClass: '[&&]:tw-w-[120px]' },
                { key: 'deleteComponent', label: '', columnCellClass: '[&&]:tw-w-[56px]' },
                { key: 'addEditPaymentComponent', label: '', columnCellClass: '[&&]:tw-w-[56px]' },
            ],
        };
    }

    public ngOnInit(): void {
        this.paymentObs$.subscribe({
            next: (payments) => {
                this.router.navigate([], {
                    relativeTo: this.route,
                    replaceUrl: true,
                });

                if (payments.length > 0) {
                    this.initBreadcrumbs(payments[0].bankAccount.bankName, payments[0].bankAccount.bic);
                }

                const directDebit = payments.filter((order) => order.type === 'DIRECT_DEBIT');
                const moneyTransfer = payments.filter((order) => order.type === 'MONEY_TRANSFER');

                const directDebitGrouped = this.reducePayments(directDebit);
                const moneyTransferGrouped = this.reducePayments(moneyTransfer);

                // money transfer payments checkbox form
                const moneyTransferPaymentsControls: any = {};
                moneyTransfer.map((payment) => {
                    moneyTransferPaymentsControls[payment.id] = new UntypedFormControl(
                        payment.paymentStatus === Payment.PaymentStatusEnum.Incomplete ? false : true
                    );
                });

                this.moneyTransferPaymentsToApproveForm = new UntypedFormGroup(moneyTransferPaymentsControls);

                //direct debit payments checkbox form
                const directDebitPaymentsControls: any = {};
                directDebit.map((payment) => {
                    directDebitPaymentsControls[payment.id] = new UntypedFormControl(
                        payment.paymentStatus === Payment.PaymentStatusEnum.Incomplete ? false : true
                    );
                });

                this.directDebitPaymentsToApproveForm = new UntypedFormGroup(directDebitPaymentsControls);

                this.openPaymentsDirectDebit = directDebit.length;
                this.openPaymentsMoneyTransfer = moneyTransfer.length;

                this.directDebits$.next(directDebitGrouped);
                this.transfers$.next(moneyTransferGrouped);
                if (payments.length > 0) {
                    this.bankConnection = payments[0].bankAccount;
                }

                for (let i = 0; i < moneyTransferGrouped.length; i++) {
                    this.moneyTransferTable[i] = this.generatePaymentTable(moneyTransferGrouped[i], true);
                }

                for (let i = 0; i < directDebitGrouped.length; i++) {
                    this.directDebitTable[i] = this.generatePaymentTable(directDebitGrouped[i], true);
                }
            },
            error: (error) => {
                this.toastService.showError(error.error['message']);
            },
        });
    }

    private initBreadcrumbs(bankName: string, bic: string): void {
        const newBreadcrumb = [
            {
                content: bankName,
                route: [
                    this.ledgerId
                        ? `/accounting/ledger/${this.ledgerId}/payment-approvals/${bic}`
                        : `/accounting/payment-approvals/${bic}`,
                ],
                current: true,
            },
        ];

        this.breadcrumbService.updateBreadCrumbs(newBreadcrumb);
        this.breadcrumbs = this.breadcrumbService.getCurrentBreadCrumbs();
    }

    public openWebForm(event: {
        paymentsToApproveGroup: PaymentDto[];
        bankAccountId: string;
        paymentType: PaymentDto.TypeEnum;
    }): void {
        const { paymentsToApproveGroup, bankAccountId, paymentType } = event;

        const paymentsToApproveIdsGroup = paymentsToApproveGroup.map((payment) => payment.id);

        const paymentIdsToApprove = this.getSelectedPayments(paymentType)?.filter((id) =>
            paymentsToApproveIdsGroup?.includes(id)
        );

        if (!paymentIdsToApprove) {
            return;
        }

        this.paymentCustomService
            .approvePayment(paymentIdsToApprove.join(','), bankAccountId, paymentType)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                error: (error) => {
                    this.toastService.showError(error.error['message']);
                },
            });
    }

    public openDeletePaymentOverlay(payment: PaymentDto): void {
        const ref = this.overlayService.open(BankingPaymentDeleteOverlayComponent, { data: { payment } });

        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.refresh$.next();
        });
    }

    public openAddEditPaymentOverlay(payment: PaymentDto): void {
        const config: AddPaymentComponentConfig = {
            data: { paymentType: payment.type, editMode: true, paymentId: payment.id },
        };
        const ref = this.overlayService.open(BankingPaymentsAddEditOverlayComponent, config);
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.refresh$.next());
    }

    public paymentControl(paymentType: PaymentDto.TypeEnum, paymentId: string): UntypedFormControl {
        const paymentFormGroup = this.getPaymentControlByPaymentType(paymentType);
        return paymentFormGroup.get(paymentId) as UntypedFormControl;
    }

    public onCheckboxChange(event: any, paymentId: string, paymentType: PaymentDto.TypeEnum): void {
        const paymentFormGroup = this.getPaymentControlByPaymentType(paymentType);

        paymentFormGroup.get(paymentId)?.setValue(event.checked);
    }

    public selectAllPayments(event: any, paymentType: PaymentDto.TypeEnum): void {
        const paymentFormGroup = this.getPaymentControlByPaymentType(paymentType);
        Object.keys(paymentFormGroup.controls).map((key) => {
            paymentFormGroup.get(key)?.setValue(event.checked);
        });
    }

    public exportPayments(paymentType: PaymentDto.TypeEnum): void {
        const paymentIdsToExport = this.getSelectedPayments(paymentType);

        if (!paymentIdsToExport) {
            return;
        }

        (paymentType === PaymentDto.TypeEnum.MoneyTransfer
            ? this.paymentsService.exportMoneyTransfers(paymentIdsToExport.join(','))
            : this.paymentsService.exportDirectDebits(paymentIdsToExport.join(','))
        ).subscribe({
            next: (response) => {
                const link = document.createElement('a');
                const blob = new Blob([response], { type: '.xml' });
                link.href = window.URL.createObjectURL(blob);
                link.download = `${
                    paymentType === PaymentDto.TypeEnum.MoneyTransfer ? 'Überweisungen' : 'Lastschriften'
                }_${getCurrentDateYYYY_MM_DD()}.xml`;
                link.click();
                this.refresh$.next();
                this.isLoading = false;
            },
            error: (error) => {
                this.toastService.showError(error.error['message']);
                this.isLoading = false;
            },
        });
    }

    public getSelectedPayments(paymentType: PaymentDto.TypeEnum): string[] | undefined {
        const paymentIdsToApprove: string[] = [];
        const paymentFormGroup = this.getPaymentControlByPaymentType(paymentType);

        Object.keys(paymentFormGroup.controls).map((key) => {
            if (paymentFormGroup.get(key)?.value === true) {
                paymentIdsToApprove.push(key);
            }
        });

        if (paymentIdsToApprove.length > 0) {
            return paymentIdsToApprove;
        }

        return;
    }

    public getPaymentControlByPaymentType(paymentType: PaymentDto.TypeEnum): UntypedFormGroup {
        return paymentType === PaymentDto.TypeEnum.MoneyTransfer
            ? this.moneyTransferPaymentsToApproveForm
            : this.directDebitPaymentsToApproveForm;
    }
}
