<ng-container *ngIf="linkParams?.routerLink; else mainContent">
    <a [routerLink]="linkParams?.routerLink" queryParamsHandling="merge" [class]="linkParams?.linkClass">
        <ng-container *ngTemplateOutlet="mainContent"></ng-container>
    </a>
</ng-container>

<ng-template #mainContent
    ><div class="tw-flex tw-items-center tw-space-x-3">
        <div [class]="labelClass">{{ label }}</div>

        <ng-container *ngIf="showTooltip$ | async as showTooltip">
            <app-tooltip
                position="bottom"
                *ngIf="tooltipInnerHtml && !tooltipInnerHtml?.includes('missing translation')"
            >
                <div
                    content
                    class="tw-s-head-14-22-regular tw-text-scalaraGray-01 tw-w-72 lg:tw-w-[36rem] tw-p-4 lg:tw-px-12 lg:tw-py-8"
                >
                    <div class="" [innerHTML]="tooltipInnerHtml"></div>
                </div>

                <div hoverable-item class="tw-w-6 tw-h-6">
                    <svg-icon
                        class="tw-fill-scalaraGray-04 hover:tw-fill-scalaraBlue-01 tw-w-6 tw-h-6"
                        src="/assets/icons/24_information.svg"
                    ></svg-icon>
                </div>
            </app-tooltip>
        </ng-container></div
></ng-template>
