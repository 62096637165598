import { Observable, Subject } from 'rxjs';

export class OverlayRef {
    private readonly _afterClosed = new Subject<any>();

    public saveEmitter$ = new Subject<void | string>();
    public cancelEmitter$ = new Subject<void>();
    public afterClosed: Observable<any> = this._afterClosed.asObservable();

    public close(result?: any): void {
        this._afterClosed.next(result);
    }
}
