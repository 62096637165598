<app-signin-signup-container routeLink="sign-up" [containerWidthStyle]="containerWidth">
    <div class="w-100">
        <h1 class="s-head-28-36-bold mb-3r mt-2r">{{ this.conditionalTextLabels[this.flowType].headerLabel }}</h1>
        <app-notification [type]="'success'" class="s-body-14-22-regular">
            <div *ngIf="this.flowType === authSuccessFlowTypeDeclared.resetPassword; else verificationNote">
                {{ 'PAGES.AUTH_SUCCESS.FLOWS.RESET_PASSWORD.SUCCESS_NOTE' | translate }}
            </div>
        </app-notification>

        <div class="mt-0-75r ml-1r" *ngIf="flowType !== authSuccessFlowTypeDeclared.resetPassword">
            <app-icon-info-box [iconName]="'24_information.svg'" [iconColor]="'blue'">
                <div>
                    <span>{{ 'PAGES.AUTH_SUCCESS.FLOWS.VERIFICATION.SUCCESS_SUBNOTE.PART_1' | translate }}</span>
                    <span class="d-block">
                        <span>{{ 'PAGES.AUTH_SUCCESS.FLOWS.VERIFICATION.SUCCESS_SUBNOTE.PART_2' | translate }}</span>
                        <a
                            routerLink="/reset-password/give-email"
                            [queryParams]="{ flowType: this.giveEmailFlowTypeObjDeclared.resendVerificationLink }"
                            >{{ 'PAGES.AUTH_SUCCESS.FLOWS.VERIFICATION.SUCCESS_NOTE.HERE' | translate }}</a
                        >
                        <span>{{ 'PAGES.AUTH_SUCCESS.FLOWS.VERIFICATION.SUCCESS_SUBNOTE.PART_3' | translate }}</span>
                    </span>
                </div>
            </app-icon-info-box>
        </div>

        <div class="action-button-wrapper mt-5r">
            <app-scalara-button type="button" variant="default" (click)="onButtonClick()" [fullWidth]="true">
                {{ this.buttonLabel }}
            </app-scalara-button>
        </div>
    </div>
</app-signin-signup-container>

<ng-template #verificationNote>
    <div>
        <span class="mb-1r d-block">{{ 'PAGES.AUTH_SUCCESS.FLOWS.VERIFICATION.SUCCESS_NOTE.PART_1' | translate }}</span>
        <span class="d-block">
            <span>{{ 'PAGES.AUTH_SUCCESS.FLOWS.VERIFICATION.SUCCESS_NOTE.PART_2' | translate }}</span>
            <a [routerLink]="'/sign-in'">{{ 'PAGES.AUTH_SUCCESS.FLOWS.VERIFICATION.SUCCESS_NOTE.HERE' | translate }}</a>
            <span>{{ 'PAGES.AUTH_SUCCESS.FLOWS.VERIFICATION.SUCCESS_NOTE.PART_3' | translate }}</span>
        </span>
    </div>
</ng-template>
