<div class="tw-flex tw-justify-between tw-mb-6">
    <app-headline-with-icon color="green" image="24_dokument.svg" text="Energieausweis"></app-headline-with-icon>

    <app-scalara-button variant="ghost" svgName="24_add.svg" (click)="openAddEditEnergyCertificateOverlay()">
        {{ 'CONTROLS.BUTTONS.ADD' | translate }}
    </app-scalara-button>
</div>

<ng-container *ngIf="energyCertificates.length > 0; else notSpecified">
    <div class="tw-space-y-4">
        <div
            *ngFor="let energyCertificateItem of energyCertificates"
            class="tile tile--not-clickable tile--static tw-flex tw-group tw-relative"
        >
            <div class="tw-p-2 tw-pr-0">
                <div
                    *ngIf="
                        energyCertificateItem &&
                            energyCertificateItem.energyCertificateFiles &&
                            energyCertificateItem.energyCertificateFiles.length > 0 &&
                            energyCertificateItem.energyCertificateFiles[0].filePreview;
                        else empty
                    "
                >
                    <img
                        class="tw-w-[149px] tw-h-[211px]"
                        src="data:image/png;base64,{{ energyCertificateItem.energyCertificateFiles[0].filePreview }}"
                    />
                </div>
            </div>

            <div class="tw-p-6 tw-w-full tw-flex tw-flex-col">
                <div class="tw-flex">
                    <div>
                        <div>
                            <div class="tw-s-label-12-22-semibold tw-mb-2 tw-text-scalaraGray-03">
                                {{ 'ENTITIES.ENERGY_CERTIFICATE.LABEL_ENERGY_CERTIFICATE_TYPE' | translate }}
                            </div>
                            <div class="tw-s-body-14-22-regular">
                                {{
                                    'ENTITIES.ENERGY_CERTIFICATE.LABEL_' + energyCertificateItem.certificateType
                                        | translate
                                }}
                            </div>
                        </div>

                        <div class="tw-mt-4">
                            <div class="tw-s-label-12-22-semibold tw-mb-2 tw-text-scalaraGray-03">
                                {{ 'ENTITIES.ENERGY_CERTIFICATE.LABEL_ENERGY_PARAMETER' | translate }}
                            </div>
                            <div class="tw-s-body-14-22-regular">{{ energyCertificateItem.energyValueInkWh }} kwh</div>
                        </div>

                        <div class="tw-mt-4">
                            <div class="tw-s-label-12-22-semibold tw-mb-2 tw-text-scalaraGray-03">
                                {{ 'ENTITIES.ENERGY_CERTIFICATE.LABEL_ENERGY_EFFICIENCY_CLASS' | translate }}
                            </div>
                            <div
                                *ngIf="energyCertificateItem.energyEfficiencyClass; else notSpecified"
                                class="tw-s-body-14-22-regular"
                            >
                                {{ energyCertificateItem.energyEfficiencyClass }}
                            </div>
                        </div>
                    </div>
                    <div class="tw-ml-14">
                        <div>
                            <div class="tw-s-label-12-22-semibold tw-mb-2 tw-text-scalaraGray-03">
                                {{ 'ENTITIES.ENERGY_CERTIFICATE.LABEL_DATE_ISSUE' | translate }}
                            </div>
                            <div
                                *ngIf="energyCertificateItem.issueDate; else notSpecified"
                                class="tw-s-body-14-22-regular"
                            >
                                {{ energyCertificateItem.issueDate | dateFormat }}
                            </div>
                        </div>

                        <div class="tw-mt-4">
                            <div class="tw-s-label-12-22-semibold tw-mb-2 tw-text-scalaraGray-03">
                                {{ 'ENTITIES.ENERGY_CERTIFICATE.LABEL_VALID_UNTIL' | translate }}
                            </div>
                            <div
                                *ngIf="energyCertificateItem.validUntil; else notSpecified"
                                class="tw-s-body-14-22-regular"
                            >
                                {{ energyCertificateItem.validUntil | dateFormat }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tw-space-y-1 tw-mt-6" *ngIf="energyCertificateItem.energyCertificateFiles">
                    <app-filestorage-documents-list
                        [documentList]="energyCertificateItem.energyCertificateFiles"
                        [showDocumentIcon]="false"
                    ></app-filestorage-documents-list>
                </div>
            </div>

            <div class="tw-invisible group-hover:tw-visible tw-absolute tw-right-6 tw-top-4">
                <div class="tw-flex">
                    <app-scalara-button
                        height="40px"
                        variant="icon-only"
                        (click)="openDeleteEnergyCertificate(energyCertificateItem)"
                        svgName="24_delete.svg"
                    ></app-scalara-button>
                    <app-scalara-button
                        (click)="openAddEditEnergyCertificateOverlay(energyCertificateItem)"
                        height="40px"
                        variant="icon-only"
                        svgName="24_edit.svg"
                    ></app-scalara-button>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #notSpecified>
    <div class="s-body-14-22-italic">
        {{ 'PAGES.OWNERSHIP.DETAIL_VIEW.LABEL_NOT_SPECIFIED' | translate }}
    </div>
</ng-template>

<ng-template #empty>
    <div class="tw-w-[149px] tw-h-[211px] tw-bg-scalaraGray-06 tw-flex tw-justify-center tw-items-center">
        <div class="tw-h-10 tw-w-10 tw-fill-scalaraGray-05">
            <svg-icon src="/assets/icons/40_dokument.svg"></svg-icon>
        </div>
    </div>
</ng-template>
