<table class="w-100 mb-2-5r">
    <thead class="account-table-header">
        <tr class="s-label-12-22-semibold text-left">
            <th class="p-1r">{{ 'ENTITIES.BANK_ACCOUNT.ACCOUNT_TYPE' | translate }}</th>
            <th class="p-1r">{{ 'ACCOUNTING.BANK_ACCOUNT_DETAILS.BANK_NAME' | translate }}</th>
            <th class="p-1r">{{ 'ACCOUNTING.BANK_ACCOUNT_DETAILS.IBAN' | translate }}</th>
            <th class="p-1r">
                {{ 'ACCOUNTING.BANK_ACCOUNT_DETAILS.COUNTERPART_BANKNAME' | translate }}
            </th>
            <th class="p-1r"></th>
        </tr>
    </thead>

    <tbody *ngIf="bankAccounts.length">
        <tr
            *ngFor="let account of bankAccounts; let last = last"
            [class.account-table-row-border]="!last"
            class="s-body-14-20-regular account-table-row"
            (click)="selectBankAccount(account)"
            [ngClass]="{
                'disabled-account-selection': showAddNewBankAccount
            }"
        >
            <td class="p-1r">{{ getBankTypeLabel(account) }}</td>
            <td class="p-1r">{{ account.bankName }}</td>
            <td class="p-1r">{{ account.iban }}</td>
            <td class="p-1r">{{ account.holderName }}</td>
            <td
                class="p-1r formated-icon"
                [ngClass]="{
                    'selected-bank-account-icon': selectedBankAccount?.id === account.id,
                    'unselected-bank-account-icon': selectedBankAccount?.id !== account.id
                }"
            >
                <svg-icon
                    [src]="
                        '/assets/icons/' +
                        (selectedBankAccount?.id === account.id ? '24_checkbox-check.svg' : '24_add.svg')
                    "
                ></svg-icon>
            </td>
        </tr>
    </tbody>
    <div class="d-flex align-items-center s-body-14-22-regular" *ngIf="!bankAccounts.length">
        <svg-icon class="icon-info" src="/assets/icons/24_information.svg"></svg-icon>
        {{ 'PAGES.OPERATIONS_EXPENSE_STATEMENTS.EDIT_OES.EMPTY_BANK_SELECTION' | translate }}
    </div>
</table>

<div *ngIf="!showAddNewBankAccount">
    <app-scalara-button (click)="addNewBankAccount()" variant="ghost" svgName="24_add.svg"
        >Andere Kontoverbindung verwenden</app-scalara-button
    >
</div>

<form [formGroup]="form">
    <div *ngIf="showAddNewBankAccount" class="mt-3-5r">
        <div>
            <hr />
            <div class="d-flex justify-content-end">
                <app-scalara-button
                    (click)="closeNewBankAccount()"
                    variant="ghost"
                    svgName="24_close.svg"
                    height="40px"
                ></app-scalara-button>
            </div>
        </div>

        <div class="d-flex justify-space-between mb-1r">
            <ibm-label
                [invalid]="isInvalidForm('accountHolderName')"
                invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                class="mr-2r"
                >Kontoinhaber*
                <input
                    ibmText
                    name="accountHolderName"
                    id="accountHolderName"
                    [invalid]="isInvalidForm('accountHolderName')"
                    formControlName="accountHolderName"
                />
            </ibm-label>

            <ibm-label [invalid]="isInvalidForm('bankName')" invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                >Bankinstitut*
                <input
                    ibmText
                    name="bankName"
                    id="bankName"
                    [invalid]="isInvalidForm('bankName')"
                    formControlName="bankName"
                />
            </ibm-label>
        </div>

        <div class="d-flex justify-space-between">
            <ibm-label [invalid]="isInvalidForm('iban')" invalidText="{{ 'ERROR.REQUIRED' | translate }}" class="mr-2r"
                >IBAN*
                <input ibmText name="iban" id="iban" [invalid]="isInvalidForm('iban')" formControlName="iban" />
            </ibm-label>

            <ibm-label [invalid]="isInvalidForm('bic')" invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                >BIC*
                <input ibmText name="bic" id="bic" [invalid]="isInvalidForm('bic')" formControlName="bic" />
            </ibm-label>
        </div>
    </div>

    <div class="mt-5r">
        <div class="s-head-16-24-bold mb-1-5r">Geben Sie das Fristdatum für Nachzahlungen ein</div>

        <div class="date-picker-styled">
            <ibm-date-picker
                label="Datum*"
                id="date"
                [placeholder]="'tt.mm.jjjj'"
                [size]="'md'"
                [language]="'de'"
                [dateFormat]="'d.m.Y'"
                class="mr-1r"
                invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                formControlName="outstandingBalanceDeadline"
                [invalid]="isInvalidForm('outstandingBalanceDeadline')"
            >
            </ibm-date-picker>
        </div>
    </div>
</form>
<div class="d-flex justify-space-between mt-7-5r">
    <app-scalara-button (click)="abort()" [variant]="'outline'">
        {{ 'CONTROLS.BUTTONS.BACK' | translate }}
    </app-scalara-button>

    <div class="d-flex">
        <div class="mr-1-5r">
            <app-scalara-button (click)="submit()" [disabled]="disabledButton()" height="48px">
                Speichern
            </app-scalara-button>
        </div>
    </div>
</div>
