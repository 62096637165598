import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { GridModule, InputModule, LoadingModule, TableModule } from 'carbon-components-angular';
import { SharedModule } from 'src/app/shared/shared.module';
import { ChangeInvoiceInformationOverlayComponent } from './components/change-invoice-information-overlay/change-invoice-information-overlay.component';
import { SubscriptionRowComponent } from './components/subscription-row/subscription-row.component';
import { SubscriptionsComponent } from './components/subscriptions/subscriptions.component';
import { UpdateSubscriptionOverlayComponent } from './components/update-subscription-overlay/update-subscription-overlay.component';

@NgModule({
    declarations: [
        SubscriptionsComponent,
        SubscriptionRowComponent,
        ChangeInvoiceInformationOverlayComponent,
        UpdateSubscriptionOverlayComponent,
    ],
    imports: [
        CommonModule,
        TableModule,
        ReactiveFormsModule,
        InputModule,
        GridModule,
        TranslateModule,
        LoadingModule,
        SharedModule,
        AngularSvgIconModule,
    ],
})
export class SubscriptionsModule {}
