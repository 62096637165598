<ibm-loading *ngIf="isLoading" [overlay]="true"></ibm-loading>
<app-overlay-container [headlineTitle]="headline" containerSize="default">
    <ng-container *ngIf="!selectedBank && !localConfig?.data?.editMode">
        <div class="s-body-14-22-regular tw-mb-6">Wählen Sie das Bankkonto aus</div>
        <div *ngFor="let bankAccount of bankAccounts$ | async" class="tile expandable-tile-selector mb-1-5r">
            <div class="tw-flex tw-p-4" (click)="selectBank(bankAccount)">
                <ng-container *ngTemplateOutlet="bankDetails; context: { bankAccount }"></ng-container>
                <div class="tile__icon rotate-270">
                    <svg-icon src="/assets/icons/24_arrow-down.svg"></svg-icon>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="selectedBank">
        <ng-container *ngTemplateOutlet="bankDetails; context: { bankAccount: selectedBank }"></ng-container>
        <form [formGroup]="form" class="tw-mt-8">
            <div class="tw-mb-6">
                <app-combo-box
                    size="md"
                    id="counterpartName"
                    placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT_2' | translate }}"
                    [appendInline]="true"
                    label="{{
                        (paymentType === 'MONEY_TRANSFER'
                            ? 'ACCOUNTING.BANK_TRANSACTION_DETAILS.RECIPIENTS'
                            : 'ACCOUNTING.BANK_TRANSACTION_DETAILS.COUNTERPART_NAME'
                        ) | translate
                    }}*"
                    [items]="personsList"
                    (selected)="onSelectPerson($event)"
                    (change)="onSelectPerson($event)"
                    [invalid]="isInvalidForm('counterpartName')"
                    invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                >
                    <ibm-dropdown-list></ibm-dropdown-list>
                </app-combo-box>
            </div>

            <div class="tw-mb-6" *ngIf="personsBankAccountList.length > 0">
                <app-combo-box
                    size="md"
                    id="bankAccount"
                    placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT_2' | translate }}"
                    [appendInline]="true"
                    label="Zahlungsverbindung"
                    [items]="personsBankAccountList"
                    (selected)="onSelectPersonBankAccount($event)"
                    invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                >
                    <ibm-dropdown-list></ibm-dropdown-list>
                </app-combo-box>
            </div>

            <div class="tw-mb-6">
                <ibm-label [invalid]="isInvalidForm('counterpartIban')" invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                    >IBAN*
                    <input
                        ibmText
                        name="counterpartIban"
                        id="counterpartIban"
                        [invalid]="isInvalidForm('counterpartIban')"
                        formControlName="counterpartIban"
                    />
                </ibm-label>
            </div>

            <div class="tw-mb-6" *ngIf="paymentType === 'DIRECT_DEBIT'">
                <app-combo-box
                    size="md"
                    id="mandateId"
                    placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT_2' | translate }}"
                    [appendInline]="true"
                    label="Mandats ID*"
                    [items]="mandateList ?? []"
                    (selected)="onSelectMandateId($event)"
                    (change)="onSelectMandateId($event)"
                    [invalid]="isInvalidForm('mandateId')"
                    invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                >
                    <ibm-dropdown-list></ibm-dropdown-list>
                </app-combo-box>
            </div>

            <div class="tw-mb-6">
                <ibm-label
                    >BIC
                    <input ibmText name="counterpartBic" id="counterpartBic" formControlName="counterpartBic" />
                </ibm-label>
            </div>

            <div class="tw-mb-6">
                <app-number-input
                    label="Betrag in €*"
                    [allowNegatives]="false"
                    formControlName="amount"
                    [invalid]="isInvalidForm('amount')"
                    invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                    orientation="left"
                ></app-number-input>
            </div>

            <div class="tw-mb-6">
                <ibm-label
                    >Verwendungszweck
                    <input ibmText name="purpose" id="purpose" formControlName="purpose" />
                </ibm-label>
            </div>

            <ng-container *ngIf="(flow$ | async) === 'base'">
                <div>
                    <ibm-date-picker
                        label="{{ 'ENTITIES.PAYMENT.LABEL_EXECUITION_DATE' | translate }}"
                        id="executionDate"
                        [placeholder]="'tt.mm.jjjj'"
                        [size]="'md'"
                        [language]="'de'"
                        [dateFormat]="'d.m.Y'"
                        formControlName="executionDate"
                    >
                    </ibm-date-picker>

                    <div class="s-label-12-16-regular s-gray-03 tw-mt-2">
                        {{ 'PAGES.ADD_BANK_ORDER.TEXT_' + paymentType + '_EXECUTION_DATE' | translate }}
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="(flow$ | async) === 'recurring'">
                <div>
                    <ibm-date-picker
                        label="{{ 'ENTITIES.PAYMENT.LABEL_FIRST_EXECUTION_DATE' | translate }}*"
                        id="firstExecutionDate"
                        [placeholder]="'tt.mm.jjjj'"
                        [size]="'md'"
                        [language]="'de'"
                        [dateFormat]="'d.m.Y'"
                        formControlName="firstExecutionDate"
                    >
                    </ibm-date-picker>

                    <div class="s-label-12-16-regular s-gray-03 tw-mt-2">
                        {{ 'PAGES.ADD_BANK_ORDER.DATE_HELPING_TEXT' | translate }}
                    </div>
                </div>

                <div class="tw-mt-8 tw-mb-6">
                    <app-combo-box
                        id="executionRhythm"
                        [invalid]="isInvalidForm('executionRhythm')"
                        invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                        placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT_2' | translate }}"
                        [size]="'md'"
                        [appendInline]="true"
                        [items]="rateIntervalListItems"
                        formControlName="executionRhythm"
                        label="{{ 'ENTITIES.PAYMENT.LABEL_RATE_INTERVAL' | translate }}*"
                        placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT' | translate }}"
                    >
                        <ibm-dropdown-list></ibm-dropdown-list>
                    </app-combo-box>
                </div>

                <div class="tw-my-6">
                    <ibm-checkbox formControlName="unlimitedToEconomicPlan">
                        <span class="ml-0-5r">Unbegrenzt</span>
                    </ibm-checkbox>
                </div>

                <div *ngIf="(lastExecutionDateIsRequiredByFormValues$ | async) === true">
                    <ibm-date-picker
                        label="{{ 'ENTITIES.PAYMENT.LABEL_LAST_EXECUTION_DATE' | translate }}*"
                        id="lastExecutionDate"
                        [placeholder]="'tt.mm.jjjj'"
                        [size]="'md'"
                        [language]="'de'"
                        [dateFormat]="'d.m.Y'"
                        formControlName="lastExecutionDate"
                    >
                    </ibm-date-picker>
                </div>
            </ng-container>
        </form>

        <div class="tw-mt-28 tw-w-full tw-flex tw-justify-between">
            <app-scalara-button (click)="abort()" height="48px" variant="outline">{{
                'CONTROLS.BUTTONS.CANCEL' | translate
            }}</app-scalara-button>

            <app-scalara-button [disabled]="!form.valid" (click)="submit()" height="48px" class="tw-mb-4 md:tw-mb-0">{{
                'CONTROLS.BUTTONS.SAVE' | translate
            }}</app-scalara-button>
        </div>
    </ng-container>
</app-overlay-container>

<ng-template #bankDetails let-bankAccount="bankAccount">
    <div class="tw-flex">
        <div class="tw-bg-scalaraGray-07 tw-mr-4 tw-w-12 tw-h-12 tw-flex tw-items-center tw-justify-center">
            <img
                *ngIf="bankAccount?.bankLogoUrl"
                [src]="bankAccount?.bankLogoUrl"
                alt=""
                class="tw-max-w-full tw-max-h-full tw-p-1"
            />
            <div class="formated-icon" *ngIf="!bankAccount?.bankLogoUrl">
                <svg-icon class="tw-fill-scalaraGray-04" src="/assets/icons/24_bank.svg"></svg-icon>
            </div>
        </div>

        <div>
            <div class="tw-mb-1 s-body-14-22-bold">{{ bankAccount?.name }}</div>
            <div class="s-body-14-20-regular">
                {{ getBankAccountTypeName(bankAccount) }}
                <span class="tw-ml-4">{{ bankAccount?.iban | ibanFormatter }}</span>
            </div>
        </div>
    </div>
</ng-template>
