<ibm-loading *ngIf="isLoading" [isActive]="true" [size]="'normal'" [overlay]="true"></ibm-loading>
<app-overlay-container
    headlineTitle="PAGES.OPERATIONS_EXPENSE_STATEMENTS.OVERVIEW.LABEL_ADD_OPERATIONS_EXPENSE_STATEMENTS"
    [sizeIcon]="72"
    [image]="showConfirmationPage ? '40_information.svg' : '40_document_edit.svg'"
    [colorIcon]="showConfirmationPage ? 'blue' : 'green'"
    containerSize="small"
>
    <div class="s-head-16-24-bold mb-2-5r">
        {{
            (showConfirmationPage
                ? 'PAGES.OPERATIONS_EXPENSE_STATEMENTS.ADD_OPERATIONS_EXPENSE_STATEMENTS.TITLE_CONFIRMATION_OVERLAY'
                : 'PAGES.OPERATIONS_EXPENSE_STATEMENTS.ADD_OPERATIONS_EXPENSE_STATEMENTS.TITLE'
            ) | translate
        }}
    </div>

    <div class="mb-7-5r">
        <div class="s-body-14-22-regular pre-wrap" *ngIf="showConfirmationPage">
            <span>{{
                'PAGES.OPERATIONS_EXPENSE_STATEMENTS.ADD_OPERATIONS_EXPENSE_STATEMENTS.DESCRIPTION_CONFIRMATION_OVERLAY'
                    | translate
            }}</span>
        </div>

        <ng-container *ngIf="!showConfirmationPage">
            <div class="d-flex justify-space-between mb-1-25r" [formGroup]="dateRangeForm">
                <div class="w-100 mr-2r">
                    <ibm-date-picker
                        label="{{ 'ENTITIES.COMMON.LABEL_START_DATE' | translate }}*"
                        id="start"
                        [placeholder]="'tt.mm.jjjj'"
                        [language]="'de'"
                        [dateFormat]="'d.m.Y'"
                        invalidText="Das Startdatum muss vor den Enddatum liegen"
                        formControlName="startDate"
                        [invalid]="isDateInvalid()"
                    >
                    </ibm-date-picker>
                </div>

                <div class="w-100">
                    <ibm-date-picker
                        label="{{ 'ENTITIES.COMMON.LABEL_FINISH_DATE' | translate }}*"
                        id="end"
                        [placeholder]="'tt.mm.jjjj'"
                        [language]="'de'"
                        [dateFormat]="'d.m.Y'"
                        invalidText="Das Startdatum muss vor den Enddatum liegen"
                        formControlName="endDate"
                        [invalid]="isDateInvalid()"
                    >
                    </ibm-date-picker>
                </div>
            </div>
            <div class="s-body-14-22-regular">
                {{
                    'PAGES.OPERATIONS_EXPENSE_STATEMENTS.ADD_OPERATIONS_EXPENSE_STATEMENTS.LABEL_CORRESPONDS_DAYS'
                        | translate : { days: correspondsDays() }
                }}
            </div>

            <div class="mt-2-5r" *ngIf="existStatementForPeriod">
                <app-notification type="warning">
                    <div class="s-head-14-22-regular">
                        {{
                            'PAGES.OPERATIONS_EXPENSE_STATEMENTS.ADD_OPERATIONS_EXPENSE_STATEMENTS.INFO_BOX_ALREADY_EXIST_FOR_PERIOD'
                                | translate
                        }}
                    </div>
                </app-notification>
            </div>
        </ng-container>
    </div>

    <div class="d-flex justify-space-between">
        <app-scalara-button (click)="abort()" [variant]="'outline'">
            {{ 'CONTROLS.BUTTONS.CANCEL' | translate }}
        </app-scalara-button>

        <app-scalara-button
            (click)="showConfirmationPage ? submit() : nextPage()"
            [disabled]="isDateInvalid() || dateRangeForm.invalid"
            [variant]="'default'"
            type="submit"
        >
            {{
                (showConfirmationPage
                    ? 'PAGES.OPERATIONS_EXPENSE_STATEMENTS.ADD_OPERATIONS_EXPENSE_STATEMENTS.BUTTON_CONFIRMATION_OVERLAY'
                    : 'CONTROLS.BUTTONS.CONTINUE'
                ) | translate
            }}
        </app-scalara-button>
    </div>
</app-overlay-container>
