import {
    ApplicationRef,
    ComponentFactoryResolver,
    ComponentRef,
    EmbeddedViewRef,
    Injectable,
    Injector,
    Type,
} from '@angular/core';
import { OverlayComponent } from '../components/overlay/overlay.component';
import { OverlayInjector } from '../utils/overlay-injector';
import { OverlayRef } from '../utils/overlay-ref';
import { OverlayConfig } from '../utils/overlay.config';

@Injectable({
    providedIn: 'root',
})
export class OverlayService {
    private overlayComponentRefs: ComponentRef<OverlayComponent>[] = [];
    private firstClickOutsideIgnored = false;

    public constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector
    ) {}

    public open(componentType: Type<any>, config: OverlayConfig): OverlayRef {
        const overlayRef = this.appendDialogComponentToBody(config);
        const overlayComponentRef = this.overlayComponentRefs[this.overlayComponentRefs.length - 1];
        overlayComponentRef.instance.childComponentType = componentType;
        overlayComponentRef.instance.overlayRef = overlayRef;

        document.getElementsByTagName('html')[0].style.overflowY = 'hidden';

        this.firstClickOutsideIgnored = false;

        return overlayRef;
    }

    public getMountedComponent<T>(): ComponentRef<any> | undefined {
        if (this.overlayComponentRefs.length > 0) {
            return this.overlayComponentRefs[this.overlayComponentRefs.length - 1].instance.componentRef;
        }
        return undefined;
    }

    private appendDialogComponentToBody(config: OverlayConfig): OverlayRef {
        const map = new WeakMap();
        map.set(OverlayConfig, config);

        const overlayRef = new OverlayRef();
        map.set(OverlayRef, overlayRef);

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(OverlayComponent);
        const overlayComponentRef = componentFactory.create(new OverlayInjector(this.injector, map));
        overlayComponentRef.instance.config = config;
        overlayComponentRef.instance.overlayRef = overlayRef;

        this.appRef.attachView(overlayComponentRef.hostView);

        const domElem = (overlayComponentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
        document.getElementById('overlay-container')!.appendChild(domElem);

        this.overlayComponentRefs.push(overlayComponentRef);

        overlayRef.afterClosed.subscribe(() => {
            this.removeOverlayComponentFromBody(overlayRef);
        });

        return overlayRef;
    }

    public removeOverlayComponentFromBody(overlayRef?: OverlayRef): void {
        if (!overlayRef) {
            overlayRef = this.getCurrentOverlayRef();
        }
        const overlayComponentRefIndex = this.overlayComponentRefs.findIndex(
            (ref) => ref.instance.overlayRef === overlayRef
        );

        if (overlayComponentRefIndex !== -1) {
            const overlayComponentRef = this.overlayComponentRefs[overlayComponentRefIndex];
            this.appRef.detachView(overlayComponentRef.hostView);
            overlayComponentRef.destroy();
            this.overlayComponentRefs.splice(overlayComponentRefIndex, 1);
        }

        if (this.overlayComponentRefs.length === 0) {
            document.getElementsByTagName('html')[0].style.overflowY = 'visible';
        }
    }

    public getOverlayCount(): number {
        return this.overlayComponentRefs.length;
    }

    public closeOverlay(): boolean {
        if (
            this.overlayComponentRefs.length > 1 ||
            (this.overlayComponentRefs.length === 0 &&
                this.overlayComponentRefs[0].instance.config?.overlayType !== 'half-page')
        ) {
            this.firstClickOutsideIgnored = false;
            return false;
        }

        if (!this.firstClickOutsideIgnored) {
            this.firstClickOutsideIgnored = true;
            return false;
        }

        return true;
    }

    public getCurrentOverlayRef(): OverlayRef | undefined {
        if (this.overlayComponentRefs.length > 0) {
            return this.overlayComponentRefs[this.overlayComponentRefs.length - 1].instance.overlayRef;
        }
        return undefined;
    }
}
