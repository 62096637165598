import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
    BehaviorSubject,
    Observable,
    ObservedValueOf,
    Subject,
    combineLatest,
    distinctUntilChanged,
    filter,
    map,
    of,
    shareReplay,
    startWith,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs';
import { ResolveType } from 'src/app/core/models/ts-utils';
import { getFileCategory } from 'src/app/core/utils/common';
import { TooltipKey } from 'src/app/features/account-settings/services/custom-tooltip.service';
import { OccupationService, OwnershipsService, PropertiesService } from 'src/app/generated-sources/base';
import { FilesService } from 'src/app/generated-sources/file';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { TableItem } from 'src/app/shared/table/interfaces/table-item';
import { FiletoView } from '../../../../../shared/components/file-viewer/file-viewer.component';
import { CellTemplate } from '../../../../../shared/table/enums/cell-template';
import { TableModel } from '../../../../../shared/table/interfaces/table-model';
import { DeleteDocumentComponent } from '../../delete-document/delete-document.component';
import {
    AddDocumentConfig,
    AddDocumentOverlayComponent,
    DocumentsFlow,
    OptionalDocumentsData,
} from '../add-document-overlay/add-document-overlay.component';

//  ownership is needed in add-document, but not here
//  file-reference enum is needed in add-document, but here not always (fetching all files for property for example - there we do not call add-document)
export type InputData = ResolveType<Exclude<OptionalDocumentsData, 'ownershipId'>>;

@Component({
    selector: 'app-document-table',
    templateUrl: './document-table.component.html',
    styleUrls: ['./document-table.component.scss'],
})
export class DocumentTableComponent implements OnInit, OnChanges {
    public showModal = false;
    private unsubscribe$ = new Subject<void>();

    @Input() public showButton = true;
    @Input() public title? = '';
    @Input() public tooltipInfo?: { tooltipKey: TooltipKey; tooltipValue: string };

    @Input() public inputData?: InputData;

    public refresh$ = new BehaviorSubject<void>(undefined);
    public inputData$ = new BehaviorSubject<InputData | null>(null);

    public propertyId$ = this.route.url.pipe(
        map(() => {
            if (this.vm) {
                this.vm.isLoading = true;
            }

            const snapshot = this.route.snapshot;
            return snapshot.parent?.paramMap.get('id');
        }),
        filter(Boolean),
        distinctUntilChanged()
    );

    public ownershipId$ = combineLatest([this.route.url, this.inputData$]).pipe(
        map(([_, inputData]) => {
            if (this.vm) {
                this.vm.isLoading = true;
            }
            const designatedOwnershipId = inputData && inputData.flow === 'ownership' ? inputData?.ownershipId : null;
            const snapshot = this.route.snapshot;
            //  get ownershipId from input data (preferred) or from route
            return designatedOwnershipId ?? snapshot.paramMap.get('ownershipId');
        }),
        distinctUntilChanged()
    );

    public filesState$ = this.refresh$.pipe(
        switchMap(() => {
            if (this.vm) {
                this.vm.isLoading = true;
            }
            return combineLatest([this.inputData$, this.propertyId$, this.ownershipId$]).pipe(
                switchMap(([inputData, propertyId, ownershipId]) => {
                    if (inputData?.flow === DocumentsFlow.property && propertyId) {
                        return this.propertiesService.findPropertyFiles(propertyId, inputData.fileReferenceEnum);
                    }
                    const isOccupationFlow =
                        DocumentsFlow.occupation === inputData?.flow ||
                        DocumentsFlow.occupationForm === inputData?.flow;
                    if (isOccupationFlow && inputData.occupationId && propertyId) {
                        const occupationObs = this.occupationService.findOccupationFiles(
                            propertyId,
                            inputData.occupationId
                        );
                        const occupationFormObs = this.occupationService.findOccupationFormFiles(
                            propertyId,
                            inputData.occupationId
                        );
                        return inputData.flow === DocumentsFlow.occupation ? occupationObs : occupationFormObs;
                    }
                    if (inputData && propertyId && ownershipId && inputData.flow === DocumentsFlow.ownership) {
                        return this.ownershipService.findOwnershipFiles(propertyId, ownershipId);
                    }
                    console.warn('something went wrong, check inputs');
                    return of([]);
                })
            );
        }),
        map((files) => ({ isLoading: false, files })),
        startWith({ isLoading: true, files: [] }),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    public tableModel$: Observable<TableModel | null> = combineLatest([this.inputData$, this.filesState$]).pipe(
        map(([inputData, { files }]) => {
            //  show for all cases but not properties and ownerhips
            if (!inputData) {
                return null;
            }
            const showReferenceColumn = true;
            // Boolean(
            //     ![DocumentsFlow.ownership, DocumentsFlow.property].includes(inputData.flow)
            // ); // TODO: check this logic with BE

            const header: TableModel['header'] = [
                'ENTITIES.DOCUMENT.DOCUMENT_NAME',
                'ENTITIES.DOCUMENT.CATEGORY',
                ...(showReferenceColumn ? ['ENTITIES.DOCUMENT.REFERENCE'] : []),
                'ENTITIES.DOCUMENT.UPLOADED_AT',
                '',
            ];
            const tableData: TableModel['data'] = files.map((file) =>
                this.createRow({ data: file, showFileReference: showReferenceColumn })
            );
            return { header, data: tableData };
        })
    );

    public vm$ = combineLatest([
        this.tableModel$,
        this.propertyId$,
        this.ownershipId$,
        this.inputData$,
        this.filesState$,
    ]).pipe(
        map(([tableModel, propertyId, ownershipId, inputData, filesState]) => {
            return {
                tableModel,
                propertyId,
                ownershipId,
                inputData,
                isLoading: filesState.isLoading,
            };
        })
    );

    public vm: ObservedValueOf<typeof this.vm$> | null = null;

    public constructor(
        private filesService: FilesService,
        private overlayService: OverlayService,
        private route: ActivatedRoute,
        private translateService: TranslateService,
        private propertiesService: PropertiesService,
        private ownershipService: OwnershipsService,
        private occupationService: OccupationService
    ) {}

    public fileToView?: FiletoView;

    public ngOnInit(): void {
        this.title = this.translateService.instant(this.title ? this.title : 'PAGES.DOCUMENTS.TABLE_TITLE');

        //  small hack to update loading spinner on refresh
        this.refresh$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            if (this.vm) {
                this.vm.isLoading = false;
            }
        });

        this.vm$.pipe(takeUntil(this.unsubscribe$)).subscribe((vm) => {
            this.vm = vm;
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['inputData'] && changes['inputData'].currentValue) {
            this.inputData$.next(changes['inputData'].currentValue);
        }
    }

    private createRow({ data, showFileReference }: { data: any; showFileReference?: boolean }): TableItem[] {
        const row = [
            {
                data: {
                    label: data.fileName ?? data.filename, //TODO: BE should be fixed to use ONLY fileName instead filename
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: getFileCategory(this.translateService, data.fileCategory),
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: data.createdAt,
                },
                template: CellTemplate.Date,
            },
            {
                data: {
                    label: '',
                    extraData: { ...data, showOnlyWithHover: true },
                },
                template: CellTemplate.Actions,
            },
        ];

        if (showFileReference) {
            let fileReference = data.ownershipName || data.fileReference;

            if (!fileReference) {
                fileReference = '';
            } else {
                if (data.ownershipName) {
                    fileReference = `Einheit ${data.ownershipName}`;
                } else {
                    fileReference = data.fileReference === 'BASIC_DATA' ? 'Basisdaten' : 'Einheiten';
                }
            }

            row.splice(2, 0, {
                data: {
                    label: fileReference,
                },
                template: CellTemplate.Default,
            });
        }

        return row;
    }

    public handleActions($event: any): void {
        const fileName = $event.data.extraData.fileName ?? $event.data.extraData.filename;
        this.filesService
            .getDownloadLink($event.data.extraData.fileStorageId)
            .pipe(
                tap((data: any) => {
                    if ($event.action === 'download') {
                        const link = document.createElement('a');
                        link.href = data.url;
                        link.download = fileName;
                        link.click();
                    } else if ($event.action === 'view') {
                        this.fileToView = { fileName: fileName, file: data.url };
                        this.showModal = true;
                    } else if ($event.action === 'delete') {
                        this.openDeleteDocumentOverlay(fileName, $event.data.extraData.id);
                    }
                })
            )
            .subscribe();
    }

    public handleModal(): void {
        this.showModal = !this.showModal;
    }

    public openAddDocumentOverlay(): void {
        if (!this.vm || !this.vm.inputData) {
            console.warn('vm is not defined');
            return;
        }
        const optionalData = {
            ...this.vm.inputData,
            ...(this.vm.inputData.flow === DocumentsFlow.ownership ? { ownershipId: this.vm.ownershipId ?? '' } : {}),
        };
        if (this.vm.inputData.flow === DocumentsFlow.ownership && !optionalData.ownershipId) {
            console.warn('ownershipId is not defined, there could be an error in the flow');
            return;
        }
        if (optionalData.flow === DocumentsFlow.property && !optionalData?.fileReferenceEnum) {
            console.warn('fileReferenceEnum is not defined, there could be an error in the flow');
            return;
        }

        const addDocumentConfig: AddDocumentConfig = {
            data: {
                propertyId: this.vm.propertyId,
                ...optionalData,
            },
        };

        const ref = this.overlayService.open(AddDocumentOverlayComponent, addDocumentConfig);

        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.refresh$.next());
    }

    public openDeleteDocumentOverlay(fileName: string, id: string): void {
        const ref = this.overlayService.open(DeleteDocumentComponent, { data: { fileName, id } });

        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.refresh$.next());
    }
}
