import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { controlInFormGroupIsInvalid } from 'src/app/core/utils/formValidationHelpers';
import {
    Building,
    BuildingsService,
    CreateAddressDto,
    CreateBuildingDto,
    Property,
} from 'src/app/generated-sources/base';
import { AutocompletePlaceSelectedItem } from 'src/app/shared/components/scalara-input-address-autocomplete/scalara-input-address-autocomplete.component';
import { Country } from 'src/app/shared/country';
import { countries } from 'src/app/shared/country-data';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';

@Component({
    selector: 'app-add-edit-building-overlay',
    templateUrl: './add-edit-building-overlay.component.html',
    styleUrls: ['./add-edit-building-overlay.component.scss'],
})
export class AddEditBuildingOverlayComponent extends OverlayChildComponent implements OnInit, OnDestroy {
    public isLoading = false;
    public form = this.formBuilder.group({
        id: [''],
        name: ['', [Validators.required]],
        streetName: ['', [Validators.required]],
        streetNumber: ['', [Validators.required]],
        streetAddition: [''],
        zipCode: ['', [Validators.required]],
        area: ['', [Validators.required]],
        country: ['', [Validators.required]],
        propertyDetailArea: [],
        district: [],
        consecutiveNumberOfProperties: [],
        cadastralDistrict: [],
        landParcel: [],
        economicType: [],
        constructionYear: [undefined, [Validators.min(1000), Validators.max(2099), Validators.pattern('^[0-9]*$')]],
        plotArea: [],
        floorCount: [],
    });

    private unsubscribe$ = new Subject<void>();

    public propertyType = '';
    public countries: Country[] = countries;
    public propertyId = '';

    public buildingToEdit?: Building;

    public constructor(
        private formBuilder: UntypedFormBuilder,
        private buildingsService: BuildingsService,
        private toastService: ToastService,
        private cd: ChangeDetectorRef,
        private translateService: TranslateService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.propertyId = this.config?.data.propertyId;
        this.buildingToEdit = this.config?.data.buildingToEdit;

        if (!this.buildingToEdit && this.config?.data.property) {
            const propertyAddress = (this.config?.data.property as Property)?.address;

            this.form.patchValue({
                streetName: propertyAddress?.streetName || '',
                streetNumber: propertyAddress?.streetNumber || '',
                streetAddition: propertyAddress?.streetAddition || '',
                zipCode: propertyAddress?.zipCode || '',
                area: propertyAddress?.area || '',
                country: propertyAddress?.country || '',
            });
        }

        if (this.buildingToEdit) {
            const address = this.buildingToEdit.address as any;
            this.form.patchValue({
                streetName: address?.streetName || '',
                streetNumber: address?.streetNumber || '',
                streetAddition: address?.streetAddition || '',
                zipCode: address?.zipCode || '',
                area: address?.area || '',
                country: address?.country || '',
                name: this.buildingToEdit.name,
                plotArea: this.buildingToEdit?.plotArea,
                district: this.buildingToEdit?.district,
                consecutiveNumberOfProperties: this.buildingToEdit?.consecutiveNumberOfProperties,
                cadastralDistrict: this.buildingToEdit?.cadastralDistrict,
                landParcel: this.buildingToEdit?.landParcel,
                economicType: this.buildingToEdit?.economicType,
                constructionYear: this.buildingToEdit?.constructionYear,
                floorCount: this.buildingToEdit?.floorCount,
            });
        }
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public populateAddress(): CreateAddressDto {
        return {
            streetName: this.form.get('streetName')?.value,
            streetNumber: this.form.get('streetNumber')?.value,
            streetAddition: this.form.get('streetAddition')?.value,
            zipCode: this.form.get('zipCode')?.value,
            area: this.form.get('area')?.value,
            country: this.form.get('country')?.value,
        };
    }

    public onSubmit(): void {
        this.isLoading = true;

        const createBuildingDto: CreateBuildingDto = {
            name: this.form.get('name')?.value,
            address: this.populateAddress(),
            plotArea: this.form.get('plotArea')?.value ?? undefined,
            district: this.form.get('district')?.value ?? undefined,
            consecutiveNumberOfProperties: this.form.get('consecutiveNumberOfProperties')?.value ?? undefined,
            cadastralDistrict: this.form.get('cadastralDistrict')?.value ?? undefined,
            landParcel: this.form.get('landParcel')?.value ?? undefined,
            economicType: this.form.get('economicType')?.value ?? undefined,
            constructionYear: this.form.get('constructionYear')?.value ?? undefined,
            floorCount: this.form.get('floorCount')?.value ?? undefined,
        };

        (this.buildingToEdit
            ? this.buildingsService.update(this.propertyId, this.buildingToEdit.id, createBuildingDto)
            : this.buildingsService.create(this.propertyId, createBuildingDto)
        )
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: () => {
                    this.toastService.showSuccess(
                        this.translateService.instant(
                            this.buildingToEdit
                                ? 'PAGES.ADD_EDIT_BUILDING.EDIT_BUILDING_SUCCESS_TOAST'
                                : 'PAGES.ADD_EDIT_BUILDING.ADD_BUILDING_SUCCESS_TOAST'
                        )
                    );
                    this.saveEmitter$.next();
                    this.isLoading = false;
                },
                error: (error) => {
                    if (error) {
                        this.toastService.showError(error.error['message']);
                    }
                    this.saveEmitter$.next();
                    this.isLoading = false;
                },
            });
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public onAutocompletePlaceSelected(item: AutocompletePlaceSelectedItem): void {
        this.form.patchValue({
            streetName: item.formData.streetName,
            streetNumber: item.formData.streetNumber,
            zipCode: item.formData.postalCode,
            area: item.formData.city,
            country: item.formData.countryCode,
        });
        const formControlsLabels = ['streetName', 'streetNumber', 'zipCode', 'area', 'country'];
        formControlsLabels.forEach((label) => {
            this.form.controls[label]?.markAsTouched();
            this.form.controls[label]?.markAsDirty();
            this.form.controls[label]?.updateValueAndValidity();
        });
        this.cd.detectChanges();
    }

    public controlInFormGroupIsInvalid = controlInFormGroupIsInvalid;
}
