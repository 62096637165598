import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, finalize, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { ReceiptsService } from 'src/app/generated-sources/accounting';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';

@Component({
    selector: 'app-receipts-delete-receipt-overlay',
    templateUrl: './receipts-delete-receipt-overlay.component.html',
    styleUrls: ['./receipts-delete-receipt-overlay.component.scss'],
})
export class ReceiptsDeleteReceiptOverlayComponent extends OverlayChildComponent implements OnInit {
    private unsubscribe$ = new Subject<void>();
    public ledgerId = '';
    public receiptId = '';
    public fileName = '';
    public isLoading = false;
    public constructor(
        private receiptsService: ReceiptsService,
        private toastService: ToastService,
        private translateService: TranslateService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.isLoading = true;
        this.ledgerId = this.config?.data.ledgerId;
        this.receiptId = this.config?.data.id;
        this.fileName = this.config?.data.fileName;
        this.isLoading = false;
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public submit(): void {
        this.isLoading = true;
        this.receiptsService
            .deleteReceipts(this.ledgerId, [this.receiptId])
            .pipe(
                takeUntil(this.unsubscribe$),
                finalize(() => (this.isLoading = false))
            )
            .subscribe({
                next: () => {
                    this.toastService.showSuccess(this.translateService.instant('PAGES.DELETE_RECEIPT.SUCCESS_TOAST'));
                    this.saveEmitter$.next();
                },
                error: (error) => {
                    if (error) {
                        this.toastService.showError(error.error['message']);
                    }
                },
            });
    }
}
