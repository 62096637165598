<app-overlay-container
    headlineTitle="PAGES.TICKET_DETAILS.ADD_SERVICE_PROVIDER"
    [sizeIcon]="72"
    image="40_add-person.svg"
    colorIcon="green"
    containerSize="small"
>
    <div class="mb-3-5r">
        <app-table-multi-selector
            [header]="relatedOWnershipsTableHeader"
            [data]="relatedOwnershipsTable"
            emptyText="PAGES.RELATIONS.SERVICE_PROVIDER.LABEL_EMPTY_TABLE"
            #TableMultiSelectorComponent
            (rowSelected)="onRowClick($event)"
        >
        </app-table-multi-selector>
    </div>

    <form [formGroup]="form">
        <div class="mb-3r">
            <app-combo-box
                size="md"
                id="serviceProvider"
                placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT' | translate }}"
                [appendInline]="true"
                label="Dienstleister aus allen Kontakten auswählen"
                [items]="serviceProviderList"
                formControlName="serviceProvider"
                invalidText="{{ 'ACCOUNTING.ERROR.INVALID_AMOUNT' | translate }}"
            >
                <ibm-dropdown-list></ibm-dropdown-list>
            </app-combo-box>
        </div>

        <ibm-label>
            Kommentar
            <textarea
                ibmTextArea
                rows="3"
                aria-label="textarea"
                maxlength="100000"
                formControlName="comment"
            ></textarea>
        </ibm-label>
    </form>

    <div class="d-flex justify-space-between mt-7-5r">
        <app-scalara-button (click)="onCancel()" variant="outline" [fullWidth]="true">{{
            'CONTROLS.BUTTONS.CANCEL' | translate
        }}</app-scalara-button>
        <app-scalara-button type="button" (click)="onSubmit()" [fullWidth]="true" variant="default">{{
            'CONTROLS.BUTTONS.ADD' | translate
        }}</app-scalara-button>
    </div>
</app-overlay-container>

<!-- custom templates  -->
<ng-template #serviceProviderItem let-data="data">
    <div class="d-flex align-items-center px-0-75r py-0-5r">
        <app-headline-with-icon
            [color]="data.extraData?.person?.hasRegisteredUser ? 'green' : 'gray'"
            image="24_person.svg"
            [text]="getNameFromPerson(data.extraData?.person)"
            textType="body"
            borderColor="gray"
            [userAvatar]="data.extraData?.person.imgSmall ?? undefined"
        ></app-headline-with-icon>
        <div>
            {{ data.extraData?.serviceProvided }}
        </div>
    </div>
</ng-template>
