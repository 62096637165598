<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<div class="tw-mt-6 tw-mb-20 tw-flex tw-justify-between">
    <ibm-breadcrumb [items]="breadcrumbs" [noTrailingSlash]="true"> </ibm-breadcrumb>
    <div *ngIf="isPropertyManager" class="tw-flex">
        <app-scalara-button variant="ghost" (click)="openDeleteEntityOverlay()" svgName="24_delete.svg">
            {{ 'CONTROLS.BUTTONS.REMOVE' | translate }}
        </app-scalara-button>
        <app-scalara-button variant="ghost" (click)="openEditComponent()" svgName="24_edit.svg">
            {{ 'COMMON.EDIT' | translate }}
        </app-scalara-button>
    </div>
</div>

<div class="tw-mb-8 tw-flex tw-justify-between">
    <div>
        <div class="tw-s-label-12-22-semibold tw-text-scalaraGray-03 tw-mb-2">Bezeichnung</div>

        <div class="tw-s-head-18-26-bold">{{ unit?.name }}</div>
    </div>

    <ng-container *ngIf="currentOccupationMVProperty">
        <a
            [class.info-box__empty]="currentOccupationMVProperty.occupationType === 'VACANCY'"
            class="info-box px-1r d-flex align-items-center justify-space-between"
            [href]="
                '/properties/' +
                propertyId +
                '/occupations/' +
                currentOccupationMVProperty.id +
                (currentOccupationMVProperty.occupationNumber ? '/commit' : '/draft')
            "
        >
            <div class="d-flex">
                <span
                    class="s-label-12-22-semibold s-green-01 d-flex mr-1r"
                    [class.s-gray-03]="currentOccupationMVProperty.occupationType === 'VACANCY'"
                >
                    <span class="mr-0-75r">
                        <svg-icon
                            class="formated-icon"
                            [class.formated-icon__empty]="currentOccupationMVProperty.occupationType === 'VACANCY'"
                            [src]="
                                currentOccupationMVProperty.occupationType === 'VACANCY'
                                    ? '/assets/icons/24_open.svg'
                                    : '/assets/icons/24_closed.svg'
                            "
                        ></svg-icon
                    ></span>
                    {{
                        getOccupationTypeLabel(
                            currentOccupationMVProperty.occupationType,
                            !!currentOccupationMVProperty.rentDetails?.rentType
                        )
                    }}</span
                >
                <span class="s-body-14-22-regular s-gray-01">{{ currentOccupationMVProperty.occupationNumber }}</span>
            </div>
            <svg-icon class="formated-icon info-box-button" src="/assets/icons/24_arrow-right.svg"></svg-icon>
        </a>
    </ng-container>
</div>

<div class="tw-grid tw-grid-cols-4 tw-mb-20">
    <div class="tw-col-start-1 tw-s-label-12-22-semibold tw-text-scalaraGray-03 tw-space-y-3">
        <div>{{ 'ENTITIES.OWNERSHIP.LABEL_FRACTION_LONG' | translate }}</div>
        <div>{{ 'ENTITIES.OWNERSHIP.LABEL_BUILDING' | translate }}</div>
        <div>{{ 'ENTITIES.OWNERSHIP.LABEL_LOCATION' | translate }}</div>
        <div>{{ 'ENTITIES.OWNERSHIP.LABEL_ROOM' | translate }}</div>
        <div>{{ 'ENTITIES.OWNERSHIP.LABEL_SQUARE_METERS' | translate }}</div>
        <div>{{ 'ENTITIES.OWNERSHIP.LABEL_HEATED_SQUARE_METERS' | translate }}</div>
        <div>{{ 'ENTITIES.OWNERSHIP.LABEL_CONDITION' | translate }}</div>
        <div>{{ 'ENTITIES.OWNERSHIP.LABEL_INVENTORY' | translate }}</div>
    </div>

    <div class="tw-col-start-2 tw-s-body-14-22-regular tw-space-y-3">
        <div *ngIf="unit?.fraction || unit?.fraction === 0; else notSpecified">
            {{ unit?.fraction | number : '0.1-5' : 'de-DE' }}
        </div>

        <div *ngIf="unit?.buildingName; else notSpecified">
            {{ unit?.buildingName }}
        </div>

        <div *ngIf="unit?.location; else notSpecified">
            {{ unit?.location }}
        </div>

        <div *ngIf="unit?.roomCount; else notSpecified">
            {{ unit?.roomCount }}
        </div>

        <div *ngIf="unit?.squaremeters || unit?.squaremeters === 0; else notSpecified">
            {{ unit?.squaremeters | number : '' : 'de-DE' }}
        </div>

        <div *ngIf="unit?.heatedSquaremeters || unit?.heatedSquaremeters === 0; else notSpecified">
            {{ unit?.heatedSquaremeters | number : '' : 'de-DE' }}
        </div>

        <div *ngIf="unit?.condition; else notSpecified">
            {{ unit?.condition }}
        </div>

        <div *ngIf="unit?.inventory; else notSpecified">
            {{ unit?.inventory }}
        </div>
    </div>
</div>

<div class="tw-mb-20" *ngIf="currentTenants?.length! > 0">
    <div class="tw-mb-6 tw-flex">
        <div class="tw-s-head-14-22-bold">Aktuelle Mieter</div>

        <app-tooltip class="tw-ml-4">
            <div content class="tw-w-[575px] tw-p-8 tw-s-head-14-22-regular">
                Um einen Mieter hinzuzufügen, gehen Sie zu Mietverwaltung.
            </div>
            <div hoverable-item class="tw-flex">
                <svg-icon src="/assets/icons/24_information.svg" class="formated-icon formated-icon__gray"></svg-icon>
            </div>
        </app-tooltip>
    </div>
    <div *ngFor="let tenant of currentTenants">
        <ng-container *ngIf="tenant.person">
            <div class="tw-mb-3 tw-flex tw-items-center">
                <app-headline-with-icon
                    [color]="tenant.person && tenant.person.hasRegisteredUser ? 'green' : 'gray'"
                    image="24_person.svg"
                    [text]="getNameFromPerson(tenant.person)"
                    textType="body"
                    textWeight="regular"
                    borderColor="gray"
                ></app-headline-with-icon>
                <div class="tw-ml-4 tw-s-body-14-20-regular tw-text-scalaraGray-03">
                    {{ getTypeUnitName(tenant.unit) + ', ' + tenant.unit.name }}
                </div>
            </div>
        </ng-container>
    </div>
</div>

<div class="tw-mb-20">
    <app-additional-information-input
        [additionalInformation]="unit?.additionalInformation"
        (updateAdditionalInformation)="updateAdditionalInformation($event)"
    ></app-additional-information-input>
</div>

<div>
    <app-document-table
        [showButton]="isPropertyManager"
        [tooltipInfo]="tooltipInfoDocuments"
        [inputData]="{
            flow: DocumentsFlow.ownership,
            ownershipId: unit?.designatedOwnershipId ?? ''
        }"
    ></app-document-table>
</div>

<ng-template #notSpecified>
    <div class="s-body-14-22-italic">
        {{ 'PAGES.OWNERSHIP.DETAIL_VIEW.LABEL_NOT_SPECIFIED' | translate }}
    </div>
</ng-template>
