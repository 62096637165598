import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbItem } from 'carbon-components-angular';
import {
    BehaviorSubject,
    Subject,
    combineLatest,
    distinctUntilChanged,
    filter,
    firstValueFrom,
    map,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { getNameFromPerson, unitTypeNameFromEnum } from 'src/app/core/utils/common';
import { TooltipKey } from 'src/app/features/account-settings/services/custom-tooltip.service';
import { OwnershipUnitDetailsOverlayComponent } from 'src/app/features/property/components/unit-ownership/ownership-unit-details-overlay/ownership-unit-details-overlay.component';
import {
    Occupation,
    OccupationService,
    Ownership,
    OwnershipsService,
    Person,
    PersonCountDto,
    SharedOwnership,
    SharedOwnershipService,
    Unit,
    UnitsService,
} from 'src/app/generated-sources/base';
import { PersonAmountCallbacks } from 'src/app/shared/components/person-amount-and-time-frame-table/person-amount-and-time-frame-table.component';
import {
    RemoveEntityOverlayComponent,
    RemoveEntityOverlayComponentConfig,
} from 'src/app/shared/components/remove-entity-overlay/remove-entity-overlay.component';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { TableComponent } from 'src/app/shared/table/components/table/table.component';
import { CellTemplate } from 'src/app/shared/table/enums/cell-template';
import { TableItem } from 'src/app/shared/table/interfaces/table-item';
import { TableModel } from 'src/app/shared/table/interfaces/table-model';
import { PropertyCustomService } from '../../../services/property-custom.service';
import { DocumentsFlow } from '../../documents/add-document-overlay/add-document-overlay.component';
import { AddEditOwnershipFormComponent } from '../add-edit-ownership-form/add-edit-ownership-form.component';

interface UnitWithBuilding extends Unit {
    building?: string;
}

@Component({
    selector: 'app-weg-ownership-details',
    templateUrl: './weg-ownership-details.component.html',
    styleUrls: ['./weg-ownership-details.component.scss'],
})
export class WegOwnershipDetailsComponent implements OnInit, OnDestroy {
    public isLoading = false;
    public breadcrumbs: BreadcrumbItem[] = [];
    public isPropertyManager = false;
    public ownership?: Ownership;
    public propertyId = '';
    public units: UnitWithBuilding[] = [];
    public currentTenants?: { person: Person; unit: Unit }[] = [];
    public sharedOwnershipTable: TableModel = { data: [], header: [] };
    public ownershipUnitsTable: TableModel = { data: [], header: [] };

    private unsubscribe$ = new Subject<void>();
    private refreshOwnership$ = new BehaviorSubject<void>(undefined);
    private refreshSharedOwnership$ = new BehaviorSubject<void>(undefined);

    @ViewChild(TableComponent)
    public tableComponent?: TableComponent;

    public propertyId$ = this.propertyCustomService.getPropertyId$().pipe(filter(Boolean));

    public ownershipId$ = this.route.url.pipe(
        map(() => {
            const ownershipId = this.route.snapshot.paramMap.get('ownershipId');
            return ownershipId;
        }),
        filter(Boolean),
        distinctUntilChanged()
    );

    public ownerships$ = combineLatest([this.propertyId$, this.ownershipId$, this.refreshOwnership$]).pipe(
        switchMap(([propertyId, ownershipId]) => {
            this.isLoading = true;
            this.propertyId = propertyId;
            return this.ownershipsService.findOne(propertyId, ownershipId);
        }),
        tap((ownership) => {
            this.ownership = ownership;
            this.isPropertyManager = Object.values(ownership.permissionRoles).includes(
                Person.PermissionRolesEnum.PropertyManager
            );
        }),
        switchMap(() => {
            return this.unitsService.findAll(this.propertyId, this.ownership?.id ?? '');
        }),
        tap((units) => {
            this.units = units;

            this.ownershipUnitsTable.header = [
                'ENTITIES.UNIT.LABEL_UNIT_TYPE',
                'PROPERTY.COMMON.DESCRIPTION',
                'ENTITIES.OWNERSHIP.LABEL_BUILDING',
                'ENTITIES.OWNERSHIP.LABEL_LOCATION',
            ];

            this.ownershipUnitsTable.data = this.units.map((unit) => this.createOwnershipUnitTableRow(unit));
        }),
        takeUntil(this.unsubscribe$)
    );

    public sharedOwnership$ = combineLatest([this.propertyId$, this.ownershipId$, this.refreshSharedOwnership$]).pipe(
        switchMap(([propertyId, ownershipId]) => {
            return this.sharedOwnershipService.getSharedOwnershipForOwnership(propertyId, ownershipId);
        }),
        tap((sharedOwnerhip) => {
            this.sharedOwnershipTable.header = [
                'ENTITIES.SHARED_OWNERSHIP.LABEL_CATEGORY',
                'ENTITIES.PROPERTY.LABEL_NAME',
                'ENTITIES.OWNERSHIP.LABEL_BUILDING',
                'ENTITIES.OWNERSHIP.LABEL_ENTITY',
            ];
            this.sharedOwnershipTable.data = sharedOwnerhip.map((item) => this.createSharedOwnershipTableRow(item));
        }),
        takeUntil(this.unsubscribe$)
    );

    // get occupation to show all currents tenants in the ownership
    public occupation$ = combineLatest([this.propertyId$, this.ownershipId$]).pipe(
        switchMap(([propertyId, ownershipId]) => {
            return this.occupationService.findAllOccupationsForExactOwnershipSet(propertyId, ownershipId);
        }),
        tap((occupation) => {
            const uniqueIDs: any = {};

            occupation.reduce((result: any, currentOccupation: Occupation) => {
                currentOccupation.unitsIncluded.forEach((unit: Unit) => {
                    if (!uniqueIDs[unit.id]) {
                        uniqueIDs[unit.id] = true;
                        if (!result[unit.id]) {
                            occupation.map((item) => {
                                const findUnit = item.unitsIncluded.find((item) => item.id === unit.id);
                                if (findUnit) {
                                    const currentDate = new Date().getTime();
                                    const from = new Date(item.from).getTime();
                                    const to = item.to ? new Date(item.to).getTime() : null;

                                    if (
                                        (to && currentDate >= from && currentDate <= to) ||
                                        (!to && currentDate >= from)
                                    ) {
                                        const tenants = item.tenants.map((item) => {
                                            return { person: item, unit: unit };
                                        });
                                        this.currentTenants?.push(...tenants);
                                    }
                                }
                            });
                        }
                    }
                });
                return result;
            }, {});
        })
    );

    public personAmountCallbacks: PersonAmountCallbacks = {
        addPersonsAmountCallback: async (personCountObj: PersonCountDto) => {
            //occupation-servie.post
            //unit-service.post
            if (this.ownership?.id) {
                await firstValueFrom(
                    this.ownershipsService.addPersonCount(this.propertyId, this.ownership?.id, personCountObj)
                );
            }
            this.refreshOwnership$.next();
        },
        editPersonsAmountCallback: async (personCountObj: PersonCountDto) => {
            if (this.ownership?.id && personCountObj.id) {
                await firstValueFrom(
                    this.ownershipsService.updatePersonCount(
                        this.propertyId,
                        this.ownership?.id,
                        personCountObj.id,
                        personCountObj
                    )
                );
                this.refreshOwnership$.next();
            }
            console.log('Edited the person and Saved' + JSON.stringify(personCountObj));
            return;
        },
        deletePersonsAmountCallback: async (id: string) => {
            if (this.ownership?.id) {
                try {
                    await firstValueFrom(
                        this.ownershipsService.removePersonCount(this.propertyId, this.ownership?.id, id)
                    );
                    this.refreshOwnership$.next();
                } catch (e) {
                    this.toastService.showError((e as HttpErrorResponse).error.message);
                }
            }
            return;
        },
    };

    public constructor(
        private overlayService: OverlayService,
        private ownershipsService: OwnershipsService,
        private router: Router,
        private route: ActivatedRoute,
        private translateService: TranslateService,
        private propertyCustomService: PropertyCustomService,
        private unitsService: UnitsService,
        private sharedOwnershipService: SharedOwnershipService,
        private occupationService: OccupationService,
        private toastService: ToastService
    ) {}

    public ngOnInit(): void {
        this.ownerships$.subscribe(() => {
            this.breadcrumbs = [
                {
                    content: this.translateService.instant('PROPERTY.COMMON.UNITS'),
                    route: [`/properties/${this.propertyId}/ownerships`],
                },
                {
                    content: this.ownership?.name ?? '',
                    route: [`/properties/${this.propertyId}/ownerships/${this.ownership?.id}`],
                    current: true,
                },
            ];
            this.isLoading = false;
        });
        this.sharedOwnership$.subscribe();
        this.occupation$.subscribe();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public openDeleteEntityOverlay(): void {
        const data: RemoveEntityOverlayComponentConfig = {
            data: {
                entityName: this.ownership?.name ?? '',
                entityType: 'OWNERSHIP',
                deleteEntityCallback: async () => {
                    await firstValueFrom(this.ownershipsService.remove(this.propertyId, this.ownership?.id || ''));
                },
            },
        };
        const ref = this.overlayService.open(RemoveEntityOverlayComponent, data);
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            ref.close();
        });

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.router.navigate([`/properties/${this.propertyId}/ownerships`], { relativeTo: this.route });
        });
    }

    public openEditComponent(): void {
        const ref = this.overlayService.open(AddEditOwnershipFormComponent, {
            data: {
                ownership: this.ownership,
                editingMode: true,
                units: this.units,
                propertyId: this.propertyId,
            },
        });

        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            ref.close();
        });

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.refreshOwnership$.next());
    }

    public openOwnershipUnitDetailsOverlay(event: any): void {
        const clickedRow = event !== null ? this.tableComponent?.model.row(event) : undefined;
        const unitId = clickedRow ? clickedRow[0]?.data?.extraData?.unit?.id : undefined;

        const ref = this.overlayService.open(OwnershipUnitDetailsOverlayComponent, {
            data: {
                unitId,
                units: this.units,
                propertyId: this.propertyId,
                ownership: this.ownership,
            },
            overlayType: 'half-page',
        });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.refreshOwnership$.next();
            ref.close();
        });

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.refreshOwnership$.next();
        });
    }

    public updateAdditionalInformation(event: any): void {
        if (!this.propertyId || !this.ownership) {
            return;
        }

        this.ownershipsService
            .update(this.propertyId, this.ownership?.id, { additionalInformation: event ?? '' })
            .subscribe(() => this.refreshOwnership$);
    }

    public getNameFromPerson(person: Person): string {
        return getNameFromPerson(person);
    }

    public getTypeUnitName(unit: Unit): string {
        return unitTypeNameFromEnum(unit);
    }

    public tooltipInfoOwner: { tooltipKey: TooltipKey; tooltipValue: string } = {
        tooltipKey: TooltipKey.tooltipsInProperties,
        tooltipValue: this.translateService.instant('PAGES.TOOLTIPS.OWNERSHIP_DETAIL.OWNER_TOOLTIP'),
    };

    public tooltipInfoDocuments: { tooltipKey: TooltipKey; tooltipValue: string } = {
        tooltipKey: TooltipKey.tooltipsInProperties,
        tooltipValue: this.translateService.instant('PAGES.TOOLTIPS.OWNERSHIP_DETAIL.DOCUMENTS_TOOLTIP'),
    };
    public DocumentsFlow = DocumentsFlow;

    // create row tables
    private createSharedOwnershipTableRow(item: SharedOwnership): TableItem[] {
        return [
            {
                data: {
                    label: item.category ?? '',
                    extraData: { sharedOwnership: item },
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: item.title ?? '',
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: item.buildingInfo?.name ?? '',
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: item.ownershipInfo?.name ?? '',
                },
                template: CellTemplate.Default,
            },
        ];
    }

    private createOwnershipUnitTableRow(item: Unit): TableItem[] {
        return [
            {
                data: {
                    label: this.getTypeUnitName(item),
                    extraData: { unit: item },
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: item.name,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: item.buildingName,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: item.location || '',
                },
                template: CellTemplate.Default,
            },
        ];
    }
}
