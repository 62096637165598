<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>
<app-overlay-container
    [headlineTitle]="headLineTitle"
    [sizeIcon]="72"
    image="40_person.svg"
    colorIcon="green"
    containerSize="small"
>
    <form [formGroup]="form">
        <div class="tw-flex tw-gap-8 tw-mb-4">
            <ibm-label class="tw-w-full"
                >Personenanzahl
                <input
                    ibmText
                    name="personAmount"
                    id="personAmount"
                    [invalid]="isInvalidForm('personAmount')"
                    formControlName="personAmount"
                />
            </ibm-label>
        </div>
        <div>
            <ibm-date-picker
                label="{{ 'COMMON.LABEL_START_DATE' | translate }} *"
                id="start"
                [placeholder]="'tt.mm.jjjj'"
                [size]="'xl'"
                [language]="'de'"
                [dateFormat]="'d.m.Y'"
                invalidText="{{ 'ACCOUNTING.ERROR.INVALID_DATE' | translate }}"
                (valueChange)="changeStartDate($event)"
                formControlName="start"
            >
            </ibm-date-picker>
        </div>
        <div class="tw-mt-28 tw-w-full tw-flex tw-justify-between">
            <app-scalara-button (click)="abort()" height="48px" variant="outline">{{
                'CONTROLS.BUTTONS.CANCEL' | translate
            }}</app-scalara-button>
            <app-scalara-button [disabled]="form.invalid" (click)="submit()" height="48px" class="tw-mb-4 md:tw-mb-0">{{
                'CONTROLS.BUTTONS.SAVE' | translate
            }}</app-scalara-button>
        </div>
    </form>
</app-overlay-container>
