import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as XLSX from 'xlsx';

export interface FiletoView {
    fileName: string;
    file: string;
    // isPdf: boolean;
}

export const ACCEPTED_FILE_TYPES = [
    'jpg',
    'jpeg',
    'png',
    'pdf',
    'csv',
    'xlsx',
    'xls',
    'xlsm',
    'XLSM',
    'XLS',
    'XLSX',
    'CSV',
    'PDF',
    'JPG',
    'PNG',
    'JPEG',
] as const;
export type AcceptedFileType = (typeof ACCEPTED_FILE_TYPES)[number];

@Component({
    selector: 'app-file-viewer',
    templateUrl: './file-viewer.component.html',
    styleUrls: ['./file-viewer.component.scss'],
})
export class FileViewerComponent implements OnInit {
    private readonly acceptedFileTypes = ACCEPTED_FILE_TYPES as unknown as AcceptedFileType[];
    @Input() public fileToView?: FiletoView;

    @Output() public closeViewer = new EventEmitter();

    public excelData: {
        headers: any[];
        rows: any[][];
    }[] = [];

    public viewerOptions: Viewer.Options = {
        navbar: false,
        backdrop: false,
        button: false,
        inline: true,
        initialCoverage: 1,
        title: false,
        toolbar: {
            zoomIn: true,
            zoomOut: true,
            oneToOne: true,
            reset: true,
            prev: false,
            play: false,
            next: false,
            rotateLeft: false,
            rotateRight: false,
            flipHorizontal: false,
            flipVertical: false,
        },
    };

    public close(): void {
        this.closeViewer.emit();
    }

    public ngOnInit(): void {
        if (this.isSheets) {
            if (!this.fileToView?.file) {
                return;
            }
            this.downloadFile(this.fileToView?.file);
        }
    }

    public get fileType(): AcceptedFileType | null {
        const fileName = this.fileToView?.fileName;
        if (!fileName) {
            return null;
        }
        const fileExtension = fileName.split('.').pop() as AcceptedFileType | undefined;
        if (!fileExtension || !this.acceptedFileTypes.includes(fileExtension)) {
            console.warn('File extension not supported');
            return null;
        }
        return fileExtension;
    }

    public get isPdf(): boolean {
        return this.fileType?.toLowerCase() === 'pdf';
    }

    public get isImg(): boolean {
        return (
            this.fileType?.toLowerCase() === 'png' ||
            this.fileType?.toLowerCase() === 'jpg' ||
            this.fileType?.toLowerCase() === 'jpeg'
        );
    }

    public get isSheets(): boolean {
        return (
            this.fileType?.toLowerCase() === 'csv' ||
            this.fileType?.toLowerCase() === 'xlsx' ||
            this.fileType?.toLowerCase() === 'xls' ||
            this.fileType?.toLowerCase() === 'xlsm'
        );
    }

    public downloadFile(fileUrl: string): void {
        fetch(fileUrl)
            .then((response) => response.arrayBuffer())
            .then((data) => {
                this.processExcel(data);
            })
            .catch((error) => {
                console.warn('error downloading file', error);
            });
    }

    public processExcel(data: ArrayBuffer): void {
        const arrayBuffer = new Uint8Array(data);
        const workbook = XLSX.read(arrayBuffer, { type: 'array', cellStyles: true });

        workbook.SheetNames.forEach((sheetName) => {
            const worksheet = workbook.Sheets[sheetName];

            const jsonData: any[] = XLSX.utils.sheet_to_json(worksheet, {
                header: 1,
                blankrows: true,
                raw: true,
                skipHidden: true,
                rawNumbers: true,
            });

            const headers = jsonData[0];
            const rows = jsonData.slice(1);

            this.excelData?.push({ headers, rows });
        });
    }
}
