import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ListItem } from 'carbon-components-angular';
import { BehaviorSubject, Subject, combineLatest, filter, switchMap, takeUntil, tap } from 'rxjs';
import { BankTransactionForListDto, BankTransactionsService } from 'src/app/generated-sources/accounting';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { CellTemplate } from 'src/app/shared/table/enums/cell-template';
import { HeaderTemplate } from 'src/app/shared/table/enums/header-template';
import { TableItem } from 'src/app/shared/table/interfaces/table-item';
import { TableModel } from 'src/app/shared/table/interfaces/table-model';
import {
    GetStatus,
    defaultSortTransaction,
    formatDateYYYYMMDDWithoutHours,
    getStatusTransaction,
} from '../../../../../../../core/utils/common';
import { AddBookingSelectionOverlayComponent } from '../../../../bookings/components/add-booking-selection-overlay/add-booking-selection-overlay.component';
import { BookingsCustomService } from '../../../../bookings/service/bookings-custom.service';
import { AccountingFilterCustomService } from '../../../../services/accounting-filter-custom.service';
import { LedgerCustomService } from '../../../../services/ledger-custom.service';
import { BankingTransactionsMarkBookedOverlayComponent } from '../../banking-transactions-mark-booked-overlay/banking-transactions-mark-booked-overlay.component';

type SELECTED_FILTER_ID = 'alle' | 'unverbucht' | 'verbucht';

interface FilterDate {
    startDate: string;
    endDate: string;
}

type SelectedFilterValue = true | false | undefined;

@Component({
    selector: 'app-banking-transactions-transactions',
    templateUrl: './banking-transactions-transactions.component.html',
    styleUrls: ['./banking-transactions-transactions.component.scss'],
})
export class BankingTransactionsTransactionsComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();
    private selectedFilterValue$ = new BehaviorSubject<SelectedFilterValue>(undefined);
    public refresh$ = new BehaviorSubject(null);

    public readonly FILTER_DATE_URL_PARAM_NAME = 'bankTransactionsFilterDateId';
    public readonly DEFAULT_SELECTED_FILTER_ID: SELECTED_FILTER_ID = 'alle';

    public tableModel: TableModel = { data: [], header: [] };
    public isLoading = false;
    public bankTransactions: BankTransactionForListDto[] = [];
    public amountUnbookedTransaction = 0;
    public amountSuggestedBookings = 0;
    public ledgerId = '';

    public selectedFilterDate?: FilterDate = undefined;

    public constructor(
        public translateService: TranslateService,
        private bankTransactionsService: BankTransactionsService,
        private bookingsCustomService: BookingsCustomService,
        private route: ActivatedRoute,
        private overlayService: OverlayService,
        private router: Router,
        private ledgerCustomService: LedgerCustomService,
        private accountingFilterCustomService: AccountingFilterCustomService
    ) {}

    public readonly filterItemsBase: ({ id: SELECTED_FILTER_ID; value: SelectedFilterValue } & ListItem)[] = [
        {
            content: this.translateService.instant('PAGES.BANK_TRANSACTIONS.FILTER_ALL'),
            value: undefined,
            selected: true,
            id: 'alle',
        },
        {
            content: this.translateService.instant('PAGES.BANK_TRANSACTIONS.FILTER_NOT_BOOKED'),
            value: false,
            selected: false,
            id: 'unverbucht',
        },
        {
            content: this.translateService.instant('PAGES.BANK_TRANSACTIONS.FILTER_BOOKED'),
            value: true,
            selected: false,
            id: 'verbucht',
        },
    ];

    public filterItems: ListItem[] | null = null;

    public selectFilter(selectedFilter: ListItem): void {
        const filterItemsCopy = [...this.filterItemsBase];
        filterItemsCopy.forEach((i) =>
            i['id'] === selectedFilter['id'] ? (i['selected'] = true) : (i['selected'] = false)
        );

        this.filterItems = filterItemsCopy;
        this.refresh$.next(null);
    }

    public ledgerId$ = this.ledgerCustomService.getLedgerId$().pipe(
        filter(Boolean),
        tap((ledgerId) => (this.ledgerId = ledgerId))
    );

    public datePickerSelectedDates$ = this.accountingFilterCustomService.getDatePickerSelectedDates$();

    public bankTransactions$ = combineLatest([
        this.ledgerId$,
        this.datePickerSelectedDates$,
        this.selectedFilterValue$,
        this.refresh$,
    ]).pipe(
        tap(() => (this.isLoading = true)),
        switchMap(([ledgerId, datePickerDates, filterSelected]) => {
            const dates = datePickerDates;
            if (dates) {
                const startDate = formatDateYYYYMMDDWithoutHours(dates.startDate);
                const endDate = formatDateYYYYMMDDWithoutHours(dates.endDate);

                return this.bankTransactionsService.findAllBankTransactionsByLedgerId(
                    ledgerId,
                    startDate,
                    endDate,
                    filterSelected
                );
            }
            return this.bankTransactionsService.findAllBankTransactionsByLedgerId(
                ledgerId,
                undefined,
                undefined,
                filterSelected
            );
        }),
        tap((transactions: BankTransactionForListDto[]) => {
            this.bankTransactions = transactions;
            this.amountUnbookedTransaction = 0;
            const transactionsSorted = defaultSortTransaction(transactions);

            this.amountUnbookedTransaction = transactionsSorted.filter((x) => !x.isFullyBooked).length;

            this.tableModel.data = transactionsSorted.map((item) => {
                return this.createRow(item);
            });
            this.isLoading = false;
        })
    );

    private updateUrlParams(selectedFilerId: string): void {
        const params = { [this.FILTER_DATE_URL_PARAM_NAME]: selectedFilerId };
        const urlTree = this.router.createUrlTree([], {
            queryParams: params,
            queryParamsHandling: 'merge',
            relativeTo: this.route,
        });

        this.router.navigateByUrl(urlTree);
    }

    public loadSelectedFilterFromUrlParams(): void {
        const routeSnapshot = this.route.snapshot;

        //  set default alle if no param
        const selectedFilterId = String(
            routeSnapshot.queryParams[this.FILTER_DATE_URL_PARAM_NAME] ?? this.DEFAULT_SELECTED_FILTER_ID
        );
        const selectedFilter = this.filterItemsBase.find((i) => i['id'] == selectedFilterId);
        if (!selectedFilter) {
            console.error('no filter found');
            this.selectFilter(this.filterItemsBase[0]);
            return;
        }
        this.selectedFilterValue$.next(selectedFilter['value']);
        this.selectFilter(selectedFilter);
    }

    public ngOnInit(): void {
        this.isLoading = true;
        this.initTableHeaders();
        this.filterItems = this.filterItemsBase;
        this.loadSelectedFilterFromUrlParams();
        this.bankTransactions$
            .pipe(
                tap(() => {
                    this.isLoading = false;
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }

    private initTableHeaders(): void {
        this.tableModel.header = [
            'ACCOUNTING.BANK_TRANSACTION_DETAILS.STATUS',
            'ACCOUNTING.BANK_TRANSACTION_DETAILS.COUNTERPART_NAME',
            'ACCOUNTING.BANK_TRANSACTION_DETAILS.PURPOSE',
            'ACCOUNTING.BANK_TRANSACTION_DETAILS.VALUE_DATE',
            {
                data: { key: 'ACCOUNTING.BANK_TRANSACTION_DETAILS.TOTAL_AMOUNT', params: {} },
                template: HeaderTemplate.RightAligned,
            },
            {
                data: { key: 'ACCOUNTING.BANK_TRANSACTION_DETAILS.OPEN_AMOUNT', params: {} },
                template: HeaderTemplate.RightAligned,
            },
            'PAGES.BANK_TRANSACTIONS.HEADER_BOOKING_RECOMMENDATION',
            '',
            '',
        ];
    }

    public onSelectFilter($event: any): void {
        this.selectedFilterValue$.next($event.item['value']);
        this.updateUrlParams($event.item.id);
    }

    private formatDate(date: Date): string {
        const dateFormated = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();

        return dateFormated;
    }

    public getRestAmount(amountAssignedToBookings: number, amount: number): number {
        return amount - ((amountAssignedToBookings ?? 0) * Math.abs(amount)) / amount;
    }

    public handlingEvent($event: any): void {
        if ($event.extraData && $event.extraData.eventName === 'markBankTransaction') {
            this.openMarkAsBookedOverlay($event);
        } else {
            this.openAddBookingOverlay($event);
        }
    }

    public openAddBookingOverlay($event: any): void {
        const data = {
            ledgerId: this.ledgerId,
            bankTransactionId: $event.extraData.id,
        };
        const ref = this.overlayService.open(AddBookingSelectionOverlayComponent, {
            data,
        });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.refresh$.next(null));
    }

    private createRow(transactionItem: BankTransactionForListDto): TableItem[] {
        const statusInfo: GetStatus = getStatusTransaction(
            Math.abs(transactionItem.amount),
            Math.abs(transactionItem.amountAssignedToBookings || 0),
            transactionItem.isFullyBooked
        );

        const isIgnored = statusInfo.status === 'Ignoriert';
        const link = `/accounting/ledger/${this.ledgerId}/bank-transactions/${transactionItem.id}/bank-accounts/${transactionItem.bankAccountId}`;
        const hasSuggestedBooking = transactionItem.hasBookingRecommendation;
        const restAmount = this.getRestAmount(transactionItem.amountAssignedToBookings || 0, transactionItem.amount);
        let purpose = transactionItem.purpose?.replace(/[+-]/g, ' ') ?? '';
        purpose = purpose.length >= 150 ? purpose.substring(0, 150) + '...' : purpose;

        return [
            {
                data: {
                    label: statusInfo.status,
                    textColor: statusInfo.color,
                    iconSrc: statusInfo.iconSrc,
                    link,
                    extraData: {
                        bankTransaction: transactionItem,
                        enableCustomSorting: true,
                    },
                },
                template: CellTemplate.DynamicIcon,
            },
            {
                data: {
                    label: transactionItem.counterpartName,
                    textColor: isIgnored ? 's-gray-03' : 's-gray-01',
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: purpose,
                    textColor: isIgnored ? 's-gray-03' : 's-gray-01',
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: transactionItem.valueDate,
                    textColor: isIgnored ? 's-gray-03' : 's-gray-01',
                    link,
                },
                template: CellTemplate.Date,
            },
            {
                data: {
                    label: transactionItem.amount,
                    textColor: isIgnored ? 's-gray-03' : 's-gray-01',
                    link,
                    rightAligned: true,
                },
                template: CellTemplate.EuroCent,
            },
            {
                data: {
                    label: restAmount,
                    textColor: isIgnored ? 's-gray-03' : 's-gray-01',
                    link,
                    rightAligned: true,
                },
                template: CellTemplate.EuroCent,
            },
            {
                data: {
                    label: hasSuggestedBooking ? 'Vorschlag' : '',
                    extraData: { color: 'green' },
                    iconSrc: '/assets/icons/24_linked.svg',
                },
                template: hasSuggestedBooking ? CellTemplate.textWithIcon : CellTemplate.Default,
            },
            ...[
                statusInfo.status === 'Teilverbucht' || statusInfo.status === 'Verbucht'
                    ? {
                          data: {
                              label: '',
                              link,
                          },
                          template: CellTemplate.Default,
                      }
                    : {
                          data: {
                              label: '',
                              textColor: 's-gray-02',
                              iconSrc: isIgnored ? '/assets/icons/24_add-cycle.svg' : '/assets/icons/24_ignor.svg',
                              extraData: {
                                  showOnlyWithHover: true,
                                  eventName: 'markBankTransaction',
                                  bankTransaction: transactionItem,
                              },
                          },
                          template: CellTemplate.iconButton,
                      },
            ],
            {
                data: {
                    label: restAmount === 0 || isIgnored ? '' : 'Verbuchen',
                    extraData: transactionItem,
                    rightAligned: true,
                },
                template: restAmount === 0 || isIgnored ? CellTemplate.Default : CellTemplate.button,
            },
        ];
    }

    public openMarkAsBookedOverlay($event: any): void {
        const data = {
            bankTransaction: $event.extraData.bankTransaction,
        };
        const ref = this.overlayService.open(BankingTransactionsMarkBookedOverlayComponent, {
            data,
        });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.refresh$.next(null));
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
