<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<div ibmRow class="mt-06 mb-11">
    <div ibmCol>
        <ibm-breadcrumb [items]="breadcrumbs" [noTrailingSlash]="true"> </ibm-breadcrumb>
    </div>
    <div ibmCol class="text-right d-flex justifiy-content-end">
        <app-scalara-button
            *ngIf="bankTransaction && showIgnoreButton()"
            [variant]="'ghost'"
            type="button"
            (click)="openMarkAsBookedOverlay()"
            svgName="{{ bankTransaction.isFullyBooked ? '24_add-cycle.svg' : '24_ignor.svg' }}"
        >
            {{
                bankTransaction.isFullyBooked
                    ? ('ACCOUNTING.BANK_TRANSACTION_DETAILS.BUTTON_IGNORE_REMOVE' | translate)
                    : ('ACCOUNTING.BANK_TRANSACTION_DETAILS.BUTTON_IGNORE_APPLY' | translate)
            }}
        </app-scalara-button>
    </div>
</div>

<div class="pb-13">
    <app-banking-transaction-detail-card
        *ngIf="bankTransaction"
        [bankAccount]="bankAccount"
        [bankTransaction]="bankTransaction"
        (overlayIsClosed)="refreshBankTransactionDetailInfo()"
    ></app-banking-transaction-detail-card>

    <div class="mt-10">
        <div class="mb-0-75r s-body-14-22-bold">{{ 'PAGES.RECEIPTS.HEADLINE' | translate }}</div>
        <app-receipts-table [receipts]="receipts"></app-receipts-table>
    </div>

    <div>
        <div class="s-body-14-22-bold mb-03">
            {{ 'ACCOUNTING.BANK_TRANSACTION_DETAILS.CONNECTED_BOOKING' | translate }}
        </div>
        <app-booking-table [bookings]="bankTransaction?.bookings"></app-booking-table>
    </div>
</div>
