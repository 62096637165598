import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbItem } from 'carbon-components-angular';
import { Observable, Subject, combineLatest, filter, of, switchMap, takeUntil, tap } from 'rxjs';
import { BreadCrumbService } from 'src/app/core/services/breadcrumb.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { PersonLocalService } from 'src/app/features/property/services/person-local.service';
import {
    BalanceComparisonDto,
    ChangeStatusDto,
    EconomicPlanDto,
    WegSettlementStatementsService,
    WssDto,
} from 'src/app/generated-sources/accounting';
import { Ownership, OwnershipsService, Person, Property } from 'src/app/generated-sources/base';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { LedgerCustomService } from '../../accounting/components/services/ledger-custom.service';
import { ActivateAnnualStatementComponent } from '../activate-annual-statement/activate-annual-statement.component';
import { AddAnnualStatementConsumptionKeyComponent } from '../add-annual-statement-consumption-key/add-annual-statement-consumption-key.component';
import { CustomWssService } from '../custom-wss.service';
import { DeleteAnnualStatementComponent } from '../delete-annual-statement/delete-annual-statement.component';
import { EditAnnualStatementComponent } from '../edit-annual-statement/edit-annual-statement.component';

type TabsToShow = 'individualEconomicPlanTab' | 'distributionKeysTab';

@Component({
    selector: 'app-annual-statement-details',
    templateUrl: './annual-statement-details.component.html',
    styleUrls: ['./annual-statement-details.component.scss'],
})
export class AnnualStatementDetailsComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();
    public isLoading = false;

    public ledgerId = '';
    private wssId = '';
    public annualStatement?: WssDto;
    public balance?: BalanceComparisonDto;
    public ownerships: Ownership[] = [];
    public annualStatementType = '';
    public isPropertyManager = false;

    public editMode = true;
    public breadcrumbs: BreadcrumbItem[] = [];
    public annualStatementDates = '';

    public isBalanceOk = true;
    public isBalanceBoxOpen = false;
    public refresh = 1;

    //  we want to render chosen tabs only when they are opened
    public tabsToShow: TabsToShow[] = [];

    public constructor(
        private breadcrumbService: BreadCrumbService,
        private translateService: TranslateService,
        private annualStatementsService: WegSettlementStatementsService,
        private ownershipsService: OwnershipsService,
        private route: ActivatedRoute,
        private overlayService: OverlayService,
        private datePipe: DatePipe,
        private personLocalService: PersonLocalService,
        private ledgerCustomService: LedgerCustomService,
        private toastService: ToastService,
        private customWssService: CustomWssService,
        private router: Router
    ) {}

    public ngOnInit(): void {
        this.isLoading = true;
        combineLatest([this.route.parent!.paramMap, this.route.paramMap, this.route.queryParamMap])
            .pipe(
                tap((params: ParamMap[]) => {
                    this.ledgerId = String(params[0].get('id'));
                    this.wssId = String(params[1].get('annualStatementId'));
                    this.customWssService.setLedgerId(this.ledgerId);
                }),
                switchMap(() => this.personLocalService.getRolesOfProperty()),
                tap((roles: Property.PermissionRolesEnum[] | null) => {
                    this.isPropertyManager = roles
                        ? Object.values(roles).includes(Person.PermissionRolesEnum.PropertyManager)
                        : false;
                }),
                switchMap(() => this.annualStatementsService.findOne(this.ledgerId, this.wssId)),
                tap((annualStatement: WssDto) => {
                    this.annualStatement = annualStatement;
                    this.customWssService.setWss(annualStatement);
                    this.annualStatementDates =
                        this.datePipe.transform(this.annualStatement?.economicPlan.startDate, 'dd.MM.yyyy') +
                        ' – ' +
                        this.datePipe.transform(this.annualStatement?.economicPlan.endDate, 'dd.MM.yyyy');

                    this.editMode = annualStatement.status === WssDto.StatusEnum.Draft;
                    this.initBreadCrumbs();
                }),
                switchMap(() => {
                    return this.ledgerCustomService.getLedgerPropertyId$();
                }),
                filter(Boolean),
                switchMap((propertyId) => this.ownershipsService.findAll(propertyId)),
                tap((ownerships) => {
                    this.ownerships = ownerships;
                }),
                switchMap(() => {
                    if (this.annualStatement?.status == 'DRAFT') {
                        return this.annualStatementsService.getBalanceComparison(this.ledgerId, this.wssId);
                    } else {
                        return of(null);
                    }
                }),
                tap((balance: BalanceComparisonDto) => {
                    if (balance) {
                        this.balance = balance;
                        this.isBalanceOk = balance.totalAmountBankAccounts === balance.totalAmountLedgerAccounts;
                    }
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => (this.isLoading = false));
    }

    private initBreadCrumbs(): void {
        const breadcrumb: BreadcrumbItem[] = [
            {
                content: this.translateService.instant('ENTITIES.ANNUAL_STATEMENT.LABEL_ENTITY'),
                route: [`/accounting/ledger/${this.ledgerId}/annual-statements`],
            },
            {
                content:
                    this.datePipe.transform(this.annualStatement?.economicPlan?.startDate, 'dd.MM.yyyy') +
                    ' – ' +
                    this.datePipe.transform(this.annualStatement?.economicPlan?.endDate, 'dd.MM.yyyy'),
                route: [`/accounting/ledger/${this.ledgerId}/annual-statements/${this.wssId}`],
                current: true,
            },
        ];
        this.breadcrumbService.resetBreadCrumbs();
        this.breadcrumbService.updateBreadCrumbs(breadcrumb);
        this.breadcrumbs = this.breadcrumbService.getCurrentBreadCrumbs();
    }

    public switchToReviewAnnualStatement(): void {
        const changeStatusDto: ChangeStatusDto = {
            status: ChangeStatusDto.StatusEnum.InReview,
        };

        this.annualStatementsService
            .changeStatus(this.ledgerId, this.wssId, changeStatusDto)
            .pipe(
                switchMap(() => this.annualStatementsService.findOne(this.ledgerId, this.wssId)),
                tap((annualStatement: WssDto) => {
                    this.annualStatement = annualStatement;
                    this.customWssService.setWss(annualStatement);
                    this.editMode = annualStatement.status === EconomicPlanDto.StatusEnum.Draft;
                    this.router.navigate(['/accounting', 'ledger', this.ledgerId, 'annual-statements']);
                    this.toastService.showSuccess(
                        this.translateService.instant('PAGES.ANNUAL_STATEMENT.DETAILS.TOAST_TO_REVIEW_SUCCESS_MESSAGE')
                    );
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }

    public switchToDraftAnnualStatement(): void {
        const changeStatusDto: ChangeStatusDto = {
            status: ChangeStatusDto.StatusEnum.Draft,
        };

        this.annualStatementsService
            .changeStatus(this.ledgerId, this.wssId, changeStatusDto)
            .pipe(
                switchMap(() => this.annualStatementsService.findOne(this.ledgerId, this.wssId)),
                tap((annualStatement: WssDto) => {
                    this.annualStatement = annualStatement;
                    this.customWssService.setWss(annualStatement);
                    this.editMode = annualStatement.status === EconomicPlanDto.StatusEnum.Draft;
                    this.router.navigate(['/accounting', 'ledger', this.ledgerId, 'annual-statements']);
                    this.toastService.showSuccess(
                        this.translateService.instant(
                            'PAGES.ANNUAL_STATEMENT.DETAILS.TOAST_BACK_TO_DRAFT_SUCCESS_MESSAGE'
                        )
                    );
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }

    public openActivateAnnualStatementOverlay(): void {
        const ref = this.overlayService.open(ActivateAnnualStatementComponent, {
            data: { ledgerId: this.ledgerId, annualStatement: this.annualStatement },
        });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
    }

    public openEditAnnualStatementOverlay(): void {
        this.openOverlayAndRefreshOnSave$(EditAnnualStatementComponent, {
            data: { ledgerId: this.ledgerId, wssId: this.wssId },
        }).subscribe();
    }

    public openDeleteAnnualStatementOverlay(): void {
        this.openOverlayAndRefreshOnSave$(DeleteAnnualStatementComponent, {
            data: { ledgerId: this.ledgerId, annualStatementDates: this.annualStatementDates, wssId: this.wssId },
        }).subscribe();
    }

    public openAddConsumptionDistributionKeyOverlay(): void {
        const data = { ledgerId: this.ledgerId, wssId: this.annualStatement?.id };
        const ref = this.overlayService.open(AddAnnualStatementConsumptionKeyComponent, {
            data,
        });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.refresh++;
        });
    }

    public openOverlayAndRefreshOnSave$ = (...openParams: Parameters<OverlayService['open']>): Observable<WssDto> => {
        const ref = this.overlayService.open(...openParams);
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.overlayService.removeOverlayComponentFromBody();
            ref.close();
        });

        return ref.saveEmitter$.pipe(
            switchMap(() => this.annualStatementsService.findOne(this.ledgerId, this.wssId)),
            tap((annualStatement: WssDto) => {
                this.annualStatement = annualStatement;
                this.customWssService.setWss(annualStatement);
                this.editMode = annualStatement.status === EconomicPlanDto.StatusEnum.Draft;
                this.overlayService.removeOverlayComponentFromBody();
            }),
            takeUntil(this.unsubscribe$)
        );
    };

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public get isEditButtonDisabled(): boolean {
        return this.ownerships.length < 1;
    }

    public changeBalanceBoxDisplay(): void {
        this.isBalanceBoxOpen = !this.isBalanceBoxOpen;
    }

    public onTabSelected(tab: TabsToShow): void {
        if (this.tabsToShow.includes(tab)) {
            return;
        }
        this.tabsToShow.push(tab);
    }

    public updateWss(): void {
        this.annualStatementsService
            .changeStatus(this.ledgerId, this.wssId, { status: ChangeStatusDto.StatusEnum.Draft })
            .subscribe({
                next: () => this.router.navigate(['/accounting', 'ledger', this.ledgerId, 'annual-statements']),
            });
    }
}
