import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { tap } from 'rxjs';
import { formatDateWithoutHhMmSs, getNameFromPerson } from 'src/app/core/utils/common';
import {
    AccountMetricsDto,
    CustomerStatisticDto,
    OperationsService,
    PersonWithUserDto,
    PlatformStatisticDto,
} from 'src/app/generated-sources/base';
import { CellTemplate } from 'src/app/shared/table/enums/cell-template';
import { TableItem } from 'src/app/shared/table/interfaces/table-item';
import { TableModel } from 'src/app/shared/table/interfaces/table-model';

@Component({
    selector: 'app-operation-base-statistics',
    templateUrl: './operation-base-statistics.component.html',
    styleUrls: ['./operation-base-statistics.component.scss'],
})
export class OperationBaseStatisticsComponent implements OnInit, OnChanges {
    public customerStatistics?: CustomerStatisticDto[];
    public lastEnrolledStatistics?: PersonWithUserDto[];
    public orphanAccounts?: PersonWithUserDto[];
    public accountMetrics?: AccountMetricsDto;
    public displayLastEnrolledStatistics = false;
    public displayOrphanAccounts = false;
    public displayCustomerStatistics = false;
    public displayCustomerSubscriptions = false;
    public avgPersonXUser = 0.0;

    public lastEnrolledTable: TableModel = { data: [], header: [] };
    public orphanAccountsTable: TableModel = { data: [], header: [] };
    public customerStatisticsTable: TableModel = { data: [], header: [] };
    public customerSubscriptionsTable: TableModel = { data: [], header: [] };

    @Input() public platformStatistics: PlatformStatisticDto = {};
    @Input() public customerSubscriptions: CustomerStatisticDto[] = [];

    public constructor(private statisticsController: OperationsService) {}

    public ngOnInit(): void {
        this.getOrphanAccounts();
        this.getLastEnrolledUsers();
        this.getAllCustomers();
        this.getMetrics();
        this.initTableHeader();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if ('customerSubscriptions' in changes && this.customerSubscriptions != null) {
            this.prepareCustomerSubscriptions();
        }
    }

    private initTableHeader(): void {
        this.lastEnrolledTable.header = [
            'ENTITIES.PERSON.LABEL_ENTITY',
            'ENTITIES.USER.LABEL_ENTITY',
            'ENTITIES.COMMON.LABEL_CREATED_DATE',
        ];
        this.orphanAccountsTable.header = [
            'ENTITIES.PERSON.LABEL_ENTITY',
            'ENTITIES.USER.LABEL_ENTITY',
            'ENTITIES.COMMON.LABEL_CREATED_DATE',
        ];
        this.customerStatisticsTable.header = [
            'ENTITIES.PERSON.LABEL_ENTITY',
            'ENTITIES.PROPERTY.LABEL_ENTITY_PLURAL',
            'ENTITIES.OWNERSHIP.LABEL_ENTITY_PLURAL',
            'ENTITIES.USER.LABEL_ENTITY_PLURAL',
            'ENTITIES.COMMON.LABEL_CREATED_DATE',
        ];
        this.customerSubscriptionsTable.header = [
            'ENTITIES.PERSON.LABEL_ENTITY',
            'ENTITIES.PROPERTY.LABEL_ENTITY_PLURAL',
            'ENTITIES.OWNERSHIP.LABEL_ENTITY_PLURAL',
            'ENTITIES.USER.LABEL_ENTITY_PLURAL',
            'ENTITIES.COMMON.LABEL_CREATED_DATE',
        ];
    }

    private async getAllCustomers(): Promise<void> {
        await this.statisticsController
            .findAllCustomers()
            .pipe(tap((result) => (this.customerStatistics = result)))
            .subscribe();
    }

    private async getLastEnrolledUsers(daysInThePast = 7): Promise<void> {
        daysInThePast ? daysInThePast : (daysInThePast = 7);
        await this.statisticsController
            .getLastEnrollments(daysInThePast)
            .pipe(tap((result) => (this.lastEnrolledStatistics = result)))
            .subscribe();
    }

    private async getOrphanAccounts(): Promise<void> {
        await this.statisticsController
            .getOrphansAccounts()
            .pipe(tap((result) => (this.orphanAccounts = result)))
            .subscribe();
    }

    private async getMetrics(): Promise<void> {
        await this.statisticsController
            .getAccountMetrics()
            .pipe(tap((result) => (this.accountMetrics = result)))
            .subscribe();
    }

    public doSomething(action: string): void {
        if (action == 'lastEnrolledStatistics') {
            this.prepareLastEnrolledStatistics();
            this.displayLastEnrolledStatistics = true;
            this.displayOrphanAccounts = false;
            this.displayCustomerStatistics = false;
        } else if (action == 'orphanAccounts') {
            this.prepareOrphanAccounts();
            this.displayLastEnrolledStatistics = false;
            this.displayOrphanAccounts = true;
            this.displayCustomerStatistics = false;
            this.displayCustomerSubscriptions = false;
        } else if (action == 'customerStatistics') {
            this.prepareCustomerStatistics();
            this.displayLastEnrolledStatistics = false;
            this.displayOrphanAccounts = false;
            this.displayCustomerStatistics = true;
            this.displayCustomerSubscriptions = false;
        } else if (action == 'customerSubscriptions') {
            this.displayLastEnrolledStatistics = false;
            this.displayOrphanAccounts = false;
            this.displayCustomerStatistics = false;
            this.displayCustomerSubscriptions = true;
        }
    }
    private prepareLastEnrolledStatistics(): void {
        if (this.lastEnrolledStatistics) {
            this.lastEnrolledTable.data = this.lastEnrolledStatistics?.map((owner) =>
                this.createPersonWithUserRow(owner)
            );
        }
    }
    private prepareOrphanAccounts(): void {
        if (this.orphanAccounts) {
            this.orphanAccountsTable.data = this.orphanAccounts?.map((owner) => this.createPersonWithUserRow(owner));
        }
    }
    private prepareCustomerStatistics(): void {
        if (this.customerStatistics) {
            this.customerStatisticsTable.data = this.customerStatistics?.map((owner) =>
                this.createCustomerStatisticsRow(owner)
            );
        }
    }
    private prepareCustomerSubscriptions(): void {
        if (this.customerSubscriptions) {
            this.avgPersonXUser = 0;
            this.customerSubscriptionsTable.data = this.customerSubscriptions?.map((owner) => {
                this.avgPersonXUser += owner.users.length;
                return this.createCustomerStatisticsRow(owner);
            });
            this.avgPersonXUser /= this.customerSubscriptions.length;
        }
    }

    private createPersonWithUserRow(data: PersonWithUserDto): TableItem[] {
        const link = `/contacts/${data.person.id}`;
        return [
            {
                data: {
                    label: data.person ? getNameFromPerson(data.person) : 'Keine Person gefunden',
                    link,
                    extraData: { isUserRegistered: data.person.hasRegisteredUser },
                },
                template: CellTemplate.personWithAvatar,
            },
            {
                data: {
                    label: data.user?.email ?? '',
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: formatDateWithoutHhMmSs(data.person.createdAt ?? ''),
                    link,
                },
                template: CellTemplate.Default,
            },
        ];
    }
    private createCustomerStatisticsRow(data: CustomerStatisticDto): TableItem[] {
        const link = `/contacts/${data.person.id}`;
        let userlist = '';
        userlist = data.users.join(', ');

        return [
            {
                data: {
                    label: data.person ? getNameFromPerson(data.person) : 'Keine Person gefunden',
                    link,
                    extraData: { isUserRegistered: data.person.hasRegisteredUser },
                },
                template: CellTemplate.personWithAvatar,
            },
            {
                data: {
                    label: data.properties ?? '',
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: data.ownerships ?? '',
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: userlist ?? '',
                    link,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: formatDateWithoutHhMmSs(data.person.createdAt ?? ''),
                    link,
                },
                template: CellTemplate.Default,
            },
        ];
    }
}
