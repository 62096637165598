<app-overlay-container
    headlineTitle="PAGES.OPERATIONS_EXPENSE_STATEMENTS.ACTIVATE.HEADLINE"
    [sizeIcon]="72"
    image="40_document_check.svg"
    colorIcon="green"
>
    <div class="s-head-16-24-bold mb-2r">
        {{ 'PAGES.OPERATIONS_EXPENSE_STATEMENTS.ACTIVATE.TITLE' | translate }}
    </div>

    <div class="pre-wrap s-head-14-22-regular">
        <span>{{ 'PAGES.OPERATIONS_EXPENSE_STATEMENTS.ACTIVATE.LABEL_WARNING' | translate }}</span>
    </div>

    <div class="mt-2-5r" *ngIf="existStatementForPeriod">
        <app-notification type="warning">
            <div class="s-head-14-22-regular">
                {{ 'PAGES.OPERATIONS_EXPENSE_STATEMENTS.ACTIVATE.INFO_BOX_ALREADY_EXIST_FOR_PERIOD' | translate }}
            </div>
        </app-notification>
    </div>

    <div class="d-flex justify-space-between mt-7-5r">
        <app-scalara-button (click)="abort()" variant="outline">
            {{ 'CONTROLS.BUTTONS.CANCEL' | translate }}
        </app-scalara-button>

        <app-scalara-button (click)="submit()" [variant]="'default'" type="submit">
            {{ 'PAGES.OPERATIONS_EXPENSE_STATEMENTS.ACTIVATE.BUTTON_SUBMIT' | translate }}
        </app-scalara-button>
    </div>
</app-overlay-container>
