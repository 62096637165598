import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, filter, map, ObservedValueOf, shareReplay, Subject, switchMap, takeUntil } from 'rxjs';
import { AssetReportDto, WegSettlementStatementsService } from 'src/app/generated-sources/accounting';
import { Column, RowData } from 'src/app/shared/components/simple-table/simple-table-row/simple-table-row.component';
import { EurocentPipe } from 'src/app/shared/pipes/eurocent.pipe';
import { CustomWssService } from '../custom-wss.service';

type ColumnKey = 'name' | 'amount';
type TableData = {
    columns: Column<ColumnKey>[];
    rows: RowData<ColumnKey>[];
};
type AssetType = 'bankAccounts' | 'receivables' | 'liabilities';

@Component({
    selector: 'app-asset-report',
    templateUrl: './asset-report.component.html',
    styleUrls: ['./asset-report.component.scss'],
})
export class AssetReportComponent implements OnInit, OnDestroy {
    public constructor(
        public wssService: WegSettlementStatementsService,
        public euroCent: EurocentPipe,
        public datePipe: DatePipe,
        public customWssService: CustomWssService
    ) {}

    public unsubscribe$ = new Subject<void>();
    public ledgerId$ = this.customWssService.getLedgerId$().pipe(filter(Boolean));
    public wss$ = this.customWssService.getWss$().pipe(filter(Boolean));
    public assetReport$ = combineLatest([this.ledgerId$, this.wss$]).pipe(
        switchMap(([ledgerId, wss]) => this.wssService.getAssetReport(ledgerId, wss.id)),
        shareReplay({ bufferSize: 1, refCount: true })
    );
    public table$ = combineLatest([this.wss$, this.assetReport$]).pipe(
        map(([wss, assetReport]) => {
            const tableData: TableData = {
                columns: [
                    { key: 'name', label: '' },
                    { key: 'amount', label: 'Beträge in €' },
                ],
                rows: [
                    {
                        cells: [
                            {
                                key: 'name',
                                type: 'value',
                                value: `Stand der Rücklagen am ${this.datePipe.transform(
                                    wss.economicPlan.endDate,
                                    'dd.MM.yyyy'
                                )}`,
                                rowCellClass: '[&&]:tw-s-head-16-24-bold [&&]:tw-py-6',
                            },
                        ],
                    },
                    ...assetReport.reserves.map(
                        (reserve): RowData<ColumnKey> => ({
                            cells: [
                                { key: 'name', type: 'value', value: reserve.description },
                                { key: 'amount', type: 'value', value: reserve.amount, pipeToApply: 'eurocent' },
                            ],
                        })
                    ),
                    { cells: [{ key: 'name', type: 'value', value: '', rowCellClass: '[&&]:tw-h-2' }] },
                    {
                        cells: [
                            {
                                key: 'name',
                                type: 'value',
                                value: 'Rücklagen gesamt',
                            },
                            {
                                key: 'amount',
                                type: 'value',
                                value: assetReport.reserves.reduce((acc, r) => acc + r.amount, 0),
                                pipeToApply: 'eurocent',
                            },
                        ],
                        rowClass: '[&&]:tw-bg-scalaraGray-06 [&&]:tw-h-10 [&&]:tw-font-bold',
                    },
                    //Aufstellung über das wesentliche Gemeinschaftsvermögen
                    {
                        cells: [
                            {
                                key: 'name',
                                type: 'value',
                                value: 'Aufstellung über das wesentliche Gemeinschaftsvermögen',
                                rowCellClass: '[&&]:tw-s-head-16-24-bold [&&]:tw-pt-14 [&&]:tw-pb-6',
                            },
                        ],
                    },
                    //Geldkontenstände + Forderungen + Verbindlichkeiten
                    ...this.assetsToSubtableVariants
                        .map((variant) => this.assetsToSubtable({ assetReport, variant }))
                        .flat(),
                    //Bewegliches Vermögen
                    {
                        cells: [
                            {
                                key: 'name',
                                type: 'value',
                                value: 'Bewegliches Vermögen',
                                rowCellClass: '[&&]:tw-s-head-16-24-bold [&&]:tw-pb-5 [&&]:tw-pt-6',
                            },
                        ],
                    },
                    { cells: [{ key: 'name', type: 'value', value: wss.movableAssetDescription || '-' }] },
                    { cells: [{ key: 'name', type: 'value', value: '', rowCellClass: '[&&]:tw-h-14' }] },
                    //Immobilieneigentum
                    {
                        cells: [
                            {
                                key: 'name',
                                type: 'value',
                                value: 'Immobilieneigentum',
                                rowCellClass: '[&&]:tw-s-head-16-24-bold [&&]:tw-pb-5',
                            },
                        ],
                    },
                    { cells: [{ key: 'name', type: 'value', value: wss.realEstateDescription || '-' }] },
                    { cells: [{ key: 'name', type: 'value', value: '', rowCellClass: '[&&]:tw-h-14' }] },
                ],
            };
            return tableData;
        })
    );

    public vm$ = combineLatest([this.ledgerId$, this.wss$, this.table$, this.assetReport$]).pipe(
        map(([ledgerId, wss, tableData, assetReport]) => ({ ledgerId, wss, tableData, assetReport }))
    );
    public vm: ObservedValueOf<typeof this.vm$> | null = null;

    public ngOnInit(): void {
        this.vm$.pipe(takeUntil(this.unsubscribe$)).subscribe((vm) => {
            this.vm = vm;
            console.log({ vm });
        });
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public readonly assetsToSubtableVariants: AssetType[] = ['bankAccounts', 'receivables', 'liabilities'];

    public assetsToSubtable({
        assetReport,
        variant,
    }: {
        assetReport: AssetReportDto;
        variant: AssetType;
    }): RowData<ColumnKey>[] {
        const assets = [...assetReport[variant]];
        const subtableHeading: Record<AssetType, string> = {
            bankAccounts: 'Geldkontenstände',
            receivables: 'Forderungen',
            liabilities: 'Verbindlichkeiten',
        } as const;
        const subtableSummaryLabel: Record<AssetType, string> = {
            bankAccounts: 'Rücklagen gesamt',
            receivables: 'Forderungen gesamt',
            liabilities: 'Verbindlichkeiten gesamt',
        } as const;
        const heading: RowData<ColumnKey> = {
            cells: [
                {
                    key: 'name',
                    type: 'value',
                    value: subtableHeading[variant],
                    rowCellClass: '[&&]:tw-font-bold [&&]:tw-pb-4',
                },
            ],
        };
        const rows: RowData<ColumnKey>[] = assets.map((asset) => ({
            cells: [
                { key: 'name', type: 'value', value: asset.description },
                { key: 'amount', type: 'value', value: asset.amount, pipeToApply: 'eurocent' },
            ],
        }));
        const summaryRows: RowData<ColumnKey>[] = [
            { cells: [{ key: 'name', type: 'value', value: '', rowCellClass: '[&&]:tw-h-2' }] },
            {
                cells: [
                    {
                        key: 'name',
                        type: 'value',
                        value: subtableSummaryLabel[variant],
                        rowCellClass: '[&&]:tw-font-bold [&&]:tw-h-10 [&&]:tw-bg-scalaraGray-06',
                    },
                    {
                        key: 'amount',
                        type: 'value',
                        value: assets.reduce((acc, r) => acc + r.amount, 0),
                        rowCellClass: '[&&]:tw-font-bold [&&]:tw-h-10 [&&]:tw-bg-scalaraGray-06',
                        pipeToApply: 'eurocent',
                    },
                ],
            },
            { cells: [{ key: 'name', type: 'value', value: '', rowCellClass: '[&&]:tw-h-8' }] },
        ];
        return [heading, ...rows, ...summaryRows];
    }
}
