import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ListItem } from 'carbon-components-angular';
import {
    BehaviorSubject,
    combineLatest,
    finalize,
    forkJoin,
    map,
    Observable,
    of,
    Subject,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs';
import { LayoutMode, MediaQueriesService } from 'src/app/core/services/media-queries.service.ts.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { getNameFromPerson } from 'src/app/core/utils/common';
import { PersonLocalService } from 'src/app/features/property/services/person-local.service';
import {
    AttachmentDto,
    CreateAttachment,
    CreateTicketDto,
    Occupation,
    OccupationService,
    OwnershipsService,
    PropertiesService,
    Property,
    TicketDto,
    TicketsService,
    UpdateTicketDto,
} from 'src/app/generated-sources/base';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';
import { environment } from 'src/environments/environment';

interface ListItemWithId extends ListItem {
    id: string;
    permissions?: Property.PermissionRolesEnum[];
    propertyType?: Property.PropertyTypeEnum;
    isOccupation?: boolean;
}
@Component({
    selector: 'app-add-edit-ticket-form',
    templateUrl: './add-edit-ticket-form.component.html',
    styleUrls: ['./add-edit-ticket-form.component.scss'],
})
export class AddEditTicketFormComponent extends OverlayChildComponent implements OnInit {
    private unsubscribe$ = new Subject<void>();
    public ispropertyManager = false;
    public readonly fileSizeInMb = environment.maxFileSizeInMB;
    public areFilesFullyUploaded = true;
    public fileUuids: string[] = [];
    public properties: ListItemWithId[] = [];
    public ownerships: ListItemWithId[] = [];
    public ownershipsList: [ListItemWithId[]] = [[]];
    public ticketToEdit?: TicketDto;
    public isLoading = false;
    public propertyTypeSelected?: Property.PropertyTypeEnum;

    public form: UntypedFormGroup = this.formBuilder.group({
        property: [null, [Validators.required]],
        ownerships: this.formBuilder.array([]),
        title: [null, [Validators.required]],
        description: [null],
    });

    public vm?: {
        activeRouteLabel: string;
        currentLayoutMode: LayoutMode;
    };

    public activeRouteLabel$ = new BehaviorSubject<string>('');
    public vm$ = combineLatest([this.activeRouteLabel$, this.mediaQueriesService.getCurrentLayoutMode()]).pipe(
        map(([activeRouteLabel, currentLayoutMode]) => ({
            activeRouteLabel,
            currentLayoutMode,
        }))
    );

    public constructor(
        private propertiesService: PropertiesService,
        private ownershipService: OwnershipsService,
        private formBuilder: UntypedFormBuilder,
        private mediaQueriesService: MediaQueriesService,
        private ticketService: TicketsService,
        private toastService: ToastService,
        private translateService: TranslateService,
        private router: Router,
        private personLocalService: PersonLocalService,
        private occupationService: OccupationService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.isLoading = true;
        this.setPersonAndAccess();
        this.findProperties();

        this.vm$.pipe(takeUntil(this.unsubscribe$)).subscribe((vm) => {
            this.vm = vm;
        });

        if (this.config?.data.ticket) {
            this.ticketToEdit = this.config?.data.ticket;
            const objectToEdit = {
                title: this.ticketToEdit?.title,
                description: this.ticketToEdit?.description,
            };
            this.findOwndershipsToEdit();
            this.form.patchValue(objectToEdit);
        } else {
            this.addNewOwnership();
        }
    }

    public setPersonAndAccess(): void {
        this.personLocalService
            .getPerson$()
            .pipe(
                tap((person) => {
                    this.ispropertyManager = person.personRoles.includes('IS_PROPERTY_MANAGER');
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }

    private getPropertyLabel(property: Property): string {
        return (
            property.propertyType + ' ' + property.name
            // ', ' +
            // property.address.streetName +
            // ' ' +
            // property.address.streetNumber +
            // ', ' +
            // property.address.zipCode +
            // ' ' +
            // property.address.area
        );
    }

    public findProperties(): void {
        this.propertiesService
            .findAll()
            .pipe(
                tap((properties) => {
                    this.properties = properties.map((property) => {
                        const selected =
                            this.ticketToEdit && this.ticketToEdit.property.id === property.id ? true : false;
                        if (selected) {
                            this.form.get('property')?.patchValue({
                                selected: selected,
                                content: this.getPropertyLabel(property),
                                id: property.id,
                                permissions: property.permissionRoles,
                                propertyType: property.propertyType,
                            });
                        }
                        return {
                            selected: selected,
                            content: this.getPropertyLabel(property),
                            id: property.id,
                            permissions: property.permissionRoles,
                            propertyType: property.propertyType,
                        };
                    });
                }),
                takeUntil(this.unsubscribe$),
                finalize(() => (this.isLoading = false))
            )
            .subscribe();
    }

    public updateLoadingFilesStatus($event: boolean): void {
        this.areFilesFullyUploaded = $event;
    }

    public updateFileIdsLodaded($event: string[]): void {
        if ($event.length === 0) {
            this.areFilesFullyUploaded = true;
        }
        this.fileUuids = $event;
    }

    public get ownershipControl(): UntypedFormArray {
        return this.form.controls['ownerships'] as UntypedFormArray;
    }

    public get propertyControl(): UntypedFormControl | undefined | UntypedFormArray {
        return this?.form?.controls['property'] as UntypedFormControl | undefined | UntypedFormArray;
    }

    public get propertyControlIsNotEmpty(): boolean {
        const value = this.propertyControl?.value;
        if (!value) {
            return false;
        }
        if (Array.isArray(value) && value.length < 1) {
            return false;
        }
        return true;
    }

    public getOwnershipItemForm(index: number): UntypedFormGroup {
        return this.ownershipControl.at(index) as UntypedFormGroup;
    }

    public addNewOwnership(ownership?: ListItemWithId): void {
        const ownershipControl = this.form.get('ownerships') as UntypedFormArray;
        ownershipControl.push(
            this.formBuilder.group({
                ownership: [ownership ? ownership : ''],
            })
        );
    }

    public removeOwnership(index: number): void {
        const ownershipControl = this.form.get('ownerships') as UntypedFormArray;
        ownershipControl.removeAt(index);
    }

    public findOwndershipsToEdit(): void {
        this.propertyTypeSelected = this.ticketToEdit?.property.propertyType;

        if (this.ticketToEdit?.property.id) {
            (this.propertyTypeSelected === 'MV' || this.propertyTypeSelected === 'SEV'
                ? of([])
                : this.ownershipService.findAll(this.ticketToEdit?.property.id)
            )
                .pipe(
                    switchMap((ownerships) => {
                        if (this.propertyTypeSelected !== 'WEG') {
                            return this.occupationService.findAllOccupations(this.ticketToEdit?.property.id || '').pipe(
                                map((occupations) => ({ ownerships, occupations })) // Combine both ownerships and occupations
                            );
                        } else {
                            return of({ ownerships, occupations: [] });
                        }
                    }),

                    tap(({ ownerships, occupations }) => {
                        this.ownerships = [];

                        let thereIsNoOwnership = true;

                        this.ownerships = ownerships.map((ownership) => {
                            if (this.ticketToEdit?.ownerships) {
                                const findOwnership = this.ticketToEdit?.ownerships?.find(
                                    (item) => item.id === ownership.id
                                );
                                if (findOwnership) {
                                    thereIsNoOwnership = false;
                                    this.addNewOwnership({ selected: true, content: ownership.name, id: ownership.id });
                                }
                            }

                            return { selected: false, content: ownership.name, id: ownership.id };
                        });

                        if (occupations.length > 0) {
                            const occupationsList = occupations.map((occupation) => {
                                if (this.ticketToEdit?.occupations) {
                                    const findOwnership = this.ticketToEdit?.occupations?.find(
                                        (item) => item.id === occupation.id
                                    );
                                    if (findOwnership) {
                                        thereIsNoOwnership = false;
                                        this.addNewOwnership({
                                            selected: true,
                                            content: this.getOccupationLabel(occupation),
                                            id: occupation.id,
                                            isOccupation: true,
                                        });
                                    }
                                }

                                return {
                                    selected: false,
                                    content: this.getOccupationLabel(occupation),
                                    id: occupation.id,
                                    isOccupation: true,
                                };
                            });
                            if (this.ownerships.length === 0) {
                                this.ownerships = occupationsList;
                            } else {
                                this.ownerships.push(...occupationsList);
                            }
                        }

                        this.ownerships.push({ selected: false, content: 'Keine Auswahl', id: '' });
                        if (thereIsNoOwnership) {
                            this.addNewOwnership();
                        }
                    }),
                    takeUntil(this.unsubscribe$),
                    finalize(() => (this.isLoading = false))
                )
                .subscribe();
        }
    }

    public getOccupationLabel(occupation: Occupation): string {
        const tenant =
            occupation.tenants && occupation.tenants.length > 0 && occupation.tenants[0]
                ? getNameFromPerson(occupation.tenants[0])
                : '';

        return occupation.occupationNumber + (occupation.occupationType === 'VACANCY' ? '' : ', ' + tenant);
    }

    public findOwnderships(): void {
        (this.form.get('ownerships') as UntypedFormArray).patchValue([
            this.formBuilder.group({
                ownership: ['', Validators.required],
            }),
        ]);
        this.propertyTypeSelected = this.form?.value?.property.propertyType;

        if (this.form?.value?.property?.id) {
            (this.propertyTypeSelected === 'MV' || this.propertyTypeSelected === 'SEV'
                ? of([])
                : this.ownershipService.findAll(this.form?.value?.property?.id)
            )
                .pipe(
                    switchMap((ownerships) => {
                        if (this.propertyTypeSelected !== 'WEG') {
                            return this.occupationService.findAllOccupations(this.form?.value?.property?.id).pipe(
                                map((occupations) => ({ ownerships, occupations })) // Combine both ownerships and occupations
                            );
                        } else {
                            return of({ ownerships, occupations: [] });
                        }
                    }),
                    tap(({ ownerships, occupations }) => {
                        this.ownerships = [];
                        if (ownerships.length > 0) {
                            this.ownerships = ownerships.map((ownership) => ({
                                selected: false,
                                content: ownership.name + ', ' + ownership.building,
                                id: ownership.id,
                            }));
                        }
                        if (occupations.length > 0) {
                            const occupationsList = occupations.map((occupation) => {
                                return {
                                    selected: false,
                                    content: this.getOccupationLabel(occupation),
                                    id: occupation.id,
                                    isOccupation: true,
                                };
                            });
                            if (this.ownerships.length === 0) {
                                this.ownerships = occupationsList;
                            } else {
                                this.ownerships.push(...occupationsList);
                            }
                        }
                        this.ownerships.push({ selected: false, content: 'Keine Auswahl', id: '' });
                    }),
                    takeUntil(this.unsubscribe$)
                )
                .subscribe();
        }
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public onSubmit(): void {
        const ownershipIds = this.form.value.ownerships
            .filter((data: any) => data.ownership.id && !data.ownership.isOccupation)
            .map((data: any) => data.ownership.id);

        const occupationIds = this.form.value.ownerships
            .filter((data: any) => data.ownership.id && data.ownership.isOccupation)
            .map((data: any) => data.ownership.id);

        if (this.ticketToEdit && this.ticketToEdit.id) {
            const updateTicketDto: UpdateTicketDto = {
                title: this.form.value.title,
                description: this.form.value.description,
                ownershipIds: ownershipIds,
                serviceProviderIds: [],
                propertyId: this.form.value.property.id,
                occupationIds: occupationIds,
            };

            const requests: (Observable<TicketDto> | Observable<AttachmentDto[]>)[] = [
                this.ticketService.update(this.ticketToEdit.id, updateTicketDto),
            ];

            if (this.fileUuids.length > 0) {
                const fileStorageIds: CreateAttachment = { fileStorageIds: this.fileUuids };
                requests.push(this.ticketService.addAttachmentToTicket(this.ticketToEdit.id, fileStorageIds));
            }

            forkJoin(requests)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe({
                    next: () => {
                        this.toastService.showSuccess(
                            this.translateService.instant('PAGES.ADD_TICKET.TOAST_SUCCESS_EDIT')
                        );
                        this.saveEmitter$.next();
                    },
                    error: (error) => {
                        if (error) {
                            this.toastService.showError(error.error['message']);
                        }
                    },
                });
        } else {
            const ticketDto: CreateTicketDto = {
                title: this.form.value.title,
                description: this.form.value.description,
                ownershipIds: ownershipIds,
                serviceProviderIds: [],
                propertyId: this.form.value.property.id,
                fileStorageIds: this.fileUuids,
                occupationIds: occupationIds,
            };

            this.ticketService
                .create(ticketDto)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe({
                    next: (data) => {
                        this.toastService.showSuccess(this.translateService.instant('PAGES.ADD_TICKET.TOAST_SUCCESS'));
                        this.saveEmitter$.next();
                        this.router.navigate(['/communications', data.id]);
                    },
                    error: (error) => {
                        if (error) {
                            this.toastService.showError(error.error['message']);
                        }
                    },
                });
        }
    }

    public onSelectOwnership(selectedOwnership: any, index: number): void {
        if (!selectedOwnership.item?.id) {
            return;
        }
        const isDuplicate = this.form.value.ownerships.find((item: any, i: number) => {
            if (i === index) {
                return false;
            }
            return item.ownership.id === selectedOwnership.item?.id;
        });
        const ownershipErrorControl = this.getOwnershipItemForm(index).get('ownership');
        if (isDuplicate) {
            if (ownershipErrorControl) {
                ownershipErrorControl?.setErrors({ duplicate: true });
            }
        } else {
            ownershipErrorControl?.clearValidators();
        }
    }

    public isInvalid(index: number): boolean {
        return this.getOwnershipItemForm(index).get('ownership')?.errors?.['duplicate'];
    }

    public clearPropertyInput(): void {
        this.ownerships = [];

        (this.form.get('ownerships') as UntypedFormArray).patchValue([
            this.formBuilder.group({
                ownership: ['', Validators.required],
            }),
        ]);
    }

    public getLabelInput(): string {
        if (this.propertyTypeSelected === 'MV' || this.propertyTypeSelected === 'SEV') {
            return 'Nutzung';
        }
        if (this.propertyTypeSelected === 'WEG_SEV') {
            return 'Einheit oder Nutzung';
        }
        if (this.propertyTypeSelected === 'WEG') {
            return 'Einheit';
        }
        return '';
    }

    public getLabelButton(): string {
        if (this.propertyTypeSelected === 'MV' || this.propertyTypeSelected === 'SEV') {
            return 'PAGES.ADD_TICKET.NEW_OCCUPATION';
        }
        if (this.propertyTypeSelected === 'WEG_SEV') {
            return 'PAGES.ADD_TICKET.NEW_OCCUPATION_OWNERSHIP';
        }
        if (this.propertyTypeSelected === 'WEG') {
            return 'PAGES.ADD_TICKET.NEW_OWNERSHIP';
        }
        return '';
    }

    public getTooltipText(): string {
        if (!this.ispropertyManager) {
            return 'PAGES.ADD_TICKET.TOOLTIP_OWNER';
        }

        if (this.propertyTypeSelected === 'WEG') {
            return 'PAGES.ADD_TICKET.TOOLTIP_PROPERTY_MANAGER';
        }

        if (this.propertyTypeSelected === 'WEG_SEV') {
            return 'PAGES.ADD_TICKET.TOOLTIP_WEG_SEV';
        }

        if (this.propertyTypeSelected === 'MV' || this.propertyTypeSelected === 'SEV') {
            return 'PAGES.ADD_TICKET.TOOLTIP_SEV_MV';
        }

        return 'PAGES.ADD_TICKET.TOOLTIP_WEG_SEV';
    }
}
