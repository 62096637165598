import { Component, OnInit } from '@angular/core';
import { finalize, Subject, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { FilesService } from 'src/app/generated-sources/base';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';

@Component({
    selector: 'app-delete-document',
    templateUrl: './delete-document.component.html',
    styleUrls: ['./delete-document.component.scss'],
})
export class DeleteDocumentComponent extends OverlayChildComponent implements OnInit {
    public fileName = 'Sondernutzungsrechte.pdf';
    private fileId: string[] = [];
    public isLoading = false;
    private unsubscribe$ = new Subject<void>();

    public constructor(private filesService: FilesService, private toastService: ToastService) {
        super();
    }

    public ngOnInit(): void {
        this.fileName = this.config?.data.fileName;
        this.fileId.push(this.config?.data.id);
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public onSubmit(): void {
        this.filesService
            .deleteFiles(this.fileId)
            .pipe(
                takeUntil(this.unsubscribe$),
                finalize(() => (this.isLoading = false))
            )
            .subscribe({
                next: () => {
                    this.toastService.showSuccess('Dokument/Datei erfolgreich gelöscht');
                    this.saveEmitter$.next();
                },
                error: (error) => {
                    if (error) {
                        this.toastService.showError(error.error['message']);
                    }
                },
            });
    }
}
