<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>
<div class="tw-mt-8">
    <div class="tw-mb-12 tw-flex tw-items-center tw-space-x-3">
        <svg-icon class="tw-h-6 tw-w-6 tw-fill-scalaraBlue-01" src="/assets/icons/24_information.svg"></svg-icon>
        <div class="s-body-12-22-regular">
            {{ 'PAGES.BANK_TRANSACTIONS.BOOKING_RULES.BOOKING_RULE_INFO' | translate }}
        </div>
    </div>

    <div class="tw-mt-10">
        <div class="tw-flex tw-justify-between">
            <div class="tw-s-body-14-22-bold">Automatisch generierte Buchungsregeln</div>
            <app-search searchInputId="automaticBookingRulesTable"></app-search>
        </div>

        <app-table
            emptyText=""
            [header]="automaticBookingRulesTable.header"
            [data]="automaticBookingRulesTable.data"
            [itemsPerPage]="20"
            tableSearchId="automaticBookingRulesTable"
        >
        </app-table>

        <ng-container *ngIf="automaticBookingRulesTable.data.length === 0" [ngTemplateOutlet]="emptyBookingRuleTable">
        </ng-container>
    </div>

    <div class="tw-mt-20">
        <div class="tw-flex tw-justify-between">
            <div class="tw-s-body-14-22-bold">Manuell hinzugefügte Buchungsregeln</div>

            <div class="d-flex tw-space-x-2">
                <app-search searchInputId="manualBookingRulesTable"></app-search>
                <app-scalara-button
                    (click)="openAddBankingTransactionsBookingRulesOverlay()"
                    variant="ghost"
                    svgName="24_add.svg"
                >
                    {{ 'CONTROLS.BUTTONS.ADD' | translate }}
                </app-scalara-button>
            </div>
        </div>

        <app-table
            emptyText="PAGES.BANK_TRANSACTIONS.BOOKING_RULES.LABEL_EMPTY_MANUAL_BOOKING_RULES_TABLE"
            [header]="manualBookingRulesTable.header"
            [data]="manualBookingRulesTable.data"
            [itemsPerPage]="20"
            tableSearchId="manualBookingRulesTable"
        >
        </app-table>
    </div>
</div>

<ng-template #acceptOrRejectBookingRuleButtons let-data="data">
    <div class="tw-flex tw-space-x-1">
        <app-scalara-button
            (click)="accepctOrRecejectBookingRule(data.extraData?.bookingRule, 'ACTIVATED')"
            variant="ghost"
            height="40px"
            >Annehmen</app-scalara-button
        >

        <app-scalara-button
            (click)="accepctOrRecejectBookingRule(data.extraData?.bookingRule, 'REJECTED')"
            variant="ghost"
            height="40px"
            >Ablehnen</app-scalara-button
        >
    </div>
</ng-template>

<ng-template #emptyBookingRuleTable>
    <app-notification type="info">
        <div class="s-body-14-22-regular">
            <span class="tw-whitespace-break-spaces">{{
                'PAGES.BANK_TRANSACTIONS.BOOKING_RULES.LABEL_EMPTY_BOOKING_RULES_SUGGESTIONS_TABLE' | translate
            }}</span>
        </div>
    </app-notification>
</ng-template>
