<app-overlay-details
    [title]="pageTitle"
    iconColor="scalaraGreen"
    [iconSrc]="'40_technical-equipment-' + (technicalEquipment?.equipmentTypeId ?? '1') + '.svg'"
    [itemListForPagination]="technicalEquipmentsIdList"
    [currentItemId]="(technicalEquipmentCurrentId$ | async) ?? undefined"
    (changeItemOnOverlay)="findNewEquipmentId($event)"
    [overlayCallbacks]="overlayCallbacks"
    [documentsList]="technicalEquipment?.technicalEquipmentFiles"
>
    <div *ngFor="let item of fieldsToShow" class="tw-whitespace-break-spaces">
        <div
            class="tw-grid tw-grid-cols-4 tw-mb-2"
            *ngIf="item.key !== 'specificationArray' && item.key !== 'technicalEquipmentFiles'"
        >
            <div class="tw-col-span-1 tw-s-label-12-22-semibold tw-text-scalaraGray-03">{{ item.label }}</div>
            <div
                class="tw-col-start-2 tw-col-span-full tw-s-body-14-22-regular"
                *ngIf="technicalEquipment && technicalEquipment[item.key]; else notSpecifiedLabel"
            >
                {{ technicalEquipment[item.key] }}
            </div>
        </div>

        <div class="tw-grid tw-grid-cols-4 tw-mb-2" *ngIf="item.key === 'specificationArray'">
            <div class="tw-col-span-1 tw-s-label-12-22-semibold tw-text-scalaraGray-03">Merkmale</div>
            <div
                class="tw-col-start-2 tw-col-span-full tw-s-body-14-22-regular"
                *ngIf="
                    technicalEquipment &&
                        technicalEquipment.specificationArray &&
                        technicalEquipment.specificationArray.length > 0;
                    else notSpecifiedLabel
                "
            >
                <div *ngFor="let item of technicalEquipment.specificationArray">
                    {{ item }}
                </div>
            </div>
        </div>
    </div>
</app-overlay-details>

<ng-template #notSpecifiedLabel>
    <div class="s-body-14-22-italic">
        {{ 'PAGES.OWNERSHIP.DETAIL_VIEW.LABEL_NOT_SPECIFIED' | translate }}
    </div>
</ng-template>
