import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChange,
    SimpleChanges,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { BehaviorSubject, ObservedValueOf, Subject, combineLatest, map, takeUntil } from 'rxjs';
import { WssDto } from 'src/app/generated-sources/accounting';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { AddAnnualStatementConsumptionKeyComponent } from '../add-annual-statement-consumption-key/add-annual-statement-consumption-key.component';
import { AddWssDistributionKeyComponent } from '../add-wss-distribution-key/add-wss-distribution-key.component';
import { AssetReportComponent } from '../asset-report/asset-report.component';
import { BillingResultsOverviewComponent } from '../billing-results-overview/billing-results-overview.component';
import { IncomeAndExpenseStatementComponent } from '../income-and-expense-statement/income-and-expense-statement.component';
import { ReservesDevelopmentComponent } from '../reserves-development/reserves-development.component';

type ComponentType =
    | typeof IncomeAndExpenseStatementComponent
    | typeof BillingResultsOverviewComponent
    | typeof ReservesDevelopmentComponent
    | typeof AssetReportComponent;
type TabListItem = {
    label: string;
    id: number;
    // link?: string;
    // callback?: () => void;
    selected: boolean;
    component: ComponentType;
};

@Component({
    selector: 'app-annual-statement-tabs',
    templateUrl: './annual-statement-tabs.component.html',
    styleUrls: ['./annual-statement-tabs.component.scss'],
})
export class AnnualStatementTabsComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild('vcr_complete_statement', { static: false, read: ViewContainerRef })
    public vcr_complete_statement?: ViewContainerRef;

    @Input() public ledgerId?: string;
    @Input() public wss?: WssDto;
    @Input() public wssDate?: string;
    @Input() public triggerTabRefreshValue = 1;

    public unsubscribe$ = new Subject<void>();
    public ledgerId$ = new BehaviorSubject<string | null>(null);
    public wss$ = new BehaviorSubject<WssDto | null>(null);
    public refresh$ = new BehaviorSubject(null);

    public completeStatementTabItems: TabListItem[] = [
        {
            label: 'Einnahmen und Ausgabenrechnung',
            id: 0,
            component: IncomeAndExpenseStatementComponent,
            selected: false,
        },
        {
            label: 'Übersicht der Abrechnungsergebnisse aller Einheiten',
            id: 1,
            component: BillingResultsOverviewComponent,
            selected: false,
        },
        { label: 'Entwicklung der Rücklagen', id: 2, component: ReservesDevelopmentComponent, selected: false },
        { label: 'Vermögensbericht', id: 3, component: AssetReportComponent, selected: false },
    ];

    public constructor(private overlayService: OverlayService, private viewContainerRef: ViewContainerRef) {}

    public calculateState(): { showing: 'listItems' | 'selectedComponent' } {
        const selectedTabItem = this.completeStatementTabItems.find((tabItem) => tabItem.selected);
        if (selectedTabItem) {
            return { showing: 'selectedComponent' };
        }
        return { showing: 'listItems' };
    }

    public getSelectedItem(): TabListItem | undefined {
        return this.completeStatementTabItems.find((tabItem) => tabItem.selected);
    }

    public selectCompleteStatementTabItem(tabItem: TabListItem): void {
        console.log({ tabItem });
        this.completeStatementTabItems.forEach((i) => (i.selected = false));
        const index = this.completeStatementTabItems.findIndex((i) => i.id === tabItem.id);
        this.completeStatementTabItems[index].selected = true;
        console.log({ completeStatementTabItems: this.completeStatementTabItems });

        if (!this.vcr_complete_statement) {
            console.log('no vcr_complete_statement');
            return;
        }

        const ref = this.vcr_complete_statement.createComponent(tabItem.component as any);
    }

    public unselectCompleteStatementTabItem(): void {
        this.completeStatementTabItems.forEach((tabItem) => (tabItem.selected = false));
        this.vcr_complete_statement?.clear();
    }

    public vm$ = combineLatest([this.ledgerId$, this.wss$, this.refresh$]).pipe(
        map(([ledgerId, wss]) => ({
            ledgerId,
            wss,
        }))
    );
    public vm: null | ObservedValueOf<typeof this.vm$> = null;

    public ngOnInit(): void {
        this.vm$.pipe(takeUntil(this.unsubscribe$)).subscribe((vm) => {
            this.vm = vm;
        });
        this.refreshTabs();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        const ledgerIdChange: SimpleChange = changes['ledgerId'];
        const wssChange: SimpleChange = changes['wss'];
        const refreshChange: SimpleChange = changes['triggerTabRefreshValue'];
        const ledgerIdChanged = ledgerIdChange && ledgerIdChange.currentValue !== ledgerIdChange.previousValue;
        const wssChanged = wssChange && wssChange.currentValue !== wssChange.previousValue;
        if (ledgerIdChanged && this.ledgerId) {
            this.ledgerId$.next(this.ledgerId);
        }
        if (wssChanged && this.wss) {
            this.wss$.next(this.wss);
        }
        if (refreshChange) {
            this.refreshTabs();
        }
    }

    public refreshTabs(): void {
        this.triggerTabRefreshValue = this.triggerTabRefreshValue + 1;
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public openAddDistributionKeyOverlay(distributionBase: 'CONSUMPTION' | 'DISTRIBUTION_KEY'): void {
        const ref = this.overlayService.open(
            distributionBase === 'CONSUMPTION'
                ? AddAnnualStatementConsumptionKeyComponent
                : AddWssDistributionKeyComponent,
            {
                data: { ledgerId: this.ledgerId, wssId: this.wss?.id },
            }
        );

        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.triggerTabRefreshValue++;
        });
    }
    // TODO: check if vcr need to be destoyed in hook
}
