<ibm-label
    [formGroup]="form"
    [invalid]="
        inputName === 'password' ? passwordInvalid && !isTooltipVisible : passwordConfirmInvalid && !isTooltipVisible
    "
    invalidText="{{ 'CONTROLS.FORMCONTROLS.PASSWORD.INVALID' | translate }}"
    class="password-input"
>
    <span class="d-flex justify-space-between align-items-center password-label">{{ label }} </span>
    <input
        ibmText
        [type]="isPasswordVisible ? 'text' : 'password'"
        name="{{ inputName }}"
        id="{{ inputName }}"
        formControlName="{{ inputName }}"
        [invalid]="
            inputName === 'password'
                ? passwordInvalid && !isTooltipVisible
                : passwordConfirmInvalid && !isTooltipVisible
        "
        class="d-block"
        (focus)="setTooltipVisibility(true)"
        (blur)="setTooltipVisibility(false)"
    />
    <div class="password-visibility" (click)="setPasswordVisibility()">
        <svg-icon *ngIf="isPasswordVisible" src="/assets/icons/24_view.svg"></svg-icon>
        <svg-icon *ngIf="!isPasswordVisible" src="/assets/icons/24_view--off.svg"></svg-icon>
    </div>
    <app-password-tooltip
        *ngIf="inputName === 'password' && isTooltipVisible"
        [requirementErrors]="passwordRequirementsErrors"
    ></app-password-tooltip>
</ibm-label>
