import { DecimalPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    EconomicPlanDetailsAccountForOwnershipDto,
    EconomicPlanDetailsDto,
    SingleEconomicPlanDetailsDto,
} from 'src/app/generated-sources/accounting';

import { EurocentPipe } from 'src/app/shared/pipes/eurocent.pipe';

export type EconomicPlanType = 'complete' | 'single';
type ColumnHeader = {
    label: string;
    class: string;
};
type SubtableData = {
    headlingLabel: string;
    dataRowValues: string[][];
    summaryRowValues: string[] | null;
};

type GrouppedAccountsKeys = keyof Pick<
    EconomicPlanDetailsDto,
    'revenueAccounts' | 'expenseAccounts' | 'apportionableExpenseAccounts'
>;

type BreaklineWithSummary = { values: [number, number]; labels: [string, string] } | null;

@Component({
    selector: 'app-weg-economic-plan-details-table',
    templateUrl: './weg-economic-plan-details-table.component.html',
    styleUrls: ['./weg-economic-plan-details-table.component.scss'],
})
export class WegEconomicPlanDetailsTableComponent implements OnInit {
    @Input() public economicPlan: EconomicPlanDetailsDto | SingleEconomicPlanDetailsDto = {} as any;
    @Input() public economicPlanType: EconomicPlanType = 'single';
    @Input() public isLoading = false;

    public constructor(public translateService: TranslateService) {}

    public readonly grouppedAccountsKeysArray: GrouppedAccountsKeys[] = [
        'revenueAccounts',
        'apportionableExpenseAccounts',
        'expenseAccounts',
    ];

    public readonly subtableHeadingTranslations: Record<GrouppedAccountsKeys, string> = {
        apportionableExpenseAccounts: this.translateService.instant(
            'PAGES.ECONOMIC_PLAN.TABLE.ACCOUNTS_TYPE.APROPORTIONABLE'
        ),
        expenseAccounts: this.translateService.instant('PAGES.ECONOMIC_PLAN.TABLE.ACCOUNTS_TYPE.NON_APROPORTIONABLE'),
        revenueAccounts: this.translateService.instant('PAGES.ECONOMIC_PLAN.TABLE.ACCOUNTS_TYPE.REVENUE'),
    };

    public ngOnInit(): void {
        if (!this.economicPlan || Object.keys(this.economicPlan).length < 1) {
            return;
        }
    }

    public get columnHeaders(): ColumnHeader[] {
        const mainColumnConditionalName: Record<EconomicPlanType, string> = {
            complete: this.translateService.instant('ENTITIES.ECONOMIC_PLAN.LABEL_PLAN_TYPE_TOTAL'),
            single: this.translateService.instant('ENTITIES.ECONOMIC_PLAN.LABEL_PLAN_TYPE_SINGLE'),
        };
        const baseSecondaryColumnNames = [
            this.translateService.instant('PAGES.ECONOMIC_PLAN.TABLE.COLUMNS.TOTAL_DISTIBUTION_AMOUNT'),
            this.translateService.instant('PAGES.ECONOMIC_PLAN.TABLE.COLUMNS.DISTRIBUTION_KEY_NAME'),
            this.translateService.instant('PAGES.ECONOMIC_PLAN.TABLE.COLUMNS.TOTAL_DISTIBUTION'),
        ];
        const additionalSecondaryColumnNames = [
            this.translateService.instant('PAGES.ECONOMIC_PLAN.TABLE.COLUMNS.OWNERSHIP_DISTRIBUTION_SHARE'),
            this.translateService.instant('PAGES.ECONOMIC_PLAN.TABLE.COLUMNS.OWNERSHIP_DISTRIBUTION_AMOUNT'),
        ];

        const secondaryColumnConditionalNames: Record<EconomicPlanType, string[]> = {
            complete: [...baseSecondaryColumnNames],
            single: [...baseSecondaryColumnNames, ...additionalSecondaryColumnNames],
        };

        const baseHeaderCellClass = 'header-cell';
        const mainColumnClasses = `${baseHeaderCellClass} s-head-16-24-bold text-left`;
        const secondaryColumnClasses = `${baseHeaderCellClass} s-label-12-22-semibold text-right`;

        const result = [
            {
                label: mainColumnConditionalName[this.economicPlanType],
                class: mainColumnClasses,
            },
            ...secondaryColumnConditionalNames[this.economicPlanType].map((label) => ({
                label,
                class: secondaryColumnClasses,
            })),
        ];

        return result;
    }

    public getManagementCostsSubtableDataArray(): SubtableData[] {
        const result = this.grouppedAccountsKeysArray.map((key) => this.getManagementCostsSubtableData(key));
        return result;
    }

    public getManagementCostsSubtableData(key: GrouppedAccountsKeys): SubtableData {
        const NO_ROWS_LABEL = this.translateService.instant('PAGES.ECONOMIC_PLAN.TABLE.NO_ROWS_LABEL');

        const dataRowValuesForCompletePlan: SubtableData['dataRowValues'] =
            this.economicPlan && this.economicPlan[key].length > 0
                ? this.accountsToDataRowValues(this.economicPlan[key] as EconomicPlanDetailsAccountForOwnershipDto[])
                : [[NO_ROWS_LABEL]];
        const summaryRowValuesForCompletePlan: SubtableData['summaryRowValues'] =
            this.economicPlan && this.economicPlan[key].length > 0
                ? this.accountsToSummaryRowValues(this.economicPlan[key] as EconomicPlanDetailsAccountForOwnershipDto[])
                : null;
        const result: SubtableData = {
            headlingLabel: this.subtableHeadingTranslations[key],
            dataRowValues: dataRowValuesForCompletePlan,
            summaryRowValues: summaryRowValuesForCompletePlan,
        };
        return result;
    }

    public get managementCosts(): BreaklineWithSummary {
        if (this.economicPlan === null) {
            return null;
        }
        const f = this.formatCurrency;
        //  totalDistributionAmount = expenses - revenue; we need getOperator to get the right sign for calculation
        const getOperator = (key: (typeof this.grouppedAccountsKeysArray)[number]): 1 | -1 =>
            key === 'revenueAccounts' ? -1 : 1;
        const totalDistributionAmount = this.grouppedAccountsKeysArray.reduce(
            (prev, key) =>
                prev +
                (this.economicPlan[key] as EconomicPlanDetailsAccountForOwnershipDto[]).reduce(
                    (prev, curr) => prev + (curr.forecastCurrentYear ?? 0),
                    0
                ) *
                    getOperator(key),
            0
        );
        const sharedAmount = this.grouppedAccountsKeysArray.reduce(
            (prev, key) =>
                prev +
                (this.economicPlan[key] as EconomicPlanDetailsAccountForOwnershipDto[]).reduce(
                    (prev, curr) => prev + (curr.ownershipDistributionAmount ?? 0),
                    0
                ) *
                    getOperator(key),
            0
        );

        return {
            values: [totalDistributionAmount, sharedAmount],
            labels: [f(totalDistributionAmount), f(sharedAmount)],
        };
    }

    public get finalCosts(): BreaklineWithSummary {
        if (this.economicPlan === null) {
            return null;
        }
        const f = this.formatCurrency;
        const managementCosts = this.managementCosts;
        if (managementCosts === null) {
            return {
                values: [0, 0],
                labels: ['0', '0'],
            };
        }
        const accounts = [
            ...this.economicPlan.reserveFundAccounts,
            ...this.economicPlan.individualReserveFundAccounts,
        ] as EconomicPlanDetailsAccountForOwnershipDto[];
        const totalDistributionAmount =
            managementCosts.values[0] +
            accounts.reduce((prev, account) => prev + (account.forecastCurrentYear ?? 0), 0);

        const sharedAmount =
            managementCosts.values[1] +
            accounts.reduce((prev, account) => prev + (account.ownershipDistributionAmount ?? 0), 0);

        return {
            values: [totalDistributionAmount, sharedAmount],
            labels: [f(totalDistributionAmount), f(sharedAmount)],
        };
    }

    public getReserveFundSubtableDataArray(): SubtableData[] {
        const accounts = this.economicPlan
            ? [...this.economicPlan.reserveFundAccounts, ...this.economicPlan.individualReserveFundAccounts]
            : [];
        const reserveResult = accounts.map((acc) => this.getReserveFundSubtableData(acc));
        return reserveResult;
    }

    public getReserveFundSubtableData(account: EconomicPlanDetailsDto['reserveFundAccounts'][number]): SubtableData {
        const result: SubtableData = {
            headlingLabel: account.accountName,
            dataRowValues: this.accountsToDataRowValues([account as EconomicPlanDetailsAccountForOwnershipDto]),
            summaryRowValues: this.accountsToSummaryRowValues([account as EconomicPlanDetailsAccountForOwnershipDto]),
        };
        return result;
    }

    public accountsToDataRowValues(
        accounts: SingleEconomicPlanDetailsDto['reserveFundAccounts']
    ): SubtableData['dataRowValues'] {
        const CONTRIBUTIONS_LABEL = this.translateService.instant('PAGES.ECONOMIC_PLAN.TABLE.CONTRIBUTIONS_LABEL');
        //  if there is only one account, it means that this is 'Rucklage'
        //  table, where account names must be prefixed with 'Beiträge'
        const accountNamePrefix = accounts.length === 1 ? CONTRIBUTIONS_LABEL : '';
        const getAccountDescription = (account: (typeof accounts)[number]): string =>
            account.accountDescription ? `, ${account.accountDescription}` : '';
        const f = this.formatCurrency;
        const d = this.formatDecimal;

        accounts = accounts.filter((item) => {
            return (
                item.forecastCurrentYear > 0 ||
                item.forecastCurrentYear < 0 ||
                item.ownershipDistributionAmount > 0 ||
                item.ownershipDistributionAmount < 0
            );
        });
        return accounts.map((account) => [
            `${accountNamePrefix}${account.accountName}` + getAccountDescription(account),
            f(account.forecastCurrentYear ?? 0),
            account.distributionKeyName,
            d(account.totalDistribution),
            ...(this.economicPlanType === 'single'
                ? [d(account.ownershipDistributionShare ?? 0), f(account.ownershipDistributionAmount ?? 0)]
                : []),
        ]);
    }

    public accountsToSummaryRowValues(
        accounts: SingleEconomicPlanDetailsDto['reserveFundAccounts']
    ): SubtableData['summaryRowValues'] {
        const SUBTOTAL_LABEL = this.translateService.instant('PAGES.ECONOMIC_PLAN.TABLE.SUBTOTAL_LABEL');

        const f = this.formatCurrency;
        const totalDistributionAmountSummary = f(
            accounts.reduce((prev, curr) => prev + (curr.forecastCurrentYear ?? 0), 0)
        );
        const ownershipDistributionAmountSummary = f(
            accounts.reduce((prev, curr) => prev + (curr.ownershipDistributionAmount ?? 0), 0)
        );
        const optionalValues = this.economicPlanType === 'single' ? ['', ownershipDistributionAmountSummary] : [];
        return [SUBTOTAL_LABEL, totalDistributionAmountSummary, '', '', ...optionalValues];
    }

    public asSubtableDataArray = (data: SubtableData[]): SubtableData[] => data as SubtableData[];

    public formatCurrency = (value: number): string => {
        const euroCent = new EurocentPipe();
        return euroCent.transform(value);
    };

    public formatDecimal = (value: number): string => {
        const decimal = new DecimalPipe('de-De');
        return decimal.transform(value, '1.0-3') ?? 'ERROR';
    };
}
