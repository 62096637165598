import { Injectable } from '@angular/core';
import { NotificationService } from 'carbon-components-angular/notification';
@Injectable({
    providedIn: 'root',
})
export class ToastService {
    private readonly DURATION = 4000;

    // private notificationService!: NotificationService;

    public constructor(private notificationService: NotificationService) {}
    // public constructor(private injector: Injector, private ngZone: NgZone) {}

    // public registerViewContainerRef(ref: ViewContainerRef): void {
    //     this.viewContainerRef = ref;
    //     this.notificationService = new NotificationService(this.injector, this.viewContainerRef, this.ngZone);
    // } for componente > 5.15

    public showError(title?: string, caption?: string): void {
        if (!title) {
            console.error("'title' must not be empty");
            return;
        }
        this.notificationService.showToast({
            title,
            caption,
            type: 'error',
            duration: this.DURATION,
            target: '#notification-container',
        });
    }

    public showInfo(title?: string, caption?: string): void {
        if (!title) {
            console.error("'title' must not be empty");
            return;
        }
        this.notificationService.showToast({
            title,
            caption,
            type: 'info',
            duration: this.DURATION,
            target: '#notification-container',
        });
    }

    public showSuccess(title?: string, caption?: string): void {
        if (!title) {
            console.error("'title' must not be empty");
            return;
        }
        this.notificationService.showToast({
            title,
            caption,
            type: 'success',
            duration: this.DURATION,
            target: '#notification-container',
        });
    }
}
