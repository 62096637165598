<app-modal *ngIf="fileToView">
    <div class="header-modal flex-column-reverse lg-flex-direction-row">
        <div class="s-body-14-22-semibold">
            {{ fileToView.fileName }}
        </div>
        <div class="d-flex align-items-center actions">
            <a [href]="fileToView.file" download class="mr-04 download-button d-flex align-items-center">
                <svg-icon src="/assets/icons/24_download.svg"></svg-icon>
                <div class="s-white s-body-14-22-semibold ml-04">
                    {{ 'PAGES.RECEIPTS.DOWNLOAD' | translate }}
                </div>
            </a>
            <button (click)="close()" class="close-button ml-10">
                <svg-icon src="/assets/icons/24_close.svg"></svg-icon>
            </button>
        </div>
    </div>
    <div *ngIf="fileToView && isPdf" class="file-viewer-pdf">
        <ngx-extended-pdf-viewer
            [src]="fileToView.file"
            [useBrowserLocale]="true"
            [filenameForDownload]="fileToView.fileName"
            [showOpenFileButton]="false"
            [height]="'calc(100vh - 122px)'"
        >
        </ngx-extended-pdf-viewer>
    </div>
    <div *ngIf="fileToView && isImg" class="file-viewer-image">
        <img
            class="file-viewer-image__img"
            [src]="fileToView.file"
            [alt]="fileToView.fileName"
            [style.height]="'calc(100vh - 122px)'"
            ngxViewer
            [viewerOptions]="viewerOptions"
        />
    </div>

    <div
        *ngIf="excelData.length > 0 && isSheets"
        class="tw-bg-scalaraWhite tw-p-8 tw-w-full tw-h-auto tw-overflow-x-auto"
    >
        <table *ngFor="let data of excelData" class="tw-w-full tw-h-full tw-mb-8">
            <thead>
                <tr>
                    <th
                        class="s-label-12-22-semibold tw-text-left tw-p-4 tw-border-b-[1px] tw-border-scalaraGray-05 tw-border-solid"
                        *ngFor="let header of data.headers"
                    >
                        {{ header }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of data.rows">
                    <td
                        class="s-body-14-22-regular tw-p-4 tw-border-b-[1px] tw-border-scalaraGray-05 tw-border-solid"
                        *ngFor="let cell of row"
                    >
                        {{ cell }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</app-modal>
