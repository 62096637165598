/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

export enum ListGeneratorFlowEnum {
    'selectProperty' = 'selectProperty',
    'allProperties' = 'allProperties',
}
@Component({
    selector: 'app-list-generator-overview',
    templateUrl: './list-generator-overview.component.html',
    styleUrls: ['./list-generator-overview.component.scss'],
})
export class ListGeneratorOverviewComponent {
    public constructor(
        private router: Router,
        private route: ActivatedRoute,
        private translateService: TranslateService
    ) {}
    private readonly translationKeys: { [KEY in ListGeneratorFlowEnum]: string } = {
        selectProperty: 'PAGES.LIST_GENERATOR.SELECT_PROPERTY',
        allProperties: 'PAGES.LIST_GENERATOR.ALL_PROPERTIES',
    };

    public readonly listGeneratorTypes: { icon: string; title: string; onClick: () => any }[] = [
        {
            icon: '40_immo-auswahl.svg',
            title: this.translateService.instant(this.translationKeys.selectProperty),
            onClick: () =>
                this.router.navigate(['details'], {
                    relativeTo: this.route,
                    queryParams: { flow: ListGeneratorFlowEnum['selectProperty'] },
                }),
        },
        {
            icon: '40_dokument.svg',
            title: this.translateService.instant(this.translationKeys.allProperties),

            onClick: () =>
                this.router.navigate(['details'], {
                    relativeTo: this.route,
                    queryParams: { flow: ListGeneratorFlowEnum['allProperties'] },
                }),
        },
    ];
}
