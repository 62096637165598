/**
 * SCALARA Accounting Module
 * The SCALARA Accounting API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BookingRecommendationInfoDto { 
    lastRecommendationGenerationDate: string;
    recommendationGenerationStatus: BookingRecommendationInfoDto.RecommendationGenerationStatusEnum;
    numberOfUnprocessedBookings: number;
}
export namespace BookingRecommendationInfoDto {
    export type RecommendationGenerationStatusEnum = 'PENDING' | 'RUNNING' | 'FINISHED';
    export const RecommendationGenerationStatusEnum = {
        Pending: 'PENDING' as RecommendationGenerationStatusEnum,
        Running: 'RUNNING' as RecommendationGenerationStatusEnum,
        Finished: 'FINISHED' as RecommendationGenerationStatusEnum
    };
}


