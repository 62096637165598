import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbItem, ListItem } from 'carbon-components-angular';
import { Subject, of, switchMap, takeUntil } from 'rxjs';
import { BreadCrumbService } from 'src/app/core/services/breadcrumb.service';
import { BankAccountsService, BankTransactionForListDto } from 'src/app/generated-sources/accounting';
import { TableModel } from 'src/app/shared/table/interfaces/table-model';
import { FilterDate } from '../../../../interfaces';
import { LedgerCustomService } from '../../../services/ledger-custom.service';

@Component({
    selector: 'app-banking-transactions-index',
    templateUrl: './banking-transactions-index.component.html',
    styleUrls: ['./banking-transactions-index.component.scss'],
})
export class BankingTransactionsIndexComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();

    public readonly FILTER_DATE_URL_PARAM_NAME = 'bankTransactionsFilterDateId';
    public readonly DEFAULT_SELECTED_FILTER_DATE_ID = '1';

    public tableModel: TableModel = { data: [], header: [] };
    public isLoading = false;
    public bankTransactions: BankTransactionForListDto[] = [];
    public amountUnbookedTransaction = 0;
    public amountRecommendedBookings = 0;
    public amountBookingsRule = 0;
    public ledgerId = '';
    public thereIsAccount = false;
    public openSuggestedTabOnInit = false;
    public openBookingRuleSuggestionOnInit = false;

    public selectedFilterDate?: FilterDate = undefined;

    public bankAccounts = this.ledgerCustomService.getLedgerId$().pipe(
        switchMap((ledgerId) => {
            if (!ledgerId) {
                return of();
            }
            this.ledgerId = ledgerId;
            return this.bankAccountsService.findAll(ledgerId);
        })
    );

    public constructor(
        public translateService: TranslateService,
        private route: ActivatedRoute,
        private breadcrumbService: BreadCrumbService,
        private router: Router,
        private ledgerCustomService: LedgerCustomService,
        private bankAccountsService: BankAccountsService
    ) {
        const routeState = this.router.getCurrentNavigation();
        if (routeState && routeState.extras.state) {
            this.openSuggestedTabOnInit = routeState.extras.state['openSuggestedTabOnInit'];
            this.openBookingRuleSuggestionOnInit = routeState.extras.state['openBookingRuleSuggestionOnInit'];
        }
    }

    public filterItems: ListItem[] | null = null;

    private updateUrlParams(selectedFilerId: string): void {
        const params = { [this.FILTER_DATE_URL_PARAM_NAME]: selectedFilerId };
        const urlTree = this.router.createUrlTree([], {
            queryParams: params,
            queryParamsHandling: 'merge',
            relativeTo: this.route,
        });

        this.router.navigateByUrl(urlTree);
    }

    public ngOnInit(): void {
        this.ledgerCustomService
            .getLedgerId$()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((ledgerId) => {
                if (ledgerId) {
                    this.ledgerId = ledgerId;
                    this.updateBreadcrumb(ledgerId);
                }
            });
    }

    public updateAmountRecommendedBookings($event: any): void {
        this.amountRecommendedBookings = $event;
    }

    public updateAmountBookingsRule($event: any): void {
        this.amountBookingsRule = $event;
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public onSelectFilter($event: any): void {
        this.selectedFilterDate = { startDate: $event.item.startDate, endDate: $event.item.endDate };
        this.updateUrlParams($event.item.id);
    }

    private updateBreadcrumb(ledgerId: string): void {
        const breadcrumb: BreadcrumbItem[] = [
            {
                content: this.translateService.instant('ACCOUNTING.BANK_TRANSACTION_DETAILS.MOVEMENTS'),
                current: true,
                route: [`/accounting/ledger/${ledgerId}/bank-transactions`],
            },
        ];
        this.breadcrumbService.resetBreadCrumbs();
        this.breadcrumbService.updateBreadCrumbs(breadcrumb);
    }
}
