import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, finalize, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { OperationsExpenseStatementsService } from 'src/app/generated-sources/accounting';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';

@Component({
    selector: 'app-delete-oes-distribution-key',
    templateUrl: './delete-oes-distribution-key.component.html',
    styleUrls: ['./delete-oes-distribution-key.component.scss'],
})
export class DeleteOesDistributionKeyComponent extends OverlayChildComponent implements OnInit {
    private unsubscribe$ = new Subject<void>();
    public isLoading = false;
    public ledgerId = '';
    public oesId = '';
    public oesDistributionKeyDescription = '';
    public keyId = '';

    public constructor(
        private operationsExpenseStatementsService: OperationsExpenseStatementsService,
        private toastService: ToastService,
        private translateService: TranslateService,
        private router: Router
    ) {
        super();
    }

    public ngOnInit(): void {
        this.ledgerId = this.config?.data.ledgerId;
        this.oesId = this.config?.data.oesId;
        this.keyId = this.config?.data.keyId;
        this.oesDistributionKeyDescription = this.config?.data.oesDistributionKeyDescription;
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public submit(): void {
        this.isLoading = true;

        this.operationsExpenseStatementsService
            .deleteDistributionKey(this.ledgerId, this.oesId, this.keyId)
            .pipe(
                takeUntil(this.unsubscribe$),
                finalize(() => (this.isLoading = false))
            )
            .subscribe({
                next: () => {
                    this.toastService.showSuccess(
                        this.translateService.instant('PAGES.DISTRIBUTION_KEYS.DELETE.TOAST_SUCCESS_MESSAGE')
                    );
                    this.saveEmitter$.next();
                    this.router.navigate([
                        '/accounting',
                        'ledger',
                        this.ledgerId,
                        'operations-expense-statements',
                        this.oesId,
                    ]);
                },
                error: (error) => {
                    if (error) {
                        this.toastService.showError(error.error['message']);
                    }
                },
            });
    }
}
