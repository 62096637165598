<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>
<div ibmGrid class="bx--grid--full-width">
    <div ibmRow [ngClass]="'mb-08'">
        <div ibmCol class="text-left">
            <div class="s-head-28-36-bold">Wollen Sie wirklich Person: {{ personId }} vertreten?</div>
            <hr class="mt-08" />
            <pre><code>Details der Person:</code><code>{{ selectedPerson | json }}</code></pre>
        </div>
    </div>
    <hr class="mt-08" />
    <div ibmRow class="mt-11">
        <div ibmCol class="text-right">
            Achtung: Bei Bestätigung wird der Benutzer automatisch ausgeloggt und es muss sich neu Angemeldet werden!
        </div>
    </div>
    <div ibmRow class="mt-11">
        <div ibmCol>
            <button [ibmButton]="'tertiary'" type="reset" (click)="abort()">
                {{ 'COMMON.BTN_CANCEL' | translate }}
            </button>
        </div>

        <div ibmCol class="text-right">
            <button [ibmButton]="'primary'" type="submit" (click)="approve()">Vertreten!</button>
        </div>
    </div>
</div>
