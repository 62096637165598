import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil, tap } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { LedgerCustomService } from 'src/app/features/accounting/components/services/ledger-custom.service';
import { Payment, PaymentsService } from 'src/app/generated-sources/accounting';
import { ButtonVariant } from 'src/app/shared/components/scalara-button/scalara-button.component';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';

@Component({
    selector: 'app-banking-payment-delete-overlay',
    templateUrl: './banking-payment-delete-overlay.component.html',
    styleUrls: ['./banking-payment-delete-overlay.component.scss'],
})
export class BankingPaymentDeleteOverlayComponent extends OverlayChildComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();
    public payment?: Payment;
    public ledgerId = '';

    // TODO: override isRecurring to true or false depending on payment type
    //  if Angular change detection does not work here and template is not updated ->
    //  call ChangeDetectorRef.detectChanges manually
    public ctx = {
        isRecurring: false,
        headlineI18Key: {
            base: 'PAGES.DELETE_PAYMENT.HEADLINE',
            recurring: 'PAGES.DELETE_PAYMENT.HEADLINE_RECURRING_PAYMENT',
        },
        overlayInputs: {
            iconSrc: {
                base: '40_delete.svg',
                recurring: '40_error.svg',
            },
            iconColor: {
                base: 'red' as const,
                recurring: 'green' as const,
            },
        },
        submitButton: {
            label: {
                base: 'CONTROLS.BUTTONS.OVERLAY_REMOVE',
                recurring: 'CONTROLS.BUTTONS.OVERLAY_FINISH',
            },
            buttonVariant: {
                base: 'danger' as const,
                recurring: 'default' as const,
            },
        },
        getKey: function (): 'recurring' | 'base' {
            return this.isRecurring ? 'recurring' : 'base';
        },
        getHeadline: function (): string {
            return this.headlineI18Key[this.getKey()];
        },
        getOverlayInputs: function (): {
            iconSrc: string;
            iconColor: 'blue' | 'green' | 'orange' | 'purple' | 'gray' | 'red' | 'no-fill';
        } {
            const iconSrc = this.overlayInputs.iconSrc[this.getKey()];
            const iconColor = this.overlayInputs.iconColor[this.getKey()];

            return { iconSrc, iconColor };
        },
        getButtonInputs: function (): { label: string; buttonVariant: ButtonVariant } {
            return {
                label: this.submitButton.label[this.getKey()],
                buttonVariant: this.submitButton.buttonVariant[this.getKey()],
            };
        },
    };

    public constructor(
        private toastService: ToastService,
        private translateService: TranslateService,
        private paymentsService: PaymentsService,
        private router: Router,
        private route: ActivatedRoute,
        private ledgerCustomService: LedgerCustomService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.payment = this.config?.data.payment;
        this.ledgerCustomService
            .getLedgerId$()
            .pipe(
                tap((ledgerId) => {
                    if (ledgerId) {
                        this.ledgerId = ledgerId;
                    }
                })
            )
            .subscribe();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public submit(): void {
        if (!this.payment) {
            return;
        }

        // TODO: add 'beenden' logic to recurring payment
        this.paymentsService
            .remove(this.payment.id)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: () => {
                    this.toastService.showSuccess(this.translateService.instant('PAGES.DELETE_PAYMENT.SUCCESS_TOAST'));
                    this.saveEmitter$.next();

                    if (this.config?.data.parentComponent === 'payments-details') {
                        this.router.navigate(['/accounting', 'ledger', this.ledgerId, 'payments']);
                    }
                },
                error: (error) => {
                    if (error) {
                        this.toastService.showError(error.error['message']);
                    }
                },
            });
    }
}
