import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { ImportFilesService, ImportInfo } from 'src/app/generated-sources/base';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';

@Component({
    selector: 'app-migration-deletemigration-overlay-component',
    templateUrl: './migration-deletemigration-overlay.component.html',
    styleUrls: ['./migration-deletemigration-overlay.component.scss'],
})
export class DeleteMigrationOverlayComponent extends OverlayChildComponent implements OnInit {
    private unsubscribe$ = new Subject<void>();
    public importInfo?: ImportInfo;
    public isFullyBooked = false;

    public constructor(
        private toastService: ToastService,
        private importFilesService: ImportFilesService,
        private translateService: TranslateService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.importInfo = this.config?.data.importInfo as ImportInfo;
    }

    public submit(): void {
        console.log(this.importInfo);
        if (this.importInfo) {
            this.importFilesService
                .removeImportInfo(this.importInfo?.id)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe({
                    next: () => {
                        this.toastService.showSuccess(
                            this.translateService.instant('PAGES.MIGRATION.TOAST_SUCCESS_DELETE')
                        );

                        this.saveEmitter$.next();
                    },
                    error: (error) => {
                        if (error) {
                            console.error(error.error['message']);
                            this.toastService.showError(this.translateService.instant('COMPONENTS.TOAST.TOAST_ERROR'));
                        }
                    },
                });
            this.saveEmitter$.next();
        }
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }
}
