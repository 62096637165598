import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-property-base-data-general-info-card',
    templateUrl: './property-base-data-general-info-card.component.html',
    styleUrls: ['./property-base-data-general-info-card.component.scss'],
})
export class PropertyBaseDataGeneralInfoCardComponent {
    @Input() public iconSrc = '';
    @Input() public title = '';
    @Input() public info: number | string = '';
}
