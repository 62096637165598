import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ButtonModule, CheckboxModule, InputModule, LoadingModule, RadioModule } from 'carbon-components-angular';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { SharedModule } from 'src/app/shared/shared.module';
import { UserManagementModule } from '../user-management/user-management.module';
import { AccountSettingsComponent } from './components/account-settings/account-settings.component';
import { AddUserComponent } from './components/add-user/add-user.component';
import { DeleteUserComponent } from './components/delete-user/delete-user.component';
import { DeleteMigrationOverlayComponent } from './components/migration-delete-migration-overlay/migration-deletemigration-overlay.component';
import { MigrationOverviewComponent } from './components/migration-overview/migration-overview.component';
import { PdfConfigurationsFileInputComponent } from './components/pfd-configurations/pdf-configurations-file-input/pdf-configurations-file-input.component';
import { PdfConfigurationsIndexComponent } from './components/pfd-configurations/pdf-configurations-index/pdf-configurations-index.component';
import { SettingsDetailViewComponent } from './components/settings-detail-view/settings-detail-view.component';
import { UsersOverviewComponent } from './components/users-overview/users-overview.component';

@NgModule({
    declarations: [
        SettingsDetailViewComponent,
        UsersOverviewComponent,
        AddUserComponent,
        DeleteUserComponent,
        AccountSettingsComponent,
        MigrationOverviewComponent,
        DeleteMigrationOverlayComponent,
        PdfConfigurationsIndexComponent,
        PdfConfigurationsFileInputComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ButtonModule,
        LoadingModule,
        AngularSvgIconModule,
        SharedModule,
        UserManagementModule,
        TranslateModule,
        CheckboxModule,
        InputModule,
        ReactiveFormsModule,
        RadioModule,
        NgxExtendedPdfViewerModule,
    ],
})
export class SettingsModule {}
