import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export class PasswordValidators {
    private static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            if (!control.value) {
                return null;
            }
            return regex.test(control.value) ? null : error;
        };
    }

    public static passwordMatch(control: AbstractControl): ValidationErrors | null {
        const password: string = control.get('password')?.value;
        const confirmPassword: string = control.get('confirmPassword')?.value;
        return password === confirmPassword ? null : { noMatch: true };
    }

    public static requiredNumber(control: AbstractControl): ValidationErrors | null {
        return PasswordValidators.patternValidator(
            /\d/,
            { requiredNumber: true }
        )(control);
    }

    public static requiredCapitalCase(control: AbstractControl): ValidationErrors | null {
        return PasswordValidators.patternValidator(
            /[A-Z]/,
            { requiredCapitalCase: true }
        )(control);
    }

    public static requiredSmallCase(control: AbstractControl): ValidationErrors | null {
        return PasswordValidators.patternValidator(
            /[a-z]/,
            { requiredSmallCase: true }
        )(control);
    }

    public static requiredSpecialCharacter(control: AbstractControl): ValidationErrors | null {
        return PasswordValidators.patternValidator(
            /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/,
            { requiredSpecialCharacter: true }
        )(control);
    }
}
