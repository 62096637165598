import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { TicketsService } from 'src/app/generated-sources/base';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';

@Component({
    selector: 'app-delete-ticket',
    templateUrl: './delete-ticket.component.html',
    styleUrls: ['./delete-ticket.component.scss'],
})
export class DeleteTicketComponent extends OverlayChildComponent implements OnInit {
    public ticketName = '';
    private ticketId = '';
    private unsubscribe$ = new Subject<void>();

    public constructor(
        private ticketService: TicketsService,
        private toastService: ToastService,
        private router: Router,
        private translateService: TranslateService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.ticketName = this.config?.data.ticketName;
        this.ticketId = this.config?.data.ticketId;
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public onSubmit(): void {
        this.ticketService
            .deleteTicket(this.ticketId)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: () => {
                    this.toastService.showSuccess(this.translateService.instant('PAGES.DELETE_TICKET.SUCCESS_TOAST'));
                    this.saveEmitter$.next();
                    this.router.navigate(['/communications']);
                },
                error: (error) => {
                    if (error) {
                        this.toastService.showError(error.error['message']);
                    }
                },
            });
    }
}
