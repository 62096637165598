import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbItem } from 'carbon-components-angular';
import {
    BehaviorSubject,
    Subject,
    combineLatest,
    distinctUntilChanged,
    map,
    of,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs';
import { BreadCrumbService } from 'src/app/core/services/breadcrumb.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { AddAccountFormComponent } from 'src/app/features/accounting/components/add-account-form/add-account-form.component';
import { ToggleSwitchCallbacks } from 'src/app/shared/components/toggle-switch/toggle-switch.component';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import {
    accountTypeNameFromEnum,
    formatDateYYYYMMDDWithoutHours,
    getCurrentDateDDMMYYYY,
    replaceAccountGroupNameIfGlobal,
} from '../../../../../core/utils/common';
import { AccountBookingDto, AccountDto, AccountsService } from '../../../../../generated-sources/accounting';
import { AccountCalculationMode, AccountingFilterCustomService } from '../../services/accounting-filter-custom.service';
import { BusinessYearDates } from '../../services/economic-plan-custom.service';
import { LedgerCustomService } from '../../services/ledger-custom.service';
@Component({
    selector: 'app-account-detail',
    templateUrl: './account-detail.component.html',
    styleUrls: ['./account-detail.component.scss'],
})
export class AccountDetailComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();
    public refresh$ = new BehaviorSubject<void>(undefined);
    public accountBalance?: number;
    public accountId$ = this.route.url.pipe(
        map(() => {
            this.accountId = this.route.snapshot.paramMap.get('accountId') ?? '';
            return this.route.snapshot.paramMap.get('accountId');
        }),
        distinctUntilChanged()
    );

    public isInitialLoading = false;
    public isTableLoading = false;
    public isDownloading = false;
    public today = getCurrentDateDDMMYYYY();
    private ledgerId = '';
    private accountId = '';
    public accountCalculationMode?: AccountCalculationMode;
    public account?: AccountDto;
    public accountBookings: AccountBookingDto[] = [];
    public breadcrumbs: BreadcrumbItem[] = [];
    public originalTypeName = '';
    public selectedBusinessYear: BusinessYearDates = {
        startDate: new Date(new Date().getFullYear(), 0, 1),
        endDate: new Date(new Date().getFullYear(), 11, 31),
    };

    public toggleSwitchCallbacks: ToggleSwitchCallbacks = {
        leftCallback: () => {
            this.accountingFilterCustomService.setBookingsCalculationMode('BOOKING_DATE');
            this.refresh$.next(undefined);
        },
        rightCallback: () => {
            this.accountingFilterCustomService.setBookingsCalculationMode('EFFECTIVE_DATE');
            this.refresh$.next(undefined);
        },
    };

    public bookingsCalculationMode$ = this.accountingFilterCustomService.getBookingsCalculationMode$();

    public constructor(
        private translateService: TranslateService,
        private accountsService: AccountsService,
        private breadcrumbService: BreadCrumbService,
        private route: ActivatedRoute,
        private overlayService: OverlayService,
        private accountingFilterCustomService: AccountingFilterCustomService,
        private ledgerCustomService: LedgerCustomService,
        private toastService: ToastService
    ) {}

    public ngOnInit(): void {
        // get account
        combineLatest([
            this.ledgerCustomService.getLedgerId$(),
            this.accountId$,
            this.accountingFilterCustomService.getDatePickerSelectedDates$(),
            this.bookingsCalculationMode$,
            this.refresh$,
        ])
            .pipe(
                switchMap(([ledgerId, accountId, datePickerFilter, accountCalculationMode]) => {
                    this.isInitialLoading = true;

                    if (!ledgerId || !accountId) {
                        return of();
                    }
                    this.ledgerId = ledgerId;

                    return this.accountsService.findOne(
                        ledgerId,
                        accountId,
                        formatDateYYYYMMDDWithoutHours(datePickerFilter.startDate),
                        formatDateYYYYMMDDWithoutHours(datePickerFilter.endDate),
                        accountCalculationMode
                    );
                }),
                tap((account: AccountDto) => {
                    this.originalTypeName = account.type;
                    this.account = account;
                    this.account.type = accountTypeNameFromEnum(account);
                    this.account.description = account.description || '–';
                    this.account.accountGroup.name = replaceAccountGroupNameIfGlobal(account);
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: () => {
                    this.initBreadCrumbs();
                    this.isInitialLoading = false;
                },
                error: (error) => {
                    if (error) {
                        this.toastService.showError(error.error['message']);
                    }
                    this.isInitialLoading = false;
                },
            });

        // get booking for account
        combineLatest([
            this.ledgerCustomService.getLedgerId$(),
            this.accountId$,
            this.accountingFilterCustomService.getDatePickerSelectedDates$(),
            this.accountingFilterCustomService.getBookingsFilter$(),
            this.bookingsCalculationMode$,
            this.refresh$,
        ])
            .pipe(
                switchMap(([ledgerId, accountId, datePickerFilter, bookingsFilter, accountCalculationMode]) => {
                    this.isTableLoading = true;
                    if (!ledgerId || !accountId) {
                        return of([]);
                    }
                    this.selectedBusinessYear = datePickerFilter;

                    const excludeCancelledBookings =
                        bookingsFilter.find((filter) => filter.id === 'excludeCancelledBookings')?.selected ?? false;
                    this.accountCalculationMode = accountCalculationMode;

                    return this.accountsService.findAllForAccount(
                        ledgerId,
                        accountId,
                        excludeCancelledBookings,
                        formatDateYYYYMMDDWithoutHours(datePickerFilter.startDate),
                        formatDateYYYYMMDDWithoutHours(datePickerFilter.endDate),
                        accountCalculationMode
                    );
                }),
                tap((accountBookings) => {
                    this.accountBookings = accountBookings;
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: () => {
                    this.isTableLoading = false;
                },
                error: (error) => {
                    if (error) {
                        this.toastService.showError(error.error['message']);
                    }
                    this.isTableLoading = false;
                },
            });
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public getAccountStatement(): void {
        this.isDownloading = true;
        this.accountsService
            .getAccountStatementsPdf(
                this.ledgerId,
                this.accountId,
                formatDateYYYYMMDDWithoutHours(this.selectedBusinessYear.startDate),
                formatDateYYYYMMDDWithoutHours(this.selectedBusinessYear.endDate),
                this.accountCalculationMode
            )
            .subscribe({
                next: (response) => {
                    const link = document.createElement('a');
                    const blob = new Blob([response], { type: 'application/pdf' });
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `Kontoauszug_${this.account?.name}_${this.account?.description}.pdf`;
                    link.click();
                    this.isDownloading = false;
                },
                error: (e) => {
                    this.toastService.showError(e.message);
                    this.isDownloading = false;
                },
            });
    }

    private initBreadCrumbs(): void {
        this.breadcrumbs = [
            {
                content: this.translateService.instant('ACCOUNTING.COMMON.ACCOUNT_CHARTS'),
                route: [`/accounting/ledger/${this.ledgerId}/account-charts`],
            },
            {
                content: this.account?.name ?? '',
                route: [`/accounting/ledger/${this.ledgerId}/account-charts/${this.accountId}`],
                current: true,
            },
        ];
        this.breadcrumbService.resetBreadCrumbs();
        this.breadcrumbService.updateBreadCrumbs(this.breadcrumbs);
    }

    public openAddAccount(isEditing: boolean): void {
        const accountFormated = { ...this.account, type: this.originalTypeName };

        const data = {
            componentParent: 'account-details',
            isEditing,
            extraData: { account: accountFormated, ledgerId: this.ledgerId },
        };

        const ref = this.overlayService.open(AddAccountFormComponent, { data });
        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.refresh$.next();
        });
    }
}
