import { Component, Input, OnChanges } from '@angular/core';
import { ReceiptDto } from '../../../generated-sources/accounting';
import { FilesService } from '../../../generated-sources/file';
import { CellTemplate } from '../../../shared/table/enums/cell-template';
import { TableItem } from '../../../shared/table/interfaces/table-item';
import { TableModel } from '../../../shared/table/interfaces/table-model';
import { FiletoView } from '../file-viewer/file-viewer.component';

@Component({
    selector: 'app-receipts-table',
    templateUrl: './receipts-table.component.html',
    styleUrls: ['./receipts-table.component.scss'],
})
export class ReceiptsTableComponent implements OnChanges {
    @Input() public receipts?: ReceiptDto[];

    public tableModel: TableModel = { data: [], header: [] };
    public fileToView?: FiletoView;
    public showModal = false;
    public viewerOptions: Viewer.Options = {
        navbar: false,
        backdrop: false,
        button: false,
        inline: true,
        initialCoverage: 1,
        title: false,
        toolbar: {
            zoomIn: true,
            zoomOut: true,
            oneToOne: true,
            reset: true,
            prev: false,
            play: false,
            next: false,
            rotateLeft: false,
            rotateRight: false,
            flipHorizontal: false,
            flipVertical: false,
        },
    };

    public constructor(private filesService: FilesService) {}

    public ngOnChanges(): void {
        if (this.receipts) {
            this.tableModel.header = [
                {
                    data: { key: 'PAGES.RECEIPTS.HEADER_FILE_NAME', params: {} },
                    width: '460px',
                },
                'ENTITIES.RECEIPT.LABEL_RECEIPT_NUMBER',
            ];
            this.tableModel.data = this.receipts.map((receipt) => this.createRow(receipt));
        }
    }

    public handleReceipt($event: any): void {
        this.filesService.getDownloadLink($event.extraData.fileStorageId).subscribe((data: any) => {
            if ($event.isDownload) {
                const link = document.createElement('a');
                link.href = data.url;
                link.download = $event.extraData.fileName;
                link.click();
            } else {
                this.fileToView = { fileName: $event.extraData.fileName, file: data.url };
                this.showModal = true;
            }
        });
    }

    public handleModal(): void {
        this.showModal = !this.showModal;
    }

    private createRow(data: ReceiptDto): TableItem[] {
        return [
            {
                data: {
                    label: data.fileName,
                    extraData: data,
                    iconSrc: '/assets/icons/24_dokument.svg',
                },
                template: CellTemplate.textWithIcon,
            },
            {
                data: {
                    label: data.receiptNumber,
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: '',
                    extraData: data,
                },
                template: CellTemplate.filesActions,
            },
        ];
    }
}
