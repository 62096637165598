import { Component, OnDestroy } from '@angular/core';
import { Subject, firstValueFrom } from 'rxjs';
import { convertToISODateString } from 'src/app/core/utils/common';
import {
    AccountingReportsControllerGetOpenItemsReport200Response,
    AccountingReportsService,
} from 'src/app/generated-sources/accounting';
import { AccountingFilterCustomService, DatePickerDates } from '../../services/accounting-filter-custom.service';
import { LedgerCustomService } from '../../services/ledger-custom.service';

@Component({
    selector: 'app-mv-income-and-expense-tax-report-tab',
    templateUrl: './mv-income-and-expense-tax-report-tab.component.html',
    styleUrls: ['./mv-income-and-expense-tax-report-tab.component.scss'],
})
export class MvIncomeAndExpenseTaxReportTabComponent implements OnDestroy {
    public isLoading = false;
    public filterDates?: DatePickerDates;
    public filterDates$ = this.accountingFilterCustomService.getDatePickerSelectedDates$();
    public unsubscribe$ = new Subject<void>();
    public ledgerId$ = this.ledgerCustomService.getLedgerId$();
    public ledgerId?: string;
    public downloadUrl?: AccountingReportsControllerGetOpenItemsReport200Response;

    public constructor(
        private accountingReportsService: AccountingReportsService,
        private accountingFilterCustomService: AccountingFilterCustomService,
        private ledgerCustomService: LedgerCustomService
    ) {}

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public async downloadTaxReport(): Promise<void> {
        const dates = await firstValueFrom(this.filterDates$);
        const ledgerId = await firstValueFrom(this.ledgerId$);

        this.isLoading = true;
        if (!dates || !ledgerId) {
            this.isLoading = false;
            return;
        }
        try {
            const response = await firstValueFrom(
                this.accountingReportsService.getSevTaxReport(
                    ledgerId,
                    convertToISODateString(dates.startDate),
                    convertToISODateString(dates.endDate)
                )
            );
            if (!response.url) {
                return;
            }
            const link = document.createElement('a');
            link.href = response.url;
            link.download = 'download';
            link.click();
            link.remove();
            this.isLoading = false;
        } catch (error) {
            this.isLoading = false;
        }
    }
}
