import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ListItem } from 'carbon-components-angular';
import { BehaviorSubject, Observable, Subject, combineLatest, filter, map, switchMap, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import {
    formatDateYYYYMMDDWithoutHours,
    getPersonDescriptionCombobox,
    isCurrentDateWithinRange,
} from 'src/app/core/utils/common';
import { CreateRelationToPersonDto, OwnerRelationDto, OwnershipsService, Person } from 'src/app/generated-sources/base';
import { DateRangePickerComponent } from 'src/app/shared/components/date-range-picker/date-range-picker.component';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';
import { PersonInfosFormComponent } from 'src/app/shared/person-infos-form/person-infos-form.component';
import { PropertyCustomService } from '../../property/services/property-custom.service';
import { ExistingPersonsComboBoxComponent } from '../existing-persons-combo-box/existing-persons-combo-box.component';
import { DateIntervalDto } from '../interfaces';

@Component({
    selector: 'app-add-resident-overlay',
    templateUrl: './add-resident-overlay.component.html',
    styleUrls: ['./add-resident-overlay.component.scss'],
})
export class AddResidentOverlayComponent extends OverlayChildComponent implements OnInit, OnDestroy, AfterViewInit {
    public durationForm: UntypedFormGroup = new UntypedFormGroup({});
    public createUserForm: UntypedFormGroup = new UntypedFormGroup({});
    public selectedPerson = {} as Person;
    private unsubscribe$ = new Subject<void>();
    public isExistingUserSelected = false;
    public isLoading = false;
    public selfUse$ = new BehaviorSubject<boolean>(false);

    public property$ = this.propertyCustomService.getProperty$().pipe(filter(Boolean), takeUntil(this.unsubscribe$));
    public vm: { ownershipId: string; propertyId: string; owners?: OwnerRelationDto[] } = {
        ownershipId: '',
        propertyId: '',
        owners: [],
    };

    public owners = this.property$.pipe(
        switchMap((property) => {
            return this.ownershipsService.findOwnerRelations(property.id, this.config?.data.ownershipId);
        }),
        map((relations) => relations.filter((relation) => isCurrentDateWithinRange(relation.from, relation.to))),
        takeUntil(this.unsubscribe$)
    );

    public vm$ = combineLatest([this.property$, this.owners]).pipe(
        map(
            ([property, owners]) =>
                (this.vm = { propertyId: property.id, ownershipId: this.config?.data.ownershipId || '', owners })
        ),
        takeUntil(this.unsubscribe$)
    );

    public currentOwnersList: Observable<ListItem[]> = this.owners.pipe(
        map((owners) => {
            return owners.map((owner) => ({
                selected: false,
                content: getPersonDescriptionCombobox(owner.person),
                id: owner.person.id,
            }));
        }),
        takeUntil(this.unsubscribe$)
    );

    @ViewChild(PersonInfosFormComponent)
    public personInfosFormComponent?: PersonInfosFormComponent;
    @ViewChild(DateRangePickerComponent)
    public dateRangePickerChildComponent?: DateRangePickerComponent;
    @ViewChild(ExistingPersonsComboBoxComponent)
    public existingPersonsComboBox?: ExistingPersonsComboBoxComponent;

    public constructor(
        private ownershipsService: OwnershipsService,
        private toast: ToastService,
        private propertyCustomService: PropertyCustomService,
        private translateService: TranslateService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.vm$.subscribe();
    }

    public ngAfterViewInit(): void {
        if (!this.personInfosFormComponent || !this.dateRangePickerChildComponent || !this.existingPersonsComboBox) {
            console.warn('come of mandaroty children are not initialised');
            return;
        }

        this.createUserForm.addControl('personForm', this.personInfosFormComponent.personForm);
        this.personInfosFormComponent.personForm.setParent(this.createUserForm);

        this.durationForm.addControl('dateRangeForm', this.dateRangePickerChildComponent.dateRangeForm);
        this.dateRangePickerChildComponent.dateRangeForm.setParent(this.durationForm);
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }
    public submit(): void {
        if (!this.isFormsValid()) {
            return;
        }
        this.isLoading = true;
        const serviceProvider: CreateRelationToPersonDto = {
            ...this.createRealtionWithTimeConstraintObject(),
            person: this.isExistingUserSelected ? undefined : this.createPersonDto(),
            personId: this.isExistingUserSelected ? this.selectedPerson.id : undefined,
        };

        this.ownershipsService
            .createIsResidentRelation(this.vm.propertyId, this.vm.ownershipId, serviceProvider)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: () => {
                    this.isLoading = false;
                    this.toast.showSuccess(
                        this.translateService.instant('PAGES.RELATIONS.RESIDENTS.ADD_RESIDENT_SUCCESS_TOAST')
                    );
                    this.saveEmitter$.next();
                },
                error: (error) => {
                    this.isLoading = false;
                    if (error) {
                        this.toast.showError(error.error['message']);
                    }
                },
            });
    }

    private createRealtionWithTimeConstraintObject(): DateIntervalDto {
        const startDate = this.durationForm.get('dateRangeForm')?.value['startDate'][0];
        const endDate = this.durationForm.get('dateRangeForm')?.value['endDate'][0];
        const datesObj: DateIntervalDto = {
            from: formatDateYYYYMMDDWithoutHours(startDate),
        };
        if (endDate) {
            datesObj.to = formatDateYYYYMMDDWithoutHours(endDate);
        }
        return datesObj;
    }

    private createPersonDto(): Person {
        const userForm = this.createUserForm.get('personForm')?.value;
        userForm.phoneNumbers =
            userForm.phoneNumbers && userForm.phoneNumbers.length > 0 && userForm.phoneNumbers[0]?.phoneNumber
                ? userForm.phoneNumbers.map((item: any) => {
                      return {
                          phoneNumber: item.phoneNumber,
                          type: item.phoneNumberType?.value ?? item.phoneNumberType,
                      };
                  })
                : [];
        return userForm;
    }

    public switchExistingUserSelection(isExistingSelected: boolean): void {
        if (isExistingSelected) {
            this.isExistingUserSelected = true;
        } else {
            this.isExistingUserSelected = false;
        }
    }

    public onSelectPerson($event: Person): void {
        this.selectedPerson = $event || {};
    }

    public isFormsValid(): boolean {
        if (!this.durationForm.valid) {
            return false;
        }
        if (!this.isExistingUserSelected && this.createUserForm.valid) {
            return true;
        }
        if (this.isExistingUserSelected && this.isValidPerson()) {
            return true;
        }
        return false;
    }

    public isValidPerson(): boolean {
        return Boolean(this.selectedPerson.id);
    }

    public onRadioSelfUseChange(event: any): void {
        const selfUse = event.value === 'true';
        this.isExistingUserSelected = selfUse;
        this.selfUse$.next(selfUse);
    }

    public onSelectCurrentOwner(event: any): void {
        const relation = this.vm.owners?.find((owner) => owner.person.id === event.item.id);
        if (!relation) {
            return;
        }
        this.selectedPerson = relation.person;

        this.isExistingUserSelected = true;
        this.durationForm.patchValue({
            dateRangeForm: { endDate: relation.to ? [relation.to] : '', startDate: [relation.from] },
        });
    }
}
