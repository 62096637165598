<ibm-loading *ngIf="isLoading" [isActive]="true" [size]="'normal'" [overlay]="true"></ibm-loading>
<app-overlay-container
    [headlineTitle]="
        isEditOesOverlay
            ? 'PAGES.ANNUAL_STATEMENTS.EDIT_OES.HEADLINE'
            : 'PAGES.ANNUAL_STATEMENTS.ADD_WSS_DISTRIBUTION_KEY.HEADLINE'
    "
    [sizeIcon]="72"
    [image]="isEditOesOverlay ? '40_document_edit.svg' : ''"
    [colorIcon]="isEditOesOverlay ? 'blue' : 'no-fill'"
>
    <form [formGroup]="form">
        <div class="mb-4r d-flex">
            <ibm-label class="mr-2r" invalidText="{{ 'ERROR.REQUIRED' | translate }}">
                {{ 'ENTITIES.DISTRIBUTION_KEY.LABEL_DESCRIPTION' | translate }}*
                <input formControlName="description" ibmText name="name" id="name" />
            </ibm-label>

            <ibm-label invalidText="{{ 'ERROR.REQUIRED' | translate }}">
                <app-combo-box
                    id="distributionBase"
                    placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT_2' | translate }}"
                    label="Verteilergrundlage*"
                    [items]="listDistributionBase"
                    (selected)="onSelectDistributionBase($event)"
                    formControlName="distributionBase"
                    invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                >
                    <ibm-dropdown-list></ibm-dropdown-list>
                </app-combo-box>
            </ibm-label>
        </div>

        <div class="mb-2r s-body-14-22-bold">
            {{ 'PAGES.ANNUAL_STATEMENTS.ADD_WSS_DISTRIBUTION_KEY.INFO' | translate }}
        </div>

        <div class="d-flex justify-space-between mb-1-5r">
            <div class="s-gray-03 s-label-12-22-semibold">Nutzung</div>
            <div class="s-gray-03 s-label-12-22-semibold">Anteil laut Verteilungsgrundlage</div>
        </div>

        <ng-container formArrayName="occupations">
            <div
                *ngFor="let occupationGroup of occupationsControl.controls; let i = index"
                class="d-flex justify-space-between"
            >
                <ng-container [formGroupName]="i">
                    <ng-container *ngIf="getOccupationDescription(occupationGroup.value?.occupation) as occupationInfo">
                        <div class="d-flex align-items-center">
                            <ibm-checkbox formControlName="selected"></ibm-checkbox>
                            <div class="s-body-14-22-regular ml-0-5r">
                                <div>{{ occupationInfo.occupationDetail }}</div>
                                <div class="s-gray-03">{{ occupationInfo.occupationNumberAndTenants }}</div>
                            </div>
                        </div>

                        <div class="s-head-14-22-regular">
                            {{ occupationGroup.value?.distributionBaseShare }}
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>
    </form>
    <div class="mt-1-5r d-flex justify-content-end">
        <div class="text-right">
            <div class="s-gray-03 s-label-12-22-semibold mb-o-75r">Gesamtverteiler</div>
            <div class="s-body-14-22-bold">{{ sumTotalDistribution() }}</div>
        </div>
    </div>

    <!-- <div class="d-flex justify-space-between mt-7-5r">
        <app-scalara-button (click)="abort()" [variant]="'outline'">
            {{ 'CONTROLS.BUTTONS.CANCEL' | translate }}
        </app-scalara-button>

        <app-scalara-button (click)="submit()" [disabled]="isInvalidForm()" [variant]="'default'" type="submit">
            {{ 'CONTROLS.BUTTONS.SAVE' | translate }}
        </app-scalara-button>
    </div> -->

    <div class="d-flex justify-space-between mt-7-5r">
        <app-scalara-button (click)="abort()" [variant]="'outline'">
            {{ (isEditOesOverlay ? 'CONTROLS.BUTTONS.BACK' : 'CONTROLS.BUTTONS.CANCEL') | translate }}
        </app-scalara-button>

        <app-scalara-button
            *ngIf="!isEditOesOverlay"
            (click)="submit()"
            [disabled]="isInvalidForm()"
            [variant]="'default'"
            type="submit"
        >
            {{ 'CONTROLS.BUTTONS.SAVE' | translate }}
        </app-scalara-button>

        <div *ngIf="isEditOesOverlay" class="d-flex">
            <div class="mr-1-5r">
                <app-scalara-button
                    [disabled]="isInvalidForm()"
                    (click)="submit(true)"
                    [variant]="'ghost'"
                    height="48px"
                >
                    {{ 'Speichern & schließen' }}
                </app-scalara-button>
            </div>

            <app-scalara-button [disabled]="isInvalidForm()" variant="default" (click)="submit()">
                Speichern & weiter
            </app-scalara-button>
        </div>
    </div>
</app-overlay-container>
