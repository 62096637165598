/**
 * SCALARA Base Module
 * The SCALARA API for User, Person and Property resources
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Person } from './person';
import { RentIntervalDto } from './rentIntervalDto';


export interface OccupationForm { 
    id: string;
    occupationType?: OccupationForm.OccupationTypeEnum;
    occupationEntryPhase: OccupationForm.OccupationEntryPhaseEnum;
    isCommitted: boolean;
    numberOfPersons?: number;
    from?: string;
    to?: string;
    miscellaneousInfo?: string;
    minimumTermInMonth?: number;
    automaticExtensionInMonth?: number;
    cancellationPeriodInMonth?: number;
    unitsIds?: Array<string>;
    unitsNames?: Array<string>;
    rentCalculationMethod?: OccupationForm.RentCalculationMethodEnum;
    rentType?: OccupationForm.RentTypeEnum;
    optedForVAT?: boolean;
    dueWorkingDay?: number;
    depositType?: OccupationForm.DepositTypeEnum;
    depositAmount?: number;
    depositDueDate?: string;
    oneTimeSmallRepairsAmount?: number;
    annualSmallRepairsAmount?: number;
    percentageTaxIncluded?: OccupationForm.PercentageTaxIncludedEnum;
    rentIntervals?: Array<RentIntervalDto>;
    tenants?: Array<Person>;
    tenantIds?: Array<string>;
    permissionRoles: Array<OccupationForm.PermissionRolesEnum>;
    createdAt?: string;
    updatedAt?: string;
}
export namespace OccupationForm {
    export type OccupationTypeEnum = 'RENTING' | 'VACANCY' | 'OWN_OCCUPATION';
    export const OccupationTypeEnum = {
        Renting: 'RENTING' as OccupationTypeEnum,
        Vacancy: 'VACANCY' as OccupationTypeEnum,
        OwnOccupation: 'OWN_OCCUPATION' as OccupationTypeEnum
    };
    export type OccupationEntryPhaseEnum = 'UNIT_SELECTION' | 'PERSON_SELECTION' | 'RENT_SPECIFICATION' | 'COMPLETED';
    export const OccupationEntryPhaseEnum = {
        UnitSelection: 'UNIT_SELECTION' as OccupationEntryPhaseEnum,
        PersonSelection: 'PERSON_SELECTION' as OccupationEntryPhaseEnum,
        RentSpecification: 'RENT_SPECIFICATION' as OccupationEntryPhaseEnum,
        Completed: 'COMPLETED' as OccupationEntryPhaseEnum
    };
    export type RentCalculationMethodEnum = 'BLANKET_RENT' | 'BASE_RENT_OPERATIONAL_COST_HEATING' | 'BASE_RENT_TOTAL_PREPAID' | 'BLANKET_RENT_CONSUMPTION';
    export const RentCalculationMethodEnum = {
        BlanketRent: 'BLANKET_RENT' as RentCalculationMethodEnum,
        BaseRentOperationalCostHeating: 'BASE_RENT_OPERATIONAL_COST_HEATING' as RentCalculationMethodEnum,
        BaseRentTotalPrepaid: 'BASE_RENT_TOTAL_PREPAID' as RentCalculationMethodEnum,
        BlanketRentConsumption: 'BLANKET_RENT_CONSUMPTION' as RentCalculationMethodEnum
    };
    export type RentTypeEnum = 'PRIVATE' | 'COMMERCIAL';
    export const RentTypeEnum = {
        Private: 'PRIVATE' as RentTypeEnum,
        Commercial: 'COMMERCIAL' as RentTypeEnum
    };
    export type DepositTypeEnum = 'NONE' | 'BANK_TRANSFER' | 'CASH_PAYMENT' | 'PLEDGED_SAVING_ACCOUNT' | 'BANK_GUARANTEE' | 'DEPOSIT_INSURANCE' | 'PARENTAL_GUARANTEE';
    export const DepositTypeEnum = {
        None: 'NONE' as DepositTypeEnum,
        BankTransfer: 'BANK_TRANSFER' as DepositTypeEnum,
        CashPayment: 'CASH_PAYMENT' as DepositTypeEnum,
        PledgedSavingAccount: 'PLEDGED_SAVING_ACCOUNT' as DepositTypeEnum,
        BankGuarantee: 'BANK_GUARANTEE' as DepositTypeEnum,
        DepositInsurance: 'DEPOSIT_INSURANCE' as DepositTypeEnum,
        ParentalGuarantee: 'PARENTAL_GUARANTEE' as DepositTypeEnum
    };
    export type PercentageTaxIncludedEnum = '0.' | '7.' | '19.';
    export const PercentageTaxIncludedEnum = {
        _0: '0.' as PercentageTaxIncludedEnum,
        _7: '7.' as PercentageTaxIncludedEnum,
        _19: '19.' as PercentageTaxIncludedEnum
    };
    export type PermissionRolesEnum = 'PROPERTY_MANAGER' | 'OWNER' | 'SERVICE_PROVIDER' | 'ADVISER' | 'CO_OWNER' | 'TENANT';
    export const PermissionRolesEnum = {
        PropertyManager: 'PROPERTY_MANAGER' as PermissionRolesEnum,
        Owner: 'OWNER' as PermissionRolesEnum,
        ServiceProvider: 'SERVICE_PROVIDER' as PermissionRolesEnum,
        Adviser: 'ADVISER' as PermissionRolesEnum,
        CoOwner: 'CO_OWNER' as PermissionRolesEnum,
        Tenant: 'TENANT' as PermissionRolesEnum
    };
}


