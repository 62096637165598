<app-delete-overlay
    title="PAGES.OPERATIONS_EXPENSE_STATEMENTS.DELETE_OPERATIONS_EXPENSE_STATEMENTS.TITLE"
    headline="PAGES.OPERATIONS_EXPENSE_STATEMENTS.DELETE_OPERATIONS_EXPENSE_STATEMENTS.HEADLINE"
    subtitle="PAGES.OPERATIONS_EXPENSE_STATEMENTS.DELETE_OPERATIONS_EXPENSE_STATEMENTS.LABEL_WARNING"
    (closeOverlay)="abort()"
    (submitOverlay)="onSubmit()"
>
    <div class="d-flex align-items-center files mb-7-5r">
        <span class="s-body-14-22-regular s-gray-01">
            {{ period?.startDate || '' | dateFormat }} – {{ period?.endDate || '' | dateFormat }}</span
        >
    </div>
</app-delete-overlay>
