import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Subject, takeUntil, tap } from 'rxjs';
import { FilesService } from 'src/app/generated-sources/file';
import { FiletoView } from 'src/app/shared/components/file-viewer/file-viewer.component';
import { RowSelected } from 'src/app/shared/components/table-multi-selector/row-selected';
import { HeaderItem } from 'src/app/shared/table/interfaces/header-item';
import { TableMultiSelectorComponent } from '../../../../shared/components/table-multi-selector/table-multi-selector.component';
import { TableItem } from '../../../../shared/table/interfaces/table-item';

export interface FileSelectedObject {
    name: string;
    id: string;
    rowNumber: number;
    page: number;
}

@Component({
    selector: 'app-file-selector',
    templateUrl: './file-selector.component.html',
    styleUrls: ['./file-selector.component.scss'],
})
export class FileSelectorComponent {
    private unsubscribe$ = new Subject<void>();

    public isCollapsed = true;

    public isFilteringBookingReceipts = true;

    public isReceiptsAllAccountsShowing = false;

    public itemsPerPage = 10;

    public selectedRows: FileSelectedObject[] = [];

    private currentPage = 1;

    private _data: TableItem[][] = [];

    public fileToView?: FiletoView;

    public showModal = true;

    @Input() public disabled = false;

    @Input() public disabledText = 'deaktiviert';

    @Input() public emptyText? = '';

    @Input() public emptyTextLink? = '';

    @Input() public emptyTextLinkLabel? = '';

    @Input() public title = 'ACCOUNTING.ADD_BOOKING_FORM.RECEIPT_SELECTION';

    /**
     * Input of header and data of the table model should happen seperatly so
     * angulars rerender will trigger. In daily use, the header is mostly synchronous
     * but the data will be asynchronous.
     */
    @Input() public header?: (string | HeaderItem)[];

    @Input()
    public get data(): TableItem[][] {
        return this._data;
    }
    public set data(data: TableItem[][]) {
        this._data = data;
    }

    @Output() public rowSelected = new EventEmitter<string[]>();

    @Output() public checkboxFilterBookedReceipts = new EventEmitter<boolean>();

    @Output() public checkboxAllAccountsReceipts = new EventEmitter<boolean>();

    @ViewChild('TableMultiSelectorComponent')
    public tableMultiSelectorComponent!: TableMultiSelectorComponent;

    public constructor(private filesService: FilesService) {}

    public onRowClick($event: RowSelected): void {
        this.currentPage = $event.currentPage;

        const displaySelectedRow: FileSelectedObject = {
            name: $event.data[1].data.label,
            rowNumber: $event.rowNumber,
            page: $event.currentPage,
            id: $event.data[$event.data.length - 1].data.link,
        };

        const rowIndexOnList = this.isRowAlreadyDisplayedInList(displaySelectedRow.id);

        if (rowIndexOnList >= 0) {
            this.selectedRows.splice(rowIndexOnList, 1);
        } else {
            this.selectedRows.push(displaySelectedRow);
        }

        const idsSelected: string[] = this.selectedRows.map((selectedRow) => selectedRow.id);

        this.rowSelected.emit(idsSelected);
    }

    public removeSelectedFile($event: FileSelectedObject): void {
        this.tableMultiSelectorComponent.selectPage($event.page);

        // Triggering onRowClick of file-selector.component by calling this function of the child component.
        this.tableMultiSelectorComponent.onRowClick($event.rowNumber);
        this.tableMultiSelectorComponent.selectPage(this.currentPage);
    }

    public changeSelectDisplay(): void {
        this.isCollapsed = !this.isCollapsed;
    }

    public handleFiles($event: any): void {
        this.filesService
            .getDownloadLink($event.extraData.fileStorageId)
            .pipe(
                tap((data: any) => {
                    if ($event.isDownload) {
                        const link = document.createElement('a');
                        link.href = data.url;
                        link.download = $event.extraData.fileName;
                        link.click();
                    }
                    if (!$event.isDownload && !$event.isDelete) {
                        this.fileToView = { fileName: $event.extraData.fileName, file: data.url };
                        this.showModal = true;
                    }
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }

    public handleModal(): void {
        this.showModal = !this.showModal;
    }

    public filterBookedReceiptsCheck(): void {
        this.isFilteringBookingReceipts = !this.isFilteringBookingReceipts;
        this.checkboxFilterBookedReceipts.emit(this.isFilteringBookingReceipts);
    }

    public receiptsAllAccountsCheck(): void {
        this.isReceiptsAllAccountsShowing = !this.isReceiptsAllAccountsShowing;
        this.checkboxAllAccountsReceipts.emit(this.isReceiptsAllAccountsShowing);
    }

    /**
     * Checking if the row is already displayed in the collapsable list.
     *
     * @param displaySelectedRowId
     * @returns index of the list of selected files. -1 if not found
     */
    private isRowAlreadyDisplayedInList(displaySelectedRowId: string): number {
        let rowAlreadyDisplayed = false;
        let rowIndex = 0;
        while (!rowAlreadyDisplayed && rowIndex < this.selectedRows.length) {
            if (this.selectedRows[rowIndex].id === displaySelectedRowId) {
                rowAlreadyDisplayed = true;
            } else {
                rowIndex += 1;
            }
        }
        if (rowAlreadyDisplayed) {
            return rowIndex;
        } else {
            return -1;
        }
    }
}
