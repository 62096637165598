<!-- do not show this notification if the user is in demo environment -->
<div ibmRow *ngIf="isExpired && !environment.demoEnvironment" class="mb-5r" style="white-space: pre-wrap">
    <div ibmCol [columnNumbers]="{ md: 16, lg: 16 }">
        <app-notification type="error">
            <div>
                <span>{{ 'PAGES.USER_MANAGEMENT.ACCOUNTS_VIEW.EXPIRE_ERROR_NOTE' | translate }}</span>
                <a routerLink="/user-management/accounts" class="s-gray-01 s-link-14-22-regular">{{
                    'PAGES.USER_MANAGEMENT.ACCOUNTS_VIEW.LINK_BANK_CONNECTIONS' | translate
                }}</a>
                <span>{{ 'PAGES.USER_MANAGEMENT.ACCOUNTS_VIEW.EXPIRE_NOTE_RENEW' | translate }}</span>
            </div>
        </app-notification>
    </div>
</div>
