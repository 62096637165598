import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil, tap } from 'rxjs';
import { OesDistributionKeyDto, OperationsExpenseStatementsService } from 'src/app/generated-sources/accounting';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { CellTemplate } from 'src/app/shared/table/enums/cell-template';
import { HeaderTemplate } from 'src/app/shared/table/enums/header-template';
import { TableItem } from 'src/app/shared/table/interfaces/table-item';
import { TableModel } from 'src/app/shared/table/interfaces/table-model';
import { AddOesDistributionKeyComponent } from '../add-oes-distribution-key/add-oes-distribution-key.component';

@Component({
    selector: 'app-operations-expense-statements-distribution-key-tab',
    templateUrl: './operations-expense-statements-distribution-key-tab.component.html',
    styleUrls: ['./operations-expense-statements-distribution-key-tab.component.scss'],
})
export class OperationsExpenseStatementsDistributionKeyTabComponent implements OnChanges, OnInit {
    public tableModel: TableModel = { data: [], header: [] };
    public tableModelIndividualDistributionKey: TableModel = { data: [], header: [] };
    public isLoading = false;
    public oesDistributionKey: OesDistributionKeyDto[] = [];
    private unsubscribe$ = new Subject<void>();

    @Input() public ledgerId = '';
    @Input() public oesId = '';
    @Input() public oesDate = '';
    @Input() public isEditOesOverlay = false;

    public constructor(
        private translateService: TranslateService,
        private operationsExpenseStatementsService: OperationsExpenseStatementsService,
        private overlayService: OverlayService
    ) {}

    public ngOnChanges(): void {
        this.isLoading = true;
        this.loadOesDistributionKey();
    }

    public loadOesDistributionKey(): void {
        this.operationsExpenseStatementsService
            .findAllDistributionKeys(this.ledgerId, this.oesId, undefined, 'CONSUMPTION')
            .pipe(
                tap((data) => {
                    this.oesDistributionKey = data;

                    if (!this.isEditOesOverlay) {
                        this.tableModel.data = this.oesDistributionKey
                            .filter((item) => item.distributionGroup === 'ALL_OCCUPATIONS')
                            .map((item) => {
                                return this.createRow(item);
                            });
                    }

                    this.tableModelIndividualDistributionKey.data = this.oesDistributionKey
                        .filter((item) => item.distributionGroup !== 'ALL_OCCUPATIONS')
                        .map((item) => {
                            return this.createRow(item);
                        });
                    this.isLoading = false;
                })
            )
            .subscribe();
    }

    public ngOnInit(): void {
        this.initTableHeader();
    }

    private initTableHeader(): void {
        this.tableModel.header = [
            'ACCOUNTING.COMMON.DESCRIPTION',
            'ENTITIES.DISTRIBUTION_KEY.LABEL_DISTRIBUTION_BASE',
            'ENTITIES.DISTRIBUTION_KEY.LABEL_DISTRIBUTION_GROUP',
            {
                data: { key: 'ENTITIES.DISTRIBUTION_KEY.LABEL_TOTAL_DISTRIBUTION', params: {} },
                template: HeaderTemplate.RightAligned,
            },
        ];

        this.tableModelIndividualDistributionKey.header = [
            'ACCOUNTING.COMMON.DESCRIPTION',
            'ENTITIES.DISTRIBUTION_KEY.LABEL_DISTRIBUTION_BASE',
            'ENTITIES.DISTRIBUTION_KEY.LABEL_DISTRIBUTION_GROUP',
            {
                data: { key: 'ENTITIES.DISTRIBUTION_KEY.LABEL_TOTAL_DISTRIBUTION', params: {} },
                template: HeaderTemplate.RightAligned,
            },
        ];
    }

    private createRow(item: OesDistributionKeyDto): TableItem[] {
        const link = this.isEditOesOverlay
            ? undefined
            : `/accounting/ledger/${this.ledgerId}/operations-expense-statements/${this.oesId}/distribution-key/${item.id}`;
        const queryParams = {
            ...item,
            occupations: JSON.stringify(item.occupations),
            oesDate: this.oesDate,
            oesId: this.oesId,
            ledgerId: this.ledgerId,
        };
        return [
            {
                data: {
                    label: item.description,
                    link,
                    extraData: { queryParams },
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: this.translateService.instant('ENTITIES.OWNERSHIP.LABEL_' + item?.distributionBase),
                    link,
                    extraData: { queryParams },
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: this.translateService.instant('ENTITIES.DISTRIBUTION_KEY.' + item?.distributionGroup),
                    link,
                    extraData: { queryParams },
                },
                template: CellTemplate.Default,
            },
            {
                data: {
                    label: item.totalDistribution,
                    rightAligned: true,
                    link,
                    extraData: { queryParams },
                },
                template: CellTemplate.Default,
            },
        ];
    }

    public openAddConsumptionDistributionKeyOverlay(): void {
        const ref = this.overlayService.open(AddOesDistributionKeyComponent, {
            data: { ledgerId: this.ledgerId, oesId: this.oesId },
        });

        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());
        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.loadOesDistributionKey());
    }
}
