<div *ngIf="isLoading" class="is-loading">
    <div class="d-flex align-items-center justify-content-center mt-4r">
        <ibm-inline-loading> </ibm-inline-loading>
    </div>
</div>

<div ibmRow class="mt-4r mb-1r">
    <div ibmCol class="s-head-16-24-bold align-items-center">
        <app-text-with-tooltip
            class="tw-inline-block"
            [label]="'PAGES.DISTRIBUTION_KEYS.OVERVIEW.STANDARD_KEYS_TABLE_TITLE' | translate"
            [labelClass]="''"
            [tooltipKey]="TooltipKey.tooltipsInAccounting"
            [tooltipInnerHtml]="'PAGES.TOOLTIPS.DISTRIBUTION_KEYS.OVERVIEW.STANDARD_KEYS_TABLE_TITLE' | translate"
        ></app-text-with-tooltip>
    </div>

    <div ibmCol class="d-flex justify-content-end">
        <app-search [searchInputId]="'distributionKeysTable'"></app-search>
    </div>
</div>
<div ibmRow class="mb-4-5r">
    <div ibmCol>
        <app-table
            [emptyText]="'PAGES.DISTRIBUTION_KEYS.OVERVIEW.EMPTY_TABLE'"
            [header]="standardDistKeysTableModel.header"
            [data]="standardDistKeysTableModel.data"
            [tableSearchId]="'distributionKeysTable'"
            [isLoading]="isLoading"
        >
        </app-table>
        <div class="w-100 notification-box">
            <app-notification
                *ngIf="showMissingDataWarning"
                type="error"
                subtitle="ACHTUNG! Um alle Verteilerschlüssel verwenden zu können, müssen Sie die fehlenden Angaben in den Einheiten ergänzen."
            ></app-notification>
        </div>
    </div>
</div>

<div ibmRow class="mb-0-75r">
    <div ibmCol [columnNumbers]="{ md: 6, lg: 6 }" class="s-head-16-24-bold">
        <app-text-with-tooltip
            class="tw-inline-block"
            [label]="'PAGES.DISTRIBUTION_KEYS.OVERVIEW.INDIVIDUAL_KEYS_TABLE_TITLE' | translate"
            [labelClass]="''"
            [tooltipKey]="TooltipKey.tooltipsInAccounting"
            [tooltipInnerHtml]="'PAGES.TOOLTIPS.DISTRIBUTION_KEYS.OVERVIEW.INDIVIDUAL_KEYS_TABLE_TITLE' | translate"
        ></app-text-with-tooltip>
    </div>
    <div ibmCol *ngIf="editMode && isPropertyManager" class="text-right d-flex justify-content-end">
        <app-scalara-button variant="ghost" (click)="openAddIndividualDistributionKeyOverlay()" svgName="24_add.svg">
            {{ 'CONTROLS.BUTTONS.ADD' | translate }}
        </app-scalara-button>
    </div>
</div>

<div ibmRow class="mb-2r">
    <div ibmCol>
        <app-table
            [emptyText]="'PAGES.DISTRIBUTION_KEYS.OVERVIEW.EMPTY_TABLE'"
            [header]="standardDistKeysTableModel.header"
            [data]="individualDistKeysTableModel.data"
            [tableSearchId]="'distributionKeysTable'"
            [isLoading]="isLoading"
        >
        </app-table>
    </div>
</div>

<ng-template #warningNotificationTemplate let-data="data">
    <a
        [routerLink]="data.link"
        class="warning-missing-data warning-missing-data--{{
            data.label === 'missingData' ? 'red' : 'blue'
        }} d-flex align-items-center"
    >
        <svg-icon src="/assets/icons/24_{{ data.label === 'missingData' ? 'error' : 'information' }}.svg"></svg-icon>
        <div class="s-{{ data.label === 'missingData' ? 'red' : 'blue' }}-01 s-body-14-22-semibold ml-1r">
            {{
                (data.label === 'missingData'
                    ? 'PAGES.DISTRIBUTION_KEYS.OVERVIEW.NO_DATA'
                    : 'PAGES.DISTRIBUTION_KEYS.OVERVIEW.INCOMPLETE_DATA'
                ) | translate
            }}
        </div>
    </a>
</ng-template>
