import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { TableComponent } from '../../table/components/table/table.component';
import { HeaderItem } from '../../table/interfaces/header-item';
import { TableItem } from '../../table/interfaces/table-item';
import { RowSelected } from './row-selected';

@Component({
    selector: 'app-table-multi-selector',
    templateUrl: './table-multi-selector.component.html',
    styleUrls: ['./table-multi-selector.component.scss'],
})
export class TableMultiSelectorComponent implements OnChanges {
    @Input() public itemsPerPage = 15;

    @Input() public emptyText? = '';

    @Input() public emptyTextLink? = '';

    @Input() public emptyTextLinkLabel? = '';

    @Input() public tableSearchId = 'default';

    @Input() public isLoading = false;

    /**
     * Allows the component to update the row status by itself,
     * without depending on external components.
     * If a external component updates this component,
     * A third row status is available named "row-disabled", which disables the row.
     */
    @Input() public isComponentStandAlone = true;

    /**
     * Input of header and data of the table model should happen seperatly so
     * angulars rerender will trigger. In daily use, the header is mostly synchronous
     * but the data will be asynchronous.
     */
    @Input() public header?: (string | HeaderItem)[];

    @Input()
    public get data(): TableItem[][] {
        return this._data;
    }
    public set data(data: TableItem[][]) {
        this._data = data;
    }

    public dataMultiSelect: TableItem[][] = [];

    @Output() public rowSelected = new EventEmitter<RowSelected>();

    @Output() public handleFile = new EventEmitter<void>();

    @ViewChild(TableComponent)
    public tableComponent!: TableComponent;

    private _data: TableItem[][] = [];

    public preparedData: TableItem[][] = [];

    public ngOnChanges(): void {
        /**
         * We keep the unfiltered table up to date by saving the initial index of the rows, so
         * whenever we change the filter, the selection remains.
         */
        if (this.tableSearchId !== '' && this.data && this.data.length > 0 && this.data[0].length > 0) {
            const multiSelectTable = [...this.data];

            for (let i = 0; i < multiSelectTable.length; i++) {
                multiSelectTable[i][this.data[0].length - 1].data.extraData = { unFilteredPosition: i };
            }
            this.data = [...multiSelectTable];
        }
    }

    public onRowClick($event: any): void {
        if (!this.tableComponent.model.isRowDisabled($event)) {
            const rowSelected: RowSelected = {
                rowNumber: $event,
                currentPage: this.tableComponent.model.currentPage,
                data: this.tableComponent.model.row($event),
            };
            this.rowSelected.emit(rowSelected);
            if (this.isComponentStandAlone) {
                const indexOfLastCell = rowSelected.data.length - 1;
                const updatedTable = [...this.tableComponent.unFilteredTableData];
                const unFilteredPosition =
                    this.tableComponent.searchValue !== 'initial_empty_search_value' &&
                    this.tableComponent.searchValue !== ''
                        ? this.tableComponent.model.row($event)[indexOfLastCell].data.extraData.unFilteredPosition
                        : rowSelected.rowNumber;

                if (
                    updatedTable[unFilteredPosition + this.itemsPerPage * (rowSelected.currentPage - 1)][
                        indexOfLastCell
                    ].data.label === 'row-available'
                ) {
                    updatedTable[unFilteredPosition + this.itemsPerPage * (rowSelected.currentPage - 1)][
                        indexOfLastCell
                    ].data.label = 'row-selected';
                } else {
                    updatedTable[unFilteredPosition + this.itemsPerPage * (rowSelected.currentPage - 1)][
                        indexOfLastCell
                    ].data.label = 'row-available';
                }
                if (
                    this.tableComponent.searchValue !== 'initial_empty_search_value' &&
                    this.tableComponent.searchValue !== ''
                ) {
                    this.tableComponent.filteringTableModel();
                } else {
                    this.data = [...updatedTable];
                }
            }
        }
    }

    public handleFiles($event: any): void {
        this.handleFile.emit($event);
    }

    public selectPage(page: number): void {
        this.tableComponent.selectPage(page);
    }
}
