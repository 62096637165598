<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>
<div class="tw-px-6 tw-py-6 lg:tw-px-10">
    <div class="tw-mb-12">
        <div class="tw-flex tw-justify-between tw-mb-5">
            <div class="tw-s-head-28-36-bold">{{ 'PAGES.SETTINGS_USERS_OVERVIEW.HEADLINE' | translate }}</div>
            <app-scalara-button
                *ngIf="(enabledButton | async) && !environment.demoEnvironment; else disabledButtonWithTooltip"
                (click)="openAddNewUserOverlay()"
                variant="ghost"
                svgName="24_add.svg"
                >{{ 'CONTROLS.BUTTONS.ADD' | translate }}</app-scalara-button
            >
        </div>
        <div class="tw-s-head-14-22-regular tw-whitespace-break-spaces">
            <span>{{ 'PAGES.SETTINGS_USERS_OVERVIEW.TEXT_INFO' | translate }}</span>
        </div>
    </div>

    <div class="tw-flex tw-gap-x-6 tw-gap-y-[60px] tw-flex-wrap tw-mt-20">
        <div
            *ngFor="let user of users | async"
            [ngClass]="{ 'tw-text-scalaraGray-04': !user.isActive }"
            class="tw-flex tw-flex-col tw-items-center tw-justify-end tw-w-[356px] tw-h-[127px] tile tw-py-6 tw-relative"
        >
            <div *ngIf="!user.isAdmin && user.isActive" class="tw-hidden tw-absolute tw-right-1 tw-top-1 user-button">
                <app-scalara-button
                    *ngIf="!environment.demoEnvironment"
                    (click)="openDeleteUserOverlay(user)"
                    variant="icon-only"
                    height="40px"
                    svgName="24_error.svg"
                ></app-scalara-button>
            </div>

            <div *ngIf="!user.isAdmin && !user.isActive" class="tw-hidden tw-absolute tw-right-1 tw-top-1 user-button">
                <app-scalara-button
                    *ngIf="!environment.demoEnvironment"
                    (click)="updateCurrentAccount(user)"
                    variant="ghost"
                    height="40px"
                    >Neu aktivieren</app-scalara-button
                >
            </div>

            <div *ngIf="user.isAdmin" class="tw-absolute tw-right-4 tw-top-4">
                <app-tag color="blue">Admin</app-tag>
            </div>

            <div
                class="tw-absolute tw-top-0 tw--translate-y-[50%] tw-rounded-full tw-h-[72px] tw-w-[72px] tw-flex tw-items-center tw-justify-center tw-border-[1px] tw-border-solid tw-border-scalaraGray-05"
                [ngClass]="{ 'tw-bg-scalaraGray-06': !user.isActive, 'tw-bg-scalaraGreen-03': user.isActive }"
            >
                <div class="tw-w-10 tw-h-10">
                    <svg-icon
                        [ngClass]="{
                            'tw-fill-scalaraGray-05': !user.isActive,
                            'tw-fill-scalaraGreen-01': user.isActive
                        }"
                        src="/assets/icons/40_person.svg"
                    ></svg-icon>
                </div>
            </div>

            <div class="tw-s-body-14-22-bold tw-mb-1 tw-px-4 tw-line-clamp-1">
                {{ user.name }}
            </div>
            <div class="tw-s-body-14-22-regular tw-px-4 tw-line-clamp-1">{{ user.email }}</div>
        </div>
    </div>
</div>

<ng-template #disabledButtonWithTooltip>
    <app-tooltip position="left">
        <div content class="tw-px-12 tw-py-8 tw-w-[400px]">
            {{
                (environment.demoEnvironment
                    ? 'PAGES.SETTINGS_USERS_OVERVIEW.TOOLTIP_DEMO'
                    : 'PAGES.SETTINGS_USERS_OVERVIEW.TOOLTIP_MAX_USERS'
                ) | translate
            }}
        </div>
        <div hoverable-item>
            <app-scalara-button [disabled]="true" variant="ghost" svgName="24_add.svg">{{
                'CONTROLS.BUTTONS.ADD' | translate
            }}</app-scalara-button>
        </div></app-tooltip
    >
</ng-template>
