import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ListItem } from 'carbon-components-angular';
import { calculateTaxAmount, getRestAmount } from 'src/app/core/utils/common';
import { AdditionalParameterDefinitionDto, BankTransactionDto } from 'src/app/generated-sources/accounting';
import { RadioAdditionalParameter, RadioOption } from '../../../../interfaces';

interface BookingItem {
    account: AccountItem;
    amount: number;
    labourAmount: number;
}

interface AccountItem extends ListItem {
    accountId: string;
    accountType: string;
}

@Component({
    selector: 'app-add-booking-item-form',
    templateUrl: './add-booking-item-form.component.html',
    styleUrls: ['./add-booking-item-form.component.scss'],
})
export class AddBookingItemFormComponent implements OnInit, OnChanges {
    @Input() public form?: UntypedFormGroup;

    @Input() public bankTransaction?: BankTransactionDto;
    @Input() public type?: string;
    @Input() public title?: string;
    @Input() public label?: string;
    @Input() public titleReserveFundAccountListItems?: string;
    @Input() public taxShare?: RadioAdditionalParameter;
    @Input() public labourAmount?: AdditionalParameterDefinitionDto;

    @Input() public accountListItems: ListItem[] = [];
    @Input() public reserveFundAccountListItems?: ListItem[];

    @Input() public showSum = false;
    @Input() public amountDisabled = false;
    @Input() public enableMultipleBookings = false;
    @Input() public thereIsReserveFundAccount = false;
    @Input() public amountDisabledTooltipMessage = '';
    @Input() public placeHolder = '';
    @Input() public selectedBookingId = -1;

    @Output() public sizeChanged = new EventEmitter<void>();
    @Output() public addNewLabourAmountInput = new EventEmitter<void>();

    public isDoubleDropdown = false;

    public constructor(private formBuilder: UntypedFormBuilder, private translateService: TranslateService) {}

    public ngOnInit(): void {
        const onInit = true;
        this.addItemToForm(onInit);
    }

    public ngOnChanges(): void {
        this.isDoubleDropdown = !!(this.accountListItems.length && this.reserveFundAccountListItems?.length);
        // adding account selection "required" validation after we get the list of accounts from the input
        if (this.accountListItems.length > 0) {
            const control = (this.form?.get(this.type!) as UntypedFormArray)?.value as BookingItem[];
            control.map((item: any, index: number) => {
                const array = this.formArray?.at(index) as UntypedFormGroup;
                array.get('account')?.setValidators(Validators.required);
                array.get('account')?.updateValueAndValidity();
            });
        }

        if (this.thereIsReserveFundAccount) {
            const control = (this.form?.get('accounts') as UntypedFormArray)?.value as BookingItem[];
            control.map((item: any, index: number) => {
                (
                    (this.formArray?.at(index) as UntypedFormGroup).get('reserveFundAccount') as UntypedFormControl
                ).setValidators([Validators.required]);

                (
                    (this.formArray?.at(index) as UntypedFormGroup).get('reserveFundAccount') as UntypedFormControl
                ).updateValueAndValidity();
            });
        }
    }

    public addItemToForm(onInit?: boolean): void {
        let formGroup: UntypedFormGroup;

        if (onInit) {
            //  set amount from bankTransaction for initial
            const defaultValue = this.bankTransaction ? Math.abs(getRestAmount(this.bankTransaction)) : 0;

            formGroup = this.formBuilder.group({
                account: new UntypedFormControl(null),
                amount: new UntypedFormControl(defaultValue, [
                    Validators.required,
                    Validators.min(1),
                    Validators.max(9007199254740991),
                    Validators.pattern('^[0-9]*$'),
                ]),
                reserveFundAccount: new UntypedFormControl(
                    null,
                    this.thereIsReserveFundAccount ? [Validators.required] : []
                ),
                checkboxAmountLabour: new UntypedFormControl(false),
                checkboxTaxShare: new UntypedFormControl(false),
            });
        } else {
            const accountInitialValue =
                this.accountListItems.length == 1
                    ? (this.formArray?.at(0) as UntypedFormGroup).get('account')?.value
                    : null;
            formGroup = this.formBuilder.group({
                account: new UntypedFormControl(accountInitialValue),
                amount: new UntypedFormControl('0,00', [
                    Validators.required,
                    Validators.min(1),
                    Validators.max(9007199254740991),
                    Validators.pattern('^[0-9]*$'),
                ]),
                reserveFundAccount: new UntypedFormControl(
                    (this.formArray?.at(0) as UntypedFormGroup).get('reserveFundAccount')?.value,
                    this.thereIsReserveFundAccount ? [Validators.required] : []
                ),
                checkboxAmountLabour: new UntypedFormControl(false),
                checkboxTaxShare: new UntypedFormControl(false),
            });
        }

        this.formArray?.push(formGroup);

        this.sizeChanged.emit();
    }

    public addNewItemToForm(): void {
        const formGroup = this.formBuilder.group({
            account: new UntypedFormControl(null),
            amount: new UntypedFormControl('0,00', [
                Validators.required,
                Validators.min(1),
                Validators.max(9007199254740991),
                Validators.pattern('^[0-9]*$'),
            ]),
            listAccountFiltered: new UntypedFormControl(
                this.accountListItems.filter((item) => item.selected === false)
            ),
            reserveFundAccount: new UntypedFormControl(
                (this.formArray?.at(0) as UntypedFormGroup).get('reserveFundAccount')?.value
            ),
        });

        this.formArray?.push(formGroup);

        this.sizeChanged.emit();
    }

    public removeItemFromForm(index: number): void {
        this.formArray?.removeAt(index);
        this.sizeChanged.emit();
    }

    public onSelectAccount(data: any): void {
        this.addNewLabourAmountInput.emit(data);
    }

    public invalidateAccount(index: number): void {
        const control = (this.formArray?.at(index) as UntypedFormGroup).get('account') as UntypedFormControl;
        control.setValue(null);
    }

    public invalidateReserveFundAccount(index: number): void {
        const control = (this.formArray?.at(index) as UntypedFormGroup).get('reserveFundAccount') as UntypedFormControl;
        control.setValue(null);
    }

    public get formArray(): UntypedFormArray | null {
        if (!this.type) {
            return null;
        }
        return this.form?.get(this.type) as UntypedFormArray;
    }

    public isFormControlFromArrayInvalid(index: number, controlName: string): boolean {
        const control = (this.formArray?.at(index) as UntypedFormGroup).get(controlName) as UntypedFormControl;
        return control.touched ? control.invalid : false;
    }

    public checkTaxShareBox(index: number): boolean {
        const isBoxChecked = (this.formArray?.at(index) as UntypedFormGroup).get('checkboxTaxShare')?.value;
        if (isBoxChecked) {
            const controlTaxShare = new UntypedFormControl(this.taxShare?.valueOptions[1].id, []);
            (this.formArray?.at(index) as UntypedFormGroup).addControl('taxShare', controlTaxShare);
            return true;
        } else {
            (this.formArray?.at(index) as UntypedFormGroup).removeControl('taxShare');
            return false;
        }
    }

    public calculateSum(): string {
        const array = this.form?.get(this.type!)?.value as BookingItem[];
        let sum = array.reduce((prev, next) => prev + next.amount, 0);

        if (isNaN(sum)) {
            sum = 0;
        }

        return new Intl.NumberFormat('de-DE', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(sum / 100);
    }

    public calculateTaxShareSum(index: number): number {
        const taxShareId = (this.formArray?.at(index) as UntypedFormGroup).get('taxShare')?.value;
        const shareAmount = (this.formArray?.at(index) as UntypedFormGroup).get('amount')?.value;
        let taxAmount = 0;
        if (shareAmount === '0,00') {
            return 0;
        }
        if (this.taxShare) {
            if (this.taxShare.valueOptions.findIndex((option: RadioOption) => option.id == taxShareId) === 0) {
                taxAmount = calculateTaxAmount(this.taxShare.valueOptions[0].amount, shareAmount);
            } else {
                taxAmount = calculateTaxAmount(this.taxShare.valueOptions[1].amount, shareAmount);
            }
        }

        return Number(taxAmount);
    }

    public isLabourAmountInvalid(index: number, controlNameAmount: string, controlNameAmountLabour: string): boolean {
        const controlAmount = (this.formArray?.at(index) as UntypedFormGroup).get(
            controlNameAmount
        ) as UntypedFormControl;
        const controlAmountLabour = (this.formArray?.at(index) as UntypedFormGroup).get(
            controlNameAmountLabour
        ) as UntypedFormControl;
        return controlAmountLabour.touched
            ? controlAmountLabour.invalid || controlAmount.value < controlAmountLabour.value
            : false;
    }

    public showAmountErrorText(index: number): string {
        const controlAmount = (this.formArray?.at(index) as UntypedFormGroup).get('amount') as UntypedFormControl;

        if (controlAmount.invalid) {
            return this.translateService.instant('ACCOUNTING.ERROR.INVALID_AMOUNT');
        } else {
            return this.translateService.instant('ACCOUNTING.ERROR.INVALID_AMOUNT_TRANSACTION');
        }
    }

    public showLabourAmountInvalidText(index: number): string {
        const controlAmountLabour = (this.formArray?.at(index) as UntypedFormGroup).get(
            'amountLabour'
        ) as UntypedFormControl;
        if (controlAmountLabour.invalid) {
            return this.translateService.instant('ACCOUNTING.ERROR.INVALID_AMOUNT');
        } else {
            return this.translateService.instant('ACCOUNTING.ERROR.INVALID_AMOUNT_SALARY_SHARE');
        }
    }

    public checkLabourAmountBox(index: number): boolean {
        const isBoxChecked = (this.formArray?.at(index) as UntypedFormGroup).get('checkboxAmountLabour')?.value;
        const isRequired = this.labourAmount?.isOptional === false;
        if (isBoxChecked) {
            const controlAmountLabour = new UntypedFormControl('0,00', [
                Validators.min(1),
                Validators.max(9007199254740991),
                Validators.pattern('^[0-9]*$'),
            ]);
            if (isRequired) {
                controlAmountLabour.addValidators(Validators.required);
            }

            (this.formArray?.at(index) as UntypedFormGroup).addControl('amountLabour', controlAmountLabour);
            return true;
        } else {
            (this.formArray?.at(index) as UntypedFormGroup).removeControl('amountLabour');
            return false;
        }
    }
}
