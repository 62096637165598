import {Component} from '@angular/core';
import { Temporal } from '@js-temporal/polyfill';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  public readonly year = Temporal.Now.plainDateISO().year;
}
