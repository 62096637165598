import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import {
    BreadcrumbModule,
    ButtonModule,
    CheckboxModule,
    DatePickerModule,
    DialogModule,
    DropdownModule,
    GridModule,
    InlineLoadingModule,
    InputModule,
    LoadingModule,
    NotificationModule,
    NumberModule,
    RadioModule,
    SelectModule,
    TableModule,
    TilesModule,
} from 'carbon-components-angular';
import { SharedModule } from 'src/app/shared/shared.module';
import { DateRangePickerComponent } from '../../shared/components/date-range-picker/date-range-picker.component';
import { AddAdviserOverlayComponent } from './add-adviser-overlay/add-adviser-overlay.component';
import { AddOwnerOverlayComponent } from './add-owner-overlay/add-owner-overlay.component';
import { AddResidentOverlayComponent } from './add-resident-overlay/add-resident-overlay.component';
import { AddServiceProviderOverlayComponent } from './add-service-provider-overlay/add-service-provider-overlay.component';
import { AdvisersComponent } from './advisers/advisers.component';
import { DeleteRelationOverlayComponent } from './delete-relation-overlay/delete-relation-overlay.component';
import { EditRelationOverlayComponent } from './edit-relation-overlay/edit-relation-overlay.component';
import { ExistingPersonsComboBoxComponent } from './existing-persons-combo-box/existing-persons-combo-box.component';
import { OwnersComponent } from './owners/owners.component';
import { ResidentsComponent } from './residents/residents.component';
import { ServiceProviderComponent } from './service-provider/service-provider.component';
import { TableActionsComponent } from './table-actions/table-actions.component';

@NgModule({
    declarations: [
        AddOwnerOverlayComponent,
        EditRelationOverlayComponent,
        DateRangePickerComponent,
        AddServiceProviderOverlayComponent,
        AddAdviserOverlayComponent,
        DeleteRelationOverlayComponent,
        TableActionsComponent,
        AdvisersComponent,
        OwnersComponent,
        ServiceProviderComponent,
        ExistingPersonsComboBoxComponent,
        ResidentsComponent,
        AddResidentOverlayComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        TableModule,
        GridModule,
        ButtonModule,
        LoadingModule,
        InlineLoadingModule,
        DatePickerModule,
        SelectModule,
        FormsModule,
        ReactiveFormsModule,
        NotificationModule,
        SharedModule,
        RouterModule,
        DropdownModule,
        InputModule,
        NumberModule,
        AngularSvgIconModule,
        BreadcrumbModule,
        DialogModule,
        CheckboxModule,
        RadioModule,
        TilesModule,
    ],
    exports: [
        AdvisersComponent,
        OwnersComponent,
        ServiceProviderComponent,
        DateRangePickerComponent,
        ExistingPersonsComboBoxComponent,
        ResidentsComponent,
    ],
})
export class RelationsModule {}
