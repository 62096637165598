import { environment } from '../../../environments/environment';

export const initHubspot = (): void => {
    //  do not init chat on demo environment
    if (environment.demoEnvironment) {
        console.warn('do not init hubspot chat on demo');
        return;
    }
    (window as any).hsConversationsSettings = {
        loadImmediately: false,
    };

    let src = '';
    if (environment.production) {
        src = '//js-eu1.hs-scripts.com/27164378.js';
    } else {
        src = '//js-eu1.hs-scripts.com/139611758.js';
    }

    const script = document.createElement('script');
    script.setAttribute('src', src);
    script.setAttribute('type', 'text/javascript');
    document.head.appendChild(script);
};
