<div class="d-flex">
    <app-sidenav
        menuTitle="PROPERTY.OVERVIEW.HEADLINE"
        [property]="property"
        [menuItems]="menuItems"
        [showChildrenItems]="false"
    ></app-sidenav>

    <div class="bx--grid--full-width w-100 mb-10r">
        <app-property-switch [property]="property" [table]="propertiesTable" menuTitle="property"></app-property-switch>
        <div class="tw-mt-[78px] tw-mx-8">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

<ng-template #defaultPic>
    <div class="default-foto tw-rounded-full tw-h-10 tw-w-10">
        <svg-icon src="/assets/icons/40_building.svg"></svg-icon>
    </div>
</ng-template>
