import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ListItem } from 'carbon-components-angular';
import { Subject } from 'rxjs';
import { getNameFromPerson } from 'src/app/core/utils/common';
import { Occupation } from 'src/app/generated-sources/base';
import { onSubmitRegenerateRentReceivablesCallback } from '../ledger-reasonability-checks.component';

@Component({
    selector: 'app-ledger-reasonability-checks-regenerate-rent-receivables',
    templateUrl: './ledger-reasonability-checks-regenerate-rent-receivables.component.html',
    styleUrls: ['./ledger-reasonability-checks-regenerate-rent-receivables.component.scss'],
})
export class LedgerReasonabilityChecksRegenerateRentReceivablesComponent implements OnInit, OnChanges {
    public regenerateRentReceivablesFormGroup: FormGroup = new FormGroup({});
    public occupationsList$ = new Subject<ListItem[]>();

    @Input()
    public selectedOccupationId?: string;
    @Input()
    public occupationsList: Occupation[] = [];
    @Input()
    public onSubmitCallback?: onSubmitRegenerateRentReceivablesCallback;

    public constructor(
        private translateService: TranslateService,

        private formBuilder: FormBuilder
    ) {}

    public ngOnInit(): void {
        this.regenerateRentReceivablesFormGroup = this.formBuilder.group({
            occupations: new FormControl('', [Validators.required]),
        });
        this.regenerateRentReceivablesFormGroup.updateValueAndValidity();
        this.updateOccupationListItems(this.occupationsList);
    }
    public ngOnChanges(): void {
        this.updateOccupationListItems(this.occupationsList);
    }
    public onSubmit(): void {
        if (this.selectedOccupationId && this.onSubmitCallback) {
            this.onSubmitCallback(this.selectedOccupationId);
        }
    }

    private updateOccupationListItems(occupationList: Occupation[]): void {
        this.occupationsList$.next(
            occupationList.map((occupation) => {
                return {
                    content: `${occupation.occupationNumber} ${occupation.id} ${this.getTenantNames(occupation)}`,
                    value: occupation.id,
                    selected: false,
                    id: occupation.id,
                };
            })
        );
    }

    public onSelectedOccupation($event: any): void {
        this.selectedOccupationId = $event.item['value'];
    }

    private getTenantNames(occupation: Occupation): string {
        return occupation.tenants
            .map((tenant) => (tenant && (tenant.firstName || tenant.companyName) ? getNameFromPerson(tenant) : ''))
            .join(', ');
    }
}
