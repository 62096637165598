<ibm-loading *ngIf="this.vm?.isLoading" [isActive]="true" [size]="'normal'" [overlay]="true"></ibm-loading>
<app-overlay-container
    [headlineTitle]="'Zahlungsverbindung hinzufügen'"
    [translateHeadlineTitle]="false"
    [sizeIcon]="72"
    [image]="this.vm?.currentLayoutMode === 'desktop' ? '40_bank.svg' : ''"
    colorIcon="green"
    class="tw-flex justify-content-center"
>
    <div class="tw-w-full tw-max-w-[736px]">
        <form [formGroup]="form">
            <div class="tw-space-y-1">
                <ibm-label
                    [invalid]="controlInFormGroupIsInvalid(this.form, 'accountHolder')"
                    invalidText="{{ 'ERROR.GENERAL' | translate }}"
                    >{{ 'ENTITIES.BANK_ACCOUNT.ACCOUNT_OWNER' | translate }}*
                    <input
                        ibmText
                        type="text"
                        name="accountHolder"
                        id="accountHolder"
                        formControlName="accountHolder"
                        [invalid]="controlInFormGroupIsInvalid(this.form, 'accountHolder')"
                    />
                </ibm-label>

                <ibm-label
                    [invalid]="controlInFormGroupIsInvalid(this.form, 'iban')"
                    invalidText="{{ 'ERROR.GENERAL' | translate }}"
                    >{{ 'ENTITIES.BANK_ACCOUNT.IBAN' | translate }}*
                    <input
                        ibmText
                        type="text"
                        name="iban"
                        id="iban"
                        formControlName="iban"
                        [invalid]="controlInFormGroupIsInvalid(this.form, 'iban')"
                    />
                </ibm-label>

                <ibm-label
                    [invalid]="controlInFormGroupIsInvalid(this.form, 'bankName')"
                    invalidText="{{ 'ERROR.GENERAL' | translate }}"
                    >{{ 'ENTITIES.BANK_ACCOUNT.BANK_NAME' | translate }}*
                    <input
                        ibmText
                        type="text"
                        name="bankName"
                        id="bankName"
                        formControlName="bankName"
                        [invalid]="controlInFormGroupIsInvalid(this.form, 'bankName')"
                    />
                </ibm-label>

                <ibm-label
                    [invalid]="controlInFormGroupIsInvalid(this.form, 'bic')"
                    invalidText="{{ 'ERROR.GENERAL' | translate }}"
                    >{{ 'ENTITIES.BANK_ACCOUNT.BIC' | translate }}
                    <input
                        ibmText
                        type="text"
                        name="bic"
                        id="bic"
                        formControlName="bic"
                        [invalid]="controlInFormGroupIsInvalid(this.form, 'bic')"
                    />
                </ibm-label>
            </div>
        </form>

        <div
            class="tw-mt-16 lg:tw-mt-28 tw-flex tw-flex-col-reverse tw-justify-between tw-items-center lg:tw-justify-space-between lg:tw-flex-row"
        >
            <app-scalara-button
                (click)="abort()"
                [variant]="'outline'"
                class="tw-w-full lg:tw-w-auto"
                buttonStyles="tw-w-full lg:tw-w-auto"
            >
                {{ 'CONTROLS.BUTTONS.CANCEL' | translate }}
            </app-scalara-button>

            <app-scalara-button
                (click)="submit()"
                [disabled]="isInvalidForm()"
                [variant]="'default'"
                type="submit"
                class="tw-w-full tw-mb-6 lg:tw-mb-0 lg:tw-w-auto"
                buttonStyles="tw-w-full lg:tw-w-auto"
            >
                {{ 'CONTROLS.BUTTONS.SAVE' | translate }}
            </app-scalara-button>
        </div>
    </div>
</app-overlay-container>
