<ibm-loading *ngIf="isLoading" [isActive]="true" [size]="'normal'" [overlay]="true"></ibm-loading>
<app-overlay-container
    [headlineTitle]="'PAGES.ANNUAL_STATEMENT.ADD_CONSUMPTION_DISTRIBUTION_KEY.HEADLINE'"
    [sizeIcon]="72"
>
    <form [formGroup]="form">
        <div class="mb-4r">
            <ibm-label invalidText="{{ 'ERROR.REQUIRED' | translate }}">
                {{ 'ENTITIES.DISTRIBUTION_KEY.LABEL_DESCRIPTION' | translate }}*
                <input formControlName="description" ibmText name="name" id="name" />
            </ibm-label>
        </div>

        <div class="mb-2r s-body-14-22-bold">
            {{ 'PAGES.ANNUAL_STATEMENT.ADD_CONSUMPTION_DISTRIBUTION_KEY.INFO' | translate }}
        </div>

        <div class="d-flex justify-space-between mb-1-5r">
            <div class="s-gray-03 s-label-12-22-semibold">{{ 'ENTITIES.OWNERSHIP.LABEL_ENTITY' | translate }}</div>
            <div class="s-gray-03 s-label-12-22-semibold">Betrag in €</div>
        </div>

        <ng-container formArrayName="ownershipsUsageShare">
            <div
                *ngFor="let ownershipGroup of ownershipsUsageShare.controls; let i = index"
                class="d-flex justify-space-between"
            >
                <ng-container [formGroupName]="i">
                    <div class="d-flex align-items-center">
                        <ibm-checkbox
                            (change)="onCheckboxChange($event, i)"
                            formControlName="enableShare"
                        ></ibm-checkbox>
                        <div class="s-body-14-22-regular ml-0-5r">
                            <div>{{ ownerships[i].name }}</div>
                        </div>
                    </div>

                    <div>
                        <app-number-input
                            label=""
                            invalidText="{{ 'ERROR.REQUIRED' | translate }}"
                            class="mr-1r"
                            formControlName="amountShare"
                        ></app-number-input>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </form>
    <div class="mt-1-5r d-flex justify-content-end">
        <div class="text-right">
            <div class="s-gray-03 s-label-12-22-semibold mb-o-75r">
                {{ 'ENTITIES.DISTRIBUTION_KEY.LABEL_TOTAL_DISTRIBUTION' | translate }}
            </div>
            <div class="s-body-14-22-bold">{{ calculateSum() | eurocent }}</div>
        </div>
    </div>

    <div class="d-flex justify-space-between mt-7-5r">
        <app-scalara-button (click)="abort()" [variant]="'outline'">
            {{ 'CONTROLS.BUTTONS.CANCEL' | translate }}
        </app-scalara-button>

        <app-scalara-button (click)="submit()" [disabled]="isInvalidForm()" [variant]="'default'" type="submit">
            {{ 'CONTROLS.BUTTONS.SAVE' | translate }}
        </app-scalara-button>
    </div>
</app-overlay-container>
