import {Country} from './country';

export const countries:  Country[] = [
    { number: '004', code: 'AF', code3: 'AFG', name: 'Afghanistan' },
    { number: '818', code: 'EG', code3: 'EGY', name: 'Ägypten' },
    { number: '008', code: 'AL', code3: 'ALB', name: 'Albanien' },
    { number: '012', code: 'DZ', code3: 'DZA', name: 'Algerien' },
    { number: '020', code: 'AD', code3: 'AND', name: 'Andorra' },
    { number: '024', code: 'AO', code3: 'AGO', name: 'Angola' },
    { number: '028', code: 'AG', code3: 'ATG', name: 'Antigua und Barbuda' },
    { number: '226', code: 'GQ', code3: 'GNQ', name: 'Äquatorialguinea' },
    { number: '032', code: 'AR', code3: 'ARG', name: 'Argentinien' },
    { number: '051', code: 'AM', code3: 'ARM', name: 'Armenien' },
    { number: '031', code: 'AZ', code3: 'AZE', name: 'Aserbaidschan' },
    { number: '231', code: 'ET', code3: 'ETH', name: 'Äthiopien' },
    { number: '036', code: 'AU', code3: 'AUS', name: 'Australien' },
    { number: '044', code: 'BS', code3: 'BHS', name: 'Bahamas' },
    { number: '048', code: 'BH', code3: 'BHR', name: 'Bahrain' },
    { number: '050', code: 'BD', code3: 'BGD', name: 'Bangladesch' },
    { number: '052', code: 'BB', code3: 'BRB', name: 'Barbados' },
    { number: '112', code: 'BY', code3: 'BLR', name: 'Belarus' },
    { number: '056', code: 'BE', code3: 'BEL', name: 'Belgien' },
    { number: '084', code: 'BZ', code3: 'BLZ', name: 'Belize' },
    { number: '204', code: 'BJ', code3: 'BEN', name: 'Benin' },
    { number: '064', code: 'BT', code3: 'BTN', name: 'Bhutan' },
    { number: '068', code: 'BO', code3: 'BOL', name: 'Bolivien' },
    { number: '070', code: 'BA', code3: 'BIH', name: 'Bosnien und Herzegowina' },
    { number: '072', code: 'BW', code3: 'BWA', name: 'Botswana' },
    { number: '076', code: 'BR', code3: 'BRA', name: 'Brasilien' },
    { number: '096', code: 'BN', code3: 'BRN', name: 'Brunei' },
    { number: '100', code: 'BG', code3: 'BGR', name: 'Bulgarien' },
    { number: '854', code: 'BF', code3: 'BFA', name: 'Burkina Faso' },
    { number: '108', code: 'BI', code3: 'BDI', name: 'Burundi' },
    { number: '152', code: 'CL', code3: 'CHL', name: 'Chile' },
    { number: '156', code: 'CN', code3: 'CHN', name: 'Volksrepublik China' },
    { number: '188', code: 'CR', code3: 'CRI', name: 'Costa Rica' },
    { number: '208', code: 'DK', code3: 'DNK', name: 'Dänemark' },
    { number: '276', code: 'DE', code3: 'DEU', name: 'Deutschland' },
    { number: '212', code: 'DM', code3: 'DMA', name: 'Dominica' },
    { number: '214', code: 'DO', code3: 'DOM', name: 'Dominikanische Republik' },
    { number: '262', code: 'DJ', code3: 'DJI', name: 'Dschibuti' },
    { number: '218', code: 'EC', code3: 'ECU', name: 'Ecuador' },
    { number: '384', code: 'CI', code3: 'CIV', name: 'Elfenbeinküste' },
    { number: '222', code: 'SV', code3: 'SLV', name: 'El Salvador' },
    { number: '232', code: 'ER', code3: 'ERI', name: 'Eritrea' },
    { number: '233', code: 'EE', code3: 'EST', name: 'Estland' },
    { number: '748', code: 'SZ', code3: 'SWZ', name: 'Eswatini' },
    { number: '242', code: 'FJ', code3: 'FJI', name: 'Fidschi' },
    { number: '246', code: 'FI', code3: 'FIN', name: 'Finnland' },
    { number: '250', code: 'FR', code3: 'FRA', name: 'Frankreich' },
    { number: '266', code: 'GA', code3: 'GAB', name: 'Gabun' },
    { number: '270', code: 'GM', code3: 'GMB', name: 'Gambia' },
    { number: '268', code: 'GE', code3: 'GEO', name: 'Georgien' },
    { number: '288', code: 'GH', code3: 'GHA', name: 'Ghana' },
    { number: '308', code: 'GD', code3: 'GRD', name: 'Grenada' },
    { number: '300', code: 'GR', code3: 'GRC', name: 'Griechenland' },
    { number: '320', code: 'GT', code3: 'GTM', name: 'Guatemala' },
    { number: '324', code: 'GN', code3: 'GIN', name: 'Guinea' },
    { number: '624', code: 'GW', code3: 'GNB', name: 'Guinea-Bissau' },
    { number: '328', code: 'GY', code3: 'GUY', name: 'Guyana' },
    { number: '332', code: 'HT', code3: 'HTI', name: 'Haiti' },
    { number: '340', code: 'HN', code3: 'HND', name: 'Honduras' },
    { number: '356', code: 'IN', code3: 'IND', name: 'Indien' },
    { number: '360', code: 'ID', code3: 'IDN', name: 'Indonesien' },
    { number: '368', code: 'IQ', code3: 'IRQ', name: 'Irak' },
    { number: '364', code: 'IR', code3: 'IRN', name: 'Iran' },
    { number: '372', code: 'IE', code3: 'IRL', name: 'Irland' },
    { number: '352', code: 'IS', code3: 'ISL', name: 'Island' },
    { number: '376', code: 'IL', code3: 'ISR', name: 'Israel' },
    { number: '380', code: 'IT', code3: 'ITA', name: 'Italien' },
    { number: '388', code: 'JM', code3: 'JAM', name: 'Jamaika' },
    { number: '392', code: 'JP', code3: 'JPN', name: 'Japan' },
    { number: '887', code: 'YE', code3: 'YEM', name: 'Jemen' },
    { number: '400', code: 'JO', code3: 'JOR', name: 'Jordanien' },
    { number: '116', code: 'KH', code3: 'KHM', name: 'Kambodscha' },
    { number: '120', code: 'CM', code3: 'CMR', name: 'Kamerun' },
    { number: '124', code: 'CA', code3: 'CAN', name: 'Kanada' },
    { number: '132', code: 'CV', code3: 'CPV', name: 'Kap Verde' },
    { number: '398', code: 'KZ', code3: 'KAZ', name: 'Kasachstan' },
    { number: '634', code: 'QA', code3: 'QAT', name: 'Katar' },
    { number: '404', code: 'KE', code3: 'KEN', name: 'Kenia' },
    { number: '417', code: 'KG', code3: 'KGZ', name: 'Kirgisistan' },
    { number: '296', code: 'KI', code3: 'KIR', name: 'Kiribati' },
    { number: '170', code: 'CO', code3: 'COL', name: 'Kolumbien' },
    { number: '174', code: 'KM', code3: 'COM', name: 'Komoren' },
    { number: '180', code: 'CD', code3: 'COD', name: 'Kongo, Demokratische Republik' },
    { number: '178', code: 'CG', code3: 'COG', name: 'Kongo, Republik' },
    { number: '408', code: 'KP', code3: 'PRK', name: 'Korea, Nord' },
    { number: '410', code: 'KR', code3: 'KOR', name: 'Korea, Süd' },
    { number: '191', code: 'HR', code3: 'HRV', name: 'Kroatien' },
    { number: '192', code: 'CU', code3: 'CUB', name: 'Kuba' },
    { number: '414', code: 'KW', code3: 'KWT', name: 'Kuwait' },
    { number: '418', code: 'LA', code3: 'LAO', name: 'Laos' },
    { number: '426', code: 'LS', code3: 'LSO', name: 'Lesotho' },
    { number: '428', code: 'LV', code3: 'LVA', name: 'Lettland' },
    { number: '422', code: 'LB', code3: 'LBN', name: 'Libanon' },
    { number: '430', code: 'LR', code3: 'LBR', name: 'Liberia' },
    { number: '434', code: 'LY', code3: 'LBY', name: 'Libyen' },
    { number: '438', code: 'LI', code3: 'LIE', name: 'Liechtenstein' },
    { number: '440', code: 'LT', code3: 'LTU', name: 'Litauen' },
    { number: '442', code: 'LU', code3: 'LUX', name: 'Luxemburg' },
    { number: '450', code: 'MG', code3: 'MDG', name: 'Madagaskar' },
    { number: '454', code: 'MW', code3: 'MWI', name: 'Malawi' },
    { number: '458', code: 'MY', code3: 'MYS', name: 'Malaysia' },
    { number: '462', code: 'MV', code3: 'MDV', name: 'Malediven' },
    { number: '466', code: 'ML', code3: 'MLI', name: 'Mali' },
    { number: '470', code: 'MT', code3: 'MLT', name: 'Malta' },
    { number: '504', code: 'MA', code3: 'MAR', name: 'Marokko' },
    { number: '584', code: 'MH', code3: 'MHL', name: 'Marshallinseln' },
    { number: '478', code: 'MR', code3: 'MRT', name: 'Mauretanien' },
    { number: '480', code: 'MU', code3: 'MUS', name: 'Mauritius' },
    { number: '484', code: 'MX', code3: 'MEX', name: 'Mexiko' },
    { number: '583', code: 'FM', code3: 'FSM', name: 'Mikronesien' },
    { number: '498', code: 'MD', code3: 'MDA', name: 'Moldau' },
    { number: '492', code: 'MC', code3: 'MCO', name: 'Monaco' },
    { number: '496', code: 'MN', code3: 'MNG', name: 'Mongolei' },
    { number: '499', code: 'ME', code3: 'MNE', name: 'Montenegro' },
    { number: '508', code: 'MZ', code3: 'MOZ', name: 'Mosambik' },
    { number: '104', code: 'MM', code3: 'MMR', name: 'Myanmar' },
    { number: '516', code: 'NA', code3: 'NAM', name: 'Namibia' },
    { number: '520', code: 'NR', code3: 'NRU', name: 'Nauru' },
    { number: '524', code: 'NP', code3: 'NPL', name: 'Nepal' },
    { number: '554', code: 'NZ', code3: 'NZL', name: 'Neuseeland' },
    { number: '558', code: 'NI', code3: 'NIC', name: 'Nicaragua' },
    { number: '528', code: 'NL', code3: 'NLD', name: 'Niederlande' },
    { number: '562', code: 'NE', code3: 'NER', name: 'Niger' },
    { number: '566', code: 'NG', code3: 'NGA', name: 'Nigeria' },
    { number: '807', code: 'MK', code3: 'MKD', name: 'Nordmazedonien' },
    { number: '578', code: 'NO', code3: 'NOR', name: 'Norwegen' },
    { number: '512', code: 'OM', code3: 'OMN', name: 'Oman' },
    { number: '040', code: 'AT', code3: 'AUT', name: 'Österreich' },
    { number: '626', code: 'TL', code3: 'TLS', name: 'Osttimor' },
    { number: '586', code: 'PK', code3: 'PAK', name: 'Pakistan' },
    { number: '585', code: 'PW', code3: 'PLW', name: 'Palau' },
    { number: '591', code: 'PA', code3: 'PAN', name: 'Panama' },
    { number: '598', code: 'PG', code3: 'PNG', name: 'Papua-Neuguinea' },
    { number: '600', code: 'PY', code3: 'PRY', name: 'Paraguay' },
    { number: '604', code: 'PE', code3: 'PER', name: 'Peru' },
    { number: '608', code: 'PH', code3: 'PHL', name: 'Philippinen' },
    { number: '616', code: 'PL', code3: 'POL', name: 'Polen' },
    { number: '620', code: 'PT', code3: 'PRT', name: 'Portugal' },
    { number: '646', code: 'RW', code3: 'RWA', name: 'Ruanda' },
    { number: '642', code: 'RO', code3: 'ROU', name: 'Rumänien' },
    { number: '643', code: 'RU', code3: 'RUS', name: 'Russland' },
    { number: '090', code: 'SB', code3: 'SLB', name: 'Salomonen' },
    { number: '894', code: 'ZM', code3: 'ZMB', name: 'Sambia' },
    { number: '882', code: 'WS', code3: 'WSM', name: 'Samoa' },
    { number: '674', code: 'SM', code3: 'SMR', name: 'San Marino' },
    { number: '678', code: 'ST', code3: 'STP', name: 'São Tomé und Príncipe' },
    { number: '682', code: 'SA', code3: 'SAU', name: 'Saudi-Arabien' },
    { number: '752', code: 'SE', code3: 'SWE', name: 'Schweden' },
    { number: '756', code: 'CH', code3: 'CHE', name: 'Schweiz' },
    { number: '686', code: 'SN', code3: 'SEN', name: 'Senegal' },
    { number: '688', code: 'RS', code3: 'SRB', name: 'Serbien' },
    { number: '690', code: 'SC', code3: 'SYC', name: 'Seychellen' },
    { number: '694', code: 'SL', code3: 'SLE', name: 'Sierra Leone' },
    { number: '716', code: 'ZW', code3: 'ZWE', name: 'Simbabwe' },
    { number: '702', code: 'SG', code3: 'SGP', name: 'Singapur' },
    { number: '703', code: 'SK', code3: 'SVK', name: 'Slowakei' },
    { number: '705', code: 'SI', code3: 'SVN', name: 'Slowenien' },
    { number: '706', code: 'SO', code3: 'SOM', name: 'Somalia' },
    { number: '724', code: 'ES', code3: 'ESP', name: 'Spanien' },
    { number: '144', code: 'LK', code3: 'LKA', name: 'Sri Lanka' },
    { number: '659', code: 'KN', code3: 'KNA', name: 'St. Kitts und Nevis' },
    { number: '662', code: 'LC', code3: 'LCA', name: 'St. Lucia' },
    { number: '670', code: 'VC', code3: 'VCT', name: 'St. Vincent und die Grenadinen' },
    { number: '710', code: 'ZA', code3: 'ZAF', name: 'Südafrika' },
    { number: '729', code: 'SD', code3: 'SDN', name: 'Sudan' },
    { number: '728', code: 'SS', code3: 'SSD', name: 'Südsudan' },
    { number: '740', code: 'SR', code3: 'SUR', name: 'Suriname' },
    { number: '760', code: 'SY', code3: 'SYR', name: 'Syrien' },
    { number: '762', code: 'TJ', code3: 'TJK', name: 'Tadschikistan' },
    { number: '834', code: 'TZ', code3: 'TZA', name: 'Tansania' },
    { number: '764', code: 'TH', code3: 'THA', name: 'Thailand' },
    { number: '768', code: 'TG', code3: 'TGO', name: 'Togo' },
    { number: '776', code: 'TO', code3: 'TON', name: 'Tonga' },
    { number: '780', code: 'TT', code3: 'TTO', name: 'Trinidad und Tobago' },
    { number: '148', code: 'TD', code3: 'TCD', name: 'Tschad' },
    { number: '203', code: 'CZ', code3: 'CZE', name: 'Tschechien' },
    { number: '788', code: 'TN', code3: 'TUN', name: 'Tunesien' },
    { number: '792', code: 'TR', code3: 'TUR', name: 'Türkei' },
    { number: '795', code: 'TM', code3: 'TKM', name: 'Turkmenistan' },
    { number: '798', code: 'TV', code3: 'TUV', name: 'Tuvalu' },
    { number: '800', code: 'UG', code3: 'UGA', name: 'Uganda' },
    { number: '804', code: 'UA', code3: 'UKR', name: 'Ukraine' },
    { number: '348', code: 'HU', code3: 'HUN', name: 'Ungarn' },
    { number: '858', code: 'UY', code3: 'URY', name: 'Uruguay' },
    { number: '860', code: 'UZ', code3: 'UZB', name: 'Usbekistan' },
    { number: '548', code: 'VU', code3: 'VUT', name: 'Vanuatu' },
    { number: '862', code: 'VE', code3: 'VEN', name: 'Venezuela' },
    { number: '784', code: 'AE', code3: 'ARE', name: 'Vereinigte Arabische Emirate' },
    { number: '840', code: 'US', code3: 'USA', name: 'Vereinigte Staaten' },
    { number: '826', code: 'GB', code3: 'GBR', name: 'Vereinigtes Königreich' },
    { number: '704', code: 'VN', code3: 'VNM', name: 'Vietnam' },
    { number: '140', code: 'CF', code3: 'CAF', name: 'Zentralafrikanische Republik' },
    { number: '196', code: 'CY', code3: 'CYP', name: 'Zypern' }
];
