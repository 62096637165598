<div ibmRow>
    <div ibmCol [columnNumbers]="{ md: 4, lg: 4 }"></div>
    <div ibmCol class="mb-5r" [columnNumbers]="{ md: 8, lg: 8 }">
        <app-headline-with-icon
            size="xl"
            color="green"
            image="40_add-person.svg"
            text="{{ 'PAGES.RELATIONS.ADVISER.LABEL_ADD' | translate }}"
        ></app-headline-with-icon>
    </div>
    <div ibmCol [columnNumbers]="{ md: 4, lg: 4 }"></div>
</div>

<div ibmRow class="mb-3r">
    <div ibmCol></div>
    <div ibmCol [columnNumbers]="{ md: 8, lg: 8 }">
        <div class="s-head-16-24-bold mb-2r">
            <!-- {{ 'PAGES.RELATIONS.ADVISER.SELECT_PERSON' | translate }} -->
            <app-text-with-tooltip
                class="tw-inline-block"
                [label]="'PAGES.RELATIONS.ADVISER.SELECT_PERSON' | translate"
                [labelClass]="''"
                [tooltipKey]="TooltipKey.tooltipsInProperties"
                [tooltipInnerHtml]="'PAGES.TOOLTIPS.ADD_ADVISER_OVERLAY.SELECT_PERSON' | translate"
            ></app-text-with-tooltip>
        </div>
        <app-existing-persons-combo-box
            (handlePersonSelection)="onSelectPerson($event)"
            [isAdviser]="true"
            [propertyId]="propertyID"
        ></app-existing-persons-combo-box>
    </div>
    <div ibmCol></div>
</div>

<div ibmRow class="mb-3r" *ngIf="selectedPerson.id">
    <div ibmCol></div>
    <div ibmCol [columnNumbers]="{ md: 8, lg: 8 }" class="mb-06">
        <div class="s-head-16-24-bold mb-1-5r">{{ 'PAGES.RELATIONS.ADVISER.SELECTED_PERSON' | translate }}</div>
        <div class="d-flex align-items-center">
            <app-small-person-infobox
                [personDto]="selectedPerson"
                [options]="['name', 'address']"
            ></app-small-person-infobox>
            <svg-icon
                src="/assets/icons/24_checkbox-check.svg"
                class="check-icon align-items-center d-flex ml-1-5r"
            ></svg-icon>
        </div>
    </div>
    <div ibmCol></div>
</div>

<div ibmRow>
    <div ibmCol></div>
    <div ibmCol [columnNumbers]="{ md: 8, lg: 8 }" class="mb-06">
        <div class="s-head-16-24-bold mb-2r">{{ 'PAGES.RELATIONS.ADVISER.LABEL_INTERVAL_DATE' | translate }}</div>
        <app-date-range-picker></app-date-range-picker>
    </div>
    <div ibmCol></div>
</div>

<form (submit)="onSubmit()">
    <div ibmRow class="mt-11">
        <div ibmCol></div>
        <div ibmCol [columnNumbers]="{ md: 8, lg: 8 }">
            <div ibmRow class="mb-10r">
                <div ibmCol [columnNumbers]="{ md: 8, lg: 8 }">
                    <button [ibmButton]="'tertiary'" type="button" (click)="abort()">
                        {{ 'CONTROLS.BUTTONS.CANCEL' | translate }}
                    </button>
                </div>

                <div ibmCol [columnNumbers]="{ md: 8, lg: 8 }" class="text-right">
                    <button [ibmButton]="'primary'" type="submit" [disabled]="!this.isFormsValid()">
                        {{ 'CONTROLS.BUTTONS.SAVE' | translate }}
                    </button>
                </div>
            </div>
        </div>
        <div ibmCol></div>
    </div>
</form>
