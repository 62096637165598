<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<div ibmGrid *ngIf="!showAjustAdvances && !isLoading" class="bx--grid--full-width edit-economic-plan">
    <div ibmRow>
        <div ibmCol></div>
        <div ibmCol [columnNumbers]="{ md: 14, lg: 14 }">
            <div ibmRow class="mb-5r">
                <app-headline-with-icon
                    color="blue"
                    image="40_document_edit.svg"
                    size="xl"
                    text="{{ 'PAGES.ECONOMIC_PLAN.EDIT.HEADLINE' | translate }}"
                ></app-headline-with-icon>
            </div>
            <div ibmRow>
                <app-notification type="info" class="w-100">
                    <div class="s-head-14-22-regular">
                        {{ 'PAGES.ECONOMIC_PLAN.EDIT.NOTIFICATION' | translate }}
                    </div>
                </app-notification>
            </div>
        </div>
        <div ibmCol></div>
    </div>
    <div ibmRow *ngIf="!isLoading">
        <div ibmCol></div>
        <div ibmCol [columnNumbers]="{ md: 14, lg: 14 }">
            <form (keydown.enter)="$event.preventDefault()" [formGroup]="form">
                <ng-container *ngFor="let accountGroup of this.accountsList; let i = index">
                    <ng-container *ngIf="accountGroup.length > 0">
                        <div ibmRow class="s-head-16-24-bold mb-2r mt-5r">
                            {{ 'PAGES.ECONOMIC_PLAN.EDIT.ACCOUNT_GROUP_TYPE_' + i | translate }}
                        </div>
                        <div
                            ibmRow
                            class="account-row account-row__header d-flex align-items-center justify-space-between s-label-12-22-semibold mb-1r"
                        >
                            <div ibmCol [columnNumbers]="{ md: 1, lg: 1 }" class="enable">
                                <span>{{ 'ENTITIES.ECONOMIC_PLAN.LABEL_IS_RELEVANT' | translate }}</span>
                            </div>
                            <div ibmCol [columnNumbers]="{ md: 4, lg: 4 }">
                                <span>{{ 'ENTITIES.ECONOMIC_PLAN.LABEL_ACCOUNT' | translate }}</span>
                            </div>
                            <div ibmCol [columnNumbers]="{ md: 4, lg: 4 }">
                                <span>{{ 'ENTITIES.ECONOMIC_PLAN.LABEL_DISTRIBUTION_KEY' | translate }}</span>
                            </div>
                            <div ibmCol [columnNumbers]="{ md: 2, lg: 2 }" class="text-right">
                                <span>{{
                                    'ENTITIES.ECONOMIC_PLAN.LABEL_DISTRIBUTION_KEY_TOTAL_AMOUNT' | translate
                                }}</span>
                            </div>
                            <div ibmCol [columnNumbers]="{ md: 2, lg: 2 }" class="text-right">
                                <span>{{ 'ENTITIES.ECONOMIC_PLAN.LABEL_FORECAST_PREVIOUS_YEAR' | translate }}</span>
                            </div>
                            <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }" class="text-right">
                                <span>{{ 'ENTITIES.ECONOMIC_PLAN.LABEL_FORECAST_CURRENT_YEAR' | translate }}</span>
                            </div>
                        </div>
                        <ng-container [formArrayName]="accountGroups[i]">
                            <ng-container
                                *ngFor="let account of accountGroup; let j = index"
                                [formGroup]="getAccountForm(accountGroups[i], j)"
                            >
                                <div
                                    ibmRow
                                    *ngIf="!isAccountHidden(i, j)"
                                    class="account-row account-row__content d-flex align-items-center justify-space-between py-1r"
                                >
                                    <div ibmCol [columnNumbers]="{ md: 1, lg: 1 }" class="enable">
                                        <ibm-checkbox
                                            (change)="onCheckboxChange($event, accountGroups[i], j)"
                                            formControlName="enableAccount"
                                        >
                                        </ibm-checkbox>
                                    </div>
                                    <div ibmCol [columnNumbers]="{ md: 4, lg: 4 }">
                                        <span
                                            >{{ accountsList[i][j].accountName
                                            }}{{
                                                accountsList[i][j].accountDescription
                                                    ? ', ' + accountsList[i][j].accountDescription
                                                    : ''
                                            }}</span
                                        >
                                    </div>
                                    <div ibmCol [columnNumbers]="{ md: 4, lg: 4 }">
                                        <app-combo-box
                                            [size]="'md'"
                                            id="distributionKey"
                                            [items]="distributionKeysListItems"
                                            formControlName="distributionKey"
                                            (selected)="onSelectDistributionKey($event, i, j)"
                                            placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT' | translate }}"
                                        >
                                            <ibm-dropdown-list></ibm-dropdown-list>
                                        </app-combo-box>
                                    </div>
                                    <div ibmCol [columnNumbers]="{ md: 2, lg: 2 }" class="text-right">
                                        {{
                                            accountsList[i][j].distributionBase === 'CONSUMPTION'
                                                ? (accountsList[i][j].totalDistribution | eurocent)
                                                : (accountsList[i][j].totalDistribution | number : '1.0-3' : 'de-De')
                                        }}
                                    </div>
                                    <div ibmCol [columnNumbers]="{ md: 2, lg: 2 }" class="text-right">
                                        {{ accountsList[i][j].forecastPreviousYear! | eurocent }}
                                    </div>
                                    <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }">
                                        <app-number-input
                                            [allowNegatives]="false"
                                            formControlName="amountShare"
                                        ></app-number-input>
                                    </div>
                                </div>
                            </ng-container>
                            <div ibmRow class="amount-group py-0-75r align-items-center mt-1r">
                                <div ibmCol [columnNumbers]="{ md: 10, lg: 10 }">
                                    <ibm-checkbox (change)="displayCheckboxChange($event, i)">
                                        {{ 'PAGES.ECONOMIC_PLAN.EDIT.HIDE_CHECKBOX_LABEL' | translate }}
                                    </ibm-checkbox>
                                </div>
                                <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }" class="s-head-14-22-bold text-right">
                                    {{ 'ENTITIES.ECONOMIC_PLAN.LABEL_SHARED_AMOUNT' | translate }}
                                </div>
                                <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }" class="s-head-14-22-bold text-right">
                                    {{ this.accountGroupsCurrentAmount[i] | eurocent }}
                                </div>
                            </div>
                            <div
                                *ngIf="i === this.accountsList.length - 3"
                                ibmRow
                                class="total-amount pt-2r mb-4r mt-2-5r s-head-16-24-bold text-right"
                            >
                                <div ibmCol [columnNumbers]="{ md: 9, lg: 9 }"></div>
                                <div ibmCol [columnNumbers]="{ md: 4, lg: 4 }">
                                    {{ 'PAGES.ECONOMIC_PLAN.EDIT.LABEL_MANAGEMENT_COSTS' | translate }}
                                </div>
                                <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }">
                                    {{ costAllocation | eurocent }}
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
                <div ibmRow class="total-amount pt-2r mb-4r mt-2-5r s-head-16-24-bold text-right">
                    <div ibmCol [columnNumbers]="{ md: 10, lg: 10 }"></div>
                    <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }">
                        {{ 'PAGES.ECONOMIC_PLAN.EDIT.LABEL_TOTAL_ANNUAL_AMOUNT' | translate }}
                    </div>
                    <div ibmCol [columnNumbers]="{ md: 3, lg: 3 }">{{ totalAnnualAmount | eurocent }}</div>
                </div>
                <div ibmRow>
                    <div ibmCol class="s-head-16-24-bold mb-2r">
                        {{ 'PAGES.ECONOMIC_PLAN.EDIT.TITLE_MATURITY_RATE_INTERVALS' | translate }}
                    </div>
                </div>
                <div ibmRow class="mb-5r">
                    <div ibmCol [columnNumbers]="{ md: 5, lg: 5 }">
                        <app-combo-box
                            [size]="'md'"
                            [items]="maturityListItems"
                            formControlName="maturity"
                            label="{{ 'ENTITIES.ECONOMIC_PLAN.LABEL_MATURITY' | translate }}*"
                            placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT' | translate }}"
                        >
                            <ibm-dropdown-list></ibm-dropdown-list>
                        </app-combo-box>
                    </div>
                    <div ibmCol [columnNumbers]="{ md: 5, lg: 5 }">
                        <app-combo-box
                            [size]="'md'"
                            [items]="rateIntervalListItems"
                            formControlName="rateInterval"
                            label="{{ 'ENTITIES.ECONOMIC_PLAN.LABEL_RATE_INTERVAL' | translate }}*"
                            placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT' | translate }}"
                        >
                            <ibm-dropdown-list></ibm-dropdown-list>
                        </app-combo-box>
                    </div>
                </div>
                <div ibmRow>
                    <div ibmCol [ngClass]="{ 'mb-1-5r': isWrongCostsAllocation, 'mb-7-5r': !isWrongCostsAllocation }">
                        <app-notification type="info" class="w-100">
                            <div class="s-head-14-22-regular">
                                {{ 'PAGES.ECONOMIC_PLAN.EDIT.NOTIFICATION' | translate }}
                            </div>
                        </app-notification>
                    </div>
                </div>
                <div *ngIf="isWrongCostsAllocation" ibmRow>
                    <div ibmCol class="mb-7-5r">
                        <app-notification type="error" class="w-100">
                            <div class="s-head-14-22-regular">
                                {{ 'PAGES.ECONOMIC_PLAN.EDIT.WARNING_DISABLED_TO_SAVE' | translate }}
                            </div>
                        </app-notification>
                    </div>
                </div>

                <!-- START BUTTON ROW -->
                <div ibmRow class="mb-10r">
                    <div ibmCol>
                        <button [ibmButton]="'tertiary'" type="reset" (click)="abort()">
                            {{ 'CONTROLS.BUTTONS.CANCEL' | translate }}
                        </button>
                    </div>

                    <div ibmCol class="text-right">
                        <button
                            [disabled]="isWrongCostsAllocation"
                            [ibmButton]="'primary'"
                            type="submit"
                            (click)="isDifferingAdvancements ? handleAdjustAdvancesOverlay() : onSubmit()"
                        >
                            {{
                                (isDifferingAdvancements ? 'CONTROLS.BUTTONS.CONTINUE' : 'COMMON.BTN_SAVE') | translate
                            }}
                        </button>
                    </div>
                </div>
                <!-- END BUTTON ROW -->
            </form>
        </div>
        <div ibmCol></div>
    </div>
</div>

<ng-container *ngIf="showAjustAdvances"
    ><app-adjust-advances
        (handleAdjustAdvancesOverlay)="handleAdjustAdvancesOverlay()"
        (submitDifferingAdvancements)="submitDifferingAdvancements($event)"
        [ledgerId]="ledgerId"
        [economicPlanId]="economicPlanId"
        [advancements]="advancements"
    ></app-adjust-advances
></ng-container>
