<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"> </ibm-loading>

<div class="p-05"></div>

<div ibmGrid class="bx--grid--full-width app-property-overview">
    <div ibmRow [ngClass]="'mb-08'">
        <div ibmCol [columnNumbers]="{ md: 5, lg: 1, xl: 1 }"></div>
        <div ibmCol [columnNumbers]="{ md: 5, lg: 12, xl: 12 }">
            <div class="s-head-28-36-bold">Personenliste für Vertreter</div>
        </div>

        <div ibmCol [columnNumbers]="{ md: 5, lg: 1, xl: 1 }">
            <app-scalara-button
                (click)="resetSurrogateToHome()"
                class="mr-05"
                variant="ghost"
                type="button"
                svgName="24_not-available.svg"
            >
                Zurück nach Hause
            </app-scalara-button>
        </div>
    </div>

    <div ibmRow>
        <div ibmCol class="mb-1r d-flex justify-content-end">
            <app-search [searchInputId]="'surrogateTable'"></app-search>
        </div>
    </div>
    <div ibmRow>
        <div ibmCol>
            <app-table
                [emptyText]="'PROPERTY.OVERVIEW.EMPTY_TABLE'"
                [header]="tableModel.header"
                [data]="tableModel.data"
                [tableSearchId]="'surrogateTable'"
                [isLoading]="isLoading"
            >
            </app-table>
        </div>
    </div>
</div>
