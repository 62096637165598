import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject, map, switchMap, takeUntil, tap } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { ManagedUserDto, UserManagementService } from 'src/app/generated-sources/base';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { environment } from 'src/environments/environment';
import { AddUserComponent } from '../add-user/add-user.component';
import { DeleteUserComponent } from '../delete-user/delete-user.component';

@Component({
    selector: 'app-users-overview',
    templateUrl: './users-overview.component.html',
    styleUrls: ['./users-overview.component.scss'],
})
export class UsersOverviewComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();

    public refresh$ = new BehaviorSubject<void>(undefined);

    public isLoading = false;

    public readonly environment = environment;

    public users = this.refresh$.pipe(
        switchMap(() => this.userManagementService.getAllManagedUsers()),
        takeUntil(this.unsubscribe$)
    );

    public enabledButton = this.users.pipe(map((users) => users.filter((user) => user.isActive).length <= 10));

    public constructor(
        private overlayService: OverlayService,
        public userManagementService: UserManagementService,
        private toastService: ToastService,
        private translateService: TranslateService
    ) {}

    public ngOnInit(): void {}

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public openDeleteUserOverlay(user: ManagedUserDto): void {
        const ref = this.overlayService.open(DeleteUserComponent, { data: { user } });

        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.refresh$.next());
    }

    public openAddNewUserOverlay(): void {
        const ref = this.overlayService.open(AddUserComponent, { data: {} });

        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.refresh$.next());
    }

    public updateCurrentAccount(user: ManagedUserDto): void {
        this.isLoading = true;
        this.userManagementService
            .reactivateManagedUser(user.id)
            .pipe(
                tap(() => {
                    this.toastService.showSuccess(
                        this.translateService.instant('PAGES.SETTINGS_USERS_OVERVIEW.REACTIVATE_USER_SUCESS_TOAST')
                    );
                    this.refresh$.next();
                }),
                tap(() => (this.isLoading = false)),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                error: (error) => {
                    this.isLoading = false;
                    if (error) {
                        this.toastService.showError(error.error['message']);
                    }
                },
            });
    }
}
