<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"></ibm-loading>

<app-signin-signup-container routeLink="sign-up">
    <div class="main-container">
        <h1 class="s-head-28-36-bold mb-3r mt-2r">{{ 'PAGES.RESET_PASSWORD.HEADER' | translate }}</h1>
        <app-notification [type]="'info'">
            {{ 'PAGES.RESET_PASSWORD.SET_PASSWORD.SUBHEADER.INFORMATION' | translate }}
        </app-notification>

        <form [formGroup]="form" class="mt-2r">
            <div>
                <!-- CODE -->
                <ibm-label
                    [invalid]="codeControlIsInvalid"
                    invalidText="{{ 'CONTROLS.FORMCONTROLS.CODE_VERIFICATION.INVALID' | translate }}"
                >
                    {{ 'CONTROLS.FORMCONTROLS.CODE_VERIFICATION.LABEL' | translate }}*
                    <input
                        ibmText
                        type="text"
                        placeholder=""
                        name="code"
                        id="code"
                        formControlName="code"
                        [invalid]="codeControlIsInvalid"
                    />
                </ibm-label>

                <p class="s-body-14-22-regular mb-1-5r mt-1-5r">
                    {{ 'PAGES.RESET_PASSWORD.SET_PASSWORD.GIVE_PASSWORD_MESSAGE' | translate }}
                </p>

                <!-- PASSWORD -->
                <app-password-input
                    [form]="form"
                    inputName="password"
                    label="{{ 'CONTROLS.FORMCONTROLS.CONFIRM_PASSWORD.LABEL' | translate }}*"
                ></app-password-input>
                <!-- PASSWORD WIEDERHOLEN -->
                <app-password-input
                    [form]="form"
                    inputName="confirmPassword"
                    label="{{ 'CONTROLS.FORMCONTROLS.CONFIRM_PASSWORD.LABEL' | translate }}*"
                ></app-password-input>
            </div>

            <div class="d-flex justify-space-between mt-5r">
                <div>
                    <a routerLink="/sign-in">
                        {{ 'CONTROLS.LINKS.BACK_TO_LOGIN' | translate }}
                    </a>
                </div>
                <app-scalara-button type="button" variant="default" [disabled]="form.invalid" (click)="onSubmit()">
                    {{ 'CONTROLS.BUTTONS.SAVE' | translate }}
                </app-scalara-button>
            </div>
        </form>
    </div>
</app-signin-signup-container>
