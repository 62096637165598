<app-tooltip position="top" [isDisabled]="!this.disabled || this.disabledTooltipMessage === ''">
    <div content class="tooltip-box">
        {{ this.disabledTooltipMessage }}
    </div>
    <div hoverable-item>
        <div class="bx--form-item bx--text-input-wrapper">
            <label *ngIf="label" [for]="id" class="bx--label">{{ label! }}</label>

            <div class="bx--text-input__field-wrapper" [attr.data-invalid]="invalid ? true : null">
                <svg
                    *ngIf="invalid"
                    focusable="false"
                    preserveAspectRatio="xMidYMid meet"
                    style="will-change: transform"
                    xmlns="http://www.w3.org/2000/svg"
                    class="bx--text-input__invalid-icon"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    aria-hidden="true"
                >
                    <path
                        d="M8,1C4.2,1,1,4.2,1,8s3.2,7,7,7s7-3.1,7-7S11.9,1,8,1z M7.5,4h1v5h-1C7.5,9,7.5,4,7.5,4z M8,12.2	c-0.4,0-0.8-0.4-0.8-0.8s0.3-0.8,0.8-0.8c0.4,0,0.8,0.4,0.8,0.8S8.4,12.2,8,12.2z"
                    ></path>
                    <path
                        d="M7.5,4h1v5h-1C7.5,9,7.5,4,7.5,4z M8,12.2c-0.4,0-0.8-0.4-0.8-0.8s0.3-0.8,0.8-0.8	c0.4,0,0.8,0.4,0.8,0.8S8.4,12.2,8,12.2z"
                        data-icon-path="inner-path"
                        opacity="0"
                    ></path>
                </svg>

                <input
                    [id]="id"
                    type="text"
                    class="{{ 'bx--text-input text-' + orientation }}"
                    [ngClass]="{ 'bx--text-input--invalid': invalid }"
                    [(ngModel)]="value"
                    [disabled]="disabled"
                    (blur)="onInputBlur($event)"
                    onclick="this.select()"
                    (keydown)="preventLetterInput($event)"
                />
            </div>

            <div class="bx--form-requirement">{{ invalidText }}</div>
        </div>
    </div></app-tooltip
>
