import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthSuccessFlowTypeEnum } from '../types/authSuccess';
import { GiveEmailFlowTypesObj } from '../types/giveEmail';

type ConditionalTextLabels = Record<AuthSuccessFlowTypeEnum, { headerLabel: string; noteText: string }>;
@Component({
    selector: 'app-auth-success',
    templateUrl: './auth-success.component.html',
    styleUrls: ['./auth-success.component.scss'],
})
export class AuthSuccessComponent implements OnInit {
    public constructor(public router: Router, public translate: TranslateService, private route: ActivatedRoute) {}
    public authSuccessFlowTypeDeclared = AuthSuccessFlowTypeEnum;
    public flowType: AuthSuccessFlowTypeEnum = AuthSuccessFlowTypeEnum.resetPassword;
    public giveEmailFlowTypeObjDeclared = GiveEmailFlowTypesObj;

    public buttonLabel = this.translate.instant('CONTROLS.BUTTONS.TO_LOGIN');
    public conditionalTextLabels: ConditionalTextLabels = {
        'reset-password': {
            headerLabel: this.translate.instant('PAGES.RESET_PASSWORD.HEADER'),
            noteText: this.translate.instant('PAGES.AUTH_SUCCESS.FLOWS.RESET_PASSWORD.SUCCESS_NOTE'),
        },
        'resend-verification-link': {
            headerLabel: this.translate.instant('PAGES.RESEND_EMAIL.HEADER'),
            noteText: this.translate.instant('PAGES.AUTH_SUCCESS.FLOWS.VERIFICATION.SUCCESS_NOTE', {
                route: '/sign-up',
            }),
        },
        'new-sign-up': {
            headerLabel: this.translate.instant('PAGES.SIGN_UP.HEADER'),
            noteText: this.translate.instant('PAGES.AUTH_SUCCESS.FLOWS.VERIFICATION.SUCCESS_NOTE'),
        },
    };

    public ngOnInit(): void {
        this.route.queryParamMap.subscribe((params) => {
            this.flowType = params.get('flowType') as AuthSuccessFlowTypeEnum;
        });

        if (!this.flowType || !Object.values(AuthSuccessFlowTypeEnum).includes(this.flowType)) {
            console.warn('you must provide proper flowType for this page.');
        }
    }

    public onButtonClick(): void {
        this.router.navigate(['/sign-in']);
    }

    public get containerWidth(): string {
        if (this.flowType === AuthSuccessFlowTypeEnum.resetPassword) {
            return '34rem';
        }
        return '46rem';
    }
}
