<ibm-loading *ngIf="isInitialLoading" [isActive]="isInitialLoading" [size]="'normal'" [overlay]="isInitialLoading">
</ibm-loading>
<div class="property-container app-property-overview">
    <div class="mb-4r d-flex justify-space-between">
        <div class="s-head-28-36-bold">
            {{ (propertyType === 'MV' ? 'PROPERTY.COMMON.OCCUPATIONS' : 'PROPERTY.COMMON.SEV') | translate }}
        </div>
        <app-scalara-button
            *ngIf="person?.personRoles?.includes('IS_PROPERTY_MANAGER')"
            variant="ghost"
            [disabled]="showInfoBox"
            (click)="openAddOccupationOverlay()"
            svgName="24_add.svg"
        >
            {{ 'PAGES.OCCUPATION.OVERVIEW.LABEL_ADD_OCCUPATION_BUTTON' | translate }}
        </app-scalara-button>
    </div>
    <div class="mb-3r" *ngIf="showInfoBox && person?.personRoles?.includes('IS_PROPERTY_MANAGER')">
        <app-notification
            type="warning"
            subtitle="{{
                (propertyType === 'MV' ? 'PAGES.OCCUPATION.OVERVIEW.INFO_BOX_MV' : 'PAGES.OCCUPATION.OVERVIEW.INFO_BOX')
                    | translate
            }}"
        >
        </app-notification>
    </div>

    <ibm-tabs [followFocus]="true" [cacheActive]="true">
        <ibm-tab heading="Alle">
            <div class="mt-1r">
                <div class="d-flex justify-content-end mb-1r">
                    <div>
                        <app-search searchInputId="allOccupations"></app-search>
                    </div>

                    <ng-container *ngTemplateOutlet="dateFilter"></ng-container>
                </div>

                <app-table
                    emptyText="PAGES.OCCUPATION.OVERVIEW.LABEL_EMPTY_TABLE"
                    [header]="tableHeader"
                    [data]="tableModel[0].data || []"
                    tableSearchId="allOccupations"
                    [isLoading]="!isInitialLoading && isLoading"
                >
                </app-table>
            </div>
        </ibm-tab>

        <ibm-tab heading="Vermietung" title="Vermietung">
            <div class="mt-1r">
                <div class="d-flex justify-content-end mb-1r">
                    <div>
                        <app-search searchInputId="Vermietung"></app-search>
                    </div>

                    <ng-container *ngTemplateOutlet="dateFilter"></ng-container>
                </div>
                <app-table
                    emptyText="PAGES.OCCUPATION.OVERVIEW.LABEL_EMPTY_TABLE"
                    [header]="tableHeader"
                    [data]="tableModel[1].data || []"
                    tableSearchId="Vermietung"
                    [isLoading]="!isInitialLoading && isLoading"
                >
                </app-table>
            </div>
        </ibm-tab>

        <ibm-tab heading="Leerstand" title="Leerstand">
            <div class="mt-1r">
                <div class="d-flex justify-content-end mb-1r">
                    <div>
                        <app-search searchInputId="Leerstand"></app-search>
                    </div>

                    <ng-container *ngTemplateOutlet="dateFilter"></ng-container>
                </div>
                <app-table
                    emptyText="PAGES.OCCUPATION.OVERVIEW.LABEL_EMPTY_TABLE"
                    [header]="tableHeader"
                    [data]="tableModel[2].data || []"
                    tableSearchId="Leerstand"
                    [isLoading]="!isInitialLoading && isLoading"
                >
                </app-table>
            </div>
        </ibm-tab>

        <ibm-tab heading="Selbstnutzung" title="Selbstnutzung">
            <div class="mt-1r">
                <div class="d-flex justify-content-end mb-1r">
                    <div>
                        <app-search searchInputId="Selbstnutzung"></app-search>
                    </div>

                    <ng-container *ngTemplateOutlet="dateFilter"></ng-container>
                </div>
                <app-table
                    emptyText="PAGES.OCCUPATION.OVERVIEW.LABEL_EMPTY_TABLE"
                    [header]="tableHeader"
                    [data]="tableModel[3].data || []"
                    tableSearchId="Selbstnutzung"
                    [isLoading]="!isInitialLoading && isLoading"
                >
                </app-table>
            </div>
        </ibm-tab>
    </ibm-tabs>
</div>

<ng-template #iconInfoTemplate let-data="data">
    <svg-icon class="formated-icon attention-icon" src="/assets/icons/24_attention.svg"></svg-icon>
</ng-template>

<ng-template #dateFilter>
    <div class="ml-1r styled-filter">
        <ibm-dropdown
            [(ngModel)]="filterControl"
            [displayValue]="dropdownRenderer"
            placeholder="Select"
            (selected)="onSelectFilter()"
        >
            <ibm-dropdown-list [items]="filterItems"></ibm-dropdown-list>
        </ibm-dropdown>
        <ng-template #dropdownRenderer let-item="item">
            <div *ngIf="item && item.content" class="styled-icon-calender">
                <svg-icon src="/assets/icons/24_calendar.svg"></svg-icon>
                {{ item.content }}
            </div>
        </ng-template>
    </div>
</ng-template>
