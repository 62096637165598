import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, of, switchMap, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import {
    Occupation,
    OccupationService,
    RentDetails,
    RentInterval,
    RentIntervalDto,
    UpdateOccupationDto,
} from 'src/app/generated-sources/base';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';

@Component({
    selector: 'app-edit-rent-payment',
    templateUrl: './edit-rent-payment.component.html',
    styleUrls: ['./edit-rent-payment.component.scss'],
})
export class EditRentPaymentComponent extends OverlayChildComponent implements OnInit {
    public rentCalculationMethod?: RentDetails.RentCalculationMethodEnum;
    public occupation?: Occupation;
    public propertyId = '';
    private unsubscribe$ = new Subject<void>();
    public isLoading = false;
    public showOccupationDurationWarning = false;
    public showWarningMessage = false;

    public form: UntypedFormGroup = this.formBuilder.group({
        rentIntervals: this.formBuilder.array([]),
    });

    public constructor(
        private formBuilder: UntypedFormBuilder,
        private occupationService: OccupationService,
        private toastService: ToastService,
        private translateService: TranslateService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.occupation = this.config?.data.occupation;
        this.propertyId = this.config?.data.propertyId;
        this.rentCalculationMethod = this.occupation?.rentDetails?.rentCalculationMethod;
        if (this.occupation?.rentDetails?.rentIntervals && this.occupation?.rentDetails?.rentIntervals.length > 0) {
            this.occupation?.rentDetails?.rentIntervals.map((rentInterval) => {
                this.addRendInterval(rentInterval);
            });
        } else {
            this.addRendInterval();
        }
    }

    public get rentIntervalsControl(): UntypedFormArray {
        return this.form?.controls['rentIntervals'] as UntypedFormArray;
    }

    public removeRentInterval(index: number): void {
        this.rentIntervalsControl.removeAt(index);
    }

    public addRendInterval(rentInterval?: RentInterval): void {
        this.rentIntervalsControl.push(
            this.formBuilder.group({
                id: [rentInterval?.id || ''],
                startingDate: [rentInterval?.startingDate ? new Date(rentInterval?.startingDate) : null],
                rent: [(rentInterval ? rentInterval.rent : this.occupation?.rentDetails?.rentIntervals[0]?.rent) ?? 0],
                operationalCosts: [
                    (rentInterval
                        ? rentInterval.operationalCosts
                        : this.occupation?.rentDetails?.rentIntervals[0]?.operationalCosts) ?? 0,
                ],
                heatingCosts: [
                    (rentInterval
                        ? rentInterval.heatingCosts
                        : this.occupation?.rentDetails?.rentIntervals[0]?.heatingCosts) ?? 0,
                ],
                prepaidSum: [
                    (rentInterval
                        ? rentInterval.prepaidSum
                        : this.occupation?.rentDetails?.rentIntervals[0]?.prepaidSum) ?? 0,
                ],
            })
        );
    }

    public getRentIntervalItemForm(index: number): UntypedFormGroup {
        return this.rentIntervalsControl.at(index) as UntypedFormGroup;
    }

    public getPrepaidSum(index: number): number {
        const { rent, operationalCosts, heatingCosts, prepaidSum } = this.getRentIntervalItemForm(index).value;
        this.getRentIntervalItemForm(index)?.patchValue({
            prepaidSum: prepaidSum,
        });
        return Number(prepaidSum || 0) + Number(rent || 0) + Number(operationalCosts || 0) + Number(heatingCosts || 0);
    }

    public abort(): void {
        this.cancelEmitter$.next();
    }

    public submit(): void {
        if (!this.occupation?.id) {
            return;
        }

        const rentIntervals: RentIntervalDto[] = this.rentIntervalsControl.value.map((item: any) => {
            return {
                startingDate: item.startingDate[0] || item.startingDate || undefined,
                rent: item.rent,
                operationalCosts: item.operationalCosts ?? undefined,
                heatingCosts: item.heatingCosts ?? undefined,
                prepaidSum: item.prepaidSum ?? undefined,
            };
        });

        const updateOccupationDto: UpdateOccupationDto = { rentIntervals: rentIntervals };

        this.isLoading = true;

        const updateOccupationObs = this.updateOccupationObs(this.propertyId, this.occupation.id, updateOccupationDto);

        let obs;

        if (this.showWarningMessage) {
            obs = updateOccupationObs;
        } else {
            obs = this.occupationService
                .showOccupationRentReceivablesWarning(this.propertyId, this.occupation?.id, updateOccupationDto)
                .pipe(
                    switchMap((showOccupationDurationWarning) => {
                        this.showOccupationDurationWarning = showOccupationDurationWarning;

                        if (showOccupationDurationWarning || !this.occupation?.id) {
                            return of(null);
                        }

                        return updateOccupationObs;
                    }),
                    takeUntil(this.unsubscribe$)
                );
        }

        obs.subscribe({
            next: () => {
                this.isLoading = false;
                this.updateWarningMessageVisibility();
                if (this.showOccupationDurationWarning && this.showWarningMessage) {
                    return;
                }

                this.toastService.showSuccess(
                    this.translateService.instant('PAGES.OCCUPATION.EDIT_RENT_PAYMENT.SUCCESS_TOAST')
                );
                this.saveEmitter$.next();
            },
            error: (error: any) => {
                this.toastService.showError(error.error['message']);
                this.isLoading = false;
            },
        });
    }

    public updateWarningMessageVisibility(): void {
        this.showWarningMessage = !this.showWarningMessage;
    }

    public updateOccupationObs(
        propertyId: string,
        occupationId: string,
        updateOccupationDto: UpdateOccupationDto
    ): Observable<Occupation> {
        return this.occupationService.updateOccupation(propertyId, occupationId, updateOccupationDto);
    }
}
