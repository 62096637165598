<ibm-loading *ngIf="isLoading" [isActive]="isLoading" [size]="'normal'" [overlay]="isLoading"></ibm-loading>

<div ibmRow class="pb-5r">
    <div ibmCol [columnNumbers]="{ md: 4, lg: 4 }"></div>
    <div ibmCol [columnNumbers]="{ md: 8, lg: 8 }" class="s-head-28-36-bold">{{ headline | translate }}</div>
    <div ibmCol [columnNumbers]="{ md: 4, lg: 4 }"></div>
</div>

<div ibmRow class="pb-5r">
    <div ibmCol [columnNumbers]="{ md: 4, lg: 4 }"></div>
    <div ibmCol [columnNumbers]="{ md: 8, lg: 8 }">
        <app-small-person-infobox
            [personDto]="originalRelation.person"
            [options]="['name', 'type']"
        ></app-small-person-infobox>
    </div>
    <div ibmCol [columnNumbers]="{ md: 4, lg: 4 }"></div>
</div>

<div *ngIf="originalRelation.parent === 'serviceProvider'" ibmRow class="pb-2r">
    <div ibmCol [columnNumbers]="{ md: 4, lg: 4 }"></div>
    <div ibmCol [columnNumbers]="{ md: 8, lg: 8 }">
        <app-combo-box
            [size]="'xl'"
            placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT_2' | translate }}"
            [appendInline]="true"
            label="Gewerk"
            [items]="servicesList"
            (selected)="onSelectServiceProvider($event)"
        >
            <ibm-dropdown-list></ibm-dropdown-list>
        </app-combo-box>
    </div>
</div>

<div ibmRow class="pb-2r">
    <div ibmCol [columnNumbers]="{ md: 4, lg: 4 }"></div>
    <div ibmCol [columnNumbers]="{ md: 6, lg: 6 }" class="s-head-16-24-bold">{{ subHeadline | translate }}</div>
    <div ibmCol [columnNumbers]="{ md: 6, lg: 6 }"></div>
</div>
<div ibmRow>
    <div ibmCol [columnNumbers]="{ md: 4, lg: 4 }"></div>
    <div ibmCol [columnNumbers]="{ md: 8, lg: 8 }">
        <form (submit)="onSubmit()" [formGroup]="relationIntervalForm">
            <app-date-range-picker [defaultDates]="this.originalRelation"></app-date-range-picker>

            <div *ngIf="originalRelation.parent === 'ownership'" class="tw-mt-6">
                <div class="tw-s-head-16-24-bold tw-mb-6">Ansprechpartner bei mehreren Eigentümern je Einheit</div>
                <ibm-checkbox formControlName="isContactPerson"
                    ><span class="tw-ml-2">Diesen Kontakt als Hauptansprechpartner vermerken</span>
                </ibm-checkbox>
            </div>

            <div ibmRow class="mt-11">
                <div ibmCol>
                    <button [ibmButton]="'tertiary'" (click)="abort()">
                        {{ 'CONTROLS.BUTTONS.CANCEL' | translate }}
                    </button>
                </div>

                <div ibmCol class="text-right">
                    <button [ibmButton]="'primary'" type="submit" [disabled]="relationIntervalForm.invalid">
                        {{ 'CONTROLS.BUTTONS.SAVE' | translate }}
                    </button>
                </div>
            </div>
            <div class="p-10"></div>
        </form>
    </div>
    <div ibmCol [columnNumbers]="{ md: 4, lg: 4 }"></div>
</div>
