<app-table
    [emptyText]="emptyText"
    [header]="header"
    [data]="data"
    [itemsPerPage]="itemsPerPage"
    (rowClick)="onRowClick($event)"
    (handleFile)="handleFiles($event)"
    [isZebraTable]="true"
    [tableSearchId]="tableSearchId"
    [isLoading]="isLoading"
>
</app-table>
