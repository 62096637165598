import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AddOccupationStep3Component } from './components/add-occupation-step3/add-occupation-step3.component';
import { AddOccupationStep4Component } from './components/add-occupation-step4/add-occupation-step4.component';
import { AddOccupationComponent } from './components/add-occupation/add-occupation.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import {
    BreadcrumbModule,
    ButtonModule,
    CheckboxModule,
    DatePickerModule,
    DropdownModule,
    GridModule,
    IconModule,
    InlineLoadingModule,
    InputModule,
    LoadingModule,
    RadioModule,
    SelectModule,
    TableModule,
    TabsModule,
    UIShellModule,
} from 'carbon-components-angular';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SharedModule } from '../../shared/shared.module';
import { PropertyModule } from '../property/property.module';
import { RelationsModule } from '../relations/relations.module';
import { DepositOverviewComponent } from './components/deposit-overview/deposit-overview.component';
import { EditOccupationDetailsComponent } from './components/edit-occupation-details/edit-occupation-details.component';
import { EditRentPaymentComponent } from './components/edit-rent-payment/edit-rent-payment.component';
import { OccupationDetailsComponent } from './components/occupation-details/occupation-details.component';

@NgModule({
    declarations: [
        AddOccupationComponent,
        AddOccupationStep3Component,
        AddOccupationStep4Component,
        OccupationDetailsComponent,
        EditRentPaymentComponent,
        DepositOverviewComponent,
        EditOccupationDetailsComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        InputModule,
        SelectModule,
        GridModule,
        RadioModule,
        TranslateModule,
        ButtonModule,
        LoadingModule,
        InlineLoadingModule,
        TableModule,
        UIShellModule,
        SharedModule,
        BreadcrumbModule,
        DropdownModule,
        ImageCropperModule,
        AngularSvgIconModule,
        IconModule,
        RelationsModule,
        TabsModule,
        DatePickerModule,
        CheckboxModule,
        PropertyModule,
    ],
})
export class OccupationModule {}
