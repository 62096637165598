<div class="tw-mb-10 tw-flex tw-justify-between">
    <div>
        <ibm-breadcrumb [items]="breadcrumbs" [noTrailingSlash]="true"> </ibm-breadcrumb>
    </div>
    <div class="tw-flex tw-justify-center">
        <div>
            <app-scalara-button
                *ngIf="
                    (payment?.paymentStatus === 'OPEN' && !payment?.approvalDate) ||
                    payment?.paymentStatus === 'INCOMPLETE' ||
                    payment?.paymentStatus === 'EXPORTED'
                "
                [variant]="'ghost'"
                type="button"
                (click)="openDeletePaymentOverlay()"
                svgName="24_delete.svg"
            >
                {{ 'CONTROLS.BUTTONS.REMOVE' | translate }}
            </app-scalara-button>
        </div>
        <div>
            <app-scalara-button
                *ngIf="
                    (payment && payment?.paymentStatus === 'OPEN' && !payment?.approvalDate) ||
                    payment?.paymentStatus === 'INCOMPLETE' ||
                    payment?.paymentStatus === 'EXPORTED'
                "
                [variant]="'ghost'"
                type="button"
                (click)="openAddEditPaymentOverlay(payment?.type ?? 'DIRECT_DEBIT', true, payment?.id)"
                svgName="24_edit.svg"
            >
                {{ 'CONTROLS.BUTTONS.EDIT' | translate }}
            </app-scalara-button>
        </div>
    </div>
</div>

<div class="tw-s-head-14-22-bold tw-mb-6">Auftragsdetails</div>

<div class="tw-border-[1px] tw-border-solid tw-border-scalaraGray-05">
    <div
        [ngClass]="{
            'tw-bg-scalaraOrange-03': payment?.paymentStatus === 'OPEN',
            'tw-bg-scalaraGreen-03': payment?.paymentStatus === 'DONE',
            'tw-bg-scalaraRed-03': payment?.paymentStatus === 'ERROR' || payment?.paymentStatus === 'INCOMPLETE',
            'tw-bg-scalaraBlue-03': payment?.paymentStatus === 'PROCESSING',
            'tw-bg-scalaraGray-05': payment?.paymentStatus === 'EXPORTED'
        }"
        class="tw-items-center tw-px-6 tw-py-4 tw-flex tw-justify-end"
    >
        <svg-icon
            [ngClass]="{
                'tw-fill-scalaraOrange-01': payment?.paymentStatus === 'OPEN',
                'tw-fill-scalaraGreen-01': payment?.paymentStatus === 'DONE',
                'tw-fill-scalaraRed-01': payment?.paymentStatus === 'ERROR' || payment?.paymentStatus === 'INCOMPLETE',
                'tw-fill-scalaraBlue-01': payment?.paymentStatus === 'PROCESSING',
                'tw-fill-scalaraGray-02': payment?.paymentStatus === 'EXPORTED'
            }"
            class="tw-w-6 tw-h-6"
            [src]="getStatusIcon()"
        ></svg-icon>

        <div
            [ngClass]="{
                'tw-text-scalaraOrange-01': payment?.paymentStatus === 'OPEN',
                'tw-text-scalaraGreen-01': payment?.paymentStatus === 'DONE',
                'tw-text-scalaraRed-01': payment?.paymentStatus === 'ERROR' || payment?.paymentStatus === 'INCOMPLETE',
                'tw-text-scalaraBlue-01': payment?.paymentStatus === 'PROCESSING',
                'tw-text-scalaraGray-01': payment?.paymentStatus === 'EXPORTED'
            }"
            class="tw-s-label-12-16-regular tw-ml-2"
        >
            {{ 'ENTITIES.PAYMENT.LABEL_STATUS_' + payment?.paymentStatus | translate }}
        </div>
    </div>

    <div class="tw-flex" *ngIf="payment">
        <!-- column 1 -->
        <div class="tw-p-8 tw-w-full">
            <div class="tw-flex">
                <div class="tw-s-label-12-22-semibold tw-text-scalaraGray-03 tw-min-w-[208px]">
                    <div class="tw-mb-3">Freigabedatum</div>
                    <div class="tw-mb-3">{{ payment.type === 'MONEY_TRANSFER' ? 'Empfänger' : 'Kontoinhaber' }}</div>
                    <div class="tw-mb-3" *ngIf="payment.type === 'DIRECT_DEBIT'">Kreditor</div>
                    <div class="tw-mb-3">Verwendungszweck</div>
                    <div class="tw-mb-3">IBAN</div>
                    <div class="tw-mb-3">BIC</div>
                    <div class="tw-mb-3">Art</div>
                    <div class="tw-mb-3" *ngIf="payment.type === 'DIRECT_DEBIT'">Mandats ID</div>
                    <!-- TODO: add *ngIf here when BE is ready (if not recurring) -->
                    <ng-container>
                        <div class="tw-mb-3">
                            {{ 'ENTITIES.PAYMENT.LABEL_EXECUITION_DATE' | translate }}
                        </div>
                    </ng-container>
                    <!-- TODO: add *ngIf here when BE is ready (if recurring) -->
                    <ng-container *ngIf="true">
                        <div class="tw-mb-3">
                            {{ 'ENTITIES.PAYMENT.LABEL_FIRST_EXECUTION_DATE' | translate }}
                        </div>
                        <div class="tw-mb-3">
                            {{ 'ENTITIES.PAYMENT.LABEL_RATE_INTERVAL' | translate }}
                        </div>
                        <div class="tw-mb-3">
                            {{ 'ENTITIES.PAYMENT.LABEL_LAST_EXECUTION_DATE' | translate }}
                        </div>
                    </ng-container>
                </div>

                <div class="tw-s-body-14-22-regular">
                    <div class="tw-mb-3">
                        <ng-container *ngIf="!payment.approvalDate && !(payment.paymentStatus === 'INCOMPLETE')">
                            <div class="tw-flex">
                                <span class="tw-italic tw-text-scalaraGray-03 tw-mr-9">Wartet auf Freigabe</span>
                                <a
                                    [routerLink]="routerlink"
                                    class="tw-underline tw-cursor-pointer tw-text-scalaraGray-01"
                                >
                                    Jetzt freigeben
                                </a>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="payment.approvalDate">
                            {{ payment.approvalDate | dateFormat }}
                        </ng-container>
                        <ng-container *ngIf="payment.paymentStatus === 'INCOMPLETE'"> - </ng-container>
                    </div>
                    <div class="tw-mb-3">{{ payment.counterpartName }}</div>
                    <div class="tw-mb-3" *ngIf="payment.type === 'DIRECT_DEBIT'">
                        {{ payment.creditorIdentifier }}
                    </div>
                    <div class="tw-mb-3">
                        {{ payment.purpose || '–' }}
                    </div>
                    <div class="tw-mb-3">{{ payment.counterpartIban | ibanFormatter }}</div>
                    <div class="tw-mb-3">{{ payment.counterpartBic || '–' }}</div>
                    <div class="tw-mb-3">{{ 'ENTITIES.PAYMENT.LABEL_' + payment.type | translate }}</div>
                    <div class="tw-mb-3" *ngIf="payment.type === 'DIRECT_DEBIT'">{{ payment.mandateId || '–' }}</div>
                    <!-- TODO: add *ngIf here when BE is ready (if not recurring) -->
                    <ng-container>
                        <div class="tw-mb-3">{{ payment.executionDate | dateFormat }}</div>
                    </ng-container>
                    <!-- TODO: add *ngIf here when BE is ready (if recurring) and add data-->
                    <ng-container *ngIf="true">
                        <div class="tw-mb-3">-</div>
                        <div class="tw-mb-3">-</div>
                        <div class="tw-mb-3">-</div>
                    </ng-container>
                </div>
            </div>

            <hr class="tw-my-4" />

            <div class="tw-flex">
                <div class="tw-s-label-12-22-semibold tw-text-scalaraGray-03 tw-min-w-[208px]">
                    <div class="tw-mb-3">Kontoinhaber</div>
                    <div class="tw-mb-3">IBAN</div>
                    <div class="tw-mb-3">BIC</div>
                    <div class="tw-mb-3">Kontotyp</div>
                    <div class="tw-mb-3">Bankinstitut</div>
                </div>
                <div class="tw-s-body-14-22-regular" *ngIf="payment.bankAccount">
                    <div class="tw-mb-3">{{ payment.bankAccount.holderName }}</div>
                    <div class="tw-mb-3">{{ payment.bankAccount.iban | ibanFormatter }}</div>
                    <div class="tw-mb-3">
                        {{ payment.bankAccount.bic || '–' }}
                    </div>
                    <div class="tw-mb-3">
                        {{ getBankAccountTypeName(payment.bankAccount) || '-' }}
                    </div>
                    <div class="tw-mb-3">
                        {{ payment.bankAccount.bankName }}
                    </div>
                </div>
            </div>
        </div>

        <!-- column 2 -->
        <div class="tw-p-8 tw-border-l-[1px] tw-border-solid tw-border-l-scalaraGray-05 tw-w-[40%] tw-flex tw-flex-col">
            <div class="tw-flex tw-justify-between">
                <div class="tw-s-label-12-22-semibold tw-text-scalaraGray-03">Gesamtbetrag</div>
                <div class="tw-s-head-18-26-bold">{{ payment.amount | eurocent }}€</div>
            </div>

            <div class="tw-flex tw-justify-center tw-mt-14">
                <app-scalara-button
                    *ngIf="payment?.paymentStatus === 'INCOMPLETE'"
                    [variant]="'default'"
                    [fullWidth]="true"
                    height="40px"
                    type="button"
                    (click)="openAddEditPaymentOverlay(payment.type ? payment.type : 'DIRECT_DEBIT', true, payment.id)"
                    svgName="24_edit.svg"
                    class="tw-w-full"
                >
                    {{ 'CONTROLS.BUTTONS.COMPLETE_DATA' | translate }}
                </app-scalara-button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="bankTransactionsTable | async as tableModel">
    <div class="s-body-14-22-bold tw-mb-4 tw-mt-16">
        {{ 'PAGES.BOOKINGS.BOOKING_DETAILS.LINKED_TRANSACTION' | translate }}
    </div>

    <ng-container>
        <app-table
            (customButton)="handlingEvent($event)"
            [emptyText]="'ACCOUNTING.BANK_TRANSACTION_DETAILS.EMPTY_TABLE'"
            [header]="tableModel.header"
            [data]="tableModel.data"
        >
        </app-table>
    </ng-container>
</div>
