<div
    [formGroup]="getFileFormGroup(i)"
    *ngFor="let file of files.controls; let i = index"
    class="tw-grid tw-grid-cols-2 tw-gap-8 tw-mt-6"
>
    <div class="tw-flex tw-justify-between tw-items-center tw-border-b-[1px] tw-border-solid tw-border-scalaraGray-05">
        <div class="tw-w-6 tw-fill-scalaraGreen-01">
            <svg-icon src="/assets/icons/24_checkbox-check-green.svg"></svg-icon>
        </div>
        <div class="tw-px-4 s-body-14-20-regular tw-truncate">
            {{ file?.value?.fileName }}
        </div>
        <app-scalara-button variant="icon-only" svgName="24_close.svg" (click)="removeFile(i)"></app-scalara-button>
    </div>

    <div class="tw-w-full">
        <app-combo-box
            size="md"
            label="{{ 'ENTITIES.DOCUMENT.CATEGORY' | translate }}*"
            [items]="categories || []"
            (selected)="onSelectCategory(i, $event)"
            placeholder="{{ 'COMMON.LABEL_PLEASE_SELECT' | translate }}"
            [clearable]="false"
            formControlName="category"
            [disabled]="isCategoryDisable"
        >
            <ibm-dropdown-list></ibm-dropdown-list>
        </app-combo-box>
    </div>
</div>
