import { Component, Input, OnChanges, SimpleChanges, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[dynamicComponentWrapper]',
    templateUrl: './dynamic-component-wrapper.component.html',
    styleUrls: ['./dynamic-component-wrapper.component.scss'],
})
export class DynamicComponentWrapperComponent implements OnChanges {
    @ViewChild('vcr', { static: true, read: ViewContainerRef })
    public vcr?: ViewContainerRef;

    @Input() public component: any = null;
    @Input() public componentData: any = null;
    //  map componentData['input'] to component's inputs
    //  componentData['input'] = { name: 'John', age: 30 } to component's inputs { name: 'John', age: 30 }
    @Input() public unpackComponentData = false;

    public ngOnChanges(changes: SimpleChanges): void {
        if (!this.vcr || !this.component) {
            return;
        }
        this.vcr.clear();

        if (this.component instanceof TemplateRef) {
            // when using a template instead of a component (<ng-template>)
            this.vcr.createEmbeddedView(this.component, { data: this.componentData });
        } else {
            const componentRef = this.vcr.createComponent(this.component);
            if (this.unpackComponentData) {
                Object.assign(componentRef.instance as any, this.componentData);
                return;
            }
            (componentRef.instance as any)['data'] = this.componentData;
        }
    }
}
