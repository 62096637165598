import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { ListItem } from 'carbon-components-angular/dropdown/list-item.interface';
import { BehaviorSubject, combineLatest, debounceTime, map, of, startWith, Subject, switchMap, takeUntil } from 'rxjs';
import { CustomGoogleServicesService } from '../../services/custom-google-services.service';

export type AutocompletePlaceSelectedItem = {
    place: google.maps.places.PlaceResult | null;
    formData: {
        streetName: string;
        streetNumber: string;
        postalCode: string;
        city: string;
        countryCode: string;
    };
};

@Component({
    selector: 'app-scalara-input-address-autocomplete',
    templateUrl: './scalara-input-address-autocomplete.component.html',
    styleUrls: ['./scalara-input-address-autocomplete.component.scss'],
})
export class ScalaraInputAddressAutocompleteComponent implements ControlValueAccessor, OnDestroy, OnInit {
    public showedInput = '';
    public touched = false;
    public disabled = false;

    @Input() public label = '';
    @Input() public invalid = false;
    @Input() public invalidText = '';
    @Output() public autocompletePlaceSelected = new EventEmitter<AutocompletePlaceSelectedItem>();

    public listItems: ListItem[] = [];

    public googleSearchInput$ = new Subject<string>();
    public isOpen$ = new BehaviorSubject<boolean>(false);
    public unsubscribe$ = new Subject<void>();

    public ngOnInit(): void {
        this.customGoogleServicesService
            .getGooglePlacesInitialized$()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((isInitialized) => {
                if (isInitialized) {
                    this.autocompleteService = new google.maps.places.AutocompleteService();
                    this.placesService = new google.maps.places.PlacesService(document.createElement('div'));
                }
            });
    }

    public writeValue(obj: any): void {
        this.showedInput = obj;
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public markAsTouched(): void {
        if (!this.touched) {
            this.touched = true;
            this.onTouched();
        }
    }

    public setDisabledState(disabled: boolean): void {
        this.disabled = disabled;
    }

    public googlePredictions$ = this.googleSearchInput$.asObservable().pipe(
        debounceTime(400),
        switchMap((input) => {
            if (input.length < 1 || !this.autocompleteService) {
                return [];
            }
            return this.autocompleteService.getPlacePredictions(
                { input, language: 'de_DE', componentRestrictions: { country: ['de'] } },
                (predictions) => {
                    if (predictions) {
                        return predictions;
                    }
                    return [];
                }
            );
        })
    );

    public listItems$ = this.googlePredictions$.pipe(
        switchMap((predictions) =>
            of(
                predictions.predictions.map((i) => ({
                    content: i.description,
                    selected: false,
                    disabled: false,
                    data: i,
                }))
            )
        )
    );

    // startWith([]) is needed here, because the listItems$ observable is not yet subscribed to when the first value is emitted
    public vm$ = combineLatest([this.isOpen$, this.listItems$.pipe(startWith([]))]).pipe(
        map(([isOpen, listItems]) => ({ isOpen, listItems }))
    );

    public constructor(
        public cd: ChangeDetectorRef,
        private ngZone: NgZone,
        public ngControl: NgControl,
        public customGoogleServicesService: CustomGoogleServicesService
    ) {
        ngControl.valueAccessor = this;
    }
    public autocompleteService: google.maps.places.AutocompleteService | null = null;
    public placesService: google.maps.places.PlacesService | null = null;

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public setIsOpen(val: boolean): void {
        this.ngZone.run(() => this.isOpen$.next(val));
    }

    public toggleSetIsOpen(): void {
        this.setIsOpen(!this.isOpen$.getValue());
    }

    public onItemSelect($event: ListItem): void {
        this.setIsOpen(false);
        if (this.placesService === null) {
            console.warn('placesService is null');
            return;
        }
        this.placesService.getDetails({ placeId: $event['data'].place_id }, (place, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK && !!place) {
                const autocompletePlaceSelectedItem = {
                    place,
                    formData: {
                        streetName: place?.address_components?.find((i) => i.types.includes('route'))?.long_name ?? '',
                        streetNumber:
                            place?.address_components?.find((i) => i.types.includes('street_number'))?.long_name ?? '',
                        postalCode:
                            place?.address_components?.find((i) => i.types.includes('postal_code'))?.long_name ?? '',
                        city: place?.address_components?.find((i) => i.types.includes('locality'))?.long_name ?? '',
                        countryCode:
                            place?.address_components?.find((i) => i.types.includes('country'))?.short_name ?? '',
                    },
                };
                this.autocompletePlaceSelected.emit(autocompletePlaceSelectedItem);
            }
        });
    }

    public onKey(event: any): void {
        const value = event.target.value;
        this.onChange(value);
        this.isOpen$.next(true);
        this.showedInput = value;
        this.googleSearchInput$.next(value);
        this.markAsTouched();
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public onChange = (input: string): void => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public onTouched = (): void => {};
}
