import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-mv-income-and-expense-index',
    templateUrl: './mv-income-and-expense-index.component.html',
    styleUrls: ['./mv-income-and-expense-index.component.scss'],
})
export class MvIncomeAndExpenseIndexComponent implements OnInit {
    public ngOnInit(): void {
        return;
    }
}
