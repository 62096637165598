import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ObservedValueOf, Subject, combineLatest, filter, map, of, shareReplay, switchMap, takeUntil } from 'rxjs';
import { IncomeAndExpenditureDto, WegSettlementStatementsService } from 'src/app/generated-sources/accounting';
import { Column, RowData } from 'src/app/shared/components/simple-table/simple-table-row/simple-table-row.component';
import { CustomWssService } from '../custom-wss.service';

type ColumnKey = 'name' | 'amount' | 'distributionAmount' | 'planAmount';
type TableData = {
    columns: Column<ColumnKey>[];
    rows: RowData<ColumnKey>[];
};
type ExtendedIncomeAndExpenditure = IncomeAndExpenditureDto & {
    bankAccountsInitialAmountSumm: number;
    bankAccountsFinalAmountSumm: number;
    revenueAccountsAmountSumm: number;
    revenueAccountsDistributionAmountSumm: number;
    revenueAccountsPlanAmountSumm: number;
    apportionableExpenseAccountsAmountSumm: number;
    apportionableExpenseAccountsDistributionAmountSumm: number;
    apportionableExpenseAccountsPlanAmountSumm: number;
    nonApportionableExpenseAccountsAmountSumm: number;
    nonApportionableExpenseAccountsDistributionAmountSumm: number;
    nonApportionableExpenseAccountsPlanAmountSumm: number;
    totalAmount: number;
    totalDistributionAmount: number;
    totalPlanAmount: number;
};

@Component({
    selector: 'app-income-and-expense-statement',
    templateUrl: './income-and-expense-statement.component.html',
    styleUrls: ['./income-and-expense-statement.component.scss'],
})
export class IncomeAndExpenseStatementComponent implements OnInit, OnDestroy {
    public constructor(
        public wssService: WegSettlementStatementsService,
        public customWssService: CustomWssService,
        public datePipe: DatePipe
    ) {}

    public unsubscribe$ = new Subject<void>();
    public ledgerId$ = this.customWssService.getLedgerId$();
    public wss$ = this.customWssService.getWss$();

    public incomeAndExpenditure$ = combineLatest([this.ledgerId$, this.wss$]).pipe(
        switchMap(([ledgerId, wss]) => {
            if (ledgerId && wss) {
                return this.wssService.getIncomeAndExpenditure(ledgerId, wss.id);
            }
            console.log('no params');
            return of(null);
        }),
        filter(Boolean),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    public extendedIncomeAndExpenditure$ = this.incomeAndExpenditure$.pipe(
        map((i) => {
            const copyObj = { ...i };
            const bankAccountsInitialAmountSumm = copyObj.bankAccountsInitial.reduce(
                (acc, curr) => acc + curr.accountBalance,
                0
            );
            const bankAccountsFinalAmountSumm = copyObj.bankAccountsFinal.reduce(
                (acc, curr) => acc + curr.accountBalance,
                0
            );
            const revenueAccountsAmountSumm = copyObj.revenueAccounts.reduce((acc, curr) => acc + curr.amount, 0);
            const revenueAccountsDistributionAmountSumm = copyObj.revenueAccounts.reduce(
                (acc, curr) => acc + curr.distributionRelevantAmount,
                0
            );
            const revenueAccountsPlanAmountSumm = copyObj.revenueAccounts.reduce(
                (acc, curr) => acc + curr.planAmount,
                0
            );
            const apportionableExpenseAccountsAmountSumm = copyObj.apportionableExpenseAccounts.reduce(
                (acc, curr) => acc + curr.amount,
                0
            );
            const apportionableExpenseAccountsDistributionAmountSumm = copyObj.apportionableExpenseAccounts.reduce(
                (acc, curr) => acc + curr.distributionRelevantAmount,
                0
            );
            const apportionableExpenseAccountsPlanAmountSumm = copyObj.apportionableExpenseAccounts.reduce(
                (acc, curr) => acc + curr.planAmount,
                0
            );
            const nonApportionableExpenseAccountsAmountSumm = copyObj.nonApportionableExpenseAccounts.reduce(
                (acc, curr) => acc + curr.amount,
                0
            );
            const nonApportionableExpenseAccountsDistributionAmountSumm =
                copyObj.nonApportionableExpenseAccounts.reduce((acc, curr) => acc + curr.distributionRelevantAmount, 0);
            const nonApportionableExpenseAccountsPlanAmountSumm = copyObj.nonApportionableExpenseAccounts.reduce(
                (acc, curr) => acc + curr.planAmount,
                0
            );
            const totalDistributionAmount =
                revenueAccountsDistributionAmountSumm +
                apportionableExpenseAccountsDistributionAmountSumm +
                nonApportionableExpenseAccountsDistributionAmountSumm;
            const totalPlanAmount =
                revenueAccountsPlanAmountSumm +
                apportionableExpenseAccountsPlanAmountSumm +
                nonApportionableExpenseAccountsPlanAmountSumm;

            const totalAmount =
                bankAccountsInitialAmountSumm +
                revenueAccountsAmountSumm +
                apportionableExpenseAccountsAmountSumm +
                nonApportionableExpenseAccountsAmountSumm;
            const extendedIncomeAndExpenditure: ExtendedIncomeAndExpenditure = {
                ...copyObj,
                bankAccountsInitialAmountSumm,
                bankAccountsFinalAmountSumm,
                revenueAccountsAmountSumm,
                revenueAccountsDistributionAmountSumm,
                revenueAccountsPlanAmountSumm,
                apportionableExpenseAccountsAmountSumm,
                apportionableExpenseAccountsDistributionAmountSumm,
                apportionableExpenseAccountsPlanAmountSumm,
                nonApportionableExpenseAccountsAmountSumm,
                nonApportionableExpenseAccountsDistributionAmountSumm,
                nonApportionableExpenseAccountsPlanAmountSumm,
                totalDistributionAmount,
                totalPlanAmount,
                totalAmount,
            };
            // extendedIncomeAndExpenditure.bankAccountsInitialAmountSumm = bankAccountsInitialAmountSumm;
            return extendedIncomeAndExpenditure;
        })
    );

    public tableData$ = combineLatest([this.extendedIncomeAndExpenditure$, this.wss$]).pipe(
        map(([extendedIncomeAndExpenditure, wss]) => {
            const table: TableData = {
                columns: [
                    { key: 'name', label: '' },
                    { key: 'amount', label: 'Gesamtkontobestände in €' },
                    { key: 'distributionAmount', label: 'Verteilungsrelevante Beträge in €' },
                    { key: 'planAmount', label: 'Wirtschaftsplanbetrag in €' },
                ],
                rows: [
                    {
                        cells: [
                            {
                                key: 'name',
                                value: `Geldkonten Anfangsbestand am ${this.datePipe.transform(
                                    wss?.economicPlan.startDate,
                                    'dd.MM.yyyy'
                                )}`,
                                type: 'value',
                                rowCellClass: ' [&&]:tw-s-head-16-24-bold [&&]:tw-pt-8 [&&]:tw-pb-4',
                            },
                        ],
                    },
                    ...extendedIncomeAndExpenditure.bankAccountsInitial
                        .map((i) => {
                            const row: RowData<ColumnKey> = {
                                cells: [
                                    { key: 'name', value: i.accountName, type: 'value' },
                                    { key: 'amount', type: 'value', value: i.accountBalance, pipeToApply: 'eurocent' },
                                ],
                            };

                            return row;
                        })
                        .flat(),
                    {
                        cells: [
                            {
                                key: 'name',
                                type: 'value',
                                value: '',
                                rowCellClass: '[&&]:tw-h-4 ',
                            },
                        ],
                        rowClass: '[&&]:tw-border-b [&&]:tw-border-solid [&&]:tw-border-scalaraGray-01',
                    },
                    {
                        cells: [
                            {
                                key: 'name',
                                type: 'value',
                                value: 'Gesamtgeldkontobestände',
                                rowCellClass: '[&&]:tw-pt-2',
                            },
                            {
                                key: 'amount',
                                type: 'value',
                                value: extendedIncomeAndExpenditure.bankAccountsInitialAmountSumm,
                                rowCellClass: '[&&]:tw-pt-2',
                                pipeToApply: 'eurocent',
                            },
                        ],
                        rowClass: '[&&]:tw-s-head-14-22-bold',
                    },

                    //einnahmen
                    { cells: [{ key: 'name', type: 'value', value: '', rowCellClass: '[&&]:tw-h-14' }] },
                    {
                        cells: [
                            {
                                key: 'name',
                                type: 'value',
                                value: 'Einnahmen',
                                rowCellClass: '[&&]:tw-s-head-16-24-bold [&&]:tw-pb-4',
                            },
                        ],
                    },
                    ...extendedIncomeAndExpenditure.revenueAccounts
                        .filter((item) => item.distributionRelevantAmount || item.amount || item.planAmount)
                        .map((i) => {
                            const revenueAccountRow: RowData<ColumnKey> = {
                                cells: [
                                    {
                                        key: 'name',
                                        type: 'value',
                                        value: i.name + (i.description ? ', ' + i.description : ''),
                                    },
                                    { key: 'amount', type: 'value', value: i.amount, pipeToApply: 'eurocent' },
                                    {
                                        key: 'distributionAmount',
                                        type: 'value',
                                        value: i.distributionRelevantAmount,
                                        pipeToApply: 'eurocent',
                                    },
                                    { key: 'planAmount', type: 'value', value: i.planAmount, pipeToApply: 'eurocent' },
                                ],
                            };
                            return revenueAccountRow;
                        }),
                    { cells: [{ key: 'name', type: 'value', value: '', rowCellClass: '[&&]:tw-h-2' }] },
                    {
                        cells: [
                            { key: 'name', type: 'value', value: 'Summe Einnahmen' },
                            {
                                key: 'amount',
                                type: 'value',
                                value: extendedIncomeAndExpenditure.revenueAccountsAmountSumm,
                                pipeToApply: 'eurocent',
                            },
                            {
                                key: 'distributionAmount',
                                type: 'value',
                                value: extendedIncomeAndExpenditure.revenueAccountsDistributionAmountSumm,
                                pipeToApply: 'eurocent',
                            },
                            {
                                key: 'planAmount',
                                type: 'value',
                                value: extendedIncomeAndExpenditure.revenueAccountsPlanAmountSumm,
                                pipeToApply: 'eurocent',
                            },
                        ],
                        rowClass: '[&&]:tw-s-head-14-22-bold [&&]:tw-bg-scalaraGray-06 [&&]:tw-h-10',
                    },

                    // Ausgaben, umlagefähige Beträge
                    { cells: [{ key: 'name', type: 'value', value: '', rowCellClass: '[&&]:tw-h-14' }] },
                    {
                        cells: [
                            {
                                key: 'name',
                                type: 'value',
                                value: 'Ausgaben, umlagefähige Beträge',
                                rowCellClass: '[&&]:tw-s-head-16-24-bold [&&]:tw-pb-4',
                            },
                        ],
                    },
                    ...extendedIncomeAndExpenditure.apportionableExpenseAccounts
                        .filter((item) => item.distributionRelevantAmount || item.amount || item.planAmount)
                        .map((i) => {
                            const row: RowData<ColumnKey> = {
                                cells: [
                                    {
                                        key: 'name',
                                        value: i.name + (i.description ? ', ' + i.description : ''),
                                        type: 'value',
                                    },
                                    { key: 'amount', value: i.amount, type: 'value', pipeToApply: 'eurocent' },
                                    {
                                        key: 'distributionAmount',
                                        type: 'value',
                                        value: i.distributionRelevantAmount,
                                        pipeToApply: 'eurocent',
                                    },
                                    { key: 'planAmount', type: 'value', value: i.planAmount, pipeToApply: 'eurocent' },
                                ],
                            };
                            return row;
                            // cells: [{ key: 'name', value: i.name, type: 'value' }],
                        }),
                    { cells: [{ key: 'name', type: 'value', value: '', rowCellClass: '[&&]:tw-h-2' }] },
                    {
                        cells: [
                            { key: 'name', type: 'value', value: 'Summe Ausgaben, umlagefähige Beträge' },
                            {
                                key: 'amount',
                                type: 'value',
                                value: extendedIncomeAndExpenditure.apportionableExpenseAccountsAmountSumm,
                                pipeToApply: 'eurocent',
                            },
                            {
                                key: 'distributionAmount',
                                type: 'value',
                                value: extendedIncomeAndExpenditure.apportionableExpenseAccountsDistributionAmountSumm,
                                pipeToApply: 'eurocent',
                            },
                            {
                                key: 'planAmount',
                                type: 'value',
                                value: extendedIncomeAndExpenditure.apportionableExpenseAccountsPlanAmountSumm,
                                pipeToApply: 'eurocent',
                            },
                        ],
                        rowClass: '[&&]:tw-s-head-14-22-bold [&&]:tw-bg-scalaraGray-06 [&&]:tw-h-10',
                    },

                    //Ausgaben, nicht umlagefähige Beträge
                    { cells: [{ key: 'name', type: 'value', value: '', rowCellClass: '[&&]:tw-h-14' }] },
                    {
                        cells: [
                            {
                                key: 'name',
                                type: 'value',
                                value: 'Ausgaben, nicht umlagefähige Beträge',
                                rowCellClass: '[&&]:tw-s-head-16-24-bold [&&]:tw-pb-4',
                            },
                        ],
                    },
                    ...extendedIncomeAndExpenditure.nonApportionableExpenseAccounts
                        .filter((item) => item.distributionRelevantAmount || item.amount || item.planAmount)
                        .map((i) => {
                            const row: RowData<ColumnKey> = {
                                cells: [
                                    {
                                        key: 'name',
                                        value: i.name + (i.description ? ', ' + i.description : ''),
                                        type: 'value',
                                    },
                                    { key: 'amount', value: i.amount, type: 'value', pipeToApply: 'eurocent' },
                                    {
                                        key: 'distributionAmount',
                                        type: 'value',
                                        value: i.distributionRelevantAmount,
                                        pipeToApply: 'eurocent',
                                    },
                                    { key: 'planAmount', type: 'value', value: i.planAmount, pipeToApply: 'eurocent' },
                                ],
                            };
                            return row;
                        }),
                    { cells: [{ key: 'name', type: 'value', value: '', rowCellClass: '[&&]:tw-h-2' }] },
                    {
                        cells: [
                            { key: 'name', type: 'value', value: 'Summe Ausgaben, nicht umlagefähige Beträge' },
                            {
                                key: 'amount',
                                type: 'value',
                                value: extendedIncomeAndExpenditure.nonApportionableExpenseAccountsAmountSumm,
                                pipeToApply: 'eurocent',
                            },
                            {
                                key: 'distributionAmount',
                                type: 'value',
                                value: extendedIncomeAndExpenditure.nonApportionableExpenseAccountsDistributionAmountSumm,
                                pipeToApply: 'eurocent',
                            },
                            {
                                key: 'planAmount',
                                type: 'value',
                                value: extendedIncomeAndExpenditure.nonApportionableExpenseAccountsPlanAmountSumm,
                                pipeToApply: 'eurocent',
                            },
                        ],
                        rowClass: '[&&]:tw-s-head-14-22-bold [&&]:tw-bg-scalaraGray-06 [&&]:tw-h-10',
                    },
                    //Summe Kostentragung
                    {
                        cells: [{ key: 'name', type: 'value', value: '', rowCellClass: '[&&]:tw-h-10' }],
                        rowClass: '[&&]:tw-border-b [&&]:tw-border-solid [&&]:tw-border-scalaraGray-01',
                    },
                    {
                        cells: [
                            { key: 'name', type: 'value', value: 'Summe' },
                            {
                                key: 'distributionAmount',
                                type: 'value',
                                value: extendedIncomeAndExpenditure.totalDistributionAmount,
                                pipeToApply: 'eurocent',
                            },
                            {
                                key: 'planAmount',
                                type: 'value',
                                value: extendedIncomeAndExpenditure.totalPlanAmount,
                                pipeToApply: 'eurocent',
                            },
                        ],
                        rowClass: '[&&]:tw-s-head-14-22-bold [&&]:tw-h-10',
                    },
                    //Ergebnis zum 31.12.2023
                    {
                        cells: [{ key: 'name', type: 'value', value: '', rowCellClass: '[&&]:tw-h-16' }],
                        rowClass: '[&&]:tw-border-b [&&]:tw-border-solid [&&]:tw-border-scalaraGray-01',
                    },
                    {
                        cells: [
                            {
                                key: 'name',
                                type: 'value',
                                value: `Ergebnis ${this.datePipe.transform(wss?.economicPlan.endDate, 'dd.MM.yyyy')}`,
                            },
                            {
                                key: 'amount',
                                type: 'value',
                                value: extendedIncomeAndExpenditure.totalAmount,
                                pipeToApply: 'eurocent',
                            },
                        ],
                        rowClass: '[&&]:tw-s-head-14-22-bold [&&]:tw-h-10',
                    },
                    //Geldkonten Endbestand am 31.12.2023
                    { cells: [{ key: 'name', type: 'value', value: '', rowCellClass: '[&&]:tw-h-14' }] },
                    {
                        cells: [
                            {
                                key: 'name',
                                type: 'value',
                                value: `Geldkonten Endbestand am ${this.datePipe.transform(
                                    wss?.economicPlan.endDate,
                                    'dd.MM.yyyy'
                                )}`,
                                rowCellClass: '[&&]:tw-s-head-16-24-bold [&&]:tw-pb-4',
                            },
                        ],
                    },
                    ...extendedIncomeAndExpenditure.bankAccountsFinal.map((i) => {
                        const row: RowData<ColumnKey> = {
                            cells: [
                                { key: 'name', value: i.accountName, type: 'value' },
                                { key: 'amount', value: i.accountBalance, type: 'value', pipeToApply: 'eurocent' },
                            ],
                        };
                        return row;
                    }),
                    { cells: [{ key: 'name', value: '', type: 'value', rowCellClass: '[&&]:tw-h-4' }] },
                    {
                        cells: [
                            { key: 'name', value: 'Gesamtgeldkontobestände', type: 'value' },
                            {
                                key: 'amount',
                                value: extendedIncomeAndExpenditure.bankAccountsFinalAmountSumm,
                                type: 'value',
                                pipeToApply: 'eurocent',
                            },
                        ],
                        rowClass:
                            '[&&]:tw-s-head-14-22-bold [&&]:tw-h-10 [&&]:tw-border-t [&&]:tw-border-solid [&&]:tw-border-scalaraGray-01',
                    },
                ],
            };

            return table;
        })
    );

    public vm$ = combineLatest([this.ledgerId$, this.wss$, this.incomeAndExpenditure$, this.tableData$]).pipe(
        map(([ledgerId, wss, incomeAndExpenditure, tableData]) => ({ ledgerId, wss, incomeAndExpenditure, tableData }))
    );
    public vm: ObservedValueOf<typeof this.vm$> | null = null;

    public ngOnInit(): void {
        this.vm$.pipe(takeUntil(this.unsubscribe$)).subscribe((vm) => {
            this.vm = vm;
            console.log({ vm });
        });
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
