import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ListItem } from 'carbon-components-angular';
import { Subject, of, switchMap, takeUntil, tap } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { getNameFromPerson, getOccupationTypeLabel, unitTypeNameFromEnum } from 'src/app/core/utils/common';
import {
    CreateOesDistributionKeyDto,
    OccupationForOESDistributionKey,
    OesDistributionKeyDto,
    OperationsExpenseStatementsService,
} from 'src/app/generated-sources/accounting';
import { Occupation, OccupationService } from 'src/app/generated-sources/base';
import { OverlayChildComponent } from 'src/app/shared/overlay/components/overlay-child/overlay-child.component';
import { OverlayService } from 'src/app/shared/overlay/services/overlay.service';
import { LedgerCustomService } from '../../services/ledger-custom.service';
import { EditOperationExpenseStatementComponent } from '../edit-operation-expense-statement/edit-operation-expense-statement.component';

@Component({
    selector: 'app-add-oes-distribution-key',
    templateUrl: './add-oes-distribution-key.component.html',
    styleUrls: ['./add-oes-distribution-key.component.scss'],
})
export class AddOesDistributionKeyComponent extends OverlayChildComponent implements OnInit {
    private unsubscribe$ = new Subject<void>();
    public isLoading = false;
    public oesDistributionKey: OesDistributionKeyDto[] = [];
    public selectedDistributionKey: OesDistributionKeyDto.DistributionBaseEnum = 'RESIDENTIAL_UNITS';
    public occupations: Occupation[] = [];
    public ledgerId = '';
    public oesId = '';
    public form: UntypedFormGroup = this.formBuilder.group({
        description: ['', [Validators.required]],
        distributionBase: ['OCCUPATIONS', [Validators.required]],
        occupations: this.formBuilder.array([]),
    });
    public isEditOesOverlay = false;
    public listDistributionBase: ListItem[] = [
        {
            value: 'RESIDENTIAL_UNITS',
            content: this.translateService.instant('ENTITIES.OWNERSHIP.LABEL_RESIDENTIAL_UNITS'),
            selected: true,
        },
        {
            value: 'PERSONS',
            content: this.translateService.instant('ENTITIES.OWNERSHIP.LABEL_PERSONS'),
            selected: false,
        },
        {
            value: 'SQUARE_METERS',
            content: this.translateService.instant('ENTITIES.OWNERSHIP.LABEL_SQUARE_METERS'),
            selected: false,
        },
        {
            value: 'HEATED_SQUARE_METERS',
            content: this.translateService.instant('ENTITIES.OWNERSHIP.LABEL_HEATED_SQUARE_METERS'),
            selected: false,
        },
    ];
    public totalAmount = 0;

    public constructor(
        private operationsExpenseStatementsService: OperationsExpenseStatementsService,
        private occupationService: OccupationService,
        private ledgerCustomService: LedgerCustomService,
        private formBuilder: UntypedFormBuilder,
        private toastService: ToastService,
        private translateService: TranslateService,
        private overlayService: OverlayService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.ledgerId = this.config?.data.ledgerId;
        this.oesId = this.config?.data.oesId;
        this.isEditOesOverlay = this.config?.data.isEditOesOverlay;
        this.init();
    }

    private init(): void {
        this.isLoading = true;

        this.operationsExpenseStatementsService
            .findAllDistributionKeys(this.ledgerId, this.oesId, '', 'OCCUPATIONS')
            .pipe(
                switchMap((oesDistributionKeyDto) => {
                    this.oesDistributionKey = oesDistributionKeyDto.filter(
                        (item) => item.distributionGroup === 'ALL_OCCUPATIONS'
                    );

                    return this.ledgerCustomService.getLedgerPropertyId$();
                }),
                switchMap((propertyId) => {
                    if (this.oesDistributionKey.length > 0) {
                        const occupationsIds: string[] = [];
                        this.oesDistributionKey.map((item) =>
                            item.occupations.map((occupation) => occupationsIds.push(occupation.occupationId))
                        );

                        return this.occupationService.findAllOccupations(propertyId || '', occupationsIds.join(','));
                    } else {
                        return of([]);
                    }
                }),
                tap((occupations) => {
                    this.occupations = occupations;
                    this.occupationsControl.clear();
                    occupations.map((occupation) => {
                        let distributionBaseShare;
                        this.oesDistributionKey.map((item) => {
                            const findItem = item.occupations.find(
                                (itemOccupation) =>
                                    itemOccupation.occupationId === occupation.id &&
                                    item.distributionBase === this.selectedDistributionKey
                            );
                            if (findItem) {
                                if (this.selectedDistributionKey === 'RESIDENTIAL_UNITS') {
                                    distributionBaseShare = occupation.unitsIncluded.length;
                                } else {
                                    distributionBaseShare = findItem.distributionBaseShare;
                                }
                            }
                        });
                        this.occupationsControl.push(
                            this.formBuilder.group({
                                occupationId: [occupation.id],
                                distributionBaseShare: [distributionBaseShare],
                                selected: [false],
                                occupation: [occupation],
                            })
                        );
                    });
                    this.isLoading = false;
                })
            )
            .subscribe();
    }

    public get occupationsControl(): UntypedFormArray {
        return this.form.controls['occupations'] as UntypedFormArray;
    }

    public getOccupationDescription(occupation: Occupation): {
        occupationDetail: string;
        occupationNumberAndTenants: string;
    } {
        if (!occupation) {
            return { occupationDetail: '', occupationNumberAndTenants: '' };
        }
        const units = occupation.unitsIncluded.map((unit) => unitTypeNameFromEnum(unit) + ' ' + unit.name).join(', ');
        const tenants = occupation.tenants
            .map((tenant) => (tenant && (tenant.firstName || tenant.companyName) ? getNameFromPerson(tenant) : ''))
            .join(', ');

        return {
            occupationDetail:
                getOccupationTypeLabel(occupation?.occupationType, occupation.rentDetails?.rentType, false) +
                ', ' +
                units,
            occupationNumberAndTenants:
                occupation.occupationNumber + (occupation.occupationType === 'VACANCY' ? '' : ', ' + tenants),
        };
    }

    public isInvalidForm(): boolean {
        return (
            this.form.invalid ||
            (this.occupationsControl.value.find((value: any) => value.selected === true && value.distributionBaseShare)
                ? false
                : true)
        );
    }

    public abort(): void {
        if (this.isEditOesOverlay) {
            this.cancelEmitter$.next();

            this.overlayService.removeOverlayComponentFromBody();

            const ref = this.overlayService.open(EditOperationExpenseStatementComponent, {
                data: { ledgerId: this.ledgerId, oesId: this.oesId, componentToShow: 'add_consumption' },
            });

            ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

            ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe({
                next: () => {
                    this.saveEmitter$.next();
                },
            });
        } else {
            this.cancelEmitter$.next();
        }
    }

    public submit(closeOverlay = false): void {
        this.isLoading = true;
        const occupationForOESDistributionKey: OccupationForOESDistributionKey[] = this.occupationsControl.value
            .filter((value: any) => value.selected === true)
            .map((value: any) => {
                return {
                    occupationId: value.occupationId,
                };
            });

        const createOesDistributionKeyDto: CreateOesDistributionKeyDto = {
            description: this.form.value.description,
            distributionBase: this.selectedDistributionKey,
            occupations: occupationForOESDistributionKey,
        };

        this.isLoading = true;

        this.operationsExpenseStatementsService
            .createDistributionKey(this.ledgerId, this.oesId, createOesDistributionKeyDto)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: () => {
                    this.toastService.showSuccess('Verbrauch erfolgreich erstellt.');
                    if (this.isEditOesOverlay && !closeOverlay) {
                        this.cancelEmitter$.next();

                        this.overlayService.removeOverlayComponentFromBody();

                        const ref = this.overlayService.open(EditOperationExpenseStatementComponent, {
                            data: {
                                ledgerId: this.ledgerId,
                                oesId: this.oesId,
                                componentToShow: 'add_distribution_key',
                            },
                        });

                        ref.cancelEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => ref.close());

                        ref.saveEmitter$.pipe(takeUntil(this.unsubscribe$)).subscribe({
                            next: () => {
                                this.saveEmitter$.next();
                            },
                        });
                    } else {
                        this.saveEmitter$.next();
                    }
                    this.isLoading = false;
                },
                error: (error) => {
                    this.isLoading = false;
                    if (error) {
                        this.toastService.showError(error.error['message']);
                    }
                },
            });
    }

    public onCheckboxChange(event: any, index: number): void {
        const distributionBaseShare =
            (this.occupationsControl?.at(index) as UntypedFormGroup).get('distributionBaseShare')?.value ?? 0;
        if (event.checked) {
            this.totalAmount = (this.totalAmount || 0) + distributionBaseShare;
        } else {
            if (this.totalAmount > 0) {
                this.totalAmount = this.totalAmount - distributionBaseShare;
            }
        }
    }

    public onSelectDistributionBase(event: any): void {
        this.totalAmount = 0;
        this.selectedDistributionKey = event.item.value;
        this.init();
    }
}
