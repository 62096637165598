import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ListItem } from 'carbon-components-angular';
import { Subject } from 'rxjs';
import { BankAccountDto } from 'src/app/generated-sources/accounting';
import { onSubmitAddBankaccountCallback } from '../ledger-reasonability-checks.component';

@Component({
    selector: 'app-ledger-reasonability-checks-add-additional-bankaccount',
    templateUrl: './ledger-reasonability-checks-add-additional-bankaccount.component.html',
    styleUrls: ['./ledger-reasonability-checks-add-additional-bankaccount.component.scss'],
})
export class LedgerReasonabilityChecksAddAdditionalBankaccountComponent implements OnInit, OnChanges {
    public addbankAccountFormGroup: FormGroup = new FormGroup({});
    public bankAccountItemList$ = new Subject<ListItem[]>();
    public selectedBankAccount?: string;
    @Input()
    public bankAccountList: BankAccountDto[] = [];
    @Input()
    public onSubmitCallback?: onSubmitAddBankaccountCallback;

    public constructor(
        private translateService: TranslateService,

        private formBuilder: FormBuilder
    ) {}

    public ngOnInit(): void {
        this.addbankAccountFormGroup = this.formBuilder.group({
            bankAccounts: new FormControl('', [Validators.required]),
        });
        this.addbankAccountFormGroup.updateValueAndValidity();
        this.updateBankAccountListItems(this.bankAccountList);
    }
    public ngOnChanges(): void {
        this.updateBankAccountListItems(this.bankAccountList);
    }

    public onSubmit(): void {
        if (this.selectedBankAccount && this.onSubmitCallback) {
            this.onSubmitCallback(this.selectedBankAccount);
        }
    }

    private updateBankAccountListItems(bankAccountList: BankAccountDto[]): void {
        this.bankAccountItemList$.next(
            bankAccountList.map((bankAccount) => {
                return {
                    content: `${bankAccount.iban}, ${bankAccount.holderName}, ${bankAccount.bankName}`,
                    value: bankAccount.id,
                    selected: false,
                    id: bankAccount.id,
                };
            })
        );
    }

    public onSelectedBankAccount($event: any): void {
        this.selectedBankAccount = $event.item['value'];
        this.addbankAccountFormGroup;
    }
}
