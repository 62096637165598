<div [formGroup]="form!">
    <div [class.mb-10]="selectedBookingId !== 4" [formArrayName]="type!">
        <div class="mb-07">
            <div class="s-head-18-26-bold">{{ title! | translate }}</div>
        </div>

        <ng-container *ngFor="let bookingItem of formArray?.controls; let i = index">
            <ng-container [formGroupName]="i">
                <ng-container *ngIf="i > 0">
                    <hr class="mb-04 mt-06" />
                    <div class="d-flex justify-content-end mb-05">
                        <div
                            class="tw-fill-scalaraGray-01 tw-w-4 tw-h-4 tw-cursor-pointer"
                            (click)="removeItemFromForm(i)"
                        >
                            <svg-icon src="/assets/icons/24_close.svg"></svg-icon>
                        </div>
                    </div>
                </ng-container>

                <div
                    ibmRow
                    *ngIf="
                        thereIsReserveFundAccount && reserveFundAccountListItems && reserveFundAccountListItems.length
                    "
                    class="mb-07"
                >
                    <div ibmCol [columnNumbers]="{ md: isDoubleDropdown ? 11 : 16, lg: isDoubleDropdown ? 11 : 16 }">
                        <app-combo-box
                            [size]="'md'"
                            id="property"
                            placeholder="{{
                                reserveFundAccountListItems.length < 2
                                    ? reserveFundAccountListItems[0].content
                                    : ('COMMON.LABEL_PLEASE_SELECT' | translate)
                            }}"
                            [disabled]="reserveFundAccountListItems.length < 2"
                            [appendInline]="true"
                            label="{{ titleReserveFundAccountListItems }}"
                            [items]="reserveFundAccountListItems || []"
                            (search)="invalidateReserveFundAccount(i)"
                            [invalid]="isFormControlFromArrayInvalid(i, 'reserveFundAccount')"
                            invalidText="{{ 'ACCOUNTING.ERROR.INVALID_AMOUNT' | translate }}"
                            formControlName="reserveFundAccount"
                        >
                            <ibm-dropdown-list></ibm-dropdown-list>
                        </app-combo-box>
                    </div>
                </div>

                <div ibmRow class="align-items-base mb-04">
                    <div ibmCol [columnNumbers]="{ md: 11, lg: 11 }">
                        <app-combo-box
                            *ngIf="accountListItems.length"
                            [size]="'md'"
                            id="property"
                            placeholder="{{
                                accountListItems.length < 2
                                    ? accountListItems[0].content
                                    : ('COMMON.LABEL_PLEASE_SELECT' | translate)
                            }}"
                            [disabled]="accountListItems.length < 2"
                            [appendInline]="true"
                            label="{{
                                label ? label : ('ACCOUNTING.ADD_BOOKING_FORM.LABEL_ACCOUNT' | translate) + '*'
                            }}"
                            [items]="accountListItems"
                            (selected)="onSelectAccount({ index: i, type: type, formArray: formArray })"
                            (search)="invalidateAccount(i)"
                            [invalid]="isFormControlFromArrayInvalid(i, 'account')"
                            invalidText="{{ 'ACCOUNTING.ERROR.INVALID_AMOUNT' | translate }}"
                            formControlName="account"
                        >
                            <ibm-dropdown-list></ibm-dropdown-list>
                        </app-combo-box>
                    </div>

                    <div ibmCol [columnNumbers]="{ md: 5, lg: 5 }">
                        <app-number-input
                            label="{{ 'ACCOUNTING.ADD_BOOKING_FORM.LABEL_AMMOUNT_IN_EURO' | translate }}*"
                            [invalid]="isFormControlFromArrayInvalid(i, 'amount')"
                            invalidText="{{ 'ACCOUNTING.ERROR.INVALID_AMOUNT' | translate }}"
                            [disabled]="amountDisabled"
                            [disabledTooltipMessage]="amountDisabledTooltipMessage"
                            formControlName="amount"
                        ></app-number-input>
                    </div>
                </div>
                <div *ngIf="taxShare" ibmRow class="align-items-base mb-04 tax-share">
                    <div ibmCol [columnNumbers]="{ md: 11, lg: 11 }" class="d-flex align-items-center tax">
                        <div>
                            <ibm-checkbox formControlName="checkboxTaxShare">
                                {{ 'ACCOUNTING.COMMON.TAX_RATE_OPTION' | translate }}
                            </ibm-checkbox>
                        </div>
                        <div class="d-flex ml-auto" *ngIf="checkTaxShareBox(i)">
                            <div class="s-label-12-22-semibold mr-1-5r">
                                {{ taxShare.label }}
                            </div>
                            <ibm-radio-group
                                aria-label="radiogroup"
                                orientation="horizontal"
                                formControlName="taxShare"
                            >
                                <ibm-radio value="{{ taxShare.valueOptions[0].id }}">
                                    {{ taxShare.valueOptions[0].label }}
                                </ibm-radio>
                                <ibm-radio [checked]="true" value="{{ taxShare.valueOptions[1].id }}">
                                    {{ taxShare.valueOptions[1].label }}
                                </ibm-radio>
                            </ibm-radio-group>
                        </div>
                    </div>
                    <div *ngIf="checkTaxShareBox(i)" ibmCol class="text-right pr-2r" [columnNumbers]="{ md: 5, lg: 5 }">
                        {{ calculateTaxShareSum(i) | eurocent }}
                    </div>
                </div>
                <div *ngIf="labourAmount" ibmRow>
                    <div ibmCol [columnNumbers]="{ md: 11, lg: 11 }">
                        <ibm-checkbox formControlName="checkboxAmountLabour">
                            {{ 'ACCOUNTING.COMMON.SALARY_SHARE' | translate }}
                        </ibm-checkbox>
                    </div>
                    <div ibmCol [columnNumbers]="{ md: 5, lg: 5 }" *ngIf="checkLabourAmountBox(i)">
                        <app-number-input
                            label="{{ 'ACCOUNTING.COMMON.SALARY_AMOUNT' | translate }} {{
                                this.labourAmount.isOptional ? '' : '*'
                            }}"
                            [invalid]="isLabourAmountInvalid(i, 'amount', 'amountLabour')"
                            [invalidText]="showLabourAmountInvalidText(i)"
                            [disabled]="amountDisabled"
                            [disabledTooltipMessage]="amountDisabledTooltipMessage"
                            formControlName="amountLabour"
                        ></app-number-input>
                    </div>
                </div>
            </ng-container>
        </ng-container>

        <div ibmRow class="align-items-center" *ngIf="enableMultipleBookings">
            <div ibmCol [columnNumbers]="{ md: 11, lg: 11 }">
                <app-scalara-button (click)="addItemToForm()" variant="ghost" svgName="24_add.svg" height="40px"
                    >{{ 'ACCOUNTING.ADD_BOOKING_FORM.BTN_ADD_ACCOUNT' | translate }}
                </app-scalara-button>
            </div>
            <div ibmCol [columnNumbers]="{ md: 5, lg: 5 }">
                <div ibmRow *ngIf="showSum">
                    <div ibmCol class="s-gray-03 s-head-14-22-regular">
                        {{ 'ACCOUNTING.ADD_BOOKING_FORM.SUM' | translate }}
                    </div>
                    <div ibmCol class="text-right pr-07">
                        <div class="s-label-12-22">{{ calculateSum() }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
