<div ibmRow *ngIf="this.receipts">
    <div ibmCol>
        <app-table
            [emptyText]="'PAGES.RECEIPTS.NO_RECEIPTS_AVAILABLE'"
            [header]="tableModel.header"
            (handleFile)="handleReceipt($event)"
            [data]="tableModel.data"
        >
        </app-table>
    </div>

    <ng-container *ngIf="fileToView && showModal">
        <app-file-viewer [fileToView]="fileToView" (closeViewer)="handleModal()"></app-file-viewer>
    </ng-container>
</div>
